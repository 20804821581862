import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Arrange(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Arrange Rows of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>arrange()</code></strong> to reorder rows of a dataset by values of selected columns. By default, rows are arranged in the ascending order.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># reorder rows by "height" in the default ascending order</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">arrange</span>(height)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name                  height  mass hair_color skin_color 
<br/>    &lt;chr&gt;                  &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Yoda                      66    17 white      green      
<br/>  2 Ratts Tyerel              79    15 none       grey, blue 
<br/>  3 Wicket Systri Warrick     88    20 brown      brown      
<br/>  4 Dud Bolt                  94    45 none       blue, grey 
<br/>  5 R2-D2                     96    32 &lt;NA&gt;       white, blue
<br/>  6 R4-P17                    96    NA none       silver, red
<br/>  7 R5-D4                     97    32 &lt;NA&gt;       white, red 
<br/>  8 Sebulba                  112    40 none       grey, red  
<br/>  # ℹ 79 more rows</code></pre>
</div>
</div>
<p>Use <strong><code>desc()</code></strong> to rearrange by descending order.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">arrange</span>(<span className="fu">desc</span>(height))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name         height  mass hair_color skin_color  
<br/>    &lt;chr&gt;         &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;       
<br/>  1 Yarael Poof     264    NA none       white       
<br/>  2 Tarfful         234   136 brown      brown       
<br/>  3 Lama Su         229    88 none       grey        
<br/>  4 Chewbacca       228   112 brown      unknown     
<br/>  5 Roos Tarpals    224    82 none       grey        
<br/>  6 Grievous        216   159 none       brown, white
<br/>  7 Taun We         213    NA none       grey        
<br/>  8 Rugor Nass      206    NA none       green       
<br/>  # ℹ 79 more rows</code></pre>
</div>
</div>
<p>You can arrange multiple variables at the same time with <code>arrange(A, B, C...)</code>: rows are first arranged by variable A; for rows having the same level of A, they are further arranged based on levels of B, and so on.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># rearrange FIRST by "hair_color" (in the alphabetical order)</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="co"># if there are multiple rows having the same hair color, </span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a><span className="co"># these rows are further arranged by "height" (in numerical order)</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">arrange</span>(hair_color, height)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name            height  mass hair_color    skin_color
<br/>    &lt;chr&gt;            &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;         &lt;chr&gt;     
<br/>  1 Mon Mothma         150  NA   auburn        fair      
<br/>  2 Wilhuff Tarkin     180  NA   auburn, grey  fair      
<br/>  3 Obi-Wan Kenobi     182  77   auburn, white fair      
<br/>  4 Watto              137  NA   black         blue, grey
<br/>  5 Shmi Skywalker     163  NA   black         fair      
<br/>  6 Barriss Offee      166  50   black         yellow    
<br/>  7 Luminara Unduli    170  56.2 black         yellow    
<br/>  8 Eeth Koth          171  NA   black         brown     
<br/>  # ℹ 79 more rows</code></pre>
</div>
</div>
<p>Unlike verbs <Link to="../4-mutate-columns"><code>mutate()</code></Link>, <Link to="../3-filter-rows"><code>filter()</code></Link>, and <Link to="../5-summarize"><code>summarise()</code></Link>, <code>arrange()</code> ignores the <Link to="../6-grouped-dataset">grouping variables</Link> (specified by <code>group_by()</code>), and arranges rows across the entire dataset. To arrange rows separately for each group, explicitly set <strong><code>.by_group = T</code></strong>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># reorder by "height" within each type of "hair_color"</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">group_by</span>(hair_color) <span className="sc">%&gt;%</span> </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">arrange</span>(height, <span className="at">.by_group =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>  # Groups:   hair_color [12]
<br/>    name            height  mass hair_color    skin_color
<br/>    &lt;chr&gt;            &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;         &lt;chr&gt;     
<br/>  1 Mon Mothma         150  NA   auburn        fair      
<br/>  2 Wilhuff Tarkin     180  NA   auburn, grey  fair      
<br/>  3 Obi-Wan Kenobi     182  77   auburn, white fair      
<br/>  4 Watto              137  NA   black         blue, grey
<br/>  5 Shmi Skywalker     163  NA   black         fair      
<br/>  6 Barriss Offee      166  50   black         yellow    
<br/>  7 Luminara Unduli    170  56.2 black         yellow    
<br/>  8 Eeth Koth          171  NA   black         brown     
<br/>  # ℹ 79 more rows</code></pre>
</div>
</div>
</main>
</div>
</div>
)}