import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ConvertVectorToOneDimensionTibble(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Convert a Vector or List to a Single Dimension Tibble</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<ul>
<li><a href="#as_tibble_col"><code>as_tibble_col()</code></a> turns a vector or list to a single-<strong><em>column</em></strong> tibble.</li>
<li><a href="#as_tibble_row"><code>as_tibble_row()</code></a> turns a vector or list to a single-<strong><em>row</em></strong> tibble.</li>
</ul>
<hr/>
<p><span id="as_tibble_col"><code>as_tibble_col()</code></span> converts a vector to a single-column tibble.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># Create a named vector of fruit sales</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>s <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="at">apples =</span> <span className="dv">50</span>, <span className="at">oranges =</span> <span className="dv">30</span>, <span className="at">bananas =</span> <span className="dv">20</span>, <span className="at">grapes =</span> <span className="dv">40</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># turn the vector to a single-column tibble</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">as_tibble_col</span>(s)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 1
<br/>  value
<br/>  &lt;dbl&gt;
<br/>1    50
<br/>2    30
<br/>3    20
<br/>4    40</code></pre>
</div>
</div>
<p>Use argument <code>column_name</code> to specify the column name.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># rename column as 'sales'</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">as_tibble_col</span>(s, <span className="at">column_name =</span> <span className="st">"sales"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 1
<br/>  sales
<br/>  &lt;dbl&gt;
<br/>1    50
<br/>2    30
<br/>3    20
<br/>4    40</code></pre>
</div>
</div>
<p>Names of the vector elements (if any) are dropped from the tibble output. If you wish to reserve the names of the vector elements, use <Link to="../convert-vector-list-to-tibble"><code>enframe()</code></Link> instead.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">enframe</span>(s, <span className="at">name =</span> <span className="st">"fruit"</span>, <span className="at">value =</span> <span className="st">"sales"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 2
<br/>  fruit   sales
<br/>  &lt;chr&gt;   &lt;dbl&gt;
<br/>1 apples     50
<br/>2 oranges    30
<br/>3 bananas    20
<br/>4 grapes     40</code></pre>
</div>
</div>
<p>You can also use <code>as_tibble_col()</code> to convert a list to a one-column tibble, with the column being a list-column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>L <span className="ot">&lt;-</span> <span className="fu">list</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="at">name =</span> <span className="st">"John"</span>, <span className="at">age =</span> <span className="dv">30</span>, <span className="at">married =</span> <span className="cn">TRUE</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="at">children =</span> <span className="fu">list</span>(<span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>)))</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a></span><br/>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a><span className="fu">as_tibble_col</span>(L, <span className="at">column_name =</span> <span className="st">"person"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 1
<br/>  person      
<br/>  &lt;named list&gt;
<br/>1 &lt;chr [1]&gt;   
<br/>2 &lt;dbl [1]&gt;   
<br/>3 &lt;lgl [1]&gt;   
<br/>4 &lt;list [1]&gt;  </code></pre>
</div>
</div>
<p>You can use <Link to="../convert-two-columns-to-vector-or-list"><code>deframe()</code></Link> to turn the output back to list; that is, <code>L</code> is identical to <code>as_tibble_col(L) %&gt;% deframe()</code>.</p>
<hr/>
<p>Opposite to <code>as_tibble_col()</code>, the function <span id="as_tibble_row"><strong><code>as_tibble_row()</code></strong></span> turns a vector or list into a single-row tibble, using names of the elements as the column names, and values of the elements as values in the first row.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># convert a vector to one-row tibble</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">as_tibble_row</span>(s)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 1 × 4
<br/>  apples oranges bananas grapes
<br/>   &lt;dbl&gt;   &lt;dbl&gt;   &lt;dbl&gt;  &lt;dbl&gt;
<br/>1     50      30      20     40</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># convert a list to one-row tibble</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">as_tibble_row</span>(L)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 1 × 4
<br/>  name    age married children 
<br/>  &lt;chr&gt; &lt;dbl&gt; &lt;lgl&gt;   &lt;list&gt;   
<br/>1 John     30 TRUE    &lt;chr [2]&gt;</code></pre>
</div>
</div>
</main>
</div>
</div>
)}