import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2DrawWithMathFunctions from '../graphics/artwork_overflow_completed.png'; 
import imgGgplot2DrawWithMathFunctionsWebp from '../graphics/artwork_overflow_completed.webp'; 
export default function Ggplot2DrawWithMathFunctions(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Interesting Patterns with Math Functions in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2DrawWithMathFunctionsWebp} />
    <img className="cover-img" src={imgGgplot2DrawWithMathFunctions} />
  </picture>

<p>This fun visualization is inspired from a <Link to="https://stackoverflow.com/questions/21088480/change-ggplot2-colourbar-tick-marks-to-black">post in stack overflow</Link>, and illustrates the use of mathematical functions to create interesting patterns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>fun.plot  <span className="ot">&lt;-</span>  <span className="cf">function</span>(<span className="at">n =</span> <span className="dv">100</span>)&#123;</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  y <span className="ot">&lt;-</span> x <span className="ot">&lt;-</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">4</span> <span className="sc">*</span> pi, <span className="dv">4</span> <span className="sc">*</span> pi, <span className="at">len =</span> n)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  r <span className="ot">&lt;-</span> <span className="fu">sin</span>( <span className="fu">cos</span>(<span className="fu">outer</span>(x<span className="sc">^</span><span className="dv">2</span>, y<span className="sc">^</span><span className="dv">2</span>, <span className="st">"+"</span>)) <span className="sc">^</span> <span className="dv">2</span> )</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  df <span className="ot">&lt;-</span> <span className="fu">data.frame</span>( </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>    <span className="at">x =</span> <span className="fu">rep</span>( x , <span className="at">each =</span> n) , </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>    <span className="at">y =</span> <span className="fu">rep</span>( y , <span className="at">times =</span> n ), </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>    <span className="at">val =</span> <span className="fu">c</span>(r))</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">ggplot</span>(df, <span className="fu">aes</span>(x, y, <span className="at">fill =</span> val))<span className="sc">+</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>    <span className="co"># create a heatmap</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>    <span className="fu">geom_raster</span>() <span className="sc">+</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>    <span className="co"># annotate with n</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>    <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="dv">0</span>, </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>             <span className="at">label =</span> <span className="fu">paste</span>(<span className="st">"n ="</span>, n),</span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>             <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">8</span>) <span className="sc">+</span></span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>    </span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>    <span className="fu">scale_fill_distiller</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>) <span className="sc">+</span></span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>    <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>    <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>&#125;</span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a></span><br/>
<span id="cb1-24"><a aria-hidden="true" href="#cb1-24" tabindex="-1"></a></span><br/>
<span id="cb1-25"><a aria-hidden="true" href="#cb1-25" tabindex="-1"></a><span className="co"># Combine six plots together </span></span>
<span id="cb1-26"><a aria-hidden="true" href="#cb1-26" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb1-27"><a aria-hidden="true" href="#cb1-27" tabindex="-1"></a></span><br/>
<span id="cb1-28"><a aria-hidden="true" href="#cb1-28" tabindex="-1"></a>(<span className="fu">fun.plot</span>(<span className="at">n =</span> <span className="dv">10</span>)  <span className="sc">|</span> <span className="fu">fun.plot</span>(<span className="at">n =</span> <span className="dv">25</span>) <span className="sc">|</span> <span className="fu">fun.plot</span>(<span className="at">n =</span> <span className="dv">50</span>) ) <span className="sc">/</span></span>
<span id="cb1-29"><a aria-hidden="true" href="#cb1-29" tabindex="-1"></a>  (<span className="fu">fun.plot</span>(<span className="at">n =</span> <span className="dv">100</span>) <span className="sc">|</span> <span className="fu">fun.plot</span>(<span className="at">n =</span> <span className="dv">200</span>) <span className="sc">|</span> <span className="fu">fun.plot</span>(<span className="at">n =</span> <span className="dv">500</span>))</span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In addition to using self-defined math formulas to create beautiful patterns, the <Link to="../ggplot2-aRtsy-R-artworks"><strong>aRtsy</strong></Link> package is another amazing approach to create engaging artworks using pre-defined algorithms.</p>
<p><Link to="../ggplot2-aRtsy-R-artworks">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/aRtsy_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/aRtsy_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to abstract artworks created by randomization algorithms, <strong>heatmaps</strong> are another powerful tool that not only offers unique aesthetic appeal, but also provides valuable insights into data patterns – check out the following <Link to="../ggplot2-heatmap-African-population"><strong>amazing heatmap visualizing the population distribution in Africa</strong></Link>.</p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>And check out these <Link to="../ggplot2-heatmap-vaccine-for-8-diseases"><strong>beautiful heatmaps highlighting the power of vaccines in disease control</strong></Link> throughout the U.S. history.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Last but not the least, check out this awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}