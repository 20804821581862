import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ExtractASingleColumn(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Extract a Single Column of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>pull()</code></strong> is similar to the dollar operator <code>$</code>: it extracts values from a single selected column. The main advantage of <code>pull()</code> is its better streamline with the pipe operation. The output is a <em>vector</em>, not a dataset. There are three options to specify a column to extract:</p>
<ul>
<li>a literal variable name,</li>
<li>a positive integer, counting the column from the left of the dataset</li>
<li>a negative integer, counting the column from the right of the dataset</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>students <span className="ot">&lt;-</span> <span className="fu">data.frame</span>(</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"Charlie"</span>, <span className="st">"David"</span>, <span className="st">"Eva"</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">22</span>, <span className="dv">23</span>, <span className="dv">21</span>, <span className="dv">24</span>, <span className="dv">22</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="at">Test_Score =</span> <span className="fu">c</span>(<span className="dv">85</span>, <span className="dv">92</span>, <span className="dv">78</span>, <span className="dv">89</span>, <span className="dv">95</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>students</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">       Name Age Test_Score
<br/>  1   Alice  22         85
<br/>  2     Bob  23         92
<br/>  3 Charlie  21         78
<br/>  4   David  24         89
<br/>  5     Eva  22         95</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>students<span className="sc">$</span>Name</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "Alice"   "Bob"     "Charlie" "David"   "Eva"</code></pre>
</div>
</div>
<p>The script above can be equivalently written in a piped style:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>students <span className="sc">%&gt;%</span> <span className="fu">pull</span>(Name)</span></code></pre></div>
</div>
<p>If the column is not specified, the function defaults to extract the last column, assuming that the last column is the one most recently created. (recall that by default new columns created by <Link to="../4-mutate-columns"><code>mutate()</code></Link> are placed at the end of the dataset)</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="co"># extract the last column </span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>students <span className="sc">%&gt;%</span> <span className="fu">pull</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 85 92 78 89 95</code></pre>
</div>
</div>
<p>Use argument <strong><code>name</code></strong> to specify a column whose values will be used as names of elements of the extracted vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># name the scores with students' names</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>students <span className="sc">%&gt;%</span> <span className="fu">pull</span>(Test_Score, <span className="at">name =</span> Name)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    Alice     Bob Charlie   David     Eva 
<br/>       85      92      78      89      95</code></pre>
</div>
</div>
<p>Recall that alternative to <code>pull()</code>, you can use the <Link to="../1-pipe-operator">special feature of the pipe operator</Link>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>students <span className="sc">%&gt;%</span> .<span className="sc">$</span>Name</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "Alice"   "Bob"     "Charlie" "David"   "Eva"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}