import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderViolinPlots from '../tutorial/reorder_violin_plot_default_alphabetical.png';

export default function Ggplot2ReorderViolinPlots(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Reorder Violin Plots by Summary Statistics in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>From the prior learning, you have mastered <Link to="../4-ggplot2-reorder-bars">four distinct approaches to reorder a bar plot</Link>. In this tutorial, you’ll elevate your skills to a higher level - <strong>reorder the graphics based on <em>summary statistics</em></strong>:</p>
<p>You’ll use the <code>mpg</code> dataset from the ggplot2 package, and create violin plots to display the highway mileage per gallon (<code>hwy</code>) of different class (<code>class</code>) of cars. You’ll reorder the violin plots based on different statistics (e.g., mean and standard deviation) of the <code>hwy</code> variable.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>())</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">head</span>(mpg, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 11
<br/>  manufacturer model displ  year   cyl trans      drv     cty   hwy fl    class 
<br/>  &lt;chr&gt;        &lt;chr&gt; &lt;dbl&gt; &lt;int&gt; &lt;int&gt; &lt;chr&gt;      &lt;chr&gt; &lt;int&gt; &lt;int&gt; &lt;chr&gt; &lt;chr&gt; 
<br/>1 audi         a4      1.8  1999     4 auto(l5)   f        18    29 p     compa…
<br/>2 audi         a4      1.8  1999     4 manual(m5) f        21    29 p     compa…
<br/>3 audi         a4      2    2008     4 manual(m6) f        20    31 p     compa…
<br/>4 audi         a4      2    2008     4 auto(av)   f        21    30 p     compa…</code></pre>
</div>
</div>
<p>The default violin plots are arranged in the alphabetical order of the <code>class</code> names of the cars.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>mpg <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(class, hwy)) <span className="sc">+</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_violin</span>(<span className="at">width =</span> <span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderViolinPlots} /></p>
</figure>
</div>
</div>
</div>
<section className="level3" id="reorder-violins-by-the-average-of-mileage-per-gallon">
<h3 className="anchored" data-anchor-id="reorder-violins-by-the-average-of-mileage-per-gallon">Reorder violins by the <em>average</em> of mileage per gallon</h3>
<p>You’ll rearrange the violin plots (the <code>class</code> variable) based on the average of mileage per gallon (the <code>hwy</code> variable). You’ll complete this via two approaches: the <code>dplyr</code> way, and the <code>forcats</code> way.</p>
<section className="level4" id="rearrange-the-violins-via-the-dplyr-approach.">
<h4 className="anchored" data-anchor-id="rearrange-the-violins-via-the-dplyr-approach.">Rearrange the violins via the <code>dplyr</code> approach.</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># Arrange the rows based on the mean of 'hwy' of each 'class'</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>mpg.arranged <span className="ot">&lt;-</span> mpg <span className="sc">%&gt;%</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">group_by</span>(class) <span className="sc">%&gt;%</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">hwy.mean =</span> <span className="fu">mean</span>(hwy)) <span className="sc">%&gt;%</span> </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">arrange</span>(hwy.mean)</span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a></span><br/>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a><span className="co"># Extract distinct levels while retaining the rearranged order</span></span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>class.ordered <span className="ot">&lt;-</span> <span className="fu">unique</span>(mpg.arranged<span className="sc">$</span>class)</span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a></span><br/>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a><span className="co"># Convert 'class' to factor to memorize current rearranged order</span></span>
<span id="cb4-11"><a aria-hidden="true" href="#cb4-11" tabindex="-1"></a><span className="co"># there should be NO duplicated values for the 'levels' argument</span></span>
<span id="cb4-12"><a aria-hidden="true" href="#cb4-12" tabindex="-1"></a>mpg.reordered <span className="ot">&lt;-</span> mpg.arranged <span className="sc">%&gt;%</span> </span>
<span id="cb4-13"><a aria-hidden="true" href="#cb4-13" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">class =</span> <span className="fu">factor</span>(class, <span className="at">levels =</span> class.ordered))</span>
<span id="cb4-14"><a aria-hidden="true" href="#cb4-14" tabindex="-1"></a></span><br/>
<span id="cb4-15"><a aria-hidden="true" href="#cb4-15" tabindex="-1"></a><span className="co"># Plot</span></span>
<span id="cb4-16"><a aria-hidden="true" href="#cb4-16" tabindex="-1"></a>mpg.reordered <span className="sc">%&gt;%</span>  </span>
<span id="cb4-17"><a aria-hidden="true" href="#cb4-17" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(class, hwy)) <span className="sc">+</span> </span>
<span id="cb4-18"><a aria-hidden="true" href="#cb4-18" tabindex="-1"></a>  <span className="fu">geom_violin</span>(<span className="at">width =</span> <span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_violin_plot_dplyr_method.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="rearrange-the-violins-via-the-forcats-approach.">
<h4 className="anchored" data-anchor-id="rearrange-the-violins-via-the-forcats-approach.">Rearrange the violins via the <code>forcats</code> approach.</h4>
<p>Use the <code>fct_reorder()</code> function from the <code>forcats</code> package to reorder the levels of the <code>class</code> variable based on the associated <code>hwy</code> values. Use <code>.fun = mean</code> to specify the reordering to be based on the <em>average</em> in each categorical level (group) of the <code>class</code> variable. By default, the ordering is based on the <em>median</em>. Note that there are <em>no</em> parentheses following the function name for the <code>.fun</code> argument.</p>
<p>You can quickly display the average in each violin plot at the same time using <code>stat_summary()</code> with <code>fun = mean</code>. (Check out more techniques to <Link to="../../R/gallery/ggplot2-line-summary-statistics-milk">rapidly visualize group statistics</Link>)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a></span><br/>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>mpg <span className="sc">%&gt;%</span> </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">class =</span> <span className="fu">fct_reorder</span>(class, hwy, <span className="at">.fun =</span> mean)) <span className="sc">%&gt;%</span> </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(class, hwy)) <span className="sc">+</span> </span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">geom_violin</span>(<span className="at">width =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="co"># display the mean of each violin</span></span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun =</span> mean, <span className="at">size =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"turquoise4"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_violin_plot_forcats_mean.png"/></p>
</figure>
</div>
</div>
</div>
<p>As you could see, the <code>forcats</code> package offers a much faster approach than the <code>dplyr</code> way to reorder the violin plots. We’ll keep using the <code>forcats</code> method in the following discussion.</p>
</section>
</section>
<section className="level3" id="reorder-by-the-standard-deviation-of-mileage-per-gallon">
<h3 className="anchored" data-anchor-id="reorder-by-the-standard-deviation-of-mileage-per-gallon">Reorder by the <em>standard deviation</em> of mileage per gallon</h3>
<p>Alternatively, you can <strong>reorder the violins based on the standard deviation</strong> (via function <code>sd()</code>) in each <code>class</code> of the car. Use <code>stat_summary()</code> and <code>fun.data = mean_sdl</code> to simultaneously display the mean and standard deviation (check <Link to="../../R/gallery/ggplot2-line-summary-statistics-milk">here</Link> to learn more about <code>stat_summary()</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>mpg <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">class =</span> <span className="fu">fct_reorder</span>(class, hwy, <span className="at">.fun =</span> sd)) <span className="sc">%&gt;%</span> </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(class, hwy)) <span className="sc">+</span> </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">geom_violin</span>(<span className="at">width =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="co"># display the mean and standard deviation of each violin plot</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun.data =</span> mean_sdl, <span className="at">color =</span> <span className="st">"coral"</span>,</span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>               <span className="at">size =</span> <span className="dv">1</span>, <span className="at">linewidth =</span> <span className="fl">1.5</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_violin_plot_by_variability.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="reorder-by-the-observation-number-in-each-car-class">
<h3 className="anchored" data-anchor-id="reorder-by-the-observation-number-in-each-car-class">Reorder by the <em>observation number</em> in each car class</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>mpg <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">class =</span> <span className="fu">fct_reorder</span>(class, hwy, <span className="at">.fun =</span> length)) <span className="sc">%&gt;%</span> </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(class, hwy)) <span className="sc">+</span> </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">geom_violin</span>() <span className="sc">+</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="co"># display each individual points</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="at">width =</span> .<span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_violin_plot_forcats_observation_number.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}