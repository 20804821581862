import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrLength(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Length of a Vector vs. Length of a String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>It is important to distinguish two fundamental concepts, the length of a string vector, and the length of the string itself. The <strong>length of a vector</strong> refers to the number of <em>elements</em> in that vector. In the following example, we have created a vector of length 3.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apple"</span>, <span className="st">"banana"</span>, <span className="st">"cherry"</span>)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">length</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 3</code></pre>
</div>
</div>
<p>In contrast, the <strong>length of a string</strong> refers to the number of <em>characters</em> (e.g., letters, digits, whitespace, special characters) in that specific string, which can be counted by the <code>stringr</code> function <strong><code>str_length()</code></strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a></span><br/>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a><span className="co"># a single element in the vector</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="fu">str_length</span>(<span className="fu">c</span>(<span className="st">"It's a book"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 11</code></pre>
</div>
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># multiple elements in the vector</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="fu">str_length</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"bcd"</span>, <span className="st">"ef"</span>, <span className="st">"It's a book"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1]  1  3  2 11</code></pre>
</div>
</div>
<p><code>str_length()</code> is equivalent to the base R function <code>nchar()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">nchar</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"bc"</span>, <span className="st">"def"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 1 2 3</code></pre>
</div>
</div>
</main>
</div>
</div>
)}