import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonWhileloop(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="while-Loop"><code>while</code> Loop<a class="anchor-link" href="#while-Loop">¶</a></h3><p>While a <Link to="../python-for-loop">for loop</Link> is associated with iterating over iterables, a <code>while</code> loop relies on comparison operations. It continues executing as long as the comparison evaluates to True. It provides more flexibility as the number of iterations is not fixed and depends on when the condition evaluates to false. Let's explore some examples.</p>
<p><strong><code>while</code>  Loop Syntax:</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="k">while</span> <span class="n">condition</span><span class="p">:</span></span>

<span>    <span class="c1"># Code to be executed while the condition is true</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<ul>
<li><strong>condition</strong>: often a comparison operation. The <code>while</code> loop continues as long as this condition evaluates to True, and stops once it turns False.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example-1">Example 1<a class="anchor-link" href="#Example-1">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Assign 1 to a variable named "count"</span></span>

<span><span class="n">count</span> <span class="o">=</span> <span class="mi">1</span></span>


<br /><span><span class="c1"># Use while loop to execute the code inside the loop a certain times until the count value becomes larger than 5</span></span>

<span><span class="k">while</span> <span class="n">count</span> <span class="o">&lt;=</span> <span class="mi">5</span><span class="p">:</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"This is iteration"</span><span class="p">,</span> <span class="n">count</span><span class="p">)</span></span>

<span>    <span class="c1"># add 1 to the count variable</span></span>

<span>    <span class="n">count</span> <span class="o">+=</span> <span class="mi">1</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>This is iteration 1
<br />This is iteration 2
<br />This is iteration 3
<br />This is iteration 4
<br />This is iteration 5
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong></p>
<ul>
<li><code>count = 1</code>: This line initializes the variable <code>count</code> with the value 1.</li>
</ul>
<ul>
<li><p><code>while count &lt;= 5</code>: This is the start of a <code>while</code> loop. It sets up a condition that says, "While the value of the variable count is less than or equal to 5, do the following."</p>
</li>
<li><p><code>print("This is iteration", count)</code>: Inside the <code>while</code> loop, this line prints the current value of count. It will print the value of count during each iteration of the loop.</p>
</li>
<li><p><code>count += 1</code>: After printing the value of count, this line increments the value of count by 1. This step is crucial because it ensures that the condition <code>count &lt;= 5</code> will eventually become False, enabling the loop to stop.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example-2">Example 2<a class="anchor-link" href="#Example-2">¶</a></h4><p>Example below uses <code>while</code> loop to take user input repeatedly until a specific condition is met.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Assign an empty string to a variable named user_input</span></span>

<span><span class="n">user_input</span> <span class="o">=</span> <span class="s2">""</span></span>


<br /><span><span class="k">while</span> <span class="n">user_input</span> <span class="o">!=</span> <span class="s1">'quit'</span><span class="p">:</span></span>

<span>    <span class="c1"># Use input() function to ask for user input </span></span>

<span>    <span class="n">user_input</span> <span class="o">=</span> <span class="nb">input</span><span class="p">(</span><span class="s2">"Enter a command (type 'quit' to exit): "</span><span class="p">)</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="sa">f</span><span class="s2">"You entered: </span><span class="si">&#123;</span><span class="n">user_input</span><span class="si">&#125;</span><span class="s2">"</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Enter a command (type 'quit' to exit): test1
<br />You entered: test1
<br />Enter a command (type 'quit' to exit): test2
<br />You entered: test2
<br />Enter a command (type 'quit' to exit): quit
<br />You entered: quit
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong></p>
<ul>
<li><p><code>user_input = ""</code>: This line initializes a variable called <code>user_input</code> and assigns it an empty string as its initial value. This variable will be used to store the user's input.</p>
</li>
<li><p><code>while user_input != 'quit'</code>: This is the start of a while loop. It sets up a condition that says, "While the value of user_input is not equal to "quit", do the following."</p>
</li>
<li><p><code>user_input = input("Enter a command (type 'quit' to exit): ")</code>: Inside the while loop, this line prompts the user to enter a command. It uses the <code>input()</code> function to read the user's input from the keyboard and assigns it to the <code>user_input</code> variable. The user's input will be stored as a string.</p>
</li>
<li><p><code>print(f"You entered: &#123;user_input&#125;")</code>: After getting the user's input, this line prints a message that includes what the user entered.</p>
</li>
</ul>
<p>The while loop keeps prompting for user input until "quit" is entered. As shown in the Output section, in the first two iterations, "test1" and "test2" were entered, and upon entering "quit" the third time, the loop ceased.</p>
</div>
</div>
</div>
</div>
</div>
)}