import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function AddColumnsToTibble(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Add Columns to a Tibble</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>add_column()</code></strong> adds one or more columns of data to an existing data frame.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># create a tibble</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>students <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"Charlie"</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>                   <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="dv">22</span>, <span className="dv">21</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>                   <span className="at">Enrolled =</span> <span className="fu">c</span>(<span className="cn">TRUE</span>, <span className="cn">FALSE</span>, <span className="cn">TRUE</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>students</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  Name      Age Enrolled
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice      20 TRUE    
<br/>2 Bob        22 FALSE   
<br/>3 Charlie    21 TRUE    </code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># add two more columns</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>students <span className="sc">%&gt;%</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">add_column</span>(</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>    <span className="at">grades =</span> <span className="fu">c</span>(<span className="dv">89</span>, <span className="dv">98</span>, <span className="dv">92</span>),</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="at">university =</span> <span className="fu">c</span>(<span className="st">"Harvard"</span>, <span className="st">"MIT"</span>, <span className="st">"NYU"</span>)</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  Name      Age Enrolled grades university
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;     &lt;dbl&gt; &lt;chr&gt;     
<br/>1 Alice      20 TRUE         89 Harvard   
<br/>2 Bob        22 FALSE        98 MIT       
<br/>3 Charlie    21 TRUE         92 NYU       </code></pre>
</div>
</div>
<p>Use <code>.before</code> (or <code>.after</code>) to specify the position of the new columns. (The same arguments are also used in <Link to="/R/data-wrangling/dplyr/4-mutate-columns">mutate()</Link> in the same way; and in <Link to="../add-rows-to-tibble"><code>add_row()</code></Link> to specify new rows’ position.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>students <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">add_column</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    <span className="at">grades =</span> <span className="fu">c</span>(<span className="dv">89</span>, <span className="dv">98</span>, <span className="dv">92</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="at">university =</span> <span className="fu">c</span>(<span className="st">"Harvard"</span>, <span className="st">"MIT"</span>, <span className="st">"NYU"</span>), </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    <span className="co"># add the two columns after the 'Name' column</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>    <span className="at">.after =</span> <span className="st">"Name"</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  Name    grades university   Age Enrolled
<br/>  &lt;chr&gt;    &lt;dbl&gt; &lt;chr&gt;      &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice       89 Harvard       20 TRUE    
<br/>2 Bob         98 MIT           22 FALSE   
<br/>3 Charlie     92 NYU           21 TRUE    </code></pre>
</div>
</div>
<p>You can use this function to combine two tibbles by columns. Note that you can’t overwrite existing columns, and the columns to be added together must have the same row number.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>more_features <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="at">grades =</span> <span className="fu">c</span>(<span className="dv">89</span>, <span className="dv">98</span>, <span className="dv">92</span>),</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="at">university =</span> <span className="fu">c</span>(<span className="st">"Harvard"</span>, <span className="st">"MIT"</span>, <span className="st">"NYU"</span>))</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a></span><br/>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a><span className="co"># insert the `more_features` tibble before the 'Name' column</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>students <span className="sc">%&gt;%</span> <span className="fu">add_column</span>(more_features, <span className="at">.after =</span> <span className="st">"Name"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  Name    grades university   Age Enrolled
<br/>  &lt;chr&gt;    &lt;dbl&gt; &lt;chr&gt;      &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice       89 Harvard       20 TRUE    
<br/>2 Bob         98 MIT           22 FALSE   
<br/>3 Charlie     92 NYU           21 TRUE    </code></pre>
</div>
</div>
</main>
</div>
</div>
)}