import React from 'react'; 
import {useState} from 'react'

export default function AddAnswers({answer}){
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
    if (!clicked) {
        setClicked(true);
    }
    };
    return (
        <div className={`clickable ${clicked ? "clicked" : ""}`} id='A' onClick={handleClick}>
            <p className={`clicktoshow ${clicked ? "disappear" : ""}`}>Click here to view the answer!</p>
            <p className={`${clicked ? "showup" : "disappear"}`}>The correct answer is {answer}!</p>
        </div>
    )
}