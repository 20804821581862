import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2MapAirline from '../graphics/map_airlines_completed.png'; 
import imgGgplot2MapAirlineWebp from '../graphics/map_airlines_completed.webp'; 
export default function Ggplot2MapAirline(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Global Airports and Flights with ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2MapAirlineWebp} />
    <img className="cover-img" src={imgGgplot2MapAirline} />
  </picture>

<p>In this article, we’ll visualize the global flights and airports in the world map. Visualization with ggplot2 is quite straightforward. More efforts are made in the data clean up.</p>
<p><strong>Key techniques covered in this article include:</strong></p>
<ul>
<li><a href="#tidy_data">Convert dataset into the “tidy” structure.</a></li>
<li><a href="#merge_dataset">Merge two datasets.</a></li>
<li><a href="#map">Create a world map with lines and points.</a></li>
<li><a href="#annotate">Create text annotation.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The data is sourced from <Link to="https://openflights.org/data">open flights</Link>, and can be directly imported via the URL. We’ll process 2 datasets in a total of 3 major steps.</p>
<p><strong>(1)</strong> Import <strong>the <em>airport</em> dataset</strong>, and update its column names based on the website’s description. To make it easier to navigate through the dataset, we’ll only select columns used in this visualization.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># read the dataset</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>url.airport <span className="ot">&lt;-</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/airports.dat"</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a></span><br/>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>airport <span className="ot">&lt;-</span> <span className="fu">read.table</span>(url.airport, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> <span className="cn">FALSE</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">as_tibble</span>()  </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a></span><br/>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a><span className="co"># Update column names based on website description; </span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a><span className="co"># use "lat" for latitude,  and "long" for longitude, </span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a><span className="co"># to be consistent with the 'map_data' which is built in R </span></span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="fu">colnames</span>(airport) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="st">"airport_ID"</span>, <span className="st">"name"</span>, <span className="st">"city"</span>, <span className="st">"country"</span>, </span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="st">"IATA"</span>, <span className="st">"ICAO"</span>, <span className="st">"lat"</span>, <span className="st">"long"</span>, <span className="st">"altitude"</span>, <span className="st">"timezone"</span>,</span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>  <span className="st">"DST"</span>, <span className="st">"Tz_database"</span>, <span className="st">"time_zone"</span>, <span className="st">"type"</span>)</span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a></span><br/>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a><span className="co"># select columns useful in this visualization</span></span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>airport.selected <span className="ot">&lt;-</span> airport <span className="sc">%&gt;%</span> </span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a>  <span className="fu">select</span>(name, airport_ID, lat, long, country) <span className="sc">%&gt;%</span> </span>
<span id="cb1-24"><a aria-hidden="true" href="#cb1-24" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">airport_ID =</span> <span className="fu">as.character</span>(airport_ID))</span>
<span id="cb1-25"><a aria-hidden="true" href="#cb1-25" tabindex="-1"></a></span><br/>
<span id="cb1-26"><a aria-hidden="true" href="#cb1-26" tabindex="-1"></a><span className="fu">head</span>(airport.selected, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  name                         airport_ID   lat  long country         
<br/>  &lt;chr&gt;                        &lt;chr&gt;      &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;           
<br/>1 Goroka Airport               1          -6.08  145. Papua New Guinea
<br/>2 Madang Airport               2          -5.21  146. Papua New Guinea
<br/>3 Mount Hagen Kagamuga Airport 3          -5.83  144. Papua New Guinea</code></pre>
</div>
</div>
<p>This <code>airport.selected</code> serves two purposes:</p>
<ul>
<li><p>It will be directly used to create an airport scatterplot: the <code>long</code> variable (longitude) mapped to the <code>x</code>, and <code>lat</code> (latitude) to <code>y</code>.</p></li>
<li><p>It will merge with the <em>airline</em> dataset via the column key <code>airport_ID</code>, with flight lines drawn by connecting the associated airports.</p></li>
</ul>
<p><strong>(2)</strong> Load and clean up <strong>the <em>airline</em> dataset</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>url.airline <span className="ot">&lt;-</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/routes.dat"</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>airline <span className="ot">&lt;-</span> <span className="fu">read.table</span>(url.airline, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> F) <span className="sc">%&gt;%</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a></span><br/>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a><span className="co"># Update column names based on website description</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a><span className="fu">colnames</span>(airline) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="st">"airline"</span>, <span className="st">"airline_ID"</span>, <span className="st">"source_airport"</span>, </span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>  <span className="st">"source_airport_ID"</span>, <span className="st">"destination_airport"</span>,</span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>  <span className="st">"destination_airport_ID"</span>, <span className="st">"Codeshare"</span>, <span className="st">"Stops"</span>, <span className="st">"Equipment"</span>)</span>
<span id="cb3-12"><a aria-hidden="true" href="#cb3-12" tabindex="-1"></a></span><br/>
<span id="cb3-13"><a aria-hidden="true" href="#cb3-13" tabindex="-1"></a><span className="co"># select useful columns</span></span>
<span id="cb3-14"><a aria-hidden="true" href="#cb3-14" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb3-15"><a aria-hidden="true" href="#cb3-15" tabindex="-1"></a>  <span className="fu">select</span>(source_airport_ID, destination_airport_ID)</span>
<span id="cb3-16"><a aria-hidden="true" href="#cb3-16" tabindex="-1"></a></span><br/>
<span id="cb3-17"><a aria-hidden="true" href="#cb3-17" tabindex="-1"></a><span className="fu">head</span>(airline, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>  source_airport_ID destination_airport_ID
<br/>  &lt;chr&gt;             &lt;chr&gt;                 
<br/>1 2965              2990                  
<br/>2 2966              2990                  
<br/>3 2966              2962                  </code></pre>
</div>
</div>
<p><strong>For this <em>airline</em> dataset, we need to do two more critical steps:</strong></p>
<ul>
<li><strong>Assign a unique ID to each flight</strong>. By this step, each row represents a unique flight line. This unique flight ID will be mapped to the <code>group</code> aesthetic in <code>geom_line()</code>, and specifies which two data points should be connected to make one flight line.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(airline)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">flight_ID =</span> <span className="dv">1</span><span className="sc">:</span>n)</span></code></pre></div>
</div>
<ul>
<li><span id="tidy_data"><strong>Pivot the dataset into a <Link to="https://cran.r-project.org/web/packages/tidyr/vignettes/tidy-data.html">tidy</Link> structure</strong>, such that each unique flight occupies two rows: the source airport in one row, and the destination airport in another row. The <code>airport_ID</code> variable connects the <em>airline</em> and <em>airport</em> datasets, and serves as the key (shared) column to guide the data merge in the next step.</span></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>airline.tidy <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>flight_ID, </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"direction"</span>, </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"airport_ID"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">direction =</span> <span className="fu">str_remove</span>(direction, <span className="st">"_airport_ID"</span>))</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a></span><br/>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a><span className="fu">head</span>(airline.tidy, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 3
<br/>  flight_ID direction   airport_ID
<br/>      &lt;int&gt; &lt;chr&gt;       &lt;chr&gt;     
<br/>1         1 source      2965      
<br/>2         1 destination 2990      
<br/>3         2 source      2966      
<br/>4         2 destination 2990      </code></pre>
</div>
</div>
<p><span id="merge_dataset"><strong>(3)</strong> <strong>Merge the <em>airport</em> and <em>airline</em> datasets</strong> using the shared column <code>airport_ID</code>. The merged dataset has information about each airline, including the flight ID, and associated departure (source) and destination airports noted with airports ID and latitude and longitude. It’s now ready for visualization!</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># flights </span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>air.all <span className="ot">&lt;-</span> airline.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">left_join</span>(airport.selected, <span className="at">by =</span> <span className="st">"airport_ID"</span>)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a></span><br/>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a><span className="fu">head</span>(air.all, <span className="at">n =</span> <span className="dv">4</span>) <span className="co"># now ready for visualization!</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 7
<br/>  flight_ID direction   airport_ID name                        lat  long country
<br/>      &lt;int&gt; &lt;chr&gt;       &lt;chr&gt;      &lt;chr&gt;                     &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;  
<br/>1         1 source      2965       Sochi International Airp…  43.4  40.0 Russia 
<br/>2         1 destination 2990       Kazan International Airp…  55.6  49.3 Russia 
<br/>3         2 source      2966       Astrakhan Airport          46.3  48.0 Russia 
<br/>4         2 destination 2990       Kazan International Airp…  55.6  49.3 Russia </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="map">We start by creating a world map using the dataset <code>map_data</code> built in ggplot2. Both datasets <code>map_data</code> and <code>air.all</code> have the <code>long</code> (longitude) and <code>lat</code> (latitude) variables.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>my.world <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a></span><br/>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> my.world <span className="sc">%&gt;%</span> </span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> long, <span className="at">y =</span> lat)) <span className="sc">+</span> </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="co"># create a world map background</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"tomato"</span>, <span className="at">linewidth =</span> .<span className="dv">2</span>, </span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>               <span className="at">show.legend =</span> F, <span className="at">fill =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>  </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="fl">1.2</span>, <span className="co"># adjust aspect ratio</span></span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>              <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">60</span>, <span className="dv">90</span>)) <span className="sc">+</span> <span className="co"># remove Antarctica </span></span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> </span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>)) </span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a></span><br/>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_world_map.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_airlines_world_map"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Visualize the airlines using the <code>air.all</code> dataset. Airlines are created by connecting the associated source and destination airports, which are specified by the <code>long</code> and <code>lat</code> variables. (Due to the large size of the dataset, it could take several seconds to render the plot)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">data =</span> air.all, </span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">group =</span> flight_ID),</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>            <span className="at">color =</span> <span className="st">"turquoise"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>, <span className="at">alpha =</span> .<span className="dv">1</span>) </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_airlines.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_airlines_airlines"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Visualize the airports using the <code>airport.selected</code> dataset.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">data =</span> airport.selected,</span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>             <span className="at">color =</span> <span className="st">"yellow"</span>, <span className="at">shape =</span> <span className="st">"."</span>,</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>             <span className="at">alpha =</span> .<span className="dv">6</span>)</span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_airports.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_airlines_airports"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="annotate">Add plot title and acknowledge the data source.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="co"># add title at bottom left corner</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="sc">-</span><span className="dv">180</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, <span className="at">hjust =</span> <span className="dv">0</span>,</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"Airports and Flights"</span>, </span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">size =</span> <span className="fl">4.5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span> ) <span className="sc">+</span></span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>  </span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>  <span className="co"># add caption at bottom left corner</span></span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">caption =</span> <span className="st">"data source: OpenFlights https://openflights.org/data "</span>) <span className="sc">+</span></span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">hjust =</span> .<span className="dv">05</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>)))</span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a></span><br/>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_airlines_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot in the PNG format. By default, the most recently displayed plot (<code>p4</code>) will be saved. Here we save the graphic in the folder of “graphics” (which is in the same folder of the source code).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"worldmap airports and airlines.png"</span>,</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="fl">4.5</span>, <span className="at">height =</span> <span className="dv">3</span>, <span className="at">dpi =</span> <span className="dv">400</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a></span><br/>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a><span className="co"># import the airport dataset</span></span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>url.airport <span className="ot">&lt;-</span> </span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/airports.dat"</span></span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a></span><br/>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>airport <span className="ot">&lt;-</span> <span className="fu">read.table</span>(</span>
<span id="cb15-11"><a aria-hidden="true" href="#cb15-11" tabindex="-1"></a>  url.airport, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> <span className="cn">FALSE</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb15-12"><a aria-hidden="true" href="#cb15-12" tabindex="-1"></a>  <span className="fu">as_tibble</span>()  </span>
<span id="cb15-13"><a aria-hidden="true" href="#cb15-13" tabindex="-1"></a></span><br/>
<span id="cb15-14"><a aria-hidden="true" href="#cb15-14" tabindex="-1"></a><span className="co"># Update column names based on website description </span></span>
<span id="cb15-15"><a aria-hidden="true" href="#cb15-15" tabindex="-1"></a><span className="fu">colnames</span>(airport) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb15-16"><a aria-hidden="true" href="#cb15-16" tabindex="-1"></a>  <span className="st">"airport_ID"</span>, <span className="st">"name"</span>, <span className="st">"city"</span>, <span className="st">"country"</span>, </span>
<span id="cb15-17"><a aria-hidden="true" href="#cb15-17" tabindex="-1"></a>  <span className="st">"IATA"</span>, <span className="st">"ICAO"</span>, <span className="st">"lat"</span>, <span className="st">"long"</span>, <span className="st">"altitude"</span>, <span className="st">"timezone"</span>,</span>
<span id="cb15-18"><a aria-hidden="true" href="#cb15-18" tabindex="-1"></a>  <span className="st">"DST"</span>, <span className="st">"Tz_database"</span>, <span className="st">"time_zone"</span>, <span className="st">"type"</span>)</span>
<span id="cb15-19"><a aria-hidden="true" href="#cb15-19" tabindex="-1"></a></span><br/>
<span id="cb15-20"><a aria-hidden="true" href="#cb15-20" tabindex="-1"></a><span className="co"># select columns useful in this visualization</span></span>
<span id="cb15-21"><a aria-hidden="true" href="#cb15-21" tabindex="-1"></a>airport.selected <span className="ot">&lt;-</span> airport <span className="sc">%&gt;%</span> </span>
<span id="cb15-22"><a aria-hidden="true" href="#cb15-22" tabindex="-1"></a>  <span className="fu">select</span>(name, airport_ID, lat, long, country) <span className="sc">%&gt;%</span> </span>
<span id="cb15-23"><a aria-hidden="true" href="#cb15-23" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">airport_ID =</span> <span className="fu">as.character</span>(airport_ID))</span>
<span id="cb15-24"><a aria-hidden="true" href="#cb15-24" tabindex="-1"></a></span><br/>
<span id="cb15-25"><a aria-hidden="true" href="#cb15-25" tabindex="-1"></a><span className="fu">head</span>(airport.selected, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb15-26"><a aria-hidden="true" href="#cb15-26" tabindex="-1"></a></span><br/>
<span id="cb15-27"><a aria-hidden="true" href="#cb15-27" tabindex="-1"></a></span><br/>
<span id="cb15-28"><a aria-hidden="true" href="#cb15-28" tabindex="-1"></a><span className="co"># Load and clean up the airline dataset. </span></span>
<span id="cb15-29"><a aria-hidden="true" href="#cb15-29" tabindex="-1"></a>url.airline <span className="ot">&lt;-</span> </span>
<span id="cb15-30"><a aria-hidden="true" href="#cb15-30" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/routes.dat"</span></span>
<span id="cb15-31"><a aria-hidden="true" href="#cb15-31" tabindex="-1"></a></span><br/>
<span id="cb15-32"><a aria-hidden="true" href="#cb15-32" tabindex="-1"></a>airline <span className="ot">&lt;-</span> <span className="fu">read.table</span>(</span>
<span id="cb15-33"><a aria-hidden="true" href="#cb15-33" tabindex="-1"></a>  url.airline, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> F) <span className="sc">%&gt;%</span> </span>
<span id="cb15-34"><a aria-hidden="true" href="#cb15-34" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb15-35"><a aria-hidden="true" href="#cb15-35" tabindex="-1"></a></span><br/>
<span id="cb15-36"><a aria-hidden="true" href="#cb15-36" tabindex="-1"></a><span className="co"># Update column names based on website description</span></span>
<span id="cb15-37"><a aria-hidden="true" href="#cb15-37" tabindex="-1"></a><span className="fu">colnames</span>(airline) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb15-38"><a aria-hidden="true" href="#cb15-38" tabindex="-1"></a>  <span className="st">"airline"</span>, <span className="st">"airline_ID"</span>, <span className="st">"source_airport"</span>, </span>
<span id="cb15-39"><a aria-hidden="true" href="#cb15-39" tabindex="-1"></a>  <span className="st">"source_airport_ID"</span>, <span className="st">"destination_airport"</span>,</span>
<span id="cb15-40"><a aria-hidden="true" href="#cb15-40" tabindex="-1"></a>  <span className="st">"destination_airport_ID"</span>, <span className="st">"Codeshare"</span>, <span className="st">"Stops"</span>, <span className="st">"Equipment"</span>)</span>
<span id="cb15-41"><a aria-hidden="true" href="#cb15-41" tabindex="-1"></a></span><br/>
<span id="cb15-42"><a aria-hidden="true" href="#cb15-42" tabindex="-1"></a><span className="co"># select useful columns</span></span>
<span id="cb15-43"><a aria-hidden="true" href="#cb15-43" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb15-44"><a aria-hidden="true" href="#cb15-44" tabindex="-1"></a>  <span className="fu">select</span>(source_airport_ID, destination_airport_ID)</span>
<span id="cb15-45"><a aria-hidden="true" href="#cb15-45" tabindex="-1"></a></span><br/>
<span id="cb15-46"><a aria-hidden="true" href="#cb15-46" tabindex="-1"></a><span className="co"># For the airline dataset, we need to do two more critical steps:</span></span>
<span id="cb15-47"><a aria-hidden="true" href="#cb15-47" tabindex="-1"></a><span className="do">## i) Assign a unique ID to each flight to be mapped to 'group' aesthetic in 'geom_line'.</span></span>
<span id="cb15-48"><a aria-hidden="true" href="#cb15-48" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(airline)</span>
<span id="cb15-49"><a aria-hidden="true" href="#cb15-49" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">flight_ID =</span> <span className="dv">1</span><span className="sc">:</span>n)</span>
<span id="cb15-50"><a aria-hidden="true" href="#cb15-50" tabindex="-1"></a></span><br/>
<span id="cb15-51"><a aria-hidden="true" href="#cb15-51" tabindex="-1"></a><span className="do">## ii) Pivot the dataset into a tidy structure. </span></span>
<span id="cb15-52"><a aria-hidden="true" href="#cb15-52" tabindex="-1"></a>airline.tidy <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb15-53"><a aria-hidden="true" href="#cb15-53" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>flight_ID, </span>
<span id="cb15-54"><a aria-hidden="true" href="#cb15-54" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"direction"</span>, </span>
<span id="cb15-55"><a aria-hidden="true" href="#cb15-55" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"airport_ID"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb15-56"><a aria-hidden="true" href="#cb15-56" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">direction =</span> <span className="fu">str_remove</span>(direction, <span className="st">"_airport_ID"</span>))</span>
<span id="cb15-57"><a aria-hidden="true" href="#cb15-57" tabindex="-1"></a></span><br/>
<span id="cb15-58"><a aria-hidden="true" href="#cb15-58" tabindex="-1"></a><span className="fu">head</span>(airline.tidy, <span className="at">n =</span> <span className="dv">4</span>)</span>
<span id="cb15-59"><a aria-hidden="true" href="#cb15-59" tabindex="-1"></a></span><br/>
<span id="cb15-60"><a aria-hidden="true" href="#cb15-60" tabindex="-1"></a></span><br/>
<span id="cb15-61"><a aria-hidden="true" href="#cb15-61" tabindex="-1"></a><span className="co"># Merge the airport and airline datasets.</span></span>
<span id="cb15-62"><a aria-hidden="true" href="#cb15-62" tabindex="-1"></a>air.all <span className="ot">&lt;-</span> airline.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb15-63"><a aria-hidden="true" href="#cb15-63" tabindex="-1"></a>  <span className="fu">left_join</span>(airport.selected, <span className="at">by =</span> <span className="st">"airport_ID"</span>)</span>
<span id="cb15-64"><a aria-hidden="true" href="#cb15-64" tabindex="-1"></a></span><br/>
<span id="cb15-65"><a aria-hidden="true" href="#cb15-65" tabindex="-1"></a><span className="fu">head</span>(air.all, <span className="at">n =</span> <span className="dv">4</span>) <span className="co"># now ready for visualization!</span></span>
<span id="cb15-66"><a aria-hidden="true" href="#cb15-66" tabindex="-1"></a></span><br/>
<span id="cb15-67"><a aria-hidden="true" href="#cb15-67" tabindex="-1"></a></span><br/>
<span id="cb15-68"><a aria-hidden="true" href="#cb15-68" tabindex="-1"></a><span className="co"># Create a world map.</span></span>
<span id="cb15-69"><a aria-hidden="true" href="#cb15-69" tabindex="-1"></a>my.world <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>)</span>
<span id="cb15-70"><a aria-hidden="true" href="#cb15-70" tabindex="-1"></a></span><br/>
<span id="cb15-71"><a aria-hidden="true" href="#cb15-71" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> my.world <span className="sc">%&gt;%</span> </span>
<span id="cb15-72"><a aria-hidden="true" href="#cb15-72" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> long, <span className="at">y =</span> lat)) <span className="sc">+</span> </span>
<span id="cb15-73"><a aria-hidden="true" href="#cb15-73" tabindex="-1"></a>  <span className="co"># create a world map background</span></span>
<span id="cb15-74"><a aria-hidden="true" href="#cb15-74" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb15-75"><a aria-hidden="true" href="#cb15-75" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"tomato"</span>, <span className="at">linewidth =</span> .<span className="dv">2</span>, </span>
<span id="cb15-76"><a aria-hidden="true" href="#cb15-76" tabindex="-1"></a>               <span className="at">show.legend =</span> F, <span className="at">fill =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb15-77"><a aria-hidden="true" href="#cb15-77" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="fl">1.2</span>, <span className="co"># adjust aspect ratio</span></span>
<span id="cb15-78"><a aria-hidden="true" href="#cb15-78" tabindex="-1"></a>              <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">60</span>, <span className="dv">90</span>)) <span className="sc">+</span> <span className="co"># remove Antarctica </span></span>
<span id="cb15-79"><a aria-hidden="true" href="#cb15-79" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> </span>
<span id="cb15-80"><a aria-hidden="true" href="#cb15-80" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>)) </span>
<span id="cb15-81"><a aria-hidden="true" href="#cb15-81" tabindex="-1"></a>p1</span>
<span id="cb15-82"><a aria-hidden="true" href="#cb15-82" tabindex="-1"></a></span><br/>
<span id="cb15-83"><a aria-hidden="true" href="#cb15-83" tabindex="-1"></a></span><br/>
<span id="cb15-84"><a aria-hidden="true" href="#cb15-84" tabindex="-1"></a><span className="co"># Visualize the airlines.</span></span>
<span id="cb15-85"><a aria-hidden="true" href="#cb15-85" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb15-86"><a aria-hidden="true" href="#cb15-86" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">data =</span> air.all, </span>
<span id="cb15-87"><a aria-hidden="true" href="#cb15-87" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">group =</span> flight_ID),</span>
<span id="cb15-88"><a aria-hidden="true" href="#cb15-88" tabindex="-1"></a>            <span className="at">color =</span> <span className="st">"turquoise"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>, <span className="at">alpha =</span> .<span className="dv">1</span>) </span>
<span id="cb15-89"><a aria-hidden="true" href="#cb15-89" tabindex="-1"></a>p2</span>
<span id="cb15-90"><a aria-hidden="true" href="#cb15-90" tabindex="-1"></a></span><br/>
<span id="cb15-91"><a aria-hidden="true" href="#cb15-91" tabindex="-1"></a></span><br/>
<span id="cb15-92"><a aria-hidden="true" href="#cb15-92" tabindex="-1"></a><span className="co"># Visualize the airports.</span></span>
<span id="cb15-93"><a aria-hidden="true" href="#cb15-93" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb15-94"><a aria-hidden="true" href="#cb15-94" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">data =</span> airport.selected,</span>
<span id="cb15-95"><a aria-hidden="true" href="#cb15-95" tabindex="-1"></a>             <span className="at">color =</span> <span className="st">"yellow"</span>, <span className="at">shape =</span> <span className="st">"."</span>,</span>
<span id="cb15-96"><a aria-hidden="true" href="#cb15-96" tabindex="-1"></a>             <span className="at">alpha =</span> .<span className="dv">6</span>)</span>
<span id="cb15-97"><a aria-hidden="true" href="#cb15-97" tabindex="-1"></a>p3</span>
<span id="cb15-98"><a aria-hidden="true" href="#cb15-98" tabindex="-1"></a></span><br/>
<span id="cb15-99"><a aria-hidden="true" href="#cb15-99" tabindex="-1"></a></span><br/>
<span id="cb15-100"><a aria-hidden="true" href="#cb15-100" tabindex="-1"></a><span className="co"># Add plot title and the data source.</span></span>
<span id="cb15-101"><a aria-hidden="true" href="#cb15-101" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb15-102"><a aria-hidden="true" href="#cb15-102" tabindex="-1"></a>  <span className="co"># add title at bottom left corner</span></span>
<span id="cb15-103"><a aria-hidden="true" href="#cb15-103" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb15-104"><a aria-hidden="true" href="#cb15-104" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="sc">-</span><span className="dv">180</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, <span className="at">hjust =</span> <span className="dv">0</span>,</span>
<span id="cb15-105"><a aria-hidden="true" href="#cb15-105" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"Airports and Flights"</span>, </span>
<span id="cb15-106"><a aria-hidden="true" href="#cb15-106" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">size =</span> <span className="fl">4.5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span> ) <span className="sc">+</span></span>
<span id="cb15-107"><a aria-hidden="true" href="#cb15-107" tabindex="-1"></a>  <span className="co"># add caption at bottom left corner</span></span>
<span id="cb15-108"><a aria-hidden="true" href="#cb15-108" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">caption =</span> <span className="st">"data source: OpenFlights https://openflights.org/data "</span>) <span className="sc">+</span></span>
<span id="cb15-109"><a aria-hidden="true" href="#cb15-109" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">hjust =</span> .<span className="dv">05</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>)))</span>
<span id="cb15-110"><a aria-hidden="true" href="#cb15-110" tabindex="-1"></a>p4</span>
<span id="cb15-111"><a aria-hidden="true" href="#cb15-111" tabindex="-1"></a></span><br/>
<span id="cb15-112"><a aria-hidden="true" href="#cb15-112" tabindex="-1"></a></span><br/>
<span id="cb15-113"><a aria-hidden="true" href="#cb15-113" tabindex="-1"></a><span className="co"># save the plot</span></span>
<span id="cb15-114"><a aria-hidden="true" href="#cb15-114" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"worldmap airports and airlines.png"</span>,</span>
<span id="cb15-115"><a aria-hidden="true" href="#cb15-115" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,</span>
<span id="cb15-116"><a aria-hidden="true" href="#cb15-116" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">4</span>, <span className="at">dpi =</span> <span className="dv">400</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In this follow-up article, we’ll <Link to="../ggplot2-map-airline-animation"><strong>update the script and turn static plot into animation</strong></Link> using the <code>gganimate</code> package. Check it out!</p>
<p><Link to="../ggplot2-map-airline-animation"><img className="img-fluid" src="graphics/map_airlines_animation_gganimate_completed-1.gif"/></Link></p>
<p><br/><br/></p>
<p>In this article, we’ll create a <Link to="../ggplot2-world-map-cities-populations"><strong>world map combined with scatterplot</strong></Link> to visualize the distribution of cities of different population sizes.</p>
<p><Link to="../ggplot2-world-map-cities-populations">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}