import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgPublishPapersGraduateSchool from '../graphics_blog/cactus.png';  
import imgPublishPapersGraduateSchoolWebp from '../graphics_blog/cactus.webp'; 
// import GoogleAdComponent from '../../js/googleAdsense';
export default function PublishPapersGraduateSchool(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title"><strong>What Publishing 27 Papers in Graduate School Has Taught Me</strong></h1>
<p className="subtitle lead">Two Simple Insights with Broad Implications for Career Development</p>
</div>
<div className="quarto-title-meta">
</div>
</header>
<div className="quarto-figure quarto-figure-center">
  <picture>
    <source type="image/webp" srcset={imgPublishPapersGraduateSchoolWebp} />
    <img className="cover-img" src={imgPublishPapersGraduateSchool} />
  </picture>
<figcaption>Photo by the author from a solitary journey. Sunset, Texas, 2016. Standing at the edge of a cliff amidst the rugged beauty of the wilderness, a lone cactus symbolizes the resilience of life.</figcaption>

</div>
<p><br/></p>
<p>I spent six years (2014 to 2020) to get my PhD with a dissertation in analytical chemistry. From my doctoral work, I published 10 papers as the first author, including an invited book chapter. Additionally, I contributed as the second author on 8 papers, culminating in a total of 27 (co-)authored publications. Many of them appeared in renowned journals in the field.</p>
<p>Looking back, I am amazed by such productivity and creativity. In this short essay, I want to share <strong>two strategic fundamentals</strong> behind my success, which I believe have <strong>universal implications for personal growth and career development</strong>.</p>
<div className='google-ads-in-article'>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9820146172935665"
     crossorigin="anonymous"></script>
<ins className="adsbygoogle"
     style={{"display": 'block', "textAlign":"center"}}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-9820146172935665"
     data-ad-slot="6731586694"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
</div>
<section className="level3" id="find-passion-in-your-work">
<h3 className="anchored" data-anchor-id="find-passion-in-your-work">Find Passion in Your Work</h3>
<p><strong>You gotta love what you do</strong>, either turning your passion into a profitable job, or turning your job into a passion. It is a difficult but the most important aspect to generate a successful and happy career and life.</p>
<p>Most people work in jobs they don’t particularly enjoy. Even those in their favorite jobs encounter routines and moments that are less exciting. However, <strong>a deep think of our current work always lets us find elements that serve a higher purpose and ignite our passion</strong>. For instance, we can view our work as a step toward greater achievements, a game of self-challenge, an exercise in mental strength, a workshop to hone skills; or a component of a grander project to make this world a better place. </p>
<p>My passion for my doctoral work stems from many sources. First and foremost, I was driven by a strong desire for self-fulfillment. I considered each of my research papers a piece of art and science, a certificate of my self-transcendence, and a small dent I put in the universe. I enjoyed the process of transforming hard-earned data into beautiful images, which later became a foundation of my creating <Link to="https://www.databrewer.co/R/gallery">Data Viz. Gallery</Link> where I share <Link to="https://www.databrewer.co/R">R</Link> code for creating stunning data visuals. Additionally, my idealism for perfection and continuous refinement, my love of cool science, and a sense of crisis of competing for financial support, all fueled my obsession with my work. I thought about my research every day — while eating, bathing, driving, sleeping, or sitting on the toilet. I stayed late in the lab and continued working during weekends and holidays. (Thankfully, my girlfriend - later my wife - was very supportive most of the time!)</p>
<p>Being passionate didn’t mean I was mindlessly happy every day. In fact, most of the time, I felt frustrated due to experimental setbacks. I remember dragging my feet on my way home, feeling hopeless about why things didn’t work. I recall throwing myself onto the bed, desperate at the thought of repeating labor-intensive experiments yet again. But driven by my passion, I kept pushing forward. And I made it, producing one paper after another, nice and shiny, as if everything came out easily; but they weren’t easy, until you make it.</p>
<p>I’d like to conclude this section with a quote from Steve Jobs:</p>
<blockquote className="blockquote">
<p>Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven’t found it yet, keep looking. Don’t settle.</p>
</blockquote>
</section>
<section className="level3" id="just-do-one-thing-exceptionally-well">
<h3 className="anchored" data-anchor-id="just-do-one-thing-exceptionally-well">Just Do One Thing Exceptionally Well</h3>
<p>There is always so much to learn and so much to do in a career. But it’s never possible to learn and to master everything. Instead, you should focus on just one thing (especially at the beginning), the niche you are passionate about. Concentrate like a laser beam, and train yourself to do it exceptionally well. It’s not enough to be merely good; you must go through a “phase transition”: <strong>breakthrough the threshold to be excellent and extraordinary</strong>. Returns and new opportunities will become increasingly visible as you improve, and they will be exponential when you reach the top of the pyramid.</p>
<p>Along the journey, you’ll develop multiple skillsets, but focus on one thing that will become your brand. Let other skills enhance and support your primary focus. Don’t fall into the trap of chasing multiple rabbits.</p>
<p>For most of my PhD work, I focused on the use of mass spectrometry (MS), one of the most important technologies to analyze compounds. I found it incredibly fascinating. I didn’t learn much about other popular topics at the time, nor did I develop much interest. While such “narrow mindedness” came with a cost, it allowed me to concentrate all my energy on becoming an expert in my interested field. I learned instrumental optimization techniques that even manufacturer service engineers didn’t know, and developed methods to analyze compounds that other MS researchers couldn’t detect. </p>
<p>Not only did I complete my own dissertation beyond my mentor’s expectation, but also became the lead analyst of three other important projects that gave me more opportunities to showcase my expertise in MS and publish more papers. Further I became known as the MS guy among several departments. I helped many researchers analyze their interested compounds, and became a senior coauthor in their publications. </p>
<p>After graduation, I extended my expertise during postdoc at Harvard to another related field: MS-based isotope-tracing and modeling of biochemical fluxes. Amidst the vast array of emerging biotechnologies, this became my sole focus. After two to three years of dedicated work, I distinguished myself in the Harvard medical community as a highly sought-after flux expert, even though I did not originally come from a medical background. That’s the power of focusing on a niche and digging deep. </p>
<hr/>
<p><strong>Find you passion in your work. Stay focused to turn your passion into an excellence.</strong> That is strategically a most powerful way to start transforming an otherwise ordinary life into something extraordinary  —  well, each life deserves celebration, but we can always strive to soar higher. May we all tap into the fullest potential of our wonderful lives.</p>
</section>
</main>
</div>
</div>
)}