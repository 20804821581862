import React from 'react';
import { v4 as uuidv4 } from 'uuid';


import ExcelOpenpyxl from '../Python/FAQ/ExcelOpenpyxl';
import ExcelOpenpyxlStyle from '../Python/FAQ/ExcelOpenpyxlStyle';

// const ExcelOpenpyxlLazy = React.lazy(() => import('../Python/FAQ/ExcelOpenpyxl'));
// const ExcelOpenpyxlStyleLazy = React.lazy(() => import('../Python/FAQ/ExcelOpenpyxlStyle'));


// if title or static_website_url is changed here, go to App.js to change Route paths.
const PythonPostsData = [
    {id:uuidv4(), component: <ExcelOpenpyxl />, path:'use-openpyxl-to-update-excel', 'title': 'Use Python Openpyxl to Update Excel Files', 'cover': 'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    {id:uuidv4(), component: <ExcelOpenpyxlStyle />, path:'use-openpyxl-to-change-excell-style', 'title': 'Use Python Openpyxl to Change Excel Cell Style', 'cover':'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    {id:uuidv4(), component: <ExcelOpenpyxl />, path:'use-openpyxl-to-update-excel', 'title': 'Use Python Openpyxl to Update Excel Files', 'cover': 'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    {id:uuidv4(), component: <ExcelOpenpyxlStyle />, path:'use-openpyxl-to-change-excell-style', 'title': 'Use Python Openpyxl to Change Excel Cell Style', 'cover':'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    
]

export default PythonPostsData