import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2OvercrowdedDataPoints from '../tutorial/crowded_scatterplot_transparency.png';

export default function Ggplot2OvercrowdedDataPoints(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Twelve Awesome Methods to Deal with Overcrowded Scatterplot</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In this article, we’ll discuss 12 powerful methods to address overcrowded scatterplot.</p>
<ul>
<li><p><a href="#a">Case <strong>1</strong>: There are identical and completely overlapped data points.</a> The number of data points though is not high. We’ll work on the <code>iris</code> dataset to illustrate <strong>methods 1 ~ 5</strong>.</p></li>
<li><p><a href="#b">Case <strong>2</strong>: There are too many data points to be displayed individually.</a> We’ll work on the <code>txhousing</code> dataset (with 8600 data points) to illustrate <strong>methods 6 ~ 12</strong>.</p></li>
</ul>
<hr/>
<section className="level3" id="a">
<h3 className="anchored" data-anchor-id="a">Case 1: Identical and overlapped data points with the <code>iris</code> dataset.</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>())</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># create the plot base</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>i <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Sepal.Length, <span className="at">y =</span> Sepal.Width, <span className="at">color =</span> Species))</span></code></pre></div>
</div>
<section className="level4" id="method-1-apply-transparency-to-unveil-overlap.">
<h4 className="anchored" data-anchor-id="method-1-apply-transparency-to-unveil-overlap.">Method 1: Apply transparency to unveil overlap.</h4>
<p>Here we apply 50% transparency to the points with <code>alpha = .5</code>. Higher color saturation indicates the existence of data point overlap.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>i <span className="sc">+</span> <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">4</span>, <span className="at">alpha =</span> .<span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2OvercrowdedDataPoints} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-2-use-jittered-position">
<h4 className="anchored" data-anchor-id="method-2-use-jittered-position">Method 2: Use jittered position</h4>
<p>A frequently used method to unveil superimposed data points is to add a small amount of random noise to the horizontal (<code>width</code>) and vertical (<code>height</code>) position. The <code>seed</code> argument ensures reproducing the same pattern of randomization each time the script is run.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>i <span className="sc">+</span> <span className="fu">geom_point</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="at">size =</span> <span className="dv">4</span>, <span className="at">alpha =</span> .<span className="dv">5</span>,</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="at">position =</span> <span className="fu">position_jitter</span>(<span className="at">width =</span> .<span className="dv">2</span>, <span className="at">height =</span> .<span className="dv">1</span>, <span className="at">seed =</span> <span className="dv">123</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_jitter.png"/></p>
</figure>
</div>
</div>
</div>
<p><code>geom_jitter()</code> is a shorthand to <code>geom_point()</code> with jittered position (but it does <em>not</em> has the <code>seed</code> argument). In addition, the <Link to="https://github.com/eclarke/ggbeeswarm">ggbeeswarm</Link> package generates randomization in a more organized and symmetric manner – check out <strong><Link to="../18-ggplot2-create-points">here</Link></strong> to learn more.</p>
</section>
<section className="level4" id="method-3-make-shape-in-outlines">
<h4 className="anchored" data-anchor-id="method-3-make-shape-in-outlines">Method 3: Make shape in outlines</h4>
<p>Combined with “jittered” position, shape in outlines further help to visualize the data overlap. Shape of <code>21</code> is a popular option, and contains an outline and a solid interior (which remains empty by default). Check the <Link to="../18-ggplot2-create-points"><strong>basics of points</strong></Link> to learn more about shape customization.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>i <span className="sc">+</span> <span className="fu">geom_point</span>(</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="at">size =</span> <span className="dv">4</span>, <span className="at">shape =</span> <span className="dv">21</span>,</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="at">position =</span> <span className="fu">position_jitter</span>(<span className="at">width =</span> .<span className="dv">2</span>, <span className="at">height =</span> .<span className="dv">1</span>, <span className="at">seed =</span> <span className="dv">123</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_outline.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-4-use-larger-points-for-clustered-data-points">
<h4 className="anchored" data-anchor-id="method-4-use-larger-points-for-clustered-data-points">Method 4: Use larger points for clustered data points</h4>
<p><code>geom_count()</code> maps the extent of overlap to the aesthetic <code>size</code> of points.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>i <span className="sc">+</span> <span className="fu">geom_count</span>(<span className="at">alpha =</span> .<span className="dv">6</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_geom_count.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-5-enhance-with-marginal-distribution">
<h4 className="anchored" data-anchor-id="method-5-enhance-with-marginal-distribution">Method 5: Enhance with marginal distribution</h4>
<p><code>geom_rug()</code> projects each data point onto the x and y axes as short tick marks, and re-image the scatterplot as bar codes on the two sides of the plot. The density of the bar codes visualizes the distribution of each x and y variable. When the scatterplot is jittered, the rug needs to be jittered in like manner to be synchronized in position with the scatterplot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>i <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">1</span>, <span className="dv">123</span>)) <span className="sc">+</span> </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">geom_rug</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">1</span>, <span className="dv">123</span>),</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>           <span className="at">linewidth =</span> .<span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_marginal_distribution_rugs.png"/></p>
</figure>
</div>
</div>
</div>
<p>In addition, <Link to="https://cran.r-project.org/web/packages/ggExtra/vignettes/ggExtra.html"><code>ggExtra</code></Link> provides an elegant solution to visualize the marginal distribution using different types of plots, including density plot, histogram, box plot, and violin plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># install.packages("ggExtra")</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">library</span>(ggExtra)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a></span><br/>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>i2 <span className="ot">&lt;-</span> i <span className="sc">+</span> <span className="fu">geom_jitter</span>() <span className="sc">+</span> </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"bottom"</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a></span><br/>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a><span className="fu">ggMarginal</span>(i2, <span className="at">groupFill =</span> T)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_marginal_density_plots.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</section>
<section className="level3" id="b">
<h3 className="anchored" data-anchor-id="b">Case 2: High number of data points with the <code>txhousing</code> dataset</h3>
<p>The following scatterplot derived from the dataset <code>txhousing</code> contains 8600 data points. The significant overlap of the data points makes it difficult to unveil the underlying data pattern. In addition to the techniques discussed above, here we’ll introduce 6 more methods to improve the visualization.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># too many points</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>p <span className="ot">&lt;-</span> txhousing <span className="sc">%&gt;%</span> </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> sales, <span className="at">y =</span> median)) </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a></span><br/>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">geom_point</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_housing_price.png"/></p>
</figure>
</div>
</div>
</div>
<section className="level4" id="method-6-use-the-dot-shape">
<h4 className="anchored" data-anchor-id="method-6-use-the-dot-shape">Method 6: Use the dot shape</h4>
<p>A point shape in dot <code>.</code> in semi-transparency can be a quick way to reveal the pattern of a large dataset. For highly skewed data like this dataset, however, the efficiency of this method may be still limited.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">geom_point</span>(<span className="at">shape =</span> <span className="st">"."</span>, <span className="at">alpha =</span> .<span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_dustplot.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-7-map-point-colors-to-neighbor-counts">
<h4 className="anchored" data-anchor-id="method-7-map-point-colors-to-neighbor-counts">Method 7: Map point colors to neighbor counts</h4>
<ul>
<li><p>The <Link to="https://github.com/LKremer/ggpointdensity"><code>ggpointdensity</code></Link> package provides an awesome solution to visualize large dataset with significant overlap. The <code>geom_pointdensity()</code> function works very similarly to <code>geom_point()</code>, but additionally coats the points in colors based on the number of neighboring points. This way, both the overall distribution and individual points can be visualized.</p></li>
<li><p>Given the extreme skewness of the data distribution, we use <code>trans = "pseudo_log"</code> in the <Link to="../16-ggplot2-color-viridis-palette"><em>viridis</em> color scale</Link> to preferentially highlight the most clustered data points. This color scale transformation is also a silver bullet in the creation of this <Link to="../../R/gallery/ggplot2-heatmap-African-population">stunning heatmap</Link>.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># install.packages("ggpointdensity")</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(ggpointdensity)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="fu">geom_pointdensity</span>() <span className="sc">+</span> </span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">scale_color_viridis_c</span>(<span className="at">trans =</span> <span className="st">"pseudo_log"</span>) <span className="sc">+</span></span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>  <span className="co"># elongate the color bar</span></span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">color =</span> <span className="fu">guide_colorbar</span>(<span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>)))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_ggpointdensity.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-8-count-data-points-in-2d-histogram">
<h4 className="anchored" data-anchor-id="method-8-count-data-points-in-2d-histogram">Method 8: Count data points in 2D histogram</h4>
<p>The scatterplot can be summarized as a 2D histogram. The <code>bins</code> argument specifies the number of intervals / bins created along the x and y axes, and determines the number of cells the plot is divided into. The number of data points falling into each cell is counted, and mapped to a color scale. Higher bin number creates a higher resolution.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># Update the base plot with viridis color scale. </span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p <span className="sc">+</span> <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a></span><br/>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_bin_2d</span>(<span className="at">bins =</span> <span className="dv">30</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_2D_histogram.png"/></p>
</figure>
</div>
</div>
</div>
<p>Draw cells in hexagons.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_hex</span>(<span className="at">bins =</span> <span className="dv">30</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_2D_histogram_hexagon.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-9-visualize-data-distribution-with-2d-density-plot">
<h4 className="anchored" data-anchor-id="method-9-visualize-data-distribution-with-2d-density-plot">Method 9: Visualize data distribution with 2D density plot</h4>
<p>Similar to 2D histogram, 2D density plot is another way to unveil the underlying data distribution.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="co"># density in binned format</span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">stat_density_2d</span>(<span className="fu">aes</span>(<span className="at">fill =</span> <span className="fu">after_stat</span>(density)),</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>                     <span className="at">geom =</span> <span className="st">"tile"</span>, <span className="at">contour =</span> F, </span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>                     <span className="at">n =</span> <span className="dv">30</span>, <span className="at">color =</span> <span className="st">"snow3"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_2D_density.png"/></p>
</figure>
</div>
</div>
</div>
<p>Alternative to <code>geom = "tile"</code>, we can use <code>geom = "raster"</code>. The “raster” is a high-performance alternative to “tile” when all cells are of the same size, but does <em>not</em> have the <code>color</code> argument, and thus does <em>not</em> draw cell outlines.</p>
</section>
<section className="level4" id="method-10-facet-the-plot-into-subplots">
<h4 className="anchored" data-anchor-id="method-10-facet-the-plot-into-subplots">Method 10: Facet the plot into subplots</h4>
<ul>
<li><p>Faceting unveils positive correlation in x and y variables in many cities, which is otherwise much less perceivable when all data points are merged into a single plot.</p></li>
<li><p>In the <code>after_stat()</code> function: <code>density</code> presents the density in each subplot relative to the data range of the whole dataset, while <code>ndensity</code> (as demonstrated) <em>normalizes</em> the density separately in each subplot. Check out our <Link to="/R/ebook/flash-to-r-ggplot2-mastery"><strong>unique ggplot2 ebook</strong></Link> to learn more!</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>p2 <span className="sc">+</span> </span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="fu">stat_density_2d</span>(<span className="fu">aes</span>(<span className="at">fill =</span> <span className="fu">after_stat</span>(ndensity)),</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>                  <span className="at">geom =</span> <span className="st">"raster"</span>, <span className="at">contour =</span> F, <span className="at">n =</span> <span className="dv">30</span>) <span className="sc">+</span></span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>city, <span className="at">scales =</span> <span className="st">"free"</span>) <span className="sc">+</span> </span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>  <span className="co"># display complete subplot titles </span></span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">strip.clip =</span> <span className="st">"off"</span>) <span className="sc">+</span></span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>  <span className="co"># customize legend colorbar</span></span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">150</span>, <span className="st">"pt"</span>),</span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>)))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_faceted_subplot.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-11-interactive-plot">
<h4 className="anchored" data-anchor-id="method-11-interactive-plot">Method 11: Interactive plot</h4>
<p><Link to="https://davidgohel.github.io/ggiraph/"><code>ggiraph</code></Link> is a versatile ggplot2 extension package that helps generate interactive plots with a few tweaks of the script.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">library</span>(ggiraph)</span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a></span><br/>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>p.static <span className="ot">&lt;-</span> txhousing <span className="sc">%&gt;%</span> </span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> sales, <span className="at">y =</span> median, </span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>             <span className="co"># display all points associated with the same city</span></span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>             <span className="at">data_id =</span> city, </span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>             <span className="co"># interactively display the city name</span></span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>             <span className="at">tooltip =</span> city)) <span className="sc">+</span> </span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a>  <span className="fu">geom_point_interactive</span>(<span className="at">show.legend =</span> F) <span className="sc">+</span></span>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>  </span>
<span id="cb15-11"><a aria-hidden="true" href="#cb15-11" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"This Is An Interactive Plot"</span>) <span className="sc">+</span></span>
<span id="cb15-12"><a aria-hidden="true" href="#cb15-12" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> .<span className="dv">5</span>))</span>
<span id="cb15-13"><a aria-hidden="true" href="#cb15-13" tabindex="-1"></a></span><br/>
<span id="cb15-14"><a aria-hidden="true" href="#cb15-14" tabindex="-1"></a><span className="fu">ggiraph</span>(<span className="at">ggobj =</span> p.static)</span></code></pre></div>
<div className="cell-output-display">
<div className="girafe html-widget" id="htmlwidget-34ffd567c12e6f86bb1b"></div>
</div>
</div>
</section>
<section className="level4" id="method-12-animation">
<h4 className="anchored" data-anchor-id="method-12-animation">Method 12: Animation</h4>
<p>Animation with the popular package <Link to="https://gganimate.com/"><code>gganimate</code></Link> is another powerful tool to display complicated data. Animations are simply faceted plots but on a time scale. The following exploratory animation loops through 46 different cities and quickly displays the sales condition for each of them.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="co"># The animation goes through 46 different cities </span></span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>txhousing<span className="sc">$</span>city <span className="sc">%&gt;%</span> <span className="fu">n_distinct</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 46</code></pre>
</div>
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="fu">library</span>(gganimate)</span>
<span id="cb18-2"><a aria-hidden="true" href="#cb18-2" tabindex="-1"></a></span><br/>
<span id="cb18-3"><a aria-hidden="true" href="#cb18-3" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">geom_point</span>() <span className="sc">+</span></span>
<span id="cb18-4"><a aria-hidden="true" href="#cb18-4" tabindex="-1"></a>  <span className="co"># label each frame with the associated city name</span></span>
<span id="cb18-5"><a aria-hidden="true" href="#cb18-5" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"&#123;closest_state&#125;"</span>) <span className="sc">+</span></span>
<span id="cb18-6"><a aria-hidden="true" href="#cb18-6" tabindex="-1"></a>  <span className="co"># facet the plot into frames of different cities</span></span>
<span id="cb18-7"><a aria-hidden="true" href="#cb18-7" tabindex="-1"></a>  <span className="fu">transition_states</span>(<span className="at">states =</span> city, <span className="at">transition_length =</span> <span className="dv">0</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_scatterplot_gganimate_animation-1.gif"/></p>
</figure>
</div>
</div>
</div>
<p>Check out this awesome <Link to="../../R/gallery/ggplot2-population-pyramid-animation"><strong>animation of annual population pyramids</strong></Link>, which dynamically illustrates the past and predicted dynamics of population structure in Germany.</p>
<p><Link to="../../R/gallery/ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
</section>
</section>
</main>
</div>
</div>
)}