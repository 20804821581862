import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2LegendRemove from '../tutorial/remove_legend_unnamed-chunk-1.png';

export default function Ggplot2LegendRemove(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Remove Legend Components in Three Distinct Ways in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In ggplot2, the legend generation follows such a basic rule: <strong>(1)</strong> each <code>geom_*</code> is mirrored as a glyph element in the legend, and <strong>(2)</strong> each aesthetic is reflected by its own legend. As such, this tutorial explains:</p>
<ul>
<li><a href="#remove_glyph">Remove the glyph element associated with a <code>geom_*</code></a></li>
<li><a href="#remove_aesthetic_legend">Remove the legend associated with an aesthetic</a></li>
<li><a href="#remove_all">Remove all legends at the same time</a></li>
</ul>
<hr/>
<section className="level4" id="create-a-scatterplot">
<h4 className="anchored" data-anchor-id="create-a-scatterplot">Create a scatterplot</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages </span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>)) </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="co"># create a scatterplot</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>p.point <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Sepal.Length, <span className="at">y =</span> Sepal.Width, </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>             <span className="at">color =</span> Species, <span className="at">fill =</span> Species, </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>             <span className="at">size =</span> Petal.Length)) <span className="sc">+</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>), </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>             <span className="at">alpha =</span> .<span className="dv">8</span>) </span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="co"># add regression line</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a><span className="co"># the warning message (not displayed here) can be safely ignored</span></span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>p.point.smooth <span className="ot">&lt;-</span> p.point <span className="sc">+</span> <span className="fu">geom_smooth</span>(<span className="at">method =</span> <span className="st">"lm"</span>) </span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>p.point.smooth</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2LegendRemove} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="remove_glyph">
<h4 className="anchored" data-anchor-id="remove_glyph">Remove the glyph element associated with a <code>geom_*</code></h4>
<p>Each <code>geom_*</code> is mirrored as a glyph element in the legend. The <code>geom_smooth()</code> creates regression lines and confidence ribbons, which are correspondingly reflected as the line and ribbon elements in the legend keys, respectively. These two glyph elements in the legend can be removed by setting <code>show.legend = F</code> in <code>geom_smooth()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p.point <span className="sc">+</span> <span className="fu">geom_smooth</span>(<span className="at">method =</span> <span className="st">"lm"</span>, <span className="at">show.legend =</span> F) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/remove_legend_unnamed-chunk-2.png"/></p>
</figure>
</div>
</div>
</div>
<p>In the plot above, the only remaining legend glyph, the circular points, comes from <code>geom_point()</code>. These two legends reflect the two aesthetics of <code>size</code> and <code>color</code>. If <code>show.legend = F</code> is specified in both <code>geom_point()</code> and <code>geom_smooth()</code>, then all legends will be removed from the plot.</p>
</section>
<section className="level4" id="remove_aesthetic_legend">
<h4 className="anchored" data-anchor-id="remove_aesthetic_legend">Remove the legend associated with an aesthetic</h4>
<p>Each aesthetic has its own legend, which can be individually customized or removed with the <code>guides()</code> function. The script below removes the legend of the <code>size</code> aesthetic.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p.point.smooth <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">size =</span> <span className="st">"none"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/remove_legend_guides.png"/></p>
</figure>
</div>
</div>
</div>
<p>Equivalently, the <code>guides()</code> can be written inside its associated <code>scale()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># use 'guide' without the plural "s"</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>p.point.smooth <span className="sc">+</span> <span className="fu">scale_size_continuous</span>(<span className="at">guide =</span> <span className="st">"none"</span>)</span></code></pre></div>
</div>
</section>
<section className="level4" id="remove_all">
<h4 className="anchored" data-anchor-id="remove_all">Remove all legends at once</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p.point.smooth <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/remove_legend_unnamed-chunk-3.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}