import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function CountUniqueValues(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Count Unique Values in a Vector</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>n_distinct()</code></strong> to count the number of unique values in a vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">c</span>(<span className="dv">3</span>, <span className="dv">3</span>, <span className="dv">5</span>, <span className="dv">6</span>, <span className="dv">6</span>, <span className="dv">7</span>) <span className="sc">%&gt;%</span> <span className="fu">n_distinct</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 4</code></pre>
</div>
</div>
<p><code>n_distinct()</code> is case sensitive, and treat missing values <code>NA</code> as a unique value.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"A"</span>, <span className="st">"a"</span>, <span className="cn">NA</span>, <span className="cn">NA</span>) <span className="sc">%&gt;%</span> <span className="fu">n_distinct</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 3</code></pre>
</div>
</div>
<p>Use <code>na.rm = T</code> to remove <code>NA</code> values from the count.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"A"</span>, <span className="st">"a"</span>, <span className="cn">NA</span>, <span className="cn">NA</span>) <span className="sc">%&gt;%</span> <span className="fu">n_distinct</span>(<span className="at">na.rm =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 2</code></pre>
</div>
</div>
</main>
</div>
</div>
)}