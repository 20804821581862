import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrExtract(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Extract Matched Patterns from a String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<ul>
<li><code>str_extract()</code> extracts the <strong>first match</strong> from each string element.</li>
<li><code>str_extract_all()</code> extracts <strong>all matches</strong> from each string element.</li>
</ul>
<hr/>
<p><strong><code>str_extract()</code></strong> extracts characters that follow a specified pattern from each string element.</p>
<p>In the following example, the regular expression <code>[a-z]&#123;1,6&#125;</code> (character class) matches any sequence of 1 to 6 consecutive lowercase letters.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>shop_list <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="st">"apples *40 Walmart"</span>, <span className="st">"flour *12 Target"</span>, <span className="st">"sugar *3 Costco"</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">str_extract</span>(shop_list, <span className="at">pattern =</span> <span className="st">"[a-z]&#123;1,6&#125;"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apples" "flour"  "sugar" </code></pre>
</div>
</div>
<p>Note that for <code>str_extract()</code>, only the first match is extracted; although “Walmart”, “Target”, and “Costco” are also matched patterns, they are not extracted and retained in the output.</p>
<p>In addition to regular expression, the package <code>rebus</code> offers a more intuitive and easily memorable syntax to define a pattern, e.g., <code>one_or_more(WRD)</code> matches any pattern that contains one or multiple consecutive words (letters or digits, or said <code>WRD</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># install.packages("rebus")</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">library</span>(rebus) </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a><span className="fu">str_extract</span>(shop_list, <span className="at">pattern =</span> <span className="fu">one_or_more</span>(WRD))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apples" "flour"  "sugar" </code></pre>
</div>
</div>
<p>Extract consecutive digits (<code>DGT</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_extract</span>(shop_list, <span className="at">pattern =</span> <span className="fu">one_or_more</span>(DGT)) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "40" "12" "3" </code></pre>
</div>
</div>
<hr/>
<p><strong><code>str_extract_all()</code></strong> extracts all matches from each string element, and returns a list.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># shop_list &lt;- c(</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="co">#   "apples x4", "bag of flour x1", </span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="co">#   "bag of sugar x3", "milk x4")</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a></span><br/>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a><span className="fu">str_extract_all</span>(shop_list, <span className="at">pattern =</span> <span className="fu">one_or_more</span>(WRD))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[[1]]
<br/>[1] "apples"  "40"      "Walmart"

<br/>[[2]]
<br/>[1] "flour"  "12"     "Target"

<br/>[[3]]
<br/>[1] "sugar"  "3"      "Costco"</code></pre>
</div>
</div>
<p>Use <code>simplify = T</code> to return a character matrix.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(shop_list, <span className="at">pattern =</span> <span className="fu">one_or_more</span>(WRD),</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>                <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]     [,2] [,3]     
<br/>[1,] "apples" "40" "Walmart"
<br/>[2,] "flour"  "12" "Target" 
<br/>[3,] "sugar"  "3"  "Costco" </code></pre>
</div>
</div>
</main>
</div>
</div>
)}