import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Map(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Apply a Function to Each Element of a Vector or List</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>TL;DR</strong> <code>map(.x, .f)</code> repeats the operation of function <code>.f</code> to each element of <code>.x</code> (vector, list), and reruns the output as a <em>list</em> of the same length as input <code>.x</code>. The variant functions <code>map_lgl()</code>, <code>map_int()</code>, and <code>map_chr()</code> work in a similar way, but returns an <em>atomic vector</em> of the indicated type (feasible if <code>.f</code> returns a vector of length one for each iteration).</p>
<ul>
<li><a href="#map_basic">Basics of <code>map()</code></a></li>
<li><a href="#vector">Repeat operation across elements of a <strong>vector</strong> (e.g. 1)</a></li>
<li><a href="#list">Repeat operation across elements of a <strong>list</strong> (e.g. 2, 3)</a></li>
<li><a href="#column">Repeat operation across <strong>columns</strong> of a <strong>data frame</strong> (e.g. 4)</a></li>
</ul>
<hr/>
<section className="level3" id="basics-of-map-and-map_">
<h3 className="anchored" data-anchor-id="basics-of-map-and-map_"><span id="map_basic">Basics of <code>map()</code> and <code>map_*()</code></span></h3>
<p>The <code>map()</code> function applies a function to each element of a vector or list, and returns a list. For instance, <code>map(c(1, 2, 3), ~ .x + 1)</code> adds one to each vector element. The basic argument structure follows <code>map(.x, .f)</code> (as in other functions in the <code>map_*</code> family):</p>
<ul>
<li><code>.x</code>: a vector or list</li>
<li><code>.f</code>: the function to be applied to each element of <code>.x</code>. It is in the format of a named function (without quote), e.g. <code>mean</code>; or an anonymous function, e.g., <code>\(x) x + 1</code>, <code>~ .x + 1</code>, <code>function(x) x + 1</code>, or <code>~ mean(.x, na.rm = T)</code>.</li>
<li>The output is a list of the same length as <code>.x</code>.</li>
</ul>
<p>If for each iteration the return of <code>.f</code> is a vector of length one, then you can also use the <code>map_*()</code> functions to return a vector of indicated type:</p>
<ul>
<li><code>map_dbl()</code> returns a vector of type of <em>double</em> (i.e., numeric)</li>
<li><code>map_lgl()</code> returns a vector of type of <em>logic</em></li>
<li><code>map_int()</code> returns a vector of type of <em>integer</em></li>
<li><code>map_chr()</code> returns a vector of type of <em>character</em></li>
</ul>
</section>
<section className="level3" id="repeat-operation-across-elements-of-a-vector">
<h3 className="anchored" data-anchor-id="repeat-operation-across-elements-of-a-vector"><span id="vector">Repeat operation across elements of a vector</span></h3>
<p><strong>e.g.1.</strong> The following code passes <code>1</code>, <code>5</code>, and <code>10</code>, respectively, into <code>rnorm()</code> as values of the <code>mean</code> argument.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(purrr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">map</span>(<span className="at">.x =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">5</span>, <span className="dv">10</span>), </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>    <span className="at">.f =</span> \(x) <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">5</span>, <span className="at">mean =</span> x, <span className="at">sd =</span> .<span className="dv">2</span>)) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [[1]]
<br/>  [1] 1.1652089 1.0194722 0.8136804 1.0904337 1.0735241
<span>  
</span><br/>  [[2]]
<br/>  [1] 5.157856 4.901813 5.034387 4.962213 5.376604
<span>  
</span><br/>  [[3]]
<br/>  [1]  9.628559  9.480926 10.082285 10.119448 10.162851</code></pre>
</div>
</div>
<p>The <code>.f</code> argument can be equivalently written in the following way:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">map</span>(<span className="at">.x =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">5</span>, <span className="dv">10</span>), </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>    <span className="at">.f =</span> <span className="cf">function</span>(x) <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">5</span>, <span className="at">mean =</span> x, <span className="at">sd =</span> .<span className="dv">2</span>)) </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="fu">map</span>(<span className="at">.x =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">5</span>, <span className="dv">10</span>), </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="at">.f =</span> <span className="sc">~</span> <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">5</span>, <span className="at">mean =</span> .x, <span className="at">sd =</span> .<span className="dv">2</span>)) </span></code></pre></div>
</div>
<p>Of course you can rewrite the code using the <Link to="/R/data-wrangling/dplyr/1-pipe-operator">pipe operator <code>%&gt;%</code></Link>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">5</span>, <span className="dv">10</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">map</span>(\(x) <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">5</span>, <span className="at">mean =</span> x, <span className="at">sd =</span> .<span className="dv">2</span>)) </span></code></pre></div>
</div>
</section>
<section className="level3" id="repeat-operation-across-elements-of-a-list">
<h3 className="anchored" data-anchor-id="repeat-operation-across-elements-of-a-list"><span id="list">Repeat operation across elements of a <em>list</em></span></h3>
<p><strong>e.g.2</strong> Create a list of exam scores for each student.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>exam_scores <span className="ot">&lt;-</span> <span className="fu">list</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">Alice =</span> <span className="fu">c</span>(<span className="dv">80</span>, <span className="dv">75</span>, <span className="dv">85</span>, <span className="dv">90</span>),</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">Bob =</span> <span className="fu">c</span>(<span className="dv">90</span>, <span className="dv">85</span>, <span className="dv">88</span>, <span className="dv">94</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">Charlie =</span> <span className="fu">c</span>(<span className="dv">70</span>, <span className="dv">65</span>, <span className="cn">NA</span>, <span className="cn">NA</span>)</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>)</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a></span><br/>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>exam_scores</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  $Alice
<br/>  [1] 80 75 85 90
<span>  
</span><span>  $Bob
</span><br/>  [1] 90 85 88 94
<span>  
</span><span>  $Charlie
</span><br/>  [1] 70 65 NA NA</code></pre>
</div>
</div>
<p>Calculate the average scores for each student, and return the output as a list.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>exam_scores <span className="sc">%&gt;%</span> <span className="fu">map</span>(mean, <span className="at">na.rm =</span> T)</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a></span><br/>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="co"># other equivalent forms</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>exam_scores <span className="sc">%&gt;%</span> <span className="fu">map</span>( <span className="sc">~</span> <span className="fu">mean</span>(.x, <span className="at">na.rm =</span> T))</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>exam_scores <span className="sc">%&gt;%</span> <span className="fu">map</span>( \(x) <span className="fu">mean</span>(x, <span className="at">na.rm =</span> T))</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>exam_scores <span className="sc">%&gt;%</span> <span className="fu">map</span>( <span className="cf">function</span>(x) <span className="fu">mean</span>(x, <span className="at">na.rm =</span> T) )</span></code></pre></div>
</div>
<div className="cell">
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  $Alice
<br/>  [1] 82.5
<span>  
</span><span>  $Bob
</span><br/>  [1] 89.25
<span>  
</span><span>  $Charlie
</span><br/>  [1] 67.5</code></pre>
</div>
</div>
<p>Calculate the mean score of each student.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>exam_scores <span className="sc">%&gt;%</span> <span className="fu">map_dbl</span>(\(x) <span className="fu">mean</span>(x, <span className="at">na.rm =</span> T))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    Alice     Bob Charlie 
<br/>    82.50   89.25   67.50</code></pre>
</div>
</div>
<p>Check if the average score of each student is larger than 80.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>exam_scores <span className="sc">%&gt;%</span> <span className="fu">map_lgl</span>(\(x) <span className="fu">mean</span>(x, <span className="at">na.rm =</span> T) <span className="sc">&gt;</span> <span className="dv">80</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    Alice     Bob Charlie 
<br/>     TRUE    TRUE   FALSE</code></pre>
</div>
</div>
<p>Note that <code>map_*()</code> functions are feasible only if the <code>.f</code> - specified operation that is applied to each element of the list (e.g., each student) returns a vector of length-one (e.g., a single mean value).</p>
<p><strong>e.g.3.</strong> Here we calculate the correlation between <code>Sepal.Length</code> and <code>Sepal.Width</code> for each iris species.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="co"># split the `iris` dataset into a list of data frames</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>  <span className="co"># with each element (data frame) being a subset of a 'Species' type </span></span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>  <span className="fu">split</span>(iris<span className="sc">$</span>Species) <span className="sc">%&gt;%</span> </span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>  </span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>  <span className="co"># For each list element (of a species type), </span></span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>  <span className="co"># create a linear model between 'Sepal.Length' and 'Sepal.Width'; output as a list</span></span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>  <span className="fu">map</span>(<span className="sc">~</span> <span className="fu">lm</span>(Sepal.Length <span className="sc">~</span> Sepal.Width, <span className="at">data =</span> .x)) <span className="sc">%&gt;%</span> </span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>  </span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>  <span className="co"># For each linear model, create a summary and extract the R2</span></span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a>  <span className="fu">map</span>(summary) <span className="sc">%&gt;%</span> </span>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a>  <span className="fu">map_dbl</span>(<span className="at">.f =</span> <span className="st">"r.squared"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">      setosa versicolor  virginica 
<br/>   0.5513756  0.2765821  0.2090573</code></pre>
</div>
</div>
<p>An even more powerful approach is to group the dataset with <code>nest()</code> of the <Link to="/R/data-wrangling/tidyr/introduction">tidyr</Link> package, create a list-column of the model objects with <code>mutate()</code> and <code>map()</code>, and then extract and unfold the model parameters using the broom package. We’ll dive into more details of this powerful approach in a later tutorial.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>  <span className="co"># creates a 'data' column, which is a list of tibbles for each species</span></span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>  tidyr<span className="sc">::</span><span className="fu">nest</span>(<span className="sc">-</span>Species) <span className="sc">%&gt;%</span> </span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>  <span className="co"># create a 'model' column, which is a list of model objects</span></span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">model =</span> <span className="fu">map</span>(</span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>    data, <span className="sc">~</span><span className="fu">lm</span>(Sepal.Length <span className="sc">~</span> Sepal.Width, <span className="at">data =</span> .x))) <span className="sc">%&gt;%</span> </span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>  <span className="co"># create a 'glance' column, which is a list of model parameters, </span></span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>  <span className="co"># which are extracted using the broom package</span></span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">glance =</span> <span className="fu">map</span>(model, broom<span className="sc">::</span>glance)) <span className="sc">%&gt;%</span> </span>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>  <span className="co"># display the model parameters </span></span>
<span id="cb15-11"><a aria-hidden="true" href="#cb15-11" tabindex="-1"></a>  tidyr<span className="sc">::</span><span className="fu">unnest</span>(glance)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 15
<br/>    Species  data     model r.squared adj.r.squared sigma statistic  p.value    df
<br/>    &lt;fct&gt;    &lt;list&gt;   &lt;lis&gt;     &lt;dbl&gt;         &lt;dbl&gt; &lt;dbl&gt;     &lt;dbl&gt;    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 setosa   &lt;tibble&gt; &lt;lm&gt;      0.551         0.542 0.239      59.0 6.71e-10     1
<br/>  2 versico… &lt;tibble&gt; &lt;lm&gt;      0.277         0.262 0.444      18.4 8.77e- 5     1
<br/>  3 virgini… &lt;tibble&gt; &lt;lm&gt;      0.209         0.193 0.571      12.7 8.43e- 4     1
<br/>  # ℹ 6 more variables: logLik &lt;dbl&gt;, AIC &lt;dbl&gt;, BIC &lt;dbl&gt;, deviance &lt;dbl&gt;,
<br/>  #   df.residual &lt;int&gt;, nobs &lt;int&gt;</code></pre>
</div>
</div>
</section>
<section className="level3" id="repeat-operation-across-columns-of-a-data-frame">
<h3 className="anchored" data-anchor-id="repeat-operation-across-columns-of-a-data-frame"><span id="column">Repeat operation across columns of a data frame</span></h3>
<p><strong>e.g.4.</strong> The data frame is a special format of a list, with each column being an “element”. Below we calculate the mean of each column, and output the result as a named vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a>iris[, <span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>] <span className="sc">%&gt;%</span> <span className="fu">map_dbl</span>(<span className="at">.f =</span> mean)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  Sepal.Length  Sepal.Width Petal.Length  Petal.Width 
<br/>      5.843333     3.057333     3.758000     1.199333</code></pre>
</div>
</div>
<p>It is similar to the line below using <Link to="/R/data-wrangling/dplyr/14-repeated-operations-on-multiple-column"><code>across()</code></Link> to repeat operations across multiple columns, but with output as a one-row data frame.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a>iris[, <span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>] <span className="sc">%&gt;%</span> <span className="fu">summarise</span>(<span className="fu">across</span>(<span className="fu">everything</span>(), mean))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    Sepal.Length Sepal.Width Petal.Length Petal.Width
<br/>  1     5.843333    3.057333        3.758    1.199333</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}