import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function RowwiseOperations(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Rowwise Operations of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>rowwise()</code></strong> to indicate that its subsequent functions should operate on a rowwise basis. Use <Link to="../6-grouped-dataset"><code>ungroup()</code></Link> to remove the rowwise-grouped effect.</p>
<p>Consider the following example:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">data.frame</span>(<span className="at">item =</span> <span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>, <span className="st">"C"</span>),</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>                <span className="at">a =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">5</span>),</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>                <span className="at">b =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">5</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>                <span className="at">c =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">2</span>, <span className="dv">3</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>                <span className="at">d =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">2</span>, <span className="dv">7</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>x</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    item a b c d
<br/>  1    A 1 2 5 0
<br/>  2    B 1 3 2 2
<br/>  3    C 5 5 3 7</code></pre>
</div>
</div>
<p>Suppose you want to calculate the mean of the four numerical variables separately <em>for each row</em>. You may do it this way:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">row_means =</span> (a <span className="sc">+</span> b <span className="sc">+</span> c <span className="sc">+</span> d)<span className="sc">/</span><span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    item a b c d row_means
<br/>  1    A 1 2 5 0         2
<br/>  2    B 1 3 2 2         2
<br/>  3    C 5 5 3 7         5</code></pre>
</div>
</div>
<p>Equivalently, you can achieve the same effect using <code>rowwise()</code> and <code>mean()</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">rowwise</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">row_means =</span> <span className="fu">mean</span>(<span className="fu">c</span>(a, b, c, d)))</span></code></pre></div>
</div>
<p>As comparison, if without <code>rowwise()</code>, a <em>same single</em> mean value would be calculated across <em>all</em> columns and <em>all</em> rows.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">row_means =</span> <span className="fu">mean</span>(<span className="fu">c</span>(a, b, c, d)))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    item a b c d row_means
<br/>  1    A 1 2 5 0         3
<br/>  2    B 1 3 2 2         3
<br/>  3    C 5 5 3 7         3</code></pre>
</div>
</div>
<h4 className="anchored">
🚀 <strong><em>one level up!</em></strong>
</h4>
<p>You can use <strong><code>c_across()</code></strong> to select a block of columns (similar to <Link to="../14-repeated-operations-on-multiple-column"><code>across()</code></Link>).</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">rowwise</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">row_minim =</span> <span className="fu">mean</span>(<span className="fu">c_across</span>(a<span className="sc">:</span>d))) </span></code></pre></div>
</div>
<p>Inside <code>c_across()</code>, you can use other <Link to="../2-select-columns">selection helpers</Link> to select a range of columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">rowwise</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">row_minim =</span> <span className="fu">mean</span>(<span className="fu">c_across</span>(<span className="fu">where</span>(is.numeric))))</span></code></pre></div>
</div>
</main>
</div>
</div>
)}