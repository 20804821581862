import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonIfelse(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Conditional-Statements:-if...else">Conditional Statements: <code>if...else</code><a class="anchor-link" href="#Conditional-Statements:-if...else">¶</a></h3><p>Conditional statements are a cruicial component of Python, enabling programs to execute actions based on specified conditions.</p>
<p>One of the fundamental conditional statements is the <code>if...else</code> statement, along with its extended form, the <code>if...elif...else</code> statement. These constructs operate in a manner similar to the use of "if" in human language but utilize Python code to interact with programs. Let's explore some examples below to enhance your understanding of their usage.</p>
<p>Other conditional constructs, such as <code>try, except, and finally</code> statements, are used for error handling. This more advanced topic will not be discussed in this tutorial.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="if...else-statement">if...else statement<a class="anchor-link" href="#if...else-statement">¶</a></h4><p>The example below illustrates the fundamental logic of an <code>if...else</code> statment in the simplest format.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="k">if</span> <span class="kc">True</span><span class="p">:</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'The "if" block ran'</span><span class="p">)</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'The "else" block ran'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>The "if" block ran
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong>:</p>
<ul>
<li>The <code>if</code> keyword is followed by the boolean value <code>True</code>, which constitutes the condition to be evaluated. </li>
<li>In this case, since the condition is always true, the code within the <code>if</code> block will be executed. </li>
<li>Consequently, the program will print <code>The "if" block ran</code>. The code in the <code>else</code> block will never be executed.</li>
</ul>
<p>Let's modify the code to execute the <code>else</code> block.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="k">if</span> <span class="kc">False</span><span class="p">:</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'The "if" block ran'</span><span class="p">)</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'The "else" block ran'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>"else" block ran
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong>:</p>
<ul>
<li>In this scenario, the <code>if</code> keyword is followed by the boolean value False, which forms the condition to be evaluated.</li>
<li>The program first proceeds to the <code>if</code> statement and evaluate the condition. Since the condition is always false, so the code within the <code>if</code> block will not be executed.</li>
<li>Instead, the program will proceed to the <code>else</code> block and execute the code within it. </li>
<li>Consequently, the program will print <code>The "else" block ran</code>.</li>
</ul>
<p>Now that we've grasped the basic logic of the <code>if...else</code> statement, let's explore the integration of comparison operations and conditional statements to enahnce their utility.</p>
<p><strong>Example</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a variable x</span></span>

<span><span class="n">x</span> <span class="o">=</span> <span class="mi">20</span></span>


<br /><span><span class="c1"># Compare the equality of the x variable and 20</span></span>

<span><span class="k">if</span> <span class="n">x</span> <span class="o">==</span> <span class="mi">20</span><span class="p">:</span></span>

<span>    <span class="c1"># If the equality comparison evaluates to True, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="n">x</span><span class="p">)</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="c1"># If the quality comparison evaluates to False, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"x doesn't equal to 20"</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>20
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong>:</p>
<p>This code is an example of a <code>if...else</code> statement that checks the value of a variable x.</p>
<ul>
<li>The <code>if</code> keyword is followed by a equality comparison: <code>x == 20</code>. This condition compares the equality beween the variable x and the number 20.</li>
<li>If the condition is true, meaning that x is equal to 20, then the code within the <code>if</code> block is executed. In this case, it prints the value of <code>x</code>.</li>
<li>If the condition is false, indicating that x is not equal to 20, then the code within the <code>else</code> block is executed. This block prints the message "<code>x doesn't equal to 20</code>".</li>
<li>In this example, the <code>if</code> block gets executed and print the value of <code>x</code>.</li>
</ul>
<p>If we set x to 10, the <code>else</code> block gets executed instead:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a variable x</span></span>

<span><span class="n">x</span> <span class="o">=</span> <span class="mi">10</span></span>


<br /><span><span class="c1"># Compare the equality of the x variable and 20</span></span>

<span><span class="k">if</span> <span class="n">x</span> <span class="o">==</span> <span class="mi">20</span><span class="p">:</span></span>

<span>    <span class="c1"># If the equality comparison evaluates to True, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="n">x</span><span class="p">)</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="c1"># If the quality comparison evaluates to False, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"x doesn't equal to 20"</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>x doesn't equal to 20
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>After setting <code>x</code> to 10, the expression <code>x == 20</code> evaluates to <code>False</code>, promoting the program to execute the <code>else</code> block.</p>
<p>Let's see another example.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables x and y</span></span>

<span><span class="n">x</span> <span class="o">=</span> <span class="mi">100</span></span>

<span><span class="n">y</span> <span class="o">=</span> <span class="mi">10</span></span>


<br /><span><span class="c1"># Compare whether the value of x is less than or equal to the value of y</span></span>

<span><span class="k">if</span> <span class="n">x</span> <span class="o">&lt;=</span> <span class="n">y</span><span class="p">:</span></span>

<span>    <span class="c1"># If the value of x is less than or equal to the value of y, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'x &lt;= y'</span><span class="p">)</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="c1"># If the value of x is larger than the value of y, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'x &gt; y'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>x &gt; y
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong>:</p>
<p>This code is to compare whether the value of x is less than or equal to the value of y.</p>
<ul>
<li>The <code>if</code> keyword is followed by a condition: <code>x &lt;= y</code>. This condition checks if the value of x is less than or equal to the value of y.</li>
<li>If the condition is true, meaning that x is indeed less than or equal to y, then the code within the if block is executed. In this case, it prints the message "<code>x &lt;= y</code>".</li>
<li>If the condition is false, indicating that <code>x</code> is greater than y, then the code within the <code>else</code> block is executed. This block prints the message "<code>x &gt; y</code>".</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>For demonstration purposes, the examples above solely utilize the <code>print()</code> function. It's worth noting that within the <code>if...else</code> blocks, a variety of actions beyond printing can be executed.</p>
<p><strong>Example</strong></p>
<p>Use <code>if...else</code> statement to conditionally change the value of a variable.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables x, y and c</span></span>

<span><span class="n">x</span> <span class="o">=</span> <span class="mi">100</span></span>

<span><span class="n">y</span> <span class="o">=</span> <span class="mi">10</span></span>

<span><span class="n">c</span> <span class="o">=</span> <span class="mi">0</span></span>


<br /><span><span class="c1"># Compare whether the value of x is less than or equal to the value of y</span></span>

<span><span class="k">if</span> <span class="n">x</span> <span class="o">&lt;=</span> <span class="n">y</span><span class="p">:</span></span>

<span>    <span class="c1"># If the value of x is less than or equal to the value of y, then execute this code</span></span>

<span>    <span class="n">c</span> <span class="o">=</span> <span class="n">c</span> <span class="o">+</span> <span class="mi">1</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="c1"># If the value of x is larger than the value of y, then execute this code</span></span>

<span>    <span class="n">c</span> <span class="o">=</span> <span class="n">c</span> <span class="o">-</span> <span class="mi">1</span></span>

    
<br /><span><span class="nb">print</span><span class="p">(</span><span class="s2">"c changes from 0 to"</span><span class="p">,</span> <span class="n">c</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>c changes from 0 to -1
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation:</strong></p>
<p>This code sets three variables, <code>x</code>, <code>y</code>, and <code>c</code>, to specific values and then uses an <code>if...else</code> statement to conditionally change the variable <strong>c</strong> based on the comparison of x and y.</p>
<ul>
<li>The <code>if</code> condition checks if the value of <code>x</code> is less than or equal to the value of <code>y</code>.</li>
<li>If the condition is true, meaning that <code>y</code> is indeed less than or equal to <code>y</code>, then the code within the <code>if</code> block is executed. In this case, it adds 1 to the value of <code>c</code>, effectively incrementing it by 1.</li>
<li>If the condition is false, indicating that <code>x</code> is greater than <code>y</code>, then the code within the <code>else</code> block is executed. This block subtracts 1 from the value of <code>c</code>, effectively decrementing it by 1.</li>
</ul>
<p>So, based on the values assigned to <code>x</code> and <code>y</code> in this code, it substracts <code>c</code> by 1 because x (100) is greater than y (10). Therefore, after this code snippet, the value of c becomes -1.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="if...elif...else-statement"><code>if...elif...else</code> statement<a class="anchor-link" href="#if...elif...else-statement">¶</a></h4><p>The <code>if...elif...else</code> statement expands upon the basic <code>if...else</code> by adding more conditional layers. You can include multiple <code>elif</code> statements for additional conditions. Let's explore how this structure operates.</p>
<p><strong>Example</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables x and y</span></span>

<span><span class="n">x</span> <span class="o">=</span> <span class="mi">100</span></span>

<span><span class="n">y</span> <span class="o">=</span> <span class="mi">100</span></span>


<br /><span><span class="c1"># Compare whether x is less than y</span></span>

<span><span class="k">if</span> <span class="n">x</span> <span class="o">&lt;</span> <span class="n">y</span><span class="p">:</span></span>

<span>    <span class="c1"># If x is less than y, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'x &lt; y'</span><span class="p">)</span></span>

<span><span class="c1"># Compare whether x is equal to y</span></span>

<span><span class="k">elif</span> <span class="n">x</span> <span class="o">==</span> <span class="n">y</span><span class="p">:</span></span>

<span>    <span class="c1"># If x is euqal to y, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'x = y'</span><span class="p">)</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="c1"># If x is larger than y, then execute this code</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s1">'x &gt; y'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>x = y
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>In the example, we divide <code>x &lt;= y</code> to two distinct condtions <code>x &lt; y</code>  and <code>x == y</code>, enabling the program to respond to different scenarios.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The example below uses both comparison and logical operations in the conditional statements, creating a more complex setup. Can you deduce the conditions covered by the <code>else</code> statement based on preceeding <code>if</code> and <code>elif</code> statements?</p>
<p><strong>Example</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables x and y</span></span>

<span><span class="n">x</span> <span class="o">=</span> <span class="mi">10</span></span>

<span><span class="n">y</span> <span class="o">=</span> <span class="mi">10</span></span>


<br /><span><span class="c1"># Check if both x and y are less than 10.</span></span>

<span><span class="k">if</span> <span class="n">x</span> <span class="o">&lt;</span> <span class="mi">10</span> <span class="ow">and</span> <span class="n">y</span> <span class="o">&lt;</span> <span class="mi">10</span><span class="p">:</span></span>

<span>    <span class="n">c</span> <span class="o">=</span> <span class="n">x</span> <span class="o">+</span> <span class="n">y</span></span>

<span><span class="c1"># Check if x is less than 10 and y is greater than 10.</span></span>

<span><span class="k">elif</span> <span class="n">x</span> <span class="o">&lt;</span> <span class="mi">10</span> <span class="ow">and</span> <span class="n">y</span> <span class="o">&gt;</span> <span class="mi">10</span><span class="p">:</span></span>

<span>    <span class="n">c</span> <span class="o">=</span> <span class="n">x</span> <span class="o">*</span> <span class="n">y</span></span>

<span><span class="c1"># Check if x is greater than 10 and y is less than 10.</span></span>

<span><span class="k">elif</span> <span class="n">x</span> <span class="o">&gt;</span> <span class="mi">10</span> <span class="ow">and</span> <span class="n">y</span> <span class="o">&lt;</span> <span class="mi">10</span><span class="p">:</span></span>

<span>    <span class="n">c</span> <span class="o">=</span> <span class="n">y</span> <span class="o">/</span> <span class="n">x</span></span>

<span><span class="c1"># what condition is included in the else statement?</span></span>

<span><span class="k">else</span><span class="p">:</span></span>

<span>    <span class="n">c</span> <span class="o">=</span> <span class="mi">0</span></span>


<br /><span><span class="c1"># Print variable c</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">c</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>0
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation:</strong></p>
<ul>
<li>The code starts with an <code>if</code> statement with the condition <code>x &lt; 10</code> and <code>y &lt; 10</code>. This condition checks if both <code>x</code> and <code>y</code> are less than 10.<ul>
<li>If this condition is true, it adds <code>x</code> and <code>y</code> together and assigns the result to <code>c</code>. In this case, <code>c</code> becomes 20.</li>
<li>If the condition is false, it moves on to the next <code>elif (else if)</code> statement.</li>
</ul>
</li>
<li>The next <code>elif</code> statement has the condition <code>x &lt; 10</code> and <code>y &gt; 10</code>, which checks if <code>x</code> is less than 10 and <code>y</code> is greater than 10.<ul>
<li>If this condition is true, it multiplies <code>x</code> and <code>y</code> together and assigns the result to <code>c</code>. In this case, <code>c</code> becomes 100.</li>
<li>If the condition is false, it moves on to the next <code>elif</code> statement.</li>
</ul>
</li>
<li>The final <code>elif</code> statement has the condition <code>x &gt; 10</code> and <code>y &lt; 10</code>, which checks if <code>x</code> is greater than 10 and <code>y</code> is less than 10.<ul>
<li>If this condition is true, it divides <code>y</code> by <code>x</code> and assigns the result to <code>c</code>. In this case, <code>c</code> becomes 1.</li>
<li>If the condition is false, it falls into the else block.</li>
</ul>
</li>
<li>The <code>else</code> block runs when none of the preceding conditions are met, occurring when both <code>x</code> and <code>y</code> are greater than or equal to 10. In this scenario, it sets <code>c</code> to 0.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Recap">Recap<a class="anchor-link" href="#Recap">¶</a></h4><p>Conditional statements are a powerful tool that enable programs to operate differently under various conditions. We'll explore more use cases as we delve into additional topics in Python.</p>
</div>
</div>
</div>
</div>
</div>
)}