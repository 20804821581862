import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function GlimpseColumns(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Take a Quick Glimpse of the Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<section className="level3" id="functions-for-columns">
<h3 className="anchored" data-anchor-id="functions-for-columns">Functions for columns</h3>
<p><strong><code>glimpse()</code></strong> works similarly as <code>print()</code>, but outputs the dataset in a <strong>transposed</strong> format. That is, the columns are displayed vertically, while the data runs horizontally, making it easy to view every column in a data frame.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> <span className="fu">glimpse</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  Rows: 87
<br/>  Columns: 14
<span>  $ name       &lt;chr&gt; "Luke Skywalker", "C-3PO", "R2-D2", "Darth Vader", "Leia Or…
</span><span>  $ height     &lt;int&gt; 172, 167, 96, 202, 150, 178, 165, 97, 183, 182, 188, 180, 2…
</span><span>  $ mass       &lt;dbl&gt; 77.0, 75.0, 32.0, 136.0, 49.0, 120.0, 75.0, 32.0, 84.0, 77.…
</span><span>  $ hair_color &lt;chr&gt; "blond", NA, NA, "none", "brown", "brown, grey", "brown", N…
</span><span>  $ skin_color &lt;chr&gt; "fair", "gold", "white, blue", "white", "light", "light", "…
</span><span>  $ eye_color  &lt;chr&gt; "blue", "yellow", "red", "yellow", "brown", "blue", "blue",…
</span><span>  $ birth_year &lt;dbl&gt; 19.0, 112.0, 33.0, 41.9, 19.0, 52.0, 47.0, NA, 24.0, 57.0, …
</span><span>  $ sex        &lt;chr&gt; "male", "none", "none", "male", "female", "male", "female",…
</span><span>  $ gender     &lt;chr&gt; "masculine", "masculine", "masculine", "masculine", "femini…
</span><span>  $ homeworld  &lt;chr&gt; "Tatooine", "Tatooine", "Naboo", "Tatooine", "Alderaan", "T…
</span><span>  $ species    &lt;chr&gt; "Human", "Droid", "Droid", "Human", "Human", "Human", "Huma…
</span><span>  $ films      &lt;list&gt; &lt;"A New Hope", "The Empire Strikes Back", "Return of the J…
</span><span>  $ vehicles   &lt;list&gt; &lt;"Snowspeeder", "Imperial Speeder Bike"&gt;, &lt;&gt;, &lt;&gt;, &lt;&gt;, "Imp…
</span><span>  $ starships  &lt;list&gt; &lt;"X-wing", "Imperial shuttle"&gt;, &lt;&gt;, &lt;&gt;, "TIE Advanced x1",…</span></code></pre>
</div>
</div>
<p>Use <code>width</code> argument to specify the number of characters in each line. If not specified, each row defaults to extend to the full width of the console.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> <span className="fu">glimpse</span>(<span className="at">width =</span> <span className="dv">60</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  Rows: 87
<br/>  Columns: 14
<span>  $ name       &lt;chr&gt; "Luke Skywalker", "C-3PO", "R2-D2", "Da…
</span><span>  $ height     &lt;int&gt; 172, 167, 96, 202, 150, 178, 165, 97, 1…
</span><span>  $ mass       &lt;dbl&gt; 77, 75, 32, 136, 49, 120, 75, 32, 84, 7…
</span><span>  $ hair_color &lt;chr&gt; "blond", NA, NA, "none", "brown", "brow…
</span><span>  $ skin_color &lt;chr&gt; "fair", "gold", "white, blue", "white",…
</span><span>  $ eye_color  &lt;chr&gt; "blue", "yellow", "red", "yellow", "bro…
</span><span>  $ birth_year &lt;dbl&gt; 19.0, 112.0, 33.0, 41.9, 19.0, 52.0, 47…
</span><span>  $ sex        &lt;chr&gt; "male", "none", "none", "male", "female…
</span><span>  $ gender     &lt;chr&gt; "masculine", "masculine", "masculine", …
</span><span>  $ homeworld  &lt;chr&gt; "Tatooine", "Tatooine", "Naboo", "Tatoo…
</span><span>  $ species    &lt;chr&gt; "Human", "Droid", "Droid", "Human", "Hu…
</span><span>  $ films      &lt;list&gt; &lt;"A New Hope", "The Empire Strikes Bac…
</span><span>  $ vehicles   &lt;list&gt; &lt;"Snowspeeder", "Imperial Speeder Bike…
</span><span>  $ starships  &lt;list&gt; &lt;"X-wing", "Imperial shuttle"&gt;, &lt;&gt;, &lt;&gt;…</span></code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}