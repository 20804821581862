import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function SelectRows(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Select Rows of a dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>You have learned <Link to="../3-filter-rows"><code>filter()</code></Link> to select rows based on a specified criteria. The family of functions <code>slice_*()</code> is another useful tool for row selection. In this tutorial, you’ll learn:</p>
<ul>
<li><a href="#slice"><strong><code>slice()</code></strong>: select rows by their integer index.</a></li>
<li><a href="#slice_head_slice_tail"><strong><code>slice_head()</code></strong> and <strong><code>slice_tail()</code></strong>: select the first or last rows.</a></li>
<li><a href="#slice_min_slice_max"><strong><code>slice_min()</code></strong> and <strong><code>slice_max()</code></strong>: select rows with the smallest or largest values of a variable.</a></li>
<li><a href="#slice_sample"><strong><code>slice_sample()</code></strong>: randomly selects rows.</a></li>
</ul>
<section className="level3" id="slice">
<h3 className="anchored" data-anchor-id="slice">Subset rows with <strong><code>slice()</code></strong></h3>
<p>Use <strong><code>slice()</code></strong> and integer row indices to select rows. Use positive integers to include rows, and negative integers to remove rows.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># select the 3rd row</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice</span>(<span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 1 × 5
<br/>    name  height  mass hair_color skin_color 
<br/>    &lt;chr&gt;  &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 R2-D2     96    32 &lt;NA&gt;       white, blue</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># select the 3rd to 5th row</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice</span>(<span className="dv">3</span><span className="sc">:</span><span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 5
<br/>    name        height  mass hair_color skin_color 
<br/>    &lt;chr&gt;        &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 R2-D2           96    32 &lt;NA&gt;       white, blue
<br/>  2 Darth Vader    202   136 none       white      
<br/>  3 Leia Organa    150    49 brown      light</code></pre>
</div>
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># drop the first four rows</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice</span>(<span className="sc">-</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 83 × 5
<br/>    name               height  mass hair_color  skin_color
<br/>    &lt;chr&gt;               &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;       &lt;chr&gt;     
<br/>  1 Leia Organa           150    49 brown       light     
<br/>  2 Owen Lars             178   120 brown, grey light     
<br/>  3 Beru Whitesun Lars    165    75 brown       light     
<br/>  4 R5-D4                  97    32 &lt;NA&gt;        white, red
<br/>  # ℹ 79 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="slice_head_slice_tail">
<h3 className="anchored" data-anchor-id="slice_head_slice_tail">Extended helper functions</h3>
<p>Use <strong><code>slice_head()</code></strong> to select the first <strong><code>n</code></strong> rows, or <strong><code>prop</code></strong> (proportion) of rows of the dataset. In like manner, use <strong><code>slice_tail()</code></strong> to select rows from the end of the dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># select the top 3 rows</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice_head</span>(<span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 5
<br/>    name           height  mass hair_color skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond      fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;       white, blue</code></pre>
</div>
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># select the first 10% of the rows</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice_head</span>(<span className="at">prop =</span> .<span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 8 × 5
<br/>    name           height  mass hair_color skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond      fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;       white, blue
<br/>  4 Darth Vader       202   136 none       white      
<br/>  # ℹ 4 more rows</code></pre>
</div>
</div>
<p>When the dataset has grouping variables (i.e., specified by <Link to="../6-grouped-dataset"><code>group_by()</code></Link>), the slicing will be applied respectively to each group. This applies to <code>slice()</code> and all the <code>slice_*</code> helper functions (as in many other dplyr functions, but <em>not</em> in <Link to="../7-arrange"><code>arrange()</code></Link>).</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># select the first 2 rows within each group of "hair_color"</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">group_by</span>(hair_color) <span className="sc">%&gt;%</span> </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>  <span className="fu">slice_head</span>(<span className="at">n =</span> <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 18 × 5
<br/>  # Groups:   hair_color [12]
<br/>    name              height  mass hair_color    skin_color
<br/>    &lt;chr&gt;              &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;         &lt;chr&gt;     
<br/>  1 Mon Mothma           150    NA auburn        fair      
<br/>  2 Wilhuff Tarkin       180    NA auburn, grey  fair      
<br/>  3 Obi-Wan Kenobi       182    77 auburn, white fair      
<br/>  4 Biggs Darklighter    183    84 black         light     
<br/>  # ℹ 14 more rows</code></pre>
</div>
</div>
<p>Note that if <code>n</code> is greater than the number of rows in each group, the output will be the actual group size. The number of rows calculated based on proportion will be rounded down to an integer. For instance, consider the following dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="at">class =</span> <span className="fu">rep</span>(<span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>, <span className="st">"C"</span>), <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">3</span>)),</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>  <span className="at">sales =</span> <span className="fu">rnorm</span>(<span className="dv">6</span>, <span className="dv">500</span>, <span className="dv">50</span>))</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>x</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 6 × 2
<br/>    class sales
<br/>    &lt;chr&gt; &lt;dbl&gt;
<br/>  1 A      530.
<br/>  2 B      513.
<br/>  3 B      565.
<br/>  4 C      603.
<br/>  5 C      493.
<br/>  6 C      543.</code></pre>
</div>
</div>
<p>The code below selects 50% of rows from each level of “class”, or 0.5, 1 and 1.5 rows from A, B, and C, respectively. With a rounding-down rule, 0, 1, and 1 row is taken separately from each group and retained in the output dataset (information of class “A” is thus missed).</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">group_by</span>(class) <span className="sc">%&gt;%</span> <span className="fu">slice_head</span>(<span className="at">prop =</span> .<span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>  # Groups:   class [2]
<br/>    class sales
<br/>    &lt;chr&gt; &lt;dbl&gt;
<br/>  1 B      513.
<br/>  2 C      603.</code></pre>
</div>
</div>
<section className="level4" id="slice_min_slice_max">
<h4 className="anchored" data-anchor-id="slice_min_slice_max"></h4>
<p>Use <strong><code>slice_min()</code></strong> to select rows containing the minimum values of a specified variable. Alternatively, use <strong><code>slice_max()</code></strong> to select rows containing the maximum values of a variable. Consider the following example.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">class =</span> letters[<span className="dv">1</span><span className="sc">:</span><span className="dv">6</span>],</span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a>            <span className="at">A =</span> <span className="fu">c</span>(<span className="dv">10</span>, <span className="dv">3</span>, <span className="dv">8</span>, <span className="dv">8</span>, <span className="dv">8</span>, <span className="dv">5</span>),</span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a>            <span className="at">B =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">8</span>, <span className="dv">6</span>, <span className="dv">5</span>, <span className="dv">10</span>))</span>
<span id="cb17-4"><a aria-hidden="true" href="#cb17-4" tabindex="-1"></a>x</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 6 × 3
<br/>    class     A     B
<br/>    &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 a        10     2
<br/>  2 b         3     3
<br/>  3 c         8     8
<br/>  4 d         8     6
<br/>  5 e         8     5
<br/>  6 f         5    10</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a><span className="co"># select 3 rows containing the minimum values of "B" column</span></span>
<span id="cb19-2"><a aria-hidden="true" href="#cb19-2" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">slice_min</span>(B, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 3
<br/>    class     A     B
<br/>    &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 a        10     2
<br/>  2 b         3     3
<br/>  3 e         8     5</code></pre>
</div>
</div>
<p>In the presence of ties, more rows than requested will be returned. In the following example, the output dataset has a total of four rows instead of requested two rows, as three rows contain the tied value 8 of variable “A”.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb21"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb21-1"><a aria-hidden="true" href="#cb21-1" tabindex="-1"></a><span className="co"># select 2 rows containing the maximum values of "A"</span></span>
<span id="cb21-2"><a aria-hidden="true" href="#cb21-2" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">slice_max</span>(A, <span className="at">n =</span> <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 4 × 3
<br/>    class     A     B
<br/>    &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 a        10     2
<br/>  2 c         8     8
<br/>  3 d         8     6
<br/>  4 e         8     5</code></pre>
</div>
</div>
<p>Use <strong><code>with_ties = FALSE</code></strong> to return exactly <strong><code>n</code></strong> matches in presence of ties.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb23"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb23-1"><a aria-hidden="true" href="#cb23-1" tabindex="-1"></a><span className="co"># select 2 rows containing the maximum values of "A"</span></span>
<span id="cb23-2"><a aria-hidden="true" href="#cb23-2" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">slice_max</span>(A, <span className="at">n =</span> <span className="dv">2</span>, <span className="at">with_ties =</span> F)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 3
<br/>    class     A     B
<br/>    &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 a        10     2
<br/>  2 c         8     8</code></pre>
</div>
</div>
<p>When selecting multiple variables, wrap the variable names inside <code>tibble()</code>. In the example below, rows are first selected based on the 1st variable “A”; in presence of ties in “A”, rows are then selected from the second variables “B”.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb25"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb25-1"><a aria-hidden="true" href="#cb25-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">slice_max</span>(<span className="fu">tibble</span>(A, B), <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 4 × 3
<br/>    class     A     B
<br/>    &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 a        10     2
<br/>  2 c         8     8
<br/>  3 d         8     6
<br/>  4 e         8     5</code></pre>
</div>
</div>
</section>
<section className="level4" id="slice_sample">
<h4 className="anchored" data-anchor-id="slice_sample"></h4>
<p>Use <strong><code>slice_sample()</code></strong> to randomly select <strong><code>n</code></strong> rows, or a <strong><code>prop</code></strong> (proportion) of rows.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb27"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb27-1"><a aria-hidden="true" href="#cb27-1" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb27-2"><a aria-hidden="true" href="#cb27-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice_sample</span>(<span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 5
<br/>    name            height  mass hair_color skin_color
<br/>    &lt;chr&gt;            &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     
<br/>  1 Shmi Skywalker     163    NA black      fair      
<br/>  2 Raymus Antilles    188    79 brown      light     
<br/>  3 Jocasta Nu         167    NA white      fair</code></pre>
</div>
</div>
<p>Use <strong><code>replace = T</code></strong> for sampling with replacement.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb29"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb29-1"><a aria-hidden="true" href="#cb29-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">slice_sample</span>(<span className="at">n =</span> <span className="dv">3</span>, <span className="at">replace =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 5
<br/>    name           height  mass hair_color   skin_color
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;        &lt;chr&gt;     
<br/>  1 Wilhuff Tarkin    180    NA auburn, grey fair      
<br/>  2 Jar Jar Binks     196    66 none         orange    
<br/>  3 Quarsh Panaka     183    NA black        dark</code></pre>
</div>
</div>
</section>
</section>
</main>
</div>
</div>
)}