import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderLegendColorbar from '../tutorial/colorbar_heatmap.png';

export default function Ggplot2ReorderLegendColorbar(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Change Direction of Legend Colorbar in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains how to <strong>change the direction of legendary color bars</strong> of <strong><em>continuous</em> variables</strong>. It covers <em>two distinct topics</em>:</p>
<ul>
<li><a href="#reorient">physically reorient colorbar (e.g., upside-down)</a></li>
<li><a href="#invert_scale">invert the color scale (darker colors mean bigger values, or the opposite)</a></li>
</ul>
<blockquote className="blockquote">
<p><span className="highlightGreen">If you wish to reorder legend keys of <strong><em>discrete</em></strong> variables, check <Link to="../8-ggplot2-reorder-legend-keys">here</Link> instead.</span></p>
</blockquote>
<section className="level4" id="create-a-heatmap-with-continuous-colorbar">
<h4 className="anchored" data-anchor-id="create-a-heatmap-with-continuous-colorbar">Create a heatmap with continuous colorbar</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages </span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(reshape2) <span className="co"># to use the melt function</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>() <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">legend.text =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>)))</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a></span><br/>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a><span className="co"># create a demo heatmap</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>p <span className="ot">&lt;-</span> volcano <span className="sc">%&gt;%</span> </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">melt</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Var1, <span className="at">y =</span> Var2, <span className="at">fill =</span> value)) <span className="sc">+</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">geom_raster</span>() <span className="sc">+</span> <span className="co"># create heatmap</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>  <span className="co"># apply viridis colors palette B</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>) <span className="sc">+</span> </span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="co"># expand heatmap to fill up all plot area</span></span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span> </span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>  <span className="co"># elongate the colorbar</span></span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.key.height =</span> <span className="fu">unit</span>(<span className="dv">40</span>, <span className="st">"pt"</span>)) </span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderLegendColorbar} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="reorient">
<h4 className="anchored" data-anchor-id="reorient">Physically reordient the colorbar (e.g., upside-down)</h4>
<p>The code below physically flips the colorbar upside down, while retaining the same color scale; the association between colors and data values remain the same (i.e., <span><strong>large values</strong></span> are depicted in <span><strong>bright yellows</strong></span>, and <span><strong>small values</strong></span> in <span><strong>dark blues</strong></span>, same as the plot above).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(<span className="at">reverse =</span> T))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/colorbar_reorident_upside_down.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="invert_scale">
<h4 className="anchored" data-anchor-id="invert_scale">Invert the color scale</h4>
<p>Use the <code>direction</code> argument to <em>invert</em> the color scale, such that <span><strong>large values</strong></span> are mapped in <span><strong>dark blues</strong></span>, and <span><strong>small values</strong></span> in <span><strong>bright yellows</strong></span>. This drastically changes the appearance of the heatmap.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="co"># overwrite the "old" fill scale</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># The 'direction' argument takes a binary value of 1 or -1. </span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output cell-output-stderr">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">Scale for fill is already present.
<br/>Adding another scale for fill, which will replace the existing scale.</code></pre>
</div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/colorbar_invert_direction.png"/></p>
</figure>
</div>
</div>
</div>
<p>The <code>direction</code> argument also applies to the color scale using <Link to="../15-ggplot2-color-brewer-palette">brewer palettes</Link>:</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p <span className="sc">+</span> <span className="fu">scale_fill_distiller</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a></span><br/>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p <span className="sc">+</span> <span className="fu">scale_fill_distiller</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>, <span className="at">direction =</span> <span className="dv">1</span>)</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a></span><br/>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p1 <span className="sc">|</span> p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/colorbar_brewer.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}