import VerticalPosts from "./galleryPostsVertical"
import {useEffect} from 'react';
import RGalleryData from "../data/RGalleryData";


export default function GalleryIndexPage() {
    const description = "Learn awesome ggplot2 and data viz. tips with ready-to-use code and dataset"
    const title = "Unleash Your Potential with Data Visualization"
    useEffect(()=>{
        document.title = title
        var existingMetaTag = document.querySelector('meta[name="description"]');
            
        if (existingMetaTag) {
            // If it exists, update its content
            existingMetaTag.setAttribute('content', description);
        } else {
            document.title = 'R Gallery'
            var metaTag = document.createElement('meta');
            metaTag.setAttribute('name', 'description');
            metaTag.setAttribute('content', description);
            document.head.appendChild(metaTag);
        }
    }, [])
 

    return(
        <div className='main mg-t-80'>
            <div className='text-center'>
                <h1 className='fc-darkBlue fontfamily-freeman' style={{'lineHeight': '1'}}>{title}</h1>
                <h4 className='fc-light-grey' style={{'width':'50%', 'display':'inline-block', 'fontSize':'22px', 'marginTop':'10px'}}>
                    {description}
                </h4>
            </div>
  
            <div className="gallery-page mg-t-50">

            { RGalleryData.map((post, index) => (  
                <VerticalPosts {...post} />                   
                ))
            }
            </div>  
            <div className="empty-space"></div>
            <div className="empty-space"></div> 
        </div>        
               
    )

}