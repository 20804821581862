import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ExpandCombinationsVariablesPart3(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create All Possible Combinations of Selected Variables (3/3): Expand from <em>Vectors</em> with <code>expand_grid()</code></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Similar to <Link to="../expand-combinations-variables-part1"><code>expand()</code></Link> covered in the last two sections, <code>expand_grid()</code> also generates combinations but based on levels of <em>vectors</em> as input, instead of a dataset. It is very similar to the base R function <code>expand.grid()</code>, but with output carrying attributes of a <Link to="/R/data-wrangling/tibble/introduction">tibble</Link> dataset, and can expand over any generalized vectors, such as data frames and matrices, as shown below.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">expand_grid</span>(<span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>, <span className="at">y =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 6 × 2
<br/>      x     y
<br/>  &lt;int&gt; &lt;int&gt;
<br/>1     1     1
<br/>2     1     2
<br/>3     2     1
<br/>4     2     2
<br/>5     3     1
<br/>6     3     2</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">expand_grid</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>, <span className="st">"c"</span>), <span className="at">y =</span> <span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>, <span className="st">"C"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 9 × 2
<br/>  x     y    
<br/>  &lt;chr&gt; &lt;chr&gt;
<br/>1 a     A    
<br/>2 a     B    
<br/>3 a     C    
<br/>4 b     A    
<br/>5 b     B    
<br/>6 b     C    
<br/>7 c     A    
<br/>8 c     B    
<br/>9 c     C    </code></pre>
</div>
</div>
<p>You can use <code>expand_grid()</code> to expand data frames.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># expand data frames</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="fu">expand_grid</span>(<span className="fu">tibble</span>(<span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>, <span className="at">y =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>)), <span className="at">z =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 6 × 3
<br/>      x y         z
<br/>  &lt;int&gt; &lt;chr&gt; &lt;int&gt;
<br/>1     1 a         1
<br/>2     1 a         2
<br/>3     1 a         3
<br/>4     2 b         1
<br/>5     2 b         2
<br/>6     2 b         3</code></pre>
</div>
</div>
<p>This can be equivalently written using <code>expand()</code> and <code>nesting()</code> as covered in the <Link to="../expand-combinations-variables-part1">earlier section</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">tibble</span>(<span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>, <span className="at">y =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">expand</span>(<span className="fu">nesting</span>(x, y), <span className="at">z =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>)</span></code></pre></div>
</div>
<p>You can also use <code>expand_grid()</code> to expand matrices.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">expand_grid</span>(<span className="at">x1 =</span> <span className="fu">matrix</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>, <span className="at">nrow =</span> <span className="dv">2</span>), <span className="at">x2 =</span> <span className="fu">matrix</span>(<span className="dv">5</span><span className="sc">:</span><span className="dv">8</span>, <span className="at">nrow =</span> <span className="dv">2</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 2
<br/>  x1[,1]  [,2] x2[,1]  [,2]
<br/>   &lt;int&gt; &lt;int&gt;  &lt;int&gt; &lt;int&gt;
<br/>1      1     3      5     7
<br/>2      1     3      6     8
<br/>3      2     4      5     7
<br/>4      2     4      6     8</code></pre>
</div>
</div>
</main>
</div>
</div>
)}