import React from 'react'; 
import { Link } from 'react-router-dom';
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonDatastructurestuples(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Tuples">Tuples<a class="anchor-link" href="#Tuples">¶</a></h3><p>Tuples are similar to lists but are immutable, meaning their contents cannot be changed after creation. Tuples are created using parentheses <code>()</code> or the <code>tuple()</code> constructor.</p>
<p>Tuples are widely used in Python due to their performance advantage over lists, especially in situations where a fixed collection of items is needed.<br/><br/>
<strong>Properties of Tuples</strong></p>
<ul>
<li><strong>Immutable</strong>: Tuple's elements can't be modified after creation.</li>
<li><strong>Ordered</strong>: Tuples maintain the order of elements.</li>
<li><strong>Indexable</strong>: Elements can be accessed by their index.</li>
<li><strong>Iterable</strong>: Tuples can be used in loops.</li>
<li>Tuples can contain a mix of data types.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Example</strong></p>
<p>The tuple below contains integers, a string and a boolean value.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a tuple named sample_tuple</span></span>

<span><span class="n">sample_tuple</span> <span class="o">=</span> <span class="p">(</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">,</span> <span class="kc">True</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Indexing-and-Slicing-Tuples">Indexing and Slicing Tuples<a class="anchor-link" href="#Indexing-and-Slicing-Tuples">¶</a></h4><p>Similar to <Link to="../data-structure-lists">lists</Link>, tuples allow retrieving subsets through indexing or slicing.</p>
<h5 id="Indexing">Indexing<a class="anchor-link" href="#Indexing">¶</a></h5><p>Indexing involves retrieving a particular element by its index. For instance, to retrieve 'hello' from <code>sample_tuple</code>, you would use index 2. It's important to note that in Python, as with many programming languages, indexing starts from 0.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a tuple named sample_tuple</span></span>

<span><span class="n">sample_tuple</span> <span class="o">=</span> <span class="p">(</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">,</span> <span class="kc">True</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_tuple</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>(1, 2, 'hello', True)
</code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Example of accesing the first element of sample_tuple</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_tuple</span><span class="p">[</span><span class="mi">0</span><span class="p">])</span></span>


<br /><span><span class="c1"># Example of accessing the word 'hello' </span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_tuple</span><span class="p">[</span><span class="mi">2</span><span class="p">])</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>1
<br />hello
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>We can also use negative indexing which starts from the end, e.g. <code>sample_tuple[-1]</code> accesses the last element.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Access the last element of sample_tuple</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_tuple</span><span class="p">[</span><span class="o">-</span><span class="mi">1</span><span class="p">])</span></span>


<br /><span><span class="c1"># Access the word 'hello' using negative indexing</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_tuple</span><span class="p">[</span><span class="o">-</span><span class="mi">2</span><span class="p">])</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>True
<br />hello
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Slicing">Slicing<a class="anchor-link" href="#Slicing">¶</a></h5><p>Slicing refers to access a subset of a tuple. It's done using the colon <code>:</code> symbol, e.g. <code>sample_tuple[0:3]</code> retrieves the first three elements of the tuple. It's inclusive on the left and exclusive on the right.</p>
<p><strong>Syntax</strong>: <code>tuple[start : stop : step]</code></p>
<ul>
<li><strong>start</strong>: The starting index (inclusive)</li>
<li><strong>stop</strong>: The ending index (exclusive)</li>
<li><strong>step</strong>: The step size (optional).</li>
</ul>
<p>If the <strong>start</strong> or <strong>stop</strong> index is omitted, it defaults to the beginning or end of the tuple, respectively. E.g. <code>sample_tuple[:3]</code> is equivalent to <code>sample_tuple[0:3]</code>, and <code>sample_tuple[2:]</code> is the same as <code>sample_tuple[2:4]</code></p>
<p>The <strong>step</strong> parameter specifies intervals, so <code>sample_tuple[::2]</code> accesses every second element in the list.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Example of slicing a tuple</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a new list</span></span>

<span><span class="n">tuple_new</span> <span class="o">=</span> <span class="p">(</span><span class="mi">10</span><span class="p">,</span> <span class="mi">20</span><span class="p">,</span> <span class="mi">30</span><span class="p">,</span> <span class="mi">40</span><span class="p">,</span> <span class="mi">50</span><span class="p">,</span> <span class="mi">60</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s1">'tuple_new: '</span><span class="p">,</span> <span class="n">tuple_new</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>tuple_new:  (10, 20, 30, 40, 50, 60)
</code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># 1. Get elements from index 0 to 2</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">tuple_new</span><span class="p">[</span><span class="mi">0</span><span class="p">:</span><span class="mi">3</span><span class="p">])</span></span>


<br /><span><span class="c1"># 2. Get elements from index 1 to 4</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">tuple_new</span><span class="p">[</span><span class="mi">1</span><span class="p">:</span><span class="mi">5</span><span class="p">])</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>(10, 20, 30)
<br />(20, 30, 40, 50)
</code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [13]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># 3. Get every second element starting from index 0</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s1">'Get every second element starting from index 0:'</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">tuple_new</span><span class="p">[::</span><span class="mi">2</span><span class="p">])</span></span>


<br /><span><span class="nb">print</span><span class="p">(</span><span class="s1">'---------------------'</span><span class="p">)</span></span>


<br /><span><span class="c1"># 4. Get every second element starting from index 1</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s1">'Get every second element starting from index 1:'</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">tuple_new</span><span class="p">[</span><span class="mi">1</span><span class="p">::</span><span class="mi">2</span><span class="p">])</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Get every second element starting from index 0:
<br />(10, 30, 50)
<br />---------------------
<br />Get every second element starting from index 1:
<br />(20, 40, 60)
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Methods-of-Tuples">Methods of Tuples<a class="anchor-link" href="#Methods-of-Tuples">¶</a></h4><p>Tuples have fewer methods compared to lists because they are immutable. Therefore, tuples lack methods for adding, removing, or modifying existing elements. However, they still provide some commonly used methods.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="len()-Function"><code>len()</code> Function<a class="anchor-link" href="#len()-Function">¶</a></h5><p>The <code>len()</code> function returns the number of elements in a tuple.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [15]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a new list</span></span>

<span><span class="n">tuple_sample</span> <span class="o">=</span> <span class="p">(</span><span class="mi">10</span><span class="p">,</span> <span class="mi">20</span><span class="p">,</span> <span class="mi">30</span><span class="p">,</span> <span class="mi">40</span><span class="p">,</span> <span class="mi">50</span><span class="p">,</span> <span class="mi">60</span><span class="p">)</span></span>


<br /><span><span class="c1"># get the number of elements in the tuple</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s1">'the length of the tuple is'</span><span class="p">,</span> <span class="nb">len</span><span class="p">(</span><span class="n">tuple_sample</span><span class="p">))</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>the length of the tuple is 6
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="index()-Function"><code>index()</code> Function<a class="anchor-link" href="#index()-Function">¶</a></h5><p>The <code>index()</code> function finds the first occurrence of a specified value and returns its index.</p>
<p><strong>Syntax</strong>: <code>tuple.index(item)</code></p>
<ul>
<li><strong>item</strong>: item is the target value you want to find the index of in the tuple.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [14]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a tuple</span></span>

<span><span class="n">sample_tuple</span> <span class="o">=</span> <span class="p">[</span><span class="s1">'hello'</span><span class="p">,</span> <span class="s1">'world'</span><span class="p">,</span> <span class="s1">'this'</span><span class="p">,</span> <span class="s1">'is'</span><span class="p">,</span> <span class="s1">'my'</span><span class="p">,</span> <span class="s1">'first'</span><span class="p">,</span> <span class="s1">'line'</span><span class="p">,</span> <span class="s1">'of'</span><span class="p">,</span> <span class="s1">'code'</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">,</span> <span class="s1">'again'</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">]</span></span>


<br />
<br /><span><span class="c1"># Get the index of word 'first' in the tuple</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s1">'index of word "first" in the tuple: '</span><span class="p">,</span> <span class="n">sample_tuple</span><span class="o">.</span><span class="n">index</span><span class="p">(</span><span class="s1">'first'</span><span class="p">))</span></span>


<br /><span><span class="c1"># Get the index of word 'hello' in the tuple</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s1">'index of word "hello" in the tuple: '</span><span class="p">,</span> <span class="n">sample_tuple</span><span class="o">.</span><span class="n">index</span><span class="p">(</span><span class="s1">'hello'</span><span class="p">))</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>index of word "first" in the tuple:  5
<br />index of word "hello" in the tuple:  0
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The word "<strong>first</strong>" is the 6th word in the list, so its index is 5 since list index starts from 0.</p>
<p>Though the word "<strong>hello</strong>" appears three times in the tuple, the <code>index()</code> method only returns the index of the first occurrence of the word.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="count()-Function"><code>count()</code> Function<a class="anchor-link" href="#count()-Function">¶</a></h5><p>The <code>count()</code> function returns the number of times a specified value occurs in a tuple.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a tuple</span></span>

<span><span class="n">sample_tuple</span> <span class="o">=</span> <span class="p">(</span><span class="s1">'hello'</span><span class="p">,</span> <span class="s1">'world'</span><span class="p">,</span> <span class="s1">'this'</span><span class="p">,</span> <span class="s1">'is'</span><span class="p">,</span> <span class="s1">'my'</span><span class="p">,</span> <span class="s1">'first'</span><span class="p">,</span> <span class="s1">'line'</span><span class="p">,</span> <span class="s1">'of'</span><span class="p">,</span> <span class="s1">'code'</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">,</span> <span class="s1">'again'</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">)</span></span>


<br />
<br /><span><span class="c1"># Count how many times the word 'hello' appeared in the tuple</span></span>

<span><span class="n">sample_tuple</span><span class="o">.</span><span class="n">count</span><span class="p">(</span><span class="s1">'hello'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>3</span></code></pre></div>
</div>
</div>
</div>
</div>
</div>
)}