import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderLegendKeys from '../tutorial/legend_keys_barplot.png';

export default function Ggplot2ReorderLegendKeys(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Reorder Legend Keys and Labels in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains how to <strong>reorder the legendary keys and labels</strong> of <strong><em>categorical</em></strong> (<strong><em>discrete</em></strong>) variables in a legend.</p>
<blockquote className="blockquote">
<p><span className="highlightGreen">If you wish to reorder the legend <strong><em>colorbar</em></strong> of a <strong><em>continuous (numeric)</em></strong> variable, check <Link to="../9-ggplot2-reorder-legend-colorBar">here</Link> instead.</span></p>
</blockquote>
<section className="level4" id="start-with-a-barplot">
<h4 className="anchored" data-anchor-id="start-with-a-barplot">Start with a barplot</h4>
<p>In the following bar plot, the legend keys by default are arranged in the same order as the bars, based on the numeric-alphabetical order of the <code>class</code> levels.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages </span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set global default theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">14</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">30</span>, <span className="at">hjust =</span> <span className="dv">1</span>)))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="co"># create a bar plot</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>p <span className="ot">&lt;-</span> mpg <span className="sc">%&gt;%</span> </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> class, <span className="at">fill =</span> class)) <span className="sc">+</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">geom_bar</span>(<span className="at">alpha =</span> .<span className="dv">4</span>, <span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>)</span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderLegendKeys} /></p>
</figure>
</div>
</div>
</div>
<p>If you wish to reorder both the bars and the legend keys at the same time, check <Link to="../4-ggplot2-reorder-bars">this guide</Link>. The following tutorial instead illustrates how to <strong>reorder the legend keys independent of the bar order</strong>.</p>
</section>
<section className="level4" id="reverse-the-legend-key-order">
<h4 className="anchored" data-anchor-id="reverse-the-legend-key-order">Reverse the legend key order</h4>
<p>Use <code>guides()</code> to address non-data related outlook of scales (legends and axes). The <code>reverse</code> argument reverse the order of legend keys while retaining the same color scale (the color assignment to the car classes remains the same). As the legend is associated with the <code>fill</code> aesthetic, use <code>fill</code> in the guides syntax.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(<span className="at">reverse =</span> T))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_keys_guide_reverse.png"/></p>
</figure>
</div>
</div>
</div>
<p>Equivalently, the <code>guide</code> syntax can be put inside the <code>scale_*()</code> function. (This updated <code>scale_fill_brewer()</code> overwrites the earlier one, and brings a message reminder in the console).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_brewer</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="at">palette =</span> <span className="st">"Set1"</span>,</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="at">guide =</span> <span className="fu">guide_legend</span>(<span className="at">reverse =</span> T))</span></code></pre></div>
<div className="cell-output cell-output-stderr">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">Scale for fill is already present.
<br/>Adding another scale for fill, which will replace the existing scale.</code></pre>
</div>
</div>
</section>
<section className="level4" id="specify-any-particular-order-manually">
<h4 className="anchored" data-anchor-id="specify-any-particular-order-manually">Specify any particular order “manually”</h4>
<p>As the <code>fill</code> scale is <em>discrete</em>, the legend key order can be manually manipulated using the <code>limits</code> argument in the <code>scale_*()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># order of 'class' to be displayed in the legend</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>desired_order <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="st">"subcompact"</span>, <span className="st">"compact"</span>, <span className="st">"minivan"</span>, <span className="st">"midsize"</span>,</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="st">"2seater"</span>,  <span className="st">"pickup"</span>, <span className="st">"suv"</span>)</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a></span><br/>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>, <span className="at">limits =</span> desired_order)</span></code></pre></div>
<div className="cell-output cell-output-stderr">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">Scale for fill is already present.
<br/>Adding another scale for fill, which will replace the existing scale.</code></pre>
</div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_keys_scale_limits.png"/></p>
</figure>
</div>
</div>
</div>
<p>This same technique can be also used to reorder categorical axial levels. In the following plot, the bars are arranged by the specified order, while the legend keys are arranged separately in the default numeric-alphabetical order.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(<span className="at">limits =</span> desired_order)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_keys_axis.png"/></p>
</figure>
</div>
</div>
</div>
<hr/>
<h4 className="anchored">
<strong><em>Extended Reading — 🚀 one level up!</em></strong>
</h4>
<p>There are <strong>several important techniques related with aforementioned <code>scale_fill_brewer()</code></strong>:</p>
<ul>
<li><p>The fill scale in this tutorial employs the <Link to="../15-ggplot2-color-brewer-palette">brewer palettes</Link>. As the variable <code>class</code> mapped to <code>fill</code> is <em>categorical</em>, the <code>brewer</code> suffix is used (in place of the asterisk in <code>scale_fill_*</code>); if a <em>continuous</em> variable is mapped to <code>fill</code>, use suffix <code>distiller</code> instead.</p></li>
<li><p>When using the <Link to="../16-ggplot2-color-viridis-palette">viridis palettes</Link>, the syntax to reorder the legend keys remains similar:</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_viridis_d</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">limits =</span> desired_order)</span></code></pre></div>
</div>
<ul>
<li>If you wish to use the <em>default</em> fill scale, you may call <code>scale_fill_discrete()</code>:</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_discrete</span>(<span className="at">limits =</span> desired_order)</span></code></pre></div>
</div>
</section>
</main>
</div>
</div>
)}