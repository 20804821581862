import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function SelectColumns(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Select Columns from a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains how to select columns using the <code>select()</code> function. In addition, a range of related techniques will be demonstrated, which are helpful not only for <code>select()</code>, but also for many other functions of dplyr.</p>
<p>This tutorial covers the following four topics:</p>
<ul>
<li><a href="#basics">Basics of column selection</a></li>
<li><a href="#selection_helpers">Selection helper functions</a></li>
<li><a href="#operators">Select with operators</a></li>
<li><a href="#purrr_style">Select in <code>purrr</code> style with extra flexibility</a></li>
</ul>
<hr/>
<section className="level3" id="dataset">
<h3 className="anchored" data-anchor-id="dataset">Dataset</h3>
<p>We’ll use a subset (first five columns) of the <code>starwars</code> dataset for demonstration with ease of learning. The <code>starwars</code> dataset is built in the dplyr package.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>] <span className="co"># select first five columns</span></span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>starwars2</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass hair_color skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond      fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;       white, blue
<br/>  4 Darth Vader       202   136 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="basics">
<h3 className="anchored" data-anchor-id="basics">Basics of column selection</h3>
<p>Select columns with <strong><code>select()</code></strong>. Note that the column names are not quoted; you access the column name as if you are calling the name of an object or variable (a technique known as <Link to="../25-data-masking">data masking</Link> that makes typing easier for routine tasks).</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(name, height, skin_color)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    name           height skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;chr&gt;      
<br/>  1 Luke Skywalker    172 fair       
<br/>  2 C-3PO             167 gold       
<br/>  3 R2-D2              96 white, blue
<br/>  4 Darth Vader       202 white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Equivalently, you can also select column names inside the concatenation function <code>c()</code>, or use the corresponding column index number.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># put column names inside c() </span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="fu">c</span>(name, height, skin_color))</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a></span><br/>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a><span className="co"># select 1st, 2nd and 5th column</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">5</span>))</span></code></pre></div>
</div>
<p>Use the colon operator <strong><code>:</code></strong> to select <em>consecutive</em> columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="co"># select consecutive columns from "name" to "mass"</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(name<span className="sc">:</span>mass)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    name           height  mass
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt;
<br/>  1 Luke Skywalker    172    77
<br/>  2 C-3PO             167    75
<br/>  3 R2-D2              96    32
<br/>  4 Darth Vader       202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<section className="level5" id="negation_exclamation_exchangeable">
<h5 className="anchored" data-anchor-id="negation_exclamation_exchangeable"></h5>
<p>Use the negative operator <strong><code>-</code></strong> to remove specified columns from the dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># Remove "name" and "mass" columns. </span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="sc">-</span><span className="fu">c</span>(name, mass))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    height hair_color skin_color 
<br/>     &lt;int&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1    172 blond      fair       
<br/>  2    167 &lt;NA&gt;       gold       
<br/>  3     96 &lt;NA&gt;       white, blue
<br/>  4    202 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>In the above example, you can also use the logic negation operator <code>!</code> to exclude columns. However, the minus <code>-</code> and exclamation <code>!</code> are <em>not</em> always exchangeable (as in <a href="#negation_exclamation_not_exchangeable">this case</a>).</p>
</section>
</section>
<section className="level3" id="selection_helpers">
<h3 className="anchored" data-anchor-id="selection_helpers">Selection helpers functions</h3>
<p>Use <strong><code>starts_with()</code></strong> to select columns whose header names start with the specified prefix, and <strong><code>ends_with()</code></strong> to select columns ended with the specified suffix.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># select column "name" and columns with names ended with "color“</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(name, <span className="fu">ends_with</span>(<span className="st">"color"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    name           hair_color skin_color 
<br/>    &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker blond      fair       
<br/>  2 C-3PO          &lt;NA&gt;       gold       
<br/>  3 R2-D2          &lt;NA&gt;       white, blue
<br/>  4 Darth Vader    none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Use <strong><code>contains()</code></strong> to select columns whose names contain the specified strings.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="co"># select all columns whose name contain "eight"</span></span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="fu">contains</span>(<span className="st">"eight"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 1
<br/>    height
<br/>     &lt;int&gt;
<br/>  1    172
<br/>  2    167
<br/>  3     96
<br/>  4    202
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Use <strong><code>matches()</code></strong> to match column names using <Link to="/R/data-wrangling/regular-expression/0-introduction">regular expression</Link>. In the following example, we use <Link to="/R/data-wrangling/regular-expression/1-character-class">character class</Link> to specify the search pattern.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="co"># select all columns with names containing the letter "n" or "m". </span></span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="fu">matches</span>(<span className="st">"[nm]"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    name            mass skin_color 
<br/>    &lt;chr&gt;          &lt;dbl&gt; &lt;chr&gt;      
<br/>  1 Luke Skywalker    77 fair       
<br/>  2 C-3PO             75 gold       
<br/>  3 R2-D2             32 white, blue
<br/>  4 Darth Vader      136 white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Use <strong><code>num_range()</code></strong> to select columns whose names contain a numeric sequence. Here we demonstrate with <code>billboard2</code>, a subset of the <code>billboard</code> dataset from the <code>tidyr</code> package.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="co"># select 3rd, and 10-17th columns</span></span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>billboard2 <span className="ot">&lt;-</span> tidyr<span className="sc">::</span>billboard[, <span className="fu">c</span>(<span className="dv">3</span>, <span className="dv">10</span><span className="sc">:</span><span className="dv">16</span>)] </span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>billboard2</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 317 × 8
<br/>    date.entered   wk7   wk8   wk9  wk10  wk11  wk12  wk13
<br/>    &lt;date&gt;       &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 2000-02-26      99    NA    NA    NA    NA    NA    NA
<br/>  2 2000-09-02      NA    NA    NA    NA    NA    NA    NA
<br/>  3 2000-04-08      54    53    51    51    51    51    47
<br/>  4 2000-10-21      55    59    62    61    61    59    61
<br/>  # ℹ 313 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="co"># select the 1st column, and columns of wk9 to wk12</span></span>
<span id="cb18-2"><a aria-hidden="true" href="#cb18-2" tabindex="-1"></a>billboard2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="dv">1</span>, <span className="fu">num_range</span>(<span className="st">"wk"</span>, <span className="dv">9</span><span className="sc">:</span><span className="dv">12</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 317 × 5
<br/>    date.entered   wk9  wk10  wk11  wk12
<br/>    &lt;date&gt;       &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 2000-02-26      NA    NA    NA    NA
<br/>  2 2000-09-02      NA    NA    NA    NA
<br/>  3 2000-04-08      51    51    51    51
<br/>  4 2000-10-21      62    61    61    59
<br/>  # ℹ 313 more rows</code></pre>
</div>
</div>
<p><strong><code>where()</code></strong> takes a function as its input. This input function returns a TRUE or FALSE for each column, and columns judged as TRUE will be selected. Note that inside <code>where()</code>, the function does not carry any parenthesis. In the following example, we select columns that are a specific type; similar functions include <code>is.factor()</code>, <code>is.numeric()</code>, and <code>is.integer()</code>, etc.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb20"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb20-1"><a aria-hidden="true" href="#cb20-1" tabindex="-1"></a><span className="co"># test if a column is a "character" variable</span></span>
<span id="cb20-2"><a aria-hidden="true" href="#cb20-2" tabindex="-1"></a><span className="co"># the output of the function is a "logic" vector </span></span>
<span id="cb20-3"><a aria-hidden="true" href="#cb20-3" tabindex="-1"></a><span className="fu">is.character</span>(starwars2<span className="sc">$</span>name)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] TRUE</code></pre>
</div>
<div className="sourceCode cell-code" id="cb22"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb22-1"><a aria-hidden="true" href="#cb22-1" tabindex="-1"></a><span className="co"># select only "character" columns </span></span>
<span id="cb22-2"><a aria-hidden="true" href="#cb22-2" tabindex="-1"></a><span className="co"># note that there are no parentheses in the input function</span></span>
<span id="cb22-3"><a aria-hidden="true" href="#cb22-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="fu">where</span>(is.character))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    name           hair_color skin_color 
<br/>    &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker blond      fair       
<br/>  2 C-3PO          &lt;NA&gt;       gold       
<br/>  3 R2-D2          &lt;NA&gt;       white, blue
<br/>  4 Darth Vader    none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="operators">
<h3 className="anchored" data-anchor-id="operators">Select with operators</h3>
<p>Logical operators such as AND <code>&amp;</code>, OR <code>|</code> , and negation <code>!</code>, are frequently used in <code>select()</code> and <Link to="../3-filter-rows"><code>filter()</code></Link>. Below are several examples demonstrating their use.</p>
<ul>
<li>Select columns that are simultaneously (1) “character” variables, and (2) ended with “color” in the column name. Both criteria must be met, as noted by the intersection operator <code>&amp;</code>.</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb24"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb24-1"><a aria-hidden="true" href="#cb24-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb24-2"><a aria-hidden="true" href="#cb24-2" tabindex="-1"></a>  <span className="fu">select</span>(<span className="fu">where</span>(is.character) <span className="sc">&amp;</span> <span className="fu">ends_with</span>(<span className="st">"color"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 2
<br/>    hair_color skin_color 
<br/>    &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 blond      fair       
<br/>  2 &lt;NA&gt;       gold       
<br/>  3 &lt;NA&gt;       white, blue
<br/>  4 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<ul>
<li>Select columns that are either a “numeric” variable(including both the “integer” and “double” type), <em>or</em> contains “skin” in the column name, noted by the union (“or”) operator <code>|</code>.</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb26"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb26-1"><a aria-hidden="true" href="#cb26-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb26-2"><a aria-hidden="true" href="#cb26-2" tabindex="-1"></a>  <span className="fu">select</span>(<span className="fu">where</span>(is.numeric) <span className="sc">|</span> <span className="fu">contains</span>(<span className="st">"skin"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 3
<br/>    height  mass skin_color 
<br/>     &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      
<br/>  1    172    77 fair       
<br/>  2    167    75 gold       
<br/>  3     96    32 white, blue
<br/>  4    202   136 white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<section className="level5" id="negation_exclamation_not_exchangeable">
<h5 className="anchored" data-anchor-id="negation_exclamation_not_exchangeable"></h5>
<ul>
<li>Rearrange the order of columns: first select column “name”, and then columns ended with “color”, and finally those <em>not</em> ended with “color”. Note that the <em>NOT</em> is expressed via the logic negation operator <code>!</code>, instead of the column removal operator <code>-</code>. In some cases though these two operators are exchangeable, as in <a href="#negation_exclamation_exchangeable">this example</a>.</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb28"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb28-1"><a aria-hidden="true" href="#cb28-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb28-2"><a aria-hidden="true" href="#cb28-2" tabindex="-1"></a>  <span className="fu">select</span>(name, <span className="fu">ends_with</span>(<span className="st">"color"</span>), <span className="sc">!</span><span className="fu">ends_with</span>(<span className="st">"color"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           hair_color skin_color  height  mass
<br/>    &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;        &lt;int&gt; &lt;dbl&gt;
<br/>  1 Luke Skywalker blond      fair           172    77
<br/>  2 C-3PO          &lt;NA&gt;       gold           167    75
<br/>  3 R2-D2          &lt;NA&gt;       white, blue     96    32
<br/>  4 Darth Vader    none       white          202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
</section>
<section className="level3" id="purrr_style">
<h3 className="anchored" data-anchor-id="purrr_style">Select in <code>purrr</code> style with extra flexibility</h3>
<h4 className="anchored">
🏆 <strong><em>expert level skill</em></strong>
</h4>
<p>You can write <code>where()</code> in a <Link to="/R/data-wrangling/purrr/introduction">purrr</Link> style for more efficient feature selection. Consider the following script.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb30"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb30-1"><a aria-hidden="true" href="#cb30-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(<span className="fu">where</span>(is.numeric))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 2
<br/>    height  mass
<br/>     &lt;int&gt; &lt;dbl&gt;
<br/>  1    172    77
<br/>  2    167    75
<br/>  3     96    32
<br/>  4    202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>The above line can be equivalently written with an anonymous function (lambda function) defined on the fly, that checks whether each column (noted as <code>x</code> in the anonymous function) is numeric.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb32"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb32-1"><a aria-hidden="true" href="#cb32-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb32-2"><a aria-hidden="true" href="#cb32-2" tabindex="-1"></a>  <span className="fu">select</span>(<span className="fu">where</span>( <span className="cf">function</span>(x) <span className="fu">is.numeric</span>(x) ))</span></code></pre></div>
</div>
<p>Alternatively, you can use tilde <code>~</code> to create the anonymous function, where each column is represented by <code>.x</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb33"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb33-1"><a aria-hidden="true" href="#cb33-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb33-2"><a aria-hidden="true" href="#cb33-2" tabindex="-1"></a>  <span className="fu">select</span>(<span className="fu">where</span>(<span className="sc">~</span> <span className="fu">is.numeric</span>(.x) ))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 2
<br/>    height  mass
<br/>     &lt;int&gt; &lt;dbl&gt;
<br/>  1    172    77
<br/>  2    167    75
<br/>  3     96    32
<br/>  4    202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>This format allows for flexible addition of more selection criteria. In the script below, numeric columns are selected only if the selected variable has a mean value above 100. <code>&amp;&amp;</code> is used to connect the two criteria. (use <code>na.rm = T</code> to remove any <code>NA</code> values when calculating the mean)</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb35"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb35-1"><a aria-hidden="true" href="#cb35-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">select</span>(</span>
<span id="cb35-2"><a aria-hidden="true" href="#cb35-2" tabindex="-1"></a>  name,</span>
<span id="cb35-3"><a aria-hidden="true" href="#cb35-3" tabindex="-1"></a>  <span className="fu">where</span>(<span className="sc">~</span> <span className="fu">is.numeric</span>(.x) <span className="sc">&amp;&amp;</span> <span className="fu">mean</span>(.x, <span className="at">na.rm =</span> T) <span className="sc">&gt;</span> <span className="dv">100</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 2
<br/>    name           height
<br/>    &lt;chr&gt;           &lt;int&gt;
<br/>  1 Luke Skywalker    172
<br/>  2 C-3PO             167
<br/>  3 R2-D2              96
<br/>  4 Darth Vader       202
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>In addition to the selection helpers mentioned above, <Link to="../14-repeated-operations-on-multiple-column"><code>across()</code></Link> is another powerful helper function that enables repeated operations across multiple columns, and is frequently used together with <Link to="../4-mutate-columns"><code>mutate()</code></Link> and <Link to="../5-summarize"><code>summarize()</code></Link>. We’ll introduce this more advanced technique in a later section.</p>
</section>
</main>
</div>
</div>
)}