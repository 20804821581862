import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react";
import BookGgplot2 from './media/ggplot2 book cover_compressed.png'
import BookGgplot2Webp from './media/book-cover.webp'
import starSvg from "./media/start-yellow-border.svg" 

export function Adsggplot() {
    const location = useLocation();
    const [key, setKey] = useState(0);

    // book stars
    const stars = []
    for (let i = 0; i < 5; i++) {
        stars.push(
            <img 
                key={i}
                id='bookstar'
                src={`${starSvg}?key=${key}`}
                alt='star'
            />
        );
    }
  
    // Use a unique key to force image reload when content changes
    useEffect(() => {
      setKey((prevKey) => prevKey + 1);
    }, [location]);

    return (
        <div className="ads-right mg-auto" style={{}} >
            <Link to='/R/eBook/flash-to-r-ggplot2-mastery'>
                <h5 className='' style={{color:'#379aa4'}}>Amazing eBook to learn ggplot2 FAST & EASY</h5>
                {/* <img id='ggplo2-book' src={`https://s3.amazonaws.com/articles.rtistrystudio/cover_images/ggplot2_book_cover_portrait.png?key=${key}`} alt='book cover for sliding your way to ggplot2 mastery'></img> */}
                <picture>
                    <source type="image/webp" srcset={BookGgplot2Webp} />
                    <img id='ggplot2-book' className='book-img' src={`${BookGgplot2}?key=${key}`} alt='book cover for sliding your way to ggplot2 mastery'></img>
                </picture>
            </Link>
            <div className="book-star">
                {stars}
            </div>
        </div>     
    )
    
}

export function AdsggplotForBlog(){
    const location = useLocation();
    const [key, setKey] = useState(0);

    // book stars
    const stars = []
    for (let i = 0; i < 5; i++) {
        stars.push(
            <img 
                key={i}
                id='bookstar'
                src={`${starSvg}?key=${key}`}
                alt='star'
            />
        );
    }
  
    // Use a unique key to force image reload when content changes
    useEffect(() => {
      setKey((prevKey) => prevKey + 1);
    }, [location]);

    return (
        <div className="ads-container">
            <Link to='/R/eBook/flash-to-r-ggplot2-mastery'>
                <h5 className='' style={{color:'#379aa4'}}>Amazing eBook to learn ggplot2 FAST & EASY</h5>
                {/* <img id='ggplo2-book' src={`https://s3.amazonaws.com/articles.rtistrystudio/cover_images/ggplot2_book_cover_portrait.png?key=${key}`} alt='book cover for sliding your way to ggplot2 mastery'></img> */}
                <picture>
                    <source type="image/webp" srcset={BookGgplot2Webp} />
                    <img id='ggplot2-book' className='book-img' src={`${BookGgplot2}?key=${key}`} alt='book cover for sliding your way to ggplot2 mastery'></img>
                </picture>
            </Link>
            <div className="book-star">
                {stars}
            </div>
        </div>     
    )
}

export function AdsHorizontalGgplot() {
    const location = useLocation();
    const [key, setKey] = useState(0);

    // book stars
    const stars = []
    for (let i = 0; i < 5; i++) {
        stars.push(
            <img 
                key={i}
                id='bookstar'
                src={`${starSvg}?key=${key}`}
                alt='star'
            />
        );
    }
  
    // Use a unique key to force image reload when content changes
    useEffect(() => {
      setKey((prevKey) => prevKey + 1);
    }, [location]);
    return (
        <div className="ads-right" >
            <h4 className='mg-b-5'>Amazing ebook to learn ggplot2 FAST & EASY</h4>
            {/* replace the img src with another horizontal image */}
            <Link to='/R/eBook/flash-to-r-ggplot2-mastery'>
            <img id='ggplo2-book' src={`${BookGgplot2}?key=${key}`} alt='book cover for sliding your way to ggplot2 mastery'></img>
            <h4>Book Price</h4>   
            </Link>
            <div className="book-star">
                {stars}
            </div>
  
        </div>     
    )
}