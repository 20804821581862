import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LineSlopeplot from '../graphics/slopePlot_patchwork_completed.png'; 
import imgGgplot2LineSlopeplotWebp from '../graphics/slopePlot_patchwork_completed.webp'; 
export default function Ggplot2LineSlopeplot(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Temporal Changes of Multiple Groups Using Slope Graph in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2LineSlopeplotWebp} />
    <img className="cover-img" src={imgGgplot2LineSlopeplot} />
  </picture>

<p>Slopegraphs are minimalist and efficient presentations of your data that can simultaneously convey the relative rankings, the actual numeric values and group labels, and the changes and directionality of the data over time.</p>
<p>The flagship function<code>newggslopegraph()</code> of the <Link to="https://ibecav.github.io/CGPfunctionsb/"><strong>CGPfunctions</strong></Link> package by <Link to="https://github.com/ibecav">Chuck Powell</Link> is a wrapper of ggplot2 to create such elegant slopegraphs. It takes a <code>dataframe</code> as input, with three named columns for arguments <code>Times</code> (mapped to the <code>x</code>), <code>Measurement</code> (the <code>y</code>), and <code>Grouping</code> (the <code>color</code>), to draw the plot.</p>
<p>Below we’ll create four plots to demonstrate this useful function.</p>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="visualize-rate-of-cancer-survival-over-years">
<h3 className="anchored" data-anchor-id="visualize-rate-of-cancer-survival-over-years">Visualize rate of cancer survival over years</h3>
<p>In this example, we’ll use the <code>newcancer</code> dataset built in the CGPfunctions package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyverse)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(CGPfunctions)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">as_tibble</span>(newcancer)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 96 × 3
<br/>  Year    Type     Survival
<br/>  &lt;ord&gt;   &lt;fct&gt;       &lt;dbl&gt;
<br/>1 5 Year  Prostate       99
<br/>2 10 Year Prostate       95
<br/>3 15 Year Prostate       87
<br/>4 20 Year Prostate       81
<br/>5 5 Year  Thyroid        96
<br/># ℹ 91 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> <span className="fu">newggslopegraph</span>(newcancer,  <span className="co"># dataframe</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>                Year,       <span className="co"># Times, the 'x'</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>                Survival,   <span className="co"># Measurement, the 'y'</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>                Type,       <span className="co"># Grouping, the 'color'</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>                <span className="at">Title =</span> <span className="st">"the Rate of Cancer Survival"</span>,</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>                <span className="co"># remove the default subtitle and caption</span></span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>                <span className="at">SubTitle =</span> <span className="cn">NULL</span>, <span className="at">Caption =</span> <span className="cn">NULL</span>) </span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/slopePlot_cancer_survival_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/slopePlot_cancer_survival_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="visualize-the-increase-of-life-expectancy-in-europe">
<h3 className="anchored" data-anchor-id="visualize-the-increase-of-life-expectancy-in-europe">Visualize the increase of life expectancy in Europe</h3>
<p>In this graphic, we use the dataset <code>gapminder</code> built in the <strong>gapminder</strong> package. Here we’ll demonstrate two more aspects of the <code>newggslopegraph()</code> function:</p>
<ul>
<li>The variable mapped to the <code>x</code>-axis (the <code>Times</code> argument) must be an <em>ordered factor</em>. (Setting ordered factor is also the key to <Link to="/R/visualization/4-ggplot2-reorder-bars">rearrange graphic elements</Link> in ggplot2, such as bars, facet plots, legend keys, etc.)</li>
<li>As the output is a ggplot2 object, generic functions of ggplot2 can be subsequently added for continued plot customization.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">library</span>(gapminder)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a></span><br/>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">filter</span>(continent <span className="sc">%in%</span> <span className="st">"Europe"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">seq</span>(<span className="dv">1952</span>, <span className="dv">2007</span>, <span className="dv">10</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="co"># convert the x-axis variable (the 'Times' argument) to ordered factor</span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">factor</span>(year, <span className="at">ordered =</span> T),</span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>         <span className="at">lifeExp =</span> <span className="fu">round</span>(lifeExp))</span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a></span><br/>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a>g</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 180 × 6
<br/>  country continent year  lifeExp     pop gdpPercap
<br/>  &lt;fct&gt;   &lt;fct&gt;     &lt;ord&gt;   &lt;dbl&gt;   &lt;int&gt;     &lt;dbl&gt;
<br/>1 Albania Europe    1952       55 1282697     1601.
<br/>2 Albania Europe    1962       65 1728137     2313.
<br/>3 Albania Europe    1972       68 2263554     3313.
<br/>4 Albania Europe    1982       70 2780097     3631.
<br/>5 Albania Europe    1992       72 3326498     2497.
<br/># ℹ 175 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="co"># create colors as many as the number of countries in Europe</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">colorRampPalette</span>(<span className="fu">brewer.pal</span>(<span className="dv">11</span>, <span className="st">"Spectral"</span>))(<span className="fu">n_distinct</span>(g<span className="sc">$</span>country))</span></code></pre></div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># create slope plot</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">newggslopegraph</span>(year, lifeExp, country, </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>                  <span className="at">DataTextSize =</span> <span className="dv">3</span>, <span className="co"># size of the text (life expectancy)</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>                  <span className="at">LineColor =</span> myColors, </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>                  <span className="at">WiderLabels =</span> T) <span className="sc">+</span> </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="co"># add verticle lines to mark the years</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">color =</span> <span className="st">"snow2"</span>) <span className="sc">+</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="co"># remove plot and axis titles </span></span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="cn">NULL</span>, <span className="at">subtitle =</span> <span className="cn">NULL</span>, <span className="at">caption =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="co"># annotate with plot title at graphic bottom right corner </span></span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Life Expectancy</span><span className="sc">\n</span><span className="st">In Europe"</span>,</span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>           <span className="co"># x-axis registered as 1, 2, 3, etc. under the hood</span></span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">5.3</span>, <span className="at">y =</span> <span className="dv">48</span>, </span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">8</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"snow4"</span>) </span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/slopePlot_life_expectancy_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/slopePlot_life_expectancy_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="visualize-johnsonjohnsons-quarterly-sales">
<h3 className="anchored" data-anchor-id="visualize-johnsonjohnsons-quarterly-sales">Visualize JohnsonJohnson’s quarterly sales</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># install.packages("TSstudio")</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(TSstudio)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a></span><br/>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>J <span className="ot">&lt;-</span> JohnsonJohnson <span className="sc">%&gt;%</span> </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  TSstudio<span className="sc">::</span><span className="fu">ts_reshape</span>() <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>quarter, <span className="at">names_to =</span> <span className="st">"year"</span>, <span className="at">values_to =</span> <span className="st">"sales"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">quarter =</span> <span className="fu">paste0</span>(<span className="st">"Q"</span>, quarter) <span className="sc">%&gt;%</span> <span className="fu">factor</span>(<span className="at">ordered =</span> T),</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>         <span className="at">sales =</span> <span className="fu">round</span>(sales, <span className="dv">1</span>))</span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>J</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 84 × 3
<br/>  quarter year  sales
<br/>  &lt;ord&gt;   &lt;chr&gt; &lt;dbl&gt;
<br/>1 Q1      1960    0.7
<br/>2 Q1      1961    0.6
<br/>3 Q1      1962    0.7
<br/>4 Q1      1963    0.8
<br/>5 Q1      1964    0.9
<br/># ℹ 79 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># create colors as many as the number of years</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>myColors2 <span className="ot">&lt;-</span> </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">colorRampPalette</span>(<span className="fu">brewer.pal</span>(<span className="dv">9</span>, <span className="st">"YlGn"</span>))(<span className="fu">n_distinct</span>(g<span className="sc">$</span>country))</span></code></pre></div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> J <span className="sc">%&gt;%</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">newggslopegraph</span>(</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>    <span className="at">Times =</span> quarter, </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>    <span className="at">Measurement =</span> sales, </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>    <span className="at">Grouping =</span> year,</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>    <span className="at">DataTextSize =</span> <span className="dv">3</span>, </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>    <span className="at">LineColor =</span> myColors2</span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>  ) <span className="sc">+</span></span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Johnson &amp; Johnson Earnings ($) per share"</span>,</span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="cn">NULL</span>,</span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>    <span className="at">caption =</span> <span className="st">"Shumway, R. H. and Stoffer, D. S. (2000) </span></span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a><span className="st">       Time Series Analysis and its Applications.Second Edition. Springer"</span>)</span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a></span><br/>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/slopePlot_JohnsonJohnson_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/slopePlot_JohnsonJohnson_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<section className="level4" id="visualize-indometh-pharmacokinetics">
<h4 className="anchored" data-anchor-id="visualize-indometh-pharmacokinetics">Visualize indometh pharmacokinetics</h4>
<p>This plot shows how quickly the drug is cleared away from blood after intravenous administration into six subjects.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">as.tibble</span>(Indometh)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 66 × 3
<br/>  Subject  time  conc
<br/>  &lt;ord&gt;   &lt;dbl&gt; &lt;dbl&gt;
<br/>1 1        0.25  1.5 
<br/>2 1        0.5   0.94
<br/>3 1        0.75  0.78
<br/>4 1        1     0.48
<br/>5 1        1.25  0.37
<br/># ℹ 61 more rows</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> Indometh <span className="sc">%&gt;%</span> </span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">time =</span> <span className="fu">factor</span>(time, <span className="at">ordered =</span> T)) <span className="sc">%&gt;%</span> </span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>  <span className="fu">newggslopegraph</span>(time, conc, Subject) <span className="sc">+</span></span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="cn">NULL</span>, <span className="at">subtitle =</span> <span className="cn">NULL</span>, <span className="at">caption =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>  <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">name =</span> <span className="st">"Time (h)"</span>) <span className="sc">+</span> </span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">3</span>, .<span className="dv">5</span>), </span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"mcg/mL"</span>) <span className="sc">+</span></span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Pharmacokinetics</span><span className="sc">\n</span><span className="st">of indometacin"</span>,</span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">7</span>, <span className="at">y =</span> <span className="fl">1.8</span>, <span className="at">size =</span> <span className="dv">8</span>, <span className="at">color =</span> <span className="st">"snow4"</span>, </span>
<span id="cb14-13"><a aria-hidden="true" href="#cb14-13" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb14-14"><a aria-hidden="true" href="#cb14-14" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/slopePlot_Indometh_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/slopePlot_Indometh_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Combine all plots together.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a></span><br/>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a><span className="co"># create a function adding plot margin around the plots</span></span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>f <span className="ot">&lt;-</span> <span className="cf">function</span>(p)&#123;</span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>  p <span className="ot">&lt;-</span> p <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>)))</span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>  <span className="fu">return</span>(p)</span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>&#125;</span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a></span><br/>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a><span className="co"># combine the plots</span></span>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>( <span className="fu">f</span>(p1) <span className="sc">|</span> <span className="fu">f</span>(p2) ) <span className="sc">/</span> ( <span className="fu">f</span>(p3) <span className="sc">|</span> <span className="fu">f</span>(p4) )</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/slopePlot_patchwork_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/slopePlot_patchwork_completed"/>
  </picture>
</figure>
</div>
</div>
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="co"># save the combined plots</span></span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"slopePlot_completed.png"</span>,</span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>       <span className="at">height =</span> <span className="dv">10</span>, <span className="at">width =</span> <span className="dv">11</span>)</span></code></pre></div>
</div>
</section>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="co"># e.g. 1. Visualize cancer survival rate</span></span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a><span className="fu">library</span>(tidyverse)</span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a><span className="fu">library</span>(CGPfunctions)</span>
<span id="cb17-4"><a aria-hidden="true" href="#cb17-4" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer)</span>
<span id="cb17-5"><a aria-hidden="true" href="#cb17-5" tabindex="-1"></a></span><br/>
<span id="cb17-6"><a aria-hidden="true" href="#cb17-6" tabindex="-1"></a><span className="fu">as_tibble</span>(newcancer)</span>
<span id="cb17-7"><a aria-hidden="true" href="#cb17-7" tabindex="-1"></a></span><br/>
<span id="cb17-8"><a aria-hidden="true" href="#cb17-8" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> <span className="fu">newggslopegraph</span>(newcancer,  <span className="co"># dataframe</span></span>
<span id="cb17-9"><a aria-hidden="true" href="#cb17-9" tabindex="-1"></a>                Year,       <span className="co"># Times, the 'x'</span></span>
<span id="cb17-10"><a aria-hidden="true" href="#cb17-10" tabindex="-1"></a>                Survival,   <span className="co"># Measurement, the 'y'</span></span>
<span id="cb17-11"><a aria-hidden="true" href="#cb17-11" tabindex="-1"></a>                Type,       <span className="co"># Grouping, the 'color'</span></span>
<span id="cb17-12"><a aria-hidden="true" href="#cb17-12" tabindex="-1"></a>                <span className="at">Title =</span> <span className="st">"the Rate of Cancer Survival"</span>,</span>
<span id="cb17-13"><a aria-hidden="true" href="#cb17-13" tabindex="-1"></a>                <span className="co"># remove the default subtitle and caption</span></span>
<span id="cb17-14"><a aria-hidden="true" href="#cb17-14" tabindex="-1"></a>                <span className="at">SubTitle =</span> <span className="cn">NULL</span>, <span className="at">Caption =</span> <span className="cn">NULL</span>) </span>
<span id="cb17-15"><a aria-hidden="true" href="#cb17-15" tabindex="-1"></a>p1</span>
<span id="cb17-16"><a aria-hidden="true" href="#cb17-16" tabindex="-1"></a></span><br/>
<span id="cb17-17"><a aria-hidden="true" href="#cb17-17" tabindex="-1"></a></span><br/>
<span id="cb17-18"><a aria-hidden="true" href="#cb17-18" tabindex="-1"></a><span className="co"># ---------------------------------------------------------------</span></span>
<span id="cb17-19"><a aria-hidden="true" href="#cb17-19" tabindex="-1"></a></span><br/>
<span id="cb17-20"><a aria-hidden="true" href="#cb17-20" tabindex="-1"></a></span><br/>
<span id="cb17-21"><a aria-hidden="true" href="#cb17-21" tabindex="-1"></a><span className="co"># e.g.2 Visualize increase of life expectancy in Europe  </span></span>
<span id="cb17-22"><a aria-hidden="true" href="#cb17-22" tabindex="-1"></a><span className="fu">library</span>(gapminder)</span>
<span id="cb17-23"><a aria-hidden="true" href="#cb17-23" tabindex="-1"></a></span><br/>
<span id="cb17-24"><a aria-hidden="true" href="#cb17-24" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb17-25"><a aria-hidden="true" href="#cb17-25" tabindex="-1"></a>  <span className="fu">filter</span>(continent <span className="sc">%in%</span> <span className="st">"Europe"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb17-26"><a aria-hidden="true" href="#cb17-26" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">seq</span>(<span className="dv">1952</span>, <span className="dv">2007</span>, <span className="dv">10</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb17-27"><a aria-hidden="true" href="#cb17-27" tabindex="-1"></a>  <span className="co"># convert the x-axis variable (the 'Times' argument) to ordered factor</span></span>
<span id="cb17-28"><a aria-hidden="true" href="#cb17-28" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">factor</span>(year, <span className="at">ordered =</span> T),</span>
<span id="cb17-29"><a aria-hidden="true" href="#cb17-29" tabindex="-1"></a>         <span className="at">lifeExp =</span> <span className="fu">round</span>(lifeExp))</span>
<span id="cb17-30"><a aria-hidden="true" href="#cb17-30" tabindex="-1"></a>g</span>
<span id="cb17-31"><a aria-hidden="true" href="#cb17-31" tabindex="-1"></a></span><br/>
<span id="cb17-32"><a aria-hidden="true" href="#cb17-32" tabindex="-1"></a><span className="co"># create colors as many as the number of countries in Europe</span></span>
<span id="cb17-33"><a aria-hidden="true" href="#cb17-33" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> </span>
<span id="cb17-34"><a aria-hidden="true" href="#cb17-34" tabindex="-1"></a>  <span className="fu">colorRampPalette</span>(<span className="fu">brewer.pal</span>(<span className="dv">11</span>, <span className="st">"Spectral"</span>))(<span className="fu">n_distinct</span>(g<span className="sc">$</span>country))</span>
<span id="cb17-35"><a aria-hidden="true" href="#cb17-35" tabindex="-1"></a></span><br/>
<span id="cb17-36"><a aria-hidden="true" href="#cb17-36" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb17-37"><a aria-hidden="true" href="#cb17-37" tabindex="-1"></a>  <span className="co"># create slope plot</span></span>
<span id="cb17-38"><a aria-hidden="true" href="#cb17-38" tabindex="-1"></a>  <span className="fu">newggslopegraph</span>(year, lifeExp, country, </span>
<span id="cb17-39"><a aria-hidden="true" href="#cb17-39" tabindex="-1"></a>                  <span className="at">DataTextSize =</span> <span className="dv">3</span>, <span className="co"># size of the text (life expectancy)</span></span>
<span id="cb17-40"><a aria-hidden="true" href="#cb17-40" tabindex="-1"></a>                  <span className="at">LineColor =</span> myColors, </span>
<span id="cb17-41"><a aria-hidden="true" href="#cb17-41" tabindex="-1"></a>                  <span className="at">WiderLabels =</span> T) <span className="sc">+</span> </span>
<span id="cb17-42"><a aria-hidden="true" href="#cb17-42" tabindex="-1"></a>  <span className="co"># add verticle lines to mark the years</span></span>
<span id="cb17-43"><a aria-hidden="true" href="#cb17-43" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">color =</span> <span className="st">"snow2"</span>) <span className="sc">+</span></span>
<span id="cb17-44"><a aria-hidden="true" href="#cb17-44" tabindex="-1"></a>  <span className="co"># remove plot and axis titles </span></span>
<span id="cb17-45"><a aria-hidden="true" href="#cb17-45" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="cn">NULL</span>, <span className="at">subtitle =</span> <span className="cn">NULL</span>, <span className="at">caption =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb17-46"><a aria-hidden="true" href="#cb17-46" tabindex="-1"></a>  <span className="co"># annotate with plot title at graphic bottom right corner </span></span>
<span id="cb17-47"><a aria-hidden="true" href="#cb17-47" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb17-48"><a aria-hidden="true" href="#cb17-48" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Life Expectancy</span><span className="sc">\n</span><span className="st">In Europe"</span>,</span>
<span id="cb17-49"><a aria-hidden="true" href="#cb17-49" tabindex="-1"></a>           <span className="co"># x-axis registered as 1, 2, 3, etc. under the hood</span></span>
<span id="cb17-50"><a aria-hidden="true" href="#cb17-50" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">5.3</span>, <span className="at">y =</span> <span className="dv">48</span>, </span>
<span id="cb17-51"><a aria-hidden="true" href="#cb17-51" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">8</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"snow4"</span>) </span>
<span id="cb17-52"><a aria-hidden="true" href="#cb17-52" tabindex="-1"></a>p2</span>
<span id="cb17-53"><a aria-hidden="true" href="#cb17-53" tabindex="-1"></a></span><br/>
<span id="cb17-54"><a aria-hidden="true" href="#cb17-54" tabindex="-1"></a></span><br/>
<span id="cb17-55"><a aria-hidden="true" href="#cb17-55" tabindex="-1"></a><span className="co"># ---------------------------------------------------------------</span></span>
<span id="cb17-56"><a aria-hidden="true" href="#cb17-56" tabindex="-1"></a></span><br/>
<span id="cb17-57"><a aria-hidden="true" href="#cb17-57" tabindex="-1"></a></span><br/>
<span id="cb17-58"><a aria-hidden="true" href="#cb17-58" tabindex="-1"></a><span className="co"># e.g.3 visualize JohnsonJohnson's quarterly sales </span></span>
<span id="cb17-59"><a aria-hidden="true" href="#cb17-59" tabindex="-1"></a></span><br/>
<span id="cb17-60"><a aria-hidden="true" href="#cb17-60" tabindex="-1"></a><span className="co"># install.packages("TSstudio")</span></span>
<span id="cb17-61"><a aria-hidden="true" href="#cb17-61" tabindex="-1"></a><span className="fu">library</span>(TSstudio)</span>
<span id="cb17-62"><a aria-hidden="true" href="#cb17-62" tabindex="-1"></a></span><br/>
<span id="cb17-63"><a aria-hidden="true" href="#cb17-63" tabindex="-1"></a>J <span className="ot">&lt;-</span> JohnsonJohnson <span className="sc">%&gt;%</span> </span>
<span id="cb17-64"><a aria-hidden="true" href="#cb17-64" tabindex="-1"></a>  TSstudio<span className="sc">::</span><span className="fu">ts_reshape</span>() <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb17-65"><a aria-hidden="true" href="#cb17-65" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>quarter, <span className="at">names_to =</span> <span className="st">"year"</span>, <span className="at">values_to =</span> <span className="st">"sales"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb17-66"><a aria-hidden="true" href="#cb17-66" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">quarter =</span> <span className="fu">paste0</span>(<span className="st">"Q"</span>, quarter) <span className="sc">%&gt;%</span> <span className="fu">factor</span>(<span className="at">ordered =</span> T),</span>
<span id="cb17-67"><a aria-hidden="true" href="#cb17-67" tabindex="-1"></a>         <span className="at">sales =</span> <span className="fu">round</span>(sales, <span className="dv">1</span>))</span>
<span id="cb17-68"><a aria-hidden="true" href="#cb17-68" tabindex="-1"></a>J</span>
<span id="cb17-69"><a aria-hidden="true" href="#cb17-69" tabindex="-1"></a></span><br/>
<span id="cb17-70"><a aria-hidden="true" href="#cb17-70" tabindex="-1"></a><span className="co"># create colors as many as the number of years</span></span>
<span id="cb17-71"><a aria-hidden="true" href="#cb17-71" tabindex="-1"></a>myColors2 <span className="ot">&lt;-</span> </span>
<span id="cb17-72"><a aria-hidden="true" href="#cb17-72" tabindex="-1"></a>  <span className="fu">colorRampPalette</span>(<span className="fu">brewer.pal</span>(<span className="dv">9</span>, <span className="st">"YlGn"</span>))(<span className="fu">n_distinct</span>(g<span className="sc">$</span>country))</span>
<span id="cb17-73"><a aria-hidden="true" href="#cb17-73" tabindex="-1"></a></span><br/>
<span id="cb17-74"><a aria-hidden="true" href="#cb17-74" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> J <span className="sc">%&gt;%</span> </span>
<span id="cb17-75"><a aria-hidden="true" href="#cb17-75" tabindex="-1"></a>  <span className="fu">newggslopegraph</span>(</span>
<span id="cb17-76"><a aria-hidden="true" href="#cb17-76" tabindex="-1"></a>    <span className="at">Times =</span> quarter, </span>
<span id="cb17-77"><a aria-hidden="true" href="#cb17-77" tabindex="-1"></a>    <span className="at">Measurement =</span> sales, </span>
<span id="cb17-78"><a aria-hidden="true" href="#cb17-78" tabindex="-1"></a>    <span className="at">Grouping =</span> year,</span>
<span id="cb17-79"><a aria-hidden="true" href="#cb17-79" tabindex="-1"></a>    <span className="at">DataTextSize =</span> <span className="dv">3</span>, </span>
<span id="cb17-80"><a aria-hidden="true" href="#cb17-80" tabindex="-1"></a>    <span className="at">LineColor =</span> myColors2</span>
<span id="cb17-81"><a aria-hidden="true" href="#cb17-81" tabindex="-1"></a>  ) <span className="sc">+</span></span>
<span id="cb17-82"><a aria-hidden="true" href="#cb17-82" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb17-83"><a aria-hidden="true" href="#cb17-83" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Johnson &amp; Johnson Earnings ($) per share"</span>,</span>
<span id="cb17-84"><a aria-hidden="true" href="#cb17-84" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="cn">NULL</span>,</span>
<span id="cb17-85"><a aria-hidden="true" href="#cb17-85" tabindex="-1"></a>    <span className="at">caption =</span> <span className="st">"Shumway, R. H. and Stoffer, D. S. (2000) </span></span>
<span id="cb17-86"><a aria-hidden="true" href="#cb17-86" tabindex="-1"></a><span className="st">       Time Series Analysis and its Applications.Second Edition. Springer"</span>)</span>
<span id="cb17-87"><a aria-hidden="true" href="#cb17-87" tabindex="-1"></a>p3</span>
<span id="cb17-88"><a aria-hidden="true" href="#cb17-88" tabindex="-1"></a></span><br/>
<span id="cb17-89"><a aria-hidden="true" href="#cb17-89" tabindex="-1"></a></span><br/>
<span id="cb17-90"><a aria-hidden="true" href="#cb17-90" tabindex="-1"></a><span className="co"># ---------------------------------------------------------------</span></span>
<span id="cb17-91"><a aria-hidden="true" href="#cb17-91" tabindex="-1"></a></span><br/>
<span id="cb17-92"><a aria-hidden="true" href="#cb17-92" tabindex="-1"></a></span><br/>
<span id="cb17-93"><a aria-hidden="true" href="#cb17-93" tabindex="-1"></a><span className="co"># e.g.4 Visualize indometh pharmacokinetics </span></span>
<span id="cb17-94"><a aria-hidden="true" href="#cb17-94" tabindex="-1"></a><span className="fu">as.tibble</span>(Indometh)</span>
<span id="cb17-95"><a aria-hidden="true" href="#cb17-95" tabindex="-1"></a></span><br/>
<span id="cb17-96"><a aria-hidden="true" href="#cb17-96" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> Indometh <span className="sc">%&gt;%</span> </span>
<span id="cb17-97"><a aria-hidden="true" href="#cb17-97" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">time =</span> <span className="fu">factor</span>(time, <span className="at">ordered =</span> T)) <span className="sc">%&gt;%</span> </span>
<span id="cb17-98"><a aria-hidden="true" href="#cb17-98" tabindex="-1"></a>  <span className="fu">newggslopegraph</span>(time, conc, Subject) <span className="sc">+</span></span>
<span id="cb17-99"><a aria-hidden="true" href="#cb17-99" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="cn">NULL</span>, <span className="at">subtitle =</span> <span className="cn">NULL</span>, <span className="at">caption =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb17-100"><a aria-hidden="true" href="#cb17-100" tabindex="-1"></a>  <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb17-101"><a aria-hidden="true" href="#cb17-101" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb17-102"><a aria-hidden="true" href="#cb17-102" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">name =</span> <span className="st">"Time (h)"</span>) <span className="sc">+</span> </span>
<span id="cb17-103"><a aria-hidden="true" href="#cb17-103" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">3</span>, .<span className="dv">5</span>), </span>
<span id="cb17-104"><a aria-hidden="true" href="#cb17-104" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"mcg/mL"</span>) <span className="sc">+</span></span>
<span id="cb17-105"><a aria-hidden="true" href="#cb17-105" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb17-106"><a aria-hidden="true" href="#cb17-106" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Pharmacokinetics</span><span className="sc">\n</span><span className="st">of indometacin"</span>,</span>
<span id="cb17-107"><a aria-hidden="true" href="#cb17-107" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">7</span>, <span className="at">y =</span> <span className="fl">1.8</span>, <span className="at">size =</span> <span className="dv">8</span>, <span className="at">color =</span> <span className="st">"snow4"</span>, </span>
<span id="cb17-108"><a aria-hidden="true" href="#cb17-108" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb17-109"><a aria-hidden="true" href="#cb17-109" tabindex="-1"></a>p4</span>
<span id="cb17-110"><a aria-hidden="true" href="#cb17-110" tabindex="-1"></a></span><br/>
<span id="cb17-111"><a aria-hidden="true" href="#cb17-111" tabindex="-1"></a></span><br/>
<span id="cb17-112"><a aria-hidden="true" href="#cb17-112" tabindex="-1"></a><span className="co"># ---------------------------------------------------------------</span></span>
<span id="cb17-113"><a aria-hidden="true" href="#cb17-113" tabindex="-1"></a></span><br/>
<span id="cb17-114"><a aria-hidden="true" href="#cb17-114" tabindex="-1"></a></span><br/>
<span id="cb17-115"><a aria-hidden="true" href="#cb17-115" tabindex="-1"></a><span className="co"># Combine all plots together.</span></span>
<span id="cb17-116"><a aria-hidden="true" href="#cb17-116" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb17-117"><a aria-hidden="true" href="#cb17-117" tabindex="-1"></a></span><br/>
<span id="cb17-118"><a aria-hidden="true" href="#cb17-118" tabindex="-1"></a><span className="co"># create a function adding plot margin around the plots</span></span>
<span id="cb17-119"><a aria-hidden="true" href="#cb17-119" tabindex="-1"></a>f <span className="ot">&lt;-</span> <span className="cf">function</span>(p)&#123;</span>
<span id="cb17-120"><a aria-hidden="true" href="#cb17-120" tabindex="-1"></a>  p <span className="ot">&lt;-</span> p <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>)))</span>
<span id="cb17-121"><a aria-hidden="true" href="#cb17-121" tabindex="-1"></a>  <span className="fu">return</span>(p)</span>
<span id="cb17-122"><a aria-hidden="true" href="#cb17-122" tabindex="-1"></a>&#125;</span>
<span id="cb17-123"><a aria-hidden="true" href="#cb17-123" tabindex="-1"></a></span><br/>
<span id="cb17-124"><a aria-hidden="true" href="#cb17-124" tabindex="-1"></a><span className="co"># combine the plots</span></span>
<span id="cb17-125"><a aria-hidden="true" href="#cb17-125" tabindex="-1"></a>( <span className="fu">f</span>(p1) <span className="sc">|</span> <span className="fu">f</span>(p2) ) <span className="sc">/</span> ( <span className="fu">f</span>(p3) <span className="sc">|</span> <span className="fu">f</span>(p4) )</span>
<span id="cb17-126"><a aria-hidden="true" href="#cb17-126" tabindex="-1"></a></span><br/>
<span id="cb17-127"><a aria-hidden="true" href="#cb17-127" tabindex="-1"></a><span className="co"># save the combined plots</span></span>
<span id="cb17-128"><a aria-hidden="true" href="#cb17-128" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"slopePlot_completed.pdf"</span>,</span>
<span id="cb17-129"><a aria-hidden="true" href="#cb17-129" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,</span>
<span id="cb17-130"><a aria-hidden="true" href="#cb17-130" tabindex="-1"></a>       <span className="at">height =</span> <span className="dv">10</span>, <span className="at">width =</span> <span className="dv">11</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In a line plot containing multiple groups, <strong>highlighting certain observations of interest</strong> is a powerful way of storytelling, as demonstrated in the following <Link to="../ggplot2-line-plot-cigarettes"><strong>annotated and highlighted line plot</strong></Link> that shows the changing popularity of smoking in U.S., Germany, and France, as well as other countries over the past century.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
</main>
</div>
</div>
)}