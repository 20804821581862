import FaqPageVertical from "../faqPages/faqPagesVertical";
import {useEffect, useState}from "react";
import PythonPostsData from "../data/PythonPostsData";

export default function PythonPostsIndex({id, title, summary, content,static_website_url, cover="", date}) {
    const [posts, setPosts] = useState([]);
    useEffect(()=> {
        // 'https://api.rtistrystudio.com/posts'
        // 'http://localhost:4002/posts'
            // fetch('http://localhost:4002/posts').then(response=> {
            //     response.json().then(Items => {
            //         setPosts(Items.items)     
            //     }).catch(error=> {
            //         console.error('Fetch error:', error);
            //     })
            // }) 
    }, []); 
    return (
      <div>
          { PythonPostsData.map((post, index) => (
            <div className="graph" key={index}>
                <FaqPageVertical {...post} />
            </div>
        ))
        }
      </div>
    )
}