import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrCount(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Count Specified Patterns in a String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_count()</code></strong> counts the number of occurrences of a specific pattern in a string. In the following example, we count the number of letter “a” in each of the fruit names.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>fruit <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apple"</span>, <span className="st">"banana"</span>, <span className="st">"pear"</span>, <span className="st">"kiwi"</span>)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">str_count</span>(fruit, <span className="at">pattern =</span> <span className="st">"a"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 1 3 1 0</code></pre>
</div>
</div>
<p>Here we count the letter of “a”, “b”, “p”, and “p”, respectively, in each of the four fruit names. Note that the length of the pattern is the same as the input vector <code>fruit</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_count</span>(fruit, <span className="at">pattern =</span> <span className="fu">c</span>(<span className="st">"p"</span>, <span className="st">"a"</span>, <span className="st">"e"</span>, <span className="st">"i"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 2 3 1 2</code></pre>
</div>
</div>
</main>
</div>
</div>
)}