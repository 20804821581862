import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrSortStrOrder(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Sort Strings in Numeric or Alphabetic Order</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_sort()</code></strong> returns the sorted elements in numerical and alphabetical order.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"cheese"</span>, <span className="st">"grape"</span>, <span className="st">"apple"</span>, <span className="cn">NA</span>)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">str_sort</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apple"  "cheese" "grape"  NA      </code></pre>
</div>
</div>
<p>You can sort by the reverse order: from z to a, and from big to small numbers.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_sort</span>(x, <span className="at">decreasing =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "grape"  "cheese" "apple"  NA      </code></pre>
</div>
</div>
<p>All <code>NA</code> values are positioned at the end of the output vector by default. Alternatively, you can return the NA values at the beginning of the output.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_sort</span>(x, <span className="at">na_last =</span> F)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] NA       "apple"  "cheese" "grape" </code></pre>
</div>
</div>
<p>String elements started with numbers are sorted first, beginning with the first digit and progressing to the second digit, and so forth. These elements are returned at the beginning of the sorted vector.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"abc"</span>, <span className="st">"100"</span>, <span className="st">"101"</span>, <span className="st">"x30"</span>, <span className="st">"2"</span>, <span className="st">"50"</span>)</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">str_sort</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "100" "101" "2"   "50"  "abc" "x30"</code></pre>
</div>
</div>
<p>Use <code>numeric = TRUE</code> to sort numbers in strings.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_sort</span>(x, <span className="at">numeric =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "2"   "50"  "100" "101" "abc" "x30"</code></pre>
</div>
</div>
<p><strong><code>str_order()</code></strong> returns a vector of integers that indicate the position of the sorted elements in the original input vector.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">str_order</span>(x) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 2 3 5 6 1 4</code></pre>
</div>
</div>
<p>As such, <code>str_sort(x)</code> is equivalent to <code>x[str_order(x)]</code>.</p>
</main>
</div>
</div>
)}