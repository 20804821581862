import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function KeepDistinctRows(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Remove Duplicated Rows of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>distinct()</code></strong> to remove duplicated rows. Only unique (distinct) rows are retained in the output dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">data.frame</span>(</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="at">ID =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">1</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"John"</span>, <span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"John"</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">25</span>, <span className="dv">30</span>, <span className="dv">22</span>, <span className="dv">25</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>x <span className="co"># 1st and 4th rows are duplicated</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    ID  Name Age
<br/>  1  1  John  25
<br/>  2  2 Alice  30
<br/>  3  3   Bob  22
<br/>  4  1  John  25</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># remove duplicated rows</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">distinct</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    ID  Name Age
<br/>  1  1  John  25
<br/>  2  2 Alice  30
<br/>  3  3   Bob  22</code></pre>
</div>
</div>
<section className="level3" id="check-duplication-for-only-selected-variables">
<h3 className="anchored" data-anchor-id="check-duplication-for-only-selected-variables">Check duplication for only selected variables</h3>
<p>By default, all variables are checked for row duplication. Alternatively, you can check only selected variables for duplication. In the following example, all rows are unique. However, when considering only the first two columns “ID” and “Name”, the 1st and 4th rows are duplicated.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">data.frame</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">ID =</span>    <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">1</span>),</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">Name =</span>  <span className="fu">c</span>(<span className="st">"John"</span>,  <span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"John"</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">Sales =</span> <span className="fu">c</span>(<span className="dv">25</span>, <span className="dv">30</span>, <span className="dv">25</span>, <span className="dv">50</span>))</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>x</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    ID  Name Sales
<br/>  1  1  John    25
<br/>  2  2 Alice    30
<br/>  3  3   Bob    25
<br/>  4  1  John    50</code></pre>
</div>
</div>
<p>Check only “ID” and “Name” for row uniqueness. By default, only these selected columns are retained in the output dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">distinct</span>(ID, Name)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    ID  Name
<br/>  1  1  John
<br/>  2  2 Alice
<br/>  3  3   Bob</code></pre>
</div>
</div>
<p>Use <strong><code>.keep_all = T</code></strong> to keep all original columns in the output dataset (though only “ID” and “Name” are used to check row uniqueness).</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">distinct</span>(ID, Name, <span className="at">.keep_all =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    ID  Name Sales
<br/>  1  1  John    25
<br/>  2  2 Alice    30
<br/>  3  3   Bob    25</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}