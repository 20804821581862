import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Acknowledgement(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Acknowledgement and References</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>We extend our heartfelt gratitude to the R Core Team for their dedication and invaluable contributions to the R language. We thank <Link to="https://hadley.nz/">Hadley Wickham</Link>, <Link to="https://github.com/DavisVaughan">Davis Vaughan</Link>, Maximilian Girlich, et al., for their great work on developing and maintaining the tidyr package, that has evolved into one of the most used tools for data analysis in the R and broader data science community.</p>
<p>This serial tutorials has extensive reference to the <Link to="https://tidyr.tidyverse.org/"><strong>official tidyr documentation</strong></Link>. Substantial adaption and optimization are made to offer more streamlined content structure, self-explanatory and practical illustrating examples, and improved texts, etc., aiming for improved understanding, memorability, and systematic learning.</p>
</main>
</div>
</div>
)}