import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonFunctionsreturnstatements(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Return-Statement">Return Statement<a class="anchor-link" href="#Return-Statement">¶</a></h3><p>In <Link to="../function-definition-and-parameters">Function Definition and Parameters</Link>, we covered function creation and parameter usage. While we've utilized the <code>print()</code> method to display function results in the console, practical scenarios often require using <code>return</code> instead of <code>print</code>. Unlike <code>print</code>, <code>return</code> allows the function result to be stored in a variable for further use.</p>
<p>In Python, the <code>return</code> statement is used to exit the function and return a value to the caller. This allows functions to compute a value and provide it for further use outside of the function.</p>
<p>Let's explore how the <code>return</code> statement work in Python functions.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Return-Statement:-return-a-value-to-the-caller"><code>Return</code> Statement: return a value to the caller<a class="anchor-link" href="#Return-Statement:-return-a-value-to-the-caller">¶</a></h4><p>In the example below, a function named <code>add</code> takes two arguments <code>a</code> and <code>b</code>, adds them together, and returns the result using the <code>return</code> statement.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a function</span></span>

<span><span class="k">def</span> <span class="nf">add</span><span class="p">(</span><span class="n">a</span><span class="p">,</span> <span class="n">b</span><span class="p">):</span></span>

<span>    <span class="c1"># Use return statement to return the sum of a and b</span></span>

<span>    <span class="k">return</span> <span class="n">a</span> <span class="o">+</span> <span class="n">b</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Then call the <code>add()</code> function with positional arguments 3 and 5, the returned result was stored in the variable <code>result</code>. Subsequently, we can access the function result through the <code>result</code> variable.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Store function result in the result variable</span></span>

<span><span class="n">result</span> <span class="o">=</span> <span class="n">add</span><span class="p">(</span><span class="mi">3</span><span class="p">,</span> <span class="mi">5</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">result</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>8
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Recall from <Link to="../function-definition-and-parameters">Function Definition and Parameters</Link>, <code>print()</code> function is used within the <code>add</code> function. Let's explore how this approach differs from using a return statement.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a function</span></span>

<span><span class="k">def</span> <span class="nf">add_print</span><span class="p">(</span><span class="n">a</span><span class="p">,</span> <span class="n">b</span><span class="p">):</span></span>

<span>    <span class="c1"># Use print() method inside the function</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="n">a</span> <span class="o">+</span> <span class="n">b</span><span class="p">)</span></span>


<br /><span><span class="c1"># Call the add_print() function and </span></span>

<span><span class="c1"># store function result to the result_print variable</span></span>

<span><span class="n">result_print</span> <span class="o">=</span> <span class="n">add_print</span><span class="p">(</span><span class="mi">3</span><span class="p">,</span> <span class="mi">5</span><span class="p">)</span></span>


<br /><span><span class="c1"># Print the result_print variable returns "None"</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">result_print</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>8
<br />None
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>In this example, the add function takes two parameters <code>a</code> and <code>b</code>, and it prints the sum of <code>a</code> and <code>b</code> using the print statement. However, there is no <code>return</code> statement in the function, which means the function doesn't explicitly return any value.</p>
<p>When the function is invoked with <code>add_print(3, 5)</code>, it prints the result of <code>3 + 5</code> (which is 8) to the console. However, since there is no <code>return</code> value, the variable <code>result_print</code> will be assigned <code>None</code>. Therefore, when <code>print(result_print)</code> is executed, it prints <code>None</code>, indicating that no value was returned from the function.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Return-Statement:-exit-a-function"><code>Return</code> Statement: exit a function<a class="anchor-link" href="#Return-Statement:-exit-a-function">¶</a></h4><p>The <code>return</code> statement in Python functions marks the exit point for the function, irrespective of its location within the function. Once executed, the return statement concludes the function's execution, halting any further code execution that follows it.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example">Example<a class="anchor-link" href="#Example">¶</a></h5><p>The function <code>counter()</code> below initializes a variable <code>count</code> to 0. It then enters a <code>while</code> loop that continues until the <code>count</code> value is larger than 10. Within the loop, there's a condition that checks if <code>count</code> is equal to 5. If it is, the function immediately exits and returns the value of <code>count</code>. Without the <code>return</code> statement, it increments <code>count</code> value by 1 and continues looping until <code>count</code> reaches or exceeds 10.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create function counter()</span></span>

<span><span class="k">def</span> <span class="nf">counter</span><span class="p">():</span></span>

<span>    <span class="c1"># Initialize a variable count with value 0</span></span>

<span>    <span class="n">count</span> <span class="o">=</span> <span class="mi">0</span></span>

    
<br /><span>    <span class="c1"># While loop </span></span>

<span>    <span class="k">while</span> <span class="n">count</span> <span class="o">&lt;=</span><span class="mi">10</span><span class="p">:</span></span>

<span>        <span class="c1"># If condition statement to check if the count value equals 5</span></span>

<span>        <span class="k">if</span> <span class="n">count</span> <span class="o">==</span> <span class="mi">5</span><span class="p">:</span></span>

<span>            <span class="c1"># Return the count value and exit the function if count euqal 5</span></span>

<span>            <span class="k">return</span> <span class="n">count</span></span>

<span>        <span class="c1"># Increment count by 1</span></span>

<span>        <span class="n">count</span> <span class="o">+=</span> <span class="mi">1</span></span>   

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Call the <code>counter()</code> function:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Call the counter() function and store function result to a variable</span></span>

<span><span class="n">result</span> <span class="o">=</span> <span class="n">counter</span><span class="p">()</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">result</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>5
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Because the <code>if</code> condition checks if <code>count</code> equals 5, once this condition is met, the <code>return</code> statement executes to exit the function, returning the current value of count. Consequently, the <code>count</code> will never be incremented beyond 5.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example">Example<a class="anchor-link" href="#Example">¶</a></h5><p>The code below defines a function named <code>example_function()</code>. Within this function, an empty list <code>init_list</code> and a variable <code>a</code> are initialized. Then, a <code>while</code> loop runs as long as the variable <code>a</code> is less than 10.</p>
<p>During each iteration, the value of <code>a</code> is added to <code>init_list</code>, and <code>a</code> is incremented. If the length of <code>init_list</code> becomes 3, the function exits prematurely, returning the current state of <code>init_list</code>. Otherwise, the loop continues until <code>a</code> is larger than or equal to 10.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [10]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a function</span></span>

<span><span class="k">def</span> <span class="nf">example_function</span><span class="p">():</span></span>

<span>    <span class="c1"># Initialize an empty list</span></span>

<span>    <span class="n">init_list</span> <span class="o">=</span> <span class="p">[]</span></span>

<span>    <span class="c1"># Initialize a variable with 5</span></span>

<span>    <span class="n">a</span> <span class="o">=</span> <span class="mi">5</span></span>

    
<br /><span>    <span class="c1"># While loop</span></span>

<span>    <span class="k">while</span> <span class="n">a</span> <span class="o">&lt;</span> <span class="mi">10</span><span class="p">:</span></span>

<span>        <span class="c1"># Append current value of a to the init_list list</span></span>

<span>        <span class="n">init_list</span><span class="o">.</span><span class="n">append</span><span class="p">(</span><span class="n">a</span><span class="p">)</span></span>

<span>        <span class="c1"># Increment a by 1</span></span>

<span>        <span class="n">a</span> <span class="o">+=</span> <span class="mi">1</span></span>

<span>        <span class="c1"># If condition to check the length of the init_list </span></span>

<span>        <span class="k">if</span> <span class="nb">len</span><span class="p">(</span><span class="n">init_list</span><span class="p">)</span> <span class="o">==</span> <span class="mi">3</span><span class="p">:</span></span>

<span>            <span class="c1"># If the init_list contains three elements</span></span>

<span>            <span class="c1"># return the list and exit the function</span></span>

<span>            <span class="k">return</span> <span class="n">init_list</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Call the <code>example_function()</code> function:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">result</span> <span class="o">=</span> <span class="n">example_function</span><span class="p">()</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">result</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>[5, 6, 7]
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Similar to the previous example, the <code>if</code> condition and <code>return</code> statement ensures that <code>init_list</code> never contains more than three values. The <code>return</code> statement causes the function to terminate prematurely once <code>init_list</code> reaches a length of three.</p>
</div>
</div>
</div>
</div>
</div>
)}