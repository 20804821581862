import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2WafflePlotChernoffFacesWealth from '../graphics/Emoji_waffle_happiness_completed.png'; 
import imgGgplot2WafflePlotChernoffFacesWealthWebp from '../graphics/Emoji_waffle_happiness_completed.webp'; 
export default function Ggplot2WafflePlotChernoffFacesWealth(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Emoji Faces in a Waffle Plot with ggplot2 to Visualize Wealth Distribution</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2WafflePlotChernoffFacesWealthWebp} />
    <img className="cover-img" src={imgGgplot2WafflePlotChernoffFacesWealth} />
  </picture>

<p>In this article, we’ll create <Link to="https://en.wikipedia.org/wiki/Chernoff_face">Emoji / Chernoff faces</Link> arranged in a Waffle grid to visualize the wealth distribution in the U.S. population. <strong>Major techniques covered in this tutorial include:</strong></p>
<ul>
<li><a href="#waffle_grid">Create the data strcture suitable for a Waffle grid.</a></li>
<li><a href="#emoji_chernoff">Draw Emoji / Chernoff faces.</a></li>
<li><a href="#merge_legends">Merge split legends.</a></li>
<li><a href="#google_font">Use Google fonts.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">packages and data cleanup</h3>
<p>The majority of data cleanup is identical to <Link to="../ggplot2-waffle-plot-wealth-distribution">this work</Link> where we created a Waffle plot in a 10 x 10 grid. <span className="highlightGreen">If you are already familiar with it, you can directly <a href="#jump"><strong>skip</strong></a> to the Emoji visualization.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(ggmosaic)   <span className="co"># use the "happy" dataset of this package</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(ggChernoff) <span className="co"># create Emoji faces</span></span></code></pre></div>
</div>
<p><span id="waffle_grid"><strong>Prepare the dataset to create a Waffle grid.</strong> We start by first calculating the number of people in each wealth category.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> <span className="fu">select</span>(finrela)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a></span><br/>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a><span className="co"># remove rows containing NA values</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>h <span className="ot">&lt;-</span> h[<span className="fu">complete.cases</span>(h), ] </span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a></span><br/>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a><span className="co"># summarize the number of people in each wealth category</span></span>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a>counts <span className="ot">&lt;-</span> h<span className="sc">$</span>finrela <span className="sc">%&gt;%</span> <span className="fu">table</span>()</span></code></pre></div>
</div>
<p>Calculate the head counts for each wealth category <em>normalized</em> to a total of 100 people.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>nrows <span className="ot">&lt;-</span> <span className="dv">10</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>counts.normalized <span className="ot">&lt;-</span> <span className="fu">round</span>( counts <span className="sc">*</span> (nrows<span className="sc">^</span><span className="dv">2</span> <span className="sc">/</span> <span className="fu">sum</span>(counts)) )</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="co"># check to make sure the sum of counts after rounding is 100 </span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a><span className="fu">sum</span>(counts.normalized)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 100</code></pre>
</div>
</div>
<p>Create a tidy dataset containing 100 people’s wealth condition.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">expand.grid</span>(<span className="at">y =</span> <span className="dv">1</span><span className="sc">:</span>nrows, <span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span>nrows)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">wealth =</span> <span className="fu">rep</span>(<span className="fu">names</span>(counts.normalized), counts.normalized))</span></code></pre></div>
</div>
<p>In the <code>expand.grid()</code> function above, we can also swap the x and y position, i.e., using <code>expand.grid(x = 1:nrows, y = 1:nrows)</code>. This creates a Waffle plot with transposed layout.</p>
<p><br/></p>
<p><span className="highlightGreen" id="jump">The code above is identical to <Link to="../ggplot2-waffle-plot-wealth-distribution">this work</Link> where we created the Waffle plot. Code below is specifically catered to create the <strong>Emoji / Chernoff faces.</strong></span></p>
<hr/>
<p><strong>An extra step to prepare the data to draw Chernoff faces</strong>: create a numerical variable to be mapped to the facial expression. Larger numbers correspond to happier Chernoff faces. Here we use the facial expression to represent different wealth status.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>a <span className="ot">&lt;-</span>  <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a><span className="fu">names</span>(a) <span className="ot">&lt;-</span> d<span className="sc">$</span>wealth <span className="sc">%&gt;%</span> <span className="fu">unique</span>()</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a></span><br/>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>d.smile <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span> <span className="ot">=</span> a[wealth])</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a></span><br/>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a><span className="fu">head</span>(d.smile, <span className="at">n =</span> <span className="dv">3</span>) <span className="co"># read for visualization</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>      y     x wealth            `wealth amount`
<br/>  &lt;int&gt; &lt;int&gt; &lt;chr&gt;                       &lt;int&gt;
<br/>1     1     1 far below average               1
<br/>2     2     1 far below average               1
<br/>3     3     1 far below average               1</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="emoji_chernoff"><strong>Create Chernoff faces.</strong> To achieve this, we use the <Link to="https://github.com/Selbosh/ggChernoff"><code>ggChernoff</code></Link> package. A main function is <code>geom_chernoff()</code>, which works similarly as <code>geom_point()</code>, but takes aesthetics <code>smile</code>, <code>eyes</code>, and <code>brow</code>. These aesthetics are mapped with numerical variables, and larger values correspond to happier faces. Note that for the <code>fill</code> aesthetic, the <code>wealth amount</code> variable is locally converted to a categorical variable to generate the desired <em>discrete</em> color scale, instead of a continuous color bar.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.smile <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> x , <span className="at">y =</span> y, </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>             <span className="at">fill =</span> <span className="fu">factor</span>(<span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>), </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>             <span className="at">smile =</span> <span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>,</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>             <span className="at">eyes =</span> <span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>,</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>             <span className="at">brow =</span> <span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>)) <span className="sc">+</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="fu">geom_chernoff</span>(<span className="at">size =</span> <span className="dv">12</span>)</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a></span><br/>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_waffle_happiness_Chernoff_face.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_waffle_happiness_Chernoff_face"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="merge_legends"><strong>Merge the legends.</strong> In ggplot2, each aesthetic has its own legend. The key to merge the legends of different aesthetics (e.g., <code>fill</code>, <code>smile</code>, <code>brow</code>, and <code>eye</code>) is to keep their legend titles and labels all the same.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>legend.labels <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"$"</span>, <span className="st">"$$"</span>, <span className="st">"$$$"</span>, <span className="st">"$$$$"</span>, <span className="st">"$$$$$"</span>)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a></span><br/>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"red"</span>, <span className="st">"yellow"</span>, <span className="st">"skyblue1"</span>, <span className="st">"green2"</span>),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>                    <span className="at">name =</span> <span className="st">"wealth amount"</span>, <span className="co"># rename legend title</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>                    <span className="at">labels =</span> legend.labels) <span className="sc">+</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  </span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>  <span className="fu">scale_smile_continuous</span>(<span className="at">labels =</span> legend.labels) <span className="sc">+</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>  <span className="fu">scale_eyes_continuous</span> (<span className="at">labels =</span> legend.labels) <span className="sc">+</span></span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>  <span className="fu">scale_brow_continuous</span> (<span className="at">labels =</span> legend.labels) </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a></span><br/>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_waffle_happiness_merge_legend.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_waffle_happiness_merge_legend"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A final polish-up. Here we <strong>load font</strong> from <a href="https://fonts.google.com/" id="google_font">Google font repository</a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># Load fonts from Google font repository</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Gochi Hand"</span>, <span className="at">family =</span> <span className="st">"gochi"</span>)</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a></span><br/>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"wealth distribution in the US"</span>) <span className="sc">+</span></span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>  <span className="co"># theme_void() +</span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>    <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">family =</span> <span className="st">"gochi"</span>, <span className="at">size =</span> <span className="dv">18</span>),</span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, </span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)),</span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"snow"</span>),</span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>    <span className="at">axis.ticks =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-17"><a aria-hidden="true" href="#cb10-17" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"bottom"</span></span>
<span id="cb10-18"><a aria-hidden="true" href="#cb10-18" tabindex="-1"></a>  ) <span className="sc">+</span></span>
<span id="cb10-19"><a aria-hidden="true" href="#cb10-19" tabindex="-1"></a>  <span className="fu">coord_equal</span>()</span>
<span id="cb10-20"><a aria-hidden="true" href="#cb10-20" tabindex="-1"></a></span><br/>
<span id="cb10-21"><a aria-hidden="true" href="#cb10-21" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_waffle_happiness_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_waffle_happiness_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) <span className="co"># use the "happy" dataset of this package</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="fu">library</span>(ggChernoff) <span className="co"># create Emoji faces</span></span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a></span><br/>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a><span className="co">#Calculate the number of people in each wealth category. </span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> <span className="fu">select</span>(finrela)</span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a></span><br/>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a><span className="co"># remove rows containing NA values</span></span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>h <span className="ot">&lt;-</span> h[<span className="fu">complete.cases</span>(h), ] </span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a></span><br/>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a><span className="co"># summarize the number of people in each wealth category</span></span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a>counts <span className="ot">&lt;-</span> h<span className="sc">$</span>finrela <span className="sc">%&gt;%</span> <span className="fu">table</span>()</span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a></span><br/>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a><span className="co"># Calculate the head counts in each wealth category normalized to a total of 100 people.</span></span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a>nrows <span className="ot">&lt;-</span> <span className="dv">10</span></span>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a>counts.normalized <span className="ot">&lt;-</span> <span className="fu">round</span>( counts <span className="sc">*</span> (nrows<span className="sc">^</span><span className="dv">2</span> <span className="sc">/</span> <span className="fu">sum</span>(counts)) )</span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a></span><br/>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a><span className="co"># check to make sure the sum of counts after rounding is 100 </span></span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a><span className="fu">sum</span>(counts.normalized)</span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a></span><br/>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a><span className="co"># Create a tidy dataset containing 100 people's wealth condition. </span></span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">expand.grid</span>(<span className="at">y =</span> <span className="dv">1</span><span className="sc">:</span>nrows, <span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span>nrows)</span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">wealth =</span> <span className="fu">rep</span>(<span className="fu">names</span>(counts.normalized), counts.normalized))</span>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a></span><br/>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a><span className="co"># Create a numerical variable to be mapped to facial expression. </span></span>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a>a <span className="ot">&lt;-</span>  <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span></span>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a><span className="fu">names</span>(a) <span className="ot">&lt;-</span> d<span className="sc">$</span>wealth <span className="sc">%&gt;%</span> <span className="fu">unique</span>()</span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a></span><br/>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>d.smile <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span> <span className="ot">=</span> a[wealth])</span>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a></span><br/>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a><span className="fu">head</span>(d.smile, <span className="at">n =</span> <span className="dv">3</span>) <span className="co"># ready for visualization</span></span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a></span><br/>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a></span><br/>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a></span><br/>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a><span className="co"># Create Chernoff faces.</span></span>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.smile <span className="sc">%&gt;%</span> </span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> x , <span className="at">y =</span> y, </span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a>             <span className="at">fill =</span> <span className="fu">factor</span>(<span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>), </span>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>             <span className="at">smile =</span> <span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>,</span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>             <span className="at">eyes =</span> <span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>,</span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a>             <span className="at">brow =</span> <span className="st">`</span><span className="at">wealth amount</span><span className="st">`</span>)) <span className="sc">+</span></span>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a>  <span className="fu">geom_chernoff</span>(<span className="at">size =</span> <span className="dv">12</span>)</span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a></span><br/>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>p1</span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a></span><br/>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a></span><br/>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a><span className="co"># Merge the legends.</span></span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>legend.labels <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"$"</span>, <span className="st">"$$"</span>, <span className="st">"$$$"</span>, <span className="st">"$$$$"</span>, <span className="st">"$$$$$"</span>)</span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a></span><br/>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"red"</span>, <span className="st">"yellow"</span>, <span className="st">"skyblue1"</span>, <span className="st">"green2"</span>),</span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a>                    <span className="at">name =</span> <span className="st">"wealth amount"</span>, <span className="co"># rename legend title</span></span>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a>                    <span className="at">labels =</span> legend.labels) <span className="sc">+</span></span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a>  </span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a>  <span className="fu">scale_smile_continuous</span>(<span className="at">labels =</span> legend.labels) <span className="sc">+</span></span>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a>  <span className="fu">scale_eyes_continuous</span> (<span className="at">labels =</span> legend.labels) <span className="sc">+</span></span>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a>  <span className="fu">scale_brow_continuous</span> (<span className="at">labels =</span> legend.labels) </span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a></span><br/>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>p2</span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a></span><br/>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a></span><br/>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a><span className="co"># A final polish-up. </span></span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Gochi Hand"</span>, <span className="at">family =</span> <span className="st">"gochi"</span>)</span>
<span id="cb11-68"><a aria-hidden="true" href="#cb11-68" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb11-69"><a aria-hidden="true" href="#cb11-69" tabindex="-1"></a></span><br/>
<span id="cb11-70"><a aria-hidden="true" href="#cb11-70" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-71"><a aria-hidden="true" href="#cb11-71" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"wealth distribution in the US"</span>) <span className="sc">+</span></span>
<span id="cb11-72"><a aria-hidden="true" href="#cb11-72" tabindex="-1"></a>  <span className="co"># theme_void() +</span></span>
<span id="cb11-73"><a aria-hidden="true" href="#cb11-73" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-74"><a aria-hidden="true" href="#cb11-74" tabindex="-1"></a>    <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">family =</span> <span className="st">"gochi"</span>, <span className="at">size =</span> <span className="dv">18</span>),</span>
<span id="cb11-75"><a aria-hidden="true" href="#cb11-75" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, </span>
<span id="cb11-76"><a aria-hidden="true" href="#cb11-76" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)),</span>
<span id="cb11-77"><a aria-hidden="true" href="#cb11-77" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"snow"</span>),</span>
<span id="cb11-78"><a aria-hidden="true" href="#cb11-78" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-79"><a aria-hidden="true" href="#cb11-79" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-80"><a aria-hidden="true" href="#cb11-80" tabindex="-1"></a>    <span className="at">axis.ticks =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-81"><a aria-hidden="true" href="#cb11-81" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"bottom"</span>) <span className="sc">+</span></span>
<span id="cb11-82"><a aria-hidden="true" href="#cb11-82" tabindex="-1"></a>  <span className="fu">coord_equal</span>()</span>
<span id="cb11-83"><a aria-hidden="true" href="#cb11-83" tabindex="-1"></a></span><br/>
<span id="cb11-84"><a aria-hidden="true" href="#cb11-84" tabindex="-1"></a>p3</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p><span id="barplot">Check out the following article where we’ll <Link to="../ggplot2-bar-Emoji-happy-money"><strong>combine bars, lines, and Emoji faces</strong></Link> to illustrate the population distribution in happiness at different wealth conditions, as well as the relationship between happiness and the wealth status.</span></p>
<p><Link to="../ggplot2-bar-Emoji-happy-money">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_annotation_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_annotation_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>The above Emoji faces in a Waffle plot showed the <em>univariate</em> distribution of wealth, and the <a href="#barplot">bar plot</a> above showed the <em>univariate</em> distribution of happiness <em>conditioned</em> to each wealth status. As one step further, we can employ the <strong>mosaic plot</strong> to illustrate the <strong>bivariate</strong> distribution of both wealth and happiness. Check the article below how to <Link to="../ggplot2-mosaic-health-wealth"><strong>create a mosaic plot to visualize the population composition at varied levels of wealth and health condition</strong></Link>.</p>
<p><Link to="../ggplot2-mosaic-health-wealth">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}