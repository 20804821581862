import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Wildcards(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Match Any Character with a Wildcard</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>The dot <strong><code>.</code></strong> is a wildcard that matches any <em>single</em> character, e.g., <strong><code>....</code></strong> represents any four consecutive characters, and <strong><code>.*</code></strong> represents a string of any length (including an empty string; recall that the <Link to="../2-quantifiers">quantifier asterisk</Link> represents <em>zero or more</em> of the immediately preceding character). Consider the following examples.</p>
<p><strong><em>eg.1.</em></strong> <code>...at</code> matches any five characters (including whitespace) that ends with “at”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"The cat and the hat"</span>, <span className="st">"an apple at the table"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"...at"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ Th&lt;e cat&gt; and th&lt;e hat&gt;
<br/>[2] │ an app&lt;le at&gt; the table</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"...at"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]    [,2]   
<br/>[1,] "e cat" "e hat"
<br/>[2,] "le at" ""     </code></pre>
</div>
</div>
</div>
<p><strong><em>eg.2.</em></strong> <code>@.*</code> extracts “@” and all the following characters, and returns the email domain names.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>e <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"david.doxey@harvard.edu"</span>, </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>       <span className="st">"young_mike@cisco.com"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_view</span>(e, <span className="st">"@.*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ david.doxey&lt;@harvard.edu&gt;
<br/>[2] │ young_mike&lt;@cisco.com&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract</span>(e, <span className="st">"@.*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "@harvard.edu" "@cisco.com"  </code></pre>
</div>
</div>
</div>
</main>
</div>
</div>
)}