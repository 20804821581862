import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LinePlotCigarettes from '../graphics/lineplot_smoking_theme_completed.png'; 
import imgGgplot2LinePlotCigarettesWebp from '../graphics/lineplot_smoking_theme_completed.webp'; 
export default function Ggplot2LinePlotCigarettes(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Annotated Line Plot in ggplot2 to Visualize the Changing Popularity of Smoking</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><span id="lineplot"><img className="cover-img" src={imgGgplot2LinePlotCigarettes} /></span></p>
<p>In this work, we will create a line plot to visualize the rise and fall of the smoking popularity worldwide, with the United States, Germany, and France highlighted and annotated. The plot is a ggplot2 reproduction of the demo graphic by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link>.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleaup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleaup">Packages and data cleaup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)    <span className="co"># </span></span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)    <span className="co"># tidy up data structure   </span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)  <span className="co"># string manipulation</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">15</span>)) <span className="co"># global theme</span></span></code></pre></div>
</div>
<p>The data is sourced from <Link to="https://ourworldindata.org/smoking#the-rise-and-fall-of-smoking-in-rich-countries">Our World in Data</Link>, and can be downloaded <a className="downloadData" href="dataset/r-gallery/cigarettes.csv"><strong>here</strong></a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/cigarettes.csv"</span>)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a></span><br/>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>year, <span className="at">names_to =</span> <span className="st">"country"</span>, <span className="at">values_to =</span> <span className="st">"cigarettes"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">cigarettes =</span> <span className="fu">as.numeric</span>(cigarettes))</span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a></span><br/>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>   year country cigarettes
<br/>  &lt;int&gt; &lt;chr&gt;        &lt;dbl&gt;
<br/>1  1875 Austria       NA  
<br/>2  1875 Canada        NA  
<br/>3  1875 France         0.1</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Generate a simple line plot to visualize cigarette sales for each country. The lines, presented in grey, serve as a backdrop against which we will later highlight three specific countries.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> cigarettes, <span className="at">group =</span> country)) <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">color =</span> <span className="st">"snow3"</span>) </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_background.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lineplot_smoking_background"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Highlight the cigarette sales in three selected countries.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>countries.selected <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"United.States"</span>, <span className="st">"France"</span>, <span className="st">"Germany"</span>)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a></span><br/>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">geom_line</span>(</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    <span className="at">data =</span> d.tidy <span className="sc">%&gt;%</span> <span className="fu">filter</span>(country <span className="sc">%in%</span> countries.selected),</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">color =</span> country),</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>    <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"skyblue3"</span>, <span className="st">"steelblue4"</span>, <span className="st">"firebrick"</span>))</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_highlight.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lineplot_smoking_highlight"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add text annotations to the three highlight lines in replace of the default legend. And use text annotation to replace the default y-axis title. The default legend and y-axis title will be removed at a later step.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1933</span>, <span className="dv">1948</span>, <span className="dv">1955</span>, <span className="dv">1880</span>),</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">6</span>, <span className="dv">1</span>, <span className="dv">3</span>, <span className="dv">10</span>),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">0</span>, <span className="dv">0</span>, <span className="dv">0</span>), <span className="co"># 1, right justify;  0, left justify</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"United States"</span>, <span className="st">"France"</span>, <span className="st">"Germany"</span>, </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>              <span className="st">"Cigarettes sold</span><span className="sc">\n</span><span className="st">per day per adult"</span>),</span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"firebrick"</span>, <span className="st">"skyblue3"</span>, <span className="st">"steelblue4"</span>, <span className="st">"snow4"</span>),</span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">5</span>, <span className="dv">5</span>, <span className="dv">4</span>)) </span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_text_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lineplot_smoking_text_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Adjust the axis breaks and scales, remove axis titles, and add plot titles. For the long plot subtitle, we use the <code>str_wrap()</code> function from the popular <Link to="/R/data-wrangling/stringr/0-introduction"><code>stringr</code> package</Link> to automatically wrap long strings into nicely formatted paragraphs.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># adjust axis breaks</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1880</span>, <span className="dv">2000</span>, <span className="dv">20</span>)) <span className="sc">+</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">10</span>, <span className="dv">2</span>)) <span className="sc">+</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="co"># expand to fill up the entire plotting range</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="co"># update the axial and plot titles</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>    <span className="co"># remove the axial titles</span></span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>    <span className="at">y =</span> <span className="cn">NULL</span>, <span className="at">x =</span> <span className="cn">NULL</span>, </span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>    <span className="co"># add plot title and subtitle</span></span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Cigarette consumption in developed countries"</span>,</span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="fu">str_wrap</span>(</span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>      <span className="st">"Smoking became increasingly popular since 1920s, peaked around 1960s ~ 1980s, and significant decreased in 1990s."</span>, </span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>      <span className="at">width =</span> <span className="dv">60</span>) <span className="co"># number of characters per line</span></span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>  ) </span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a></span><br/>
<span id="cb7-18"><a aria-hidden="true" href="#cb7-18" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_axis_title.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lineplot_smoking_axis_title"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Final polish-up of the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">1</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>),</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_line</span>(<span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>    <span className="at">axis.ticks.x =</span> <span className="fu">element_line</span>(<span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>),</span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">color =</span> <span className="st">"orange4"</span>,</span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>                                 <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">15</span>))) </span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lineplot_smoking_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a></span><br/>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">15</span>)) <span className="co"># global theme</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a></span><br/>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/cigarettes.csv"</span>)</span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a></span><br/>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>year, <span className="at">names_to =</span> <span className="st">"country"</span>, <span className="at">values_to =</span> <span className="st">"cigarettes"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">cigarettes =</span> <span className="fu">as.numeric</span>(cigarettes))</span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a></span><br/>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a><span className="co">#Create a basic line plot</span></span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> cigarettes, <span className="at">group =</span> country)) <span className="sc">+</span></span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">color =</span> <span className="st">"snow3"</span>) </span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a>p1</span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a></span><br/>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a></span><br/>
<span id="cb9-21"><a aria-hidden="true" href="#cb9-21" tabindex="-1"></a><span className="co"># Highlight the cigarettes sales in three selected countries. </span></span>
<span id="cb9-22"><a aria-hidden="true" href="#cb9-22" tabindex="-1"></a>countries.selected <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"United.States"</span>, <span className="st">"France"</span>, <span className="st">"Germany"</span>)</span>
<span id="cb9-23"><a aria-hidden="true" href="#cb9-23" tabindex="-1"></a></span><br/>
<span id="cb9-24"><a aria-hidden="true" href="#cb9-24" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb9-25"><a aria-hidden="true" href="#cb9-25" tabindex="-1"></a>  <span className="fu">geom_line</span>(</span>
<span id="cb9-26"><a aria-hidden="true" href="#cb9-26" tabindex="-1"></a>    <span className="at">data =</span> d.tidy <span className="sc">%&gt;%</span> <span className="fu">filter</span>(country <span className="sc">%in%</span> countries.selected),</span>
<span id="cb9-27"><a aria-hidden="true" href="#cb9-27" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">color =</span> country),</span>
<span id="cb9-28"><a aria-hidden="true" href="#cb9-28" tabindex="-1"></a>    <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb9-29"><a aria-hidden="true" href="#cb9-29" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"skyblue3"</span>, <span className="st">"steelblue4"</span>, <span className="st">"firebrick"</span>))</span>
<span id="cb9-30"><a aria-hidden="true" href="#cb9-30" tabindex="-1"></a>p2</span>
<span id="cb9-31"><a aria-hidden="true" href="#cb9-31" tabindex="-1"></a></span><br/>
<span id="cb9-32"><a aria-hidden="true" href="#cb9-32" tabindex="-1"></a></span><br/>
<span id="cb9-33"><a aria-hidden="true" href="#cb9-33" tabindex="-1"></a><span className="co"># Add text annotation to the three highlight lines in replace of the default legend, </span></span>
<span id="cb9-34"><a aria-hidden="true" href="#cb9-34" tabindex="-1"></a><span className="co"># and use text annotation to replace the default y-axis title.</span></span>
<span id="cb9-35"><a aria-hidden="true" href="#cb9-35" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb9-36"><a aria-hidden="true" href="#cb9-36" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb9-37"><a aria-hidden="true" href="#cb9-37" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb9-38"><a aria-hidden="true" href="#cb9-38" tabindex="-1"></a>    <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1933</span>, <span className="dv">1948</span>, <span className="dv">1955</span>, <span className="dv">1880</span>),</span>
<span id="cb9-39"><a aria-hidden="true" href="#cb9-39" tabindex="-1"></a>    <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">6</span>, <span className="dv">1</span>, <span className="dv">3</span>, <span className="dv">10</span>),</span>
<span id="cb9-40"><a aria-hidden="true" href="#cb9-40" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">0</span>, <span className="dv">0</span>, <span className="dv">0</span>), <span className="co"># 1, right justify;  0, left justify</span></span>
<span id="cb9-41"><a aria-hidden="true" href="#cb9-41" tabindex="-1"></a>    <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"United States"</span>, <span className="st">"France"</span>, <span className="st">"Germany"</span>, </span>
<span id="cb9-42"><a aria-hidden="true" href="#cb9-42" tabindex="-1"></a>              <span className="st">"Cigarettes sold</span><span className="sc">\n</span><span className="st">per day per adult"</span>),</span>
<span id="cb9-43"><a aria-hidden="true" href="#cb9-43" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"firebrick"</span>, <span className="st">"skyblue3"</span>, <span className="st">"steelblue4"</span>, <span className="st">"snow4"</span>),</span>
<span id="cb9-44"><a aria-hidden="true" href="#cb9-44" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">5</span>, <span className="dv">5</span>, <span className="dv">4</span>)) </span>
<span id="cb9-45"><a aria-hidden="true" href="#cb9-45" tabindex="-1"></a>p3</span>
<span id="cb9-46"><a aria-hidden="true" href="#cb9-46" tabindex="-1"></a></span><br/>
<span id="cb9-47"><a aria-hidden="true" href="#cb9-47" tabindex="-1"></a></span><br/>
<span id="cb9-48"><a aria-hidden="true" href="#cb9-48" tabindex="-1"></a><span className="co"># Adjust the axis breaks and scales, remove axis titles, and add plot titles. </span></span>
<span id="cb9-49"><a aria-hidden="true" href="#cb9-49" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb9-50"><a aria-hidden="true" href="#cb9-50" tabindex="-1"></a>  <span className="co"># adjust axis breaks</span></span>
<span id="cb9-51"><a aria-hidden="true" href="#cb9-51" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1880</span>, <span className="dv">2000</span>, <span className="dv">20</span>)) <span className="sc">+</span></span>
<span id="cb9-52"><a aria-hidden="true" href="#cb9-52" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">10</span>, <span className="dv">2</span>)) <span className="sc">+</span></span>
<span id="cb9-53"><a aria-hidden="true" href="#cb9-53" tabindex="-1"></a>  <span className="co"># expand to fill up the entire plotting range</span></span>
<span id="cb9-54"><a aria-hidden="true" href="#cb9-54" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb9-55"><a aria-hidden="true" href="#cb9-55" tabindex="-1"></a>  <span className="co"># update the axial and plot titles</span></span>
<span id="cb9-56"><a aria-hidden="true" href="#cb9-56" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb9-57"><a aria-hidden="true" href="#cb9-57" tabindex="-1"></a>    <span className="co"># remove the axial titles</span></span>
<span id="cb9-58"><a aria-hidden="true" href="#cb9-58" tabindex="-1"></a>    <span className="at">y =</span> <span className="cn">NULL</span>, <span className="at">x =</span> <span className="cn">NULL</span>, </span>
<span id="cb9-59"><a aria-hidden="true" href="#cb9-59" tabindex="-1"></a>    <span className="co"># add plot title and subtitle</span></span>
<span id="cb9-60"><a aria-hidden="true" href="#cb9-60" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Cigarette consumption in developed countries"</span>,</span>
<span id="cb9-61"><a aria-hidden="true" href="#cb9-61" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="fu">str_wrap</span>(</span>
<span id="cb9-62"><a aria-hidden="true" href="#cb9-62" tabindex="-1"></a>      <span className="st">"Smoking became increasingly popular since 1920s, peaked around 1960s ~ 1980s, and significant decreased in 1990s."</span>, </span>
<span id="cb9-63"><a aria-hidden="true" href="#cb9-63" tabindex="-1"></a>      <span className="at">width =</span> <span className="dv">60</span>)) <span className="co"># number of characters per line</span></span>
<span id="cb9-64"><a aria-hidden="true" href="#cb9-64" tabindex="-1"></a>   </span>
<span id="cb9-65"><a aria-hidden="true" href="#cb9-65" tabindex="-1"></a>p4</span>
<span id="cb9-66"><a aria-hidden="true" href="#cb9-66" tabindex="-1"></a></span><br/>
<span id="cb9-67"><a aria-hidden="true" href="#cb9-67" tabindex="-1"></a></span><br/>
<span id="cb9-68"><a aria-hidden="true" href="#cb9-68" tabindex="-1"></a><span className="co"># Final polish-up of the theme.</span></span>
<span id="cb9-69"><a aria-hidden="true" href="#cb9-69" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-70"><a aria-hidden="true" href="#cb9-70" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb9-71"><a aria-hidden="true" href="#cb9-71" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb9-72"><a aria-hidden="true" href="#cb9-72" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-73"><a aria-hidden="true" href="#cb9-73" tabindex="-1"></a>    <span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">1</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>),</span>
<span id="cb9-74"><a aria-hidden="true" href="#cb9-74" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_line</span>(<span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb9-75"><a aria-hidden="true" href="#cb9-75" tabindex="-1"></a>    <span className="at">axis.ticks.x =</span> <span className="fu">element_line</span>(<span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb9-76"><a aria-hidden="true" href="#cb9-76" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>),</span>
<span id="cb9-77"><a aria-hidden="true" href="#cb9-77" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(</span>
<span id="cb9-78"><a aria-hidden="true" href="#cb9-78" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">12</span>, <span className="at">color =</span> <span className="st">"orange4"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">15</span>))) </span>
<span id="cb9-79"><a aria-hidden="true" href="#cb9-79" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In the following <strong><Link to="../ggplot2-line-plot-social-mobility">jittered line plot</Link></strong>, we’ll visualize the social mobility: how a son’s career path was affected by his father’s occupation in the United States during the 1970s. Each single line represents a distinct father-son pair.</p>
<p><Link to="../ggplot2-line-plot-social-mobility">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>To depict chronological changes, line plots are a powerful tool as <a href="#lineplot">illustrated above</a> and in <Link to="../ggplot2-line-plot-life-expectancy">this example</Link>. Besides, ribbons are an attractive alternative with engaging visual appeal. Check out this awesome <Link to="../ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link>, which shows dynamic shifts in the migrant population to the United States from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}