import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2MapMapproj from '../graphics/mapproj_theme_completed.png'; 
import imgGgplot2MapMapprojWebp from '../graphics/mapproj_theme_completed.webp'; 
export default function Ggplot2MapMapproj(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualizing 1970s U.S. Unemployment: Create Geographic Heatmap with ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2MapMapprojWebp} />
    <img className="cover-img" src={imgGgplot2MapMapproj} />
  </picture>

<p>In this work, we’ll use R ggplot2 to create a geographic heatmap to visualize the unemployment rate in counties in the U.S. in the 1970s.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="data-cleanup">
<h3 className="anchored" data-anchor-id="data-cleanup">Data cleanup</h3>
<p>Load the unemployment data.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyverse)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(mapproj)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">data</span>(unemp, <span className="at">package =</span> <span className="st">"viridis"</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">tibble</span>(unemp)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3,218 × 8
<br/>  id       state_fips county_fips name                year  rate county  state
<br/>  &lt;chr&gt;         &lt;int&gt;       &lt;int&gt; &lt;chr&gt;              &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;   &lt;chr&gt;
<br/>1 CN010010          1           1 Autauga County, AL  2009   9.7 autauga AL   
<br/>2 PA011000          1           3 Baldwin County, AL  2009   9.1 baldwin AL   
<br/>3 CN010050          1           5 Barbour County, AL  2009  13.4 barbour AL   
<br/># ℹ 3,215 more rows</code></pre>
</div>
</div>
<p>Prepare the dataset of the counties. It is used to draw county outlines, and is combined with the unemployment dataset.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>d.county <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"county"</span>, </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>                      <span className="at">projection =</span> <span className="st">"albers"</span>, </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>                      <span className="at">parameters =</span> <span className="fu">c</span>(<span className="dv">39</span>, <span className="dv">45</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="co"># rename columns</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">rename</span>(<span className="at">state_name =</span> <span className="st">"region"</span>, <span className="at">county =</span> <span className="st">"subregion"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="co"># create state name abbreviations</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">state =</span> state.abb[<span className="fu">match</span>(.<span className="sc">$</span>state_name, <span className="fu">tolower</span>(state.name))]) <span className="sc">%&gt;%</span> </span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="fu">left_join</span>(unemp, <span className="at">by =</span> <span className="fu">c</span>(<span className="st">"state"</span>, <span className="st">"county"</span>))</span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a></span><br/>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>d.county</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 87,949 × 13
<br/>   long   lat group order state_name county  state id     state_fips county_fips
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;int&gt; &lt;chr&gt;      &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt;       &lt;int&gt;       &lt;int&gt;
<br/>1 0.139 -1.27     1     1 alabama    autauga AL    CN010…          1           1
<br/>2 0.139 -1.27     1     2 alabama    autauga AL    CN010…          1           1
<br/>3 0.138 -1.27     1     3 alabama    autauga AL    CN010…          1           1
<br/># ℹ 87,946 more rows
<br/># ℹ 3 more variables: name &lt;chr&gt;, year &lt;int&gt;, rate &lt;dbl&gt;</code></pre>
</div>
</div>
<p>Dataset of the states, used to draw state outlines.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>d.state <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"state"</span>, </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>                    <span className="at">projection =</span> <span className="st">"albers"</span>, </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>                    <span className="at">parameters =</span> <span className="fu">c</span>(<span className="dv">39</span>, <span className="dv">45</span>)) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>d.state</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 15,537 × 6
<br/>   long   lat group order region  subregion
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;int&gt; &lt;chr&gt;   &lt;chr&gt;    
<br/>1 0.128 -1.31     1     1 alabama &lt;NA&gt;     
<br/>2 0.128 -1.31     1     2 alabama &lt;NA&gt;     
<br/>3 0.127 -1.31     1     3 alabama &lt;NA&gt;     
<br/># ℹ 15,534 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a map outlined by counties, with unemployment rate mapped to colors.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.county <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(long, lat, <span className="at">group =</span> group, <span className="at">fill =</span> rate)) <span className="sc">+</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">colour =</span> <span className="fu">alpha</span>(<span className="st">"white"</span>, .<span className="dv">7</span>), </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>               <span className="at">linewidth =</span> <span className="fl">0.2</span>) <span className="sc">+</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">coord_fixed</span>() </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mapproj_county.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/mapproj_county"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Sketch the outline of states.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">data =</span> d.state, </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>               <span className="at">colour =</span> <span className="st">"white"</span>, <span className="at">fill =</span> <span className="cn">NA</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mapproj_state_outlined.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/mapproj_state_outlined"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Make some final outlook adjustment.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># polish up the theme </span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"bottom"</span>) <span className="sc">+</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  </span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  <span className="co"># adjust the colorbar</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">7</span>, <span className="st">"pt"</span>),</span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"unemployment rate in the U.S."</span>,</span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"top"</span>, <span className="co"># put title on top of the colorbar</span></span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">vjust =</span> .<span className="dv">8</span>))) <span className="sc">+</span></span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>  </span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>  <span className="co"># use the viridis color scale</span></span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">30</span>, <span className="dv">5</span>)) </span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a></span><br/>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mapproj_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/mapproj_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(tidyverse)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(mapproj)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a><span className="co"># load unemployment data</span></span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a><span className="fu">data</span>(unemp, <span className="at">package =</span> <span className="st">"viridis"</span>)</span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a><span className="fu">tibble</span>(unemp)</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a></span><br/>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a><span className="co"># Prepare the dataset of the counties. </span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>d.county <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"county"</span>, </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>                      <span className="at">projection =</span> <span className="st">"albers"</span>, </span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>                      <span className="at">parameters =</span> <span className="fu">c</span>(<span className="dv">39</span>, <span className="dv">45</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>  <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>  <span className="co"># rename columns</span></span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>  <span className="fu">rename</span>(<span className="at">state_name =</span> <span className="st">"region"</span>, <span className="at">county =</span> <span className="st">"subregion"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>  <span className="co"># create state name abbreviations</span></span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">state =</span> state.abb[<span className="fu">match</span>(.<span className="sc">$</span>state_name, <span className="fu">tolower</span>(state.name))]) <span className="sc">%&gt;%</span> </span>
<span id="cb10-17"><a aria-hidden="true" href="#cb10-17" tabindex="-1"></a>  <span className="fu">left_join</span>(unemp, <span className="at">by =</span> <span className="fu">c</span>(<span className="st">"state"</span>, <span className="st">"county"</span>))</span>
<span id="cb10-18"><a aria-hidden="true" href="#cb10-18" tabindex="-1"></a></span><br/>
<span id="cb10-19"><a aria-hidden="true" href="#cb10-19" tabindex="-1"></a>d.county</span>
<span id="cb10-20"><a aria-hidden="true" href="#cb10-20" tabindex="-1"></a></span><br/>
<span id="cb10-21"><a aria-hidden="true" href="#cb10-21" tabindex="-1"></a></span><br/>
<span id="cb10-22"><a aria-hidden="true" href="#cb10-22" tabindex="-1"></a><span className="co"># Dataset of the states, used to draw state outlines. </span></span>
<span id="cb10-23"><a aria-hidden="true" href="#cb10-23" tabindex="-1"></a>d.state <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"state"</span>, </span>
<span id="cb10-24"><a aria-hidden="true" href="#cb10-24" tabindex="-1"></a>                    <span className="at">projection =</span> <span className="st">"albers"</span>, </span>
<span id="cb10-25"><a aria-hidden="true" href="#cb10-25" tabindex="-1"></a>                    <span className="at">parameters =</span> <span className="fu">c</span>(<span className="dv">39</span>, <span className="dv">45</span>)) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb10-26"><a aria-hidden="true" href="#cb10-26" tabindex="-1"></a>d.state</span>
<span id="cb10-27"><a aria-hidden="true" href="#cb10-27" tabindex="-1"></a></span><br/>
<span id="cb10-28"><a aria-hidden="true" href="#cb10-28" tabindex="-1"></a><span className="do">### Visualization</span></span>
<span id="cb10-29"><a aria-hidden="true" href="#cb10-29" tabindex="-1"></a></span><br/>
<span id="cb10-30"><a aria-hidden="true" href="#cb10-30" tabindex="-1"></a><span className="co"># Create a map outlined by counties, with unemployment rate mapped to colors.</span></span>
<span id="cb10-31"><a aria-hidden="true" href="#cb10-31" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.county <span className="sc">%&gt;%</span> </span>
<span id="cb10-32"><a aria-hidden="true" href="#cb10-32" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(long, lat, <span className="at">group =</span> group, <span className="at">fill =</span> rate)) <span className="sc">+</span></span>
<span id="cb10-33"><a aria-hidden="true" href="#cb10-33" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">colour =</span> <span className="fu">alpha</span>(<span className="st">"white"</span>, .<span className="dv">7</span>), </span>
<span id="cb10-34"><a aria-hidden="true" href="#cb10-34" tabindex="-1"></a>               <span className="at">linewidth =</span> <span className="fl">0.2</span>) <span className="sc">+</span></span>
<span id="cb10-35"><a aria-hidden="true" href="#cb10-35" tabindex="-1"></a>  <span className="fu">coord_fixed</span>() </span>
<span id="cb10-36"><a aria-hidden="true" href="#cb10-36" tabindex="-1"></a>p1</span>
<span id="cb10-37"><a aria-hidden="true" href="#cb10-37" tabindex="-1"></a></span><br/>
<span id="cb10-38"><a aria-hidden="true" href="#cb10-38" tabindex="-1"></a><span className="co"># Sketch the outline of states. </span></span>
<span id="cb10-39"><a aria-hidden="true" href="#cb10-39" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb10-40"><a aria-hidden="true" href="#cb10-40" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">data =</span> d.state, </span>
<span id="cb10-41"><a aria-hidden="true" href="#cb10-41" tabindex="-1"></a>               <span className="at">colour =</span> <span className="st">"white"</span>, <span className="at">fill =</span> <span className="cn">NA</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) </span>
<span id="cb10-42"><a aria-hidden="true" href="#cb10-42" tabindex="-1"></a>p2</span>
<span id="cb10-43"><a aria-hidden="true" href="#cb10-43" tabindex="-1"></a></span><br/>
<span id="cb10-44"><a aria-hidden="true" href="#cb10-44" tabindex="-1"></a><span className="co"># Make some final outlook adjustment. </span></span>
<span id="cb10-45"><a aria-hidden="true" href="#cb10-45" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb10-46"><a aria-hidden="true" href="#cb10-46" tabindex="-1"></a>  <span className="co"># polish up the theme </span></span>
<span id="cb10-47"><a aria-hidden="true" href="#cb10-47" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb10-48"><a aria-hidden="true" href="#cb10-48" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"bottom"</span>) <span className="sc">+</span></span>
<span id="cb10-49"><a aria-hidden="true" href="#cb10-49" tabindex="-1"></a>  </span>
<span id="cb10-50"><a aria-hidden="true" href="#cb10-50" tabindex="-1"></a>  <span className="co"># adjust the colorbar</span></span>
<span id="cb10-51"><a aria-hidden="true" href="#cb10-51" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb10-52"><a aria-hidden="true" href="#cb10-52" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb10-53"><a aria-hidden="true" href="#cb10-53" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">7</span>, <span className="st">"pt"</span>),</span>
<span id="cb10-54"><a aria-hidden="true" href="#cb10-54" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"unemployment rate in the U.S."</span>,</span>
<span id="cb10-55"><a aria-hidden="true" href="#cb10-55" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"top"</span>, </span>
<span id="cb10-56"><a aria-hidden="true" href="#cb10-56" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">vjust =</span> .<span className="dv">8</span>))) <span className="sc">+</span></span>
<span id="cb10-57"><a aria-hidden="true" href="#cb10-57" tabindex="-1"></a>  <span className="co"># use the viridis color scale</span></span>
<span id="cb10-58"><a aria-hidden="true" href="#cb10-58" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">30</span>, <span className="dv">5</span>)) </span>
<span id="cb10-59"><a aria-hidden="true" href="#cb10-59" tabindex="-1"></a></span><br/>
<span id="cb10-60"><a aria-hidden="true" href="#cb10-60" tabindex="-1"></a>p3</span></code></pre></div>
</div>
</div>
</div>
</div>
</main>
</div>
</div>
)}