import React, {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom'
import Navigation from './NavSidBar';
import PythonDataWranglingData from '../../data/PythonDataWranglingData'
import {useNavigate, useLocation} from 'react-router-dom'
import {Adsggplot} from "../../Ads"
import logoPandas from "../../media/logo_python_pandas.png"



export default function DWTutorialIndexLayout() {
    const navigate = useNavigate()
    const location = useLocation()    
    const paths = [];
    // For previous and next page functionality
    for(let i= 0; i< PythonDataWranglingData.length; i++){
        if (PythonDataWranglingData[i]['path'] !=''){
            paths.push(PythonDataWranglingData[i]['path'])
        }
    }
    const [currentPage, setCurrentPage] = useState(0)
    useEffect(()=> {
        const path = location.pathname
        const parts = path.split('/')
        const currentRoute = parts[parts.length -1]
        const currentRouteIndex = paths.findIndex((element)=> element == currentRoute)
        setCurrentPage(currentRouteIndex)
        // console.log(currentPage)
    }, [location.pathname]);

    const nextPage = ()=> {
        if(currentPage < paths.length - 1){
            const nextRoute = paths[currentPage +1]
            setCurrentPage(currentPage + 1)
            navigate(nextRoute)     
        }
    };
    const prevPage = ()=> {
        if (currentPage>0){
            const previousRoute = paths[currentPage - 1]
            setCurrentPage(currentPage - 1)
            navigate(previousRoute)
        }
    };
    // For hilighted selection in the side nav bar with previous and next button

    return (
        <div className='main'>
            <div className="row">
        
                <div className="col-md-2 col-sm-12" style={{padding:0}}>
                    <Navigation currentPage={currentPage} />
                </div>              

                <div className="col-md-9 col-sm-12" >
                    <div className="row">
                        <div className='col-md-10 p-r-md-60'>
                            {/* <div className='ads-top'>
                                Save this space for Ads                          
                            </div> */}
                            <div className="main-container w-xl-85 mg-t-50">
                                <div className='flexSpaceBetweenCenter'>
                                    <div className="small-logo">
                                        <img className="logo-diff img-fluid cover-img" src={logoPandas} /></div>
                                    <div className='flexSpaceBetween'>             
                                        <div className='letter-space-min p-t-5 p-b-5 mg-r-50 center-text'>
                                            <a id="prevPage" onClick={()=>prevPage()}  className='button'>&lt; Previous</a>
                                        </div>
                                        <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                            <a id="nextPage" onClick={()=>nextPage()} className='button'>Next &gt;</a>
                                        </div>
                                    </div>
                                </div>
                                <Outlet />
                            </div>
                            <div className='ads-mid'>
                                {/* Save this space for Ads */}
            
                            </div>
                            <div class="main-container flexSpaceBetween w-xl-85">
                                <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                    {/* <a id="prevPage" onClick={()=>prevPage()}  className='button'>&lt; Previous</a> */}
                                    <button onClick={() => prevPage()} className='button'>&lt; Previous</button>
                                </div>
                                <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                    <button onClick={() => nextPage()} className='button'>Next &gt;</button>
                                    {/* <a id="nextPage" onClick={()=>nextPage()} className='button'>Next &gt;</a> */}
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className='col-md-2'>
                        {/* Save this space for Ads */}
                       
                        </div>
                    </div>
                    
                </div>
                
                <div className="col-md-1 col-sm-12">
                    <Adsggplot /> 
                </div>
            </div>

        </div>
    )
}