import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ReorderColumns(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Reorder Columns of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>relocate()</code></strong> to change positions of columns. It uses the same <Link to="../2-select-columns">syntax of column selection</Link> as used in <code>select()</code>. By default, selected columns will be moved to the most left side of the output dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># move "mass" and "height" to the first two columns</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">relocate</span>(mass, height)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>     mass height name           hair_color skin_color 
<br/>    &lt;dbl&gt;  &lt;int&gt; &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;      
<br/>  1    77    172 Luke Skywalker blond      fair       
<br/>  2    75    167 C-3PO          &lt;NA&gt;       gold       
<br/>  3    32     96 R2-D2          &lt;NA&gt;       white, blue
<br/>  4   136    202 Darth Vader    none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Selection helpers are useful tools to select and move blocks of columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># move columns containing "color" to the most left of the output dataset</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">relocate</span>(<span className="fu">contains</span>(<span className="st">"color"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    hair_color skin_color  name           height  mass
<br/>    &lt;chr&gt;      &lt;chr&gt;       &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt;
<br/>  1 blond      fair        Luke Skywalker    172    77
<br/>  2 &lt;NA&gt;       gold        C-3PO             167    75
<br/>  3 &lt;NA&gt;       white, blue R2-D2              96    32
<br/>  4 none       white       Darth Vader       202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># move numerical columns to the left side of the output dataset</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">relocate</span>(<span className="fu">where</span>(is.numeric))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    height  mass name           hair_color skin_color 
<br/>     &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;      
<br/>  1    172    77 Luke Skywalker blond      fair       
<br/>  2    167    75 C-3PO          &lt;NA&gt;       gold       
<br/>  3     96    32 R2-D2          &lt;NA&gt;       white, blue
<br/>  4    202   136 Darth Vader    none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Use <strong><code>.before = X</code></strong> or <strong><code>.after = X</code></strong> to move selected columns before or after column X, respectively (similar syntax as in <Link to="../4-mutate-columns"><code>mutate()</code></Link>). X can be a column name, or refereed to using selection helpers.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># move the "color"-containing columns after the column "name"  </span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">relocate</span>(<span className="fu">contains</span>(<span className="st">"color"</span>), <span className="at">.after =</span> name)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           hair_color skin_color  height  mass
<br/>    &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;        &lt;int&gt; &lt;dbl&gt;
<br/>  1 Luke Skywalker blond      fair           172    77
<br/>  2 C-3PO          &lt;NA&gt;       gold           167    75
<br/>  3 R2-D2          &lt;NA&gt;       white, blue     96    32
<br/>  4 Darth Vader    none       white          202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># move the "color"-containing columns before all other character variables</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">relocate</span>(<span className="fu">contains</span>(<span className="st">"color"</span>), <span className="at">.before =</span> <span className="fu">where</span>(is.character))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    hair_color skin_color  name           height  mass
<br/>    &lt;chr&gt;      &lt;chr&gt;       &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt;
<br/>  1 blond      fair        Luke Skywalker    172    77
<br/>  2 &lt;NA&gt;       gold        C-3PO             167    75
<br/>  3 &lt;NA&gt;       white, blue R2-D2              96    32
<br/>  4 none       white       Darth Vader       202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Use <strong><code>last_col()</code></strong> (with parentheses) to refer to the last column position.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># Move all numerical variables to the end of the dataset</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">relocate</span>(<span className="fu">where</span>(is.numeric), <span className="at">.after =</span> <span className="fu">last_col</span>())</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           hair_color skin_color  height  mass
<br/>    &lt;chr&gt;          &lt;chr&gt;      &lt;chr&gt;        &lt;int&gt; &lt;dbl&gt;
<br/>  1 Luke Skywalker blond      fair           172    77
<br/>  2 C-3PO          &lt;NA&gt;       gold           167    75
<br/>  3 R2-D2          &lt;NA&gt;       white, blue     96    32
<br/>  4 Darth Vader    none       white          202   136
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</main>
</div>
</div>
)}