import React from 'react';
import useCustomEffect from '../../useCustomEffect';

export default function ExcelOpenpyxlStyle() {
    useCustomEffect()
    return (
      <div>
         <div id="header">
                <h1 class="title toc-ignore">Use Python Openpyxl to Change Excel Cell Formats</h1>
        </div>
<div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="kn">from</span> <span class="nn">openpyxl</span> <span class="kn">import</span> <span class="n">load_workbook</span></span>
<span><span class="kn">from</span> <span class="nn">openpyxl.styles</span> <span class="kn">import</span> <span class="n">Font</span><span class="p">,</span> <span class="n">PatternFill</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>We've learned how to use Openpyxl package to read and modify values in Excel worksheet (link to the post). Next, let's continue to learn how to use Openpyxl to add styles and color to cells.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Load-Excel-files-using-Openpyxl">Load Excel files using Openpyxl<a class="anchor-link" href="#Load-Excel-files-using-Openpyxl">¶</a></h3>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [50]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">file</span> <span class="o">=</span> <span class="s2">"sample dataset.xlsx"</span></span>
<span><span class="n">workbook</span> <span class="o">=</span> <span class="n">load_workbook</span><span class="p">(</span><span class="n">file</span><span class="p">)</span></span>
<span><span class="c1"># select 'Sheet1' from the Excel file</span></span>
<span><span class="n">worksheet</span> <span class="o">=</span> <span class="n">workbook</span><span class="p">[</span><span class="s1">'Sheet1'</span><span class="p">]</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Change-font-styles">Change font styles<a class="anchor-link" href="#Change-font-styles">¶</a></h3><p>Use Font() method to set all font related styles. Apply font sytles to cell A2.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [27]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">font</span> <span class="o">=</span> <span class="n">Font</span><span class="p">(</span><span class="n">name</span><span class="o">=</span><span class="s1">'Calibri'</span><span class="p">,</span></span>
<span>            <span class="n">size</span><span class="o">=</span><span class="mi">13</span><span class="p">,</span> <span class="c1">#set font size</span></span>
<span>            <span class="n">bold</span><span class="o">=</span><span class="kc">True</span><span class="p">,</span> <span class="c1">#set weight to bold</span></span>
<span>            <span class="n">italic</span><span class="o">=</span><span class="kc">False</span><span class="p">,</span></span>
<span>            <span class="n">vertAlign</span><span class="o">=</span><span class="kc">None</span><span class="p">,</span></span>
<span>            <span class="n">underline</span><span class="o">=</span><span class="s1">'none'</span><span class="p">,</span></span>
<span>            <span class="n">strike</span><span class="o">=</span><span class="kc">False</span><span class="p">,</span></span>
<span>            <span class="n">color</span><span class="o">=</span><span class="s1">'fd0101'</span><span class="c1">#set color to red</span></span>
<span>           <span class="p">)</span></span>

<span><span class="n">worksheet</span><span class="p">[</span><span class="s1">'A2'</span><span class="p">]</span><span class="o">.</span><span class="n">font</span> <span class="o">=</span> <span class="n">font</span></span>

<span><span class="n">workbook</span><span class="o">.</span><span class="n">save</span><span class="p">(</span><span class="n">file</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Change-cell-Fill-color">Change cell Fill color<a class="anchor-link" href="#Change-cell-Fill-color">¶</a></h3><p>Use PatternFill() method to change cell Fill related styles. Fill cell B2 background with red color.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [51]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">fill</span><span class="o">=</span> <span class="n">PatternFill</span><span class="p">(</span><span class="n">start_color</span><span class="o">=</span><span class="s1">'FFFFFFFF'</span><span class="p">,</span> <span class="c1">#set fill color to red</span></span>
<span>                   <span class="n">end_color</span><span class="o">=</span><span class="s1">'fd0101'</span><span class="p">,</span> <span class="c1">#set fill color to red</span></span>
<span>                   <span class="n">fill_type</span><span class="o">=</span><span class="s1">'solid'</span><span class="p">)</span></span>

<span><span class="n">worksheet</span><span class="p">[</span><span class="s1">'B2'</span><span class="p">]</span><span class="o">.</span><span class="n">fill</span> <span class="o">=</span> <span class="n">fill</span></span>

<span><span class="n">workbook</span><span class="o">.</span><span class="n">save</span><span class="p">(</span><span class="n">file</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>

      </div>
    );
  }
