import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrSubset(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Select Strings Containing a Matched Pattern</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_subset()</code></strong> is very similar to <Link to="../5-str-detect"><code>str_detect()</code></Link>, but instead of returning a logical vector of TRUE and FALSE, it returns the string elements that contain the matched pattern.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>fruit <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apple"</span>, <span className="st">"banana"</span>, <span className="st">"pear"</span>, <span className="st">"kiwi"</span>)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># Return fruit names containing letter "e"</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">str_subset</span>(fruit, <span className="at">pattern =</span> <span className="st">"e"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apple" "pear" </code></pre>
</div>
</div>
<p>Regular expression is often used to define a pattern. For instance, the caret sign <code>^</code> indicates a pattern at the <em>start</em> of a string, and <code>$</code> indicates a pattern at the <em>end</em> of a string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># return elements that are started with letter "a"</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">str_subset</span>(fruit, <span className="at">pattern =</span> <span className="st">"^a"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apple"</code></pre>
</div>
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># return elements that are ended with letter "a"</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="fu">str_subset</span>(fruit, <span className="at">pattern =</span> <span className="st">"a$"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "banana"</code></pre>
</div>
</div>
<p>We can select elements that <em>don’t</em> match the specified pattern with <code>negate = TRUE</code> (which defaults to FALSE).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># return elements not ended with letter "a". </span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">str_subset</span>(fruit, <span className="at">pattern =</span> <span className="st">"a$"</span>, <span className="at">negate =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apple" "pear"  "kiwi" </code></pre>
</div>
</div>
<p>The missing value <code>NA</code> is <em>not</em> a match to any pattern.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_subset</span>(<span className="fu">c</span>(<span className="cn">NA</span>, <span className="st">"apple"</span>, <span className="st">"bee"</span>), <span className="st">"e"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apple" "bee"  </code></pre>
</div>
</div>
<p>🎁 <strong>Bonus knowledge !</strong></p>
<p><code>str_subset()</code> is basically a wrapper (a simplified function) around <code>x[str_detect(x, pattern)]</code>. However, it handles the <code>NA</code> values slightly different. See example below.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>X <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="cn">NA</span>, <span className="st">"abc"</span>, <span className="st">"xyz"</span>)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a></span><br/>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="co"># NA is included in the output</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>X [ <span className="fu">str_detect</span>(X, <span className="st">"a"</span>) ]</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] NA    "abc"</code></pre>
</div>
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="co"># NA is removed from the output</span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">str_subset</span>(X, <span className="st">"a"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "abc"</code></pre>
</div>
</div>
<p><code>str_subset()</code> is equivalent to the base R function <code>grep(pattern, x, value = TRUE)</code>. A major difference between these two functions is the default order of arguments: the string vector is the first argument in all functions of the <code>stringr</code> package, but is the second argument in <code>grep()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">grep</span>(<span className="at">pattern =</span> <span className="st">"a$"</span>, <span className="at">x =</span> fruit, <span className="at">value =</span> <span className="cn">TRUE</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "banana"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}