import React from 'react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AdsggplotForBlog } from "../Ads";
import { Link } from 'react-router-dom';

 
export default function BlogLayOut() {
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [headerStyle, setHeaderStyle] = useState({top: '80px'});

    const handleScroll = () => {
        if (window.innerWidth >= 758) {
            const currentScrollPos = window.scrollY;
            if (prevScrollPos > currentScrollPos) {
                setHeaderStyle({top: '80px'})
            } else {
                setHeaderStyle({top: '0px'});
            }
            setPrevScrollPos(currentScrollPos);
        }
       
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);
    return(
        <div className='blog-main-container'>
            <div className='blog-layout-contianer'>
                <div className='blog-button'>
                    <Link to="../blog" className='back-button stick-top-left' style={headerStyle}>
                        <i className="fas fa-arrow-left large-bold-arrow"></i> Blog
                    </Link>
                </div>
                <div className="blog-content">
                    <Outlet />

                </div>
                <div id="ads-in-blog">
                    <AdsggplotForBlog />
                </div>
            </div>

        </div>
        // <div className='main'>
        //     <div classname="row">
        //         <div className="col-md-1 col-sm-12 p-l-md-50" >
        //                 {/* {window.innerWidth >= 758 && (
        //                     <Link to="../blog" className='back-button stick-top-left' style={headerStyle}>
        //                         <i className="fas fa-arrow-left large-bold-arrow"></i> Blog
        //                     </Link>
        //                 )} */}
        //                 {window.innerWidth >= 758 && (
        //                 <Link to="../blog" className='back-button stick-top-left' style={headerStyle}>
        //                         &lt; Blog
        //                 </Link>
        //                 )}
        //         </div>
        //         <div className="col-md-11 col-sm-12 p-l-md-50" id='blog'>
        //             <div className="row">
        //                 <div className="col-md-9 p-r-md-100 mg-t-15">
        //                     {/* <div className='ads-top'>
        //                         Save this space for Ads                        
        //                     </div> */}
        //                     <div className="main-container w-xl-80 blog-article">
        //                         <Outlet />
                                
        //                     </div>
        //                     <div className='ads-mid'>
        //                         {/* Save this space for Ads */}
            
        //                     </div>

        //                 </div>
        //                 <div className='col-md-2'>
        //                     {/* Save this space for Ads */}
        //                     <Adsggplot />   
        //                 </div>


        //             </div>
                    
        //         </div>
               
        //     </div>

        // </div>
    )
}