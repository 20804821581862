export function LoadJsScripts(scriptUrls) {
   // Loop through the script URLs and create script elements for each
    scriptUrls.forEach((scriptUrl) => {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true; // You can set other attributes as needed
    
        // Define a callback function to execute when the script is loaded
        script.onload = () => {
            console.log(`Script ${scriptUrl} has been loaded`);
        };
    
        // Append the script element to the <head> of the document
        document.head.appendChild(script);
        });
}

export function LoadCssScripts(cssUrls) {
    cssUrls.forEach((url) => {
        const link = document.createElement('link');
        link.href = url;
        link.rel = "stylesheet";
        link.async = true; // You can set other attributes as needed
    
        // Define a callback function to execute when the script is loaded
        link.onload = () => {
            console.log(`Script ${url} has been loaded`);
        };
    
        // Append the script element to the <head> of the document
        document.head.appendChild(link);
        });
}