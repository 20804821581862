import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrTo(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Convert Letters to Upper and Lower Case</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>A family of the <strong><code>str_to_*()</code></strong> functions convert letters to upper and lower case.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="st">"I sAw a pIeCe oF SizZlinG bAcOn."</span></span></code></pre></div>
</div>
<p>Convert all letters to upper case.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_to_upper</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "I SAW A PIECE OF SIZZLING BACON."</code></pre>
</div>
</div>
<p>Convert all letters to lower case.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_to_lower</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "i saw a piece of sizzling bacon."</code></pre>
</div>
</div>
<p>Convert to title case, where only the first letter of each word is capitalized.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">str_to_title</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "I Saw A Piece Of Sizzling Bacon."</code></pre>
</div>
</div>
<p>Convert to sentence case, where only the first letter is capitalized.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">str_to_sentence</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "I saw a piece of sizzling bacon."</code></pre>
</div>
</div>
</main>
</div>
</div>
)}