import React from 'react'; 
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkAvg(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="avg"><code>avg()</code><a class="anchor-link" href="#avg">¶</a></h3><p>The <code>avg()</code> function in Apache Spark is an aggregation function used to calculate the average value of a numeric column in a DataFrame.<br/><br/>
<code>avg()</code> can be used on its own to compute the average of a column, or in conjunction with <code>groupBy()</code> to calculate the average for each group.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session-and-sample-DataFrame">Create Spark Session and sample DataFrame<a class="anchor-link" href="#Create-Spark-Session-and-sample-DataFrame">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>
<span><span class="kn">from</span> <span class="nn">pyspark.sql.functions</span> <span class="kn">import</span> <span class="n">avg</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"avgExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>

<br /><span><span class="c1"># Sample DataFrame</span></span>
<span><span class="n">data</span> <span class="o">=</span> <span class="p">[(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"classA"</span><span class="p">,</span><span class="mi">85</span><span class="p">),</span> <span class="p">(</span><span class="s2">"Anna"</span><span class="p">,</span><span class="s2">"classA"</span><span class="p">,</span> <span class="mi">90</span><span class="p">),</span> <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span><span class="s2">"classA"</span><span class="p">,</span> <span class="mi">88</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"classB"</span><span class="p">,</span><span class="mi">90</span><span class="p">),</span> <span class="p">(</span><span class="s2">"Anna"</span><span class="p">,</span><span class="s2">"classB"</span><span class="p">,</span> <span class="mi">80</span><span class="p">),</span> <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span><span class="s2">"classB"</span><span class="p">,</span> <span class="mi">90</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"classC"</span><span class="p">,</span><span class="mi">82</span><span class="p">),</span> <span class="p">(</span><span class="s2">"Anna"</span><span class="p">,</span><span class="s2">"classC"</span><span class="p">,</span> <span class="mi">94</span><span class="p">),</span> <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span><span class="s2">"classC"</span><span class="p">,</span> <span class="mi">92</span><span class="p">),</span></span>
<span>       <span class="p">]</span></span>
<span><span class="n">columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Name"</span><span class="p">,</span> <span class="s2">"Class"</span><span class="p">,</span> <span class="s2">"Grade"</span><span class="p">]</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="p">,</span> <span class="n">columns</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+------+------+-----+
<br />|  Name| Class|Grade|
<br />+------+------+-----+
<br />| James|classA|   85|
<br />|  Anna|classA|   90|
<br />|Robert|classA|   88|
<br />| James|classB|   90|
<br />|  Anna|classB|   80|
<br />|Robert|classB|   90|
<br />| James|classC|   82|
<br />|  Anna|classC|   94|
<br />|Robert|classC|   92|
<br />+------+------+-----+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-avg-function-to-compute-the-average-of-a-numeric-column">Example: Use <code>avg()</code> function to compute the average of a numeric column<a class="anchor-link" href="#Example:-Use-avg-function-to-compute-the-average-of-a-numeric-column">¶</a></h4><ul>
<li><code>avg("Grade")</code>: it calculates the average of the entire <strong>Grade</strong> column.</li>
<li><code>alias("Average Grade")</code>: it renames the resulted average column to <strong>Average Grade</strong>.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">select</span><span class="p">(</span><span class="n">avg</span><span class="p">(</span><span class="s2">"Grade"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"Average Grade"</span><span class="p">))</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+-----------------+
<br />|    Average Grade|
<br />+-----------------+
<br />|87.88888888888889|
<br />+-----------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-avg-with-groupBy-to-calculate-averages-of-each-group-in-a-column">Example: Use <code>avg()</code> with <code>groupBy()</code> to calculate averages of each group in a column<a class="anchor-link" href="#Example:-Use-avg-with-groupBy-to-calculate-averages-of-each-group-in-a-column">¶</a></h4><ul>
<li><code>groupBy("Class")</code>: this functions groups the data by the <strong>Class</strong> column.</li>
<li><code>avg("Grade")</code>: it calculates the average grade of each class based on the group by column <strong>Class</strong>.</li>
<li><code>agg()</code>: <code>agg()</code> function is used to chain <code>avg()</code> function together with <code>alias()</code> function.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">grouped_data</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">groupBy</span><span class="p">(</span><span class="s2">"Class"</span><span class="p">)</span><span class="o">.</span><span class="n">agg</span><span class="p">(</span><span class="n">avg</span><span class="p">(</span><span class="s2">"Grade"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"Average Grade"</span><span class="p">))</span></span>
<span><span class="n">grouped_data</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+------+-----------------+
<br />| Class|    Average Grade|
<br />+------+-----------------+
<br />|classA|87.66666666666667|
<br />|classB|86.66666666666667|
<br />|classC|89.33333333333333|
<br />+------+-----------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}