import {Link} from "react-router-dom"
import { useEffect, useState, useContext } from "react";
import { UserContext } from "./UserContext";
import dblogo from "./media/logo-databrewer-288.png"
import logoDb from "./media/logo_DB_databrewer.png"
import logoDbWebp from "./media/logo_DB_databrewer.webp"


export default function Header() {
    // useContext is to use global state
    const {setUserInfo, userInfo} = useContext(UserContext);


//     useEffect(() => {
//         fetch('http://localhost:4002/profile', {
//             credentials:'include',
//         }).then(response => {
//             response.json().then(userInfoRes => {
//                 setUserInfo(userInfoRes);
                
//             })
//         })
// }, []); // [] will make the app run the function inside useEffect everytime we moun this component 

    // const username = userInfo?.username // Safely accesses username or results in undefined
    
    // function logout() {
    //     fetch("http://localhost:4002/logout", {
    //         method: "POST",
    //         credentials:'include',
    //     }).then(response => {
    //         if (response.ok) {
    //             setUserInfo(null);
    //         } else {
    //             console.error("log out failed with unexpected error")
    //         }
    //     })
       
    // }



    function expand() {
        if (window.innerWidth < 541) {
            var x = document.getElementById("subtopnav");
            if (x.className === "subtopnav") {
              x.className += " responsive";
            } else {
              x.className = "subtopnav";
            }
    
            var y = document.getElementById("headerTop");
            if (y.className === "flexStart-flexstart header-topnav") {
                y.className += " responsive"
            } else {
                y.className = "flexStart-flexstart header-topnav"
            }
          
        }
     
     
    }    


    // // for sticky header top nav bar
    // let prevScrollpos = window.scrollY;
    // useEffect(() => {
    //     const handleScroll = () => {
    //         let currentScrollPos = window.scrollY;
    //         if (prevScrollpos > currentScrollPos) {
    //             document.getElementById("headerTop").style.top = "0";
    //         } else {
    //             document.getElementById("headerTop").style.top = "-210px";
    //         }
    //         prevScrollpos = currentScrollPos;
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    return (
        <div>
            {/* <div className="topnav" id="myTopnav"> */}
                {/* <Link to="/" className="logo">Rtistry Studio</Link> 
                <Link to="/python-tutorial">Python Tutorial</Link>
                <Link to="/python">Python FAQ</Link>          
                <Link to="/R-Gallery">R Visual Gallery</Link> 
                <Link to="/R-programming-frequently-asked-questions">R-Programming FAQ</Link>
                <Link to="/eBook/sliding-you-way-to-ggplot2-mastery">ggplot2 eBook</Link>
                    
                <Link to="contact">About</Link>
                 */}
           
            {/* <a>Rtistry Studio</a>
            <a>Rtistry Studio</a>
            <a>Rtistry Studio</a>
            <a>Rtistry Studio</a> */}
            {/* <div className="dropdown">
                <button className="dropbtn">Dropdowns 
                <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                    <a href="#">Link 3</a>
                </div>
            </div>  */}
           
            {/* <header className='header'>             
                <Link to="/" className="logo">Rtistry Studio</Link>            
                <nav className="header-right">
                    <Link to="/posts" className="nav-item">Gallery</Link>
                    <Link to="/faq" className="nav-item">R-FAQ</Link>
                    <Link to="/ggplot2-book" className="nav-item">ggplot2 eBook</Link>
                    <Link to="contact" className="nav-item">Contact</Link> */}
                    {/* {username && (
                        <>
                            <Link to="/create" className="user">Create new post</Link>
                            <Link to="/profile" className="user">Hello! {username}</Link>  
                            <a className="logging" onClick={logout}>Logout</a>
                        </>
                    )}
                    {!username && (
                        <>
                            <Link to="/login" className="logging">Login</Link>
                            <Link to="/register" className="logging">Register</Link>
                        </>
                    )}     */}
                {/* </nav>
            </header> */}
            {/* </div> */}
            {/* <div className="">
                <div id='logo' style={{display:'none'}}>
                    <Link to="/" className="logo"><span><img src={dblogo} className='width-5'></img></span><span style={{color:'#315866'}}>Data</span><span style={{color:'#D5732E'}}>Brewer</span></Link> 
                </div> 
                <div className="subtopnav" id="subtopnav" style={{display:'none'}}>              
                    <ul>
                        <li>
                            <div className='dropdown'>
                                <Link to=".." className='dropbtn'>Home</Link>
                            </div>      
                        </li>
                        <li>
                            <div className='dropdown'>
                                <button className='dropbtn'>
                                    Python <i class="fa fa-caret-down"></i>
                                </button>
                                <div className='dropdown-content'>
                                    <Link to="/python-tutorial/getting-started" onClick={expand}>Python Tutorial</Link>
                                    <Link to="/python-data-wrangling/introduction-of-Pandas" onClick={expand}>Data Wrangling</Link>             
                                </div>                 
                            </div>   
                        </li>
                        <li>
                            <div className='dropdown'>
                                <Link to="/pyspark-tutorial/getting-started" className='dropbtn'>PySpark</Link>
                            </div>                    
                        </li>
                        <li>
                            <div className='dropdown'>
                                <button className='dropbtn'>
                                R <i class="fa fa-caret-down"></i>
                                </button>
                                <div className='dropdown-content'>

                                    <Link to="R/data-wrangling" onClick={expand} className="dropLevel1">Data Wrangling
                                    </Link>
                                    <div className="extra-sublist">
                                            <Link to="R/data-wrangling/dplyr/0-introduction">dplyr</Link>
                                            <Link to="R/data-wrangling/stringr/0-introduction">stringr</Link>
                                            <Link to="R/data-wrangling/regular-expression/0-introduction">regular expression</Link>
                                    </div>
                                
                                    <Link to="R/gallery" onClick={expand}>Gallery</Link> 
                                    <Link to="R/visualization/introduction" onClick={expand}>Visualization</Link>
                                </div>
                            </div>      
                        </li>
        
                        <li>
                            <div className='dropdown'>
                                <Link to="R/eBook/flash-to-r-ggplot2-mastery" className='dropbtn'>eBook</Link>
                            </div>                    
                        </li>
                        <li>
                            <div className="drop-icon"> 
                                <Link href="##" className="dropicon" onClick={expand} >&#9776;</Link>
                            </div>
                            
                        </li>
                    </ul>

                </div>
            </div> */}

            <div className='flexStart-flexstart header-topnav' id='headerTop'>              
                {/* <div className="flexStart-center">
                    <div className='mg-l-20 mg-r-5' style={{width:'40px'}}>
                        <img src={dblogo} className=" "></img>
                    </div>
                    <div className=''>
                        <Link to="/" className="logo fs-16 logo-font">                             
                            <span style={{color:'#315866'}}>Data</span><span style={{color:'#D5732E'}}>Brewer</span>
                        </Link>  
                    </div>
                </div>             */}
                <Link to='./' className='mg-l-m-50 mg-r-5'>
                    <picture>
                        <source type="image/webp" srcset={logoDbWebp} />
                        <img src={logoDb}  style={{width:'170px'}} />
                    </picture>
                    
                </Link>

                <div className="subtopnav" id='subtopnav'>                       
                    <ul className='flexStart'>                      
                        <li>
                            <div className='dropdown'>
                                <Link to=".." className='dropbtn' onClick={expand}>Home</Link>
                            </div>      
                        </li>
                        <li>
                            <div className='dropdown' id='python'>
                                <button className='dropbtn' >
                                    Python <i class="fa fa-caret-down"></i>
                                </button>
                                <div className='dropdown-content' id='dropdown-python'>
                                    <Link to="/python-tutorial/getting-started" onClick={expand}>Python Tutorial</Link>
                                    <Link to="/python-data-wrangling/introduction-of-Pandas" onClick={expand}>Data Wrangling</Link>             
                                </div>                 
                            </div>   
                        </li>
                        <li>
                            <div className='dropdown'>
                                <Link to="/pyspark-tutorial/getting-started" className='dropbtn' onClick={expand}>PySpark</Link>
                            </div>                    
                        </li>
                        <li>
                            <div className='dropdown' id='R'>
                                <Link to='./R' className='header-r' >
                                    <button className='dropbtn'>
                                            R <i class="fa fa-caret-down"></i>
                                    </button>
                                </Link>                                   
                                <div className='dropdown-content' id='dropdown-R'>
                                    <Link to="R/data-wrangling"  className="dropLevel1" onClick={expand}>Data Wrangling
                                    </Link>
                                    <div className='extra-sublist'>
                                            <Link to="R/data-wrangling/dplyr/0-introduction">dplyr</Link>
                                            <Link to="R/data-wrangling/stringr/0-introduction">stringr</Link>
                                            <Link to="R/data-wrangling/regular-expression/0-introduction">regular expression</Link>
                                            <Link to="R/data-wrangling/tidyr/introduction">tidyr</Link>
                                            <Link to="R/data-wrangling/purrr/introduction">purrr</Link>
                                            <Link to="R/data-wrangling/tibble/introduction">tibble</Link>
                                    </div>
                                
                                    <Link to="R/gallery" onClick={expand}>Gallery</Link> 
                                    <Link to="R/visualization/introduction" onClick={expand}>Visualization</Link>
                                </div>
                            </div>      
                        </li>  
                        <li>
                            <div className='dropdown'>
                                <Link to="blog" className='dropbtn' onClick={expand}>Blog</Link>
                            </div>                    
                        </li>    
                        <li>
                            <div className='dropdown'>
                                <Link to="R/eBook/flash-to-r-ggplot2-mastery" className='dropbtn' onClick={expand}>eBook</Link>
                            </div>                    
                        </li>
                        <li>
                            <div className="drop-icon"> 
                                <Link href="##" className="dropicon" onClick={expand} >&#9776;</Link>
                            </div>
                            
                        </li>
                    </ul>

                </div>


            </div>


        </div>
       
       
    )
}