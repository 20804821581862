import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LollipopDonutHorsepower from '../graphics/horsepower_lollipop_theme_completed.png'; 
import imgGgplot2LollipopDonutHorsepowerWebp from '../graphics/horsepower_lollipop_theme_completed.webp'; 
export default function Ggplot2LollipopDonutHorsepower(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Donut-Lollipops with ggplot2 to Visualize Cars Horsepower</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2LollipopDonutHorsepowerWebp} />
    <img className="cover-img" src={imgGgplot2LollipopDonutHorsepower} />
  </picture>

<p>In the <Link to="../ggplot2-bar-polar-coord-horsepower">earlier article</Link>, we visualized the cars’ engine horsepower with a reordered barplot in a polar coordinate. <strong>In this work, we’ll update the bars into lollipops.</strong> These wheels spoke-like geometrics may further jazz the graphic up. <strong>Compared with the prior work, new technical highlights include:</strong></p>
<ul>
<li><a href="#lollipop_plot">Create a lollipop plot.</a></li>
<li><a href="#format_enriched_text">Add format-enriched plot title.</a></li>
<li><a href="#donut_hole">Generate a donut plot in polar coordinate.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="data-cleanup">
<h3 className="anchored" data-anchor-id="data-cleanup">Data cleanup</h3>
<p>In this visualization, we’ll use the <code>mtcars</code> dataset which is built in base R.</p>
<ul>
<li><p><strong>Turn the numeric variable <code>cyl</code> (cylinder number ) to a categorical variable.</strong> This way, when <code>cyl</code> is mapped to <code>color</code>, the cylinder number (4, 6, or 8) will be appropriately displayed as distinct colors on a <em>discrete</em> color scale, instead of on a less readable continuous (gradient) color scale.</p></li>
<li><p><strong>Rearrange the rows (car names) by order of horsepower (<code>hp</code>) to reorder the lollipops.</strong> More details about reordering of graphical elements in ggplot2 can be found in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide</Link>.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">colour =</span> <span className="st">"snow3"</span>)))</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a></span><br/>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>mtcars2 <span className="ot">&lt;-</span> mtcars <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span>  </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">rownames</span>(mtcars), <span className="at">cyl =</span> <span className="fu">factor</span>(cyl)) <span className="sc">%&gt;%</span> </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="co"># reorder cars by horsepower</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">arrange</span>(hp) <span className="sc">%&gt;%</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">factor</span>(car, <span className="at">levels =</span> car)) </span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="fu">head</span>(mtcars2, <span className="dv">4</span>) <span className="co"># ready for visualization</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 12
<br/>    mpg cyl    disp    hp  drat    wt  qsec    vs    am  gear  carb car         
<br/>  &lt;dbl&gt; &lt;fct&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;fct&gt;       
<br/>1  30.4 4      75.7    52  4.93  1.62  18.5     1     1     4     2 Honda Civic 
<br/>2  24.4 4     147.     62  3.69  3.19  20       1     0     4     2 Merc 240D   
<br/>3  33.9 4      71.1    65  4.22  1.84  19.9     1     1     4     1 Toyota Coro…
<br/>4  32.4 4      78.7    66  4.08  2.2   19.5     1     1     4     1 Fiat 128    </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="lollipop_plot">Create an ordered lollipop plot.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> mtcars2 <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> car, <span className="at">y =</span> hp, <span className="at">color =</span> cyl)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="fu">aes</span>(<span className="at">xend =</span> car, <span className="at">yend =</span> <span className="dv">0</span>), <span className="at">linewidth =</span> <span className="dv">1</span>)</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="clip_off"><strong>Transform the linear coordinate to a polar coordinate.</strong></span></p>
<ul>
<li><p><code>theta = "x"</code> (default) maps the <code>x</code> aesthetic to angles in the polar coordinate. In contrast, <code>theta = "y"</code> maps the <code>y</code> aesthetic to angles, and is used to create pie and donut charts.</p></li>
<li><p>Use <code>clip = "off"</code> to display graphical elements that extend beyond the plot boundary (often done together with plot margin adjustment; see more in the step of creating plot <a href="#plot_margin"><code>p5</code></a>).</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"x"</span>, <span className="at">clip =</span> <span className="st">"off"</span>)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_polar_coordinate.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_polar_coordinate"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>We aim to label the car names in line with the orientation of associated lollipops. To do this, first we need to <strong>calculate the angles by which the texts need to be rotated</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(mtcars2)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>angle_perSlice <span className="ot">=</span> <span className="dv">360</span> <span className="sc">/</span> n</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a></span><br/>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a><span className="co"># degrees of angle to rotate per lollipop / text</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>angle_perSlice</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 11.25</code></pre>
</div>
</div>
<p><strong>Create a vector of angles of successive rotations for car names.</strong> The rotation starts from the first lollipop (i.e., the most left in linear coordinate) - Honda Civic, with an initial angle of 81° (by visual estimate of <code>p2</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>angles_to_rotate <span className="ot">&lt;-</span> <span className="dv">81</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span>(n<span className="dv">-1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>angles_to_rotate</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"> [1]   81.00   69.75   58.50   47.25   36.00   24.75   13.50    2.25   -9.00
<br/>[10]  -20.25  -31.50  -42.75  -54.00  -65.25  -76.50  -87.75  -99.00 -110.25
<br/>[19] -121.50 -132.75 -144.00 -155.25 -166.50 -177.75 -189.00 -200.25 -211.50
<br/>[28] -222.75 -234.00 -245.25 -256.50 -267.75</code></pre>
</div>
</div>
<p><strong>Add labels of car names.</strong> The x-axis from a linear coordinate collapses into the central point in a polar coordinate. Accordingly, mapped with <code>x = car</code> (inherited from the <code>ggplot</code> line), all texts are headed towards the center of the circle. In addition, the texts are left aligned (by <code>hjust = 0</code>) to the <code>y</code> position that is 40 units above the height of the lollipop (<code>y = hp + 40</code>), with respective rotations specified in <code>angles_to_rotate</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate,</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_angle.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_angle"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>In the above plot, texts on the right half look nicely rotated, but those on the left half are turned upside down, and are difficult to read. The following edit aims to <strong>readjust the rotation angles of texts on the left side</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># same text angles for the first 17 cars (right half) from Honda civic to Merc 280C</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>angles1 <span className="ot">&lt;-</span> angles_to_rotate[<span className="dv">1</span><span className="sc">:</span><span className="dv">17</span>] </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a><span className="co"># update text angles for the left half cars from Dodge to Maserati</span></span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>angles2 <span className="ot">&lt;-</span> <span className="dv">71</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span> (n <span className="sc">-</span> <span className="dv">17</span> <span className="sc">-</span><span className="dv">1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a></span><br/>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a><span className="co"># an updated angle vector</span></span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>angles_to_rotate_new <span className="ot">&lt;-</span> <span className="fu">c</span>(angles1, angles2)</span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a></span><br/>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_new_angles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_new_angles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Now the text rotation angle are well adjusted. However, as all texts are left aligned to <code>y = hp + 40</code>, the left-side texts are shifted to the right, and mostly overlapped with the lollipops. To fix this, we’ll instead <strong><em>right</em>-justify texts on the left side</strong>, while keep the left justification for texts on the right side.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># trial 3: fix the alignment</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_justification.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_justification"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Much better - now add <strong>a few more touch on the texts font and size</strong>. Note that the last (largest) lollipop’s text label “Maserati Bora” is not displayed completely. We’ll fix it at the last step.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>            <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ),</span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>            <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_text_font.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_text_font"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="format_enriched_text"><strong>Add plot title</strong> at the center of the graphic, here with <strong>three major technical highlights:</strong></span></p>
<p><strong>(1)</strong> <strong>Load fonts from the <Link to="https://fonts.google.com/">Google Font Repository</Link></strong> using the <code>showtext</code> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="co"># load google fonts</span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="st">"Anton"</span>, <span className="st">"Anton"</span>)</span></code></pre></div>
</div>
<p><strong>(2)</strong> <strong>Format the text using <code>ggtext</code> package.</strong> It invovles two steps:</p>
<ul>
<li><p>Incorporate HTML in the title string. The simple line breaks in the script (made by pressing <em>Enter</em> or <em>return</em> key) are <em>invisible</em> in the rendered graphics. Instead, use tag <code>&lt;br&gt;</code> to make indicated line breaks in the rendered graphic.</p></li>
<li><p>To render the HTML markup, inside the <code>annotate</code> function, replace <code>geom = "text"</code> with <code>geom = "richtext"</code>. (Alternatively, as in <Link to="../ggplot2-segment-US-president-terms">this example</Link>, if the text can be customized using the <code>theme</code> syntax, you can replace <code>element_text()</code> with <code>element_markdown()</code>, or replace with <code>element_textbox_simple()</code> for automatic text wrapping)</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="co"># format the title </span></span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a></span><br/>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>mytitle <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>  <span className="st">'**&lt;span style="font-family:Anton;"&gt;AUTOMABILES&lt;br&gt;</span></span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a><span className="st">  HORSEPOWER&lt;br&gt;&lt;/span&gt;</span></span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a><span className="st">  with &lt;span style="color:orange;font-family:Anton;"&gt;4&lt;/span&gt;,</span></span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a><span className="st">  &lt;span style="color:steelblue;font-family:Anton;"&gt;**6**&lt;/span&gt;,</span></span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a><span className="st">  &lt;span style="color:red2;font-family:Anton;"&gt;8&lt;/span&gt;&lt;br&gt;</span></span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a><span className="st">  cylinders**'</span></span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>)</span></code></pre></div>
</div>
<p><span id="donut_hole"><strong>(3)</strong> <strong>Create a donut hole:</strong> as the text <code>y</code> value is negative (e.g., -300) in linear coordinate, it correspondingly generates a central donut hole in the polar coordinate.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"richtext"</span>,</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>           <span className="at">label =</span> mytitle,</span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">300</span>, <span className="at">hjust =</span> .<span className="dv">48</span>, </span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">4</span>, <span className="co"># family = "Anton",</span></span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>           <span className="at">label.color =</span> <span className="cn">NA</span>, <span className="at">fill =</span> <span className="cn">NA</span>) <span className="sc">+</span></span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>  <span className="co"># udpate the color scale to be consistent with title colors</span></span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"orange"</span>, <span className="st">"steelblue"</span>, <span className="st">"red2"</span>)) </span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_Google_fonts_richtext_donut.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_Google_fonts_richtext_donut"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="plot_margin"><strong>A final touch on the theme:</strong></span></p>
<ul>
<li>Apply the theme of void to make a clean background.</li>
<li>Increase the margin at the top and bottom of the plot. Together with the prior <a href="#clip_off"><code>clip = "off"</code></a>, this displays completely the text “Maserati” at the top of the plot.</li>
<li>The horizontal <code>hline</code> at y = 0 from a linear coordinate is transformed into a circle at the base of lollipops in the polar coordinate.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">30</span>, <span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>))  <span className="sc">+</span></span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>)</span>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_lollipop_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="co"># packages</span></span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb17-4"><a aria-hidden="true" href="#cb17-4" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb17-5"><a aria-hidden="true" href="#cb17-5" tabindex="-1"></a></span><br/>
<span id="cb17-6"><a aria-hidden="true" href="#cb17-6" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb17-7"><a aria-hidden="true" href="#cb17-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb17-8"><a aria-hidden="true" href="#cb17-8" tabindex="-1"></a>  <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">colour =</span> <span className="st">"snow3"</span>)))</span>
<span id="cb17-9"><a aria-hidden="true" href="#cb17-9" tabindex="-1"></a></span><br/>
<span id="cb17-10"><a aria-hidden="true" href="#cb17-10" tabindex="-1"></a>mtcars2 <span className="ot">&lt;-</span> mtcars <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb17-11"><a aria-hidden="true" href="#cb17-11" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">rownames</span>(mtcars), <span className="at">cyl =</span> <span className="fu">factor</span>(cyl)) <span className="sc">%&gt;%</span> </span>
<span id="cb17-12"><a aria-hidden="true" href="#cb17-12" tabindex="-1"></a>  <span className="co"># reorder cars by horsepower</span></span>
<span id="cb17-13"><a aria-hidden="true" href="#cb17-13" tabindex="-1"></a>  <span className="fu">arrange</span>(hp) <span className="sc">%&gt;%</span></span>
<span id="cb17-14"><a aria-hidden="true" href="#cb17-14" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">factor</span>(car, <span className="at">levels =</span> car)) </span>
<span id="cb17-15"><a aria-hidden="true" href="#cb17-15" tabindex="-1"></a></span><br/>
<span id="cb17-16"><a aria-hidden="true" href="#cb17-16" tabindex="-1"></a><span className="fu">head</span>(mtcars2, <span className="dv">4</span>) <span className="co"># ready for visualization</span></span>
<span id="cb17-17"><a aria-hidden="true" href="#cb17-17" tabindex="-1"></a></span><br/>
<span id="cb17-18"><a aria-hidden="true" href="#cb17-18" tabindex="-1"></a></span><br/>
<span id="cb17-19"><a aria-hidden="true" href="#cb17-19" tabindex="-1"></a><span className="do">### Visualization ---- ---- ---- ---- ---- ---- ----</span></span>
<span id="cb17-20"><a aria-hidden="true" href="#cb17-20" tabindex="-1"></a></span><br/>
<span id="cb17-21"><a aria-hidden="true" href="#cb17-21" tabindex="-1"></a><span className="co"># Create an ordered lollipop plot.</span></span>
<span id="cb17-22"><a aria-hidden="true" href="#cb17-22" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> mtcars2 <span className="sc">%&gt;%</span> </span>
<span id="cb17-23"><a aria-hidden="true" href="#cb17-23" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> car, <span className="at">y =</span> hp, <span className="at">color =</span> cyl)) <span className="sc">+</span></span>
<span id="cb17-24"><a aria-hidden="true" href="#cb17-24" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb17-25"><a aria-hidden="true" href="#cb17-25" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="fu">aes</span>(<span className="at">xend =</span> car, <span className="at">yend =</span> <span className="dv">0</span>), <span className="at">linewidth =</span> <span className="dv">1</span>)</span>
<span id="cb17-26"><a aria-hidden="true" href="#cb17-26" tabindex="-1"></a>p1</span>
<span id="cb17-27"><a aria-hidden="true" href="#cb17-27" tabindex="-1"></a></span><br/>
<span id="cb17-28"><a aria-hidden="true" href="#cb17-28" tabindex="-1"></a></span><br/>
<span id="cb17-29"><a aria-hidden="true" href="#cb17-29" tabindex="-1"></a><span className="co"># Transform the linear coordinate to a polar coordinate.</span></span>
<span id="cb17-30"><a aria-hidden="true" href="#cb17-30" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"x"</span>, <span className="at">clip =</span> <span className="st">"off"</span>)</span>
<span id="cb17-31"><a aria-hidden="true" href="#cb17-31" tabindex="-1"></a>p2</span>
<span id="cb17-32"><a aria-hidden="true" href="#cb17-32" tabindex="-1"></a></span><br/>
<span id="cb17-33"><a aria-hidden="true" href="#cb17-33" tabindex="-1"></a></span><br/>
<span id="cb17-34"><a aria-hidden="true" href="#cb17-34" tabindex="-1"></a><span className="co"># Label the car names in line with the lollipops. </span></span>
<span id="cb17-35"><a aria-hidden="true" href="#cb17-35" tabindex="-1"></a><span className="co"># First calculate angles by which the texts need to be rotated.</span></span>
<span id="cb17-36"><a aria-hidden="true" href="#cb17-36" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(mtcars2)</span>
<span id="cb17-37"><a aria-hidden="true" href="#cb17-37" tabindex="-1"></a>angle_perSlice <span className="ot">=</span> <span className="dv">360</span> <span className="sc">/</span> n</span>
<span id="cb17-38"><a aria-hidden="true" href="#cb17-38" tabindex="-1"></a></span><br/>
<span id="cb17-39"><a aria-hidden="true" href="#cb17-39" tabindex="-1"></a><span className="co"># degrees of angle to rotate per lollipop / text</span></span>
<span id="cb17-40"><a aria-hidden="true" href="#cb17-40" tabindex="-1"></a>angle_perSlice</span>
<span id="cb17-41"><a aria-hidden="true" href="#cb17-41" tabindex="-1"></a></span><br/>
<span id="cb17-42"><a aria-hidden="true" href="#cb17-42" tabindex="-1"></a><span className="co"># Create a vector of angles of successive rotations for all car names.</span></span>
<span id="cb17-43"><a aria-hidden="true" href="#cb17-43" tabindex="-1"></a>angles_to_rotate <span className="ot">&lt;-</span> <span className="dv">81</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span>(n<span className="dv">-1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb17-44"><a aria-hidden="true" href="#cb17-44" tabindex="-1"></a>angles_to_rotate</span>
<span id="cb17-45"><a aria-hidden="true" href="#cb17-45" tabindex="-1"></a></span><br/>
<span id="cb17-46"><a aria-hidden="true" href="#cb17-46" tabindex="-1"></a><span className="co"># Add labels of car names.</span></span>
<span id="cb17-47"><a aria-hidden="true" href="#cb17-47" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb17-48"><a aria-hidden="true" href="#cb17-48" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate,</span>
<span id="cb17-49"><a aria-hidden="true" href="#cb17-49" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span>
<span id="cb17-50"><a aria-hidden="true" href="#cb17-50" tabindex="-1"></a></span><br/>
<span id="cb17-51"><a aria-hidden="true" href="#cb17-51" tabindex="-1"></a></span><br/>
<span id="cb17-52"><a aria-hidden="true" href="#cb17-52" tabindex="-1"></a><span className="co"># Adjust the rotation angles of texts on the left side.</span></span>
<span id="cb17-53"><a aria-hidden="true" href="#cb17-53" tabindex="-1"></a><span className="do">## keep the same text angles for the first 17 cars </span></span>
<span id="cb17-54"><a aria-hidden="true" href="#cb17-54" tabindex="-1"></a>angles1 <span className="ot">&lt;-</span> angles_to_rotate[<span className="dv">1</span><span className="sc">:</span><span className="dv">17</span>] </span>
<span id="cb17-55"><a aria-hidden="true" href="#cb17-55" tabindex="-1"></a><span className="do">## update text angles for the left half cars from Dodge to Maserati</span></span>
<span id="cb17-56"><a aria-hidden="true" href="#cb17-56" tabindex="-1"></a>angles2 <span className="ot">&lt;-</span> <span className="dv">71</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span> (n <span className="sc">-</span> <span className="dv">17</span> <span className="sc">-</span><span className="dv">1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb17-57"><a aria-hidden="true" href="#cb17-57" tabindex="-1"></a><span className="do">## an updated angle vector</span></span>
<span id="cb17-58"><a aria-hidden="true" href="#cb17-58" tabindex="-1"></a>angles_to_rotate_new <span className="ot">&lt;-</span> <span className="fu">c</span>(angles1, angles2)</span>
<span id="cb17-59"><a aria-hidden="true" href="#cb17-59" tabindex="-1"></a></span><br/>
<span id="cb17-60"><a aria-hidden="true" href="#cb17-60" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb17-61"><a aria-hidden="true" href="#cb17-61" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb17-62"><a aria-hidden="true" href="#cb17-62" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span>
<span id="cb17-63"><a aria-hidden="true" href="#cb17-63" tabindex="-1"></a></span><br/>
<span id="cb17-64"><a aria-hidden="true" href="#cb17-64" tabindex="-1"></a></span><br/>
<span id="cb17-65"><a aria-hidden="true" href="#cb17-65" tabindex="-1"></a><span className="co"># Right-justify texts on the left side</span></span>
<span id="cb17-66"><a aria-hidden="true" href="#cb17-66" tabindex="-1"></a><span className="co"># while retaining left justification for texts on the right side.</span></span>
<span id="cb17-67"><a aria-hidden="true" href="#cb17-67" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb17-68"><a aria-hidden="true" href="#cb17-68" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb17-69"><a aria-hidden="true" href="#cb17-69" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ))</span>
<span id="cb17-70"><a aria-hidden="true" href="#cb17-70" tabindex="-1"></a></span><br/>
<span id="cb17-71"><a aria-hidden="true" href="#cb17-71" tabindex="-1"></a></span><br/>
<span id="cb17-72"><a aria-hidden="true" href="#cb17-72" tabindex="-1"></a><span className="co"># A few more touch on the texts font and size</span></span>
<span id="cb17-73"><a aria-hidden="true" href="#cb17-73" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb17-74"><a aria-hidden="true" href="#cb17-74" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">40</span>), </span>
<span id="cb17-75"><a aria-hidden="true" href="#cb17-75" tabindex="-1"></a>            <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb17-76"><a aria-hidden="true" href="#cb17-76" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ),</span>
<span id="cb17-77"><a aria-hidden="true" href="#cb17-77" tabindex="-1"></a>            <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb17-78"><a aria-hidden="true" href="#cb17-78" tabindex="-1"></a>p3</span>
<span id="cb17-79"><a aria-hidden="true" href="#cb17-79" tabindex="-1"></a></span><br/>
<span id="cb17-80"><a aria-hidden="true" href="#cb17-80" tabindex="-1"></a></span><br/>
<span id="cb17-81"><a aria-hidden="true" href="#cb17-81" tabindex="-1"></a></span><br/>
<span id="cb17-82"><a aria-hidden="true" href="#cb17-82" tabindex="-1"></a><span className="co"># Add plot title at the center of the graphic</span></span>
<span id="cb17-83"><a aria-hidden="true" href="#cb17-83" tabindex="-1"></a></span><br/>
<span id="cb17-84"><a aria-hidden="true" href="#cb17-84" tabindex="-1"></a><span className="do">## Load fonts from the Google Font Repository</span></span>
<span id="cb17-85"><a aria-hidden="true" href="#cb17-85" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb17-86"><a aria-hidden="true" href="#cb17-86" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb17-87"><a aria-hidden="true" href="#cb17-87" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="st">"Anton"</span>, <span className="st">"Anton"</span>)</span>
<span id="cb17-88"><a aria-hidden="true" href="#cb17-88" tabindex="-1"></a></span><br/>
<span id="cb17-89"><a aria-hidden="true" href="#cb17-89" tabindex="-1"></a><span className="do">## Format the text with HTML markup</span></span>
<span id="cb17-90"><a aria-hidden="true" href="#cb17-90" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb17-91"><a aria-hidden="true" href="#cb17-91" tabindex="-1"></a>mytitle <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb17-92"><a aria-hidden="true" href="#cb17-92" tabindex="-1"></a>  <span className="st">'**&lt;span style="font-family:Anton;"&gt;AUTOMABILES&lt;br&gt;</span></span>
<span id="cb17-93"><a aria-hidden="true" href="#cb17-93" tabindex="-1"></a><span className="st">  HORSEPOWER&lt;br&gt;&lt;/span&gt;</span></span>
<span id="cb17-94"><a aria-hidden="true" href="#cb17-94" tabindex="-1"></a><span className="st">  with &lt;span style="color:orange;font-family:Anton;"&gt;4&lt;/span&gt;,</span></span>
<span id="cb17-95"><a aria-hidden="true" href="#cb17-95" tabindex="-1"></a><span className="st">  &lt;span style="color:steelblue;font-family:Anton;"&gt;**6**&lt;/span&gt;,</span></span>
<span id="cb17-96"><a aria-hidden="true" href="#cb17-96" tabindex="-1"></a><span className="st">  &lt;span style="color:red2;font-family:Anton;"&gt;8&lt;/span&gt;&lt;br&gt;</span></span>
<span id="cb17-97"><a aria-hidden="true" href="#cb17-97" tabindex="-1"></a><span className="st">  cylinders**'</span></span>
<span id="cb17-98"><a aria-hidden="true" href="#cb17-98" tabindex="-1"></a>)</span>
<span id="cb17-99"><a aria-hidden="true" href="#cb17-99" tabindex="-1"></a></span><br/>
<span id="cb17-100"><a aria-hidden="true" href="#cb17-100" tabindex="-1"></a><span className="do">## Create a donut hole containing the plot title. </span></span>
<span id="cb17-101"><a aria-hidden="true" href="#cb17-101" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb17-102"><a aria-hidden="true" href="#cb17-102" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"richtext"</span>,</span>
<span id="cb17-103"><a aria-hidden="true" href="#cb17-103" tabindex="-1"></a>           <span className="at">label =</span> mytitle,</span>
<span id="cb17-104"><a aria-hidden="true" href="#cb17-104" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">300</span>, <span className="at">hjust =</span> .<span className="dv">48</span>, </span>
<span id="cb17-105"><a aria-hidden="true" href="#cb17-105" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">4</span>, <span className="co"># family = "Anton",</span></span>
<span id="cb17-106"><a aria-hidden="true" href="#cb17-106" tabindex="-1"></a>           <span className="at">label.color =</span> <span className="cn">NA</span>, <span className="at">fill =</span> <span className="cn">NA</span>) <span className="sc">+</span></span>
<span id="cb17-107"><a aria-hidden="true" href="#cb17-107" tabindex="-1"></a>  <span className="co"># udpate the color scale to be consistent with title colors</span></span>
<span id="cb17-108"><a aria-hidden="true" href="#cb17-108" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"orange"</span>, <span className="st">"steelblue"</span>, <span className="st">"red2"</span>)) </span>
<span id="cb17-109"><a aria-hidden="true" href="#cb17-109" tabindex="-1"></a>p4</span>
<span id="cb17-110"><a aria-hidden="true" href="#cb17-110" tabindex="-1"></a></span><br/>
<span id="cb17-111"><a aria-hidden="true" href="#cb17-111" tabindex="-1"></a></span><br/>
<span id="cb17-112"><a aria-hidden="true" href="#cb17-112" tabindex="-1"></a><span className="co"># A final touch on the theme</span></span>
<span id="cb17-113"><a aria-hidden="true" href="#cb17-113" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb17-114"><a aria-hidden="true" href="#cb17-114" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb17-115"><a aria-hidden="true" href="#cb17-115" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb17-116"><a aria-hidden="true" href="#cb17-116" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">30</span>, <span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>))  <span className="sc">+</span></span>
<span id="cb17-117"><a aria-hidden="true" href="#cb17-117" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>)</span>
<span id="cb17-118"><a aria-hidden="true" href="#cb17-118" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>The above donut plots are produced by transforming the <strong><code>x</code></strong> aesthetic from a linear coordinate into angles in a polar coordinate. More often, however, donut plots are created by <strong>mapping the <code>y</code> aesthetic into angles to present “proportion of a whole”</strong>. Check the following <Link to="../ggplot2-donut-GDP"><strong>exploded donut plots in faceted panels</strong></Link> that visualize the contribution of the top 4 largest GDP makers to the total GDP in each continent.</p>
<p><Link to="../ggplot2-donut-GDP">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check the following awesome <Link to="../ggplot2-geofacet-US-states"><strong>donut plots faceted in a U.S. map layout</strong></Link> that visualizes the state-wise voting results of the 2016 U.S. Presidential Election between Hillary Clinton and Donald Trump.</p>
<p><Link to="../ggplot2-geofacet-US-states">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}