import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom'
import RdplyrNavSidBar from './RdplyrNavSidBar';
import RdplyrData from '../../data/RdplyrData';
import {useNavigate, useLocation} from 'react-router-dom'
import imgdplyr from './dplyr_graphics/logo_R_dplyr.png'; 
import imgdplyrWebp from '../../media/logo_R_dplyr.webp'
import {Adsggplot} from '../../Ads'


export default function RdplyrIndexLayout() {
    const navigate = useNavigate()
    const location = useLocation()    
    // const paths = [];
    const paths = RdplyrData.filter(data => data.path !== '').map(data => data.path);

    const [currentPage, setCurrentPage] = useState(0)
    
    useEffect(()=> {
        const path = location.pathname
        const parts = path.split('/')
        const currentRoute = parts[parts.length -1]
        const currentRouteIndex = paths.findIndex((element)=> element == currentRoute)
        setCurrentPage(currentRouteIndex)
    }, [location.pathname])
    


    const nextPage = ()=> {
        if(currentPage < paths.length - 1){
            const nextRoute = paths[currentPage +1]
            setCurrentPage(currentPage + 1)
            navigate(nextRoute)
           
        }

    }  
    const prevPage = ()=> {
        if (currentPage>0){
            const previousRoute = paths[currentPage - 1]
            setCurrentPage(currentPage - 1)
            navigate(previousRoute)
        }

    }
    console.log(imgdplyrWebp)

    return (
        <div className='main'>
            <div className="row">
        
                <div className="col-md-2 col-sm-12" style={{padding:0}}>              
                    <RdplyrNavSidBar currentPage={currentPage}/>                 
                </div>    
                <div className="col-md-9 col-sm-12" >
                    <div className="row">
                        <div className='col-md-10 p-r-md-60'>
                            {/* <div className='mg-t-30 p-l-md-60'>
                                <Link to="../R/data-wrangling">
                                    <button className="back-button-lg"><i class="fas fa-arrow-left large-bold-arrow"></i> Data Wrangling</button>
                                </Link>   
                            </div> */}
                            <div className="main-container w-xl-85 mg-t-50">                            
                                <div className='flexSpaceBetweenCenter'>
                                    <div className="small-logo">
                                        <picture className="small-logo">
                                            <source className="logo-diff img-fluid cover-img" type="image/webp" srcset={`${imgdplyrWebp}, ${imgdplyr}`} />                 
                                            <img className="logo-diff img-fluid cover-img" src={imgdplyr} ></img>
                                        </picture>
                                    </div>

                                    
                                    <div className='flexSpaceBetween'>             
                                        <div className='letter-space-min p-t-5 p-b-5 mg-r-50 center-text'>
                                            <a id="prevPage" onClick={()=>prevPage()}  className='button'>&lt; Previous</a>
                                        </div>
                                        <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                            <a id="nextPage" onClick={()=>nextPage()} className='button'>Next &gt;</a>
                                        </div>
                                    </div>
                                </div>
                                <Outlet />
                            </div>
                            <div className='ads-mid'>
                                {/* Save this space for Ads */}
            
                            </div>
                            <div class="main-container flexSpaceBetween w-xl-85">
                                <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                    <a id="prevPage" onClick={()=>prevPage()}  className='button'>&lt; Previous</a>
                                </div>
                                <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                    <a id="nextPage" onClick={()=>nextPage()} className='button'>Next &gt;</a>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className='col-md-2 mg-t-150'>

                            {/* This is Ads */}
                        </div>


                    </div>
                    
                </div>
                <div className="col-md-1 col-sm-12">
                    <Adsggplot /> 
                </div>
                

            </div>

        </div>
    )
}