import {Link} from "react-router-dom";
export default function FaqPageVertical({title, summary, path, cover="", date}) {

    return (
        <div className="post">
            <div className="image">
                <Link to={path}>
                    <img src={cover}></img>
                </Link>
               
            </div>
            
            <div className="texts">
           
                <Link to={path}>
                    <h2 >{title}</h2>
                </Link>
                
                <p className="info">
                    <a className="author">author</a>
                </p>
                <p className="summary">{summary}</p>
            </div>  
        </div>
    )
}