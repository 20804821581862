import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function DropRowsContainingMissingValues(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Remove Rows Containing Missing Values</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>drop_na()</code> drops rows that contain <code>NA</code> values in selected columns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>df <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">id =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="cn">NA</span>, <span className="dv">4</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>             <span className="at">first_name =</span> <span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="cn">NA</span>, <span className="st">"David"</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>             <span className="at">last_name =</span> <span className="fu">c</span>(<span className="st">"Smith"</span>, <span className="st">"Johnson"</span>, <span className="cn">NA</span>, <span className="st">"Miller"</span>),</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>             <span className="at">age =</span> <span className="fu">c</span>(<span className="dv">25</span>, <span className="cn">NA</span>, <span className="dv">35</span>, <span className="dv">40</span>))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>df</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 4
<br/>     id first_name last_name   age
<br/>  &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     &lt;dbl&gt;
<br/>1     1 Alice      Smith        25
<br/>2     2 Bob        Johnson      NA
<br/>3    NA &lt;NA&gt;       &lt;NA&gt;         35
<br/>4     4 David      Miller       40</code></pre>
</div>
</div>
<p>Remove rows containing missing values <code>NA</code>. Only rows not containing <code>NA</code> are retained in the output.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>df <span className="sc">%&gt;%</span> <span className="fu">drop_na</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 2 × 4
<br/>     id first_name last_name   age
<br/>  &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     &lt;dbl&gt;
<br/>1     1 Alice      Smith        25
<br/>2     4 David      Miller       40</code></pre>
</div>
</div>
<p>Remove rows that contain <code>NA</code> values in the <code>id</code> and <code>first_name</code> column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>df <span className="sc">%&gt;%</span> <span className="fu">drop_na</span>(id, first_name)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>     id first_name last_name   age
<br/>  &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     &lt;dbl&gt;
<br/>1     1 Alice      Smith        25
<br/>2     2 Bob        Johnson      NA
<br/>3     4 David      Miller       40</code></pre>
</div>
</div>
<p><Link to="/R/data-wrangling/dplyr/2-select-columns#selection_helpers">Selection helpers</Link> can be used to conveniently select a block of columns. E.g., here we remove rows that contain <code>NA</code> values in numeric columns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>df <span className="sc">%&gt;%</span> <span className="fu">drop_na</span>(<span className="fu">where</span>(is.numeric))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 2 × 4
<br/>     id first_name last_name   age
<br/>  &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     &lt;dbl&gt;
<br/>1     1 Alice      Smith        25
<br/>2     4 David      Miller       40</code></pre>
</div>
</div>
</main>
</div>
</div>
)}