import VerticalPosts from "../RgalleryPages/galleryPostsVertical"
import {useEffect} from 'react';
import RGalleryData from "../data/RGalleryData";
import Image from './graphics_blog/pseudo_log_logarithmic_transform.png'
import { Link } from "react-router-dom";
import BlogData from "../data/BlogData";
import { AdsggplotForBlog } from "../Ads";

export default function GalleryIndexPage() {
    const description = "Blog Articles"
    const title = "Blogs"
    useEffect(()=>{
        document.title = title
        var existingMetaTag = document.querySelector('meta[name="description"]');
            
        if (existingMetaTag) {
            // If it exists, update its content
            existingMetaTag.setAttribute('content', description);
        } else {
            document.title = title
            var metaTag = document.createElement('meta');
            metaTag.setAttribute('name', 'description');
            metaTag.setAttribute('content', description);
            document.head.appendChild(metaTag);
        }
    }, [])
 

    return(
        <div className='main mg-t-80' id='blog-layout'>
            {/* <div className='text-center'>
                <h1 className='fc-darkBlue fontfamily-freeman' style={{'lineHeight': '1'}}>{title}</h1>
                <h4 className='fc-light-grey' style={{'width':'50%', 'display':'inline-block', 'fontSize':'18px', 'marginTop':'10px'}}>
                    {description}
                </h4>
            </div> */}
            <div className='container-blog'>
                <div className='content-blog-main'>     
                    <div className='content-blog'>
                        {/* <h1 className='recent-blog-title'>Recent Posts</h1>        */}
                        <div className='text-center mg-b-50'>
                            <h1 className='fc-darkBlue fontfamily-freeman' style={{'lineHeight': '1', 'fontSize': '50px'}}>{title}</h1>
                        </div>
                        {BlogData.map((data, index)=> (
                            <div className="blog-post">                                                 
                                <div className="blog-preview">
                                    <Link to={data.path}>
                                        <h2 className='blog-article-main-title-index'>
                                            {data.title}
                                        </h2>
                                        <h5 className='subtitle'>{data.subTitle}</h5>
                                        <p className="preview-text">{data.dscrpt}</p>
                                    </Link>
                                    <div className='mg-t-l-30'>
                                        <p className='fc-grey fs-18'><span>{data.time} read</span></p>
                                    </div>
                                </div>
                                <div className="img-container">
                                    <Link to={data.path}>
                                        <picture>
                                            <source type='image/webp' srcset={data.cover_webp} />
                                            <img className="blog-img" alt={data.title} src={data.image}></img>
                                        </picture>
                                    </Link>
                                </div> 
                            </div>
                        ))}              
                    </div>      
                </div>

                <div id='ads-in-blog'>
                        <AdsggplotForBlog /> 
                </div>
                
            </div>
        
        </div>        
               
    )

}