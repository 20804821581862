import { Link } from 'react-router-dom';
import SparkTutorialData from '../data/SparkTutorialData';
import React, { useState, useEffect } from 'react'; 


function SparkNavigation(props) { 
  const [showNav, setShowNav] = useState(false);
  const [linkActive, setLinkActive] = useState(null);
  // only work when screen size is smaller than 992px
  const toggleNav = () => {
      if (window.innerWidth<992) {
        setShowNav(!showNav);
      }
    }
  const linkSelected = (id) => { 
    setLinkActive(id)
  };
  useEffect(()=> {
    setLinkActive(props.currentPage)
  }, [props.currentPage])

  return (
    <div >
      <nav className=''>
          <button className="toggle-button" onClick={toggleNav}>
            &#9776;
          </button>
        <div className={`nav-container ${showNav ? 'show' : ''}`}>
          <div className='nav-title'>
            {/* <Link to='./'> */}
              <h3>Pyspark</h3>
            {/* </Link>            */}
          </div>  
          {/* <ul className='navList'>
            {SparkTutorialData.map((data, index) => (
              data.path === ''?(
                <div key={index} id='label'>{data.title}</div>
              ) : (
                <li key={index}>
                    <Link to={data.path} className='navLink' onClick={toggleNav}>
                    <div  className={`navListItem ${linkActive === index ? 'linkActiveSelected' : ''}`} onClick = {()=>{linkSelected(index)}}>
                        {data.title}
                      </div>
                      
                    </Link>
                </li>
              )
            ))} 
          </ul> */}
           <ul className='navList'>
            {SparkTutorialData.map((data, index) => {
              if (data.path === '') {
                return (
                  <div key={index} id='label'>{data.title}</div>
                );
              } else {
                const filteredIndex = SparkTutorialData.slice(0, index + 1).filter(item => item.path !== '').length - 1;
                return (
                  <li key={index}>
                    <Link to={data.path} className='navLink' onClick={toggleNav}>
                      <div className={`navListItem ${linkActive === filteredIndex ? 'linkActiveSelected' : ''}`} onClick={() => linkSelected(filteredIndex)}>
                        {data.title}
                      </div>
                    </Link>
                  </li>
                );
              }
            })}
        </ul>
        </div>
        
      </nav>
    </div>
    
  );
}

export default SparkNavigation
