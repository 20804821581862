import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderStackingOfBars from '../tutorial/stacking_barplot.png';

export default function Ggplot2ReorderStackingOfBars(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Reorder the Stacking of Bars and Area / Ribbon plots in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In the earlier tutorials, we covered the <Link to="../4-ggplot2-reorder-bars">fundamental techniques</Link> to reorder graphic elements in ggplot2, including how to <Link to="../5-ggplot2-reorder-violin-plots">reorder violin plots based on summary statistics</Link>, and <Link to="../6-ggplot2-reorder-faceted-panels">reorder faceted panels (subplots)</Link>. The same techniques can be readily extended to specify the <em>stacking order</em> of graphic elements, such as bars, area / ribbon plots, texts, lines, etc.</p>
<p>In this <em>advanced-level</em> tutorial, we’ll discuss how to:</p>
<ul>
<li><a href="#reorder_bar_stack">reorder the stacking of bars with the <code>forcats</code> approach</a></li>
<li><a href="#bar_area_ribbon">convert the bar plot to an area / ribbons plot</a></li>
<li><a href="#cluster_ribbons">cluster the ribbons with the <code>dplyr</code> approach</a></li>
</ul>
<hr/>
<section className="level4" id="packages-global-theme-and-dataset">
<h4 className="anchored" data-anchor-id="packages-global-theme-and-dataset">Packages, global theme, and dataset</h4>
<p>We’ll work on the famous <code>gapminder</code> dataset. It shows the life expectancy (<code>lifeExp</code>), population (<code>pop</code>), and GDP per capita (<code>gdpPercap</code>) in countries from different continents in every 5 years from 1950s to 2000s. 🌎</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>))</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># install.packages("gapminder")</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">library</span>(gapminder)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">head</span>(gapminder, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 6
<br/>  country     continent  year lifeExp      pop gdpPercap
<br/>  &lt;fct&gt;       &lt;fct&gt;     &lt;int&gt;   &lt;dbl&gt;    &lt;int&gt;     &lt;dbl&gt;
<br/>1 Afghanistan Asia       1952    28.8  8425333      779.
<br/>2 Afghanistan Asia       1957    30.3  9240934      821.
<br/>3 Afghanistan Asia       1962    32.0 10267083      853.
<br/>4 Afghanistan Asia       1967    34.0 11537966      836.</code></pre>
</div>
</div>
</section>
<section className="level4" id="draw-bars-to-visualize-the-total-gdp-in-each-continent">
<h4 className="anchored" data-anchor-id="draw-bars-to-visualize-the-total-gdp-in-each-continent">Draw bars to visualize the total GDP in each continent</h4>
<p>Calculate the GDP (in billions) per year in each country as the product of population size and GDP per capita.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">gdp.billion =</span> pop <span className="sc">*</span> gdpPercap <span className="sc">/</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">9</span>)</span></code></pre></div>
</div>
<p>Create bars to visualize the total GDP in each continent during each year.</p>
<ul>
<li><p>At each year, bars of different countries are stacked up by default.</p></li>
<li><p>By the aesthetic mapping of <code>fill = continent</code>, countries of the same continent have the same fill and are grouped together, visually depicting the total GDP of the associated continent.</p></li>
<li><p>The continents are arranged in the default alphabetical order.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># create a stacked bar plot</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>g <span className="sc">%&gt;%</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(year, gdp.billion, <span className="at">fill =</span> continent)) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_col</span>() <span className="sc">+</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderStackingOfBars} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="reorder_bar_stack">
<h4 className="anchored" data-anchor-id="reorder_bar_stack">Reorder the stacking of bars based on continent GDP</h4>
<p>Let’s say we want to put continents with large GDPs at the bottom of the bar plot, and continents with smaller GDPs at the top. In the <code>fct_reorder()</code> function, <code>.fun = sum</code> calculates the sum of GDP across all countries and all years separately for each continent, and these continent-wise sums determines the continent stacking order.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>g_reordered_continent <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">continent =</span> <span className="fu">fct_reorder</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    continent, gdp.billion, <span className="at">.fun =</span> sum))</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a></span><br/>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>g_reordered_continent <span className="sc">%&gt;%</span> </span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="co"># ggplot2 part is the same as before</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(year, gdp.billion, <span className="at">fill =</span> continent)) <span className="sc">+</span></span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="fu">geom_col</span>() <span className="sc">+</span></span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/stacking_barplot_reordered.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="bar_area_ribbon">
<h4 className="anchored" data-anchor-id="bar_area_ribbon">Convert the bar plot to area / ribbons plot</h4>
<p>The bar plot can be easily converted to an area / ribbon plot by switching <code>geom_col()</code> to <code>geom_area()</code> with an additional aesthetic mapping <code>aes(group = country)</code>, so that data points of the same country are connected across the x-axis to form a continuous ribbon. Due to this <code>group</code> aesthetic, however, countries of the same continent are no longer bundled together.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>g_reordered_continent <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(year, gdp.billion, <span className="at">fill =</span> continent)) <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">geom_area</span>(<span className="fu">aes</span>(<span className="at">group =</span> country)) <span className="sc">+</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/stacking_area_ribbon_plot.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="cluster_ribbons">
<h4 className="anchored">Cluster ribbons of countries in the same continent</h4>
<p>Apply <code>arrange()</code> to reorder rows by continent, and use <code>factor()</code> to turn <code>country</code> into a factor variable with levels being the current <em>appearance order</em> in the reordered dataset. As each <code>country</code> level is duplicated in multiple rows (corresponding to different years), <code>unique()</code> is used to extract distinct country names.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>g_reordered_continent_country <span className="ot">&lt;-</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># cluster countries in the same continent</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  g_reordered_continent <span className="sc">%&gt;%</span> </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">arrange</span>(continent) <span className="sc">%&gt;%</span> </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">country =</span> <span className="fu">factor</span>(country, <span className="at">levels =</span> <span className="fu">unique</span>(country)))</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a></span><br/>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>g_reordered_continent_country <span className="sc">%&gt;%</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="co"># The ggplot2 part is the same as above</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(year, gdp.billion, <span className="at">fill =</span> continent)) <span className="sc">+</span></span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  <span className="fu">geom_area</span>(<span className="fu">aes</span>(<span className="at">group =</span> country)) <span className="sc">+</span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/stacking_ribbon_reordered.png"/></p>
</figure>
</div>
</div>
</div>
<hr/>
<h3 className="anchored" data-anchor-id="cluster_ribbons">
<strong><em>Apply Your Learning in Practice!</em></strong> 🏆
</h3>
<p>The following <Link to="../../R/gallery/ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link> shows dynamic shifts in the migrant population to the United States from 1820 to 2009. In this plot, countries are reordered such that those from the same continent are clustered together. This enables the use of color gradient to distinguish both countries and continents.</p>
<p><Link to="../../R/gallery/ggplot2-area-plot-migration"><img className="img-fluid" src="graphics/area_plot_migration_theme_completed.png"/></Link></p>
</section>
</main>
</div>
</div>
)}