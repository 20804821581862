import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function BetweenARange(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Judge If a Value Falls Within a Specified Range</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>between()</code></strong> judges if each element in an input vector is within the specified <code>left</code> and <code>right</code> boundaries, and returns a logical vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">7</span>, <span className="dv">6</span>, <span className="dv">10</span>, <span className="dv">8</span>)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">between</span>(<span className="at">left =</span> <span className="dv">6</span>, <span className="at">right =</span> <span className="dv">8</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] FALSE  TRUE  TRUE FALSE  TRUE</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="co"># select rows with mass between 100 and 120</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(<span className="fu">between</span>(mass, <span className="dv">100</span>, <span className="dv">120</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 5 × 5
<br/>    name             height  mass hair_color  skin_color
<br/>    &lt;chr&gt;             &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;       &lt;chr&gt;     
<br/>  1 Owen Lars           178   120 brown, grey light     
<br/>  2 Chewbacca           228   112 brown       unknown   
<br/>  3 Jek Tono Porkins    180   110 brown       fair      
<br/>  4 Bossk               190   113 none        green     
<br/>  # ℹ 1 more row</code></pre>
</div>
</div>
<p>It is equivalent to the following code:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">filter</span>(mass <span className="sc">&gt;=</span> <span className="dv">100</span> <span className="sc">&amp;</span> mass <span className="sc">&lt;=</span> <span className="dv">120</span>)</span></code></pre></div>
</div>
</main>
</div>
</div>
)}