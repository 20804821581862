import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonInstallingpython(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Installing-Python">Installing Python<a class="anchor-link" href="#Installing-Python">¶</a></h3><p>Before we begin, let's ensure Python is installed on your system. To check, run this command in your terminal; it will display the Python version if it's installed:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">python</span> <span class="o">--</span><span class="n">version</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>If Python was not installed, please keep reading and install it.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="For-Windows-Users">For Windows Users<a class="anchor-link" href="#For-Windows-Users">¶</a></h4>

<ol>
<li><p>Visit the official Python website <a href="https://www.python.org/downloads/windows/">python.org</a>.</p></li>
<li>
    <p>Click and download the latest Python installer for your Windows version. 
Deciding between 32-bit or 64-bit version? Check your Windows operating system type.</p>
</li>
<ol>
    <p>2.1 On Windows 10/11:</p>
    <ol>
        <p>
        2.1.1 Right-click on the "Start" button.<br />
        2.1.2 Select "System."<br />
        2.1.3 Under "System type," you'll see whether your system is 32-bit or 64-bit.
        </p>
        
    </ol>
</ol>
{/* <pre className='demo-highlight python'><code className='sourceCode'>  */}
{/* <br /></code></pre> */}
<ol>
    <p>2.2 On Windows 7:</p>
    <ol>
        <p> 2.2.1 Click the "Start" button.<br />
        2.2.2 Right-click on "Computer" or "My Computer."<br />
        2.2.3 Select "Properties."<br />
        2.2.4 Under "System," you'll see whether your system is 32-bit or 64-bit.</p>
       
    </ol>
</ol>
<li><p>Run the downloaded installer.</p>
</li>
<li><p>Check the box that says "Add Python x.y to PATH" during installation. This is important as it makes Python accessible from the command prompt.</p>
</li>
<li><p>Click the "Install Now" button to start the installation.</p>
</li>
<li><p>Python will be installed to your system. You can verify the installation by opening a Command Prompt and running:</p>
</li>
</ol>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">python</span> <span class="o">--</span><span class="n">version</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This command should display the installed Python version.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="For-macOS-Users">For macOS Users<a class="anchor-link" href="#For-macOS-Users">¶</a></h4><ol>
<li><p>Visit the official Python website <a href="https://www.python.org/downloads/macos/">python.org</a>.</p></li>
<li><p>Click and download the latest Python installer.</p></li>
<li><p>Click to run the Installer:<br/>
Follow the on-screen instructions to complete the installation. You can typically accept the default options. However, make sure to check the box that says "Add Python x.y to PATH" (where x.y.z is the version number) during installation. This will add Python to your system's PATH, making it accessible from the terminal.</p>
</li>
<li><p>Verify Python Installation by running the command below in a terminal window:</p></li>
</ol>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">python3</span> <span class="o">--</span><span class="n">version</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}