import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import SparkInfo from '../Databricks/ViewTableDFCompare'
// import SparkDropDuplicates from '../Databricks/contents/SparkDropDuplicates';
// import SparkLimit from '../Databricks/contents/SparkLimit';
import SparkSparksession from '../Databricks/contents/SparkSession_output_react'
// import SparkView from '../Databricks/contents/SparkView';
import SparkJoin from '../Databricks/contents/join_output_react'
import SparkLimit from '../Databricks/contents/limit_output_react'
import SparkExplode from '../Databricks/contents/explode_output_react'
import SparkVar from '../Databricks/contents/var_pop_output_react'
import SparkSplit from '../Databricks/contents/split_output_react'
import SparkLtrim from '../Databricks/contents/ltrim_rtrim_trim_output_react'
import SparkView from '../Databricks/contents/view_output_react'
import SparkCurrent from '../Databricks/contents/current_date_output_react'
import SparkShow from '../Databricks/contents/show_output_react'
import SparkRand from '../Databricks/contents/rand_output_react'
import SparkInstall from '../Databricks/contents/Install_output_react'
import SparkDropduplicates from '../Databricks/contents/dropDuplicates_output_react'
import SparkDateAdd from '../Databricks/contents/date_add_output_react'
import SparkCollectList from '../Databricks/contents/collect_list_output_react'
import SparkCollectSet from '../Databricks/contents/collect_set_output_react'
import SparkAddMonths from '../Databricks/contents/add_months_output_react'
import SparkSumdistinct from '../Databricks/contents/sumDistinct_output_react'
import SparkWriter from '../Databricks/contents/writer_output_react'
import SparkCreatedataframe from '../Databricks/contents/createDataFrame_output_react'
import SparkTable from '../Databricks/contents/table_output_react'
import SparkSelectexpr from '../Databricks/contents/selectExpr_output_react'
import SparkAvg from '../Databricks/contents/Avg_output_react'
import SparkStddev from '../Databricks/contents/stddev_samp_output_react'
import SparkReplace from '../Databricks/contents/replace_output_react'
import SparkElement from '../Databricks/contents/element_at_output_react'
import SparkCast from '../Databricks/contents/cast_output_react'
import SparkNa from '../Databricks/contents/na_output_react'
import SparkLit from '../Databricks/contents/lit_output_react'
import SparkUnionandunionbyname from '../Databricks/contents/unionAndUnionByName_output_react'
import SparkWithcolumnrenamed from '../Databricks/contents/withColumnRenamed_output_react'
import SparkCount from '../Databricks/contents/count_output_react'
import SparkRound from '../Databricks/contents/round_output_react'
import SparkMean from '../Databricks/contents/mean_output_react'
import SparkCeil from '../Databricks/contents/ceil_output_react'
import SparkSelect from '../Databricks/contents/select_output_react'
import SparkApprox from '../Databricks/contents/approx_count_distinct_output_react'
import SparkDrop from '../Databricks/contents/drop_output_react'
import SparkAgg from '../Databricks/contents/agg_output_react'
import SparkWithcolumn from '../Databricks/contents/withColumn_output_react'
import SparkSqrt from '../Databricks/contents/sqrt_output_react'
import SparkSparkvspyspark from '../Databricks/contents/1-sparkVsPyspark_output_react'
import SparkFilter from '../Databricks/contents/filter_output_react'
import SparkCorr from '../Databricks/contents/corr_output_react'
import SparkPivot from '../Databricks/contents/pivot_output_react'
import SparkDayofweek from '../Databricks/contents/dayofweek_output_react'
import SparkSum from '../Databricks/contents/sum_output_react'
import SparkLower from '../Databricks/contents/lower_output_react'
import SparkSparkvsdatabricks from '../Databricks/contents/sparkVsDatabricks_output_react'
import SparkLog from '../Databricks/contents/log_output_react'
import SparkToDate from '../Databricks/contents/to_date_output_react'
import SparkArray from '../Databricks/contents/array_contains_output_react'
import SparkMin from '../Databricks/contents/min_output_react'
import SparkMax from '../Databricks/contents/max_output_react'
import SparkSparkdataframe from '../Databricks/contents/sparkDataFrame_output_react'
import SparkUpper from '../Databricks/contents/upper_output_react'
import SparkCos from '../Databricks/contents/cos_output_react'
import SparkIsnull from '../Databricks/contents/isnull_output_react'
import SparkReader from '../Databricks/contents/Reader_output_react'
import SparkDateFormat from '../Databricks/contents/date_format_output_react'
import SparkCol from '../Databricks/contents/col_output_react'
import SparkSort from '../Databricks/contents/sort_output_react'
import SparkGettingStarted from '../Databricks/contents/0-SparkGettingStarted'
import Schema from '../Databricks/contents/schema_output_react'

const SparkTutorialData = [
    {'component': <SparkGettingStarted />, path:'getting-started', title: 'Getting Started'},
    {'component': <SparkSparkvspyspark />, 'path': 'spark-vs-pyspark', 'title': 'Apache Spark & PySpark'},
    // {'component': <SparkSparkvsdatabricks />, 'path': 'databricks', 'title': 'Databricks'},
    {id:uuidv4(), component: <SparkInstall />, path:'install', title: 'Install PySpark'},
    {'component': <SparkSparksession />, 'path': 'spark-session', 'title': 'Spark Session'},
    {id:uuidv4(), component: <SparkSparkdataframe />, path:'pyspark-dataframe', title: 'PySpark DataFrame'},
    {id:uuidv4(), path:'', title: 'Input'},
    {'component': <SparkCreatedataframe />, 'path': 'createDataFrame', 'title': 'create DataFrame '},
    {'component': <SparkReader />, 'path': 'dataframe-reader', 'title': 'Read Files to DataFrame'},
    {'component': <Schema />, 'path': 'dataframe-schema', 'title': 'Schema'},
    {id:uuidv4(), path:'', title: 'Output'},
    {'component': <SparkWriter />, 'path': 'dataframe-writer', 'title': 'Save DataFrame to Files'},
    {id:uuidv4(), component: <SparkView />, path:'views', title: 'Create View'},
    {'component': <SparkTable />, 'path': 'managed-and-external-table', 'title': 'table'},
    // {id:uuidv4(), component: <SparkInfo />, path:'compare-dataframe-view-table', title: 'Compare Databricks DataFrame, View, and Table'},
    {id:uuidv4(), path:'', title: 'Dataset'},
    {id:uuidv4(), 'component': <SparkExplode />, 'path': 'explode', 'title': 'explode'},
    {'component': <SparkNa />, 'path': 'fillna', 'title': 'fillna'},
    {'component': <SparkIsnull />, 'path': 'isnull', 'title': 'isNull'},
    {id:uuidv4(), 'component': <SparkJoin />, 'path': 'join', 'title': 'join'},
    {id:uuidv4(), 'component': <SparkLimit />, 'path': 'limit', 'title': 'limit'},
    {'component': <SparkPivot />, 'path': 'pivot', 'title': 'pivot'},
    {'component': <SparkShow />, 'path': 'show', 'title': 'show'},
    {'component': <SparkSelect />, 'path': 'select', 'title': 'select'},
    {'component': <SparkSelectexpr />, 'path': 'selectExpr', 'title': 'selectExpr'},
    {'component': <SparkSort />, 'path': 'sort', 'title': 'sort'},
    {'component': <SparkUnionandunionbyname />, 'path': 'union_unionByName', 'title': 'Union DataFrames'},
    {id:uuidv4(), path:'', title: 'Columns'},
    {'component': <SparkArray />, 'path': 'array_contains', 'title': 'array_contains'},
    {'component': <SparkCast />, 'path': 'cast', 'title': 'cast'},
    {'component': <SparkCol />, 'path': 'col', 'title': 'col'},
    {'component': <SparkDrop />, 'path': 'drop', 'title': 'drop'},
    {'component': <SparkElement />, 'path': 'element_at', 'title': 'element_at'},
    {'component': <SparkReplace />, 'path': 'replace', 'title': 'replace'},
    {'component': <SparkLit />, 'path': 'lit', 'title': 'lit'},
    {'component': <SparkLower />, 'path': 'lower', 'title': 'lower'},
    {id:uuidv4(), 'component': <SparkSplit />, 'path': 'split', 'title': 'split'},
    {'component': <SparkLtrim />, 'path': 'trim', 'title': 'trim'},
    {'component': <SparkUpper />, 'path': 'upper', 'title': 'upper'},
    {'component': <SparkWithcolumn />, 'path': 'withColumn', 'title': 'withColumn'}, 
    {'component': <SparkWithcolumnrenamed />, 'path': 'withColumnRenamed', 'title': 'withColumnRenamed'},
    {id:uuidv4(), path:'', title: 'Rows'},
    {'component': <SparkDropduplicates />, 'path': 'dropDuplicates', 'title': 'dropDuplicates'},
    {'component': <SparkFilter />, 'path': 'filter', 'title': 'filter'},
    {id:uuidv4(), path:'', title: 'Aggregation'},
    {'component': <SparkAgg />, 'path': 'aggregation', 'title': 'agg'},
    {'component': <SparkAvg />, 'path': 'avg', 'title': 'avg'},
    {'component': <SparkApprox />, 'path': 'approx', 'title': 'approx'},
    {'component': <SparkCount />, 'path': 'count', 'title': 'count'},
    {'component': <SparkCollectList />, 'path': 'collect_list', 'title': 'collect_list'},
    {'component': <SparkCollectSet />, 'path': 'collect_set', 'title': 'collect_set'},
    {'component': <SparkMin />, 'path': 'min', 'title': 'min'},
    {'component': <SparkMax />, 'path': 'max', 'title': 'max'},
    {'component': <SparkMean />, 'path': 'mean', 'title': 'mean'},
    {'component': <SparkSum />, 'path': 'sum', 'title': 'sum'},
    {id:uuidv4(), path:'', title: 'Date'},
    {'component': <SparkAddMonths />, 'path': 'add_months', 'title': 'add_months'},
    {'component': <SparkCurrent />, 'path': 'current_date', 'title': 'current_date'},
    {'component': <SparkDateAdd />, 'path': 'date_add', 'title': 'date_add'},
    {'component': <SparkDayofweek />, 'path': 'day-of-week', 'title': 'dayofweek'},
    {'component': <SparkDateFormat />, 'path': 'date_format', 'title': 'date_format'},
    {'component': <SparkToDate />, 'path': 'to_date', 'title': 'to_date'},
    {id:uuidv4(), path:'', title: 'Math'},
    {'component': <SparkCeil />, 'path': 'ceil', 'title': 'ceil'},
    {'component': <SparkCorr />, 'path': 'corr', 'title': 'corr'},
    {'component': <SparkCos />, 'path': 'cos', 'title': 'cos'},
    {'component': <SparkLog />, 'path': 'log', 'title': 'log'},
    {'component': <SparkRand />, 'path': 'rand', 'title': 'rand'},
    {'component': <SparkRound />, 'path': 'round', 'title': 'round'},
    {'component': <SparkStddev />, 'path': 'stddev_samp', 'title': 'stddev_samp'},
    {'component': <SparkSumdistinct />, 'path': 'sumDistinct', 'title': 'sumDistinct'},
    {'component': <SparkSqrt />, 'path': 'sqrt', 'title': 'sqrt'},
    {'component': <SparkVar />, 'path': 'var_pop', 'title': 'var_pop'},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},


]

export default SparkTutorialData


