import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2ScatterplotDiamonds from '../graphics/diamonds_marginal_distribution_completed.png'; 
import imgGgplot2ScatterplotDiamondsWebp from '../graphics/diamonds_marginal_distribution_completed.webp'; 
export default function Ggplot2ScatterplotDiamonds(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Draw Scatterplot with Logarithmic Scales in ggplot2 to Visualize Diamonds’ Price Relation with Size</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2ScatterplotDiamondsWebp} />
    <img className="cover-img" src={imgGgplot2ScatterplotDiamonds} />
  </picture>

<p>In this article, we’ll work on the <code>diamonds</code> dataset (built in ggplot2), and visualize the relationship between the diamonds’ price and carat size on logarithmic scales.</p>
<p><strong>Major techniques explained in this article include:</strong></p>
<ul>
<li><a href="#step1">Point shape &amp; aesthetic inheritance override in the legend.</a></li>
<li><a href="#logarithmic_axis">Logarithmic scale and annotations.</a></li>
<li><a href="#marginal_distribution">Visualize marginal distribution with <code>ggExtra</code> package.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>())</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">head</span>(diamonds, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 10
<br/>  carat cut     color clarity depth table price     x     y     z
<br/>  &lt;dbl&gt; &lt;ord&gt;   &lt;ord&gt; &lt;ord&gt;   &lt;dbl&gt; &lt;dbl&gt; &lt;int&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>1  0.23 Ideal   E     SI2      61.5    55   326  3.95  3.98  2.43
<br/>2  0.21 Premium E     SI1      59.8    61   326  3.89  3.84  2.31
<br/>3  0.23 Good    E     VS1      56.9    65   327  4.05  4.07  2.31</code></pre>
</div>
</div>
<p><span id="step1"><strong>1. Create a simple scatterplot.</strong> The “color” variable is mapped to both aesthetics of <code>color</code> and <code>fill</code> with the same <Link to="../../R/visualization/16-ggplot2-color-viridis-palette">viridis color palette</Link>. As the points are created in the shape of “<strong>.</strong>”, only the aesthetic mapping <code>color = color</code> is effective (the two “colors” respectively referring to the aesthetic and the variable name), while <code>fill = color</code> is not useful to adjust the scatterplot colors at this step. Instead, the <code>fill</code> aesthetic is created to prepare for the customization of the legend keys (see <a href="#step2">step 2</a>).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> diamonds <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> carat, <span className="at">y =</span> price, <span className="at">color =</span> color, <span className="at">fill =</span> color)) <span className="sc">+</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">shape =</span> <span className="st">"."</span>) <span className="sc">+</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="co"># color scale</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">scale_color_viridis_d</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">85</span>, .<span className="dv">4</span>)) </span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/diamonds_scatterplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/diamonds_scatterplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="step2"><strong>2. Enhance the visibility of legend keys.</strong> By default, the legend keys inherit aesthetic properties from the associated <code>geom_*</code>, and in this example are displayed as small semi-transparent dots. To make the keys visually more prominent, here we <em>override</em> the inheritance of <em>specific</em> properties by using <code>guides()</code> and <code>override.aes = list()</code> functions. The point shape 21 creates a circular outline and a filled interior in the legend (learn more <Link to="../../R/visualization/18-ggplot2-create-points">basics of points</Link>). Thanks to the earlier mapping of <code>fill</code> and <code>scale_fill_viridis_d</code> (review <a href="#step1">step 1</a>), the fill in the legend keys remain mapped to the “color” variable, and are thus consistent with the main plot.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>    <span className="at">override.aes =</span> <span className="fu">list</span>(</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">5</span>, <span className="at">alpha =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">shape =</span> <span className="dv">21</span>)))</span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/diamonds_legend_override.aes.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/diamonds_legend_override.aes"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="logarithmic_axis"><strong>3. Transform the x and y axes to logarithmic scale of base 2.</strong> (check out this example on <Link to="../ggplot2-scatterplot-urbanization">semi-log scale on base 10</Link>)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="co"># log 2 transformation</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">trans =</span> <span className="st">"log2"</span>) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="co"># breaks are original value before log transformation</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">trans =</span> <span className="st">"log2"</span>, <span className="at">breaks =</span> <span className="dv">2</span><span className="sc">^</span><span className="fu">c</span>(<span className="dv">9</span><span className="sc">:</span><span className="dv">14</span>)) <span className="sc">+</span>  </span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">annotation_logticks</span>(<span className="at">base =</span> <span className="dv">2</span>) </span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/diamonds_log_transformation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/diamonds_log_transformation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>4. Calculate the simple linear regression</strong> for each color grade of the diamond, showing the relationship between log2(price) and log2(carat).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="co"># regression calculated upon data after transformation</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">geom_smooth</span>(</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">method =</span> <span className="st">"lm"</span>, <span className="at">se =</span> F, <span className="at">linewidth =</span> .<span className="dv">5</span>, <span className="at">show.legend =</span> F) <span className="sc">+</span> </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="co"># zoom in</span></span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="co"># the limit are original values before the log transformation</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">xlim =</span> <span className="fu">c</span>(.<span className="dv">25</span>, <span className="dv">3</span>), <span className="at">ylim =</span> <span className="fu">c</span>(<span className="dv">400</span>, <span className="dv">2</span><span className="sc">^</span><span className="dv">14</span>))</span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/diamonds_regression_zoom.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/diamonds_regression_zoom"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="marginal_distribution"><strong>5. Visualize the price distribution</strong> for each color grade of the diamond using the <code>ggExtra</code> package.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># install.packages("ggExtra")</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">library</span>(ggExtra)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="fu">ggMarginal</span>(p4, <span className="at">margins =</span> <span className="st">"y"</span>, <span className="at">groupColour =</span> T) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/diamonds_marginal_distribution_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/diamonds_marginal_distribution_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>())</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a></span><br/>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a><span className="fu">head</span>(diamonds, <span className="dv">3</span>)</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a><span className="co"># Create a basic scatter plot. </span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> diamonds <span className="sc">%&gt;%</span> </span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> carat, <span className="at">y =</span> price, <span className="at">color =</span> color, <span className="at">fill =</span> color)) <span className="sc">+</span> </span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">shape =</span> <span className="st">"."</span>) <span className="sc">+</span></span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>  <span className="co"># color scale</span></span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span> (<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>  <span className="fu">scale_color_viridis_d</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">85</span>, .<span className="dv">4</span>)) </span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a>p1</span>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a></span><br/>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a></span><br/>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a><span className="co"># Enhance the visibility of legend keys. </span></span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(</span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>    <span className="at">override.aes =</span> <span className="fu">list</span>(</span>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">5</span>, <span className="at">alpha =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">shape =</span> <span className="dv">21</span>)))</span>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a>p2</span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a></span><br/>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a></span><br/>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a><span className="co"># Transform the x and y axes to logarithmic scale of base 2. </span></span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a>  <span className="co"># log 2 transformation</span></span>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">trans =</span> <span className="st">"log2"</span>) <span className="sc">+</span></span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a>  <span className="co"># breaks are original value before log transformation</span></span>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">trans =</span> <span className="st">"log2"</span>, <span className="at">breaks =</span> <span className="dv">2</span><span className="sc">^</span><span className="fu">c</span>(<span className="dv">9</span><span className="sc">:</span><span className="dv">14</span>)) <span className="sc">+</span>  </span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a>  <span className="fu">annotation_logticks</span>(<span className="at">base =</span> <span className="dv">2</span>) </span>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a>p3</span>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a></span><br/>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a></span><br/>
<span id="cb8-36"><a aria-hidden="true" href="#cb8-36" tabindex="-1"></a><span className="co"># Calculate a simple linear regression</span></span>
<span id="cb8-37"><a aria-hidden="true" href="#cb8-37" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb8-38"><a aria-hidden="true" href="#cb8-38" tabindex="-1"></a>  <span className="co"># regression calculated upon data after transformation</span></span>
<span id="cb8-39"><a aria-hidden="true" href="#cb8-39" tabindex="-1"></a>  <span className="fu">geom_smooth</span>(</span>
<span id="cb8-40"><a aria-hidden="true" href="#cb8-40" tabindex="-1"></a>    <span className="at">method =</span> <span className="st">"lm"</span>, <span className="at">se =</span> F, <span className="at">linewidth =</span> .<span className="dv">5</span>, <span className="at">show.legend =</span> F) <span className="sc">+</span> </span>
<span id="cb8-41"><a aria-hidden="true" href="#cb8-41" tabindex="-1"></a>  <span className="co"># zoom in</span></span>
<span id="cb8-42"><a aria-hidden="true" href="#cb8-42" tabindex="-1"></a>  <span className="co"># the limit are original values before the log transformation</span></span>
<span id="cb8-43"><a aria-hidden="true" href="#cb8-43" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">xlim =</span> <span className="fu">c</span>(.<span className="dv">25</span>, <span className="dv">3</span>), <span className="at">ylim =</span> <span className="fu">c</span>(<span className="dv">400</span>, <span className="dv">2</span><span className="sc">^</span><span className="dv">14</span>))</span>
<span id="cb8-44"><a aria-hidden="true" href="#cb8-44" tabindex="-1"></a>p4</span>
<span id="cb8-45"><a aria-hidden="true" href="#cb8-45" tabindex="-1"></a></span><br/>
<span id="cb8-46"><a aria-hidden="true" href="#cb8-46" tabindex="-1"></a></span><br/>
<span id="cb8-47"><a aria-hidden="true" href="#cb8-47" tabindex="-1"></a><span className="co"># Visualize the marginal distribution of price</span></span>
<span id="cb8-48"><a aria-hidden="true" href="#cb8-48" tabindex="-1"></a><span className="co"># install.packages("ggExtra")</span></span>
<span id="cb8-49"><a aria-hidden="true" href="#cb8-49" tabindex="-1"></a><span className="fu">library</span>(ggExtra)</span>
<span id="cb8-50"><a aria-hidden="true" href="#cb8-50" tabindex="-1"></a><span className="fu">ggMarginal</span>(p4, <span className="at">margins =</span> <span className="st">"y"</span>, <span className="at">groupColour =</span> T) </span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>A scatterplot is often enhanced by visualizing the marginal (univariate) distribution of the x and y variables, and the bivariate distribution pattern with confidence ellipses. Check out the following <Link to="../ggplot2-penguin-scatterplot-ellipse-ggExtra"><strong>scatterplot with marginal and ellipses visualization</strong></Link>.</p>
<p><Link to="../ggplot2-penguin-scatterplot-ellipse-ggExtra">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_ggExtra_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/penguin_ggExtra_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>For data with high skewness, mathematical transformations are a powerful tool to aid in visualizing the underlying data structure, as shown in the diamonds’ scatterplot. In addition, math transformations are also commonly leveraged in the color scale. Check out the following awesome <Link to="../ggplot2-heatmap-African-population"><strong>heatmap on African population density</strong></Link>, with <strong>pseudo-logarithmic transformation in the color scale to unveil highly skewed data pattern</strong>.</p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}