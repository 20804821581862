import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrSub(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Select Characters by Specified Positions</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_sub()</code></strong> extracts characters from the <code>start</code> position to the <code>end</code> position, which are specified with integer numbers.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"sweet"</span>, <span className="st">"egg"</span>, <span className="st">"tarts"</span>)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">str_sub</span>(x, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="dv">3</span>) <span className="co"># select 1st to 3rd character</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "swe" "egg" "tar"</code></pre>
</div>
</div>
<p>The <code>start</code> and <code>end</code> index number can be either positive or negative. A positive integer counts from the left side of a string, and a negative integer counts from the right side of the string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># select the 1st to 2nd-to-last character</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">str_sub</span>(x, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="sc">-</span><span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "swee" "eg"   "tart"</code></pre>
</div>
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># select the 2nd-to-last to the last character</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="fu">str_sub</span>(x, <span className="at">start =</span> <span className="sc">-</span><span className="dv">2</span>, <span className="at">end =</span> <span className="sc">-</span><span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "et" "gg" "ts"</code></pre>
</div>
</div>
<p>If the selected range of characters go beyond the limit of strings, the extra positions will be silently removed. In the following example, the 4th and 5th character is not available in “egg”, and only the 3rd character is selected.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># select the 3rd to the 5th character</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">str_sub</span>(x, <span className="at">start =</span> <span className="dv">3</span>, <span className="at">end =</span> <span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "eet" "g"   "rts"</code></pre>
</div>
</div>
<h4 className="anchored">
🏆 <strong>One level up !</strong>
</h4>
<p>We can use <code>str_sub()</code> to modify strings, e.g., replace ‘juicy’ with ‘yummy’ in the following example.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"juicy melon"</span>)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">str_sub</span>(a, <span className="dv">1</span>, <span className="dv">5</span>) <span className="ot">&lt;-</span> <span className="st">"yummy"</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "yummy melon"</code></pre>
</div>
</div>
<p>Here we use an empty quote to remove the white space at 6th position.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">str_sub</span>(a, <span className="dv">6</span>, <span className="dv">6</span>) <span className="ot">&lt;-</span> <span className="st">""</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "yummymelon"</code></pre>
</div>
</div>
<h4 className="anchored">
🏵️ <strong>Expert Skill !</strong>
</h4>
<p>Position vectors can be used to select different positions for different strings. In the following example, we select the 1st to 2nd letters for “mozzarella”, and 3rd-to-last character to the last character in “smearcase”, respectively.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"mozzarella"</span>, <span className="st">"smearcase"</span>)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">str_sub</span>(x, <span className="at">start =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="sc">-</span><span className="dv">3</span>), <span className="at">end =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="sc">-</span><span className="dv">1</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mo"  "ase"</code></pre>
</div>
</div>
<p>Slightly different from <code>str_sub()</code>, the function <code>str_sub_all()</code> outputs a <em>list</em> of the same length as the input vector.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">str_sub_all</span>(x, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[[1]]
<br/>[1] "mo"

<br/>[[2]]
<br/>[1] "sm"</code></pre>
</div>
</div>
<p>When the <code>start</code> and <code>end</code> positions are vectors, all specified positions are applied to each of the string elements, and output as a list. In the following code, the 1st to 2nd, 1st to 3rd, and 2nd to 4th characters are selected from each of the two strings.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="fu">str_sub_all</span>(x, <span className="at">start =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">2</span>), <span className="at">end =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">4</span>)) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[[1]]
<br/>[1] "mo"  "moz" "ozz"

<br/>[[2]]
<br/>[1] "sm"  "sme" "mea"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}