import React from 'react'; 
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkSum(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="sum"><code>sum()</code><a class="anchor-link" href="#sum">¶</a></h3><p>The <code>sum()</code> function is an aggregation function that computes the total sum of a numeric column in a DataFrame.</p>
<h4 id="Usage">Usage<a class="anchor-link" href="#Usage">¶</a></h4><ul>
<li><code>sum()</code> can be applied to a DataFrame to calculate the total of a specific column.</li>
<li>When combined with <code>groupBy()</code>, it can be used to compute the sum for each group in a dataset.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session-and-sample-DataFrame">Create Spark Session and sample DataFrame<a class="anchor-link" href="#Create-Spark-Session-and-sample-DataFrame">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>
<span><span class="kn">from</span> <span class="nn">pyspark.sql.functions</span> <span class="kn">import</span> <span class="nb">sum</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"sumExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>

<br /><span><span class="c1"># Sample DataFrame</span></span>
<span><span class="n">data</span> <span class="o">=</span> <span class="p">[(</span><span class="s2">"group A"</span><span class="p">,</span> <span class="mi">45</span><span class="p">),</span> <span class="p">(</span><span class="s2">"group A"</span><span class="p">,</span> <span class="mi">30</span><span class="p">),</span> <span class="p">(</span><span class="s2">"group A"</span><span class="p">,</span> <span class="mi">55</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"group B"</span><span class="p">,</span> <span class="mi">10</span><span class="p">),</span> <span class="p">(</span><span class="s2">"group B"</span><span class="p">,</span> <span class="mi">20</span><span class="p">),</span> <span class="p">(</span><span class="s2">"group B"</span><span class="p">,</span> <span class="mi">60</span><span class="p">),</span></span>
<span>       <span class="p">]</span></span>
<span><span class="n">columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Group"</span><span class="p">,</span> <span class="s2">"Variable"</span><span class="p">]</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="p">,</span> <span class="n">columns</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+-------+--------+
<br />|  Group|Variable|
<br />+-------+--------+
<br />|group A|      45|
<br />|group A|      30|
<br />|group A|      55|
<br />|group B|      10|
<br />|group B|      20|
<br />|group B|      60|
<br />+-------+--------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-sum-to-get-the-total-sum-of-a-numeric-column">Example: Use <code>sum()</code> to get the total sum of a numeric column<a class="anchor-link" href="#Example:-Use-sum-to-get-the-total-sum-of-a-numeric-column">¶</a></h4><ul>
<li><code>sum("Variable")</code>: it calculates the sum of value in the <strong>Variable</strong> column of the DataFrame <strong>df</strong>. </li>
<li><code>alias("Sum of Variable")</code>: it renames the resulting column as <strong>Sum of Variable</strong>.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">total_df</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">select</span><span class="p">(</span><span class="nb">sum</span><span class="p">(</span><span class="s2">"Variable"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"Sum of Variable"</span><span class="p">))</span></span>
<span><span class="n">total_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---------------+
<br />|Sum of Variable|
<br />+---------------+
<br />|            220|
<br />+---------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-sum-and-groupBy-to-get-sum-of-different-groups">Example: Use <code>sum()</code> and <code>groupBy()</code> to get sum of different groups<a class="anchor-link" href="#Example:-Use-sum-and-groupBy-to-get-sum-of-different-groups">¶</a></h4><ul>
<li><code>groupBy("Group")</code>: it groups the <strong>df</strong> DataFrame by the <strong>Group</strong> column.</li>
<li><code>agg</code>: <code>agg</code> function is used for aggregation functions.</li>
<li><code>sum("Variable")</code>: it sums up <strong>Variable</strong> values of each unique group. </li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">grouped_data</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">groupBy</span><span class="p">(</span><span class="s2">"Group"</span><span class="p">)</span><span class="o">.</span><span class="n">agg</span><span class="p">(</span><span class="nb">sum</span><span class="p">(</span><span class="s2">"Variable"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"Sum of Variable"</span><span class="p">))</span></span>
<span><span class="n">grouped_data</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+-------+---------------+
<br />|  Group|Sum of Variable|
<br />+-------+---------------+
<br />|group A|            130|
<br />|group B|             90|
<br />+-------+---------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}