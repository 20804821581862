import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2DonutGdp from '../graphics/doughnut_plot_GDP_ggdraw_completed.png'; 
import imgGgplot2DonutGdpWebp from '../graphics/doughnut_plot_GDP_ggdraw_completed.webp'; 
export default function Ggplot2DonutGdp(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Faceted Doughnut Charts with ggplot2 to Visualize Continent-Wise GDP</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2DonutGdpWebp} />
    <img className="cover-img" src={imgGgplot2DonutGdp} />
  </picture>

<p>In this article, we’ll leverage the doughnut chart to visualize the GDP contribution proportion of the top 4 countries separately in each continent. <strong>Major techniques covered in this tutorial include:</strong></p>
<ul>
<li>Specify the stacking order of bars and doughnut slices.</li>
<li>Transform a linear coordinate into a polar coordinate.</li>
<li>Create a doughnut chart with text annotation.</li>
<li>Combine two plots together using the <code>cowplot</code> package.</li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>We’ll use the <code>gapminder</code> dataset from the famous <code>gapminder</code> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(gapminder) <span className="co"># use its dataset</span></span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)     <span className="co"># data wrangling  </span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)   <span className="co"># string manipulation</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">library</span>(scico)     <span className="co"># color palettes</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">library</span>(cowplot)   <span className="co"># combine plots together</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">head</span>(gapminder, <span className="dv">3</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  country     continent  year lifeExp      pop gdpPercap
<br/>  &lt;fct&gt;       &lt;fct&gt;     &lt;int&gt;   &lt;dbl&gt;    &lt;int&gt;     &lt;dbl&gt;
<br/>1 Afghanistan Asia       1952    28.8  8425333      779.
<br/>2 Afghanistan Asia       1957    30.3  9240934      821.
<br/>3 Afghanistan Asia       1962    32.0 10267083      853.</code></pre>
</div>
</div>
<p>We’ll use the subset of year 2007 for demonstration. First, we calculate the total GDP (GDP per capita multiplied by the population) in each country in 2007.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">c</span>(<span className="dv">2007</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># calculate total GDP in billions in each country</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">gdp.Total =</span> pop <span className="sc">*</span> gdpPercap <span className="sc">/</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">9</span>)</span></code></pre></div>
</div>
<p><span id="rank">Prepare a dataset containing the GDP of the <em>top 4</em> countries, as well as the sum of the rest of the countries (named as “others”), separately in each continent. In particular, we create a <em>factor</em> variable <code>gdp.rank</code> which ranks countries from 1 to 5. This variable specifies the <Link to="../../R/visualization/7-ggplot2-reorder-stacking-of-bars"><strong>stacking order</strong></Link> of the countries in the bars / doughnut plot. (Check <Link to="../../R/visualization/4-ggplot2-reorder-bars">here</Link> for a complete guide on graphic reordering)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># separately for each continent, rank countries' GDP in descending order</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>g <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">group_by</span>(continent) <span className="sc">%&gt;%</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">gdp.rank =</span> <span className="fu">rank</span>(<span className="fu">desc</span>(gdp.Total))) <span className="sc">%&gt;%</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  </span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>    <span className="co"># in each continent, rename all countries ranked below 4th as "others" </span></span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>    <span className="at">country  =</span> <span className="fu">ifelse</span>(gdp.rank <span className="sc">&lt;=</span> <span className="dv">4</span>, <span className="fu">as.character</span>(country), <span className="st">"others"</span>),</span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a>    </span>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a>    <span className="co"># rank the "others" as 5th, and convert the rank to a factor variable</span></span>
<span id="cb4-11"><a aria-hidden="true" href="#cb4-11" tabindex="-1"></a>    <span className="co"># the factor level determines the stacking order of the bar </span></span>
<span id="cb4-12"><a aria-hidden="true" href="#cb4-12" tabindex="-1"></a>    <span className="at">gdp.rank =</span> <span className="fu">ifelse</span>(gdp.rank <span className="sc">&lt;=</span> <span className="dv">4</span>, gdp.rank, <span className="dv">5</span>) <span className="sc">%&gt;%</span> <span className="fu">factor</span>(<span className="at">levels =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb4-13"><a aria-hidden="true" href="#cb4-13" tabindex="-1"></a>  </span>
<span id="cb4-14"><a aria-hidden="true" href="#cb4-14" tabindex="-1"></a>  <span className="co"># calculate the GDP of the 5 countries (including "others") in each continent</span></span>
<span id="cb4-15"><a aria-hidden="true" href="#cb4-15" tabindex="-1"></a>  <span className="fu">group_by</span>(continent, country, gdp.rank) <span className="sc">%&gt;%</span> </span>
<span id="cb4-16"><a aria-hidden="true" href="#cb4-16" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">gdp.Total =</span> <span className="fu">sum</span>(gdp.Total))</span>
<span id="cb4-17"><a aria-hidden="true" href="#cb4-17" tabindex="-1"></a></span><br/>
<span id="cb4-18"><a aria-hidden="true" href="#cb4-18" tabindex="-1"></a><span className="fu">head</span>(g, <span className="dv">3</span>) <span className="co"># ready for visualization!</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/># Groups:   continent, country [3]
<br/>  continent country gdp.rank gdp.Total
<br/>  &lt;fct&gt;     &lt;chr&gt;   &lt;fct&gt;        &lt;dbl&gt;
<br/>1 Africa    Algeria 4             207.
<br/>2 Africa    Egypt   1             448.
<br/>3 Africa    Nigeria 3             272.</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>We’ll first create a stacked bar plot in the linear Cartesian coordinate (the default system), and then convert the bars into a doughnut plot by transforming the linear coordinate into a polar coordinate. Check this <Link to="../../R/visualization/17-ggplot2-polar-coord-summary"><strong>very useful summary</strong></Link> to develop an intuitive insight into the relationship between the linear and polar coordinate.</p>
<p><strong>1.</strong> Create a stacked bar plot for each continent. One critical edit here is the use of the <code>fill</code> position to normalize the bars to the same total height of 1 unit. This normalization ensures a <em>complete 360-degree</em> in the doughnut circles that the bars will be transformed into; otherwise, in faceted panels, bars of varying heights will become <em>fractional</em> circles when converted in a polar coordinate – check <Link to="../../R/visualization/17-ggplot2-polar-coord-summary"><strong>here</strong></Link> to learn an illustrated example.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> g  <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1</span>, <span className="at">y =</span> gdp.Total, </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>             <span className="co"># color based on rank</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>             <span className="at">fill =</span> gdp.rank, <span className="at">color =</span> gdp.rank)) <span className="sc">+</span> </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">geom_col</span>(</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="co"># bars stacked and normalized to the range of [0, 1] </span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(), </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>    <span className="co"># the bar outline creates the explosive effect in doughnut chart</span></span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>    <span className="co"># we'll later merge the outline into the background color</span></span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"floralwhite"</span>, </span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>    <span className="at">linewidth =</span> <span className="dv">2</span>, <span className="at">alpha =</span> .<span className="dv">7</span>) <span className="sc">+</span> </span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>  <span className="fu">facet_wrap</span>( <span className="sc">~</span> continent, <span className="at">nrow =</span> <span className="dv">2</span>) </span>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_stacked_bars.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_stacked_bars"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>2.</strong> Add country names to the <em>right</em> side of the bar. Once transformed to a polar coordinate, the texts will be positioned on the <em>peripheral</em> side of the doughnut. (Don’t worry that only half of the texts are displayed by this step. We’ll fix it later)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="fl">2.3</span>, <span className="at">label =</span> <span className="fu">str_wrap</span>(country, <span className="at">width =</span> <span className="dv">1</span>)), </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_texts_right.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_texts_right"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>3.</strong> Add names of the continents to the left edge of the plot (with an x-coordinate value of -1). The empty space created on the <em>left</em> side of the bar will become a <em>central</em> hole of the doughnut plot when transformed into a polar coordinate. A smaller x-coordinate value will create more space on the left side of the bar, and corresponds to a thinner doughnut.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="sc">-</span><span className="dv">1</span>, <span className="at">y =</span> .<span className="dv">5</span>, <span className="at">label =</span> continent),</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>, <span className="at">color =</span> <span className="st">"grey50"</span>)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_texts_left.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_texts_left"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="linear_polar"><strong>4.</strong> Transform the linear Cartesian coordinate into the polar coordinate system.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">coord_polar</span>(</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># map the y-aesthetic to angles  </span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="at">theta =</span> <span className="st">"y"</span>, </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="co"># show texts that extend beyond the panel range</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  <span className="at">clip =</span> <span className="st">"off"</span>) </span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_polar_coordinate.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_polar_coordinate"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>5.</strong> Clean up the theme. Now it can be seen clearly that in the doughnut chart of each continent, in a counterclockwise direction and going from blue to pink, each doughnut piece corresponds to the 1st to the 4th largest GDP countries, followed by the sum of the rest of the countries. This consistency is achieved by specifying the bar stacking order via the <code>gdp.rank</code> variable (<a href="#rank">review this earlier step</a>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="co"># color scale, using the "scico" package</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">scale_fill_scico_d</span>() <span className="sc">+</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">scale_color_scico_d</span>() <span className="sc">+</span></span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  </span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> <span className="co"># a commonly used theme for pie/doughnut charts</span></span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>, </span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>        <span className="at">strip.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>        </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>        <span className="co"># reduce the spacing between panels</span></span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>        <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="sc">-</span><span className="dv">20</span>, <span className="st">"pt"</span>), </span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>        </span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>        <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"floralwhite"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>        <span className="co"># increase space to the left and right side of the plot</span></span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">l =</span> <span className="dv">30</span>, <span className="at">r =</span> <span className="dv">20</span>))  </span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>6.</strong> Create a plot title at the bottom right corner. Since it’s not straightforward to directly annotate in one of the faceted panels in a polar coordinate, we’ll instead first create a separate ggplot2 object of the plot title. Then, we’ll combine the doughnut plot and the title plot in the next step.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p.title <span className="ot">&lt;-</span> <span className="fu">ggplot</span>() <span className="sc">+</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"2007 GDP of</span><span className="sc">\n</span><span className="st">the TOP 4 countries</span><span className="sc">\n</span><span className="st">in each continent"</span>,</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>    <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="dv">0</span>, <span className="at">size =</span> <span className="dv">5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>,</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"steelblue4"</span>) <span className="sc">+</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>  <span className="fu">theme_void</span>()</span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a></span><br/>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>p.title</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_title.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_title"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>7.</strong> Combine the doughnut plot and the title plot using the <code>cowplot</code> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>artwork <span className="ot">&lt;-</span> <span className="fu">ggdraw</span>() <span className="sc">+</span></span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">draw_plot</span>(p5) <span className="sc">+</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">draw_plot</span>(p.title, <span className="at">x =</span> .<span className="dv">3</span>, <span className="at">y =</span> <span className="sc">-</span>.<span className="dv">25</span>,</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>            <span className="at">width =</span> <span className="dv">1</span>)</span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a></span><br/>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>artwork</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/doughnut_plot_GDP_ggdraw_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>8</strong> Save the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"2007 GDP doughnut.pdf"</span>, </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">8</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a><span className="fu">library</span>(gapminder) <span className="co"># use its dataset</span></span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)     <span className="co"># data wrangling  </span></span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a><span className="fu">library</span>(stringr)   <span className="co"># string manipulation</span></span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a><span className="fu">library</span>(scico)     <span className="co"># color palettes</span></span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a><span className="fu">library</span>(cowplot)   <span className="co"># combine plots together</span></span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a></span><br/>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a><span className="co"># Calculate the total GDP (GDP per capita times population) in each country in 2007. </span></span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">c</span>(<span className="dv">2007</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>  <span className="co"># calculate total GDP in billions in each country</span></span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">gdp.Total =</span> pop <span className="sc">*</span> gdpPercap <span className="sc">/</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">9</span>)</span>
<span id="cb14-13"><a aria-hidden="true" href="#cb14-13" tabindex="-1"></a></span><br/>
<span id="cb14-14"><a aria-hidden="true" href="#cb14-14" tabindex="-1"></a></span><br/>
<span id="cb14-15"><a aria-hidden="true" href="#cb14-15" tabindex="-1"></a><span className="co"># Prepare a dataset containing the GDP of the top 4 countries, </span></span>
<span id="cb14-16"><a aria-hidden="true" href="#cb14-16" tabindex="-1"></a><span className="co"># and the sum of the rest of the countries, separately in each continent.</span></span>
<span id="cb14-17"><a aria-hidden="true" href="#cb14-17" tabindex="-1"></a></span><br/>
<span id="cb14-18"><a aria-hidden="true" href="#cb14-18" tabindex="-1"></a>g <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb14-19"><a aria-hidden="true" href="#cb14-19" tabindex="-1"></a>  <span className="co"># separately for each continent:</span></span>
<span id="cb14-20"><a aria-hidden="true" href="#cb14-20" tabindex="-1"></a>  <span className="fu">group_by</span>(continent) <span className="sc">%&gt;%</span> </span>
<span id="cb14-21"><a aria-hidden="true" href="#cb14-21" tabindex="-1"></a>  <span className="co"># rank countries' GDP in descending order</span></span>
<span id="cb14-22"><a aria-hidden="true" href="#cb14-22" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">gdp.rank =</span> <span className="fu">rank</span>(<span className="fu">desc</span>(gdp.Total))) <span className="sc">%&gt;%</span></span>
<span id="cb14-23"><a aria-hidden="true" href="#cb14-23" tabindex="-1"></a>  </span>
<span id="cb14-24"><a aria-hidden="true" href="#cb14-24" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb14-25"><a aria-hidden="true" href="#cb14-25" tabindex="-1"></a>    <span className="co"># in each continent, rename all countries ranked below 4th as "others" </span></span>
<span id="cb14-26"><a aria-hidden="true" href="#cb14-26" tabindex="-1"></a>    <span className="at">country  =</span> <span className="fu">ifelse</span>(gdp.rank <span className="sc">&lt;=</span> <span className="dv">4</span>, <span className="fu">as.character</span>(country), <span className="st">"others"</span>),</span>
<span id="cb14-27"><a aria-hidden="true" href="#cb14-27" tabindex="-1"></a>    </span>
<span id="cb14-28"><a aria-hidden="true" href="#cb14-28" tabindex="-1"></a>    <span className="co"># rank the "others" as 5th, and convert the rank to a factor variable</span></span>
<span id="cb14-29"><a aria-hidden="true" href="#cb14-29" tabindex="-1"></a>    <span className="co"># the factor level determines the stacking order of the bar </span></span>
<span id="cb14-30"><a aria-hidden="true" href="#cb14-30" tabindex="-1"></a>    <span className="at">gdp.rank =</span> <span className="fu">ifelse</span>(gdp.rank <span className="sc">&lt;=</span> <span className="dv">4</span>, gdp.rank, <span className="dv">5</span>) <span className="sc">%&gt;%</span> <span className="fu">factor</span>(<span className="at">levels =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb14-31"><a aria-hidden="true" href="#cb14-31" tabindex="-1"></a>  </span>
<span id="cb14-32"><a aria-hidden="true" href="#cb14-32" tabindex="-1"></a>  <span className="co"># calculate the GDP of the 5 countries (including "others") in each continent</span></span>
<span id="cb14-33"><a aria-hidden="true" href="#cb14-33" tabindex="-1"></a>  <span className="fu">group_by</span>(continent, country, gdp.rank) <span className="sc">%&gt;%</span> </span>
<span id="cb14-34"><a aria-hidden="true" href="#cb14-34" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">gdp.Total =</span> <span className="fu">sum</span>(gdp.Total))</span>
<span id="cb14-35"><a aria-hidden="true" href="#cb14-35" tabindex="-1"></a></span><br/>
<span id="cb14-36"><a aria-hidden="true" href="#cb14-36" tabindex="-1"></a><span className="fu">head</span>(g, <span className="dv">3</span>) <span className="co"># ready for visualization!</span></span>
<span id="cb14-37"><a aria-hidden="true" href="#cb14-37" tabindex="-1"></a></span><br/>
<span id="cb14-38"><a aria-hidden="true" href="#cb14-38" tabindex="-1"></a></span><br/>
<span id="cb14-39"><a aria-hidden="true" href="#cb14-39" tabindex="-1"></a><span className="co"># Create a stacked bar plot for each continent. </span></span>
<span id="cb14-40"><a aria-hidden="true" href="#cb14-40" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> g  <span className="sc">%&gt;%</span> </span>
<span id="cb14-41"><a aria-hidden="true" href="#cb14-41" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1</span>, <span className="at">y =</span> gdp.Total, </span>
<span id="cb14-42"><a aria-hidden="true" href="#cb14-42" tabindex="-1"></a>             <span className="co"># color based on rank</span></span>
<span id="cb14-43"><a aria-hidden="true" href="#cb14-43" tabindex="-1"></a>             <span className="at">fill =</span> gdp.rank, <span className="at">color =</span> gdp.rank)) <span className="sc">+</span> </span>
<span id="cb14-44"><a aria-hidden="true" href="#cb14-44" tabindex="-1"></a>  <span className="fu">geom_col</span>(</span>
<span id="cb14-45"><a aria-hidden="true" href="#cb14-45" tabindex="-1"></a>    <span className="co"># bars stacked and normalized to the range of [0, 1] </span></span>
<span id="cb14-46"><a aria-hidden="true" href="#cb14-46" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(), </span>
<span id="cb14-47"><a aria-hidden="true" href="#cb14-47" tabindex="-1"></a>    <span className="co"># the bar outline creates the explosive effect in doughnut chart</span></span>
<span id="cb14-48"><a aria-hidden="true" href="#cb14-48" tabindex="-1"></a>    <span className="co"># we'll later merge the outline into the background color</span></span>
<span id="cb14-49"><a aria-hidden="true" href="#cb14-49" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"floralwhite"</span>, </span>
<span id="cb14-50"><a aria-hidden="true" href="#cb14-50" tabindex="-1"></a>    <span className="at">linewidth =</span> <span className="dv">2</span>, <span className="at">alpha =</span> .<span className="dv">7</span>) <span className="sc">+</span> </span>
<span id="cb14-51"><a aria-hidden="true" href="#cb14-51" tabindex="-1"></a>  <span className="fu">facet_wrap</span>( <span className="sc">~</span> continent, <span className="at">nrow =</span> <span className="dv">2</span>) </span>
<span id="cb14-52"><a aria-hidden="true" href="#cb14-52" tabindex="-1"></a>p1</span>
<span id="cb14-53"><a aria-hidden="true" href="#cb14-53" tabindex="-1"></a></span><br/>
<span id="cb14-54"><a aria-hidden="true" href="#cb14-54" tabindex="-1"></a></span><br/>
<span id="cb14-55"><a aria-hidden="true" href="#cb14-55" tabindex="-1"></a><span className="co"># Add country names to the right side of the bar. </span></span>
<span id="cb14-56"><a aria-hidden="true" href="#cb14-56" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb14-57"><a aria-hidden="true" href="#cb14-57" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb14-58"><a aria-hidden="true" href="#cb14-58" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="fl">2.3</span>, <span className="at">label =</span> <span className="fu">str_wrap</span>(country, <span className="at">width =</span> <span className="dv">1</span>)), </span>
<span id="cb14-59"><a aria-hidden="true" href="#cb14-59" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb14-60"><a aria-hidden="true" href="#cb14-60" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>)</span>
<span id="cb14-61"><a aria-hidden="true" href="#cb14-61" tabindex="-1"></a>p2</span>
<span id="cb14-62"><a aria-hidden="true" href="#cb14-62" tabindex="-1"></a></span><br/>
<span id="cb14-63"><a aria-hidden="true" href="#cb14-63" tabindex="-1"></a></span><br/>
<span id="cb14-64"><a aria-hidden="true" href="#cb14-64" tabindex="-1"></a><span className="co"># Add names of the continents to the left edge of the plot.</span></span>
<span id="cb14-65"><a aria-hidden="true" href="#cb14-65" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb14-66"><a aria-hidden="true" href="#cb14-66" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb14-67"><a aria-hidden="true" href="#cb14-67" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="sc">-</span><span className="dv">1</span>, <span className="at">y =</span> .<span className="dv">5</span>, <span className="at">label =</span> continent),</span>
<span id="cb14-68"><a aria-hidden="true" href="#cb14-68" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>, <span className="at">color =</span> <span className="st">"grey50"</span>)</span>
<span id="cb14-69"><a aria-hidden="true" href="#cb14-69" tabindex="-1"></a>p3</span>
<span id="cb14-70"><a aria-hidden="true" href="#cb14-70" tabindex="-1"></a></span><br/>
<span id="cb14-71"><a aria-hidden="true" href="#cb14-71" tabindex="-1"></a></span><br/>
<span id="cb14-72"><a aria-hidden="true" href="#cb14-72" tabindex="-1"></a><span className="co"># Transform the linear Cartesian coordinate into the polar coordinate system. </span></span>
<span id="cb14-73"><a aria-hidden="true" href="#cb14-73" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">coord_polar</span>(</span>
<span id="cb14-74"><a aria-hidden="true" href="#cb14-74" tabindex="-1"></a>  <span className="co"># map the y-aesthetic to angles  </span></span>
<span id="cb14-75"><a aria-hidden="true" href="#cb14-75" tabindex="-1"></a>  <span className="at">theta =</span> <span className="st">"y"</span>, </span>
<span id="cb14-76"><a aria-hidden="true" href="#cb14-76" tabindex="-1"></a>  <span className="co"># show texts that extend beyond the panel range</span></span>
<span id="cb14-77"><a aria-hidden="true" href="#cb14-77" tabindex="-1"></a>  <span className="at">clip =</span> <span className="st">"off"</span>) </span>
<span id="cb14-78"><a aria-hidden="true" href="#cb14-78" tabindex="-1"></a>p4</span>
<span id="cb14-79"><a aria-hidden="true" href="#cb14-79" tabindex="-1"></a></span><br/>
<span id="cb14-80"><a aria-hidden="true" href="#cb14-80" tabindex="-1"></a></span><br/>
<span id="cb14-81"><a aria-hidden="true" href="#cb14-81" tabindex="-1"></a><span className="co"># Clean up the theme. </span></span>
<span id="cb14-82"><a aria-hidden="true" href="#cb14-82" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb14-83"><a aria-hidden="true" href="#cb14-83" tabindex="-1"></a>  <span className="co"># color scale, using the "scico" package</span></span>
<span id="cb14-84"><a aria-hidden="true" href="#cb14-84" tabindex="-1"></a>  <span className="fu">scale_fill_scico_d</span>() <span className="sc">+</span></span>
<span id="cb14-85"><a aria-hidden="true" href="#cb14-85" tabindex="-1"></a>  <span className="fu">scale_color_scico_d</span>() <span className="sc">+</span></span>
<span id="cb14-86"><a aria-hidden="true" href="#cb14-86" tabindex="-1"></a>  </span>
<span id="cb14-87"><a aria-hidden="true" href="#cb14-87" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> <span className="co"># a commonly used theme for pie/doughnut charts</span></span>
<span id="cb14-88"><a aria-hidden="true" href="#cb14-88" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>, </span>
<span id="cb14-89"><a aria-hidden="true" href="#cb14-89" tabindex="-1"></a>        <span className="at">strip.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb14-90"><a aria-hidden="true" href="#cb14-90" tabindex="-1"></a>        </span>
<span id="cb14-91"><a aria-hidden="true" href="#cb14-91" tabindex="-1"></a>        <span className="co"># reduce the spacing between panels</span></span>
<span id="cb14-92"><a aria-hidden="true" href="#cb14-92" tabindex="-1"></a>        <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="sc">-</span><span className="dv">20</span>, <span className="st">"pt"</span>), </span>
<span id="cb14-93"><a aria-hidden="true" href="#cb14-93" tabindex="-1"></a>        </span>
<span id="cb14-94"><a aria-hidden="true" href="#cb14-94" tabindex="-1"></a>        <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"floralwhite"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb14-95"><a aria-hidden="true" href="#cb14-95" tabindex="-1"></a>        <span className="co"># increase space to the left and right side of the plot</span></span>
<span id="cb14-96"><a aria-hidden="true" href="#cb14-96" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">l =</span> <span className="dv">30</span>, <span className="at">r =</span> <span className="dv">20</span>))  </span>
<span id="cb14-97"><a aria-hidden="true" href="#cb14-97" tabindex="-1"></a>p5</span>
<span id="cb14-98"><a aria-hidden="true" href="#cb14-98" tabindex="-1"></a></span><br/>
<span id="cb14-99"><a aria-hidden="true" href="#cb14-99" tabindex="-1"></a></span><br/>
<span id="cb14-100"><a aria-hidden="true" href="#cb14-100" tabindex="-1"></a><span className="co"># Create the plot title. </span></span>
<span id="cb14-101"><a aria-hidden="true" href="#cb14-101" tabindex="-1"></a>p.title <span className="ot">&lt;-</span> <span className="fu">ggplot</span>() <span className="sc">+</span></span>
<span id="cb14-102"><a aria-hidden="true" href="#cb14-102" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb14-103"><a aria-hidden="true" href="#cb14-103" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb14-104"><a aria-hidden="true" href="#cb14-104" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"2007 GDP of</span><span className="sc">\n</span><span className="st">the TOP 4 countries</span><span className="sc">\n</span><span className="st">in each continent"</span>,</span>
<span id="cb14-105"><a aria-hidden="true" href="#cb14-105" tabindex="-1"></a>    <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="dv">0</span>, <span className="at">size =</span> <span className="dv">5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>,</span>
<span id="cb14-106"><a aria-hidden="true" href="#cb14-106" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"steelblue4"</span>) <span className="sc">+</span></span>
<span id="cb14-107"><a aria-hidden="true" href="#cb14-107" tabindex="-1"></a>  <span className="fu">theme_void</span>()</span>
<span id="cb14-108"><a aria-hidden="true" href="#cb14-108" tabindex="-1"></a></span><br/>
<span id="cb14-109"><a aria-hidden="true" href="#cb14-109" tabindex="-1"></a>p.title</span>
<span id="cb14-110"><a aria-hidden="true" href="#cb14-110" tabindex="-1"></a></span><br/>
<span id="cb14-111"><a aria-hidden="true" href="#cb14-111" tabindex="-1"></a></span><br/>
<span id="cb14-112"><a aria-hidden="true" href="#cb14-112" tabindex="-1"></a><span className="co"># Combine the doughnut plot and the title plot using the `cowplot` package. </span></span>
<span id="cb14-113"><a aria-hidden="true" href="#cb14-113" tabindex="-1"></a>artwork <span className="ot">&lt;-</span> <span className="fu">ggdraw</span>() <span className="sc">+</span></span>
<span id="cb14-114"><a aria-hidden="true" href="#cb14-114" tabindex="-1"></a>  <span className="fu">draw_plot</span>(p5) <span className="sc">+</span></span>
<span id="cb14-115"><a aria-hidden="true" href="#cb14-115" tabindex="-1"></a>  <span className="fu">draw_plot</span>(p.title, <span className="at">x =</span> .<span className="dv">3</span>, <span className="at">y =</span> <span className="sc">-</span>.<span className="dv">25</span>,</span>
<span id="cb14-116"><a aria-hidden="true" href="#cb14-116" tabindex="-1"></a>            <span className="at">width =</span> <span className="dv">1</span>)</span>
<span id="cb14-117"><a aria-hidden="true" href="#cb14-117" tabindex="-1"></a></span><br/>
<span id="cb14-118"><a aria-hidden="true" href="#cb14-118" tabindex="-1"></a>artwork</span>
<span id="cb14-119"><a aria-hidden="true" href="#cb14-119" tabindex="-1"></a></span><br/>
<span id="cb14-120"><a aria-hidden="true" href="#cb14-120" tabindex="-1"></a></span><br/>
<span id="cb14-121"><a aria-hidden="true" href="#cb14-121" tabindex="-1"></a><span className="co"># Save the plot. </span></span>
<span id="cb14-122"><a aria-hidden="true" href="#cb14-122" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"2007 GDP doughnut.pdf"</span>, </span>
<span id="cb14-123"><a aria-hidden="true" href="#cb14-123" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,</span>
<span id="cb14-124"><a aria-hidden="true" href="#cb14-124" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">8</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check these <Link to="../ggplot2-geofacet-US-states"><strong>exploded donut plots in a U.S. map layout</strong></Link> that visualizes the state-wise voting results of the 2016 Presidential Election between Hillary Clinton and Donald Trump .</p>
<p><Link to="../ggplot2-geofacet-US-states">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In the above continent-wise GDP visualization, reordering is a key edit that renders consistent position and color design across the faceted donut plots. The following <Link to="../ggplot2-area-plot-migration"><strong>stacked area / alluvial plot</strong></Link> leverages a similar technique to cluster ribbons (countries) of the same continent, and to use color gradients to distinguish countries and continents.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}