import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2DonutPlotAdmission from '../graphics/donut_plot_completed.png'; 
import imgGgplot2DonutPlotAdmissionWebp from '../graphics/donut_plot_completed.webp'; 
export default function Ggplot2DonutPlotAdmission(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use ggplot2 Exploded Donut Plot to Visualize Proportions</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2DonutPlotAdmissionWebp} />
    <img className="cover-img" src={imgGgplot2DonutPlotAdmission} />
  </picture>

<p>The exploded donut plots are generated by the code below. Check here for a <Link to="../../R/visualization/17-ggplot2-polar-coord-summary">complete guide</Link> to create pies and donut plots.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>UCBAdmissions <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1</span>, <span className="at">y =</span> n, <span className="at">fill =</span> Admit)) <span className="sc">+</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"fill"</span>, <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="fu">facet_grid</span>(Gender <span className="sc">~</span> Dept, <span className="at">switch =</span> <span className="st">"y"</span>) <span className="sc">+</span></span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="fl">1.5</span>)) <span className="sc">+</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"steelblue2"</span>, <span className="st">"firebrick2"</span>)) <span className="sc">+</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>) <span className="sc">+</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>    <span className="at">fill =</span> <span className="cn">NULL</span>, </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Student admissions to UC Berkeley six largest departments in 1973</span><span className="sc">\n</span><span className="st">"</span>) <span className="sc">+</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"bottom"</span>,</span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">13</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>    <span className="at">strip.text.y =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>),</span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"grey50"</span>, <span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">hjust =</span> .<span className="dv">5</span>)) </span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check these <Link to="../ggplot2-donut-GDP"><strong>exploded donut plots in faceted layout</strong></Link> that visualizes the GDP contribution proportion of the top 4 countries separately in each continent.</p>
<p><Link to="../ggplot2-donut-GDP">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check the following <strong><Link to="../ggplot2-geofacet-US-states">exploded donut plots in a U.S. map layout</Link></strong> that visualizes the state-wise voting results of the 2016 Presidential Election between Hillary Clinton and Donald Trump .</p>
<p><Link to="../ggplot2-geofacet-US-states">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}