import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2DumbellPlotVotesTrumpClinton from '../graphics/dumbbell_president_election_completed.png'; 
import imgGgplot2DumbellPlotVotesTrumpClintonWebp from '../graphics/dumbbell_president_election_completed.webp'; 
export default function Ggplot2DumbellPlotVotesTrumpClinton(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Dumbbell Plot with ggplot2 to Visualize Voting Demographics</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2DumbellPlotVotesTrumpClintonWebp} />
    <img className="cover-img" src={imgGgplot2DumbellPlotVotesTrumpClinton} />
  </picture>

<hr/>
<p>In this article, we’ll create a dumbbell plot showing the percentage of votes won by Donald Trump and Hillary Clinton from different population groups at the 2016 United States presidential election. The data used in this visualization can be downloaded <a className="downloadData" href="dataset/r-gallery/Trump_vs_Clinton.csv">here</a>. This work is a ggplot2 reproduction of the demo graphic by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link>.</p>
<p><strong>Major techniques explained in this article include: </strong></p>
<ul>
<li>Reorder axial variables</li>
<li>Make unique annotations to selected faceted panels (subplots)</li>
<li>Independent panel size adjustment</li>
<li>Aesthetic customization with <code>ifelse()</code></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/Trump_vs_Clinton.csv"</span>)</span></code></pre></div>
</div>
<p>Reorder the x-axis labels. This is achieved by turning the variable that is mapped to the <code>x</code> aesthetic into a <em>factor</em> with specified level order. In this visualization, we specify the factor levels in the reverse order of their appearance in the input dataset. More details in graphic elements rearrangement can be found in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>d.ordered <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span></span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">category =</span> <span className="fu">factor</span>(category, <span className="at">levels =</span> <span className="fu">rev</span>(d<span className="sc">$</span>category))) </span></code></pre></div>
</div>
<p>Tidy up the data, such that <code>candidate</code> and <code>percentage</code> each is a single variable (column). In addition, we create a new column <code>is.max</code> to indicate which candidate has the most votes in each voting group. This variable will help specify the y-axis position of the label of the voting percentage later in <a href="#step7">step 7</a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.ordered <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="co"># tidy up</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="fu">contains</span>(<span className="st">"Vote"</span>), </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"candidate"</span>, <span className="at">values_to =</span> <span className="st">"percent"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">candidate =</span> <span className="fu">str_remove</span>(candidate, <span className="st">"Vote.for."</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="co"># mark the candidate with the most votes in each voting group</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  <span className="fu">group_by</span>(category) <span className="sc">%&gt;%</span> </span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">is.max =</span> percent <span className="sc">==</span> <span className="fu">max</span>(percent))</span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a></span><br/>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/># Groups:   category [2]
<br/>  category group candidate percent is.max
<br/>  &lt;fct&gt;    &lt;chr&gt; &lt;chr&gt;       &lt;int&gt; &lt;lgl&gt; 
<br/>1 18-29    Age   Clinton        58 TRUE  
<br/>2 18-29    Age   Trump          28 FALSE 
<br/>3 30-49    Age   Clinton        51 TRUE  </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>1.</strong> Create line segments and points. Note that the aesthetic mapping of of the <code>candidate</code> variable (<code>aes(color = candidate)</code>) is specified in its own associated layer <code>geom_point</code> (and <code>geom_text</code> in <a href="#step7">step 7</a>), but <em>not</em> in the global aesthetic mapping in the <code>ggplot()</code> line. This is convenient so that when another <code>geom_*</code> has a new input dataset for the <code>data =</code> argument, this new dataset does not have to possess the <code>candidate</code> variable. (An alternative approach is to map <code>candidate</code> in the <code>ggplot()</code> line, and set <code>inherit.aes = F</code> in the new <code>geom_*</code> layer when its associated dataset does not have the <code>candidate</code> variable - consult our <strong><Link to="/R/ebook/flash-to-r-ggplot2-mastery">excellent ggplot2 e-book</Link></strong> to learn more!)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> category, <span className="at">y =</span> percent)) <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="co"># using the dataset before tidy up to create the segments</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="at">data =</span> d.ordered,</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>               <span className="fu">aes</span>(<span className="at">x =</span> category, <span className="at">xend =</span> category,</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>                   <span className="at">y =</span> Vote.for.Clinton, <span className="at">yend =</span> Vote.for.Trump), </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">linewidth =</span> <span className="dv">2</span>) <span className="sc">+</span></span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  </span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>  <span className="co"># points created using the "global" d.tidy dataset</span></span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="fu">aes</span>(<span className="at">color =</span> candidate), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"Clinton"</span> <span className="ot">=</span> <span className="st">"steelblue"</span>, </span>
<span id="cb5-13"><a aria-hidden="true" href="#cb5-13" tabindex="-1"></a>                                <span className="st">"Trump"</span> <span className="ot">=</span> <span className="st">"firebrick3"</span>))</span>
<span id="cb5-14"><a aria-hidden="true" href="#cb5-14" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="step2"><strong>2.</strong> Flip and facet the plot. Here we use <code>clip = "off"</code> to prevent graphic elements beyond the panel boundary from being clipped away (see <a href="#step3">step 3</a>). <code>facet_grid(variable ~ .)</code> is a more flexible alternative to <code>facet_wrap(~ variable)</code>. It has the unique argument <code>space</code> which allows <em>different panel size</em> when the number of variables are different across panels (e.g., the “Gender” panel has two groups, and is thinner in size than the other panels with four groups).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span> <span className="co"># flip the plot</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="co"># facet the plot based on voting groups</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">facet_grid</span>(group <span className="sc">~</span> ., <span className="at">scales =</span> <span className="st">"free"</span>, <span className="at">space =</span> <span className="st">"free_y"</span>)  </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_flipped.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_flipped"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="step3"><strong>3.</strong> Add panel titles manually at the top left corner, in replace of the default ones. To add text annotations unique to each faceted panel (subplot), the key is to include in the input dataset the same variable <code>group</code> which has been used to facet the plot. This variable dictates which faceted panel the texts should be plotted in, and is also mapped to the <code>label</code> aesthetic. The same technique has also been used <Link to="../ggplot2-arrows-facet-women-parliament">here</Link>, and <Link to="../ggpplot2-bar-mortality">here</Link> (with <em>reordered</em> faceting variable).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># use the same `group` variable which has been used for faceting</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">group =</span> <span className="fu">c</span>(<span className="st">"Age"</span>, <span className="st">"Education"</span>, <span className="st">"Gender"</span>, <span className="st">"Race/Ethnicity"</span>)),</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="fl">5.2</span>, <span className="fl">5.2</span>, <span className="fl">3.2</span>, <span className="fl">5.2</span>), </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>                <span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>                <span className="at">label =</span> group), </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fl">3.5</span>) </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_manual_panel_titles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_manual_panel_titles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Note that the panel titles are riding across the border of the faceted panels, and are displayed completely. If we hadn’t set up the <code>clip = "off"</code> in <a href="#step2">step 2</a>, the part of texts beyond the panel boundary would be otherwise invisible (clipped away).</p>
<p><strong>4.</strong> Manually add the x (left-side) axis labels in line with the panel titles added at the earlier step. As the data for this layer of <code>geom_text</code> inherits from <code>d.tidy</code>, which has two rows (two candidates) for each voting group, each axis label displayed is actually a perfect overlap of two labels.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">label =</span> category),</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="at">color =</span> <span className="st">"grey30"</span>)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_manual_axis_labels.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_manual_axis_labels"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>5.</strong> Remove the default panel titles (<code>strip_text</code>), and the x-axis labels and ticks.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">theme_minimal</span>() <span className="sc">+</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>        <span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>        <span className="at">strip.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>        </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>        <span className="co"># adjust distance between subplots</span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>        <span className="at">panel.spacing.y =</span> <span className="fu">unit</span>(<span className="dv">15</span>, <span className="st">"pt"</span>)) </span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_cleanup.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_cleanup"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>6.</strong> Adjust the vertical panel grid (associated with the y-axis scale).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">scale_y_continuous</span>(</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">20</span>),</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="at">minor_breaks =</span> <span className="cn">NULL</span>) <span className="sc">+</span> <span className="co"># remove the minor vertical grids </span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">2</span>))</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_grid.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_grid"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="step7"><strong>7.</strong></span> Add text labels of the voting percentage. We use <code>is.max</code> variable to determine the y-axis position of the text label.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">paste</span>(percent, <span className="st">"%"</span>), </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>        <span className="at">y =</span> <span className="fu">ifelse</span>(is.max <span className="sc">==</span> T, percent <span className="sc">+</span> <span className="dv">8</span>, percent <span className="sc">-</span> <span className="dv">8</span>),</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>        <span className="at">color =</span> candidate),</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>    <span className="at">show.legend =</span> F) </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>p7</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_voting_percent.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_voting_percent"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>8.</strong> Add text annotations in the top panel in replace of the default legend. Similar to <a href="#step3">step 3</a>, we create a dataset with the faceting variable <code>group</code> to uniquely annotate the desired subplot. Also add the plot title.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span></span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="co"># add text annotation in the top first panel</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>    <span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">percent =</span> <span className="fu">c</span>(<span className="dv">12</span>, <span className="dv">80</span>), </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>                  <span className="at">category =</span> <span className="st">"18-29"</span>,</span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>                  <span className="at">text =</span> <span className="fu">c</span>(<span className="st">"vote for Trump"</span>, <span className="st">"vote for Clinton"</span>),</span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a>                  <span className="at">group =</span> <span className="st">"Age"</span>), <span className="co"># texts added only to the 1st panel "Age"</span></span>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> category, <span className="at">y =</span> percent, <span className="at">label =</span> text),</span>
<span id="cb12-9"><a aria-hidden="true" href="#cb12-9" tabindex="-1"></a>    </span>
<span id="cb12-10"><a aria-hidden="true" href="#cb12-10" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"firebrick3"</span>, <span className="st">"steelblue"</span>), </span>
<span id="cb12-11"><a aria-hidden="true" href="#cb12-11" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>,</span>
<span id="cb12-12"><a aria-hidden="true" href="#cb12-12" tabindex="-1"></a>    <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">2</span>) <span className="sc">+</span> <span className="co"># text shifted upward</span></span>
<span id="cb12-13"><a aria-hidden="true" href="#cb12-13" tabindex="-1"></a>  </span>
<span id="cb12-14"><a aria-hidden="true" href="#cb12-14" tabindex="-1"></a>  <span className="co"># remove default legend</span></span>
<span id="cb12-15"><a aria-hidden="true" href="#cb12-15" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span> </span>
<span id="cb12-16"><a aria-hidden="true" href="#cb12-16" tabindex="-1"></a>  </span>
<span id="cb12-17"><a aria-hidden="true" href="#cb12-17" tabindex="-1"></a>  <span className="co"># adjust the plot title</span></span>
<span id="cb12-18"><a aria-hidden="true" href="#cb12-18" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Voter Demographics in 2016</span><span className="sc">\n</span><span className="st">"</span>)  <span className="sc">+</span></span>
<span id="cb12-19"><a aria-hidden="true" href="#cb12-19" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">size =</span> <span className="dv">15</span>))</span>
<span id="cb12-20"><a aria-hidden="true" href="#cb12-20" tabindex="-1"></a></span><br/>
<span id="cb12-21"><a aria-hidden="true" href="#cb12-21" tabindex="-1"></a>p8</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/dumbbell_president_election_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>As illustrated above, labeling the plot with “vote for Trump” and “vote for Clinton” is an effective and concise replacement to the traditional legends. Another powerful approach is to incorporate such notations in distinct colors in the plot title, as shown in this <Link to="../ggplot2-segment-US-president-terms">line segment - rectangle plot</Link>, this <Link to="../ggplot2-lollipop-donut-horsepower">donut-lollipop plot</Link>, and this <Link to="../ggplot2-bar-CO2-air-travel">clustered bar plot</Link>.</p>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a></span><br/>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/Trump_vs_Clinton.csv"</span>)</span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a></span><br/>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a><span className="co"># Reorder axial labels. </span></span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>d.ordered <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span></span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">category =</span> <span className="fu">factor</span>(category, <span className="at">levels =</span> <span className="fu">rev</span>(d<span className="sc">$</span>category))) </span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a></span><br/>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a><span className="co"># Tidy up the data.</span></span>
<span id="cb13-13"><a aria-hidden="true" href="#cb13-13" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.ordered <span className="sc">%&gt;%</span> </span>
<span id="cb13-14"><a aria-hidden="true" href="#cb13-14" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="fu">contains</span>(<span className="st">"Vote"</span>), </span>
<span id="cb13-15"><a aria-hidden="true" href="#cb13-15" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"candidate"</span>, <span className="at">values_to =</span> <span className="st">"percent"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb13-16"><a aria-hidden="true" href="#cb13-16" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">candidate =</span> <span className="fu">str_remove</span>(candidate, <span className="st">"Vote.for."</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb13-17"><a aria-hidden="true" href="#cb13-17" tabindex="-1"></a>  </span>
<span id="cb13-18"><a aria-hidden="true" href="#cb13-18" tabindex="-1"></a>  <span className="co"># mark the candidate with the most votes in each voting group</span></span>
<span id="cb13-19"><a aria-hidden="true" href="#cb13-19" tabindex="-1"></a>  <span className="fu">group_by</span>(category) <span className="sc">%&gt;%</span> </span>
<span id="cb13-20"><a aria-hidden="true" href="#cb13-20" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">is.max =</span> percent <span className="sc">==</span> <span className="fu">max</span>(percent))</span>
<span id="cb13-21"><a aria-hidden="true" href="#cb13-21" tabindex="-1"></a></span><br/>
<span id="cb13-22"><a aria-hidden="true" href="#cb13-22" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb13-23"><a aria-hidden="true" href="#cb13-23" tabindex="-1"></a></span><br/>
<span id="cb13-24"><a aria-hidden="true" href="#cb13-24" tabindex="-1"></a></span><br/>
<span id="cb13-25"><a aria-hidden="true" href="#cb13-25" tabindex="-1"></a><span className="co"># Create a dumbbell plot containing line segments and points. </span></span>
<span id="cb13-26"><a aria-hidden="true" href="#cb13-26" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb13-27"><a aria-hidden="true" href="#cb13-27" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> category, <span className="at">y =</span> percent)) <span className="sc">+</span></span>
<span id="cb13-28"><a aria-hidden="true" href="#cb13-28" tabindex="-1"></a>  </span>
<span id="cb13-29"><a aria-hidden="true" href="#cb13-29" tabindex="-1"></a>  <span className="co"># using the dataset before tidy up to create the segments</span></span>
<span id="cb13-30"><a aria-hidden="true" href="#cb13-30" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="at">data =</span> d.ordered,</span>
<span id="cb13-31"><a aria-hidden="true" href="#cb13-31" tabindex="-1"></a>               <span className="fu">aes</span>(<span className="at">x =</span> category, <span className="at">xend =</span> category,</span>
<span id="cb13-32"><a aria-hidden="true" href="#cb13-32" tabindex="-1"></a>                   <span className="at">y =</span> Vote.for.Clinton, <span className="at">yend =</span> Vote.for.Trump), </span>
<span id="cb13-33"><a aria-hidden="true" href="#cb13-33" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">linewidth =</span> <span className="dv">2</span>) <span className="sc">+</span></span>
<span id="cb13-34"><a aria-hidden="true" href="#cb13-34" tabindex="-1"></a>  </span>
<span id="cb13-35"><a aria-hidden="true" href="#cb13-35" tabindex="-1"></a>  <span className="co"># points created using the "global" d.tidy dataset</span></span>
<span id="cb13-36"><a aria-hidden="true" href="#cb13-36" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="fu">aes</span>(<span className="at">color =</span> candidate), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb13-37"><a aria-hidden="true" href="#cb13-37" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"Clinton"</span> <span className="ot">=</span> <span className="st">"steelblue"</span>, </span>
<span id="cb13-38"><a aria-hidden="true" href="#cb13-38" tabindex="-1"></a>                                <span className="st">"Trump"</span> <span className="ot">=</span> <span className="st">"firebrick3"</span>))</span>
<span id="cb13-39"><a aria-hidden="true" href="#cb13-39" tabindex="-1"></a>p1</span>
<span id="cb13-40"><a aria-hidden="true" href="#cb13-40" tabindex="-1"></a></span><br/>
<span id="cb13-41"><a aria-hidden="true" href="#cb13-41" tabindex="-1"></a></span><br/>
<span id="cb13-42"><a aria-hidden="true" href="#cb13-42" tabindex="-1"></a><span className="co"># Flip and facet the plot. </span></span>
<span id="cb13-43"><a aria-hidden="true" href="#cb13-43" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb13-44"><a aria-hidden="true" href="#cb13-44" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span> <span className="co"># flip the plot</span></span>
<span id="cb13-45"><a aria-hidden="true" href="#cb13-45" tabindex="-1"></a>  <span className="co"># facet the plot based on voting groups</span></span>
<span id="cb13-46"><a aria-hidden="true" href="#cb13-46" tabindex="-1"></a>  <span className="fu">facet_grid</span>(group <span className="sc">~</span> ., <span className="at">scales =</span> <span className="st">"free"</span>, <span className="at">space =</span> <span className="st">"free_y"</span>)  </span>
<span id="cb13-47"><a aria-hidden="true" href="#cb13-47" tabindex="-1"></a>p2</span>
<span id="cb13-48"><a aria-hidden="true" href="#cb13-48" tabindex="-1"></a></span><br/>
<span id="cb13-49"><a aria-hidden="true" href="#cb13-49" tabindex="-1"></a></span><br/>
<span id="cb13-50"><a aria-hidden="true" href="#cb13-50" tabindex="-1"></a><span className="co"># Add panel titles manually at the top left corner, in replace of the default ones.</span></span>
<span id="cb13-51"><a aria-hidden="true" href="#cb13-51" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb13-52"><a aria-hidden="true" href="#cb13-52" tabindex="-1"></a>  <span className="co"># use the same `group` variable name used for faceting</span></span>
<span id="cb13-53"><a aria-hidden="true" href="#cb13-53" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">group =</span> <span className="fu">c</span>(<span className="st">"Age"</span>, <span className="st">"Education"</span>, <span className="st">"Gender"</span>, <span className="st">"Race/Ethnicity"</span>)),</span>
<span id="cb13-54"><a aria-hidden="true" href="#cb13-54" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="fl">5.2</span>, <span className="fl">5.2</span>, <span className="fl">3.2</span>, <span className="fl">5.2</span>), </span>
<span id="cb13-55"><a aria-hidden="true" href="#cb13-55" tabindex="-1"></a>                <span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">label =</span> group), </span>
<span id="cb13-56"><a aria-hidden="true" href="#cb13-56" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fl">3.5</span>) </span>
<span id="cb13-57"><a aria-hidden="true" href="#cb13-57" tabindex="-1"></a>p3</span>
<span id="cb13-58"><a aria-hidden="true" href="#cb13-58" tabindex="-1"></a></span><br/>
<span id="cb13-59"><a aria-hidden="true" href="#cb13-59" tabindex="-1"></a></span><br/>
<span id="cb13-60"><a aria-hidden="true" href="#cb13-60" tabindex="-1"></a><span className="co"># Manually add the x (left-side) axis labels, in line with the newly added panel titles. </span></span>
<span id="cb13-61"><a aria-hidden="true" href="#cb13-61" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb13-62"><a aria-hidden="true" href="#cb13-62" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">label =</span> category),</span>
<span id="cb13-63"><a aria-hidden="true" href="#cb13-63" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="at">color =</span> <span className="st">"grey30"</span>)</span>
<span id="cb13-64"><a aria-hidden="true" href="#cb13-64" tabindex="-1"></a>p4</span>
<span id="cb13-65"><a aria-hidden="true" href="#cb13-65" tabindex="-1"></a></span><br/>
<span id="cb13-66"><a aria-hidden="true" href="#cb13-66" tabindex="-1"></a></span><br/>
<span id="cb13-67"><a aria-hidden="true" href="#cb13-67" tabindex="-1"></a><span className="co"># Remove the default panel titles, the x-axis labels, and ticks.</span></span>
<span id="cb13-68"><a aria-hidden="true" href="#cb13-68" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb13-69"><a aria-hidden="true" href="#cb13-69" tabindex="-1"></a>  <span className="fu">theme_minimal</span>() <span className="sc">+</span></span>
<span id="cb13-70"><a aria-hidden="true" href="#cb13-70" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-71"><a aria-hidden="true" href="#cb13-71" tabindex="-1"></a>        <span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-72"><a aria-hidden="true" href="#cb13-72" tabindex="-1"></a>        <span className="at">strip.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-73"><a aria-hidden="true" href="#cb13-73" tabindex="-1"></a>        </span>
<span id="cb13-74"><a aria-hidden="true" href="#cb13-74" tabindex="-1"></a>        <span className="co"># adjust distance between subplots</span></span>
<span id="cb13-75"><a aria-hidden="true" href="#cb13-75" tabindex="-1"></a>        <span className="at">panel.spacing.y =</span> <span className="fu">unit</span>(<span className="dv">15</span>, <span className="st">"pt"</span>)) </span>
<span id="cb13-76"><a aria-hidden="true" href="#cb13-76" tabindex="-1"></a>p5</span>
<span id="cb13-77"><a aria-hidden="true" href="#cb13-77" tabindex="-1"></a></span><br/>
<span id="cb13-78"><a aria-hidden="true" href="#cb13-78" tabindex="-1"></a></span><br/>
<span id="cb13-79"><a aria-hidden="true" href="#cb13-79" tabindex="-1"></a><span className="co"># Adjust the vertical panel grid (associated with the y-axis scale).</span></span>
<span id="cb13-80"><a aria-hidden="true" href="#cb13-80" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">scale_y_continuous</span>(</span>
<span id="cb13-81"><a aria-hidden="true" href="#cb13-81" tabindex="-1"></a>  <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">20</span>),</span>
<span id="cb13-82"><a aria-hidden="true" href="#cb13-82" tabindex="-1"></a>  <span className="at">minor_breaks =</span> <span className="cn">NULL</span>) <span className="sc">+</span> <span className="co"># remove the minor vertical grids </span></span>
<span id="cb13-83"><a aria-hidden="true" href="#cb13-83" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">2</span>))</span>
<span id="cb13-84"><a aria-hidden="true" href="#cb13-84" tabindex="-1"></a>p6</span>
<span id="cb13-85"><a aria-hidden="true" href="#cb13-85" tabindex="-1"></a></span><br/>
<span id="cb13-86"><a aria-hidden="true" href="#cb13-86" tabindex="-1"></a></span><br/>
<span id="cb13-87"><a aria-hidden="true" href="#cb13-87" tabindex="-1"></a><span className="co"># Add the voting percentage. </span></span>
<span id="cb13-88"><a aria-hidden="true" href="#cb13-88" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span> </span>
<span id="cb13-89"><a aria-hidden="true" href="#cb13-89" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb13-90"><a aria-hidden="true" href="#cb13-90" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">paste</span>(percent, <span className="st">"%"</span>), </span>
<span id="cb13-91"><a aria-hidden="true" href="#cb13-91" tabindex="-1"></a>        <span className="at">y =</span> <span className="fu">ifelse</span>(is.max <span className="sc">==</span> T, percent <span className="sc">+</span> <span className="dv">8</span>, percent <span className="sc">-</span> <span className="dv">8</span>),</span>
<span id="cb13-92"><a aria-hidden="true" href="#cb13-92" tabindex="-1"></a>        <span className="at">color =</span> candidate),</span>
<span id="cb13-93"><a aria-hidden="true" href="#cb13-93" tabindex="-1"></a>    <span className="at">show.legend =</span> F) </span>
<span id="cb13-94"><a aria-hidden="true" href="#cb13-94" tabindex="-1"></a>p7</span>
<span id="cb13-95"><a aria-hidden="true" href="#cb13-95" tabindex="-1"></a></span><br/>
<span id="cb13-96"><a aria-hidden="true" href="#cb13-96" tabindex="-1"></a></span><br/>
<span id="cb13-97"><a aria-hidden="true" href="#cb13-97" tabindex="-1"></a><span className="co"># Add text annotations in the top panel in replace of the default legend. </span></span>
<span id="cb13-98"><a aria-hidden="true" href="#cb13-98" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span></span>
<span id="cb13-99"><a aria-hidden="true" href="#cb13-99" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb13-100"><a aria-hidden="true" href="#cb13-100" tabindex="-1"></a>    <span className="co"># add text annotation in the top first panel</span></span>
<span id="cb13-101"><a aria-hidden="true" href="#cb13-101" tabindex="-1"></a>    <span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">percent =</span> <span className="fu">c</span>(<span className="dv">12</span>, <span className="dv">80</span>), </span>
<span id="cb13-102"><a aria-hidden="true" href="#cb13-102" tabindex="-1"></a>                  <span className="at">category =</span> <span className="st">"18-29"</span>,</span>
<span id="cb13-103"><a aria-hidden="true" href="#cb13-103" tabindex="-1"></a>                  <span className="at">text =</span> <span className="fu">c</span>(<span className="st">"vote for Trump"</span>, <span className="st">"vote for Clinton"</span>),</span>
<span id="cb13-104"><a aria-hidden="true" href="#cb13-104" tabindex="-1"></a>                  <span className="at">group =</span> <span className="st">"Age"</span>), <span className="co"># texts added only to the 1st panel "Age"</span></span>
<span id="cb13-105"><a aria-hidden="true" href="#cb13-105" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> category, <span className="at">y =</span> percent, <span className="at">label =</span> text),</span>
<span id="cb13-106"><a aria-hidden="true" href="#cb13-106" tabindex="-1"></a>    </span>
<span id="cb13-107"><a aria-hidden="true" href="#cb13-107" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"firebrick3"</span>, <span className="st">"steelblue"</span>), </span>
<span id="cb13-108"><a aria-hidden="true" href="#cb13-108" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>,</span>
<span id="cb13-109"><a aria-hidden="true" href="#cb13-109" tabindex="-1"></a>    <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">2</span>) <span className="sc">+</span> <span className="co"># text shifted upward</span></span>
<span id="cb13-110"><a aria-hidden="true" href="#cb13-110" tabindex="-1"></a>  </span>
<span id="cb13-111"><a aria-hidden="true" href="#cb13-111" tabindex="-1"></a>  <span className="co"># remove default legend</span></span>
<span id="cb13-112"><a aria-hidden="true" href="#cb13-112" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span> </span>
<span id="cb13-113"><a aria-hidden="true" href="#cb13-113" tabindex="-1"></a>  </span>
<span id="cb13-114"><a aria-hidden="true" href="#cb13-114" tabindex="-1"></a>  <span className="co"># adjust the plot title</span></span>
<span id="cb13-115"><a aria-hidden="true" href="#cb13-115" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Voter Demographics in 2016</span><span className="sc">\n</span><span className="st">"</span>)  <span className="sc">+</span></span>
<span id="cb13-116"><a aria-hidden="true" href="#cb13-116" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">size =</span> <span className="dv">15</span>))</span>
<span id="cb13-117"><a aria-hidden="true" href="#cb13-117" tabindex="-1"></a></span><br/>
<span id="cb13-118"><a aria-hidden="true" href="#cb13-118" tabindex="-1"></a>p8</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>The dumbbell plot based on <code>geom_segment</code> can be easily updated into arrows to display information about trend of changes. The following article leverages <strong><Link to="../ggplot2-arrows-facet-women-parliament">annotated arrows in faceted panels</Link></strong> to depict the changes in the proportion of seats held by women in national parliaments from 2000 to 2020.</p>
<p><Link to="../ggplot2-arrows-facet-women-parliament">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to the classic dumbbell style illustrated above, the following visualization presents an <Link to="../ggplot2-line-plot-life-expectancy"><strong>“upgraded” version of a dumbbell plot</strong></Link>: it effectively highlights the increase of life expectancy from 1952 to 2007, showing such changes at both the country-wise (each single line) and the continent-wise ( central thick line) level.</p>
<p><Link to="../ggplot2-line-plot-life-expectancy">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}