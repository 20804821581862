import logoFlashtoGgplot2 from './media/logo_flash_to_R_ggplot2_Mastery_2024.png'
import logoWhatulearn from './media/book_sale_page_design/what_u_learn.png'
import logoWhybook from './media/book_sale_page_design/why_this_book.png'
import ggplot2Book from './media/ggplot2 book cover_compressed.png'
import { Helmet } from 'react-helmet'


import barChart from './media/book_sale_page_design/bar-chart-svgrepo-com.svg'
import Umbrella from './media/book_sale_page_design/beach-umbrella-2-svgrepo-com.svg'
import brush from './media/book_sale_page_design/brush-1-svgrepo-com.svg'
import bulb from './media/book_sale_page_design/bulb-lighting-svgrepo-com.svg'
import ebookInformation from './media/book_sale_page_design/ebook_information.png'
import firework from './media/book_sale_page_design/fireworks-svgrepo-com.svg'
import jigsaw from './media/book_sale_page_design/jigsaw-piece-svgrepo-com.svg'
import key from './media/book_sale_page_design/key-svgrepo-com.svg'
import lighthouse from './media/book_sale_page_design/lighthouse-svgrepo-com.svg'
import palette from './media/book_sale_page_design/palette-2-svgrepo-com.svg'
import rocket from './media/book_sale_page_design/rocket-svgrepo-com.svg'
import shield from './media/book_sale_page_design/shield-star-svgrepo-com.svg'
import tool from './media/book_sale_page_design/tool-cross-svgrepo-com.svg'
import author from './media/book_sale_page_design/author_headshot.png'


export default function BookPage({name}){
    return (
        <div>
            <Helmet>
                <title>Flash to R ggplot2 Mastery 2024 eBook</title>
                <meta
                    name="description"
                    content="This carefully crafted e-book brings you onto an amazing fast track to master ggplot2 to create stunning data visualizations in R."
                    />
            </Helmet>

        <div className="container" id="eBook">  
            <div className="mg-t-30 mg-t-md-50">
                <div className="flexCenter">
                        <img className='w-md-100 w-xl-70 w-l-80' src={logoFlashtoGgplot2} alt="eBook-Flash-to-ggplot2-Mastery-title"></img>
                </div>
                <div className="flexCenter text-center">
                    <p style={{width:"60%", fontSize:'20px', letterSpacing:'1.1px', fontWeight:'600', color:'#7e7e7e'}}>
                        This carefully crafted eBook brings you onto an <span>amazing fast track </span>to master ggplot2 to <span>create stunning data visualizations in R</span>.
                    </p>
                </div>      
                {/* <div className='flexSpaceBetweenCenter mg-t-50' style={{width:"80%", marginLeft: 'auto', marginRight: 'auto'}}>
                    <div className='mg-l-100' style={{width:"50%"}}>
                        <img src={ggplot2Book} ></img>
                    </div>
                    <div className='mg-l-50' style={{width:"40%"}}>
                        <div className="button-gray text-center border-radius-30" style={{width:"50%"}}>
                            <a href='https://3codeacademy.s3.amazonaws.com/ebook/Flash-to-R-ggplot2-Mastery-SAMPLE.pdf' className="fs-20 fc-white fw-800">Read sample</a>
                        </div>            
                        <div className="button-orange text-center mg-t-50 border-radius-30" style={{width:"50%"}}>
                            <a to="https://3codeacademy.gumroad.com/l/fskzt" className="fs-20 fc-white fw-800">Order now !</a>
                        </div>
                        <div className='mg-t-50'>
                            <span className='discount'>-30%</span><span className='price mg-l-20'>$34.19</span><span className='original-price mg-l-20'>$49</span>
                        </div>
                    </div>
                </div> */}
                <div className="row mg-t-50">
                    <div className='col-md-6'>
                        <div className='mg-large-left-100 w-ll-70 margin-lg-auto'>
                            <a href="https://databrewer.s3.amazonaws.com/ebook/Flash-to-R-ggplot2-Mastery-SAMPLE-042724.pdf" target="_blank" rel="noopener">
                             <img src={ggplot2Book} alt="eBook-Flash-to-ggplot2-Mastery-sample-read"></img>
                            </a>
        
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mg-large-left-50  margin-top-xlarge-30 ' >
                            <a href="https://databrewer.s3.amazonaws.com/ebook/Flash-to-R-ggplot2-Mastery-SAMPLE-042724.pdf" className="fs-20 fc-white fw-800" target="_blank" rel="noopener">
                                <div className="button-gray text-center border-radius-30 w-50 margin-lg-auto">
                                    Read sample
                                </div>  
                            </a>      
                            
                            <a href="https://databrewer.gumroad.com/l/fskzt" className="fs-20 fc-white fw-800" target="_blank" rel="noopener">
                                <div className="button-orange text-center mg-t-50 border-radius-30 w-50 margin-lg-auto mg-lg-t-20">
                                Order now !
                                </div>
                            </a>     
                            <div className='mg-t-50 price-container'>
                                    <span className='discount'>-30%</span>                  
                                    <span className='price mg-l-10'>$34.3</span>
                                    <span className='original-price mg-l-10'>$49</span>
                                   
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>  
            <div className='text-center mg-t-20'>
                    <img src={ebookInformation} className='w-100 w-l-70 w-xl-60' alt="ebook-information"></img>
                </div>        
        </div>
        
    <div style={{backgroundColor:'rgb(247 247 247)'}}>
        <div className="row mg-t-50" style={{paddingLeft:'50px', paddingRight:'50px'}}>
                    <div className='col-md-3 w-70 w-ms-50 w-l-40 w-sl-20 w-xl-20 margin-lg-auto float-lg-none sticky-img'>
                        <img src={logoWhybook} alt="why-this-ebook"></img>
                    </div>
                    <div className='col-md-9 margin-lg-auto float-lg-none'>
                        <div className="blocks mg-t-10">
                            <div className='block'>   
                                <img src={bulb} className='icon mg-l-10' alt="bulb"></img>
                                                 
                                    <div className='card-image card-block-red flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-22 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>reading is<br />AWESOME</p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">Compared with watching tutorial videos, reading is faster, and encourages active thinking and reflection. It allows you to learn completely at your own pace, and knowledge review is much easier.</p>
                                    </div>                       
                                
                            </div>
                            
                            <div className='block mg-t-10'>   
                                <img src={Umbrella} className='icon mg-l-10'></img>
                                           
                                    <div className='card-image card-block-red flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>comic<br />book style</p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">Each line of script is progressively unfolded and graphically illustrated, making learning a delightful and intuitive experience, akin to flipping through a comic book.</p>
                                    </div>                       
                                 
                            </div>

                            <div className='block mg-t-10'>   
                                <img src={lighthouse} className='icon mg-l-10'></img>
                                                
                                    <div className='card-image card-block-red flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-22 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>complete<br />tutorial guide</p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">This ebook covers a broad spectrum of topics from very basic to advanced techniques. It’s the only book you need to be awesome at ggplot2.</p>
                                    </div>                       
                                  
                            </div>
                            <div className='block mg-t-10'>   
                                <img src={palette} className='icon mg-l-10'></img>
                                             
                                    <div className='card-image card-block-red flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-22 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>syntax<br />color highlight</p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">The script syntax and parentheses are color-highlighted, providing much enhanced readability and a pleasant learning experience.</p>
                                    </div>                       
                                
                            </div>
                            <div className='block mg-t-10'>   
                                <img src={rocket} className='icon mg-l-10'></img>           
                                    <div className='card-image card-block-red flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-22 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>practical <br />techniques </p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">No bluffing theory. All things you learn are highly practical and down-to-earth to address real-world data visualization task...</p>
                                    </div>                       
                                   
                            </div>
                            <div className='block mg-t-10'>   
                                <img src={shield} className='icon mg-l-10'></img>       
                                    <div className='card-image card-block-red flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-20 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>created by trusted author</p>     
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">The book is carefully designed and crafted by a leading Harvard scientist with proven excellence in data analysis and visualization.</p>
                                    </div>                       
                              
                            </div>
                        </div>
                    </div>
                </div>
               
    </div>
       

        <div className="row mg-t-50" style={{paddingLeft:'50px', paddingRight:'50px'}}>
                    <div className='col-md-3 w-70 w-ms-50 w-l-40 w-sl-20 w-xl-20 margin-lg-auto float-lg-none sticky-img'>
                        <img src={logoWhatulearn}></img>
                    </div>
                    <div className='col-md-9 margin-lg-auto float-lg-none'>
                        <div className="blocks">
                            <div className='block mg-t-10 bg-grey'>   
                                <img src={key} className='icon mg-l-10 mg-t-10 rotate-80'></img>          
                                    <div className='card-image card-block-deep-blue flexCenter card-block-size '>
                                        <div className=''>
                                            <p className='fc-white fs-22 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>grammar fundamentals</p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">You’ll master the cornerstone grammar fundamentals: 1) tidy data, 2) plot layer by layer, and 3) aesthetic & data inheritance. A good understanding of these critical concepts prepares you to generate intricate graphics down the road.</p>
                                    </div>                       
                                 
                            </div>
                          
                            <div className='block mg-t-10 bg-grey'>   
                                <img src={barChart} className='icon mg-l-10 w-xl-15'></img>        
                                    <div className='card-image card-block-deep-blue flexCenter card-block-size p-t-15'>                                    
                                        <div className=''>
                                            <p className='fc-white fs-18 fs-md-20 fs-xl-22 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>40+ graphic<br />elements & types</p>
                                            
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">You’ll learn how to create a wide range of graphical components, such as points, lines, bars, error bars, boxplots, histograms, and density plots, heatmaps, ribbons, arrows, texts, annotations, pies and donuts charts, and more. </p>
                                    </div>                       
                                  
                            </div>               

                            <div className='block mg-t-10 bg-grey'>   
                                <img src={jigsaw} className='icon w-xl-12 mg-l-10'></img>            
                                    <div className='card-image card-block-deep-blue flexCenter card-block-size'> 
                                        <div className=''>
                                            <p className='fc-white fs-18 fs-md-20 fs-xl-22 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>construct<br />graphics<br />like LEGO</p>
                                           
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">Similar to the way you build a LEGO castle block by block, you’ll know learn how to create stunning visuals by seamlessly integrating different graphic components, meanwhile harnessing the rules of grammar such as aesthetic inheritance.  </p>
                                    </div>                       
                                
                            </div>
                             <div className='block mg-t-10 bg-grey'>   
                                <img src={brush} className='icon mg-l-10'></img>     
                                    <div className='card-image card-block-deep-blue flexCenter card-block-size p-t-15'> 
                                        <div className=''>
                                            <p className='fc-white fs-20 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>customize<br />everything</p>
                                           
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">You’ll possess the skill to customize “everything” in a plot, including axes, legends, breaks, scales, labels, titles, panels, grids, color, and size, etc., including the unique feature of ggplot2 of creating faceted subplots.</p>
                                    </div>                       
                                
                            </div>
                            <div className='block mg-t-10 bg-grey'>   
                                <img src={firework} className='icon mg-l-10'></img>            
                                    <div className='card-image card-block-deep-blue flexCenter card-block-size p-t-15'> 
                                        <div className=''>
                                            <p className='fc-white fs-20 fs-md-20 fs-xl-22 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>10+ extension packages</p>
                                           
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">You’ll learn popular ggplot2 extension packages to create specialized visuals in a simple line of code, such as correlation matrix heatmaps, stacked density plots, marginal distribution visualizations, customized texts, stunning color scales, and more. </p>
                                    </div>                       
                             
                            </div>
                            <div className='block mg-t-10 bg-grey'>   
                                <img src={tool} className='icon mg-l-10'></img>          
                                    <div className='card-image card-block-deep-blue flexCenter card-block-size p-t-15'> 
                                        <div className=''>
                                            <p className='fc-white fs-22 fs-xs-24 fw-900 p-50 p-m-10 p-md-20 p-xl-10 lh-30'>critical data wrangling</p>
                                           
                                        </div>                                      
                                    </div>  
                                    <div className="p-20 text-left" >
                                        <p className="fs-14 fc-light-grey">You’ll learn how to prepare and transform the dataset into the powerful tidy structure to facilitate data visualization. You’ll master the most used functions in the data wrangling packages dplyr and tidyr.</p>
                                    </div>                       
                                
                            </div>
                        </div>
                    </div>
        </div> 
        <div style={{backgroundColor:'#F4F4F4'}} className='mg-b-150'>
            <div className='row mg-t-100' style={{paddingLeft:'50px', paddingRight:'50px'}}>
                <div className='col-md-1'></div>
                <div className='col-md-2 text-center' style={{paddingRight:'0px'}}>
                    <p className='fs-24 fw-900' style={{lineHeight:'40px', color:'grey'}}>About the<br />Author</p>
                </div>
                {/* <div className='col-md-1'></div> */}
                <div className='col-md-2 text-center' style={{paddingRight:'0px'}}>
                    <img src={author} className='w-30 w-sl-60'></img>
                </div>
                <div className='col-md-6' style={{paddingLeft:'10px', paddingRight:"0px"}}>
                    <p>Bo Yuan, Ph.D, is a leading expert in the cutting-edge field of quantitative fluxomics in physiology and systems biology, and author of 30+ peer-reviewed papers in renowned scientific journals. He's currently a Postdoctoral Fellow at Harvard University and Metabolomics Specialist at Harvard School of Public Health.</p>
                </div>
            
            </div>

        </div>


    </div>
    )
}