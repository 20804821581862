import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrDup(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Duplicate a String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_dup()</code></strong> makes duplicated copies for each element of a string vector. It changes the length of each string element, but not the <Link to="../1-str-length">length of the vector</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>fruit <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"mango"</span>, <span className="st">"peach"</span>, <span className="st">"lemon"</span>)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># Repeat 2 times for each string element.</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">str_dup</span>(fruit, <span className="at">times =</span> <span className="dv">2</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mangomango" "peachpeach" "lemonlemon"</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># Duplicate 0, 1, and 3 times, respectively, for the 1st, 2nd and 3rd string.</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">str_dup</span>(fruit, <span className="at">times =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">1</span>, <span className="dv">3</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] ""                "peach"           "lemonlemonlemon"</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># Duplicate "na" 0 to 3 times, and combine with "ba".</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="fu">str_c</span>(<span className="st">"ba"</span>, <span className="fu">str_dup</span>(<span className="st">"na"</span>, <span className="dv">0</span><span className="sc">:</span><span className="dv">3</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "ba"       "bana"     "banana"   "bananana"</code></pre>
</div>
</div>
<hr/>
<p>In comparison, the base R function <code>rep()</code> also creates replicates. However, it reserves the length of each element, and changes the length of the vector.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">rep</span>(fruit, <span className="at">times =</span> <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango" "peach" "lemon" "mango" "peach" "lemon"</code></pre>
</div>
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">rep</span>(fruit, <span className="at">each =</span> <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango" "mango" "peach" "peach" "lemon" "lemon"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}