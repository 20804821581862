import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgVisualizeChangeWithTime from '../graphics_blog/change_with_time_summary.png'; 
import imgVisualizeChangeWithTimeWebp from '../graphics_blog/change_with_time_summary.webp'; 
export default function VisualizeChangeWithTime(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Six Awesome Strategies to Visualize Temporal Changes Effectively</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgVisualizeChangeWithTimeWebp} />
    <img className="cover-img" src={imgVisualizeChangeWithTime} />
  </picture>

<p>This article summarizes effective strategies to visualize temporal changes, illustrated with inspiring graphic examples.</p>
<section className="level3" id="use-line-segments-to-contrast-two-time-points">
<h3 className="anchored" data-anchor-id="use-line-segments-to-contrast-two-time-points">Use Line Segments to Contrast Two Time Points</h3>
<p>The following graph compares 1800 and 2015 regarding human life expectancy, population size, and GDP per capita for each country. The points signify the specific time points, and the line segments provide a clear visual trajectory. Additionally, the light grey rectangles serve to underscore the distinction between the two time periods.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_theme_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/lines_lifespan_theme_completed.png" data-fallback="graphics/lines_lifespan_theme_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-line-plot-gapminder"><strong>R source code</strong></Link>; inspired by Lisa Charlotte Rost by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link>.</figcaption>
</div>
</div>
</section>
<section className="level3" id="use-arrows-to-emphasize-direction-of-changes">
<h3 className="anchored" data-anchor-id="use-arrows-to-emphasize-direction-of-changes">Use Arrows to Emphasize Direction of Changes</h3>
<p>In addition to line segments, arrows are telltale elements that highlights direction of changes from one time point to another. The graph below employs this strategy to show the changes of seats held by women in national parliaments.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/women_seats_parliament_completed.png" data-fallback="graphics/women_seats_parliament_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-arrows-facet-women-parliament">R source code</Link>; inspired by demo graphics @ <Link to="https://www.datawrapper.de/charts">Datawrapper</Link></figcaption>
</div>
</div>
</section>
<section className="level3" id="use-lines-to-depict-continuous-changes">
<h3 className="anchored" data-anchor-id="use-lines-to-depict-continuous-changes">Use Lines to Depict Continuous Changes</h3>
<p>Lines are intuitive indicators of continuous changes recorded by a string of data points. The following plot shows the dynamics of smoking popularity in major western countries from 1880s to 2000s, and spotlighted such changes in United States, Germany and France in bright colors.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/lineplot_smoking_theme_completed.png" data-fallback="graphics/lineplot_smoking_theme_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-line-plot-cigarettes">R source code</Link>; inspired by demo graphics @ <Link to="https://www.datawrapper.de/charts">Datawrapper</Link></figcaption>
</div>
</div>
<p>On top of tracing temporal changes in each individual country, the average trend lines and variability ribbons can be added to enrich the graphic information. In the following plot, each thin line tracks the changes in human life expectancy in each country. On a continent-wise basis, a central thick line shows the average life span, and the shaded ribbon represents the variability around this average trend, showing one standard deviation above and below the mean.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_theme_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/lines_longevity_theme_completed.png" data-fallback="graphics/lines_longevity_theme_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-line-plot-life-expectancy">R source code</Link>.</figcaption>
</div>
</div>
</section>
<section className="level3" id="use-ribbons-to-illustrate-total-values-at-each-time-point">
<h3 className="anchored" data-anchor-id="use-ribbons-to-illustrate-total-values-at-each-time-point">Use Ribbons to Illustrate Total Values at Each Time Point</h3>
<p>The above line plots have shown dynamic changes for each individual country. When the cumulative sum of y-values at each time point is of interest, the area plot is ready to achieve this goal. Area plots are essentially stacked line plots, with filled colors between lines to form continuous bands or ribbons.</p>
<p>The following plot shows the dynamics of migration population from worldwide to the U.S. in the past two hundred years. It shows both country-wise and total population number at each time point.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/area_plot_migration_theme_completed.png" data-fallback="graphics/area_plot_migration_theme_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-area-plot-migration">ggplot2 demo.</Link> with <Link to="https://corybrunson.github.io/ggalluvial/">ggalluviam</Link> package; inspired by Mirko Lorenz with <Link to="https://www.datawrapper.de/charts">Datawrapper</Link>.</figcaption>
</div>
</div>
<p>A variant of the area / alluvium plot is the stream plot, which gives an extra touch of aesthetic enhancement. The following plot shows the changing popularity of movie genres in the past four decades.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_theme_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/ggstream_blockbuster_theme_completed.png" data-fallback="graphics/ggstream_blockbuster_theme_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-stream-blockbuster">R source code</Link> with the <Link to="https://github.com/davidsjoberg/ggstream">ggstream</Link> package.</figcaption>
</div>
</div>
</section>
<section className="level3" id="use-heatmap-to-present-temporal-changes">
<h3 className="anchored" data-anchor-id="use-heatmap-to-present-temporal-changes">Use Heatmap to Present Temporal Changes</h3>
<p>Heatmaps are another powerful tool with an engaging appeal to show temporal changes, and maps numeric values to a color scale. The following plots visualize the yearly incidences of eight infectious diseases across the U.S. states before and after the introduction of vaccines. It impressively highlights the power of vaccination in the control of disease spread.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid figure-img" src="graphics/heatmap_vaccine_function_completed_all_8_diseases.png" data-fallback="graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-heatmap-vaccine-for-8-diseases">R source code</Link>; inspired by Tynan DeBold and Dov Friedman in <Link to="https://graphics.wsj.com/infectious-diseases-and-vaccines/">WSJ</Link>.</figcaption>
</div>
</div>
<p>While colors effectively reflect the overall trend of changes, they are not as easily interpreted for precise numeric values when compared to a simple y-axis. It can be helpful to augment a heatmap with other plots. The following heatmap displays monthly sunspot activities spanning the past two hundred years. Adjacent to the heatmap, a synchronized line plot illustrates the continuous changes in sunspot activity along the y-axis, facilitating a more straightforward interpretation of fluctuations in the sunspot activities.</p>
<div className="quarto-figure quarto-figure-center">
<div className="mg-t-20 mg-b-20">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.webp" />
    <img className="img-fluid figure-img" src="graphics/heatmap_sunspot_completed.png" data-fallback="graphics/heatmap_sunspot_completed.png" />
  </picture>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-heatmap-sunspot-activities">R source code</Link>.</figcaption>
</div>
</div>
</section>
<section className="level3" id="visualize-changes-in-animation">
<h3 className="anchored" data-anchor-id="visualize-changes-in-animation">Visualize Changes in Animation</h3>
<p>Data animation was made famous back in 2007 by Hans Rosling in his legendary TED talk <Link to="https://www.youtube.com/watch?v=hVimVzgtD6w"><em>the best stats you’ve ever seen</em></Link>. Yet even today to render data to animation appears an appalling task to many data analysts - but it does not have to be! In fact, with the awesome R <Link to="https://gganimate.com/">gganimate</Link> package, render a static plot to animation can be as simple as adding a single line of code “faceting” the dataset based on a time variable. The following animated population pyramids show the human population structures in Germany in the past (in dark colors) and in the future with simulated data (in bright colors).</p>
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid figure-img" src="graphics/population_pyramid_animation_completed-1.gif"/></p>
<figcaption>See <Link to="https://www.databrewer.co/R/gallery/ggplot2-population-pyramid-animation">R source code</Link> with <Link to="(https://gganimate.com/)">gganimate</Link> package.</figcaption>
</figure>
</div>
</section>
</main>
</div>
</div>
)}