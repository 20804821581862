import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import UniteColumns from '../RDataWrangling/tidyr/contents/unite-columns_output_react'
import ExpandCombinationsVariablesPart2 from '../RDataWrangling/tidyr/contents/expand-combinations-variables-part2_output_react'
import Introduction from '../RDataWrangling/tidyr/contents/introduction_output_react'
import DropRowsContainingMissingValues from '../RDataWrangling/tidyr/contents/drop-rows-containing-missing-values_output_react'
import ExpandCombinationsVariables from '../RDataWrangling/tidyr/contents/expand-combinations-variables_output_react'
import PivotLongerPart1 from '../RDataWrangling/tidyr/contents/pivot-longer-part1_output_react'
import PivotLongerPart3 from '../RDataWrangling/tidyr/contents/pivot-longer-part3_output_react'
import SeparateColumns from '../RDataWrangling/tidyr/contents/separate-columns_output_react'
import Expand from '../RDataWrangling/tidyr/contents/expand_output_react'
import PivotWiderPart1 from '../RDataWrangling/tidyr/contents/pivot-wider-part1_output_react'
import ReplaceNaWithSpecifiedValues from '../RDataWrangling/tidyr/contents/replace-NA-with-specified-values_output_react'
import ExpandCombinationsVariablesPart3 from '../RDataWrangling/tidyr/contents/expand-combinations-variables-part3_output_react'
import PivotLongerPart4 from '../RDataWrangling/tidyr/contents/pivot-longer-part4_output_react'
import PivotWiderPart3 from '../RDataWrangling/tidyr/contents/pivot-wider-part3_output_react'
import Acknowledgement from '../RDataWrangling/tidyr/contents/acknowledgement_output_react'
import Pack from '../RDataWrangling/tidyr/contents/pack_output_react'
import PivotLongerPart2 from '../RDataWrangling/tidyr/contents/pivot-longer-part2_output_react'
import FillUpMissingValues from '../RDataWrangling/tidyr/contents/fill-up-missing-values_output_react'
import PivotWiderPart2 from '../RDataWrangling/tidyr/contents/pivot-wider-part2_output_react'
import ExpandCombinationsVariablesPart1 from '../RDataWrangling/tidyr/contents/expand-combinations-variables-part1_output_react'

const Rtidyr=[
        
    {'component': <Introduction />, 'path': 'introduction', 'title': 'introduction'},
    {id:uuidv4(), path:'', title: 'Pivot Longer'}, 
    {'component': <PivotLongerPart1 />, 'path': 'pivot-longer-part1', 'title': '1.1 pivot longer-1'},
    {'component': <PivotLongerPart2 />, 'path': 'pivot-longer-part2', 'title': '1.2 pivot longer-2'},
    {'component': <PivotLongerPart3 />, 'path': 'pivot-longer-part3', 'title': '1.3 pivot longer-3'},
    {'component': <PivotLongerPart4 />, 'path': 'pivot-longer-part4', 'title': '1.4 pivot longer-4'},
    
    {id:uuidv4(), path:'', title: 'Pivot Wider'}, 
    {'component': <PivotWiderPart1 />, 'path': 'pivot-wider-part1', 'title': '2.1 pivot wider-1'},
    {'component': <PivotWiderPart2 />, 'path': 'pivot-wider-part2', 'title': '2.2 pivot wider-2'},
    {'component': <PivotWiderPart3 />, 'path': 'pivot-wider-part3', 'title': '2.3 pivot wider-3'},

    {id:uuidv4(), path:'', title: 'Separate and Unite Columns'}, 
    {'component': <SeparateColumns />, 'path': 'separate-columns', 'title': '3. separate columns'},
    {'component': <UniteColumns />, 'path': 'unite-columns', 'title': '4. unite columns'},
    {'component': <Pack />, 'path': 'pack', 'title': '5. pack'},

    {id:uuidv4(), path:'', title: 'Handle Missing Values'}, 
    {'component': <DropRowsContainingMissingValues />, 'path': 'drop-rows-containing-missing-values', 'title': '6. drop rows containing missing values'},
    {'component': <ReplaceNaWithSpecifiedValues />, 'path': 'replace-NA-with-specified-values', 'title': '7. replace NA with specified values'},
    {'component': <FillUpMissingValues />, 'path': 'fill-up-missing-values', 'title': '8. fill up missing values'},
    
    // {'component': <Expand />, 'path': 'expand', 'title': 'expand'},
    // {'component': <ExpandCombinationsVariables />, 'path': 'expand-combinations-variables', 'title': 'expand combinations variables'},
    {id:uuidv4(), path:'', title: 'Expand Variable Combinations'}, 
    {'component': <ExpandCombinationsVariablesPart1 />, 'path': 'expand-combinations-variables-part1', 'title': '9.1 expand variables-1'},
    {'component': <ExpandCombinationsVariablesPart2 />, 'path': 'expand-combinations-variables-part2', 'title': '9.2 expand variables-2'},
    {'component': <ExpandCombinationsVariablesPart3 />, 'path': 'expand-combinations-variables-part3', 'title': '9.3 expand variables-3'},
   
    {'component': <Acknowledgement />, 'path': 'Acknowledgement', 'title': 'Acknowledgement'},
    {'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},

]

export default Rtidyr