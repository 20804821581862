import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2MosaicHealthWealth from '../graphics/mosaic_happy_theme_completed.png'; 
import imgGgplot2MosaicHealthWealthWebp from '../graphics/mosaic_happy_theme_completed.webp'; 
export default function Ggplot2MosaicHealthWealth(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Draw Mosaic Plot in ggplot2 to Visualize the Population Composition at Different Wealth and Health Status</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2MosaicHealthWealthWebp} />
    <img className="cover-img" src={imgGgplot2MosaicHealthWealth} />
  </picture>

<p>A mosaic plot is a graphical representation of the contingency table (frequency distribution of two or more categorical variables), and use the rectangle area to illustrate the proportion of cases falling into the different combination of category levels. In this work, we’ll create a mosaic plot to visualize the population composition at different wealth and health status in the United States. We’ll use the <Link to="https://haleyjeppson.github.io/ggmosaic/"><code>ggmosaic</code></Link> package by Haley et al.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>From the <code>happy</code> dataset built in the <code>ggmosaic</code> package, we’ll select two columns, <code>finrela</code>, the relative financial condition, and <code>health</code>, the health condition. We’ll only retain rows without missing values.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># install.packages("ggmosaic")</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggmosaic)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">select</span>(finrela, health) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>h <span className="ot">&lt;-</span> h[<span className="fu">complete.cases</span>(h), ]  </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">head</span>(h, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 2
<br/>  finrela       health   
<br/>  &lt;fct&gt;         &lt;fct&gt;    
<br/>1 average       good     
<br/>2 above average fair     
<br/>3 average       excellent
<br/>4 average       good     </code></pre>
</div>
</div>
<p>Create a contingency table showing the head counts in each combination of wealth and health status. We’ll visualize this table as a mosaic plot at a following step.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>h <span className="sc">%&gt;%</span> <span className="fu">table</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">                   health
<br/>finrela             excellent  good  fair  poor
<br/>  far below average       464   853   671   398
<br/>  below average          2262  4735  2808  1044
<br/>  average                6607 10473  4044   882
<br/>  above average          3450  3669   873   158
<br/>  far above average       403   292   102    41</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a mosaic plot visualizing the above contingency table. The area of each rectangle represents the proportion of population that falls into the associated wealth and health status.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>() <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_mosaic</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="fu">product</span>(finrela), <span className="at">fill =</span> health)) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">theme_mosaic</span>()</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_base_plot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/mosaic_happy_base_plot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Polish up the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"turquoise3"</span>, <span className="st">"steelblue1"</span>, <span className="st">"orange"</span>, <span className="st">"tomato2"</span>)) <span className="sc">+</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"Financial Status"</span>, <span className="at">y =</span> <span className="st">"Health Status"</span>) <span className="sc">+</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>),</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">40</span>, <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">size =</span> <span className="dv">10</span>),</span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">14</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"grey40"</span>),</span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a></span><br/>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/mosaic_happy_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># install.packages("ggmosaic")</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">library</span>(ggmosaic)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a></span><br/>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">select</span>(finrela, health) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>h <span className="ot">&lt;-</span> h[<span className="fu">complete.cases</span>(h), ]  </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a><span className="fu">head</span>(h, <span className="dv">4</span>)</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a></span><br/>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a><span className="co"># Create a contingency table </span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a><span className="co"># showing the head counts in each combination of wealth and health status. </span></span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>h <span className="sc">%&gt;%</span> <span className="fu">table</span>()</span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a></span><br/>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a></span><br/>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a><span className="do">### Visualization</span></span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a><span className="co"># Create a mosaic plot visualizing the above contingency table. </span></span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span> </span>
<span id="cb7-18"><a aria-hidden="true" href="#cb7-18" tabindex="-1"></a>  <span className="fu">ggplot</span>() <span className="sc">+</span></span>
<span id="cb7-19"><a aria-hidden="true" href="#cb7-19" tabindex="-1"></a>  <span className="fu">geom_mosaic</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="fu">product</span>(finrela), <span className="at">fill =</span> health)) <span className="sc">+</span></span>
<span id="cb7-20"><a aria-hidden="true" href="#cb7-20" tabindex="-1"></a>  <span className="fu">theme_mosaic</span>()</span>
<span id="cb7-21"><a aria-hidden="true" href="#cb7-21" tabindex="-1"></a>p1</span>
<span id="cb7-22"><a aria-hidden="true" href="#cb7-22" tabindex="-1"></a></span><br/>
<span id="cb7-23"><a aria-hidden="true" href="#cb7-23" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb7-24"><a aria-hidden="true" href="#cb7-24" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb7-25"><a aria-hidden="true" href="#cb7-25" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"turquoise3"</span>, <span className="st">"steelblue1"</span>, <span className="st">"orange"</span>, <span className="st">"tomato2"</span>)) <span className="sc">+</span></span>
<span id="cb7-26"><a aria-hidden="true" href="#cb7-26" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"Financial Status"</span>, <span className="at">y =</span> <span className="st">"Health Status"</span>) <span className="sc">+</span></span>
<span id="cb7-27"><a aria-hidden="true" href="#cb7-27" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb7-28"><a aria-hidden="true" href="#cb7-28" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>),</span>
<span id="cb7-29"><a aria-hidden="true" href="#cb7-29" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">40</span>, <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">size =</span> <span className="dv">10</span>),</span>
<span id="cb7-30"><a aria-hidden="true" href="#cb7-30" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">14</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"grey40"</span>),</span>
<span id="cb7-31"><a aria-hidden="true" href="#cb7-31" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb7-32"><a aria-hidden="true" href="#cb7-32" tabindex="-1"></a></span><br/>
<span id="cb7-33"><a aria-hidden="true" href="#cb7-33" tabindex="-1"></a>p2</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>The mosaic plot is a powerful tool to visualize the <em>bivariate</em> distribution, or a contingency table of <em>two</em> categories. But how about the “contingency table” of a <em>single</em> variable? Well, check out the article below and learn how to <Link to="../ggplot2-waffle-plot-wealth-distribution"><strong>create a Waffle plot to visualize the population distribution at different wealth status</strong></Link> in the United States.</p>
<p><Link to="../ggplot2-waffle-plot-wealth-distribution">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/waffle_plot_wealth_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/waffle_plot_wealth_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Compared with the mosaic plot, which visualizes relative proportions by <em>rectangle areas</em>, the <strong>2D histogram</strong> visualizes the proportion or counts using the <em>color scale</em>, and can be applied to both categorical variables (see <Link to="../ggplot2-2D-histogram-happy">this example</Link>) and continuous variables. For example, check out the following article on how to <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>create a 2D histogram with a map overlay</strong></Link> to visualize the hurricane activities in the North Atlantic region.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}