import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function RenameColumns(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Rename Columns of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<section className="level3" id="rename-columns-with-rename">
<h3 className="anchored" data-anchor-id="rename-columns-with-rename">Rename columns with <strong><code>rename()</code></strong></h3>
<p><strong><code>rename()</code></strong> alters the names of selected columns by employing the syntax: new_name = old_name.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># use "H" in replace of the old name "hair_color" </span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># use "S" in replace of the old name "skin_color" </span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename</span>(<span className="at">H =</span> hair_color, <span className="at">S =</span> skin_color)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass H     S          
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt; &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;  gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;  white, blue
<br/>  4 Darth Vader       202   136 none  white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Alternatively, you can use a named vector inside <strong><code>all_of()</code></strong> to specify the update of column names.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>x1 <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="at">H =</span> <span className="st">"hair_color"</span>, <span className="at">S =</span> <span className="st">"skin_color"</span>)</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename</span>(<span className="fu">all_of</span>(x1))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass H     S          
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt; &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;  gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;  white, blue
<br/>  4 Darth Vader       202   136 none  white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Note that if the named vector contains an element that is not one of the column names in the input dataset (e.g., “country” in the example below), it’ll incur an error. To increase the tolerance to such inconsistency, use <code>any_of()</code> instead.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>x2 <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="at">H =</span> <span className="st">"hair_color"</span>, <span className="at">S =</span> <span className="st">"skin_color"</span>, <span className="at">C =</span> <span className="st">"country"</span>)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a></span><br/>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a><span className="co"># "country" is not a column name in "starwars2"</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename</span>(<span className="fu">any_of</span>(x2))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass H     S          
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt; &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;  gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;  white, blue
<br/>  4 Darth Vader       202   136 none  white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="use-functions-to-rename-by-rename_with">
<h3 className="anchored" data-anchor-id="use-functions-to-rename-by-rename_with">Use functions to rename by <strong><code>rename_with()</code></strong></h3>
<p>Use <strong><code>rename_with()</code></strong> to rename columns with a function that specifies the renaming rule. The function takes the 2nd argument (<code>.fn</code>), and columns to be renamed takes the 3rd argument (<code>.cols</code>). If this 3rd argument is not specified, the function defaults to be applied to all columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># capitalize all column names</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename_with</span>(<span className="at">.fn =</span> toupper)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    NAME           HEIGHT  MASS HAIR_COLOR SKIN_COLOR 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond      fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;       white, blue
<br/>  4 Darth Vader       202   136 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>The code above is equivalent to:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># the function defaults to apply to every column (everything)</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename_with</span>(<span className="at">.fn =</span> toupper, <span className="at">.cols =</span> <span className="fu">everything</span>())</span></code></pre></div>
</div>
<p>Alternatively, in the 3rd argument, you can indicate specific columns to be renamed. (The argument names are omitted.)</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename_with</span>(toupper, <span className="fu">c</span>(hair_color, skin_color))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass HAIR_COLOR SKIN_COLOR 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond      fair       
<br/>  2 C-3PO             167    75 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32 &lt;NA&gt;       white, blue
<br/>  4 Darth Vader       202   136 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>You can use the <Link to="../2-select-columns"><strong>purrr</strong> style</Link> and an anonymous function (marked by the tilde <code>~</code>) to rename the columns. For instance, here for each selected column (noted by <code>.x</code>), you can replace the underscore <code>_</code> with a hyphen <code>-</code> in the column names. Use <code>ends_with()</code> to select columns whose names are ended with “color”.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">rename_with</span>(</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="sc">~</span> stringr<span className="sc">::</span><span className="fu">str_replace</span>(.x, <span className="at">pattern =</span> <span className="st">"_"</span>, <span className="at">replacement =</span> <span className="st">"-"</span>),</span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">ends_with</span>(<span className="st">"color"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass `hair-color` `skin-color`
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;        &lt;chr&gt;       
<br/>  1 Luke Skywalker    172    77 blond        fair        
<br/>  2 C-3PO             167    75 &lt;NA&gt;         gold        
<br/>  3 R2-D2              96    32 &lt;NA&gt;         white, blue 
<br/>  4 Darth Vader       202   136 none         white       
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}