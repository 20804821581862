import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarMortality from '../graphics/stacked_bar_mortality_theme_completed.png'; 
import imgGgplot2BarMortalityWebp from '../graphics/stacked_bar_mortality_theme_completed.webp'; 
export default function Ggplot2BarMortality(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Stacked and Faceted Bar Plots in ggplot2 to Visualize U.S. Mortality</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarMortalityWebp} />
    <img className="cover-img" src={imgGgplot2BarMortality} />
  </picture>

<p>In this article, we’ll visualize the population mortality resulted from different diseases in the United States. We’ll use the <code>USMortality</code> dataset from the <code>lattice</code> package.</p>
<p><strong>Major techniques covered in this tutorial include:</strong></p>
<ul>
<li>Create stacked bar plots with text annotation.</li>
<li>Divide a plot into faceted panels (subplots).</li>
<li>Annotate selected faceted panels.</li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p><strong>Wrap long strings of disease names into multiple lines</strong>. This helps to better utilize the available plot space. This is achieved using <code>str_wrap</code> from the <Link to="/R/data-wrangling/stringr/0-introduction"><code>stringr</code> package</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(lattice)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>u <span className="ot">&lt;-</span> USMortality <span className="sc">%&gt;%</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="co"># divide long names into single words in multiple lines to ease visualization</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">Cause =</span> <span className="fu">str_wrap</span>(Cause, <span className="at">width =</span> <span className="dv">7</span>)) <span className="co"># max of 7 characters per line</span></span></code></pre></div>
</div>
<p><strong>Create an ordered bar plot by the disease occurrence rate</strong>. To do this, here we convert the <code>Cause</code> variable to a factor, and specify the level order by the sum of occurrence rate in an descending order. This is done using <code>fct_reorder</code> from the <code>forcats</code> package. (check this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide on ordering graphic elements</Link> in ggplot2)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>u <span className="ot">&lt;-</span> u <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="co"># convert "Cause" into a factor, reordered by the sum of occurrence "Rate" </span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">Cause =</span> <span className="fu">fct_reorder</span>(Cause, Rate, <span className="at">.fun =</span> sum, <span className="at">.desc =</span> T)) <span className="sc">%&gt;%</span> </span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a></span><br/>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a><span className="fu">head</span>(u, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  Status Sex    Cause             Rate    SE
<br/>  &lt;fct&gt;  &lt;fct&gt;  &lt;fct&gt;            &lt;dbl&gt; &lt;dbl&gt;
<br/>1 Urban  Male   "Heart\ndisease"  210.   0.2
<br/>2 Rural  Male   "Heart\ndisease"  243.   0.6
<br/>3 Urban  Female "Heart\ndisease"  132.   0.2</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>Create a bar plot</strong>, displaying the disease occurrence rate in different <code>Status</code> and <code>Sex</code>. The plot is divided into faceted panels (subplots) by the <code>Cause</code> variable. Here we use <code>facet_grid(Cause ~ .)</code> instead of <code>facet_wrap(~Cause, ncol = 1)</code>, so that the facet titles can be switched to the left side of the bars. We’ll fix the graphic crowdedness in the following steps.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">15</span>))</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a></span><br/>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> u <span className="sc">%&gt;%</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Sex, <span className="at">y =</span> Rate, <span className="at">fill =</span> Sex, <span className="at">color =</span> Sex, <span className="at">alpha =</span> Status)) <span className="sc">+</span> </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"stack"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) <span className="sc">+</span></span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="co"># create a facet panel for each cause of disease </span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="fu">facet_grid</span>(Cause <span className="sc">~</span> ., <span className="at">scales =</span> <span className="st">"free_y"</span>, </span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>             <span className="co"># relocate the facet title to the left</span></span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a>             <span className="at">switch =</span> <span className="st">"y"</span>)</span>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/stacked_bar_mortality_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/stacked_bar_mortality_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="p2"><strong>Flip the plot</strong>. Note that after flip, the aesthetic mapping remains unchanged (e.g., <code>Sex</code> remains the <code>x</code> aesthetic, and <code>Rate</code> remains the <code>y</code>). In contrast, in the <code>theme()</code> syntax, the horizontal axis is always treated as the x-axis, and vertical axis as the y-axis, regardless of the flip.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">coord_flip</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    <span className="at">expand =</span> <span className="dv">0</span>, <span className="co"># expand the plot, and remove margins around the plot </span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="at">clip =</span> F) <span className="co">#  ensure the title added later is displayed completely</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/stacked_bar_mortality_flip.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/stacked_bar_mortality_flip"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Annotate the bars with the disease occurrence rate.</strong></p>
<ul>
<li><p>Use position in <code>stack</code> to synchronize texts with bars in position, and use <code>vjust = .5</code> to centralize the texts.</p></li>
<li><p>Colors are applied first to texts in one subplot and in the barplot of the male (white - steelblue4), and then to the barplot of the female (white - red4). This pattern is then repeatedly 10 times across the 10 faceted panels (<code>rep(10)</code>).</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">round</span>(Rate)), </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>), <span className="at">alpha =</span> .<span className="dv">6</span>,</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"white"</span>, <span className="st">"steelblue4"</span>, <span className="st">"white"</span>, <span className="st">"red4"</span>) <span className="sc">%&gt;%</span> <span className="fu">rep</span>(<span className="dv">10</span>),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/stacked_bar_mortality_texts.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/stacked_bar_mortality_texts"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Add plot title at the bottom right corner of the plot.</strong></p>
<p><strong><em>Method 1:</em></strong> <strong>Use <code>ggtitle()</code> to add plot title</strong>. Use <code>theme</code> to specify the title’s position, with <code>vjust</code> to adjust the text’s relative vertical position, and <code>hjust</code> to adjust the horizontal position. This approach, however, takes trial and error to find a suitable <code>vjust</code> value. In addition, a small change in the <code>size</code> of the text and the dimension of the display device can lead to drastic change of the title position. So the <code>vjust</code> approach for plot title is <em>not</em> a very robust one.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"U.S. mortality rates, 2011-2013</span><span className="sc">\n</span><span className="st">deaths per 100,000 population"</span>) <span className="sc">+</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">100</span>, <span className="co"># this parameter value can vary a lot!</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">size =</span> <span className="dv">12</span>, </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="at">face =</span> <span className="st">"bold"</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/stacked_bar_mortality_title_method1.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/stacked_bar_mortality_title_method1"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong><em>Method 2</em></strong>: <strong>Add title using <code>geom_text()</code></strong>. This is a more robust approach. As it involves adding texts only to selected subplots, the syntax is a bit more complicated.</p>
<ul>
<li><p>For faceted panels, a regular text mapping adds texts to <em>all</em> panels. To add texts <em>uniquely</em> to <em>selected panels</em>, we need to create a dataset (e.g., <code>myTitle</code>) to specify the text content and position, including the <strong>faceting variable</strong> (i.e., <code>Cause</code>) <strong>to explicitly indicate the name of the panel</strong> (e.g., “Nephritis”) <strong>where the texts should be added</strong>.</p></li>
<li><p>Also importantly, <strong>we keep the faceting variable <code>Cause</code> defined in <code>myTitle</code> as a <em>factor</em> to reserve the bar order in the plot</strong>. If <code>Cause</code> is a character variable, it’ll rearrange the bars (subplots) in the default alphabetical order.</p></li>
<li><p>Note that we had set <code>clip = F</code> in <code>coord_flip()</code> at the earlier step of making <a href="#p2"><code>p2</code></a>. This is critical for complete texts display when the texts cross the border of the faceted panels.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>myTitle <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="at">X =</span> <span className="st">"Male"</span>, </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="at">Y =</span> <span className="dv">450</span>, </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="at">text =</span> <span className="st">"U.S. mortality rates, 2011-2013</span><span className="sc">\n</span><span className="st">deaths per 100,000 population"</span>, </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="co"># add text to the "Nephritis" panel</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="at">Cause =</span> <span className="fu">factor</span>(<span className="st">"Nephritis"</span>)</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>)</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a></span><br/>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="at">data =</span> myTitle,</span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>  <span className="fu">aes</span>(X, Y, <span className="at">label =</span> text), </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>  <span className="at">inherit.aes =</span> F, </span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="dv">1</span>, <span className="co"># align to the right boundary of the panel </span></span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>  <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>)</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a></span><br/>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/stacked_bar_mortality_title_method2.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/stacked_bar_mortality_title_method2"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>A final polish-up.</strong> Major edits include the removal of default x-axis (left) labels (and this frees up more space for the plot title), reorient the subplot titles, and add a black outline to the legend keys of the <code>alpha</code> aesthetic to make them visually more prominent. It’s worth noting that to customize the subplot titles, <strong><code>strip.text.y</code> does <em>not</em> work; it must have the suffix <code>.left</code> to be effective.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># color scale</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>)  <span className="sc">+</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>)  <span className="sc">+</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  </span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>    <span className="co"># show facet titles completely</span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>    <span className="at">strip.clip =</span> <span className="st">"off"</span>,</span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>    </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>    <span className="co"># </span><span className="al">NOTE</span><span className="co">!! "strip.text.y" does not work; must add suffix ".left"</span></span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>    <span className="at">strip.text.y.left =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">0</span>, <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">face =</span> <span className="st">"bold"</span>), </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>    <span className="at">strip.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"cornsilk"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>    </span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>    <span className="co"># remove axial elements</span></span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>    <span className="at">axis.ticks =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">9</span>, .<span className="dv">5</span>),</span>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a>    <span className="at">legend.title =</span> <span className="fu">element_blank</span>()) <span className="sc">+</span></span>
<span id="cb9-21"><a aria-hidden="true" href="#cb9-21" tabindex="-1"></a>  </span>
<span id="cb9-22"><a aria-hidden="true" href="#cb9-22" tabindex="-1"></a>  <span className="co"># add outline to alpha's legend </span></span>
<span id="cb9-23"><a aria-hidden="true" href="#cb9-23" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">alpha =</span> <span className="fu">guide_legend</span>(<span className="at">override.aes =</span> <span className="fu">list</span>( <span className="at">color =</span> <span className="st">'black'</span>)))</span>
<span id="cb9-24"><a aria-hidden="true" href="#cb9-24" tabindex="-1"></a></span><br/>
<span id="cb9-25"><a aria-hidden="true" href="#cb9-25" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/stacked_bar_mortality_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/stacked_bar_mortality_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a><span className="fu">library</span>(lattice)</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a></span><br/>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">15</span>))</span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a></span><br/>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a><span className="co"># data cleanup</span></span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>u <span className="ot">&lt;-</span> USMortality <span className="sc">%&gt;%</span> </span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>  <span className="co"># divide long names into single words in multiple lines to ease visualization</span></span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>  <span className="co"># max of 7 characters per line </span></span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">Cause =</span> <span className="fu">str_wrap</span>(Cause, <span className="at">width =</span> <span className="dv">7</span>)) <span className="sc">%&gt;%</span>  </span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>  </span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>  <span className="co"># convert "Cause" into a factor, reordered by the sum of occurrence "Rate" </span></span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">Cause =</span> <span className="fu">fct_reorder</span>(Cause, Rate, <span className="at">.fun =</span> sum, <span className="at">.desc =</span> T)) <span className="sc">%&gt;%</span> </span>
<span id="cb10-17"><a aria-hidden="true" href="#cb10-17" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb10-18"><a aria-hidden="true" href="#cb10-18" tabindex="-1"></a></span><br/>
<span id="cb10-19"><a aria-hidden="true" href="#cb10-19" tabindex="-1"></a><span className="fu">head</span>(u, <span className="dv">3</span>)</span>
<span id="cb10-20"><a aria-hidden="true" href="#cb10-20" tabindex="-1"></a></span><br/>
<span id="cb10-21"><a aria-hidden="true" href="#cb10-21" tabindex="-1"></a></span><br/>
<span id="cb10-22"><a aria-hidden="true" href="#cb10-22" tabindex="-1"></a><span className="co"># Create a base bar plot, </span></span>
<span id="cb10-23"><a aria-hidden="true" href="#cb10-23" tabindex="-1"></a><span className="co"># displaying the disease occurrence rate in different `Status` and `Sex`. </span></span>
<span id="cb10-24"><a aria-hidden="true" href="#cb10-24" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> u <span className="sc">%&gt;%</span> </span>
<span id="cb10-25"><a aria-hidden="true" href="#cb10-25" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Sex, <span className="at">y =</span> Rate, <span className="at">fill =</span> Sex, <span className="at">color =</span> Sex, <span className="at">alpha =</span> Status)) <span className="sc">+</span> </span>
<span id="cb10-26"><a aria-hidden="true" href="#cb10-26" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"stack"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) <span className="sc">+</span></span>
<span id="cb10-27"><a aria-hidden="true" href="#cb10-27" tabindex="-1"></a>  <span className="co"># create a facet panel for each cause of disease </span></span>
<span id="cb10-28"><a aria-hidden="true" href="#cb10-28" tabindex="-1"></a>  <span className="fu">facet_grid</span>(Cause <span className="sc">~</span> ., <span className="at">scales =</span> <span className="st">"free_y"</span>, </span>
<span id="cb10-29"><a aria-hidden="true" href="#cb10-29" tabindex="-1"></a>             <span className="co"># relocate the facet title to the left</span></span>
<span id="cb10-30"><a aria-hidden="true" href="#cb10-30" tabindex="-1"></a>             <span className="at">switch =</span> <span className="st">"y"</span>)</span>
<span id="cb10-31"><a aria-hidden="true" href="#cb10-31" tabindex="-1"></a>p1</span>
<span id="cb10-32"><a aria-hidden="true" href="#cb10-32" tabindex="-1"></a></span><br/>
<span id="cb10-33"><a aria-hidden="true" href="#cb10-33" tabindex="-1"></a></span><br/>
<span id="cb10-34"><a aria-hidden="true" href="#cb10-34" tabindex="-1"></a><span className="co"># Flip the plot </span></span>
<span id="cb10-35"><a aria-hidden="true" href="#cb10-35" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">coord_flip</span>(</span>
<span id="cb10-36"><a aria-hidden="true" href="#cb10-36" tabindex="-1"></a>  <span className="at">expand =</span> <span className="dv">0</span>, <span className="co"># expand the plot, and remove margins around the plot </span></span>
<span id="cb10-37"><a aria-hidden="true" href="#cb10-37" tabindex="-1"></a>  <span className="at">clip =</span> F) <span className="co">#  ensure the title added later is displayed completely</span></span>
<span id="cb10-38"><a aria-hidden="true" href="#cb10-38" tabindex="-1"></a>p2</span>
<span id="cb10-39"><a aria-hidden="true" href="#cb10-39" tabindex="-1"></a></span><br/>
<span id="cb10-40"><a aria-hidden="true" href="#cb10-40" tabindex="-1"></a></span><br/>
<span id="cb10-41"><a aria-hidden="true" href="#cb10-41" tabindex="-1"></a><span className="co"># Annotate the bars with the disease occurrence rate.</span></span>
<span id="cb10-42"><a aria-hidden="true" href="#cb10-42" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb10-43"><a aria-hidden="true" href="#cb10-43" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">round</span>(Rate)), </span>
<span id="cb10-44"><a aria-hidden="true" href="#cb10-44" tabindex="-1"></a>  <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>), <span className="at">alpha =</span> .<span className="dv">6</span>,</span>
<span id="cb10-45"><a aria-hidden="true" href="#cb10-45" tabindex="-1"></a>  <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"white"</span>, <span className="st">"steelblue4"</span>, <span className="st">"white"</span>, <span className="st">"red4"</span>) <span className="sc">%&gt;%</span> <span className="fu">rep</span>(<span className="dv">10</span>),</span>
<span id="cb10-46"><a aria-hidden="true" href="#cb10-46" tabindex="-1"></a>  <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb10-47"><a aria-hidden="true" href="#cb10-47" tabindex="-1"></a>p3</span>
<span id="cb10-48"><a aria-hidden="true" href="#cb10-48" tabindex="-1"></a></span><br/>
<span id="cb10-49"><a aria-hidden="true" href="#cb10-49" tabindex="-1"></a></span><br/>
<span id="cb10-50"><a aria-hidden="true" href="#cb10-50" tabindex="-1"></a><span className="co"># Add plot title at the bottom right corner of the plot. </span></span>
<span id="cb10-51"><a aria-hidden="true" href="#cb10-51" tabindex="-1"></a><span className="co"># Method 1 </span></span>
<span id="cb10-52"><a aria-hidden="true" href="#cb10-52" tabindex="-1"></a><span className="co"># (!! the title position may be VERY OFF depending on display device status)</span></span>
<span id="cb10-53"><a aria-hidden="true" href="#cb10-53" tabindex="-1"></a>p3 <span className="sc">+</span> </span>
<span id="cb10-54"><a aria-hidden="true" href="#cb10-54" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"US mortality rates, 2011-2013</span><span className="sc">\n</span><span className="st">deaths per 100,000 population"</span>) <span className="sc">+</span></span>
<span id="cb10-55"><a aria-hidden="true" href="#cb10-55" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(</span>
<span id="cb10-56"><a aria-hidden="true" href="#cb10-56" tabindex="-1"></a>    <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">110</span>, <span className="co"># varies extremely in different display size</span></span>
<span id="cb10-57"><a aria-hidden="true" href="#cb10-57" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">size =</span> <span className="dv">12</span>, </span>
<span id="cb10-58"><a aria-hidden="true" href="#cb10-58" tabindex="-1"></a>    <span className="at">face =</span> <span className="st">"bold"</span>))</span>
<span id="cb10-59"><a aria-hidden="true" href="#cb10-59" tabindex="-1"></a></span><br/>
<span id="cb10-60"><a aria-hidden="true" href="#cb10-60" tabindex="-1"></a><span className="co"># Method 2</span></span>
<span id="cb10-61"><a aria-hidden="true" href="#cb10-61" tabindex="-1"></a>myTitle <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb10-62"><a aria-hidden="true" href="#cb10-62" tabindex="-1"></a>  <span className="at">X =</span> <span className="st">"Male"</span>, </span>
<span id="cb10-63"><a aria-hidden="true" href="#cb10-63" tabindex="-1"></a>  <span className="at">Y =</span> <span className="dv">450</span>, </span>
<span id="cb10-64"><a aria-hidden="true" href="#cb10-64" tabindex="-1"></a>  <span className="at">text =</span> <span className="st">"US mortality rates, 2011-2013</span><span className="sc">\n</span><span className="st">deaths per 100,000 population"</span>, </span>
<span id="cb10-65"><a aria-hidden="true" href="#cb10-65" tabindex="-1"></a>  <span className="co"># add text to the "Nephritis" panel</span></span>
<span id="cb10-66"><a aria-hidden="true" href="#cb10-66" tabindex="-1"></a>  <span className="at">Cause =</span> <span className="fu">factor</span>(<span className="st">"Nephritis"</span>)</span>
<span id="cb10-67"><a aria-hidden="true" href="#cb10-67" tabindex="-1"></a>)</span>
<span id="cb10-68"><a aria-hidden="true" href="#cb10-68" tabindex="-1"></a></span><br/>
<span id="cb10-69"><a aria-hidden="true" href="#cb10-69" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb10-70"><a aria-hidden="true" href="#cb10-70" tabindex="-1"></a>  <span className="at">data =</span> myTitle,</span>
<span id="cb10-71"><a aria-hidden="true" href="#cb10-71" tabindex="-1"></a>  <span className="fu">aes</span>(X, Y, <span className="at">label =</span> text), </span>
<span id="cb10-72"><a aria-hidden="true" href="#cb10-72" tabindex="-1"></a>  <span className="at">inherit.aes =</span> F, </span>
<span id="cb10-73"><a aria-hidden="true" href="#cb10-73" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="dv">1</span>, <span className="co"># align to the right boundary of the panel </span></span>
<span id="cb10-74"><a aria-hidden="true" href="#cb10-74" tabindex="-1"></a>  <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>)</span>
<span id="cb10-75"><a aria-hidden="true" href="#cb10-75" tabindex="-1"></a></span><br/>
<span id="cb10-76"><a aria-hidden="true" href="#cb10-76" tabindex="-1"></a>p4</span>
<span id="cb10-77"><a aria-hidden="true" href="#cb10-77" tabindex="-1"></a></span><br/>
<span id="cb10-78"><a aria-hidden="true" href="#cb10-78" tabindex="-1"></a></span><br/>
<span id="cb10-79"><a aria-hidden="true" href="#cb10-79" tabindex="-1"></a><span className="co"># final polish-up.</span></span>
<span id="cb10-80"><a aria-hidden="true" href="#cb10-80" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb10-81"><a aria-hidden="true" href="#cb10-81" tabindex="-1"></a>  <span className="co"># color scale</span></span>
<span id="cb10-82"><a aria-hidden="true" href="#cb10-82" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>)  <span className="sc">+</span></span>
<span id="cb10-83"><a aria-hidden="true" href="#cb10-83" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>)  <span className="sc">+</span></span>
<span id="cb10-84"><a aria-hidden="true" href="#cb10-84" tabindex="-1"></a>  </span>
<span id="cb10-85"><a aria-hidden="true" href="#cb10-85" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb10-86"><a aria-hidden="true" href="#cb10-86" tabindex="-1"></a>    <span className="co"># show facet titles completely</span></span>
<span id="cb10-87"><a aria-hidden="true" href="#cb10-87" tabindex="-1"></a>    <span className="at">strip.clip =</span> <span className="st">"off"</span>,</span>
<span id="cb10-88"><a aria-hidden="true" href="#cb10-88" tabindex="-1"></a>    </span>
<span id="cb10-89"><a aria-hidden="true" href="#cb10-89" tabindex="-1"></a>    <span className="co"># </span><span className="al">NOTE</span><span className="co">!! "strip.text.y" does not work; must add suffix ".left"</span></span>
<span id="cb10-90"><a aria-hidden="true" href="#cb10-90" tabindex="-1"></a>    <span className="at">strip.text.y.left =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">0</span>, <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">face =</span> <span className="st">"bold"</span>), </span>
<span id="cb10-91"><a aria-hidden="true" href="#cb10-91" tabindex="-1"></a>    <span className="at">strip.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"cornsilk"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb10-92"><a aria-hidden="true" href="#cb10-92" tabindex="-1"></a>    </span>
<span id="cb10-93"><a aria-hidden="true" href="#cb10-93" tabindex="-1"></a>    <span className="co"># remove axial elements</span></span>
<span id="cb10-94"><a aria-hidden="true" href="#cb10-94" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-95"><a aria-hidden="true" href="#cb10-95" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-96"><a aria-hidden="true" href="#cb10-96" tabindex="-1"></a>    <span className="at">axis.ticks =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-97"><a aria-hidden="true" href="#cb10-97" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-98"><a aria-hidden="true" href="#cb10-98" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">9</span>, .<span className="dv">5</span>),</span>
<span id="cb10-99"><a aria-hidden="true" href="#cb10-99" tabindex="-1"></a>    <span className="at">legend.title =</span> <span className="fu">element_blank</span>()) <span className="sc">+</span></span>
<span id="cb10-100"><a aria-hidden="true" href="#cb10-100" tabindex="-1"></a>  </span>
<span id="cb10-101"><a aria-hidden="true" href="#cb10-101" tabindex="-1"></a>  <span className="co"># add outline to alpha's legend </span></span>
<span id="cb10-102"><a aria-hidden="true" href="#cb10-102" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">alpha =</span> <span className="fu">guide_legend</span>(<span className="at">override.aes =</span> <span className="fu">list</span>( <span className="at">color =</span> <span className="st">'black'</span>)))</span>
<span id="cb10-103"><a aria-hidden="true" href="#cb10-103" tabindex="-1"></a></span><br/>
<span id="cb10-104"><a aria-hidden="true" href="#cb10-104" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>When handling long axis labels, <Link to="../../R/visualization/3-ggplot2-wrap-long-texts">wrapping them into multiple lines</Link> is an effective solution as demonstrated above. A <strong>powerful alternative solution is to display them as horizontal titles of faceted subplots</strong>, as illustrated below.</p>
<p><Link to="../ggplot2-bar-CO2-air-travel">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Barplots are the building blocks of the <Link to="../ggplot2-population-pyramid"><strong>population pyramid</strong></Link>, a frequently used tool in demographics to illustrate the age and gender distribution of a population.</p>
<p><Link to="../ggplot2-population-pyramid">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_theme_completed.png" />
  </picture>
</Link></p>
<p>And check this awesome article to <Link to="../ggplot2-population-pyramid-animation"><strong>render the pyramids to animation</strong></Link>!</p>
<p><Link to="../ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
</main>
</div>
</div>
)}