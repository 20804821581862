import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Introduction(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Welcome to the <strong>tibble</strong> Package!</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>A tibble, or <code>tbl_df</code>, is a modern re-imagining of the <code>data.frame</code>, keeping what time has proven to be effective, and throwing out the obsolete features. While similar to <code>data.frame</code>, it has important improvements to provide enhanced printing for large and complex datasets, and to help create cleaner and more expressive code.</p>
<p>The tibble structure is the central data structure for the set of packages known as the tidyverse, including <Link to="/R/data-wrangling/dplyr/0-introduction">dplyr</Link>, <Link to="/R/visualization/introduction">ggplot2</Link>, <Link to="/R/data-wrangling/tidyr/introduction">tidyr</Link>, and <Link to="https://tibble.tidyverse.org/">readr</Link>. Most datasets in this website’s R tutorials are displayed in the tibble format.</p>
<p>The easiest way to get the tibble package is to install the whole tidyverse.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"tidyverse"</span>)</span></code></pre></div>
</div>
<p>Alternatively, you can install just the tibble package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"tibble"</span>)</span></code></pre></div>
</div>
<p>In the following tutorials, we’ll cover the most used functions in the tibble package:</p>
<ul>
<li><p><strong>Printing tibbles.</strong> <Link to="../print-tibbles">Improved printing method</Link> is one of its most striking improvements compared with traditional data.frame.</p></li>
<li><p><strong>Create tibbles from scratch</strong>, using <Link to="../create-tibbles"><code>tibble()</code></Link> (column-wise) or <Link to="../row-wise-tibble-creation"><code>tribble()</code></Link> (row-wise, a more readable style).</p></li>
<li><p><strong>Convert various data types</strong> (e.g., data.frame, matrix, vector and list) <strong>to tibbles.</strong> <Link to="../print-tibbles"><code>as_tibble()</code></Link> is the most used. There are other convenient functions for special cases, including <Link to="../convert-vector-to-one-dimension-tibble"><code>as_tibble_col()</code> and <code>as_tibble_row()</code></Link>, and <Link to="../convert-vector-list-to-tibble"><code>enframe()</code></Link> (along with its opposite operation <Link to="../convert-two-columns-to-vector-or-list"><code>deframe()</code></Link>).</p></li>
<li><p><strong>Modify a tibble</strong> by adding additional rows with <Link to="../add-rows-to-tibble"><code>add_row()</code></Link>, or add additional columns with <Link to="../add-columns-to-tibble"><code>add_column()</code></Link>.</p></li>
</ul>
<p>Now, let’s dive into the details of each of these powerful functions!</p>
</main>
</div>
</div>
)}