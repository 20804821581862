import React from 'react';
import Ggplot2WrapLongTexts from '../RVisualization/contents/3-ggplot2-wrap-long-texts_output_react'
import Ggplot2ReorderViolinPlots from '../RVisualization/contents/5-ggplot2-reorder-violin-plots_output_react'
import Ggplot2ReorderStackingOfBars from '../RVisualization/contents/7-ggplot2-reorder-stacking-of-bars_output_react'
import Ggplot2TitleLegendRemoval from '../RVisualization/contents/13-2-ggplot2-title-legend-removal_output_react'
import Ggplot2RenameTitleLegend from '../RVisualization/contents/13-ggplot2-rename-title-legend_output_react'
import Ggplot2ReorderLegendKeys from '../RVisualization/contents/8-ggplot2-reorder-legend-keys_output_react'
import Ggplot2OverlapErrorbar from '../RVisualization/contents/3-ggplot2-overlap-errorbar_output_react'
import Ggplot2RelocateLegendPosition from '../RVisualization/contents/10-ggplot2-relocate-legend-position_output_react'
import Ggplot2ChangeLegendRowNumber from '../RVisualization/contents/11-ggplot2-change-legend-row-number_output_react'
import Ggplot2CreatePoints from '../RVisualization/contents/18-ggplot2-create-points_output_react'
import Ggplot2ReorderLegendColorbar from '../RVisualization/contents/9-ggplot2-reorder-legend-colorBar_output_react'
import Ggplot2ReorderMultipleLegends from '../RVisualization/contents/12-ggplot2-reorder-multiple-legends_output_react'
import Ggplot2ColorBrewerPalette from '../RVisualization/contents/15-ggplot2-color-brewer-palette_output_react'
import Ggplot2ReorderBars from '../RVisualization/contents/4-ggplot2-reorder-bars_output_react'
import Ggplot2ColorViridisPalette from '../RVisualization/contents/16-ggplot2-color-viridis-palette_output_react'
import Ggplot2OvercrowdedDataPoints from '../RVisualization/contents/1-ggplot2-overcrowded-data-points_output_react'
import Ggplot2ReorderFacetedPanels from '../RVisualization/contents/6-ggplot2-reorder-faceted-panels_output_react'
import Ggplot2OvercrowdedTextLabels from '../RVisualization/contents/2-ggplot2-overcrowded-text-labels_output_react'
import Ggplot2LegendRemove from '../RVisualization/contents/14-ggplot2-legend-remove_output_react'
import Ggplot2PolarCoordSummary from '../RVisualization/contents/17-ggplot2-polar-coord-summary_output_react'
import RVisualLandingPage from '../RVisualization/RVisualLandingPage'


const RVisualizationData=[
{'component':<RVisualLandingPage />, 'path': 'introduction', 'title': 'Introduction'},
{'component': <Ggplot2OvercrowdedDataPoints />, 'path': '1-ggplot2-overcrowded-data-points', 'title': 'Overcrowded data points'},
{'component': <Ggplot2OvercrowdedTextLabels />, 'path': '2-ggplot2-overcrowded-text-labels', 'title': 'Overcrowded text labels'},
{'component': <Ggplot2WrapLongTexts />, 'path': '3-ggplot2-wrap-long-texts', 'title': 'Wrap long texts'},
{'component': <Ggplot2OverlapErrorbar />, 'path': '3-ggplot2-overlap-errorbar', 'title': 'Overlap errorbar'},
{'component': <Ggplot2ReorderBars />, 'path': '4-ggplot2-reorder-bars', 'title': 'Reorder bars'},
{'component': <Ggplot2ReorderViolinPlots />, 'path': '5-ggplot2-reorder-violin-plots', 'title': 'Reorder violin plots'},
{'component': <Ggplot2ReorderFacetedPanels />, 'path': '6-ggplot2-reorder-faceted-panels', 'title': 'Reorder faceted panels'},
{'component': <Ggplot2ReorderStackingOfBars />, 'path': '7-ggplot2-reorder-stacking-of-bars', 'title': 'Reorder stacking of bars'},
{'component': <Ggplot2ReorderLegendKeys />, 'path': '8-ggplot2-reorder-legend-keys', 'title': 'Reorder legend keys'},
{'component': <Ggplot2ReorderLegendColorbar />, 'path': '9-ggplot2-reorder-legend-colorBar', 'title': 'Reorder legend colorBar'},
{'component': <Ggplot2RelocateLegendPosition />, 'path': '10-ggplot2-relocate-legend-position', 'title': 'Relocate legend position'},
{'component': <Ggplot2ChangeLegendRowNumber />, 'path': '11-ggplot2-change-legend-row-number', 'title': 'Change legend row number'},
{'component': <Ggplot2ReorderMultipleLegends />, 'path': '12-ggplot2-reorder-multiple-legends', 'title': 'Reorder multiple legends'},
{'component': <Ggplot2RenameTitleLegend />, 'path': '13-ggplot2-rename-title-legend', 'title': 'Rename title legend'},
{'component': <Ggplot2TitleLegendRemoval />, 'path': '13-2-ggplot2-title-legend-removal', 'title': 'Title legend removal'},
{'component': <Ggplot2LegendRemove />, 'path': '14-ggplot2-legend-remove', 'title': 'Legend remove'},
{'component': <Ggplot2ColorBrewerPalette />, 'path': '15-ggplot2-color-brewer-palette', 'title': 'Color brewer palette'},
{'component': <Ggplot2ColorViridisPalette />, 'path': '16-ggplot2-color-viridis-palette', 'title': 'Color viridis palette'},
{'component': <Ggplot2PolarCoordSummary />, 'path': '17-ggplot2-polar-coord-summary', 'title': 'Polar coord summary'},
{'component': <Ggplot2CreatePoints />, 'path': '18-ggplot2-create-points', 'title': 'Create points'},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
]

export default RVisualizationData