import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2PenguinScatterplotEllipseGgextra from '../graphics/penguin_ggExtra_completed.png'; 
import imgGgplot2PenguinScatterplotEllipseGgextraWebp from '../graphics/penguin_ggExtra_completed.webp'; 
export default function Ggplot2PenguinScatterplotEllipseGgextra(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Annotate Scatterplots with Confidence Ellipse and Marginal Distribution in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2PenguinScatterplotEllipseGgextraWebp} />
    <img className="cover-img" src={imgGgplot2PenguinScatterplotEllipseGgextra} />
  </picture>

<p>In this article, I’ll illustrate how to create a scatterplot with different types of annotations. <strong>Major techniques explained in this article include:</strong></p>
<ul>
<li><a href="#confidence_ellipses">Create confidence ellipses.</a></li>
<li><a href="#text_annotation">Create text annotations with <code>geom_text</code>.</a></li>
<li><a href="#theme">Customize the theme.</a></li>
<li><a href="#marginal_distribution">Visualize the marginal distribution in two distinct ways.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<p>Here we’ll use the popular <code>penguins</code> dataset from the <Link to="https://allisonhorst.github.io/palmerpenguins/"><code>palmerpenguins</code> package</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># install.packages("palmerpenguins")</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(palmerpenguins) <span className="co"># data package</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># set as default theme</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">16</span>))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="fu">head</span>(penguins, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 8
<br/>  species island    bill_length_mm bill_depth_mm flipper_length_mm body_mass_g
<br/>  &lt;fct&gt;   &lt;fct&gt;              &lt;dbl&gt;         &lt;dbl&gt;             &lt;int&gt;       &lt;int&gt;
<br/>1 Adelie  Torgersen           39.1          18.7               181        3750
<br/>2 Adelie  Torgersen           39.5          17.4               186        3800
<br/>3 Adelie  Torgersen           40.3          18                 195        3250
<br/>4 Adelie  Torgersen           NA            NA                  NA          NA
<br/># ℹ 2 more variables: sex &lt;fct&gt;, year &lt;int&gt;</code></pre>
</div>
</div>
<p>Create a scatterplot, showing the relationship between the length and depth of a penguin’s bill.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> penguins <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> bill_length_mm, <span className="at">y =</span> bill_depth_mm, </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>             <span className="at">color =</span> species)) <span className="sc">+</span> </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="co"># add small amount of random noise to points position to reduce overlap</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>    <span className="at">width =</span> .<span className="dv">2</span>, <span className="at">height =</span> <span className="fl">0.2</span>, <span className="at">seed =</span> <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  </span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="co"># color scale for categorical variable</span></span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Set2"</span>) </span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_scatterplot_jittered.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguin_scatterplot_jittered"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="confidence_ellipses">Draw confidence ellipse that encircles 95% of data points.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">stat_ellipse</span>(<span className="at">level =</span> .<span className="dv">95</span>, <span className="at">show.legend =</span> F, <span className="at">linewidth =</span> .<span className="dv">7</span>) </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_confidence_ellipse.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguin_confidence_ellipse"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="text_annotation">Annotate the scatterplot with the species’ names in place of the legend. We’ll remove the legend in the next step.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>species <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">34</span>, <span className="fl">56.5</span>, <span className="dv">56</span>),</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="fl">16.5</span>, <span className="dv">19</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">species =</span> <span className="fu">c</span>(<span className="st">"Adelie"</span>, <span className="st">"Gentoo"</span>,  <span className="st">"Chinstrap"</span>))</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a></span><br/>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  <span className="at">data =</span> species, </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">x =</span> x, <span className="at">y =</span> y, <span className="at">label =</span> species, <span className="at">color =</span> species),</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  <span className="co"># not inherit aesthetic mapping from the ggplot line</span></span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>  <span className="at">inherit.aes =</span> F, </span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  <span className="at">fontface =</span> <span className="st">"bold.italic"</span>, <span className="at">size =</span> <span className="dv">6</span>)</span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguin_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="theme">Polish up more details as commented below.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="co"># rename axes' titles</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"bill length (mm)"</span>, <span className="at">y =</span> <span className="st">"bill depth (mm)"</span>) <span className="sc">+</span> </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="co"># adjust axis breaks </span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">20</span>, <span className="at">to =</span> <span className="dv">60</span>, <span className="at">by =</span> <span className="dv">4</span>)) <span className="sc">+</span> </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">10</span>, <span className="at">to =</span> <span className="dv">25</span>, <span className="at">by =</span> <span className="dv">2</span>)) <span className="sc">+</span> </span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>    <span className="co"># remove legend</span></span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>    <span className="co"># remove the minor grids, and adjust the width of the major grids</span></span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>    <span className="at">panel.grid.minor =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>    <span className="at">panel.grid.major =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">3</span>),</span>
<span id="cb6-14"><a aria-hidden="true" href="#cb6-14" tabindex="-1"></a>    <span className="co"># bold axis titles</span></span>
<span id="cb6-15"><a aria-hidden="true" href="#cb6-15" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb6-16"><a aria-hidden="true" href="#cb6-16" tabindex="-1"></a>    <span className="co"># increase the margin on the right side of y-axis title, and on top of x-axis title</span></span>
<span id="cb6-17"><a aria-hidden="true" href="#cb6-17" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb6-18"><a aria-hidden="true" href="#cb6-18" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>))) <span className="sc">+</span> </span>
<span id="cb6-19"><a aria-hidden="true" href="#cb6-19" tabindex="-1"></a>  </span>
<span id="cb6-20"><a aria-hidden="true" href="#cb6-20" tabindex="-1"></a>  <span className="co"># center justify the legend title</span></span>
<span id="cb6-21"><a aria-hidden="true" href="#cb6-21" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">color =</span> <span className="fu">guide_legend</span>(<span className="at">title.hjust =</span> .<span className="dv">5</span>)) </span>
<span id="cb6-22"><a aria-hidden="true" href="#cb6-22" tabindex="-1"></a></span><br/>
<span id="cb6-23"><a aria-hidden="true" href="#cb6-23" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguin_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="marginal_distribution">Visualize the marginal distribution. <code>geom_rug()</code> creates barcode like images at the edge of the plot, and intuitively depict the data distribution.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">geom_rug</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="co"># apply the same "jitter" position to align with points</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_jitter</span>(<span className="at">width =</span> .<span className="dv">2</span>, <span className="at">height =</span> <span className="fl">0.2</span>, <span className="at">seed =</span> <span className="dv">1</span>), </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">sides =</span> <span className="st">"tr"</span>, <span className="co"># t, top; r, right; b, bottom; l, left       </span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="at">length =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>), <span className="co"># shorter bar line length </span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>    <span className="at">linewidth =</span> .<span className="dv">1</span>) <span className="co"># smaller width to reduce overlap</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a></span><br/>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_univariate_distribution_rug.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguin_univariate_distribution_rug"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Further enhance the marginal distribution visualization with density plots using the <code>ggExtra</code> package. This package also provides an interactive dashboard approach to customize the plots. The dashboard can be generated by calling <code>ggMarginalGadget(p5)</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># install.packages("ggExtra")</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(ggExtra)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">ggMarginal</span>(p5, <span className="at">groupFill =</span> T, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">linewidth =</span> <span className="dv">0</span>, <span className="at">alpha =</span> .<span className="dv">4</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_ggExtra_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguin_ggExtra_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a><span className="co"># install.packages("palmerpenguins")</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a><span className="fu">library</span>(palmerpenguins) <span className="co"># data package</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a></span><br/>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a><span className="co"># set as default theme</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">16</span>))</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a></span><br/>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a></span><br/>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a><span className="co"># Create a basic scatterplot</span></span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> penguins <span className="sc">%&gt;%</span> </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> bill_length_mm, <span className="at">y =</span> bill_depth_mm, </span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>             <span className="at">color =</span> species)) <span className="sc">+</span> </span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>  </span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>  <span className="co"># add a small amount of random noise to points position to reduce overlap</span></span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(</span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>    <span className="at">width =</span> .<span className="dv">2</span>, <span className="at">height =</span> <span className="fl">0.2</span>, <span className="at">seed =</span> <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a>  </span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a>  <span className="co"># color scale for categorical variable</span></span>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Set2"</span>) </span>
<span id="cb9-21"><a aria-hidden="true" href="#cb9-21" tabindex="-1"></a>p1</span>
<span id="cb9-22"><a aria-hidden="true" href="#cb9-22" tabindex="-1"></a></span><br/>
<span id="cb9-23"><a aria-hidden="true" href="#cb9-23" tabindex="-1"></a></span><br/>
<span id="cb9-24"><a aria-hidden="true" href="#cb9-24" tabindex="-1"></a><span className="co"># Add confidence ellipse that encircles 95% of data points. </span></span>
<span id="cb9-25"><a aria-hidden="true" href="#cb9-25" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb9-26"><a aria-hidden="true" href="#cb9-26" tabindex="-1"></a>  <span className="fu">stat_ellipse</span>(<span className="at">level =</span> .<span className="dv">95</span>, <span className="at">show.legend =</span> F, <span className="at">linewidth =</span> .<span className="dv">7</span>) </span>
<span id="cb9-27"><a aria-hidden="true" href="#cb9-27" tabindex="-1"></a>p2</span>
<span id="cb9-28"><a aria-hidden="true" href="#cb9-28" tabindex="-1"></a></span><br/>
<span id="cb9-29"><a aria-hidden="true" href="#cb9-29" tabindex="-1"></a></span><br/>
<span id="cb9-30"><a aria-hidden="true" href="#cb9-30" tabindex="-1"></a><span className="co"># Directly annotate the scatterplot with species names in replace of the legend.</span></span>
<span id="cb9-31"><a aria-hidden="true" href="#cb9-31" tabindex="-1"></a>species <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb9-32"><a aria-hidden="true" href="#cb9-32" tabindex="-1"></a>  <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">34</span>, <span className="fl">56.5</span>, <span className="dv">56</span>),</span>
<span id="cb9-33"><a aria-hidden="true" href="#cb9-33" tabindex="-1"></a>  <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="fl">16.5</span>, <span className="dv">19</span>),</span>
<span id="cb9-34"><a aria-hidden="true" href="#cb9-34" tabindex="-1"></a>  <span className="at">species =</span> <span className="fu">c</span>(<span className="st">"Adelie"</span>, <span className="st">"Gentoo"</span>,  <span className="st">"Chinstrap"</span>))</span>
<span id="cb9-35"><a aria-hidden="true" href="#cb9-35" tabindex="-1"></a></span><br/>
<span id="cb9-36"><a aria-hidden="true" href="#cb9-36" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb9-37"><a aria-hidden="true" href="#cb9-37" tabindex="-1"></a>  <span className="at">data =</span> species, </span>
<span id="cb9-38"><a aria-hidden="true" href="#cb9-38" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">x =</span> x, <span className="at">y =</span> y, <span className="at">label =</span> species, <span className="at">color =</span> species),</span>
<span id="cb9-39"><a aria-hidden="true" href="#cb9-39" tabindex="-1"></a>  <span className="co"># not inherit aesthetic mapping from the ggplot line</span></span>
<span id="cb9-40"><a aria-hidden="true" href="#cb9-40" tabindex="-1"></a>  <span className="at">inherit.aes =</span> F, </span>
<span id="cb9-41"><a aria-hidden="true" href="#cb9-41" tabindex="-1"></a>  <span className="at">fontface =</span> <span className="st">"bold.italic"</span>, <span className="at">size =</span> <span className="dv">6</span>)</span>
<span id="cb9-42"><a aria-hidden="true" href="#cb9-42" tabindex="-1"></a>p3</span>
<span id="cb9-43"><a aria-hidden="true" href="#cb9-43" tabindex="-1"></a></span><br/>
<span id="cb9-44"><a aria-hidden="true" href="#cb9-44" tabindex="-1"></a></span><br/>
<span id="cb9-45"><a aria-hidden="true" href="#cb9-45" tabindex="-1"></a><span className="co"># Polish up more details</span></span>
<span id="cb9-46"><a aria-hidden="true" href="#cb9-46" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb9-47"><a aria-hidden="true" href="#cb9-47" tabindex="-1"></a>  <span className="co"># rename axis titles</span></span>
<span id="cb9-48"><a aria-hidden="true" href="#cb9-48" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"bill length (mm)"</span>, <span className="at">y =</span> <span className="st">"bill depth (mm)"</span>) <span className="sc">+</span> </span>
<span id="cb9-49"><a aria-hidden="true" href="#cb9-49" tabindex="-1"></a>  <span className="co"># adjust axis breaks </span></span>
<span id="cb9-50"><a aria-hidden="true" href="#cb9-50" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">20</span>, <span className="at">to =</span> <span className="dv">60</span>, <span className="at">by =</span> <span className="dv">4</span>)) <span className="sc">+</span> </span>
<span id="cb9-51"><a aria-hidden="true" href="#cb9-51" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">10</span>, <span className="at">to =</span> <span className="dv">25</span>, <span className="at">by =</span> <span className="dv">2</span>)) <span className="sc">+</span> </span>
<span id="cb9-52"><a aria-hidden="true" href="#cb9-52" tabindex="-1"></a>  </span>
<span id="cb9-53"><a aria-hidden="true" href="#cb9-53" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb9-54"><a aria-hidden="true" href="#cb9-54" tabindex="-1"></a>    <span className="co"># remove legend</span></span>
<span id="cb9-55"><a aria-hidden="true" href="#cb9-55" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb9-56"><a aria-hidden="true" href="#cb9-56" tabindex="-1"></a>    <span className="co"># remove the minor grids, and adjust the width of the major grids</span></span>
<span id="cb9-57"><a aria-hidden="true" href="#cb9-57" tabindex="-1"></a>    <span className="at">panel.grid.minor =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-58"><a aria-hidden="true" href="#cb9-58" tabindex="-1"></a>    <span className="at">panel.grid.major =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">3</span>),</span>
<span id="cb9-59"><a aria-hidden="true" href="#cb9-59" tabindex="-1"></a>    <span className="co"># bold axis titles</span></span>
<span id="cb9-60"><a aria-hidden="true" href="#cb9-60" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb9-61"><a aria-hidden="true" href="#cb9-61" tabindex="-1"></a>    <span className="co"># increase the margin on the right side of y-axis title, and on top of x-axis title</span></span>
<span id="cb9-62"><a aria-hidden="true" href="#cb9-62" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb9-63"><a aria-hidden="true" href="#cb9-63" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>))) <span className="sc">+</span> </span>
<span id="cb9-64"><a aria-hidden="true" href="#cb9-64" tabindex="-1"></a>  </span>
<span id="cb9-65"><a aria-hidden="true" href="#cb9-65" tabindex="-1"></a>  <span className="co"># center justify the legend title</span></span>
<span id="cb9-66"><a aria-hidden="true" href="#cb9-66" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">color =</span> <span className="fu">guide_legend</span>(<span className="at">title.hjust =</span> .<span className="dv">5</span>)) </span>
<span id="cb9-67"><a aria-hidden="true" href="#cb9-67" tabindex="-1"></a></span><br/>
<span id="cb9-68"><a aria-hidden="true" href="#cb9-68" tabindex="-1"></a>p4</span>
<span id="cb9-69"><a aria-hidden="true" href="#cb9-69" tabindex="-1"></a></span><br/>
<span id="cb9-70"><a aria-hidden="true" href="#cb9-70" tabindex="-1"></a></span><br/>
<span id="cb9-71"><a aria-hidden="true" href="#cb9-71" tabindex="-1"></a><span className="co"># Visualize the marginal distribution. </span></span>
<span id="cb9-72"><a aria-hidden="true" href="#cb9-72" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb9-73"><a aria-hidden="true" href="#cb9-73" tabindex="-1"></a>  <span className="fu">geom_rug</span>(</span>
<span id="cb9-74"><a aria-hidden="true" href="#cb9-74" tabindex="-1"></a>    <span className="co"># apply the same "jitter" position to align with points</span></span>
<span id="cb9-75"><a aria-hidden="true" href="#cb9-75" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_jitter</span>(<span className="at">width =</span> .<span className="dv">2</span>, <span className="at">height =</span> <span className="fl">0.2</span>, <span className="at">seed =</span> <span className="dv">1</span>), </span>
<span id="cb9-76"><a aria-hidden="true" href="#cb9-76" tabindex="-1"></a>    <span className="at">sides =</span> <span className="st">"tr"</span>, <span className="co"># t, top; r, right; b, bottom; l, left       </span></span>
<span id="cb9-77"><a aria-hidden="true" href="#cb9-77" tabindex="-1"></a>    <span className="at">length =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>), <span className="co"># shorter bar line length </span></span>
<span id="cb9-78"><a aria-hidden="true" href="#cb9-78" tabindex="-1"></a>    <span className="at">linewidth =</span> .<span className="dv">1</span>) <span className="co"># smaller width to reduce overlap</span></span>
<span id="cb9-79"><a aria-hidden="true" href="#cb9-79" tabindex="-1"></a></span><br/>
<span id="cb9-80"><a aria-hidden="true" href="#cb9-80" tabindex="-1"></a>p5</span>
<span id="cb9-81"><a aria-hidden="true" href="#cb9-81" tabindex="-1"></a></span><br/>
<span id="cb9-82"><a aria-hidden="true" href="#cb9-82" tabindex="-1"></a><span className="co"># Enhance the marginal distribution visualization with density plots</span></span>
<span id="cb9-83"><a aria-hidden="true" href="#cb9-83" tabindex="-1"></a><span className="co"># install.packages("ggExtra")</span></span>
<span id="cb9-84"><a aria-hidden="true" href="#cb9-84" tabindex="-1"></a><span className="fu">library</span>(ggExtra)</span>
<span id="cb9-85"><a aria-hidden="true" href="#cb9-85" tabindex="-1"></a><span className="fu">ggMarginal</span>(p5, <span className="at">groupFill =</span> T, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">linewidth =</span> <span className="dv">0</span>, <span className="at">alpha =</span> .<span className="dv">4</span>) </span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>A scatterplot is often drawn on a semi-logarithmic or double-log scale when there is significant data skewness in one or two axes. Check out the following <Link to="../ggplot2-scatterplot-urbanization"><strong>scatterplot on semi-logarithmic scale</strong></Link> that unveils linear relationship between the percentage of urbanization and log(GDP per capita).</p>
<p><Link to="../ggplot2-scatterplot-urbanization">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/scatterplot_urbanization_ggrepel_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/scatterplot_urbanization_ggrepel_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to the scatterplot, line plots are another most used tool in data visualization. Check out the following <Link to="../ggplot2-line-plot-cigarettes"><strong>annotated line plot</strong></Link> that shows the evolving popularity of smoking worldwide, especially in the highlighted countries of the United States, Germany, and France.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}