import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrReplace(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Replace Matched Pattern with Other Strings</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<ul>
<li><code>str_replace()</code> replaces the <em>first match</em> in each string element</li>
<li><code>str_replace_all()</code> replaces <em>all matches</em> in each string element</li>
<li>`<code>str_replace_na()</code> replaces missing value <code>NA</code> into character <code>"NA"</code></li>
</ul>
<hr/>
<p><strong><code>str_replace()</code></strong> is similar to <code>str_extract()</code>, but instead of extracting the matched component, it <em>replaces</em> it with a new string.</p>
<ul>
<li><p>In each string element, only the first match is replaced.</p></li>
<li><p>The <code>pattern</code> argument takes a specific string, or a regular expression as input. Special characters need to be escaped by the double-backslash to be interpreted literally, instead of as a functional operator.</p></li>
<li><p>For the <code>replacement</code> argument, it is not mandatory to escape special characters by the double-backslash; what is inside the quote will be treated literally.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>phones <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="st">"Mia Smith, 728*971*9652"</span>, </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="st">"Max Lee, 683*976*9876"</span>,</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="st">"Ava Johnson, 912*254*3387"</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="co"># replace the first asterisk with a dot.</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="fu">str_replace</span>(phones, </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>            <span className="at">pattern =</span> <span className="st">"</span><span className="sc">\\</span><span className="st">*"</span>, <span className="co"># search for a single asterisk</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>            <span className="at">replacement =</span> <span className="st">"."</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "Mia Smith, 728.971*9652"   "Max Lee, 683.976*9876"     "Ava Johnson, 912.254*3387"</code></pre>
</div>
</div>
<hr/>
<p><strong><code>str_replace_all()</code></strong> replaces <strong>all</strong> the matches. Here we replace all asterisks with a dot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_replace_all</span>(phones, <span className="at">pattern =</span> <span className="st">"</span><span className="sc">\\</span><span className="st">*"</span>, <span className="at">replacement =</span> <span className="st">"."</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "Mia Smith, 728.971.9652"   "Max Lee, 683.976.9876"     "Ava Johnson, 912.254.3387"</code></pre>
</div>
</div>
<p>The <code>pattern</code> and <code>replacement</code> can be vectorized. In the code below, we replace each person’s name with their initials, respectively.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_replace</span>(phones, </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>            <span className="at">pattern =</span> <span className="fu">c</span>(<span className="st">"Mia Smith"</span>, <span className="st">"Max Lee"</span>, <span className="st">"Ava Johnson"</span>),</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>            <span className="at">replacement =</span> <span className="fu">c</span>(<span className="st">"MS"</span>, <span className="st">"ML"</span>, <span className="st">"AJ"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "MS, 728*971*9652" "ML, 683*976*9876" "AJ, 912*254*3387"</code></pre>
</div>
</div>
<p>The <code>replacement</code> can be a function to be applied to the matched components. Here, we turn all names to upper case using <code>str_to_upper()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_replace</span>(phones, </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>            <span className="at">pattern =</span> <span className="fu">c</span>(<span className="st">"Mia Smith"</span>, <span className="st">"Max Lee"</span>, <span className="st">"Ava Johnson"</span>),</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>            <span className="at">replacement =</span> str_to_upper)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "MIA SMITH, 728*971*9652"   "MAX LEE, 683*976*9876"     "AVA JOHNSON, 912*254*3387"</code></pre>
</div>
</div>
<p>The above code can be slightly simplified using <code>rebus</code> package. While <code>WRD</code> matches only a single letter or digit, <code>str_replace_all()</code> repeats such replacement across the entire string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(rebus)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">str_replace_all</span>(phones, <span className="at">pattern =</span> WRD, </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>                <span className="at">replacement =</span> str_to_upper)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "MIA SMITH, 728*971*9652"   "MAX LEE, 683*976*9876"     "AVA JOHNSON, 912*254*3387"</code></pre>
</div>
</div>
<hr/>
<p><strong><code>str_replace_na()</code></strong> turns missing value <code>NA</code> to the character string <code>"NA"</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">str_replace_na</span>(<span className="fu">c</span>(<span className="st">"abc"</span>, <span className="cn">NA</span>, <span className="st">"xyz"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "abc" "NA"  "xyz"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}