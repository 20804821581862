
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer className="footer-section p-t-100">
            <div className="section" style={{color:'grey'}}>
                ©Copyright 2024 DataBrewer          
            </div>
            <div className="section">
                <Link to='/privacy'>
                    Privacy Policy & Terms
                </Link>
            </div>
            
        </footer>
    )
}