import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2OvercrowdedTextLabels from '../tutorial/crowded_texts_base.png';

export default function Ggplot2OvercrowdedTextLabels(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Seven Tips to Deal with Overcrowded Text Labels</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>A graphic can be often plagued with crammed text labels. In this tutorial, we’ll discuss 7 great methods to deal with this common issue in data visualization.</p>
<ul>
<li><a href="#case1">Case <strong>1</strong>: Too many densely packed labels. (Methods <strong>1 ~ 4</strong>)</a></li>
<li><a href="#case2">Case <strong>2</strong>: Long text strings. (Methods <strong>5 ~ 6</strong>)</a></li>
<li><a href="#case3">Case <strong>3</strong>: Crowded scatterplot of texts (Method <strong>7</strong>)</a></li>
</ul>
<hr/>
<section className="level3" id="packages-and-global-theme">
<h3 className="anchored" data-anchor-id="packages-and-global-theme">Packages and global theme</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># default theme</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">theme_set</span>(</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>    <span className="at">axis.text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"red3"</span>, <span className="at">face =</span> <span className="st">"bold"</span>)))</span></code></pre></div>
</div>
</section>
<section className="level3" id="case1">
<h3 className="anchored" data-anchor-id="case1">Case 1: Too many densely packed labels</h3>
<p>In the bar chart displayed below, the x-axis labels are densely packed and overlapping, making it difficult to read. <strong>Methods 1 ~ 4</strong> come in handy to address this situation.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> mpg <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> manufacturer)) <span className="sc">+</span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">geom_bar</span>(<span className="at">fill =</span> <span className="st">"grey80"</span>)</span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2OvercrowdedTextLabels} /></p>
</figure>
</div>
</div>
</div>
<section className="level4" id="method-1-swap-the-x-and-y-axis">
<h4 className="anchored" data-anchor-id="method-1-swap-the-x-and-y-axis">Method 1: Swap the x and y axis</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">coord_flip</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_flip.png"/></p>
</figure>
</div>
</div>
</div>
<p>Note that with <code>coord_flip()</code>, the rule of aesthetic mapping is not changed: the <code>manufacturer</code> variable remains the <code>x</code> aesthetic (left vertical axis), and the <code>count</code> variable remains the <code>y</code> aesthetic (bottom horizontal axis). In the <code>theme()</code> syntax, however, the horizontal axis is always treated as the x-axis, and the vertical axis as the y-axis.</p>
</section>
<section className="level4" id="method-2-tilt-the-axis-labels">
<h4 className="anchored" data-anchor-id="method-2-tilt-the-axis-labels">Method 2: Tilt the axis labels</h4>
<p>Note that the text is horizontally justified to the right with <code>hjust = 1</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">60</span>, <span className="at">hjust =</span> <span className="dv">1</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_tilted_axis_labels.png"/></p>
</figure>
</div>
</div>
</div>
<p>The <code>guides()</code> syntax can be used to render the same graphical effect.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># the text is automatically justified to the right </span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">x =</span> <span className="fu">guide_axis</span>(<span className="at">angle =</span> <span className="dv">60</span>)) </span></code></pre></div>
</div>
<p>The code above is equivalent to the following one:</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(<span className="at">guide =</span> <span className="fu">guide_axis</span>(<span className="at">angle =</span> <span className="dv">60</span>))</span></code></pre></div>
</div>
</section>
<section className="level4" id="method-3-stagger-the-axis-labels">
<h4 className="anchored" data-anchor-id="method-3-stagger-the-axis-labels">Method 3: Stagger the axis labels</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">x =</span> <span className="fu">guide_axis</span>(<span className="at">n.dodge =</span> <span className="dv">2</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_stagger.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-4-abbreviate-the-labels.">
<h4 className="anchored" data-anchor-id="method-4-abbreviate-the-labels.">Method 4: Abbreviate the labels.</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(<span className="at">labels =</span> abbreviate)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_abbreviate.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</section>
<section className="level3" id="case2">
<h3 className="anchored" data-anchor-id="case2">Case 2: Long text strings</h3>
<p>In the scatterplot displayed below, each x-axis label is composed of multiple words. The long strings overlap with each other and are difficult to read.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>course <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="st">"Environmental science and policy"</span>, </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="st">"Global economic issues"</span>, </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="st">"Introduction to ggplot2 visualization"</span>, </span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  <span className="st">"Introduction to psychology"</span>, </span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  <span className="st">"Quantum mechanics fundamentals"</span>)</span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>scores <span className="ot">=</span> <span className="fu">c</span>(<span className="dv">78</span>, <span className="dv">90</span>, <span className="dv">100</span>, <span className="dv">87</span>, <span className="dv">90</span>)</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a></span><br/>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>t <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">course =</span> course, <span className="at">scores =</span> scores)</span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a></span><br/>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>b <span className="ot">&lt;-</span> <span className="fu">ggplot</span>(t, <span className="fu">aes</span>(course, scores)) <span className="sc">+</span> </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">alpha =</span> .<span className="dv">2</span>)</span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>b</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_overlapped_labels.png"/></p>
</figure>
</div>
</div>
</div>
<p>You can use <strong>methods 5-6</strong> to wrap texts to avoid/reduce text overlap. Check <Link to="../3-ggplot2-wrap-long-texts">this article</Link> for a systematic summary of text wrapping techniques.</p>
<section className="level4" id="method-5-wrap-axis-labels-with-the-scales-package">
<h4 className="anchored" data-anchor-id="method-5-wrap-axis-labels-with-the-scales-package">Method 5: Wrap axis labels with the <code>scales</code> package</h4>
<p>Use the <code>label_wrap()</code> function from the popular <Link to="https://scales.r-lib.org/"><code>scales</code></Link> package to wrap long strings of axial labels. The <code>width</code> argument specifies the maximum number of characters in each line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(scales)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>b <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(<span className="at">labels =</span> <span className="fu">label_wrap</span>(<span className="at">width =</span> <span className="dv">15</span>))  </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_wrap_axis_labels.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="wrap">
<h4 className="anchored" data-anchor-id="wrap">Method 6: Wrap texts with the <code>stringr</code> pakcage.</h4>
<p>The <code>stringr</code> is a popular package for string manipulation. The function <code>str_wrap()</code> is a more flexibly way to wrap texts in ggplot2. The <code>width</code> argument specifies the maximum number of characters in each line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>b <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="at">labels =</span> <span className="cf">function</span>(x)<span className="fu">str_wrap</span>(x, <span className="at">width =</span> <span className="dv">10</span>))  </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_stringr_wrap_labels.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</section>
<section className="level3" id="case3">
<h3 className="anchored" data-anchor-id="case3">Case 3: Crowded scatterplot of texts</h3>
<p>Consider the following scatterplot of texts with much overlap.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>m <span className="ot">&lt;-</span> mtcars <span className="sc">%&gt;%</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">cars =</span> <span className="fu">rownames</span>(mtcars)) <span className="sc">%&gt;%</span> </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(mpg, qsec, <span className="at">label =</span> cars)) </span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a></span><br/>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>m <span className="sc">+</span> <span className="fu">geom_text</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_text_scatterplot.png"/></p>
</figure>
</div>
</div>
</div>
<p>In addition to techniques like <a href="#wrap">text wrapping</a> illustrated above, some approaches used to visualize overcrowded scatterplots (<Link to="../1-ggplot2-overcrowded-data-points"><strong>check here</strong></Link>) can be also useful. On top of these, <strong>method 7</strong> is a special tool to address overlapped scatter-texts.</p>
<section className="level4" id="method7">
<h4 className="anchored" data-anchor-id="method7">Method 7: Use <Link to="https://ggrepel.slowkow.com/"><code>ggrepel</code></Link> package to fine-tune the text position for minimal overlap.</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">library</span>(ggrepel)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a></span><br/>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>m <span className="sc">+</span> <span className="fu">geom_text_repel</span>(</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>  <span className="co"># reduce the padding space around the text to zero</span></span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>  <span className="at">box.padding =</span> <span className="fu">unit</span>(<span className="dv">0</span>, <span className="at">units =</span> <span className="st">"pt"</span>), </span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>  </span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>  <span className="co"># Always show all texts despite potential overlap</span></span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>  <span className="co"># by default, some texts will NOT be shown if there are too many texts</span></span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>  <span className="at">max.overlaps =</span> <span className="cn">Inf</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_ggrepel.png"/></p>
</figure>
</div>
</div>
</div>
<p>Use colors to help distinguish the labels. And add points to display the original position of each data point.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>m <span className="sc">+</span> </span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="fu">geom_text_repel</span>(<span className="fu">aes</span>(<span className="at">color =</span> cars),</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>                  <span className="at">box.padding =</span> <span className="fu">unit</span>(<span className="dv">0</span>, <span className="at">units =</span> <span className="st">"pt"</span>), </span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>                  <span className="at">max.overlaps =</span> <span className="cn">Inf</span>) <span className="sc">+</span></span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>  <span className="co"># add points to display the actual position</span></span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="fu">aes</span>(<span className="at">color =</span> cars, <span className="at">fill =</span> cars), </span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>             <span className="at">shape =</span> <span className="dv">21</span>, <span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>  <span className="co"># remove the legend</span></span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/crowded_texts_points.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</section>
</main>
</div>
</div>
)}