import React from 'react';
import Introduction from '../RDataWrangling/tibble/contents/introduction_output_react'
import PrintTibbles from '../RDataWrangling/tibble/contents/print-tibbles_output_react'
import ConvertVectorToOneDimensionTibble from '../RDataWrangling/tibble/contents/convert-vector-to-one-dimension-tibble_output_react'
import CreateTibbles from '../RDataWrangling/tibble/contents/create-tibbles_output_react'
import ConvertVectorListToTibble from '../RDataWrangling/tibble/contents/convert-vector-list-to-tibble_output_react'
import AddRowsToTibble from '../RDataWrangling/tibble/contents/add-rows-to-tibble_output_react'
import AddColumnsToTibble from '../RDataWrangling/tibble/contents/add-columns-to-tibble_output_react'
import ConvertTwoColumnsToVectorOrList from '../RDataWrangling/tibble/contents/convert-two-columns-to-vector-or-list_output_react'
import RowWiseTibbleCreation from '../RDataWrangling/tibble/contents/row-wise-tibble-creation_output_react'

const RtibbleData=[
{'component': <Introduction />, 'path': 'introduction', 'title': '0. Introduction'},
{'component': <PrintTibbles />, 'path': 'print-tibbles', 'title': '1. Print tibbles'},
{'component': <CreateTibbles />, 'path': 'create-tibbles', 'title': '2. Create tibbles'},
{'component': <RowWiseTibbleCreation />, 'path': 'row-wise-tibble-creation', 'title': '3. Row-wise tibble creation'},
{'component': <ConvertVectorToOneDimensionTibble />, 'path': 'convert-vector-to-one-dimension-tibble', 'title': '4. Convert vector or list to one dimension tibble'},
// {'component': <ConvertVectorOrListToTibble />, 'path': 'convert-vector-or-list-to-tibble', 'title': 'convert vector or list to tibble'},
{'component': <ConvertVectorListToTibble />, 'path': 'convert-vector-list-to-tibble', 'title': '5. Convert vector or list to two-column tibble'},
{'component': <ConvertTwoColumnsToVectorOrList />, 'path': 'convert-two-columns-to-vector-or-list', 'title': '6. Convert two columns to vector or list'},
{'component': <AddRowsToTibble />, 'path': 'add-rows-to-tibble', 'title': '7. Add rows to tibble'},
{'component': <AddColumnsToTibble />, 'path': 'add-columns-to-tibble', 'title': '8. Add columns to tibble'},
]

export default RtibbleData