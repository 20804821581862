import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrTrim(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Remove White Spaces from a String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_trim()</code></strong> is opposite to <Link to="../7-str-pad"><code>str_pad()</code></Link>, and removes the white spaces from a string. By default, white spaces at both the left and right side of the string will be removed. Internal spaces, however, are <em>not</em> touched.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"   french fries, "</span>, </span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>       <span className="st">"   BBQ    chicken,  "</span>,</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>       <span className="st">"and   lemonade.   "</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">str_trim</span>(x, <span className="at">side =</span> <span className="st">"both"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "french fries,"   "BBQ    chicken," "and   lemonade."</code></pre>
</div>
</div>
<p><strong><code>str_squish()</code></strong> not only removes white spaces at the two sides of a string, but also reduces internal consecutive white spaces into a single white space.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_squish</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "french fries," "BBQ chicken,"  "and lemonade."</code></pre>
</div>
</div>
</main>
</div>
</div>
)}