export default function Privacy() {
    return(
        <div className='main-container mg-t-100 mg-b-250'>
            <div className='row'>
                <div className='col-md-2'></div>
                <div className='col-md-8  mg-b-30'>
                    <div className="text-center">
                    <h1 class="title" style={{color:'grey'}}>Privacy Statement</h1>
                    </div>
                    
                <div>
                    <p>This Privacy Policy outlines how your personal information is collected, utilized, and safeguarded when you interact with or make purchases from <strong>DataBrewer</strong> (referred to as “the site,” “we,” or “us”).</p>
                    <p><strong>Cookies:</strong> A cookie is a small file comprising an identifier (a string of letters and numbers) transmitted by a web server to a browser and stored by the browser. Whenever the browser requests a page from the server, the identifier is sent back to the server. Cookies may be categorized as “persistent” or “session” cookies: persistent cookies remain stored by a web browser until their set expiry date unless deleted by the user beforehand, while session cookies expire at the end of the user session, upon closure of the web browser. Generally, cookies do not contain personally identifying information, but the personal data we retain about you may be associated with the information stored in and obtained from cookies.</p>
                    <p><strong>How We Use Cookies:</strong> When you visit our Site, cookies are generated and collected for the following purposes:</p>
                    <ul>
                    <li><p>Understanding your website usage patterns to improve our website and tailor our products to better serve your learning objectives.</p></li>
                    <li><p>Personalizing advertisements based on your interests.</p></li>
                    <li><p>Identifying and preventing fraudulent activities.</p></li>
                    </ul>
                    <p><strong>Third-Party Analytics:</strong> We utilize Google Analytics to analyze website usage. Google Analytics employs cookies to gather information about website usage. The collected data is utilized to generate reports regarding website usage trends. For more information, please refer to Google’s privacy policy available at: <a href="https://policies.google.com/privacy">Google Privacy Policy</a>. Most web browsers offer options to refuse cookie acceptance or delete cookies.</p>
                    <p>This Privacy Policy aims to provide clarity and transparency regarding the handling of your personal information. By accessing our website, you consent to the practices outlined herein. We reserve the right to amend this policy periodically. Any significant changes will be communicated through our website.</p>
                    <p>If you have any queries or concerns regarding our Privacy Policy, please contact us at <em>yuanbo.faith@gmail.com</em>.</p>

                </div>
                  
                </div>
                <div className='col-md-2'></div>
            </div>
         
            


        </div>
    )
}