import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarSleep from '../graphics/bars_reordered_sleeptime_completed.png'; 
import imgGgplot2BarSleepWebp from '../graphics/bars_reordered_sleeptime_completed.webp'; 
export default function Ggplot2BarSleep(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Barplot in Polar Coordinate with ggplot2 to Visualize Cars Engine Power</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarSleepWebp} />
    <img className="cover-img" src={imgGgplot2BarSleep} />
  </picture>

<p>In this work, we’ll create an ordered bar plot to visualize the relative length of sleeping time (normalized into z-score) of mammalian animals. <strong>Major techniques covered in this work include:</strong></p>
<ul>
<li><a href="#normalization_reorder">data normalization and bar reordering</a></li>
<li><a href="#text">text annotation with customized positions</a></li>
<li><a href="#theme">theme customization</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset used for visualization is built in base R.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">head</span>(msleep, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 11
<br/>  name    genus vore  order conservation sleep_total sleep_rem sleep_cycle awake
<br/>  &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt; &lt;chr&gt; &lt;chr&gt;              &lt;dbl&gt;     &lt;dbl&gt;       &lt;dbl&gt; &lt;dbl&gt;
<br/>1 Cheetah Acin… carni Carn… lc                  12.1      NA        NA      11.9
<br/>2 Owl mo… Aotus omni  Prim… &lt;NA&gt;                17         1.8      NA       7  
<br/>3 Mounta… Aplo… herbi Rode… nt                  14.4       2.4      NA       9.6
<br/>4 Greate… Blar… omni  Sori… lc                  14.9       2.3       0.133   9.1
<br/># ℹ 2 more variables: brainwt &lt;dbl&gt;, bodywt &lt;dbl&gt;</code></pre>
</div>
</div>
<p><span id="normalization_reorder">Normalize the sleep time into z-scores.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>msleep<span className="fl">.2</span> <span className="ot">&lt;-</span> msleep <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">sleep.z =</span> <span className="fu">scale</span>(sleep_total))</span></code></pre></div>
</div>
<p>Rearrange the rows based on sleeping time, and convert the <code>name</code> variable to a factor to “memorize” the current name order. This will correspondingly generate an ordered bar plot arranged by the <code>name</code> variable. Check this <Link to="../../R/visualization/4-ggplot2-reorder-bars"><strong>complete guide</strong></Link> to learn more techniques about reordering of graphic elements in ggplot2.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>msleep<span className="fl">.2</span> <span className="ot">&lt;-</span> msleep<span className="fl">.2</span> <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">arrange</span>(sleep_total) <span className="sc">%&gt;%</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">name =</span> <span className="fu">factor</span>(name, <span className="at">levels =</span> name))</span></code></pre></div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a flipped bar plot. After coordinate flip, the aesthetic mapping rule is unchanged: the horizontal axis (normalized sleep time) remains the aesthetic <code>y</code> axis, and the vertical axis (animal names) remains the aesthetic <code>x</code> axis.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> msleep<span className="fl">.2</span> <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> name, <span className="at">y =</span> sleep.z, <span className="at">fill =</span> <span className="fu">log10</span>(bodywt))) <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_col</span>() <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">coord_flip</span>() <span className="sc">+</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>)</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_reordered_sleeptime_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="text">Relabel the animal names near the base of the bars with <code>geom_text</code>. A key in this edit is to <span><strong>right</strong></span>-justify texts in the <span><strong>upper</strong></span> half of the plot, and <span><strong>left</strong></span>-justify texts in the <span><strong>lower</strong></span> half, using <strong><code>y</code>-aesthetic</strong> as the <strong>justification anchoring point</strong>. In this example, the bottom 45 labels are left-justified to y = .05, and the top 38 labels are right-justified to y = -0.05. In both vectors of <code>y</code> aesthetic and <code>hjust</code>, the values are sequentially applied to the animal names in a <strong>bottom-up</strong> direction. The resulted layout greatly improves readability, and the efficiency of plot space utilization.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> name, </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>        <span className="co"># the anchoring position</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>        <span className="at">y =</span> <span className="fu">c</span>(<span className="fu">rep</span>(.<span className="dv">05</span>, <span className="dv">45</span>), <span className="fu">rep</span>( <span className="sc">-</span>.<span className="dv">05</span>, <span className="dv">38</span>))),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="co"># left or right justification</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">45</span>), <span className="fu">rep</span>(<span className="dv">1</span>, <span className="dv">38</span>)), </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">2</span>) </span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_texts.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_reordered_sleeptime_texts"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="text">Adjust the color scale and the associated color bar.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># use viridis palette G</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">option =</span> <span className="st">"G"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>, </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">1</span>), </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>    <span className="co"># adjust the appearance of the color bar</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="at">guide =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>      <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>      <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>),</span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>      <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>      <span className="at">title =</span> <span className="st">"log10( body weight )"</span>))</span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_color_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_reordered_sleeptime_color_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="theme">A final touch-up.</span> In the <code>theme</code> syntax, horizontal axis (at the bottom or top of the plot) is always considered as x-axis, and vertical axis (left or right) as the y-axis, regardless of coordinate flip.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="co"># revise y axis title</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"Sleeping time (z-score) of Animals"</span>) <span className="sc">+</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>    <span className="co"># remove elements on the left-side axis</span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>    <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>    <span className="at">axis.ticks.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>    <span className="at">axis.line.y.left =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>    </span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    <span className="co"># modify elements on the top-side axis </span></span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">15</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_line</span>(), <span className="co"># for the top axis</span></span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a>    </span>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>    <span className="co"># increase margin around the plot</span></span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a>    <span className="co"># l: left; r, right; t, top; b, bottom</span></span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">l =</span> <span className="dv">30</span>, <span className="at">r =</span> <span className="dv">30</span>, <span className="at">t =</span> <span className="dv">20</span>, <span className="at">b =</span> <span className="dv">20</span>),</span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>    </span>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a>    <span className="at">legend.position =</span>  <span className="fu">c</span>(.<span className="dv">9</span>, .<span className="dv">5</span>),</span>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a>    </span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a>    <span className="co"># background</span></span>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a>    <span className="at">legend.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>)) <span className="sc">+</span></span>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a>  </span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a>  <span className="co"># move y axis (default on the left before flip) to the opposite (i.e., right) side of the plot</span></span>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a>  <span className="co"># after flip, the y-axis is positioned at the top of the plot</span></span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">position =</span> <span className="st">"right"</span>) <span className="sc">+</span></span>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a>  </span>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a>  <span className="co"># increase the margin between the top-axis and the bar plots</span></span>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">expand =</span> <span className="fu">expansion</span>(<span className="at">add =</span> <span className="dv">2</span>))</span>
<span id="cb8-36"><a aria-hidden="true" href="#cb8-36" tabindex="-1"></a></span><br/>
<span id="cb8-37"><a aria-hidden="true" href="#cb8-37" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_reordered_sleeptime_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"bars sleep time.pdf"</span>,</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">8</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>msleep<span className="fl">.2</span> <span className="ot">&lt;-</span> msleep <span className="sc">%&gt;%</span> </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="co"># normalize the sleeping time to z-score</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">sleep.z =</span> <span className="fu">scale</span>(sleep_total)) <span className="sc">%&gt;%</span> </span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>  <span className="co"># reorder the bar (animals) by sleeping time</span></span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>  <span className="fu">arrange</span>(sleep_total) <span className="sc">%&gt;%</span> </span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">name =</span> <span className="fu">factor</span>(name, <span className="at">levels =</span> name))</span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a></span><br/>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a></span><br/>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a><span className="co"># Create a flipped bar plot.</span></span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> msleep<span className="fl">.2</span> <span className="sc">%&gt;%</span> </span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> name, <span className="at">y =</span> sleep.z, <span className="at">fill =</span> <span className="fu">log10</span>(bodywt))) <span className="sc">+</span></span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>  <span className="fu">geom_col</span>() <span className="sc">+</span></span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>  <span className="fu">coord_flip</span>() <span className="sc">+</span></span>
<span id="cb10-17"><a aria-hidden="true" href="#cb10-17" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>)</span>
<span id="cb10-18"><a aria-hidden="true" href="#cb10-18" tabindex="-1"></a>p1</span>
<span id="cb10-19"><a aria-hidden="true" href="#cb10-19" tabindex="-1"></a></span><br/>
<span id="cb10-20"><a aria-hidden="true" href="#cb10-20" tabindex="-1"></a></span><br/>
<span id="cb10-21"><a aria-hidden="true" href="#cb10-21" tabindex="-1"></a><span className="co"># Relabel the animal names near the base of the bars.</span></span>
<span id="cb10-22"><a aria-hidden="true" href="#cb10-22" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb10-23"><a aria-hidden="true" href="#cb10-23" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb10-24"><a aria-hidden="true" href="#cb10-24" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> name, </span>
<span id="cb10-25"><a aria-hidden="true" href="#cb10-25" tabindex="-1"></a>        <span className="co"># the anchoring position</span></span>
<span id="cb10-26"><a aria-hidden="true" href="#cb10-26" tabindex="-1"></a>        <span className="at">y =</span> <span className="fu">c</span>(<span className="fu">rep</span>(.<span className="dv">05</span>, <span className="dv">45</span>), <span className="fu">rep</span>( <span className="sc">-</span>.<span className="dv">05</span>, <span className="dv">38</span>))),</span>
<span id="cb10-27"><a aria-hidden="true" href="#cb10-27" tabindex="-1"></a>    <span className="co"># justification direction</span></span>
<span id="cb10-28"><a aria-hidden="true" href="#cb10-28" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">45</span>), <span className="fu">rep</span>(<span className="dv">1</span>, <span className="dv">38</span>)), </span>
<span id="cb10-29"><a aria-hidden="true" href="#cb10-29" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">2</span>) </span>
<span id="cb10-30"><a aria-hidden="true" href="#cb10-30" tabindex="-1"></a>p2</span>
<span id="cb10-31"><a aria-hidden="true" href="#cb10-31" tabindex="-1"></a></span><br/>
<span id="cb10-32"><a aria-hidden="true" href="#cb10-32" tabindex="-1"></a></span><br/>
<span id="cb10-33"><a aria-hidden="true" href="#cb10-33" tabindex="-1"></a><span className="co"># Adjust the color scale and the associated legend color bar. </span></span>
<span id="cb10-34"><a aria-hidden="true" href="#cb10-34" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb10-35"><a aria-hidden="true" href="#cb10-35" tabindex="-1"></a>  <span className="co"># use viridis palette G</span></span>
<span id="cb10-36"><a aria-hidden="true" href="#cb10-36" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(</span>
<span id="cb10-37"><a aria-hidden="true" href="#cb10-37" tabindex="-1"></a>    <span className="at">option =</span> <span className="st">"G"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>, </span>
<span id="cb10-38"><a aria-hidden="true" href="#cb10-38" tabindex="-1"></a>    <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">1</span>), </span>
<span id="cb10-39"><a aria-hidden="true" href="#cb10-39" tabindex="-1"></a>    </span>
<span id="cb10-40"><a aria-hidden="true" href="#cb10-40" tabindex="-1"></a>    <span className="co"># adjust the appearance of the color bar</span></span>
<span id="cb10-41"><a aria-hidden="true" href="#cb10-41" tabindex="-1"></a>    <span className="at">guide =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb10-42"><a aria-hidden="true" href="#cb10-42" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb10-43"><a aria-hidden="true" href="#cb10-43" tabindex="-1"></a>      <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb10-44"><a aria-hidden="true" href="#cb10-44" tabindex="-1"></a>      <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>),</span>
<span id="cb10-45"><a aria-hidden="true" href="#cb10-45" tabindex="-1"></a>      <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb10-46"><a aria-hidden="true" href="#cb10-46" tabindex="-1"></a>      <span className="at">title =</span> <span className="st">"log10( body weight )"</span>))</span>
<span id="cb10-47"><a aria-hidden="true" href="#cb10-47" tabindex="-1"></a>p3</span>
<span id="cb10-48"><a aria-hidden="true" href="#cb10-48" tabindex="-1"></a></span><br/>
<span id="cb10-49"><a aria-hidden="true" href="#cb10-49" tabindex="-1"></a></span><br/>
<span id="cb10-50"><a aria-hidden="true" href="#cb10-50" tabindex="-1"></a><span className="co"># Theme customization</span></span>
<span id="cb10-51"><a aria-hidden="true" href="#cb10-51" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb10-52"><a aria-hidden="true" href="#cb10-52" tabindex="-1"></a>  <span className="co"># revise y axis title</span></span>
<span id="cb10-53"><a aria-hidden="true" href="#cb10-53" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"Sleeping time (z-score) of Animals"</span>) <span className="sc">+</span></span>
<span id="cb10-54"><a aria-hidden="true" href="#cb10-54" tabindex="-1"></a>  </span>
<span id="cb10-55"><a aria-hidden="true" href="#cb10-55" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb10-56"><a aria-hidden="true" href="#cb10-56" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb10-57"><a aria-hidden="true" href="#cb10-57" tabindex="-1"></a>    <span className="co"># remove elements on the left-side axis</span></span>
<span id="cb10-58"><a aria-hidden="true" href="#cb10-58" tabindex="-1"></a>    <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-59"><a aria-hidden="true" href="#cb10-59" tabindex="-1"></a>    <span className="at">axis.ticks.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-60"><a aria-hidden="true" href="#cb10-60" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-61"><a aria-hidden="true" href="#cb10-61" tabindex="-1"></a>    <span className="at">axis.line.y.left =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-62"><a aria-hidden="true" href="#cb10-62" tabindex="-1"></a>    </span>
<span id="cb10-63"><a aria-hidden="true" href="#cb10-63" tabindex="-1"></a>    <span className="co"># modify elements on the top-side axis </span></span>
<span id="cb10-64"><a aria-hidden="true" href="#cb10-64" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb10-65"><a aria-hidden="true" href="#cb10-65" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">15</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb10-66"><a aria-hidden="true" href="#cb10-66" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_line</span>(), <span className="co"># for the top axis</span></span>
<span id="cb10-67"><a aria-hidden="true" href="#cb10-67" tabindex="-1"></a>    </span>
<span id="cb10-68"><a aria-hidden="true" href="#cb10-68" tabindex="-1"></a>    <span className="co"># increase margin around the plot</span></span>
<span id="cb10-69"><a aria-hidden="true" href="#cb10-69" tabindex="-1"></a>    <span className="co"># l: left; r, right; t, top; b, bottom</span></span>
<span id="cb10-70"><a aria-hidden="true" href="#cb10-70" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">l =</span> <span className="dv">30</span>, <span className="at">r =</span> <span className="dv">30</span>, <span className="at">t =</span> <span className="dv">20</span>, <span className="at">b =</span> <span className="dv">20</span>),</span>
<span id="cb10-71"><a aria-hidden="true" href="#cb10-71" tabindex="-1"></a>    </span>
<span id="cb10-72"><a aria-hidden="true" href="#cb10-72" tabindex="-1"></a>    <span className="at">legend.position =</span>  <span className="fu">c</span>(.<span className="dv">9</span>, .<span className="dv">5</span>),</span>
<span id="cb10-73"><a aria-hidden="true" href="#cb10-73" tabindex="-1"></a>    </span>
<span id="cb10-74"><a aria-hidden="true" href="#cb10-74" tabindex="-1"></a>    <span className="co"># background</span></span>
<span id="cb10-75"><a aria-hidden="true" href="#cb10-75" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-76"><a aria-hidden="true" href="#cb10-76" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb10-77"><a aria-hidden="true" href="#cb10-77" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb10-78"><a aria-hidden="true" href="#cb10-78" tabindex="-1"></a>    <span className="at">legend.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>)) <span className="sc">+</span></span>
<span id="cb10-79"><a aria-hidden="true" href="#cb10-79" tabindex="-1"></a>  </span>
<span id="cb10-80"><a aria-hidden="true" href="#cb10-80" tabindex="-1"></a>  <span className="co"># move y axis (default on the left before flip) to the opposite (i.e., right) side of the plot</span></span>
<span id="cb10-81"><a aria-hidden="true" href="#cb10-81" tabindex="-1"></a>  <span className="co"># after flip, the y-axis is positioned at the top of the plot</span></span>
<span id="cb10-82"><a aria-hidden="true" href="#cb10-82" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">position =</span> <span className="st">"right"</span>) <span className="sc">+</span></span>
<span id="cb10-83"><a aria-hidden="true" href="#cb10-83" tabindex="-1"></a>  </span>
<span id="cb10-84"><a aria-hidden="true" href="#cb10-84" tabindex="-1"></a>  <span className="co"># increase the margin between the top-axis and the bar plots</span></span>
<span id="cb10-85"><a aria-hidden="true" href="#cb10-85" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">expand =</span> <span className="fu">expansion</span>(<span className="at">add =</span> <span className="dv">2</span>))</span>
<span id="cb10-86"><a aria-hidden="true" href="#cb10-86" tabindex="-1"></a></span><br/>
<span id="cb10-87"><a aria-hidden="true" href="#cb10-87" tabindex="-1"></a>p4</span>
<span id="cb10-88"><a aria-hidden="true" href="#cb10-88" tabindex="-1"></a></span><br/>
<span id="cb10-89"><a aria-hidden="true" href="#cb10-89" tabindex="-1"></a></span><br/>
<span id="cb10-90"><a aria-hidden="true" href="#cb10-90" tabindex="-1"></a><span className="co"># Save the plot.</span></span>
<span id="cb10-91"><a aria-hidden="true" href="#cb10-91" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"bars sleep time.pdf"</span>,</span>
<span id="cb10-92"><a aria-hidden="true" href="#cb10-92" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># using a relative path, save to the "graphics" folder</span></span>
<span id="cb10-93"><a aria-hidden="true" href="#cb10-93" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">8</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In the reordered bar plot above, relabeling the animal names (the x-axis labels) at the base of the bars greatly improves plot readability and space utilization efficiency. The following <strong><Link to="../ggplot2-geofacet-US-states">faceted donut plots in a U.S. map layout</Link></strong> is another excellent example of <strong>text position refinement</strong>: relocating the U.S. state names (faceted subplot titles) to the inside of donuts not only renders a more concise outlook, but also frees more space to further enlarge the donut circles.</p>
<p><Link to="../ggplot2-geofacet-US-states">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Proper reordering of graphic elements is one of the most used and powerful techniques in data visualization, as illustrated above in the bar plot of animals’ sleeping time. Besides, check the following amazing <Link to="../ggplot2-area-plot-migration"><strong>stacked area / alluvial plot</strong></Link>, where ribbons of different countries are carefully reordered and clustered, such that gradient colors can be leveraged to differentiate countries and continents.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}