import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2WafflePlotWealthDistribution from '../graphics/waffle_plot_wealth_completed.png'; 
import imgGgplot2WafflePlotWealthDistributionWebp from '../graphics/waffle_plot_wealth_completed.webp'; 
export default function Ggplot2WafflePlotWealthDistribution(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Waffle Plot in ggplot2 to Visualize U.S. Wealth Distribution</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2WafflePlotWealthDistributionWebp} />
    <img className="cover-img" src={imgGgplot2WafflePlotWealthDistribution} />
  </picture>

<p>A waffle plot, also known as a square pie chart, or grid plot, resembles a grid of squares, where each square represents a certain portion of the whole dataset. It is a vivid approach to display data distribution. In this article, we’ll create a waffle plot to visualize the wealth distribution in the U.S. population.</p>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) <span className="co"># use the "happy" dataset of this package</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr) <span className="co"># for string manipulation</span></span></code></pre></div>
</div>
<p>Calculate the number of people in each wealth category.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> <span className="fu">select</span>(finrela)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a></span><br/>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a><span className="co"># remove rows containing NA values</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>h <span className="ot">&lt;-</span> h[<span className="fu">complete.cases</span>(h), ] </span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a></span><br/>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a><span className="co"># summarize the number of people in each wealth category</span></span>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a>counts <span className="ot">&lt;-</span> h<span className="sc">$</span>finrela <span className="sc">%&gt;%</span> <span className="fu">table</span>()</span></code></pre></div>
</div>
<p>Calculate the head counts for each wealth category <em>normalized</em> to a total of 100 people.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>nrows <span className="ot">&lt;-</span> <span className="dv">10</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>counts.normalized <span className="ot">&lt;-</span> <span className="fu">round</span>( counts <span className="sc">*</span> (nrows<span className="sc">^</span><span className="dv">2</span> <span className="sc">/</span> <span className="fu">sum</span>(counts)) )</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="co"># check to make sure the sum of counts after rounding is 100 </span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a><span className="fu">sum</span>(counts.normalized)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 100</code></pre>
</div>
</div>
<p>Create a tidy dataset containing 100 people’s wealth condition. In the <code>expand.grid</code> function, we can also swap the x and y position, i.e., using <code>expand.grid(x = 1:nrows, y = 1:nrows)</code>. This would otherwise create a <em>transposed</em> Waffle plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">expand.grid</span>(<span className="at">y =</span> <span className="dv">1</span><span className="sc">:</span>nrows, <span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span>nrows)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">wealth =</span> <span className="fu">rep</span>(<span className="fu">names</span>(counts.normalized), counts.normalized))</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a></span><br/>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  y x            wealth
<br/>1 1 1 far below average
<br/>2 2 1 far below average
<br/>3 3 1 far below average
<br/>4 4 1 far below average
<br/>5 5 1 far below average</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a heatmap representing the financial status of each person in the 10 x 10 grid.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># plot</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> x , <span className="at">y =</span> y, <span className="at">fill =</span> wealth)) <span className="sc">+</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">geom_tile</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) <span className="sc">+</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Pastel1"</span>) <span className="sc">+</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"Wealth distribution in US"</span>) <span className="sc">+</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="fu">theme</span>( <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>)) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/waffle_plot_wealth_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/waffle_plot_wealth_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>In addition to <code>geom_tile</code>, <code>geom_raster</code> is a high-performance alternative to generate heatmaps when each cell is of the same size, but does not have the <code>color</code> argument, and does not draw cell outlines.</p>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) <span className="co"># use the "happy" dataset of this package</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a><span className="fu">library</span>(stringr) <span className="co"># for string manipulation</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a></span><br/>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a><span className="co"># Calculate the number of people in each wealth category. </span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> <span className="fu">select</span>(finrela)</span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a></span><br/>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a><span className="co"># remove rows containing NA values</span></span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>h <span className="ot">&lt;-</span> h[<span className="fu">complete.cases</span>(h), ] </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a></span><br/>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a><span className="co"># summarize the number of people in each wealth category</span></span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>counts <span className="ot">&lt;-</span> h<span className="sc">$</span>finrela <span className="sc">%&gt;%</span> <span className="fu">table</span>()</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a></span><br/>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a><span className="co"># Calculate the head counts for each wealth category normalized to a total of 100 people.</span></span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a>nrows <span className="ot">&lt;-</span> <span className="dv">10</span></span>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>counts.normalized <span className="ot">&lt;-</span> <span className="fu">round</span>( counts <span className="sc">*</span> (nrows<span className="sc">^</span><span className="dv">2</span> <span className="sc">/</span> <span className="fu">sum</span>(counts)) )</span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a></span><br/>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a><span className="co"># check to make sure the sum of counts after rounding is 100 </span></span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a><span className="fu">sum</span>(counts.normalized)</span>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a></span><br/>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a><span className="co"># Create a tidy dataset containing 100 people's wealth condition.</span></span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">expand.grid</span>(<span className="at">y =</span> <span className="dv">1</span><span className="sc">:</span>nrows, <span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span>nrows)</span>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">wealth =</span> <span className="fu">rep</span>(<span className="fu">names</span>(counts.normalized), counts.normalized))</span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a></span><br/>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a><span className="co"># Create a heatmap representing the financial status of each person in the 10 x 10 grid. </span></span>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a>d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> x , <span className="at">y =</span> y, <span className="at">fill =</span> wealth)) <span className="sc">+</span></span>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a>  <span className="fu">geom_tile</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) <span className="sc">+</span></span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Pastel1"</span>) <span className="sc">+</span></span>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"Wealth distribution in US"</span>) <span className="sc">+</span></span>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a>  <span className="fu">theme</span>( <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>)) </span></code></pre></div>
</div>
</div>
</div>
</div>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Now that you know how to create a Waffle grid, check out the following article on how to <Link to="../ggplot2-Waffle-plot-Chernoff-faces-wealth"><strong>create Emoji faces in a Waffle grid</strong></Link> using the <Link to="https://github.com/Selbosh/ggChernoff">ggChernoff</Link> package.</p>
<p><Link to="../ggplot2-Waffle-plot-Chernoff-faces-wealth">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_waffle_happiness_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_waffle_happiness_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>The above Waffle plots showed the <em>univariate</em> distribution of wealth. As one step further, we can use the <strong>mosaic plot</strong> to visualize the <strong>bivariate</strong> distribution of two variables, such as wealth and health. Check out the following article below on how to <Link to="../ggplot2-mosaic-health-wealth"><strong>create a mosaic plot to visualize the population composition at varied levels of wealth and health status</strong></Link>.</p>
<p><Link to="../ggplot2-mosaic-health-wealth">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}