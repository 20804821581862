import React from 'react';
import {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Adsggplot } from "../Ads";
import Card from '../pages/CardSection'
import imgDplyr from '../RDataWrangling/dplyr/dplyr_graphics/logo_dplyr.png'
import imgStringr from '../RDataWrangling/stringr/stringr_graphics/logo_stringr.png'
import imgRegex from '../RDataWrangling/regular-expression/regular-expression-graphics/logo_regular_expression.png'
import imgTidyr from '../RDataWrangling/tidyr/tidyr_graphics/logo_tidyr.png'
import imgPurrr from '../RDataWrangling/purrr/purrr_graphics/logo_purrr.png'
import imgTibble from '../RDataWrangling/tibble/tibble_graphics/logo_tibble.png'

export default function RDataWranglingLandingPage() { 
    const dplyr_description='Efficient dataset manipulation' 
    const strinr_desc = 'Manipulate strings'
    const regex_desc = 'Match a pattern'
    const tidyr_desc = 'Pivot dataset to the tidy structure'
    const purrr_desc = 'Functional programming'
    const tibble_desc = 'Moderen reimage of the dataframe'


    document.title = 'Data Wrangling with R'
    const description = 'Learn mostly used R packages for data wrangling: dplyr, stringr and regular expression'
    var existingMetaTag = document.querySelector('meta[name="description"]');
            
    if (existingMetaTag) {
        // If it exists, update its content
        existingMetaTag.setAttribute('content', description);
    } else {
        // If it doesn't exist, create and append it
        var metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'description');
        metaTag.setAttribute('content', description);
        document.head.appendChild(metaTag);
    }
    return (
        <div className='main'>
        <div classname="row">
            {/* <div className="col-md-2 col-sm-12" style={{padding:0}}>
             
            </div> */}
            <div className="col-md-12 col-sm-12 p-l-md-50">
                <div className="row">
                    <div className="col-md-10 p-l-md-100">
                        {/* <div className='ads-top'>
                            Save this space for Ads                        
                        </div> */}
                        
                        <div className="main-container p-r-md-100">
                            <h1 className='fc-darkBlue fontfamily-freeman text-center mg-t-20'>Data Wrangling with R </h1>
                            <div className='card-container mg-t-50'>      
                                {/*dplyr  */}
                                <Card path={'../../R/data-wrangling/dplyr/0-introduction'} img={imgDplyr} description={dplyr_description} color='pink' />
                                {/* string4 */}
                                <div className="card-section flex-width-large-3">
                                    <Link className='a-black' to='../../R/data-wrangling/stringr/0-introduction'>
                                        <div className="">
                                            <div className={`card-image card-block-blue flexCenter card-logo-size`}>
                                                <img src={imgStringr} className='img-size width-170'></img>
                                            </div>  
                                            <div className="p-20 text-center">
                                                <p className="fs-20">{strinr_desc}</p>
                                            </div>          
                                        
                                        </div>                
                                    </Link>
                                </div>  
                                {/* regex */}
                                <div className="card-section flex-width-large-3">
                                    <Link className='a-black' to='../../R/data-wrangling/regular-expression/0-introduction'>
                                        <div className="">
                                            <div className={`card-image card-block-yellow flexCenter card-logo-size`}>
                                                <img src={imgRegex} className='img-size width-250'></img>
                                            </div>  
                                            <div className="p-20 text-center">
                                                <p className="fs-20">{regex_desc}</p>
                                            </div>                                             
                                        </div>                
                                    </Link>
                                </div>  
                                
                            </div>
                            <div className='card-container mg-t-50'>
                                <div className="card-section flex-width-large-3">
                                    <Link className='a-black' to='../../R/data-wrangling/tidyr/introduction'>
                                        <div className="">
                                            <div className={`card-image card-block-blue flexCenter card-logo-size`}>
                                                <img src={imgTidyr} className='img-size'></img>
                                            </div>  
                                            <div className="p-20 text-center">
                                                <p className="fs-20">{tidyr_desc}</p>
                                            </div>          
                                        
                                        </div>                
                                    </Link>
                                </div> 
                                <div className="card-section flex-width-large-3">
                                    <Link className='a-black' to='../../R/data-wrangling/purrr/introduction'>
                                        <div className="">
                                            <div className={`card-image card-block-grey flexCenter card-logo-size`}>
                                                <img src={imgPurrr} className='img-size'></img>
                                            </div>  
                                            <div className="p-20 text-center">
                                                <p className="fs-20">{purrr_desc}</p>
                                            </div>          
                                        
                                        </div>                
                                    </Link>
                                </div>   
                                <div className="card-section flex-width-large-3">
                                    <Link className='a-black' to='../../R/data-wrangling/tibble/introduction'>
                                        <div className="">
                                            <div className={`card-image card-block-pink flexCenter card-logo-size`}>
                                                <img src={imgTibble} className='img-size width-170'></img>
                                            </div>  
                                            <div className="p-20 text-center">
                                                <p className="fs-20">{tibble_desc}</p>
                                            </div>          
                                        
                                        </div>                
                                    </Link>
                                </div>   
                            </div>
                            
                            
                        </div>
                        <div className='ads-mid'>
                            {/* Save this space for Ads */}
        
                        </div>

                    </div>
                    <div className='col-md-2'>
                        {/* Save this space for Ads */}
                        <Adsggplot />   
                    </div>


                </div>
                
            </div>
           
        </div>

    </div>

   
       
        
    )
}