import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
import {Link} from "react-router-dom"
export default function PythonPythonbasicslandingpage(){
useCustomEffect()
return ( 
<div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div>
<div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 class="posts-heading">Welcome to the Python 101 Crash Course!</h3>
<p>Embarking on a journey to learn a new programming language can often feel like navigating through a maze of uncertainty. It's perfectly normal to feel a bit overwhelmed at the beginning. But fear not! Before we delve into the nitty-gritty details, let's take a step back and get a bird's-eye view of what lies ahead, so you can approach it with clarity and confidence.</p>
<p>Take a moment to glance at the structure below; it outlines the 8 key topics waiting to be explored. And trust me, it's not as daunting as it may seem. By dedicating just 1 to 2 hours a day to each topic, you can complete this tutorial in a mere 2 to 3 weeks. Once you've mastered these foundational concepts, you'll be equipped to venture into the exciting realms of Python, from web development to data science, and to data engineering.</p>
<p>If you're still uncertain about Python's capabilities and how it can enhance your work, you can check our <Link to="../introduction-to-python">Introduction to Python</Link> page to learn more about it.  It's a valuable resource that addresses some of the most common inquiries about Python.</p>
<p>Remember, taking the first step is always the most challenging part of any journey. But once you've taken that leap, each subsequent stride becomes more natural and exhilarating. So, without further ado, let's dive into your first Python lesson and take that first step forward!</p>
<h4 id="8-Python-Key-Topics">8 Key Topics in Python<a class="anchor-link" href="#8-Python-Key-Topics">¶</a></h4>
<ul>
    <Link to='../variables'><li>Data Types and Variables</li></Link>
    <Link to='../data-structure-lists'><li>Data Structures</li></Link>
    <Link to='../arithmetic-operations'><li>Basic Operations</li></Link>
    <Link to='../python-if-else'><li>Conditional statements</li></Link>
    <Link to='../python-for-loop'><li>Loops</li></Link>
    <Link to='../function-definition-and-parameters'><li>Functions</li></Link>
    <Link to='../python-modules-and-libraries'><li>Modules and Libraries</li></Link>
    <Link to='../python-classes-and-objects'><li>Object-Oriented Programming (OOP)</li></Link>
</ul>
</div>
</div>
</div>
</div>
</div>
)}