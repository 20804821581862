import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Introduction(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Text Manipulation in R with <strong>stringr</strong></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In R, a <strong>string</strong> is a sequence of quoted characters. It is used to represent text data. It is created by enclosing a sequence of characters in either single quotes, e.g., <code>'This is a string.'</code>, or a double quote, e.g., <code>"This is also a string."</code>. When handling strings, <code>stringr</code> is a powerful tool, and is also a core package in the tidyverse collection.</p>
<section className="level3" id="installation">
<h3 className="anchored" data-anchor-id="installation">Installation</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"stringr"</span>)</span></code></pre></div>
</div>
<p>Alternatively, we can install all the <code>tidyverse</code> packages including <code>stringr</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"tidyverse"</span>)</span></code></pre></div>
</div>
<p><br/></p>
<p>In the following sections, let’s dive into a variety of functions that will cover a range of common string operations.</p>
</section>
</main>
</div>
</div>
)}