import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonMergingdataframes(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Merge-DateFrames">Merge DateFrames<a class="anchor-link" href="#Merge-DateFrames">¶</a></h3><p>In real life, the data we need often comes from different places like databases or files. Thus, it requires us to combine various data into a single table before preceeding with analysis. In this lesson, we'll explore how to combine two dataframes based on columns.<br/>
<br />In pandas, you can use both the <code>merge()</code> and <code>join()</code> functions to merge DataFrames, but they have different behaviors. <code>merge()</code> is a more versatile and powerful method that provides finer control when combining DataFrames, whereas <code>join()</code> is simpler. In this tutorial, we'll focus on using the <code>merge()</code> function because of its flexibility. <br/> <br/>
To demonstrate how to merge DataFrames using the <code>merge()</code> function, we'll create two example dataframes.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-dataframes">Create dataframes<a class="anchor-link" href="#Create-dataframes">¶</a></h4><p>We'll create two dataframes: one named <strong>df_restaurants</strong>, containing restaurant IDs and their respective locations; and another dataframe named <strong>df_ratings</strong>, containing restaurant IDs and their corresponding ratings.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">import</span> <span class="nn">pandas</span> <span class="k">as</span> <span class="nn">pd</span></span>


<br /><span><span class="n">restaurants</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'id'</span><span class="p">:</span> <span class="p">[</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">,</span> <span class="mi">4</span><span class="p">]</span>,</span>

<span>               <span class="s1">'city'</span><span class="p">:</span> <span class="p">[</span><span class="s1">'NY'</span><span class="p">,</span> <span class="s1">'LA'</span><span class="p">,</span> <span class="s1">'SF'</span><span class="p">,</span> <span class="s1">'Boston'</span><span class="p"></span>]</span>

<span>                <span class="p"></span>&#125;</span>

<span><span class="n">df_restaurants</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">DataFrame</span><span class="p">(</span><span class="n">restaurants</span><span class="p"></span>)</span>

<span><span class="n">df_restaurant</span>s</span> 

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>id</th>
<th>city</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>NY</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>LA</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>SF</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>Boston</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">ratings</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'id'</span><span class="p">:</span> <span class="p">[</span><span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">,</span> <span class="mi">4</span><span class="p">,</span> <span class="mi">5</span><span class="p">],</span></span>

<span>           <span class="s1">'rating'</span><span class="p">:</span> <span class="p">[</span><span class="mf">4.2</span><span class="p">,</span> <span class="mf">4.5</span><span class="p">,</span> <span class="mf">4.0</span><span class="p">,</span> <span class="mf">4.1</span><span class="p"></span>]</span>

<span>          <span class="p"></span>&#125;</span>

<span><span class="n">df_ratings</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">DataFrame</span><span class="p">(</span><span class="n">ratings</span><span class="p"></span>)</span>

<span><span class="n">df_rating</span>s</span> 

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>id</th>
<th>rating</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>2</td>
<td>4.2</td>
</tr>
<tr>
<th>1</th>
<td>3</td>
<td>4.5</td>
</tr>
<tr>
<th>2</th>
<td>4</td>
<td>4.0</td>
</tr>
<tr>
<th>3</th>
<td>5</td>
<td>4.1</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Merge-two-dataframes">Merge two dataframes<a class="anchor-link" href="#Merge-two-dataframes">¶</a></h4><p>The next step is to use the <code>merge()</code> function to merge <strong>df_restaurants</strong> and <strong>df_ratings</strong> based on their common restaurant IDs. This merging process will create a single table including both the restaurant's location and its corresponding rating information.<br/>
<br />Here is the syntax for the <code>merge()</code> function: <br/><br/>
<code>df_left.merge(df_right, how='inner', on=None)</code></p>
<ul>
<li><strong>df_left</strong> and <strong>df_right</strong> are the two DataFrames that you want to join. You can place them in any order, but it's common to put the dataframe with the main information on the left.  </li>
<li><strong>how</strong>: the default value is <code>inner</code>, showing only the matching records. Other options include "outer", "left" and "right". Examples of each option is provided below.</li>
<li><strong>on</strong>: this parameter specifies the columns to use for joining, and these columns must exist in both dataframes. If the joining columns have different names in the two DataFrames, you can use the <strong>left_on</strong> and <strong>right_on</strong> parameters to specify the respective column names.</li>
<li><strong>left_on</strong>: use this parameter to specify the column names to join on in the left DataFrame.</li>
<li><strong>right_on</strong>: use this parameter to specify the column names to join on in the right DataFrame.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example:-Inner-join">Example: Inner join<a class="anchor-link" href="#Example:-Inner-join">¶</a></h5><p>An inner join displays records that exist in both DataFrames. In our example, only restaurants 2, 3 and 4 are found in both location and rating tables.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df_restaurants</span><span class="o">.</span><span class="n">merge</span><span class="p">(</span><span class="n">df_ratings</span><span class="p">,</span> <span class="n">on</span><span class="o">=</span><span class="s1">'id'</span><span class="p">,</span> <span class="n">how</span><span class="o">=</span><span class="s1">'inner'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>id</th>
<th>city</th>
<th>rating</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>2</td>
<td>LA</td>
<td>4.2</td>
</tr>
<tr>
<th>1</th>
<td>3</td>
<td>SF</td>
<td>4.5</td>
</tr>
<tr>
<th>2</th>
<td>4</td>
<td>Boston</td>
<td>4.0</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example:-outer-join">Example: outer join<a class="anchor-link" href="#Example:-outer-join">¶</a></h5><p>An outer join combines data from two dataframes and displays all records, filling in missing values with <em>NaN</em>.
In our example, restaurant 1 doesn't have a matching entry in the rating table, so its rating is displayed as <em>NaN</em>. Similarly, restaurant 5 doesn't appear in the location table, resulting in a <em>NaN</em> value for its location.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df_restaurants</span><span class="o">.</span><span class="n">merge</span><span class="p">(</span><span class="n">df_ratings</span><span class="p">,</span> <span class="n">on</span><span class="o">=</span><span class="s1">'id'</span><span class="p">,</span> <span class="n">how</span><span class="o">=</span><span class="s1">'outer'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>id</th>
<th>city</th>
<th>rating</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>NY</td>
<td>NaN</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>LA</td>
<td>4.2</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>SF</td>
<td>4.5</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>Boston</td>
<td>4.0</td>
</tr>
<tr>
<th>4</th>
<td>5</td>
<td>NaN</td>
<td>4.1</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example:-left-join">Example: left join<a class="anchor-link" href="#Example:-left-join">¶</a></h5><p>A left join displays all the records from the left DataFrame and includes only the matching records from the right DataFrame. In this specific case, the merged DataFrame will contain information about restaurants 1 to 4.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df_restaurants</span><span class="o">.</span><span class="n">merge</span><span class="p">(</span><span class="n">df_ratings</span><span class="p">,</span> <span class="n">on</span><span class="o">=</span><span class="s1">'id'</span><span class="p">,</span> <span class="n">how</span><span class="o">=</span><span class="s1">'left'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>id</th>
<th>city</th>
<th>rating</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>NY</td>
<td>NaN</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>LA</td>
<td>4.2</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>SF</td>
<td>4.5</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>Boston</td>
<td>4.0</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example:-right-join">Example: right join<a class="anchor-link" href="#Example:-right-join">¶</a></h5><p>A right join displays all the records from the right DataFrame while including only the matching records from the left DataFrame. Therefore, in the merged DataFrame, you will find restaurants 2 to 5.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df_restaurants</span><span class="o">.</span><span class="n">merge</span><span class="p">(</span><span class="n">df_ratings</span><span class="p">,</span> <span class="n">on</span><span class="o">=</span><span class="s1">'id'</span><span class="p">,</span> <span class="n">how</span><span class="o">=</span><span class="s1">'right'</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>id</th>
<th>city</th>
<th>rating</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>2</td>
<td>LA</td>
<td>4.2</td>
</tr>
<tr>
<th>1</th>
<td>3</td>
<td>SF</td>
<td>4.5</td>
</tr>
<tr>
<th>2</th>
<td>4</td>
<td>Boston</td>
<td>4.0</td>
</tr>
<tr>
<th>3</th>
<td>5</td>
<td>NaN</td>
<td>4.1</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Excellent! In this tutorial, we have explored various methods for merging DataFrames. In our next tutorial, we will delve into an alternative approach to combine DataFrames that does not rely on having common columns. Please stay tuned for more insights!</p>
</div>
</div>
</div>
</div>
</div>
)}