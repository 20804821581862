import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Pack(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Pack Columns into a Dataframe-Column, and Unpack a Dataframe-Column into Separate Columns</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>pack()</code> mimics the nested column headers popularly used in Excel, and clusters multiple columns into a single dataframe-column. <code>unpack()</code> is the reverse procedure, and turns the single packed data-frame column into separate columns.</p>
<p><code>pack()</code> is barely used in practice, while <code>unpack()</code> is generally a more useful function, as it turns complicated nested structure into simple one. We’ll first discuss the basics of the two functions, and then demonstrate the use of <code>unpack()</code> in the context of a <Link to="/R/data-wrangling/regular-expression/8-capture-group">capture group</Link>.</p>
<p>This tutorial covers the following content:</p>
<ul>
<li><a href="#pack"><strong><code>pack()</code></strong>: pack multiple columns into a single dataframe-column</a></li>
<li><a href="#unpack"><strong><code>unpack()</code></strong>: unpack a dataframe-column into separate columns</a></li>
<li><a href="#capture_group_unpack">Demo: use <code>unpack()</code> with <strong>capture groups</strong></a></li>
</ul>
<hr/>
<section className="level3" id="pack">
<h3 className="anchored" data-anchor-id="pack">Pack multiple columns into a single dataframe-column</h3>
<p>In this example, the columns <code>Sepal.Length</code> and <code>Sepal.Width</code> are packed into a single dataframe-column <code>Sepal</code>, and <code>Petal.Length</code> and <code>Petal.Width</code> are packed into <code>Petal</code>. <Link to="/R/data-wrangling/dplyr/2-select-columns#selection_helpers">Selection helpers</Link>, e.g., <code>starts_with()</code>, can be used to select a range of columns to be packed up.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="fu">pack</span>(<span className="at">Sepal =</span> <span className="fu">starts_with</span>(<span className="st">"Sepal"</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>       <span className="at">Petal =</span> <span className="fu">starts_with</span>(<span className="st">"Petal"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 150 × 3
<br/>  Species Sepal$Sepal.Length $Sepal.Width Petal$Petal.Length $Petal.Width
<br/>  &lt;fct&gt;                &lt;dbl&gt;        &lt;dbl&gt;              &lt;dbl&gt;        &lt;dbl&gt;
<br/>1 setosa                 5.1          3.5                1.4          0.2
<br/>2 setosa                 4.9          3                  1.4          0.2
<br/>3 setosa                 4.7          3.2                1.3          0.2
<br/>4 setosa                 4.6          3.1                1.5          0.2
<br/>5 setosa                 5            3.6                1.4          0.2
<br/># ℹ 145 more rows</code></pre>
</div>
</div>
<p>To simplify columns names, you can use <code>.names_sep</code> to strip off the common prefix before the specified separator in column names.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">pack</span>(<span className="at">Sepal =</span> <span className="fu">starts_with</span>(<span className="st">"Sepal"</span>),</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>       <span className="at">Petal =</span> <span className="fu">starts_with</span>(<span className="st">"Petal"</span>),</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>       <span className="at">.names_sep =</span> <span className="st">"."</span>)</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 150 × 3
<br/>  Species Sepal$Length $Width Petal$Length $Width
<br/>  &lt;fct&gt;          &lt;dbl&gt;  &lt;dbl&gt;        &lt;dbl&gt;  &lt;dbl&gt;
<br/>1 setosa           5.1    3.5          1.4    0.2
<br/>2 setosa           4.9    3            1.4    0.2
<br/>3 setosa           4.7    3.2          1.3    0.2
<br/>4 setosa           4.6    3.1          1.5    0.2
<br/>5 setosa           5      3.6          1.4    0.2
<br/># ℹ 145 more rows</code></pre>
</div>
</div>
<p>You can extract <code>Sepal</code> in two ways with a slightly different effect:</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># return two unpacked columns</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>a<span className="sc">$</span>Sepal</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 150 × 2
<br/>  Length Width
<br/>   &lt;dbl&gt; &lt;dbl&gt;
<br/>1    5.1   3.5
<br/>2    4.9   3  
<br/>3    4.7   3.2
<br/>4    4.6   3.1
<br/>5    5     3.6
<br/># ℹ 145 more rows</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># retain dataframe-column format</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>a <span className="sc">%&gt;%</span> <span className="fu">select</span>(Sepal)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 150 × 1
<br/>  Sepal$Length $Width
<br/>         &lt;dbl&gt;  &lt;dbl&gt;
<br/>1          5.1    3.5
<br/>2          4.9    3  
<br/>3          4.7    3.2
<br/>4          4.6    3.1
<br/>5          5      3.6
<br/># ℹ 145 more rows</code></pre>
</div>
</div>
</div>
</section>
<section className="level3" id="unpack-the-dataframe-column">
<h3 className="anchored" data-anchor-id="unpack-the-dataframe-column"><span id="unpack">Unpack the dataframe-column</span></h3>
<p>Use <code>unpack()</code> to release the clustered dataframe-column into separate columns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>a <span className="sc">%&gt;%</span> <span className="fu">unpack</span>(Sepal)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 150 × 4
<br/>  Species Length Width Petal$Length $Width
<br/>  &lt;fct&gt;    &lt;dbl&gt; &lt;dbl&gt;        &lt;dbl&gt;  &lt;dbl&gt;
<br/>1 setosa     5.1   3.5          1.4    0.2
<br/>2 setosa     4.9   3            1.4    0.2
<br/>3 setosa     4.7   3.2          1.3    0.2
<br/>4 setosa     4.6   3.1          1.5    0.2
<br/>5 setosa     5     3.6          1.4    0.2
<br/># ℹ 145 more rows</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>a <span className="sc">%&gt;%</span> <span className="fu">unpack</span>(<span className="fu">c</span>(Sepal, Petal), <span className="at">names_sep =</span> <span className="st">"_"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 150 × 5
<br/>  Species Sepal_Length Sepal_Width Petal_Length Petal_Width
<br/>  &lt;fct&gt;          &lt;dbl&gt;       &lt;dbl&gt;        &lt;dbl&gt;       &lt;dbl&gt;
<br/>1 setosa           5.1         3.5          1.4         0.2
<br/>2 setosa           4.9         3            1.4         0.2
<br/>3 setosa           4.7         3.2          1.3         0.2
<br/>4 setosa           4.6         3.1          1.5         0.2
<br/>5 setosa           5           3.6          1.4         0.2
<br/># ℹ 145 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="use-unpack-with-capture-groups">
<h3 className="anchored" data-anchor-id="use-unpack-with-capture-groups"><span id="capture_group_unpack">Use <code>unpack()</code> with capture groups</span></h3>
<p>A <Link to="/R/data-wrangling/regular-expression/8-capture-group">capture group</Link> is a part of a regular expression that is enclosed in parentheses <code>()</code>. <Link to="/R/data-wrangling/stringr/19-str-match"><code>str_match()</code></Link> in the <Link to="/R/data-wrangling/stringr/0-introduction">stringr</Link> package is a nice tool to capture and extract the matched patterns, and returns a matrix. This matrix can be embedded in a <Link to="/R/data-wrangling/tibble/create-tibbles">tibble</Link> as a <em>matrix-column</em>, then converted to a <em>tibble-column</em>, and further unpacked to release the individual columns. The following example demonstrates this procedure. (A detailed instruction about the preparation of dataset <code>x</code> can be found <Link to="/R/data-wrangling/regular-expression/8-capture-group#capture_group_tibble">here</Link>.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a></span><br/>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a><span className="co"># create a named capture group</span></span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>named.capture <span className="ot">&lt;-</span> <span className="st">"(?&lt;status&gt;new)_?(?&lt;type&gt;.*)_(?&lt;gender&gt;.)(?&lt;age&gt;.*)"</span></span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a></span><br/>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>x <span className="ot">&lt;-</span> who <span className="sc">%&gt;%</span> </span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>  <span className="co"># select top-20 rows containing most outbreaks in male at age 15-24 </span></span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>  <span className="fu">slice_max</span>(<span className="at">order_by =</span> new_sp_m1524, <span className="at">n =</span> <span className="dv">20</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>  <span className="co"># convert to tidy structure</span></span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span><span className="fu">c</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>), <span className="at">names_to =</span> <span className="st">"condition"</span>, <span className="at">values_to =</span> <span className="st">"count"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a>  </span>
<span id="cb13-13"><a aria-hidden="true" href="#cb13-13" tabindex="-1"></a>  <span className="co"># split the `condition` column into 4 separate columns</span></span>
<span id="cb13-14"><a aria-hidden="true" href="#cb13-14" tabindex="-1"></a>  <span className="co"># with values extracted from the defined capture group</span></span>
<span id="cb13-15"><a aria-hidden="true" href="#cb13-15" tabindex="-1"></a>  <span className="co"># return the captured groups as a tibble-column</span></span>
<span id="cb13-16"><a aria-hidden="true" href="#cb13-16" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb13-17"><a aria-hidden="true" href="#cb13-17" tabindex="-1"></a>    <span className="at">a =</span> <span className="fu">str_match</span>(condition, named.capture) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>(), </span>
<span id="cb13-18"><a aria-hidden="true" href="#cb13-18" tabindex="-1"></a>    <span className="at">.keep =</span> <span className="st">"unused"</span>)</span>
<span id="cb13-19"><a aria-hidden="true" href="#cb13-19" tabindex="-1"></a>x</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 1,120 × 6
<br/>  country iso2  iso3   year count a$V1         $status $type $gender $age 
<br/>  &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;        &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt;   &lt;chr&gt;
<br/>1 India   IN    IND    2010  4871 new_sp_m014  new     sp    m       014  
<br/>2 India   IN    IND    2010 78278 new_sp_m1524 new     sp    m       1524 
<br/>3 India   IN    IND    2010 82757 new_sp_m2534 new     sp    m       2534 
<br/>4 India   IN    IND    2010 90440 new_sp_m3544 new     sp    m       3544 
<br/>5 India   IN    IND    2010 81210 new_sp_m4554 new     sp    m       4554 
<br/># ℹ 1,115 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="co"># unpack the single tibble-column into separate columns</span></span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>x <span className="sc">%&gt;%</span> <span className="fu">unpack</span>(a)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 1,120 × 10
<br/>  country iso2  iso3   year count V1           status type  gender age  
<br/>  &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;        &lt;chr&gt;  &lt;chr&gt; &lt;chr&gt;  &lt;chr&gt;
<br/>1 India   IN    IND    2010  4871 new_sp_m014  new    sp    m      014  
<br/>2 India   IN    IND    2010 78278 new_sp_m1524 new    sp    m      1524 
<br/>3 India   IN    IND    2010 82757 new_sp_m2534 new    sp    m      2534 
<br/>4 India   IN    IND    2010 90440 new_sp_m3544 new    sp    m      3544 
<br/>5 India   IN    IND    2010 81210 new_sp_m4554 new    sp    m      4554 
<br/># ℹ 1,115 more rows</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}