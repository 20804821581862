import React, {useEffect, useState}  from 'react';
import { Outlet } from 'react-router-dom'
import Navigation from './NavSidBar';
import PythonTutorialData from '../../data/PythonTutorialData';
import {useNavigate, useLocation} from 'react-router-dom'
import {Adsggplot} from "../../Ads"
import logoPython from "../../media/Python-Logo.png"
 

export default function TutorialIndexLayout() {
    const navigate = useNavigate()
    const location = useLocation()    
    const paths = [];

    for(let i= 0; i< PythonTutorialData.length; i++){
        if (PythonTutorialData[i]['path'] !=''){
            paths.push(PythonTutorialData[i]['path'])
        }
    }
    // console.log(paths)
    const [currentPage, setCurrentPage] = useState(0)
    
    useEffect(()=> {
        const path = location.pathname
        const parts = path.split('/')
        const currentRoute = parts[parts.length -1]
        const currentRouteIndex = paths.findIndex((element)=> element == currentRoute)
        setCurrentPage(currentRouteIndex)
        // console.log(currentPage)
    }, [location.pathname])
 

    const nextPage = ()=> {
        if(currentPage < paths.length - 1){
            const nextRoute = paths[currentPage +1]
            setCurrentPage(currentPage + 1)
            navigate(nextRoute)
           
        }

    }
    const prevPage = ()=> {
        if (currentPage>0){
            const previousRoute = paths[currentPage - 1]
            setCurrentPage(currentPage - 1)
            navigate(previousRoute)
        }

    }
     // If the user navigates to /pyspark-tutorial, redirect to the first valid page
     useEffect(() => {
        if (location.pathname === '/python-tutorial' | location.pathname === '/python-tutorial/') {
        const firstValidRoute = paths[0];
        navigate(firstValidRoute);
        }
    }, [location.pathname, paths, navigate]);

    return (
        <div className='main'>
            <div className="row">
        
                <div className="col-md-2 col-sm-12" style={{padding:0}}>
                    <Navigation currentPage={currentPage} />
                </div>              

                <div className="col-md-9 col-sm-12" >
                    <div className="row">
                        <div className='col-md-10 p-r-md-60'>                       
                            {/* <div className='ads-top'>
                                Save this space for Ads
                            
                            </div> */}
                           
                            <div className="main-container w-xl-85 mg-t-50">
                                <div className='flexSpaceBetweenCenter'>
                                    <div className="small-logo">
                                        <img className="logo-diff img-fluid cover-img" src={logoPython} /></div>
                                    <div className='flexSpaceBetween'>             
                                        <div className='letter-space-min p-t-5 p-b-5 mg-r-50 center-text'>
                                            {/* <a id="prevPage" onClick={()=>prevPage()}  className='button'>&lt; Previous</a> */}
                                            <button onClick={() => prevPage()} className='button'>&lt; Previous</button>
                                        </div>
                                        <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                            {/* <a id="nextPage" onClick={()=>nextPage()} className='button'>Next &gt;</a> */}
                                            <button onClick={() => nextPage()} className='button'>Next &gt;</button>
                                        </div>
                                    </div>
                                </div>
                                <Outlet />
                            </div>
                            <div className='ads-mid'>
                                {/* Save this space for Ads */}
            
                            </div>
                            <div class="main-container flexSpaceBetween w-xl-85">
                                <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                    <a id="prevPage" onClick={()=>prevPage()}  className='button'>&lt; Previous</a>
                                </div>
                                <div className='letter-space-min p-t-5 p-b-5 center-text'>
                                    <a id="nextPage" onClick={()=>nextPage()} className='button'>Next &gt;</a>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className='col-md-2 mg-t-150'>
                          
                        {/* Save this space for Ads */}
                        {/* This is Ads */}
                        </div>
                    </div>
                    
                </div>
                
                <div className="col-md-1 col-sm-12">
                    <Adsggplot /> 
                </div>
            </div>

        </div>
    )
}