import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function CreateTibbles(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Tibbles, and Their Important Features</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>tibble()</code> is a nice way to create a tibble from scratch, and <code>as_tibble()</code> converts other data frames (e.g., <code>data.frame</code> and matrix) to a tibble. Below we’ll discuss how to create tibbles using these two functions; during the processing of creating tibbles, we’ll also discuss their important properties.</p>
<hr/>
<p><strong>1. You can create a tibble in a similar way as with <code>data.frame</code></strong> simply by specifying the name of the column and the associated cell values. Different from <code>data.frame</code>, a tibble when printed will nicely display the dataset dimension (row number × column number), and the types of each column, making it informative for downstream analysis.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"Charlie"</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>            <span className="at">Age  =</span> <span className="fu">c</span>(<span className="dv">25</span>, <span className="dv">30</span>, <span className="dv">35</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>            <span className="at">City =</span> <span className="fu">c</span>(<span className="st">"New York"</span>, <span className="st">"Los Angeles"</span>, <span className="st">"Chicago"</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  Name      Age City       
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;chr&gt;      
<br/>1 Alice      25 New York   
<br/>2 Bob        30 Los Angeles
<br/>3 Charlie    35 Chicago    </code></pre>
</div>
</div>
<p>A tibble has an official class of <code>tbl_df</code>**, as well as <code>data.frame</code> and <code>tbl</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">class</span>(a)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "tbl_df"     "tbl"        "data.frame"</code></pre>
</div>
</div>
<p><strong>2. You can include <em>lists</em> in a tibble column.</strong> This is helpful to do high-performance functional programming. (You cannot do this with <code>data.frame</code>.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">tibble</span>(<span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>, <span className="at">y =</span> <span className="fu">list</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>, <span className="dv">1</span><span className="sc">:</span><span className="dv">10</span>, <span className="dv">1</span><span className="sc">:</span><span className="dv">20</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>      x y         
<br/>  &lt;int&gt; &lt;list&gt;    
<br/>1     1 &lt;int [5]&gt; 
<br/>2     2 &lt;int [10]&gt;
<br/>3     3 &lt;int [20]&gt;</code></pre>
</div>
</div>
<p><strong>3. <code>tibble()</code> builds columns sequentially.</strong> When defining a column, you can refer to columns created earlier; e.g., column <code>C</code> is created based on prior-defined <code>A</code> and <code>B</code>. (You cannot do this with <code>data.frame</code>.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">tibble</span>(<span className="at">A =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>, <span className="at">B =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">3</span>, <span className="dv">6</span>), <span className="at">C =</span> A<span className="sc">^</span><span className="dv">2</span> <span className="sc">+</span> B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>      A     B     C
<br/>  &lt;int&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>1     1     1     2
<br/>2     2     3     7
<br/>3     3     6    15</code></pre>
</div>
</div>
<p><strong>4. <code>tibble()</code> faithfully reserves complicated column names.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">tibble</span>(<span className="st">`</span><span className="at">:)</span><span className="st">`</span> <span className="ot">=</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>, <span className="st">`</span><span className="at">A^2 + B</span><span className="st">`</span> <span className="ot">=</span> <span className="dv">4</span><span className="sc">:</span><span className="dv">6</span>, <span className="st">`</span><span className="at">3</span><span className="st">`</span> <span className="ot">=</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>, <span className="st">"c"</span>)) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<span>   `:)` `A^2 + B` `3`  
</span><br/>  &lt;int&gt;     &lt;int&gt; &lt;chr&gt;
<br/>1     1         4 a    
<br/>2     2         5 b    
<br/>3     3         6 c    </code></pre>
</div>
</div>
<p>In comparison, <code>data.frame</code> may alter the column name.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">data.frame</span>(<span className="st">`</span><span className="at">:)</span><span className="st">`</span> <span className="ot">=</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>, <span className="st">`</span><span className="at">A^2 + B</span><span className="st">`</span> <span className="ot">=</span> <span className="dv">4</span><span className="sc">:</span><span className="dv">6</span>, <span className="st">`</span><span className="at">3</span><span className="st">`</span> <span className="ot">=</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>, <span className="st">"c"</span>)) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  X.. A.2...B X3
<br/>1   1       4  a
<br/>2   2       5  b
<br/>3   3       6  c</code></pre>
</div>
</div>
<p><strong>5. Only columns of length one can be recycled.</strong> In the code below, for instance, you cannot recycle <code>B = c(1, 2)</code> three times to create a total of six rows. This limit is designed so because recycling vectors of longer length is a frequent source of bugs. In comparison, <code>data.frame</code> can recycle vectors of longer length(e.g., <code>data.frame(A = 1:6, B = c(1, 2))</code> recycles <code>B = c(1, 2)</code> three times.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">tibble</span>(<span className="at">A =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">6</span>, <span className="at">B =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>))</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="co"># Error:</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a><span className="co"># ! Tibble columns must have compatible sizes.</span></span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a><span className="co"># • Size 6: Existing data.</span></span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a><span className="co"># • Size 2: Column `B`.</span></span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a><span className="co"># ℹ Only values of size one are recycled.</span></span></code></pre></div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">data.frame</span>(<span className="at">A =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">6</span>, <span className="at">B =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  A B
<br/>1 1 1
<br/>2 2 2
<br/>3 3 1
<br/>4 4 2
<br/>5 5 1
<br/>6 6 2</code></pre>
</div>
</div>
<p><strong>6. Use <code>as_tibble()</code> to convert a <code>data.frame</code> to a tibble.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="fu">as_tibble</span>(mtcars) <span className="co"># convert 'data.frame' mtcars dataset to a tibble</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 32 × 11
<br/>     mpg   cyl  disp    hp  drat    wt  qsec    vs    am  gear  carb
<br/>   &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/> 1  21       6  160    110  3.9   2.62  16.5     0     1     4     4
<br/> 2  21       6  160    110  3.9   2.88  17.0     0     1     4     4
<br/> 3  22.8     4  108     93  3.85  2.32  18.6     1     1     4     1
<br/> 4  21.4     6  258    110  3.08  3.22  19.4     1     0     3     1
<br/> 5  18.7     8  360    175  3.15  3.44  17.0     0     0     3     2
<br/> 6  18.1     6  225    105  2.76  3.46  20.2     1     0     3     1
<br/> 7  14.3     8  360    245  3.21  3.57  15.8     0     0     3     4
<br/> 8  24.4     4  147.    62  3.69  3.19  20       1     0     4     2
<br/> 9  22.8     4  141.    95  3.92  3.15  22.9     1     0     4     2
<br/>10  19.2     6  168.   123  3.92  3.44  18.3     1     0     4     4
<br/># ℹ 22 more rows</code></pre>
</div>
</div>
<p><strong>7. Tibbles do not support row names.</strong> Note that the rownames of <code>mtcars</code> are dropped after the data frame is converted to a tibble.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="fu">rownames</span>(mtcars)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"> [1] "Mazda RX4"           "Mazda RX4 Wag"       "Datsun 710"          "Hornet 4 Drive"      "Hornet Sportabout"   "Valiant"             "Duster 360"          "Merc 240D"           "Merc 230"            "Merc 280"            "Merc 280C"           "Merc 450SE"          "Merc 450SL"         
<br/>[14] "Merc 450SLC"         "Cadillac Fleetwood"  "Lincoln Continental" "Chrysler Imperial"   "Fiat 128"            "Honda Civic"         "Toyota Corolla"      "Toyota Corona"       "Dodge Challenger"    "AMC Javelin"         "Camaro Z28"          "Pontiac Firebird"    "Fiat X1-9"          
<br/>[27] "Porsche 914-2"       "Lotus Europa"        "Ford Pantera L"      "Ferrari Dino"        "Maserati Bora"       "Volvo 142E"         </code></pre>
</div>
<div className="sourceCode cell-code" id="cb20"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb20-1"><a aria-hidden="true" href="#cb20-1" tabindex="-1"></a><span className="fu">as_tibble</span>(mtcars) <span className="sc">%&gt;%</span> <span className="fu">rownames</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"> [1] "1"  "2"  "3"  "4"  "5"  "6"  "7"  "8"  "9"  "10" "11" "12" "13" "14" "15" "16" "17" "18" "19" "20" "21" "22" "23" "24" "25" "26" "27" "28" "29" "30" "31" "32"</code></pre>
</div>
</div>
<p>You can reserve row names as a separate column using <code>rownames_to_column()</code> from the tibble package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb22"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb22-1"><a aria-hidden="true" href="#cb22-1" tabindex="-1"></a><span className="fu">rownames_to_column</span>(mtcars) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 32 × 12
<br/>   rowname             mpg   cyl  disp    hp  drat    wt  qsec    vs    am  gear  carb
<br/>   &lt;chr&gt;             &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/> 1 Mazda RX4          21       6  160    110  3.9   2.62  16.5     0     1     4     4
<br/> 2 Mazda RX4 Wag      21       6  160    110  3.9   2.88  17.0     0     1     4     4
<br/> 3 Datsun 710         22.8     4  108     93  3.85  2.32  18.6     1     1     4     1
<br/> 4 Hornet 4 Drive     21.4     6  258    110  3.08  3.22  19.4     1     0     3     1
<br/> 5 Hornet Sportabout  18.7     8  360    175  3.15  3.44  17.0     0     0     3     2
<br/> 6 Valiant            18.1     6  225    105  2.76  3.46  20.2     1     0     3     1
<br/> 7 Duster 360         14.3     8  360    245  3.21  3.57  15.8     0     0     3     4
<br/> 8 Merc 240D          24.4     4  147.    62  3.69  3.19  20       1     0     4     2
<br/> 9 Merc 230           22.8     4  141.    95  3.92  3.15  22.9     1     0     4     2
<br/>10 Merc 280           19.2     6  168.   123  3.92  3.44  18.3     1     0     4     4
<br/># ℹ 22 more rows</code></pre>
</div>
</div>
<p>Equivalently, the code above can be rewritten using <Link to="/R/data-wrangling/dplyr/4-mutate-columns"><code>mutate()</code></Link> from the <Link to="/R/data-wrangling/dplyr/0-introduction">dplyr</Link> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb24"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb24-1"><a aria-hidden="true" href="#cb24-1" tabindex="-1"></a>mtcars <span className="sc">%&gt;%</span> </span>
<span id="cb24-2"><a aria-hidden="true" href="#cb24-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">carnames =</span> <span className="fu">rownames</span>(mtcars), <span className="at">.before =</span> <span className="dv">1</span>) <span className="sc">%&gt;%</span></span>
<span id="cb24-3"><a aria-hidden="true" href="#cb24-3" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span></code></pre></div>
</div>
<p><br/></p>
<p>The next tutorial will discuss <Link to="../row-wise-tibble-creation"><code>tribble()</code></Link>, another great way to create tibbles on a row-wise basis in a very intuitive manner.</p>
</main>
</div>
</div>
)}