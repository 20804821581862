import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function WordBoundary(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Word Boundary</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>A word boundary naturally occurs at the start and end of a string, and between a word and a non-word. The character <code>\b</code> is used to indicate a word boundary, and <code>\B</code> to indicate a position not being a word boundary.</p>
<p>For example, <code>\\bapple\\b</code> will match “apple” as a whole word, and not within other words like “pineapple” or “applesauce”. (Recall that a second backslash is used to escape the backslash itself; review <Link to="../5-escape-characters">escape character</Link>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apples in a jar"</span>, </span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>       <span className="st">"sweet applesource"</span>, </span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>       <span className="st">"a pineapple in boxes"</span>)</span></code></pre></div>
</div>
<p><strong><em>eg. 1.</em></strong> <code>apple\\b</code> matches “apple” at the <em>end</em> of a word.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="at">pattern =</span> <span className="st">"apple</span><span className="sc">\\</span><span className="st">b"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ apples in a jar
<br/>[2] │ sweet applesource
<br/>[3] │ a pine&lt;apple&gt; in boxes</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_subset</span>(x, <span className="at">pattern =</span> <span className="st">"apple</span><span className="sc">\\</span><span className="st">b"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "a pineapple in boxes"</code></pre>
</div>
</div>
</div>
<p><strong><em>eg. 2.</em></strong> <code>\\bapple</code> matches “apple” at the <em>start</em> of a word.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="at">pattern =</span> <span className="st">"</span><span className="sc">\\</span><span className="st">bapple"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;apple&gt;s in a jar
<br/>[2] │ sweet &lt;apple&gt;source
<br/>[3] │ a pineapple in boxes</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">str_subset</span>(x, <span className="at">pattern =</span> <span className="st">"</span><span className="sc">\\</span><span className="st">bapple"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apples in a jar"   "sweet applesource"</code></pre>
</div>
</div>
</div>
<hr/>
<p>In comparison, <code>^</code> and <code>$</code> are <Link to="../3-anchors">anchors</Link> that specify respectively the start and end position of a <em>character vector element</em>, not the start of a word. E.g., <code>^apple</code> matches “apple” that is at the start of a string element.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="at">pattern =</span> <span className="st">"^apple"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;apple&gt;s in a jar
<br/>[2] │ sweet applesource
<br/>[3] │ a pineapple in boxes</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">str_subset</span>(x, <span className="at">pattern =</span> <span className="st">"^apple"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "apples in a jar"</code></pre>
</div>
</div>
</div>
</main>
</div>
</div>
)}