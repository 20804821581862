import React from 'react';
import CharacterClass from '../RDataWrangling/regular-expression/contents/1-character-class_output_react'
import LookaroundAssertion from '../RDataWrangling/regular-expression/contents/9-lookaround-assertion_output_react'
import Alternation from '../RDataWrangling/regular-expression/contents/7-alternation_output_react'
import Anchors from '../RDataWrangling/regular-expression/contents/3-anchors_output_react'
import CaptureGroup from '../RDataWrangling/regular-expression/contents/8-capture-group_output_react'
import EscapeCharacters from '../RDataWrangling/regular-expression/contents/5-escape-characters_output_react'
import Introduction from '../RDataWrangling/regular-expression/contents/0-introduction_output_react'
import Wildcards from '../RDataWrangling/regular-expression/contents/4-wildcards_output_react'
import WordBoundary from '../RDataWrangling/regular-expression/contents/6-word-boundary_output_react'
import Quantifiers from '../RDataWrangling/regular-expression/contents/2-quantifiers_output_react'

const RregularExpressionData=[
{'component': <Introduction />, 'path': '0-introduction', 'title': 'introduction'},
{'component': <CharacterClass />, 'path': '1-character-class', 'title': '1. character class'},
{'component': <Quantifiers />, 'path': '2-quantifiers', 'title': '2. quantifiers'},
{'component': <Anchors />, 'path': '3-anchors', 'title': '3. anchors'},
{'component': <Wildcards />, 'path': '4-wildcards', 'title': '4. wildcards'},
{'component': <EscapeCharacters />, 'path': '5-escape-characters', 'title': '5. escape characters'},
{'component': <WordBoundary />, 'path': '6-word-boundary', 'title': '6. word boundary'},
{'component': <Alternation />, 'path': '7-alternation', 'title': '7. alternation'},
{'component': <CaptureGroup />, 'path': '8-capture-group', 'title': '8. capture group'},
{'component': <LookaroundAssertion />, 'path': '9-lookaround-assertion', 'title': '9. lookaround assertion'},


]

export default RregularExpressionData