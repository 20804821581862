import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ColorBrewerPalette from '../tutorial/brewer_discrete_scale.png';

export default function Ggplot2ColorBrewerPalette(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">A Complete Guide on the “brewer” Color Palettes in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains all the <strong>essential techniques of <em>brewer</em> palettes</strong>:</p>
<ul>
<li><a href="#brewer_distiller_fermenter">Three types of color scales: <code>brewer</code>, <code>distiller</code>, and <code>fermenter</code></a></li>
<li><a href="#color_hex_codes">Extract and display color hex codes from the palettes</a></li>
<li><a href="#max_limit">Use color interpolation to resolve the maximum color limit</a></li>
</ul>
<hr/>
<p>We’ll use the <code>iris</code> dataset for illustration.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">head</span>(iris, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  Sepal.Length Sepal.Width Petal.Length Petal.Width Species
<br/>1          5.1         3.5          1.4         0.2  setosa
<br/>2          4.9         3.0          1.4         0.2  setosa
<br/>3          4.7         3.2          1.3         0.2  setosa</code></pre>
</div>
</div>
<section className="level3" id="brewer_distiller_fermenter">
<h3 className="anchored" data-anchor-id="brewer_distiller_fermenter">brewer, distiller, and fermenter</h3>
<p>To apply the brewer color palettes, the basic formula is <code>scale_color_*</code> (or <code>scale_fill_*</code>), with <code>*</code> representing three different suffix options: <strong>brewer</strong>, <strong>distiller</strong>, and <strong>fermenter</strong>, depending on the type of the variable that is mapped to the <code>color</code> (or <code>fill</code>) aesthetic.</p>
<section className="level4" id="categorical-variable-corresponds-to-suffix-brewer">
<h4 className="anchored" data-anchor-id="categorical-variable-corresponds-to-suffix-brewer">Categorical variable corresponds to suffix <code>brewer</code></h4>
<p>When a <strong>categorical</strong> variable is mapped to the <code>color</code> aesthetic, use suffix <strong>brewer</strong>, i.e., in the form of <code>scale_color_brewer()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># create a scatterplot</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>base <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> <span className="fu">ggplot</span>(<span className="fu">aes</span>(Sepal.Length, Sepal.Width))</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>base <span className="sc">+</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="fu">aes</span>(<span className="at">color =</span> Species), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Accent"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ColorBrewerPalette} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="numeric-variable-corresponds-to-suffix-distiller-or-fermenter">
<h4 className="anchored" data-anchor-id="numeric-variable-corresponds-to-suffix-distiller-or-fermenter">Numeric variable corresponds to suffix <code>distiller</code> or <code>fermenter</code></h4>
<p>When a <strong>numeric</strong> variable is mapped to <code>color</code>, there are two options:</p>
<ul>
<li>use suffix <strong>distiller</strong> to create a <em>continuous color bar</em> in the legend.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># Petal.Length a numerical variable</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>base <span className="sc">+</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="fu">aes</span>(<span className="at">color =</span> Petal.Length), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">scale_color_distiller</span>(<span className="at">palette =</span> <span className="st">"Accent"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_continuous_scale.png"/></p>
</figure>
</div>
</div>
</div>
<ul>
<li>use suffix <strong>fermenter</strong> to create a <em>binned</em> (stepwise) color scale.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>base <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="fu">aes</span>(<span className="at">color =</span> Petal.Length), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span> </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">scale_color_fermenter</span>(<span className="at">palette =</span> <span className="st">"Accent"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_binned_scale.png"/></p>
</figure>
</div>
</div>
</div>
<p>When the type of the variable is mismatched with the color scale syntax, it’ll generate an error message typically like: <span> <em>Error: Continuous value supplied to discrete scale. </em></span></p>
</section>
</section>
<section className="level3" id="display_brewer_palette_names">
<h3 className="anchored" data-anchor-id="display_brewer_palette_names">Display available brewer palettes</h3>
<p><code>scale_color_brewer()</code>, <code>scale_color_distiller()</code>, and <code>scale_color_fermenter()</code> (and the <code>scale_fill_*</code> functions) are all built in the ggplot2 package. However, in order to display the brewer’s available palette names and colors, or to extract colors hex codes from the brewer palettes, you need to additionally load the <code>RColorBrewer</code> package.</p>
<p>The following code displays the brewer’s palette names and colors. It also shows the maximum number of colors available in the palette when a categorical variable is mapped to <code>color</code> (or <code>fill</code>) (see <a href="#max_limit">maximum color limit</a> below).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a><span className="fu">display.brewer.all</span>()</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_display_palettes.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="color_hex_codes">
<h3 className="anchored" data-anchor-id="color_hex_codes">Extract colors from brewer palettes</h3>
<p>After loading the <code>RColorBrewer</code> package, colors can be extracted from a palette and returned as hex codes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>colors<span className="fl">.9</span> <span className="ot">&lt;-</span> <span className="fu">brewer.pal</span>(<span className="at">n =</span> <span className="dv">9</span>, <span className="at">name =</span> <span className="st">"Set3"</span>)</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>colors<span className="fl">.9</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "#8DD3C7" "#FFFFB3" "#BEBADA" "#FB8072" "#80B1D3" "#FDB462" "#B3DE69"
<br/>[8] "#FCCDE5" "#D9D9D9"</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualize-color-hex-codes">
<h3 className="anchored" data-anchor-id="visualize-color-hex-codes">Visualize color hex codes</h3>
<p>The <code>scales</code> package offers a solution to visualize the color hex codes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(scales)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">show_col</span>(colors<span className="fl">.9</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_display_hex_codes.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="max_limit">
<h3 className="anchored" data-anchor-id="max_limit">Maximum color number limit in brewer’s discrete color scale</h3>
<p>When a categorical (discrete) variable is mapped to <code>color</code> and <code>scale_color_brewer()</code>, the colors available in the plot is limited by the number of colors in the associated palette. For example, a maximum of 9 colors is available in palette <code>Set 3</code> (use <code>display.brewer.all()</code> to show available colors; <a href="#display_brewer_palette_names">see above</a>). In like manner, when extracting colors using <code>brewer.pal()</code>, no more than 9 colors can be extracted from <code>Set 3</code>.</p>
<p>Note that this maximum color number limit does <em>not</em> apply to <code>scale_color_fermenter()</code> or <code>scale_color_distiller()</code>.</p>
<p>This limit in <code>scale_color_brewer()</code> often makes sense, as too many discrete colors (typically used for categorical variables) are difficult to distinguish visually. However, if you do need more colors, you can use the base R function <code>colorRampPalette()</code> to interpolate (generate) as many transitional colors as needed (see below).</p>
</section>
<section className="level3" id="color-interpolation">
<h3 className="anchored" data-anchor-id="color-interpolation">Color interpolation</h3>
<p>The following code generates 20 colors with smoother color transition from 9 colors of the <code>Set 3</code> palette.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>colors<span className="fl">.20</span> <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(colors<span className="fl">.9</span>)(<span className="dv">20</span>) </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>colors<span className="fl">.20</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"> [1] "#8DD3C7" "#BDE5BE" "#EDF8B6" "#EDECBD" "#D2CFCD" "#C4B3CF" "#DE9BA3"
<br/> [8] "#F78377" "#CD9295" "#99A6BE" "#9AB1BB" "#CEB28B" "#F9B662" "#D9C765"
<br/>[15] "#BAD968" "#CAD890" "#E8D1C4" "#F6CEE3" "#E7D3DE" "#D9D9D9"</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">show_col</span>(colors<span className="fl">.20</span>, <span className="at">cex_label =</span> .<span className="dv">6</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_display_interploated_hex_codes.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="demo-example-use-color-interpolation-to-overcome-maximumm-color-number-limit">
<h3 className="anchored" data-anchor-id="demo-example-use-color-interpolation-to-overcome-maximumm-color-number-limit">Demo example: Use color interpolation to overcome maximumm color number limit</h3>
<p><code>stat_density_2d_filled()</code> creates a density heatmap. It divides the continuous density scale into 13 discrete (categorical) intervals, with each interval mapped with a color, and corresponds to color scale <code>scale_fill_brewer()</code>. The <code>Spectral</code> palette, however, can only offer a maximum of 11 colors. This leads to incomplete color mapping in the heatmap and legend.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>f <span className="ot">&lt;-</span> faithful <span className="sc">%&gt;%</span> </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(waiting, eruptions)) <span className="sc">+</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>  <span className="fu">stat_density_2d_filled</span>() <span className="sc">+</span></span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a></span><br/>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>f <span className="sc">+</span> <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_2D_density.png"/></p>
</figure>
</div>
</div>
</div>
<p>To generate all the needed 13 colors, we can employ color interpolation to the <code>Spectral</code> palette. Note that the generated colors are supplied to the <code>values</code> argument in the <code>scale_color_manual()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(<span className="fu">brewer.pal</span>(<span className="dv">11</span>, <span className="st">"Spectral"</span>))(<span className="dv">13</span>) </span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>f <span className="sc">+</span> <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> myColors)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_2D_density_interploated.png"/></p>
</figure>
</div>
</div>
</div>
<p>An alternative solution to color interpolation is to use the popular <Link to="../16-ggplot2-color-viridis-palette"><strong>viridis</strong></Link> or <Link to="https://github.com/thomasp85/scico"><strong>scico</strong></Link> color palettes, which are never limited to the maximum color number limits.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="co"># viridis palettes</span></span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> f <span className="sc">+</span> <span className="fu">scale_fill_viridis_d</span>(<span className="at">option =</span> <span className="st">"A"</span>)</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a></span><br/>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a><span className="co"># scico palettes; require calling / loading the package</span></span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> f <span className="sc">+</span> scico<span className="sc">::</span><span className="fu">scale_fill_scico_d</span>(<span className="at">palette =</span> <span className="st">"batlowK"</span>)</span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a></span><br/>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>p1 <span className="sc">|</span> p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/brewer_viridis_scico.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}