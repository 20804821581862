import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2ArrowsFacetWomenParliament from '../graphics/women_seats_parliament_completed.png'; 
import imgGgplot2ArrowsFacetWomenParliamentWebp from '../graphics/women_seats_parliament_completed.webp'; 
export default function Ggplot2ArrowsFacetWomenParliament(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Faceted Arrow Plots in ggplot2 to Visualize Women’s Seats Change in National Parliaments</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2ArrowsFacetWomenParliamentWebp} />
    <img className="cover-img" src={imgGgplot2ArrowsFacetWomenParliament} />
  </picture>

<p>This article visualizes the changes in the proportion of seats held by women in national parliaments from 2000 to 2020. This work features a high level of plot customization. <strong>Major technical highlights covered in this article include:</strong></p>
<ul>
<li>Draw arrows.</li>
<li>Aesthetics inheritance.</li>
<li>Create unique annotations in selected faceted panels (subplots).</li>
<li>Annotation alignment and plot margin control.</li>
</ul>
<p>This work is a ggplot2 reproduction of the graphic by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link>.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset is sourced from <Link to="https://data.worldbank.org/indicator/SG.GEN.PARL.ZS">World Bank</Link>, and can be downloaded <a className="downloadData" href="dataset/r-gallery/women%20in%20parliament.csv"><strong>here</strong></a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>())</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/women in parliament.csv"</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>d2 <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="co"># Is the percentage in 2020 higher than 2020? </span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="co"># If true, then the arrows are plotted in blue, otherwise red</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">increased =</span> percent_2020 <span className="sc">&gt;</span> percent_2000) <span className="sc">%&gt;%</span> </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">arrange</span>(percent_2020)</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a></span><br/>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a><span className="co"># country as ordered factor based on the percentage of seats held by women in 2020</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>d2<span className="sc">$</span>country <span className="ot">&lt;-</span> <span className="fu">factor</span>(d2<span className="sc">$</span>country, <span className="at">levels =</span> d2<span className="sc">$</span>country, <span className="at">ordered =</span> T)</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="fu">head</span>(d2, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  country  percent_2000 percent_2020 region                increased
<br/>  &lt;ord&gt;           &lt;dbl&gt;        &lt;dbl&gt; &lt;chr&gt;                 &lt;lgl&gt;    
<br/>1 Japan            7.29         9.89 East Asia &amp; Pacific   TRUE     
<br/>2 Malaysia        10.4         14.4  East Asia &amp; Pacific   TRUE     
<br/>3 Turkey           4.18        17.3  Europe &amp; Central Asia TRUE     </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="1"><strong>1. Create arrows</strong></span> to indicate the changes in the percentage of seats held by women from 2000 (start of arrows) to 2020 (end of arrows).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d2 <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> country, <span className="at">xend =</span> country, </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>             <span className="at">y =</span> percent_2000, <span className="at">yend =</span> percent_2020,</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>             <span className="at">color =</span> increased)) <span className="sc">+</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="at">arrow =</span> <span className="fu">arrow</span>(<span className="at">length =</span> <span className="fu">unit</span>(<span className="dv">8</span>, <span className="st">"pt"</span>)),</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>               <span className="at">linewidth =</span> <span className="dv">1</span>)  </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_arrows.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_arrows"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="2"><strong>2. Facet the plots based on the <code>region</code> variable, and flip the axes.</strong></span> Here <code>expand = 0</code> removes the default margins around the plot, and helps to align subplot titles and axial labels (manually added at <a href="#3">step 3</a>) with the plot title (added at <a href="#8">step 8</a>). The plot margins will be added back later using the <code>theme()</code> function (at <a href="#8">step 8</a>) while retaining the alignment.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">facet_grid</span>(region <span className="sc">~</span> ., <span className="at">scales =</span> <span className="st">"free_y"</span>, <span className="at">space =</span> <span className="st">"free_y"</span>) <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"firebrick4"</span>, <span className="st">"steelblue4"</span>)) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> <span className="st">"off"</span>) </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a></span><br/>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_facet_flip.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_facet_flip"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="3"><strong>3. Add subplot titles and x-axis (left) labels manually</strong>.</span> Here we use <code>geom_text()</code> to create texts left-justified (<code>hjsut = 0</code>) to the anchoring point <code>y = -20</code>. We’ll remove the default texts later at <a href="#4">step 4</a>. In the first layer of <code>geom_text</code> (for subplot titles), we create a new input dataset using the <code>tibble</code> function. The <code>region</code> variable is not only mapped to the <code>label</code> aesthetic, but also serves as the faceting variable indicating which subplot the titles should be added in. In addition, we use <code>inherit.aes = F</code> to inhibit aesthetic inheritance of this geom from the <code>ggplot()</code> line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="co"># add faceted panel (subplot) titles</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">region =</span> <span className="fu">c</span>(<span className="st">"East Asia &amp; Pacific"</span>, </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>                                     <span className="st">"Europe &amp; Central Asia"</span>)),</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="dv">7</span>, <span className="dv">10</span>), <span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, </span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>                <span className="at">label =</span> region), </span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>,</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>            <span className="at">inherit.aes =</span> F) <span className="sc">+</span> </span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>  </span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  <span className="co"># add x-axis (country) labels</span></span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">label =</span> country), </span>
<span id="cb5-13"><a aria-hidden="true" href="#cb5-13" tabindex="-1"></a>            <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">hjust =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb5-14"><a aria-hidden="true" href="#cb5-14" tabindex="-1"></a>  </span>
<span id="cb5-15"><a aria-hidden="true" href="#cb5-15" tabindex="-1"></a>  <span className="co"># increase margin between the two subplots</span></span>
<span id="cb5-16"><a aria-hidden="true" href="#cb5-16" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.spacing.y =</span> <span className="fu">unit</span>(<span className="dv">30</span>, <span className="st">"pt"</span>)) </span>
<span id="cb5-17"><a aria-hidden="true" href="#cb5-17" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_manual_subplot_title_label.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_manual_subplot_title_label"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>To learn more examples of making unique annotations in selected subplots, check this <Link to="../ggplot2-dumbell-plot-votes-Trump-Clinton">faceted dumbbell plot</Link> and this <Link to="../ggpplot2-bar-mortality">barplot with ordered subplots</Link>.</p>
<p><span id="4"><strong>4. Remove the redundant default facet titles and x-axis (left) labels</strong>.</span> Note that after coordinate flip, the aesthetic mapping remains unchanged, and the <code>country</code> variable (left vertical axis) remains the <code>x</code> aesthetic (e.g., as used in <code>labs()</code>). In contrast, in the <code>theme()</code> syntax, horizontal axis is always treated as the x-axis, and vertical axis as the y-axis, regardless of the presence of coordinate flip or not.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span>  </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">strip.text =</span> <span className="fu">element_blank</span>(), <span className="co"># remove default facet titles</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>()) <span className="sc">+</span> <span className="co"># remove default country labels</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>) <span className="co"># remove the country title</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_cleanup.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_cleanup"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="5"><strong>5. Revise the y-axis (bottom) labels, and remove its title</strong>.</span> After coordinate flip, the “percent_2000” variable remains the <code>y</code> aesthetic (e.g., as used in <code>scale_y_continuous()</code>), but is treated as the x-axis in the <code>theme()</code> syntax (e.g., <code>axis.text.x</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># breaks</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span>   </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">50</span>, <span className="dv">10</span>),</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>                     <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste0</span>(x, <span className="st">"%"</span>)&#125;,</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>                     <span className="at">minor_breaks =</span> <span className="cn">NULL</span>, <span className="co"># remove the vertical minor grids</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>                     <span className="at">name =</span> <span className="cn">NULL</span>) <span className="sc">+</span> <span className="co"># remove the title</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="co"># thinner horizontal grids</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>    <span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">2</span>), </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>    <span className="co"># increase the top margin of the bottom - axis labels</span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">15</span>))) </span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_axis.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_axis"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="6"><strong>6. Add labels showing the percent of seats held by women in 2020</strong>.</span> Here we use <code>ifelse()</code> to determine the labels’ <code>y</code> aesthetic depending on the direction of the percent change from 2000 to 2020.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="at">y =</span> <span className="fu">ifelse</span>(increased <span className="sc">==</span> T, percent_2020 <span className="sc">+</span> <span className="dv">4</span>, percent_2000 <span className="sc">-</span> <span className="dv">6</span>), </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">label =</span> <span className="fu">round</span>(percent_2020, <span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="fu">paste0</span>(<span className="st">"%"</span>))) </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_annotate_percentage.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_annotate_percentage"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="7"><strong>7. Mark the start of the arrow as year 2000, and end of the arrow as 2020</strong></span>, using the first arrow for illustration. In the new input dataset, the <code>region</code> variable serves as the faceting variable, similar to <a href="#3">step 3</a>. As <code>region</code> is assigned here with only one level “East Asia &amp; Pacific”, the text is displayed only in the first subplot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span>   </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">region =</span> <span className="st">"East Asia &amp; Pacific"</span>,</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>                          <span className="at">x =</span> <span className="dv">7</span>, <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">31</span>, <span className="fl">40.8</span>),</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>                          <span className="at">label =</span> <span className="fu">c</span>(<span className="fu">c</span>(<span className="st">"2000 |"</span>, <span className="st">"| 2020"</span>))),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> x, <span className="at">y =</span> y, <span className="at">label =</span> label),</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>            <span className="at">inherit.aes =</span> F, </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">0</span>), </span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>,</span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>            <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"darkgreen"</span>, <span className="st">"orange3"</span>)) </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>p7</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_year_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_year_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="8"><strong>8. Final polish-up.</strong></span> Here we add the plot title, which is nicely aligned to the left with the manually added subplot titles and country names. Accurate alignment was made possible by removing the plot margin at the earlier <a href="#2">step 2</a>. After adding the plot title, we then manually add the margin back here while retaining the alignment.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="co"># add and customize plot title</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"Most countries have a higher share of women</span><span className="sc">\n</span><span className="st">in their national parliaments than twenty years ago"</span>) <span className="sc">+</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span> </span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">16</span>,</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>                                  <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">30</span>)),</span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>        <span className="co"># add 20 units of margin to the four sides of the plot</span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>)))  </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>p8</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/women_seats_parliament_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>())</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a></span><br/>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/women in parliament.csv"</span>)</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a></span><br/>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>d2 <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>  <span className="co"># Is the percentage in 2020 higher than 2020? </span></span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>  <span className="co"># If true, then the arrows are plotted in blue, otherwise red</span></span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">increased =</span> percent_2020 <span className="sc">&gt;</span> percent_2000) <span className="sc">%&gt;%</span> </span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>  <span className="fu">arrange</span>(percent_2020)</span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a></span><br/>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a><span className="co"># country as ordered factor based on the percentage of seats held by women in 2020</span></span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a>d2<span className="sc">$</span>country <span className="ot">&lt;-</span> <span className="fu">factor</span>(d2<span className="sc">$</span>country, <span className="at">levels =</span> d2<span className="sc">$</span>country, <span className="at">ordered =</span> T)</span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a></span><br/>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a><span className="fu">head</span>(d2, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a></span><br/>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a></span><br/>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a><span className="co">#Create arrows to indicate the changes in the percentage of seats from 2000 (start of arrows) to 2020 (end of arrows).</span></span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d2 <span className="sc">%&gt;%</span> </span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> country, <span className="at">xend =</span> country, </span>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a>             <span className="at">y =</span> percent_2000, <span className="at">yend =</span> percent_2020,</span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a>             <span className="at">color =</span> increased)) <span className="sc">+</span></span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="at">arrow =</span> <span className="fu">arrow</span>(<span className="at">length =</span> <span className="fu">unit</span>(<span className="dv">8</span>, <span className="st">"pt"</span>)),</span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a>               <span className="at">linewidth =</span> <span className="dv">1</span>)  </span>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a>p1</span>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a></span><br/>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a></span><br/>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a><span className="co"># Facet the plots based on `region`, and flip the axes.</span></span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>  <span className="fu">facet_grid</span>(region <span className="sc">~</span> ., <span className="at">scales =</span> <span className="st">"free_y"</span>, <span className="at">space =</span> <span className="st">"free_y"</span>) <span className="sc">+</span></span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"firebrick4"</span>, <span className="st">"steelblue4"</span>)) <span className="sc">+</span></span>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> <span className="st">"off"</span>) </span>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a>p2</span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a></span><br/>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a></span><br/>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a><span className="co"># Add panel titles and x-axis labels manually</span></span>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a>  <span className="co"># add faceted panel titles</span></span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">region =</span> <span className="fu">c</span>(<span className="st">"East Asia &amp; Pacific"</span>, </span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a>                                     <span className="st">"Europe &amp; Central Asia"</span>)),</span>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="dv">7</span>, <span className="dv">10</span>), <span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, </span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>                <span className="at">label =</span> region), </span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, </span>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>,</span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a>            <span className="at">inherit.aes =</span> F) <span className="sc">+</span> </span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>  </span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a>  <span className="co"># add x-axis (country) labels</span></span>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">y =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">label =</span> country), </span>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a>            <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">hjust =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>  </span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>  <span className="co"># increase margin between the two subplots</span></span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.spacing.y =</span> <span className="fu">unit</span>(<span className="dv">30</span>, <span className="st">"pt"</span>)) </span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>p3</span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a></span><br/>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a></span><br/>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a><span className="co"># Remove the duplicated old (default) facet titles and x-axis (country) labels.</span></span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span>  </span>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">strip.text =</span> <span className="fu">element_blank</span>(), <span className="co"># remove default facet titles</span></span>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>()) <span className="sc">+</span> <span className="co"># remove default country labels</span></span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>) <span className="co"># remove the country title</span></span>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>p4</span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a></span><br/>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a></span><br/>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a><span className="co"># Revise the y-axis (percentage) labels, and remove its title.</span></span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span>   </span>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">50</span>, <span className="dv">10</span>),</span>
<span id="cb11-68"><a aria-hidden="true" href="#cb11-68" tabindex="-1"></a>                     <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste0</span>(x, <span className="st">"%"</span>)&#125;,</span>
<span id="cb11-69"><a aria-hidden="true" href="#cb11-69" tabindex="-1"></a>                     <span className="at">minor_breaks =</span> <span className="cn">NULL</span>, <span className="co"># remove the vertical minor grids</span></span>
<span id="cb11-70"><a aria-hidden="true" href="#cb11-70" tabindex="-1"></a>                     <span className="at">name =</span> <span className="cn">NULL</span>) <span className="sc">+</span> <span className="co"># remove the title</span></span>
<span id="cb11-71"><a aria-hidden="true" href="#cb11-71" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-72"><a aria-hidden="true" href="#cb11-72" tabindex="-1"></a>    <span className="co"># thinner horizontal grids</span></span>
<span id="cb11-73"><a aria-hidden="true" href="#cb11-73" tabindex="-1"></a>    <span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> .<span className="dv">2</span>), </span>
<span id="cb11-74"><a aria-hidden="true" href="#cb11-74" tabindex="-1"></a>    <span className="co"># increase the top margin of the axis labels</span></span>
<span id="cb11-75"><a aria-hidden="true" href="#cb11-75" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">15</span>))) </span>
<span id="cb11-76"><a aria-hidden="true" href="#cb11-76" tabindex="-1"></a>p5</span>
<span id="cb11-77"><a aria-hidden="true" href="#cb11-77" tabindex="-1"></a></span><br/>
<span id="cb11-78"><a aria-hidden="true" href="#cb11-78" tabindex="-1"></a></span><br/>
<span id="cb11-79"><a aria-hidden="true" href="#cb11-79" tabindex="-1"></a><span className="co"># Display the percentage of seats held by women in 2020.</span></span>
<span id="cb11-80"><a aria-hidden="true" href="#cb11-80" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb11-81"><a aria-hidden="true" href="#cb11-81" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(</span>
<span id="cb11-82"><a aria-hidden="true" href="#cb11-82" tabindex="-1"></a>    <span className="at">y =</span> <span className="fu">ifelse</span>(increased <span className="sc">==</span> T, percent_2020 <span className="sc">+</span> <span className="dv">4</span>, percent_2000 <span className="sc">-</span> <span className="dv">6</span>), </span>
<span id="cb11-83"><a aria-hidden="true" href="#cb11-83" tabindex="-1"></a>    <span className="at">label =</span> <span className="fu">round</span>(percent_2020, <span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="fu">paste0</span>(<span className="st">"%"</span>))) </span>
<span id="cb11-84"><a aria-hidden="true" href="#cb11-84" tabindex="-1"></a>p6</span>
<span id="cb11-85"><a aria-hidden="true" href="#cb11-85" tabindex="-1"></a></span><br/>
<span id="cb11-86"><a aria-hidden="true" href="#cb11-86" tabindex="-1"></a></span><br/>
<span id="cb11-87"><a aria-hidden="true" href="#cb11-87" tabindex="-1"></a><span className="co"># 7. Mark the start of the arrow as year 2000, and end of the arrow as 2020</span></span>
<span id="cb11-88"><a aria-hidden="true" href="#cb11-88" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span>   </span>
<span id="cb11-89"><a aria-hidden="true" href="#cb11-89" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> <span className="fu">tibble</span>(<span className="at">region =</span> <span className="st">"East Asia &amp; Pacific"</span>,</span>
<span id="cb11-90"><a aria-hidden="true" href="#cb11-90" tabindex="-1"></a>                          <span className="at">x =</span> <span className="dv">7</span>, <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">31</span>, <span className="fl">40.8</span>),</span>
<span id="cb11-91"><a aria-hidden="true" href="#cb11-91" tabindex="-1"></a>                          <span className="at">label =</span> <span className="fu">c</span>(<span className="fu">c</span>(<span className="st">"2000 |"</span>, <span className="st">"| 2020"</span>))),</span>
<span id="cb11-92"><a aria-hidden="true" href="#cb11-92" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> x, <span className="at">y =</span> y, <span className="at">label =</span> label),</span>
<span id="cb11-93"><a aria-hidden="true" href="#cb11-93" tabindex="-1"></a>            <span className="at">inherit.aes =</span> F, </span>
<span id="cb11-94"><a aria-hidden="true" href="#cb11-94" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">0</span>), </span>
<span id="cb11-95"><a aria-hidden="true" href="#cb11-95" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>,</span>
<span id="cb11-96"><a aria-hidden="true" href="#cb11-96" tabindex="-1"></a>            <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"darkgreen"</span>, <span className="st">"orange3"</span>)) </span>
<span id="cb11-97"><a aria-hidden="true" href="#cb11-97" tabindex="-1"></a>p7</span>
<span id="cb11-98"><a aria-hidden="true" href="#cb11-98" tabindex="-1"></a></span><br/>
<span id="cb11-99"><a aria-hidden="true" href="#cb11-99" tabindex="-1"></a></span><br/>
<span id="cb11-100"><a aria-hidden="true" href="#cb11-100" tabindex="-1"></a><span className="co"># Final polish-up.</span></span>
<span id="cb11-101"><a aria-hidden="true" href="#cb11-101" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span> </span>
<span id="cb11-102"><a aria-hidden="true" href="#cb11-102" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"Most countries have a higher share of women</span><span className="sc">\n</span><span className="st">in their national parliaments than twenty years ago"</span>) <span className="sc">+</span></span>
<span id="cb11-103"><a aria-hidden="true" href="#cb11-103" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span> </span>
<span id="cb11-104"><a aria-hidden="true" href="#cb11-104" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">16</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">30</span>)),</span>
<span id="cb11-105"><a aria-hidden="true" href="#cb11-105" tabindex="-1"></a>        <span className="co"># add 20 units of margin to the four sides of the plot</span></span>
<span id="cb11-106"><a aria-hidden="true" href="#cb11-106" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>)))  </span>
<span id="cb11-107"><a aria-hidden="true" href="#cb11-107" tabindex="-1"></a>p8</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Alongside arrow plots, line plots are commonly employed to illustrate the evolving trends over a period of time. In the following plot, we employ <Link to="../ggplot2-line-plot-gapminder"><strong>annotated lines and points</strong></Link> to highlight the significant changes in the human life span and population size from 1800 to 2015.</p>
<p><Link to="../ggplot2-line-plot-gapminder">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check the following <Link to="../ggplot2-line-plot-cigarettes"><strong>annotated line plot</strong></Link> that shows the changing popularity of smoking worldwide, in particular in the countries of the United States, France, and Germany.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Furthermore, ribbons are an attractive alternative to illustrate chronological changes with engaging visual appeal. Check out this awesome <Link to="../ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link>, which shows dynamic shifts in the migrant population to the United States from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}