import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrRemove(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Remove Matched Patterns from a String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<ul>
<li><code>str_remove()</code> removes the <em>first match</em> in each string element</li>
<li><code>str_remove_all()</code> removes <em>all matches</em> in each string element</li>
</ul>
<hr/>
<p><strong><code>str_remove()</code></strong> removes the <strong>first</strong> matched component in each input vector element. It is equivalent to <code>str_replace()</code> with replacement by an empty quote.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>phones <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="st">"Mia Smith, 728*971*9652"</span>, </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="st">"Max Lee, 683*976*9876"</span>,</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="st">"Ava Johnson, 912*254*3387"</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">str_remove</span>(phones, <span className="at">pattern =</span> <span className="st">"</span><span className="sc">\\</span><span className="st">*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "Mia Smith, 728971*9652"   "Max Lee, 683976*9876"     "Ava Johnson, 912254*3387"</code></pre>
</div>
</div>
<p><strong><code>str_remove_all()</code></strong> removes <strong>all</strong> the matches from each string element.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_remove_all</span>(phones, <span className="at">pattern =</span> <span className="st">"</span><span className="sc">\\</span><span className="st">*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "Mia Smith, 7289719652"   "Max Lee, 6839769876"     "Ava Johnson, 9122543387"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}