import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function RowWiseTibbleCreation(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Tibbles in a Row-Wise Manner</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>tribble()</code> is short for transposed tibble, and is another way to create a tibble on a row-wise basis in a very intuitive manner.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">tribble</span>(</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="sc">~</span>Name,      <span className="sc">~</span>Age,  <span className="sc">~</span>City,</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="st">"Alice"</span>,    <span className="dv">25</span>,    <span className="st">"New York"</span>,</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="st">"Bob"</span>,      <span className="dv">30</span>,    <span className="st">"Los Angeles"</span>,</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="st">"Charlie"</span>,  <span className="dv">35</span>,    <span className="st">"Chicago"</span></span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  Name      Age City       
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;chr&gt;      
<br/>1 Alice      25 New York   
<br/>2 Bob        30 Los Angeles
<br/>3 Charlie    35 Chicago    </code></pre>
</div>
</div>
<p><code>tribble()</code> will create a <em>list</em> column if any value in that column is not a scalar (vector of length 1)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">tribble</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="sc">~</span>Name,      <span className="sc">~</span>Age,  <span className="sc">~</span>City,</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="st">"Alice"</span>,    <span className="dv">25</span>,    <span className="fu">c</span>(<span className="st">"New York"</span>, <span className="st">"Beijing"</span>),</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="st">"Bob"</span>,      <span className="dv">30</span>,    <span className="fu">c</span>(<span className="st">"Los Angeles"</span>, <span className="st">"Chicago"</span>, <span className="st">"Princeton"</span>),</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="st">"Charlie"</span>,  <span className="dv">35</span>,    <span className="st">"Chicago"</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  Name      Age City     
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;list&gt;   
<br/>1 Alice      25 &lt;chr [2]&gt;
<br/>2 Bob        30 &lt;chr [3]&gt;
<br/>3 Charlie    35 &lt;chr [1]&gt;</code></pre>
</div>
</div>
</main>
</div>
</div>
)}