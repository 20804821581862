import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderFacetedPanels from '../tutorial/reorder_subplots_default_alphabetical.png';

export default function Ggplot2ReorderFacetedPanels(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Change the Order of Faceted Panels (Subplots) in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In <Link to="../4-ggplot2-reorder-bars">this earlier tutorial</Link>, you learned four powerful approaches to reorder a bar graph in ggplot2. These four approaches are broadly applicable to reorder other graphical elements. And in this article, we’ll use these techniques to reorder faceted panels (subplots).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>() <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="co"># make subplot titles more prominent</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">strip.text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">14</span>, <span className="at">face =</span> <span className="st">"bold"</span>)))</span></code></pre></div>
</div>
<p>By default, faceted panels are arranged in the alphabetical order of the subplot titles.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(Sepal.Length, Sepal.Width)) <span className="sc">+</span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">size =</span> <span className="dv">6</span>, <span className="at">color =</span> <span className="st">"turquoise4"</span>) <span className="sc">+</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>Species)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderFacetedPanels} /></p>
</figure>
</div>
</div>
</div>
<p>To <strong>reorder the faceted panels</strong>, the generic approach is to <strong>convert the faceting variable</strong>, e.g., <code>Species</code>, <strong>into a <em>factor</em> with specified level order</strong>, before feeding the dataset into ggplot2. Below you’ll learn how to rearrange the faceted panels:</p>
<ul>
<li><a href="#a">manually in a particular order</a></li>
<li><a href="#b">based on summary statistics of the dataset</a></li>
</ul>
<section className="level4" id="a">
<h4 className="anchored" data-anchor-id="a">“Manually” reorder faceted panels in a particular order</h4>
<p>In this example, the <code>setosa</code> species is relocated from the first to the last panel. This is achieved by using the <code>mutate()</code> and <code>factor()</code> function, with explicitly indicated levels’ order.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">Species =</span> <span className="fu">factor</span>(</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>    Species, </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>    <span className="co"># specify the desired order to be displayed</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="at">levels =</span> <span className="fu">c</span>(<span className="st">"versicolor"</span>, <span className="st">"virginica"</span>, <span className="st">"setosa"</span>))) <span className="sc">%&gt;%</span> </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(Sepal.Length, Sepal.Width)) <span className="sc">+</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">size =</span> <span className="dv">6</span>, <span className="at">color =</span> <span className="st">"turquoise4"</span>) <span className="sc">+</span></span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>Species)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_subplots_manual_particular_order.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="b">
<h4 className="anchored">Reorder faceted panels based on summary statistics</h4>
<p>An earlier tutorial discussed how to <Link to="../5-ggplot2-reorder-violin-plots">reorder violin plots</Link> based on group statistics using the <code>fct_reorder()</code> function from the <code>forcats</code> package. Using the same technique, you can easily change the subplots order, for instance, based on the average of the <code>Sepal.Width</code> variable in an ascending order.</p>
<p>This following code generates the same plot as the one above.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a></span><br/>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="co"># reorder the 'Species' variable by the average of 'Sepal.Width'</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">Species =</span> <span className="fu">fct_reorder</span>(Species, Sepal.Width, <span className="at">.fun =</span> mean)) <span className="sc">%&gt;%</span> </span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  </span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(Sepal.Length, Sepal.Width)) <span className="sc">+</span></span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">size =</span> <span className="dv">6</span>, <span className="at">color =</span> <span className="st">"turquoise4"</span>) <span className="sc">+</span></span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>Species)</span></code></pre></div>
</div>
<hr/>
<h3 className="anchored" data-anchor-id="b">
<strong><em>Apply Your Learning in Practice!</em></strong> 🏆
</h3>
<p>The following <Link to="../../R/gallery/ggplot2-line-plot-life-expectancy"><strong>faceted line plots</strong></Link> (of different continents) applies the same facet reordering technique discussed above, and effectively displays the differences in life expectancy between different continents.</p>
<p><Link to="../../R/gallery/ggplot2-line-plot-life-expectancy"><img className="img-fluid" src="graphics/lines_longevity_theme_completed.png"/></Link></p>
<hr/>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p>The <Link to="https://hafen.github.io/geofacet/index.html"><code>geofacet</code></Link> package developed by Ryan Hafen is a fascinating ggplot2 extension package that allows <strong>reordering faceted subplots <em>in a map layout</em></strong>. Check the following <Link to="../../R/gallery/ggplot2-geofacet-US-states"><strong>faceted donut plots in a U.S. map layout</strong></Link> that visualize the voting results of the 2016 U.S. presidential election in each state.</p>
<p><Link to="../../R/gallery/ggplot2-geofacet-US-states"><img className="img-fluid" src="graphics/geofacet_election_completed.png"/></Link></p>
</section>
</main>
</div>
</div>
)}