
export function ChangeImageUrlPython() {
    const images =  document.querySelectorAll('img:not(.logo-diff):not(#bookstar):not(#headerTop > a > img):not(#ggplot2-book):not(.cover-img)')
    images.forEach((image, index) => {
        // first image is DataBrewer logo, second image is the first image of an article
        if (index !=0 & index != 1){
            const currentSrc = image.getAttribute('src');
            const newSrc = "https://s3.amazonaws.com/databrewer/media/" + currentSrc
            // //console.log('newSrc', newSrc)
            image.setAttribute('src', newSrc)
        }
        
    })
}
 
export function ChangeImageUrlR() {
    // exclude the logo image appearing in all R data wrangling pages
    // exclude the header logo image as well
    const images =  document.querySelectorAll('img:not(.logo-diff):not(#bookstar):not(#headerTop > a > img):not(#ggplot2-book):not(.cover-img)')
    // //console.log(images)
    // const path = window.location.pathname;
    images.forEach((image, index) => {
        if (index != 0){
            // console.log(index)
            const currentSrc = image.getAttribute('src');
            // console.log(currentSrc)
            const newSrc = "https://s3.amazonaws.com/databrewer/media/" + currentSrc
            // const newSrc = "https://databrewer.s3.amazonaws.com/media/" + currentSrc
            // console.log(newSrc)
            image.setAttribute('src', newSrc)
    }
    })
}


export function ChangeDatasetUrlR(){
    const links = document.querySelectorAll('a.downloadData')
    // console.log(links)
    links.forEach((link) => {
        const currentSrc = link.getAttribute('href')
        //console.log('currentSrc', currentSrc)
        // const newSrc = "https://s3.amazonaws.com/3codeacademy/" + currentSrc
        const newSrc = "https://3codeacademy.s3.amazonaws.com/" + currentSrc
        link.setAttribute('href', newSrc)
    })
}

export function changeAttribute(tag, oldAttr, newAttr) {
    const Tags = document.querySelectorAll(tag); 
    Tags.forEach((tag) => {
        const tagValue = tag.getAttribute(oldAttr);
        tag.removeAttribute(oldAttr);
        tag.setAttribute(newAttr, tagValue);
    }) 
}

