import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PivotLongerPart3(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Gather Columns into Longer and Narrower Dataset (3/4): <em>Deal with Jammed Variables in Column Names</em></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>A more challenging situation occurs when you have multiple variables crammed into the column names. For example, consider the <code>who</code> dataset from the World Health Organization Global Tuberculosis Report. For ease of demo, we’ll use a subset of this data: using <Link to="/R/data-wrangling/dplyr/9-select-rows"><code>slice_max()</code></Link> to select the top 20 rows of records of the most tuberculosis outbreaks.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># select top-20 rows containing most outbreaks in male at age 15-24 </span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>who.max <span className="ot">&lt;-</span> who <span className="sc">%&gt;%</span> </span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="fu">slice_max</span>(<span className="at">order_by =</span> new_sp_m1524, <span className="at">n =</span> <span className="dv">20</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>who.max</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 20 × 60
<br/>   country iso2  iso3   year new_sp_m014 new_sp_m1524 new_sp_m2534 new_sp_m3544 new_sp_m4554 new_sp_m5564 new_sp_m65 new_sp_f014 new_sp_f1524 new_sp_f2534 new_sp_f3544 new_sp_f4554 new_sp_f5564 new_sp_f65 new_sn_m014 new_sn_m1524 new_sn_m2534 new_sn_m3544 new_sn_m4554 new_sn_m5564 new_sn_m65
<br/>   &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt; &lt;dbl&gt;       &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;      &lt;dbl&gt;       &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;      &lt;dbl&gt;       &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;        &lt;dbl&gt;      &lt;dbl&gt;
<br/> 1 India   IN    IND    2010        4871        78278        82757        90440        81210        60766      38442        8544        53415        49425        34035        22719        15527       9735          NA           NA           NA           NA           NA           NA         NA
<br/> 2 India   IN    IND    2009        5001        78177        84003        90830        80097        59163      37419        8576        51945        49747        33754        22032        14929       8944          NA           NA           NA           NA           NA           NA         NA
<br/> 3 India   IN    IND    2011        4649        78096        82762        89706        82921        63625      42443        8336        53958        49227        34698        23977        17182      10731          NA           NA           NA           NA           NA           NA         NA
<br/> 4 India   IN    IND    2008        4648        77121        83798        90498        78815        56928      36079        8319        51485        49887        33664        21486        14407       8357          NA           NA           NA           NA           NA           NA         NA
<br/> 5 India   IN    IND    2012        4697        75502        79594        88111        82356        63814      41322        8260        53975        47511        33378        23267        17300      10502          NA           NA           NA           NA           NA           NA         NA
<br/> 6 India   IN    IND    2007        4305        73947        83850        88045        76408        53414      31922        7575        50289        49519        32407        20316        13195       7395          NA           NA           NA       250051           NA           NA         NA
<br/> 7 India   IN    IND    2006        3566        68346        79037        82939        71621        49320      28716        6963        47702        47420        31128        18870        11752       6417          NA           NA           NA           NA           NA           NA         NA
<br/> 8 India   IN    IND    2005        3185        62620        74678        76870        64843        43038      24726        6292        45136        45629        28577        17042        10513       5408          NA           NA           NA           NA           NA           NA         NA
<br/> 9 India   IN    IND    2004        3018        57208        72132        74450        62173        40769      22388        5860        41017        42808        27000        16121         9705       5016          NA           NA           NA           NA           NA           NA         NA
<br/>10 India   IN    IND    2003        2411        47251        61758        63587        52865        33739      18018        4745        34511        36317        23320        14055         8322       3985          NA           NA           NA           NA           NA           NA         NA
<br/># ℹ 10 more rows
<br/># ℹ 35 more variables: new_sn_f014 &lt;dbl&gt;, new_sn_f1524 &lt;dbl&gt;, new_sn_f2534 &lt;dbl&gt;, new_sn_f3544 &lt;dbl&gt;, new_sn_f4554 &lt;dbl&gt;, new_sn_f5564 &lt;dbl&gt;, new_sn_f65 &lt;dbl&gt;, new_ep_m014 &lt;dbl&gt;, new_ep_m1524 &lt;dbl&gt;, new_ep_m2534 &lt;dbl&gt;, new_ep_m3544 &lt;dbl&gt;, new_ep_m4554 &lt;dbl&gt;, new_ep_m5564 &lt;dbl&gt;, new_ep_m65 &lt;dbl&gt;,
<br/>#   new_ep_f014 &lt;dbl&gt;, new_ep_f1524 &lt;dbl&gt;, new_ep_f2534 &lt;dbl&gt;, new_ep_f3544 &lt;dbl&gt;, new_ep_f4554 &lt;dbl&gt;, new_ep_f5564 &lt;dbl&gt;, new_ep_f65 &lt;dbl&gt;, newrel_m014 &lt;dbl&gt;, newrel_m1524 &lt;dbl&gt;, newrel_m2534 &lt;dbl&gt;, newrel_m3544 &lt;dbl&gt;, newrel_m4554 &lt;dbl&gt;, newrel_m5564 &lt;dbl&gt;, newrel_m65 &lt;dbl&gt;, newrel_f014 &lt;dbl&gt;,
<br/>#   newrel_f1524 &lt;dbl&gt;, newrel_f2534 &lt;dbl&gt;, newrel_f3544 &lt;dbl&gt;, newrel_f4554 &lt;dbl&gt;, newrel_f5564 &lt;dbl&gt;, newrel_f65 &lt;dbl&gt;</code></pre>
</div>
</div>
<p><code>country</code>, <code>iso2</code>, <code>iso3</code>, and <code>year</code> are already variables, so they can be left as is. But the columns from <code>new_sp_m014</code> to the last column <code>newrel_f65</code> encode four pieces of information in their names:</p>
<ul>
<li>The <code>new_</code> and <code>new</code> prefix indicates that the counts are new tuberculosis cases.</li>
<li><code>sp</code>, <code>sn</code>, <code>ep</code>, and <code>rel</code> describe the diagnosis result: <code>sp</code>, positive pulmonary smear; <code>sn</code>, negative pulmonary smear; <code>ep</code>, extra pulmonary; <code>rel</code>, relapse.</li>
<li><code>m</code> an <code>f</code> indicates the gender: <code>m</code>, male; <code>f</code>, female.</li>
<li>The digits show the age ranges: <code>014</code>, 0-14 years of age; <code>1524</code>, 15-24 years of age; <code>2535</code>, 25-35 years of age, etc.</li>
</ul>
<p>It’s desirable to break these four pieces of information into four separate columns during pivoting. We can use <code>names_to</code> to specify names of the four new column to create: <code>status</code>, <code>diagnosis</code>, <code>gender</code> and <code>age</code>. Meanwhile, we use <code>names_pattern</code> to extract values for each of the four new columns: you give it a <Link to="/R/data-wrangling/regular-expression/0-introduction">regular expression</Link> containing <Link to="/R/data-wrangling/regular-expression/8-capture-group">capture groups</Link> defined by a pair of parentheses <code>()</code>, and it puts each capture group into each associated column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>who.max <span className="sc">%&gt;%</span> <span className="fu">pivot_longer</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="at">cols =</span> new_sp_m014 <span className="sc">:</span> <span className="fu">last_col</span>(),</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># create 3 new columns</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="at">names_to =</span> <span className="fu">c</span>(<span className="st">"status"</span>, <span className="st">"diagnosis"</span>, <span className="st">"gender"</span>, <span className="st">"age"</span>),</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="co"># define the associated capture group for each new column</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="at">names_pattern =</span> <span className="st">"(new)_?(.*)_(.)(.*)"</span>,</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="at">values_to =</span> <span className="st">"count"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 1,120 × 9
<br/>   country iso2  iso3   year status diagnosis gender age   count
<br/>   &lt;chr&gt;   &lt;chr&gt; &lt;chr&gt; &lt;dbl&gt; &lt;chr&gt;  &lt;chr&gt;     &lt;chr&gt;  &lt;chr&gt; &lt;dbl&gt;
<br/> 1 India   IN    IND    2010 new    sp        m      014    4871
<br/> 2 India   IN    IND    2010 new    sp        m      1524  78278
<br/> 3 India   IN    IND    2010 new    sp        m      2534  82757
<br/> 4 India   IN    IND    2010 new    sp        m      3544  90440
<br/> 5 India   IN    IND    2010 new    sp        m      4554  81210
<br/> 6 India   IN    IND    2010 new    sp        m      5564  60766
<br/> 7 India   IN    IND    2010 new    sp        m      65    38442
<br/> 8 India   IN    IND    2010 new    sp        f      014    8544
<br/> 9 India   IN    IND    2010 new    sp        f      1524  53415
<br/>10 India   IN    IND    2010 new    sp        f      2534  49425
<br/># ℹ 1,110 more rows</code></pre>
</div>
</div>
<blockquote className="blockquote">
<p>The above script is equivalent to first calling <code>pivot_longer(names_to = "condition", values_to = "count")</code>, and then split column <code>condition</code> into separate columns (first extract the four pieces of information with capture groups, and then put the pieces into four separate columns using <code>stringr::str_match()</code> as demonstrated <Link to="/R/data-wrangling/regular-expression/8-capture-group#capture_group_tibble#capture_group_tibble">here</Link>).</p>
</blockquote>
<p>We could go one step further using <code>names_transform</code> and the <Link to="https://readr.tidyverse.org/index.html"><code>readr</code></Link> functions to convert <code>gender</code> and <code>age</code> to factors. In the following script, we create lambda functions on the fly, marked by the tilde sign <code>~</code>, and use <code>.x</code> to refer to columns being processed.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>who <span className="sc">%&gt;%</span> <span className="fu">pivot_longer</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">cols =</span> new_sp_m014 <span className="sc">:</span> <span className="fu">last_col</span>(),</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">names_to =</span> <span className="fu">c</span>(<span className="st">"diagnosis"</span>, <span className="st">"gender"</span>, <span className="st">"age"</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">names_pattern =</span> <span className="st">"new_?(.*)_(.)(.*)"</span>,</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="co"># convert gender and age to normal and ordered factor, respectively</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="at">names_transform =</span> <span className="fu">list</span>(</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>    <span className="at">gender =</span> <span className="sc">~</span> readr<span className="sc">::</span><span className="fu">parse_factor</span>(.x, <span className="at">levels =</span> <span className="fu">c</span>(<span className="st">"f"</span>, <span className="st">"m"</span>)),</span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>    <span className="at">age =</span> <span className="sc">~</span> readr<span className="sc">::</span><span className="fu">parse_factor</span>(</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>      .x, <span className="at">levels =</span> <span className="fu">c</span>(<span className="st">"014"</span>, <span className="st">"1524"</span>, <span className="st">"2534"</span>, <span className="st">"3544"</span>, <span className="st">"4554"</span>, <span className="st">"5564"</span>, <span className="st">"65"</span>),</span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>      <span className="at">ordered =</span> T)</span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  ),</span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>  <span className="at">values_to =</span> <span className="st">"count"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 405,440 × 8
<br/>   country     iso2  iso3   year diagnosis gender age   count
<br/>   &lt;chr&gt;       &lt;chr&gt; &lt;chr&gt; &lt;dbl&gt; &lt;chr&gt;     &lt;fct&gt;  &lt;ord&gt; &lt;dbl&gt;
<br/> 1 Afghanistan AF    AFG    1980 sp        m      014      NA
<br/> 2 Afghanistan AF    AFG    1980 sp        m      1524     NA
<br/> 3 Afghanistan AF    AFG    1980 sp        m      2534     NA
<br/> 4 Afghanistan AF    AFG    1980 sp        m      3544     NA
<br/> 5 Afghanistan AF    AFG    1980 sp        m      4554     NA
<br/> 6 Afghanistan AF    AFG    1980 sp        m      5564     NA
<br/> 7 Afghanistan AF    AFG    1980 sp        m      65       NA
<br/> 8 Afghanistan AF    AFG    1980 sp        f      014      NA
<br/> 9 Afghanistan AF    AFG    1980 sp        f      1524     NA
<br/>10 Afghanistan AF    AFG    1980 sp        f      2534     NA
<br/># ℹ 405,430 more rows</code></pre>
</div>
</div>
</main>
</div>
</div>
)}