import { Link } from 'react-router-dom'
import RpackagesWebp from '../media/R_packages.webp'
import Rpacakges from '../media/R_packages.png'
import gallery from '../media/R_Gallery_cover.png'
import galleryWebp from '../media/R_Gallery_cover.webp'
import ggplot2Logo from '../media/logo_ggplot2.png'
import ggplot2LogoWebp from '../media/logo_ggplot2.webp'
import RlogoWebp from '../media/logo_R.webp'


export default function RLandingPage () {
    return (
        <div className='mg-t-50'>
            <div className='container-landing'>
                <div id='data-wrangling'>
                    <div  className='r-logo'>
                        <img src={RlogoWebp}></img>
                    </div>
                   
                    <div className='text-center'>
                        <h1>
                            Mastering Data Wrangling: A Pro's Guide
                        </h1>
                        <h2 className='fc-super-light-grey fw-500 fs-24'>
                            Turn Messy Data into Clean and Usable Format
                        </h2>
                    </div>
                    <div id='banner' className='banner-rpackages'>
                        <picture>
                            <source type='image/webp' srcset={RpackagesWebp} />
                            <img src={Rpacakges}></img>
                        </picture>
                    </div>
                    <div className='buttons'>
                        <Link to='./data-wrangling'>
                           <div className='button-text'>
                                Enter Modules
                           </div>          
                        </Link>
                    </div>
                </div>
               
            </div>
            <div id='r-gallery' className='mg-t-50 gallery-section'>
                <div className='text-center mg-b-30'>
                    <h1>
                        Data Visualization with ggplot2 Ecosystem
                    </h1>
                    <h2 className='fc-super-light-grey fw-500 fs-24'>A picture is worth a thousand words.</h2>
                </div>
               
                <div className='layout'>
                    <div className='card-layout'>
                        <div id='top' className='card-image  flexCenter'>
                            <picture>
                                <source type='image/webp' srcset={ggplot2LogoWebp} />
                                <img className='visual-image' src={ggplot2Logo}></img>
                            </picture>
                        </div>
                        
                        <div id='bottom' className='text-center flexCenter '>
                            <Link to='./visualization/introduction'>
                                <div className='h-20 buttons'>
                                    Enter Tutorial
                                </div>   
                            </Link>                         
                        </div>                       
                                             
                    </div>
                    <div id='banner' className='banner-gallery '>
                        <Link to='./gallery'>
                            <picture>
                                <source type='image/webp' srcset={galleryWebp} />
                                <img src={gallery}></img>
                            </picture>
                        </Link>

                    </div>

                </div>
                
            </div>
           
        </div>
    )
}