import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Quantifiers(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Specify the Number of Matched Patterns</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>Quantifiers</strong> specify the number of occurrences of the <strong>immediately preceding</strong> character.</p>
<p><strong><code>?</code> (zero or one occurrence) </strong>, e.g., <code>colou?r</code> matches “color” and “colour”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Life's colorful"</span>, <span className="st">"vivid colours"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, <span className="st">"colou?r"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ Life's &lt;color&gt;ful
<br/>[2] │ vivid &lt;colour&gt;s</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(a, <span className="st">"colou?r"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]    
<br/>[1,] "color" 
<br/>[2,] "colour"</code></pre>
</div>
</div>
</div>
<p><strong><code>+</code> (one or more) </strong>, e.g., <code>a+</code> matches one or more consecutive letter “a”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"bb"</span>, <span className="st">"ba++"</span>, <span className="st">"baaa-naaaa-nAAA"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"a+"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ bb
<br/>[2] │ b&lt;a&gt;++
<br/>[3] │ b&lt;aaa&gt;-n&lt;aaaa&gt;-nAAA</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"a+"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]  [,2]  
<br/>[1,] ""    ""    
<br/>[2,] "a"   ""    
<br/>[3,] "aaa" "aaaa"</code></pre>
</div>
</div>
</div>
<p><strong><code>*</code> (zero or more) </strong>, e.g., <code>ba*</code> matches “b” followed by zero or more “a” characters.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"ba*"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;b&gt;&lt;b&gt;
<br/>[2] │ &lt;ba&gt;++
<br/>[3] │ &lt;baaa&gt;-naaaa-nAAA</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"ba*"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]   [,2]
<br/>[1,] "b"    "b" 
<br/>[2,] "ba"   ""  
<br/>[3,] "baaa" ""  </code></pre>
</div>
</div>
</div>
<p><strong><code>&#123;n&#125;</code> (exactly n times) </strong>, e.g., <code>ba&#123;3&#125;</code> matches “baaa”.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"ba&#123;3&#125;"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ bb
<br/>[2] │ ba++
<br/>[3] │ &lt;baaa&gt;-naaaa-nAAA</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"ba&#123;3&#125;"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]  
<br/>[1,] ""    
<br/>[2,] ""    
<br/>[3,] "baaa"</code></pre>
</div>
</div>
</div>
<p><strong><code>&#123;n,&#125;</code> (at Least n times) </strong>, e.g., <code>ba&#123;2,&#125;</code> match “baa”, “baaa”, “baaaa”, and so on. Note that there should be <em>no white space inside the curly braces</em>.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"ba&#123;2,&#125;"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ bb
<br/>[2] │ ba++
<br/>[3] │ &lt;baaa&gt;-naaaa-nAAA</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb21"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb21-1"><a aria-hidden="true" href="#cb21-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"ba&#123;2,&#125;"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]  
<br/>[1,] ""    
<br/>[2,] ""    
<br/>[3,] "baaa"</code></pre>
</div>
</div>
</div>
<p><strong><code>&#123;n,m&#125;</code> (between n and m times) </strong>, e.g., <code>ba&#123;1,2&#125;</code> matches “ba” or “baa”. Again, <em>no white space</em> should be present in the curly braces.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb23"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb23-1"><a aria-hidden="true" href="#cb23-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"ba&#123;1,2&#125;"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ bb
<br/>[2] │ &lt;ba&gt;++
<br/>[3] │ &lt;baa&gt;a-naaaa-nAAA</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb25"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb25-1"><a aria-hidden="true" href="#cb25-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"ba&#123;1,2&#125;"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1] 
<br/>[1,] ""   
<br/>[2,] "ba" 
<br/>[3,] "baa"</code></pre>
</div>
</div>
</div>
<section className="level3" id="combination-of-character-class-and-quantifier">
<h3 className="anchored" data-anchor-id="combination-of-character-class-and-quantifier">Combination of Character Class and Quantifier</h3>
<p><code>[0-9]&#123;3,4&#125;</code> or <code>[:digit:]&#123;3,4&#125;</code> matches 3 to 4 consecutive numeric characters. The following examples extract the area and subscriber code from telephone numbers.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb27"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb27-1"><a aria-hidden="true" href="#cb27-1" tabindex="-1"></a>s <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Alice: 137-807-6865"</span>, <span className="st">"Mike: 732-987-1986"</span>)</span>
<span id="cb27-2"><a aria-hidden="true" href="#cb27-2" tabindex="-1"></a>p <span className="ot">&lt;-</span> <span className="st">"[:digit:]&#123;3,4&#125;"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb28"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb28-1"><a aria-hidden="true" href="#cb28-1" tabindex="-1"></a><span className="fu">str_view_all</span>(s, p)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ Alice: &lt;137&gt;-&lt;807&gt;-&lt;6865&gt;
<br/>[2] │ Mike: &lt;732&gt;-&lt;987&gt;-&lt;1986&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb30"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb30-1"><a aria-hidden="true" href="#cb30-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(s, p, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]  [,2]  [,3]  
<br/>[1,] "137" "807" "6865"
<br/>[2,] "732" "987" "1986"</code></pre>
</div>
</div>
</div>
<p>As comparison, <code>[:digit:]</code> alone without the <code>&#123;3,4&#125;</code> quantifier considers each individual number as a match.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb32"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb32-1"><a aria-hidden="true" href="#cb32-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(s, <span className="st">"[:digit:]"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1] [,2] [,3] [,4] [,5] [,6] [,7] [,8] [,9] [,10]
<br/>[1,] "1"  "3"  "7"  "8"  "0"  "7"  "6"  "8"  "6"  "5"  
<br/>[2,] "7"  "3"  "2"  "9"  "8"  "7"  "1"  "9"  "8"  "6"  </code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}