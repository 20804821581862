import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ChangeLegendRowNumber from '../tutorial/legend_row_base.png';

export default function Ggplot2ChangeLegendRowNumber(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Change Number of Rows and Columns in Legend Keys in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains <strong>how to split a long legend into multiple rows or columns</strong>.</p>
<hr/>
<section className="level4" id="start-with-a-long-legend">
<h4 className="anchored" data-anchor-id="start-with-a-long-legend">Start with a long legend</h4>
<p>Let’s first build an ordered stacked bar plot, with a long vertical legend positioned at the top left corner of the plot. <span>(Check <Link to="../4-ggplot2-reorder-bars"><strong>here</strong></Link> to learn fundamental techniques to reorder graphic elements, and <Link to="../5-ggplot2-reorder-violin-plots"><strong>here</strong></Link> to learn advanced reordering based on <em>group statistics</em> as used in this article)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(forcats) <span className="co"># to reorder rows</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>p <span className="ot">&lt;-</span> mpg <span className="sc">%&gt;%</span> </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="co"># reorder bars based on the counts in each manufacturer</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">manufacturer =</span> <span className="fu">fct_reorder</span>(</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>    manufacturer, manufacturer, length)) <span className="sc">%&gt;%</span> </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(manufacturer, <span className="at">fill =</span> class)) <span className="sc">+</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">geom_bar</span>(<span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Pastel2"</span>) <span className="sc">+</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>    <span className="co"># rotate and right justify axis label</span></span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">45</span>, <span className="at">hjust =</span> <span className="dv">1</span>), </span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">3</span>, .<span className="dv">7</span>))</span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ChangeLegendRowNumber} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="divide-legend-into-multiple-rows">
<h4 className="anchored" data-anchor-id="divide-legend-into-multiple-rows">Divide legend into multiple rows</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(<span className="at">nrow =</span> <span className="dv">3</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_row_guides.png"/></p>
</figure>
</div>
</div>
</div>
<p>Equivalently, the <code>guides()</code> can be written in its associated <code>scale_*</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_brewer</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="at">palette =</span> <span className="st">"Pastel2"</span>,</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># Note to use 'guide', without the plural 's'</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="at">guide =</span> <span className="fu">guide_legend</span>(<span className="at">nrow =</span> <span className="dv">3</span>)) </span></code></pre></div>
</div>
</section>
<section className="level4" id="adjust-legend-title-position">
<h4 className="anchored" data-anchor-id="adjust-legend-title-position">Adjust legend title position</h4>
<p>With a more horizontally oriented legend, it is desirable to <strong>center justify the legend title</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="at">nrow =</span> <span className="dv">3</span>,</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>))) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_row_title_position.png"/></p>
</figure>
</div>
</div>
</div>
<p>Or consider putting the legend title on the left side and rotated at 90°.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">nrow =</span> <span className="dv">3</span>, </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">title.theme =</span> <span className="fu">element_text</span>(</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">angle =</span> <span className="dv">90</span>))) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_row_title_theme.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}