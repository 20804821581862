import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2HeatmapAfricanPopulation from '../graphics/map_African_population_completed.png'; 
import imgGgplot2HeatmapAfricanPopulationWebp from '../graphics/map_African_population_completed.webp'; 
export default function Ggplot2HeatmapAfricanPopulation(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize African population with Heatmap Using ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2HeatmapAfricanPopulationWebp} />
    <img className="cover-img" src={imgGgplot2HeatmapAfricanPopulation} />
  </picture>

<p>This article visualizes the African population density. The code is adapted from the work of <Link to="https://github.com/gkaramanis/tidytuesday/blob/master/2021/2021-week45/afrimapr.R">Georgios Karamanis</Link>. The dataset is available via the <Link to="https://github.com/afrimapr/afrilearndata">afrilearndata</Link> package.</p>
<p><strong>Major techniques covered in this article include:</strong></p>
<ul>
<li><a href="#Google_Fonts">Use Google Fonts.</a></li>
<li><a href="#heatmap">Create a heatmap.</a></li>
<li><a href="#color_scale_transform">Color scale transformation to address high skewness in data distribution.</a></li>
<li><a href="#theme">Theme customization.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># the African population dataset</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># install.packages("remotes") # if not already installed</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># remotes::install_github("afrimapr/afrilearndata") # if fails, try restarting R</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">library</span>(afrilearndata) </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="co"># packages for data cleanup</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a><span className="fu">library</span>(raster) </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a><span className="fu">library</span>(sp)</span></code></pre></div>
</div>
<p><span id="Google_Fonts">Make “Paytone One” font available to use in ggplot2. More fonts can be found in <Link to="https://fonts.google.com/">Google Fonts</Link>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Paytone One"</span>, <span className="at">family =</span> <span className="st">"Paytone One"</span>)</span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a><span className="fu">showtext.auto</span>() <span className="co"># use 'showtext' to draw text</span></span></code></pre></div>
</div>
<p>Prepare the dataset into desired structure.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>afripop_df <span className="ot">&lt;-</span> afripop2020 <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">as.data.frame</span>(<span className="at">xy =</span> <span className="cn">TRUE</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">rename</span>(<span className="at">pop =</span> <span className="dv">3</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span><span className="fu">is.na</span>(pop)) <span className="sc">%&gt;%</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a></span><br/>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a><span className="fu">head</span>(afripop_df, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 3
<br/>      x     y   pop
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>1  9.29  37.3  29.3
<br/>2  9.46  37.3  31.7
<br/>3  9.62  37.3  37.0
<br/>4  9.79  37.3 210. </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="heatmap">Create a heatmap with <code>geom_raster()</code>. Compared with <code>geom_tile()</code> (as used in this <Link to="../ggplot2-heatmap-vaccine-for-polio">awesome example</Link>), <code>geom_raster()</code> is a high-performance alternative, and does not draw cell outlines; given the size of the heatmap (a grid of ~92,000 cells), it is a better option.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> afripop_df <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>() <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_raster</span>(<span className="fu">aes</span>(x, y, <span className="at">fill =</span> pop)) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="fl">1.1</span>) </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a></span><br/>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_African_population_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Due to the very sparse distribution of high-density values (corresponding to the light blue dots), it is very difficult to discern the population distribution pattern by this step.</p>
<p><span id="color_scale_transform">Adjust the color scale. Use <strong><code>trans = "pseudo_log"</code></strong> to create a nonlinear color scale to preferentially highlight data with high population density.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>fff <span className="ot">&lt;-</span> <span className="cf">function</span>(x)&#123;x<span className="sc">^</span>.<span className="dv">2</span>&#125;</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a></span><br/>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">trans =</span> <span className="st">"pseudo_log"</span>, <span className="at">option =</span> <span className="st">"G"</span>,</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>                       <span className="at">breaks =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">1000</span>, <span className="dv">5000</span>, <span className="dv">20000</span>),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>                       <span className="at">labels =</span> <span className="cf">function</span>(x) &#123;<span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">1000</span>, <span className="st">"K"</span>)&#125; )</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_color_scale_pseudo_log.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_African_population_color_scale_pseudo_log"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Such math transformation in aesthetic scales is a very efficient approach to unveil the skewed data distribution. It is also often used in the <Link to="../ggplot2-line-plot-gapminder">x-axis transform</Link> and <Link to="../ggplot2-scatterplot-urbanization">y-axis transform</Link>.</p>
<p><span id="theme">Position the legend at the plot bottom, and adjust its labels and title. A quick way to add superscript is to copy - paste the <Link to="https://en.wikipedia.org/wiki/Unicode_subscripts_and_superscripts">unicode</Link>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"bottom"</span>) <span className="sc">+</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="co"># further customize the colorbar legend</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">5</span>, <span className="st">"pt"</span>),</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"top"</span>,</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"populatin density / km²"</span>,</span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">family =</span> <span className="st">"Paytone One"</span>)))</span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_legend.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_African_population_legend"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add plot title using the <code>annotate</code> way.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">10</span>, <span className="at">label =</span> <span className="st">"Africa"</span>, </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">11</span>, <span className="at">family =</span> <span className="st">"Paytone One"</span>, <span className="at">hjust =</span> <span className="dv">0</span>) </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_title.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_African_population_title"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add a warm background as a final touch.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"cornsilk"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>        <span className="co"># increase the margin around the plot</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">20</span>, <span className="at">l =</span> <span className="dv">10</span>, <span className="at">r =</span> <span className="dv">10</span>, <span className="at">t =</span> <span className="dv">10</span>))</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a></span><br/>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_African_population_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="st">"Heatmap of African population.pdf"</span>,</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="fl">4.2</span>, <span className="at">height =</span> <span className="fl">5.6</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a></span><br/>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="co"># the African population dataset</span></span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a><span className="co"># install.packages("remotes") # if not already installed</span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a><span className="co"># remotes::install_github("afrimapr/afrilearndata") # if fails, try restarting R</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a><span className="fu">library</span>(afrilearndata) </span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a></span><br/>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a><span className="co"># packages for data cleanup</span></span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a><span className="fu">library</span>(raster) </span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a><span className="fu">library</span>(sp)</span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a></span><br/>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a></span><br/>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a><span className="co"># Load "Paytone One" font from Google Fonts Repository.</span></span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Paytone One"</span>, <span className="at">family =</span> <span className="st">"Paytone One"</span>)</span>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a><span className="fu">showtext.auto</span>() <span className="co"># use 'showtext' to draw text</span></span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a></span><br/>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a><span className="co"># Data cleanup</span></span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a>afripop_df <span className="ot">&lt;-</span> afripop2020 <span className="sc">%&gt;%</span> </span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a>  <span className="fu">as.data.frame</span>(<span className="at">xy =</span> <span className="cn">TRUE</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a>  <span className="fu">rename</span>(<span className="at">pop =</span> <span className="dv">3</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span><span className="fu">is.na</span>(pop)) <span className="sc">%&gt;%</span> </span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a></span><br/>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a><span className="fu">head</span>(afripop_df, <span className="dv">4</span>)</span>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a></span><br/>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a></span><br/>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a><span className="co"># Create a heatmap base. </span></span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> afripop_df <span className="sc">%&gt;%</span> </span>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>  <span className="fu">ggplot</span>() <span className="sc">+</span></span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a>  <span className="fu">geom_raster</span>(<span className="fu">aes</span>(x, y, <span className="at">fill =</span> pop)) <span className="sc">+</span></span>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="fl">1.1</span>) </span>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a>p1</span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a></span><br/>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a></span><br/>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a><span className="co"># Adjust color scale</span></span>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">trans =</span> <span className="st">"pseudo_log"</span>, <span className="at">option =</span> <span className="st">"G"</span>,</span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a>                       <span className="at">breaks =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">1000</span>, <span className="dv">5000</span>, <span className="dv">20000</span>),</span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a>                       <span className="at">labels =</span> <span className="cf">function</span>(x) &#123;<span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">1000</span>, <span className="st">"K"</span>)&#125; )</span>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>p2</span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a></span><br/>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a></span><br/>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a><span className="co"># adjust legend. Use unicode for easy input of superscripts. </span></span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"bottom"</span>) <span className="sc">+</span></span>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a>  <span className="co"># further customize the colorbar legend</span></span>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">5</span>, <span className="st">"pt"</span>),</span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"top"</span>,</span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"populatin density / km²"</span>,</span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">family =</span> <span className="st">"Paytone One"</span>)))</span>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a>p3</span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a></span><br/>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a></span><br/>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a><span className="co"># Add plot title.</span></span>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="sc">-</span><span className="dv">20</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">10</span>, <span className="at">label =</span> <span className="st">"Africa"</span>, </span>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">11</span>, <span className="at">family =</span> <span className="st">"Paytone One"</span>, <span className="at">hjust =</span> <span className="dv">0</span>) </span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a>p4</span>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a></span><br/>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a></span><br/>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a><span className="co"># Add a warm background as a final touch.</span></span>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-68"><a aria-hidden="true" href="#cb11-68" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"cornsilk"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb11-69"><a aria-hidden="true" href="#cb11-69" tabindex="-1"></a>        <span className="co"># increase the margin around the plot</span></span>
<span id="cb11-70"><a aria-hidden="true" href="#cb11-70" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">20</span>, <span className="at">l =</span> <span className="dv">10</span>, <span className="at">r =</span> <span className="dv">10</span>, <span className="at">t =</span> <span className="dv">10</span>))</span>
<span id="cb11-71"><a aria-hidden="true" href="#cb11-71" tabindex="-1"></a></span><br/>
<span id="cb11-72"><a aria-hidden="true" href="#cb11-72" tabindex="-1"></a>p5</span>
<span id="cb11-73"><a aria-hidden="true" href="#cb11-73" tabindex="-1"></a></span><br/>
<span id="cb11-74"><a aria-hidden="true" href="#cb11-74" tabindex="-1"></a></span><br/>
<span id="cb11-75"><a aria-hidden="true" href="#cb11-75" tabindex="-1"></a><span className="co"># Save the plot.</span></span>
<span id="cb11-76"><a aria-hidden="true" href="#cb11-76" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="st">"Heatmap of African population.pdf"</span>,</span>
<span id="cb11-77"><a aria-hidden="true" href="#cb11-77" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, </span>
<span id="cb11-78"><a aria-hidden="true" href="#cb11-78" tabindex="-1"></a>       <span className="at">device =</span> <span className="st">"pdf"</span>, </span>
<span id="cb11-79"><a aria-hidden="true" href="#cb11-79" tabindex="-1"></a>       <span className="at">width =</span> <span className="fl">4.2</span>, <span className="at">height =</span> <span className="fl">5.6</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check this <strong><Link to="../ggplot2-heatmap-vaccine-for-polio">single heatmap</Link></strong> that highlights how vaccination controlled the wide spread of polio disease in the U.S. history; and check this article <strong><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">using functions to automate heatmap creation for <em>eight</em> infectious diseases</Link></strong> in the U.S. history (displayed below).</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>The above heatmaps are drawn using the generic function <code>geom_tile()</code> or <code>geom_raster()</code>. Alternatively, a heatmap can be created using the <code>geom_bin2d()</code> function – check out the following awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}