import React from 'react';
import VisualizeSkewedData from '../blog/contents/visualize-skewed-data_output_react'
import VisualizeChangeWithTime from '../blog/contents/visualize-change-with-time_output_react'
import PseudoLogTransformation from '../blog/contents/pseudo-log-transformation_output_react'
import imgVisualizeSkewedData from '../blog/graphics_blog/housing_skew_summary.png'
import imgVisualizeChangeWithTime from '../blog/graphics_blog/change_with_time_summary.png'
import imgPseudoLogTransformation from '../blog/graphics_blog/pseudo_log_cover.png'
import imgVisualizeSkewedDataWebp from '../blog/graphics_blog/housing_skew_summary.webp'
import imgVisualizeChangeWithTimeWebp from '../blog/graphics_blog/change_with_time_summary.webp'
import imgPseudoLogTransformationWebp from '../blog/graphics_blog/pseudo_log_cover.webp'
import PublishPapersGraduateSchool from '../blog/contents/publish-27-papers-graduate-school_output_react'
import imgPublishPapersGraduateSchoolWebp from '../blog/graphics_blog/cactus.webp'
const BlogData = [
    {'component': <VisualizeSkewedData  />, 'path': 'visualize-skewed-data', 'title': 'Golden Methods to Visualize the Skewed Data','subTitle': '', 'dscrpt': 'In this article, I’ll demonstrate powerful methods for visualizing data with highly skewed distributions...', 'image': imgVisualizeSkewedData, 'cover_webp': imgVisualizeSkewedDataWebp, 'time': '6 min'},
    {'component': <VisualizeChangeWithTime  />, 'path': 'visualize-change-with-time', 'title': 'Six Awesome Strategies to Visualize Temporal Changes Effectively', 'subTitle': '', 'dscrpt': 'This article summarizes effective strategies to visualize temporal changes, illustrated with inspiring graphic examples.', 'image': imgVisualizeChangeWithTime, 'cover_webp': imgVisualizeChangeWithTimeWebp, 'time': '5 min'},
    {'component': <PseudoLogTransformation  />, 'path': 'pseudo-log-transformation', 'title': 'Pseudo-logarithmic Transformation in Data Visualization','subTitle': '', 'dscrpt': 'This article illustrates and compares the use of logarithmic, pseudo-logarithmic, and root transformation in data viz., with a special focus on pseudo-logarithmic transformation.', 'image': imgPseudoLogTransformation, 'cover_webp': imgPseudoLogTransformationWebp, 'time': '6 min'},
    {'component': <PublishPapersGraduateSchool />, 'path': 'publish-27-papers-graduate-school', 'title': 'What Publishing 27 Papers in Graduate School Has Taught Me', 'subTitle': 'Insights with Broad Implications for Personal Growth and Career Development', 'dscrpt': 'In this short essay, I want to share two strategic fundamentals behind my success, which I believe have universal implications for personal growth and career development.', 'image': 'imgPublishPapersGraduateSchool', 'cover_webp': imgPublishPapersGraduateSchoolWebp, 'time': '5 min'},
]

export default BlogData