import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
import imgPipeOperator from '../dplyr_graphics/pipe_iris2-1.png';

export default function PipeOperator(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Connect functions with pipe operator <strong><code>%&gt;%</code></strong></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<ul>
<li><a href="#basics">Basics of the pipe operator</a></li>
<li><a href="#advanced_feature">Advanced features of the pipe operator</a></li>
</ul>
<hr/>
<section className="level3" id="basics-of-the-pipe-operator">
<h3 className="anchored" data-anchor-id="basics-of-the-pipe-operator"><strong><span id="basics">Basics of the pipe operator</span></strong></h3>
<p>The pipe operator <strong><code>%&gt;%</code></strong> originates from the <code>magrittr</code> package, and is also included in the <code>dplyr</code> package. You can use the pipe operator whenever <code>dplyr</code> or <code>tidyverse</code> is loaded. You can quickly type the pipe operator by the built-in keyboard shortcut <strong>Ctrl / Cmd</strong> + <strong>Shift</strong> + <strong>M</strong>.</p>
<p>The pipe operator takes the output of the expression on its left and passes it as the <strong>first argument</strong> to the function on its right. That is, <code>x %&gt;% f(y)</code> (<code>f()</code> for function) is equivalent to <code>f(x, y)</code>. To get a better understanding, consider the following script, which calculates the mean of a vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">mean</span>(<span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">7</span>, <span className="cn">NA</span>), <span className="at">na.rm =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 4</code></pre>
</div>
</div>
<p>It is equivalent to:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">7</span>, <span className="cn">NA</span>) <span className="sc">%&gt;%</span> <span className="fu">mean</span>(<span className="at">na.rm =</span> T)</span></code></pre></div>
</div>
<p>You can use the pipe operator to chain a sequence of operations in a way that reads from left to right, making code more readable and expressive. For instance, <code>x %&gt;% f(y) %&gt;% g(z)</code> is equivalent to <code>g(f(x, y), z)</code>. You can consider the pipe operator as a “then” conjunction of consecutive functions. To let the ideas sink in, consider the following example.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="do">## Example 1</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a><span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">7</span>, <span className="cn">NA</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">mean</span>(<span className="at">na.rm =</span> T) <span className="sc">%&gt;%</span>  <span className="co"># calculate the mean </span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">sqrt</span>() <span className="sc">%&gt;%</span> <span className="co"># then calculate result's square root  </span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">log2</span>() <span className="co"># then calculates the result's log2 value</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 1</code></pre>
</div>
</div>
<p>The following second example demonstrates the high efficiency in data wrangling made possible by the pipe operator. (You’ll learn the details of these functions soon in later sections)</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="do">## Example 2</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="co"># perform following calculations separately to each group of "Species"</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">group_by</span>(Species) <span className="sc">%&gt;%</span> </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="co"># then calculate the mean of Sepal and Petal length</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">S.mean =</span> <span className="fu">mean</span>(Sepal.Length),</span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>            <span className="at">P.mean =</span> <span className="fu">mean</span>(Petal.Length)) <span className="sc">%&gt;%</span> </span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>  </span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>  <span className="co"># then calculate the difference of the means</span></span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">difference =</span> S.mean <span className="sc">-</span> P.mean)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 4
<br/>    Species    S.mean P.mean difference
<br/>    &lt;fct&gt;       &lt;dbl&gt;  &lt;dbl&gt;      &lt;dbl&gt;
<br/>  1 setosa       5.01   1.46       3.54
<br/>  2 versicolor   5.94   4.26       1.68
<br/>  3 virginica    6.59   5.55       1.04</code></pre>
</div>
</div>
</section>
<section className="level3" id="advanced-features-of-the-pipe-operator-one-level-up">
<h3 className="anchored" data-anchor-id="advanced-features-of-the-pipe-operator-one-level-up"><span id="advanced_feature"><strong>Advanced features of the pipe operator</strong> —🚀 <strong><em>one level up!</em></strong></span></h3>
<p>You can employ the dot <code>.</code> as a placeholder of the pipe’s left-hand output, and use it in the right-hand expression. This can be particularly useful in the following three different cases.</p>
<section className="level4" id="change-the-argument-placement">
<h4 className="anchored" data-anchor-id="change-the-argument-placement"><strong>Change the argument placement</strong></h4>
<p>While the pipe defaults to pass the left-side output as the <em>first</em> argument to the right-side function, you can change the argument placement using the dot <code>.</code> placeholder. For instance, <code>x %&gt;% f(y, .)</code> is equivalent to <code>f(y, x)</code>.</p>
<p>In the following <code>t.test()</code> function, you can use <code>data = .</code> to specify that the filtered dataset should be passed to <code>data</code> argument.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">filter</span>(Species <span className="sc">!=</span> <span className="st">"setosa"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">t.test</span>(<span className="at">formula =</span> Sepal.Length <span className="sc">~</span> Species, <span className="at">data =</span> .)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  
<br/>    Welch Two Sample t-test
<span>  
</span><br/>  data:  Sepal.Length by Species
<br/>  t = -5.6292, df = 94.025, p-value = 1.866e-07
<br/>  alternative hypothesis: true difference in means between group versicolor and group virginica is not equal to 0
<br/>  95 percent confidence interval:
<br/>   -0.8819731 -0.4220269
<br/>  sample estimates:
<br/>  mean in group versicolor  mean in group virginica 
<br/>                     5.936                    6.588</code></pre>
</div>
</div>
</section>
<section className="level4" id="use-dot-inside-another-function">
<h4 className="anchored" data-anchor-id="use-dot-inside-another-function"><strong>Use dot inside another function</strong></h4>
<p><code>x %&gt;% f(g(.))</code> is equivalent to <code>f(x, g(x))</code>, where the x is passed to both functions <code>g()</code> and <code>f()</code>. In the piped syntax, as the dot is used inside the function <code>g()</code>, it is not considered as an argument placement of <code>f()</code>. As the default pipe behavior, <code>x</code> would still be passed to the <em>first</em> argument of <code>f()</code>.</p>
<p>Consider the following example: in order to create a row index (as a new column) in the output dataset of <code>summarise()</code>, if without using the dot syntax, one may need to first assign the output dataset to an intermediary variable <code>a</code>, and then pass <code>a</code> to functions <code>mutate()</code> and <code>nrow()</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># create a summarized dataset, and assign to "a"</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>a <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="co"># for each group of "Species"</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">group_by</span>(Species) <span className="sc">%&gt;%</span> </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="co"># calculate the mean of sepal length</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">S.mean =</span> <span className="fu">mean</span>(Sepal.Length))</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a></span><br/>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a><span className="co"># add row index to the summarized dataset</span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>a <span className="ot">&lt;-</span> a <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">index =</span> <span className="dv">1</span><span className="sc">:</span><span className="fu">nrow</span>(a))</span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 3
<br/>    Species    S.mean index
<br/>    &lt;fct&gt;       &lt;dbl&gt; &lt;int&gt;
<br/>  1 setosa       5.01     1
<br/>  2 versicolor   5.94     2
<br/>  3 virginica    6.59     3</code></pre>
</div>
</div>
<p>By using the <code>.</code> as a placeholder of the summarized dataset, you can streamline all operations, and skip the intermediary assignment. As the dot is used in <code>nrow()</code>, it is <em>not</em> an argument placement of the <code>mutate()</code> function; as such, as the default behavior, the summarized dataset would still be passed to <code>mutate()</code>’s first argument <code>.data</code>, as well as to <code>nrow()</code> .</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">group_by</span>(Species) <span className="sc">%&gt;%</span> </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">S.mean =</span> <span className="fu">mean</span>(Sepal.Length)) <span className="sc">%&gt;%</span> </span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">index =</span> <span className="dv">1</span><span className="sc">:</span><span className="fu">nrow</span>(.))</span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>a</span></code></pre></div>
</div>
<p>(Alternative to <code>nrow(.)</code>, you can use the dplyr function <code>n()</code> to give the current row number, i.e., using <code>mutate(index = 1:n()</code>).</p>
</section>
<section className="level4" id="use-dot-inside-another-function-while-overwriting-default-pipe-behavior">
<h4 className="anchored" data-anchor-id="use-dot-inside-another-function-while-overwriting-default-pipe-behavior"><strong>Use dot inside another function while overwriting default pipe behavior</strong></h4>
<p>You can indicate not to pass to the first argument of the right-hand function by wrapping this function in curly braces: <code>x %&gt;% &#123;f(g(.), h(.))&#125;</code> is equivalent to <code>f(g(x), h(x))</code>, without passing <code>x</code> to the first argument of <code>f()</code>.</p>
<p>The dot as data placeholder works with operators such as<code>$</code>, <code>[</code>, and <code>[[</code>, which can be considered as functions. Consider the following script. <em>It does not run.</em> Why?</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> <span className="fu">plot</span>(<span className="at">x =</span> .<span className="sc">$</span>Sepal.Length, <span className="at">y =</span> .<span className="sc">$</span>Petal.Length)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="co">#&gt; Error in plot.xy(xy, type, ...) : invalid plot type</span></span></code></pre></div>
</div>
<p>As the dots are used in a new function (the special one of <code>$</code>), they are not argument placements of the <code>plot()</code> function. Therefore, as a default behavior, the <code>iris</code> dataset would still be passed to the first argument of <code>plot()</code>, which is <code>x</code>, thus creating a conflict with specified <code>x = .$Sepal.Length</code>. To stop the default passing of <code>iris</code> to the first argument of <code>plot()</code>, wrap curly braces around the <code>plot()</code> function.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>iris <span className="sc">%&gt;%</span> &#123;<span className="fu">plot</span>(<span className="at">x =</span> .<span className="sc">$</span>Sepal.Length, <span className="at">y =</span> .<span className="sc">$</span>Petal.Length)&#125;</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a></span><br/>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a><span className="co"># which is correctly equivalent to: </span></span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a><span className="fu">plot</span>(iris<span className="sc">$</span>Sepal.Length, iris<span className="sc">$</span>Petal.Length)</span></code></pre></div>
</div>
<div className="cell">
<div className="cell-output-display">
<div>
<figure className="figure">
<p><img className="cover-img" src={imgPipeOperator} /></p>
</figure>
</div>
</div>
</div>
</section>
</section>
</main>
</div>
</div>
)}