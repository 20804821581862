import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2DensityPlotGgridgeSunspot from '../graphics/ggridge_sunspot_theme_completed.png'; 
import imgGgplot2DensityPlotGgridgeSunspotWebp from '../graphics/ggridge_sunspot_theme_completed.webp'; 
export default function Ggplot2DensityPlotGgridgeSunspot(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Stacked Density Plots in ggplot2 to Visualize Sunsplot Activities</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2DensityPlotGgridgeSunspotWebp} />
    <img className="cover-img" src={imgGgplot2DensityPlotGgridgeSunspot} />
  </picture>

<p>In this article, we’ll leverage the <Link to="https://wilkelab.org/ggridges/"><code>ggridges</code></Link> package by <Link to="https://clauswilke.com/">Claus O. Wilke</Link> to visualize the sunspot activities using a list of density plots stacked in chronological order.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset <code>sunspot.month</code> (time series) used is built-in base R.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(TSstudio) <span className="co"># transform time series data</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">library</span>(ggridges) <span className="co"># draw stacked density plots</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>s <span className="ot">&lt;-</span> sunspot.month <span className="sc">%&gt;%</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="co"># convert time series to data.frame</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">ts_reshape</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="co"># tidy up</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>month, <span className="at">names_to =</span> <span className="st">"year"</span>, </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"activity"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">as.integer</span>(year)) <span className="sc">%&gt;%</span> </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">seq</span>(<span className="dv">1750</span>, <span className="dv">2010</span>, <span className="dv">2</span>))</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="fu">head</span>(s, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  month  year activity
<br/>  &lt;dbl&gt; &lt;int&gt;    &lt;dbl&gt;
<br/>1     1  1750     73.3
<br/>2     1  1752     35  
<br/>3     1  1754      0  </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a list of stacked density plots.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> s <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> activity, <span className="at">y =</span> year, <span className="at">fill =</span> <span className="fu">after_stat</span>(x))) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># create density plots in stacked manner</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">geom_density_ridges_gradient</span>(</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> year), <span className="at">scale =</span> <span className="dv">8</span>, <span className="at">color =</span> <span className="st">"white"</span>)</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggridge_sunspot_geom_density_ridges.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggridge_sunspot_geom_density_ridges"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Adjust the color scale, axis scale, and titles.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"C"</span>) <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1750</span>, <span className="dv">2010</span>, <span className="dv">10</span>), <span className="at">name =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">xlim =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="cn">NA</span>), <span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"Activity intensity"</span>) <span className="sc">+</span></span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="co"># add plot title</span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">220</span>, <span className="at">y =</span> <span className="dv">1790</span>,</span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"Sunspot</span><span className="sc">\n</span><span className="st"> yearly</span><span className="sc">\n</span><span className="st"> activity</span><span className="sc">\n</span><span className="st"> distribution</span><span className="sc">\n\n</span><span className="st">1740 ~ 2013"</span>, </span>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>, <span className="at">color =</span> <span className="st">"tomato3"</span>)</span>
<span id="cb4-11"><a aria-hidden="true" href="#cb4-11" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggridge_sunspot_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggridge_sunspot_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A final fine-tune of the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>        <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>        <span className="at">axis.ticks.y =</span> <span className="fu">element_line</span>(<span className="at">color =</span> <span className="st">"snow2"</span>),</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>        <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>))) </span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggridge_sunspot_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggridge_sunspot_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a><span className="fu">library</span>(TSstudio) <span className="co"># transform time series data</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a><span className="fu">library</span>(ggridges) <span className="co"># draw stacked density plots</span></span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a></span><br/>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>s <span className="ot">&lt;-</span> sunspot.month <span className="sc">%&gt;%</span> </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>  <span className="co"># convert time series to data.frame</span></span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>  <span className="fu">ts_reshape</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>  <span className="co"># tidy up</span></span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>month, <span className="at">names_to =</span> <span className="st">"year"</span>, </span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"activity"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">as.integer</span>(year)) <span className="sc">%&gt;%</span> </span>
<span id="cb6-14"><a aria-hidden="true" href="#cb6-14" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">seq</span>(<span className="dv">1750</span>, <span className="dv">2010</span>, <span className="dv">2</span>))</span>
<span id="cb6-15"><a aria-hidden="true" href="#cb6-15" tabindex="-1"></a></span><br/>
<span id="cb6-16"><a aria-hidden="true" href="#cb6-16" tabindex="-1"></a><span className="fu">head</span>(s, <span className="at">n =</span> <span className="dv">3</span>) <span className="co"># ready for visualization!</span></span>
<span id="cb6-17"><a aria-hidden="true" href="#cb6-17" tabindex="-1"></a></span><br/>
<span id="cb6-18"><a aria-hidden="true" href="#cb6-18" tabindex="-1"></a></span><br/>
<span id="cb6-19"><a aria-hidden="true" href="#cb6-19" tabindex="-1"></a><span className="co"># Create a list of stacked density plots. </span></span>
<span id="cb6-20"><a aria-hidden="true" href="#cb6-20" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> s <span className="sc">%&gt;%</span> </span>
<span id="cb6-21"><a aria-hidden="true" href="#cb6-21" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> activity, <span className="at">y =</span> year, <span className="at">fill =</span> <span className="fu">after_stat</span>(x))) <span className="sc">+</span></span>
<span id="cb6-22"><a aria-hidden="true" href="#cb6-22" tabindex="-1"></a>  <span className="co"># create density plots in stacked manner</span></span>
<span id="cb6-23"><a aria-hidden="true" href="#cb6-23" tabindex="-1"></a>  <span className="fu">geom_density_ridges_gradient</span>(</span>
<span id="cb6-24"><a aria-hidden="true" href="#cb6-24" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> year), <span className="at">scale =</span> <span className="dv">8</span>, <span className="at">color =</span> <span className="st">"white"</span>)</span>
<span id="cb6-25"><a aria-hidden="true" href="#cb6-25" tabindex="-1"></a>p1</span>
<span id="cb6-26"><a aria-hidden="true" href="#cb6-26" tabindex="-1"></a></span><br/>
<span id="cb6-27"><a aria-hidden="true" href="#cb6-27" tabindex="-1"></a></span><br/>
<span id="cb6-28"><a aria-hidden="true" href="#cb6-28" tabindex="-1"></a><span className="co"># Adjust the color scale, axis scale, and titles. </span></span>
<span id="cb6-29"><a aria-hidden="true" href="#cb6-29" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb6-30"><a aria-hidden="true" href="#cb6-30" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"C"</span>) <span className="sc">+</span></span>
<span id="cb6-31"><a aria-hidden="true" href="#cb6-31" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1750</span>, <span className="dv">2010</span>, <span className="dv">10</span>), <span className="at">name =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb6-32"><a aria-hidden="true" href="#cb6-32" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">xlim =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="cn">NA</span>), <span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb6-33"><a aria-hidden="true" href="#cb6-33" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"Activity intensity"</span>) <span className="sc">+</span></span>
<span id="cb6-34"><a aria-hidden="true" href="#cb6-34" tabindex="-1"></a>  <span className="co"># add plot title</span></span>
<span id="cb6-35"><a aria-hidden="true" href="#cb6-35" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb6-36"><a aria-hidden="true" href="#cb6-36" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">220</span>, <span className="at">y =</span> <span className="dv">1790</span>,</span>
<span id="cb6-37"><a aria-hidden="true" href="#cb6-37" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"Sunspot</span><span className="sc">\n</span><span className="st"> yearly</span><span className="sc">\n</span><span className="st"> activity</span><span className="sc">\n</span><span className="st"> distribution</span><span className="sc">\n\n</span><span className="st">1740 ~ 2013"</span>, </span>
<span id="cb6-38"><a aria-hidden="true" href="#cb6-38" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>, <span className="at">color =</span> <span className="st">"tomato3"</span>)</span>
<span id="cb6-39"><a aria-hidden="true" href="#cb6-39" tabindex="-1"></a>p2</span>
<span id="cb6-40"><a aria-hidden="true" href="#cb6-40" tabindex="-1"></a></span><br/>
<span id="cb6-41"><a aria-hidden="true" href="#cb6-41" tabindex="-1"></a></span><br/>
<span id="cb6-42"><a aria-hidden="true" href="#cb6-42" tabindex="-1"></a><span className="co"># A final fine-tune of the theme. </span></span>
<span id="cb6-43"><a aria-hidden="true" href="#cb6-43" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb6-44"><a aria-hidden="true" href="#cb6-44" tabindex="-1"></a>  <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb6-45"><a aria-hidden="true" href="#cb6-45" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb6-46"><a aria-hidden="true" href="#cb6-46" tabindex="-1"></a>        <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb6-47"><a aria-hidden="true" href="#cb6-47" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb6-48"><a aria-hidden="true" href="#cb6-48" tabindex="-1"></a>        <span className="at">axis.ticks.y =</span> <span className="fu">element_line</span>(<span className="at">color =</span> <span className="st">"snow2"</span>),</span>
<span id="cb6-49"><a aria-hidden="true" href="#cb6-49" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb6-50"><a aria-hidden="true" href="#cb6-50" tabindex="-1"></a>        <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>))) </span>
<span id="cb6-51"><a aria-hidden="true" href="#cb6-51" tabindex="-1"></a>p3</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In addition to the density plots, we can alternatively visualize the sunspot activities using a heatmap. In the following article, we’ll <Link to="../ggplot2-heatmap-sunspot-activities"><strong>combine the heatmap with a line plot synchronized in position</strong></Link> to vividly visualize the sunspot activities.</p>
<p><Link to="../ggplot2-heatmap-sunspot-activities">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>To visualize complicated chronological data like the sunspot activities, in addition to the density plot, heatmaps and line plot, animation is another powerful approach. Check out the following <Link to="../ggplot2-population-pyramid-animation"><strong>annual population pyramids in animation</strong></Link> that vividly unveils the predicted changing dynamics in the population structure in Germany.</p>
<p><Link to="../ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
</main>
</div>
</div>
)}