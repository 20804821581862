import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2PolarCoordSummary from '../tutorial/linear_polar_barplot.png';

export default function Ggplot2PolarCoordSummary(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">From Bars to Pies and Donuts: Comparison between Linear and Plar Coordinates</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This article graphically illustrates the association when a linear Cartesian ordinate and a polar coordinate. An intuitive knowledge of such association will greatly help the creation of <Link to="../../R/gallery/ggplot2-donut-plot-admission">pie and donut charts</Link>.</p>
<hr/>
<section className="level4" id="start-with-a-dummy-dataset-and-a-bar-plot">
<h4 className="anchored">Start with a dummy dataset and a bar plot</h4>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">24</span>))</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>t <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">a =</span> <span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>, <span className="st">"C"</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>            <span className="at">b =</span> <span className="fu">c</span>(<span className="dv">100</span>, <span className="dv">200</span>, <span className="dv">280</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>t</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>  a         b
<br/>  &lt;chr&gt; &lt;dbl&gt;
<br/>1 A       100
<br/>2 B       200
<br/>3 C       280</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>base <span className="ot">&lt;-</span> t <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1</span>, <span className="at">y =</span> b, <span className="at">fill =</span> a)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set2"</span>)</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a></span><br/>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>p <span className="ot">&lt;-</span> base <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>p</span></code></pre></div>
</div>
<div>
</div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="cover-img" src={imgGgplot2PolarCoordSummary} /></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_barplot-2.png" width="480"/></p>
</div>
</div>
</div>
<p><code>theta = "x"</code> transforms the <strong><code>x</code></strong> aesthetic from the linear coordinate into angles in a polar coordinate, and generates the bullseye plot or other formats (see creative examples <Link to="../../R/gallery/ggplot2-bar-polar-coord-horsepower">here</Link> and <Link to="../../R/gallery/ggplot2-lollipop-donut-horsepower">here</Link>). In contrast, <code>theta = "y"</code> transforms the <strong><code>y</code></strong> aesthetic into angles, and creates the typical pie and donut plots (see great examples <Link to="../../R/gallery/ggplot2-donut-GDP">here</Link> and <Link to="../../R/gallery/ggplot2-geofacet-US-states">here</Link>).</p>
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"x"</span>)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_coord.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_coord-2.png" width="480"/></p>
</div>
</div>
</div>
<p>As the pie and donut plots are much more commonly used, we will focus on the <code>theta = "y"</code>-based transformation in the rest of this article.</p>
<hr/>
<p>A thick white outline in the bars generates the exploding effect in the pie.</p>
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> base <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> <span className="dv">5</span>) </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>x</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>x <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_exploding_effect.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_exploding_effect-2.png" width="480"/></p>
</div>
</div>
</div>
<p>Add texts. Use the <code>stack</code> position with center-alignment <code>vjust = .5</code> to synchronize the texts with the associated bars. This correspondingly puts the texts in the “center” position of each slice in the pie plot.</p>
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> a), </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>)</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>p1</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>p1 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_centered.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_centered-2.png" width="480"/></p>
</div>
</div>
</div>
<p>Shift texts upward or downward with <code>vjust</code>. This correspondingly changes the rotating angle of the texts in the pie slices.</p>
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> a), </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> <span className="dv">1</span>),</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>p2</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_angle_shifted.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_angle_shifted-2.png" width="480"/></p>
</div>
</div>
</div>
<p>Adjust the text <code>x</code> aesthetic to shift texts to the left or right. This correspondingly changes the radial distance between the texts and the center of the pie.</p>
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> a, <span className="at">x =</span> <span className="fl">1.6</span>), </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>)</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>p3</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>p3 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_radial_right.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_radial_right-2.png" width="480"/></p>
</div>
</div>
</div>
<p>Extend the lower limit on the x-axis in the bar plot. The space on the left of the bar will be transformed into a central whole in the donut plot. The x-axis upper limit, set as NA, extends automatically to the maximum of the data (i.e., the right edge of the bar).</p>
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="cn">NA</span>))</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a></span><br/>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>p4</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>p4 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_left_space_donut.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_left_space_donut-2.png" width="480"/></p>
</div>
</div>
</div>
<p>A smaller value of the lower limit (e.g., -10) on the x-axis generates more white space of the left side of the bar. This generates a narrower bar plot, and corresponds to a thinner donut plot in a polar coordinate. In like manner, a larger upper limit (e.g., 10) on the x-axis creates more space on the right side of the bar. Correspondingly, this introduces more space on the external side of the donut, and compresses it into a smaller circle.</p>
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>) </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a></span><br/>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a><span className="co"># a thin donut</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>p5 <span className="sc">+</span> <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">10</span>, <span className="cn">NA</span>))  </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a><span className="co"># a thinner donut</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>p5 <span className="sc">+</span> <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">10</span>, <span className="dv">10</span>)) </span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_thinner_smaller_donut.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_thinner_smaller_donut-2.png" width="480"/></p>
</div>
</div>
</div>
<p>A graphical object on the left side of the barplot in a linear coordinate will be positioned at the center of the donut plot in a polar coordinate.</p>
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"XXX"</span>,</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="dv">400</span>, </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">10</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>p6 </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>p6 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>) </span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_donut_hole.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_text_donut_hole-2.png" width="480"/></p>
</div>
</div>
</div>
<p>Adding a negative <code>vjust</code> value in the <code>position</code> argument of <code>geom_col()</code> generate extra space in the negative range of the y axis. This correspondingly generates a partial pie.</p>
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> base <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> <span className="sc">-</span><span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> a), </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">10</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a></span><br/>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a>p7</span>
<span id="cb12-9"><a aria-hidden="true" href="#cb12-9" tabindex="-1"></a>p7 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>) </span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_fractional_pie.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_fractional_pie-2.png" width="480"/></p>
</div>
</div>
</div>
<p>Rotate the pie to a balanced orientation. The <code>start</code> argument specifies the amount of rotation, and can take a bit of trial-and-error to find a suitable value. On top of this, extension of the lower limit of the x-axis further turns the halved pie into a halved donut (a dashboard plot).</p>
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>, </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>                       <span className="at">direction =</span> <span className="dv">1</span>, <span className="co"># turn clockwise</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>                       <span className="at">start =</span> <span className="fl">2.15</span>) <span className="co"># amount of offset from 12 o'lock</span></span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>p8</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>p8 <span className="sc">+</span> <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="cn">NA</span>))</span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
<div className="quarto-layout-row">
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_dashboard_plot.png" width="480"/></p>
</div>
<div className="quarto-layout-cell">
<p><img className="img-fluid" src="tutorial/linear_polar_dashboard_plot-2.png" width="480"/></p>
</div>
</div>
</div>
<hr/>
<p>Now let’s check the polar transformations with <em>faceted panels</em>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>t2 <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">a =</span> <span className="fu">rep</span>(<span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>, <span className="st">"C"</span>), <span className="at">time =</span> <span className="dv">2</span>),</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>             <span className="at">b =</span> <span className="fu">c</span>(<span className="dv">100</span>, <span className="dv">200</span>, <span className="dv">300</span>, <span className="dv">50</span>, <span className="dv">400</span>, <span className="dv">800</span>),</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>             <span className="at">m =</span> <span className="fu">rep</span>(<span className="fu">c</span>(<span className="st">"α"</span>, <span className="st">"β"</span>), <span className="at">each =</span> <span className="dv">3</span>))</span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a></span><br/>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>p9 <span className="ot">&lt;-</span> t2 <span className="sc">%&gt;%</span> </span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="dv">1</span>, b, <span className="at">fill =</span> a)) <span className="sc">+</span> </span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>m) <span className="sc">+</span></span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set2"</span>)</span></code></pre></div>
</div>
<p>The bar height is proportionally mapped to the angle of the pies <strong>based on the same scaling factor across <em>all</em> panels</strong>. As a result, differences in the total height of the bars in the faceted panels would result in fractional pies in the polar coordinate.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>p9 <span className="sc">+</span> <span className="fu">geom_col</span>() </span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a></span><br/>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>p9 <span className="sc">+</span> <span className="fu">geom_col</span>() <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)</span></code></pre></div>
</div>
<div className="cell">
<div className="cell-output-display">
<div>
<figure className="figure">
<p><img className="img-fluid figure-img" src="tutorial/linear_polar_unnamed-chunk-4.png"/></p>
</figure>
</div>
</div>
</div>
<p>To get pies with the entirety of 360° degree, an easy approach is to normalize the bar height to the scale of [0, 1] for each panel by specifying the <code>fill</code> position.</p>
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>p9 <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"fill"</span>) </span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a></span><br/>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>p9 <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"fill"</span>) <span className="sc">+</span> </span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>  <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)   </span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
</div>
<div className="cell">
<div className="cell-output-display">
<div>
<figure className="figure">
<p><img className="img-fluid figure-img" src="tutorial/linear_polar_facted_fill.png"/></p>
</figure>
</div>
</div>
</div>
<p>The texts can take the same <code>fill</code> position to be aligned with the stacked bars / pies.</p>
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a>p10 <span className="ot">&lt;-</span> p9 <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"fill"</span>) <span className="sc">+</span></span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> a),</span>
<span id="cb17-4"><a aria-hidden="true" href="#cb17-4" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb17-5"><a aria-hidden="true" href="#cb17-5" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">8</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb17-6"><a aria-hidden="true" href="#cb17-6" tabindex="-1"></a></span><br/>
<span id="cb17-7"><a aria-hidden="true" href="#cb17-7" tabindex="-1"></a>p10</span>
<span id="cb17-8"><a aria-hidden="true" href="#cb17-8" tabindex="-1"></a>p10 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>)   </span></code></pre></div>
<div className="cell quarto-layout-panel" data-layout-ncol="2">
</div>
<div className="cell">
<div className="cell-output-display">
<div>
<figure className="figure">
<p><img className="img-fluid figure-img" src="tutorial/linear_polar_facet_texts.png"/></p>
</figure>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored" data-anchor-id="start-with-a-dummy-dataset-and-a-bar-plot">
<strong>Apply Your Learning in Practice!</strong> 🏆
</h3>
<p>Check these <Link to="../../R/gallery/ggplot2-donut-GDP"><strong>exploded donut plots in faceted layout</strong></Link> that visualizes the GDP contribution proportion of the top 4 countries separately in each continent.</p>
<p><Link to="../../R/gallery/ggplot2-donut-GDP"><img className="img-fluid" src="graphics/doughnut_plot_GDP_ggdraw_completed.png"/></Link></p>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong>Continue Exploring — 🚀 one level up!</strong>
</h3>
<p>Check the following <strong><Link to="../../R/gallery/ggplot2-geofacet-US-states">exploded donut plots in a U.S. map layout</Link></strong> that visualizes the state-wise voting results of the 2016 Presidential Election between Hillary Clinton and Donald Trump .</p>
<p><Link to="../../R/gallery/ggplot2-geofacet-US-states"><img className="img-fluid" src="graphics/geofacet_election_completed.png"/></Link></p>
</section>
</main>
</div>
</div>
)}