import {Link} from "react-router-dom";

export default function VerticalPosts({title, path, cover="", cover_webp=""}) {
    return (
        <div className="gallery-container ">
            <div className="image-post navHover">
                <Link to={path}>
                    <picture>
                        <source type="image/webp" srcset={cover_webp} />
                        <img src={cover}></img> 
                    </picture>
                    
                </Link>   
            </div>  
            <div className="center-text navHover">
                <Link to={path} className='navLink'>
                    {/* <h5 >{title}</h5> */}
                </Link> 
            </div>                     
        </div>
    )
}