import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function MutateColumns(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Mutate Columns of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>mutate()</code></strong> to create new columns as a function of existing columns, or modify (overwrite) existing columns. The functions inside <code>mutate()</code> operate on selected columns, and independently for each row of the dataset.</p>
<section className="level3" id="basics-of-column-mutation">
<h3 className="anchored" data-anchor-id="basics-of-column-mutation">Basics of column mutation</h3>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span></code></pre></div>
</div>
<p>Create new columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span></span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">h =</span> height<span className="sc">/</span><span className="dv">100</span>, <span className="co"># divide height by 100</span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>         <span className="at">h.m =</span> height <span className="sc">+</span> mass) <span className="co"># sum up height and mass</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 7
<br/>    name           height  mass hair_color skin_color      h   h.m
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;       &lt;dbl&gt; &lt;dbl&gt;
<br/>  1 Luke Skywalker    172    77 blond      fair         1.72   249
<br/>  2 C-3PO             167    75 &lt;NA&gt;       gold         1.67   242
<br/>  3 R2-D2              96    32 &lt;NA&gt;       white, blue  0.96   128
<br/>  4 Darth Vader       202   136 none       white        2.02   338
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Modify (overwrite) existing columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="co"># capitalize values of the "skin_color" variable</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">skin_color =</span> <span className="fu">toupper</span>(skin_color))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name           height  mass hair_color skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 blond      FAIR       
<br/>  2 C-3PO             167    75 &lt;NA&gt;       GOLD       
<br/>  3 R2-D2              96    32 &lt;NA&gt;       WHITE, BLUE
<br/>  4 Darth Vader       202   136 none       WHITE      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<p>Remove a column by setting it to <code>NULL</code>. Alternatively, use <Link to="../2-select-columns"><code>select()</code></Link> to remove columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">hair_color =</span> <span className="cn">NULL</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 4
<br/>    name           height  mass skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 fair       
<br/>  2 C-3PO             167    75 gold       
<br/>  3 R2-D2              96    32 white, blue
<br/>  4 Darth Vader       202   136 white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="specify-the-position-of-newly-created-columns">
<h3 className="anchored" data-anchor-id="specify-the-position-of-newly-created-columns">Specify the position of newly created columns</h3>
<p>Newly added columns by default are added to the most right side of the data frame. Alternatively, you can use arguments <strong><code>.before</code></strong> and <strong><code>.after</code></strong> to change the position of newly generated columns.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">h2 =</span> height<span className="sc">^</span><span className="dv">2</span>, <span className="at">m2 =</span> mass<span className="sc">^</span><span className="dv">2</span>, </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>         <span className="co"># add new columns starting from the 4th column</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>         <span className="at">.before =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 7
<br/>    name           height  mass    h2    m2 hair_color skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 29584  5929 blond      fair       
<br/>  2 C-3PO             167    75 27889  5625 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32  9216  1024 &lt;NA&gt;       white, blue
<br/>  4 Darth Vader       202   136 40804 18496 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">h2 =</span> height<span className="sc">^</span><span className="dv">2</span>, <span className="at">m2 =</span> mass<span className="sc">^</span><span className="dv">2</span>, </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>         <span className="co"># add new columns after the "mass" column</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>         <span className="at">.after =</span> mass)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 7
<br/>    name           height  mass    h2    m2 hair_color skin_color 
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 Luke Skywalker    172    77 29584  5929 blond      fair       
<br/>  2 C-3PO             167    75 27889  5625 &lt;NA&gt;       gold       
<br/>  3 R2-D2              96    32  9216  1024 &lt;NA&gt;       white, blue
<br/>  4 Darth Vader       202   136 40804 18496 none       white      
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="retain-selected-columns-in-the-output-dataset">
<h3 className="anchored" data-anchor-id="retain-selected-columns-in-the-output-dataset">Retain selected columns in the output dataset</h3>
<p>By default, <em>all</em> original and newly generated columns are kept in the output. Alternatively, use <strong><code>.keep</code></strong> argument (defaults to <strong><code>"all"</code></strong>) to selectively reserve desired columns in the output. <strong>Newly generated variables and the <Link to="../6-grouped-dataset">grouping variables</Link> are always kept in the output.</strong></p>
<ul>
<li><strong><code>"used"</code></strong>: retain only columns used to create new columns.</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="at">h2 =</span> height<span className="sc">^</span><span className="dv">2</span>, </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="co"># only "height" and "h2" in the output </span></span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>  <span className="at">.keep =</span> <span className="st">"used"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 2
<br/>    height    h2
<br/>     &lt;int&gt; &lt;dbl&gt;
<br/>  1    172 29584
<br/>  2    167 27889
<br/>  3     96  9216
<br/>  4    202 40804
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<ul>
<li><strong><code>"unused"</code></strong>: retain only columns <em>not</em> used in creating new columns.</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="at">h2 =</span> height<span className="sc">^</span><span className="dv">2</span>, </span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>  <span className="co"># "height" is removed from the output</span></span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>  <span className="at">.keep =</span> <span className="st">"unused"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 5
<br/>    name            mass hair_color skin_color     h2
<br/>    &lt;chr&gt;          &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;       &lt;dbl&gt;
<br/>  1 Luke Skywalker    77 blond      fair        29584
<br/>  2 C-3PO             75 &lt;NA&gt;       gold        27889
<br/>  3 R2-D2             32 &lt;NA&gt;       white, blue  9216
<br/>  4 Darth Vader      136 none       white       40804
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
<ul>
<li><strong><code>"none"</code></strong>: remove all columns (except the newly generated columns and the grouping variables).</li>
</ul>
<div className="cell">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> </span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>  <span className="co"># calculate the relative height of each being WITHIN its own species</span></span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>  <span className="fu">group_by</span>(species) <span className="sc">%&gt;%</span> </span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a>    <span className="at">h =</span> height <span className="sc">/</span> <span className="fu">max</span>(height, <span className="at">na.rm =</span> T), </span>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a>    <span className="co"># only "species" and "h" are kept in the output</span></span>
<span id="cb16-7"><a aria-hidden="true" href="#cb16-7" tabindex="-1"></a>    <span className="at">.keep =</span> <span className="st">"none"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 87 × 2
<br/>  # Groups:   species [38]
<br/>    species     h
<br/>    &lt;chr&gt;   &lt;dbl&gt;
<br/>  1 Human   0.851
<br/>  2 Droid   0.835
<br/>  3 Droid   0.48 
<br/>  4 Human   1    
<br/>  # ℹ 83 more rows</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}