import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import PythonVariables from '../Python/basics/content/PythonVariables_output_react';
import PythonPrintmethod from '../Python/basics/content/PythonPrintMethod_output_react'
import PythonDatatypes from '../Python/basics/content/PythonDataTypes_output_react'
import PythonDatastructureslists from '../Python/basics/content/PythonDataStructuresLists_output_react'
import PythonDatastructurestuples from '../Python/basics/content/PythonDataStructuresTuples_output_react'
import PythonDatastructuresdictionaries from '../Python/basics/content/PythonDataStructuresDictionaries_output_react'
import PythonDatastructuressets from '../Python/basics/content/PythonDataStructuresSets_output_react'
import PythonOperationsarithmetic from '../Python/basics/content/PythonOperationsArithmetic_output_react'
import PythonOperationscomparison from '../Python/basics/content/PythonOperationsComparison_output_react'
import PythonOperationslogical from '../Python/basics/content/PythonOperationsLogical_output_react'
import PythonIfelse from '../Python/basics/content/PythonIfElse_output_react'
import PythonForloops from '../Python/basics/content/Pythonforloops_output_react'
import PythonWhileloop from '../Python/basics/content/PythonWhileLoop_output_react'
import PythonLoopcontrolstatements from '../Python/basics/content/PythonLoopControlStatements_output_react'
import PythonFunctionsdefinitionandparameters from '../Python/basics/content/PythonFunctionsDefinitionandParameters_output_react'
import PythonFunctionsreturnstatements from '../Python/basics/content/PythonFunctionsReturnStatements_output_react'
import PythonFunctionslocalvariableandglobalvariable from '../Python/basics/content/PythonFunctionsLocalVariableandGlobalVariable_output_react'
import PythonFunctionslambdafunctions from '../Python/basics/content/PythonFunctionsLambdaFunctions_output_react'
import PythonModulesandlibraries from '../Python/basics/content/PythonModulesAndLibraries_output_react'
import PythonOopclassesandobjects from '../Python/basics/content/PythonOOPClassesAndObjects_output_react'
import PythonClassinheritance from '../Python/basics/content/PythonClassInheritance_output_react'
import PythonIntroductiontopython from '../Python/basics/content/PythonIntroductionToPython_output_react'
import PythonInstallingpython from '../Python/basics/content/PythonInstallingPython_output_react'
import PythonInstallingjupyternotebook from '../Python/basics/content/PythonInstallingJupyterNotebook_output_react'
import PythonBasicsPage from '../Python/basics/content/PythonPythonBasicsLandingPage_output_react'


// if title or static_website_url is changed here, go to App.js to change Route paths.
const PythonTutorialData = [
    {'component': <PythonBasicsPage />, path:'getting-started', title: 'Getting Started'},
    {'component': <PythonIntroductiontopython />, 'path': 'introduction-to-python', 'title': 'Introduction To Python'},
    {'component': <PythonInstallingpython />, 'path': 'install-python', 'title': 'Install Python'},
    {'component': <PythonInstallingjupyternotebook />, 'path': 'install-jupyter-notebook', 'title': 'Install Jupyter Notebook'},
    {id:uuidv4(), 'path':'', title: 'Foundation'},
    {'component': <PythonPrintmethod />, 'path': 'print-method', 'title': 'print() Method'},
    {'component': <PythonVariables />, 'path': 'variables', 'title': 'Variables'},
    {'component': <PythonDatatypes />, 'path': 'data-types', 'title': 'Data Types'},
    {id:uuidv4(), 'path':'', title: 'Data Structure'},
    {'component': <PythonDatastructureslists />, 'path': 'data-structure-lists', 'title': 'Lists'},
    {'component': <PythonDatastructurestuples />, 'path': 'data-structure-tuples', 'title': 'Tuples'},
    {'component': <PythonDatastructuresdictionaries />, 'path': 'data-structure-dictionaries', 'title': 'Dictionaries'},
    {'component': <PythonDatastructuressets />, 'path': 'data-structure-sets', 'title': 'Sets'},
    {id:uuidv4(), 'path':'', title: 'Operations'},
    {'component': <PythonOperationsarithmetic />, 'path': 'arithmetic-operations', 'title': 'Arithmetic Operations'},
    {'component': <PythonOperationscomparison />, 'path': 'python-operations-comparison', 'title': 'Comparison Operations'},
    {'component': <PythonOperationslogical />, 'path': 'python-operations-logical', 'title': 'Logical Operations'},
    {id:uuidv4(), 'path':'', title: 'Conditional Statements'},
    {'component': <PythonIfelse />, 'path': 'python-if-else', 'title': 'if...else'},
    {id:uuidv4(), 'path':'', title: 'Loops'},
    {'component': <PythonForloops />, 'path': 'python-for-loop', 'title': 'for Loop'},
    {'component': <PythonWhileloop />, 'path': 'python-while-loop', 'title': 'while Loop'},
    {'component': <PythonLoopcontrolstatements />, 'path': 'python-loop-control-statements', 'title': 'Loop Control Statements'},
    {id:uuidv4(), 'path':'', title: 'Functions'},
    {'component': <PythonFunctionsdefinitionandparameters />, 'path': 'function-definition-and-parameters', 'title': 'Function Definition and Parameters'},
    {'component': <PythonFunctionsreturnstatements />, 'path': 'python-functions-return-statements', 'title': 'Return Statements'},
    {'component': <PythonFunctionslocalvariableandglobalvariable />, 'path': 'local-variable-and-global-variable', 'title': 'Local and Global Variables'},
    {'component': <PythonFunctionslambdafunctions />, 'path': 'python-lambda-functions', 'title': 'Lambda Functions'},
    {id:uuidv4(), 'path':'', title: 'Object Oriented Programming'},
    {'component': <PythonOopclassesandobjects />, 'path': 'python-classes-and-objects', 'title': 'Classes And Objects'},
    {'component': <PythonClassinheritance />, 'path': 'python-class-inheritance', 'title': 'Class Inheritances'},
    {id:uuidv4(), 'path':'', title: 'Modules and Libraries'},
    {'component': <PythonModulesandlibraries  />, 'path': 'python-modules-and-libraries', 'title': 'Modules And Libraries '},
    // {id:uuidv4(), component: <ExcelOpenpyxlStyle />, path:'use-openpyxl-to-change-excell-style', title: 'Vectors', 'cover':'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},
    {'component': '', 'path':'', title:''},

    // {id:uuidv4(), component: <ExcelOpenpyxl />, path:'use-openpyxl-to-update-excel', title: 'Load files', 'cover': 'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    // {id:uuidv4(), component: <ExcelOpenpyxlStyle />, path:'use-openpyxl-to-change-excell-style', title: 'Vectors', 'cover':'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
    // {id:uuidv4(), component: <SparkInfo />, path:'compare-Databricks-dataframe-view-table', title: 'Compare Databricks DataFrame, View, and Table', 'cover': 'https://s3.amazonaws.com/articles.rtistrystudio/cover_images/heatmap.svg', 'summary':'test test test test test test test test test test test test test test test test test test test test test test test test test test test test test testtest test test test test test'},
 
 ]


export default PythonTutorialData