import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function Python7(){
useCustomEffect()
return ( <div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Compute-Numerical-Statistics:-Min,-Max,-Sum,-Mean-and-More">Compute Numerical Statistics: Min, Max, Sum, Mean and More<a className="anchor-link" href="#Compute-Numerical-Statistics:-Min,-Max,-Sum,-Mean-and-More">¶</a></h3><p>When working with numerical data, it is a common practice to calculate statistics that provide aggregated insights for business reports. In this tutorial, we will guide you through the process of calculating various numerical statistics for a numeric column, including the minimum, maximum, sum, mean, count, and additional measures.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This tutorial uses classic Iris dataset, which can be downloaded here <a href="https://3codeacademy.s3.amazonaws.com/dataset/python/Iris.csv" id="downloadData">Iris dataset</a>.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [28]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="kn">import</span> <span className="nn">pandas</span> <span className="k">as</span> <span className="nn">pd</span></span>

<span><span className="n">df</span> <span className="o">=</span> <span className="n">pd</span><span className="o">.</span><span className="n">read_csv</span><span className="p">(</span><span className="s1">'Iris.csv'</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="1.-Find-Minimum-Value-via-min()">1. Find Minimum Value via <code>min()</code><a className="anchor-link" href="#1.-Find-Minimum-Value-via-min()">¶</a></h4><h5 id="Find-min-of-one-numerical-column">Find min of one numerical column<a className="anchor-link" href="#Find-min-of-one-numerical-column">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[</span><span className="s1">'SepalLengthCm'</span><span className="p">]</span><span className="o">.</span><span className="n">min</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>4.3</span></code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Find-min-of-multiple-numerical-columns">Find min of multiple numerical columns<a className="anchor-link" href="#Find-min-of-multiple-numerical-columns">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">min</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    4.3
<br />SepalWidthCm     2.0
<br />PetalLengthCm    1.0
<br />PetalWidthCm     0.1
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="2.-Find-Maximum-Value-via-max()">2. Find Maximum Value via <code>max()</code><a className="anchor-link" href="#2.-Find-Maximum-Value-via-max()">¶</a></h4><h5 id="Find-max-of-one-numerical-column">Find max of one numerical column<a className="anchor-link" href="#Find-max-of-one-numerical-column">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[</span><span className="s1">'SepalLengthCm'</span><span className="p">]</span><span className="o">.</span><span className="n">max</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>7.9</span></code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Find-max-of-multiple-numerical-columns">Find max of multiple numerical columns<a className="anchor-link" href="#Find-max-of-multiple-numerical-columns">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">max</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    7.9
<br />SepalWidthCm     4.4
<br />PetalLengthCm    6.9
<br />PetalWidthCm     2.5
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Calculate-Mean-Value-via-mean()">Calculate Mean Value via <code>mean()</code><a className="anchor-link" href="#Calculate-Mean-Value-via-mean()">¶</a></h4><h5 id="Calculate-mean-one-numerical-column">Calculate mean one numerical column<a className="anchor-link" href="#Calculate-mean-one-numerical-column">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [10]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[</span><span className="s1">'SepalLengthCm'</span><span className="p">]</span><span className="o">.</span><span className="n">mean</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>5.843333333333335</span></code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Calculate-mean-values-of-multiple-numerical-columns">Calculate mean values of multiple numerical columns<a className="anchor-link" href="#Calculate-mean-values-of-multiple-numerical-columns">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">mean</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    5.843333
<br />SepalWidthCm     3.054000
<br />PetalLengthCm    3.758667
<br />PetalWidthCm     1.198667
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="3.-Calculate-Sum-via-sum()">3. Calculate Sum via <code>sum()</code><a className="anchor-link" href="#3.-Calculate-Sum-via-sum()">¶</a></h4><h5 id="Calculate-sum-one-numerical-column">Calculate sum of one numerical column<a className="anchor-link" href="#Calculate-sum-one-numerical-column">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[</span><span className="s1">'SepalLengthCm'</span><span className="p">]</span><span className="o">.</span><span className="n">sum</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>876.5</span></code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Calculate-sum-of-multiple-numerical-columns">Calculate sum of multiple numerical columns<a className="anchor-link" href="#Calculate-sum-of-multiple-numerical-columns">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [13]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">sum</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    876.5
<br />SepalWidthCm     458.1
<br />PetalLengthCm    563.8
<br />PetalWidthCm     179.8
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="4.-Calculate-Counts-via-count()">4. Calculate Counts via <code>count()</code><a className="anchor-link" href="#4.-Calculate-Counts-via-count()">¶</a></h4><h5 id="Calculate-total-number-of-records-of-the-data">Calculate total number of records of the data<a className="anchor-link" href="#Calculate-total-number-of-records-of-the-data">¶</a></h5><p><code>df.count()</code> returns the number of records of each column in the dataset.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [15]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="o">.</span><span className="n">count</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Id               150
<br />SepalLengthCm    150
<br />SepalWidthCm     150
<br />PetalLengthCm    150
<br />PetalWidthCm     150
<br />Species          150
<br />dtype: int64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="5.-More-statistic-metrics">5. More Statistic Metrics<a className="anchor-link" href="#5.-More-statistic-metrics">¶</a></h4><p>In addition to the statistics above, other metrics offered by pandas are median (<code>median()</code>), mode (<code>mode()</code>), varaince(<code>var()</code>), standard deviation (<code>std()</code>), kurtosis (<code>kurt()</code>) and skewness (<code>skew()</code>).</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Quick-examples">Quick examples<a className="anchor-link" href="#Quick-examples">¶</a></h4>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Calculate-Median-of-Multiple-Columns-viamedian()">Calculate Median of Multiple Columns via <code>median()</code><a className="anchor-link" href="#Calculate-Median-of-Multiple-Columns-viamedian()">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [21]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">median</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    5.80
<br />SepalWidthCm     3.00
<br />PetalLengthCm    4.35
<br />PetalWidthCm     1.30
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Calculate-Variance-of-Multiple-Columns-via-var()">Calculate Variance of Multiple Columns via <code>var()</code><a className="anchor-link" href="#Calculate-Variance-of-Multiple-Columns-via-var()">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [23]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">var</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    0.685694
<br />SepalWidthCm     0.188004
<br />PetalLengthCm    3.113179
<br />PetalWidthCm     0.582414
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Calculate-Standard-Deviation-of-Multiple-Columns-via-std()">Calculate Standard Deviation of Multiple Columns via <code>std()</code><a className="anchor-link" href="#Calculate-Standard-Deviation-of-Multiple-Columns-via-std()">¶</a></h5>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [27]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="p">[[</span><span className="s1">'SepalLengthCm'</span><span className="p">,</span> <span className="s1">'SepalWidthCm'</span><span className="p">,</span> <span className="s1">'PetalLengthCm'</span><span className="p">,</span> <span className="s1">'PetalWidthCm'</span><span className="p">]]</span><span className="o">.</span><span className="n">std</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedText jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>SepalLengthCm    0.828066
<br />SepalWidthCm     0.433594
<br />PetalLengthCm    1.764420
<br />PetalWidthCm     0.763161
<br />dtype: float64</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Excellent! Now that we've explored the methods for calculating statistics, we'll shift our focus towards learning methods of finding unique values.</p>
</div>
</div>
</div>
</div>
</div>
)}