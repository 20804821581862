import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2WrapLongTexts from '../tutorial/wrap_texts_title_overflow.png';

export default function Ggplot2WrapLongTexts(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Wrap Long Texts in ggplot2 with 4 Distinct Approaches</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In this article, we’ll discuss how to wrap texts. First, we’ll talk about how to wrap long plot titles using three distinct approaches. Lastly, we’ll take a special look at how to quickly wrap axial labels using a fourth solution.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">theme_set</span>(</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="fu">theme_minimal</span>() <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"turquoise4"</span>)))</span></code></pre></div>
</div>
<p>The title in the following scatterplot extends beyond the plot boundary, and is not displayed completely. Below we’ll discuss three distinct approaches to deal with this situation.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>myTitle <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="st">"The Iris flower data set or Fisher's Iris data set is a multivariate data set used and made famous by the British statistician and biologist Ronald Fisher in his 1936 paper on the use of multiple measurements in taxonomic problems as an example of linear discriminant analysis."</span>)</span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a></span><br/>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>a <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(Sepal.Length, Sepal.Width)) <span className="sc">+</span></span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">size =</span> <span className="dv">6</span>) </span>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a></span><br/>
<span id="cb2-8"><a aria-hidden="true" href="#cb2-8" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">ggtitle</span>(myTitle)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2WrapLongTexts} /></p>
</figure>
</div>
</div>
</div>
<hr/>
<section className="level4" id="method-1-manually-insert-line-breaks-in-the-title-script">
<h4 className="anchored" data-anchor-id="method-1-manually-insert-line-breaks-in-the-title-script">Method 1: Manually insert line breaks in the title script</h4>
<ul>
<li><p>Simply type the <strong>Enter</strong> key (in Windows) or <strong>return</strong> (in Mac) to break the lines in the script of the title string. This not only introduces visible line breaks in the rendered graphic, but also makes the script more readable (<em>see plot title 1st line</em>).</p></li>
<li><p>Alternatively, insert <code>\n</code> in the string anywhere desired to make a line break. A <code>\n</code> in the middle of a script line shifts following texts to a new line; a <code>\n</code> at the end of a script line introduces an empty line (<em>see plot title 3rd empty line</em>).</p></li>
<li><p>The line breaker <code>&lt;br&gt;</code> in HTML will be interpreted literally, and does <em>not</em> work to make a line break in this context (<em>see plot title 4th line</em>).</p></li>
<li><p>Techniques above will behave differently when <code>ggtext</code> is used (see <a href="#ggtext">method 3</a>).</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>myTitle_2 <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="st">"The Iris flower dataset is a multivariate data set used</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a><span className="st">  and made famous by the British statistician and biologist</span><span className="sc">\n</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="st">  Ronald Fisher in his 1936 paper on the &lt;br&gt;&lt;br&gt; use of</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a><span className="st">  multiple measurements in taxonomic problems  </span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a><span className="st">  as an example of linear discriminant analysis."</span>)</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a></span><br/>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">ggtitle</span>(myTitle_2)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/wrap_texts_manual_line_break.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-2-use-str_wrap-from-the-stringr-package.">
<h4 className="anchored" data-anchor-id="method-2-use-str_wrap-from-the-stringr-package.">Method 2: Use <strong><code>str_wrap()</code></strong> from the <Link to="/R/data-wrangling/stringr/0-introduction"><strong><code>stringr</code></strong></Link> package.</h4>
<p>Here we demonstrate using the original title <code>myTitle</code>, which does <em>not</em> contain any line breaker. We use <code>str_wrap()</code> for automatic text wrapping. The <code>width</code> argument specifies the maximum number of characters in each line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">ggtitle</span>(<span className="fu">str_wrap</span>(myTitle, <span className="at">width =</span> <span className="dv">50</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/wrap_texts_stringr_str_wrap.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="ggtext">
<h4 className="anchored" data-anchor-id="ggtext">Method 3: Format the title with <strong><code>ggtext</code></strong> package</h4>
<p><Link to="https://wilkelab.org/ggtext/"><code>ggtext</code></Link> is a very useful package that formats a selected piece of texts regarding their font, color, size, bold and italics, sub- or superscript. <strong>Its use involves two steps:</strong></p>
<p><strong>(1)</strong> Use HTML markup language in the title string.</p>
<ul>
<li><p>In the context of <code>ggtext</code>, you can break the lines in the script by typing the <em>Enter</em> key (in Windows) or <em>return</em> (in Mac), but <em>without</em> the line breaks displayed in the rendered graphic.</p></li>
<li><p>To make a line break in the rendered graphic, add the HTML tag <code>&lt;br&gt;</code> anywhere desired (<em>see 1st, 2nd, and 4th line in plot title</em>). Alternatively, type two white spaces <em>at the end of each line</em> to make a line break. Or use <code>\n</code> <em>at the end of each line</em> to start a new paragraph with larger line break margin (<em>see 4th line in plot title</em>).</p></li>
<li><p>The aforementioned <code>str_wrap()</code> function is <em>not</em> effective in the <code>ggtext</code> context.</p></li>
</ul>
<p><strong>(2)</strong> In the <code>theme()</code> function, use <code>element_markdown()</code> in place of <code>element_text()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a></span><br/>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>myTitle_3 <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="st">"The Iris flower dataset &lt;br&gt; is a multivariate dataset </span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a><span className="st">  used and made famous by the British &lt;br&gt; statistician </span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a><span className="st">  and biologist Ronald Fisher in his 1936 paper</span><span className="sc">\n</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a><span className="st">  on the use of multiple measurements in taxonomic problems &lt;br&gt; as </span></span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a><span className="st">  an example of linear discriminant analysis."</span>)</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a></span><br/>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">ggtitle</span>(myTitle_3) <span className="sc">+</span></span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_markdown</span>())</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/wrap_texts_ggtext.png"/></p>
</figure>
</div>
</div>
</div>
<p>You can also use <code>element_textbox_simple()</code> to wrap texts automatically. Here we demonstrate using the original <code>myTitle</code> which does <em>not</em> contain any manually added line breaker.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>a <span className="sc">+</span> <span className="fu">ggtitle</span>(myTitle) <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="co"># increase the margin on top and bottom of the title</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  ))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/wrap_texts_automatic_wrap.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-4-wrap-axial-labels-with-the-scales-package">
<h4 className="anchored" data-anchor-id="method-4-wrap-axial-labels-with-the-scales-package">Method 4: Wrap axial labels with the <code>scales</code> package</h4>
<p>The x-axis labels in the following scatterplot is greatly overlapped. Wrapping the labels is a great way to improve readability. (Check out <Link to="../2-ggplot2-overcrowded-text-labels">additional approaches to address overcrowded axis labels</Link>)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>course <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="st">"Environmental science and policy"</span>, <span className="st">"Global economic issues"</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="st">"Introduction to ggplot2 visualization"</span>, <span className="st">"Introduction to psychology"</span>, </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="st">"Quantum mechanics fundamentals"</span>)</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>scores <span className="ot">=</span> <span className="fu">c</span>(<span className="dv">78</span>, <span className="dv">90</span>, <span className="dv">100</span>, <span className="dv">87</span>, <span className="dv">90</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a></span><br/>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>t <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">course =</span> course, <span className="at">scores =</span> scores)</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a></span><br/>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>b <span className="ot">&lt;-</span> <span className="fu">ggplot</span>(t, <span className="fu">aes</span>(course, scores)) <span className="sc">+</span> </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">alpha =</span> .<span className="dv">2</span>) <span className="sc">+</span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"turquoise4"</span>, <span className="at">face =</span> <span className="st">"bold"</span>))</span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>b</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/wrap_texts_overlap.png"/></p>
</figure>
</div>
</div>
</div>
<p>The <code>label_wrap()</code> function from the popular <Link to="https://scales.r-lib.org/"><code>scales</code></Link> package offers a quick solution to wrap axial labels. The <code>width</code> argument specifies the maximum number of characters in each line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(scales)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>b <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(<span className="at">labels =</span> <span className="fu">label_wrap</span>(<span className="at">width =</span> <span className="dv">15</span>))  </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/wrap_texts_scales_label_wrap.png"/></p>
</figure>
</div>
</div>
</div>
<p>Alternatively, we can achieve the same effect using the aforementioned <code>str_wrap()</code> function from the <code>stringr</code> package, yet with a slightly different syntax.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>b <span className="sc">+</span> <span className="fu">scale_x_discrete</span>(<span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">str_wrap</span>(x, <span className="dv">15</span>)&#125;)  </span></code></pre></div>
</div>
</section>
</main>
</div>
</div>
)}