import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Introduction(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Welcome to Purrr Package!</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>The <strong>purrr</strong> package provides a complete and consistent set of tools for functional programming, and allows you to perform complex data wrangling tasks and computations with high efficiency.</p>
<section className="level3" id="installation">
<h3 className="anchored" data-anchor-id="installation">Installation</h3>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># The easiest way to get purrr is to install the whole tidyverse:</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"tidyverse"</span>)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># Alternatively, install just purrr:</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"purrr"</span>)</span></code></pre></div>
</div>
</section>
<section className="level3" id="content-of-tutorial">
<h3 className="anchored" data-anchor-id="content-of-tutorial">Content of Tutorial</h3>
<p><strong>Functional iteration across elements of one or more vectors or lists</strong></p>
<p>The best place to start is the family of <code>map()</code> functions which allow you to replace many <code>for</code> loops with code that is both more succinct and easier to read.</p>
<ul>
<li><p><Link to="../map"><strong><code>map()</code></strong></Link> applies a function to each element of a vector or list, and returns a <em>list</em>. Its variant functions <code>map_lgl()</code>, <code>map_int()</code>, and <code>map_chr()</code> returns a <em>vector</em> of the indicated type.</p></li>
<li><p><Link to="../map2"><strong><code>map2()</code></strong></Link> applies a function to each paired elements of <em>two inputs</em> (vector or list). Its variant functions <code>map2_lgl()</code>, <code>map2_int()</code>, and <code>map2_chr()</code> returns a <em>vector</em> of the indicated type.</p></li>
<li><p><Link to="../pmap"><strong><code>pmap()</code></strong></Link> applies a function to elements of <em>multiple inputs</em> in parallel. Its variant functions <code>pmap_lgl()</code>, <code>pmap_int()</code>, and <code>pmap_chr()</code> returns a <em>vector</em> of the indicated type.</p></li>
</ul>
<p><strong>Getting or setting a single element.</strong></p>
<ul>
<li><p><Link to="../pluck-part1"><strong><code>pluck()</code></strong></Link> is a generalized form of <code>[[ ]]</code> that extracts elements from nested data structure.</p></li>
<li><p><Link to="../pluck-part2"><strong><code>pluck_depth()</code></strong></Link> calculates the level of nesting.</p></li>
<li><p><Link to="../pluck-part2"><strong><code>pluck_exists()</code></strong></Link> checks if the specified element is present or not.</p></li>
<li><p><Link to="../pluck-part2"><strong><code>chuck()</code></strong></Link> is like <code>pluck()</code> but returns an error for non-existing elements to be extracted.</p></li>
</ul>
</section>
</main>
</div>
</div>
)}