import Clipboard from "clipboard";

export function addKlippy(e) {
    const elements = document.querySelectorAll(e);
    // console.log(elements)
    for (let i = 0; i < elements.length; i += 1) {
        elements[i].className += ' klippy';
    };
}

function getUriOcticonClippy(e,t){
    var o;
    if(e === 'auto'){
        var n=document.createElement("a");
        document.body.appendChild(n);
        o=window.getComputedStyle(n).getPropertyValue("color");
        document.body.removeChild(n);
    } else {
        o=e;
    }

    // replace the copy button svg image 
    var newSvg = '<svg fill="#f7f7f7" width="256px" height="256px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" stroke="#f7f7f7" stroke-width="0.00016"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M13.49 3 10.74.37A1.22 1.22 0 0 0 9.86 0h-4a1.25 1.25 0 0 0-1.22 1.25v11a1.25 1.25 0 0 0 1.25 1.25h6.72a1.25 1.25 0 0 0 1.25-1.25V3.88a1.22 1.22 0 0 0-.37-.88zm-.88 9.25H5.89v-11h2.72v2.63a1.25 1.25 0 0 0 1.25 1.25h2.75zm0-8.37H9.86V1.25l2.75 2.63z"/><path d="M10.11 14.75H3.39v-11H4V2.5h-.61a1.25 1.25 0 0 0-1.25 1.25v11A1.25 1.25 0 0 0 3.39 16h6.72a1.25 1.25 0 0 0 1.25-1.25v-.63h-1.25z"/></g></svg>'
    // var l='<?xml version="1.0" encoding="UTF-8"?><svg version="1.1" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g fill="'
    // +o+'" opacity="'
    // +t+'"><path d="m2 13h4v1h-4v-1zm5-6h-5v1h5v-1zm2 3v-2l-3 3 3 3v-2h5v-2h-5zm-4.5-1h-2.5v1h2.5v-1zm-2.5 3h2.5v-1h-2.5v1zm9 1h1v2c-0.02 0.28-0.11 0.52-0.3 0.7s-0.42 0.28-0.7 0.3h-10c-0.55 0-1-0.45-1-1v-11c0-0.55 0.45-1 1-1h3c0-1.11 0.89-2 2-2s2 0.89 2 2h3c0.55 0 1 0.45 1 1v5h-1v-3h-10v9h10v-2zm-9-8h8c0-0.55-0.45-1-1-1h-1c-0.55 0-1-0.45-1-1s-0.45-1-1-1-1 0.45-1 1-0.45 1-1 1h-1c-0.55 0-1 0.45-1 1z"/></g></g></svg>';
    return"data:image/svg+xml;base64,"+window.btoa(newSvg)
}

function changeTooltipMessage(e,t){
    e.setAttribute("aria-label",t);
   
    setTimeout(function () {
        // e.removeAttribute("aria-label");
        // e.setAttribute("arial-label", 'click to copy')
      }, 10);
     
}

export function initKlippy(t,o,n,l,i,a){ 
        var r=getUriOcticonClippy(n,l);
        var c="<button type='button' class='btn-klippy tooltipped tooltipped-no-delay' aria-label='"
            +i+
            "' onfocusout='changeTooltipMessage(this,&quot;"
            +i+
            "&quot;)' data-clipboard-klippy><div><img class='octicon' src='"
            +r+
            "' alt='Copy'></div></button>";
        // const d=document.querySelectorAll(".klippy > code");
        const d=document.querySelectorAll("pre.klippy");
        for(let p=0; p<d.length; p++){
                d.item(p).insertAdjacentHTML("beforebegin",c); //add <button> defined as c right before <code> element
            }
             
            var s=document.getElementsByClassName("btn-klippy");
            Array.prototype.map.call(s,function(e){
                var n=e.parentElement,l=window.getComputedStyle(n).getPropertyValue("padding-"+t);
                i=e.querySelector(".octicon");
                i.style.width=l;
                i.style.verticalAlign=o;
                if (t === 'right') {
                    e.style.right="10";
                    e.className+=" tooltipped-w";
                } else {
                    e.style.left="0";
                    e.className+=" tooltipped-e";
                };
                if (o === "bottom") {
                    e.style.bottom="0";
                } else {
                    e.style.top="0";
                };
            });
            // new code to modify copy texts function
            // var clipboard = new ClipboardJS('.btn');
            // new code to modify copy texts function


            var u=new Clipboard(
                "[data-clipboard-klippy]",{
                    text:function(e){
                        return e.parentNode.querySelector("code").innerText
                    }
                });

            u.on("success",function(e){
                changeTooltipMessage(e.trigger,a);
                e.clearSelection()
            });

            u.on("error",function(e){
                changeTooltipMessage(e.trigger,
                    "Press Ctrl+C or Command+C to copy")
            });           
    }

