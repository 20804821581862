import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Alternation(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Alternation: Match Any One of Specified Patterns</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>|</code></strong> denotes alternation, and allows you to match one of the two or more specified patterns</p>
<p><strong><em>eg. 1.</em></strong> <code>animal|cat|dog</code> matches “animal”, “cat”, and “dog” in a string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"I have a cat"</span>, </span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>       <span className="st">"Tom has a dog"</span>, </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>       <span className="st">"There are many animals on the farm"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"animal|cat|dog"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ I have a &lt;cat&gt;
<br/>[2] │ Tom has a &lt;dog&gt;
<br/>[3] │ There are many &lt;animal&gt;s on the farm</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract</span>(x, <span className="st">"animal|cat|dog"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "cat"    "dog"    "animal"</code></pre>
</div>
</div>
</div>
<p><strong><em>eg. 2.</em></strong> <code>\\d&#123;3,7&#125;</code> matches any 3 to 7 consecutive digits, and <code>[:alpha:]&#123;4,6&#125;</code> matches any 4 to 6 alphabetical letters. <code>\\d&#123;3,7&#125;|[:alpha:]&#123;4,6&#125;</code> makes a match with both patterns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"233-abcd-12345"</span>, <span className="st">"124-ABCDEF-79265"</span>)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>p <span className="ot">&lt;-</span> <span className="st">"</span><span className="sc">\\</span><span className="st">d&#123;3,7&#125;|[:alpha:]&#123;4,6&#125;"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, p)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;233&gt;-&lt;abcd&gt;-&lt;12345&gt;
<br/>[2] │ &lt;124&gt;-&lt;ABCDEF&gt;-&lt;79265&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(a, p, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]  [,2]     [,3]   
<br/>[1,] "233" "abcd"   "12345"
<br/>[2,] "124" "ABCDEF" "79265"</code></pre>
</div>
</div>
</div>
</main>
</div>
</div>
)}