import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2PhoneZoomIn from '../graphics/telephone_theme_completed.png'; 
import imgGgplot2PhoneZoomInWebp from '../graphics/telephone_theme_completed.webp'; 
export default function Ggplot2PhoneZoomIn(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create a Connected-Scatterplot with Zoom-In in ggplot2 to Visualize Increase of Phones in 1950s</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2PhoneZoomInWebp} />
    <img className="cover-img" src={imgGgplot2PhoneZoomIn} />
  </picture>

<p>In this work, we’ll use R ggplot2 to create a connected scatterplot to visualize the increasing popularity of telephones in the world in the 1950s. In particular, we’ll leverage the <Link to="https://ggforce.data-imaginist.com/">ggforce</Link> package to create a zoom-in view over clustered data regions.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and Data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(ggforce)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>())</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>w <span className="ot">&lt;-</span> WorldPhones <span className="sc">%&gt;%</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="fu">as_tibble</span>(<span className="at">rownames =</span> <span className="st">"year"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>    <span className="sc">-</span>year, </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"region"</span>, <span className="at">values_to =</span> <span className="st">"number"</span>)</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>w</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 49 × 3
<br/>  year  region  number
<br/>  &lt;chr&gt; &lt;chr&gt;    &lt;dbl&gt;
<br/>1 1951  N.Amer   45939
<br/>2 1951  Europe   21574
<br/>3 1951  Asia      2876
<br/>4 1951  S.Amer    1815
<br/>5 1951  Oceania   1646
<br/># ℹ 44 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a line plot showing the number of telephones in each continent or geographic region.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> w <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> number, <span className="at">color =</span> region)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> region))</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/telephone_line.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/telephone_line"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Create a scatterplot showing individual data points. The first layer of points (as in <code>p2</code>) creates white opaque points residing on top of the lines. The second (top) layer of points (as in <code>p3</code>) paints semi-transparent colors associated with the lines. This two-layer-point technique draws points without lines passing through them.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1<span className="sc">+</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">shape =</span> <span className="dv">21</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fill =</span> <span className="st">"white"</span>) </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a></span><br/>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">shape =</span> <span className="dv">21</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="fu">aes</span>(<span className="at">fill =</span> region), <span className="at">alpha =</span> .<span className="dv">5</span>) </span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a></span><br/>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>cowplot<span className="sc">::</span><span className="fu">plot_grid</span>(p2, p3, <span className="at">nrow =</span> <span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/telephone_scatterplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/telephone_scatterplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Label the continent or geographic regions the data points are associated with.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    <span className="at">data =</span> w <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">1960</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> region), </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    <span className="co"># shift the text by different amount for different regions</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>    <span className="at">nudge_y =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">6000</span>, <span className="dv">5000</span>, <span className="dv">4000</span>, <span className="dv">500</span>, <span className="sc">-</span><span className="dv">400</span>, <span className="sc">-</span><span className="dv">400</span>, <span className="sc">-</span><span className="dv">400</span>),</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/telephone_labels.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/telephone_labels"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Create a separate panel to zoom in over clustered data points at the plot bottom. This can be easily achieved using the <code>facet_zoom()</code> function from the <Link to="https://ggforce.data-imaginist.com/">ggforce</Link> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">facet_zoom</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="at">y =</span> <span className="sc">!</span> region <span className="sc">%in%</span> <span className="fu">c</span>(<span className="st">"N.Amer"</span>, <span className="st">"Europe"</span>, <span className="st">"Asia"</span>), </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">zoom.size =</span> <span className="dv">1</span>, <span className="co"># same size as the main plot</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">ylim =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">4000</span>)) </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/telephone_zoom_in_ggforce.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/telephone_zoom_in_ggforce"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Make some final outlook adjustment.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="at">n.breaks =</span> <span className="dv">10</span>,</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="co"># the original number in the dataset is given in thousands, </span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="co"># further divide by 1000 to make unit in millions</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x)&#123; <span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">1000</span>) &#125;) <span className="sc">+</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="co"># adjust axis labels and plot titles</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"numbers (in millions)"</span>, </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>       <span className="at">x =</span> <span className="cn">NULL</span>,</span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Number of telephones in the world"</span>) <span className="sc">+</span></span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>  </span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"floralwhite"</span>),</span>
<span id="cb7-18"><a aria-hidden="true" href="#cb7-18" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">15</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>)) </span>
<span id="cb7-19"><a aria-hidden="true" href="#cb7-19" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/telephone_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/telephone_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a><span className="fu">library</span>(ggforce)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>())</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>w <span className="ot">&lt;-</span> WorldPhones <span className="sc">%&gt;%</span> </span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>  <span className="fu">as_tibble</span>(<span className="at">rownames =</span> <span className="st">"year"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>    <span className="sc">-</span>year, </span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"region"</span>, <span className="at">values_to =</span> <span className="st">"number"</span>)</span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>w</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a></span><br/>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a><span className="do">### Visualization</span></span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a></span><br/>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a><span className="co"># Create a line plot</span></span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> w <span className="sc">%&gt;%</span> </span>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> number, <span className="at">color =</span> region)) <span className="sc">+</span></span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> region))</span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a>p1</span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a></span><br/>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a><span className="co"># Create a scatterplot showing individual data points.</span></span>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1<span className="sc">+</span></span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">shape =</span> <span className="dv">21</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fill =</span> <span className="st">"white"</span>) </span>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a></span><br/>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">shape =</span> <span className="dv">21</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="fu">aes</span>(<span className="at">fill =</span> region), <span className="at">alpha =</span> .<span className="dv">5</span>) </span>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a></span><br/>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a>cowplot<span className="sc">::</span><span className="fu">plot_grid</span>(p2, p3, <span className="at">nrow =</span> <span className="dv">1</span>)</span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a></span><br/>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a><span className="co"># Label the continent or geographic regions</span></span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a>    <span className="at">data =</span> w <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">1960</span>),</span>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> region), </span>
<span id="cb8-36"><a aria-hidden="true" href="#cb8-36" tabindex="-1"></a>    <span className="co"># shift the text by different amount for different regions</span></span>
<span id="cb8-37"><a aria-hidden="true" href="#cb8-37" tabindex="-1"></a>    <span className="at">nudge_y =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">6000</span>, <span className="dv">5000</span>, <span className="dv">4000</span>, <span className="dv">500</span>, <span className="sc">-</span><span className="dv">400</span>, <span className="sc">-</span><span className="dv">400</span>, <span className="sc">-</span><span className="dv">400</span>),</span>
<span id="cb8-38"><a aria-hidden="true" href="#cb8-38" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) </span>
<span id="cb8-39"><a aria-hidden="true" href="#cb8-39" tabindex="-1"></a>p4</span>
<span id="cb8-40"><a aria-hidden="true" href="#cb8-40" tabindex="-1"></a></span><br/>
<span id="cb8-41"><a aria-hidden="true" href="#cb8-41" tabindex="-1"></a><span className="co"># Create a separate panel to zoom in over clustered data points. </span></span>
<span id="cb8-42"><a aria-hidden="true" href="#cb8-42" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb8-43"><a aria-hidden="true" href="#cb8-43" tabindex="-1"></a>  <span className="fu">facet_zoom</span>(</span>
<span id="cb8-44"><a aria-hidden="true" href="#cb8-44" tabindex="-1"></a>    <span className="at">y =</span> <span className="sc">!</span> region <span className="sc">%in%</span> <span className="fu">c</span>(<span className="st">"N.Amer"</span>, <span className="st">"Europe"</span>, <span className="st">"Asia"</span>), </span>
<span id="cb8-45"><a aria-hidden="true" href="#cb8-45" tabindex="-1"></a>    <span className="at">zoom.size =</span> <span className="dv">1</span>, <span className="co"># same size as the main plot</span></span>
<span id="cb8-46"><a aria-hidden="true" href="#cb8-46" tabindex="-1"></a>    <span className="at">ylim =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">4000</span>)) </span>
<span id="cb8-47"><a aria-hidden="true" href="#cb8-47" tabindex="-1"></a>p5</span>
<span id="cb8-48"><a aria-hidden="true" href="#cb8-48" tabindex="-1"></a></span><br/>
<span id="cb8-49"><a aria-hidden="true" href="#cb8-49" tabindex="-1"></a><span className="co"># Make some final outlook adjustment. </span></span>
<span id="cb8-50"><a aria-hidden="true" href="#cb8-50" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span></span>
<span id="cb8-51"><a aria-hidden="true" href="#cb8-51" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb8-52"><a aria-hidden="true" href="#cb8-52" tabindex="-1"></a>    <span className="at">n.breaks =</span> <span className="dv">10</span>,</span>
<span id="cb8-53"><a aria-hidden="true" href="#cb8-53" tabindex="-1"></a>    <span className="co"># the original number in the dataset is given in thousands, </span></span>
<span id="cb8-54"><a aria-hidden="true" href="#cb8-54" tabindex="-1"></a>    <span className="co"># further divide by 1000 to make unit in millions</span></span>
<span id="cb8-55"><a aria-hidden="true" href="#cb8-55" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x)&#123; <span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">1000</span>) &#125;) <span className="sc">+</span></span>
<span id="cb8-56"><a aria-hidden="true" href="#cb8-56" tabindex="-1"></a>  </span>
<span id="cb8-57"><a aria-hidden="true" href="#cb8-57" tabindex="-1"></a>  <span className="co"># adjust axis labels and plot titles</span></span>
<span id="cb8-58"><a aria-hidden="true" href="#cb8-58" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"numbers (in millions)"</span>, </span>
<span id="cb8-59"><a aria-hidden="true" href="#cb8-59" tabindex="-1"></a>       <span className="at">x =</span> <span className="cn">NULL</span>,</span>
<span id="cb8-60"><a aria-hidden="true" href="#cb8-60" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Number of telephones in the world"</span>) <span className="sc">+</span></span>
<span id="cb8-61"><a aria-hidden="true" href="#cb8-61" tabindex="-1"></a>  </span>
<span id="cb8-62"><a aria-hidden="true" href="#cb8-62" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb8-63"><a aria-hidden="true" href="#cb8-63" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-64"><a aria-hidden="true" href="#cb8-64" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-65"><a aria-hidden="true" href="#cb8-65" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb8-66"><a aria-hidden="true" href="#cb8-66" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"floralwhite"</span>),</span>
<span id="cb8-67"><a aria-hidden="true" href="#cb8-67" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">15</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>)) </span>
<span id="cb8-68"><a aria-hidden="true" href="#cb8-68" tabindex="-1"></a>p6</span></code></pre></div>
</div>
</div>
</div>
</div>
</main>
</div>
</div>
)}