import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2RadarWine from '../graphics/radar_plot_car_completed.png'; 
import imgGgplot2RadarWineWebp from '../graphics/radar_plot_car_completed.webp'; 
export default function Ggplot2RadarWine(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Wine qualities with Radar Plot in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2RadarWineWebp} />
    <img className="cover-img" src={imgGgplot2RadarWine} />
  </picture>

<p>Here we visualize wine attributes with a radar plot using the <Link to="https://github.com/ricardo-bion/ggradar"><code>ggradar</code></Link> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># devtools::install_github("ricardo-bion/ggradar",</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co">#                          dependencies = TRUE)</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">library</span>(ggradar)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="co"># create a toy example</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>wine <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">wine =</span> <span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>), <span className="at">Fruity =</span> <span className="fu">c</span>(<span className="dv">4</span>, <span className="dv">6</span>), </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>               <span className="at">Acidic =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">6</span>),   <span className="at">Oaky =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">5</span>),</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>               <span className="at">Tannic =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">1</span>),   <span className="at">Sweet =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">2</span>), </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>               <span className="at">Body =</span> <span className="fu">c</span>(<span className="dv">6</span>, <span className="dv">3</span>))</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>wine</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 2 × 7
<br/>  wine  Fruity Acidic  Oaky Tannic Sweet  Body
<br/>  &lt;chr&gt;  &lt;dbl&gt;  &lt;dbl&gt; &lt;dbl&gt;  &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>1 A          4      2     2      5     5     6
<br/>2 B          6      6     5      1     2     3</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>wine <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggradar</span>(</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>    <span className="co"># The circular outlines:</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>    <span className="co"># min to max: 1-6; and merge the circular min and mid line</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="at">grid.min =</span> <span className="dv">1</span>, <span className="at">grid.mid =</span> <span className="dv">1</span>, <span className="at">grid.max =</span> <span className="dv">6</span>, </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>    <span className="co"># values to display on the circular outline</span></span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>    <span className="at">values.radar =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">6</span>), </span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>    <span className="co"># not show the middle level circular outline</span></span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>    <span className="at">label.gridline.mid =</span> F, </span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>    </span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>    <span className="co"># set the min and mid (merged) circular outline</span></span>
<span id="cb3-12"><a aria-hidden="true" href="#cb3-12" tabindex="-1"></a>    <span className="at">gridline.max.colour =</span> <span className="st">"salmon1"</span>,</span>
<span id="cb3-13"><a aria-hidden="true" href="#cb3-13" tabindex="-1"></a>    <span className="at">gridline.mid.colour =</span> <span className="st">"salmon1"</span>, </span>
<span id="cb3-14"><a aria-hidden="true" href="#cb3-14" tabindex="-1"></a>    </span>
<span id="cb3-15"><a aria-hidden="true" href="#cb3-15" tabindex="-1"></a>    <span className="co"># radiant lines</span></span>
<span id="cb3-16"><a aria-hidden="true" href="#cb3-16" tabindex="-1"></a>    <span className="at">axis.line.colour =</span> <span className="st">"salmon1"</span>, </span>
<span id="cb3-17"><a aria-hidden="true" href="#cb3-17" tabindex="-1"></a>    </span>
<span id="cb3-18"><a aria-hidden="true" href="#cb3-18" tabindex="-1"></a>    <span className="co"># radar background</span></span>
<span id="cb3-19"><a aria-hidden="true" href="#cb3-19" tabindex="-1"></a>    <span className="at">background.circle.colour =</span> <span className="st">"gold"</span>,</span>
<span id="cb3-20"><a aria-hidden="true" href="#cb3-20" tabindex="-1"></a>    <span className="at">background.circle.transparency =</span> .<span className="dv">05</span>,</span>
<span id="cb3-21"><a aria-hidden="true" href="#cb3-21" tabindex="-1"></a>    </span>
<span id="cb3-22"><a aria-hidden="true" href="#cb3-22" tabindex="-1"></a>    <span className="co"># the polygon color</span></span>
<span id="cb3-23"><a aria-hidden="true" href="#cb3-23" tabindex="-1"></a>    <span className="at">fill =</span> T, <span className="at">fill.alpha =</span> .<span className="dv">1</span> </span>
<span id="cb3-24"><a aria-hidden="true" href="#cb3-24" tabindex="-1"></a>  ) <span className="sc">+</span></span>
<span id="cb3-25"><a aria-hidden="true" href="#cb3-25" tabindex="-1"></a>  </span>
<span id="cb3-26"><a aria-hidden="true" href="#cb3-26" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb3-27"><a aria-hidden="true" href="#cb3-27" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb3-28"><a aria-hidden="true" href="#cb3-28" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="fl">7.5</span>, <span className="dv">5</span>), </span>
<span id="cb3-29"><a aria-hidden="true" href="#cb3-29" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(.<span className="dv">3</span>, <span className="fl">5.5</span>), </span>
<span id="cb3-30"><a aria-hidden="true" href="#cb3-30" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"wine of</span><span className="sc">\n</span><span className="st">champion"</span>, <span className="st">"wine of</span><span className="sc">\n</span><span className="st">glory"</span>), </span>
<span id="cb3-31"><a aria-hidden="true" href="#cb3-31" tabindex="-1"></a>           <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"red4"</span>, <span className="st">"purple4"</span>), </span>
<span id="cb3-32"><a aria-hidden="true" href="#cb3-32" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">6</span>) </span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out the following article that visualizes the <Link to="../ggplot2-map-airline"><strong>global flights and airports</strong></Link> illustrated below. And also check how to modify the script to <Link to="../ggplot2-map-airline-animation"><strong>turn static flight lines into animation</strong></Link>.</p>
<p><Link to="../ggplot2-map-airline">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Learn how to create this awesome <Link to="../ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link>, which shows dynamic shifts in the migrant population to the United States from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out how to generate <strong><Link to="../ggplot2-geofacet-US-states">exploded donut plots in a U.S. map layout</Link></strong> that visualizes the state-wise voting results of the 2016 Presidential Election between Hillary Clinton and Donald Trump .</p>
<p><Link to="../ggplot2-geofacet-US-states">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}