import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function FillUpMissingValues(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Fill in Missing Values with Previous or Next Value</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>fill()</code> fills missing value <code>NA</code> in selected columns using the value in the next or previous entry. This method is particularly useful in scenarios where data is recorded only when it changes and not repeated across consecutive entries.</p>
<p><strong>e.g.1.</strong> <code>fill()</code> defaults to replacing missing data from top to bottom; that is, it fills missing values using the value from the previous non-missing entry in the column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># create a dataset</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>sales <span className="ot">&lt;-</span> <span className="fu">tribble</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="sc">~</span>quarter, <span className="sc">~</span>year, <span className="sc">~</span>sales,</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="st">"Q1"</span>,     <span className="dv">1998</span>,  <span className="fl">5.52</span>,</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="st">"Q2"</span>,     <span className="cn">NA</span>,    <span className="fl">1.72</span>,</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="st">"Q3"</span>,     <span className="cn">NA</span>,    <span className="fl">6.69</span>,</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="st">"Q4"</span>,     <span className="cn">NA</span>,    <span className="fl">4.74</span>,</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="st">"Q1"</span>,     <span className="dv">2000</span>,  <span className="fl">9.77</span>,</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="st">"Q2"</span>,     <span className="cn">NA</span>,    <span className="fl">9.68</span>,</span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="st">"Q3"</span>,     <span className="cn">NA</span>,    <span className="fl">9.27</span>,</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  <span className="st">"Q4"</span>,     <span className="cn">NA</span>,    <span className="fl">5.96</span></span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>)</span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a></span><br/>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a><span className="co"># fill in missing values downwards (the default if not specified)</span></span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>sales <span className="sc">%&gt;%</span> <span className="fu">fill</span>(year, <span className="at">.direction =</span> <span className="st">"down"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 8 × 3
<br/>  quarter  year sales
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;dbl&gt;
<br/>1 Q1       1998  5.52
<br/>2 Q2       1998  1.72
<br/>3 Q3       1998  6.69
<br/>4 Q4       1998  4.74
<br/>5 Q1       2000  9.77
<br/>6 Q2       2000  9.68
<br/>7 Q3       2000  9.27
<br/>8 Q4       2000  5.96</code></pre>
</div>
</div>
<p><strong>e.g.2.</strong> Use <code>.direction = "up"</code> to fill in missing values upwards, i.e., replacing <code>NA</code> with the nearest non-missing value below in the column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># create a dataset</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>pets <span className="ot">&lt;-</span> <span className="fu">tribble</span>(</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="sc">~</span>rank, <span className="sc">~</span>pet_type, <span className="sc">~</span>breed,</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="dv">4</span>L,    <span className="cn">NA</span>,       <span className="st">"French Bulldogs"</span>,</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="dv">5</span>L,    <span className="cn">NA</span>,       <span className="st">"Bulldogs"</span>,</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="dv">6</span>L,    <span className="st">"Dog"</span>,    <span className="st">"Beagles"</span>,</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="dv">1</span>L,    <span className="cn">NA</span>,       <span className="st">"Persian"</span>,</span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  <span className="dv">2</span>L,    <span className="cn">NA</span>,       <span className="st">"Maine Coon"</span>,</span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="dv">6</span>L,    <span className="st">"Cat"</span>,    <span className="st">"American Short"</span></span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>)</span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a></span><br/>
<span id="cb3-12"><a aria-hidden="true" href="#cb3-12" tabindex="-1"></a><span className="co"># fill in missing values upwards</span></span>
<span id="cb3-13"><a aria-hidden="true" href="#cb3-13" tabindex="-1"></a>pets <span className="sc">%&gt;%</span> <span className="fu">fill</span>(pet_type, <span className="at">.direction =</span> <span className="st">"up"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 6 × 3
<br/>   rank pet_type breed          
<br/>  &lt;int&gt; &lt;chr&gt;    &lt;chr&gt;          
<br/>1     4 Dog      French Bulldogs
<br/>2     5 Dog      Bulldogs       
<br/>3     6 Dog      Beagles        
<br/>4     1 Cat      Persian        
<br/>5     2 Cat      Maine Coon     
<br/>6     6 Cat      American Short </code></pre>
</div>
</div>
<p><strong>e.g.3.</strong> Use <code>.direction = "downup"</code> to fill missing values in both directions: preferentially downwards; if not applicable, then upward. Alternatively, you an use <code>"updown"</code> to switch the preference order. In addition, the filling can be also operated on a per group basis specified by <Link to="/R/data-wrangling/dplyr/6-grouped-dataset"><code>group_by()</code></Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># create a dataset</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>fruits <span className="ot">&lt;-</span> <span className="fu">tribble</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="sc">~</span>store, <span className="sc">~</span>product, <span className="sc">~</span>stock,</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="st">"A"</span>,    <span className="st">"Apple"</span>,   <span className="dv">10</span>,</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="st">"A"</span>,    <span className="st">"Banana"</span>,  <span className="cn">NA</span>,</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="st">"A"</span>,    <span className="st">"Orange"</span>,  <span className="dv">20</span>,</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  <span className="st">"B"</span>,    <span className="st">"Apple"</span>,   <span className="cn">NA</span>,</span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="st">"B"</span>,    <span className="st">"Banana"</span>,  <span className="dv">15</span>,</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  <span className="st">"B"</span>,    <span className="st">"Orange"</span>,  <span className="cn">NA</span>,</span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>  <span className="st">"C"</span>,    <span className="st">"Apple"</span>,   <span className="cn">NA</span>,</span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  <span className="st">"C"</span>,    <span className="st">"Banana"</span>,  <span className="cn">NA</span>,</span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>  <span className="st">"C"</span>,    <span className="st">"Orange"</span>,  <span className="dv">30</span></span>
<span id="cb5-13"><a aria-hidden="true" href="#cb5-13" tabindex="-1"></a>)</span>
<span id="cb5-14"><a aria-hidden="true" href="#cb5-14" tabindex="-1"></a></span><br/>
<span id="cb5-15"><a aria-hidden="true" href="#cb5-15" tabindex="-1"></a>fruits <span className="sc">%&gt;%</span></span>
<span id="cb5-16"><a aria-hidden="true" href="#cb5-16" tabindex="-1"></a>  <span className="co"># separately for each store:</span></span>
<span id="cb5-17"><a aria-hidden="true" href="#cb5-17" tabindex="-1"></a>  <span className="fu">group_by</span>(store) <span className="sc">%&gt;%</span></span>
<span id="cb5-18"><a aria-hidden="true" href="#cb5-18" tabindex="-1"></a>  <span className="co"># fill in missing values upwards preferentially, otherwise downwards</span></span>
<span id="cb5-19"><a aria-hidden="true" href="#cb5-19" tabindex="-1"></a>  <span className="fu">fill</span>(stock, <span className="at">.direction =</span> <span className="st">"updown"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 9 × 3
<br/># Groups:   store [3]
<br/>  store product stock
<br/>  &lt;chr&gt; &lt;chr&gt;   &lt;dbl&gt;
<br/>1 A     Apple      10
<br/>2 A     Banana     20
<br/>3 A     Orange     20
<br/>4 B     Apple      15
<br/>5 B     Banana     15
<br/>6 B     Orange     15
<br/>7 C     Apple      30
<br/>8 C     Banana     30
<br/>9 C     Orange     30</code></pre>
</div>
</div>
</main>
</div>
</div>
)}