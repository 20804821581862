import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2AreaPlotMigration from '../graphics/area_plot_migration_theme_completed.png'; 
import imgGgplot2AreaPlotMigrationWebp from '../graphics/area_plot_migration_theme_completed.webp'; 
export default function Ggplot2AreaPlotMigration(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Stacked Area / Alluvial Plot in ggplot2 to Visualize Migration to U.S.</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><span id="US_migration_visualization"><img className="cover-img" src={imgGgplot2AreaPlotMigration} /></span></p>
<p>In this tutorial, we’ll create an <strong>annotated</strong> and <strong>stacked area / alluvial plot</strong> to visualize the changing population of migration to US from 1820 to 2009. The plot is a ggplot2 reproduction of the demo graphic made by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link> by Mirko Lorenz.</p>
<p><strong>Major techniques covered in this tutorial include:</strong></p>
<ul>
<li>Create stacked area / alluvial plot in specified order.</li>
<li>Annotate with texts and rectangles.</li>
<li>Customize colors and theme.</li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The data is sourced from the <Link to="https://www.dhs.gov/">US Department of Homeland Security</Link>, and a cleaned version used in this visualization can be downloaded <a className="downloadData" href="dataset/r-gallery/migration_to_US.csv">here</a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(ggalluvial)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/migration_to_US.csv"</span>)</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a></span><br/>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>year, </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"countries"</span>, </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"population"</span>)</span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>   year countries       population
<br/>  &lt;int&gt; &lt;chr&gt;                &lt;int&gt;
<br/>1  1825 Austria.Hungary          0
<br/>2  1825 Germany               5753
<br/>3  1825 Ireland              51617</code></pre>
</div>
</div>
<p><span id="colorvector"><strong>Create a vector of color names</strong></span>, with each color for each country. Here we use different shades of <span>blues</span> to represent <span>European</span> countries, distinct <span>yellows</span> for <span>Asian</span> countries, and varied <span>reds</span> for <span>American</span> countries, and so on. Transition of colors in different hues signifies the transition between continents. (more techniques about the <em>brewer</em> color palette used here can be found in this <Link to="../../R/visualization/15-ggplot2-color-brewer-palette">complete guide</Link>)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co">#  colors for 8 European countries</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>blues <span className="ot">&lt;-</span> <span className="fu">brewer.pal</span>(<span className="dv">8</span>, <span className="st">"Blues"</span>) </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a><span className="co">#  colors for 4 Asian countries </span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>yellows <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"cornsilk2"</span>, <span className="st">"yellow2"</span>, <span className="st">"gold"</span>, <span className="st">"orange"</span>) </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a><span className="co">#  colors for 6 American countries </span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>reds <span className="ot">&lt;-</span> <span className="fu">brewer.pal</span>(<span className="dv">6</span>, <span className="st">"Reds"</span>) </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a><span className="co">#  colors for 3 other countries</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>greys <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"grey80"</span>, <span className="st">"grey50"</span>, <span className="st">"grey20"</span>)   </span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a></span><br/>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a><span className="co"># combine all colors</span></span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>colors.countries <span className="ot">&lt;-</span> <span className="fu">c</span>(blues, yellows, reds, greys)</span></code></pre></div>
</div>
<p>Display the color hex codes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># preview the colors</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>colors.countries <span className="sc">%&gt;%</span> scales<span className="sc">::</span><span className="fu">show_col</span>(<span className="at">cex_label =</span> .<span className="dv">7</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_show_col.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_show_col"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>The countries in the dataset are already sorted by continent. Now we <strong>convert the <code>countries</code> variable into a factor to “memorize” this order</strong> (though in reverse direction). This allows countries from the same continent to be grouped together in the visualization (learn more from this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete serial guides</Link> on <em>graphic elements reordering</em>). The reversal of the factor level (by <code>rev</code>) leads to great color contrast (from dark to light colors) when transitioning from countries of one continent to another continent (see plot <a href="#color_scale"><code>p2</code></a>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">names</span>(colors.countries) <span className="ot">&lt;-</span> d.tidy<span className="sc">$</span>countries <span className="sc">%&gt;%</span> <span className="fu">unique</span>() </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a></span><br/>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>d.tidy.ordered <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">countries =</span> <span className="fu">factor</span>(</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    countries, <span className="at">levels =</span> <span className="fu">rev</span>(<span className="fu">names</span>(colors.countries))))</span></code></pre></div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>Create an area / alluvium plot using <code>geom_alluvium</code> from the <Link to="https://corybrunson.github.io/ggalluvial/"><code>ggalluvial</code></Link> package.</strong> In this work, each color ribbon represents a country, and the ribbon width represents the number of migrants from that country to U.S. The <code>geom_alluvium</code> can be viewed as a smoothed version of <code>geom_area(aes(group = countries))</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p0 <span className="ot">&lt;-</span> d.tidy.ordered <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> population, <span className="at">fill =</span> countries)) <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a></span><br/>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_alluvium</span>(<span className="fu">aes</span>(<span className="at">alluvium =</span> countries), <span className="at">alpha =</span> <span className="dv">1</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_alluvium.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_alluvium"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Here we take one step back - before the addition of alluvium ribbons, we instead first lay a bottom layer of two rectangles to mark the years of the World Wars, and then lay the alluvium ribbons on top of it.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p0 <span className="sc">+</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>           <span className="at">xmin =</span> <span className="fu">c</span>(<span className="dv">1914</span>, <span className="dv">1939</span>),</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>           <span className="at">xmax =</span> <span className="fu">c</span>(<span className="dv">1918</span>, <span className="dv">1945</span>), </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>           <span className="at">ymin =</span> <span className="dv">0</span>, <span className="at">ymax =</span> <span className="cn">Inf</span>,</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"snow2"</span>) <span className="sc">+</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="fu">geom_alluvium</span>(<span className="fu">aes</span>(<span className="at">alluvium =</span> countries), <span className="at">alpha =</span> <span className="dv">1</span>)</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_rectangle_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_rectangle_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Update the color scale using the prepared <a href="#colorvector" id="color_scale">color vector</a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> colors.countries) </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_color_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_color_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add text annotations.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>  </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># World Wars</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1914</span>, <span className="dv">1939</span>), </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">9</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, <span className="dv">4</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>), </span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"World</span><span className="sc">\n</span><span className="st">War I"</span>, <span className="st">"World</span><span className="sc">\n</span><span className="st">War II"</span>),</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  <span className="co"># continents</span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1895</span>, <span className="dv">1990</span>, <span className="dv">1995</span>), </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">6</span>) <span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"EUROPE"</span>, <span className="st">"ASIA"</span>, <span className="st">"AME-</span><span className="sc">\n</span><span className="st">RICA"</span>), </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">8</span>, <span className="dv">8</span>, <span className="dv">5</span>)) </span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a></span><br/>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_text_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_text_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Revise the axes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="co"># expand to fill up the plot range</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span> </span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1820</span>, <span className="dv">2010</span>, <span className="dv">20</span>)) <span className="sc">+</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">10</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, <span className="dv">2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>                     <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, <span className="st">"M"</span>)&#125;,</span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>                     <span className="at">position =</span> <span className="st">"right"</span>, </span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>                     <span className="at">name =</span> <span className="cn">NULL</span>) </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_axes.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_axes"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A final touch-up of the titles and theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="co"># plot titles</span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Migration to the US by world regions, 1820-2009"</span>,</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="st">"Ribbon width represents number of migrants per decade from a country / region"</span>) <span className="sc">+</span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>  </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>,<span className="at">size =</span> <span className="dv">16</span>),</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">11</span>, <span className="at">color =</span> <span className="st">"grey30"</span>),</span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>    <span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>()) </span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_plot_migration_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a><span className="fu">library</span>(ggalluvial)</span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer)</span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a><span className="fu">library</span>(scales)</span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a></span><br/>
<span id="cb12-9"><a aria-hidden="true" href="#cb12-9" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/migration_to_US.csv"</span>)</span>
<span id="cb12-10"><a aria-hidden="true" href="#cb12-10" tabindex="-1"></a></span><br/>
<span id="cb12-11"><a aria-hidden="true" href="#cb12-11" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb12-12"><a aria-hidden="true" href="#cb12-12" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>year, </span>
<span id="cb12-13"><a aria-hidden="true" href="#cb12-13" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"countries"</span>, </span>
<span id="cb12-14"><a aria-hidden="true" href="#cb12-14" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"population"</span>)</span>
<span id="cb12-15"><a aria-hidden="true" href="#cb12-15" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="dv">3</span>)</span>
<span id="cb12-16"><a aria-hidden="true" href="#cb12-16" tabindex="-1"></a></span><br/>
<span id="cb12-17"><a aria-hidden="true" href="#cb12-17" tabindex="-1"></a></span><br/>
<span id="cb12-18"><a aria-hidden="true" href="#cb12-18" tabindex="-1"></a><span className="co"># Create a vector of color names -------</span></span>
<span id="cb12-19"><a aria-hidden="true" href="#cb12-19" tabindex="-1"></a><span className="co">#  colors for 8 European countries</span></span>
<span id="cb12-20"><a aria-hidden="true" href="#cb12-20" tabindex="-1"></a>blues <span className="ot">&lt;-</span> <span className="fu">brewer.pal</span>(<span className="dv">8</span>, <span className="st">"Blues"</span>) </span>
<span id="cb12-21"><a aria-hidden="true" href="#cb12-21" tabindex="-1"></a><span className="co">#  colors for 4 Asian countries </span></span>
<span id="cb12-22"><a aria-hidden="true" href="#cb12-22" tabindex="-1"></a>yellows <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"cornsilk2"</span>, <span className="st">"yellow2"</span>, <span className="st">"gold"</span>, <span className="st">"orange"</span>) </span>
<span id="cb12-23"><a aria-hidden="true" href="#cb12-23" tabindex="-1"></a><span className="co">#  colors for 6 American countries </span></span>
<span id="cb12-24"><a aria-hidden="true" href="#cb12-24" tabindex="-1"></a>reds <span className="ot">&lt;-</span> <span className="fu">brewer.pal</span>(<span className="dv">6</span>, <span className="st">"Reds"</span>) </span>
<span id="cb12-25"><a aria-hidden="true" href="#cb12-25" tabindex="-1"></a><span className="co">#  colors for 3 other countries</span></span>
<span id="cb12-26"><a aria-hidden="true" href="#cb12-26" tabindex="-1"></a>greys <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"grey80"</span>, <span className="st">"grey50"</span>, <span className="st">"grey20"</span>)   </span>
<span id="cb12-27"><a aria-hidden="true" href="#cb12-27" tabindex="-1"></a></span><br/>
<span id="cb12-28"><a aria-hidden="true" href="#cb12-28" tabindex="-1"></a><span className="co"># combine all colors</span></span>
<span id="cb12-29"><a aria-hidden="true" href="#cb12-29" tabindex="-1"></a>colors.countries <span className="ot">&lt;-</span> <span className="fu">c</span>(blues, yellows, reds, greys)</span>
<span id="cb12-30"><a aria-hidden="true" href="#cb12-30" tabindex="-1"></a></span><br/>
<span id="cb12-31"><a aria-hidden="true" href="#cb12-31" tabindex="-1"></a><span className="co"># Display the color hex codes (optional)</span></span>
<span id="cb12-32"><a aria-hidden="true" href="#cb12-32" tabindex="-1"></a>colors.countries <span className="sc">%&gt;%</span> <span className="fu">show_col</span>(<span className="at">cex_label =</span> .<span className="dv">7</span>) </span>
<span id="cb12-33"><a aria-hidden="true" href="#cb12-33" tabindex="-1"></a></span><br/>
<span id="cb12-34"><a aria-hidden="true" href="#cb12-34" tabindex="-1"></a></span><br/>
<span id="cb12-35"><a aria-hidden="true" href="#cb12-35" tabindex="-1"></a><span className="co"># Convert the "countries" variable into a factor (in reverse order)</span></span>
<span id="cb12-36"><a aria-hidden="true" href="#cb12-36" tabindex="-1"></a><span className="fu">names</span>(colors.countries) <span className="ot">&lt;-</span> d.tidy<span className="sc">$</span>countries <span className="sc">%&gt;%</span> <span className="fu">unique</span>() </span>
<span id="cb12-37"><a aria-hidden="true" href="#cb12-37" tabindex="-1"></a></span><br/>
<span id="cb12-38"><a aria-hidden="true" href="#cb12-38" tabindex="-1"></a>d.tidy.ordered <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb12-39"><a aria-hidden="true" href="#cb12-39" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">countries =</span> <span className="fu">factor</span>(</span>
<span id="cb12-40"><a aria-hidden="true" href="#cb12-40" tabindex="-1"></a>    countries, <span className="at">levels =</span> <span className="fu">rev</span>(<span className="fu">names</span>(colors.countries))))</span>
<span id="cb12-41"><a aria-hidden="true" href="#cb12-41" tabindex="-1"></a></span><br/>
<span id="cb12-42"><a aria-hidden="true" href="#cb12-42" tabindex="-1"></a></span><br/>
<span id="cb12-43"><a aria-hidden="true" href="#cb12-43" tabindex="-1"></a></span><br/>
<span id="cb12-44"><a aria-hidden="true" href="#cb12-44" tabindex="-1"></a><span className="co"># Create an area / alluvium plot, with annotating rectangles at the bottom layer</span></span>
<span id="cb12-45"><a aria-hidden="true" href="#cb12-45" tabindex="-1"></a>p0 <span className="ot">&lt;-</span> d.tidy.ordered <span className="sc">%&gt;%</span> </span>
<span id="cb12-46"><a aria-hidden="true" href="#cb12-46" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> population, <span className="at">fill =</span> countries)) <span className="sc">+</span></span>
<span id="cb12-47"><a aria-hidden="true" href="#cb12-47" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb12-48"><a aria-hidden="true" href="#cb12-48" tabindex="-1"></a></span><br/>
<span id="cb12-49"><a aria-hidden="true" href="#cb12-49" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p0 <span className="sc">+</span></span>
<span id="cb12-50"><a aria-hidden="true" href="#cb12-50" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, </span>
<span id="cb12-51"><a aria-hidden="true" href="#cb12-51" tabindex="-1"></a>           <span className="at">xmin =</span> <span className="fu">c</span>(<span className="dv">1914</span>, <span className="dv">1939</span>),</span>
<span id="cb12-52"><a aria-hidden="true" href="#cb12-52" tabindex="-1"></a>           <span className="at">xmax =</span> <span className="fu">c</span>(<span className="dv">1918</span>, <span className="dv">1945</span>), </span>
<span id="cb12-53"><a aria-hidden="true" href="#cb12-53" tabindex="-1"></a>           <span className="at">ymin =</span> <span className="dv">0</span>, <span className="at">ymax =</span> <span className="cn">Inf</span>,</span>
<span id="cb12-54"><a aria-hidden="true" href="#cb12-54" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"snow2"</span>) <span className="sc">+</span></span>
<span id="cb12-55"><a aria-hidden="true" href="#cb12-55" tabindex="-1"></a>  <span className="fu">geom_alluvium</span>(<span className="fu">aes</span>(<span className="at">alluvium =</span> countries), <span className="at">alpha =</span> <span className="dv">1</span>)</span>
<span id="cb12-56"><a aria-hidden="true" href="#cb12-56" tabindex="-1"></a>p1</span>
<span id="cb12-57"><a aria-hidden="true" href="#cb12-57" tabindex="-1"></a></span><br/>
<span id="cb12-58"><a aria-hidden="true" href="#cb12-58" tabindex="-1"></a></span><br/>
<span id="cb12-59"><a aria-hidden="true" href="#cb12-59" tabindex="-1"></a><span className="co"># Update the color scale using the prepared color vector.</span></span>
<span id="cb12-60"><a aria-hidden="true" href="#cb12-60" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb12-61"><a aria-hidden="true" href="#cb12-61" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> colors.countries) </span>
<span id="cb12-62"><a aria-hidden="true" href="#cb12-62" tabindex="-1"></a>p2</span>
<span id="cb12-63"><a aria-hidden="true" href="#cb12-63" tabindex="-1"></a></span><br/>
<span id="cb12-64"><a aria-hidden="true" href="#cb12-64" tabindex="-1"></a></span><br/>
<span id="cb12-65"><a aria-hidden="true" href="#cb12-65" tabindex="-1"></a><span className="co"># Add text annotations. </span></span>
<span id="cb12-66"><a aria-hidden="true" href="#cb12-66" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>  </span>
<span id="cb12-67"><a aria-hidden="true" href="#cb12-67" tabindex="-1"></a>  <span className="co"># World Wars</span></span>
<span id="cb12-68"><a aria-hidden="true" href="#cb12-68" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1914</span>, <span className="dv">1939</span>), </span>
<span id="cb12-69"><a aria-hidden="true" href="#cb12-69" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">9</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, <span className="dv">4</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>), </span>
<span id="cb12-70"><a aria-hidden="true" href="#cb12-70" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"World</span><span className="sc">\n</span><span className="st">War I"</span>, <span className="st">"World</span><span className="sc">\n</span><span className="st">War II"</span>),</span>
<span id="cb12-71"><a aria-hidden="true" href="#cb12-71" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb12-72"><a aria-hidden="true" href="#cb12-72" tabindex="-1"></a>  <span className="co"># continents</span></span>
<span id="cb12-73"><a aria-hidden="true" href="#cb12-73" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb12-74"><a aria-hidden="true" href="#cb12-74" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1895</span>, <span className="dv">1990</span>, <span className="dv">1995</span>), </span>
<span id="cb12-75"><a aria-hidden="true" href="#cb12-75" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">6</span>) <span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, </span>
<span id="cb12-76"><a aria-hidden="true" href="#cb12-76" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"EUROPE"</span>, <span className="st">"ASIA"</span>, <span className="st">"AME-</span><span className="sc">\n</span><span className="st">RICA"</span>), </span>
<span id="cb12-77"><a aria-hidden="true" href="#cb12-77" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">8</span>, <span className="dv">8</span>, <span className="dv">5</span>)) </span>
<span id="cb12-78"><a aria-hidden="true" href="#cb12-78" tabindex="-1"></a></span><br/>
<span id="cb12-79"><a aria-hidden="true" href="#cb12-79" tabindex="-1"></a>p3</span>
<span id="cb12-80"><a aria-hidden="true" href="#cb12-80" tabindex="-1"></a></span><br/>
<span id="cb12-81"><a aria-hidden="true" href="#cb12-81" tabindex="-1"></a></span><br/>
<span id="cb12-82"><a aria-hidden="true" href="#cb12-82" tabindex="-1"></a><span className="co"># Revise the axes.</span></span>
<span id="cb12-83"><a aria-hidden="true" href="#cb12-83" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb12-84"><a aria-hidden="true" href="#cb12-84" tabindex="-1"></a>  <span className="co"># expand to fill up the plot range</span></span>
<span id="cb12-85"><a aria-hidden="true" href="#cb12-85" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span> </span>
<span id="cb12-86"><a aria-hidden="true" href="#cb12-86" tabindex="-1"></a>  </span>
<span id="cb12-87"><a aria-hidden="true" href="#cb12-87" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1820</span>, <span className="dv">2010</span>, <span className="dv">20</span>)) <span className="sc">+</span></span>
<span id="cb12-88"><a aria-hidden="true" href="#cb12-88" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">10</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, <span className="dv">2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb12-89"><a aria-hidden="true" href="#cb12-89" tabindex="-1"></a>                     <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>, <span className="st">"M"</span>)&#125;,</span>
<span id="cb12-90"><a aria-hidden="true" href="#cb12-90" tabindex="-1"></a>                     <span className="at">position =</span> <span className="st">"right"</span>, </span>
<span id="cb12-91"><a aria-hidden="true" href="#cb12-91" tabindex="-1"></a>                     <span className="at">name =</span> <span className="cn">NULL</span>) </span>
<span id="cb12-92"><a aria-hidden="true" href="#cb12-92" tabindex="-1"></a>p4</span>
<span id="cb12-93"><a aria-hidden="true" href="#cb12-93" tabindex="-1"></a></span><br/>
<span id="cb12-94"><a aria-hidden="true" href="#cb12-94" tabindex="-1"></a></span><br/>
<span id="cb12-95"><a aria-hidden="true" href="#cb12-95" tabindex="-1"></a><span className="co"># A final touch-up of the titles and theme. </span></span>
<span id="cb12-96"><a aria-hidden="true" href="#cb12-96" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb12-97"><a aria-hidden="true" href="#cb12-97" tabindex="-1"></a>  <span className="co"># plot titles</span></span>
<span id="cb12-98"><a aria-hidden="true" href="#cb12-98" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb12-99"><a aria-hidden="true" href="#cb12-99" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Migration to the US by world regions, 1820-2009"</span>,</span>
<span id="cb12-100"><a aria-hidden="true" href="#cb12-100" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="st">"Ribbon width represents number of migrants per decade from a country / region"</span>) <span className="sc">+</span></span>
<span id="cb12-101"><a aria-hidden="true" href="#cb12-101" tabindex="-1"></a>  </span>
<span id="cb12-102"><a aria-hidden="true" href="#cb12-102" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb12-103"><a aria-hidden="true" href="#cb12-103" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>,<span className="at">size =</span> <span className="dv">16</span>),</span>
<span id="cb12-104"><a aria-hidden="true" href="#cb12-104" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">11</span>, <span className="at">color =</span> <span className="st">"grey30"</span>),</span>
<span id="cb12-105"><a aria-hidden="true" href="#cb12-105" tabindex="-1"></a>    <span className="at">panel.grid.major.y =</span> <span className="fu">element_line</span>()) </span>
<span id="cb12-106"><a aria-hidden="true" href="#cb12-106" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Similar to <code>geom_alluvium</code> from the <code>ggalluvial</code> package, <strong><code>geom_stream</code></strong> from <strong><code>ggstream</code></strong> also generates area/ribbon plots, yet in a more variant and visually engaging style. Check this <Link to="../ggplot2-stream-blockbuster"><strong>symmetrically stacked stream plot</strong></Link> that displays the changing popularity of different movie genres from 1980 to 2020.</p>
<p><Link to="../ggplot2-stream-blockbuster">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In the above <a href="#US_migration_visualization">U.S. migration visualization</a>, a critical technique is the reordering of country names, which empowers the use of color gradient to differentiate countries and continents in the stacked area and ribbon plot. You can find many more examples like this in our <Link to="/R/gallery"><strong>gallery</strong></Link> how reordering enhances plot readability and beauty. As a representative example, in the following plot, <Link to="../ggplot2-bar-sleep"><strong>ordering of bars and refinement of axis-text position</strong></Link> effectively highlights the differences of animal’s sleep time, and its association with body weight.</p>
<p><Link to="../ggplot2-bar-sleep">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}