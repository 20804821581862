
import Post from '../Post'
import {useEffect}from "react";
import React from 'react';
import {Link} from "react-router-dom";
import starSvg from "../media/start-yellow-border.svg" 
import { Helmet } from 'react-helmet';

import RGalleryData from '../data/RGalleryData';
import RPostsData from '../data/RFAQPostsData';
import CardSection from './HomePythonSection'
import BookGgplot2 from '../media/ggplot2 book cover_compressed.png'
import PythonLogo from '../media/Python-logo-notext.svg.png'
import PysparkLogo from '../media/Apache_Spark_logo.svg.png'
import siteMetaData from '../data/siteMetaData'

export default function IndexPage() {
    // const [posts, setPosts] = useState([]);

    // useEffect(()=> {
    //     // 'https://api.rtistrystudio.com/posts'
    //     // 'http://localhost:4002/posts'
    //         fetch('http://localhost:4002/posts').then(response=> {
    //             response.json().then(Items => {
    //                 setPosts(Items.items.slice(0, 3))     
    //             }).catch(error=> {
    //                 console.error('Fetch error:', error);
    //             })
    //         }) 
    // }, []); 

    return (
        <div>
            <Helmet>
                <title>DataBrewer: Data Analysis and Visualization in Efficient Programming</title>
                <meta
                    name="description"
                    content="Learn Python, Pyspark and R to be awesome at data analysis."
                    />
            </Helmet>
      
            {/* <div className="opening">             
                <div className="opening-texts ">
                    <h3 className='anim-type'>
                        <span className='word fs-20' >Place&nbsp;</span>
                        <span className='word fs-20'>for&nbsp;</span>                         
                        <span className='word fs-30' style={{color:'#ff8a07ed'}}>Mega&nbsp;</span>
                        <span className='word fs-30' style={{color:'#ff8a07ed'}}>Learn.&nbsp;</span>  
                    </h3>
                </div>
                
            </div> */}
            <div className="mg-t-35">
                {/* two cards */}
                {/* <div id='home-Python' className='container'>
                    <h2>Featured Tutorials</h2>
                    <CardSection />

                    <div className="empty-space"></div>
                </div> */}
                <div className='blk-turquoise blk-padding-32'>
                        <div className='row blk-content '>
                            <div className='col-sm-6 center-text'>
                                <h1 className='fs-80'>Python</h1>
                                <p>A popular programming language</p>
                                <div className='mg-t-20 mg-b-10 '>
                                    <Link to='python-tutorial/getting-started' className='button-pink'>Learn Python</Link>
                                </div>
                                <div className='mg-t-20 mg-b-10'>
                                    <Link to='python-data-wrangling/introduction-of-Pandas' className='button-dark'>Data Wrangling</Link>
                                </div>
                                {/* <div className='mg-t-20 mg-b-10'>
                                    <Link to='python-data-wrangling' className='button-yellow'>Web Crawling</Link>
                                </div>
                                 */}
                                
                            </div>
                            <div className='col-sm-6 '>
                                {/* <h1>Python</h1> */}
                                <div className='text-center mg-t-50'>
                                    <img src={PythonLogo} />
                                </div>                     
                            </div>
                        </div>

                </div>
                <div className='blk-light-green  blk-padding-32'>
                        <div className='row blk-content '>
                            <div className='col-sm-6 center-text'>
                                <h1 className='fs-80'>PySpark</h1>
                                <p>A Python API for Apache Spark</p>
                                <div className='mg-t-20 mg-b-10 '>
                                    <Link to='pyspark-tutorial/getting-started' className='button-3c'>Learn PySpark</Link>
                                </div>
                                {/* <div className='mg-t-20 mg-b-10'>
                                    <Link to='python-data-wrangling/introduction-of-Pandas' className='button-dark'>Data Wrangling</Link>
                                </div> */}
                                {/* <div className='mg-t-20 mg-b-10'>
                                    <Link to='python-data-wrangling' className='button-yellow'>Web Crawling</Link>
                                </div>
                                 */}
                                
                            </div>
                            <div className='col-sm-6 '>
                                {/* <h1>Python</h1> */}
                                <div className='text-center mg-t-10'>
                                    <img src={PysparkLogo} style={{maxWidth: '70%'}}/>
                                </div>                   
                            </div>
                        </div>

                </div>
                <div className='blk-yellow blk-padding-32'>
                        <div className='row blk-content' >
                            <div className='col-sm-6 center-text'>
                                <h1 className='fs-80'>R</h1>
                                <p>A popular programming language</p>
                                <div className='mg-t-20 mg-b-10 '>
                                    <Link to='R/gallery' className='button-pink'>Gallery</Link>
                                </div>
                                <div className='mg-t-20 mg-b-10'>
                                    <Link to='R/visualization/introduction' className='button-dark'>Visualization</Link>
                                </div>
                                <div className='mg-t-20 mg-b-10'>
                                    
                                    <Link to='R/data-wrangling' className='button-3c'>Data Wrangling</Link>
                                </div>  
                            </div>
                            <div className='col-sm-6 text-center' >
                                <div className='' >               
                                        <Link to="R/eBook/flash-to-r-ggplot2-mastery" className='navHover' style={{color:'#161E2A'}}>
                                            <h5 className='fs-20' style={{marginBottom:"10px"}}>One eBook to master ggplot2 visualization</h5> 
                                        </Link>                                      
                                        <div className="book-star" style={{marginBottom: '10px'}}>
                                            <img src={starSvg}></img>
                                            <img src={starSvg}></img>
                                            <img src={starSvg}></img>
                                            <img src={starSvg}></img>
                                            <img src={starSvg}></img>
                                        </div>
                                        <div className='navHover' style={{width:'350px', margin:'auto', backgroundColor:'white', backgroundColor: 'none'}} >
                                            <Link to="R/eBook/flash-to-r-ggplot2-mastery" className='navHover'>
                                                <img src={BookGgplot2}>
                                                </img>
                                            </Link>

                                        </div>
                                        
                                        <div className="more" >
                                            <Link to="R/eBook/flash-to-r-ggplot2-mastery" className="button-3c" style={{paddingTop:'0.25rem', paddingBottom:'0.25rem'}}>Learn More</Link>
                                        </div>
                                </div>
                              
                            </div>
                        </div>

                </div>
                {/* <div id="home-R" className='container mg-t-50'>                
                    <div className='block-section'>
                            <h1 className="mg-l-20">R ggplot2 Gallery</h1>
                            <h3 className="mg-l-20">Explore graphs created by using R ggplot2</h3>
                    </div>          
                    <div className="gallery-grid">
                    { RGalleryData.slice(0, 6).map((post, index) => (
                            <div className="graph" key={index}>
                                <Post key={index} title={post.title} path={`R/Gallery/${post.path}`} summary={post.summary} cover={post.cover} />
                            </div>
                        ))
                    }
                    </div>
                    
                    <div className="more">
                        <Link to="R/Gallery" className="button">More Plots</Link>
                    </div>
                    <div className="empty-space"></div>
                </div> */}
    
                {/* <div className='block-section'>
                        <h2>R basics</h2>
                        <h3>Start with R</h3>
                </div> */}

                {/* <div className='container'>
                    <div className="book-star">
                        <img src={starSvg}></img>
                        <img src={starSvg}></img>
                        <img src={starSvg}></img>
                        <img src={starSvg}></img>
                        <img src={starSvg}></img>
                    </div>
                    <div className="ebook">
                        <img src="https://s3.amazonaws.com/articles.rtistrystudio/cover_images/ggplot2_book_cover_portrait.png"></img>
                    </div>
                    <div className="mg-b-50 text-center">
                        <h3>One book to master ggplot2 visualization</h3>
                        <h4>Best ggplot2 Viusalization eBook</h4>
                    </div> 
                    <div className="more">
                        <Link to="eBook/sliding-you-way-to-ggplot2-mastery" className="button">Learn More</Link>
                    </div>
                </div> */}
                
            <div className="empty-space"></div>
                
            </div>


        </div>
      
    )
}