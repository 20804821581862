import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrSplit(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Split Strings into Multiple Pieces</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_split()</code></strong> splits each string element into multiple pieces based on the specified separator, and returns a list.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>fruits <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"mango, peach, kiwi, and lemon"</span>, </span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>            <span className="st">"apple, banana, and orange"</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># split strings at the site of commas</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">str_split</span>(fruits, <span className="at">pattern =</span> <span className="st">","</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[[1]]
<br/>[1] "mango"      " peach"     " kiwi"      " and lemon"

<br/>[[2]]
<br/>[1] "apple"       " banana"     " and orange"</code></pre>
</div>
</div>
<p>The argument <code>n</code> specifies the maximum number of pieces each string element should be split into. In the example below, we limit the maximum number of pieces each string element is split into to be 3.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_split</span>(fruits, <span className="at">pattern =</span> <span className="st">","</span>, <span className="at">n =</span> <span className="dv">3</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[[1]]
<br/>[1] "mango"            " peach"           " kiwi, and lemon"

<br/>[[2]]
<br/>[1] "apple"       " banana"     " and orange"</code></pre>
</div>
</div>
<p>Use <code>simplify = T</code> to return the split strings as a matrix.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_split</span>(fruits, <span className="at">pattern =</span> <span className="st">","</span>, <span className="at">n =</span> <span className="dv">3</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]    [,2]      [,3]              
<br/>[1,] "mango" " peach"  " kiwi, and lemon"
<br/>[2,] "apple" " banana" " and orange"     </code></pre>
</div>
</div>
<p>When returned as a matrix, if the specified <code>n</code> value (e.g., <code>n = 5</code>) exceeds the maximal number of possible splits, empty columns will be created to make a total of <code>n</code> columns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_split</span>(fruits, <span className="at">pattern =</span> <span className="st">","</span>, <span className="at">n =</span> <span className="dv">5</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]    [,2]      [,3]          [,4]         [,5]
<br/>[1,] "mango" " peach"  " kiwi"       " and lemon" ""  
<br/>[2,] "apple" " banana" " and orange" ""           ""  </code></pre>
</div>
</div>
<h4 className="anchored">
🏆 <strong>One level up !</strong>
</h4>
<p><code>str_split()</code> has three variant functions, which differ primarily in their input and output types:</p>
<ul>
<li><strong><code>str_split1()</code></strong> takes a vector of length 1 as the string input, and returns the split strings as a vector.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_split_1</span>(fruits[<span className="dv">1</span>], <span className="at">pattern =</span> <span className="st">","</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango"      " peach"     " kiwi"      " and lemon"</code></pre>
</div>
</div>
<ul>
<li><strong><code>str_split_fixed()</code></strong> returns a character matrix with fixed <code>n</code> columns. In many ways it is equivalent to <code>str_split(..., simplify = T)</code>.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"a piece of sizzling bacon"</span>,</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>       <span className="st">"two bread loafs"</span>)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a></span><br/>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="fu">str_split_fixed</span>(x, <span className="at">pattern =</span> <span className="st">" "</span>, <span className="at">n =</span> <span className="dv">5</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]  [,2]    [,3]    [,4]       [,5]   
<br/>[1,] "a"   "piece" "of"    "sizzling" "bacon"
<br/>[2,] "two" "bread" "loafs" ""         ""     </code></pre>
</div>
</div>
<ul>
<li><strong><code>str_split_i()</code></strong> extracts the i<sup>th</sup> piece from each of the input element, and returns a vector.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="co"># extract the 2nd word from each string element</span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">str_split_i</span>(x, <span className="at">pattern =</span> <span className="st">" "</span>, <span className="at">i =</span> <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "piece" "bread"</code></pre>
</div>
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="co"># extract the last word from each string element</span></span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a><span className="fu">str_split_i</span>(x, <span className="at">pattern =</span> <span className="st">" "</span>, <span className="at">i =</span> <span className="sc">-</span><span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "bacon" "loafs"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}