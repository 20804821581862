import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2HeatmapVaccineForPolio from '../graphics/heatmap_vaccine_polio_completed.png'; 
import imgGgplot2HeatmapVaccineForPolioWebp from '../graphics/heatmap_vaccine_polio_completed.webp'; 
export default function Ggplot2HeatmapVaccineForPolio(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Heatmap in ggplot2 to Visualize the Control of Polio Disease by Vaccination in the U.S.</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2HeatmapVaccineForPolioWebp} />
    <img className="cover-img" src={imgGgplot2HeatmapVaccineForPolio} />
  </picture>

<p>In this article, we’ll create a heatmap to visualize the annual incidences of the polio disease in the U.S. history, and highlight the power of vaccination in checking polio’s occurrences. This work is a ggplot2 reproduction of the original work created by Tynan and Dov in <Link to="https://graphics.wsj.com/infectious-diseases-and-vaccines/">Wall Street Journal</Link>.</p>
<p><strong>Major techniques covered in this article include:</strong></p>
<ul>
<li><a href="#create_heatmap">Create and customize a heatmap.</a></li>
<li><a href="#color_scale">Generate a beautiful color scale for data with high skewness.</a></li>
<li><a href="#colorbar">Customize the colorbar.</a></li>
<li><a href="#text_line_annotation">Make text and line annotations.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>To access the <strong>authentic dataset</strong> (as used in this demonstration), go to <Link to="https://www.tycho.pitt.edu/"><strong>Project TYCHO</strong></Link>, scroll down to “ACCESS DATA” - “DATASETS Pre-Compiled”, and click “START NOW”. Then scroll <em>up</em> to “PREVIOUS VERSIONS” - “Level 1 Data peer-reviewed research 8 diseases”, and click download ( create an account to initiate download).</p>
<p>For practice purpose, you can <em>download this</em> <strong>simulated data <a className="downloadData" href="dataset/r-gallery/US_disease_simulated.csv">here</a></strong> <em>with a single click</em>. This simulated dataset has the same data structure as the original one.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer) </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>data <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/US_disease.csv"</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>data <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="fu">head</span>(data, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 7
<br/>  epi_week state loc       loc_type disease     cases incidence_per_100000
<br/>     &lt;int&gt; &lt;chr&gt; &lt;chr&gt;     &lt;chr&gt;    &lt;chr&gt;       &lt;chr&gt;                &lt;dbl&gt;
<br/>1   196601 MN    MINNESOTA STATE    HEPATITIS A 3                     0.08
<br/>2   196601 CO    COLORADO  STATE    HEPATITIS A 1                     0.05
<br/>3   196601 AZ    ARIZONA   STATE    HEPATITIS A 6                     0.37</code></pre>
</div>
</div>
<p>Create a dataset summarizing the annual incidences per 100K population in each state and for each disease.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>data.summary <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="co"># extract years </span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">str_sub</span>(epi_week, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="dv">4</span>) <span className="sc">%&gt;%</span> <span className="fu">as.integer</span>()) <span className="sc">%&gt;%</span>  </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">select</span>(<span className="sc">-</span>epi_week) <span className="sc">%&gt;%</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="co"># sum up cases across all weeks for each disease in each year and state </span></span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="co"># incidences presented as per 100 K population</span></span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  <span className="fu">group_by</span>(state, year, disease) <span className="sc">%&gt;%</span> </span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">n.per.100K =</span> <span className="fu">sum</span>(incidence_per_100000)) </span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a></span><br/>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a><span className="fu">head</span>(data.summary, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/># Groups:   state, year [2]
<br/>  state  year disease   n.per.100K
<br/>  &lt;chr&gt; &lt;int&gt; &lt;chr&gt;          &lt;dbl&gt;
<br/>1 AK     1950 PERTUSSIS       63.7
<br/>2 AK     1950 POLIO           45.2
<br/>3 AK     1951 PERTUSSIS       49.4</code></pre>
</div>
</div>
<p>In this article, we’ll create a heatmap for the polio disease only. In the <Link to="../ggplot2-heatmap-vaccine-for-8-diseases">next article</Link>, we’ll update the code into a function to automate the creation of heatmaps for all eight different diseases.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>polio <span className="ot">&lt;-</span> data.summary <span className="sc">%&gt;%</span> <span className="fu">filter</span>(disease <span className="sc">==</span> <span className="st">"POLIO"</span>)</span></code></pre></div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="create_heatmap">Create a basic heatmap using the generic <code>geom_tile()</code> function. Compared with this function, <code>geom_raster()</code> is a high-performance alternative when all the cells have the same size, but does <em>not</em> draw cell outlines (as used in <Link to="../ggplot2-heatmap-African-population">this example</Link>).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> polio <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> state, <span className="at">fill =</span> n.per<span className="fl">.100</span>K)) <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="co"># create heatmap</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">geom_tile</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a></span><br/>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_polio_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="color_scale">Design a color scale to highlight the dynamic changes in the polio occurrences: greens and blues are used only when the incidences are extremely low, and yellows and reds are applied for the majority range of the color scale. The colors are selected with trial and error to bring out the most vivid color contrast. (It helps to use <code>scales::show_col(myColors)</code> to visualize the color hex codes.)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">c</span>(<span className="st">"grey95"</span>, <span className="st">"#d1e6f7"</span>, <span className="st">"#a6d3f1"</span>, <span className="st">"#57b7e7"</span>,<span className="st">"#1f9ad4"</span>, <span className="st">"#3aab70"</span>, <span className="st">"#e0d141"</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="st">"#fdd53e"</span>, <span className="st">"#f4cd3c"</span>, <span className="st">"#feb73e"</span>, <span className="st">"orange"</span>, <span className="st">"#eb6c10"</span>,  <span className="st">"firebrick2"</span>, <span className="st">"firebrick4"</span>),</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="at">bias =</span> <span className="dv">3</span>)(<span className="dv">50</span>) </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a></span><br/>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(<span className="at">colours =</span> myColors, </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>                       <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">0</span>, <span className="at">to =</span> <span className="dv">150</span>, <span className="at">by =</span> <span className="dv">50</span>))</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a></span><br/>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_color_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_polio_color_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Label the x-axis with 5-year time interval, remove the axis titles, and add plot title and subtitle.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="co"># x axis:  year label ending with 00 or 05</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">1930</span>, <span className="at">to =</span> <span className="dv">1965</span>, <span className="at">by =</span> <span className="dv">5</span>)) <span className="sc">+</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  </span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="co"># axes and plot titles</span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>, </span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>       <span className="at">fill =</span> <span className="st">"yearly cases </span><span className="sc">\n</span><span className="st">per 100K"</span>,</span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"POLIO"</span>,</span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"yearly cases per 100K population"</span> <span className="sc">%&gt;%</span> <span className="fu">paste</span>( <span className="st">"</span><span className="sc">\n</span><span className="st">"</span>)) </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a></span><br/>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_labels_titles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_polio_labels_titles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Polish up the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># color bar customization</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>        <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>),</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">8</span>),</span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>        <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">20</span>),</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>        <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">color =</span> <span className="st">"grey60"</span>),</span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"bottom"</span>,</span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">unit</span>(<span className="fu">c</span>(.<span className="dv">5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>), <span className="st">"cm"</span>))</span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_polio_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="colorbar">Adjust the horizontal colorbar to be wider and thinner.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(<span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">250</span>, <span className="st">"pt"</span>),</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>                               <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>                               <span className="at">title =</span> <span className="cn">NULL</span>)) </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_colorbar.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_polio_colorbar"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="text_line_annotation">Annotate the heatmap with the year of vaccine introduction. Note that <code>clip = "off"</code> (the last line) is critical to completely display the texts, which would otherwise be clipped away and invisible after shifting upward (by <code>vjust</code>) beyond the panel boundary.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="co"># annotate with vertical line: the year of vaccine introduction   </span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="fl">1954.5</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>  </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>  <span className="co"># annotate with text: the year of vaccine introduction   </span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">1954.5</span>, <span className="at">y =</span> <span className="dv">51</span>, </span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Vaccine introduced"</span>, </span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>           <span className="at">vjust =</span> <span className="sc">-</span><span className="fl">1.2</span>, <span className="co"># shift the label upward</span></span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>, <span className="co"># left justify</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>  </span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a>  <span className="co"># expand to fill up entire plot area, </span></span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a>  <span className="co"># and clip off to display the text annotation</span></span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> <span className="st">"off"</span>)  </span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_polio_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"heatmap_vaccine_polio.pdf"</span>,</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">7</span>, <span className="at">height =</span> <span className="dv">7</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer) </span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a></span><br/>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>data <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/US_disease.csv"</span>)</span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>data <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a></span><br/>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>data.summary <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span></span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>  <span className="co"># extract years </span></span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">str_sub</span>(epi_week, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="dv">4</span>) <span className="sc">%&gt;%</span> <span className="fu">as.integer</span>()) <span className="sc">%&gt;%</span>  </span>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a>  <span className="fu">select</span>(<span className="sc">-</span>epi_week) <span className="sc">%&gt;%</span> </span>
<span id="cb13-13"><a aria-hidden="true" href="#cb13-13" tabindex="-1"></a>  </span>
<span id="cb13-14"><a aria-hidden="true" href="#cb13-14" tabindex="-1"></a>  <span className="co"># sum up cases across all weeks for each disease in each year and each state </span></span>
<span id="cb13-15"><a aria-hidden="true" href="#cb13-15" tabindex="-1"></a>  <span className="co"># incidences presented as per 100 K population</span></span>
<span id="cb13-16"><a aria-hidden="true" href="#cb13-16" tabindex="-1"></a>  <span className="fu">group_by</span>(state, year, disease) <span className="sc">%&gt;%</span> </span>
<span id="cb13-17"><a aria-hidden="true" href="#cb13-17" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">n.per.100K =</span> <span className="fu">sum</span>(incidence_per_100000)) </span>
<span id="cb13-18"><a aria-hidden="true" href="#cb13-18" tabindex="-1"></a></span><br/>
<span id="cb13-19"><a aria-hidden="true" href="#cb13-19" tabindex="-1"></a>polio <span className="ot">&lt;-</span> data.summary <span className="sc">%&gt;%</span> <span className="fu">filter</span>(disease <span className="sc">==</span> <span className="st">"POLIO"</span>)</span>
<span id="cb13-20"><a aria-hidden="true" href="#cb13-20" tabindex="-1"></a></span><br/>
<span id="cb13-21"><a aria-hidden="true" href="#cb13-21" tabindex="-1"></a><span className="co"># color scale</span></span>
<span id="cb13-22"><a aria-hidden="true" href="#cb13-22" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(</span>
<span id="cb13-23"><a aria-hidden="true" href="#cb13-23" tabindex="-1"></a>  <span className="fu">c</span>(<span className="st">"grey95"</span>, <span className="st">"#d1e6f7"</span>, <span className="st">"#a6d3f1"</span>, <span className="st">"#57b7e7"</span>,<span className="st">"#1f9ad4"</span>, <span className="st">"#3aab70"</span>, <span className="st">"#e0d141"</span>, </span>
<span id="cb13-24"><a aria-hidden="true" href="#cb13-24" tabindex="-1"></a>    <span className="st">"#fdd53e"</span>, <span className="st">"#f4cd3c"</span>, <span className="st">"#feb73e"</span>, <span className="st">"orange"</span>, <span className="st">"#eb6c10"</span>,  <span className="st">"firebrick2"</span>, <span className="st">"firebrick4"</span>),</span>
<span id="cb13-25"><a aria-hidden="true" href="#cb13-25" tabindex="-1"></a>  <span className="at">bias =</span> <span className="dv">3</span>)(<span className="dv">50</span>) </span>
<span id="cb13-26"><a aria-hidden="true" href="#cb13-26" tabindex="-1"></a></span><br/>
<span id="cb13-27"><a aria-hidden="true" href="#cb13-27" tabindex="-1"></a>plt <span className="ot">&lt;-</span> polio <span className="sc">%&gt;%</span> </span>
<span id="cb13-28"><a aria-hidden="true" href="#cb13-28" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> state, <span className="at">fill =</span> n.per<span className="fl">.100</span>K)) <span className="sc">+</span></span>
<span id="cb13-29"><a aria-hidden="true" href="#cb13-29" tabindex="-1"></a>  <span className="co"># create heatmap</span></span>
<span id="cb13-30"><a aria-hidden="true" href="#cb13-30" tabindex="-1"></a>  <span className="fu">geom_tile</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) <span className="sc">+</span></span>
<span id="cb13-31"><a aria-hidden="true" href="#cb13-31" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(<span className="at">colours =</span> myColors, </span>
<span id="cb13-32"><a aria-hidden="true" href="#cb13-32" tabindex="-1"></a>                       <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">0</span>, <span className="at">to =</span> <span className="dv">150</span>, <span className="at">by =</span> <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb13-33"><a aria-hidden="true" href="#cb13-33" tabindex="-1"></a>  </span>
<span id="cb13-34"><a aria-hidden="true" href="#cb13-34" tabindex="-1"></a>  <span className="co"># x axis:  year label ending with 00 or 05</span></span>
<span id="cb13-35"><a aria-hidden="true" href="#cb13-35" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(</span>
<span id="cb13-36"><a aria-hidden="true" href="#cb13-36" tabindex="-1"></a>    <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="at">from =</span> <span className="dv">1930</span>, <span className="at">to =</span> <span className="dv">1965</span>, <span className="at">by =</span> <span className="dv">5</span>)) <span className="sc">+</span></span>
<span id="cb13-37"><a aria-hidden="true" href="#cb13-37" tabindex="-1"></a>  <span className="co"># titles</span></span>
<span id="cb13-38"><a aria-hidden="true" href="#cb13-38" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>, </span>
<span id="cb13-39"><a aria-hidden="true" href="#cb13-39" tabindex="-1"></a>       <span className="at">fill =</span> <span className="st">"yearly cases </span><span className="sc">\n</span><span className="st">per 100K"</span>,</span>
<span id="cb13-40"><a aria-hidden="true" href="#cb13-40" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"POLIO"</span>,</span>
<span id="cb13-41"><a aria-hidden="true" href="#cb13-41" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"yearly cases per 100K population"</span> <span className="sc">%&gt;%</span> <span className="fu">paste</span>( <span className="st">"</span><span className="sc">\n</span><span className="st">"</span>)) <span className="sc">+</span></span>
<span id="cb13-42"><a aria-hidden="true" href="#cb13-42" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb13-43"><a aria-hidden="true" href="#cb13-43" tabindex="-1"></a>  <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb13-44"><a aria-hidden="true" href="#cb13-44" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-45"><a aria-hidden="true" href="#cb13-45" tabindex="-1"></a>        <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>),</span>
<span id="cb13-46"><a aria-hidden="true" href="#cb13-46" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">8</span>),</span>
<span id="cb13-47"><a aria-hidden="true" href="#cb13-47" tabindex="-1"></a>        <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">16</span>),</span>
<span id="cb13-48"><a aria-hidden="true" href="#cb13-48" tabindex="-1"></a>        <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">color =</span> <span className="st">"grey60"</span>),</span>
<span id="cb13-49"><a aria-hidden="true" href="#cb13-49" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"bottom"</span>,</span>
<span id="cb13-50"><a aria-hidden="true" href="#cb13-50" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">unit</span>(<span className="fu">c</span>(.<span className="dv">5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>), <span className="st">"cm"</span>)) <span className="sc">+</span></span>
<span id="cb13-51"><a aria-hidden="true" href="#cb13-51" tabindex="-1"></a>  <span className="co"># color bar </span></span>
<span id="cb13-52"><a aria-hidden="true" href="#cb13-52" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(<span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">250</span>, <span className="st">"pt"</span>),</span>
<span id="cb13-53"><a aria-hidden="true" href="#cb13-53" tabindex="-1"></a>                               <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb13-54"><a aria-hidden="true" href="#cb13-54" tabindex="-1"></a>                               <span className="at">title =</span> <span className="cn">NULL</span>)) <span className="sc">+</span></span>
<span id="cb13-55"><a aria-hidden="true" href="#cb13-55" tabindex="-1"></a>  </span>
<span id="cb13-56"><a aria-hidden="true" href="#cb13-56" tabindex="-1"></a>  <span className="co"># annotate with vertical line: the year of vaccine introduction   </span></span>
<span id="cb13-57"><a aria-hidden="true" href="#cb13-57" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="fl">1954.5</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb13-58"><a aria-hidden="true" href="#cb13-58" tabindex="-1"></a>  </span>
<span id="cb13-59"><a aria-hidden="true" href="#cb13-59" tabindex="-1"></a>  <span className="co"># annotate with text: the year of vaccine introduction   </span></span>
<span id="cb13-60"><a aria-hidden="true" href="#cb13-60" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb13-61"><a aria-hidden="true" href="#cb13-61" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">1954.5</span>, <span className="at">y =</span> <span className="dv">51</span>, </span>
<span id="cb13-62"><a aria-hidden="true" href="#cb13-62" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Vaccine introduced"</span>, </span>
<span id="cb13-63"><a aria-hidden="true" href="#cb13-63" tabindex="-1"></a>           <span className="at">vjust =</span> <span className="sc">-</span><span className="fl">1.2</span>, <span className="co"># shift the label upward</span></span>
<span id="cb13-64"><a aria-hidden="true" href="#cb13-64" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>, <span className="co"># left justify</span></span>
<span id="cb13-65"><a aria-hidden="true" href="#cb13-65" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb13-66"><a aria-hidden="true" href="#cb13-66" tabindex="-1"></a>  </span>
<span id="cb13-67"><a aria-hidden="true" href="#cb13-67" tabindex="-1"></a>  <span className="co"># expand to fill up entire plot area, </span></span>
<span id="cb13-68"><a aria-hidden="true" href="#cb13-68" tabindex="-1"></a>  <span className="co"># and clip off to display the text annotation</span></span>
<span id="cb13-69"><a aria-hidden="true" href="#cb13-69" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> <span className="st">"off"</span>)  </span>
<span id="cb13-70"><a aria-hidden="true" href="#cb13-70" tabindex="-1"></a></span><br/>
<span id="cb13-71"><a aria-hidden="true" href="#cb13-71" tabindex="-1"></a>plt</span>
<span id="cb13-72"><a aria-hidden="true" href="#cb13-72" tabindex="-1"></a></span><br/>
<span id="cb13-73"><a aria-hidden="true" href="#cb13-73" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"heatmap_vaccine_polio.pdf"</span>,</span>
<span id="cb13-74"><a aria-hidden="true" href="#cb13-74" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb13-75"><a aria-hidden="true" href="#cb13-75" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">7</span>, <span className="at">height =</span> <span className="dv">7</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Now that we have a pipeline to create the heatmap for the polio disease as demonstrated above, we’ll next wrap the code into a function to <Link to="../ggplot2-heatmap-vaccine-for-8-diseases"><strong>automate the heatmap creation for eight infectious diseases</strong></Link> throughout the US history, and highlight how vaccination played a vital role in the disease control.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>To generate heatmaps, in addition to the generic <code>geom_tile()</code> and <code>geom_raster()</code>, <code>geom_bin2d()</code> is another powerful function – Check out the following awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}