import React from 'react';
import { Link } from 'react-router-dom';
import image from '../media/404 error page.png'

function NotFoundPage() {
  return (
    <div className='main'>
      <div className='row'>
        <div className="p-l-md-50" > 
          <div className='ads-top'>
                {/* Save this space for Ads */}                          
          </div>
          <div className="main-container mg-t-150 ">
            <div className="content-not-found">
              <div className='mg-t-30'>
                <h1 className='fs-50 fw-900'>Oops!</h1>
                <p className='mg-t-10 mg-b-0 fw-800'>Where are we?</p>
                <p className='mg-t-0 fw-800'>Page not found. We'll fix it soon!</p>
              </div>

              <div className='' style={{ marginTop: '35px' }}> {/* Wrapper div for centering */}
                <Link to="/" style={{ padding: '10px 20px', backgroundColor: '#25b0f0', color: '#ffffff', textDecoration: 'none', borderRadius: '5px' }}>
                  Go Home
                </Link>
              </div>

            </div>
          
            <div className='background-bear'>
              <img src={image} alt="Background" />
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
