import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PrintTibbles(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Print Tibbles</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>An improved printing method is one of the most important improvements in tibbles compared with the traditional <code>data.frame</code>, very informative to help downstream analysis. When you print a tibble, it displays the first ten rows and all columns that fit in the console width, along with names of the additional columns. It prints the dataset dimension, and an abbreviated description of the column type (e.g., <code>chr</code> for character, <code>dbl</code> for double or numeric, <code>int</code> for integer, etc.).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># print the billboard dataset (a tibble) built in tidyr package</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>tidyr<span className="sc">::</span>billboard</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 317 × 79
<br/>   artist   track date.entered   wk1   wk2   wk3   wk4   wk5   wk6   wk7   wk8   wk9  wk10  wk11  wk12  wk13  wk14  wk15  wk16  wk17  wk18  wk19  wk20  wk21  wk22  wk23  wk24  wk25  wk26  wk27  wk28  wk29  wk30  wk31  wk32  wk33  wk34  wk35  wk36  wk37  wk38  wk39  wk40  wk41  wk42  wk43  wk44  wk45
<br/>   &lt;chr&gt;    &lt;chr&gt; &lt;date&gt;       &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/> 1 2 Pac    Baby… 2000-02-26      87    82    72    77    87    94    99    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 2 2Ge+her  The … 2000-09-02      91    87    92    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 3 3 Doors… Kryp… 2000-04-08      81    70    68    67    66    57    54    53    51    51    51    51    47    44    38    28    22    18    18    14    12     7     6     6     6     5     5     4     4     4     4     3     3     3     4     5     5     9     9    15    14    13    14    16    17
<br/> 4 3 Doors… Loser 2000-10-21      76    76    72    69    67    65    55    59    62    61    61    59    61    66    72    76    75    67    73    70    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 5 504 Boyz Wobb… 2000-04-15      57    34    25    17    17    31    36    49    53    57    64    70    75    76    78    85    92    96    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 6 98^0     Give… 2000-08-19      51    39    34    26    26    19     2     2     3     6     7    22    29    36    47    67    66    84    93    94    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 7 A*Teens  Danc… 2000-07-08      97    97    96    95   100    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 8 Aaliyah  I Do… 2000-01-29      84    62    51    41    38    35    35    38    38    36    37    37    38    49    61    63    62    67    83    86    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 9 Aaliyah  Try … 2000-03-18      59    53    38    28    21    18    16    14    12    10     9     8     6     1     2     2     2     2     3     4     5     5     6     9    13    14    16    23    22    33    36    43    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/>10 Adams, … Open… 2000-08-26      76    76    74    69    68    67    61    58    57    59    66    68    61    67    59    63    67    71    79    89    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/># ℹ 307 more rows
<br/># ℹ 31 more variables: wk46 &lt;dbl&gt;, wk47 &lt;dbl&gt;, wk48 &lt;dbl&gt;, wk49 &lt;dbl&gt;, wk50 &lt;dbl&gt;, wk51 &lt;dbl&gt;, wk52 &lt;dbl&gt;, wk53 &lt;dbl&gt;, wk54 &lt;dbl&gt;, wk55 &lt;dbl&gt;, wk56 &lt;dbl&gt;, wk57 &lt;dbl&gt;, wk58 &lt;dbl&gt;, wk59 &lt;dbl&gt;, wk60 &lt;dbl&gt;, wk61 &lt;dbl&gt;, wk62 &lt;dbl&gt;, wk63 &lt;dbl&gt;, wk64 &lt;dbl&gt;, wk65 &lt;dbl&gt;, wk66 &lt;lgl&gt;, wk67 &lt;lgl&gt;, wk68 &lt;lgl&gt;,
<br/>#   wk69 &lt;lgl&gt;, wk70 &lt;lgl&gt;, wk71 &lt;lgl&gt;, wk72 &lt;lgl&gt;, wk73 &lt;lgl&gt;, wk74 &lt;lgl&gt;, wk75 &lt;lgl&gt;, wk76 &lt;lgl&gt;</code></pre>
</div>
</div>
<p><strong>You can control the default appearance of the printed tibbles:</strong></p>
<ul>
<li><p><code>options(pillar.print_max = n, pillar.print_min = m)</code>: if there are more than n rows, print only the first m rows. Use <code>options(pillar.print_max = Inf)</code> to always show all rows.</p></li>
<li><p><code>options(pillar.width = n)</code>: use n character slots horizontally to show the data. If n &gt; <code>getOption("width")</code>, this will result in multiple tiers. Use <code>options(pillar.width = Inf)</code> to always print all columns, regardless of the width of the screen.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># reset default to print only the first two rows</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">options</span>(<span className="at">pillar.print_min =</span> <span className="dv">2</span>)  </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="co"># reset default to print 60 characters at max per line</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a><span className="fu">options</span>(<span className="at">pillar.width =</span> <span className="dv">60</span>)     </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a></span><br/>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>tidyr<span className="sc">::</span>billboard</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 317 × 79
<br/>  artist  track   date.entered   wk1   wk2   wk3   wk4   wk5
<br/>  &lt;chr&gt;   &lt;chr&gt;   &lt;date&gt;       &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/>1 2 Pac   Baby D… 2000-02-26      87    82    72    77    87
<br/>2 2Ge+her The Ha… 2000-09-02      91    87    92    NA    NA
<br/># ℹ 315 more rows
<br/># ℹ 71 more variables: wk6 &lt;dbl&gt;, wk7 &lt;dbl&gt;, wk8 &lt;dbl&gt;,
<br/>#   wk9 &lt;dbl&gt;, wk10 &lt;dbl&gt;, wk11 &lt;dbl&gt;, wk12 &lt;dbl&gt;,
<br/>#   wk13 &lt;dbl&gt;, wk14 &lt;dbl&gt;, wk15 &lt;dbl&gt;, wk16 &lt;dbl&gt;,
<br/>#   wk17 &lt;dbl&gt;, wk18 &lt;dbl&gt;, wk19 &lt;dbl&gt;, wk20 &lt;dbl&gt;,
<br/>#   wk21 &lt;dbl&gt;, wk22 &lt;dbl&gt;, wk23 &lt;dbl&gt;, wk24 &lt;dbl&gt;,
<br/>#   wk25 &lt;dbl&gt;, wk26 &lt;dbl&gt;, wk27 &lt;dbl&gt;, wk28 &lt;dbl&gt;, …</code></pre>
</div>
</div>
</main>
</div>
</div>
)}