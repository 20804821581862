import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Introduction(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">A Comprehensive Guide to <strong>Regular Expressions</strong></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Regular expressions (RegEx) are a sequence of characters that define a search pattern. They are widely used in programming for pattern matching and text manipulation. Below is a summary of commonly used RegEx.</p>
<div className="cell" data-layout-align="center">
<div className="cell-output-display">
<table className="table table-sm table-striped small">
<thead>
<tr className="header">
<th>Pattern</th>
<th>Description</th>
</tr>
</thead>
<tbody>
<tr className="odd">
<td><code>.</code></td>
<td>Match any character; a wildcard</td>
</tr>
<tr className="even">
<td><code>[abc]</code></td>
<td>Match any character a, b, or c</td>
</tr>
<tr className="odd">
<td><code>[^abc]</code></td>
<td>Match any character except a, b, or c</td>
</tr>
<tr className="even">
<td><code>[a-z]</code></td>
<td>Match any lowercase letter</td>
</tr>
<tr className="odd">
<td><code>[:digit:]</code></td>
<td>Match any digit (0-9)</td>
</tr>
<tr className="even">
<td><code>[:alpha:]</code></td>
<td>Match any alphabetic character (uppercase or lowercase)</td>
</tr>
<tr className="odd">
<td><code>[:lower:]</code></td>
<td>Match any lowercase alphabetic letter</td>
</tr>
<tr className="even">
<td><code>[:upper:]</code></td>
<td>Match any uppercase alphabetic letter</td>
</tr>
<tr className="odd">
<td><code>[:alnum:]</code></td>
<td>Match any alphanumeric character (letter or digit)</td>
</tr>
<tr className="even">
<td><code>[:space:]</code></td>
<td>Match a single whitespace character</td>
</tr>
<tr className="odd">
<td><code>[:punct:]</code></td>
<td>Match any punctuation character</td>
</tr>
<tr className="even">
<td><code>[:print:]</code></td>
<td>Match any printable character (alphanumeric and punctuation)</td>
</tr>
<tr className="odd">
<td><code>\d</code></td>
<td>Match any digit</td>
</tr>
<tr className="even">
<td><code>\D</code></td>
<td>Match any non-digit</td>
</tr>
<tr className="odd">
<td><code>\w</code></td>
<td>Match any word character</td>
</tr>
<tr className="even">
<td><code>\W</code></td>
<td>Match any non-word character</td>
</tr>
<tr className="odd">
<td><code>\s</code></td>
<td>Match any whitespace character</td>
</tr>
<tr className="even">
<td><code>\S</code></td>
<td>Match any non-whitespace character</td>
</tr>
<tr className="odd">
<td><code>&#123;n&#125;</code></td>
<td>Match exactly n occurrences</td>
</tr>
<tr className="even">
<td><code>*</code></td>
<td>Match zero or more occurrences</td>
</tr>
<tr className="odd">
<td><code>+</code></td>
<td>Match one or more occurrences</td>
</tr>
<tr className="even">
<td><code>?</code></td>
<td>Match zero or one occurrence</td>
</tr>
<tr className="odd">
<td><code>^</code></td>
<td>Anchor for the start of a line</td>
</tr>
<tr className="even">
<td><code>$</code></td>
<td>Anchor for the end of a line</td>
</tr>
<tr className="odd">
<td><code>(...)</code></td>
<td>Match a group of characters</td>
</tr>
<tr className="even">
<td><code>\b</code></td>
<td>Match a word boundary</td>
</tr>
<tr className="odd">
<td><code>A(?=B)</code></td>
<td>Positive lookahead assertion</td>
</tr>
<tr className="even">
<td><code>(?&lt;=B)A</code></td>
<td>Positive lookbehind assertion</td>
</tr>
<tr className="odd">
<td><code>A(?!B)</code></td>
<td>Negative lookahead assertion</td>
</tr>
<tr className="even">
<td><code>(?&lt;!B)A</code></td>
<td>Negative lookbehind assertion</td>
</tr>
<tr className="odd">
<td><code>^\bapple\b</code></td>
<td>Match ‘apple’ at the beginning of a line</td>
</tr>
<tr className="even">
<td><code>\bapple\b$</code></td>
<td>Match ‘apple’ at the end of a line</td>
</tr>
<tr className="odd">
<td><code>\bapple\b</code></td>
<td>Match the whole word ‘apple’</td>
</tr>
<tr className="even">
<td><code>\b\d\b</code></td>
<td>Match a standalone digit</td>
</tr>
<tr className="odd">
<td><code>\b(\w+)\b</code></td>
<td>Match and capture any whole word</td>
</tr>
</tbody>
</table>
</div>
</div>
<p><em>Note:</em> For characters escaped by backslash, remember to type a second backslash in practice to render the first one as a literal backslash.</p>
<p><br/></p>
<p>We’ll dive into details of different types of expressions in the following chapters. You’ll learn RegEx from enriched examples. We’ll use <code>str_view_all()</code> to highlight the matched pattern itself, and further demonstrate its use with functions <code>str_extract()</code> and <code>str_extract_all()</code> from <Link to="/R/data-wrangling/stringr/0-introduction"><code>stringr</code></Link>, a popular R package (part of tidyverse) for string manipulation.</p>
</main>
</div>
</div>
)}