import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ConvertTwoColumnsToVectorOrList(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Convert Two Columns to a Named Vector or List</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Opposite to <code>enframe()</code>, the function <code>deframe()</code> converts a two-column tibble to a named vector or list, using the first column as name and the second column as value. If the input tibble has only one column, an unnamed vector is returned.</p>
<p><strong>e.g.1.</strong> Turn a two-column tibble to a named vector.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>a <span className="ot">&lt;-</span> dplyr<span className="sc">::</span>band_instruments2</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>  artist plays 
<br/>  &lt;chr&gt;  &lt;chr&gt; 
<br/>1 John   guitar
<br/>2 Paul   bass  
<br/>3 Keith  guitar</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">deframe</span>(a)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">    John     Paul    Keith 
"guitar"   "bass" "guitar" </code></pre>
</div>
</div>
<p><strong>e.g.2.</strong> Turn two-columns to a named list.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># create a tibble containing list-columns</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>students <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">Student =</span> <span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"Charlie"</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">Courses =</span> <span className="fu">list</span>( <span className="fu">c</span>(<span className="st">"Math"</span>, <span className="st">"Science"</span>, <span className="st">"History"</span>),</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>                  <span className="fu">c</span>(<span className="st">"English"</span>, <span className="st">"Math"</span>, <span className="st">"Art"</span>),</span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>                  <span className="fu">c</span>(<span className="st">"Science"</span>, <span className="st">"History"</span>, <span className="st">"Geography"</span>) ),</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  <span className="at">Grades =</span> <span className="fu">list</span>( <span className="fu">c</span>(<span className="dv">90</span>, <span className="dv">85</span>, <span className="dv">80</span>),</span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>                 <span className="fu">c</span>(<span className="dv">88</span>, <span className="dv">92</span>, <span className="dv">85</span>),</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>                 <span className="fu">c</span>(<span className="dv">85</span>, <span className="dv">87</span>, <span className="dv">90</span>) ))</span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>students</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  Student Courses   Grades   
<br/>  &lt;chr&gt;   &lt;list&gt;    &lt;list&gt;   
<br/>1 Alice   &lt;chr [3]&gt; &lt;dbl [3]&gt;
<br/>2 Bob     &lt;chr [3]&gt; &lt;dbl [3]&gt;
<br/>3 Charlie &lt;chr [3]&gt; &lt;dbl [3]&gt;</code></pre>
</div>
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># turn the first two columns into a named list</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">deframe</span>(students[, <span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>])</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">$Alice
<br/>[1] "Math"    "Science" "History"

$Bob
<br/>[1] "English" "Math"    "Art"    

$Charlie
<br/>[1] "Science"   "History"   "Geography"</code></pre>
</div>
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># turn the second and third column to a named list</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">deframe</span>(students[, <span className="dv">2</span><span className="sc">:</span><span className="dv">3</span>])</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">$`c("Math", "Science", "History")`
<br/>[1] 90 85 80

$`c("English", "Math", "Art")`
<br/>[1] 88 92 85

$`c("Science", "History", "Geography")`
<br/>[1] 85 87 90</code></pre>
</div>
</div>
</main>
</div>
</div>
)}