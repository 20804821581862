import { useEffect } from "react"
import {addKlippy, initKlippy} from './js/addClassKlippyTo'

export default function CopyIcon() {
     // for copy icon 
     useEffect(()=> {
        // const e = ["pre.r", "pre.markdown"]
        // exclude output cell pre tag to be selected
        const e = ["pre.sourceCode:not(div.cell-output > pre.sourceCode)"]
        addKlippy(e); // add klippy to element in e
        initKlippy('right', 'top', '#CDC9C9', '1', 'Click to copy', 'Copied!');
        // var tooltip = document.querySelector('.tooltipped-no-delay')
        // if (tooltip){
        //     console.log('tolltip found')
        // }
        // tooltip.addEventListener('click', function(){
        //     tooltip.blur();
        // }) 
        // clean up function
        return (() => {
            const d=document.querySelectorAll("button.btn-klippy");
            for(let p=0; p<d.length; p++){
                    d.item(p).remove();
                };
            const elements = document.querySelectorAll(e);    
            for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.remove('klippy') ;
            };
        })
    })
}

