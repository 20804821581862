import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonInstallingjupyternotebook(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Installing-Jupyer-Notebook">Installing Jupyer Notebook<a class="anchor-link" href="#Installing-Jupyer-Notebook">¶</a></h3><p>Jupyter Notebook is an interactive computing environment that enables users to write, execute code, and display results. It is popular for tasks like data analysis, visualization and write instructional contents. I strongly recommend using Jupyter Notebook for Python learning. In the following section, we will guide you through the installation process.</p>
<ol>
    <p><li>Ensure you have Python installed on your system. If not, please follow <a href="install-python">Installing Python</a> to install it.</li>
<li>Open a terminal or command prompt.</li>
<li>Use pip (Python's package manager) to install Jupyter Notebook. Run the following command:</li></p>

</ol>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">pip</span> <span class="n">install</span> <span class="n">jupyter</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<ol start='4'>
    <p>
    <li>Wait for the installation to complete.
<br /><strong>Lauching Jupyter Notebook:</strong></li>
    <li>In your terminal or command prompt, navigate to the directory where you want to work with Jupyter Notebook.</li>
    <li>Run the following command to start the Jupyter Notebook server:</li>
    </p>

</ol>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">jupyter</span> <span class="n">notebook</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This will open a web browser and display the Jupyter Notebook dashboard, allowing you to create and run Jupyter Notebooks.</p>
</div>
</div>
</div>
</div>
</div>
)}