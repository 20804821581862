import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrDetect(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Test If a String Contains a Specified Pattern</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_detect()</code></strong> returns a logical vector, with TRUE for each string element that contains a match of the specified pattern, and FALSE for each string element that doesn’t has such a match.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>fruit <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apple"</span>, <span className="st">"banana"</span>, <span className="st">"pear"</span>, <span className="st">"kiwi"</span>)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># test if each element contains letter e</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">str_detect</span>(fruit, <span className="at">pattern =</span> <span className="st">"e"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1]  TRUE FALSE  TRUE FALSE</code></pre>
</div>
</div>
<p>The argument <code>negate = T</code> reverses the logical values: it returns matched string elements as FALSE, and mismatched ones as TRUE.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_detect</span>(<span className="fu">c</span>(fruit), <span className="at">pattern =</span> <span className="st">"e"</span>, <span className="at">negate =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] FALSE  TRUE FALSE  TRUE</code></pre>
</div>
</div>
<p>The above code is equivalent to the following line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="sc">!</span> <span className="fu">str_detect</span>(fruit, <span className="at">pattern =</span> <span className="st">"e"</span>, <span className="at">negate =</span> F) </span></code></pre></div>
</div>
<p><code>NA</code> elements in the input string will be returned as <code>NA</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">str_detect</span>(<span className="fu">c</span>(<span className="cn">NA</span>, <span className="st">"apple"</span>, <span className="st">"bee"</span>), <span className="at">pattern =</span> <span className="st">"e"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1]   NA TRUE TRUE</code></pre>
</div>
</div>
<h4 className="anchored">
🎁 <strong>Bonus skills !</strong>
</h4>
<p><strong><code>str_starts()</code></strong> and <strong><code>str_ends()</code></strong> are special cases of <code>str_detect()</code> that only search the matched pattern at the very beginning or end of a string, respectively.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>fruit <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apple"</span>, <span className="st">"lemon"</span>, <span className="st">"barley"</span>)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">str_starts</span>(fruit, <span className="at">pattern =</span> <span className="st">"le"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] FALSE  TRUE FALSE</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">str_ends</span>(fruit, <span className="at">pattern =</span> <span className="st">"le"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1]  TRUE FALSE FALSE</code></pre>
</div>
</div>
</main>
</div>
</div>
)}