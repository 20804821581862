import React from 'react';

import PythonSlicingdataframe from '../Python/data wrangling/content/3_SlicingDataFrame_output_react'
import PythonReplacingvalues from '../Python/data wrangling/content/5_ReplacingValues_output_react'
import PythonRenamingcolumns from '../Python/data wrangling/content/6_RenamingColumns_output_react'
import PythonGroupingrowsbyvalues from '../Python/data wrangling/content/12_GroupingRowsbyValues_output_react'
import PythonMissingvalues from '../Python/data wrangling/content/9_MissingValues_output_react'
import PythonConcatenatedataframes from '../Python/data wrangling/content/15_ConcatenateDataFrames_output_react'
import PythonMinmaxsummeancount from '../Python/data wrangling/content/7_MinMaxSumMeanCount_output_react'
import PythonDroppingduplicaterows from '../Python/data wrangling/content/11_DroppingDuplicateRows_output_react'
import PythonLoadingdataintopandasdataframe from '../Python/data wrangling/content/1_LoadingDataIntoPandasDataFrame_output_react'
import PythonUniquevalues from '../Python/data wrangling/content/8_UniqueValues_output_react'
import PythonDeletingcolumnsrows from '../Python/data wrangling/content/10_DeletingColumnsRows_output_react'
import PythonMergingdataframes from '../Python/data wrangling/content/14_MergingDataFrames_output_react'
import PythonOpeningstatement from '../Python/data wrangling/content/0_OpeningStatement_output_react'
import PythonSortingvalues from '../Python/data wrangling/content/4_SortingValues_output_react'
import PythonApplycustomfunction from '../Python/data wrangling/content/13_ApplyCustomFunction_output_react'
import PythonGettinginformationaboutdata from '../Python/data wrangling/content/2_GettingInformationAboutData_output_react'


const PythonDataWranglingData=[
    {'component': <PythonOpeningstatement />, 'path': 'introduction-of-Pandas', 'title': '0. Introduction of Pandas'},
    {'component': <PythonLoadingdataintopandasdataframe />, 'path': 'load-data-into-pandas-dataframe', 'title': '1. Load Data Into Pandas DataFrame'},
    {'component': <PythonGettinginformationaboutdata />, 'path': 'get-information-of-data', 'title': '2. Get Information of DataFrame'},
    {'component': <PythonSlicingdataframe />, 'path': 'slice-dataframe', 'title': '3. Slice DataFrame '},
    {'component': <PythonSortingvalues />, 'path': 'sort-dataframes', 'title': '4. Sort Data'},
    {'component': <PythonReplacingvalues />, 'path': 'replace-values', 'title': '5. Replace Values'},
    {'component': <PythonRenamingcolumns />, 'path': 'rename-columns', 'title': '6. Rename Columns'},
    {'component': <PythonMinmaxsummeancount />, 'path': 'find-min-max-mean', 'title': '7. Find Min, Max, Mean'},
    {'component': <PythonUniquevalues />, 'path': 'find-unique-values-in-columns', 'title': '8. Find Unique Values in Columns'},
    {'component': <PythonMissingvalues />, 'path': 'Handle-missing-values', 'title': '9. Handle Missing Values'},
    {'component': <PythonDeletingcolumnsrows />, 'path': 'delete-columns-rows', 'title': '10. Delete Columns and Rows'},
    {'component': <PythonDroppingduplicaterows />, 'path': 'find-and-drop-duplicate-rows', 'title': '11. Find and Drop Duplicate Rows'},
    {'component': <PythonGroupingrowsbyvalues />, 'path': 'group-data', 'title': '12. Group Data'},
    {'component': <PythonApplycustomfunction />, 'path': 'transform-data-with-custom-functions', 'title': '13. Transform Data with Custom Functions'},
    {'component': <PythonMergingdataframes />, 'path': 'merge-dataframes', 'title': '14. Merge DataFrames'},
    {'component': <PythonConcatenatedataframes />, 'path': 'concatenate-dataframes', 'title': '15. concatenate DataFrames'},


    






]

export default PythonDataWranglingData