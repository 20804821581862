import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
import AddAnswers from '../../../js/addAnswerReveal'; 

export default function PythonDatatypes(){
useCustomEffect()

return ( <div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Data-Types">Data Types<a className="anchor-link" href="#Data-Types">¶</a></h3><p>This tutorial will cover two main topics:</p>
<ol>
<li><p>Python's four most used data types, including <a href="#String">String (<code>str</code>)</a>, <a href="#Integer">Integer (<code>int</code>)</a>, <a href="#Float">Float (<code>float</code>)</a>, <a href="#Boolean">Boolean (<code>bool</code>)</a></p>
</li>
<li><p><a href="#Properties-of-Different-Data-Types">Properties of Different Data Types</a></p>
</li>
</ol>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="String">String<a className="anchor-link" href="#String">¶</a></h4><p><strong>String</strong>, denoted as <strong>str</strong> in Python, is used to represent text and character data. String type data are enclosed within single (<code>''</code>), double (<code>""</code>), or triple quotes (<code>""" """</code>). Regardless of the content—be it letters, numbers, symbols, or characters from other languages—anything enclosed within quotes is considered a string type.</p>
<p>Single (<code>''</code>) and double (<code>""</code>) quotes can be used interchangeably to denote string literals in Python. Triple quotes (<code>""" """</code>), on the other hand, are primarily used when you need to represent multi-line strings, allowing you to include line breaks and preserve formatting within the string.</p>
<h5 id="Example-of-Strings">Example of Strings<a className="anchor-link" href="#Example-of-Strings">¶</a></h5><p>Examples below are all string type data.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># With single quote</span></span>

<span><span className="s1">'English'</span></span>

<span><span className="s1">'An apple a day, keeps doctor away'</span></span>

<span><span className="s1">'!@#'</span></span>


<br /><span><span className="c1"># With double quotes</span></span>

<span><span className="s2">"132"</span></span>

<span><span className="s2">"www.google.com"</span></span>


<br /><span><span className="c1"># Multi-line strings with triple quotes</span></span>

<span><span className="sd">"""</span></span>

<span><span className="sd">This is a line.</span></span>

<span><span className="sd">This is another line.</span></span>

<span><span className="sd">This is a third line.</span></span>

<span><span className="sd">"""</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Integer">Integer<a className="anchor-link" href="#Integer">¶</a></h4><p><strong>Integer</strong>, denoted as <strong>int</strong> in Python, is a fundamental data type used to represent whole numbers, including zero, positive and negative values, without any decimal points.</p>
<h5 id="Example-of-Integers">Example of Integers<a className="anchor-link" href="#Example-of-Integers">¶</a></h5><p>Examples below are integer type data.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># Positive integers</span></span>

<span><span className="mi">20</span></span>

<span><span className="mi">1234</span></span>


<br /><span><span className="c1"># Negative integers</span></span>

<span><span className="o">-</span><span className="mi">20</span></span>

<span><span className="o">-</span><span className="mi">999</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><span className="question-color-green">Q:</span> <span className="question-sentence-color-green">What are the data types of these three data: <span className="bgc-yellow">'hello word'</span>, <span className="bgc-yellow">108</span>, <span className="bgc-yellow">"108"</span>.&lt;/span&gt;</span></p>
<div className="questionOptions">
<p><strong>A.</strong> Interger, Number, String</p>
<p><strong>B.</strong> String, Integer, Integer</p>
<p><strong>C.</strong> String, Integer, String</p>
<p><strong>D.</strong> Integer, Integer, Integer</p>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Answer:</strong></p>
<AddAnswers answer="C" />
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Float">Float<a className="anchor-link" href="#Float">¶</a></h4><p><strong>Float</strong>, denoted as <strong>float</strong> in Python, is used to represent numbers with decimal points, including fractional values.</p>
<h5 id="Example-of-Floats">Example of Floats<a className="anchor-link" href="#Example-of-Floats">¶</a></h5><p>Examples below are float data type.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># Positive floats</span></span>

<span><span className="mf">10.2</span></span>

<span><span className="mf">3.1415</span></span>


<br /><span><span className="c1"># Negative floats</span></span>

<span><span className="o">-</span><span className="mf">22.2</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Boolean">Boolean<a className="anchor-link" href="#Boolean">¶</a></h4><p><strong>Boolean</strong>, denoted as <strong>bool</strong> in Python, represents binary values, either True or False, and is often used for logical operations.</p>
<h5 id="Example-of-Booleans">Example of Booleans<a className="anchor-link" href="#Example-of-Booleans">¶</a></h5><p>Examples below are boolean data type.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># A boolean true</span></span>

<span><span className="kc">True</span></span>


<br /><span><span className="c1"># A boolean false</span></span>

<span><span className="kc">False</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Assign-Various-Data-Types-to-Variables">Assign Various Data Types to Variables<a className="anchor-link" href="#Variables-Types">¶</a></h4><p>In our previous article on <Link to="../variables">Python Variables</Link>, we described them as containers for holding values accessible by names. Now, we'll demonstrate assigning various data types to variables and display their types.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># Assign strings to variables</span></span>

<span><span className="n">str1</span> <span className="o">=</span> <span className="s1">'Europe'</span></span>

<span><span className="n">str2</span> <span className="o">=</span> <span className="s2">"North America"</span></span>


<br /><span><span className="c1"># Assign integers and floats to variables</span></span>

<span><span className="n">num1</span> <span className="o">=</span> <span className="mi">120</span></span>

<span><span className="n">num2</span> <span className="o">=</span> <span className="mf">3.1415</span></span>


<br /><span><span className="c1"># Assign boolean values to variables</span></span>

<span><span className="n">bool_val</span> <span className="o">=</span> <span className="kc">True</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>To check the data type of the values stored in variables, we can use the built-in function <code>type()</code></p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># Print the data type of the variable "string1"</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="nb">type</span><span className="p">(</span><span className="n">str1</span><span className="p">))</span></span>


<br /><span><span className="c1"># Print the data type of the variable "string2"</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="nb">type</span><span className="p">(</span><span className="n">str2</span><span className="p">))</span></span>


<br /><span><span className="c1"># Print the data type of the variable "num1"</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="nb">type</span><span className="p">(</span><span className="n">num1</span><span className="p">))</span></span>


<br /><span><span className="c1"># Print the data type of the variable "num2"</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="nb">type</span><span className="p">(</span><span className="n">num2</span><span className="p">))</span></span>


<br /><span><span className="c1"># Print the data type of the variable "bool_val"</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="nb">type</span><span className="p">(</span><span className="n">bool_val</span><span className="p">))</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&lt;class 'str'&gt;
<br />&lt;class 'str'&gt;
<br />&lt;class 'int'&gt;
<br />&lt;class 'float'&gt;
<br />&lt;class 'bool'&gt;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The outputs have shown that the 5 variables contain these data types: string, integer, float and boolean.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Properties-of-Different-Data-Types">Properties of Different Data Types<a className="anchor-link" href="#Properties-of-Different-Data-Types">¶</a></h4><p>After having basic concepts of data types, let's see some properties of each data type in practice.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Arithmetic-Calculations-of-Intger-and-Float-Variables">Arithmetic Calculations of Intger and Float Variables<a className="anchor-link" href="#Arithmetic-Calculations-of-Intger-and-Float-Variables">¶</a></h5><p>Both integers and floats are applicable for mathematical calculations.</p>
<h5 id="Example">Example<a className="anchor-link" href="#Example">¶</a></h5><p>Define two integer variables, <strong>a</strong> and <strong>b</strong>. Given that both variables are integers, we can readily perform arithmetic calculations on them.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># creating variables</span></span>

<span><span className="n">a</span> <span className="o">=</span> <span className="mi">10</span></span>

<span><span className="n">b</span> <span className="o">=</span> <span className="mi">2</span></span>


<br /><span><span className="c1"># summation</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="n">a</span> <span className="o">+</span> <span className="n">b</span><span className="p">)</span></span>


<br /><span><span className="c1"># deduction</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="n">a</span> <span className="o">-</span> <span className="n">b</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>12
<br />8
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The output has shown that the sum of <strong>a</strong> and <strong>b</strong> is 12, and their difference is 8.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Other Operational Character:</strong></p>
<ul>
<li><strong>multiplication:</strong> <code>*</code></li>
<li><strong>division:</strong> <code>/</code></li>
<li><strong>modulo:</strong> <code>%</code><ul>
<li>it is used to find the remainder of the division of the left operand by the right operand.</li>
<li>e.g. <code>7%2 outputs 1</code></li>
</ul>
</li>
<li><strong>floor division or integer division:</strong> <code>//</code><ul>
<li>it is used to calculate the quotient of a division.</li>
<li>e.g <code>10%3 outputs 3</code></li>
</ul>
</li>
<li><strong>exponential:</strong> <code>**</code><ul>
<li>e.g. <code>3**2 outputs 9</code></li>
</ul>
</li>
</ul>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># Example for modulo % </span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="s2">"Example for modulo:"</span><span className="p">)</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="mi">7</span><span className="o">%</span><span className="k">2</span>)
</span>

<br /><span><span className="c1"># Example for //</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="s2">"Example for floor division"</span><span className="p">)</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="mi">10</span><span className="o">//</span><span className="mi">3</span><span className="p">)</span></span>


<br /><span><span className="c1"># Example for exponential **</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="s2">"Example for exponetial:"</span><span className="p">)</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="mi">3</span><span className="o">**</span><span className="mi">2</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Example for modulo:
<br />1
<br />Example for floor division
<br />3
<br />Example for exponetial:
<br />9
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>💡 Experiment by typing these code examples with different values yourself, execute the calculations, and observe the outcomes.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Concatenation-of-Strings">Concatenation of Strings<a className="anchor-link" href="#Concatenation-of-Strings">¶</a></h5><p>When "<code>+</code>" applied on strings, it is used for string concatenation.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [10]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="c1"># creating string variables</span></span>

<span><span className="n">text1</span> <span className="o">=</span> <span className="s1">'Hello'</span></span>

<span><span className="n">text2</span> <span className="o">=</span> <span className="s2">"World"</span></span>


<br /><span><span className="c1"># Add strings together and print the result</span></span>

<span><span className="nb">print</span><span className="p">(</span><span className="n">text1</span> <span className="o">+</span> <span className="n">text2</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>HelloWorld
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The result shows the two strings concatenated without a space between them. Let's add a space to separate them.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="nb">print</span><span className="p">(</span><span className="n">text1</span> <span className="o">+</span> <span className="s1">' '</span> <span className="o">+</span> <span className="n">text2</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Hello World
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>By adding a space denoted by <code>' '</code> between <strong>text1</strong> and <strong>text2</strong> ('Hello World'), we combined them with a space in between.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>String concatenation is widely used in data preprocessing and web development, especially for merging separate strings to create desired content.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Let's explore another example.</p>
<p>If we aim to print out "<code>My uer ID is 23412</code>", could we construct the code like this:</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">var_1</span> <span className="o">=</span> <span className="s1">'My user ID is: '</span></span>

<span><span className="n">ID</span> <span className="o">=</span> <span className="mi">23412</span></span>


<br /><span><span className="nb">print</span><span className="p">(</span><span className="n">var_1</span> <span className="o">+</span> <span className="n">ID</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="application/vnd.jupyter.stderr">
<pre className='demo-highlight python'><code className='sourceCode'>
<span><span className="ansi-red-fg">---------------------------------------------------------------------------</span></span>

<span><span className="ansi-red-fg">TypeError</span>                                 Traceback (most recent call last)
</span>
<span>Input <span className="ansi-green-fg">In [12]</span>, in <span className="ansi-cyan-fg">&lt;cell line: 4&gt;</span><span className="ansi-blue-fg">()</span></span>

<span><span className="ansi-green-intense-fg ansi-bold">      1</span> var_1 <span>=</span> <span>'</span><span>My user ID is: </span><span>'</span></span>

<span><span className="ansi-green-intense-fg ansi-bold">      2</span> ID <span>=</span> <span>23412</span></span>

<span><span className="ansi-green-fg">----&gt; 4</span> <span>print</span>(<span className="ansi-yellow-bg">var_1</span><span className="ansi-yellow-bg"> </span><span className="ansi-yellow-bg">+</span><span className="ansi-yellow-bg"> </span><span className="ansi-yellow-bg">ID</span>)
</span>

<br /><span><span className="ansi-red-fg">TypeError</span>: can only concatenate str (not "int") to st</span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Immediately, it leads to a TypeError indicating that concatenating strings with integers data is allowed.</p>
<p>This error occurs because the ID variable is an integer, which cannot be directly concatenated with strings.</p>
<p>In order to print out "<code>My uer ID is 23412</code>", we must convert the ID variable from an integer to a string using <strong>data type conversion</strong>.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="str()-Function"><code>str()</code> Function<a className="anchor-link" href="#str()-Function">¶</a></h5><p>The <code>str()</code> function converts data from other types into the string type. By using this function to convert the ID variable to string, we can complete the string concatenation to print "<code>My suer ID is 23412</code>".</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">var_1</span> <span className="o">=</span> <span className="s1">'My user ID is: '</span></span>

<span><span className="n">ID</span> <span className="o">=</span> <span className="mi">23412</span></span>


<br /><span><span className="nb">print</span><span className="p">(</span><span className="n">var_1</span> <span className="o">+</span> <span className="nb">str</span><span className="p">(</span><span className="n">ID</span><span className="p">))</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>My user ID is: 23412
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Indeed, another method for converting data to string type, which we've discussed in previous articles, involves directly enclosing values in quotes:</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="nb">print</span><span className="p">(</span><span className="n">var_1</span> <span className="o">+</span> <span className="s1">'23412'</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>My user ID is: 23412
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>It's important to note that quotes were used directly on the value, not on the variable. This is because enclosing a variable in quotes converts the variable's name into a string, not the value it holds.</p>
<p>Example of mistakenly using quotes around the variable name:</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">var_1</span> <span className="o">=</span> <span className="s1">'My user ID is: '</span></span>

<span><span className="n">ID</span> <span className="o">=</span> <span className="mi">23412</span></span>


<br /><span><span className="nb">print</span><span className="p">(</span><span className="n">var_1</span> <span className="o">+</span> <span className="s1">'ID'</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div className="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div className="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>My user ID is: ID
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Both the <code>str()</code> and quotes can convert data to string. Select the method that best suits your needs.</p>
<p>Let's do a quick check to see how well you understand the <code>str()</code> function</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><span className="question-color-green">Q:</span> <span className="question-sentence-color-green">Which code below has error?</span></p>
<div className="questionOptions">
<p><strong>A.</strong> print(1+2+3)</p>
<p><strong>B.</strong> print('Hello '+"World!")</p>
<p><strong>C.</strong> print('Year ' + 2024)</p>
<p><strong>C.</strong> print('1+2 equlas ' + str(3))</p>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Answer:</strong></p>
<AddAnswers answer="C" />
</div>
</div>
</div>
</div>
</div>
)}