import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ConvertVectorListToTibble(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Convert a Vector or List to a Tibble</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>enframe()</code></strong> converts a named atomic vector to a two-column tibble: the first column records the name of the elements in the vector, and the second column records the element values in the vector.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># create a named vector</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="at">apple =</span> <span className="dv">5</span>, <span className="at">peach =</span> <span className="dv">7</span>, <span className="at">melon =</span> <span className="dv">9</span>) </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># turn named vector to a two-column tibble</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">enframe</span>(x) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>  name  value
<br/>  &lt;chr&gt; &lt;dbl&gt;
<br/>1 apple     5
<br/>2 peach     7
<br/>3 melon     9</code></pre>
</div>
</div>
<p>If the vector is not named, then a natural numeric sequence is used to create the <code>name</code> column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">enframe</span>(<span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">3</span>, <span className="dv">6</span>, <span className="dv">19</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 2
<br/>   name value
<br/>  &lt;int&gt; &lt;dbl&gt;
<br/>1     1     1
<br/>2     2     3
<br/>3     3     6
<br/>4     4    19</code></pre>
</div>
</div>
<p>Use <code>name = NULL</code> to turn off the <code>name</code> column. This creates a tibble with a single column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">enframe</span>(<span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">3</span>, <span className="dv">6</span>, <span className="dv">19</span>), <span className="at">name =</span> <span className="cn">NULL</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 1
<br/>  value
<br/>  &lt;dbl&gt;
<br/>1     1
<br/>2     3
<br/>3     6
<br/>4    19</code></pre>
</div>
</div>
<p>You can use <code>enframe()</code> to convert a named list to a tibble in a similar way. The result will be a nested tibble with a column of type <em>list</em>. For unnamed vectors, the natural sequence is used as the <code>name</code> column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># create a named list</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">list</span>(<span className="at">Mike =</span> .<span className="dv">1</span>, <span className="at">Tom =</span> <span className="dv">2</span><span className="sc">:</span><span className="dv">3</span>, <span className="at">Jane =</span> <span className="fu">c</span>(<span className="st">"x"</span>, <span className="st">"y"</span>, <span className="st">"z"</span>)) </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">$Mike
<br/>[1] 0.1

$Tom
<br/>[1] 2 3

$Jane
<br/>[1] "x" "y" "z"</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># convert the list to a tibble</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">enframe</span>(a)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>  name  value    
<br/>  &lt;chr&gt; &lt;list&gt;   
<br/>1 Mike  &lt;dbl [1]&gt;
<br/>2 Tom   &lt;int [2]&gt;
<br/>3 Jane  &lt;chr [3]&gt;</code></pre>
</div>
</div>
</main>
</div>
</div>
)}