import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2RenameTitleLegend from '../tutorial/rename_legend_title_unnamed-chunk-3.png';

export default function Ggplot2RenameTitleLegend(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Rename Legend and Axial Titles by Three Methods in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains how to <strong>rename legend titles</strong>. You can use the same technique to rename x and y-axis titles. (Check <Link to="../13-2-ggplot2-title-legend-removal"><strong><em>here</em></strong></Link> if you wish to <em>remove</em> legend titles)</p>
<hr/>
<section className="level4" id="packages-dataset-and-mistitled-plot">
<h4 className="anchored" data-anchor-id="packages-dataset-and-mistitled-plot">Packages, dataset, and mistitled plot</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages </span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set the default global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">legend.title =</span> <span className="fu">element_text</span>(</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>    <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">16</span>, <span className="at">color =</span> <span className="st">"turquoise3"</span>)))</span></code></pre></div>
</div>
<p>Here we use the <code>ToothGrowth</code> dataset (of base R) for demonstration.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="co"># convert to tibble for easier dataset navigation</span></span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>ToothGrowth <span className="ot">&lt;-</span> <span className="fu">as_tibble</span>(ToothGrowth)</span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a><span className="fu">head</span>(ToothGrowth, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>    len supp   dose
<br/>  &lt;dbl&gt; &lt;fct&gt; &lt;dbl&gt;
<br/>1   4.2 VC      0.5
<br/>2  11.5 VC      0.5
<br/>3   7.3 VC      0.5</code></pre>
</div>
</div>
<p>In the following boxplot, the <code>dose</code> variable is mapped to <code>fill</code> aesthetic. As <code>dose</code> is a continuous variable, we wrap it in <code>factor()</code> to convert it to a categorical variable. This edit turns the otherwise continuous colorbar into a <em>discrete</em> legend, which fits better to depict three discrete doses. This, however, also changes the legend title to “factor(dose)”. <strong>The following tutorial explains how to rename the legend title as “dose”.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># create a boxplot with a not-so-pretty legend title</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>p <span className="ot">&lt;-</span> ToothGrowth <span className="sc">%&gt;%</span> </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> supp, <span className="at">y =</span> len, <span className="at">fill =</span> <span className="fu">factor</span>(dose))) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_boxplot</span>() </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2RenameTitleLegend} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-1-rename-with-labs">
<h4 className="anchored" data-anchor-id="method-1-rename-with-labs">Method 1: Rename with <code>labs()</code></h4>
<p><code>labs()</code> is a quick approach to change axial and legend titles. As the legend is associated with the <code>fill</code> aesthetic, we use <code>fill = ...</code> to change the associated title.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">labs</span>(<span className="at">fill =</span> <span className="st">"dose"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/rename_legend_title_labs.png"/></p>
</figure>
</div>
</div>
</div>
<p><em>Side note:</em> <code>fill</code> refers to the filled color of the interior of a shape, while <code>color</code> refers to the outline of a shape. In <code>geom_point()</code>, the use of <code>color</code> and <code>fill</code> depends on the associated <code>shape</code>. Check <Link to="../18-ggplot2-create-points">here</Link> to learn more.</p>
</section>
<section className="level4" id="method-2-rename-with-scale_">
<h4 className="anchored" data-anchor-id="method-2-rename-with-scale_">Method 2: Rename with <code>scale_*</code></h4>
<p>Each aesthetic has its own legend, and the associated scale. As <code>fill</code> is mapped with a <em>categorical</em> variable <code>factor(dose)</code>, it corresponds to the scale of <code>scale_fill_discrete()</code>. If <code>fill</code> is associated with a continuous variable, it corresponds to <code>scale_fill_continuous()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_discrete</span>(<span className="at">name =</span> <span className="st">"dose"</span>)</span></code></pre></div>
</div>
<p>The <code>color</code> and <code>fill</code> scales are often associated with a color palette. The following <code>fill</code> scale employs the <Link to="../15-ggplot2-color-brewer-palette">brewer palette</Link> and <Link to="../16-ggplot2-color-viridis-palette">viridis palette</Link> while updating the legend title to “dose”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Pastel2"</span>, <span className="at">name =</span> <span className="st">"dose"</span>)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a></span><br/>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p <span className="sc">+</span> </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_d</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">name =</span> <span className="st">"dose"</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a></span><br/>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>p1 <span className="sc">|</span> p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/rename_legend_title_brewer_viridis.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-3-rename-with-guides">
<h4 className="anchored" data-anchor-id="method-3-rename-with-guides">Method 3: Rename with <code>guides()</code></h4>
<p><code>guides()</code> is a powerful tool to customize non-data related appearance of axes and legends.</p>
<ul>
<li><p>If the legend is associated with a <em>discrete</em> variable, use syntax <code>guide_legend()</code>.</p></li>
<li><p>If a numeric variable is mapped to <code>color</code> or <code>fill</code> to generate a <em>continuous colorbar</em> in the legend, use <code>guide_colorbar()</code> instead. This differentiation of “legend” and “colorbar” terminology only exists in the <code>guides()</code> function. In <code>theme()</code>, both colorbar and legends are known as the “legend”.</p></li>
<li><p>For axis, use <code>guide_axis()</code>.</p></li>
</ul>
<p>The <code>guides</code> syntax can be written in the following two equivalent forms.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(<span className="at">title =</span> <span className="st">"dose"</span>))</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a></span><br/>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="co"># use "guide = ", without the plural "s"</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_fill_discrete</span>(<span className="at">guide =</span> <span className="fu">guide_legend</span>(<span className="at">title =</span> <span className="st">"dose"</span>))</span></code></pre></div>
</div>
</section>
</main>
</div>
</div>
)}