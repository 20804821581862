import { Link } from 'react-router-dom';
import PythonDataWranglingData from '../../data/PythonDataWranglingData';
import { useState, useEffect } from 'react'; 

 
function Navigation(props) { 
  const [showNav, setShowNav] = useState(false);
  const [linkActive, setLinkActive] = useState(null);
  const toggleNav = () => {
    setShowNav(!showNav);
  }
  const linkSelected = (id) => {
      setLinkActive(id)
  }
  useEffect(()=> {
    setLinkActive(props.currentPage)
  }, [props.currentPage])

  return (
    <div >
      <nav className=''>
          <button className="toggle-button" onClick={toggleNav}>
            &#9776;
          </button>
        <div className={`nav-container ${showNav ? 'show' : ''}`}>
          <div className='nav-title'>
            <h3>Python Data Wrangling </h3>
          </div>  
          {/* <ul className='navList'>
            {PythonDataWranglingData.map((data, index) => (
                <li>
                    <Link key={index} to={data.path} className='navLink' onClick={toggleNav}>
                      <div className={`navListItem ${linkActive === index ? 'linkActiveSelected' : ''}`} onClick = {()=>{linkSelected(index)}}>
                        {data.title}
                      </div>
                      
                    </Link>
                </li>
            ))} 
          </ul> */}
           <ul className='navList'>
            {PythonDataWranglingData.map((data, index) => {
              if (data.path === '') {
                return (
                  <div key={index} id='label'>{data.title}</div>
                );
              } else {
                const filteredIndex = PythonDataWranglingData.slice(0, index + 1).filter(item => item.path !== '').length - 1;
                return (
                  <li key={index}>
                    <Link to={data.path} className='navLink' onClick={toggleNav}>
                      <div className={`navListItem ${linkActive === filteredIndex ? 'linkActiveSelected' : ''}`} onClick={() => linkSelected(filteredIndex)}>
                        {data.title}
                      </div>
                    </Link>
                  </li>
                );
              }
              })}
          </ul>
        </div>
        
      </nav>
    </div>
    
  );
}

export default Navigation
