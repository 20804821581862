import React from 'react'; 
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkElement(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="element_at"><code>element_at()</code><a class="anchor-link" href="#element_at">¶</a></h3><p>The <code>element_at()</code> function is used to fetch an element from an array or a map column based on its index or key, respectively.</p>
<h4 id="Usage">Usage<a class="anchor-link" href="#Usage">¶</a></h4><ul>
<li>In the case of an array, <code>element_at()</code> takes the array column and an integer index as arguments and returns the element at that index. Array indexing starts at 1.</li>
<li>For a map, it takes the map column and the key, returning the value associated with that key.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session">Create Spark Session<a class="anchor-link" href="#Create-Spark-Session">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>
<span><span class="kn">from</span> <span class="nn">pyspark.sql.functions</span> <span class="kn">import</span> <span class="n">element_at</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"elementAtExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-element_at-with-Array-columns">Example: Use <code>element_at()</code> with Array columns<a class="anchor-link" href="#Example:-Use-element_at-with-Array-columns">¶</a></h4><h5 id="Create-sample-DataFrame-with-Array-Columns">Create sample DataFrame with Array Columns<a class="anchor-link" href="#Create-sample-DataFrame-with-Array-Columns">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">array_data</span> <span class="o">=</span> <span class="p">[([</span><span class="s2">"Java"</span><span class="p">,</span> <span class="s2">"Python"</span><span class="p">,</span> <span class="s2">"C++"</span><span class="p">],),</span> <span class="p">([</span><span class="s2">"Spark"</span><span class="p">,</span> <span class="s2">"Java"</span><span class="p">,</span> <span class="s2">"C++"</span><span class="p">],),</span> <span class="p">([</span><span class="s2">"Python"</span><span class="p">,</span> <span class="s2">"Scala"</span><span class="p">],)]</span></span>
<span><span class="n">array_columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Languages"</span><span class="p">]</span></span>
<span><span class="n">array_df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">array_data</span><span class="p">,</span> <span class="n">array_columns</span><span class="p">)</span></span>
<span><span class="n">array_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+-------------------+
<br />|          Languages|
<br />+-------------------+
<br />|[Java, Python, C++]|
<br />| [Spark, Java, C++]|
<br />|    [Python, Scala]|
<br />+-------------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div>

</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">element_at_array_df</span> <span class="o">=</span> <span class="n">array_df</span><span class="o">.</span><span class="n">withColumn</span><span class="p">(</span><span class="s2">"SecondLanguage"</span><span class="p">,</span> <span class="n">element_at</span><span class="p">(</span><span class="n">array_df</span><span class="o">.</span><span class="n">Languages</span><span class="p">,</span> <span class="mi">2</span><span class="p">))</span></span>
<span><span class="n">element_at_array_df</span><span class="o">.</span><span class="n">show</span><span class="p">(</span><span class="n">truncate</span><span class="o">=</span><span class="kc">False</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+-------------------+--------------+
<br />|Languages          |SecondLanguage|
<br />+-------------------+--------------+
<br />|[Java, Python, C++]|Python        |
<br />|[Spark, Java, C++] |Java          |
<br />|[Python, Scala]    |Scala         |
<br />+-------------------+--------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<ul>
<li><code>element_at(array_df.Languages, 2)</code>: this retrieves the second element from each array in the <strong>Languages</strong> column.</li>
</ul>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-element_at-with-Map-Columns">Example: Use <code>element_at()</code> with Map Columns<a class="anchor-link" href="#Example:-Use-element_at-with-Map-Columns">¶</a></h4><h5 id="Create-sample-DataFrame-with-Map-Columns">Create sample DataFrame with Map Columns<a class="anchor-link" href="#Create-sample-DataFrame-with-Map-Columns">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">map_data</span> <span class="o">=</span> <span class="p">[(&#123;</span><span class="s2">"Java"</span><span class="p">:</span> <span class="s2">"JVM"</span><span class="p">,</span> <span class="s2">"Python"</span><span class="p">:</span> <span class="s2">"CPython"</span><span class="p">&#125;,),</span> <span class="p">(&#123;</span><span class="s2">"C++"</span><span class="p">:</span> <span class="s2">"GCC"</span><span class="p">,</span> <span class="s2">"Java"</span><span class="p">:</span> <span class="s2">"OpenJDK"</span><span class="p">&#125;,)]</span></span>
<span><span class="n">map_columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"LanguageMap"</span><span class="p">]</span></span>
<span><span class="n">map_df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">map_data</span><span class="p">,</span> <span class="n">map_columns</span><span class="p">)</span></span>
<span><span class="n">map_df</span><span class="o">.</span><span class="n">show</span><span class="p">(</span><span class="n">truncate</span><span class="o">=</span><span class="kc">False</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+--------------------------------+
<br />|LanguageMap                     |
<br />+--------------------------------+
<br />|&#123;Java -&gt; JVM, Python -&gt; CPython&#125;|
<br />|&#123;Java -&gt; OpenJDK, C++ -&gt; GCC&#125;   |
<br />+--------------------------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div>

</div>
</div>
</div>
<div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">element_at_map_df</span> <span class="o">=</span> <span class="n">map_df</span><span class="o">.</span><span class="n">withColumn</span><span class="p">(</span><span class="s2">"JavaPlatform"</span><span class="p">,</span> <span class="n">element_at</span><span class="p">(</span><span class="n">map_df</span><span class="o">.</span><span class="n">LanguageMap</span><span class="p">,</span> <span class="s2">"Java"</span><span class="p">))</span></span>
<span><span class="n">element_at_map_df</span><span class="o">.</span><span class="n">show</span><span class="p">(</span><span class="n">truncate</span><span class="o">=</span><span class="kc">False</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+--------------------------------+------------+
<br />|LanguageMap                     |JavaPlatform|
<br />+--------------------------------+------------+
<br />|&#123;Java -&gt; JVM, Python -&gt; CPython&#125;|JVM         |
<br />|&#123;Java -&gt; OpenJDK, C++ -&gt; GCC&#125;   |OpenJDK     |
<br />+--------------------------------+------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<ul>
<li><code>element_at(map_df.LanguageMap, "Java")</code>: this retrieves the value associated with the key "Java" from each map in the <strong>LanguageMap</strong> column.</li>
</ul>
</div>
<div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [10]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}