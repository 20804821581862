import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LinePlotLifeExpectancy from '../graphics/lines_longevity_theme_completed.png'; 
import imgGgplot2LinePlotLifeExpectancyWebp from '../graphics/lines_longevity_theme_completed.webp'; 
export default function Ggplot2LinePlotLifeExpectancy(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Draw Line Plots in ggplot2 to Visualize Changes in Global Life Expectancy</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2LinePlotLifeExpectancyWebp} />
    <img className="cover-img" src={imgGgplot2LinePlotLifeExpectancy} />
  </picture>

<p>In this article, we’ll use the <code>gapminder</code> dataset to visualize the changing human life expectancy across five continents from 1952 to 2007.</p>
<p><strong>Major techniques explained in this article include:</strong></p>
<ul>
<li><a href="#reorder_faceted_panel_subplot">Arrange the order of faceted panels (subplots).</a></li>
<li><a href="#stat_summary">Create efficient visualization of summary statistics with <code>stat_summary()</code>.</a></li>
<li><a href="#intact_display_overflowing_texts">Completely display graphic elements that extend beyond the plot/panel boundary.</a></li>
<li><a href="#annotate_selected_subplot">Make unique annotations in selected faceted panels.</a></li>
<li><a href="#google_font">Load text fonts from Google Font Repository.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(gapminder) <span className="co"># load the "gapminder" dataset</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">12</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">head</span>(gapminder, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  country     continent  year lifeExp      pop gdpPercap
<br/>  &lt;fct&gt;       &lt;fct&gt;     &lt;int&gt;   &lt;dbl&gt;    &lt;int&gt;     &lt;dbl&gt;
<br/>1 Afghanistan Asia       1952    28.8  8425333      779.
<br/>2 Afghanistan Asia       1957    30.3  9240934      821.
<br/>3 Afghanistan Asia       1962    32.0 10267083      853.</code></pre>
</div>
</div>
<p><span id="reorder_faceted_panel_subplot">The plot will be faceted into subplots based on the <code>continent</code> variable. Here we <strong>specify the order of continents the subplots should be arranged by</strong>. A nominal (<code>fct</code>) and an ordered (<code>ord</code>) factor type work the same regarding the reordering of graphic units. More details about graphic elements reordering can be found in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide</Link>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># arrange the faceted panels in this order:</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>ordered.continent <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="st">"Africa"</span>, <span className="st">"Asia"</span>, <span className="st">"Americas"</span>, <span className="st">"Europe"</span>, <span className="st">"Oceania"</span>)</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a></span><br/>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a><span className="co"># convert "continent" to a factor with specified level order</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">continent =</span> <span className="fu">factor</span>(continent, <span className="at">levels =</span> ordered.continent))</span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a></span><br/>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a><span className="fu">head</span>(g, <span className="at">n =</span> <span className="dv">3</span>) <span className="co"># ready for visualization</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  country     continent  year lifeExp      pop gdpPercap
<br/>  &lt;fct&gt;       &lt;fct&gt;     &lt;int&gt;   &lt;dbl&gt;    &lt;int&gt;     &lt;dbl&gt;
<br/>1 Afghanistan Asia       1952    28.8  8425333      779.
<br/>2 Afghanistan Asia       1957    30.3  9240934      821.
<br/>3 Afghanistan Asia       1962    32.0 10267083      853.</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>1. Create a line plot showing the changes in life expectancy over the years in each country.</strong> Each individual country is displayed as a distinct line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> lifeExp, </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>             <span className="at">color =</span> continent, <span className="at">fill =</span> continent )) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> country), <span className="at">alpha =</span> .<span className="dv">3</span>)</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="stat_summary"><strong>2. Create continent-wise average trend lines using the <code>stat_summary()</code> function.</strong></span></p>
<ul>
<li><p>Based on the aesthetic inheritance of <code>color = continent</code> from the <code>ggplot()</code> line, a trend line is calculated for the average life expectancy separately for each continent, is visualized in the matched color.</p></li>
<li><p>Since a single statistic value, the mean, is calculated, we use the <code>fun</code> argument (as opposed to <code>fun.data</code> when multiple statistics are calculated at <a href="#step3">step 3</a>).</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun =</span> mean, <span className="at">geom =</span> <span className="st">"line"</span>, <span className="at">size =</span> <span className="dv">2</span>)</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_stat_summary_mean.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_stat_summary_mean"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="step3"><strong>3. Draw ribbons to depict one standard deviation (SD) above and below the mean of life expectancy.</strong></span></p>
<ul>
<li><p>As multiple statistics are calculated, i.e., the mean, the upper SD limit, and the lower SD limit, we use argument <code>fun.data</code> (as opposed to <code>fun</code> for a single statistic at <a href="#stat_summary">step2</a>).</p></li>
<li><p>Use <code>fun.args = list(...)</code> for additional arguments of the <code>mean_sdl</code> function.</p></li>
<li><p>In like manner, <code>mean_se</code> calculates the standard error of the mean, <code>mean_cl_normal</code> calculates the confidence interval. Find more <code>stat_summary()</code> examples <Link to="../ggplot2-line-summary-statistics-milk">here</Link>, and check out our <Link to="/R/ebook/flash-to-r-ggplot2-mastery"><strong>awesome ggplot2 ebook</strong></Link> to easily master all essential skills.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>  </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">stat_summary</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="co"># 1 multiple of SD around the mean</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">fun.data =</span> mean_sdl, <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">mult =</span> <span className="dv">1</span>),</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"ribbon"</span>, <span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">color =</span> <span className="cn">NA</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a></span><br/>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_stat_summary_standard_deviation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_stat_summary_standard_deviation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>4. Divide the plot into subplots, with each continent in a separate panel.</strong> And remove the now redundant legend. In addition, we update the color using <code>continent_colors</code>, a named color vector built in the <code>gapminder</code> package. The panels are displayed in order of life expectancy thanks to the conversion of <code>continent</code> (the faceting variable) into an ordered factor at <a href="#reorder_faceted_panel_subplot">this early step</a> of data processing.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>continent, <span className="at">nrow =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> continent_colors) <span className="sc">+</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> continent_colors) </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_facet.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_facet"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>5. Add annotation lines and texts to mark the starting and ending years of 1952 and 2007.</strong> Note that the annotations are repeated across all subplots. As comparison, <a href="#annotate_selected_subplot">step 8</a> makes unique annotations in each panel.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># add annotating lines to mark years 1952 vs. 2007</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="co"># year 1952</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="dv">1952</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">color =</span> <span className="st">"orange3"</span>) <span className="sc">+</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  <span className="co"># year 2007</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="dv">2007</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">color =</span> <span className="st">"skyblue3"</span>) <span className="sc">+</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  </span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>  <span className="co"># add text annotation</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>  <span className="co"># year 1952</span></span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">1952</span>, <span className="at">y =</span> <span className="dv">20</span>, <span className="at">label =</span> <span className="st">" 1952"</span>, </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"orange3"</span>) <span className="sc">+</span></span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  <span className="co"># year 2007</span></span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">2007</span>, <span className="at">y =</span> <span className="dv">20</span>, <span className="at">label =</span> <span className="st">"2007 "</span>, </span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"skyblue3"</span>) </span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_annotation_vline.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_annotation_vline"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>6. Annotate the plot with the average life expectancy in 1952 and 2007 in each continent.</strong> Here we first create a small dataset of the summary statistics.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>life<span className="fl">.1952</span>_2007 <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">c</span>(<span className="dv">1952</span>, <span className="dv">2007</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">group_by</span>(continent, year) <span className="sc">%&gt;%</span> </span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">lifeExp =</span> <span className="fu">mean</span>(lifeExp) <span className="sc">%&gt;%</span> <span className="fu">round</span>())</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a></span><br/>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a><span className="fu">head</span>(life<span className="fl">.1952</span>_2007, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/># Groups:   continent [2]
<br/>  continent  year lifeExp
<br/>  &lt;fct&gt;     &lt;int&gt;   &lt;dbl&gt;
<br/>1 Africa     1952      39
<br/>2 Africa     2007      55
<br/>3 Asia       1952      46</code></pre>
</div>
</div>
<p>Label the plot with the average life expectancy in 1952 and 2007. As the labels extend beyond the boundary of the faceted panels, most of labels are clipped off by default. We’ll fix this in the next step.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">geom_label</span>(</span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>    <span className="at">data =</span> life<span className="fl">.1952</span>_2007, <span className="fu">aes</span>(<span className="at">label =</span> lifeExp), </span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"white"</span>, </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>    </span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>    <span className="co"># right and left justify the labels, respectively, </span></span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a>    <span className="co"># to the left and right edge of the faceted panels. </span></span>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">0</span>) <span className="sc">%&gt;%</span> <span className="fu">rep</span>(<span className="dv">5</span>)) </span>
<span id="cb12-9"><a aria-hidden="true" href="#cb12-9" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_label.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_label"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="intact_display_overflowing_texts"><strong>7. Completely display the texts</strong> (or other graphical elements) <strong>beyond the plot/panel boundary</strong>.</span></p>
<ul>
<li><p>Turn off the <code>clip</code> in the associated coordinate. The <code>clip</code> argument applies not only in <code>coord_cartesian()</code>, but also other coordinate systems if used, e.g., <code>coord_flip()</code>, or <code>coord_fixed()</code>, <code>coord_polar()</code>, etc.</p></li>
<li><p>In the <code>theme()</code> function, increase the <code>plot.margin</code> (space around the entire plot) and <code>panel.spacing</code> (space between the subplots).</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span> </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="co"># not clip graphical elements beyond the panel range</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span> </span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>    <span className="co"># increase margin on the four edges of the plot</span></span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>)),</span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>    <span className="co"># increase margin between panels</span></span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>    <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="dv">20</span>, <span className="st">"pt"</span>))</span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>p7</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_clip_margin.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_clip_margin"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="annotate_selected_subplot"><strong>8. Label the panels with continent names</strong> in place of the default subplot titles (removed in the next step). In the dataset <code>panel.titles</code> defined below, the <strong><code>continent</code> variables serves two important roles</strong>:</span></p>
<ul>
<li>It is the faceting variable, indicative of which panels to be labeled;</li>
<li>It is mapped to the <code>label</code> aesthetic to label panels with associated continent names.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>panel.titles <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">continent =</span> <span className="fu">factor</span>(ordered.continent))</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a></span><br/>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span> </span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>    <span className="at">data =</span> panel.titles,</span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1980</span>, <span className="at">y =</span> <span className="dv">30</span>, <span className="at">label =</span> continent),</span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">7</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>p8</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_titles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_titles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="google_font"><strong>9. Load font from the <Link to="https://fonts.google.com/">Google Font repository</Link>.</strong></span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="co"># add font "Abril Fatface", and name the font as "fat" for our use</span></span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Abril Fatface"</span>, <span className="at">family =</span> <span className="st">"fat"</span>)</span></code></pre></div>
</div>
<p>Polish up the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>p9 <span className="ot">&lt;-</span> p8 <span className="sc">+</span> </span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>  <span className="co"># titles</span></span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Steady increase of Human Life Expectancy"</span>, </span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a>    <span className="at">caption =</span> <span className="st">"Each line represents one country; central line: the average; </span><span className="sc">\n</span><span className="st">ribbon, one standard deviation around the mean."</span>,</span>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a>    <span className="at">x =</span> <span className="cn">NULL</span>)  <span className="sc">+</span></span>
<span id="cb16-7"><a aria-hidden="true" href="#cb16-7" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb16-8"><a aria-hidden="true" href="#cb16-8" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_blank</span>(), </span>
<span id="cb16-9"><a aria-hidden="true" href="#cb16-9" tabindex="-1"></a>    </span>
<span id="cb16-10"><a aria-hidden="true" href="#cb16-10" tabindex="-1"></a>    <span className="at">axis.line.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb16-11"><a aria-hidden="true" href="#cb16-11" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb16-12"><a aria-hidden="true" href="#cb16-12" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb16-13"><a aria-hidden="true" href="#cb16-13" tabindex="-1"></a>    <span className="at">axis.ticks.x =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb16-14"><a aria-hidden="true" href="#cb16-14" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb16-15"><a aria-hidden="true" href="#cb16-15" tabindex="-1"></a>    <span className="at">axis.ticks.length.y =</span> <span className="fu">unit</span>(<span className="dv">20</span>, <span className="st">"pt"</span>),</span>
<span id="cb16-16"><a aria-hidden="true" href="#cb16-16" tabindex="-1"></a>    <span className="at">axis.ticks.y =</span> <span className="fu">element_line</span>(<span className="at">linetype =</span> <span className="st">"dashed"</span>),</span>
<span id="cb16-17"><a aria-hidden="true" href="#cb16-17" tabindex="-1"></a>    </span>
<span id="cb16-18"><a aria-hidden="true" href="#cb16-18" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">18</span>, <span className="at">family =</span> <span className="st">"fat"</span>),</span>
<span id="cb16-19"><a aria-hidden="true" href="#cb16-19" tabindex="-1"></a>    <span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"grey60"</span>),</span>
<span id="cb16-20"><a aria-hidden="true" href="#cb16-20" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"azure"</span>),</span>
<span id="cb16-21"><a aria-hidden="true" href="#cb16-21" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"azure"</span>)</span>
<span id="cb16-22"><a aria-hidden="true" href="#cb16-22" tabindex="-1"></a>  ) </span>
<span id="cb16-23"><a aria-hidden="true" href="#cb16-23" tabindex="-1"></a></span><br/>
<span id="cb16-24"><a aria-hidden="true" href="#cb16-24" tabindex="-1"></a>p9</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_longevity_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_longevity_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Save the plot.</strong> By default, the most recently displayed graphic will be saved.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"line plot life expectancy.pdf"</span>,</span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"/Users/boyuan/Desktop/R/gallery/graphics"</span>,</span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">8</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="co"># Packages and data cleanup</span></span>
<span id="cb18-2"><a aria-hidden="true" href="#cb18-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb18-3"><a aria-hidden="true" href="#cb18-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb18-4"><a aria-hidden="true" href="#cb18-4" tabindex="-1"></a><span className="fu">library</span>(gapminder)</span>
<span id="cb18-5"><a aria-hidden="true" href="#cb18-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">12</span>))</span>
<span id="cb18-6"><a aria-hidden="true" href="#cb18-6" tabindex="-1"></a></span><br/>
<span id="cb18-7"><a aria-hidden="true" href="#cb18-7" tabindex="-1"></a><span className="fu">head</span>(gapminder, <span className="dv">3</span>)</span>
<span id="cb18-8"><a aria-hidden="true" href="#cb18-8" tabindex="-1"></a></span><br/>
<span id="cb18-9"><a aria-hidden="true" href="#cb18-9" tabindex="-1"></a><span className="co"># The plot will be faceted into subplots based on the `continent` variable. </span></span>
<span id="cb18-10"><a aria-hidden="true" href="#cb18-10" tabindex="-1"></a><span className="co"># Specify the order of continents the subplots should be arranged by. </span></span>
<span id="cb18-11"><a aria-hidden="true" href="#cb18-11" tabindex="-1"></a>ordered.continent <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb18-12"><a aria-hidden="true" href="#cb18-12" tabindex="-1"></a>  <span className="st">"Africa"</span>, <span className="st">"Asia"</span>, <span className="st">"Americas"</span>, <span className="st">"Europe"</span>, <span className="st">"Oceania"</span>)</span>
<span id="cb18-13"><a aria-hidden="true" href="#cb18-13" tabindex="-1"></a></span><br/>
<span id="cb18-14"><a aria-hidden="true" href="#cb18-14" tabindex="-1"></a><span className="co"># convert "continent" to a factor with specified level order</span></span>
<span id="cb18-15"><a aria-hidden="true" href="#cb18-15" tabindex="-1"></a>g <span className="ot">&lt;-</span> gapminder <span className="sc">%&gt;%</span> </span>
<span id="cb18-16"><a aria-hidden="true" href="#cb18-16" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">continent =</span> <span className="fu">factor</span>(continent, <span className="at">levels =</span> ordered.continent))</span>
<span id="cb18-17"><a aria-hidden="true" href="#cb18-17" tabindex="-1"></a></span><br/>
<span id="cb18-18"><a aria-hidden="true" href="#cb18-18" tabindex="-1"></a><span className="fu">head</span>(g, <span className="at">n =</span> <span className="dv">3</span>) <span className="co"># ready for visualization!</span></span>
<span id="cb18-19"><a aria-hidden="true" href="#cb18-19" tabindex="-1"></a></span><br/>
<span id="cb18-20"><a aria-hidden="true" href="#cb18-20" tabindex="-1"></a></span><br/>
<span id="cb18-21"><a aria-hidden="true" href="#cb18-21" tabindex="-1"></a><span className="do">### Visualization</span></span>
<span id="cb18-22"><a aria-hidden="true" href="#cb18-22" tabindex="-1"></a></span><br/>
<span id="cb18-23"><a aria-hidden="true" href="#cb18-23" tabindex="-1"></a><span className="co"># Create a line plot, with each line representing one country. </span></span>
<span id="cb18-24"><a aria-hidden="true" href="#cb18-24" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb18-25"><a aria-hidden="true" href="#cb18-25" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> lifeExp, </span>
<span id="cb18-26"><a aria-hidden="true" href="#cb18-26" tabindex="-1"></a>             <span className="at">color =</span> continent, <span className="at">fill =</span> continent )) <span className="sc">+</span></span>
<span id="cb18-27"><a aria-hidden="true" href="#cb18-27" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> country), <span className="at">alpha =</span> .<span className="dv">3</span>)</span>
<span id="cb18-28"><a aria-hidden="true" href="#cb18-28" tabindex="-1"></a>p1</span>
<span id="cb18-29"><a aria-hidden="true" href="#cb18-29" tabindex="-1"></a></span><br/>
<span id="cb18-30"><a aria-hidden="true" href="#cb18-30" tabindex="-1"></a></span><br/>
<span id="cb18-31"><a aria-hidden="true" href="#cb18-31" tabindex="-1"></a><span className="co"># Add average trending line.</span></span>
<span id="cb18-32"><a aria-hidden="true" href="#cb18-32" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb18-33"><a aria-hidden="true" href="#cb18-33" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun =</span> mean, <span className="at">geom =</span> <span className="st">"line"</span>, <span className="at">size =</span> <span className="dv">2</span>)</span>
<span id="cb18-34"><a aria-hidden="true" href="#cb18-34" tabindex="-1"></a>p2</span>
<span id="cb18-35"><a aria-hidden="true" href="#cb18-35" tabindex="-1"></a></span><br/>
<span id="cb18-36"><a aria-hidden="true" href="#cb18-36" tabindex="-1"></a></span><br/>
<span id="cb18-37"><a aria-hidden="true" href="#cb18-37" tabindex="-1"></a><span className="co"># Add ribbons to depict one standard deviation (SD) above and below the mean. </span></span>
<span id="cb18-38"><a aria-hidden="true" href="#cb18-38" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>  </span>
<span id="cb18-39"><a aria-hidden="true" href="#cb18-39" tabindex="-1"></a>  <span className="fu">stat_summary</span>(</span>
<span id="cb18-40"><a aria-hidden="true" href="#cb18-40" tabindex="-1"></a>    <span className="co"># 1 multiple of SD around the mean</span></span>
<span id="cb18-41"><a aria-hidden="true" href="#cb18-41" tabindex="-1"></a>    <span className="at">fun.data =</span> mean_sdl, <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">mult =</span> <span className="dv">1</span>),</span>
<span id="cb18-42"><a aria-hidden="true" href="#cb18-42" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"ribbon"</span>, <span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">color =</span> <span className="cn">NA</span>)</span>
<span id="cb18-43"><a aria-hidden="true" href="#cb18-43" tabindex="-1"></a>p3</span>
<span id="cb18-44"><a aria-hidden="true" href="#cb18-44" tabindex="-1"></a></span><br/>
<span id="cb18-45"><a aria-hidden="true" href="#cb18-45" tabindex="-1"></a></span><br/>
<span id="cb18-46"><a aria-hidden="true" href="#cb18-46" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb18-47"><a aria-hidden="true" href="#cb18-47" tabindex="-1"></a>  <span className="co"># Divide the plot into subplots.  </span></span>
<span id="cb18-48"><a aria-hidden="true" href="#cb18-48" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>continent, <span className="at">nrow =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb18-49"><a aria-hidden="true" href="#cb18-49" tabindex="-1"></a>  <span className="co"># Remove the legend.</span></span>
<span id="cb18-50"><a aria-hidden="true" href="#cb18-50" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb18-51"><a aria-hidden="true" href="#cb18-51" tabindex="-1"></a>  <span className="co"># Update the color scale. </span></span>
<span id="cb18-52"><a aria-hidden="true" href="#cb18-52" tabindex="-1"></a>  <span className="co"># the named color vector `continent_colors` is built in the gapminder package.</span></span>
<span id="cb18-53"><a aria-hidden="true" href="#cb18-53" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> continent_colors) <span className="sc">+</span></span>
<span id="cb18-54"><a aria-hidden="true" href="#cb18-54" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> continent_colors) </span>
<span id="cb18-55"><a aria-hidden="true" href="#cb18-55" tabindex="-1"></a>p4</span>
<span id="cb18-56"><a aria-hidden="true" href="#cb18-56" tabindex="-1"></a></span><br/>
<span id="cb18-57"><a aria-hidden="true" href="#cb18-57" tabindex="-1"></a></span><br/>
<span id="cb18-58"><a aria-hidden="true" href="#cb18-58" tabindex="-1"></a><span className="co"># Add annotation lines and texts to mark the starting and ending years: 1952 vs 2007. </span></span>
<span id="cb18-59"><a aria-hidden="true" href="#cb18-59" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb18-60"><a aria-hidden="true" href="#cb18-60" tabindex="-1"></a>  <span className="co"># add annotating lines</span></span>
<span id="cb18-61"><a aria-hidden="true" href="#cb18-61" tabindex="-1"></a>  <span className="co"># year 1952</span></span>
<span id="cb18-62"><a aria-hidden="true" href="#cb18-62" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="dv">1952</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">color =</span> <span className="st">"orange3"</span>) <span className="sc">+</span></span>
<span id="cb18-63"><a aria-hidden="true" href="#cb18-63" tabindex="-1"></a>  <span className="co"># year 2007</span></span>
<span id="cb18-64"><a aria-hidden="true" href="#cb18-64" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="dv">2007</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">color =</span> <span className="st">"skyblue3"</span>) <span className="sc">+</span></span>
<span id="cb18-65"><a aria-hidden="true" href="#cb18-65" tabindex="-1"></a>  </span>
<span id="cb18-66"><a aria-hidden="true" href="#cb18-66" tabindex="-1"></a>  <span className="co"># add texts</span></span>
<span id="cb18-67"><a aria-hidden="true" href="#cb18-67" tabindex="-1"></a>  <span className="co"># year 1952</span></span>
<span id="cb18-68"><a aria-hidden="true" href="#cb18-68" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">1952</span>, <span className="at">y =</span> <span className="dv">20</span>, <span className="at">label =</span> <span className="st">" 1952"</span>, </span>
<span id="cb18-69"><a aria-hidden="true" href="#cb18-69" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"orange3"</span>) <span className="sc">+</span></span>
<span id="cb18-70"><a aria-hidden="true" href="#cb18-70" tabindex="-1"></a>  <span className="co"># year 2007</span></span>
<span id="cb18-71"><a aria-hidden="true" href="#cb18-71" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">2007</span>, <span className="at">y =</span> <span className="dv">20</span>, <span className="at">label =</span> <span className="st">"2007 "</span>, </span>
<span id="cb18-72"><a aria-hidden="true" href="#cb18-72" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"skyblue3"</span>) </span>
<span id="cb18-73"><a aria-hidden="true" href="#cb18-73" tabindex="-1"></a>p5</span>
<span id="cb18-74"><a aria-hidden="true" href="#cb18-74" tabindex="-1"></a></span><br/>
<span id="cb18-75"><a aria-hidden="true" href="#cb18-75" tabindex="-1"></a></span><br/>
<span id="cb18-76"><a aria-hidden="true" href="#cb18-76" tabindex="-1"></a><span className="co"># Calculate the average life expectancy in 1952 and 2007 in each of the continents.</span></span>
<span id="cb18-77"><a aria-hidden="true" href="#cb18-77" tabindex="-1"></a>life<span className="fl">.1952</span>_2007 <span className="ot">&lt;-</span> g <span className="sc">%&gt;%</span> </span>
<span id="cb18-78"><a aria-hidden="true" href="#cb18-78" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">%in%</span> <span className="fu">c</span>(<span className="dv">1952</span>, <span className="dv">2007</span>)) <span className="sc">%&gt;%</span> </span>
<span id="cb18-79"><a aria-hidden="true" href="#cb18-79" tabindex="-1"></a>  <span className="fu">group_by</span>(continent, year) <span className="sc">%&gt;%</span> </span>
<span id="cb18-80"><a aria-hidden="true" href="#cb18-80" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">lifeExp =</span> <span className="fu">mean</span>(lifeExp) <span className="sc">%&gt;%</span> <span className="fu">round</span>())</span>
<span id="cb18-81"><a aria-hidden="true" href="#cb18-81" tabindex="-1"></a></span><br/>
<span id="cb18-82"><a aria-hidden="true" href="#cb18-82" tabindex="-1"></a><span className="fu">head</span>(life<span className="fl">.1952</span>_2007, <span className="dv">3</span>)</span>
<span id="cb18-83"><a aria-hidden="true" href="#cb18-83" tabindex="-1"></a></span><br/>
<span id="cb18-84"><a aria-hidden="true" href="#cb18-84" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb18-85"><a aria-hidden="true" href="#cb18-85" tabindex="-1"></a>  <span className="fu">geom_label</span>(</span>
<span id="cb18-86"><a aria-hidden="true" href="#cb18-86" tabindex="-1"></a>    <span className="at">data =</span> life<span className="fl">.1952</span>_2007, <span className="fu">aes</span>(<span className="at">label =</span> lifeExp), </span>
<span id="cb18-87"><a aria-hidden="true" href="#cb18-87" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"white"</span>, </span>
<span id="cb18-88"><a aria-hidden="true" href="#cb18-88" tabindex="-1"></a>    </span>
<span id="cb18-89"><a aria-hidden="true" href="#cb18-89" tabindex="-1"></a>    <span className="co"># right and left justify the labels, respectively, </span></span>
<span id="cb18-90"><a aria-hidden="true" href="#cb18-90" tabindex="-1"></a>    <span className="co"># to the left and right edge of the faceted panels. </span></span>
<span id="cb18-91"><a aria-hidden="true" href="#cb18-91" tabindex="-1"></a>    <span className="at">hjust =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">0</span>) <span className="sc">%&gt;%</span> <span className="fu">rep</span>(<span className="dv">5</span>)) </span>
<span id="cb18-92"><a aria-hidden="true" href="#cb18-92" tabindex="-1"></a>p6</span>
<span id="cb18-93"><a aria-hidden="true" href="#cb18-93" tabindex="-1"></a></span><br/>
<span id="cb18-94"><a aria-hidden="true" href="#cb18-94" tabindex="-1"></a></span><br/>
<span id="cb18-95"><a aria-hidden="true" href="#cb18-95" tabindex="-1"></a><span className="co"># Enable complete display of the texts (or other graphical elements) beyond the plot boundary.</span></span>
<span id="cb18-96"><a aria-hidden="true" href="#cb18-96" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span> </span>
<span id="cb18-97"><a aria-hidden="true" href="#cb18-97" tabindex="-1"></a>  <span className="co"># not clip graphical elements beyond the panel range</span></span>
<span id="cb18-98"><a aria-hidden="true" href="#cb18-98" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span> </span>
<span id="cb18-99"><a aria-hidden="true" href="#cb18-99" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb18-100"><a aria-hidden="true" href="#cb18-100" tabindex="-1"></a>    <span className="co"># increase margin on the four edges of the plot</span></span>
<span id="cb18-101"><a aria-hidden="true" href="#cb18-101" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>)),</span>
<span id="cb18-102"><a aria-hidden="true" href="#cb18-102" tabindex="-1"></a>    <span className="co"># increase margin between panels</span></span>
<span id="cb18-103"><a aria-hidden="true" href="#cb18-103" tabindex="-1"></a>    <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="dv">20</span>, <span className="st">"pt"</span>))</span>
<span id="cb18-104"><a aria-hidden="true" href="#cb18-104" tabindex="-1"></a>p7</span>
<span id="cb18-105"><a aria-hidden="true" href="#cb18-105" tabindex="-1"></a></span><br/>
<span id="cb18-106"><a aria-hidden="true" href="#cb18-106" tabindex="-1"></a></span><br/>
<span id="cb18-107"><a aria-hidden="true" href="#cb18-107" tabindex="-1"></a><span className="co"># Label the panels with continent names in replace of the default subplot titles</span></span>
<span id="cb18-108"><a aria-hidden="true" href="#cb18-108" tabindex="-1"></a>panel.titles <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">continent =</span> <span className="fu">factor</span>(ordered.continent))</span>
<span id="cb18-109"><a aria-hidden="true" href="#cb18-109" tabindex="-1"></a></span><br/>
<span id="cb18-110"><a aria-hidden="true" href="#cb18-110" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> p7 <span className="sc">+</span> </span>
<span id="cb18-111"><a aria-hidden="true" href="#cb18-111" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb18-112"><a aria-hidden="true" href="#cb18-112" tabindex="-1"></a>    <span className="at">data =</span> panel.titles,</span>
<span id="cb18-113"><a aria-hidden="true" href="#cb18-113" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1980</span>, <span className="at">y =</span> <span className="dv">30</span>, <span className="at">label =</span> continent),</span>
<span id="cb18-114"><a aria-hidden="true" href="#cb18-114" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">7</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb18-115"><a aria-hidden="true" href="#cb18-115" tabindex="-1"></a>p8</span>
<span id="cb18-116"><a aria-hidden="true" href="#cb18-116" tabindex="-1"></a></span><br/>
<span id="cb18-117"><a aria-hidden="true" href="#cb18-117" tabindex="-1"></a></span><br/>
<span id="cb18-118"><a aria-hidden="true" href="#cb18-118" tabindex="-1"></a><span className="co"># Load font "Abril Fatface" from the Google Font Repository, and polish up the theme. </span></span>
<span id="cb18-119"><a aria-hidden="true" href="#cb18-119" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb18-120"><a aria-hidden="true" href="#cb18-120" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb18-121"><a aria-hidden="true" href="#cb18-121" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Abril Fatface"</span>, <span className="at">family =</span> <span className="st">"fat"</span>)</span>
<span id="cb18-122"><a aria-hidden="true" href="#cb18-122" tabindex="-1"></a></span><br/>
<span id="cb18-123"><a aria-hidden="true" href="#cb18-123" tabindex="-1"></a>p9 <span className="ot">&lt;-</span> p8 <span className="sc">+</span> </span>
<span id="cb18-124"><a aria-hidden="true" href="#cb18-124" tabindex="-1"></a>  <span className="co"># titles</span></span>
<span id="cb18-125"><a aria-hidden="true" href="#cb18-125" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb18-126"><a aria-hidden="true" href="#cb18-126" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Steady increase of Human Life Expectancy"</span>, </span>
<span id="cb18-127"><a aria-hidden="true" href="#cb18-127" tabindex="-1"></a>    <span className="at">caption =</span> <span className="st">"Each line represents one country; central line: the average; </span><span className="sc">\n</span><span className="st">ribbon, one standard deviation around the mean."</span>,</span>
<span id="cb18-128"><a aria-hidden="true" href="#cb18-128" tabindex="-1"></a>    <span className="at">x =</span> <span className="cn">NULL</span>)  <span className="sc">+</span></span>
<span id="cb18-129"><a aria-hidden="true" href="#cb18-129" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb18-130"><a aria-hidden="true" href="#cb18-130" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_blank</span>(), </span>
<span id="cb18-131"><a aria-hidden="true" href="#cb18-131" tabindex="-1"></a>    </span>
<span id="cb18-132"><a aria-hidden="true" href="#cb18-132" tabindex="-1"></a>    <span className="at">axis.line.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb18-133"><a aria-hidden="true" href="#cb18-133" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb18-134"><a aria-hidden="true" href="#cb18-134" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb18-135"><a aria-hidden="true" href="#cb18-135" tabindex="-1"></a>    <span className="at">axis.ticks.x =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb18-136"><a aria-hidden="true" href="#cb18-136" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb18-137"><a aria-hidden="true" href="#cb18-137" tabindex="-1"></a>    <span className="at">axis.ticks.length.y =</span> <span className="fu">unit</span>(<span className="dv">20</span>, <span className="st">"pt"</span>),</span>
<span id="cb18-138"><a aria-hidden="true" href="#cb18-138" tabindex="-1"></a>    <span className="at">axis.ticks.y =</span> <span className="fu">element_line</span>(<span className="at">linetype =</span> <span className="st">"dashed"</span>),</span>
<span id="cb18-139"><a aria-hidden="true" href="#cb18-139" tabindex="-1"></a>    </span>
<span id="cb18-140"><a aria-hidden="true" href="#cb18-140" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">18</span>, <span className="at">family =</span> <span className="st">"fat"</span>),</span>
<span id="cb18-141"><a aria-hidden="true" href="#cb18-141" tabindex="-1"></a>    <span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"grey60"</span>),</span>
<span id="cb18-142"><a aria-hidden="true" href="#cb18-142" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"azure"</span>),</span>
<span id="cb18-143"><a aria-hidden="true" href="#cb18-143" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"azure"</span>)</span>
<span id="cb18-144"><a aria-hidden="true" href="#cb18-144" tabindex="-1"></a>  ) </span>
<span id="cb18-145"><a aria-hidden="true" href="#cb18-145" tabindex="-1"></a></span><br/>
<span id="cb18-146"><a aria-hidden="true" href="#cb18-146" tabindex="-1"></a>p9</span>
<span id="cb18-147"><a aria-hidden="true" href="#cb18-147" tabindex="-1"></a></span><br/>
<span id="cb18-148"><a aria-hidden="true" href="#cb18-148" tabindex="-1"></a></span><br/>
<span id="cb18-149"><a aria-hidden="true" href="#cb18-149" tabindex="-1"></a><span className="co"># Save the plot. </span></span>
<span id="cb18-150"><a aria-hidden="true" href="#cb18-150" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"line plot life expectancy.pdf"</span>,</span>
<span id="cb18-151"><a aria-hidden="true" href="#cb18-151" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"/Users/boyuan/Desktop/R/gallery/graphics"</span>,</span>
<span id="cb18-152"><a aria-hidden="true" href="#cb18-152" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">8</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check the following <Link to="../ggplot2-line-plot-cigarettes"><strong>annotated line plot</strong></Link> that shows the changing popularity of smoking worldwide, in particular in the highlighted countries of United States, Germany, and France.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to line plots, arrows are a concise and powerful tool to make visual contrast between two time points. Check the following <Link to="../ggplot2-arrows-facet-women-parliament"><strong>annotated and faceted arrow plot</strong></Link> that illustrates the changing percentage of National Parliaments’ seats held by women between 2020 and 2000.</p>
<p><Link to="../ggplot2-arrows-facet-women-parliament">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Furthermore, ribbons are an attractive alternative to illustrate chronological changes with engaging visual appeal, especially when the <em>summed</em> quantity at each time point is of interest. Check out this awesome <Link to="../ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link>, which shows dynamic shifts in the migrant population to the United States from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}