import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarCo2AirTravel from '../graphics/bar_air_travel_completed.png'; 
import imgGgplot2BarCo2AirTravelWebp from '../graphics/bar_air_travel_completed.webp'; 
export default function Ggplot2BarCo2AirTravel(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Faceted Bars in ggplot2 to Visualize Global Inequalities in Aviation CO₂ Emissions</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarCo2AirTravelWebp} />
    <img className="cover-img" src={imgGgplot2BarCo2AirTravel} />
  </picture>

<p>When <strong>long text strings</strong> are naively mapped to the x or y axis, it can greatly squeeze the available plotting space. Out of the <Link to="../../R/visualization/3-ggplot2-wrap-long-texts">many solutions</Link>, I’ll introduce in this article <strong>an elegant and advanced approach: facet the plot</strong> based on the variable which contains long strings, and <strong>display the long strings in the horizontal orientation as subplot titles</strong>.</p>
<p>I’ll illustrate this technique by visualizing the amount of air travel-released carbon dioxide by developed and developing countries. The inspiration comes from the graphic by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link> by Lisa Charlotte Rost.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The data used in this visualization is sourced from <Link to="https://ourworldindata.org/breakdown-co2-aviation">Our World in Data</Link>, and can be downloaded <a className="downloadData" href="dataset/r-gallery/bar_CO2_air_travel.csv"><strong>here</strong></a>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/bar_CO2_air_travel.csv"</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">pivot_longer</span>(</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="sc">-</span>countries, <span className="at">names_to =</span> <span className="st">"aspect"</span>, <span className="at">values_to =</span> <span className="st">"percent"</span>)</span></code></pre></div>
</div>
<p>Convert the <code>countries</code> and <code>aspect</code> variables into factor variables, such that the levels are displayed in order of their appearance in the dataset (or in reverse order). Check this <Link to="../../R/visualization/4-ggplot2-reorder-bars"><strong>complete guide</strong></Link> to learn systematically how to <em>reorder graphical elements</em> in ggplot2.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>d.tidy.ordered <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span></span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="co"># use 'unique' to extract unique levels; </span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="co"># there should be NO duplicates for the 'levels' argument. </span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>    <span className="at">countries =</span> <span className="fu">factor</span>(countries, <span className="at">levels =</span> <span className="fu">unique</span>(d.tidy<span className="sc">$</span>countries)),</span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a>    <span className="at">aspect =</span> <span className="fu">factor</span>(aspect, <span className="at">levels =</span> <span className="fu">unique</span>(d.tidy<span className="sc">$</span>aspect) <span className="sc">%&gt;%</span> <span className="fu">rev</span>())) </span>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a></span><br/>
<span id="cb2-8"><a aria-hidden="true" href="#cb2-8" tabindex="-1"></a><span className="fu">head</span>(d.tidy.ordered, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  countries                     aspect                                 percent
<br/>  &lt;fct&gt;                         &lt;fct&gt;                                    &lt;int&gt;
<br/>1 High income countries         share_of_CO2_emissions_from_air_travel      62
<br/>2 High income countries         share_of_world_population                   16
<br/>3 Upper-middle income countries share_of_CO2_emissions_from_air_travel      28</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a bar plot with a flipped x and y axes. Note how the plot has been squeezed by the long labels of the x-axis (left) and legend.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy.ordered <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> countries, <span className="at">y =</span> percent, <span className="at">fill =</span> aspect)) <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"dodge"</span>) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="co"># flip, and expand bars to fill up entire plot</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>)</span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_squeezed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_squeezed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>To solve the squeezing issue, here we facet the plot into subplots arranged in a single column. We’ll use the subplot titles in place of the x-axis labels to free up more plotting space. We’ll remove the x-axis labels at a later step. (An alternative solution is to <em>wrap long labels</em> as in <Link to="../ggpplot2-bar-mortality">this example</Link>)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>countries, <span className="at">ncol =</span> <span className="dv">1</span>, <span className="at">scales =</span> <span className="st">"free_y"</span>) </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_faceted.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_faceted"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Annotate the bars with the share percentage in place of the y-axis labels (bottom). The texts are dodged with <code>width</code> of 0.9, which is the default dodge amount of <code>geom_col()</code> and <code>geom_bar()</code>. A vector of colors in length of 8 (as the number of bars) is used to specify the text color in each bar.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">paste</span>(percent, <span className="st">"%"</span>), <span className="at">y =</span> <span className="dv">3</span>),</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="co"># 0.9 is the default bar dodge width</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_dodge</span>(.<span className="dv">9</span>),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>,</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="fu">c</span>(<span className="st">"white"</span>, <span className="st">"black"</span>), <span className="dv">3</span>), <span className="st">"black"</span>, <span className="st">"black"</span>)) </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_texts.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_texts"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Use <code>theme_void</code> to quickly remove all axial elements (titles, labels, ticks), and the strip borders. As it also removes the margin around the plot, we’ll add it back later in the <code>theme</code> customization. Now we see that the removal of the long text strings on the x-axis (left) has greatly improved the graphic appearance.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">theme_void</span>()</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_theme_void.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_theme_void"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Further polish up the theme. In particular, free up more space on the plot’s right side by relocating the legend to the top of the plot in a horizontal orientation.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"top"</span>, <span className="co"># move legend to the top</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">legend.text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">13</span>),</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>    <span className="co"># make subplot titles more prominent </span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_text</span>(</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>      <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">t =</span> <span className="dv">20</span>)),</span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>    <span className="co"># slightly reduce the spacing between panels</span></span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>    <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="sc">-</span><span className="dv">5</span>, <span className="st">"pt"</span>),</span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>    </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>    <span className="co"># add margins around the four sides of the plot</span></span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">10</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>))</span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Change the bar colors, remove the underscores in the legend labels, and remove the legend title, all done in the <code>scale_fill_manual()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">scale_fill_manual</span>(</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="at">values =</span> <span className="fu">c</span>(<span className="st">"grey80"</span>, <span className="st">"firebrick3"</span>),</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="co"># remove the underscores from the legend labels</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>    <span className="fu">str_replace_all</span>(x, <span className="at">pattern =</span> <span className="st">"_"</span>, <span className="at">replacement =</span> <span className="st">" "</span>)&#125;,</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  <span className="at">name =</span> <span className="cn">NULL</span>)  <span className="co"># remove legend title</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_color_legend.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_color_legend"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add a summarizing plot title. We use <Link to="https://wilkelab.org/ggtext/"><code>ggtext</code></Link> package to enhance the text strings. This involves two steps:</p>
<ul>
<li>Use HTML to format the title</li>
<li>use <code>element_markdown()</code> in replace of <code>element_text()</code> in the <code>theme</code> syntax. Alternatively, use <code>element_textbox_simple()</code> to automatically wrap long text strings.</li>
</ul>
<p>Check here (<Link to="../ggplot2-segment-US-president-terms"><strong>1</strong></Link>, <Link to="../ggplot2-lollipop-donut-horsepower"><strong>2</strong></Link>, <Link to="../ggplot2-scatterplot-techniques"><strong>3</strong></Link>) for more examples of title enrichment with <code>ggtext</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># install.packages("ggtext")</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>plot.title <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="st">"High income countries&lt;/span&gt; make up </span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a><span className="st">   &lt;span style = 'color: black;'&gt;16% of the world population,&lt;/span&gt;</span></span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a><span className="st">   but create </span></span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a><span className="st">   &lt;span style = 'color: firebrick;'&gt;*62% of CO2 emissions*&lt;/span&gt; from air travel.</span><span className="sc">\n</span><span className="st">"</span>)</span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a></span><br/>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span></span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>  <span className="fu">ggtitle</span>(plot.title) <span className="sc">+</span></span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>    <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"steelblue2"</span>,</span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>    <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>), <span className="at">size =</span> <span className="dv">14</span>))</span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>p7</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bar_air_travel_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bar_air_travel_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a></span><br/>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/bar_CO2_air_travel.csv"</span>)</span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a></span><br/>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">pivot_longer</span>(</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>  <span className="sc">-</span>countries, <span className="at">names_to =</span> <span className="st">"aspect"</span>, <span className="at">values_to =</span> <span className="st">"percent"</span>)</span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a></span><br/>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>d.tidy.ordered <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span></span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>  <span className="co"># use 'unique' to extract unique levels; </span></span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a>  <span className="co"># there should be NO duplicates for the 'levels' argument. </span></span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">countries =</span> <span className="fu">factor</span>(countries, <span className="at">levels =</span> <span className="fu">unique</span>(d.tidy<span className="sc">$</span>countries)),</span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>         <span className="at">aspect =</span> <span className="fu">factor</span>(aspect, <span className="at">levels =</span> <span className="fu">unique</span>(d.tidy<span className="sc">$</span>aspect) <span className="sc">%&gt;%</span> <span className="fu">rev</span>())) </span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a></span><br/>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a><span className="fu">head</span>(d.tidy.ordered, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a></span><br/>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a></span><br/>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a><span className="co"># Create a basic bar plot in a flipped orientation. </span></span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy.ordered <span className="sc">%&gt;%</span> </span>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> countries, <span className="at">y =</span> percent, <span className="at">fill =</span> aspect)) <span className="sc">+</span></span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"dodge"</span>) <span className="sc">+</span></span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>  <span className="co"># flip, and expand bars to fill up entire plot</span></span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>)</span>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a>p1</span>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a></span><br/>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a></span><br/>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a><span className="co"># Facet the plot into subplots arranged in a single column. </span></span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>countries, <span className="at">ncol =</span> <span className="dv">1</span>, <span className="at">scales =</span> <span className="st">"free_y"</span>) </span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a>p2</span>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a></span><br/>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a></span><br/>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a><span className="co"># Annotate the bars with the share percentage in replace of the y (bottom) axis. </span></span>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">paste</span>(percent, <span className="st">"%"</span>), <span className="at">y =</span> <span className="dv">3</span>),</span>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a>    <span className="co"># 0.9 is the default bar dodge width</span></span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_dodge</span>(.<span className="dv">9</span>),</span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a>    <span className="at">fontface =</span> <span className="st">"bold"</span>,</span>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="fu">c</span>(<span className="st">"white"</span>, <span className="st">"black"</span>), <span className="dv">3</span>), <span className="st">"black"</span>, <span className="st">"black"</span>)) </span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>p3</span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a></span><br/>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a></span><br/>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a><span className="co"># Quickly remove all axial elements (titles, labels, ticks), and the strip borders. </span></span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">theme_void</span>()</span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a>p4</span>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a></span><br/>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a></span><br/>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a><span className="co"># Further improve the theme specification.</span></span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"top"</span>, <span className="co"># move legend to the top</span></span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a>    <span className="at">legend.text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">13</span>),</span>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a>    </span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a>    <span className="co"># make subplot titles more prominent </span></span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_text</span>(</span>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a>      <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">t =</span> <span className="dv">20</span>)),</span>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a>    <span className="co"># slightly reduce the spacing between panels</span></span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a>    <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="sc">-</span><span className="dv">5</span>, <span className="st">"pt"</span>),</span>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>    </span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a>    <span className="co"># add margins around the four sides of the plot</span></span>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="dv">10</span>, <span className="dv">10</span>, <span className="dv">10</span>, <span className="dv">10</span>))</span>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a>p5</span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a></span><br/>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a></span><br/>
<span id="cb11-68"><a aria-hidden="true" href="#cb11-68" tabindex="-1"></a><span className="co"># Change the bar colors, remove the underscores in the legend labels, and remove the legend title</span></span>
<span id="cb11-69"><a aria-hidden="true" href="#cb11-69" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">scale_fill_manual</span>(</span>
<span id="cb11-70"><a aria-hidden="true" href="#cb11-70" tabindex="-1"></a>  <span className="at">values =</span> <span className="fu">c</span>(<span className="st">"grey80"</span>, <span className="st">"firebrick3"</span>),</span>
<span id="cb11-71"><a aria-hidden="true" href="#cb11-71" tabindex="-1"></a>  <span className="co"># remove the underscores from the legend labels</span></span>
<span id="cb11-72"><a aria-hidden="true" href="#cb11-72" tabindex="-1"></a>  <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;</span>
<span id="cb11-73"><a aria-hidden="true" href="#cb11-73" tabindex="-1"></a>    <span className="fu">str_replace_all</span>(x, <span className="at">pattern =</span> <span className="st">"_"</span>, <span className="at">replacement =</span> <span className="st">" "</span>)&#125;,</span>
<span id="cb11-74"><a aria-hidden="true" href="#cb11-74" tabindex="-1"></a>  <span className="at">name =</span> <span className="cn">NULL</span>)  <span className="co"># remove legend title</span></span>
<span id="cb11-75"><a aria-hidden="true" href="#cb11-75" tabindex="-1"></a>p6</span>
<span id="cb11-76"><a aria-hidden="true" href="#cb11-76" tabindex="-1"></a></span><br/>
<span id="cb11-77"><a aria-hidden="true" href="#cb11-77" tabindex="-1"></a></span><br/>
<span id="cb11-78"><a aria-hidden="true" href="#cb11-78" tabindex="-1"></a><span className="co"># Add a summarizing plot title. </span></span>
<span id="cb11-79"><a aria-hidden="true" href="#cb11-79" tabindex="-1"></a><span className="co"># install.packages("ggtext")</span></span>
<span id="cb11-80"><a aria-hidden="true" href="#cb11-80" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb11-81"><a aria-hidden="true" href="#cb11-81" tabindex="-1"></a></span><br/>
<span id="cb11-82"><a aria-hidden="true" href="#cb11-82" tabindex="-1"></a>plot.title <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb11-83"><a aria-hidden="true" href="#cb11-83" tabindex="-1"></a>  <span className="st">"High income countries&lt;/span&gt; make up </span></span>
<span id="cb11-84"><a aria-hidden="true" href="#cb11-84" tabindex="-1"></a><span className="st">   &lt;span style = 'color: black;'&gt;16% of the world population,&lt;/span&gt;</span></span>
<span id="cb11-85"><a aria-hidden="true" href="#cb11-85" tabindex="-1"></a><span className="st">   but create </span></span>
<span id="cb11-86"><a aria-hidden="true" href="#cb11-86" tabindex="-1"></a><span className="st">   &lt;span style = 'color: firebrick;'&gt;*62% of CO2 emissions*&lt;/span&gt; from air travel.</span><span className="sc">\n</span><span className="st">"</span>)</span>
<span id="cb11-87"><a aria-hidden="true" href="#cb11-87" tabindex="-1"></a></span><br/>
<span id="cb11-88"><a aria-hidden="true" href="#cb11-88" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span></span>
<span id="cb11-89"><a aria-hidden="true" href="#cb11-89" tabindex="-1"></a>  <span className="fu">ggtitle</span>(plot.title) <span className="sc">+</span></span>
<span id="cb11-90"><a aria-hidden="true" href="#cb11-90" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb11-91"><a aria-hidden="true" href="#cb11-91" tabindex="-1"></a>    <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"steelblue2"</span>,</span>
<span id="cb11-92"><a aria-hidden="true" href="#cb11-92" tabindex="-1"></a>    <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>), <span className="at">size =</span> <span className="dv">14</span>))</span>
<span id="cb11-93"><a aria-hidden="true" href="#cb11-93" tabindex="-1"></a>p7</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Bars are among the most concise and telling elements in data visualization. When arranged in order, they can be a powerful tool for story-telling. The following <Link to="../ggplot2-bar-sleep"><strong>ordered bar plot</strong></Link> vividly highlights the wide range of animals’ sleeping time, and its relationship with body size.</p>
<p><Link to="../ggplot2-bar-sleep">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Barplots are the building blocks of the <Link to="../ggplot2-population-pyramid"><strong>population pyramid</strong></Link>, a frequently used tool in demographics to illustrate the age and gender distribution of a population.</p>
<p><Link to="../ggplot2-population-pyramid">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_theme_completed.png" />
  </picture>
</Link></p>
<p>And check this awesome article to <Link to="../ggplot2-population-pyramid-animation"><strong>render the pyramids to animation</strong></Link>!</p>
<p><Link to="../ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
</main>
</div>
</div>
)}