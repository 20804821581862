import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarPolarCoordHorsepower from '../graphics/horsepower_theme_completed.png'; 
import imgGgplot2BarPolarCoordHorsepowerWebp from '../graphics/horsepower_theme_completed.webp'; 
export default function Ggplot2BarPolarCoordHorsepower(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Barplot in Polar Coordinate with ggplot2 to Visualize Cars Engine Power</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarPolarCoordHorsepowerWebp} />
    <img className="cover-img" src={imgGgplot2BarPolarCoordHorsepower} />
  </picture>

<p>In this article, we’ll visualize the automobiles’ horsepower using an ordered barplot in a polar coordinate. <strong>Major technical highlights in this article include:</strong></p>
<ul>
<li><a href="#reorder_bars">Reorder the bar plot.</a></li>
<li><a href="#clip_off">Transform a linear coordinate into a polar coordinate.</a></li>
<li><a href="#clip_off">Display elements that overflow beyond the plot boundary.</a></li>
<li><a href="#text_position_polar_coordinate">Adjust text positions in the polar coordinate.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="data-cleanup">
<h3 className="anchored" data-anchor-id="data-cleanup">Data cleanup</h3>
<p>In this visualization, we’ll use the <code>mtcars</code> dataset which is built in base R.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">colour =</span> <span className="st">"snow3"</span>)))</span></code></pre></div>
</div>
<p><span id="reorder_bars"><strong>Prepare the dataset to generate an ordered barplot:</strong></span></p>
<ul>
<li><p><strong>Turn the numeric variable <code>cyl</code> (cylinder number ) to a categorical variable.</strong> This way, when <code>cyl</code> is mapped to <code>color</code>, the cylinder number (4, 6, or 8) will be appropriately displayed as distinct colors on a <em>discrete</em> color scale (legend), instead of on a less readable continuous (gradient) color scale (color bar).</p></li>
<li><p><strong>Reorder the barplot by horsepower (<code>hp</code>).</strong> Here we first rearrange the rows by <code>hp</code>, and turn the <code>car</code> variable to a factor with levels (car names) following the current row order. More details on graphic elements reordering in ggplot2 can be found in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide</Link>.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>mtcars2 <span className="ot">&lt;-</span> mtcars <span className="sc">%&gt;%</span>  <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">rownames</span>(mtcars), <span className="at">cyl =</span> <span className="fu">factor</span>(cyl)) <span className="sc">%&gt;%</span> </span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="co"># reorder cars by horsepower</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="fu">arrange</span>(hp) <span className="sc">%&gt;%</span></span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">factor</span>(car, <span className="at">levels =</span> car)) </span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a></span><br/>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a><span className="fu">head</span>(mtcars2, <span className="dv">4</span>) <span className="co"># ready for visualization</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 12
<br/>    mpg cyl    disp    hp  drat    wt  qsec    vs    am  gear  carb car         
<br/>  &lt;dbl&gt; &lt;fct&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;fct&gt;       
<br/>1  30.4 4      75.7    52  4.93  1.62  18.5     1     1     4     2 Honda Civic 
<br/>2  24.4 4     147.     62  3.69  3.19  20       1     0     4     2 Merc 240D   
<br/>3  33.9 4      71.1    65  4.22  1.84  19.9     1     1     4     1 Toyota Coro…
<br/>4  32.4 4      78.7    66  4.08  2.2   19.5     1     1     4     1 Fiat 128    </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>Create an ordered bar plot.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> mtcars2 <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> car, <span className="at">y =</span> hp, <span className="at">fill =</span> cyl)) <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">geom_col</span>()</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_barplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_barplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="clip_off"><strong>Transform the linear coordinate to a polar coordinate.</strong></span></p>
<ul>
<li><p><code>theta = "x"</code> (default) maps the <code>x</code> aesthetic from linear coordinate to angles in the polar coordinate. In contrast, <code>theta = "y"</code> maps the <code>y</code> aesthetic to angles, and is used to create pie and donut charts.</p></li>
<li><p>Use <code>clip = "off"</code> to display graphical elements that extend beyond the plot boundary (often done together with plot margin adjustment; see more in the step of creating plot <a href="#plot_margin"><code>p5</code></a>).</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"x"</span>, <span className="at">clip =</span> <span className="st">"off"</span>)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_polar_coordinate.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_polar_coordinate"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="text_position_polar_coordinate">We aim to label the car names in line with the orientation of associated bars. To do this, first we need to <strong>calculate the angles by which the texts need to be rotated</strong>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(mtcars2)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>angle_perSlice <span className="ot">=</span> <span className="dv">360</span> <span className="sc">/</span> n</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a></span><br/>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a><span className="co"># degrees of angle to rotate per bar / text</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>angle_perSlice</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 11.25</code></pre>
</div>
</div>
<p>Create a <strong>vector of angles of successive rotations for car names</strong>. The rotation starts from the first bar (i.e., the most left in linear coordinate) - Honda Civic, with an initial angle of 81° (by visual estimate of <code>p2</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>angles_to_rotate <span className="ot">&lt;-</span> <span className="dv">81</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span>(n<span className="dv">-1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>angles_to_rotate</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"> [1]   81.00   69.75   58.50   47.25   36.00   24.75   13.50    2.25   -9.00
<br/>[10]  -20.25  -31.50  -42.75  -54.00  -65.25  -76.50  -87.75  -99.00 -110.25
<br/>[19] -121.50 -132.75 -144.00 -155.25 -166.50 -177.75 -189.00 -200.25 -211.50
<br/>[28] -222.75 -234.00 -245.25 -256.50 -267.75</code></pre>
</div>
</div>
<p><strong>Add labels of car names.</strong> The x-axis from a linear coordinate collapses into the central point in a polar coordinate. Accordingly, mapped with <code>x = car</code> (inherited from the <code>ggplot</code> line), all texts are headed towards the center of the circle. In addition, the texts are left aligned (by <code>hjust = 0</code>) to the <code>y</code> position that is 40 units above the height of the lollipop (<code>y = hp + 40</code>), with respective rotations specified in <code>angles_to_rotate</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate,</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_angle.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_angle"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>In the above plot, texts on the right half are nicely rotated, but those on the left half are turned upside down, and are difficult to read. The following edit aims to <strong>readjust the rotation angles of texts on the left side</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># same text angles for the first 17 cars (right half) from Honda civic to Merc 280C</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>angles1 <span className="ot">&lt;-</span> angles_to_rotate[<span className="dv">1</span><span className="sc">:</span><span className="dv">17</span>] </span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a></span><br/>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="co"># update text angles for the left half cars from Dodge to Maserati</span></span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>angles2 <span className="ot">&lt;-</span> <span className="dv">71</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span> (n <span className="sc">-</span> <span className="dv">17</span> <span className="sc">-</span><span className="dv">1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a></span><br/>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a><span className="co"># an updated angle vector</span></span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>angles_to_rotate_new <span className="ot">&lt;-</span> <span className="fu">c</span>(angles1, angles2)</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a></span><br/>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_new_angles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_new_angles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Now the text rotation angle are well adjusted. However, as all texts are left aligned to <code>y = hp + 10</code>, the left-half texts are shifted towards the right, and mostly overlapped with the bars. To fix this, we’ll instead <strong><em>right</em>-justify texts on the left side</strong>, while keep the left justification for texts on the right side.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="co"># trial 3: fix the alignment</span></span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_justification.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_justification"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Much better - now <strong>add a few more touch on the texts font and size</strong>. In particular, we’ll use smaller font sizes of 2 ~ 2.5 for the first 5 cars (of smaller horsepower) that occupy a more jammed space, and a larger size 3 for the other texts. Note that the last (largest) bar’s text label “Maserati Bora” is not displayed completely. We’ll fix it at the last step.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>            <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ),</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>            <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">2</span>, <span className="fl">2.2</span>, <span className="fl">2.3</span>, <span className="fl">2.5</span>, <span className="fu">rep</span>(<span className="dv">3</span>, n <span className="sc">-</span> <span className="dv">5</span>)), </span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_text_font.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_text_font"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Add plot title at the circle center, and update color scale.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="dv">0</span>,  </span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Turbine </span><span className="sc">\n</span><span className="st">Power"</span>, <span className="at">size =</span> <span className="dv">4</span>, </span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"white"</span>) <span className="sc">+</span></span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"steelblue"</span>, <span className="st">"brown"</span>))</span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_title_color_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_title_color_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="plot_margin"><strong>A touch on the theme:</strong></span></p>
<ul>
<li>Apply the theme of void to make a clean background.</li>
<li>Relocate the legend to the top right corner (using relative coordinates of a <em>linear</em> coordinate).</li>
<li>Increase the margin at the top of the plot which, together with the prior <a href="#clip_off"><code>clip = "off"</code></a>, displays completely the “Maserati” text label.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">7</span>, .<span className="dv">93</span>),</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">30</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)) </span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/horsepower_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="co"># packages</span></span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a></span><br/>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb16-7"><a aria-hidden="true" href="#cb16-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb16-8"><a aria-hidden="true" href="#cb16-8" tabindex="-1"></a>  <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">colour =</span> <span className="st">"snow3"</span>)))</span>
<span id="cb16-9"><a aria-hidden="true" href="#cb16-9" tabindex="-1"></a></span><br/>
<span id="cb16-10"><a aria-hidden="true" href="#cb16-10" tabindex="-1"></a><span className="co"># data cleanup</span></span>
<span id="cb16-11"><a aria-hidden="true" href="#cb16-11" tabindex="-1"></a>mtcars2 <span className="ot">&lt;-</span> mtcars <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb16-12"><a aria-hidden="true" href="#cb16-12" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">rownames</span>(mtcars), <span className="at">cyl =</span> <span className="fu">factor</span>(cyl)) <span className="sc">%&gt;%</span> </span>
<span id="cb16-13"><a aria-hidden="true" href="#cb16-13" tabindex="-1"></a>  <span className="co"># reorder cars by horsepower</span></span>
<span id="cb16-14"><a aria-hidden="true" href="#cb16-14" tabindex="-1"></a>  <span className="fu">arrange</span>(hp) <span className="sc">%&gt;%</span></span>
<span id="cb16-15"><a aria-hidden="true" href="#cb16-15" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">car =</span> <span className="fu">factor</span>(car, <span className="at">levels =</span> car)) </span>
<span id="cb16-16"><a aria-hidden="true" href="#cb16-16" tabindex="-1"></a></span><br/>
<span id="cb16-17"><a aria-hidden="true" href="#cb16-17" tabindex="-1"></a><span className="fu">head</span>(mtcars2, <span className="dv">4</span>) <span className="co"># ready for visualization</span></span>
<span id="cb16-18"><a aria-hidden="true" href="#cb16-18" tabindex="-1"></a></span><br/>
<span id="cb16-19"><a aria-hidden="true" href="#cb16-19" tabindex="-1"></a></span><br/>
<span id="cb16-20"><a aria-hidden="true" href="#cb16-20" tabindex="-1"></a><span className="co"># Create an ordered bar plot.</span></span>
<span id="cb16-21"><a aria-hidden="true" href="#cb16-21" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> mtcars2 <span className="sc">%&gt;%</span> </span>
<span id="cb16-22"><a aria-hidden="true" href="#cb16-22" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> car, <span className="at">y =</span> hp, <span className="at">fill =</span> cyl)) <span className="sc">+</span></span>
<span id="cb16-23"><a aria-hidden="true" href="#cb16-23" tabindex="-1"></a>  <span className="fu">geom_col</span>()</span>
<span id="cb16-24"><a aria-hidden="true" href="#cb16-24" tabindex="-1"></a>p1</span>
<span id="cb16-25"><a aria-hidden="true" href="#cb16-25" tabindex="-1"></a></span><br/>
<span id="cb16-26"><a aria-hidden="true" href="#cb16-26" tabindex="-1"></a></span><br/>
<span id="cb16-27"><a aria-hidden="true" href="#cb16-27" tabindex="-1"></a><span className="co"># Transform the linear coordinate to a polar coordinate.</span></span>
<span id="cb16-28"><a aria-hidden="true" href="#cb16-28" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">coord_polar</span>(<span className="at">clip =</span> <span className="st">"off"</span>)</span>
<span id="cb16-29"><a aria-hidden="true" href="#cb16-29" tabindex="-1"></a>p2</span>
<span id="cb16-30"><a aria-hidden="true" href="#cb16-30" tabindex="-1"></a></span><br/>
<span id="cb16-31"><a aria-hidden="true" href="#cb16-31" tabindex="-1"></a></span><br/>
<span id="cb16-32"><a aria-hidden="true" href="#cb16-32" tabindex="-1"></a><span className="co"># manually label car names in line with the orientation of associated bars</span></span>
<span id="cb16-33"><a aria-hidden="true" href="#cb16-33" tabindex="-1"></a><span className="co"># first calculate the angles by which the texts need to be rotated. </span></span>
<span id="cb16-34"><a aria-hidden="true" href="#cb16-34" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(mtcars2)</span>
<span id="cb16-35"><a aria-hidden="true" href="#cb16-35" tabindex="-1"></a>angle_perSlice <span className="ot">=</span> <span className="dv">360</span> <span className="sc">/</span> n</span>
<span id="cb16-36"><a aria-hidden="true" href="#cb16-36" tabindex="-1"></a></span><br/>
<span id="cb16-37"><a aria-hidden="true" href="#cb16-37" tabindex="-1"></a><span className="co"># degrees of angle to rotate per bar / text</span></span>
<span id="cb16-38"><a aria-hidden="true" href="#cb16-38" tabindex="-1"></a>angle_perSlice</span>
<span id="cb16-39"><a aria-hidden="true" href="#cb16-39" tabindex="-1"></a></span><br/>
<span id="cb16-40"><a aria-hidden="true" href="#cb16-40" tabindex="-1"></a><span className="co"># Create a vector of angles of successive rotations for all car names.</span></span>
<span id="cb16-41"><a aria-hidden="true" href="#cb16-41" tabindex="-1"></a>angles_to_rotate <span className="ot">&lt;-</span> <span className="dv">81</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span>(n<span className="dv">-1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb16-42"><a aria-hidden="true" href="#cb16-42" tabindex="-1"></a>angles_to_rotate</span>
<span id="cb16-43"><a aria-hidden="true" href="#cb16-43" tabindex="-1"></a></span><br/>
<span id="cb16-44"><a aria-hidden="true" href="#cb16-44" tabindex="-1"></a><span className="co"># Add labels of car names.</span></span>
<span id="cb16-45"><a aria-hidden="true" href="#cb16-45" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb16-46"><a aria-hidden="true" href="#cb16-46" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate,</span>
<span id="cb16-47"><a aria-hidden="true" href="#cb16-47" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span>
<span id="cb16-48"><a aria-hidden="true" href="#cb16-48" tabindex="-1"></a></span><br/>
<span id="cb16-49"><a aria-hidden="true" href="#cb16-49" tabindex="-1"></a></span><br/>
<span id="cb16-50"><a aria-hidden="true" href="#cb16-50" tabindex="-1"></a><span className="co"># Readjust the rotation angles of texts on the left side. </span></span>
<span id="cb16-51"><a aria-hidden="true" href="#cb16-51" tabindex="-1"></a><span className="co"># keep the same text angles for the first 17 cars (right half) from Honda civic to Merc 280C</span></span>
<span id="cb16-52"><a aria-hidden="true" href="#cb16-52" tabindex="-1"></a>angles1 <span className="ot">&lt;-</span> angles_to_rotate[<span className="dv">1</span><span className="sc">:</span><span className="dv">17</span>] </span>
<span id="cb16-53"><a aria-hidden="true" href="#cb16-53" tabindex="-1"></a><span className="co"># update text angles for the left half cars from Dodge to Maserati</span></span>
<span id="cb16-54"><a aria-hidden="true" href="#cb16-54" tabindex="-1"></a>angles2 <span className="ot">&lt;-</span> <span className="dv">71</span> <span className="sc">-</span> (<span className="dv">0</span><span className="sc">:</span> (n <span className="sc">-</span> <span className="dv">17</span> <span className="sc">-</span><span className="dv">1</span>)) <span className="sc">*</span> angle_perSlice</span>
<span id="cb16-55"><a aria-hidden="true" href="#cb16-55" tabindex="-1"></a><span className="co"># an updated angle vector</span></span>
<span id="cb16-56"><a aria-hidden="true" href="#cb16-56" tabindex="-1"></a>angles_to_rotate_new <span className="ot">&lt;-</span> <span className="fu">c</span>(angles1, angles2)</span>
<span id="cb16-57"><a aria-hidden="true" href="#cb16-57" tabindex="-1"></a></span><br/>
<span id="cb16-58"><a aria-hidden="true" href="#cb16-58" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb16-59"><a aria-hidden="true" href="#cb16-59" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb16-60"><a aria-hidden="true" href="#cb16-60" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="dv">0</span>)</span>
<span id="cb16-61"><a aria-hidden="true" href="#cb16-61" tabindex="-1"></a></span><br/>
<span id="cb16-62"><a aria-hidden="true" href="#cb16-62" tabindex="-1"></a></span><br/>
<span id="cb16-63"><a aria-hidden="true" href="#cb16-63" tabindex="-1"></a><span className="co"># Update text justification:</span></span>
<span id="cb16-64"><a aria-hidden="true" href="#cb16-64" tabindex="-1"></a><span className="do">## Right-justify texts instead on the left side of plot;</span></span>
<span id="cb16-65"><a aria-hidden="true" href="#cb16-65" tabindex="-1"></a><span className="do">## and keep the same left justification for texts on the right side. </span></span>
<span id="cb16-66"><a aria-hidden="true" href="#cb16-66" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb16-67"><a aria-hidden="true" href="#cb16-67" tabindex="-1"></a>               <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb16-68"><a aria-hidden="true" href="#cb16-68" tabindex="-1"></a>               <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ))</span>
<span id="cb16-69"><a aria-hidden="true" href="#cb16-69" tabindex="-1"></a></span><br/>
<span id="cb16-70"><a aria-hidden="true" href="#cb16-70" tabindex="-1"></a></span><br/>
<span id="cb16-71"><a aria-hidden="true" href="#cb16-71" tabindex="-1"></a><span className="co"># Update the labels size and font.  </span></span>
<span id="cb16-72"><a aria-hidden="true" href="#cb16-72" tabindex="-1"></a><span className="co"># use smaller font for the first 5 cars that occupy a very small space</span></span>
<span id="cb16-73"><a aria-hidden="true" href="#cb16-73" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb16-74"><a aria-hidden="true" href="#cb16-74" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> car, <span className="at">y =</span> hp <span className="sc">+</span> <span className="dv">10</span>), </span>
<span id="cb16-75"><a aria-hidden="true" href="#cb16-75" tabindex="-1"></a>            <span className="at">angle =</span> angles_to_rotate_new,</span>
<span id="cb16-76"><a aria-hidden="true" href="#cb16-76" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="fu">c</span>(<span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">17</span>), <span className="fu">rep</span>(<span className="dv">1</span>, n <span className="sc">-</span> <span className="dv">17</span>) ),</span>
<span id="cb16-77"><a aria-hidden="true" href="#cb16-77" tabindex="-1"></a>            <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">2</span>, <span className="fl">2.2</span>, <span className="fl">2.3</span>, <span className="fl">2.5</span>, <span className="fu">rep</span>(<span className="dv">3</span>, n <span className="sc">-</span> <span className="dv">5</span>)), </span>
<span id="cb16-78"><a aria-hidden="true" href="#cb16-78" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb16-79"><a aria-hidden="true" href="#cb16-79" tabindex="-1"></a>p3</span>
<span id="cb16-80"><a aria-hidden="true" href="#cb16-80" tabindex="-1"></a></span><br/>
<span id="cb16-81"><a aria-hidden="true" href="#cb16-81" tabindex="-1"></a></span><br/>
<span id="cb16-82"><a aria-hidden="true" href="#cb16-82" tabindex="-1"></a><span className="co"># Add plot title at the circle center, and update color scale. </span></span>
<span id="cb16-83"><a aria-hidden="true" href="#cb16-83" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb16-84"><a aria-hidden="true" href="#cb16-84" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">0</span>, <span className="at">y =</span> <span className="dv">0</span>,  </span>
<span id="cb16-85"><a aria-hidden="true" href="#cb16-85" tabindex="-1"></a>           <span className="at">label =</span> <span className="st">"Turbine </span><span className="sc">\n</span><span className="st">Power"</span>, <span className="at">size =</span> <span className="dv">4</span>, </span>
<span id="cb16-86"><a aria-hidden="true" href="#cb16-86" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"white"</span>) <span className="sc">+</span></span>
<span id="cb16-87"><a aria-hidden="true" href="#cb16-87" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"steelblue"</span>, <span className="st">"brown"</span>))</span>
<span id="cb16-88"><a aria-hidden="true" href="#cb16-88" tabindex="-1"></a>p4</span>
<span id="cb16-89"><a aria-hidden="true" href="#cb16-89" tabindex="-1"></a></span><br/>
<span id="cb16-90"><a aria-hidden="true" href="#cb16-90" tabindex="-1"></a></span><br/>
<span id="cb16-91"><a aria-hidden="true" href="#cb16-91" tabindex="-1"></a><span className="co"># Relocate the legend to the top right corner. </span></span>
<span id="cb16-92"><a aria-hidden="true" href="#cb16-92" tabindex="-1"></a><span className="co"># Increase the margin at the top of the plot</span></span>
<span id="cb16-93"><a aria-hidden="true" href="#cb16-93" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb16-94"><a aria-hidden="true" href="#cb16-94" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">7</span>, .<span className="dv">93</span>),</span>
<span id="cb16-95"><a aria-hidden="true" href="#cb16-95" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">30</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)) </span>
<span id="cb16-96"><a aria-hidden="true" href="#cb16-96" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In the following article, we’ll update the above barplot into a <Link to="../ggplot2-lollipop-donut-horsepower"><strong>donut-lollipop with color and font-enriched plot title</strong></Link>.</p>
<p><Link to="../ggplot2-lollipop-donut-horsepower">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>All the plots above are generated by converting the <strong><code>x</code></strong> aesthetic from a linear coordinate into the angles within a polar coordinate. <strong>More common in practice, however, the <code>y</code> aesthetic is mapped into angles to make “authentic” pie and donut charts to visualize the “proportion of a whole”</strong>. Check the following <Link to="../ggplot2-donut-GDP"><strong>exploded donut plots in faceted panels</strong></Link> that visualize the contribution of the top 4 largest GDP makers to the total GDP in each continent.</p>
<p><Link to="../ggplot2-donut-GDP">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}