import useCustomEffect from '../../useCustomEffect'; 
export default function SparkLandingPage() { 
    useCustomEffect()
    return (
        <div>
            <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
                <div class="jp-Cell-inputWrapper">
                    <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser"></div>
                    <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt"></div>
                    <div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
                    <h1 class="posts-heading mg-b-30">Welcome to the PySpark Tutorial</h1>
            {/* <h4 className="sub-title">Unlock the Power of Apache Spark for Big Data Processing and Analytics</h4>                 */}
           
         
            {/* <p>Whether you're a experienced data engineer or just beginning your journey in big data, our Spark tutorial is here to guide you through the essentials.</p> */}
            <div className='mg-t-25'>
                <p>
                PySpark is a powerful tool for big data processing due to its combination of Apache Spark's distributed computing capabilities, in-memory computation, and ease of use with Python. It offers a rich set of APIs for data manipulation, machine learning, and stream processing, while also integrating seamlessly with the Hadoop ecosystem. </p>
                <p>
                This tutorial teaches essential concepts and modules of PySpark for data wrangling and analytics, from the basics to advanced topics.
                </p>   
                <p>Start learning now and discover the powerful capabilities of PySpark.</p>
            </div>
           
            </div>
            </div>
        </div>
     </div>      
</div>
       
        
    )
}