import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarsMidwestCounty from '../graphics/bars_counties_theme_completed.png'; 
import imgGgplot2BarsMidwestCountyWebp from '../graphics/bars_counties_theme_completed.webp'; 
export default function Ggplot2BarsMidwestCounty(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Ordered and Stacked Bar Plots in ggplot2 to Visualize U.S. Midwest Demographics</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarsMidwestCountyWebp} />
    <img className="cover-img" src={imgGgplot2BarsMidwestCounty} />
  </picture>

<p>In this article, we’ll create a bar plot to visualize demographic data of the U.S. midwest counties in 2000, highlighting the relationship between poverty rate and education level. <strong>Major techniques covered in this work include:</strong></p>
<ul>
<li><a href="#reorder_bar">Reorder the bar plot.</a></li>
<li><a href="#symmetrical">Create bar plots in a “symmetrical” layout.</a></li>
<li><a href="#synchronized_position">Generate regression lines on stacked bar plot with synchronized position.</a></li>
<li><a href="#theme">Customize the theme.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>We’ll use the <code>midwest</code> dataset that is built in the base R.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set up global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">15</span>))</span></code></pre></div>
</div>
<p><span id="reorder_bar"><strong>Reorder the barplot.</strong> Arrange counties (<code>PID</code>) in order of the percentage of the population living under the poverty line (<code>percbelowpoverty</code>), and convert <code>PID</code> into a factor to “memorise” this order. This ensures that the counties will be visualized in such order when rendered in the graphics. (Check this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide</Link> on how to reorder graphic elements in ggplot2)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>m.reordered <span className="ot">&lt;-</span> midwest <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">arrange</span>(percbelowpoverty) <span className="sc">%&gt;%</span> </span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">PID =</span> <span className="fu">factor</span>(PID, <span className="at">levels =</span> PID))</span></code></pre></div>
</div>
<p><strong>Tidy up the dataset.</strong> Select four variables to be plotted: the county ID (<code>PID</code>), percentage of population with high school degree (<code>perchsd</code>) and college degree (<code>percollege</code>), and the percentage living in poverty (<code>percbelowpoverty</code>). Transform the dataset into a <Link to="https://cran.r-project.org/web/packages/tidyr/vignettes/tidy-data.html">tidy structure</Link>, such that the three democratic variables names are in one column <code>education_poverty</code>, and the associated proportion are in another column <code>percent</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>m.tidy <span className="ot">&lt;-</span> m.reordered <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">select</span>(PID, perchsd, percollege, percbelowpoverty) <span className="sc">%&gt;%</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># tidy up</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="sc">-</span>PID, </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"education_poverty"</span>, </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"percent"</span>)</span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a></span><br/>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a><span className="fu">head</span>(m.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  PID   education_poverty percent
<br/>  &lt;fct&gt; &lt;chr&gt;               &lt;dbl&gt;
<br/>1 3026  perchsd             86.9 
<br/>2 3026  percollege          37.4 
<br/>3 3026  percbelowpoverty     2.18</code></pre>
</div>
</div>
<p><span id="symmetrical"><strong>Create bars in “symmetrical” layout</strong>. Turn the poverty data to negative values. This way, the poverty data will be displayed on the negative range of the y-axis, while the education data on the positive range of the y-axis (as illustrated in <a href="#p1"><code>p1</code></a>). The same technique is also used to create <Link to="../ggplot2-population-pyramid">population pyramids</Link>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># turn poverty data to negative values</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>m.signed <span className="ot">&lt;-</span> m.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">percent =</span> <span className="fu">ifelse</span>(</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    education_poverty <span className="sc">==</span> <span className="st">"percbelowpoverty"</span>, <span className="sc">-</span>percent, percent)) </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a></span><br/>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a><span className="fu">head</span>(m.signed, <span className="dv">3</span>) <span className="co"># ready for visualization</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  PID   education_poverty percent
<br/>  &lt;fct&gt; &lt;chr&gt;               &lt;dbl&gt;
<br/>1 3026  perchsd             86.9 
<br/>2 3026  percollege          37.4 
<br/>3 3026  percbelowpoverty    -2.18</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="p1">For each county (<code>PID</code>), create a bar to display the population percent living below the poverty line in the negative range of the y-axis, and percent with high school or college education in the positive range of y-axis. (Due to the large number of counties, the bars are squeezed into line segments.)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> m.signed <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> PID, <span className="at">y =</span> percent, <span className="at">fill =</span> education_poverty)) <span className="sc">+</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">alpha =</span> .<span className="dv">6</span>) <span className="sc">+</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="co"># flip the plot</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_counties_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_counties_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add a central vertical line at y = 0 (note that the coordinate is flipped), separating the data associated with the poverty and education. Relabel both halves of the y-axis with positive numbers.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>, <span className="at">linewidth =</span> <span className="fl">1.5</span>) <span className="sc">+</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">40</span>, <span className="dv">120</span>, <span className="dv">20</span>),</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">ifelse</span>(x <span className="sc">&lt;</span> <span className="dv">0</span>, <span className="sc">-</span>x, x)&#125;)</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_counties_dividor.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_counties_dividor"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="synchronized_position">Add simple linear regression lines to outline the changing trend of education across counties that have been arranged in descending order of poverty.</span></p>
<ul>
<li>use the <code>group</code> aesthetic to specify the subset of data that should be regressed.</li>
<li>use position in <code>"stack"</code> to synchronize the regression line with the bars</li>
<li>use the <em>manual</em> color &amp; fill scale to keep the bars and lines consistent in color</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2<span className="sc">+</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">geom_smooth</span>(</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>    <span className="co"># use a data subset NOT containing the poverty data</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>    <span className="at">data =</span> <span className="fu">filter</span>(m.signed, education_poverty <span className="sc">!=</span> <span className="st">"percbelowpoverty"</span>),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> education_poverty, </span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>        <span className="at">color =</span> education_poverty), </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>    </span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>    <span className="at">method =</span> <span className="st">"lm"</span>, <span className="co"># linear model</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>    <span className="at">se =</span> F, <span className="co"># not show confidence ribbon</span></span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>    <span className="at">position =</span> <span className="st">"stack"</span>, <span className="co"># align with bars in position</span></span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>    <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  </span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>  <span className="co"># make the color of bars and regression lines consistent</span></span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"steelblue2"</span>, <span className="st">"tomato"</span>)) <span className="sc">+</span> </span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"snow3"</span>, <span className="st">"steelblue1"</span>, <span className="st">"tomato"</span>)) </span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_counties_regression.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_counties_regression"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Label the bars with the categories (poverty and education degree) they are associated with.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="at">Y =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">43</span>, <span className="dv">25</span>, <span className="dv">105</span>), </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="at">X =</span> <span className="fu">rep</span>(<span className="dv">100</span>, <span className="dv">3</span>),</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="at">status =</span> <span className="fu">c</span>(<span className="st">"living</span><span className="sc">\n</span><span className="st">in poverty"</span>, </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>             <span className="st">"college</span><span className="sc">\n</span><span className="st">degree"</span>, </span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>             <span className="st">"high</span><span className="sc">\n</span><span className="st">school</span><span className="sc">\n</span><span className="st">degree"</span>))</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a></span><br/>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>  <span className="at">data =</span> a,</span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">x =</span> X, <span className="at">y =</span> Y, <span className="at">label =</span> status), </span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>  <span className="at">inherit.aes =</span> F,</span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>,</span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a>  <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"tomato"</span>, <span className="st">"steelblue4"</span>)) </span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a></span><br/>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_counties_labels.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_counties_labels"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="theme">Add axial and plot titles, and fine tune the theme.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>    <span className="at">x =</span> <span className="st">"Counties"</span>, </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>    <span className="at">y =</span> <span className="st">"Percent of population in the county"</span>,</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"U.S. midwest demographics in 2000"</span>,</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="st">"Better education is strongly associated with the decrease of poverty"</span>) <span className="sc">+</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>  </span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>    <span className="at">axis.ticks =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>    <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>    <span className="at">axis.line.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">1</span>),</span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">15</span>)),</span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> <span className="dv">1</span>),</span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a>    </span>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>))</span>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a>  ) </span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_counties_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/bars_counties_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot. By default, the most recently displayed plot will be saved. Here we save the plot to the “graphics” folder, which is in the same folder as the source code.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"bars education vs poverty.pdf"</span>,</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,  <span className="co"># a relative path</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">7</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">15</span>))</span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a></span><br/>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a><span className="co"># Arrange counties (`PID`) in order of poverty percent.</span></span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>m.reordered <span className="ot">&lt;-</span> midwest <span className="sc">%&gt;%</span> </span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>  <span className="fu">arrange</span>(percbelowpoverty) <span className="sc">%&gt;%</span> </span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">PID =</span> <span className="fu">factor</span>(PID, <span className="at">levels =</span> PID))</span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a></span><br/>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a><span className="co"># Select useful variables, and convert data to tidy structure. </span></span>
<span id="cb13-13"><a aria-hidden="true" href="#cb13-13" tabindex="-1"></a>m.tidy <span className="ot">&lt;-</span> m.reordered <span className="sc">%&gt;%</span> </span>
<span id="cb13-14"><a aria-hidden="true" href="#cb13-14" tabindex="-1"></a>  <span className="fu">select</span>(PID, perchsd, percollege, percbelowpoverty) <span className="sc">%&gt;%</span> </span>
<span id="cb13-15"><a aria-hidden="true" href="#cb13-15" tabindex="-1"></a>  <span className="co"># tidy up</span></span>
<span id="cb13-16"><a aria-hidden="true" href="#cb13-16" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb13-17"><a aria-hidden="true" href="#cb13-17" tabindex="-1"></a>    <span className="sc">-</span>PID, </span>
<span id="cb13-18"><a aria-hidden="true" href="#cb13-18" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"education_poverty"</span>, </span>
<span id="cb13-19"><a aria-hidden="true" href="#cb13-19" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"percent"</span>)</span>
<span id="cb13-20"><a aria-hidden="true" href="#cb13-20" tabindex="-1"></a></span><br/>
<span id="cb13-21"><a aria-hidden="true" href="#cb13-21" tabindex="-1"></a><span className="fu">head</span>(m.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb13-22"><a aria-hidden="true" href="#cb13-22" tabindex="-1"></a></span><br/>
<span id="cb13-23"><a aria-hidden="true" href="#cb13-23" tabindex="-1"></a><span className="co"># turn the poverty percent to negative values.</span></span>
<span id="cb13-24"><a aria-hidden="true" href="#cb13-24" tabindex="-1"></a>m.signed <span className="ot">&lt;-</span> m.tidy <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(</span>
<span id="cb13-25"><a aria-hidden="true" href="#cb13-25" tabindex="-1"></a>  <span className="at">percent =</span> <span className="fu">ifelse</span>(education_poverty <span className="sc">==</span> <span className="st">"percbelowpoverty"</span>, <span className="sc">-</span>percent, percent)) </span>
<span id="cb13-26"><a aria-hidden="true" href="#cb13-26" tabindex="-1"></a></span><br/>
<span id="cb13-27"><a aria-hidden="true" href="#cb13-27" tabindex="-1"></a><span className="fu">head</span>(m.signed, <span className="dv">3</span>) <span className="co"># ready for visualization</span></span>
<span id="cb13-28"><a aria-hidden="true" href="#cb13-28" tabindex="-1"></a></span><br/>
<span id="cb13-29"><a aria-hidden="true" href="#cb13-29" tabindex="-1"></a></span><br/>
<span id="cb13-30"><a aria-hidden="true" href="#cb13-30" tabindex="-1"></a><span className="do">### Visualization</span></span>
<span id="cb13-31"><a aria-hidden="true" href="#cb13-31" tabindex="-1"></a></span><br/>
<span id="cb13-32"><a aria-hidden="true" href="#cb13-32" tabindex="-1"></a><span className="co"># Create a bar plot.</span></span>
<span id="cb13-33"><a aria-hidden="true" href="#cb13-33" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> m.signed <span className="sc">%&gt;%</span> </span>
<span id="cb13-34"><a aria-hidden="true" href="#cb13-34" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> PID, <span className="at">y =</span> percent, <span className="at">fill =</span> education_poverty)) <span className="sc">+</span></span>
<span id="cb13-35"><a aria-hidden="true" href="#cb13-35" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">alpha =</span> .<span className="dv">6</span>) <span className="sc">+</span></span>
<span id="cb13-36"><a aria-hidden="true" href="#cb13-36" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="co"># flip the plot</span></span>
<span id="cb13-37"><a aria-hidden="true" href="#cb13-37" tabindex="-1"></a>p1</span>
<span id="cb13-38"><a aria-hidden="true" href="#cb13-38" tabindex="-1"></a></span><br/>
<span id="cb13-39"><a aria-hidden="true" href="#cb13-39" tabindex="-1"></a></span><br/>
<span id="cb13-40"><a aria-hidden="true" href="#cb13-40" tabindex="-1"></a><span className="co"># Add a central vertical line </span></span>
<span id="cb13-41"><a aria-hidden="true" href="#cb13-41" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb13-42"><a aria-hidden="true" href="#cb13-42" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>, <span className="at">linewidth =</span> <span className="fl">1.5</span>) <span className="sc">+</span></span>
<span id="cb13-43"><a aria-hidden="true" href="#cb13-43" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb13-44"><a aria-hidden="true" href="#cb13-44" tabindex="-1"></a>    <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">40</span>, <span className="dv">120</span>, <span className="dv">20</span>),</span>
<span id="cb13-45"><a aria-hidden="true" href="#cb13-45" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">ifelse</span>(x <span className="sc">&lt;</span> <span className="dv">0</span>, <span className="sc">-</span>x, x)&#125;)</span>
<span id="cb13-46"><a aria-hidden="true" href="#cb13-46" tabindex="-1"></a>p2</span>
<span id="cb13-47"><a aria-hidden="true" href="#cb13-47" tabindex="-1"></a></span><br/>
<span id="cb13-48"><a aria-hidden="true" href="#cb13-48" tabindex="-1"></a></span><br/>
<span id="cb13-49"><a aria-hidden="true" href="#cb13-49" tabindex="-1"></a><span className="co"># Add linear regression to outline the changing trend of education.</span></span>
<span id="cb13-50"><a aria-hidden="true" href="#cb13-50" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2<span className="sc">+</span></span>
<span id="cb13-51"><a aria-hidden="true" href="#cb13-51" tabindex="-1"></a>  <span className="fu">geom_smooth</span>(</span>
<span id="cb13-52"><a aria-hidden="true" href="#cb13-52" tabindex="-1"></a>    <span className="co"># use a data subset NOT containing the poverty data</span></span>
<span id="cb13-53"><a aria-hidden="true" href="#cb13-53" tabindex="-1"></a>    <span className="at">data =</span> <span className="fu">filter</span>(m.signed, education_poverty <span className="sc">!=</span> <span className="st">"percbelowpoverty"</span>),</span>
<span id="cb13-54"><a aria-hidden="true" href="#cb13-54" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> education_poverty, </span>
<span id="cb13-55"><a aria-hidden="true" href="#cb13-55" tabindex="-1"></a>        <span className="at">color =</span> education_poverty), </span>
<span id="cb13-56"><a aria-hidden="true" href="#cb13-56" tabindex="-1"></a>    </span>
<span id="cb13-57"><a aria-hidden="true" href="#cb13-57" tabindex="-1"></a>    <span className="at">method =</span> <span className="st">"lm"</span>, <span className="co"># linear model</span></span>
<span id="cb13-58"><a aria-hidden="true" href="#cb13-58" tabindex="-1"></a>    <span className="at">se =</span> F, <span className="co"># not show confidence ribbon</span></span>
<span id="cb13-59"><a aria-hidden="true" href="#cb13-59" tabindex="-1"></a>    <span className="at">position =</span> <span className="st">"stack"</span>, <span className="co"># align with bars in position</span></span>
<span id="cb13-60"><a aria-hidden="true" href="#cb13-60" tabindex="-1"></a>    <span className="at">linetype =</span> <span className="st">"dashed"</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb13-61"><a aria-hidden="true" href="#cb13-61" tabindex="-1"></a>  </span>
<span id="cb13-62"><a aria-hidden="true" href="#cb13-62" tabindex="-1"></a>  <span className="co"># make the color of bars and regression lines consistent</span></span>
<span id="cb13-63"><a aria-hidden="true" href="#cb13-63" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"steelblue2"</span>, <span className="st">"tomato"</span>)) <span className="sc">+</span> </span>
<span id="cb13-64"><a aria-hidden="true" href="#cb13-64" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"snow3"</span>, <span className="st">"steelblue1"</span>, <span className="st">"tomato"</span>)) </span>
<span id="cb13-65"><a aria-hidden="true" href="#cb13-65" tabindex="-1"></a>p3</span>
<span id="cb13-66"><a aria-hidden="true" href="#cb13-66" tabindex="-1"></a></span><br/>
<span id="cb13-67"><a aria-hidden="true" href="#cb13-67" tabindex="-1"></a></span><br/>
<span id="cb13-68"><a aria-hidden="true" href="#cb13-68" tabindex="-1"></a><span className="co"># Label the bars with the categories they are associated with. </span></span>
<span id="cb13-69"><a aria-hidden="true" href="#cb13-69" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb13-70"><a aria-hidden="true" href="#cb13-70" tabindex="-1"></a>  <span className="at">Y =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">43</span>, <span className="dv">25</span>, <span className="dv">105</span>), </span>
<span id="cb13-71"><a aria-hidden="true" href="#cb13-71" tabindex="-1"></a>  <span className="at">X =</span> <span className="fu">rep</span>(<span className="dv">100</span>, <span className="dv">3</span>),</span>
<span id="cb13-72"><a aria-hidden="true" href="#cb13-72" tabindex="-1"></a>  <span className="at">status =</span> <span className="fu">c</span>(<span className="st">"living</span><span className="sc">\n</span><span className="st">in poverty"</span>, </span>
<span id="cb13-73"><a aria-hidden="true" href="#cb13-73" tabindex="-1"></a>             <span className="st">"college</span><span className="sc">\n</span><span className="st">degree"</span>, </span>
<span id="cb13-74"><a aria-hidden="true" href="#cb13-74" tabindex="-1"></a>             <span className="st">"high</span><span className="sc">\n</span><span className="st">school</span><span className="sc">\n</span><span className="st">degree"</span>))</span>
<span id="cb13-75"><a aria-hidden="true" href="#cb13-75" tabindex="-1"></a></span><br/>
<span id="cb13-76"><a aria-hidden="true" href="#cb13-76" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb13-77"><a aria-hidden="true" href="#cb13-77" tabindex="-1"></a>  <span className="at">data =</span> a,</span>
<span id="cb13-78"><a aria-hidden="true" href="#cb13-78" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">x =</span> X, <span className="at">y =</span> Y, <span className="at">label =</span> status), </span>
<span id="cb13-79"><a aria-hidden="true" href="#cb13-79" tabindex="-1"></a>  <span className="at">inherit.aes =</span> F,</span>
<span id="cb13-80"><a aria-hidden="true" href="#cb13-80" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>,</span>
<span id="cb13-81"><a aria-hidden="true" href="#cb13-81" tabindex="-1"></a>  <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"tomato"</span>, <span className="st">"steelblue4"</span>)) </span>
<span id="cb13-82"><a aria-hidden="true" href="#cb13-82" tabindex="-1"></a></span><br/>
<span id="cb13-83"><a aria-hidden="true" href="#cb13-83" tabindex="-1"></a>p4</span>
<span id="cb13-84"><a aria-hidden="true" href="#cb13-84" tabindex="-1"></a></span><br/>
<span id="cb13-85"><a aria-hidden="true" href="#cb13-85" tabindex="-1"></a></span><br/>
<span id="cb13-86"><a aria-hidden="true" href="#cb13-86" tabindex="-1"></a><span className="co"># Add axial and plot titles, and fine tune the theme. </span></span>
<span id="cb13-87"><a aria-hidden="true" href="#cb13-87" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb13-88"><a aria-hidden="true" href="#cb13-88" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb13-89"><a aria-hidden="true" href="#cb13-89" tabindex="-1"></a>    <span className="at">x =</span> <span className="st">"Counties"</span>, </span>
<span id="cb13-90"><a aria-hidden="true" href="#cb13-90" tabindex="-1"></a>    <span className="at">y =</span> <span className="st">"Percent of population in the county"</span>,</span>
<span id="cb13-91"><a aria-hidden="true" href="#cb13-91" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"US midwest demographics in 2000"</span>,</span>
<span id="cb13-92"><a aria-hidden="true" href="#cb13-92" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="st">"Better education is strongly associated with the decrease of poverty"</span>) <span className="sc">+</span></span>
<span id="cb13-93"><a aria-hidden="true" href="#cb13-93" tabindex="-1"></a>  </span>
<span id="cb13-94"><a aria-hidden="true" href="#cb13-94" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb13-95"><a aria-hidden="true" href="#cb13-95" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb13-96"><a aria-hidden="true" href="#cb13-96" tabindex="-1"></a>    <span className="at">axis.ticks =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-97"><a aria-hidden="true" href="#cb13-97" tabindex="-1"></a>    <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-98"><a aria-hidden="true" href="#cb13-98" tabindex="-1"></a>    <span className="at">axis.line.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb13-99"><a aria-hidden="true" href="#cb13-99" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">1</span>),</span>
<span id="cb13-100"><a aria-hidden="true" href="#cb13-100" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">15</span>)),</span>
<span id="cb13-101"><a aria-hidden="true" href="#cb13-101" tabindex="-1"></a>    <span className="at">axis.line.x =</span> <span className="fu">element_line</span>(<span className="at">linewidth =</span> <span className="dv">1</span>),</span>
<span id="cb13-102"><a aria-hidden="true" href="#cb13-102" tabindex="-1"></a>    </span>
<span id="cb13-103"><a aria-hidden="true" href="#cb13-103" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb13-104"><a aria-hidden="true" href="#cb13-104" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">1</span>, <span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>))</span>
<span id="cb13-105"><a aria-hidden="true" href="#cb13-105" tabindex="-1"></a>  ) </span>
<span id="cb13-106"><a aria-hidden="true" href="#cb13-106" tabindex="-1"></a>p5</span>
<span id="cb13-107"><a aria-hidden="true" href="#cb13-107" tabindex="-1"></a></span><br/>
<span id="cb13-108"><a aria-hidden="true" href="#cb13-108" tabindex="-1"></a></span><br/>
<span id="cb13-109"><a aria-hidden="true" href="#cb13-109" tabindex="-1"></a><span className="co"># Save the plot. </span></span>
<span id="cb13-110"><a aria-hidden="true" href="#cb13-110" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"bars education vs poverty.pdf"</span>,</span>
<span id="cb13-111"><a aria-hidden="true" href="#cb13-111" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>,  <span className="co"># a relative path</span></span>
<span id="cb13-112"><a aria-hidden="true" href="#cb13-112" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">7</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In the following plot, we employ <Link to="../ggplot2-line-plot-gapminder"><strong>annotated lines and points</strong></Link> to highlight the significant changes in the human life span and population size from 1800 to 2015.</p>
<p><Link to="../ggplot2-line-plot-gapminder">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out this awesome <Link to="../ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link>, which shows dynamic shifts in the migrant population to the United States from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}