import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2PopulationPyramidAnimation from '../graphics/population_pyramid_animation_completed-1.gif'; 
import imgGgplot2PopulationPyramidAnimationWebp from '../graphics/population_pyramid_animation_completed-1.webp'; 
export default function Ggplot2PopulationPyramidAnimation(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Elegant Animation of Population Pyramids with ggplot2 and gganimate</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2PopulationPyramidAnimationWebp} />
    <img className="cover-img" src={imgGgplot2PopulationPyramidAnimation} />
  </picture>

<p>In an <Link to="../ggplot2-population-pyramid"><strong>earlier article</strong></Link>, we created a population pyramid of Germany in 2023. In this article, we’ll build on the earlier work to <strong>animate the annual population pyramids from 1970 to 2070</strong> (with simulated data). Majority of the script in this article is the same as the static graphic, <span className="highlightGreen"><strong><em>and new edits designed for animation will be highlighted in green.</em></strong></span> 🌻</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-import.">
<h3 className="anchored" data-anchor-id="packages-and-data-import.">Packages and data import.</h3>
<p><span className="highlightGreen">We’ll use the <Link to="https://gganimate.com/"><code>gganimate</code></Link> package to convert a static ggplot2 graphic into animation.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)   <span className="co"># for string manipulation</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">library</span>(gganimate) <span className="co"># create animation</span></span></code></pre></div>
</div>
<p>The raw data is sourced from <Link to="https://service.destatis.de/bevoelkerungspyramide/">Federal Statistical Office of Germany</Link>. <span className="highlightGreen">The dataset used in this animation (a cleaned version, including data from 1970 to 2070) can be downloaded <a className="downloadData" href="dataset/r-gallery/germany_population_all_years.csv"><strong>here</strong></a>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/germany_population_all_years.csv"</span>, </span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="co"># remove the "X" letters otherwise added to headers started with numbers</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="at">check.names =</span> F</span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>) </span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a></span><br/>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() </span>
<span id="cb2-8"><a aria-hidden="true" href="#cb2-8" tabindex="-1"></a></span><br/>
<span id="cb2-9"><a aria-hidden="true" href="#cb2-9" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 103
<br/>  past.future  year gender `0_1` `1_2` `2_3` `3_4` `4_5` `5_6` `6_7` `7_8` `8_9`
<br/>  &lt;chr&gt;       &lt;int&gt; &lt;chr&gt;  &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt;
<br/>1 past         1990 m        468   458   471   460   451   435   433   437   449
<br/>2 past         1991 f        406   446   441   452   440   433   418   415   419
<br/>3 past         1991 m        427   471   463   477   465   456   440   438   442
<br/># ℹ 91 more variables: `9_10` &lt;int&gt;, `10_11` &lt;int&gt;, `11_12` &lt;int&gt;,
<br/>#   `12_13` &lt;int&gt;, `13_14` &lt;int&gt;, `14_15` &lt;int&gt;, `15_16` &lt;int&gt;, `16_17` &lt;int&gt;,
<br/>#   `17_18` &lt;int&gt;, `18_19` &lt;int&gt;, `19_20` &lt;int&gt;, `20_21` &lt;int&gt;, `21_22` &lt;int&gt;,
<br/>#   `22_23` &lt;int&gt;, `23_24` &lt;int&gt;, `24_25` &lt;int&gt;, `25_26` &lt;int&gt;, `26_27` &lt;int&gt;,
<br/>#   `27_28` &lt;int&gt;, `28_29` &lt;int&gt;, `29_30` &lt;int&gt;, `30_31` &lt;int&gt;, `31_32` &lt;int&gt;,
<br/>#   `32_33` &lt;int&gt;, `33_34` &lt;int&gt;, `34_35` &lt;int&gt;, `35_36` &lt;int&gt;, `36_37` &lt;int&gt;,
<br/>#   `37_38` &lt;int&gt;, `38_39` &lt;int&gt;, `39_40` &lt;int&gt;, `40_41` &lt;int&gt;, …</code></pre>
</div>
</div>
<ul>
<li><p><code>gender</code>: <code>f</code> for female, and <code>m</code> for male</p></li>
<li><p><span className="highlightGreen"><b><code>past.future</code> </b>labels years before 2024 as “past”, and year 2024 and after as “future”. The “future” data will be visualized in brighter colors to be distinguished from the “past” data.</span></p></li>
<li><p><span className="highlightGreen">The fourth to the last column record the populations at each age interval.</span></p></li>
</ul>
<p><strong>Transform the dataset into a <Link to="https://cran.r-project.org/web/packages/tidyr/vignettes/tidy-data.html">tidy format:</Link></strong>. <span className="highlightGreen">pack the ages into one column, and the associated populations into another column. This way, the dataset is transformed from a short wide format into a long narrow format.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span><span className="fu">c</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>), <span className="at">values_to =</span> <span className="st">"population"</span>, <span className="at">names_to =</span> <span className="st">"age"</span>)</span></code></pre></div>
</div>
<p><span id="pyramid"><strong>Modify the <code>population</code> variable to create bars in a symmetrical layout:</strong></span></p>
<ul>
<li><p>The bars corresponding to <span><strong>male</strong></span> occupies the <span><strong>positive</strong></span> y-axis range, and bars of the <span><strong>female</strong></span> takes the <span><strong>negative</strong></span> y-axis range.</p></li>
<li><p>Increase the bar length by <strong>extra 80 units for each gender to offset the overlay of the central banner</strong>, which covers the bars of each gender by 80 units.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">population =</span> <span className="fu">ifelse</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    gender <span className="sc">==</span> <span className="st">"f"</span>, <span className="sc">-</span> population <span className="sc">-</span> <span className="dv">80</span>, population <span className="sc">+</span> <span className="dv">80</span> ))</span></code></pre></div>
</div>
<p><strong>Arrange the population bars by the age.</strong> To do this, we need to convert the <code>age</code> variable to a factor to “memorize” the age levels arranged from young to senior. <span className="highlightGreen">We can easily get the orderd age levels from the column names in the original dataset <code>d</code>.</span> (Learn all essential skills in <em>reordering graphic elements</em> in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete tutorial</Link>)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>d.tidy<span className="sc">$</span>age <span className="ot">&lt;-</span> <span className="fu">factor</span>(d.tidy<span className="sc">$</span>age, <span className="at">levels =</span> <span className="fu">colnames</span>(d)[<span className="sc">-</span><span className="fu">c</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>)])</span></code></pre></div>
</div>
<p><span id="masking"><strong>Highlight the population difference between genders.</strong> Here we create a new column <code>pop.min</code> to record the <em>minimum</em> population value of the two genders in each year and each age group.</span><span className="highlightGreen">In the line of <code>group_by</code>, we have two variables, <code>year</code> and <code>age</code>.</span> The minimum population values will be visualized as a semi-transparent masking layer in lighter color, and thus highlight the excess population in more saturated colors in one of the genders.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">group_by</span>(year, age) <span className="sc">%&gt;%</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">pop.min =</span> <span className="fu">min</span>(<span className="fu">abs</span>(population)), </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>         <span className="co"># female in negative values, male in positive values</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>         <span className="at">pop.min =</span> <span className="fu">ifelse</span>(gender <span className="sc">==</span> <span className="st">"m"</span>, pop.min, <span className="sc">-</span>pop.min))</span></code></pre></div>
</div>
<div className="highlightGreen">
<p><strong>Customize the color scale, such that:</strong></p>
<ul>
<li>Bars of <span><strong>man</strong></span> are drawn in <span><strong>blue</strong></span>, and <span><strong>women</strong></span> in <span><strong>red</strong></span>.</li>
<li>The <strong>future data</strong> (year 2024 and after) are drawn <strong>in brighter colors</strong>.</li>
</ul>
<p>To achieve this, we create a new column <code>time.gender</code> to record the year - gender condition, and map it to the <code>fill</code> aesthetic. The color scale is manually defined in the named vector <code>my.colors</code>.</p>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">time.gender =</span> <span className="fu">str_c</span>(past.future, <span className="st">"_"</span>, gender))</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a></span><br/>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>my.colors <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"past_m"</span> <span className="ot">=</span> <span className="st">"steelblue4"</span>, <span className="st">"past_f"</span> <span className="ot">=</span> <span className="st">"red4"</span>,</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>               <span className="st">"future_m"</span> <span className="ot">=</span> <span className="st">"steelblue1"</span>, <span className="st">"future_f"</span> <span className="ot">=</span> <span className="st">"red1"</span>)</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">4</span>) <span className="co"># now ready for visualization!</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 7
<br/># Groups:   year, age [4]
<br/>  past.future  year gender age   population pop.min time.gender
<br/>  &lt;chr&gt;       &lt;int&gt; &lt;chr&gt;  &lt;fct&gt;      &lt;dbl&gt;   &lt;dbl&gt; &lt;chr&gt;      
<br/>1 past         1990 m      0_1          548     548 past_m     
<br/>2 past         1990 m      1_2          538     538 past_m     
<br/>3 past         1990 m      2_3          551     551 past_m     
<br/>4 past         1990 m      3_4          540     540 past_m     </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<div className="highlightGreen">
<p><strong>Per visualization procedure, we’ll first create bars of all years in a <em>superimposed</em> manner, and then use animation to “<em>facet</em>” the plot, as if with <code>facet_wrap</code>, but sequentially on a time scale.</strong></p>
<p><br/></p>
<p>🌳🌳</p>
<p><strong><em>Optional:</em></strong> It is of <em>great help</em> to use only a small data subset (e.g., year of 1980) to develop the code during the initial stage or to ease troubleshooting. The line below reflects this practice. By commenting out (or not) the <code>filter</code> function, it is easy to toggle between the subset and the whole dataset. In this article, I have commented out the subset filtration, and will use the entire dataset for following demonstration.</p>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="co"># %&gt;% filter(year %in% c(1980))</span></span></code></pre></div>
</div>
<p>🌳🌳</p>
<p><br/></p>
<p><strong>Create bar plots with flipped axes.</strong> As the population value of males are positive, and females are negative, this creates bars in a symmetrical layout like mirror images (thanks to <a href="#pyramid">this step</a> of data processing).</p>
<div>
<ul>
<li><p>The bar position is <code>identity</code>, instead of the default <code>stack</code>. This means that each bar is rooted at population of 0. <strong>The plot shown here is an overlap of 81 individual pyramids from years 1990 to 2040.</strong></p></li>
<li><p>The <code>fill</code> aesthetic is mapped with variable <code>time.gender</code>, on the self-defined color scale of <code>my.colors</code>.</p></li>
</ul>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> age, <span className="at">y =</span> population, <span className="at">fill =</span> time.gender)) <span className="sc">+</span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"identity"</span>, <span className="at">width =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span></span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> my.colors)</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_animation_bars.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_bars"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Highlight population difference between genders.</strong> The regions more saturated in color signify the population excess in one gender compared to the other. This effect is achieved by generating a masking layer of bars with semi-transparent white color, with bar length equal to the minimum population of the genders in each year and at each age (thanks to <a href="#masking">this step</a> of data processing). <span className="highlightGreen"><b>Due to the overlap of the many pyramids, the superimposed translucency results in complete opaqueness of white (which will be resolved later in animation).</b></span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="fu">aes</span>(<span className="at">y =</span> pop.min), </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"white"</span>, <span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">width =</span> <span className="dv">1</span>,</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>           <span className="at">position =</span> <span className="st">"identity"</span>)  </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_animation_masking.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_masking"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Create a central banner overlay on the bars.</strong> This banner will contain the age labels, in place of the original x-axis (left vertical). Centered at y = 0, the width of the banner spans a total of 160 units, covering 80 units of bar length of each gender. To offset the coverage of the central banner to the underlying bars, we had included an additional 80 units to the population values at <a href="#pyramid">this early step</a> of data processing.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, <span className="co"># create rectangles</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>           <span className="at">xmin =</span> <span className="sc">-</span><span className="cn">Inf</span>, <span className="at">xmax =</span> <span className="cn">Inf</span>, <span className="at">ymin =</span> <span className="dv">80</span>, <span className="at">ymax =</span> <span className="sc">-</span><span className="dv">80</span>,</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"floralwhite"</span>)</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_animation_banner.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_banner"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Create age labels in the central banner</strong>. We’ll remove the x-axis labels later.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>), <span className="at">y =</span> <span className="dv">0</span>, </span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>),</span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_animation_text.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_text"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Revise the y-axis (bottom horizontal), and add plot titles.</strong></p>
<ul>
<li><p>To correct for the coverage of the central banner, the y-axis for male and female data should be shifted towards the right and left by an additional 80 units, respectively. Therefore, here we calculate the updated y-axis breaks, and label the axis with <em>positive</em> population values.</p></li>
<li><p>Use white spaces in the y-axis title to symmetrically split the title into two pieces, each half for each of the genders.</p></li>
<li><p><span className="highlightGreen">Add subtitle <code>&#123;frame_time&#125;</code> to dynamically display the corresponding year during the animation.</span></p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>breaks <span className="ot">&lt;-</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">700</span>, <span className="at">by =</span> <span className="dv">100</span>)</span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>breaks.updated <span className="ot">&lt;-</span> <span className="fu">c</span>(breaks <span className="sc">+</span> <span className="dv">80</span>, <span className="sc">-</span> breaks <span className="sc">-</span> <span className="dv">80</span>)</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a></span><br/>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>    <span className="at">breaks =</span> breaks.updated,</span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x) &#123;<span className="fu">abs</span>(x) <span className="sc">-</span> <span className="dv">80</span> &#125;) <span className="sc">+</span> </span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"Female (in thousands)                 Male (in thousands)   "</span>,</span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Simulated year"</span>,</span>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"&#123;frame_time&#125;"</span>) </span>
<span id="cb15-11"><a aria-hidden="true" href="#cb15-11" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_animation_scale_titles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_scale_titles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Revise the theme.</strong> <span className="highlightGreen">The plot title position is horizontally and vertically adjusted with <code>hjust</code> and <code>vjust</code>, respectively.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> <span className="co"># an empty canvas, with removal of all axis elements</span></span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>)),</span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>, <span className="at">b =</span> <span className="dv">5</span>)),</span>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">1</span>, <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">10</span>, <span className="at">size =</span> <span className="dv">10</span>),</span>
<span id="cb16-7"><a aria-hidden="true" href="#cb16-7" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">1</span>, <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">6</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">20</span>),</span>
<span id="cb16-8"><a aria-hidden="true" href="#cb16-8" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb16-9"><a aria-hidden="true" href="#cb16-9" tabindex="-1"></a></span><br/>
<span id="cb16-10"><a aria-hidden="true" href="#cb16-10" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_animation_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span className="highlightGreen"><strong>Create animation.</strong> In the static plot, bars from all years are superimposed. The animation serves similarly to <code>facet_wrap(~year)</code> (creating subplots for data of each year), but facets on a time scale. <code>gganimate</code> is an elegant package that converts a ggplot2 static graphic into frames of animation. It may take multiple seconds to render the animation.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span> <span className="fu">transition_time</span>(<span className="at">time =</span> year)</span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a>p7</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_animation_completed-1.gif"/></p>
</figure>
</div>
</div>
</div>
<p><span className="highlightGreen"><strong>Save the animation.</strong> By default, the last rendered animation will be saved. The <code>animation</code> argument (not shown here) can be used to explicitly indicate the animation to be saved.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="fu">anim_save</span>(<span className="at">filename =</span> <span className="st">"Population pyramid animation.gif"</span>, </span>
<span id="cb18-2"><a aria-hidden="true" href="#cb18-2" tabindex="-1"></a>          <span className="at">path =</span> <span className="st">"graphics"</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb19-2"><a aria-hidden="true" href="#cb19-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb19-3"><a aria-hidden="true" href="#cb19-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb19-4"><a aria-hidden="true" href="#cb19-4" tabindex="-1"></a><span className="fu">library</span>(stringr) <span className="co"># for string manipulation</span></span>
<span id="cb19-5"><a aria-hidden="true" href="#cb19-5" tabindex="-1"></a><span className="fu">library</span>(gganimate) <span className="co"># create animation</span></span>
<span id="cb19-6"><a aria-hidden="true" href="#cb19-6" tabindex="-1"></a></span><br/>
<span id="cb19-7"><a aria-hidden="true" href="#cb19-7" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(</span>
<span id="cb19-8"><a aria-hidden="true" href="#cb19-8" tabindex="-1"></a>  <span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/germany_population_all_years.csv"</span>, </span>
<span id="cb19-9"><a aria-hidden="true" href="#cb19-9" tabindex="-1"></a>  <span className="co"># remove the "X" letters otherwise added to headers started with numbers</span></span>
<span id="cb19-10"><a aria-hidden="true" href="#cb19-10" tabindex="-1"></a>  <span className="at">check.names =</span> F</span>
<span id="cb19-11"><a aria-hidden="true" href="#cb19-11" tabindex="-1"></a>)</span>
<span id="cb19-12"><a aria-hidden="true" href="#cb19-12" tabindex="-1"></a></span><br/>
<span id="cb19-13"><a aria-hidden="true" href="#cb19-13" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb19-14"><a aria-hidden="true" href="#cb19-14" tabindex="-1"></a></span><br/>
<span id="cb19-15"><a aria-hidden="true" href="#cb19-15" tabindex="-1"></a><span className="co"># Tidy up</span></span>
<span id="cb19-16"><a aria-hidden="true" href="#cb19-16" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb19-17"><a aria-hidden="true" href="#cb19-17" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span><span className="fu">c</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>), <span className="at">values_to =</span> <span className="st">"population"</span>, <span className="at">names_to =</span> <span className="st">"age"</span>)</span>
<span id="cb19-18"><a aria-hidden="true" href="#cb19-18" tabindex="-1"></a></span><br/>
<span id="cb19-19"><a aria-hidden="true" href="#cb19-19" tabindex="-1"></a></span><br/>
<span id="cb19-20"><a aria-hidden="true" href="#cb19-20" tabindex="-1"></a><span className="co"># Male on the positive range of y axis, and female on the negative range. </span></span>
<span id="cb19-21"><a aria-hidden="true" href="#cb19-21" tabindex="-1"></a><span className="co"># Increase the bar length by 80 units to offset the coverage of the central banner.</span></span>
<span id="cb19-22"><a aria-hidden="true" href="#cb19-22" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb19-23"><a aria-hidden="true" href="#cb19-23" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">population =</span> <span className="fu">ifelse</span>(gender <span className="sc">==</span> <span className="st">"f"</span>, <span className="sc">-</span> population <span className="sc">-</span> <span className="dv">80</span>, population <span className="sc">+</span> <span className="dv">80</span> ))</span>
<span id="cb19-24"><a aria-hidden="true" href="#cb19-24" tabindex="-1"></a></span><br/>
<span id="cb19-25"><a aria-hidden="true" href="#cb19-25" tabindex="-1"></a></span><br/>
<span id="cb19-26"><a aria-hidden="true" href="#cb19-26" tabindex="-1"></a><span className="co"># Display the population pyramid in order of age.</span></span>
<span id="cb19-27"><a aria-hidden="true" href="#cb19-27" tabindex="-1"></a>d.tidy<span className="sc">$</span>age <span className="ot">&lt;-</span> <span className="fu">factor</span>(d.tidy<span className="sc">$</span>age, <span className="at">levels =</span> <span className="fu">colnames</span>(d)[<span className="sc">-</span><span className="fu">c</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>)])</span>
<span id="cb19-28"><a aria-hidden="true" href="#cb19-28" tabindex="-1"></a></span><br/>
<span id="cb19-29"><a aria-hidden="true" href="#cb19-29" tabindex="-1"></a></span><br/>
<span id="cb19-30"><a aria-hidden="true" href="#cb19-30" tabindex="-1"></a><span className="co"># Highlight the population difference between genders.</span></span>
<span id="cb19-31"><a aria-hidden="true" href="#cb19-31" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb19-32"><a aria-hidden="true" href="#cb19-32" tabindex="-1"></a>  <span className="fu">group_by</span>(year, age) <span className="sc">%&gt;%</span></span>
<span id="cb19-33"><a aria-hidden="true" href="#cb19-33" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">pop.min =</span> <span className="fu">min</span>(<span className="fu">abs</span>(population)), </span>
<span id="cb19-34"><a aria-hidden="true" href="#cb19-34" tabindex="-1"></a>         <span className="co"># female in negative values, male in positive values</span></span>
<span id="cb19-35"><a aria-hidden="true" href="#cb19-35" tabindex="-1"></a>         <span className="at">pop.min =</span> <span className="fu">ifelse</span>(gender <span className="sc">==</span> <span className="st">"m"</span>, pop.min, <span className="sc">-</span>pop.min))</span>
<span id="cb19-36"><a aria-hidden="true" href="#cb19-36" tabindex="-1"></a></span><br/>
<span id="cb19-37"><a aria-hidden="true" href="#cb19-37" tabindex="-1"></a></span><br/>
<span id="cb19-38"><a aria-hidden="true" href="#cb19-38" tabindex="-1"></a><span className="co"># Make bars of men in blue, and women in red. </span></span>
<span id="cb19-39"><a aria-hidden="true" href="#cb19-39" tabindex="-1"></a><span className="co"># Make the past data (before 2024) in dark color, and future data in brighter color. </span></span>
<span id="cb19-40"><a aria-hidden="true" href="#cb19-40" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb19-41"><a aria-hidden="true" href="#cb19-41" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">time.gender =</span> <span className="fu">str_c</span>(past.future, <span className="st">"_"</span>, gender))</span>
<span id="cb19-42"><a aria-hidden="true" href="#cb19-42" tabindex="-1"></a></span><br/>
<span id="cb19-43"><a aria-hidden="true" href="#cb19-43" tabindex="-1"></a>my.colors <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"past_m"</span> <span className="ot">=</span> <span className="st">"steelblue4"</span>, <span className="st">"past_f"</span> <span className="ot">=</span> <span className="st">"red4"</span>,</span>
<span id="cb19-44"><a aria-hidden="true" href="#cb19-44" tabindex="-1"></a>               <span className="st">"future_m"</span> <span className="ot">=</span> <span className="st">"steelblue1"</span>, <span className="st">"future_f"</span> <span className="ot">=</span> <span className="st">"red1"</span>)</span>
<span id="cb19-45"><a aria-hidden="true" href="#cb19-45" tabindex="-1"></a></span><br/>
<span id="cb19-46"><a aria-hidden="true" href="#cb19-46" tabindex="-1"></a><span className="co"># now ready for visualization!</span></span>
<span id="cb19-47"><a aria-hidden="true" href="#cb19-47" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">4</span>) </span>
<span id="cb19-48"><a aria-hidden="true" href="#cb19-48" tabindex="-1"></a></span><br/>
<span id="cb19-49"><a aria-hidden="true" href="#cb19-49" tabindex="-1"></a><span className="do">### Visualization ----------------------------------------------------</span></span>
<span id="cb19-50"><a aria-hidden="true" href="#cb19-50" tabindex="-1"></a></span><br/>
<span id="cb19-51"><a aria-hidden="true" href="#cb19-51" tabindex="-1"></a><span className="co"># Optional: create subset to make it easier for code development and troubleshooting</span></span>
<span id="cb19-52"><a aria-hidden="true" href="#cb19-52" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="co"># %&gt;% filter(year %in% c(1980))</span></span>
<span id="cb19-53"><a aria-hidden="true" href="#cb19-53" tabindex="-1"></a></span><br/>
<span id="cb19-54"><a aria-hidden="true" href="#cb19-54" tabindex="-1"></a></span><br/>
<span id="cb19-55"><a aria-hidden="true" href="#cb19-55" tabindex="-1"></a><span className="co"># Create bar plots with flipped axes. Pyramids of all years data are now superimposed.</span></span>
<span id="cb19-56"><a aria-hidden="true" href="#cb19-56" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb19-57"><a aria-hidden="true" href="#cb19-57" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> age, <span className="at">y =</span> population, <span className="at">fill =</span> time.gender)) <span className="sc">+</span></span>
<span id="cb19-58"><a aria-hidden="true" href="#cb19-58" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"identity"</span>, <span className="at">width =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb19-59"><a aria-hidden="true" href="#cb19-59" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span></span>
<span id="cb19-60"><a aria-hidden="true" href="#cb19-60" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> my.colors)</span>
<span id="cb19-61"><a aria-hidden="true" href="#cb19-61" tabindex="-1"></a>p1</span>
<span id="cb19-62"><a aria-hidden="true" href="#cb19-62" tabindex="-1"></a></span><br/>
<span id="cb19-63"><a aria-hidden="true" href="#cb19-63" tabindex="-1"></a></span><br/>
<span id="cb19-64"><a aria-hidden="true" href="#cb19-64" tabindex="-1"></a><span className="co"># Highlight population difference between genders.</span></span>
<span id="cb19-65"><a aria-hidden="true" href="#cb19-65" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb19-66"><a aria-hidden="true" href="#cb19-66" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="fu">aes</span>(<span className="at">y =</span> pop.min), </span>
<span id="cb19-67"><a aria-hidden="true" href="#cb19-67" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"white"</span>, <span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">width =</span> <span className="dv">1</span>,</span>
<span id="cb19-68"><a aria-hidden="true" href="#cb19-68" tabindex="-1"></a>           <span className="at">position =</span> <span className="st">"identity"</span>)  </span>
<span id="cb19-69"><a aria-hidden="true" href="#cb19-69" tabindex="-1"></a>p2</span>
<span id="cb19-70"><a aria-hidden="true" href="#cb19-70" tabindex="-1"></a></span><br/>
<span id="cb19-71"><a aria-hidden="true" href="#cb19-71" tabindex="-1"></a></span><br/>
<span id="cb19-72"><a aria-hidden="true" href="#cb19-72" tabindex="-1"></a><span className="co"># Create a central banner overlay on the bars.</span></span>
<span id="cb19-73"><a aria-hidden="true" href="#cb19-73" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb19-74"><a aria-hidden="true" href="#cb19-74" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, <span className="co"># create rectangles</span></span>
<span id="cb19-75"><a aria-hidden="true" href="#cb19-75" tabindex="-1"></a>           <span className="at">xmin =</span> <span className="sc">-</span><span className="cn">Inf</span>, <span className="at">xmax =</span> <span className="cn">Inf</span>, <span className="at">ymin =</span> <span className="dv">80</span>, <span className="at">ymax =</span> <span className="sc">-</span><span className="dv">80</span>,</span>
<span id="cb19-76"><a aria-hidden="true" href="#cb19-76" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"floralwhite"</span>)</span>
<span id="cb19-77"><a aria-hidden="true" href="#cb19-77" tabindex="-1"></a>p3</span>
<span id="cb19-78"><a aria-hidden="true" href="#cb19-78" tabindex="-1"></a></span><br/>
<span id="cb19-79"><a aria-hidden="true" href="#cb19-79" tabindex="-1"></a></span><br/>
<span id="cb19-80"><a aria-hidden="true" href="#cb19-80" tabindex="-1"></a><span className="co"># Label with ages in the central banner</span></span>
<span id="cb19-81"><a aria-hidden="true" href="#cb19-81" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb19-82"><a aria-hidden="true" href="#cb19-82" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb19-83"><a aria-hidden="true" href="#cb19-83" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>), <span className="at">y =</span> <span className="dv">0</span>, </span>
<span id="cb19-84"><a aria-hidden="true" href="#cb19-84" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>),</span>
<span id="cb19-85"><a aria-hidden="true" href="#cb19-85" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb19-86"><a aria-hidden="true" href="#cb19-86" tabindex="-1"></a>p4</span>
<span id="cb19-87"><a aria-hidden="true" href="#cb19-87" tabindex="-1"></a></span><br/>
<span id="cb19-88"><a aria-hidden="true" href="#cb19-88" tabindex="-1"></a></span><br/>
<span id="cb19-89"><a aria-hidden="true" href="#cb19-89" tabindex="-1"></a><span className="co"># Revise the axial labels and titles.</span></span>
<span id="cb19-90"><a aria-hidden="true" href="#cb19-90" tabindex="-1"></a>breaks <span className="ot">&lt;-</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">700</span>, <span className="at">by =</span> <span className="dv">100</span>)</span>
<span id="cb19-91"><a aria-hidden="true" href="#cb19-91" tabindex="-1"></a>breaks.updated <span className="ot">&lt;-</span> <span className="fu">c</span>(breaks <span className="sc">+</span> <span className="dv">80</span>, <span className="sc">-</span> breaks <span className="sc">-</span> <span className="dv">80</span>)</span>
<span id="cb19-92"><a aria-hidden="true" href="#cb19-92" tabindex="-1"></a></span><br/>
<span id="cb19-93"><a aria-hidden="true" href="#cb19-93" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb19-94"><a aria-hidden="true" href="#cb19-94" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb19-95"><a aria-hidden="true" href="#cb19-95" tabindex="-1"></a>    <span className="at">breaks =</span> breaks.updated,</span>
<span id="cb19-96"><a aria-hidden="true" href="#cb19-96" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x) &#123;<span className="fu">abs</span>(x) <span className="sc">-</span> <span className="dv">80</span> &#125;) <span className="sc">+</span> </span>
<span id="cb19-97"><a aria-hidden="true" href="#cb19-97" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"Female (in thousands)                 Male (in thousands)   "</span>,</span>
<span id="cb19-98"><a aria-hidden="true" href="#cb19-98" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Simulated year"</span>,</span>
<span id="cb19-99"><a aria-hidden="true" href="#cb19-99" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"&#123;frame_time&#125;"</span>) </span>
<span id="cb19-100"><a aria-hidden="true" href="#cb19-100" tabindex="-1"></a>p5</span>
<span id="cb19-101"><a aria-hidden="true" href="#cb19-101" tabindex="-1"></a></span><br/>
<span id="cb19-102"><a aria-hidden="true" href="#cb19-102" tabindex="-1"></a><span className="co"># Revise the theme. </span></span>
<span id="cb19-103"><a aria-hidden="true" href="#cb19-103" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb19-104"><a aria-hidden="true" href="#cb19-104" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> <span className="co"># an empty canvas, with removal of all axis elements</span></span>
<span id="cb19-105"><a aria-hidden="true" href="#cb19-105" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb19-106"><a aria-hidden="true" href="#cb19-106" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>)),</span>
<span id="cb19-107"><a aria-hidden="true" href="#cb19-107" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>, <span className="at">b =</span> <span className="dv">5</span>)),</span>
<span id="cb19-108"><a aria-hidden="true" href="#cb19-108" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">1</span>, <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">10</span>, <span className="at">size =</span> <span className="dv">10</span>),</span>
<span id="cb19-109"><a aria-hidden="true" href="#cb19-109" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">1</span>, <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">6</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">20</span>),</span>
<span id="cb19-110"><a aria-hidden="true" href="#cb19-110" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb19-111"><a aria-hidden="true" href="#cb19-111" tabindex="-1"></a></span><br/>
<span id="cb19-112"><a aria-hidden="true" href="#cb19-112" tabindex="-1"></a>p6</span>
<span id="cb19-113"><a aria-hidden="true" href="#cb19-113" tabindex="-1"></a></span><br/>
<span id="cb19-114"><a aria-hidden="true" href="#cb19-114" tabindex="-1"></a></span><br/>
<span id="cb19-115"><a aria-hidden="true" href="#cb19-115" tabindex="-1"></a><span className="co"># Create animation.</span></span>
<span id="cb19-116"><a aria-hidden="true" href="#cb19-116" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> p6 <span className="sc">+</span> <span className="fu">transition_time</span>(<span className="at">time =</span> year)</span>
<span id="cb19-117"><a aria-hidden="true" href="#cb19-117" tabindex="-1"></a>p7</span>
<span id="cb19-118"><a aria-hidden="true" href="#cb19-118" tabindex="-1"></a></span><br/>
<span id="cb19-119"><a aria-hidden="true" href="#cb19-119" tabindex="-1"></a><span className="co"># Save the animation</span></span>
<span id="cb19-120"><a aria-hidden="true" href="#cb19-120" tabindex="-1"></a><span className="fu">anim_save</span>(<span className="at">filename =</span> <span className="st">"Population pyramid animation.gif"</span>, </span>
<span id="cb19-121"><a aria-hidden="true" href="#cb19-121" tabindex="-1"></a>          <span className="at">path =</span> <span className="st">"graphics"</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🛩 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out the following article, where we’ll visualize the <Link to="../ggplot2-map-airline-animation"><strong>global flights and airports over the world map in animation</strong></Link>. (And check <Link to="../ggplot2-map-airline"><strong>here</strong></Link> if you wanted the <em>static</em> plot first)</p>
<p><Link to="../ggplot2-map-airline-animation"><img className="img-fluid" src="graphics/map_airlines_animation_gganimate_completed-1.gif"/></Link></p>
</main>
</div>
</div>
)}