import React from 'react'; 
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkExplode(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="explode"><code>explode()</code><a class="anchor-link" href="#explode">¶</a></h3><p>The <code>explode()</code> function is used to convert each element in an array or each key-value pair in a map into a separate row. This transformation is particularly useful for flattening complex nested data structures in DataFrames.</p>
<h4 id="Usage">Usage<a class="anchor-link" href="#Usage">¶</a></h4><ul>
<li><code>explode()</code> is applied to an array or map column.</li>
<li>In the case of an array, each element becomes a new row.</li>
<li>For a map, each key-value pair is turned into a new row.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session">Create Spark Session<a class="anchor-link" href="#Create-Spark-Session">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>
<span><span class="kn">from</span> <span class="nn">pyspark.sql.functions</span> <span class="kn">import</span> <span class="n">explode</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"explodeExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-explode-with-Array-columns">Example: Use <code>explode()</code> with Array columns<a class="anchor-link" href="#Example:-Use-explode-with-Array-columns">¶</a></h4><h5 id="Create-a-sample-DataFrame-with-an-Array-column">Create a sample DataFrame with an Array column<a class="anchor-link" href="#Create-a-sample-DataFrame-with-an-Array-column">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">array_data</span> <span class="o">=</span> <span class="p">[(</span><span class="mi">1</span><span class="p">,</span> <span class="p">[</span><span class="s2">"Java"</span><span class="p">,</span> <span class="s2">"Python"</span><span class="p">,</span> <span class="s2">"C++"</span><span class="p">]),</span> <span class="p">(</span><span class="mi">2</span><span class="p">,</span> <span class="p">[</span><span class="s2">"Spark"</span><span class="p">,</span> <span class="s2">"Java"</span><span class="p">,</span> <span class="s2">"C++"</span><span class="p">]),</span> <span class="p">(</span><span class="mi">3</span><span class="p">,</span> <span class="p">[</span><span class="s2">"Python"</span><span class="p">,</span> <span class="s2">"Scala"</span><span class="p">])]</span></span>
<span><span class="n">array_columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Id"</span><span class="p">,</span> <span class="s2">"Languages"</span><span class="p">]</span></span>
<span><span class="n">array_df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">array_data</span><span class="p">,</span> <span class="n">array_columns</span><span class="p">)</span></span>
<span><span class="n">array_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---+-------------------+
<br />| Id|          Languages|
<br />+---+-------------------+
<br />|  1|[Java, Python, C++]|
<br />|  2| [Spark, Java, C++]|
<br />|  3|    [Python, Scala]|
<br />+---+-------------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div>
<div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
    <h5>Use <code>explode()</code> on the <em>language</em> column</h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">exploded_df</span> <span class="o">=</span> <span class="n">array_df</span><span class="o">.</span><span class="n">select</span><span class="p">(</span><span class="n">array_df</span><span class="o">.</span><span class="n">Id</span><span class="p">,</span> <span class="n">explode</span><span class="p">(</span><span class="n">array_df</span><span class="o">.</span><span class="n">Languages</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"Language"</span><span class="p">))</span></span>
<span><span class="n">exploded_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---+--------+
<br />| Id|Language|
<br />+---+--------+
<br />|  1|    Java|
<br />|  1|  Python|
<br />|  1|     C++|
<br />|  2|   Spark|
<br />|  2|    Java|
<br />|  2|     C++|
<br />|  3|  Python|
<br />|  3|   Scala|
<br />+---+--------+
<br /></code></pre>
</div>
</div>
</div>
</div>
<div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<ul>
<li><code>explode(array_df.Languages)</code>: this transforms each element in the <strong>Languages</strong> Array column into a separate row.<br/></li>
<li>The <strong>Id</strong> column is retained for each exploded row, and the new <strong>Language</strong> column contains the individual elements from the arrays.</li>
</ul>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-explode-with-Map-columns">Example: Use <code>explode()</code> with Map columns<a class="anchor-link" href="#Example:-Use-explode-with-Map-columns">¶</a></h4><h5 id="Create-a-sample-DataFrame-with-an-Map-column">Create a sample DataFrame with an Map column<a class="anchor-link" href="#Create-a-sample-DataFrame-with-an-Map-column">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">map_data</span> <span class="o">=</span> <span class="p">[(</span><span class="mi">1</span><span class="p">,</span> <span class="p">&#123;</span><span class="s2">"Java"</span><span class="p">:</span> <span class="s2">"JVM"</span><span class="p">,</span> <span class="s2">"Python"</span><span class="p">:</span> <span class="s2">"CPython"</span><span class="p">&#125;),</span></span>
<span>            <span class="p">(</span><span class="mi">2</span><span class="p">,</span> <span class="p">&#123;</span><span class="s2">"C++"</span><span class="p">:</span> <span class="s2">"GCC"</span><span class="p">,</span> <span class="s2">"Java"</span><span class="p">:</span> <span class="s2">"OpenJDK"</span><span class="p">&#125;),</span></span>
<span>            <span class="p">(</span><span class="mi">3</span><span class="p">,</span> <span class="p">&#123;</span><span class="s2">"Python"</span><span class="p">:</span> <span class="s2">"PyPy"</span><span class="p">,</span> <span class="s2">"Scala"</span><span class="p">:</span> <span class="s2">"JVM"</span><span class="p">&#125;)]</span></span>
<span><span class="n">map_columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Id"</span><span class="p">,</span> <span class="s2">"LanguageMap"</span><span class="p">]</span></span>
<span><span class="n">map_df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">map_data</span><span class="p">,</span> <span class="n">map_columns</span><span class="p">)</span></span>
<span><span class="n">map_df</span><span class="o">.</span><span class="n">show</span><span class="p">(</span><span class="n">truncate</span><span class="o">=</span><span class="kc">False</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---+--------------------------------+
<br />|Id |LanguageMap                     |
<br />+---+--------------------------------+
<br />|1  |&#123;Java -&gt; JVM, Python -&gt; CPython&#125;|
<br />|2  |&#123;Java -&gt; OpenJDK, C++ -&gt; GCC&#125;   |
<br />|3  |&#123;Scala -&gt; JVM, Python -&gt; PyPy&#125;  |
<br />+---+--------------------------------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div>

</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">exploded_map_df</span> <span class="o">=</span> <span class="n">map_df</span><span class="o">.</span><span class="n">select</span><span class="p">(</span><span class="n">map_df</span><span class="o">.</span><span class="n">Id</span><span class="p">,</span> <span class="n">explode</span><span class="p">(</span><span class="n">map_df</span><span class="o">.</span><span class="n">LanguageMap</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"Language"</span><span class="p">,</span> <span class="s2">"Platform"</span><span class="p">))</span></span>
<span><span class="n">exploded_map_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---+--------+--------+
<br />| Id|Language|Platform|
<br />+---+--------+--------+
<br />|  1|    Java|     JVM|
<br />|  1|  Python| CPython|
<br />|  2|    Java| OpenJDK|
<br />|  2|     C++|     GCC|
<br />|  3|   Scala|     JVM|
<br />|  3|  Python|    PyPy|
<br />+---+--------+--------+
<br /></code></pre>
</div>
</div>
</div>
</div>
<div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<ul>
<li><code>explode(map_df.LanguageMap)</code>: this transforms each key-value pair in the <strong>LanguageMap</strong> column into separate rows. </li>
<li>The resulting DataFrame has three columns: <strong>Id</strong>, <strong>Language</strong> (the key), and <strong>Platform</strong> (the value).</li>
</ul>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}