import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Map2(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Apply a Function to Each Element of Two Inputs</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>TL;DR</strong> <code>map2(.x, .y, .f)</code> is very similar to <Link to="../map"><code>map(.x, .f)</code></Link>, but applies the operation <code>.f</code> to elements in both inputs of <code>.x</code> and <code>.y</code>, and returns a <em>list</em>. The <code>map2_*()</code> functions, e.g., <code>map2_lgl()</code>, <code>map2_dbl()</code>, <code>map2_int()</code> and <code>map2_chr()</code>, works in a similar way as <code>map2()</code> but returns a <em>vector</em> of the indicated type.</p>
<hr/>
<blockquote className="blockquote">
<p>If you are not familar with <code>map()</code> yet, I recommend you to first check it out, and it’ll make it much easier to understand <code>map2()</code>.</p>
</blockquote>
<p>The <code>map2()</code> function applies a function to each element of two paired inputs (vector or list). The basic argument structure follows <code>map(.x, .y, .f)</code> (as in other functions in the <code>map2_*</code> family):</p>
<ul>
<li><code>.x</code> and <code>.y</code>: vector or list. The length of the two inputs should be the same; or one input is of length-one, and recycled to the length of the other (i.e., the rule of tidyverse recycle).</li>
<li><code>.f</code> defines the function to be applied to each paired elements in <code>.x</code> and <code>.y</code>. It is in the format of a named function (without quote), e.g. <code>sum</code>; or an anonymous function, e.g., <code>~ sum(.x, .y)</code>, <code>~ .x + .y</code>, <code>\(x, y) x + y</code>, and <code>function(x, y) x + y</code>.</li>
<li>The output is a list of the same length as the input.</li>
</ul>
<p>If the <code>.f</code> - specified operation that is applied to each paired elements of <code>.x</code> and <code>.y</code> returns a vector of length-one, then you can also output the result as a vector of a specific type, instead of as a list.</p>
<ul>
<li><code>map2_dbl()</code> returns a vector of type of <em>double</em> (i.e., numeric).</li>
<li><code>map2_lgl()</code> returns a vector of type of <em>logic</em>.</li>
<li><code>map2_int()</code> returns a vector of type of <em>integer</em>.</li>
<li><code>map2_chr()</code> returns a vector of type of <em>character</em>.</li>
</ul>
<p><strong>e.g.1.</strong> Calculate A raised to the power of B on a paired basis.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(purrr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>A <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">4</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>B <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">1</span>, <span className="dv">2</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">map2</span>(A, B, \(x, y) x <span className="sc">^</span> y )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [[1]]
<br/>  [1] 1
<span>  
</span><br/>  [[2]]
<br/>  [1] 2
<span>  
</span><br/>  [[3]]
<br/>  [1] 16</code></pre>
</div>
</div>
<p>This can be written in the following equivalent forms:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">map2</span>(A, B, <span className="cf">function</span>(x, y) x <span className="sc">^</span> y )</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">map2</span>(A, B, <span className="sc">~</span> .x <span className="sc">^</span> .y)</span></code></pre></div>
</div>
<p>Return the output as an integer vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">map2_int</span>(A, B, <span className="sc">~</span> .x <span className="sc">^</span> .y)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1]  1  2 16</code></pre>
</div>
</div>
<p>Return the output as a character vector.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">map2_chr</span>(A, B, <span className="sc">~</span> <span className="fu">as.character</span>(.x <span className="sc">^</span> .y) )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "1"  "2"  "16"</code></pre>
</div>
</div>
<p><strong>e.g.2.</strong> The following code creates three vectors (of a list) of a normal distribution, with <code>1</code>, <code>5</code>, and <code>10</code> as the <code>mean</code>, and <code>0.1</code>, <code>1</code>, and <code>5</code> as the associated <code>sd</code>, respectively.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>myMean <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">1</span>,  <span className="dv">5</span>, <span className="dv">10</span>)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>mySD   <span className="ot">&lt;-</span> <span className="fu">c</span>(.<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">5</span>)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a></span><br/>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a><span className="fu">map2</span>(</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="at">.x =</span> myMean, </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="at">.y =</span> mySD,</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="at">.f =</span> \(x, y) <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">10</span>, <span className="at">mean =</span> x, <span className="at">sd =</span> y) <span className="sc">%&gt;%</span> <span className="fu">round</span>(<span className="dv">1</span>)</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [[1]]
<br/>   [1] 1.0 1.0 1.0 0.9 1.2 1.0 0.9 0.9 1.2 0.8
<span>  
</span><br/>  [[2]]
<br/>   [1] 4.9 6.1 4.3 5.8 4.9 4.2 5.3 4.0 3.6 2.6
<span>  
</span><br/>  [[3]]
<br/>   [1] 13.7  8.4 18.7 17.2  7.4 16.9  7.2  1.5 14.2  6.4</code></pre>
</div>
</div>
<p>The <code>.f</code> argument can be equivalently written in the following way:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">map2</span>(<span className="at">.x =</span> myMean, <span className="at">.y =</span> mySD,</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>     <span className="at">.f =</span> <span className="cf">function</span>(x, y) <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">10</span>, <span className="at">mean =</span> x, <span className="at">sd =</span> y) <span className="sc">%&gt;%</span> <span className="fu">round</span>(<span className="dv">1</span>)) </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a><span className="fu">map2</span>(<span className="at">.x =</span> myMean, <span className="at">.y =</span> mySD,</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>     <span className="at">.f =</span> <span className="sc">~</span> <span className="fu">rnorm</span>(<span className="at">n =</span> <span className="dv">10</span>, <span className="at">mean =</span> .x, <span className="at">sd =</span> .y) <span className="sc">%&gt;%</span> <span className="fu">round</span>(<span className="dv">1</span>)) </span></code></pre></div>
</div>
<p><strong>e.g.3</strong> Using the <code>iris</code> dataset, and separately for each species, here we create a linear model between <code>Sepal.Length</code> and <code>Sepal.Width</code> (using the <Link to="../map"><code>map()</code></Link> function as covered in the last section), and then predict the <code>Sepal.Length</code> based on the observed <code>Sepal.Width</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># split the `iris` dataset into a list of data frames</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="co"># with each element (data frame) being a subset of a 'Species' type </span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>iris.list <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> <span className="fu">split</span>(iris<span className="sc">$</span>Species)</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a></span><br/>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a><span className="co"># separately for each species (element of the list), </span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a><span className="co"># create a linear model of Sepal.Length (response) vs. Sepal.Width (feature)</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>mods <span className="ot">&lt;-</span> iris.list <span className="sc">%&gt;%</span></span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>  <span className="fu">map</span>(<span className="sc">~</span><span className="fu">lm</span>(Sepal.Length <span className="sc">~</span> Sepal.Width, <span className="at">data =</span> .x))</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a></span><br/>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a><span className="co"># separately for each species</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a><span className="co"># predict 'Sepal.Length' based on the observed Sepal.Width</span></span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a><span className="fu">map2</span>(mods, iris.list, predict) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  $setosa
<br/>         1        2        3        4        5        6        7        8 
<br/>  5.055715 4.710470 4.848568 4.779519 5.124764 5.331911 4.986666 4.986666 
<br/>         9       10       11       12       13       14       15       16 
<br/>  4.641421 4.779519 5.193813 4.986666 4.710470 4.710470 5.400960 5.677156 
<br/>        17       18       19       20       21       22       23       24 
<br/>  5.331911 5.055715 5.262862 5.262862 4.986666 5.193813 5.124764 4.917617 
<br/>        25       26       27       28       29       30       31       32 
<br/>  4.986666 4.710470 4.986666 5.055715 4.986666 4.848568 4.779519 4.986666 
<br/>        33       34       35       36       37       38       39       40 
<br/>  5.470009 5.539058 4.779519 4.848568 5.055715 5.124764 4.710470 4.986666 
<br/>        41       42       43       44       45       46       47       48 
<br/>  5.055715 4.227128 4.848568 5.055715 5.262862 4.710470 5.262862 4.848568 
<br/>        49       50 
<br/>  5.193813 4.917617 
<span>  
</span><span>  $versicolor
</span><br/>        51       52       53       54       55       56       57       58 
<br/>  6.307983 6.307983 6.221476 5.529413 5.961952 5.961952 6.394491 5.615921 
<br/>        59       60       61       62       63       64       65       66 
<br/>  6.048460 5.875445 5.269890 6.134968 5.442906 6.048460 6.048460 6.221476 
<br/>        67       68       69       70       71       72       73       74 
<br/>  6.134968 5.875445 5.442906 5.702429 6.307983 5.961952 5.702429 5.961952 
<br/>        75       76       77       78       79       80       81       82 
<br/>  6.048460 6.134968 5.961952 6.134968 6.048460 5.788937 5.615921 5.615921 
<br/>        83       84       85       86       87       88       89       90 
<br/>  5.875445 5.875445 6.134968 6.480999 6.221476 5.529413 6.134968 5.702429 
<br/>        91       92       93       94       95       96       97       98 
<br/>  5.788937 6.134968 5.788937 5.529413 5.875445 6.134968 6.048460 6.048460 
<br/>        99      100 
<br/>  5.702429 5.961952 
<span>  
</span><span>  $virginica
</span><br/>       101      102      103      104      105      106      107      108 
<br/>  6.881900 6.340980 6.611440 6.521286 6.611440 6.611440 6.160673 6.521286 
<br/>       109      110      111      112      113      114      115      116 
<br/>  6.160673 7.152361 6.791747 6.340980 6.611440 6.160673 6.431133 6.791747 
<br/>       117      118      119      120      121      122      123      124 
<br/>  6.611440 7.332667 6.250826 5.890212 6.791747 6.431133 6.431133 6.340980 
<br/>       125      126      127      128      129      130      131      132 
<br/>  6.881900 6.791747 6.431133 6.611440 6.431133 6.611440 6.431133 7.332667 
<br/>       133      134      135      136      137      138      139      140 
<br/>  6.431133 6.431133 6.250826 6.611440 6.972054 6.701593 6.611440 6.701593 
<br/>       141      142      143      144      145      146      147      148 
<br/>  6.701593 6.701593 6.340980 6.791747 6.881900 6.611440 6.160673 6.611440 
<br/>       149      150 
<br/>  6.972054 6.611440</code></pre>
</div>
</div>
</main>
</div>
</div>
)}