import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LinePlotGapminder from '../graphics/lines_lifespan_theme_completed.png'; 
import imgGgplot2LinePlotGapminderWebp from '../graphics/lines_lifespan_theme_completed.webp'; 
export default function Ggplot2LinePlotGapminder(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use ggplot2 to Visualize Increases in Human Life Expectancy and Population Since 1800s</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2LinePlotGapminderWebp} />
    <img className="cover-img" src={imgGgplot2LinePlotGapminder} />
  </picture>

<p>In this work, we’ll use ggplot2 to create a line plot combined with scatterplot to visualize the increases in the human life expectancy and population size from 1800 to 2015. <strong>Major techniques covered in this work include:</strong></p>
<ul>
<li><a href="#line_scatterplot">Create a combination of the line plot and the scatterplot.</a></li>
<li><a href="#log_scale">Transform x-axis to logarithmic scale.</a></li>
<li><a href="#rectangle_text_annotation">Make rectangle and text annotations.</a></li>
<li><a href="#highlight_selected_data">Highlight a selected data subset.</a></li>
</ul>
<p>This work is a ggplot2 reproduction of the demo graphic by <Link to="https://www.datawrapper.de/charts">Datawrapper</Link> by Lisa Charlotte Rost.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset used in this work is sourced from <Link to="https://www.gapminder.org/data/">gapminder</Link>, and can be downloaded <a className="downloadData" href="dataset/r-gallery/point_line_gapminder_rising%20life%20Exp.csv"><strong>here</strong></a>. It contains the population and economic data in year 1800 and 2015.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/point_line_gapminder_rising life Exp.csv"</span>)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="co"># "population" variable is recognized as "character" due to "null" texts</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a><span className="co"># here we force it to numerical (and the "null" values beome NA)</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>    <span className="at">population =</span> <span className="fu">as.double</span>(population),</span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>    <span className="at">country =</span> <span className="fu">str_replace_all</span>(country, <span className="st">"United States"</span>, <span className="st">"US"</span>))</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 6
<br/>  country       Life.expectancy.in.y…¹ GDP.per.capita population continent  year
<br/>  &lt;chr&gt;                          &lt;dbl&gt;          &lt;int&gt;      &lt;dbl&gt; &lt;chr&gt;     &lt;int&gt;
<br/>1 Malawi                          30.3            350     737000 Africa     1800
<br/>2 Equatorial G…                   29.8            356      80377 Africa     1800
<br/>3 Solomon Isla…                   25.1            363      56998 Australia  1800
<br/>4 Mozambique                      30.3            390    1982324 Africa     1800
<br/># ℹ abbreviated name: ¹​Life.expectancy.in.years</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="line_scatterplot">Create a scatterplot, and connect the two points of the year 1800 and 2015 of the same country.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> GDP.per.capita, <span className="at">y =</span> Life.expectancy.in.years)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="fu">aes</span>(<span className="at">size =</span> population), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">alpha =</span> .<span className="dv">8</span>) <span className="sc">+</span> </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> country), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">alpha =</span> .<span className="dv">8</span>, <span className="at">linewidth =</span> .<span className="dv">05</span>)  <span className="sc">+</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_line_scatterplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_lifespan_line_scatterplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="log_scale"><strong>Modify the scale of the <code>x</code> and <code>size</code> aesthetics. </strong></span></p>
<ul>
<li><p>Transform the x-axis to logarithmic scale of base 10. This renders the scatterplot more spread apart horizontally to gives better readability. Such transformation is very efficient in unveiling the pattern of highly skewed data distribution – also check out <Link to="../ggplot2-scatterplot-urbanization">log-transform on y-axis</Link>, and <Link to="../ggplot2-heatmap-African-population">pseudo-log transform in color scale</Link>.</p></li>
<li><p>The <code>population</code> variable has been mapped to the <code>size</code> aesthetic. Here we increase the maximum size at the upper end of the size scale, and use zero size for population of zero. This creates a sharper contrast in the point size.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">scale_x_log10</span>(<span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">1000</span>, <span className="st">"K"</span>)&#125;,</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>                <span className="at">n.breaks =</span> <span className="dv">8</span>,</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>                <span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">300</span>, <span className="dv">2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">5</span>)) <span className="sc">+</span> </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">annotation_logticks</span>(<span className="at">sides =</span> <span className="st">"b"</span>) <span className="sc">+</span></span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">90</span>, <span className="dv">10</span>), <span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">18</span>, <span className="dv">90</span>)) <span className="sc">+</span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="co"># `scale_size_area()` ensures zero value is mapped to zero size</span></span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>  <span className="fu">scale_size_area</span>(<span className="at">max_size =</span> <span className="dv">20</span>)  </span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_log10_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_lifespan_log10_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="rectangle_text_annotation"><strong>Add rectangles to annotate the years.</strong> With <code>coord_cartesian(expand = 0)</code> (at the earlier step of <a href="#line_scatterplot"><code>p1</code></a>), the plot expands to eliminate all plot margin. This allows the gap between the rectangles and the axis lines to be precisely controlled by the rectangle coordinates (<code>xmin</code>, <code>xamx</code>, <code>ymin</code>, <code>ymax</code>) and the axial scale limits (see <code>limits</code> argument above).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>           <span className="at">xmin =</span> <span className="dv">350</span>, <span className="at">xmax =</span> <span className="dv">2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">5</span>, </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>           <span className="at">ymin =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="dv">48</span>), <span className="at">ymax =</span> <span className="fu">c</span>(<span className="dv">46</span>, <span className="dv">90</span>), </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>           <span className="at">alpha =</span> .<span className="dv">08</span>) <span className="sc">+</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="co"># years text annotation</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">1.2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">5</span>, <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">42</span>, <span className="dv">87</span>), <span className="at">label =</span> <span className="fu">c</span>(<span className="dv">1800</span>, <span className="dv">2015</span>), </span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">7</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_rectangles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_lifespan_rectangles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="highlight_selected_data">Highlight three selected countries with the largest population in 2015.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>d.big.countries <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">filter</span>(country <span className="sc">%in%</span> <span className="fu">c</span>(<span className="st">"China"</span>, <span className="st">"India"</span>, <span className="st">"US"</span>))</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a></span><br/>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="co"># highlight the lines</span></span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">data =</span> d.big.countries,</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">color =</span> country), </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>            <span className="at">linewidth =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">1</span>, .<span className="dv">5</span>, .<span className="dv">5</span>)) <span className="sc">+</span></span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>  <span className="co"># highlight the points</span></span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">data =</span> d.big.countries,</span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>             <span className="fu">aes</span>(<span className="at">size =</span> population, <span className="at">color =</span> country)) <span className="sc">+</span> </span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>  <span className="co"># label with country names</span></span>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> d.big.countries <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2015</span>), </span>
<span id="cb6-14"><a aria-hidden="true" href="#cb6-14" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">label =</span> country), </span>
<span id="cb6-15"><a aria-hidden="true" href="#cb6-15" tabindex="-1"></a>            <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">5</span>, <span className="dv">4</span>), </span>
<span id="cb6-16"><a aria-hidden="true" href="#cb6-16" tabindex="-1"></a>            <span className="at">fontface =</span> <span className="st">"bold"</span>) <span className="sc">+</span></span>
<span id="cb6-17"><a aria-hidden="true" href="#cb6-17" tabindex="-1"></a>  <span className="co"># add color to the three selected countries</span></span>
<span id="cb6-18"><a aria-hidden="true" href="#cb6-18" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"red4"</span>, <span className="st">"steelblue4"</span>, <span className="st">"darkgreen"</span>)) </span>
<span id="cb6-19"><a aria-hidden="true" href="#cb6-19" tabindex="-1"></a></span><br/>
<span id="cb6-20"><a aria-hidden="true" href="#cb6-20" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_selected_highlight.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_lifespan_selected_highlight"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A final touch-up. Here we use <code>str_wrap()</code> from the <Link to="/R/data-wrangling/stringr/0-introduction"><code>stringr</code></Link> package to automatically wrap the title, with ~50 characters per line.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># remove axial titles</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>) <span className="sc">+</span> </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="co"># add plot title, with text wrapping after ~50 characters each line</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="fu">str_wrap</span>(</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="st">"There has been significant increases in human life span</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a><span className="st">    and population in the last two hundred years"</span>,</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="at">width =</span> <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="co"># mark axial titles inside the plot</span></span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">340</span>, <span className="dv">10</span><span className="sc">^</span><span className="fl">4.2</span>), <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">85</span>, <span className="dv">22</span>), </span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"life expectancy</span><span className="sc">\n</span><span className="st">in years"</span>,</span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>                     <span className="st">"GDP per capita (US $)"</span>), </span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>           <span className="at">size =</span> <span className="fl">4.5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"grey30"</span>) <span className="sc">+</span></span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb7-18"><a aria-hidden="true" href="#cb7-18" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>,</span>
<span id="cb7-19"><a aria-hidden="true" href="#cb7-19" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>)))</span>
<span id="cb7-20"><a aria-hidden="true" href="#cb7-20" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_lifespan_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_lifespan_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/point_line_gapminder_rising life Exp.csv"</span>)</span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a></span><br/>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a><span className="co"># "population" variable is recognized as "character" due to "null" texts</span></span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a><span className="co"># here we force it to numerical (and the "null" values beome NA)</span></span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    <span className="at">population =</span> <span className="fu">as.double</span>(population),</span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>    <span className="at">country =</span> <span className="fu">str_replace_all</span>(country, <span className="st">"United States"</span>, <span className="st">"US"</span>))</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a></span><br/>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">4</span>)</span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a></span><br/>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a></span><br/>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a><span className="co"># Create a scatterplot, </span></span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a><span className="co"># and connect the two points of the year 1800 and 2015 of the same country.</span></span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> GDP.per.capita, <span className="at">y =</span> Life.expectancy.in.years)) <span className="sc">+</span></span>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="fu">aes</span>(<span className="at">size =</span> population), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">alpha =</span> .<span className="dv">8</span>) <span className="sc">+</span> </span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> country), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">alpha =</span> .<span className="dv">8</span>, <span className="at">linewidth =</span> .<span className="dv">05</span>)  <span className="sc">+</span></span>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a>p1</span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a></span><br/>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a></span><br/>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a><span className="co"># Modify the scale of the `x` and `size` aesthetics. </span></span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a>  <span className="fu">scale_x_log10</span>(<span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste</span>(x<span className="sc">/</span><span className="dv">1000</span>, <span className="st">"K"</span>)&#125;,</span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a>                <span className="at">n.breaks =</span> <span className="dv">8</span>,</span>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a>                <span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">300</span>, <span className="dv">2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">5</span>)) <span className="sc">+</span> </span>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a>  <span className="fu">annotation_logticks</span>(<span className="at">sides =</span> <span className="st">"b"</span>) <span className="sc">+</span></span>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">90</span>, <span className="dv">10</span>), <span className="at">limits =</span> <span className="fu">c</span>(<span className="dv">18</span>, <span className="dv">90</span>)) <span className="sc">+</span></span>
<span id="cb8-36"><a aria-hidden="true" href="#cb8-36" tabindex="-1"></a>  <span className="co"># `scale_size_area()` ensures zero value is mapped to zero size</span></span>
<span id="cb8-37"><a aria-hidden="true" href="#cb8-37" tabindex="-1"></a>  <span className="fu">scale_size_area</span>(<span className="at">max_size =</span> <span className="dv">20</span>)  </span>
<span id="cb8-38"><a aria-hidden="true" href="#cb8-38" tabindex="-1"></a>p2</span>
<span id="cb8-39"><a aria-hidden="true" href="#cb8-39" tabindex="-1"></a></span><br/>
<span id="cb8-40"><a aria-hidden="true" href="#cb8-40" tabindex="-1"></a></span><br/>
<span id="cb8-41"><a aria-hidden="true" href="#cb8-41" tabindex="-1"></a><span className="co"># Add rectangles to annotate the years.</span></span>
<span id="cb8-42"><a aria-hidden="true" href="#cb8-42" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb8-43"><a aria-hidden="true" href="#cb8-43" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, </span>
<span id="cb8-44"><a aria-hidden="true" href="#cb8-44" tabindex="-1"></a>           <span className="at">xmin =</span> <span className="dv">350</span>, <span className="at">xmax =</span> <span className="dv">2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">5</span>, </span>
<span id="cb8-45"><a aria-hidden="true" href="#cb8-45" tabindex="-1"></a>           <span className="at">ymin =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="dv">48</span>), <span className="at">ymax =</span> <span className="fu">c</span>(<span className="dv">46</span>, <span className="dv">90</span>), </span>
<span id="cb8-46"><a aria-hidden="true" href="#cb8-46" tabindex="-1"></a>           <span className="at">alpha =</span> .<span className="dv">08</span>) <span className="sc">+</span></span>
<span id="cb8-47"><a aria-hidden="true" href="#cb8-47" tabindex="-1"></a>  <span className="co"># years text annotation</span></span>
<span id="cb8-48"><a aria-hidden="true" href="#cb8-48" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb8-49"><a aria-hidden="true" href="#cb8-49" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">1.2</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">5</span>, <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">42</span>, <span className="dv">87</span>), <span className="at">label =</span> <span className="fu">c</span>(<span className="dv">1800</span>, <span className="dv">2015</span>), </span>
<span id="cb8-50"><a aria-hidden="true" href="#cb8-50" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">7</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb8-51"><a aria-hidden="true" href="#cb8-51" tabindex="-1"></a>p3</span>
<span id="cb8-52"><a aria-hidden="true" href="#cb8-52" tabindex="-1"></a></span><br/>
<span id="cb8-53"><a aria-hidden="true" href="#cb8-53" tabindex="-1"></a></span><br/>
<span id="cb8-54"><a aria-hidden="true" href="#cb8-54" tabindex="-1"></a><span className="co"># Highlight three selected countries with the largest population in 2015.</span></span>
<span id="cb8-55"><a aria-hidden="true" href="#cb8-55" tabindex="-1"></a>d.big.countries <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb8-56"><a aria-hidden="true" href="#cb8-56" tabindex="-1"></a>  <span className="fu">filter</span>(country <span className="sc">%in%</span> <span className="fu">c</span>(<span className="st">"China"</span>, <span className="st">"India"</span>, <span className="st">"US"</span>))</span>
<span id="cb8-57"><a aria-hidden="true" href="#cb8-57" tabindex="-1"></a></span><br/>
<span id="cb8-58"><a aria-hidden="true" href="#cb8-58" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb8-59"><a aria-hidden="true" href="#cb8-59" tabindex="-1"></a>  <span className="co"># highlight the lines</span></span>
<span id="cb8-60"><a aria-hidden="true" href="#cb8-60" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">data =</span> d.big.countries,</span>
<span id="cb8-61"><a aria-hidden="true" href="#cb8-61" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">color =</span> country), <span className="at">linewidth =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">1</span>, .<span className="dv">5</span>, .<span className="dv">5</span>)) <span className="sc">+</span></span>
<span id="cb8-62"><a aria-hidden="true" href="#cb8-62" tabindex="-1"></a>  <span className="co"># highlight the points</span></span>
<span id="cb8-63"><a aria-hidden="true" href="#cb8-63" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">data =</span> d.big.countries,</span>
<span id="cb8-64"><a aria-hidden="true" href="#cb8-64" tabindex="-1"></a>             <span className="fu">aes</span>(<span className="at">size =</span> population, <span className="at">color =</span> country)) <span className="sc">+</span> </span>
<span id="cb8-65"><a aria-hidden="true" href="#cb8-65" tabindex="-1"></a>  <span className="co"># label with country names</span></span>
<span id="cb8-66"><a aria-hidden="true" href="#cb8-66" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> d.big.countries <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2015</span>), </span>
<span id="cb8-67"><a aria-hidden="true" href="#cb8-67" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">label =</span> country), </span>
<span id="cb8-68"><a aria-hidden="true" href="#cb8-68" tabindex="-1"></a>            <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">5</span>, <span className="dv">5</span>, <span className="dv">4</span>), <span className="at">fontface =</span> <span className="st">"bold"</span>) <span className="sc">+</span></span>
<span id="cb8-69"><a aria-hidden="true" href="#cb8-69" tabindex="-1"></a>  <span className="co"># add color to the three selected countries</span></span>
<span id="cb8-70"><a aria-hidden="true" href="#cb8-70" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"red4"</span>, <span className="st">"steelblue4"</span>, <span className="st">"darkgreen"</span>)) </span>
<span id="cb8-71"><a aria-hidden="true" href="#cb8-71" tabindex="-1"></a>p4</span>
<span id="cb8-72"><a aria-hidden="true" href="#cb8-72" tabindex="-1"></a></span><br/>
<span id="cb8-73"><a aria-hidden="true" href="#cb8-73" tabindex="-1"></a></span><br/>
<span id="cb8-74"><a aria-hidden="true" href="#cb8-74" tabindex="-1"></a><span className="co"># A final touch-up.</span></span>
<span id="cb8-75"><a aria-hidden="true" href="#cb8-75" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb8-76"><a aria-hidden="true" href="#cb8-76" tabindex="-1"></a>  <span className="co"># remove axial titles</span></span>
<span id="cb8-77"><a aria-hidden="true" href="#cb8-77" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>) <span className="sc">+</span> </span>
<span id="cb8-78"><a aria-hidden="true" href="#cb8-78" tabindex="-1"></a>  <span className="co"># add plot title, with text wrapping after ~50 characters each line</span></span>
<span id="cb8-79"><a aria-hidden="true" href="#cb8-79" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="fu">str_wrap</span>(</span>
<span id="cb8-80"><a aria-hidden="true" href="#cb8-80" tabindex="-1"></a>    <span className="st">"There has been significant increases in human life </span></span>
<span id="cb8-81"><a aria-hidden="true" href="#cb8-81" tabindex="-1"></a><span className="st">    span and population in the last two hundred years"</span>,</span>
<span id="cb8-82"><a aria-hidden="true" href="#cb8-82" tabindex="-1"></a>    <span className="at">width =</span> <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb8-83"><a aria-hidden="true" href="#cb8-83" tabindex="-1"></a>  <span className="co"># mark axial title inside the plot</span></span>
<span id="cb8-84"><a aria-hidden="true" href="#cb8-84" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb8-85"><a aria-hidden="true" href="#cb8-85" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">340</span>, <span className="dv">10</span><span className="sc">^</span><span className="fl">4.2</span>), <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">85</span>, <span className="dv">22</span>), </span>
<span id="cb8-86"><a aria-hidden="true" href="#cb8-86" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"life expectancy</span><span className="sc">\n</span><span className="st">in years"</span>, <span className="st">"GDP per capita (US $)"</span>), </span>
<span id="cb8-87"><a aria-hidden="true" href="#cb8-87" tabindex="-1"></a>           <span className="at">size =</span> <span className="fl">4.5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb8-88"><a aria-hidden="true" href="#cb8-88" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"grey30"</span>) <span className="sc">+</span></span>
<span id="cb8-89"><a aria-hidden="true" href="#cb8-89" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb8-90"><a aria-hidden="true" href="#cb8-90" tabindex="-1"></a>        <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="fl">14.5</span>, </span>
<span id="cb8-91"><a aria-hidden="true" href="#cb8-91" tabindex="-1"></a>                                  <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>)))</span>
<span id="cb8-92"><a aria-hidden="true" href="#cb8-92" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out the following line plot that visualizes the <Link to="../ggplot2-line-plot-cigarettes"><strong>rise and fall of the smoking popularity worldwide</strong></Link>, in particular in the United States, Germany, and France.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out the following line plot that visualizes the <Link to="../ggplot2-line-plot-social-mobility"><strong>social mobility: how does a father’s occupation impact his son’s career path</strong></Link>, based on a survey in the 1970s in the United States.</p>
<p><Link to="../ggplot2-line-plot-social-mobility">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}