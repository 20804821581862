import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2ScatterplotTechniques from '../graphics/penguins_theme_completed.png'; 
import imgGgplot2ScatterplotTechniquesWebp from '../graphics/penguins_theme_completed.webp'; 
export default function Ggplot2ScatterplotTechniques(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Four Great R Packages to Enhance Scatterplot in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2ScatterplotTechniquesWebp} />
    <img className="cover-img" src={imgGgplot2ScatterplotTechniques} />
  </picture>

<p>In this article, we’ll create the above scatterplot to demonstrate four cool techniques often used in the scatterplot and beyond:</p>
<ul>
<li><a href="#google_fonts">Load fonts from the Google Font Repository using <code>showtext</code> package.</a></li>
<li><a href="#regression_line_formula">Annotate regression lines with associated formula using <code>ggpubr</code> package.</a></li>
<li><a href="#encircle_highlighted_points">Encircle selected data points using <code>ggalt</code> package.</a></li>
<li><a href="#color_enriched_plot_title">Add format-enriched texts using <code>ggtext</code> package.</a></li>
</ul>
<p>All packages can be installed via <code>install.packages("package_name")</code>.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<p>We’ll use the popular <code>penguins</code> dataset from the <Link to="https://allisonhorst.github.io/palmerpenguins/"><code>palmerpenguins</code></Link> package for illustration.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(palmerpenguins) <span className="co"># data package</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">head</span>(penguins, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 8
<br/>  species island    bill_length_mm bill_depth_mm flipper_length_mm body_mass_g
<br/>  &lt;fct&gt;   &lt;fct&gt;              &lt;dbl&gt;         &lt;dbl&gt;             &lt;int&gt;       &lt;int&gt;
<br/>1 Adelie  Torgersen           39.1          18.7               181        3750
<br/>2 Adelie  Torgersen           39.5          17.4               186        3800
<br/>3 Adelie  Torgersen           40.3          18                 195        3250
<br/># ℹ 2 more variables: sex &lt;fct&gt;, year &lt;int&gt;</code></pre>
</div>
</div>
<p><span id="google_fonts">Load more fonts from <Link to="https://fonts.google.com/">Google Font Repository</Link>, and use <Link to="https://cran.rstudio.com/web/packages/showtext/vignettes/introduction.html">showtext</Link> to automatically render texts.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Lobster"</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>) <span className="co"># for most texts</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"lemon"</span>, <span className="at">family =</span> <span className="st">"lemon"</span>) <span className="co"># for species notation</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a></span><br/>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a><span className="co"># set the default theme (use "Lobster" as the default font)</span></span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_family =</span> <span className="st">"Lobster"</span>, <span className="at">base_size =</span> <span className="dv">16</span>))</span></code></pre></div>
</div>
<p>Create a basic scatterplot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> penguins <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> flipper_length_mm, <span className="at">y =</span> body_mass_g, </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>             <span className="at">color =</span> species, <span className="at">fill =</span> species)) <span className="sc">+</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>)) </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguins_jitter_point.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguins_jitter_point"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="regression_line_formula">Create a simple linear regression for each species, and plot the associated formula using the <Link to="https://rpkgs.datanovia.com/ggpubr/"><code>ggpubr</code></Link> package.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">library</span>(ggpubr) </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="co"># create a simple linear model </span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">geom_smooth</span>(<span className="at">method =</span> <span className="st">"lm"</span>) <span className="sc">+</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="co"># Draw equations</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">stat_regline_equation</span>(</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>    <span className="at">show.legend =</span> F, <span className="at">size =</span> <span className="dv">5</span>,</span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>    <span className="at">label.x =</span> <span className="dv">170</span>, </span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>    <span className="at">label.y =</span> <span className="fu">c</span>(<span className="dv">5400</span>, <span className="dv">5700</span>, <span className="dv">6000</span>)) </span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguins_ggpubr.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguins_ggpubr"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="encircle_highlighted_points">Use the <Link to="https://github.com/hrbrmstr/ggalt"><code>ggalt</code></Link> package to highlight selected species by encircling the associated data points. Use the same position of <code>jitter</code> to synchronize the points and the circles.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(ggalt)  </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a></span><br/>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="co"># highlight "Gentoo" species as shaded area</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">geom_encircle</span>(</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">data =</span> penguins <span className="sc">%&gt;%</span> <span className="fu">filter</span>(species <span className="sc">==</span> <span className="st">"Gentoo"</span>),</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>), <span className="at">alpha =</span> .<span className="dv">2</span>, </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>    <span className="at">s_shape =</span> <span className="dv">1</span>, <span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>  </span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>  <span className="co"># highlight the other two species in dashed outlines</span></span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>  <span className="fu">geom_encircle</span>(</span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>    <span className="at">data =</span> penguins <span className="sc">%&gt;%</span> <span className="fu">filter</span>(species <span className="sc">!=</span> <span className="st">"Gentoo"</span>),</span>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> <span className="dv">1</span>), <span className="co"># applies the encircling to all dataset</span></span>
<span id="cb6-14"><a aria-hidden="true" href="#cb6-14" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>),</span>
<span id="cb6-15"><a aria-hidden="true" href="#cb6-15" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">fill =</span> <span className="cn">NA</span>,</span>
<span id="cb6-16"><a aria-hidden="true" href="#cb6-16" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">2</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>,</span>
<span id="cb6-17"><a aria-hidden="true" href="#cb6-17" tabindex="-1"></a>    <span className="at">s_shape =</span> <span className="dv">1</span>, <span className="at">expand =</span> <span className="dv">0</span>)</span>
<span id="cb6-18"><a aria-hidden="true" href="#cb6-18" tabindex="-1"></a></span><br/>
<span id="cb6-19"><a aria-hidden="true" href="#cb6-19" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguins_ggalt.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguins_ggalt"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="color_enriched_plot_title">Create a color-enriched plot title, with penguin species annotated in color. This is a more concise alternative to the color legend. This involves two steps. <strong>First step:</strong> use HTML to markup the title string.</span></p>
<ul>
<li><p>Put the long string inside <code>c()</code>. You can make a simple line break in the script (pressing the <em>Enter</em> or <em>return</em> key) <em>without</em> it being displayed in the rendered graphic (in the context of <code>ggtext</code> discussed later).</p></li>
<li><p>Use the HTML tag <code>&lt;br&gt;</code> to make an indicated line break to be displayed in the graphic. (or use automatic text wrapping; see below)</p></li>
<li><p>Use HTML to specify the text color.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>mytitle <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="st">"Among the adult foraging penguins near &lt;br&gt; Palmer</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="st">  Station in Antarctica,</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a><span className="st">  &lt;span style = 'color:DodgerBlue; font-family: lemon;'&gt;Gentoo&lt;/span&gt; </span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a><span className="st">  is much &lt;br&gt; bigger than </span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a><span className="st">  &lt;span style = 'color:LimeGreen;font-family: lemon;'&gt;Chinstrap&lt;/span&gt; and </span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a><span className="st">  &lt;span style = 'color:red;  font-family: lemon;'&gt;Adelie&lt;/span&gt;"</span>)</span></code></pre></div>
</div>
<p><strong>Second step:</strong> use <code>element_markdown()</code> from the <Link to="https://wilkelab.org/ggtext/"><code>ggtext</code></Link> package to render the plot title. The syntax in <code>element_markdown()</code> is the same as in <code>element_text()</code>. (Alternatively, you can use <code>element_textbox_simple()</code> to <em>automatically wraps texts</em>, as in <Link to="../ggplot2-segment-US-president-terms">this example</Link>)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a></span><br/>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> mytitle) <span className="sc">+</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_markdown</span>(<span className="at">size =</span> <span className="dv">15</span>, <span className="at">color =</span> <span className="st">"snow4"</span>), </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="co"># remove the legend</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguins_ggtext.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguins_ggtext"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Polish up a few more details.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># axes: finer breaks, and rename the title</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">160</span>, <span className="dv">240</span>, <span className="dv">10</span>), </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"Flipper length (mm)"</span>) <span className="sc">+</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">3000</span>, <span className="dv">7000</span>, <span className="dv">1000</span>), </span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"Body weight (g)"</span>) <span className="sc">+</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>    <span className="co"># increase margin on the right side of y-axis title, and on top of x-axis title</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>    <span className="co"># suffix .x and .y are required for margin update</span></span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>)),</span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>    <span className="co"># remove panel grid</span></span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>    <span className="co"># background color</span></span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>    <span className="co"># add more margins around the plot</span></span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>)</span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a>  )</span>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguins_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/penguins_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot. Here we save the plot to the folder “graphics”. This folder is under the same folder of the source code.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"penguis.pdf"</span>,</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="fu">library</span>(palmerpenguins) <span className="co"># data package</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a></span><br/>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a><span className="fu">head</span>(penguins, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a></span><br/>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a><span className="co"># Load more fonts from Google Font Repository.</span></span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Lobster"</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>) <span className="co"># for most texts</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"lemon"</span>, <span className="at">family =</span> <span className="st">"lemon"</span>) <span className="co"># for species notation</span></span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a></span><br/>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a><span className="co"># set as default theme (with "Lobster" being the default font)</span></span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_family =</span> <span className="st">"Lobster"</span>, <span className="at">base_size =</span> <span className="dv">16</span>))</span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a></span><br/>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a></span><br/>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a><span className="co"># Create a basic scatterplot.</span></span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> penguins <span className="sc">%&gt;%</span> </span>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> flipper_length_mm, <span className="at">y =</span> body_mass_g, </span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a>             <span className="at">color =</span> species, <span className="at">fill =</span> species)) <span className="sc">+</span> </span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>)) </span>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a>p1</span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a></span><br/>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a></span><br/>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a><span className="co"># Create a simple linear regression for each species, and plot the associated formula. </span></span>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a><span className="fu">library</span>(ggpubr) </span>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a></span><br/>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a>  <span className="co"># create a simple linear model </span></span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a>  <span className="fu">geom_smooth</span>(<span className="at">method =</span> <span className="st">"lm"</span>) <span className="sc">+</span></span>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>  <span className="co"># Draw equations</span></span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a>  <span className="fu">stat_regline_equation</span>(</span>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a>    <span className="at">show.legend =</span> F, <span className="at">size =</span> <span className="dv">5</span>,</span>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a>    <span className="at">label.x =</span> <span className="dv">170</span>, </span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a>    <span className="at">label.y =</span> <span className="fu">c</span>(<span className="dv">5400</span>, <span className="dv">5700</span>, <span className="dv">6000</span>)) </span>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a>p2</span>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a></span><br/>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a></span><br/>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a><span className="co"># Highlight selected species by encircling the associated data points. </span></span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a><span className="fu">library</span>(ggalt)  </span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a></span><br/>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>  <span className="co"># highlight "Gentoo" species as shaded area</span></span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a>  <span className="fu">geom_encircle</span>(</span>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a>    <span className="at">data =</span> penguins <span className="sc">%&gt;%</span> <span className="fu">filter</span>(species <span className="sc">==</span> <span className="st">"Gentoo"</span>),</span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>), <span className="at">alpha =</span> .<span className="dv">2</span>, </span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>    <span className="at">s_shape =</span> <span className="dv">1</span>, <span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a>  </span>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a>  <span className="co"># highlight the other two species in dashed outlines</span></span>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a>  <span className="fu">geom_encircle</span>(</span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>    <span className="at">data =</span> penguins <span className="sc">%&gt;%</span> <span className="fu">filter</span>(species <span className="sc">!=</span> <span className="st">"Gentoo"</span>),</span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> <span className="dv">1</span>), <span className="co"># applies the encircling to all dataset</span></span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>),</span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">fill =</span> <span className="cn">NA</span>,</span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">2</span>, <span className="at">linetype =</span> <span className="st">"dashed"</span>,</span>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a>    <span className="at">s_shape =</span> <span className="dv">1</span>, <span className="at">expand =</span> <span className="dv">0</span>)</span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a>p3</span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a></span><br/>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a></span><br/>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a><span className="co"># Create a plot title, with penguin species highlighted in color. </span></span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a></span><br/>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a>mytitle <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a>  <span className="st">"Among the adult foraging penguins near &lt;br&gt; Palmer</span></span>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a><span className="st">  Station in Antarctica,</span></span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a><span className="st">  &lt;span style = 'color:DodgerBlue; font-family: lemon;'&gt;Gentoo&lt;/span&gt; </span></span>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a><span className="st">  is much &lt;br&gt; bigger than </span></span>
<span id="cb11-68"><a aria-hidden="true" href="#cb11-68" tabindex="-1"></a><span className="st">  &lt;span style = 'color:LimeGreen;font-family: lemon;'&gt;Chinstrap&lt;/span&gt; and </span></span>
<span id="cb11-69"><a aria-hidden="true" href="#cb11-69" tabindex="-1"></a><span className="st">  &lt;span style = 'color:red;  font-family: lemon;'&gt;Adelie&lt;/span&gt;"</span>)</span>
<span id="cb11-70"><a aria-hidden="true" href="#cb11-70" tabindex="-1"></a></span><br/>
<span id="cb11-71"><a aria-hidden="true" href="#cb11-71" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb11-72"><a aria-hidden="true" href="#cb11-72" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> mytitle) <span className="sc">+</span></span>
<span id="cb11-73"><a aria-hidden="true" href="#cb11-73" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_markdown</span>(<span className="at">size =</span> <span className="dv">15</span>, <span className="at">color =</span> <span className="st">"snow4"</span>), </span>
<span id="cb11-74"><a aria-hidden="true" href="#cb11-74" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="co"># remove the legend</span></span>
<span id="cb11-75"><a aria-hidden="true" href="#cb11-75" tabindex="-1"></a>p4</span>
<span id="cb11-76"><a aria-hidden="true" href="#cb11-76" tabindex="-1"></a></span><br/>
<span id="cb11-77"><a aria-hidden="true" href="#cb11-77" tabindex="-1"></a></span><br/>
<span id="cb11-78"><a aria-hidden="true" href="#cb11-78" tabindex="-1"></a><span className="co"># Polish up a few more details. </span></span>
<span id="cb11-79"><a aria-hidden="true" href="#cb11-79" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-80"><a aria-hidden="true" href="#cb11-80" tabindex="-1"></a>  <span className="co"># axes: finer breaks, and rename the title</span></span>
<span id="cb11-81"><a aria-hidden="true" href="#cb11-81" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">160</span>, <span className="dv">240</span>, <span className="dv">10</span>), </span>
<span id="cb11-82"><a aria-hidden="true" href="#cb11-82" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"Flipper length (mm)"</span>) <span className="sc">+</span></span>
<span id="cb11-83"><a aria-hidden="true" href="#cb11-83" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">3000</span>, <span className="dv">7000</span>, <span className="dv">1000</span>), </span>
<span id="cb11-84"><a aria-hidden="true" href="#cb11-84" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"Body weight (g)"</span>) <span className="sc">+</span></span>
<span id="cb11-85"><a aria-hidden="true" href="#cb11-85" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-86"><a aria-hidden="true" href="#cb11-86" tabindex="-1"></a>    <span className="co"># increase margin on the right of y-axis title, and on top of x-axis title</span></span>
<span id="cb11-87"><a aria-hidden="true" href="#cb11-87" tabindex="-1"></a>    <span className="co"># suffix .x and .y are required for margin update</span></span>
<span id="cb11-88"><a aria-hidden="true" href="#cb11-88" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>)),</span>
<span id="cb11-89"><a aria-hidden="true" href="#cb11-89" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb11-90"><a aria-hidden="true" href="#cb11-90" tabindex="-1"></a>    <span className="co"># remove panel grid</span></span>
<span id="cb11-91"><a aria-hidden="true" href="#cb11-91" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-92"><a aria-hidden="true" href="#cb11-92" tabindex="-1"></a>    <span className="co"># background color</span></span>
<span id="cb11-93"><a aria-hidden="true" href="#cb11-93" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb11-94"><a aria-hidden="true" href="#cb11-94" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb11-95"><a aria-hidden="true" href="#cb11-95" tabindex="-1"></a>    <span className="co"># add more margins around the plot</span></span>
<span id="cb11-96"><a aria-hidden="true" href="#cb11-96" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>)</span>
<span id="cb11-97"><a aria-hidden="true" href="#cb11-97" tabindex="-1"></a>  )</span>
<span id="cb11-98"><a aria-hidden="true" href="#cb11-98" tabindex="-1"></a>p5</span>
<span id="cb11-99"><a aria-hidden="true" href="#cb11-99" tabindex="-1"></a></span><br/>
<span id="cb11-100"><a aria-hidden="true" href="#cb11-100" tabindex="-1"></a></span><br/>
<span id="cb11-101"><a aria-hidden="true" href="#cb11-101" tabindex="-1"></a><span className="co"># save the plot</span></span>
<span id="cb11-102"><a aria-hidden="true" href="#cb11-102" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"penguis.pdf"</span>,</span>
<span id="cb11-103"><a aria-hidden="true" href="#cb11-103" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">5</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>A scatterplot can be further enhanced by visualizing the marginal (univariate) distribution of the x and y variables, and the bivariate distribution pattern with confidence ellipses. Check out the following <Link to="../ggplot2-penguin-scatterplot-ellipse-ggExtra"><strong>scatterplot with confidence ellipses and marginal visualization</strong></Link>.</p>
<p><Link to="../ggplot2-penguin-scatterplot-ellipse-ggExtra">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/penguin_ggExtra_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/penguin_ggExtra_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>A scatterplot is often drawn on a semi-logarithmic or double-log scale when there is significant data skewness in one or two axes. Check out the following <Link to="../ggplot2-scatterplot-urbanization"><strong>scatterplot on semi-logarithmic scale</strong></Link> that unveils linear relationship between the percentage of urbanization and log(GDP per capita).</p>
<p><Link to="../ggplot2-scatterplot-urbanization">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/scatterplot_urbanization_ggrepel_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/scatterplot_urbanization_ggrepel_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}