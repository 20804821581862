import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2PopulationPyramid from '../graphics/population_pyramid_theme_completed.png'; 
import imgGgplot2PopulationPyramidWebp from '../graphics/population_pyramid_theme_completed.webp'; 
export default function Ggplot2PopulationPyramid(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Elegant Population Pyramid in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2PopulationPyramidWebp} />
    <img className="cover-img" src={imgGgplot2PopulationPyramid} />
  </picture>

<p>In this article, we’ll create a population pyramid displaying the German population structure in 2023. <strong>Major techniques covered in this article include:</strong></p>
<ul>
<li>Modify the dataset structure to fit the graphic designs.</li>
<li>Draw a population pyramid.</li>
<li>Create text and rectangle annotation.</li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr) <span className="co"># for string manipulation</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>())</span></code></pre></div>
</div>
<p>The raw data is sourced from the <Link to="https://service.destatis.de/bevoelkerungspyramide/">Federal Statistical Office of Germany</Link>. The dataset (a cleaned version) used in this visualization can be downloaded <strong><a className="downloadData" href="dataset/r-gallery/germany_population_2023.csv">here</a></strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/germany_population_2023.csv"</span>, </span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="co"># remove the "X" letters otherwise added to headers started with numbers</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="at">check.names =</span> F)</span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a></span><br/>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="co"># convert to tibble format</span></span>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a></span><br/>
<span id="cb2-8"><a aria-hidden="true" href="#cb2-8" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  age       f     m
<br/>  &lt;chr&gt; &lt;int&gt; &lt;int&gt;
<br/>1 0_1     369   389
<br/>2 1_2     368   388
<br/>3 2_3     394   416</code></pre>
</div>
</div>
<p><strong>Transform the dataset into the <Link to="https://cran.r-project.org/web/packages/tidyr/vignettes/tidy-data.html">tidy format</Link>:</strong> gender attributes (female <code>f</code> and male <code>m</code>) as one column, and the associated population as another column.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>age, <span className="at">names_to =</span> <span className="st">"gender"</span>, <span className="at">values_to =</span> <span className="st">"population"</span>)</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 3
<br/>  age   gender population
<br/>  &lt;chr&gt; &lt;chr&gt;       &lt;int&gt;
<br/>1 0_1   f             369
<br/>2 0_1   m             389
<br/>3 1_2   f             368
<br/>4 1_2   m             388</code></pre>
</div>
</div>
<p><span id="pyramid"><strong>Modify the <code>population</code> variable to create bars in a symmetrical layout:</strong></span></p>
<ul>
<li><p>The bars corresponding to <span><strong>male</strong></span> occupies the <span><strong>positive</strong></span> y-axis range, and bars of the <span><strong>female</strong></span> takes the <span><strong>negative</strong></span> y-axis range.</p></li>
<li><p>Increase the bar length by <strong>extra 80 units for each gender to offset the overlay of the central banner</strong>, which covers the bars of each gender by 80 units.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">population =</span> <span className="fu">ifelse</span>(</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>    gender <span className="sc">==</span> <span className="st">"f"</span>, <span className="sc">-</span> population <span className="sc">-</span> <span className="dv">80</span>, population <span className="sc">+</span> <span className="dv">80</span> ))</span></code></pre></div>
</div>
<p><strong>Arrange the population bars by the age.</strong> While the dataset <code>d.tidy</code> is already arranged in order of age, the <code>age</code> variable is of a “character” type, and does not “memorize” such order when rendered in graphics. In order to retain such order, we need to convert the <code>age</code> variable to a factor, with levels explicitly indicated as in the current row order of <code>d.tidy</code>. Use <code>unique</code> to extract distinct ages, as the <code>levels</code> argument requires no duplication in the input values. (Learn all essential skills in <em>reordering graphic elements</em> in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete tutorial</Link>.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>d.tidy<span className="sc">$</span>age <span className="ot">&lt;-</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">factor</span>(d.tidy<span className="sc">$</span>age, <span className="at">levels =</span> <span className="fu">unique</span>(d.tidy<span className="sc">$</span>age))</span></code></pre></div>
</div>
<p><span id="masking"><strong>Highlight the population difference between genders.</strong> Here we create a new column <code>pop.min</code> to record the <em>minimum</em> population value of the two genders in each age group. The minimum values will be visualized as a masking layer in lighter color, and thus highlight the excess population in one of the genders.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">group_by</span>(age) <span className="sc">%&gt;%</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">pop.min =</span> <span className="fu">min</span>(<span className="fu">abs</span>(population)), </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>         <span className="co"># female in negative values, male in positive values</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>         <span className="at">pop.min =</span> <span className="fu">ifelse</span>(gender <span className="sc">==</span> <span className="st">"m"</span>, pop.min, <span className="sc">-</span>pop.min))</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a></span><br/>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">4</span>) <span className="co"># ready for visualization</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 4
<br/># Groups:   age [2]
<br/>  age   gender population pop.min
<br/>  &lt;fct&gt; &lt;chr&gt;       &lt;dbl&gt;   &lt;dbl&gt;
<br/>1 0_1   f            -449    -449
<br/>2 0_1   m             469     449
<br/>3 1_2   f            -448    -448
<br/>4 1_2   m             468     448</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>Create bar plots with flipped axes.</strong> As the population value of males are positive, and females are negative, this creates bars in a symmetrical layout as mirror images (thanks to <a href="#pyramid">this step</a> of data processing).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> age, <span className="at">y =</span> population, <span className="at">fill =</span> gender)) <span className="sc">+</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">width =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">clip =</span> <span className="st">"off"</span>) </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_bars.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_bars"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Highlight population difference between genders.</strong> The regions more saturated in color signify the population excess in one gender compared to the other. This effect is achieved by generating a masking layer of bars with semi-transparent white color, with bar length equal to the minimum population of the genders at each age (thanks to <a href="#masking">this step</a> of data processing).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="fu">aes</span>(<span className="at">y =</span> pop.min), </span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"white"</span>, <span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">width =</span> <span className="dv">1</span>,</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>           <span className="at">position =</span> <span className="st">"identity"</span>)  </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_masking.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_masking"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Create a central banner overlay on the bars.</strong> This banner will contain the age labels, in place of the original x-axis (left vertical). Centered at y = 0, the width of the banner spans a total of 160 units, covering 80 units of bar length of each gender. To offset the coverage of the central banner to the underlying bars, we had included an additional 80 units to the population values at <a href="#pyramid">this early step</a> of data processing.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"rect"</span>, <span className="co"># create rectangles</span></span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>    <span className="at">xmin =</span> <span className="sc">-</span><span className="cn">Inf</span>, <span className="at">xmax =</span> <span className="cn">Inf</span>, </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>    <span className="at">ymin =</span> <span className="dv">80</span>, <span className="at">ymax =</span> <span className="sc">-</span><span className="dv">80</span>,</span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>    <span className="at">fill =</span> <span className="st">"floralwhite"</span>)</span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_rectangles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_rectangles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Create age labels in the central banner</strong>. We’ll remove the x-axis labels later.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>    <span className="at">x =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>), <span className="at">y =</span> <span className="dv">0</span>, </span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>    <span className="at">label =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>),</span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_text.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_text"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Revise the y-axis (bottom horizontal), and add plot titles.</strong></p>
<ul>
<li><p>To correct for the coverage of the central banner, the y-axis for male and female data should be shifted towards the right and left by an additional 80 units, respectively. Therefore, here we calculate the updated y-axis breaks, and label the axis with positive population values.</p></li>
<li><p>Use white spaces in the y-axis title to symmetrically split the title into two pieces, each half for each of the genders.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>breaks <span className="ot">&lt;-</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">700</span>, <span className="at">by =</span> <span className="dv">100</span>)</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>breaks.updated <span className="ot">&lt;-</span> <span className="fu">c</span>(breaks <span className="sc">+</span> <span className="dv">80</span>, <span className="sc">-</span> breaks <span className="sc">-</span> <span className="dv">80</span>) </span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a></span><br/>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>    <span className="at">breaks =</span> breaks.updated,</span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x) &#123;<span className="fu">abs</span>(x) <span className="sc">-</span> <span className="dv">80</span> &#125;) <span className="sc">+</span> </span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>    <span className="at">y =</span> <span className="st">"Female (in thousands)                 Male (in thousands)   "</span>,</span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Population Pyramid in Germany 2023"</span>,</span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>    <span className="at">caption =</span> <span className="st">"data source: https://service.destatis.de/bevoelkerungspyramide/"</span>) </span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_axis.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_axis"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add <Link to="https://fonts.google.com/">Google font</Link>, and polish up the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Abril Fatface"</span>, <span className="at">family =</span> <span className="st">"fatface"</span>)</span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a></span><br/>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> <span className="co"># an empty canvas, with removal of all axial elements</span></span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>)),</span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>, <span className="at">b =</span> <span className="dv">5</span>)),</span>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">face =</span> <span className="st">"bold"</span>, </span>
<span id="cb15-11"><a aria-hidden="true" href="#cb15-11" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>), <span className="at">family =</span> <span className="st">"fatface"</span>),</span>
<span id="cb15-12"><a aria-hidden="true" href="#cb15-12" tabindex="-1"></a>    <span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">9</span>, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb15-13"><a aria-hidden="true" href="#cb15-13" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">10</span>), <span className="co"># increase plot top and bottom margin</span></span>
<span id="cb15-14"><a aria-hidden="true" href="#cb15-14" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb15-15"><a aria-hidden="true" href="#cb15-15" tabindex="-1"></a></span><br/>
<span id="cb15-16"><a aria-hidden="true" href="#cb15-16" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/population_pyramid_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/population_pyramid_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="do">### Packages and data import</span></span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a><span className="fu">library</span>(stringr)   <span className="co"># for string manipulation</span></span>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a><span className="fu">library</span>(gganimate) <span className="co"># create animation</span></span>
<span id="cb16-7"><a aria-hidden="true" href="#cb16-7" tabindex="-1"></a></span><br/>
<span id="cb16-8"><a aria-hidden="true" href="#cb16-8" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>())</span>
<span id="cb16-9"><a aria-hidden="true" href="#cb16-9" tabindex="-1"></a></span><br/>
<span id="cb16-10"><a aria-hidden="true" href="#cb16-10" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(</span>
<span id="cb16-11"><a aria-hidden="true" href="#cb16-11" tabindex="-1"></a>  <span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/germany_population_2023.csv"</span>, </span>
<span id="cb16-12"><a aria-hidden="true" href="#cb16-12" tabindex="-1"></a>  <span className="co"># remove the "X" letters otherwise added to headers started with numbers</span></span>
<span id="cb16-13"><a aria-hidden="true" href="#cb16-13" tabindex="-1"></a>  <span className="at">check.names =</span> F</span>
<span id="cb16-14"><a aria-hidden="true" href="#cb16-14" tabindex="-1"></a>)</span>
<span id="cb16-15"><a aria-hidden="true" href="#cb16-15" tabindex="-1"></a></span><br/>
<span id="cb16-16"><a aria-hidden="true" href="#cb16-16" tabindex="-1"></a>d <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="co"># convert to tibble format</span></span>
<span id="cb16-17"><a aria-hidden="true" href="#cb16-17" tabindex="-1"></a></span><br/>
<span id="cb16-18"><a aria-hidden="true" href="#cb16-18" tabindex="-1"></a><span className="fu">head</span>(d, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb16-19"><a aria-hidden="true" href="#cb16-19" tabindex="-1"></a></span><br/>
<span id="cb16-20"><a aria-hidden="true" href="#cb16-20" tabindex="-1"></a><span className="co"># Transform the dataset into a tidy format</span></span>
<span id="cb16-21"><a aria-hidden="true" href="#cb16-21" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d <span className="sc">%&gt;%</span> </span>
<span id="cb16-22"><a aria-hidden="true" href="#cb16-22" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>age, <span className="at">names_to =</span> <span className="st">"gender"</span>, <span className="at">values_to =</span> <span className="st">"population"</span>)</span>
<span id="cb16-23"><a aria-hidden="true" href="#cb16-23" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="dv">4</span>)</span>
<span id="cb16-24"><a aria-hidden="true" href="#cb16-24" tabindex="-1"></a></span><br/>
<span id="cb16-25"><a aria-hidden="true" href="#cb16-25" tabindex="-1"></a></span><br/>
<span id="cb16-26"><a aria-hidden="true" href="#cb16-26" tabindex="-1"></a><span className="co"># Put the male data at the positive range of y axis, and female data at negative range. </span></span>
<span id="cb16-27"><a aria-hidden="true" href="#cb16-27" tabindex="-1"></a><span className="co"># And meanwhile increase the bar length by extra 80 units </span></span>
<span id="cb16-28"><a aria-hidden="true" href="#cb16-28" tabindex="-1"></a><span className="co"># to offset the overlay of the central banner which covers the bars of each gender by 80 units.</span></span>
<span id="cb16-29"><a aria-hidden="true" href="#cb16-29" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb16-30"><a aria-hidden="true" href="#cb16-30" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">population =</span> <span className="fu">ifelse</span>(</span>
<span id="cb16-31"><a aria-hidden="true" href="#cb16-31" tabindex="-1"></a>    gender <span className="sc">==</span> <span className="st">"f"</span>, <span className="sc">-</span> population <span className="sc">-</span> <span className="dv">80</span>, population <span className="sc">+</span> <span className="dv">80</span> ))</span>
<span id="cb16-32"><a aria-hidden="true" href="#cb16-32" tabindex="-1"></a></span><br/>
<span id="cb16-33"><a aria-hidden="true" href="#cb16-33" tabindex="-1"></a></span><br/>
<span id="cb16-34"><a aria-hidden="true" href="#cb16-34" tabindex="-1"></a><span className="co"># Display the population pyramid in order of age.</span></span>
<span id="cb16-35"><a aria-hidden="true" href="#cb16-35" tabindex="-1"></a>d.tidy<span className="sc">$</span>age <span className="ot">&lt;-</span> </span>
<span id="cb16-36"><a aria-hidden="true" href="#cb16-36" tabindex="-1"></a>  <span className="fu">factor</span>(d.tidy<span className="sc">$</span>age, <span className="at">levels =</span> <span className="fu">unique</span>(d.tidy<span className="sc">$</span>age))</span>
<span id="cb16-37"><a aria-hidden="true" href="#cb16-37" tabindex="-1"></a></span><br/>
<span id="cb16-38"><a aria-hidden="true" href="#cb16-38" tabindex="-1"></a></span><br/>
<span id="cb16-39"><a aria-hidden="true" href="#cb16-39" tabindex="-1"></a><span className="co"># Highlight the population difference between genders.</span></span>
<span id="cb16-40"><a aria-hidden="true" href="#cb16-40" tabindex="-1"></a>d.tidy <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb16-41"><a aria-hidden="true" href="#cb16-41" tabindex="-1"></a>  <span className="fu">group_by</span>(age) <span className="sc">%&gt;%</span></span>
<span id="cb16-42"><a aria-hidden="true" href="#cb16-42" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">pop.min =</span> <span className="fu">min</span>(<span className="fu">abs</span>(population)), </span>
<span id="cb16-43"><a aria-hidden="true" href="#cb16-43" tabindex="-1"></a>         <span className="co"># female in negative values, male in positive values</span></span>
<span id="cb16-44"><a aria-hidden="true" href="#cb16-44" tabindex="-1"></a>         <span className="at">pop.min =</span> <span className="fu">ifelse</span>(gender <span className="sc">==</span> <span className="st">"m"</span>, pop.min, <span className="sc">-</span>pop.min))</span>
<span id="cb16-45"><a aria-hidden="true" href="#cb16-45" tabindex="-1"></a></span><br/>
<span id="cb16-46"><a aria-hidden="true" href="#cb16-46" tabindex="-1"></a><span className="fu">head</span>(d.tidy, <span className="at">n =</span> <span className="dv">4</span>) <span className="co"># ready for visualization</span></span>
<span id="cb16-47"><a aria-hidden="true" href="#cb16-47" tabindex="-1"></a></span><br/>
<span id="cb16-48"><a aria-hidden="true" href="#cb16-48" tabindex="-1"></a><span className="do">### Visualization ----------------------------------------</span></span>
<span id="cb16-49"><a aria-hidden="true" href="#cb16-49" tabindex="-1"></a></span><br/>
<span id="cb16-50"><a aria-hidden="true" href="#cb16-50" tabindex="-1"></a><span className="co"># Create bar plots with flipped axes.</span></span>
<span id="cb16-51"><a aria-hidden="true" href="#cb16-51" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> d.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb16-52"><a aria-hidden="true" href="#cb16-52" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> age, <span className="at">y =</span> population, <span className="at">fill =</span> gender)) <span className="sc">+</span></span>
<span id="cb16-53"><a aria-hidden="true" href="#cb16-53" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">width =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb16-54"><a aria-hidden="true" href="#cb16-54" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">clip =</span> <span className="st">"off"</span>) </span>
<span id="cb16-55"><a aria-hidden="true" href="#cb16-55" tabindex="-1"></a>p1</span>
<span id="cb16-56"><a aria-hidden="true" href="#cb16-56" tabindex="-1"></a></span><br/>
<span id="cb16-57"><a aria-hidden="true" href="#cb16-57" tabindex="-1"></a></span><br/>
<span id="cb16-58"><a aria-hidden="true" href="#cb16-58" tabindex="-1"></a><span className="co"># Highlight population difference between genders.</span></span>
<span id="cb16-59"><a aria-hidden="true" href="#cb16-59" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb16-60"><a aria-hidden="true" href="#cb16-60" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="fu">aes</span>(<span className="at">y =</span> pop.min), </span>
<span id="cb16-61"><a aria-hidden="true" href="#cb16-61" tabindex="-1"></a>           <span className="at">fill =</span> <span className="st">"white"</span>, <span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">width =</span> <span className="dv">1</span>,</span>
<span id="cb16-62"><a aria-hidden="true" href="#cb16-62" tabindex="-1"></a>           <span className="at">position =</span> <span className="st">"identity"</span>)  </span>
<span id="cb16-63"><a aria-hidden="true" href="#cb16-63" tabindex="-1"></a>p2</span>
<span id="cb16-64"><a aria-hidden="true" href="#cb16-64" tabindex="-1"></a></span><br/>
<span id="cb16-65"><a aria-hidden="true" href="#cb16-65" tabindex="-1"></a></span><br/>
<span id="cb16-66"><a aria-hidden="true" href="#cb16-66" tabindex="-1"></a><span className="co"># Create a central banner overlay on the bars.</span></span>
<span id="cb16-67"><a aria-hidden="true" href="#cb16-67" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb16-68"><a aria-hidden="true" href="#cb16-68" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb16-69"><a aria-hidden="true" href="#cb16-69" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"rect"</span>, <span className="co"># create rectangles</span></span>
<span id="cb16-70"><a aria-hidden="true" href="#cb16-70" tabindex="-1"></a>    <span className="at">xmin =</span> <span className="sc">-</span><span className="cn">Inf</span>, <span className="at">xmax =</span> <span className="cn">Inf</span>, </span>
<span id="cb16-71"><a aria-hidden="true" href="#cb16-71" tabindex="-1"></a>    <span className="at">ymin =</span> <span className="dv">80</span>, <span className="at">ymax =</span> <span className="sc">-</span><span className="dv">80</span>,</span>
<span id="cb16-72"><a aria-hidden="true" href="#cb16-72" tabindex="-1"></a>    <span className="at">fill =</span> <span className="st">"floralwhite"</span>)</span>
<span id="cb16-73"><a aria-hidden="true" href="#cb16-73" tabindex="-1"></a>p3</span>
<span id="cb16-74"><a aria-hidden="true" href="#cb16-74" tabindex="-1"></a></span><br/>
<span id="cb16-75"><a aria-hidden="true" href="#cb16-75" tabindex="-1"></a></span><br/>
<span id="cb16-76"><a aria-hidden="true" href="#cb16-76" tabindex="-1"></a><span className="co"># Label with ages in the central banner.</span></span>
<span id="cb16-77"><a aria-hidden="true" href="#cb16-77" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb16-78"><a aria-hidden="true" href="#cb16-78" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb16-79"><a aria-hidden="true" href="#cb16-79" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>,</span>
<span id="cb16-80"><a aria-hidden="true" href="#cb16-80" tabindex="-1"></a>    <span className="at">x =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>), <span className="at">y =</span> <span className="dv">0</span>, </span>
<span id="cb16-81"><a aria-hidden="true" href="#cb16-81" tabindex="-1"></a>    <span className="at">label =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">100</span>, <span className="dv">5</span>),</span>
<span id="cb16-82"><a aria-hidden="true" href="#cb16-82" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">3</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb16-83"><a aria-hidden="true" href="#cb16-83" tabindex="-1"></a>p4</span>
<span id="cb16-84"><a aria-hidden="true" href="#cb16-84" tabindex="-1"></a></span><br/>
<span id="cb16-85"><a aria-hidden="true" href="#cb16-85" tabindex="-1"></a></span><br/>
<span id="cb16-86"><a aria-hidden="true" href="#cb16-86" tabindex="-1"></a><span className="co"># Revise the y axis (horizontal), and add plot titles</span></span>
<span id="cb16-87"><a aria-hidden="true" href="#cb16-87" tabindex="-1"></a>breaks <span className="ot">&lt;-</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">700</span>, <span className="at">by =</span> <span className="dv">100</span>)</span>
<span id="cb16-88"><a aria-hidden="true" href="#cb16-88" tabindex="-1"></a>breaks.updated <span className="ot">&lt;-</span> <span className="fu">c</span>(breaks <span className="sc">+</span> <span className="dv">80</span>, <span className="sc">-</span> breaks <span className="sc">-</span> <span className="dv">80</span>)</span>
<span id="cb16-89"><a aria-hidden="true" href="#cb16-89" tabindex="-1"></a></span><br/>
<span id="cb16-90"><a aria-hidden="true" href="#cb16-90" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb16-91"><a aria-hidden="true" href="#cb16-91" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb16-92"><a aria-hidden="true" href="#cb16-92" tabindex="-1"></a>    <span className="at">breaks =</span> breaks.updated,</span>
<span id="cb16-93"><a aria-hidden="true" href="#cb16-93" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x) &#123;<span className="fu">abs</span>(x) <span className="sc">-</span> <span className="dv">80</span> &#125;) <span className="sc">+</span></span>
<span id="cb16-94"><a aria-hidden="true" href="#cb16-94" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb16-95"><a aria-hidden="true" href="#cb16-95" tabindex="-1"></a>    <span className="at">y =</span> <span className="st">"Female (in thousands)                 Male (in thousands)   "</span>,</span>
<span id="cb16-96"><a aria-hidden="true" href="#cb16-96" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Population Pyramid in Germany 2023"</span>,</span>
<span id="cb16-97"><a aria-hidden="true" href="#cb16-97" tabindex="-1"></a>    <span className="at">caption =</span> <span className="st">"data source: https://service.destatis.de/bevoelkerungspyramide/"</span>) </span>
<span id="cb16-98"><a aria-hidden="true" href="#cb16-98" tabindex="-1"></a>p5</span>
<span id="cb16-99"><a aria-hidden="true" href="#cb16-99" tabindex="-1"></a></span><br/>
<span id="cb16-100"><a aria-hidden="true" href="#cb16-100" tabindex="-1"></a></span><br/>
<span id="cb16-101"><a aria-hidden="true" href="#cb16-101" tabindex="-1"></a><span className="co"># Add Google font, and polish up the theme. </span></span>
<span id="cb16-102"><a aria-hidden="true" href="#cb16-102" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb16-103"><a aria-hidden="true" href="#cb16-103" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb16-104"><a aria-hidden="true" href="#cb16-104" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Abril Fatface"</span>, <span className="at">family =</span> <span className="st">"fatface"</span>)</span>
<span id="cb16-105"><a aria-hidden="true" href="#cb16-105" tabindex="-1"></a></span><br/>
<span id="cb16-106"><a aria-hidden="true" href="#cb16-106" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb16-107"><a aria-hidden="true" href="#cb16-107" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span> <span className="co"># an empty canvas, with removal of all axial elements</span></span>
<span id="cb16-108"><a aria-hidden="true" href="#cb16-108" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb16-109"><a aria-hidden="true" href="#cb16-109" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>)),</span>
<span id="cb16-110"><a aria-hidden="true" href="#cb16-110" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>, <span className="at">b =</span> <span className="dv">5</span>)),</span>
<span id="cb16-111"><a aria-hidden="true" href="#cb16-111" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">face =</span> <span className="st">"bold"</span>, </span>
<span id="cb16-112"><a aria-hidden="true" href="#cb16-112" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>), <span className="at">family =</span> <span className="st">"fatface"</span>),</span>
<span id="cb16-113"><a aria-hidden="true" href="#cb16-113" tabindex="-1"></a>    <span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">9</span>, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb16-114"><a aria-hidden="true" href="#cb16-114" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">10</span>), <span className="co"># increase plot top and bottom margin</span></span>
<span id="cb16-115"><a aria-hidden="true" href="#cb16-115" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb16-116"><a aria-hidden="true" href="#cb16-116" tabindex="-1"></a></span><br/>
<span id="cb16-117"><a aria-hidden="true" href="#cb16-117" tabindex="-1"></a>p6</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>We can slightly tweak the script to visualize the <Link to="../ggplot2-population-pyramid-animation"><strong>annual population pyramids in animation</strong></Link> using the popular <Link to="https://gganimate.com/"><code>gganimate</code></Link> package. Check it out!</p>
<p><Link to="../ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
<p><br/><br/></p>
<p>The barplot is the basis of pie and donut charts, which are essentially <Link to="../../R/visualization/17-ggplot2-polar-coord-summary">bars presented in a <em>polar</em> coordinate</Link>. Check the following <Link to="../ggplot2-donut-GDP"><strong>exploded donut plots in faceted layout</strong></Link> that visualizes the top four GDP contributors in each continent.</p>
<p><Link to="../ggplot2-donut-GDP">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}