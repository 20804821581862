import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderBars from '../tutorial/reorder_bars_alphabetical.png';

export default function Ggplot2ReorderBars(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Complete Guides on Reordering Graphic Elements in ggplot2 - Crash Course on Bar Rearrangement with 4 Different Methods</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><em>Reordering</em> graphical elements in a commonly performed and critical technique in data visualization (as highlighted in this <Link to="../../R/gallery/ggplot2-bar-sleep">inspiring example</Link>), but can be also a confusing procedure for beginners. This serial tutorials on <em>reordering</em> start by explaining in this article <strong>how to rearrange a bar plot using four distinct approaches</strong>.</p>
<p>The techniques introduced here are broadly applicable to reorder other graphical elements, which are covered in these following articles:</p>
<ul>
<li><Link to="../5-ggplot2-reorder-violin-plots">Reorder violin plots based on group statistics</Link></li>
<li><Link to="../6-ggplot2-reorder-faceted-panels">Reorder faceted panels (subplots)</Link></li>
<li><Link to="../7-ggplot2-reorder-stacking-of-bars">Reorder stacking order of bars and ribbons</Link></li>
</ul>
<hr/>
<section className="level3" id="start-with-a-toy-example">
<h3 className="anchored" data-anchor-id="start-with-a-toy-example">Start with a toy example</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="co"># make x-axis label and title more prominent </span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"turquoise4"</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"tomato3"</span>, <span className="at">face =</span> <span className="st">"bold"</span>)))</span></code></pre></div>
</div>
<p>In the following plot, the bars are arranged in the <em>alphabetical order</em> of the level names of the <code>course</code> variable. <strong>Arranging in alphabetical order is the <em>default</em> for character variables in ggplot2.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>course <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"math"</span>, <span className="st">"English"</span>, <span className="st">"physics"</span>, <span className="st">"music"</span>, <span className="st">"gym"</span>) </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>scores <span className="ot">=</span> <span className="fu">c</span>(<span className="dv">58</span>, <span className="dv">90</span>, <span className="dv">65</span>, <span className="dv">87</span>, <span className="dv">120</span>)</span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>t <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">course =</span> course, <span className="at">scores =</span> scores)</span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a></span><br/>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>t <span className="sc">%&gt;%</span> <span className="fu">ggplot</span>(<span className="fu">aes</span>(course, scores)) <span className="sc">+</span></span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderBars} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level3" id="rearrange-the-bar-order">
<h3 className="anchored" data-anchor-id="rearrange-the-bar-order">Rearrange the bar order</h3>
<p>To <strong>rearrange the bar order</strong>, e.g., by descending order of course scores, <strong>a generic approach is to turn the <code>course</code> variable to a <em>factor</em> with specified level order</strong>. A factor has two types, a nominal or unordered factor (<code>fct</code>), and ordered factor (<code>ord</code>), and both types work the same regarding reordering graphics in ggplot2. The following approaches will generate nominal factors, unless otherwise specified.</p>
<section className="level4" id="method-1-use-base-r-reorder">
<h4 className="anchored" data-anchor-id="method-1-use-base-r-reorder">Method 1: Use base R <code>reorder()</code></h4>
<p>Use base R function <code>reorder()</code> to turn <code>course</code> to a factor (<code>fct</code>) based on the associated <code>scores</code>. The descending order is specified by the minus sign <code>-</code>; alternatively, you can use the <code>desc()</code> function from the <Link to="/R/data-wrangling/dplyr/0-introduction"><code>dplyr</code></Link> package, e.g., <code>desc(scores)</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>t <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">course =</span> <span className="fu">reorder</span>(course, <span className="sc">-</span>scores)) <span className="sc">%&gt;%</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(course, scores)) <span className="sc">+</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_bars_method1_mutate.png"/></p>
</figure>
</div>
</div>
</div>
<p>You can simplify the code by updating the <code>course</code> variable directly <em>in the aesthetics</em> in the <code>ggplot()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>t <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="fu">reorder</span>(course, <span className="sc">-</span>scores), scores)) <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_bars_method1_aesthetic.png"/></p>
</figure>
</div>
</div>
</div>
<p>This approach however changes the x axis title to “reorder(course, -scores)”. You can easily fix this by adding <code>+ labs(x = "course")</code> at the end of the script.</p>
</section>
<section className="level4" id="method-2-use-the-forcats-package">
<h4 className="anchored" data-anchor-id="method-2-use-the-forcats-package">Method 2: Use the <code>forcats</code> package</h4>
<p><code>forcats</code> is a versatile package for factor manipulation, and <code>fct_reorder()</code> updates the factor level order in a syntax similar to the base R <code>reorder()</code> function. Either the minus sign or <code>desc()</code> can be used to dictate the descending order.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">library</span>(forcats)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>t <span className="sc">%&gt;%</span> </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">course =</span> <span className="fu">fct_reorder</span>(course, <span className="fu">desc</span>(scores))) <span className="sc">%&gt;%</span> </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(course, scores)) <span className="sc">+</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>)</span></code></pre></div>
</div>
<p>In like manner, you can do the factor conversion directly in the aesthetics as below.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>t <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="fu">fct_reorder</span>(course, <span className="sc">-</span>scores), scores)) <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>) <span className="sc">+</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"course"</span>)</span></code></pre></div>
</div>
</section>
<section className="level4" id="method3">
<h4 className="anchored" data-anchor-id="method3">Method 3: Use the <code>dplyr</code> approach</h4>
<p>You can use the <code>arrange()</code> function from <Link to="/R/data-wrangling/dplyr/0-introduction"><code>dplyr</code></Link> package to first rearrange the rows, and then convert the <code>course</code> variable to a factor using the <code>mutate()</code> and the base R <code>factor()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>t <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">arrange</span>(<span className="sc">-</span>(scores)) <span className="sc">%&gt;%</span> </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">course =</span> <span className="fu">factor</span>(course, <span className="at">levels =</span> course)) <span className="sc">%&gt;%</span> </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(course, scores)) <span className="sc">+</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>)</span></code></pre></div>
</div>
<p>Two important points to remember:</p>
<ul>
<li><p>Simply rearranging the rows with <code>arrange()</code> is <em>not</em> enough to create an ordered bar plot, as <code>course</code> remains a <code>character</code> variable by this step, and has no “memory” of the order of the course names. It needs to be <strong>explicitly converted to a factor</strong> to “memorize” the course order.</p></li>
<li><p>Values fed to the <code>levels</code> argument must <strong><em>not</em> contain duplicated levels</strong>. If there was duplicated course names, use the <code>unique()</code> function to extract distinct levels, e.g., <code>levels = unique(course)</code>.</p></li>
</ul>
</section>
<section className="level4" id="method-4-manually-specify-the-order-of-factor-levels">
<h4 className="anchored">Method 4: Manually specify the order of factor levels</h4>
<p>Suppose that you want to rearrange the bars in a particular order as shown in the code below. You can achieve this in two ways.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># specify the desired order to be displayed</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>my_order <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"math"</span>, <span className="st">"music"</span>, <span className="st">"gym"</span>, <span className="st">"English"</span>,<span className="st">"physics"</span>)</span></code></pre></div>
</div>
<ul>
<li><strong><em>Option 1:</em></strong> Apply the aforementioned <a href="#method3">method 3</a>, except that this time it is the vector <code>my_order</code> that is fed to the <code>levels</code> argument. Check <Link to="../../R/gallery/ggplot2-line-plot-life-expectancy">this example</Link> where the same technique is applied to arrange faceted subplots in the specified order to make informative visualization.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>t <span className="sc">%&gt;%</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">course =</span> <span className="fu">factor</span>(course, <span className="at">levels =</span> my_order)) <span className="sc">%&gt;%</span> </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(course, scores)) <span className="sc">+</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>)</span></code></pre></div>
</div>
<ul>
<li><strong><em>Option 2:</em></strong> Use <code>scale_x_discrete()</code> to manipulate the plot itself. This approach does not change the underlying data input.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>t <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(course, scores)) <span className="sc">+</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">fill =</span> <span className="st">"snow3"</span>) <span className="sc">+</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">limits =</span> my_order)</span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored" data-anchor-id="method-4-manually-specify-the-order-of-factor-levels">
<strong><em>Continue Learning — 🚀 one level up!</em></strong>
</h3>
<p>In the above demonstrations, each <code>course</code> name has a <em>single</em> score, and each row corresponds to a <em>single</em> bar. When each course has <em>multiple</em> scores, or more broadly when each categorical level corresponds to <em>multiple</em> rows (observations), you can use the same technique to reorder the factor levels <strong>but based on group statistics</strong> – learn more <strong><Link to="">how to reorder violin plots</Link></strong> based on different summary statistics.</p>
<p>In addition, check out the following tutorials to further enhance your mastery on ordering customization:</p>
<ul>
<li><Link to="../6-ggplot2-reorder-faceted-panels">reorder faceted panels (subplots)</Link></li>
<li><Link to="../7-ggplot2-reorder-stacking-of-bars">reorder the stacking of bars and ribbons</Link>. An excellent example illustrating of the power of stacking reordering can be found in the following <Link to="../../R/gallery/ggplot2-area-plot-migration"><strong>stacked area / alluvial plot</strong></Link>, which clusters ribbons (countries) of the same continent, and uses color gradients to distinguish countries and continents.</li>
</ul>
<p><Link to="../../R/gallery/ggplot2-area-plot-migration"><img className="img-fluid" src="graphics/area_plot_migration_theme_completed.png"/></Link></p>
</section>
</section>
</main>
</div>
</div>
)}