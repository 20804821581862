import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PluckPart2(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Get Elements from Nested Data Structure (2/2): <em>Variants of</em> <code>pluck()</code> - <em>Check Nesting Depth, Element Presence, and Error Report</em></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In this tutorial, we’ll discuss variant functions of <Link to="../pluck-part1"><code>pluck()</code></Link>. These functions are based on the same working principles as <code>pluck()</code>, but with a different output:</p>
<ul>
<li><a href="#pluck_depth"><strong><code>pluck_depth()</code></strong> calculates the level of nesting.</a></li>
<li><a href="#pluck_exists"><strong><code>pluck_exists()</code></strong> checks if the specified element is present or not.</a></li>
<li><a href="#chuck"><strong><code>chuck()</code></strong> is like <code>pluck()</code> but returns an error for non-existing elements to be extracted.</a></li>
</ul>
<hr/>
<p>We’ll use the example below to demonstrate our functions.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(purrr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>address1 <span className="ot">&lt;-</span> <span className="fu">list</span>(<span className="at">street =</span> <span className="st">"123 Main St"</span>,</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>                 <span className="at">city =</span> <span className="st">"Anytown"</span>,</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>                 <span className="at">country =</span> <span className="st">"USA"</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>person1 <span className="ot">&lt;-</span> <span className="fu">list</span>(<span className="at">name =</span> <span className="st">"John"</span>,</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>                <span className="at">age =</span> <span className="dv">30</span>,</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>                <span className="at">address =</span> address1)</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a></span><br/>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>person2 <span className="ot">&lt;-</span> <span className="fu">list</span>(<span className="at">name =</span> <span className="st">"Alice"</span>,</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>                <span className="at">hobbies =</span> <span className="fu">c</span>(<span className="st">"painting"</span>, <span className="st">"biking"</span>))</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a></span><br/>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a><span className="co"># Update json_data to include the new person</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>people <span className="ot">&lt;-</span> <span className="fu">list</span>(<span className="st">"p1"</span> <span className="ot">=</span> person1, <span className="st">"p2"</span> <span className="ot">=</span> person2)</span></code></pre></div>
</div>
<p><strong><span id="pluck_depth"><code>pluck_depth()</code></span> calculates the level of nesting you can index into.</strong></p>
<div id="flex">
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">pluck_depth</span>(address1)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 2</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">pluck_depth</span>(person1)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 3</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">pluck_depth</span>(people)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] 4</code></pre>
</div>
</div>
</div>
<p><strong><span id="pluck_exists"><code>pluck_exists()</code></span> checks if the specified element is present or not.</strong></p>
<div id="flex">
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>people <span className="sc">%&gt;%</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">pluck_exists</span>(<span className="st">"p1"</span>, <span className="st">"hobbies"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] FALSE</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>people <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">pluck_exists</span>(<span className="st">"p2"</span>, <span className="st">"hobbies"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] TRUE</code></pre>
</div>
</div>
</div>
<p><strong><span id="chuck"><code>chuck()</code></span> is just like <code>pluck()</code>, except that if the element you are trying to access does not exist (or is <code>NULL</code>), it will return an error</strong>. Compare the following between <code>pluck()</code> and <code>chuck()</code>.</p>
<div id="flex">
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="st">"p1"</span>, <span className="st">"hobbies"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  NULL</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">chuck</span>(people, <span className="st">"p1"</span>, <span className="st">"hobbies"</span>)</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a><span className="co"># Error in `chuck()`:</span></span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a><span className="co"># ! Can't find name `hobbies` in vector.</span></span></code></pre></div>
</div>
</div>
<div id="flex">
<div className="cell">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  NULL</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="fu">chuck</span>(people, <span className="dv">3</span>)</span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a><span className="co"># Error in `chuck()`:</span></span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a><span className="co"># ! Index 1 exceeds the length of plucked object (3 &gt; 2).</span></span></code></pre></div>
</div>
</div>
<p>In comparison, <strong><code>[[ ]]</code> returns <code>NULL</code> <em>or</em> error message for non-existing elements</strong> in a less consistent manner.</p>
<div id="flex">
<div className="cell">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a>people[[<span className="st">"p1"</span>]][[<span className="st">"hobbies"</span>]]</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  NULL</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb20"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb20-1"><a aria-hidden="true" href="#cb20-1" tabindex="-1"></a>people[[<span className="dv">3</span>]]</span>
<span id="cb20-2"><a aria-hidden="true" href="#cb20-2" tabindex="-1"></a><span className="co"># Error in people[[3]] : subscript out of bounds</span></span></code></pre></div>
</div>
</div>
</main>
</div>
</div>
)}