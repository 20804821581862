import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PluckPart1(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Get Elements from Nested Data Structure (1/2): <em>Basics of</em> <code>pluck()</code></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>pluck()</code></strong> is a generalized form of <code>[[ ]]</code> that extracts elements from nested data structure.</p>
<ul>
<li><a href="#extract">Extract elements with position index or names</a></li>
<li><a href="#non-existing_element">Deal with elements that don’t exist</a></li>
<li><a href="#pipe">Work with the pipe operator <code>%&gt;%</code></a></li>
</ul>
<p>We’ll demonstrate the use of <code>pluck()</code> and its similarity &amp; difference from <code>[[ ]]</code> using the following example.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>person1 <span className="ot">&lt;-</span> <span className="fu">list</span>(</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>  <span className="at">name =</span> <span className="st">"John"</span>,</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>  <span className="at">age =</span> <span className="dv">30</span>,</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>  <span className="at">address =</span> <span className="fu">list</span>(<span className="at">street =</span> <span className="st">"123 Main St"</span>,</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>                 <span className="at">city =</span> <span className="st">"Anytown"</span>,</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>                 <span className="at">country =</span> <span className="st">"USA"</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>person2 <span className="ot">&lt;-</span> <span className="fu">list</span>(</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="at">name =</span> <span className="st">"Alice"</span>,</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="at">address =</span> <span className="fu">list</span>(<span className="at">street =</span> <span className="st">"456 Oak St"</span>,</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>                 <span className="at">city =</span> <span className="st">"Sometown"</span>,</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>                 <span className="at">country =</span> <span className="st">"USA"</span>),</span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="at">hobbies =</span> <span className="fu">c</span>(<span className="st">"painting"</span>, <span className="st">"biking"</span>, <span className="st">"gardening"</span>)</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>)</span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a></span><br/>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a><span className="co"># Update json_data to include the new person</span></span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>people <span className="ot">&lt;-</span> <span className="fu">list</span>(person1, person2)</span></code></pre></div>
</div>
<section className="level3" id="extract-elements-with-position-index-or-names">
<h3 className="anchored" data-anchor-id="extract-elements-with-position-index-or-names"><span id="extract">Extract elements with position index or names</span></h3>
<p>Get the information of the 2nd person. Here we use index 2 to specify the element position. The code below is equivalent to <code>people[[2]]</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">library</span>(purrr)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="dv">2</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  $name
<br/>  [1] "Alice"
<span>  
</span><span>  $address
</span><span>  $address$street
</span><br/>  [1] "456 Oak St"
<span>  
</span><span>  $address$city
</span><br/>  [1] "Sometown"
<span>  
</span><span>  $address$country
</span><br/>  [1] "USA"
<span>  
</span><span>  
</span><span>  $hobbies
</span><br/>  [1] "painting"  "biking"    "gardening"</code></pre>
</div>
</div>
<p>Get the 2nd person’s address. Here we use a combination of index position and element name to specify the element to extract. The code below is equivalent to <code>people[[2]][["address"]]</code></p>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="dv">2</span>, <span className="st">"address"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  $street
<br/>  [1] "456 Oak St"
<span>  
</span><span>  $city
</span><br/>  [1] "Sometown"
<span>  
</span><span>  $country
</span><br/>  [1] "USA"</code></pre>
</div>
</div>
<p>Get the 2nd person’ street name. The code below is equivalent to <code>people[[2]][["address"]][["street"]]</code></p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="dv">2</span>, <span className="st">"address"</span>, <span className="st">"street"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "456 Oak St"</code></pre>
</div>
</div>
</section>
<section className="level3" id="deal-with-elements-that-dont-exist">
<h3 className="anchored" data-anchor-id="deal-with-elements-that-dont-exist"><span id="non-existing_element">Deal with elements that don’t exist</span></h3>
<p>If the element to be extracted is not present, <code>pluck()</code> consistently returns a <code>NULL</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="dv">3</span>) <span className="co"># extract the 3rd element that is not existing</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  NULL</code></pre>
</div>
</div>
<p>You can otherwise use <code>.default</code> to change the output value for not existing elements.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">pluck</span>(people, <span className="dv">3</span>, <span className="at">.default =</span> <span className="st">"not available"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "not available"</code></pre>
</div>
</div>
<p>In comparison, the <code>[[ ]]</code> approach returns an error for missing elements. (see more discussion <Link to="../pluck-part2#chuck">here</Link>)</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>people[[<span className="dv">3</span>]]</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a><span className="co"># Error in people[[3]] : subscript out of bounds</span></span></code></pre></div>
</div>
<p>You can use <code>pluck_exists()</code> to check if the specified element is present or not.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">pluck_exists</span>(people, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] FALSE</code></pre>
</div>
</div>
</section>
<section className="level3" id="work-with-the-pipe-operator">
<h3 className="anchored" data-anchor-id="work-with-the-pipe-operator"><span id="pipe">Work with the pipe operator <code>%&gt;%</code></span></h3>
<p><code>pluck()</code> streamlined perfectly with the pipe operator.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>people <span className="sc">%&gt;%</span> <span className="fu">pluck</span>(<span className="dv">2</span>, <span className="dv">2</span>, <span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "456 Oak St"</code></pre>
</div>
</div>
<p>You can use <code>[[ ]]</code> with the <Link to="/R/data-wrangling/dplyr/1-pipe-operator#advanced_feature">special feature of the pipe operator</Link> to extract the second element of <code>people</code>, e.g., using <code>people %&gt;% .[[2]]</code>. However, it is not so convenient for elements further embedded in the nested structure.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="co"># this line is not correct, and gives error message: </span></span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a><span className="co"># Error in .[[.[[2]][[2]], 1]] : incorrect number of subscripts</span></span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a>people <span className="sc">%&gt;%</span> .[[<span className="dv">2</span>]][[<span className="dv">2</span>]][[<span className="dv">1</span>]]</span></code></pre></div>
</div>
<p>Instead it must be presented as:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a>(people <span className="sc">%&gt;%</span> .[[<span className="dv">2</span>]])[[<span className="dv">2</span>]][[<span className="dv">1</span>]]</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] "456 Oak St"</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}