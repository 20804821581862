import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function AddRowsToTibble(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Add Rows to a Tibble</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>add_row()</code></strong> adds one or more rows of data to an existing data frame.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tibble)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># create a tibble</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>students <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Alice"</span>, <span className="st">"Bob"</span>, <span className="st">"Charlie"</span>),</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>                   <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="dv">22</span>, <span className="dv">21</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>                   <span className="at">Enrolled =</span> <span className="fu">c</span>(<span className="cn">TRUE</span>, <span className="cn">FALSE</span>, <span className="cn">TRUE</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>students</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  Name      Age Enrolled
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice      20 TRUE    
<br/>2 Bob        22 FALSE   
<br/>3 Charlie    21 TRUE    </code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># add two more students' information as two extra rows</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>students <span className="sc">%&gt;%</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">add_row</span>(</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>    <span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Tom"</span>, <span className="st">"Mary"</span>), <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">28</span>, <span className="dv">18</span>), <span className="at">Enrolled =</span> <span className="fu">c</span>(F, T))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 5 × 3
<br/>  Name      Age Enrolled
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice      20 TRUE    
<br/>2 Bob        22 FALSE   
<br/>3 Charlie    21 TRUE    
<br/>4 Tom        28 FALSE   
<br/>5 Mary       18 TRUE    </code></pre>
</div>
</div>
<p>Absent variables will create <code>NA</code> values.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>students <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">add_row</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    <span className="co"># the missing column "Enrolled" is filled with 'NA' in the output</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Tom"</span>, <span className="st">"Mary"</span>), <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">28</span>, <span className="dv">18</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 5 × 3
<br/>  Name      Age Enrolled
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice      20 TRUE    
<br/>2 Bob        22 FALSE   
<br/>3 Charlie    21 TRUE    
<br/>4 Tom        28 NA      
<br/>5 Mary       18 NA      </code></pre>
</div>
</div>
<p>Use <code>.before</code> (or <code>.after</code>) to specify the first tibble’s row index where the second tibble should be inserted. (These arguments are also available in <Link to="../add-columns-to-tibble"><code>add_column()</code></Link> and <Link to="/R/data-wrangling/dplyr/4-mutate-columns"><code>mutate()</code></Link> to specify new columns’ position.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>students <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">add_row</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Tom"</span>, <span className="st">"Mary"</span>), <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">28</span>, <span className="dv">18</span>), </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="co"># insert the 2nd tibble before the 1st tibble's 3rd row</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">.before =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 5 × 3
<br/>  Name      Age Enrolled
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice      20 TRUE    
<br/>2 Bob        22 FALSE   
<br/>3 Tom        28 NA      
<br/>4 Mary       18 NA      
<br/>5 Charlie    21 TRUE    </code></pre>
</div>
</div>
<p>You can directly combine two tibbles by rows.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># create a second tibble to combine by rows</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>more_students <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">Name =</span> <span className="fu">c</span>(<span className="st">"Tom"</span>, <span className="st">"Jack"</span>, <span className="st">"Clement"</span>), </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>                        <span className="at">Age =</span> <span className="fu">c</span>(<span className="dv">28</span>, <span className="dv">22</span>, <span className="dv">34</span>), </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>                        <span className="at">Enrolled =</span> <span className="fu">c</span>(F, F, T))</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a></span><br/>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a><span className="co"># combine the two tibbles</span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>students <span className="sc">%&gt;%</span> <span className="fu">add_row</span>(more_students, <span className="at">.before =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 6 × 3
<br/>  Name      Age Enrolled
<br/>  &lt;chr&gt;   &lt;dbl&gt; &lt;lgl&gt;   
<br/>1 Alice      20 TRUE    
<br/>2 Bob        22 FALSE   
<br/>3 Tom        28 FALSE   
<br/>4 Jack       22 FALSE   
<br/>5 Clement    34 TRUE    
<br/>6 Charlie    21 TRUE    </code></pre>
</div>
</div>
</main>
</div>
</div>
)}