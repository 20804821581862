import React from 'react'; 
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkDropduplicates(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="dropDuplicates"><code>dropDuplicates()</code><a class="anchor-link" href="#dropDuplicates">¶</a></h3><p>The <code>dropDuplicates()</code> function is a handy tool for removing duplicate rows from a DataFrame. It can be used without any arguments to remove all duplicate rows based on all columns. Alternatively, you can specify a subset of columns to consider for identifying duplicates.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session-and-sample-DataFrame">Create Spark Session and sample DataFrame<a class="anchor-link" href="#Create-Spark-Session-and-sample-DataFrame">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"selectExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>

<br /><span><span class="c1"># Sample data with duplicates</span></span>
<span><span class="n">data</span> <span class="o">=</span> <span class="p">[</span></span>
<span>    <span class="p">(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"Smith"</span><span class="p">,</span> <span class="s2">"USA"</span><span class="p">,</span> <span class="mi">1</span><span class="p">),</span></span>
<span>    <span class="p">(</span><span class="s2">"Anna"</span><span class="p">,</span> <span class="s2">"Rose"</span><span class="p">,</span> <span class="s2">"UK"</span><span class="p">,</span> <span class="mi">2</span><span class="p">),</span></span>
<span>    <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span> <span class="s2">"Williams"</span><span class="p">,</span> <span class="s2">"USA"</span><span class="p">,</span> <span class="mi">3</span><span class="p">),</span></span>
<span>    <span class="p">(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"Bond"</span><span class="p">,</span> <span class="s2">"USA"</span><span class="p">,</span> <span class="mi">1</span><span class="p">),</span>  <span class="c1"># Duplicate</span></span>
<span>    <span class="p">(</span><span class="s2">"Anna"</span><span class="p">,</span> <span class="s2">"Rose"</span><span class="p">,</span> <span class="s2">"UK"</span><span class="p">,</span> <span class="mi">2</span><span class="p">),</span>     <span class="c1"># Duplicate</span></span>
<span>    <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span> <span class="s2">"Williams"</span><span class="p">,</span> <span class="s2">"USA"</span><span class="p">,</span> <span class="mi">3</span><span class="p">)</span>  <span class="c1"># Duplicate</span></span>
<span><span class="p">]</span></span>

<br /><span><span class="n">columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Firstname"</span><span class="p">,</span> <span class="s2">"Lastname"</span><span class="p">,</span> <span class="s2">"Country"</span><span class="p">,</span> <span class="s2">"ID"</span><span class="p">]</span></span>

<br /><span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="p">,</span> <span class="n">columns</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---------+--------+-------+---+
<br />|Firstname|Lastname|Country| ID|
<br />+---------+--------+-------+---+
<br />|    James|   Smith|    USA|  1|
<br />|     Anna|    Rose|     UK|  2|
<br />|   Robert|Williams|    USA|  3|
<br />|    James|    Bond|    USA|  1|
<br />|     Anna|    Rose|     UK|  2|
<br />|   Robert|Williams|    USA|  3|
<br />+---------+--------+-------+---+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Drop-duplicated-rows-based-on-all-columns-of-a-DataFrame">Example: Drop duplicated rows based on all columns of a DataFrame<a class="anchor-link" href="#Example:-Drop-duplicated-rows-based-on-all-columns-of-a-DataFrame">¶</a></h4><ul>
<li><code>df.dropDuplicates()</code>: This removes all duplicate rows in the DataFrame <code>df</code>. If two or more rows are exactly the same across all columns, only one is kept.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">dropDuplicates</span><span class="p">()</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---------+--------+-------+---+
<br />|Firstname|Lastname|Country| ID|
<br />+---------+--------+-------+---+
<br />|    James|   Smith|    USA|  1|
<br />|     Anna|    Rose|     UK|  2|
<br />|   Robert|Williams|    USA|  3|
<br />|    James|    Bond|    USA|  1|
<br />+---------+--------+-------+---+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Drop-duplicates-based-on-a-speficied-column">Example: Drop duplicates based on a speficied column<a class="anchor-link" href="#Example:-Drop-duplicates-based-on-a-speficied-column">¶</a></h4><ul>
<li><code>df.dropDuplicates(["Lastname"])</code>: This removes duplicate rows based on the <strong>Lastname</strong> column. It keeps only the first occurrence of each unique <strong>Lastname</strong>, irrespective of the other column values.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Removing duplicates</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">dropDuplicates</span><span class="p">([</span><span class="s1">'Lastname'</span><span class="p">])</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+---------+--------+-------+---+
<br />|Firstname|Lastname|Country| ID|
<br />+---------+--------+-------+---+
<br />|    James|    Bond|    USA|  1|
<br />|     Anna|    Rose|     UK|  2|
<br />|    James|   Smith|    USA|  1|
<br />|   Robert|Williams|    USA|  3|
<br />+---------+--------+-------+---+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}