import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PivotWiderPart2(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Spread Columns into Wider Dataset (2/3): <em>Aggregate the Data During Data Pivot</em></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>You can use <code>pivot_wider()</code> to perform simple aggregation. Consider the following <code>warpbreaks</code> dataset which is built in base R.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># convert to a tibble for the better print method</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>warpbreaks <span className="ot">&lt;-</span> warpbreaks <span className="sc">%&gt;%</span> </span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="fu">select</span>(wool, tension, breaks)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>warpbreaks</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 54 × 3
<br/>   wool  tension breaks
<br/>   &lt;fct&gt; &lt;fct&gt;    &lt;dbl&gt;
<br/> 1 A     L           26
<br/> 2 A     L           30
<br/> 3 A     L           54
<br/> 4 A     L           25
<br/> 5 A     L           70
<br/> 6 A     L           52
<br/> 7 A     L           51
<br/> 8 A     L           26
<br/> 9 A     L           67
<br/>10 A     M           18
<br/># ℹ 44 more rows</code></pre>
</div>
</div>
<p>This is a designed experiment with 9 replicates for every combination of <code>wool</code> type (A and B) and <code>tension</code> magnitude (L, M, H).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># explore the dataset structure by counting the number of</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="co"># observations (rows) of different combinations of "wool" and "tension"</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>warpbreaks <span className="sc">%&gt;%</span> <span className="fu">count</span>(wool, tension)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 6 × 3
<br/>  wool  tension     n
<br/>  &lt;fct&gt; &lt;fct&gt;   &lt;int&gt;
<br/>1 A     L           9
<br/>2 A     M           9
<br/>3 A     H           9
<br/>4 B     L           9
<br/>5 B     M           9
<br/>6 B     H           9</code></pre>
</div>
</div>
<p>What happens if we attempt to spread the unique levels of <code>wool</code> into separate columns?</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># Warning message:</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="co"># Values are not uniquely identified; output will contain list-cols.</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a><span className="co"># * Use `values_fn = list` to suppress this warning.</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a><span className="co"># * Use `values_fn = length` to identify where the duplicates arise</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a><span className="co"># * Use `values_fn = &#123;summary_fun&#125;` to summarise duplicates </span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a></span><br/>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>a <span className="ot">&lt;-</span> warpbreaks <span className="sc">%&gt;%</span> </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="fu">pivot_wider</span>(</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>    <span className="at">names_from =</span> wool,</span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>    <span className="at">values_from =</span> breaks</span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  )</span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  tension A         B        
<br/>  &lt;fct&gt;   &lt;list&gt;    &lt;list&gt;   
<br/>1 L       &lt;dbl [9]&gt; &lt;dbl [9]&gt;
<br/>2 M       &lt;dbl [9]&gt; &lt;dbl [9]&gt;
<br/>3 H       &lt;dbl [9]&gt; &lt;dbl [9]&gt;</code></pre>
</div>
</div>
<p>The default behavior produces list-columns, with a warning that each cell in the output corresponds to multiple cells in the input. We see from the code below that in the list-columns, each cell is a vector of values of the 9 experimental replicates.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>a<span className="sc">$</span>A[[<span className="dv">1</span>]]</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 26 30 54 25 70 52 51 26 67</code></pre>
</div>
</div>
<p>A more useful output would be summary statistics, e.g. the mean breaks for each combination of <code>wool</code> and <code>tension</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>warpbreaks <span className="sc">%&gt;%</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">pivot_wider</span>(</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>    <span className="at">names_from =</span> wool, </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>    <span className="at">values_from =</span> breaks,</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>    <span className="co"># calculate the mean of 9 replicates for each cell in list-columns</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>    <span className="at">values_fn =</span> <span className="fu">list</span>(<span className="at">breaks =</span> mean) </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>  tension     A     B
<br/>  &lt;fct&gt;   &lt;dbl&gt; &lt;dbl&gt;
<br/>1 L        44.6  28.2
<br/>2 M        24    28.8
<br/>3 H        24.6  18.8</code></pre>
</div>
</div>
<p>The code above is equivalent to the code below: creating your own summary dataset first, which is then pivoted to wider format.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>warpbreaks <span className="sc">%&gt;%</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="co"># summarize mean "breaks" for each "wool" type at each "tension" test</span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">group_by</span>(wool, tension) <span className="sc">%&gt;%</span> </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">breaks =</span> <span className="fu">mean</span>(breaks)) <span className="sc">%&gt;%</span> </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>  <span className="co"># spread wool types into separate columns</span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>  <span className="fu">pivot_wider</span>(</span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>    <span className="at">names_from =</span> wool, </span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>    <span className="at">values_from =</span> breaks)</span></code></pre></div>
</div>
<p>For more complex summary operations, it is recommend to summarize before reshaping (e.g., with <Link to="/R/data-wrangling/dplyr/6-grouped-dataset"><code>group_by()</code></Link> and <Link to="/R/data-wrangling/dplyr/5-summarize"><code>summarize()</code></Link>), but for simple cases as this example, it’s often convenient to summarize within <code>pivot_wider()</code>.</p>
</main>
</div>
</div>
)}