import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2ArtsyRArtworks from '../graphics/aRtsy_completed.png'; 
import imgGgplot2ArtsyRArtworksWebp from '../graphics/aRtsy_completed.webp'; 
export default function Ggplot2ArtsyRArtworks(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Artworks with aRtsy in R</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2ArtsyRArtworksWebp} />
    <img className="cover-img" src={imgGgplot2ArtsyRArtworks} />
  </picture>

<p>The <Link to="https://github.com/koenderks/aRtsy"><strong><code>aRtsy</code></strong></Link> package by <Link to="https://koenderks.com">Koen Derks</Link> offers a user-friendly and ggplot2-based platform for creating generative artworks. It leverages algorithms that incorporate randomness to produce unique drawings. The above demo graphics are generated by the script below.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># install.packages("aRtsy")</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(aRtsy)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">set.seed</span>(<span className="dv">123</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> <span className="fu">canvas_strokes</span>(</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">c</span>(<span className="st">"white"</span>, <span className="st">"black"</span>, <span className="st">"steelblue"</span>, <span className="st">"firebrick"</span>) )</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a></span><br/>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> <span className="fu">canvas_blacklight</span>(</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">brewer.pal</span>(<span className="dv">11</span>, <span className="st">"Spectral"</span>))</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a></span><br/>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> <span className="fu">canvas_flow</span>(</span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"dark2"</span>), </span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  <span className="at">lwd =</span> <span className="dv">1</span>, <span className="at">polar =</span> T)</span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a></span><br/>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> <span className="fu">canvas_function</span>(</span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"tuscany1"</span>),</span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>  <span className="at">background =</span> <span className="st">"black"</span>)</span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a></span><br/>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> <span className="fu">canvas_gemstone</span>(</span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"dark3"</span>))</span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a></span><br/>
<span id="cb1-24"><a aria-hidden="true" href="#cb1-24" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> <span className="fu">canvas_circlemap</span>(</span>
<span id="cb1-25"><a aria-hidden="true" href="#cb1-25" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"neon2"</span>))</span>
<span id="cb1-26"><a aria-hidden="true" href="#cb1-26" tabindex="-1"></a></span><br/>
<span id="cb1-27"><a aria-hidden="true" href="#cb1-27" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> <span className="fu">canvas_recaman</span>(</span>
<span id="cb1-28"><a aria-hidden="true" href="#cb1-28" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"random"</span>, <span className="at">n =</span> <span className="dv">10</span>))</span>
<span id="cb1-29"><a aria-hidden="true" href="#cb1-29" tabindex="-1"></a></span><br/>
<span id="cb1-30"><a aria-hidden="true" href="#cb1-30" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> <span className="fu">canvas_turmite</span>(</span>
<span id="cb1-31"><a aria-hidden="true" href="#cb1-31" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"dark2"</span>))</span>
<span id="cb1-32"><a aria-hidden="true" href="#cb1-32" tabindex="-1"></a></span><br/>
<span id="cb1-33"><a aria-hidden="true" href="#cb1-33" tabindex="-1"></a>p9 <span className="ot">&lt;-</span> <span className="fu">canvas_watercolors</span>(</span>
<span id="cb1-34"><a aria-hidden="true" href="#cb1-34" tabindex="-1"></a>  <span className="at">colors =</span> <span className="fu">colorPalette</span>(<span className="st">"tuscany2"</span>))</span>
<span id="cb1-35"><a aria-hidden="true" href="#cb1-35" tabindex="-1"></a></span><br/>
<span id="cb1-36"><a aria-hidden="true" href="#cb1-36" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb1-37"><a aria-hidden="true" href="#cb1-37" tabindex="-1"></a></span><br/>
<span id="cb1-38"><a aria-hidden="true" href="#cb1-38" tabindex="-1"></a>( p1 <span className="sc">|</span> p2 <span className="sc">|</span> p3 ) <span className="sc">/</span> </span>
<span id="cb1-39"><a aria-hidden="true" href="#cb1-39" tabindex="-1"></a>  ( p4 <span className="sc">|</span> p5 <span className="sc">|</span> p6 ) <span className="sc">/</span></span>
<span id="cb1-40"><a aria-hidden="true" href="#cb1-40" tabindex="-1"></a>  ( p7 <span className="sc">|</span> p8 <span className="sc">|</span> p9 )</span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Apart from the fantastic <Link to="https://github.com/koenderks/aRtsy"><code>aRtsy</code></Link> package, you can <Link to="../ggplot2-draw-with-math-functions"><strong>create beautiful patterns using self-defined math functions</strong></Link> as in the following article.</p>
<p><Link to="../ggplot2-draw-with-math-functions">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/artwork_overflow_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/artwork_overflow_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to abstract artworks created by randomization algorithms, <strong>heatmaps</strong> are another powerful tool that not only offers unique aesthetic appeal, but also provides valuable insights into data patterns – check out the following <Link to="../ggplot2-heatmap-African-population"><strong>amazing heatmap visualizing the population distribution in Africa</strong></Link>.</p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>And check out these <strong><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">beautiful heatmaps highlighting the power of vaccines in disease control</Link></strong> throughout the U.S. history.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Last but not the least, check out this awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}