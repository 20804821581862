import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2OverlapErrorbar from '../tutorial/overlap_error_bars_base.png';

export default function Ggplot2OverlapErrorbar(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Five Awesome Tips to Deal with Overlapped Error Bars</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In this tutorial, we’ll talk about five powerful solutions to better visualize overlapped error bars.</p>
<section className="level4" id="create-a-dummy-example">
<h4 className="anchored" data-anchor-id="create-a-dummy-example">Create a dummy example</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">16</span>) )</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">set.seed</span>(<span className="dv">123</span>)  <span className="co"># for reproducible randomization </span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># set the x values</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="dv">6</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="dv">1</span><span className="sc">:</span>n</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="co"># set the y values, corresponding to two categories</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>y1 <span className="ot">&lt;-</span> x <span className="sc">^</span>.<span className="dv">1</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>y2 <span className="ot">&lt;-</span> x <span className="sc">^</span>.<span className="dv">2</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a><span className="co"># set standard deviation SD</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>SD <span className="ot">&lt;-</span> <span className="fu">runif</span>(<span className="dv">2</span><span className="sc">*</span>n, <span className="at">min =</span> .<span className="dv">1</span>, <span className="at">max =</span> .<span className="dv">2</span>) </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a></span><br/>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a><span className="co"># Create a data frame</span></span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>mydata <span className="ot">&lt;-</span> <span className="fu">data.frame</span>(</span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="at">x =</span> <span className="fu">c</span>(x, x), </span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="at">y =</span> <span className="fu">c</span>(y1, y2), </span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>  <span className="at">SD =</span> SD, </span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>  <span className="at">category =</span> <span className="fu">rep</span>(<span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>), <span className="at">each =</span> n))</span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a></span><br/>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a><span className="fu">head</span>(mydata, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  x        y        SD category
<br/>1 1 1.000000 0.1287578        A
<br/>2 2 1.071773 0.1788305        A
<br/>3 3 1.116123 0.1408977        A</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p <span className="ot">&lt;-</span> mydata <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(x, y, <span className="at">color =</span> category)) </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">geom_point</span>() <span className="sc">+</span> </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">geom_line</span>() <span className="sc">+</span></span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>  <span className="fu">geom_errorbar</span>(<span className="fu">aes</span>(<span className="at">ymin =</span> y <span className="sc">+</span> SD, <span className="at">ymax =</span> y <span className="sc">-</span> SD))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2OverlapErrorbar} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-1-draw-error-bars-in-half">
<h4 className="anchored" data-anchor-id="method-1-draw-error-bars-in-half">Method 1: Draw error bars in half</h4>
<p>Use the <code>ifelse()</code> function in the aesthetic mapping to help create halved error bars.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">geom_point</span>() <span className="sc">+</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">geom_line</span>() <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_errorbar</span>(<span className="fu">aes</span>(<span className="at">ymin =</span> <span className="fu">ifelse</span>(category <span className="sc">==</span> <span className="st">"A"</span>, y <span className="sc">-</span> SD,  y), </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>                    <span className="at">ymax =</span> <span className="fu">ifelse</span>(category <span className="sc">==</span> <span className="st">"A"</span>, y, y <span className="sc">+</span> SD)))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_half.png"/></p>
</figure>
</div>
</div>
</div>
<p>To remove the error cap connected to the lines, one trick is to reduce the cap width, and overlay with points of larger size.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">geom_line</span>() <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_errorbar</span>(<span className="fu">aes</span>(<span className="at">ymin =</span> <span className="fu">ifelse</span>(category <span className="sc">==</span> <span className="st">"A"</span>, y <span className="sc">-</span> SD,  y), </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>                    <span className="at">ymax =</span> <span className="fu">ifelse</span>(category <span className="sc">==</span> <span className="st">"A"</span>, y, y <span className="sc">+</span> SD)),</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>                <span className="at">width =</span> .<span className="dv">2</span>) <span className="sc">+</span> <span className="co"># reduce cap width</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">4</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_half_improved.png"/></p>
</figure>
</div>
</div>
</div>
<p><code>geom_pointrange()</code> is very similar to <code>geom_errorbar()</code>, but does not draw the cap at the end of the error bar. The size of the point is controlled by the <code>size</code> argument (yet on a <em>different size scale</em> from the regular <code>geom_point()</code>), and the line thickness is controlled separately by <code>linewidth</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_line</span>() <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">geom_pointrange</span>(<span className="fu">aes</span>(<span className="at">ymin =</span> <span className="fu">ifelse</span>(category <span className="sc">==</span> <span className="st">"A"</span>, y <span className="sc">-</span> SD,  y), </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>                      <span className="at">ymax =</span> <span className="fu">ifelse</span>(category <span className="sc">==</span> <span className="st">"A"</span>, y, y <span className="sc">+</span> SD)),</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>                  <span className="at">size =</span> <span className="dv">1</span>, <span className="co"># for point</span></span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>                  <span className="at">linewidth =</span> <span className="dv">1</span>)  <span className="co"># for error bar</span></span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_geom_pointrange.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-2-stagger-error-bars-with-position-in-dodge">
<h4 className="anchored" data-anchor-id="method-2-stagger-error-bars-with-position-in-dodge">Method 2: Stagger error bars with position in <strong><code>dodge</code></strong></h4>
<p>Applying the <code>dodge</code> position to stagger the error bars (and other geometrics) is a very effective approach to reduce overlap. <em>All three</em> geometric layers should be dodged with the same width to be synchronized in position.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_dodge</span>(<span className="at">width =</span> .<span className="dv">3</span>)) <span className="sc">+</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">position =</span> <span className="fu">position_dodge</span>(<span className="at">width =</span> .<span className="dv">3</span>)) <span className="sc">+</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="fu">geom_errorbar</span>(<span className="fu">aes</span>(<span className="at">ymin =</span> y <span className="sc">+</span> SD, </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>                    <span className="at">ymax =</span> y <span className="sc">-</span> SD),</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>                <span className="at">position =</span> <span className="fu">position_dodge</span>(<span className="at">width =</span> .<span className="dv">3</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_dodge.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-3-draw-error-ribbons-in-place-of-error-bars">
<h4 className="anchored" data-anchor-id="method-3-draw-error-ribbons-in-place-of-error-bars">Method 3: Draw error ribbons in place of error bars</h4>
<p>When there are too many error bars, using the error ribbons can be a visually cleaner alternative. The argument <code>color = NA</code> removes the ribbon outline.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_point</span>() <span className="sc">+</span> </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">geom_line</span>() <span className="sc">+</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">geom_ribbon</span>(<span className="fu">aes</span>(<span className="at">ymin =</span> y <span className="sc">+</span> SD, </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>                  <span className="at">ymax =</span> y <span className="sc">-</span> SD, </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>                  <span className="at">fill =</span> category),</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>              <span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">color =</span> <span className="cn">NA</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_ribbon.png"/></p>
</figure>
</div>
</div>
</div>
<p>Check these two great illustrations (<Link to="../../R/gallery/ggplot2-line-summary-statistics-milk"><strong>plot 1</strong></Link>, <Link to="../../R/gallery/ggplot2-line-plot-life-expectancy"><strong>plot 2</strong></Link>) to quickly generate error ribbons (standard deviation, standard error of the mean, confidence interval) on the fly using the <code>stat_summary()</code> function.</p>
</section>
<section className="level4" id="method-4-facet-the-plot-into-subplots">
<h4 className="anchored" data-anchor-id="method-4-facet-the-plot-into-subplots">Method 4: Facet the plot into subplots</h4>
<p>When the dataset is large enough, faceting the graphic into subplots can be very useful. Here we demonstrate with the base R dataset <code>ChickWeight</code>, which shows the changing weight of each individual chick on different diets across different time points.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">slice_sample</span>(ChickWeight, <span className="at">n =</span> <span className="dv">4</span>) <span className="co"># display 4 random rows</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  weight Time Chick Diet
<br/>1    146   18    33    3
<br/>2    110   12     8    1
<br/>3    138   14    31    3
<br/>4     65    4    32    3</code></pre>
</div>
</div>
<p>Visualize the average weight of chicken at different time points on different diets, and create ribbons spanning one standard deviation above and below the mean using <code>stat_summary()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>w <span className="ot">&lt;-</span> ChickWeight <span className="sc">%&gt;%</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(Time, weight, <span className="at">color =</span> Diet, <span className="at">fill =</span> Diet)) <span className="sc">+</span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">geom =</span> <span className="st">"line"</span>, <span className="at">fun =</span> mean, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">geom =</span> <span className="st">"ribbon"</span>, </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>               <span className="co"># mean, with standard deviation limits (sdl)</span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>               <span className="at">fun.data =</span> mean_sdl, </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>               <span className="co"># mean ± 1 × sdl. By default, 2 × sd is displayed </span></span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>               <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">mult =</span> <span className="dv">1</span>),</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>               <span className="at">alpha =</span> .<span className="dv">4</span>, <span className="at">color =</span> <span className="cn">NA</span>)</span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>w</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_base2.png"/></p>
</figure>
</div>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>w <span className="sc">+</span> <span className="fu">facet_wrap</span>(<span className="sc">~</span>Diet, <span className="at">nrow =</span> <span className="dv">1</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/overlap_error_bars_facet.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="method-5-employ-interativity-by-the-ggiraph-package">
<h4 className="anchored" data-anchor-id="method-5-employ-interativity-by-the-ggiraph-package">Method 5: Employ interativity by the <strong><code>ggiraph</code></strong> package</h4>
<p><Link to="https://davidgohel.github.io/ggiraph/"><code>ggiraph</code></Link> is a powerful ggplot2 extension package to create interactive visualization. Here we use it to highlight the hovered group of data and dim out the other data at the same time.</p>
<p>An interactive <code>geom_*</code> is typically created by adding the suffix “interactive” after a regular geom name, e.g., <code>geom_ribbon_interactive</code>. However, in the special case of <code>stat_summary()</code>, we need to add the <code>GeomInteractive</code> prefix before the geom name; as it is not an exported subject from the package, it needs to explicitly extracted from the package using <code>:::</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">library</span>(ggiraph)</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a></span><br/>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>CW <span className="ot">&lt;-</span> ChickWeight <span className="sc">%&gt;%</span> </span>
<span id="cb13-4"><a aria-hidden="true" href="#cb13-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(</span>
<span id="cb13-5"><a aria-hidden="true" href="#cb13-5" tabindex="-1"></a>    Time, weight, <span className="at">color =</span> Diet, <span className="at">fill =</span> Diet, </span>
<span id="cb13-6"><a aria-hidden="true" href="#cb13-6" tabindex="-1"></a>    </span>
<span id="cb13-7"><a aria-hidden="true" href="#cb13-7" tabindex="-1"></a>    <span className="co"># When hovered:</span></span>
<span id="cb13-8"><a aria-hidden="true" href="#cb13-8" tabindex="-1"></a>    <span className="co"># 1. show all graphical elements associated with the same "Diet"</span></span>
<span id="cb13-9"><a aria-hidden="true" href="#cb13-9" tabindex="-1"></a>    <span className="at">data_id =</span> Diet, </span>
<span id="cb13-10"><a aria-hidden="true" href="#cb13-10" tabindex="-1"></a>    <span className="co"># 2. text in the "Diet" column is displayed in the tooltip</span></span>
<span id="cb13-11"><a aria-hidden="true" href="#cb13-11" tabindex="-1"></a>    <span className="at">tooltip =</span> <span className="fu">paste</span>(<span className="st">"This is Diet #"</span>, Diet)) </span>
<span id="cb13-12"><a aria-hidden="true" href="#cb13-12" tabindex="-1"></a>  ) <span className="sc">+</span></span>
<span id="cb13-13"><a aria-hidden="true" href="#cb13-13" tabindex="-1"></a>  </span>
<span id="cb13-14"><a aria-hidden="true" href="#cb13-14" tabindex="-1"></a>  <span className="co"># trend line</span></span>
<span id="cb13-15"><a aria-hidden="true" href="#cb13-15" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">geom =</span> ggiraph<span className="sc">:::</span>GeomInteractiveLine, </span>
<span id="cb13-16"><a aria-hidden="true" href="#cb13-16" tabindex="-1"></a>               <span className="at">fun =</span> mean, <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb13-17"><a aria-hidden="true" href="#cb13-17" tabindex="-1"></a>  <span className="co"># error ribbon</span></span>
<span id="cb13-18"><a aria-hidden="true" href="#cb13-18" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">geom =</span> ggiraph<span className="sc">:::</span>GeomInteractiveRibbon,</span>
<span id="cb13-19"><a aria-hidden="true" href="#cb13-19" tabindex="-1"></a>               <span className="at">fun.data =</span> mean_sdl, <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">mult =</span> <span className="dv">1</span>),</span>
<span id="cb13-20"><a aria-hidden="true" href="#cb13-20" tabindex="-1"></a>               <span className="at">alpha =</span> .<span className="dv">5</span>, <span className="at">color =</span> <span className="cn">NA</span>) </span>
<span id="cb13-21"><a aria-hidden="true" href="#cb13-21" tabindex="-1"></a>CW</span></code></pre></div>
</div>
<p><code>CW</code> by this step is the same static plot as <code>w</code> shown above. To render it interactive is as simple as calling <code>girafe(ggobj = CW)</code>. For better readability, the following script highlights the hovered ribbon and dims out the others.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">girafe</span>(</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  <span className="at">ggobj =</span> CW, </span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a>  <span className="at">options =</span> <span className="fu">list</span>(</span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a>    <span className="fu">opts_hover</span>(</span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a>      <span className="at">css =</span> <span className="fu">girafe_css</span>(</span>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>        <span className="co"># For hovered ribbon, use same color as in static plot</span></span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>        <span className="co"># instead of using the default color in orange</span></span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a>        <span className="at">css =</span> <span className="st">"opacity:1;"</span>, </span>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>      )</span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a>    ),</span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>    <span className="co"># dim out non-hovered elements into high transparency</span></span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a>    <span className="fu">opts_hover_inv</span>(</span>
<span id="cb14-13"><a aria-hidden="true" href="#cb14-13" tabindex="-1"></a>      <span className="at">css =</span> <span className="st">"opacity:.2;"</span></span>
<span id="cb14-14"><a aria-hidden="true" href="#cb14-14" tabindex="-1"></a>    )</span>
<span id="cb14-15"><a aria-hidden="true" href="#cb14-15" tabindex="-1"></a>  )</span>
<span id="cb14-16"><a aria-hidden="true" href="#cb14-16" tabindex="-1"></a>)</span></code></pre></div>
</div>
<div className="cell" data-layout-align="center">
<div className="cell-output-display">
<div className="girafe html-widget" id="htmlwidget-471906a988fdd2a34d50"></div>
</div>
</div>
</section>
</main>
</div>
</div>
)}