import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonOpeningstatement(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Pandas-for-Data-Wrangling">Pandas for Data Wrangling<a class="anchor-link" href="#Pandas-for-Data-Wrangling">¶</a></h3>
<p>🔥 <strong>Data wrangling</strong> describes the process of converting raw data into a structured and clean format suitable for analysis. In the realm of <strong>Data Science</strong>, data wrangling is a crucial preprocessing step, laying the foundation for successful analysis and modeling.<br/><br/>
🌟 One of the key tools in Python ecosystem for data wrangling is the Python library known as <strong>pandas</strong>. <strong>Pandas</strong> provides a robust and versatile framework for manipulating data efficiently. <br/><br/>
➡️ In this collection, you will embark on a journey of using the pandas libaray to preprocess your raw data and turn it into an organized format ready for further analaysis.<br/><br/>
✅ In this context, it becomes paramount to understand the significance of data wrangling and the pivotal role that pandas plays in Python. Together, they empower data scientists to harness the true potential of their data, ensuring it is prepared and structured optimally for subsequent analysis and modeling endeavors.<br/><br/>
<br />Without further delay, let's embark on our data wrangling journey ✨</p>
</div>
</div>
</div>
</div>
</div>
)}