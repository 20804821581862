import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2StreamBlockbuster from '../graphics/ggstream_blockbuster_theme_completed.png'; 
import imgGgplot2StreamBlockbusterWebp from '../graphics/ggstream_blockbuster_theme_completed.webp'; 
export default function Ggplot2StreamBlockbuster(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Ribbons and Streams in ggplot2 to Visualize Changing Popularity of Movie Genres</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2StreamBlockbusterWebp} />
    <img className="cover-img" src={imgGgplot2StreamBlockbuster} />
  </picture>

<p>In this visualization, we’ll create a ribbon/stream plot, leveraging the <Link to="https://github.com/davidsjoberg/ggstream"><code>ggstream</code></Link> package and its built-in dataset <code>blockbusters</code>, and visualize the changing popularity of different movie genres from 1980 to 2020.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data">
<h3 className="anchored" data-anchor-id="packages-and-data">Packages and data</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># install.packages("ggstream")</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(ggstream)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>)) <span className="co"># global theme</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">head</span>(blockbusters, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 3
<br/>   year genre     box_office
<br/>  &lt;dbl&gt; &lt;chr&gt;          &lt;dbl&gt;
<br/>1  1977 Action         2.98 
<br/>2  1977 Adventure      0.209
<br/>3  1977 Comedy         0.516
<br/>4  1977 Drama          2.54 </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>As an exploratory analysis, let’s start with creating a bar plot displaying the box office of different movie genres.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p0 <span className="ot">&lt;-</span> blockbusters <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> box_office, </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>             <span className="at">fill =</span> genre, <span className="at">color =</span> genre)) </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a></span><br/>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">alpha =</span> .<span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_barplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_barplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="geom_area">Alternative to the bar plot, we can use <strong><code>geom_area()</code></strong> (a generic ggplot2 function) to connect bars of the same genre category horizontally, effectively forming a continuous band (though with rugged edges).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_area</span>(<span className="at">alpha =</span> .<span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_area_plot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_area_plot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="geom_stream"><strong><code>geom_stream</code></strong> from the <strong><code>ggstream</code></strong> package generate ribbons similarly to <code>geom_area</code>, but with smoother ribbon outline. The smoothness is controlled by <code>bw</code> (bandwidth). A larger <code>bw</code> value creates more smoothness, while a smaller <code>bw</code> value generates more details, and may more faithfully reflects the underlying data pattern.</span></p>
<p>There are three options for the <strong><code>type</code></strong> argument: <code>ridge</code>, <code>proportional</code>, and <code>mirror</code>:</p>
<ul>
<li><strong><code>ridge</code></strong>: stack the ribbons from ground floor, as <code>geom_area</code>.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_stream</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"ridge"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_ridge.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_ridge"/>
  </picture>
</figure>
</div>
</div>
</div>
<ul>
<li><strong><code>proportional</code></strong>: stack the ribbons from ground floor, with a normalized total height of unit 1. It is reminiscent of the <code>fill</code> position.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_stream</span>(</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"proportional"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_proportional.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_proportional"/>
  </picture>
</figure>
</div>
</div>
</div>
<ul>
<li><strong><code>mirror</code></strong>: stack the ribbons symmetrically around the x-axis. It is not necessarily an easy way for data comparison, but is fun and engaging as an artwork. We’ll stick to this type for the following edits.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p0 <span className="sc">+</span> <span className="fu">geom_stream</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"mirror"</span>) </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_mirror.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_mirror"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Label the ribbons with the associated genre names using <strong><code>geom_stream_label</code></strong>. The texts are positioned automatically where the ribbon is the broadest.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_stream_label</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> genre), </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"mirror"</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_stream_label.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_stream_label"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Adjust the color palette, and add title and subtitle using the <code>annotate</code> way.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="co"># color scale</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>) <span className="sc">+</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>) <span className="sc">+</span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  <span className="co"># titles</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"Worldwide Blockbusters"</span>,  <span className="co"># title</span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>                     <span className="st">"the evolving popularity of genres"</span>), <span className="co"># subtitle</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">1978</span>, </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">8</span>, <span className="fl">6.5</span>), </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>           <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">6</span>, <span className="dv">5</span>), </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>           <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"snow3"</span>), </span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>, </span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="fu">c</span>(<span className="st">"bold"</span>, <span className="st">"italic"</span>)) </span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_annotate.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_annotate"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Remove the legend, and keep only the major x-axis grids.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>        <span className="at">panel.grid.major.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>        <span className="at">panel.grid.minor.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>        <span className="at">panel.grid.minor.x =</span> <span className="fu">element_blank</span>()) </span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/ggstream_blockbuster_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/ggstream_blockbuster_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="co"># install.packages("ggstream")</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="fu">library</span>(ggstream)</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a></span><br/>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>)) <span className="co"># global theme</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a></span><br/>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a><span className="fu">head</span>(blockbusters, <span className="at">n =</span> <span className="dv">4</span>)</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a></span><br/>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a><span className="co"># As an exploratory analysis to start with, first create a bar plot</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>p0 <span className="ot">&lt;-</span> blockbusters <span className="sc">%&gt;%</span> </span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> box_office, </span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a>             <span className="at">fill =</span> genre, <span className="at">color =</span> genre)) </span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a></span><br/>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_col</span>(<span className="at">alpha =</span> .<span className="dv">3</span>)</span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a></span><br/>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a><span className="co"># Create an area plot </span></span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_area</span>(<span className="at">alpha =</span> .<span className="dv">3</span>)</span>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a></span><br/>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a></span><br/>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a><span className="co"># ggstream creates a smoother version of area plot. </span></span>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a><span className="co"># There are three options for the `type` argument: </span></span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a><span className="do">## 1) ridge:</span></span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_stream</span>(<span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"ridge"</span>) </span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a></span><br/>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a><span className="do">## 2) proportional:</span></span>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a>p0 <span className="sc">+</span> <span className="fu">geom_stream</span>(<span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"proportional"</span>) </span>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a></span><br/>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a><span className="do">## 3) mirror:</span></span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> p0 <span className="sc">+</span> <span className="fu">geom_stream</span>(<span className="at">alpha =</span> .<span className="dv">3</span>, <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"mirror"</span>) </span>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>p1</span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a></span><br/>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a></span><br/>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a><span className="co"># Label the ribbons with the associated genre names.</span></span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a>  <span className="fu">geom_stream_label</span>(</span>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> genre), </span>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a>    <span className="at">bw =</span> .<span className="dv">5</span>, <span className="at">type =</span> <span className="st">"mirror"</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>)</span>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a>p2</span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a></span><br/>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a></span><br/>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a><span className="co"># Adjust the color palette, and add title and subtitle.</span></span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a>  <span className="co"># color scale</span></span>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>) <span className="sc">+</span></span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set1"</span>) <span className="sc">+</span></span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>  <span className="co"># titles</span></span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"Worldwide Blockbusters"</span>,  <span className="co"># title</span></span>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a>                     <span className="st">"the evolving popularity of genres"</span>), <span className="co"># subtitle</span></span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>           <span className="at">x =</span> <span className="dv">1978</span>, </span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">8</span>, <span className="fl">6.5</span>), </span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>           <span className="at">size =</span> <span className="fu">c</span>(<span className="dv">6</span>, <span className="dv">5</span>), </span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>           <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"snow4"</span>, <span className="st">"snow3"</span>), </span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>, </span>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="fu">c</span>(<span className="st">"bold"</span>, <span className="st">"italic"</span>)) </span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a>p3</span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a></span><br/>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a></span><br/>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a><span className="co"># Remove the legend, and keep only the major x-axis grids.  </span></span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a>        <span className="at">panel.grid.major.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a>        <span className="at">panel.grid.minor.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a>        <span className="at">panel.grid.minor.x =</span> <span className="fu">element_blank</span>()) </span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a>p4</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In addition to the generic function <code>geom_area</code>, and <code>geom_stream</code> from the <code>ggstream</code> package illustrated above, <strong><code>geom_alluvium</code></strong> from the <strong><code>ggalluvial</code></strong> package also renders smoothed ribbons in a similar manner. Check the following awesome <Link to="../ggplot2-area-plot-migration"><strong>stacked alluvium plot</strong></Link> that displays the changing population of migration from worldwide to the U.S. from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>To visualize changes with time, continuous bands and ribbons shown above are all powerful tools, especially when the <em>accumulated quantity</em> at each time point is of interest. In addition to these graphic elements, <Link to="../ggplot2-line-plot-cigarettes"><strong>annotated and highlighted line plots</strong></Link> are a concise and eye-catching alternative.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>For more complicated data, animations, such as the following <Link to="../ggplot2-population-pyramid-animation"><strong>population pyramids</strong></Link>, offer a unique and engaging solution to vividly visualize the dynamics of the data pattern.</p>
<p><Link to="../ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
</main>
</div>
</div>
)}