import React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Adsggplot } from "./Ads";
import { Link } from 'react-router-dom';
import { useRCustomEffect } from './useCustomEffect';
import AddTabsetQuarto from './js/addCodeFoldingTabforQuarto'
 
export default function VerticalPostsLayout() {
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [headerStyle, setHeaderStyle] = useState({top: '60px', left:'15px'});

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        if (prevScrollPos > currentScrollPos) {
            setHeaderStyle({top: '60px', left:'15px'})
        } else {
            setHeaderStyle({top: '0px', left:'15px'});
        }
        setPrevScrollPos(currentScrollPos);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);


    return (  
                
        <div className='main'>
            <div classname="row">
                <div className="col-md-1 col-sm-12 p-l-md-50" >
                    {/* <Link to="../R/gallery"> */}
                        {/* <button className="back-button stick-top-left" style={headerStyle}> */}
                        {window.innerWidth >= 758 && (
                            <Link to="../R/gallery" className='back-button stick-top-left' style={headerStyle}>
                                &lt; R Gallery
                            </Link>
                        )}
                        
{/*                         
                        <Link to="../R/gallery" className='back-button stick-top-left' style={headerStyle}>
                            <i className="fas fa-arrow-left large-bold-arrow"></i> R Gallery
                        </Link> */}
                            
                            
                        {/* </button> */}
                    {/* </Link> */}
                </div>
                <div className="col-md-11 col-sm-12 p-l-md-50">
                    <div className="row">
                        <div className="col-md-10 p-r-md-100">
                            {/* <div className='ads-top'>
                                Save this space for Ads                        
                            </div> */}
                            <div className="main-container w-xl-80">
                                <Outlet />
                            </div>
                            <div className='ads-mid'>
                                {/* Save this space for Ads */}
            
                            </div>

                        </div>
                        <div className='col-md-2'>
                            {/* Save this space for Ads */}
                            <Adsggplot />   
                        </div>


                    </div>
                    
                </div>
               
            </div>

        </div>   
    )
}