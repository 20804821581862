import React from 'react'; 
import useCustomEffect from '../../useCustomEffect';

export default function ExcelOpenpyxl() {
        useCustomEffect()
    return (
<div>
        <div id="header">
                <h1 class="title toc-ignore">Use Python Openpyxl to Update Excel Files</h1>
        </div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>Have you every need to update or change Excel worksheet cell by cell manually? To free you from doing repetative work, one of Python modules anmed Openxyl comes in handy for automating tedious Excel work. In this tutorial, you'll learn how to use Openpyxl in Python to update Excel files.</p>
 <p>Openpyxl is a rather useful module when it comes to handle Excel files in Python. Let's first see how to read in Excel files using Openpyxl.</p>
 
 </div> 
 </div>
 </div>
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>If you've not used Openpyxl package before, you need to install it first. To install the package, run following code in terminal.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[4]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">pip</span> <span class="n">install</span> <span class="n">openpyxl</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>Requirement already satisfied: openpyxl in /home/fagabby/working/anaconda3/lib/python3.9/site-packages (3.0.9)
 </span><span>Requirement already satisfied: et-xmlfile in /home/fagabby/working/anaconda3/lib/python3.9/site-packages (from openpyxl) (1.1.0)
 </span><span>Note: you may need to restart the kernel to use updated packages.
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>Then, import the necessary modules for today's example</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs  ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[1]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="kn">from</span> <span class="nn">openpyxl</span> <span class="kn">import</span> <span class="n">load_workbook</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Load-Excel-files-using-Openpyxl">Load Excel files using Openpyxl<a class="anchor-link" href="#Load-Excel-files-using-Openpyxl">&#182;</a></h3>
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs  ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[7]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">file</span> <span class="o">=</span> <span class="s2">&quot;sample dataset.xlsx&quot;</span></span>
 <span><span class="n">workbook</span> <span class="o">=</span> <span class="n">load_workbook</span><span class="p">(</span><span class="n">file</span><span class="p">)</span></span>
 <span><span class="c1"># select &#39;Sheet1&#39; from the Excel file</span></span>
 <span><span class="n">worksheet</span> <span class="o">=</span> <span class="n">workbook</span><span class="p">[</span><span class="s1">&#39;Sheet1&#39;</span><span class="p">]</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Access-rows-and-cells-in-the-Excel-file">Access rows and cells in the Excel file<a class="anchor-link" href="#Access-rows-and-cells-in-the-Excel-file">&#182;</a></h3><p>By using iter_rows() method of the worksheet, we can iterate through each row in the Excel file. Setting parameter values_only = True to show cell values.</p>
 <p>The first row is the header, and the followings are values of each row.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[39]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="k">for</span> <span class="n">row</span> <span class="ow">in</span> <span class="n">worksheet</span><span class="o">.</span><span class="n">iter_rows</span><span class="p">(</span><span class="n">values_only</span><span class="o">=</span><span class="kc">True</span><span class="p">):</span></span>
 <span>    <span class="nb">print</span><span class="p">(</span><span class="n">row</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>

 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 

 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
     
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>(&#39;Name&#39;, &#39;Age&#39;, &#39;City&#39;)
 </span><span>(&#39;Bill&#39;, &#39;45&#39;, &#39;Chicago&#39;)
 </span><span>(&#39;John&#39;, 30.0, &#39;Seattle&#39;)
 </span><span>(&#39;Mike&#39;, 35.0, &#39;Los Angeles&#39;)
 </span><span>(&#39;Jason&#39;, &#39;35&#39;, &#39;New York&#39;)
 </span><span>(&#39;Chloe&#39;, &#39;40&#39;, &#39;Chicago&#39;)
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>Another way to access one cell of the file, is by using the column and row index of Excel like "A2".</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[16]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="nb">print</span><span class="p">(</span><span class="s2">&quot;Cell A2 value is:&quot;</span><span class="p">,</span><span class="n">worksheet</span><span class="p">[</span><span class="s1">&#39;A2&#39;</span><span class="p">]</span><span class="o">.</span><span class="n">value</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>cell A2 value is: Alice
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Access-columns">Access columns<a class="anchor-link" href="#Access-columns">&#182;</a></h3><p>Similarly, we can use iter_cols() function to access columns.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[40]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="k">for</span> <span class="n">col</span> <span class="ow">in</span> <span class="n">worksheet</span><span class="o">.</span><span class="n">iter_cols</span><span class="p">(</span><span class="n">values_only</span><span class="o">=</span><span class="kc">True</span><span class="p">):</span></span>
 <span>    <span class="nb">print</span><span class="p">(</span><span class="n">col</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>(&#39;Name&#39;, &#39;Bill&#39;, &#39;John&#39;, &#39;Mike&#39;, &#39;Jason&#39;, &#39;Chloe&#39;)
 </span><span>(&#39;Age&#39;, &#39;45&#39;, 30.0, 35.0, &#39;35&#39;, &#39;40&#39;)
 </span><span>(&#39;City&#39;, &#39;Chicago&#39;, &#39;Seattle&#39;, &#39;Los Angeles&#39;, &#39;New York&#39;, &#39;Chicago&#39;)
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Modify-cell-values">Modify cell values<a class="anchor-link" href="#Modify-cell-values">&#182;</a></h3>
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[19]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="nb">print</span><span class="p">(</span><span class="s2">&quot;Cell B2 value before:&quot;</span><span class="p">,</span> <span class="n">worksheet</span><span class="p">[</span><span class="s1">&#39;B2&#39;</span><span class="p">]</span><span class="o">.</span><span class="n">value</span><span class="p">)</span></span>
 
 <span><span class="c1"># Modify Cell B2 value</span></span>
 <span><span class="n">worksheet</span><span class="p">[</span><span class="s1">&#39;B2&#39;</span><span class="p">]</span><span class="o">.</span><span class="n">value</span> <span class="o">=</span> <span class="mi">25</span></span>
 
 <span><span class="nb">print</span><span class="p">(</span><span class="s2">&quot;Cell B2 value after:&quot;</span><span class="p">,</span> <span class="n">worksheet</span><span class="p">[</span><span class="s1">&#39;B2&#39;</span><span class="p">]</span><span class="o">.</span><span class="n">value</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>Cell B2 value before: 20.0
 </span><span>Cell B2 value after: 25
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Add-new-rows-to-the-Excel-file">Add new rows to the Excel file<a class="anchor-link" href="#Add-new-rows-to-the-Excel-file">&#182;</a></h3><p>First, let's create a new data set and save it as a list of tuples. Then, use append() method to append new data to the worksheet</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs  ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[20]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">new_data</span> <span class="o">=</span> <span class="p">[</span></span>
 <span>    <span class="p">(</span><span class="s1">&#39;Jason&#39;</span><span class="p">,</span> <span class="s1">&#39;35&#39;</span><span class="p">,</span> <span class="s1">&#39;New York&#39;</span><span class="p">),</span></span>
 <span>    <span class="p">(</span><span class="s1">&#39;Chloe&#39;</span><span class="p">,</span> <span class="s1">&#39;40&#39;</span><span class="p">,</span> <span class="s1">&#39;Chicago&#39;</span><span class="p">)</span></span>
 <span><span class="p">]</span></span>
 
 <span><span class="c1"># iterate through the list of new data and append them to the Excel worksheet</span></span>
 <span><span class="k">for</span> <span class="n">row</span> <span class="ow">in</span> <span class="n">new_data</span><span class="p">:</span></span>
 <span>    <span class="n">worksheet</span><span class="o">.</span><span class="n">append</span><span class="p">(</span><span class="n">row</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>Now let's print the rows in the worksheet again and see if new rows have been added to the worksheet.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[31]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="c1"># iterate through the rows of the worksheet</span></span>
 <span><span class="k">for</span> <span class="n">index</span><span class="p">,</span> <span class="n">row</span> <span class="ow">in</span> <span class="nb">enumerate</span><span class="p">(</span><span class="n">worksheet</span><span class="o">.</span><span class="n">iter_rows</span><span class="p">(</span><span class="n">values_only</span><span class="o">=</span><span class="kc">True</span><span class="p">)):</span></span>
 <span>    <span class="nb">print</span><span class="p">(</span><span class="sa">f</span><span class="s2">&quot;row </span><span class="si">&#123;</span><span class="n">index</span> <span class="o">+</span> <span class="mi">1</span><span class="si">&#125;</span><span class="s2">:&quot;</span><span class="p">,</span> <span class="n">row</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>row 1: (&#39;Name&#39;, &#39;Age&#39;, &#39;City&#39;)
 </span><span>row 2: (&#39;Alice&#39;, 25, &#39;New York&#39;)
 </span><span>row 3: (&#39;Franklin&#39;, 25.0, &#39;San Francisco&#39;)
 </span><span>row 4: (&#39;John&#39;, 30.0, &#39;Seattle&#39;)
 </span><span>row 5: (&#39;Mike&#39;, 35.0, &#39;Los Angeles&#39;)
 </span><span>row 6: (&#39;Jason&#39;, &#39;35&#39;, &#39;New York&#39;)
 </span><span>row 7: (&#39;Chloe&#39;, &#39;40&#39;, &#39;Chicago&#39;)
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Replace-a-row-in-place">Replace a row in place<a class="anchor-link" href="#Replace-a-row-in-place">&#182;</a></h3><p>If you want to replace entire row 2 with other values, and want the new values to be placed in row 2, we can iterate cells in row 2 and replace it.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs  ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[37]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">new_row</span> <span class="o">=</span> <span class="p">[</span><span class="s1">&#39;Bill&#39;</span><span class="p">,</span> <span class="s1">&#39;45&#39;</span><span class="p">,</span> <span class="s1">&#39;Chicago&#39;</span><span class="p">]</span></span>
 
 <span><span class="c1"># select row 2 with worksheet[2] and iterate through cells in the row</span></span>
 <span><span class="k">for</span> <span class="n">index</span><span class="p">,</span> <span class="n">cell</span> <span class="ow">in</span> <span class="nb">enumerate</span><span class="p">(</span><span class="n">worksheet</span><span class="p">[</span><span class="mi">2</span><span class="p">]):</span></span>
 <span>    <span class="n">cell</span><span class="o">.</span><span class="n">value</span> <span class="o">=</span> <span class="n">new_row</span><span class="p">[</span><span class="n">index</span><span class="p">]</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>Row 2 now has been replace with new_row data.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[38]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="c1"># iterate through the rows of the worksheet</span></span>
 <span><span class="k">for</span> <span class="n">index</span><span class="p">,</span> <span class="n">row</span> <span class="ow">in</span> <span class="nb">enumerate</span><span class="p">(</span><span class="n">worksheet</span><span class="o">.</span><span class="n">iter_rows</span><span class="p">(</span><span class="n">values_only</span><span class="o">=</span><span class="kc">True</span><span class="p">)):</span></span>
 <span>    <span class="nb">print</span><span class="p">(</span><span class="sa">f</span><span class="s2">&quot;row </span><span class="si">&#123;</span><span class="n">index</span> <span class="o">+</span> <span class="mi">1</span><span class="si">&#125;</span><span class="s2">:&quot;</span><span class="p">,</span> <span class="n">row</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>row 1: (&#39;Name&#39;, &#39;Age&#39;, &#39;City&#39;)
 </span><span>row 2: (&#39;Bill&#39;, &#39;45&#39;, &#39;Chicago&#39;)
 </span><span>row 3: (&#39;John&#39;, 30.0, &#39;Seattle&#39;)
 </span><span>row 4: (&#39;Mike&#39;, 35.0, &#39;Los Angeles&#39;)
 </span><span>row 5: (&#39;Jason&#39;, &#39;35&#39;, &#39;New York&#39;)
 </span><span>row 6: (&#39;Chloe&#39;, &#39;40&#39;, &#39;Chicago&#39;)
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Delete-rows">Delete rows<a class="anchor-link" href="#Delete-rows">&#182;</a></h3><p>If you want to delete row 3 from the worksheet, you can use delete_rows() function. The first argument in the delete_rows() function specifies the row index you want to delete. The second argument 1 in the function specifies that only 1 row will be deleted.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs  ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[32]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="n">worksheet</span><span class="o">.</span><span class="n">delete_rows</span><span class="p">(</span><span class="mi">3</span><span class="p">,</span> <span class="mi">1</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <p>Let's print rows in the current worksheet. Comparing to the rows from last section, the row 3 was ('Franklin', 25.0, 'San Francisco') and now it's been deleted from the worksheet.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell   ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[33]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="c1"># iterate through the rows of the worksheet</span></span>
 <span><span class="k">for</span> <span class="n">index</span><span class="p">,</span> <span class="n">row</span> <span class="ow">in</span> <span class="nb">enumerate</span><span class="p">(</span><span class="n">worksheet</span><span class="o">.</span><span class="n">iter_rows</span><span class="p">(</span><span class="n">values_only</span><span class="o">=</span><span class="kc">True</span><span class="p">)):</span></span>
 <span>    <span class="nb">print</span><span class="p">(</span><span class="sa">f</span><span class="s2">&quot;row </span><span class="si">&#123;</span><span class="n">index</span> <span class="o">+</span> <span class="mi">1</span><span class="si">&#125;</span><span class="s2">:&quot;</span><span class="p">,</span> <span class="n">row</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 <div class="jp-Cell-outputWrapper">
 <div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
 </div>
 
 
 <div class="jp-OutputArea jp-Cell-outputArea">
 
 <div class="jp-OutputArea-child">
 
     
     <div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
 
 
 <div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
 <pre className='demo-highlight python'><code className='sourceCode r'><span>row 1: (&#39;Name&#39;, &#39;Age&#39;, &#39;City&#39;)
 </span><span>row 2: (&#39;Alice&#39;, 25, &#39;New York&#39;)
 </span><span>row 3: (&#39;John&#39;, 30.0, &#39;Seattle&#39;)
 </span><span>row 4: (&#39;Mike&#39;, 35.0, &#39;Los Angeles&#39;)
 </span><span>row 5: (&#39;Jason&#39;, &#39;35&#39;, &#39;New York&#39;)
 </span><span>row 6: (&#39;Chloe&#39;, &#39;40&#39;, &#39;Chicago&#39;)
 </span></code></pre>
 </div>
 </div>
 
 </div>
 
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 <h3 id="Save-changes-to-the-worksheet">Save changes to the worksheet<a class="anchor-link" href="#Save-changes-to-the-worksheet">&#182;</a></h3><p>To save all the changes to the original Excel file, one last step is to save the changes.</p>
 
 </div>
 </div>
 </div>
 </div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs  ">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea">
 <div class="jp-InputPrompt jp-InputArea-prompt">In&nbsp;[22]:</div>
 <div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
         <div class="CodeMirror cm-s-jupyter">
 <div class=" highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode r'><span><span class="c1"># save the workbook</span></span>
 <span><span class="n">workbook</span><span class="o">.</span><span class="n">save</span><span class="p">(</span><span class="n">file</span><span class="p">)</span></span>
 </code></pre></div>
 
         </div>
 </div>
 </div>
 </div>
 
 </div>
 <div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
 <div class="jp-Cell-inputWrapper">
 <div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
 </div>
 <div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
 </div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput " data-mime-type="text/markdown">
 
 </div>
 </div>
 </div>
 </div>
</div>            
    )
}

