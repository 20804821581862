import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2GeofacetUsStates from '../graphics/geofacet_election_completed.png'; 
import imgGgplot2GeofacetUsStatesWebp from '../graphics/geofacet_election_completed.webp'; 
export default function Ggplot2GeofacetUsStates(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Exploded Donut Plots in Geographic Map Layout with ggplot2 to Visualize the Presidential Election Results</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2GeofacetUsStatesWebp} />
    <img className="cover-img" src={imgGgplot2GeofacetUsStates} />
  </picture>

<p>In this article, we’ll use the <Link to="https://hafen.github.io/geofacet/index.html"><strong><code>geofacet</code></strong></Link> package developed by Ryan Hafen to visualize the voting results of the 2016 Presidential Election between Hillary Clinton and Donald Trump for U.S. states arranged in a map layout. The dataset we use is built in this package.</p>
<p><strong>Major techniques used in this visualization include:</strong></p>
<ul>
<li><a href="#subplots_map_layout">Create faceted subplots in a map layout</a></li>
<li><a href="#exploded_donut_plots">Create exploded donut plots with text annotations</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup 🌻</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="co"># install.packages("geofacet")</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(geofacet)</span></code></pre></div>
</div>
<p>R has two related built-in named vectors, <code>state.name</code> which records the name of 50 states in the United States, and <code>state.abb</code> which records the state name abbreviations in the same order. “District of Columbia” (“DC”) however is not included. Here we add it to these two vectors.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>state.abb<span className="fl">.2</span>  <span className="ot">&lt;-</span> <span className="fu">c</span>(state.abb, <span className="st">"DC"</span>)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>state.name<span className="fl">.2</span> <span className="ot">&lt;-</span> <span className="fu">c</span>(state.name, <span className="st">"District of Columbia"</span>)</span></code></pre></div>
</div>
<p><code>election</code> is a dataset from the <code>geofacet</code> package. It records the 2016 U.S. presidential election results from each state (including DC). Here we add to this dataset a new column of state name abbreviations to annotate the donut plots later.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>e <span className="ot">&lt;-</span> election <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">state.abbreviate =</span> state.abb<span className="fl">.2</span>[<span className="fu">match</span>(state, state.name<span className="fl">.2</span>)] )</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a><span className="fu">head</span>(e, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  state   candidate   votes   pct state.abbreviate
<br/>  &lt;chr&gt;   &lt;fct&gt;       &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;           
<br/>1 Alabama Clinton    729547 34.4  AL              
<br/>2 Alabama Trump     1318255 62.1  AL              
<br/>3 Alabama Other       75570  3.56 AL              </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization 🦩</h3>
<p><span id="subplots_map_layout"><strong>Step 1. Create “geographically” faceted bar plots showing the voting results for each state.</strong> <code>facet_geo</code> from the <code>geofacet</code> package works similarly to <code>facet_wrap</code>, but positions the subplots of each state in a manner reminiscent of their actual geographical locations.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> e <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1</span>, <span className="at">y =</span> pct, <span className="at">fill =</span> candidate)) <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">alpha =</span> <span className="fl">0.8</span>, <span className="at">color =</span> <span className="st">"white"</span>) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="co"># create subplots reflecting the geographical locations of each state</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">facet_geo</span>(<span className="sc">~</span> state, <span className="at">grid =</span> <span className="st">"us_state_grid1"</span>)  </span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a></span><br/>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_facet_geo.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/geofacet_election_facet_geo"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>In <code>facet_geo</code>, the grid option <code>us_state_grid2</code> puts Alaska at top left, and Hawaii bottom left, better mimicking the geographical position, but creates more white space. In comparison, <code>us_state_grid1</code> (as used in this work) puts both states at the bottom left corner, and is better at utilizing the available plotting space. Grids for many other geographical regions in the world are also available, and can be printed out by <code>get_grid_names()</code>.</p>
<p><span id="exploded_donut_plots"><strong>Step 2. Convert the bars into pies</strong> by applying the polar coordinate. Also use <code>theme_void</code> to create a blank canvas.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1<span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>) <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">theme_void</span>() </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_pie_chart.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/geofacet_election_pie_chart"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="3"><strong>Step 3. Convert the pies into donuts</strong>. This can be achieved simply by adjusting the x-axis scale range. A donut thinner than the displayed one can be produced by further reducing the first value in the <code>limits</code> argument, e.g., by <code>limits = c( -1, 1.5)</code>. If you don’t understand this technique, check this <Link to="../../R/visualization/17-ggplot2-polar-coord-summary"><strong>very useful summary</strong></Link> to learn the one-to-one relationship between the linear Cartesian coordinate and the polar coordinate.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>( <span className="sc">-</span>.<span className="dv">2</span>, <span className="fl">1.5</span>)) </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_donut_chart.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/geofacet_election_donut_chart"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Step 4. Add state name abbreviations at the donut center to replace subplot titles</strong> Here each abbreviated name created by <code>geom_text</code> is actually an overlap of three labels, as each state corresponds to three rows in the dataset. Also note that the coordinate x value <code>-.2</code> is the same as the left edge limit specified in <code>scale_x_continuous</code> in <a href="#3">step 3</a>, so that the state abbreviations are positioned at the center of donuts circles.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="co"># add abbreviated names</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> state.abbreviate, <span className="at">x =</span> <span className="sc">-</span>.<span className="dv">2</span>, <span className="at">y =</span> <span className="dv">0</span>),</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>            <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="co"># remove the full names (subplots titles)</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">strip.text =</span> <span className="fu">element_blank</span>())</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_centralized_texts.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/geofacet_election_centralized_texts"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Step 5. Reposition the legend and enlarge the donuts.</strong> Here we move the legend from right side of the plot to the top left corner. This frees up the space on the right side that the donuts can expand into. In addition, we reduce the margin between the subplots, and this further enlarges the donuts and renders a more compact outlook.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(<span className="at">direction =</span> <span className="st">"horizontal"</span>)) <span className="sc">+</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">2</span>, .<span className="dv">95</span>),</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>        <span className="at">legend.title =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>        <span className="co"># reduce distance between panels to expand the donuts</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>        <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="sc">-</span><span className="dv">10</span>, <span className="st">"pt"</span>)) </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a></span><br/>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/geofacet_election_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Step 6. A final polish-up.</strong> Here we update the donut colors, and add a plot title.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="co"># adjust color scale</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"#4e79a7"</span>, <span className="st">"#e15759"</span>, <span className="st">"#59a14f"</span>)) <span className="sc">+</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="co"># add  plot title</span></span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"2016 Election Results"</span>) <span className="sc">+</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">16</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> .<span className="dv">08</span>)) </span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a></span><br/>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/geofacet_election_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/geofacet_election_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="co"># install.packages("geofacet")</span></span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a><span className="fu">library</span>(geofacet)</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a></span><br/>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a><span className="co"># Create two vectors of US state names, one for full name, one for abbreviated names</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>state.abb<span className="fl">.2</span>  <span className="ot">&lt;-</span> <span className="fu">c</span>(state.abb, <span className="st">"DC"</span>)</span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>state.name<span className="fl">.2</span> <span className="ot">&lt;-</span> <span className="fu">c</span>(state.name, <span className="st">"District of Columbia"</span>)</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a></span><br/>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a><span className="co"># Here we add a new column of abbreviations of the state names.</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>e <span className="ot">&lt;-</span> election <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">state.abbreviate =</span> state.abb<span className="fl">.2</span>[<span className="fu">match</span>(state, state.name<span className="fl">.2</span>)] )</span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a></span><br/>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a><span className="co"># ready for visualization!</span></span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a><span className="fu">head</span>(e, <span className="at">n =</span> <span className="dv">3</span>) </span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a></span><br/>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a></span><br/>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a><span className="co"># Create geographically faceted bar plots showing the voting results for each state.</span></span>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> e <span className="sc">%&gt;%</span> </span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">1</span>, <span className="at">y =</span> pct, <span className="at">fill =</span> candidate)) <span className="sc">+</span></span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">alpha =</span> <span className="fl">0.8</span>, <span className="at">color =</span> <span className="st">"white"</span>) <span className="sc">+</span></span>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a>  <span className="co"># create subplots reflecting the geographical locations of each state</span></span>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a>  <span className="fu">facet_geo</span>(<span className="sc">~</span> state, <span className="at">grid =</span> <span className="st">"us_state_grid1"</span>)  </span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>p1</span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a></span><br/>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a></span><br/>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a><span className="co"># Convert the bars into pies</span></span>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1<span className="sc">+</span> </span>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a>  <span className="fu">coord_polar</span>(<span className="at">theta =</span> <span className="st">"y"</span>) <span className="sc">+</span></span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a>  <span className="fu">theme_void</span>() </span>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a>p2</span>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a></span><br/>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a></span><br/>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a><span className="co"># Step 3. Convert the pies into donuts.</span></span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">limits =</span> <span className="fu">c</span>( <span className="sc">-</span>.<span className="dv">2</span>, <span className="fl">1.5</span>)) </span>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a>p3</span>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a></span><br/>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a></span><br/>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a><span className="co"># Add state name abbreviations at the donut center to replace full names.</span></span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>  <span className="co"># add abbreviated names</span></span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> state.abbreviate, <span className="at">x =</span> <span className="sc">-</span>.<span className="dv">2</span>, <span className="at">y =</span> <span className="dv">0</span>),</span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a>            <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a>  <span className="co"># remove the full names, i.e., the title of subplots</span></span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">strip.text =</span> <span className="fu">element_blank</span>())</span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a>p4</span>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a></span><br/>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a></span><br/>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a><span className="co"># Reposition the legend and enlarge the donuts.</span></span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(<span className="at">direction =</span> <span className="st">"horizontal"</span>)) <span className="sc">+</span></span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">2</span>, .<span className="dv">95</span>),</span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a>        <span className="at">legend.title =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a>        <span className="co"># reduce distance between panels to expand the donuts</span></span>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a>        <span className="at">panel.spacing =</span> <span className="fu">unit</span>(<span className="sc">-</span><span className="dv">10</span>, <span className="st">"pt"</span>)) </span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a>p5</span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a></span><br/>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a></span><br/>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a><span className="co"># Update the donut colors, and add a plot title.</span></span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>  <span className="co"># adjust color scale</span></span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> <span className="fu">c</span>(<span className="st">"#4e79a7"</span>, <span className="st">"#e15759"</span>, <span className="st">"#59a14f"</span>)) <span className="sc">+</span></span>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a>  <span className="co"># add  plot title</span></span>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"2016 Election Results"</span>) <span className="sc">+</span></span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">16</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> .<span className="dv">08</span>)) </span>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a>p6</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check these awesome <Link to="../ggplot2-donut-GDP"><strong>exploded donut plots in faceted panels</strong></Link> that visualize the GDP contribution of the top 4 countries separately in each continent.</p>
<p><Link to="../ggplot2-donut-GDP">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/doughnut_plot_GDP_ggdraw_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>The pie and donut charts demonstrated above are created by mapping the <strong><code>y</code></strong> aesthetic from a linear coordinate to angles in a polar coordinate (<code>coord_polar(theta = "y")</code>). Alternatively, the <strong><code>x</code></strong> aesthetic can be mapped to angles (with <code>(theta = "x")</code>) to create a drastically different appearance. While less used, it opens opportunities to creative visual effects. In the following article, we use this new technique to create a <Link to="../ggplot2-bar-polar-coord-horsepower"><strong>spinning barplot</strong></Link> to echo with the theme of the visualization - the power of car engines.</p>
<p><Link to="../ggplot2-bar-polar-coord-horsepower">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_theme_completed.png" />
  </picture>
</Link></p>
<p>And in a follow-up article, we further tweak the spinning barplot into a <Link to="../ggplot2-lollipop-donut-horsepower"><strong>spinning lollipop plot</strong></Link>.</p>
<p><Link to="../ggplot2-lollipop-donut-horsepower">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/horsepower_lollipop_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}