import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function EscapeCharacters(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Escape Characters</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>An <strong>escape character</strong> is a character that indicates that its following character(s) should be interpreted differently (escaping from its original meaning). Backslash <strong><code>\</code></strong> is the most common escape character.</p>
<section className="level3" id="escape-a-special-character">
<h3 className="anchored" data-anchor-id="escape-a-special-character">Escape a special character</h3>
<p>For a special character to be a matching pattern (i.e., to be treated as a literal character), it has to be immediately escaped (preceded) by a backslash <code>\</code>. For example:</p>
<ul>
<li><code>\(</code> and <code>\)</code> separately matches the left and right literal parenthesis.</li>
<li><code>\[</code> and <code>\]</code> separately matches the left and right literal square bracket.</li>
<li><code>\.</code> is treated as a dot itself, instead of a <Link to="../4-wildcards">wildcard</Link>.</li>
<li><code>\^</code> and <code>\$</code> is treated respectively as a literal carat and dollar sign, instead of a <Link to="../3-anchors">position anchor</Link>.</li>
</ul>
<p>Since the backslash itself is a special character, it needs to be escaped with another backslash to be interpreted literally, e.g., using <code>\\.</code>, <code>\\^</code>, and <code>\\$</code>, and <code>\\(</code>.</p>
<p><strong><em>eg.1.</em></strong> <code>\\.</code> matches a literal dot, and <code>.*</code> matches a string of any length (here the dot is a wildcard). Thus, <code>\\..*</code> matches a literal dot and its following characters, i.e., the file extension.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"raw_data.xlsx"</span>, <span className="st">"data_analysis.RData"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"</span><span className="sc">\\</span><span className="st">..*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ raw_data&lt;.xlsx&gt;
<br/>[2] │ data_analysis&lt;.RData&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract</span>(x, <span className="st">"</span><span className="sc">\\</span><span className="st">..*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] ".xlsx"  ".RData"</code></pre>
</div>
</div>
</div>
</section>
<section className="level3" id="special-characters-with-characer-class">
<h3 className="anchored" data-anchor-id="special-characters-with-characer-class">Special characters with characer class</h3>
<p>When special characters are used with <Link to="../1-character-class">character class</Link> (within a pair of square brackets), they are interpreted literally, and does <strong>not</strong> need the backslash to escape.</p>
<p><strong><em>eg.2.</em></strong> <code>[$^*]</code> matches “$”, “^”, and “*” as literal characters.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>s <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"an book $"</span>, <span className="st">"carot or carat ^"</span>, <span className="st">"stars ** in the sky"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_view_all</span>(s, <span className="st">"[$^*]"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ an book &lt;$&gt;
<br/>[2] │ carot or carat &lt;^&gt;
<br/>[3] │ stars &lt;*&gt;&lt;*&gt; in the sky</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract</span>(s, <span className="st">"[$^*]"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "$" "^" "*"</code></pre>
</div>
</div>
</div>
</section>
<section className="level3" id="escape-a-regular-letter">
<h3 className="anchored" data-anchor-id="escape-a-regular-letter">Escape a regular letter</h3>
<p>As demonstrated above, when a special character is escaped (preceded) with a backslash <code>\</code>, it is interpreted literally as a character itself. On the other hand, an ordinary letter can be escaped to convey a different meaning:</p>
<ul>
<li><strong><code>\d</code></strong> matches a single digit</li>
<li><strong><code>\D</code></strong> matches a single non-digit</li>
<li><strong><code>\w</code></strong> matches a word character (alphanumeric + underscore)</li>
<li><strong><code>\W</code></strong> matches a non-word character</li>
<li><strong><code>\s</code></strong> matches any whitespace</li>
<li><strong><code>\S</code></strong> matches a non-whitespace</li>
<li><strong><code>\b</code></strong> matches a <Link to="../6-word-boundary">word boundary</Link></li>
<li><strong><code>\B</code></strong> matches a position that is not a word boundary</li>
<li><strong><code>\t</code></strong> matches a tab character</li>
<li><strong><code>\n</code></strong> matches a newline character</li>
</ul>
<p>Again, a second backslash is needed to escape itself, e.g., using <code>\\S</code>.</p>
<p>Consider the following examples.</p>
<p><strong><em>eg.3.</em></strong> <code>\\$</code> matches a literal dollar sign, and <code>\\d+</code> matches one or more digits. As such, <code>\\$\\d+</code> matches a dollar amount.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"book of $123"</span>, <span className="st">"price at 20% off"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">str_view_all</span>(d, <span className="st">"</span><span className="sc">\\</span><span className="st">$</span><span className="sc">\\</span><span className="st">d+"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ book of &lt;$123&gt;
<br/>[2] │ price at 20% off</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">str_extract</span>(d, <span className="st">"</span><span className="sc">\\</span><span className="st">$</span><span className="sc">\\</span><span className="st">d+"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "$123" NA    </code></pre>
</div>
</div>
</div>
<p><strong><em>eg.4.</em></strong> <code>\\d&#123;3&#125;\\.</code> matches three consecutive digits, followed with a literal dot. As such, <code>\\d&#123;3&#125;\\.\\d&#123;3&#125;\\.\\d&#123;4&#125;</code> matches a phone number in the form of xxx.xxx.xxxx.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Bob: 787.902.1068"</span>, <span className="st">"Mike: 910.087.1483"</span>)</span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a>p <span className="ot">&lt;-</span> <span className="st">"</span><span className="sc">\\</span><span className="st">d&#123;3&#125;</span><span className="sc">\\</span><span className="st">.</span><span className="sc">\\</span><span className="st">d&#123;3&#125;</span><span className="sc">\\</span><span className="st">.</span><span className="sc">\\</span><span className="st">d&#123;4&#125;"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, p)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ Bob: &lt;787.902.1068&gt;
<br/>[2] │ Mike: &lt;910.087.1483&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a><span className="fu">str_extract</span>(a, p)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "787.902.1068" "910.087.1483"</code></pre>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}