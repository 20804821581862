import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function FilterRows(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Filter Rows of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>The <strong><code>filter()</code></strong> function is used to select a subset of rows (i.e., observations) from a dataset that meets a specified condition. Commonly used filtration operations include bigger than <code>&gt;</code>, bigger or equal <code>&gt;=</code>, smaller than <code>&lt;</code>, smaller or equal <code>&lt;=</code>, equal <code>==</code>, not equal <code>!=</code>, logical AND <code>&amp;</code>, logical OR <code>|</code>, logical NOT <code>!</code>, multiple condition <code>%in%</code>, etc.</p>
<section className="level3" id="filter-by-a-single-criterion">
<h3 className="anchored" data-anchor-id="filter-by-a-single-criterion">Filter by a single criterion</h3>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># get beings with height lower than 100</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(height <span className="sc">&lt;</span> <span className="dv">100</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 7 × 5
<br/>    name                  height  mass hair_color skin_color 
<br/>    &lt;chr&gt;                  &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 R2-D2                     96    32 &lt;NA&gt;       white, blue
<br/>  2 R5-D4                     97    32 &lt;NA&gt;       white, red 
<br/>  3 Yoda                      66    17 white      green      
<br/>  4 Wicket Systri Warrick     88    20 brown      brown      
<br/>  # ℹ 3 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># get beings with blond hair; </span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="co"># use "==" to judge if two quantities are equal</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(hair_color <span className="sc">==</span> <span className="st">"blond"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 5
<br/>    name             height  mass hair_color skin_color
<br/>    &lt;chr&gt;             &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     
<br/>  1 Luke Skywalker      172    77 blond      fair      
<br/>  2 Anakin Skywalker    188    84 blond      fair      
<br/>  3 Finis Valorum       170    NA blond      fair</code></pre>
</div>
</div>
<p>You can use <em>functions</em> to create filtration criteria.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="co"># select beings whose "mass" contains missing value NA</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a><span className="co"># use is.na() to check if a value is NA</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(<span className="fu">is.na</span>(mass))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 28 × 5
<br/>    name           height  mass hair_color   skin_color
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;        &lt;chr&gt;     
<br/>  1 Wilhuff Tarkin    180    NA auburn, grey fair      
<br/>  2 Mon Mothma        150    NA auburn       fair      
<br/>  3 Arvel Crynyd       NA    NA brown        fair      
<br/>  4 Finis Valorum     170    NA blond        fair      
<br/>  # ℹ 24 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># get beings taller than the global average of all beings</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(height <span className="sc">&gt;</span> <span className="fu">mean</span>(height, <span className="at">na.rm =</span> T))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 52 × 5
<br/>    name              height  mass hair_color    skin_color
<br/>    &lt;chr&gt;              &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;         &lt;chr&gt;     
<br/>  1 Darth Vader          202   136 none          white     
<br/>  2 Owen Lars            178   120 brown, grey   light     
<br/>  3 Biggs Darklighter    183    84 black         light     
<br/>  4 Obi-Wan Kenobi       182    77 auburn, white fair      
<br/>  # ℹ 48 more rows</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># get beings whose hair color has "grey" component</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(<span className="fu">str_detect</span>(hair_color, <span className="st">"grey"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 5
<br/>    name           height  mass hair_color   skin_color
<br/>    &lt;chr&gt;           &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;        &lt;chr&gt;     
<br/>  1 Owen Lars         178   120 brown, grey  light     
<br/>  2 Wilhuff Tarkin    180    NA auburn, grey fair      
<br/>  3 Palpatine         170    75 grey         pale</code></pre>
</div>
</div>
<p>The above example uses <code>str_detect()</code> from the popular <Link to="/R/data-wrangling/stringr/0-introduction"><strong>stringr</strong> package</Link> to check if a vector of strings contain “grey”.</p>
</section>
<section className="level3" id="filter-by-multiple-criteria">
<h3 className="anchored" data-anchor-id="filter-by-multiple-criteria">Filter by multiple criteria</h3>
<div className="cell">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># get beings that has NA value in "mass" column, AND "height" below 100</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="co"># use "&amp;" for logical AND (intersection)</span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(<span className="fu">is.na</span>(mass) <span className="sc">&amp;</span> height <span className="sc">&lt;</span> <span className="dv">100</span> )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 1 × 5
<br/>    name   height  mass hair_color skin_color 
<br/>    &lt;chr&gt;   &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 R4-P17     96    NA none       silver, red</code></pre>
</div>
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="co"># get beings either with black hair, OR with mass below 50</span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="co"># use | for logical OR (union)</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(hair_color <span className="sc">==</span> <span className="st">"black"</span> <span className="sc">|</span> mass <span className="sc">&lt;</span> <span className="dv">50</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 24 × 5
<br/>    name              height  mass hair_color skin_color 
<br/>    &lt;chr&gt;              &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;      
<br/>  1 R2-D2                 96    32 &lt;NA&gt;       white, blue
<br/>  2 Leia Organa          150    49 brown      light      
<br/>  3 R5-D4                 97    32 &lt;NA&gt;       white, red 
<br/>  4 Biggs Darklighter    183    84 black      light      
<br/>  # ℹ 20 more rows</code></pre>
</div>
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="co"># get beings with hair color either blond OR black, OR white</span></span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a><span className="co"># use %in% to specify a scope of matches</span></span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">filter</span>(hair_color <span className="sc">%in%</span> <span className="fu">c</span>(<span className="st">"blond"</span>, <span className="st">"black"</span>, <span className="st">"white"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 20 × 5
<br/>    name              height  mass hair_color skin_color
<br/>    &lt;chr&gt;              &lt;int&gt; &lt;dbl&gt; &lt;chr&gt;      &lt;chr&gt;     
<br/>  1 Luke Skywalker       172    77 blond      fair      
<br/>  2 Biggs Darklighter    183    84 black      light     
<br/>  3 Anakin Skywalker     188    84 blond      fair      
<br/>  4 Yoda                  66    17 white      green     
<br/>  # ℹ 16 more rows</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}