import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Introduction(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Welcome to the dplyr Package!</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Data wrangling is one of the most important steps in data science. In R, a wide range of such tasks can be readily accomplished using the core package <strong>dplyr</strong>. From this tutorial, you’ll truly master the power of this package.</p>
<p>First, you’ll learn the important <strong>pipe operator <Link to="../1-pipe-operator"><code>%&gt;%</code></Link></strong>, which allows you to streamline functions in a highly efficient manner. Majority of functions in this tutorial will be written in a piped style.</p>
<p>Then, you’ll study the <strong>six most commonly used functions</strong>. A diverse combination of these six functions allows you to perform a good majority of data wrangling tasks.</p>
<ul>
<li>Select columns with <Link to="../2-select-columns"><code>select()</code></Link>. You’ll also learn many related techniques, including selection helper functions and the <strong>purrr</strong> style. These techniques are highly helpful for <code>select()</code> and many other dplyr functions.</li>
<li>Filter rows with <Link to="../3-filter-rows"><code>filter()</code></Link>.</li>
<li>Modify or create new columns with <Link to="../4-mutate-columns"><code>mutate()</code></Link>.</li>
<li>Create summarizing statistics with <Link to="../5-summarize"><code>summarize()</code></Link>.</li>
<li>Divide dataset into groups with <Link to="../6-grouped-dataset"><code>group_by()</code></Link>.</li>
<li>Arrange rows with <Link to="../7-arrange"><code>arrange()</code></Link>.</li>
</ul>
<p>Next, you’ll master more functions divided into the following sections. These functions further empower you to address complicated tasks with great efficiency and flexibility.</p>
<ul>
<li><p><strong>Functions that operate on rows</strong>. You’ll learn <Link to="../8-keep-distinct-rows"><code>distinct()</code></Link> to select unique non-duplicated rows, and the <Link to="../9-select-rows"><code>slice_*()</code></Link> family functions to select rows (complementary to <code>filter()</code>).</p></li>
<li><p><strong>Functions that operate on columns</strong>. You’ll learn <Link to="../10-glimpse-columns"><code>glimpse()</code></Link> to quickly glance at the dataset structure and content, <Link to="../11-extract-a-single-column"><code>pull()</code></Link> to extract values from a single column, <Link to="../12-rename-columns"><code>rename()</code> and <code>rename_with()</code></Link> to rename column header names, and <Link to="../13-reorder-columns"><code>relocate()</code></Link> to change column order.</p></li>
<li><p><strong>Column-wise and row-wise repeated operations.</strong> You’ll use <Link to="../14-repeated-operations-on-multiple-column"><code>across()</code></Link> to perform repeated operations across multiple columns, and <Link to="../15-rowwise-operations"><code>rowwise()</code> and <code>c_across()</code></Link> to apply repeated operations across multiple rows.</p></li>
<li><p><strong>Functions for paired datasets</strong> This section covers a wide variety of techniques to merge (or subtract) two datasets, including <Link to="../17-mutating-join-two-datasets">mutating join</Link> (<code>inner_join()</code>, <code>left_join()</code>, <code>right_join()</code>, and <code>full_join()</code>), <Link to="../18-filtering-join-two-datasets">filtering join</Link> (<code>semi_join()</code> and <code>anti_join()</code>), <Link to="../19-nest-join-two-datasets"><code>nest_join()</code></Link>, and <Link to="../20-cross-join-two-datasets"><code>cross_join()</code></Link>. In addition, you’ll learn how to <Link to="../21-bind-two-datasets-by-columns-rows">bind two or multiple datasets</Link> by columns or rows with <code>bind_cols()</code> and <code>bind_rows()</code>, and perform a variety of row-based <Link to="../22-set-operations">set operations</Link>.</p></li>
<li><p>In the end, you’ll learn the advanced feature of <Link to="../25-data-masking"><strong>data masking</strong></Link> to effectively incorporate these dplyr tools into your self-defined functions.</p></li>
</ul>
</main>
</div>
</div>
)}