import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2AreaEducation from '../graphics/area_education_theme_completed.png'; 
import imgGgplot2AreaEducationWebp from '../graphics/area_education_theme_completed.webp'; 
export default function Ggplot2AreaEducation(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create a Stacked Area (Ribbon) Plot to Visualize Temporal Changes of Education Degree Distribution in U.S.</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2AreaEducationWebp} />
    <img className="cover-img" src={imgGgplot2AreaEducation} />
  </picture>

<p>In this visualization, we’ll create a stacked ribbon plot to display the changing proportion of different education degrees earned in the U.S. from 1970 to 2020.</p>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and Data Cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) <span className="co"># load the "happy" dataset</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">17</span>))</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="co"># count number of observations in each group</span></span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="fu">group_by</span>(year, degree) <span className="sc">%&gt;%</span> </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">n =</span> <span className="fu">n</span>()) <span className="sc">%&gt;%</span> </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="co"># remove rows with NA values in "degree"</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span><span className="fu">is.na</span>(degree)) <span className="sc">%&gt;%</span> </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="co"># calculate the yearly proportion of each education degree</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  <span className="fu">group_by</span>(year) <span className="sc">%&gt;%</span> </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>    <span className="at">fraction =</span> n <span className="sc">/</span> <span className="fu">sum</span>(n),</span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>    </span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>    <span className="co"># reverse the default order of the "degree" variable</span></span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>    <span className="co"># so that high school degrees reside the bottom in the plot</span></span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>    <span className="co"># and higher education degrees reside the top in the plot</span></span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>    <span className="at">degree =</span> <span className="fu">factor</span>(degree, </span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a>                    <span className="at">levels =</span> <span className="fu">rev</span>(<span className="fu">unique</span>(degree)))) </span>
<span id="cb1-24"><a aria-hidden="true" href="#cb1-24" tabindex="-1"></a></span><br/>
<span id="cb1-25"><a aria-hidden="true" href="#cb1-25" tabindex="-1"></a><span className="fu">head</span>(h, <span className="dv">30</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 30 × 4
<br/># Groups:   year [6]
<br/>    year degree             n fraction
<br/>   &lt;dbl&gt; &lt;fct&gt;          &lt;int&gt;    &lt;dbl&gt;
<br/> 1  1972 lt high school   635   0.399 
<br/> 2  1972 high school      762   0.479 
<br/> 3  1972 junior college    17   0.0107
<br/> 4  1972 bachelor         124   0.0780
<br/> 5  1972 graduate          52   0.0327
<br/> 6  1973 lt high school   552   0.371 
<br/> 7  1973 high school      720   0.484 
<br/> 8  1973 junior college    20   0.0134
<br/> 9  1973 bachelor         132   0.0887
<br/>10  1973 graduate          65   0.0437
<br/># ℹ 20 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create ribbons using <code>geom_area()</code>. Like <code>geom_col()</code> and <code>geom_bar()</code>, the areas (ribbons) take a default stack position, and therefore all the ribbons add up to a total proportion of 100%.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> fraction, <span className="at">fill =</span> degree)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_area</span>(<span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Greens"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>)  </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_education_basic.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_education_basic"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Label with the education degree. This annotation serves as a more concise alternative to the legend. As the <code>y</code>-aesthetic is in fraction, both a <code>stack</code> of <code>fill</code> position in <code>geom_text()</code> would align the text with the ribbons.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>    <span className="at">data =</span> h <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2010</span>),</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> degree),</span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_education_label.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_education_label"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Alternatively, the above code can be written in the following format to produce an identical graphical effect: here we map the absolute counts <code>n</code> to aesthetic <code>y</code>, and apply the <code>fill</code> position to the ribbons to normalize the total height to unit 1. In addition, we specify the same <code>fill</code> position to <code>geom_text()</code> to align the texts with the ribbons.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>h <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="co"># map absolute counts "n" to aesthetic y</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> n, <span className="at">fill =</span> degree)) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="co"># use "fill" position for ribbons</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="fu">geom_area</span>(<span className="at">position =</span> <span className="st">"fill"</span>, <span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Greens"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>)  <span className="sc">+</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>    <span className="at">data =</span> h <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2010</span>),</span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> degree),</span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>    <span className="co"># use "fill" position for texts</span></span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>)) </span></code></pre></div>
</div>
<p>A final touch up.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="co"># revise y-axis labels and title</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste</span>(x <span className="sc">*</span> <span className="dv">100</span>, <span className="st">"%"</span>)&#125;,</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">n.breaks =</span> <span className="dv">6</span>, </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">name =</span> <span className="st">"Proportion"</span>) <span className="sc">+</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  </span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>  <span className="co"># remove the legend</span></span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>  <span className="co"># expand to fill up the panel</span></span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a></span><br/>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_education_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/area_education_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>The above ribbons are generated with the generic ggplot2 function <code>geom_area()</code>. The extension package <Link to="https://corybrunson.github.io/ggalluvial/"><code>ggalluvial</code></Link> generates ribbons in very like manner, but with <em>smoother ribbon outline</em>. The plot can be created using the same code above, but with <code>ggalluvial::geom_alluvium(aes(alluvium = degree))</code> in replace of <code>geom_area()</code>. (see another example below)</p>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) <span className="co"># load the "happy" dataset</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">17</span>))</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a></span><br/>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="co"># count number of observations in each group</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="fu">group_by</span>(year, degree) <span className="sc">%&gt;%</span> </span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">n =</span> <span className="fu">n</span>()) <span className="sc">%&gt;%</span> </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  </span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="co"># remove rows with NA values in "degree"</span></span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span><span className="fu">is.na</span>(degree)) <span className="sc">%&gt;%</span> </span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>  </span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>  <span className="co"># calculate the yearly proportion of each education degree</span></span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>  <span className="fu">group_by</span>(year) <span className="sc">%&gt;%</span> </span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>    <span className="at">fraction =</span> n <span className="sc">/</span> <span className="fu">sum</span>(n),</span>
<span id="cb7-18"><a aria-hidden="true" href="#cb7-18" tabindex="-1"></a>    </span>
<span id="cb7-19"><a aria-hidden="true" href="#cb7-19" tabindex="-1"></a>    <span className="co"># reverse the default order of the "degree" variable</span></span>
<span id="cb7-20"><a aria-hidden="true" href="#cb7-20" tabindex="-1"></a>    <span className="co"># so that high school degrees reside the bottom in the plot</span></span>
<span id="cb7-21"><a aria-hidden="true" href="#cb7-21" tabindex="-1"></a>    <span className="co"># and higher education degrees reside the top in the plot</span></span>
<span id="cb7-22"><a aria-hidden="true" href="#cb7-22" tabindex="-1"></a>    <span className="at">degree =</span> <span className="fu">factor</span>(degree, </span>
<span id="cb7-23"><a aria-hidden="true" href="#cb7-23" tabindex="-1"></a>                    <span className="at">levels =</span> <span className="fu">rev</span>(<span className="fu">unique</span>(degree)))) </span>
<span id="cb7-24"><a aria-hidden="true" href="#cb7-24" tabindex="-1"></a></span><br/>
<span id="cb7-25"><a aria-hidden="true" href="#cb7-25" tabindex="-1"></a><span className="fu">head</span>(h, <span className="dv">30</span>)</span>
<span id="cb7-26"><a aria-hidden="true" href="#cb7-26" tabindex="-1"></a></span><br/>
<span id="cb7-27"><a aria-hidden="true" href="#cb7-27" tabindex="-1"></a></span><br/>
<span id="cb7-28"><a aria-hidden="true" href="#cb7-28" tabindex="-1"></a><span className="co"># Create a ribbon plot</span></span>
<span id="cb7-29"><a aria-hidden="true" href="#cb7-29" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span> </span>
<span id="cb7-30"><a aria-hidden="true" href="#cb7-30" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> fraction, <span className="at">fill =</span> degree)) <span className="sc">+</span></span>
<span id="cb7-31"><a aria-hidden="true" href="#cb7-31" tabindex="-1"></a>  <span className="fu">geom_area</span>(<span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb7-32"><a aria-hidden="true" href="#cb7-32" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Greens"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>)  </span>
<span id="cb7-33"><a aria-hidden="true" href="#cb7-33" tabindex="-1"></a>p1</span>
<span id="cb7-34"><a aria-hidden="true" href="#cb7-34" tabindex="-1"></a></span><br/>
<span id="cb7-35"><a aria-hidden="true" href="#cb7-35" tabindex="-1"></a></span><br/>
<span id="cb7-36"><a aria-hidden="true" href="#cb7-36" tabindex="-1"></a><span className="co"># Label with the education degree. </span></span>
<span id="cb7-37"><a aria-hidden="true" href="#cb7-37" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb7-38"><a aria-hidden="true" href="#cb7-38" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb7-39"><a aria-hidden="true" href="#cb7-39" tabindex="-1"></a>    <span className="at">data =</span> h <span className="sc">%&gt;%</span> <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2010</span>),</span>
<span id="cb7-40"><a aria-hidden="true" href="#cb7-40" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> degree),</span>
<span id="cb7-41"><a aria-hidden="true" href="#cb7-41" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_stack</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb7-42"><a aria-hidden="true" href="#cb7-42" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>) </span>
<span id="cb7-43"><a aria-hidden="true" href="#cb7-43" tabindex="-1"></a>p2</span>
<span id="cb7-44"><a aria-hidden="true" href="#cb7-44" tabindex="-1"></a></span><br/>
<span id="cb7-45"><a aria-hidden="true" href="#cb7-45" tabindex="-1"></a></span><br/>
<span id="cb7-46"><a aria-hidden="true" href="#cb7-46" tabindex="-1"></a><span className="co"># A final touch up.  </span></span>
<span id="cb7-47"><a aria-hidden="true" href="#cb7-47" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb7-48"><a aria-hidden="true" href="#cb7-48" tabindex="-1"></a>  <span className="co"># revise y-axis labels and title</span></span>
<span id="cb7-49"><a aria-hidden="true" href="#cb7-49" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb7-50"><a aria-hidden="true" href="#cb7-50" tabindex="-1"></a>    <span className="at">labels =</span> <span className="cf">function</span>(x)&#123;<span className="fu">paste</span>(x <span className="sc">*</span> <span className="dv">100</span>, <span className="st">"%"</span>)&#125;,</span>
<span id="cb7-51"><a aria-hidden="true" href="#cb7-51" tabindex="-1"></a>    <span className="at">n.breaks =</span> <span className="dv">6</span>, </span>
<span id="cb7-52"><a aria-hidden="true" href="#cb7-52" tabindex="-1"></a>    <span className="at">name =</span> <span className="st">"Proportion"</span>) <span className="sc">+</span></span>
<span id="cb7-53"><a aria-hidden="true" href="#cb7-53" tabindex="-1"></a>  </span>
<span id="cb7-54"><a aria-hidden="true" href="#cb7-54" tabindex="-1"></a>  <span className="co"># remove the legend</span></span>
<span id="cb7-55"><a aria-hidden="true" href="#cb7-55" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) <span className="sc">+</span></span>
<span id="cb7-56"><a aria-hidden="true" href="#cb7-56" tabindex="-1"></a>  <span className="co"># expand to fill up the panel</span></span>
<span id="cb7-57"><a aria-hidden="true" href="#cb7-57" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb7-58"><a aria-hidden="true" href="#cb7-58" tabindex="-1"></a></span><br/>
<span id="cb7-59"><a aria-hidden="true" href="#cb7-59" tabindex="-1"></a>p3</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>As mentioned above, <Link to="https://corybrunson.github.io/ggalluvial/"><code>ggalluvial</code></Link>creates area plots with mathematically smoothed ribbon outlines, and is a visually appealing alternative to the generic <code>geom_area()</code> function. The following <Link to="../ggplot2-area-plot-migration"><strong>stacked ribbon / alluvium plot</strong></Link> by this package shows dynamic shifts in the migrant population to the United States from 1820 to 2009.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}