import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function UniteColumns(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Unite Multiple Columns into a Single Column</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Opposite to <Link to="../separate-columns"><code>separate()</code></Link>, <code>unite()</code> combines multiple columns into one column by pasting strings together.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>data <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="at">first_name =</span> <span className="fu">c</span>(<span className="st">"John"</span>, <span className="st">"Jane"</span>,     <span className="st">"Alice"</span>),</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="at">last_name  =</span> <span className="fu">c</span>(<span className="st">"Doe"</span>,  <span className="st">"Smith"</span>,    <span className="st">"Johnson"</span>),</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">DOB        =</span> <span className="fu">c</span>( <span className="cn">NA</span>,    <span className="st">"3/2/1993"</span>, <span className="st">"8/4/1997"</span>),</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">State      =</span> <span className="fu">c</span>(<span className="st">"MA"</span>,   <span className="st">"NJ"</span>,        <span className="cn">NA</span>),</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="at">employer   =</span> <span className="fu">c</span>(<span className="st">"MIT"</span>,   <span className="cn">NA</span>,         <span className="cn">NA</span>))</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a></span><br/>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>data</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  first_name last_name DOB      State employer
<br/>  &lt;chr&gt;      &lt;chr&gt;     &lt;chr&gt;    &lt;chr&gt; &lt;chr&gt;   
<br/>1 John       Doe       &lt;NA&gt;     MA    MIT     
<br/>2 Jane       Smith     3/2/1993 NJ    &lt;NA&gt;    
<br/>3 Alice      Johnson   8/4/1997 &lt;NA&gt;  &lt;NA&gt;    </code></pre>
</div>
</div>
<p>The <code>unite()</code> function has four basic arguments, and can be conveniently summarized as <code>unite(data, col, ..., sep = "_")</code>:</p>
<ul>
<li><code>data</code> specifies the input data frame, and is the first argument. It can be conveniently passed into this function using the <Link to="/R/data-wrangling/dplyr/1-pipe-operator">pipe operator <code>%&gt;%</code></Link>.</li>
<li><code>col</code> specifies the name of the new column to be created.</li>
<li><code>...</code> specifies the columns to unite. <Link to="/R/data-wrangling/dplyr/2-select-columns#selection_helpers">Selection helpers</Link> can be used for convenient pick of a range of columns.</li>
<li><code>sep</code> specifies the separator used between united values. If not specified, the underscore <code>_</code> is used as the separator by default.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># Create a new column combining the full and last name, connected by a hyphen</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>data <span className="sc">%&gt;%</span> <span className="fu">unite</span>(<span className="at">col =</span> full_name, first_name, last_name, <span className="at">sep =</span> <span className="st">"-"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>  full_name     DOB      State employer
<br/>  &lt;chr&gt;         &lt;chr&gt;    &lt;chr&gt; &lt;chr&gt;   
<br/>1 John-Doe      &lt;NA&gt;     MA    MIT     
<br/>2 Jane-Smith    3/2/1993 NJ    &lt;NA&gt;    
<br/>3 Alice-Johnson 8/4/1997 &lt;NA&gt;  &lt;NA&gt;    </code></pre>
</div>
</div>
<p>Use <code>remove = FALSE</code> to keep the original columns from the output.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>data <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">unite</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    <span className="at">col =</span> full_name, first_name, last_name, <span className="at">sep =</span> <span className="st">"-"</span>,</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="at">remove =</span> F) <span className="co"># do not remove original columns "first_name" and "last_name"</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  full_name     first_name last_name DOB      State employer
<br/>  &lt;chr&gt;         &lt;chr&gt;      &lt;chr&gt;     &lt;chr&gt;    &lt;chr&gt; &lt;chr&gt;   
<br/>1 John-Doe      John       Doe       &lt;NA&gt;     MA    MIT     
<br/>2 Jane-Smith    Jane       Smith     3/2/1993 NJ    &lt;NA&gt;    
<br/>3 Alice-Johnson Alice      Johnson   8/4/1997 &lt;NA&gt;  &lt;NA&gt;    </code></pre>
</div>
</div>
<p>Combine all the columns using <code>everything()</code>. Note that missing values are concatenated as “NA” strings.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>data <span className="sc">%&gt;%</span> <span className="fu">unite</span>(<span className="at">col =</span> people, <span className="fu">everything</span>(), <span className="at">remove =</span> F)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  people                       first_name last_name DOB      State employer
<br/>  &lt;chr&gt;                        &lt;chr&gt;      &lt;chr&gt;     &lt;chr&gt;    &lt;chr&gt; &lt;chr&gt;   
<br/>1 John_Doe_NA_MA_MIT           John       Doe       &lt;NA&gt;     MA    MIT     
<br/>2 Jane_Smith_3/2/1993_NJ_NA    Jane       Smith     3/2/1993 NJ    &lt;NA&gt;    
<br/>3 Alice_Johnson_8/4/1997_NA_NA Alice      Johnson   8/4/1997 &lt;NA&gt;  &lt;NA&gt;    </code></pre>
</div>
</div>
<p>Use <code>na.rm = TRUE</code> to remove missing values prior to uniting the columns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>data <span className="sc">%&gt;%</span> <span className="fu">unite</span>(<span className="at">col =</span> people, <span className="fu">everything</span>(), <span className="at">remove =</span> F, <span className="at">na.rm =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  people                 first_name last_name DOB      State employer
<br/>  &lt;chr&gt;                  &lt;chr&gt;      &lt;chr&gt;     &lt;chr&gt;    &lt;chr&gt; &lt;chr&gt;   
<br/>1 John_Doe_MA_MIT        John       Doe       &lt;NA&gt;     MA    MIT     
<br/>2 Jane_Smith_3/2/1993_NJ Jane       Smith     3/2/1993 NJ    &lt;NA&gt;    
<br/>3 Alice_Johnson_8/4/1997 Alice      Johnson   8/4/1997 &lt;NA&gt;  &lt;NA&gt;    </code></pre>
</div>
</div>
</main>
</div>
</div>
)}