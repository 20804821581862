import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2HeatmapVolcanoTopography from '../graphics/heatmap-volcanocompleted.png'; 
import imgGgplot2HeatmapVolcanoTopographyWebp from '../graphics/heatmap-volcanocompleted.webp'; 
export default function Ggplot2HeatmapVolcanoTopography(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Draw Heatmap in ggplot2 to Visualize Volcano Topography</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2HeatmapVolcanoTopographyWebp} />
    <img className="cover-img" src={imgGgplot2HeatmapVolcanoTopography} />
  </picture>

<p>This brief demo illustrates how to create a heatmap using <code>geom_raster()</code> in ggplot2.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(reshape2)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>volcano.tidy <span className="ot">&lt;-</span> volcano <span className="sc">%&gt;%</span> <span className="fu">melt</span>() <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() </span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>volcano.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Var1, <span className="at">y =</span> Var2, <span className="at">fill =</span> value)) <span className="sc">+</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="co"># create a heatmap</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">geom_raster</span>() <span className="sc">+</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="co"># color palette</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>, <span className="at">option =</span> <span className="st">"A"</span>) <span className="sc">+</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="co"># titles</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Volcano Topography"</span>, </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>    <span className="at">x =</span> <span className="st">"X"</span>, <span className="at">y =</span> <span className="st">"Y"</span>, <span className="at">fill =</span> <span className="st">"volcano height"</span>) <span className="sc">+</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="co"># legend color bar</span></span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="fu">guides</span>(</span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>    <span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>      <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>      <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>))) <span className="sc">+</span></span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb1-24"><a aria-hidden="true" href="#cb1-24" tabindex="-1"></a>  <span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">15</span>) <span className="sc">+</span></span>
<span id="cb1-25"><a aria-hidden="true" href="#cb1-25" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb1-26"><a aria-hidden="true" href="#cb1-26" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">hjust =</span> .<span className="dv">5</span>),</span>
<span id="cb1-27"><a aria-hidden="true" href="#cb1-27" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb1-28"><a aria-hidden="true" href="#cb1-28" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>()) </span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out the following <Link to="../ggplot2-heatmap-sunspot-activities"><strong>heatmap with synchornized lineplot</strong></Link> that visualize the sunspot activities in the past two hundred years.</p>
<p><Link to="../ggplot2-heatmap-sunspot-activities">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out these beautiful <Link to="../ggplot2-heatmap-vaccine-for-8-diseases"><strong>heatmaps showing the disease incidences before and after the vaccine introduction</strong></Link> throughout the U.S. history.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>And check out this <Link to="../ggplot2-heatmap-African-population"><strong>amazing heatmap visualizing the population distribution in Africa</strong></Link>. In particular, it leverages magical mathematical transformations in the color scale (with a simple line of code) to unveil the underlying highly skewed data pattern.</p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>All above heatmaps are generated using the generic function <code>geom_tile()</code> or <code>geom_raster()</code>. Alternatively, <strong>a heatmap can be created in the form of a 2D histogram using <code>geom_bin2d()</code></strong> – check out the following awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}