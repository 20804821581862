import { Link } from "react-router-dom"

export default function RVisualLandingPage() { 
    return ( 
        <div>
            <div style={{maxWidth:'30%', marginBottom:'0' }}>
                {/* <img src={pysparkImg} alt='pyspark'></img> */}
            </div>
            <div id="header" style={{marginTop: '20px'}}>
                <h1 class="posts-heading">Welcome to the R Visualization Tutorial!</h1>
            </div>             
            <p>ggplot2 is a powerful and versatile R package, and has revolutionized the way we create visual representations of data. It is nowadays one of the most used tools in data science. In the following articles, I have summarized some of the most commonly used techniques in ggplot2 for effective visualization and storytelling.
                 
            </p>
            <p>If you want to develop a systematic working knowledge about ggplot2, I invite you to check my <Link to='/R/eBook/flash-to-r-ggplot2-mastery'>unique comic-style ebook</Link>. It promises to guide you through the intricacies of ggplot2 with ease and enjoyment, and to let you master ggplot2 fast and easy like never before. </p>
           
           
        </div>
       
        
    )
}