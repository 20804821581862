import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function BindTwoDatasetsByColumnsRows(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Bind Two Datasets by Columns or Rows</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<section className="level3" id="bind-datasets-by-columns-with-bind_cols">
<h3 className="anchored" data-anchor-id="bind-datasets-by-columns-with-bind_cols">Bind datasets by columns with <strong><code>bind_cols()</code></strong></h3>
<p>Use <strong><code>bind_cols()</code></strong> to combine two or more datasets to make a wider dataset. The row number of the input datasets must be the same. Columns are combined, and the rows are kept in the original order.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>A <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">X =</span> letters[<span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>])</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>B <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">Y =</span> LETTERS[<span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>])</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>C <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">P =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">3</span>,<span className="at">Q =</span> <span className="dv">4</span><span className="sc">:</span><span className="dv">6</span>)</span></code></pre></div>
</div>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>A</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 1
<br/>    X    
<br/>    &lt;chr&gt;
<br/>  1 a    
<br/>  2 b    
<br/>  3 c</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>B</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 1
<br/>    Y    
<br/>    &lt;chr&gt;
<br/>  1 A    
<br/>  2 B    
<br/>  3 C</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>C</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>        P     Q
<br/>    &lt;int&gt; &lt;int&gt;
<br/>  1     1     4
<br/>  2     2     5
<br/>  3     3     6</code></pre>
</div>
</div>
</div>
</div>
<p>Combine the three datasets:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">bind_cols</span>(A, B, C)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 4
<br/>    X     Y         P     Q
<br/>    &lt;chr&gt; &lt;chr&gt; &lt;int&gt; &lt;int&gt;
<br/>  1 a     A         1     4
<br/>  2 b     B         2     5
<br/>  3 c     C         3     6</code></pre>
</div>
</div>
<p>It is similar to the following code using <code>do.call()</code> and the base R function <code>cbind()</code>.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">do.call</span>(cbind, <span className="fu">list</span>(A, B, C))</span></code></pre></div>
</div>
</section>
<section className="level3" id="bind-datasets-by-rows-with-bind_rows">
<h3 className="anchored" data-anchor-id="bind-datasets-by-rows-with-bind_rows">Bind datasets by rows with <strong><code>bind_rows()</code></strong></h3>
<p>Use <strong><code>bind_rows()</code></strong> to combine two or more datasets by rows to produce a longer dataset. Rows are stacked one dataset after another, with columns are kept in the original order.</p>
<p>Consider the following example. Both datasets A and B have the same column names “x” and “y”, and in addition B has a unique column “z”; dataset C has a shared column “x”, and a unique column “p”.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>A <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">x =</span> letters[<span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>], <span className="at">y =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>B <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">x =</span> LETTERS[<span className="dv">1</span><span className="sc">:</span><span className="dv">2</span>], <span className="at">y =</span> <span className="dv">4</span><span className="sc">:</span><span className="dv">5</span>, <span className="at">z =</span> <span className="fu">c</span>(<span className="st">"m"</span>, <span className="st">"n"</span>))</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>C <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="st">"cat"</span>, <span className="st">"dog"</span>), <span className="at">p =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">4</span>))</span></code></pre></div>
</div>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>A</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>    x         y
<br/>    &lt;chr&gt; &lt;int&gt;
<br/>  1 a         1
<br/>  2 b         2</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>B</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 3
<br/>    x         y z    
<br/>    &lt;chr&gt; &lt;int&gt; &lt;chr&gt;
<br/>  1 A         4 m    
<br/>  2 B         5 n</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a>C</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>    x         p
<br/>    &lt;chr&gt; &lt;dbl&gt;
<br/>  1 cat       2
<br/>  2 dog       4</code></pre>
</div>
</div>
</div>
</div>
<p>Combine datasets by the rows. Note how missing columns are filled with <code>NA</code> values in the output dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="fu">bind_rows</span>(A, B, C) <span className="sc">%&gt;%</span> <span className="fu">as.data.frame</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">      x  y    z  p
<br/>  1   a  1 &lt;NA&gt; NA
<br/>  2   b  2 &lt;NA&gt; NA
<br/>  3   A  4    m NA
<br/>  4   B  5    n NA
<br/>  5 cat NA &lt;NA&gt;  2
<br/>  6 dog NA &lt;NA&gt;  4</code></pre>
</div>
</div>
</section>
</main>
</div>
</div>
)}