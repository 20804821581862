import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function CountObservations(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Count Number of Observations of a Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Use <strong><code>count()</code></strong> to count the number of observations (rows) in each group. The following example counts the observations in each categorical level of the “sex” variable.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> <span className="fu">count</span>(sex)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 5 × 2
<br/>    sex                n
<br/>    &lt;chr&gt;          &lt;int&gt;
<br/>  1 female            16
<br/>  2 hermaphroditic     1
<br/>  3 male              60
<br/>  4 none               6
<br/>  # ℹ 1 more row</code></pre>
</div>
</div>
<p>It is equivalent to:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> <span className="fu">group_by</span>(sex) <span className="sc">%&gt;%</span> <span className="fu">summarise</span>(<span className="at">n =</span> <span className="fu">n</span>())</span></code></pre></div>
</div>
<p>Use <strong><code>sort = T</code></strong> to arrange rows by counts.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> <span className="fu">count</span>(sex, <span className="at">sort =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 5 × 2
<br/>    sex        n
<br/>    &lt;chr&gt;  &lt;int&gt;
<br/>  1 male      60
<br/>  2 female    16
<br/>  3 none       6
<br/>  4 &lt;NA&gt;       4
<br/>  # ℹ 1 more row</code></pre>
</div>
</div>
<p>You can count observations in each unique combination of two or more variables.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>starwars <span className="sc">%&gt;%</span> <span className="fu">count</span>(sex, gender, <span className="at">sort =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 6 × 3
<br/>    sex    gender        n
<br/>    &lt;chr&gt;  &lt;chr&gt;     &lt;int&gt;
<br/>  1 male   masculine    60
<br/>  2 female feminine     16
<br/>  3 none   masculine     5
<br/>  4 &lt;NA&gt;   &lt;NA&gt;          4
<br/>  # ℹ 2 more rows</code></pre>
</div>
</div>
</main>
</div>
</div>
)}