import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function CrossJoinTwoDatasets(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Cross Join Two Datasets</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>Cross join</strong> matches each row in dataset A to every row in dataset B. This gives all possible combinations between rows of A and B, and the output dataset has a row number of <code>nrow(A) * nrow(B)</code>. As such, unlike all the other <code>*_join</code> functions discussed earlier, there is no <code>by</code> argument in <code>cross_join()</code>.</p>
<p>Consider the following example:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>A <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>), <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>))</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>B <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">a =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>, <span className="st">"c"</span>), <span className="at">b =</span> <span className="fu">c</span>(<span className="dv">10</span>, <span className="dv">20</span>, <span className="dv">30</span>))</span></code></pre></div>
</div>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>A</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>    x         y
<br/>    &lt;chr&gt; &lt;dbl&gt;
<br/>  1 a         1
<br/>  2 b         2</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>B</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>    a         b
<br/>    &lt;chr&gt; &lt;dbl&gt;
<br/>  1 a        10
<br/>  2 b        20
<br/>  3 c        30</code></pre>
</div>
</div>
</div>
</div>
<p>With <code>cross_join()</code>, each row of A is matched with three rows of B, leading to a total 2 x 3 = 6 combinations (rows) in the output dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>A <span className="sc">%&gt;%</span> <span className="fu">cross_join</span>(B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 6 × 4
<br/>    x         y a         b
<br/>    &lt;chr&gt; &lt;dbl&gt; &lt;chr&gt; &lt;dbl&gt;
<br/>  1 a         1 a        10
<br/>  2 a         1 b        20
<br/>  3 a         1 c        30
<br/>  4 b         2 a        10
<br/>  5 b         2 b        20
<br/>  6 b         2 c        30</code></pre>
</div>
</div>
</main>
</div>
</div>
)}