import { useState, useEffect } from "react";


export default function AddTabsetQuarto(){
    const [activeItem, setActiveItem] = useState('stepwise-instructions');
    const toggleTab = (itemId) => {
        setActiveItem(itemId)
        // select <li> of tabs
        var list_stepwise = document.querySelector('li#tabset-1-1')
        var list_code = document.querySelector('li#tabset-1-2')
        // select content <div> tags 
        var div_stepwise = document.querySelector('div#tabset-1-1') 
        var div_code = document.querySelector('div#tabset-1-2')
        // add and remove active class to tab and contents
        if (itemId == 'tabset-1-1'){
            list_stepwise.classList.add('active');
            list_code.classList.remove('active');
            div_stepwise.classList.add('active')
            div_code.classList.remove('active')
        } else if (itemId == 'tabset-1-2'){
            list_stepwise.classList.remove('active')
            list_code.classList.add('active')
            div_stepwise.classList.remove('active')
            div_code.classList.add('active')
        }
    }
    useEffect(()=> {
        // select tab contents <div> components
        // should select out two tab contents for stewise instructions and code only separately
        var tabs = document.querySelectorAll("div.tab-pane");
        if (tabs) {
            var activeTab = 0
            // iteratively actions upon the two tab contents
            for (var i = 0; i < tabs.length; i++){
                var tab = tabs[i];
                // get id of <div> tag of contents
                var id = tab.getAttribute('id');
                if(tab.classList.contains('active')){
                    activeTab = i;
                }
                // select the <a> tag in <li> tagsby using their id
                var a = document.querySelector("a#"+id+"-tab")
                // remove the href attribute to avoid using href in <a> tags
                a.removeAttribute('href')
                
                // select the <ul> tag
                var tabList = document.querySelector("ul[role='tablist']");
                var li = tabList.children[i]
                // add id to the <li> tag as it doesn't have id 
                li.setAttribute('id', id)
                
                if (id=="tabset-1-1") {
                    li.classList.add('active')
                    tab.classList.add('active')
                }
                tab.classList.add('tabbed-pane');   
            }
        }
       
            
    }, []);
    
    useEffect(()=> {
        var list_stepwise = document.querySelector('li#tabset-1-1')
        var list_code = document.querySelector('li#tabset-1-2')
        // add click eventListener to the two <li> tabs
        if (list_stepwise){
            list_stepwise.addEventListener('click', () => toggleTab('tabset-1-1'))
        }
        if (list_code) {
            list_code.addEventListener('click', () => toggleTab('tabset-1-2'))
        }

        return() => {
            if (list_stepwise){
                list_stepwise.classList.remove('active');

            }
            if (list_stepwise){
                list_stepwise.removeEventListener('click', () => toggleTab('tabset-1-1'))
            }
            if (list_code){
                list_code.removeEventListener('click', () => toggleTab('tabset-1-2'))  
            }
        }       
    }, [activeItem])   
};
