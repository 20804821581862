import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2FishEncounters from '../graphics/fish_encounter_theme_completed.png'; 
import imgGgplot2FishEncountersWebp from '../graphics/fish_encounter_theme_completed.webp'; 
export default function Ggplot2FishEncounters(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Fish Encounter Histories in R ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2FishEncountersWebp} />
    <img className="cover-img" src={imgGgplot2FishEncounters} />
  </picture>

<p>In this work, we’ll create a scatterplot of fish icons to illustrate the detection of tagged fish which swims upstream to downstream. This visualization is inspired from the <Link to="https://fishsciences.github.io/post/visualizing-fish-encounter-histories/">work of Practical Pisces</Link>.</p>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<hr/>
<section className="level3" id="what-is-a-fish-encounter-history">
<h3 className="anchored" data-anchor-id="what-is-a-fish-encounter-history">What Is a Fish Encounter History?</h3>
<p>When working with tagged fish swimming in a river, we often generate a record of each fish’s “encounters” with the autonomous monitors in an underwater array. Encounter histories are the translation of a fish’s path into a row of ones (detected) and zeros (or <code>NA</code>s, not detected).</p>
</section>
<section className="level3" id="packages-and-data-preparation">
<h3 className="anchored" data-anchor-id="packages-and-data-preparation">Packages and Data Preparation</h3>
<p>The <code>fish_encounters</code> dataset is built in <Link to="/R/data-wrangling/tidyr/introduction">tidyr</Link> package, and already arranged in a tidy format. The <code>station</code> variable is a <em>factor</em> arranged from the most upstream (the “Release” station) to the most downstream.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>fish_encounters</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 114 × 3
<br/>  fish  station  seen
<br/>  &lt;fct&gt; &lt;fct&gt;   &lt;int&gt;
<br/>1 4842  Release     1
<br/>2 4842  I80_1       1
<br/>3 4842  Lisbon      1
<br/>4 4842  Rstr        1
<br/>5 4842  Base_TD     1
<br/># ℹ 109 more rows</code></pre>
</div>
</div>
<p>To get a better view of the dataset structure, we can transform the tidy dataset into a wider format with <Link to="/R/data-wrangling/tidyr/pivot-wider-part1"><code>pivot_wider()</code></Link>: each row represents a different tagged fish, and each column represents a different monitor location (“Station”), and <code>NA</code> values represent a lack of detection of the fish at the related station.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>fish_encounters <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">pivot_wider</span>(<span className="at">names_from =</span> station, <span className="at">values_from =</span> seen)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 19 × 12
<br/>  fish  Release I80_1 Lisbon  Rstr Base_TD   BCE   BCW  BCE2  BCW2   MAE   MAW
<br/>  &lt;fct&gt;   &lt;int&gt; &lt;int&gt;  &lt;int&gt; &lt;int&gt;   &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt; &lt;int&gt;
<br/>1 4842        1     1      1     1       1     1     1     1     1     1     1
<br/>2 4843        1     1      1     1       1     1     1     1     1     1     1
<br/>3 4844        1     1      1     1       1     1     1     1     1     1     1
<br/>4 4845        1     1      1     1       1    NA    NA    NA    NA    NA    NA
<br/>5 4847        1     1      1    NA      NA    NA    NA    NA    NA    NA    NA
<br/># ℹ 14 more rows</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>Draw dots to illustrate the detention of each fish at each station.</strong> <code>geom_path()</code> is used to connect the dots in order of their appearance in the dataset by order of stations from upstream to downstream (though <code>geom_line()</code> here produces the same graphic effect). <code>aes(group = fish)</code> ensures that dots are connected respectively for each fish.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> fish_encounters <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> station, <span className="at">y =</span> fish, <span className="at">color =</span> station)) <span className="sc">+</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_point</span>() <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">geom_path</span>(<span className="fu">aes</span>(<span className="at">group =</span> fish), <span className="at">size =</span> <span className="fl">0.25</span>, <span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a></span><br/>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/fish_encounter_points_lines.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/fish_encounter_points_lines"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A typical pattern of encounter histories for outmigrating juvenile fish is to see the detection rate decline as they migrate downstream and succumb to predation or other mortality factors.</p>
<p><strong>Draw fish icon.</strong> You can install the “Le Fish” font (<strong>available for download <Link to="https://github.com/Myfanwy/ReproducibleExamples/blob/master/encounterhistories/le_fish.zip">here</Link></strong>) on your computer, and then register it with R via the <code>extrafonts</code> package. Once you have the font installed and registered, you can call it onto your plot with <code>geom_text()</code>. Fish shapes are encoded as alphabetical letters (not case sensitive).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(extrafont)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a></span><br/>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="co"># create bigger fish icon: serves as a black outline</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">label =</span> <span className="st">"X"</span>, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">vjust =</span> <span className="fl">0.6</span>, <span className="at">family =</span> <span className="st">"LEFISH"</span>, <span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="co"># create smaller fish icon: serve as inner fill</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">label =</span> <span className="st">"X"</span>, <span className="at">size =</span> <span className="dv">9</span>, <span className="at">vjust =</span> <span className="fl">0.6</span>, <span className="at">family =</span> <span className="st">"LEFISH"</span>) <span className="sc">+</span></span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>  <span className="co"># remove the color legend</span></span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a></span><br/>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/fish_encounter_fish_icon.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/fish_encounter_fish_icon"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>You can check different fish shapes by the following code snippet.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">expand.grid</span>(<span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">7</span>, <span className="at">y =</span> <span className="dv">5</span><span className="sc">:</span><span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="co"># create a position index grid</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">slice_head</span>(<span className="at">n =</span> <span className="dv">26</span>) <span className="sc">%&gt;%</span> <span className="co"># select the top 26 rows</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">z =</span> LETTERS) <span className="sc">%&gt;%</span> <span className="co"># fish alphabetical letters</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(x, y, <span className="at">label =</span> z)) <span className="sc">+</span> </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">size =</span> <span className="dv">9</span>, <span className="at">vjust =</span> <span className="fl">0.6</span>, <span className="at">family =</span> <span className="st">"LEFISH"</span>) <span className="sc">+</span>  <span className="co"># draw fish icon</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> z, <span className="at">y =</span> y <span className="sc">+</span> .<span className="dv">4</span>), <span className="at">size =</span> <span className="dv">5</span>) <span className="sc">+</span> <span className="co"># mark the associated letter</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="fu">theme_void</span>() </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/fish_encounter_fish_icons_gallery.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/fish_encounter_fish_icons_gallery"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Finally polish up the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>    </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Encounter histories of tagged Chinook salmon smolts"</span>,</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"Upstream to downstream"</span>) <span className="sc">+</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>) <span className="sc">+</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  hrbrthemes<span className="sc">::</span><span className="fu">theme_ipsum</span>(<span className="at">base_size =</span> <span className="dv">12</span>) <span className="sc">+</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a></span><br/>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/fish_encounter_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/fish_encounter_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a></span><br/>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>fish_encounters</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a></span><br/>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a><span className="co"># View the dataset structure as wider format. </span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a></span><br/>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>fish_encounters <span className="sc">%&gt;%</span> </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>  <span className="fu">pivot_wider</span>(<span className="at">names_from =</span> station, <span className="at">values_from =</span> seen)</span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a></span><br/>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a><span className="do">### Visualization</span></span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a></span><br/>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a><span className="co"># Draw dots to illustrate the detention of each fish at each station.</span></span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> fish_encounters <span className="sc">%&gt;%</span> </span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> station, <span className="at">y =</span> fish, <span className="at">color =</span> station)) <span className="sc">+</span></span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>  <span className="fu">geom_point</span>() <span className="sc">+</span></span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a>  <span className="fu">geom_path</span>(<span className="fu">aes</span>(<span className="at">group =</span> fish), <span className="at">size =</span> <span className="fl">0.25</span>, <span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a></span><br/>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a>p1</span>
<span id="cb9-21"><a aria-hidden="true" href="#cb9-21" tabindex="-1"></a></span><br/>
<span id="cb9-22"><a aria-hidden="true" href="#cb9-22" tabindex="-1"></a></span><br/>
<span id="cb9-23"><a aria-hidden="true" href="#cb9-23" tabindex="-1"></a><span className="co"># Draw fish icon. See "Stepwise instructions" tab to download the icon library. </span></span>
<span id="cb9-24"><a aria-hidden="true" href="#cb9-24" tabindex="-1"></a><span className="fu">library</span>(extrafont)</span>
<span id="cb9-25"><a aria-hidden="true" href="#cb9-25" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb9-26"><a aria-hidden="true" href="#cb9-26" tabindex="-1"></a>  <span className="co"># create bigger fish icon: serves as a black outline</span></span>
<span id="cb9-27"><a aria-hidden="true" href="#cb9-27" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">label =</span> <span className="st">"X"</span>, <span className="at">size =</span> <span className="dv">10</span>, <span className="at">vjust =</span> <span className="fl">0.6</span>, <span className="at">family =</span> <span className="st">"LEFISH"</span>, <span className="at">color =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb9-28"><a aria-hidden="true" href="#cb9-28" tabindex="-1"></a>  <span className="co"># create smaller fish icon: serve as inner fill</span></span>
<span id="cb9-29"><a aria-hidden="true" href="#cb9-29" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">label =</span> <span className="st">"X"</span>, <span className="at">size =</span> <span className="dv">9</span>, <span className="at">vjust =</span> <span className="fl">0.6</span>, <span className="at">family =</span> <span className="st">"LEFISH"</span>) <span className="sc">+</span></span>
<span id="cb9-30"><a aria-hidden="true" href="#cb9-30" tabindex="-1"></a>  <span className="co"># remove the color legend</span></span>
<span id="cb9-31"><a aria-hidden="true" href="#cb9-31" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb9-32"><a aria-hidden="true" href="#cb9-32" tabindex="-1"></a></span><br/>
<span id="cb9-33"><a aria-hidden="true" href="#cb9-33" tabindex="-1"></a>p2</span>
<span id="cb9-34"><a aria-hidden="true" href="#cb9-34" tabindex="-1"></a></span><br/>
<span id="cb9-35"><a aria-hidden="true" href="#cb9-35" tabindex="-1"></a><span className="co"># Finally polish up the plot. </span></span>
<span id="cb9-36"><a aria-hidden="true" href="#cb9-36" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>    </span>
<span id="cb9-37"><a aria-hidden="true" href="#cb9-37" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Encounter histories of tagged Chinook salmon smolts"</span>,</span>
<span id="cb9-38"><a aria-hidden="true" href="#cb9-38" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"Upstream to downstream"</span>) <span className="sc">+</span></span>
<span id="cb9-39"><a aria-hidden="true" href="#cb9-39" tabindex="-1"></a>  <span className="fu">scale_color_brewer</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>) <span className="sc">+</span></span>
<span id="cb9-40"><a aria-hidden="true" href="#cb9-40" tabindex="-1"></a>  hrbrthemes<span className="sc">::</span><span className="fu">theme_ipsum</span>(<span className="at">base_size =</span> <span className="dv">12</span>) <span className="sc">+</span></span>
<span id="cb9-41"><a aria-hidden="true" href="#cb9-41" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb9-42"><a aria-hidden="true" href="#cb9-42" tabindex="-1"></a></span><br/>
<span id="cb9-43"><a aria-hidden="true" href="#cb9-43" tabindex="-1"></a>p3</span>
<span id="cb9-44"><a aria-hidden="true" href="#cb9-44" tabindex="-1"></a></span><br/>
<span id="cb9-45"><a aria-hidden="true" href="#cb9-45" tabindex="-1"></a><span className="co"># You can check different fish shapes by the following code snippet. </span></span>
<span id="cb9-46"><a aria-hidden="true" href="#cb9-46" tabindex="-1"></a><span className="fu">expand.grid</span>(<span className="at">x =</span> <span className="dv">1</span><span className="sc">:</span><span className="dv">7</span>, <span className="at">y =</span> <span className="dv">5</span><span className="sc">:</span><span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="co"># create a position index grid</span></span>
<span id="cb9-47"><a aria-hidden="true" href="#cb9-47" tabindex="-1"></a>  <span className="fu">slice_head</span>(<span className="at">n =</span> <span className="dv">26</span>) <span className="sc">%&gt;%</span> <span className="co"># select the top 26 rows</span></span>
<span id="cb9-48"><a aria-hidden="true" href="#cb9-48" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">z =</span> LETTERS) <span className="sc">%&gt;%</span> <span className="co"># fish alphabetical letters</span></span>
<span id="cb9-49"><a aria-hidden="true" href="#cb9-49" tabindex="-1"></a>  </span>
<span id="cb9-50"><a aria-hidden="true" href="#cb9-50" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(x, y, <span className="at">label =</span> z)) <span className="sc">+</span> </span>
<span id="cb9-51"><a aria-hidden="true" href="#cb9-51" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">size =</span> <span className="dv">9</span>, <span className="at">vjust =</span> <span className="fl">0.6</span>, <span className="at">family =</span> <span className="st">"LEFISH"</span>) <span className="sc">+</span>  <span className="co"># draw fish icon</span></span>
<span id="cb9-52"><a aria-hidden="true" href="#cb9-52" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="fu">aes</span>(<span className="at">label =</span> z, <span className="at">y =</span> y <span className="sc">+</span> .<span className="dv">4</span>), <span className="at">size =</span> <span className="dv">5</span>) <span className="sc">+</span> <span className="co"># mark the associated letter</span></span>
<span id="cb9-53"><a aria-hidden="true" href="#cb9-53" tabindex="-1"></a>  <span className="fu">theme_void</span>() </span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
</main>
</div>
</div>
)}