import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2RelocateLegendPosition from '../tutorial/legend_position_scatterplot.png';

export default function Ggplot2RelocateLegendPosition(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Change Legend Position in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains how to <strong>change the position of a <em>single</em> legend</strong>.</p>
<blockquote className="blockquote">
<p><span className="highlightGreen">If you wish to reorder the layout of <strong><em>multiple</em></strong> legends, check <Link to="../12-ggplot2-reorder-multiple-legends">here</Link> instead.</span></p>
</blockquote>
<section className="level4" id="start">
<h4 className="anchored" data-anchor-id="start">Create a scatterplot with a legend</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages </span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set the default global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">14</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="co"># bold the legend title and labels</span></span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="at">legend.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="at">legend.text =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>)</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>))</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a></span><br/>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a><span className="co"># create a scatterplot</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>p <span className="ot">&lt;-</span> mpg <span className="sc">%&gt;%</span> </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(displ, hwy, <span className="at">fill =</span> class)) <span className="sc">+</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">1</span>), </span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>             <span className="at">shape =</span> <span className="dv">21</span>, <span className="at">size =</span> <span className="dv">4</span>, <span className="at">alpha =</span> .<span className="dv">7</span>) <span className="sc">+</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="fu">scale_fill_brewer</span>(<span className="at">palette =</span> <span className="st">"Set2"</span>)</span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2RelocateLegendPosition} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="relocate-legend-to-the-plot-bottom">
<h4 className="anchored" data-anchor-id="relocate-legend-to-the-plot-bottom">Relocate legend to the plot bottom</h4>
<p>Optional values are <code>right</code> (default), <code>top</code>, <code>left</code>, and <code>bottom</code>. Once the legend is positioned to the bottom, it automatically takes a horizontal orientation.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"bottom"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_position_bottom.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="relocate-legend-anywhere-desired">
<h4 className="anchored" data-anchor-id="relocate-legend-anywhere-desired">Relocate legend anywhere desired</h4>
<p>Alternatively, use a pair of coordinate values in the range of [0, 1] to specify the legend <em>central</em> position relative to the range of the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># relocate legend to 70% of plot width, 75% of plot height</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">7</span>, .<span className="dv">75</span>)) </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_position_anywhere.png"/></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="turn-legend-keys-into-3-rows">
<h4 className="anchored" data-anchor-id="turn-legend-keys-into-3-rows">Turn legend keys into 3 rows</h4>
<p>The <code>guides()</code> function is a powerful tool to customize non-data related appearance of scales (including legends and axes). In this tutorial, as the legend is associated with the <code>fill</code> aesthetic, <code>fill = ...</code> is used inside the <code>guides()</code> function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_legend</span>(<span className="at">nrow =</span> <span className="dv">3</span>)) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/legend_position_guides_nrow.png"/></p>
</figure>
</div>
</div>
</div>
<p>The guides syntax can be equivalently written inside the associated <code>scale_*</code> function. (The new <code>scale_fill_brewer()</code> overwrites the <a href="#start">earlier one</a>, and prompts a message reminder in the console.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="sc">+</span> <span className="fu">scale_fill_brewer</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">palette =</span> <span className="st">"Set2"</span>, <span className="at">guide =</span> <span className="fu">guide_legend</span>(<span className="at">nrow =</span> <span className="dv">2</span>))</span></code></pre></div>
<div className="cell-output cell-output-stderr">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">Scale for fill is already present.
<br/>Adding another scale for fill, which will replace the existing scale.</code></pre>
</div>
</div>
<p>Check <Link to="../11-ggplot2-change-legend-row-number"><strong>here</strong></Link> to find more techniques to customize the legend rows / columns.</p>
</section>
</main>
</div>
</div>
)}