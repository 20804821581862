import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LineSummaryStatisticsMilk from '../graphics/milk_annotate_completed.png'; 
import imgGgplot2LineSummaryStatisticsMilkWebp from '../graphics/milk_annotate_completed.webp'; 
export default function Ggplot2LineSummaryStatisticsMilk(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Efficient Visualization of Summary Statistics in ggplot2: Tracking Changes in Milk Protein Content</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2LineSummaryStatisticsMilkWebp} />
    <img className="cover-img" src={imgGgplot2LineSummaryStatisticsMilk} />
  </picture>

<p>In this article, I’ll demonstrate how to <strong>rapidly create summarizing statistics in ggplot2</strong>. The demo graphic shows how the protein content in cows’ milk changes in the weeks following calving. The cattle are grouped according to whether they are fed a diet with barley alone, lupins alone, or with both barley and lupins. The dataset used is built in the <code>nlme</code> package (popular for <strong>N</strong>onlinear and <strong>L</strong>inear <strong>M</strong>ixed <strong>E</strong>ffects models).</p>
<p><strong>Major techniques explained in this article include:</strong></p>
<ul>
<li><a href="#google_font">Use Google Fonts.</a></li>
<li><a href="#variability_ribbons">Rapid generation of visual statistic summaries with <code>stat_summary()</code>.</a></li>
<li><a href="#display_beyond_panel_limits">Display graphic elements beyond the panel boundary.</a></li>
<li><a href="#label_selected_subplot">Annotate a single faceted panel (subplot).</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(nlme) <span className="co"># load the Milk dataset</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set up the default theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">base_size =</span> <span className="dv">14</span>, <span className="at">base_family =</span> <span className="st">"Abril"</span>))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>Milk <span className="ot">&lt;-</span> Milk <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="co"># convert to tibble format</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a></span><br/>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a><span className="fu">head</span>(Milk, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>  protein  Time Cow   Diet  
<br/>    &lt;dbl&gt; &lt;dbl&gt; &lt;ord&gt; &lt;fct&gt; 
<br/>1    3.63     1 B01   barley
<br/>2    3.57     2 B01   barley
<br/>3    3.47     3 B01   barley</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="google_font">Load font “Abril Fatface” from <Link to="https://fonts.google.com/">Google Font Repository</Link>, and set up global theme.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Abril Fatface"</span>, <span className="at">family =</span> <span className="st">"Abril"</span>)</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a><span className="fu">showtext.auto</span>()</span></code></pre></div>
</div>
<p><strong>Create a line plot, with each line representing a cow.</strong> The plot is faceted based on the variable <code>Diet</code>. As the legend information is already displayed in the titles of the faceted panels (subplots), we remove the legend at this step. The <code>fill</code> aesthetic so far is not useful, but will be utilized for the ribbons added in the following steps.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> Milk <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Time, <span className="at">y =</span> protein, </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>             <span className="at">color =</span> Diet, </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>             <span className="at">fill =</span> Diet)) <span className="sc">+</span> <span className="co"># for ribbon layers added later</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> Cow), <span className="at">alpha =</span> .<span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>Diet) <span className="sc">+</span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_faceted_lineplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/milk_faceted_lineplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="variability_ribbons"><strong>Create a ribbon representing one standard deviation (SD) above and below the mean.</strong> As more than one statistic is calculated, i.e., the mean, the upper SD limit, and the lower SD limit, we use <code>fun.data</code> argument (in contrast of <code>fun</code> for a single statistic, as used to create plot <a href="#summary_mean"><code>p4</code></a> at a later step). <code>fun.args = list(...)</code> specifies additional arguments of the <code>mean_sdl</code> function, and <code>mult = 1</code> dictates the calculation of one-fold of SD above and below the mean (while the default calculates two-fold of SD around the mean). (in like manner, standard error of the mean can be calculated using the function <code>mean_se</code>)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun.data =</span> mean_sdl, </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>               <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">mult =</span> <span className="dv">1</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>               <span className="at">geom =</span> <span className="st">"ribbon"</span>, <span className="at">alpha =</span> .<span className="dv">3</span>, </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>               <span className="at">color =</span> <span className="cn">NA</span>) <span className="co"># not show ribbon outline</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_stat_summary_standard_deviation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/milk_stat_summary_standard_deviation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Create a ribbon depicting 95% normal (Gaussian) confidence limits</strong> (based on the <em>t</em>-distribution). The syntax is similar to the calculation of standard deviation.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="co"># 95% confidence interval</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun.data =</span> mean_cl_normal, </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>               <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">conf.int =</span> .<span className="dv">95</span>), <span className="co"># confidence interval </span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>               <span className="at">geom =</span> <span className="st">"ribbon"</span>, <span className="at">color =</span> <span className="cn">NA</span>) </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_stat_summary_confidence_interval.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/milk_stat_summary_confidence_interval"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="summary_mean"><strong>Create a central line representing the mean.</strong> As a single statistic, the mean, is calculated, we use the argument <code>fun</code>, instead of <code>fun.data</code> as used in the above two steps.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun =</span> mean, <span className="at">geom =</span> <span className="st">"line"</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>               <span className="at">linewidth =</span> .<span className="dv">7</span>, <span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_stat_summary_mean.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/milk_stat_summary_mean"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="display_beyond_panel_limits">Polish up. Two edits here are particularly important to <strong>prepare the plot for annotation in the right margin of the plot</strong> (at the later step of creating plot <a href="#margin_annotation"><code>p6</code></a>):</span></p>
<ul>
<li><p>In <code>coord_cartesian</code> (2nd line), we use <code>clip = F</code> to display graphical elements that extend beyond the panel range (otherwise they would be clipped off by default).</p></li>
<li><p>Use <code>plot.margin</code> (last line) to add extra margin on the right side of the plot. (If there are texts in the space sandwiched between panels as in <Link to="../ggplot2-line-plot-life-expectancy">this example</Link>, it may be also necessary to adjust the margin between panels.)</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="co"># coordinate</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">ylim =</span> <span className="fu">c</span>(<span className="fl">2.5</span>, <span className="fl">4.5</span>), <span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> F) <span className="sc">+</span></span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="co"># x-axis breaks</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">16</span>, <span className="dv">4</span>)) <span className="sc">+</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="co"># axis titles</span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"weeks"</span>, <span className="at">y =</span> <span className="st">"content (%)"</span>,</span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Protein content in milk after calving"</span>) <span className="sc">+</span></span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>    <span className="at">strip.background =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_text</span>(<span className="at">vjust =</span> <span className="sc">-</span><span className="dv">2</span>), <span className="co"># bring the facet title downward</span></span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>), <span className="co"># center-justify the plot title</span></span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a>    </span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a>    <span className="co"># add plot margin, in particular to the right side of the plot</span></span>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>    <span className="co"># use t - r - b - l for top - right - bottom - left</span></span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">50</span>, <span className="at">l =</span> <span className="dv">10</span>, <span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">10</span>))  </span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a></span><br/>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/milk_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="label_selected_subplot">Add text annotation in the right margin of the plot. Technically, the annotations are made in the third panel “lupins”. To <strong>make annotations to selective panels</strong>, a typical solution is to create a dataset (<code>d</code>) to specify the annotation position and content, <strong>including the faceting variable (<code>Diet</code>) to indicate which panel the annotations should be made in</strong>. (To learn more about making unique annotations to selective panels, check this <Link to="../ggplot2-arrows-facet-women-parliament">faceted arrow plot</Link>, and this <Link to="../ggplot2-dumbell-plot-votes-Trump-Clinton">faceted dumbbell plot</Link>).</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="co"># annotation to the 3rd panel</span></span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="at">x =</span> <span className="fu">rep</span>(<span className="fl">19.5</span>, <span className="dv">3</span>), </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="at">y =</span> <span className="fu">c</span>(<span className="fl">3.5</span>, <span className="fl">3.23</span>, <span className="fl">3.1</span>),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>  <span className="at">t =</span> <span className="fu">c</span>(<span className="st">"← 95%</span><span className="sc">\n</span><span className="st">conf. int."</span>, <span className="st">"← mean"</span>, <span className="st">"← SD"</span>),</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>  </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  <span className="co"># specifies that all texts are added to the panel "lupins"</span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>  <span className="at">Diet =</span> <span className="fu">rep</span>(<span className="st">"lupins"</span>, <span className="dv">3</span>)) </span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a></span><br/>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>d</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>      x     y t                   Diet  
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;               &lt;chr&gt; 
<br/>1  19.5  3.5  "← 95%\nconf. int." lupins
<br/>2  19.5  3.23 "← mean"            lupins
<br/>3  19.5  3.1  "← SD"              lupins</code></pre>
</div>
</div>
<p><span id="margin_annotation">Add annotation using the dataset <code>d</code>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> d,</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> x, <span className="at">y =</span> y, <span className="at">label =</span> t),</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_annotate_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/milk_annotate_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a><span className="fu">library</span>(nlme) <span className="co"># load the Milk dataset</span></span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a></span><br/>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a><span className="co"># set up global default theme</span></span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(</span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a>  <span className="at">base_size =</span> <span className="dv">14</span>, <span className="at">base_family =</span> <span className="st">"Abril"</span>))</span>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a></span><br/>
<span id="cb12-9"><a aria-hidden="true" href="#cb12-9" tabindex="-1"></a>Milk <span className="ot">&lt;-</span> Milk <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="co"># convert to tibble format</span></span>
<span id="cb12-10"><a aria-hidden="true" href="#cb12-10" tabindex="-1"></a></span><br/>
<span id="cb12-11"><a aria-hidden="true" href="#cb12-11" tabindex="-1"></a><span className="fu">head</span>(Milk, <span className="dv">3</span>)</span>
<span id="cb12-12"><a aria-hidden="true" href="#cb12-12" tabindex="-1"></a></span><br/>
<span id="cb12-13"><a aria-hidden="true" href="#cb12-13" tabindex="-1"></a></span><br/>
<span id="cb12-14"><a aria-hidden="true" href="#cb12-14" tabindex="-1"></a><span className="co"># Add Google font lobster, and set up global theme. </span></span>
<span id="cb12-15"><a aria-hidden="true" href="#cb12-15" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb12-16"><a aria-hidden="true" href="#cb12-16" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Abril Fatface"</span>, <span className="at">family =</span> <span className="st">"Abril"</span>)</span>
<span id="cb12-17"><a aria-hidden="true" href="#cb12-17" tabindex="-1"></a><span className="fu">showtext.auto</span>()</span>
<span id="cb12-18"><a aria-hidden="true" href="#cb12-18" tabindex="-1"></a></span><br/>
<span id="cb12-19"><a aria-hidden="true" href="#cb12-19" tabindex="-1"></a></span><br/>
<span id="cb12-20"><a aria-hidden="true" href="#cb12-20" tabindex="-1"></a><span className="co"># Create a line plot, with each line representing a cow. </span></span>
<span id="cb12-21"><a aria-hidden="true" href="#cb12-21" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> Milk <span className="sc">%&gt;%</span> </span>
<span id="cb12-22"><a aria-hidden="true" href="#cb12-22" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Time, <span className="at">y =</span> protein, </span>
<span id="cb12-23"><a aria-hidden="true" href="#cb12-23" tabindex="-1"></a>             <span className="at">color =</span> Diet, </span>
<span id="cb12-24"><a aria-hidden="true" href="#cb12-24" tabindex="-1"></a>             <span className="at">fill =</span> Diet)) <span className="sc">+</span> <span className="co"># for ribbon layers added later</span></span>
<span id="cb12-25"><a aria-hidden="true" href="#cb12-25" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> Cow), <span className="at">alpha =</span> .<span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb12-26"><a aria-hidden="true" href="#cb12-26" tabindex="-1"></a>  <span className="fu">facet_wrap</span>(<span className="sc">~</span>Diet) <span className="sc">+</span></span>
<span id="cb12-27"><a aria-hidden="true" href="#cb12-27" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb12-28"><a aria-hidden="true" href="#cb12-28" tabindex="-1"></a>p1</span>
<span id="cb12-29"><a aria-hidden="true" href="#cb12-29" tabindex="-1"></a></span><br/>
<span id="cb12-30"><a aria-hidden="true" href="#cb12-30" tabindex="-1"></a></span><br/>
<span id="cb12-31"><a aria-hidden="true" href="#cb12-31" tabindex="-1"></a><span className="co"># Create a ribbon representing one standard deviation (SD) above and below the mean. </span></span>
<span id="cb12-32"><a aria-hidden="true" href="#cb12-32" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb12-33"><a aria-hidden="true" href="#cb12-33" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun.data =</span> mean_sdl, </span>
<span id="cb12-34"><a aria-hidden="true" href="#cb12-34" tabindex="-1"></a>               <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">mult =</span> <span className="dv">1</span>),</span>
<span id="cb12-35"><a aria-hidden="true" href="#cb12-35" tabindex="-1"></a>               <span className="at">geom =</span> <span className="st">"ribbon"</span>, <span className="at">alpha =</span> .<span className="dv">3</span>, </span>
<span id="cb12-36"><a aria-hidden="true" href="#cb12-36" tabindex="-1"></a>               <span className="at">color =</span> <span className="cn">NA</span>) <span className="co"># not show ribbon outline</span></span>
<span id="cb12-37"><a aria-hidden="true" href="#cb12-37" tabindex="-1"></a>p2</span>
<span id="cb12-38"><a aria-hidden="true" href="#cb12-38" tabindex="-1"></a></span><br/>
<span id="cb12-39"><a aria-hidden="true" href="#cb12-39" tabindex="-1"></a></span><br/>
<span id="cb12-40"><a aria-hidden="true" href="#cb12-40" tabindex="-1"></a><span className="co"># Create a ribbon depicting 95% normal (Gaussian) confidence interval. </span></span>
<span id="cb12-41"><a aria-hidden="true" href="#cb12-41" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb12-42"><a aria-hidden="true" href="#cb12-42" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun.data =</span> mean_cl_normal, </span>
<span id="cb12-43"><a aria-hidden="true" href="#cb12-43" tabindex="-1"></a>               <span className="at">fun.args =</span> <span className="fu">list</span>(<span className="at">conf.int =</span> .<span className="dv">95</span>), <span className="co"># confidence interval </span></span>
<span id="cb12-44"><a aria-hidden="true" href="#cb12-44" tabindex="-1"></a>               <span className="at">geom =</span> <span className="st">"ribbon"</span>, <span className="at">color =</span> <span className="cn">NA</span>) </span>
<span id="cb12-45"><a aria-hidden="true" href="#cb12-45" tabindex="-1"></a>p3</span>
<span id="cb12-46"><a aria-hidden="true" href="#cb12-46" tabindex="-1"></a></span><br/>
<span id="cb12-47"><a aria-hidden="true" href="#cb12-47" tabindex="-1"></a></span><br/>
<span id="cb12-48"><a aria-hidden="true" href="#cb12-48" tabindex="-1"></a><span className="co"># Create a central line representing the mean.</span></span>
<span id="cb12-49"><a aria-hidden="true" href="#cb12-49" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb12-50"><a aria-hidden="true" href="#cb12-50" tabindex="-1"></a>  <span className="fu">stat_summary</span>(<span className="at">fun =</span> mean, <span className="at">geom =</span> <span className="st">"line"</span>, </span>
<span id="cb12-51"><a aria-hidden="true" href="#cb12-51" tabindex="-1"></a>               <span className="at">linewidth =</span> .<span className="dv">7</span>, <span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb12-52"><a aria-hidden="true" href="#cb12-52" tabindex="-1"></a>p4</span>
<span id="cb12-53"><a aria-hidden="true" href="#cb12-53" tabindex="-1"></a></span><br/>
<span id="cb12-54"><a aria-hidden="true" href="#cb12-54" tabindex="-1"></a></span><br/>
<span id="cb12-55"><a aria-hidden="true" href="#cb12-55" tabindex="-1"></a><span className="co"># Polish up; and prepare the plot (clip off, and increased margin) for the next step.</span></span>
<span id="cb12-56"><a aria-hidden="true" href="#cb12-56" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb12-57"><a aria-hidden="true" href="#cb12-57" tabindex="-1"></a>  <span className="co"># coordinate</span></span>
<span id="cb12-58"><a aria-hidden="true" href="#cb12-58" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(</span>
<span id="cb12-59"><a aria-hidden="true" href="#cb12-59" tabindex="-1"></a>    <span className="at">ylim =</span> <span className="fu">c</span>(<span className="fl">2.5</span>, <span className="fl">4.5</span>), <span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> F) <span className="sc">+</span></span>
<span id="cb12-60"><a aria-hidden="true" href="#cb12-60" tabindex="-1"></a>  <span className="co"># x-axis breaks</span></span>
<span id="cb12-61"><a aria-hidden="true" href="#cb12-61" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">16</span>, <span className="dv">4</span>)) <span className="sc">+</span></span>
<span id="cb12-62"><a aria-hidden="true" href="#cb12-62" tabindex="-1"></a>  <span className="co"># axis titles</span></span>
<span id="cb12-63"><a aria-hidden="true" href="#cb12-63" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"weeks"</span>, <span className="at">y =</span> <span className="st">"content (%)"</span>,</span>
<span id="cb12-64"><a aria-hidden="true" href="#cb12-64" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Protein content in milk after calving"</span>) <span className="sc">+</span></span>
<span id="cb12-65"><a aria-hidden="true" href="#cb12-65" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb12-66"><a aria-hidden="true" href="#cb12-66" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb12-67"><a aria-hidden="true" href="#cb12-67" tabindex="-1"></a>    <span className="at">strip.background =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb12-68"><a aria-hidden="true" href="#cb12-68" tabindex="-1"></a>    <span className="at">strip.text =</span> <span className="fu">element_text</span>(<span className="at">vjust =</span> <span className="sc">-</span><span className="dv">2</span>), <span className="co"># bring the facet title downward</span></span>
<span id="cb12-69"><a aria-hidden="true" href="#cb12-69" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>), <span className="co"># center-justify the plot title</span></span>
<span id="cb12-70"><a aria-hidden="true" href="#cb12-70" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb12-71"><a aria-hidden="true" href="#cb12-71" tabindex="-1"></a>    </span>
<span id="cb12-72"><a aria-hidden="true" href="#cb12-72" tabindex="-1"></a>    <span className="co"># add plot margin, in particular to the right side of the plot</span></span>
<span id="cb12-73"><a aria-hidden="true" href="#cb12-73" tabindex="-1"></a>    <span className="co"># use t - r - b - l for top - right - bottom - left</span></span>
<span id="cb12-74"><a aria-hidden="true" href="#cb12-74" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">50</span>, <span className="at">l =</span> <span className="dv">10</span>, <span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">10</span>))  </span>
<span id="cb12-75"><a aria-hidden="true" href="#cb12-75" tabindex="-1"></a></span><br/>
<span id="cb12-76"><a aria-hidden="true" href="#cb12-76" tabindex="-1"></a>p5</span>
<span id="cb12-77"><a aria-hidden="true" href="#cb12-77" tabindex="-1"></a></span><br/>
<span id="cb12-78"><a aria-hidden="true" href="#cb12-78" tabindex="-1"></a></span><br/>
<span id="cb12-79"><a aria-hidden="true" href="#cb12-79" tabindex="-1"></a><span className="co"># Add text annotation in the right margin of the plot. </span></span>
<span id="cb12-80"><a aria-hidden="true" href="#cb12-80" tabindex="-1"></a>d <span className="ot">&lt;-</span> <span className="fu">tibble</span>(</span>
<span id="cb12-81"><a aria-hidden="true" href="#cb12-81" tabindex="-1"></a>  <span className="at">x =</span> <span className="fu">rep</span>(<span className="fl">19.5</span>, <span className="dv">3</span>), </span>
<span id="cb12-82"><a aria-hidden="true" href="#cb12-82" tabindex="-1"></a>  <span className="at">y =</span> <span className="fu">c</span>(<span className="fl">3.5</span>, <span className="fl">3.23</span>, <span className="fl">3.1</span>),</span>
<span id="cb12-83"><a aria-hidden="true" href="#cb12-83" tabindex="-1"></a>  <span className="at">t =</span> <span className="fu">c</span>(<span className="st">"← 95%</span><span className="sc">\n</span><span className="st">conf. int."</span>, <span className="st">"← mean"</span>, <span className="st">"← SD"</span>),</span>
<span id="cb12-84"><a aria-hidden="true" href="#cb12-84" tabindex="-1"></a>  </span>
<span id="cb12-85"><a aria-hidden="true" href="#cb12-85" tabindex="-1"></a>  <span className="co"># specifies that all texts are added to the panel "lupins"</span></span>
<span id="cb12-86"><a aria-hidden="true" href="#cb12-86" tabindex="-1"></a>  <span className="at">Diet =</span> <span className="fu">rep</span>(<span className="st">"lupins"</span>, <span className="dv">3</span>)) </span>
<span id="cb12-87"><a aria-hidden="true" href="#cb12-87" tabindex="-1"></a></span><br/>
<span id="cb12-88"><a aria-hidden="true" href="#cb12-88" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb12-89"><a aria-hidden="true" href="#cb12-89" tabindex="-1"></a>  <span className="fu">geom_text</span>(<span className="at">data =</span> d,</span>
<span id="cb12-90"><a aria-hidden="true" href="#cb12-90" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">x =</span> x, <span className="at">y =</span> y, <span className="at">label =</span> t),</span>
<span id="cb12-91"><a aria-hidden="true" href="#cb12-91" tabindex="-1"></a>            <span className="at">hjust =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"black"</span>) </span>
<span id="cb12-92"><a aria-hidden="true" href="#cb12-92" tabindex="-1"></a>p6</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In a typical line plot, in addition to visual summaries of statistics, such as the <a href="#summary_mean">central trend line</a> and <a href="#variability_ribbons">variability ribbons</a> illustrate above, it is also common practice to <strong>highlight certain observations of interest</strong>, as demonstrated in the following <Link to="../ggplot2-line-plot-cigarettes"><strong>annotated and highlighted line plot</strong></Link> that shows the changing popularity of smoking in U.S., Germany, and France, as well as other countries over the past century.</p>
<p><Link to="../ggplot2-line-plot-cigarettes">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lineplot_smoking_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>While lines are often employed to trace the trend of changes, it serves many other purposes. In the following plot, each line corresponds to a distinct father-son pair, vividly illustrating the influence of a father’s occupation on his son’s career trajectory in the United States during the 1970s.</p>
<p><Link to="../ggplot2-line-plot-social-mobility">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out this line plot with an underlying map to illustrate the <Link to="../ggplot2-map-airline"><strong>global network of flights</strong></Link>, as shown below. In addition, check this article to <Link to="../ggplot2-map-airline-animation"><strong>tweak this static network into a dreamy animation</strong></Link>.</p>
<p><Link to="../ggplot2-map-airline">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}