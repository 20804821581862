import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2MapAirlineAnimation from '../graphics/map_airlines_animation_gganimate_completed-1.gif'; 
import imgGgplot2MapAirlineAnimationWebp from '../graphics/map_airlines_animation_gganimate_completed-1.webp'; 
export default function Ggplot2MapAirlineAnimation(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Animate Global Airports and Flights Using ggplot2 and gganimate</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2MapAirlineAnimationWebp} />
    <img className="cover-img" src={imgGgplot2MapAirlineAnimation} />
  </picture>

<p>In this <Link to="/R/gallery/ggplot2-map-airline"><strong>earlier article</strong></Link>, we visualized the global flights and airports as a static graphic. This current work tweaks the static graphic into an animation to make the visualization much more dynamic and engaging. <span className="highlightGreen">The early part of data wrangling is identical to the static graphic. If you’re already familiar with the data cleanup, you can <a href="#skip"><strong>skip</strong></a> directly to the edits designed for animation.</span> 🌻</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The data is sourced from <Link to="https://openflights.org/data">open flights</Link>, and can be directly imported via the URL. We’ll process 2 datasets in a total of 3 major steps.</p>
<p><strong>(1)</strong> Import <strong>the <em>airport</em> dataset</strong>, and update its column names based on the website’s description. To make it easier to navigate through the dataset, we’ll only select columns used in this visualization.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># read the dataset</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>url.airport <span className="ot">&lt;-</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/airports.dat"</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a></span><br/>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>airport <span className="ot">&lt;-</span> <span className="fu">read.table</span>(</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  url.airport, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> <span className="cn">FALSE</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">as_tibble</span>()  </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a></span><br/>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a><span className="co"># Update column names based on website description; </span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a><span className="co"># use "lat" for latitude,  and "long" for longitude, </span></span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a><span className="co"># to be consistent with the 'map_data' which is built in R </span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a><span className="fu">colnames</span>(airport) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="st">"airport_ID"</span>, <span className="st">"name"</span>, <span className="st">"city"</span>, <span className="st">"country"</span>, </span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>  <span className="st">"IATA"</span>, <span className="st">"ICAO"</span>, <span className="st">"lat"</span>, <span className="st">"long"</span>, <span className="st">"altitude"</span>, <span className="st">"timezone"</span>,</span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>  <span className="st">"DST"</span>, <span className="st">"Tz_database"</span>, <span className="st">"time_zone"</span>, <span className="st">"type"</span>)</span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a></span><br/>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a><span className="co"># select columns useful in this visualization</span></span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a>airport.selected <span className="ot">&lt;-</span> airport <span className="sc">%&gt;%</span> </span>
<span id="cb1-24"><a aria-hidden="true" href="#cb1-24" tabindex="-1"></a>  <span className="fu">select</span>(name, airport_ID, lat, long, country) <span className="sc">%&gt;%</span> </span>
<span id="cb1-25"><a aria-hidden="true" href="#cb1-25" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">airport_ID =</span> <span className="fu">as.character</span>(airport_ID))</span>
<span id="cb1-26"><a aria-hidden="true" href="#cb1-26" tabindex="-1"></a></span><br/>
<span id="cb1-27"><a aria-hidden="true" href="#cb1-27" tabindex="-1"></a><span className="fu">head</span>(airport.selected, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  name                         airport_ID   lat  long country         
<br/>  &lt;chr&gt;                        &lt;chr&gt;      &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;           
<br/>1 Goroka Airport               1          -6.08  145. Papua New Guinea
<br/>2 Madang Airport               2          -5.21  146. Papua New Guinea
<br/>3 Mount Hagen Kagamuga Airport 3          -5.83  144. Papua New Guinea</code></pre>
</div>
</div>
<p>This <code>airport.selected</code> serves two purposes:</p>
<ul>
<li><p>It will be directly used to create an airport scatterplot: the <code>long</code> variable (longitude) mapped to the <code>x</code>, and <code>lat</code> (latitude) to <code>y</code>.</p></li>
<li><p>It will merge with the <em>airline</em> dataset via the column key <code>airport_ID</code>, with flight lines drawn by connecting the associated airports.</p></li>
</ul>
<p><strong>(2)</strong> Load and clean up <strong>the <em>airline</em> dataset</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>url.airline <span className="ot">&lt;-</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/routes.dat"</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a></span><br/>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>airline <span className="ot">&lt;-</span> <span className="fu">read.table</span>(</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  url.airline, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> F) <span className="sc">%&gt;%</span> </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a></span><br/>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a><span className="co"># Update column names based on website description</span></span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a><span className="fu">colnames</span>(airline) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>  <span className="st">"airline"</span>, <span className="st">"airline_ID"</span>, <span className="st">"source_airport"</span>, </span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>  <span className="st">"source_airport_ID"</span>, <span className="st">"destination_airport"</span>,</span>
<span id="cb3-12"><a aria-hidden="true" href="#cb3-12" tabindex="-1"></a>  <span className="st">"destination_airport_ID"</span>, <span className="st">"Codeshare"</span>, <span className="st">"Stops"</span>, <span className="st">"Equipment"</span>)</span>
<span id="cb3-13"><a aria-hidden="true" href="#cb3-13" tabindex="-1"></a></span><br/>
<span id="cb3-14"><a aria-hidden="true" href="#cb3-14" tabindex="-1"></a><span className="co"># select useful columns</span></span>
<span id="cb3-15"><a aria-hidden="true" href="#cb3-15" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb3-16"><a aria-hidden="true" href="#cb3-16" tabindex="-1"></a>  <span className="fu">select</span>(source_airport_ID, destination_airport_ID)</span>
<span id="cb3-17"><a aria-hidden="true" href="#cb3-17" tabindex="-1"></a></span><br/>
<span id="cb3-18"><a aria-hidden="true" href="#cb3-18" tabindex="-1"></a><span className="fu">head</span>(airline, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 2
<br/>  source_airport_ID destination_airport_ID
<br/>  &lt;chr&gt;             &lt;chr&gt;                 
<br/>1 2965              2990                  
<br/>2 2966              2990                  
<br/>3 2966              2962                  </code></pre>
</div>
</div>
<p><strong>For this <em>airline</em> dataset, we need to do two more critical steps:</strong></p>
<ul>
<li><strong>Assign a unique ID to each flight</strong>. By this step, each row represents a unique flight line. This unique flight ID will be mapped to the <code>group</code> aesthetic in <code>geom_line()</code>, and specifies which two data points should be connected to make one flight line.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(airline)</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">flight_ID =</span> <span className="dv">1</span><span className="sc">:</span>n)</span></code></pre></div>
</div>
<ul>
<li><strong>Pivot the dataset into a <Link to="https://cran.r-project.org/web/packages/tidyr/vignettes/tidy-data.html">tidy</Link> structure</strong>, such that each unique flight occupies two rows: the source airport in one row, and the destination airport in another row. The <code>airport_ID</code> variable connects the <em>airline</em> and <em>airport</em> datasets, and serves as the key (shared) column to guide the data merge in the next step.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>airline.tidy <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>flight_ID, </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"direction"</span>, </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"airport_ID"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">direction =</span> <span className="fu">str_remove</span>(direction, <span className="st">"_airport_ID"</span>))</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a></span><br/>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a><span className="fu">head</span>(airline.tidy, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 3
<br/>  flight_ID direction   airport_ID
<br/>      &lt;int&gt; &lt;chr&gt;       &lt;chr&gt;     
<br/>1         1 source      2965      
<br/>2         1 destination 2990      
<br/>3         2 source      2966      
<br/>4         2 destination 2990      </code></pre>
</div>
</div>
<p><strong>(3)</strong> <strong>Merge the <em>airport</em> and <em>airline</em> datasets</strong> using the shared column <code>airport_ID</code>. The merged dataset has information about each airline, including the flight ID, and associated departure (source) and destination airports noted with airports ID and latitude and longitude.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># flights </span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>air.all <span className="ot">&lt;-</span> airline.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">left_join</span>(airport.selected, <span className="at">by =</span> <span className="st">"airport_ID"</span>)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a></span><br/>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a><span className="fu">head</span>(air.all, <span className="at">n =</span> <span className="dv">4</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 7
<br/>  flight_ID direction   airport_ID name                        lat  long country
<br/>      &lt;int&gt; &lt;chr&gt;       &lt;chr&gt;      &lt;chr&gt;                     &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;  
<br/>1         1 source      2965       Sochi International Airp…  43.4  40.0 Russia 
<br/>2         1 destination 2990       Kazan International Airp…  55.6  49.3 Russia 
<br/>3         2 source      2966       Astrakhan Airport          46.3  48.0 Russia 
<br/>4         2 destination 2990       Kazan International Airp…  55.6  49.3 Russia </code></pre>
</div>
</div>
<p><br/></p>
<p><span className="highlightGreen" id="skip">The data wrangling above is identical to the creation of the <Link to="../ggplot2-map-airline">static graphic</Link>. <br/> <strong>Edits 1-6 below the line are particularly catered for animation.</strong></span></p>
<hr/>
<p><strong>Edit 1:</strong> Create another variable, <code>whichFrame</code>, to determine which frames of animation the points and lines should be displayed in. Here let’s design each frame to display 10% of the total dataset. To achieve this, we assign each airline (and its associated airports) a frame index randomly drawn from 1 to 10. We’ll use this new dataset <code>air.all.framed</code> to visualize flight lines.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>air.all.framed <span className="ot">&lt;-</span> air.all <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">group_by</span>(flight_ID) <span className="sc">%&gt;%</span> </span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">whichFrame =</span> <span className="fu">sample</span>(<span className="dv">10</span>, <span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="fu">factor</span>())</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a></span><br/>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a><span className="fu">head</span>(air.all.framed, <span className="at">n =</span> <span className="dv">6</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 6 × 8
<br/># Groups:   flight_ID [3]
<br/>  flight_ID direction   airport_ID name             lat  long country whichFrame
<br/>      &lt;int&gt; &lt;chr&gt;       &lt;chr&gt;      &lt;chr&gt;          &lt;dbl&gt; &lt;dbl&gt; &lt;chr&gt;   &lt;fct&gt;     
<br/>1         1 source      2965       Sochi Interna…  43.4  40.0 Russia  2         
<br/>2         1 destination 2990       Kazan Interna…  55.6  49.3 Russia  2         
<br/>3         2 source      2966       Astrakhan Air…  46.3  48.0 Russia  1         
<br/>4         2 destination 2990       Kazan Interna…  55.6  49.3 Russia  1         
<br/>5         3 source      2966       Astrakhan Air…  46.3  48.0 Russia  8         
<br/>6         3 destination 2962       Mineralnyye V…  44.2  43.1 Russia  8         </code></pre>
</div>
</div>
<p><strong>Edit 2:</strong> So far each frame contains a large number of duplicated airports shared by different flights. Here we only select <em>unique</em> airports to display in each frame. This reduces the number of data points to be plotted, and makes it faster to render the graphic.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="co"># "airport.framed" will be mapped to airport scatterplot</span></span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>airport.framed <span className="ot">&lt;-</span> air.all.framed <span className="sc">%&gt;%</span> </span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">group_by</span>(whichFrame) <span className="sc">%&gt;%</span> </span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>  <span className="fu">distinct</span>(name, lat, long)</span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a></span><br/>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a><span className="fu">head</span>(airport.framed, <span className="at">n =</span> <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 4
<br/># Groups:   whichFrame [2]
<br/>  whichFrame name                          lat  long
<br/>  &lt;fct&gt;      &lt;chr&gt;                       &lt;dbl&gt; &lt;dbl&gt;
<br/>1 2          Sochi International Airport  43.4  40.0
<br/>2 2          Kazan International Airport  55.6  49.3
<br/>3 1          Astrakhan Airport            46.3  48.0
<br/>4 1          Kazan International Airport  55.6  49.3</code></pre>
</div>
</div>
<p><strong>Edit 3:</strong> Remove rows containing <code>NA</code> values. Run <code>air.all.framed[!complete.cases(air.all.framed), ]</code> (with exclamation mark <code>!</code>), and it displays rows containing missing values. This test shows that all these rows have <code>NA</code> in <code>lat</code> and <code>long</code>, and will incur an error when rendered into animation (but not in static graphic). Here we remove all these rows with the code below (<em>without</em> <code>!</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a>air.all.framed <span className="ot">&lt;-</span> </span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a>  air.all.framed[<span className="fu">complete.cases</span>(air.all.framed), ]</span></code></pre></div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>First create a static plot of all data, an overlap of all 10 different frames</strong>.</p>
<p><strong>Edit 4:</strong> Instead of putting the map dataset <code>my.world</code> inside the <code>ggplot()</code> line (as in the <Link to="../ggplot2-map-airline">static graphic</Link>), here we put it locally in <code>geom_polygon</code>. Meanwhile, we put dataset <code>air.all.framed</code> in the <code>ggplot()</code> line as the “global” dataset, such that <code>transition_states()</code> has access to the <code>whichFrame</code> variable to “facet” the plot into frames of animation.</p>
<p><strong>Edit 5</strong>: As the entire data is divided into 10 separate frames, we want to make the data in each frame visually more prominent, and we do so by increasing the <code>alpha</code> of the lines and points from 0.6 to 0.8 or 1. As the current static plot is an overlap of all 10 different frames, it would appear over-plotted. Once displayed sequentially in animation, each frame will look just right.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>my.world <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>)</span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a></span><br/>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>p.static <span className="ot">&lt;-</span> air.all.framed <span className="sc">%&gt;%</span> </span>
<span id="cb15-4"><a aria-hidden="true" href="#cb15-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> long, <span className="at">y =</span> lat)) <span className="sc">+</span> </span>
<span id="cb15-5"><a aria-hidden="true" href="#cb15-5" tabindex="-1"></a>  </span>
<span id="cb15-6"><a aria-hidden="true" href="#cb15-6" tabindex="-1"></a>  <span className="co"># create a world map background</span></span>
<span id="cb15-7"><a aria-hidden="true" href="#cb15-7" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">data =</span> my.world, <span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb15-8"><a aria-hidden="true" href="#cb15-8" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"tomato"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>, </span>
<span id="cb15-9"><a aria-hidden="true" href="#cb15-9" tabindex="-1"></a>               <span className="at">show.legend =</span> F, <span className="at">fill =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb15-10"><a aria-hidden="true" href="#cb15-10" tabindex="-1"></a>  <span className="co"># a black theme</span></span>
<span id="cb15-11"><a aria-hidden="true" href="#cb15-11" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb15-12"><a aria-hidden="true" href="#cb15-12" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>)) <span className="sc">+</span> </span>
<span id="cb15-13"><a aria-hidden="true" href="#cb15-13" tabindex="-1"></a>  </span>
<span id="cb15-14"><a aria-hidden="true" href="#cb15-14" tabindex="-1"></a>  <span className="co"># add flight lines</span></span>
<span id="cb15-15"><a aria-hidden="true" href="#cb15-15" tabindex="-1"></a>  <span className="fu">geom_line</span>(</span>
<span id="cb15-16"><a aria-hidden="true" href="#cb15-16" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> flight_ID),</span>
<span id="cb15-17"><a aria-hidden="true" href="#cb15-17" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"turquoise"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>, <span className="at">alpha =</span> .<span className="dv">8</span>) <span className="sc">+</span></span>
<span id="cb15-18"><a aria-hidden="true" href="#cb15-18" tabindex="-1"></a>  </span>
<span id="cb15-19"><a aria-hidden="true" href="#cb15-19" tabindex="-1"></a>  <span className="co"># add airports, using the "airport.framed" dataset (duplicates removed)</span></span>
<span id="cb15-20"><a aria-hidden="true" href="#cb15-20" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb15-21"><a aria-hidden="true" href="#cb15-21" tabindex="-1"></a>    <span className="at">data =</span> airport.framed,</span>
<span id="cb15-22"><a aria-hidden="true" href="#cb15-22" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"yellow"</span>, <span className="at">shape =</span> <span className="st">"."</span>, <span className="at">alpha =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb15-23"><a aria-hidden="true" href="#cb15-23" tabindex="-1"></a>  </span>
<span id="cb15-24"><a aria-hidden="true" href="#cb15-24" tabindex="-1"></a>  <span className="co"># add title at bottom left corner</span></span>
<span id="cb15-25"><a aria-hidden="true" href="#cb15-25" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb15-26"><a aria-hidden="true" href="#cb15-26" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="sc">-</span><span className="dv">180</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, <span className="at">hjust =</span> <span className="dv">0</span>,</span>
<span id="cb15-27"><a aria-hidden="true" href="#cb15-27" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"Airports and Flights"</span>, </span>
<span id="cb15-28"><a aria-hidden="true" href="#cb15-28" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">size =</span> <span className="fl">4.5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span> ) <span className="sc">+</span></span>
<span id="cb15-29"><a aria-hidden="true" href="#cb15-29" tabindex="-1"></a>  <span className="co"># add caption at bottom left corner</span></span>
<span id="cb15-30"><a aria-hidden="true" href="#cb15-30" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">caption =</span> <span className="st">"data source: OpenFlights https://openflights.org/data.html"</span>) <span className="sc">+</span></span>
<span id="cb15-31"><a aria-hidden="true" href="#cb15-31" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.caption =</span> <span className="fu">element_text</span>(</span>
<span id="cb15-32"><a aria-hidden="true" href="#cb15-32" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">hjust =</span> .<span className="dv">05</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">unit =</span> <span className="st">"pt"</span>))) <span className="sc">+</span></span>
<span id="cb15-33"><a aria-hidden="true" href="#cb15-33" tabindex="-1"></a>  <span className="co"># coordinate</span></span>
<span id="cb15-34"><a aria-hidden="true" href="#cb15-34" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(</span>
<span id="cb15-35"><a aria-hidden="true" href="#cb15-35" tabindex="-1"></a>    <span className="at">ratio =</span> <span className="fl">1.2</span>, <span className="co"># adjust aspect ratio</span></span>
<span id="cb15-36"><a aria-hidden="true" href="#cb15-36" tabindex="-1"></a>    <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">60</span>, <span className="dv">90</span>))  <span className="co"># remove Antarctica</span></span>
<span id="cb15-37"><a aria-hidden="true" href="#cb15-37" tabindex="-1"></a></span><br/>
<span id="cb15-38"><a aria-hidden="true" href="#cb15-38" tabindex="-1"></a>p.static</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_animation_static.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_airlines_animation_static"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Edit 6:</strong> Use <Link to="https://gganimate.com/"><code>gganimate</code></Link> package to render the static ggplot2 object into an animation: using the <code>whichFrame</code> variable to “facet” the plot into subplots (the <code>states</code>), which are then sequentially displayed on a time scale. (It takes a while to render the animation, e.g., 2~3 minutes in an Apple M1 Pro computer)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="fu">library</span>(gganimate)</span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a></span><br/>
<span id="cb16-3"><a aria-hidden="true" href="#cb16-3" tabindex="-1"></a>p.static <span className="sc">+</span> <span className="fu">transition_states</span>(</span>
<span id="cb16-4"><a aria-hidden="true" href="#cb16-4" tabindex="-1"></a>    <span className="co"># instant transition between different states (frames)</span></span>
<span id="cb16-5"><a aria-hidden="true" href="#cb16-5" tabindex="-1"></a>    <span className="at">transition_length =</span> <span className="dv">0</span>,</span>
<span id="cb16-6"><a aria-hidden="true" href="#cb16-6" tabindex="-1"></a>    <span className="co"># the "faceting" variable</span></span>
<span id="cb16-7"><a aria-hidden="true" href="#cb16-7" tabindex="-1"></a>    <span className="at">states =</span> whichFrame, </span>
<span id="cb16-8"><a aria-hidden="true" href="#cb16-8" tabindex="-1"></a>    <span className="co"># transition the last state to the first state </span></span>
<span id="cb16-9"><a aria-hidden="true" href="#cb16-9" tabindex="-1"></a>    <span className="co"># to continue the animation</span></span>
<span id="cb16-10"><a aria-hidden="true" href="#cb16-10" tabindex="-1"></a>    <span className="at">wrap =</span> T)  </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_airlines_animation_gganimate_completed-1.gif"/></p>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb17-3"><a aria-hidden="true" href="#cb17-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb17-4"><a aria-hidden="true" href="#cb17-4" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb17-5"><a aria-hidden="true" href="#cb17-5" tabindex="-1"></a></span><br/>
<span id="cb17-6"><a aria-hidden="true" href="#cb17-6" tabindex="-1"></a><span className="co"># (1) Import and clean up the airport dataset</span></span>
<span id="cb17-7"><a aria-hidden="true" href="#cb17-7" tabindex="-1"></a>url.airport <span className="ot">&lt;-</span> </span>
<span id="cb17-8"><a aria-hidden="true" href="#cb17-8" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/airports.dat"</span></span>
<span id="cb17-9"><a aria-hidden="true" href="#cb17-9" tabindex="-1"></a></span><br/>
<span id="cb17-10"><a aria-hidden="true" href="#cb17-10" tabindex="-1"></a>airport <span className="ot">&lt;-</span> <span className="fu">read.table</span>(</span>
<span id="cb17-11"><a aria-hidden="true" href="#cb17-11" tabindex="-1"></a>  url.airport, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> <span className="cn">FALSE</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb17-12"><a aria-hidden="true" href="#cb17-12" tabindex="-1"></a>  <span className="fu">as_tibble</span>()  </span>
<span id="cb17-13"><a aria-hidden="true" href="#cb17-13" tabindex="-1"></a></span><br/>
<span id="cb17-14"><a aria-hidden="true" href="#cb17-14" tabindex="-1"></a><span className="co"># Update column names based on website description; </span></span>
<span id="cb17-15"><a aria-hidden="true" href="#cb17-15" tabindex="-1"></a><span className="fu">colnames</span>(airport) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb17-16"><a aria-hidden="true" href="#cb17-16" tabindex="-1"></a>  <span className="st">"airport_ID"</span>, <span className="st">"name"</span>, <span className="st">"city"</span>, <span className="st">"country"</span>, </span>
<span id="cb17-17"><a aria-hidden="true" href="#cb17-17" tabindex="-1"></a>  <span className="st">"IATA"</span>, <span className="st">"ICAO"</span>, <span className="st">"lat"</span>, <span className="st">"long"</span>, <span className="st">"altitude"</span>, <span className="st">"timezone"</span>,</span>
<span id="cb17-18"><a aria-hidden="true" href="#cb17-18" tabindex="-1"></a>  <span className="st">"DST"</span>, <span className="st">"Tz_database"</span>, <span className="st">"time_zone"</span>, <span className="st">"type"</span>)</span>
<span id="cb17-19"><a aria-hidden="true" href="#cb17-19" tabindex="-1"></a></span><br/>
<span id="cb17-20"><a aria-hidden="true" href="#cb17-20" tabindex="-1"></a><span className="co"># select columns useful in this visualization</span></span>
<span id="cb17-21"><a aria-hidden="true" href="#cb17-21" tabindex="-1"></a>airport.selected <span className="ot">&lt;-</span> airport <span className="sc">%&gt;%</span> </span>
<span id="cb17-22"><a aria-hidden="true" href="#cb17-22" tabindex="-1"></a>  <span className="fu">select</span>(name, airport_ID, lat, long, country) <span className="sc">%&gt;%</span> </span>
<span id="cb17-23"><a aria-hidden="true" href="#cb17-23" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">airport_ID =</span> <span className="fu">as.character</span>(airport_ID))</span>
<span id="cb17-24"><a aria-hidden="true" href="#cb17-24" tabindex="-1"></a></span><br/>
<span id="cb17-25"><a aria-hidden="true" href="#cb17-25" tabindex="-1"></a><span className="fu">head</span>(airport.selected, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb17-26"><a aria-hidden="true" href="#cb17-26" tabindex="-1"></a></span><br/>
<span id="cb17-27"><a aria-hidden="true" href="#cb17-27" tabindex="-1"></a></span><br/>
<span id="cb17-28"><a aria-hidden="true" href="#cb17-28" tabindex="-1"></a><span className="co"># (2) Load and clean up the airline dataset.</span></span>
<span id="cb17-29"><a aria-hidden="true" href="#cb17-29" tabindex="-1"></a>url.airline <span className="ot">&lt;-</span> </span>
<span id="cb17-30"><a aria-hidden="true" href="#cb17-30" tabindex="-1"></a>  <span className="st">"https://raw.githubusercontent.com/jpatokal/openflights/master/data/routes.dat"</span></span>
<span id="cb17-31"><a aria-hidden="true" href="#cb17-31" tabindex="-1"></a></span><br/>
<span id="cb17-32"><a aria-hidden="true" href="#cb17-32" tabindex="-1"></a>airline <span className="ot">&lt;-</span> <span className="fu">read.table</span>(</span>
<span id="cb17-33"><a aria-hidden="true" href="#cb17-33" tabindex="-1"></a>  url.airline, <span className="at">sep =</span> <span className="st">","</span>, <span className="at">header =</span> F) <span className="sc">%&gt;%</span> </span>
<span id="cb17-34"><a aria-hidden="true" href="#cb17-34" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb17-35"><a aria-hidden="true" href="#cb17-35" tabindex="-1"></a></span><br/>
<span id="cb17-36"><a aria-hidden="true" href="#cb17-36" tabindex="-1"></a><span className="co"># Update column names based on website description</span></span>
<span id="cb17-37"><a aria-hidden="true" href="#cb17-37" tabindex="-1"></a><span className="fu">colnames</span>(airline) <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb17-38"><a aria-hidden="true" href="#cb17-38" tabindex="-1"></a>  <span className="st">"airline"</span>, <span className="st">"airline_ID"</span>, <span className="st">"source_airport"</span>, </span>
<span id="cb17-39"><a aria-hidden="true" href="#cb17-39" tabindex="-1"></a>  <span className="st">"source_airport_ID"</span>, <span className="st">"destination_airport"</span>,</span>
<span id="cb17-40"><a aria-hidden="true" href="#cb17-40" tabindex="-1"></a>  <span className="st">"destination_airport_ID"</span>, <span className="st">"Codeshare"</span>, <span className="st">"Stops"</span>, <span className="st">"Equipment"</span>)</span>
<span id="cb17-41"><a aria-hidden="true" href="#cb17-41" tabindex="-1"></a></span><br/>
<span id="cb17-42"><a aria-hidden="true" href="#cb17-42" tabindex="-1"></a><span className="co"># select useful columns</span></span>
<span id="cb17-43"><a aria-hidden="true" href="#cb17-43" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb17-44"><a aria-hidden="true" href="#cb17-44" tabindex="-1"></a>  <span className="fu">select</span>(source_airport_ID, destination_airport_ID)</span>
<span id="cb17-45"><a aria-hidden="true" href="#cb17-45" tabindex="-1"></a></span><br/>
<span id="cb17-46"><a aria-hidden="true" href="#cb17-46" tabindex="-1"></a><span className="co"># For this airline dataset, we need to do two more critical steps:</span></span>
<span id="cb17-47"><a aria-hidden="true" href="#cb17-47" tabindex="-1"></a><span className="do">## Assign a unique ID to each flight to be mapped to the 'group' aesthetic for 'geom_line'.</span></span>
<span id="cb17-48"><a aria-hidden="true" href="#cb17-48" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="fu">nrow</span>(airline)</span>
<span id="cb17-49"><a aria-hidden="true" href="#cb17-49" tabindex="-1"></a>airline <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">flight_ID =</span> <span className="dv">1</span><span className="sc">:</span>n)</span>
<span id="cb17-50"><a aria-hidden="true" href="#cb17-50" tabindex="-1"></a></span><br/>
<span id="cb17-51"><a aria-hidden="true" href="#cb17-51" tabindex="-1"></a><span className="do">## Pivot the dataset into a tidy structure</span></span>
<span id="cb17-52"><a aria-hidden="true" href="#cb17-52" tabindex="-1"></a>airline.tidy <span className="ot">&lt;-</span> airline <span className="sc">%&gt;%</span> </span>
<span id="cb17-53"><a aria-hidden="true" href="#cb17-53" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>flight_ID, <span className="at">names_to =</span> <span className="st">"direction"</span>, <span className="at">values_to =</span> <span className="st">"airport_ID"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb17-54"><a aria-hidden="true" href="#cb17-54" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">direction =</span> <span className="fu">str_remove</span>(direction, <span className="st">"_airport_ID"</span>))</span>
<span id="cb17-55"><a aria-hidden="true" href="#cb17-55" tabindex="-1"></a></span><br/>
<span id="cb17-56"><a aria-hidden="true" href="#cb17-56" tabindex="-1"></a><span className="fu">head</span>(airline.tidy, <span className="at">n =</span> <span className="dv">4</span>)</span>
<span id="cb17-57"><a aria-hidden="true" href="#cb17-57" tabindex="-1"></a></span><br/>
<span id="cb17-58"><a aria-hidden="true" href="#cb17-58" tabindex="-1"></a></span><br/>
<span id="cb17-59"><a aria-hidden="true" href="#cb17-59" tabindex="-1"></a><span className="co"># (3) Merge the airport and airline datasets.</span></span>
<span id="cb17-60"><a aria-hidden="true" href="#cb17-60" tabindex="-1"></a>air.all <span className="ot">&lt;-</span> airline.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb17-61"><a aria-hidden="true" href="#cb17-61" tabindex="-1"></a>  <span className="fu">left_join</span>(airport.selected, <span className="at">by =</span> <span className="st">"airport_ID"</span>)</span>
<span id="cb17-62"><a aria-hidden="true" href="#cb17-62" tabindex="-1"></a></span><br/>
<span id="cb17-63"><a aria-hidden="true" href="#cb17-63" tabindex="-1"></a><span className="fu">head</span>(air.all, <span className="at">n =</span> <span className="dv">4</span>) </span>
<span id="cb17-64"><a aria-hidden="true" href="#cb17-64" tabindex="-1"></a></span><br/>
<span id="cb17-65"><a aria-hidden="true" href="#cb17-65" tabindex="-1"></a></span><br/>
<span id="cb17-66"><a aria-hidden="true" href="#cb17-66" tabindex="-1"></a><span className="co"># The code above is identical to that of a STATIC graphic. </span></span>
<span id="cb17-67"><a aria-hidden="true" href="#cb17-67" tabindex="-1"></a><span className="co"># Edits 1-6 below are particularly catered for ANIMATION. </span></span>
<span id="cb17-68"><a aria-hidden="true" href="#cb17-68" tabindex="-1"></a></span><br/>
<span id="cb17-69"><a aria-hidden="true" href="#cb17-69" tabindex="-1"></a><span className="co"># Edit 1: Create another variable, `whichFrame`, to determine which frames of animation the points and lines should be displayed in.</span></span>
<span id="cb17-70"><a aria-hidden="true" href="#cb17-70" tabindex="-1"></a>air.all.framed <span className="ot">&lt;-</span> air.all <span className="sc">%&gt;%</span> </span>
<span id="cb17-71"><a aria-hidden="true" href="#cb17-71" tabindex="-1"></a>  <span className="fu">group_by</span>(flight_ID) <span className="sc">%&gt;%</span> </span>
<span id="cb17-72"><a aria-hidden="true" href="#cb17-72" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">whichFrame =</span> <span className="fu">sample</span>(<span className="dv">10</span>, <span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="fu">factor</span>())</span>
<span id="cb17-73"><a aria-hidden="true" href="#cb17-73" tabindex="-1"></a></span><br/>
<span id="cb17-74"><a aria-hidden="true" href="#cb17-74" tabindex="-1"></a><span className="fu">head</span>(air.all.framed, <span className="at">n =</span> <span className="dv">6</span>)</span>
<span id="cb17-75"><a aria-hidden="true" href="#cb17-75" tabindex="-1"></a></span><br/>
<span id="cb17-76"><a aria-hidden="true" href="#cb17-76" tabindex="-1"></a></span><br/>
<span id="cb17-77"><a aria-hidden="true" href="#cb17-77" tabindex="-1"></a><span className="co"># Edit 2: In each frame, remove duplicated airports (shared by different flights). </span></span>
<span id="cb17-78"><a aria-hidden="true" href="#cb17-78" tabindex="-1"></a>airport.framed <span className="ot">&lt;-</span> air.all.framed <span className="sc">%&gt;%</span> </span>
<span id="cb17-79"><a aria-hidden="true" href="#cb17-79" tabindex="-1"></a>  <span className="fu">group_by</span>(whichFrame) <span className="sc">%&gt;%</span> </span>
<span id="cb17-80"><a aria-hidden="true" href="#cb17-80" tabindex="-1"></a>  <span className="fu">distinct</span>(name, lat, long)</span>
<span id="cb17-81"><a aria-hidden="true" href="#cb17-81" tabindex="-1"></a></span><br/>
<span id="cb17-82"><a aria-hidden="true" href="#cb17-82" tabindex="-1"></a><span className="co"># Edit 3: Remove rows containing `NA` values. </span></span>
<span id="cb17-83"><a aria-hidden="true" href="#cb17-83" tabindex="-1"></a>air.all.framed <span className="ot">&lt;-</span> </span>
<span id="cb17-84"><a aria-hidden="true" href="#cb17-84" tabindex="-1"></a>  air.all.framed[<span className="fu">complete.cases</span>(air.all.framed), ]</span>
<span id="cb17-85"><a aria-hidden="true" href="#cb17-85" tabindex="-1"></a></span><br/>
<span id="cb17-86"><a aria-hidden="true" href="#cb17-86" tabindex="-1"></a></span><br/>
<span id="cb17-87"><a aria-hidden="true" href="#cb17-87" tabindex="-1"></a><span className="co"># Make plots now! </span></span>
<span id="cb17-88"><a aria-hidden="true" href="#cb17-88" tabindex="-1"></a><span className="co"># First create a static plot of all data, an overlap of all 10 different frames:</span></span>
<span id="cb17-89"><a aria-hidden="true" href="#cb17-89" tabindex="-1"></a></span><br/>
<span id="cb17-90"><a aria-hidden="true" href="#cb17-90" tabindex="-1"></a><span className="co"># Edit 4: Mapping the world map dataset `my.world` locally in `geom_polygon`, and map `air.all.framed` in the `ggplot` line as the "global" dataset.</span></span>
<span id="cb17-91"><a aria-hidden="true" href="#cb17-91" tabindex="-1"></a></span><br/>
<span id="cb17-92"><a aria-hidden="true" href="#cb17-92" tabindex="-1"></a><span className="co"># Edit 5: Use alpha = 1 for points and lines. </span></span>
<span id="cb17-93"><a aria-hidden="true" href="#cb17-93" tabindex="-1"></a></span><br/>
<span id="cb17-94"><a aria-hidden="true" href="#cb17-94" tabindex="-1"></a>my.world <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>)</span>
<span id="cb17-95"><a aria-hidden="true" href="#cb17-95" tabindex="-1"></a></span><br/>
<span id="cb17-96"><a aria-hidden="true" href="#cb17-96" tabindex="-1"></a>p.static <span className="ot">&lt;-</span> air.all.framed <span className="sc">%&gt;%</span> </span>
<span id="cb17-97"><a aria-hidden="true" href="#cb17-97" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> long, <span className="at">y =</span> lat)) <span className="sc">+</span> </span>
<span id="cb17-98"><a aria-hidden="true" href="#cb17-98" tabindex="-1"></a>  </span>
<span id="cb17-99"><a aria-hidden="true" href="#cb17-99" tabindex="-1"></a>  <span className="co"># create a world map background</span></span>
<span id="cb17-100"><a aria-hidden="true" href="#cb17-100" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">data =</span> my.world, <span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb17-101"><a aria-hidden="true" href="#cb17-101" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"tomato"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>, </span>
<span id="cb17-102"><a aria-hidden="true" href="#cb17-102" tabindex="-1"></a>               <span className="at">show.legend =</span> F, <span className="at">fill =</span> <span className="st">"black"</span>) <span className="sc">+</span></span>
<span id="cb17-103"><a aria-hidden="true" href="#cb17-103" tabindex="-1"></a>  <span className="co"># a black theme</span></span>
<span id="cb17-104"><a aria-hidden="true" href="#cb17-104" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb17-105"><a aria-hidden="true" href="#cb17-105" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>)) <span className="sc">+</span> </span>
<span id="cb17-106"><a aria-hidden="true" href="#cb17-106" tabindex="-1"></a>  </span>
<span id="cb17-107"><a aria-hidden="true" href="#cb17-107" tabindex="-1"></a>  <span className="co"># add flight lines</span></span>
<span id="cb17-108"><a aria-hidden="true" href="#cb17-108" tabindex="-1"></a>  <span className="fu">geom_line</span>(</span>
<span id="cb17-109"><a aria-hidden="true" href="#cb17-109" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> flight_ID),</span>
<span id="cb17-110"><a aria-hidden="true" href="#cb17-110" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"turquoise"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>, <span className="at">alpha =</span> .<span className="dv">8</span>) <span className="sc">+</span></span>
<span id="cb17-111"><a aria-hidden="true" href="#cb17-111" tabindex="-1"></a>  </span>
<span id="cb17-112"><a aria-hidden="true" href="#cb17-112" tabindex="-1"></a>  <span className="co"># add airports, using the "airport.framed" dataset (duplicates removed)</span></span>
<span id="cb17-113"><a aria-hidden="true" href="#cb17-113" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb17-114"><a aria-hidden="true" href="#cb17-114" tabindex="-1"></a>    <span className="at">data =</span> airport.framed, </span>
<span id="cb17-115"><a aria-hidden="true" href="#cb17-115" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"yellow"</span>, <span className="at">shape =</span> <span className="st">"."</span>, <span className="at">alpha =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb17-116"><a aria-hidden="true" href="#cb17-116" tabindex="-1"></a>  </span>
<span id="cb17-117"><a aria-hidden="true" href="#cb17-117" tabindex="-1"></a>  <span className="co"># add title at bottom left corner</span></span>
<span id="cb17-118"><a aria-hidden="true" href="#cb17-118" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb17-119"><a aria-hidden="true" href="#cb17-119" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="sc">-</span><span className="dv">180</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, <span className="at">hjust =</span> <span className="dv">0</span>,</span>
<span id="cb17-120"><a aria-hidden="true" href="#cb17-120" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"Airports and Flights"</span>, </span>
<span id="cb17-121"><a aria-hidden="true" href="#cb17-121" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">size =</span> <span className="fl">4.5</span>, <span className="at">fontface =</span> <span className="st">"bold"</span> ) <span className="sc">+</span></span>
<span id="cb17-122"><a aria-hidden="true" href="#cb17-122" tabindex="-1"></a>  </span>
<span id="cb17-123"><a aria-hidden="true" href="#cb17-123" tabindex="-1"></a>  <span className="co"># add caption at bottom left corner</span></span>
<span id="cb17-124"><a aria-hidden="true" href="#cb17-124" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">caption =</span> <span className="st">"data source: OpenFlights https://openflights.org/data.html"</span>) <span className="sc">+</span></span>
<span id="cb17-125"><a aria-hidden="true" href="#cb17-125" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.caption =</span> <span className="fu">element_text</span>(</span>
<span id="cb17-126"><a aria-hidden="true" href="#cb17-126" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">hjust =</span> .<span className="dv">05</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">unit =</span> <span className="st">"pt"</span>))) <span className="sc">+</span></span>
<span id="cb17-127"><a aria-hidden="true" href="#cb17-127" tabindex="-1"></a>  </span>
<span id="cb17-128"><a aria-hidden="true" href="#cb17-128" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(</span>
<span id="cb17-129"><a aria-hidden="true" href="#cb17-129" tabindex="-1"></a>    <span className="at">ratio =</span> <span className="fl">1.2</span>, <span className="co"># adjust aspect ratio</span></span>
<span id="cb17-130"><a aria-hidden="true" href="#cb17-130" tabindex="-1"></a>    <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">60</span>, <span className="dv">90</span>))  <span className="co"># remove Antarctica</span></span>
<span id="cb17-131"><a aria-hidden="true" href="#cb17-131" tabindex="-1"></a></span><br/>
<span id="cb17-132"><a aria-hidden="true" href="#cb17-132" tabindex="-1"></a>p.static</span>
<span id="cb17-133"><a aria-hidden="true" href="#cb17-133" tabindex="-1"></a></span><br/>
<span id="cb17-134"><a aria-hidden="true" href="#cb17-134" tabindex="-1"></a></span><br/>
<span id="cb17-135"><a aria-hidden="true" href="#cb17-135" tabindex="-1"></a><span className="co"># Edit 6:** render the static plot into animation.</span></span>
<span id="cb17-136"><a aria-hidden="true" href="#cb17-136" tabindex="-1"></a><span className="co"># It takes several minutes to render the animation. </span></span>
<span id="cb17-137"><a aria-hidden="true" href="#cb17-137" tabindex="-1"></a><span className="fu">library</span>(gganimate)</span>
<span id="cb17-138"><a aria-hidden="true" href="#cb17-138" tabindex="-1"></a></span><br/>
<span id="cb17-139"><a aria-hidden="true" href="#cb17-139" tabindex="-1"></a>p.static <span className="sc">+</span> <span className="fu">transition_states</span>(</span>
<span id="cb17-140"><a aria-hidden="true" href="#cb17-140" tabindex="-1"></a>    <span className="co"># instant transition between different states (frames)</span></span>
<span id="cb17-141"><a aria-hidden="true" href="#cb17-141" tabindex="-1"></a>    <span className="at">transition_length =</span> <span className="dv">0</span>,</span>
<span id="cb17-142"><a aria-hidden="true" href="#cb17-142" tabindex="-1"></a>    <span className="co"># the "faceting" variable</span></span>
<span id="cb17-143"><a aria-hidden="true" href="#cb17-143" tabindex="-1"></a>    <span className="at">states =</span> whichFrame, </span>
<span id="cb17-144"><a aria-hidden="true" href="#cb17-144" tabindex="-1"></a>    <span className="co"># transition the last state to the first state </span></span>
<span id="cb17-145"><a aria-hidden="true" href="#cb17-145" tabindex="-1"></a>    <span className="co"># to continue the animation</span></span>
<span id="cb17-146"><a aria-hidden="true" href="#cb17-146" tabindex="-1"></a>    <span className="at">wrap =</span> T)  </span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Animation is a powerful approach to visualize dynamic changes with time. Check out the following <Link to="../ggplot2-population-pyramid-animation"><strong>population pyramids in animation</strong></Link> using the popular <Link to="https://gganimate.com/"><code>gganimate</code></Link> package. (You can also check first <Link to="../ggplot2-population-pyramid">how to create a <em>static</em> population pyramid</Link> before diving into the animation.)</p>
<p><Link to="../ggplot2-population-pyramid-animation"><img className="img-fluid" src="graphics/population_pyramid_animation_completed-1.gif"/></Link></p>
</main>
</div>
</div>
)}