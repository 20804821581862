import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2HeatmapVaccineForDiseases from '../graphics/heatmap_vaccine_function_completed_all_8_diseases.png'; 
import imgGgplot2HeatmapVaccineForDiseasesWebp from '../graphics/heatmap_vaccine_function_completed_all_8_diseases.webp'; 
export default function Ggplot2HeatmapVaccineForDiseases(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Heatmaps in ggplot2 to Visualize Vaccines’ Impact on Diseases Control</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2HeatmapVaccineForDiseasesWebp} />
    <img className="cover-img" src={imgGgplot2HeatmapVaccineForDiseases} />
  </picture>

<p>In this <Link to="../ggplot2-heatmap-vaccine-for-polio">earlier tutorial</Link>, we created a heatmap showing the historical incidences of the polio disease in the U.S. history, with highlight of the power of vaccination in its control. This article extends that earlier work by <strong>updating the script into a function to automate the generation of heatmaps for eight different infectious diseases</strong> throughout the history of the United States.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">packages and data cleanup</h3>
<p>To access the <strong>authentic dataset</strong> (as used in this demonstration), go to <Link to="https://www.tycho.pitt.edu/"><strong>Project TYCHO</strong></Link>, scroll down to “ACCESS DATA” - “DATASETS Pre-Compiled”, and click “START NOW”. Then scroll <em>up</em> to “PREVIOUS VERSIONS” - “Level 1 Data peer-reviewed research 8 diseases”, and click download ( create an account to initiate download).</p>
<p>For practice purpose, you can <em>download this</em> <strong>simulated data <a className="downloadData" href="dataset/r-gallery/US_disease_simulated.csv">here</a></strong> <em>with a single click</em>. This simulated dataset has the same data structure as the original one.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer) </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>data <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/US_disease.csv"</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>data <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>data.summary <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="co"># extract years </span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">str_sub</span>(epi_week, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="dv">4</span>) <span className="sc">%&gt;%</span> <span className="fu">as.integer</span>()) <span className="sc">%&gt;%</span>  </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">select</span>(<span className="sc">-</span>epi_week) <span className="sc">%&gt;%</span> </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  </span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  <span className="co"># sum up cases across all weeks for each disease in each year and each state </span></span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>  <span className="co"># incidences presented as per 100 K population</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="fu">group_by</span>(state, year, disease) <span className="sc">%&gt;%</span> </span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">n.per.100K =</span> <span className="fu">sum</span>(incidence_per_100000))</span></code></pre></div>
</div>
<p>Put the colors vector in the global environment. Alternatively, it can be put inside the function.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">c</span>(<span className="st">"grey95"</span>, <span className="st">"#d1e6f7"</span>, <span className="st">"#a6d3f1"</span>, <span className="st">"#57b7e7"</span>,<span className="st">"#1f9ad4"</span>, <span className="st">"#3aab70"</span>, <span className="st">"#e0d141"</span>, </span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>    <span className="st">"#fdd53e"</span>, <span className="st">"#f4cd3c"</span>, <span className="st">"#feb73e"</span>, <span className="st">"orange"</span>, <span className="st">"#eb6c10"</span>,  <span className="st">"firebrick2"</span>, <span className="st">"firebrick4"</span>),</span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="at">bias =</span> <span className="dv">3</span>)(<span className="dv">50</span>) </span></code></pre></div>
</div>
</section>
<section className="level3" id="create-the-visualizing-function">
<h3 className="anchored" data-anchor-id="create-the-visualizing-function">Create the visualizing function</h3>
<p>In the function, values or parameters are extracted from the input dataset to automate the customization of certain plot attributes, such as scale breaks and annotation positions. Additionally, the output from the function is further fine-tuned, if necessary, separately for each disease.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># Create function</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>func.plot <span className="ot">&lt;-</span> <span className="cf">function</span>(<span className="at">which.disease =</span> <span className="st">"HEPATITIS A"</span>,</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>                      <span className="at">year.vaccine.introduced =</span> <span className="cn">NA</span>) &#123;</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="co"># data subset containing the selected disease</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  data.summary.i <span className="ot">&lt;-</span> data.summary <span className="sc">%&gt;%</span> </span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>    <span className="fu">filter</span>(disease <span className="sc">==</span> which.disease)</span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>  </span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>  <span className="co"># create heatmap </span></span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>  myheatmap <span className="ot">&lt;-</span> data.summary.i <span className="sc">%&gt;%</span> </span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>    <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> state, <span className="at">fill =</span> n.per<span className="fl">.100</span>K)) <span className="sc">+</span></span>
<span id="cb3-12"><a aria-hidden="true" href="#cb3-12" tabindex="-1"></a>    </span>
<span id="cb3-13"><a aria-hidden="true" href="#cb3-13" tabindex="-1"></a>    <span className="co"># heatmap base</span></span>
<span id="cb3-14"><a aria-hidden="true" href="#cb3-14" tabindex="-1"></a>    <span className="fu">geom_tile</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb3-15"><a aria-hidden="true" href="#cb3-15" tabindex="-1"></a>    </span>
<span id="cb3-16"><a aria-hidden="true" href="#cb3-16" tabindex="-1"></a>    <span className="co"># x axis breaks: label with years ended with 0 and 5</span></span>
<span id="cb3-17"><a aria-hidden="true" href="#cb3-17" tabindex="-1"></a>    <span className="fu">scale_x_continuous</span>(</span>
<span id="cb3-18"><a aria-hidden="true" href="#cb3-18" tabindex="-1"></a>      <span className="at">breaks =</span> <span className="fu">seq</span>(</span>
<span id="cb3-19"><a aria-hidden="true" href="#cb3-19" tabindex="-1"></a>        <span className="at">from =</span> (<span className="fu">min</span>(data.summary.i<span className="sc">$</span>year) <span className="sc">/</span> <span className="dv">5</span>) <span className="sc">%&gt;%</span> <span className="fu">ceiling</span>() <span className="sc">*</span> <span className="dv">5</span> , </span>
<span id="cb3-20"><a aria-hidden="true" href="#cb3-20" tabindex="-1"></a>        <span className="at">to =</span> (<span className="fu">max</span>(data.summary.i<span className="sc">$</span>year) <span className="sc">/</span> <span className="dv">5</span>) <span className="sc">%&gt;%</span> <span className="fu">floor</span>() <span className="sc">*</span> <span className="dv">5</span>, </span>
<span id="cb3-21"><a aria-hidden="true" href="#cb3-21" tabindex="-1"></a>        <span className="at">by =</span> <span className="dv">5</span>)) <span className="sc">+</span> </span>
<span id="cb3-22"><a aria-hidden="true" href="#cb3-22" tabindex="-1"></a>    </span>
<span id="cb3-23"><a aria-hidden="true" href="#cb3-23" tabindex="-1"></a>    <span className="co"># color scale</span></span>
<span id="cb3-24"><a aria-hidden="true" href="#cb3-24" tabindex="-1"></a>    <span className="fu">scale_fill_gradientn</span>(</span>
<span id="cb3-25"><a aria-hidden="true" href="#cb3-25" tabindex="-1"></a>      <span className="at">colours =</span> myColors, </span>
<span id="cb3-26"><a aria-hidden="true" href="#cb3-26" tabindex="-1"></a>      <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="fu">max</span>(data.summary.i<span className="sc">$</span>n.per<span className="fl">.100</span>K), <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb3-27"><a aria-hidden="true" href="#cb3-27" tabindex="-1"></a>    </span>
<span id="cb3-28"><a aria-hidden="true" href="#cb3-28" tabindex="-1"></a>    <span className="co"># color bar customization</span></span>
<span id="cb3-29"><a aria-hidden="true" href="#cb3-29" tabindex="-1"></a>    <span className="fu">guides</span>(</span>
<span id="cb3-30"><a aria-hidden="true" href="#cb3-30" tabindex="-1"></a>      <span className="at">fill =</span> <span className="fu">guide_colorbar</span>(<span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">250</span>, <span className="st">"pt"</span>),</span>
<span id="cb3-31"><a aria-hidden="true" href="#cb3-31" tabindex="-1"></a>                            <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb3-32"><a aria-hidden="true" href="#cb3-32" tabindex="-1"></a>                            <span className="at">title =</span> <span className="cn">NULL</span>)) <span className="sc">+</span></span>
<span id="cb3-33"><a aria-hidden="true" href="#cb3-33" tabindex="-1"></a>    <span className="co"># axes labels</span></span>
<span id="cb3-34"><a aria-hidden="true" href="#cb3-34" tabindex="-1"></a>    <span className="fu">labs</span>(</span>
<span id="cb3-35"><a aria-hidden="true" href="#cb3-35" tabindex="-1"></a>      <span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>, </span>
<span id="cb3-36"><a aria-hidden="true" href="#cb3-36" tabindex="-1"></a>      <span className="at">fill =</span> <span className="st">"yearly cases </span><span className="sc">\n</span><span className="st">per 100K"</span>,</span>
<span id="cb3-37"><a aria-hidden="true" href="#cb3-37" tabindex="-1"></a>      <span className="at">title =</span> data.summary.i<span className="sc">$</span>disease <span className="sc">%&gt;%</span> <span className="fu">unique</span>(),</span>
<span id="cb3-38"><a aria-hidden="true" href="#cb3-38" tabindex="-1"></a>      <span className="at">subtitle =</span> <span className="st">"yearly cases per 100K population"</span> <span className="sc">%&gt;%</span> <span className="fu">paste</span>( <span className="st">"</span><span className="sc">\n</span><span className="st">"</span>)) <span className="sc">+</span></span>
<span id="cb3-39"><a aria-hidden="true" href="#cb3-39" tabindex="-1"></a>    </span>
<span id="cb3-40"><a aria-hidden="true" href="#cb3-40" tabindex="-1"></a>    <span className="co"># theme</span></span>
<span id="cb3-41"><a aria-hidden="true" href="#cb3-41" tabindex="-1"></a>    <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb3-42"><a aria-hidden="true" href="#cb3-42" tabindex="-1"></a>    <span className="fu">theme</span>(</span>
<span id="cb3-43"><a aria-hidden="true" href="#cb3-43" tabindex="-1"></a>      <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb3-44"><a aria-hidden="true" href="#cb3-44" tabindex="-1"></a>      <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">20</span>),</span>
<span id="cb3-45"><a aria-hidden="true" href="#cb3-45" tabindex="-1"></a>      <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">color =</span> <span className="st">"grey60"</span>),</span>
<span id="cb3-46"><a aria-hidden="true" href="#cb3-46" tabindex="-1"></a>      <span className="at">legend.position =</span> <span className="st">"bottom"</span>,</span>
<span id="cb3-47"><a aria-hidden="true" href="#cb3-47" tabindex="-1"></a>      <span className="at">plot.margin =</span> <span className="fu">unit</span>(<span className="fu">c</span>(.<span className="dv">5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>), <span className="st">"cm"</span>)) <span className="sc">+</span></span>
<span id="cb3-48"><a aria-hidden="true" href="#cb3-48" tabindex="-1"></a>    </span>
<span id="cb3-49"><a aria-hidden="true" href="#cb3-49" tabindex="-1"></a>    <span className="co"># expand to fill up entire plot area, </span></span>
<span id="cb3-50"><a aria-hidden="true" href="#cb3-50" tabindex="-1"></a>    <span className="co"># and clip off to display the text annotation</span></span>
<span id="cb3-51"><a aria-hidden="true" href="#cb3-51" tabindex="-1"></a>    <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> <span className="st">"off"</span>)  </span>
<span id="cb3-52"><a aria-hidden="true" href="#cb3-52" tabindex="-1"></a>  </span>
<span id="cb3-53"><a aria-hidden="true" href="#cb3-53" tabindex="-1"></a>  <span className="co"># if data is available, annotate with the year of vaccine introduction </span></span>
<span id="cb3-54"><a aria-hidden="true" href="#cb3-54" tabindex="-1"></a>  <span className="cf">if</span> (<span className="sc">!</span> <span className="fu">is.na</span>(year.vaccine.introduced)) &#123;</span>
<span id="cb3-55"><a aria-hidden="true" href="#cb3-55" tabindex="-1"></a>    </span>
<span id="cb3-56"><a aria-hidden="true" href="#cb3-56" tabindex="-1"></a>    myheatmap <span className="ot">&lt;-</span>  myheatmap <span className="sc">+</span></span>
<span id="cb3-57"><a aria-hidden="true" href="#cb3-57" tabindex="-1"></a>      </span>
<span id="cb3-58"><a aria-hidden="true" href="#cb3-58" tabindex="-1"></a>      <span className="co"># annotate with line: the year of vaccine introduction   </span></span>
<span id="cb3-59"><a aria-hidden="true" href="#cb3-59" tabindex="-1"></a>      <span className="fu">geom_vline</span>(</span>
<span id="cb3-60"><a aria-hidden="true" href="#cb3-60" tabindex="-1"></a>        <span className="at">xintercept =</span> year.vaccine.introduced, </span>
<span id="cb3-61"><a aria-hidden="true" href="#cb3-61" tabindex="-1"></a>        <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb3-62"><a aria-hidden="true" href="#cb3-62" tabindex="-1"></a>      </span>
<span id="cb3-63"><a aria-hidden="true" href="#cb3-63" tabindex="-1"></a>      <span className="co"># annotate with text: the year of vaccine introduction   </span></span>
<span id="cb3-64"><a aria-hidden="true" href="#cb3-64" tabindex="-1"></a>      <span className="fu">annotate</span>(</span>
<span id="cb3-65"><a aria-hidden="true" href="#cb3-65" tabindex="-1"></a>        <span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb3-66"><a aria-hidden="true" href="#cb3-66" tabindex="-1"></a>        <span className="at">x =</span> year.vaccine.introduced, </span>
<span id="cb3-67"><a aria-hidden="true" href="#cb3-67" tabindex="-1"></a>        <span className="at">y =</span> data.summary.i<span className="sc">$</span>state <span className="sc">%&gt;%</span> <span className="fu">n_distinct</span>(), </span>
<span id="cb3-68"><a aria-hidden="true" href="#cb3-68" tabindex="-1"></a>        <span className="at">label =</span> <span className="st">"Vaccine introduced"</span>, </span>
<span id="cb3-69"><a aria-hidden="true" href="#cb3-69" tabindex="-1"></a>        <span className="at">vjust =</span> <span className="sc">-</span><span className="fl">1.2</span>, <span className="co"># shift the label upward</span></span>
<span id="cb3-70"><a aria-hidden="true" href="#cb3-70" tabindex="-1"></a>        <span className="at">hjust =</span> <span className="dv">0</span>, <span className="co"># left justify</span></span>
<span id="cb3-71"><a aria-hidden="true" href="#cb3-71" tabindex="-1"></a>        <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) </span>
<span id="cb3-72"><a aria-hidden="true" href="#cb3-72" tabindex="-1"></a>  &#125;</span>
<span id="cb3-73"><a aria-hidden="true" href="#cb3-73" tabindex="-1"></a>  </span>
<span id="cb3-74"><a aria-hidden="true" href="#cb3-74" tabindex="-1"></a>  <span className="fu">return</span>(myheatmap)</span>
<span id="cb3-75"><a aria-hidden="true" href="#cb3-75" tabindex="-1"></a>&#125;</span></code></pre></div>
</div>
</section>
<section className="level3" id="create-a-heatmap-plot-for-each-disease">
<h3 className="anchored" data-anchor-id="create-a-heatmap-plot-for-each-disease">Create a heatmap plot for each disease</h3>
<p>Plot the incidences and vaccination for <strong>polio</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"POLIO"</span>, </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1954.5</span>)</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_polio.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_polio"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>mumps</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"MUMPS"</span>, </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1967.5</span>)</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_mumps.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_mumps"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>rubella</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"RUBELLA"</span>, </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1969.5</span>)</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_rubella.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_rubella"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>hepatitis A</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"HEPATITIS A"</span>, </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1995.5</span>)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_hepatitis_A.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_hepatitis_A"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>diphtheria</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"DIPHTHERIA"</span>, </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1926.5</span>) <span className="sc">+</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="co"># add manual update</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">colours =</span> myColors, <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">2000</span>, <span className="dv">500</span>))</span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_diphtheria.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_diphtheria"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>measles</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"MEASLES"</span>, </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1963.5</span>) <span className="sc">+</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="co"># update the color scale to accommodate higher case number</span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>    <span className="at">colours =</span> myColors, <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">4000</span>, <span className="dv">500</span>) )</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_measles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_measles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>smallpox</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"SMALLPOX"</span>) <span className="sc">+</span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="co"># add caption</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">caption =</span> <span className="st">"The vaccine for smallpox was introduced in 1800s."</span>) <span className="sc">+</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">0</span>))</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>p7</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_smallpox.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_smallpox"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Plot the incidences and vaccination for <strong>pertusis</strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p8 <span className="ot">&lt;-</span>  <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"PERTUSSIS"</span>) <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="co"># update the color scale </span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(<span className="at">colours =</span> myColors, </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>                       <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">800</span>, <span className="dv">100</span>)) <span className="sc">+</span></span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>  </span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>  <span className="co"># annotate the striking missing data across nearly 20 years:</span></span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, <span className="at">xmin =</span> <span className="fl">1955.5</span>, <span className="at">xmax =</span> <span className="fl">1973.5</span>, </span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>           <span className="at">ymin =</span> .<span className="dv">5</span>, <span className="at">ymax =</span> <span className="fl">51.5</span>,</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>           <span className="at">alpha =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">linewidth =</span> .<span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">1965</span>, <span className="at">y =</span> <span className="dv">30</span>, <span className="at">label =</span> <span className="st">"lost history"</span>)</span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a></span><br/>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>p8</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_pertusis.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_pertusis"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Here we combine all plots together using the <Link to="https://patchwork.data-imaginist.com/"><code>patchwork</code></Link> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>(p1 <span className="sc">|</span> p2 <span className="sc">|</span> p3 <span className="sc">|</span> p4) <span className="sc">/</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  (p5 <span className="sc">|</span> p6 <span className="sc">|</span> p7 <span className="sc">|</span> p8 ) <span className="sc">+</span></span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>  </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>  <span className="co"># and title to the combined plot</span></span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>  <span className="fu">plot_annotation</span>(</span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Impact of Vaccination Against Infectious Diseases in US"</span>, </span>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a>    <span className="at">theme =</span> <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">30</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>)))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_vaccine_function_completed_all_8_diseases"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the combined plots.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"heatmap_vaccine_all_8_diseases.pdf"</span>,</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">20</span>, <span className="at">height =</span> <span className="dv">14</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a><span className="fu">library</span>(RColorBrewer) </span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a></span><br/>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>data <span className="ot">&lt;-</span> <span className="fu">read.csv</span>(<span className="st">"/Users/boyuan/Desktop/R/gallery/DATASETS/US_disease.csv"</span>)</span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a>data <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a></span><br/>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a>data.summary <span className="ot">&lt;-</span> data <span className="sc">%&gt;%</span></span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a>  </span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a>  <span className="co"># extract years </span></span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">year =</span> <span className="fu">str_sub</span>(epi_week, <span className="at">start =</span> <span className="dv">1</span>, <span className="at">end =</span> <span className="dv">4</span>) <span className="sc">%&gt;%</span> <span className="fu">as.integer</span>()) <span className="sc">%&gt;%</span>  </span>
<span id="cb14-13"><a aria-hidden="true" href="#cb14-13" tabindex="-1"></a>  <span className="fu">select</span>(<span className="sc">-</span>epi_week) <span className="sc">%&gt;%</span> </span>
<span id="cb14-14"><a aria-hidden="true" href="#cb14-14" tabindex="-1"></a>  </span>
<span id="cb14-15"><a aria-hidden="true" href="#cb14-15" tabindex="-1"></a>  <span className="co"># sum up cases across all weeks for each disease in each year and each state </span></span>
<span id="cb14-16"><a aria-hidden="true" href="#cb14-16" tabindex="-1"></a>  <span className="co"># incidences presented as per 100 K population</span></span>
<span id="cb14-17"><a aria-hidden="true" href="#cb14-17" tabindex="-1"></a>  <span className="fu">group_by</span>(state, year, disease) <span className="sc">%&gt;%</span> </span>
<span id="cb14-18"><a aria-hidden="true" href="#cb14-18" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">n.per.100K =</span> <span className="fu">sum</span>(incidence_per_100000))</span>
<span id="cb14-19"><a aria-hidden="true" href="#cb14-19" tabindex="-1"></a></span><br/>
<span id="cb14-20"><a aria-hidden="true" href="#cb14-20" tabindex="-1"></a></span><br/>
<span id="cb14-21"><a aria-hidden="true" href="#cb14-21" tabindex="-1"></a><span className="co"># Put the colors vector in the global environment. Alternatively, it can be put inside the function.</span></span>
<span id="cb14-22"><a aria-hidden="true" href="#cb14-22" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(</span>
<span id="cb14-23"><a aria-hidden="true" href="#cb14-23" tabindex="-1"></a>  <span className="fu">c</span>(<span className="st">"grey95"</span>, <span className="st">"#d1e6f7"</span>, <span className="st">"#a6d3f1"</span>, <span className="st">"#57b7e7"</span>,<span className="st">"#1f9ad4"</span>, <span className="st">"#3aab70"</span>, <span className="st">"#e0d141"</span>, </span>
<span id="cb14-24"><a aria-hidden="true" href="#cb14-24" tabindex="-1"></a>    <span className="st">"#fdd53e"</span>, <span className="st">"#f4cd3c"</span>, <span className="st">"#feb73e"</span>, <span className="st">"orange"</span>, <span className="st">"#eb6c10"</span>,  <span className="st">"firebrick2"</span>, <span className="st">"firebrick4"</span>),</span>
<span id="cb14-25"><a aria-hidden="true" href="#cb14-25" tabindex="-1"></a>  <span className="at">bias =</span> <span className="dv">3</span>)(<span className="dv">50</span>) </span>
<span id="cb14-26"><a aria-hidden="true" href="#cb14-26" tabindex="-1"></a></span><br/>
<span id="cb14-27"><a aria-hidden="true" href="#cb14-27" tabindex="-1"></a></span><br/>
<span id="cb14-28"><a aria-hidden="true" href="#cb14-28" tabindex="-1"></a><span className="co"># Create the visualizing function</span></span>
<span id="cb14-29"><a aria-hidden="true" href="#cb14-29" tabindex="-1"></a>func.plot <span className="ot">&lt;-</span> <span className="cf">function</span>(<span className="at">which.disease =</span> <span className="st">"HEPATITIS A"</span>,</span>
<span id="cb14-30"><a aria-hidden="true" href="#cb14-30" tabindex="-1"></a>                      <span className="at">year.vaccine.introduced =</span> <span className="cn">NA</span>) &#123;</span>
<span id="cb14-31"><a aria-hidden="true" href="#cb14-31" tabindex="-1"></a>  </span>
<span id="cb14-32"><a aria-hidden="true" href="#cb14-32" tabindex="-1"></a>  <span className="co"># data subset containing the selected disease</span></span>
<span id="cb14-33"><a aria-hidden="true" href="#cb14-33" tabindex="-1"></a>  data.summary.i <span className="ot">&lt;-</span> data.summary <span className="sc">%&gt;%</span> </span>
<span id="cb14-34"><a aria-hidden="true" href="#cb14-34" tabindex="-1"></a>    <span className="fu">filter</span>(disease <span className="sc">==</span> which.disease)</span>
<span id="cb14-35"><a aria-hidden="true" href="#cb14-35" tabindex="-1"></a>  </span>
<span id="cb14-36"><a aria-hidden="true" href="#cb14-36" tabindex="-1"></a>  <span className="co"># create heatmap </span></span>
<span id="cb14-37"><a aria-hidden="true" href="#cb14-37" tabindex="-1"></a>  myheatmap <span className="ot">&lt;-</span> data.summary.i <span className="sc">%&gt;%</span> </span>
<span id="cb14-38"><a aria-hidden="true" href="#cb14-38" tabindex="-1"></a>    <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> state, <span className="at">fill =</span> n.per<span className="fl">.100</span>K)) <span className="sc">+</span></span>
<span id="cb14-39"><a aria-hidden="true" href="#cb14-39" tabindex="-1"></a>    </span>
<span id="cb14-40"><a aria-hidden="true" href="#cb14-40" tabindex="-1"></a>    <span className="co"># heatmap base</span></span>
<span id="cb14-41"><a aria-hidden="true" href="#cb14-41" tabindex="-1"></a>    <span className="fu">geom_tile</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb14-42"><a aria-hidden="true" href="#cb14-42" tabindex="-1"></a>    </span>
<span id="cb14-43"><a aria-hidden="true" href="#cb14-43" tabindex="-1"></a>    <span className="co"># x axis breaks: label with years ended with 0 and 5</span></span>
<span id="cb14-44"><a aria-hidden="true" href="#cb14-44" tabindex="-1"></a>    <span className="fu">scale_x_continuous</span>(</span>
<span id="cb14-45"><a aria-hidden="true" href="#cb14-45" tabindex="-1"></a>      <span className="at">breaks =</span> <span className="fu">seq</span>(</span>
<span id="cb14-46"><a aria-hidden="true" href="#cb14-46" tabindex="-1"></a>        <span className="at">from =</span> (<span className="fu">min</span>(data.summary.i<span className="sc">$</span>year) <span className="sc">/</span> <span className="dv">5</span>) <span className="sc">%&gt;%</span> <span className="fu">ceiling</span>() <span className="sc">*</span> <span className="dv">5</span> , </span>
<span id="cb14-47"><a aria-hidden="true" href="#cb14-47" tabindex="-1"></a>        <span className="at">to =</span> (<span className="fu">max</span>(data.summary.i<span className="sc">$</span>year) <span className="sc">/</span> <span className="dv">5</span>) <span className="sc">%&gt;%</span> <span className="fu">floor</span>() <span className="sc">*</span> <span className="dv">5</span>, </span>
<span id="cb14-48"><a aria-hidden="true" href="#cb14-48" tabindex="-1"></a>        <span className="at">by =</span> <span className="dv">5</span>)) <span className="sc">+</span> </span>
<span id="cb14-49"><a aria-hidden="true" href="#cb14-49" tabindex="-1"></a>    </span>
<span id="cb14-50"><a aria-hidden="true" href="#cb14-50" tabindex="-1"></a>    <span className="co"># color scale</span></span>
<span id="cb14-51"><a aria-hidden="true" href="#cb14-51" tabindex="-1"></a>    <span className="fu">scale_fill_gradientn</span>(</span>
<span id="cb14-52"><a aria-hidden="true" href="#cb14-52" tabindex="-1"></a>      <span className="at">colours =</span> myColors, </span>
<span id="cb14-53"><a aria-hidden="true" href="#cb14-53" tabindex="-1"></a>      <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="fu">max</span>(data.summary.i<span className="sc">$</span>n.per<span className="fl">.100</span>K), <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb14-54"><a aria-hidden="true" href="#cb14-54" tabindex="-1"></a>    </span>
<span id="cb14-55"><a aria-hidden="true" href="#cb14-55" tabindex="-1"></a>    <span className="co"># color bar customization</span></span>
<span id="cb14-56"><a aria-hidden="true" href="#cb14-56" tabindex="-1"></a>    <span className="fu">guides</span>(</span>
<span id="cb14-57"><a aria-hidden="true" href="#cb14-57" tabindex="-1"></a>      <span className="at">fill =</span> <span className="fu">guide_colorbar</span>(<span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">250</span>, <span className="st">"pt"</span>),</span>
<span id="cb14-58"><a aria-hidden="true" href="#cb14-58" tabindex="-1"></a>                            <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb14-59"><a aria-hidden="true" href="#cb14-59" tabindex="-1"></a>                            <span className="at">title =</span> <span className="cn">NULL</span>)) <span className="sc">+</span></span>
<span id="cb14-60"><a aria-hidden="true" href="#cb14-60" tabindex="-1"></a>    <span className="co"># axes labels</span></span>
<span id="cb14-61"><a aria-hidden="true" href="#cb14-61" tabindex="-1"></a>    <span className="fu">labs</span>(</span>
<span id="cb14-62"><a aria-hidden="true" href="#cb14-62" tabindex="-1"></a>      <span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>, </span>
<span id="cb14-63"><a aria-hidden="true" href="#cb14-63" tabindex="-1"></a>      <span className="at">fill =</span> <span className="st">"yearly cases </span><span className="sc">\n</span><span className="st">per 100K"</span>,</span>
<span id="cb14-64"><a aria-hidden="true" href="#cb14-64" tabindex="-1"></a>      <span className="at">title =</span> data.summary.i<span className="sc">$</span>disease <span className="sc">%&gt;%</span> <span className="fu">unique</span>(),</span>
<span id="cb14-65"><a aria-hidden="true" href="#cb14-65" tabindex="-1"></a>      <span className="at">subtitle =</span> <span className="st">"yearly cases per 100K population"</span> <span className="sc">%&gt;%</span> <span className="fu">paste</span>( <span className="st">"</span><span className="sc">\n</span><span className="st">"</span>)) <span className="sc">+</span></span>
<span id="cb14-66"><a aria-hidden="true" href="#cb14-66" tabindex="-1"></a>    </span>
<span id="cb14-67"><a aria-hidden="true" href="#cb14-67" tabindex="-1"></a>    <span className="co"># theme</span></span>
<span id="cb14-68"><a aria-hidden="true" href="#cb14-68" tabindex="-1"></a>    <span className="fu">theme_classic</span>() <span className="sc">+</span></span>
<span id="cb14-69"><a aria-hidden="true" href="#cb14-69" tabindex="-1"></a>    <span className="fu">theme</span>(</span>
<span id="cb14-70"><a aria-hidden="true" href="#cb14-70" tabindex="-1"></a>      <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb14-71"><a aria-hidden="true" href="#cb14-71" tabindex="-1"></a>      <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">20</span>),</span>
<span id="cb14-72"><a aria-hidden="true" href="#cb14-72" tabindex="-1"></a>      <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">12</span>, <span className="at">color =</span> <span className="st">"grey60"</span>),</span>
<span id="cb14-73"><a aria-hidden="true" href="#cb14-73" tabindex="-1"></a>      <span className="at">legend.position =</span> <span className="st">"bottom"</span>,</span>
<span id="cb14-74"><a aria-hidden="true" href="#cb14-74" tabindex="-1"></a>      <span className="at">plot.margin =</span> <span className="fu">unit</span>(<span className="fu">c</span>(.<span className="dv">5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>, <span className="fl">0.5</span>), <span className="st">"cm"</span>)) <span className="sc">+</span></span>
<span id="cb14-75"><a aria-hidden="true" href="#cb14-75" tabindex="-1"></a>    </span>
<span id="cb14-76"><a aria-hidden="true" href="#cb14-76" tabindex="-1"></a>    <span className="co"># expand to fill up entire plot area, </span></span>
<span id="cb14-77"><a aria-hidden="true" href="#cb14-77" tabindex="-1"></a>    <span className="co"># and clip off to display the text annotation</span></span>
<span id="cb14-78"><a aria-hidden="true" href="#cb14-78" tabindex="-1"></a>    <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>, <span className="at">clip =</span> <span className="st">"off"</span>)  </span>
<span id="cb14-79"><a aria-hidden="true" href="#cb14-79" tabindex="-1"></a>  </span>
<span id="cb14-80"><a aria-hidden="true" href="#cb14-80" tabindex="-1"></a>  <span className="co"># if data is available, annotate with the year of vaccine introduction </span></span>
<span id="cb14-81"><a aria-hidden="true" href="#cb14-81" tabindex="-1"></a>  <span className="cf">if</span> (<span className="sc">!</span> <span className="fu">is.na</span>(year.vaccine.introduced)) &#123;</span>
<span id="cb14-82"><a aria-hidden="true" href="#cb14-82" tabindex="-1"></a>    </span>
<span id="cb14-83"><a aria-hidden="true" href="#cb14-83" tabindex="-1"></a>    myheatmap <span className="ot">&lt;-</span>  myheatmap <span className="sc">+</span></span>
<span id="cb14-84"><a aria-hidden="true" href="#cb14-84" tabindex="-1"></a>      </span>
<span id="cb14-85"><a aria-hidden="true" href="#cb14-85" tabindex="-1"></a>      <span className="co"># annotate with line: the year of vaccine introduction   </span></span>
<span id="cb14-86"><a aria-hidden="true" href="#cb14-86" tabindex="-1"></a>      <span className="fu">geom_vline</span>(</span>
<span id="cb14-87"><a aria-hidden="true" href="#cb14-87" tabindex="-1"></a>        <span className="at">xintercept =</span> year.vaccine.introduced, </span>
<span id="cb14-88"><a aria-hidden="true" href="#cb14-88" tabindex="-1"></a>        <span className="at">linewidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb14-89"><a aria-hidden="true" href="#cb14-89" tabindex="-1"></a>      </span>
<span id="cb14-90"><a aria-hidden="true" href="#cb14-90" tabindex="-1"></a>      <span className="co"># annotate with text: the year of vaccine introduction   </span></span>
<span id="cb14-91"><a aria-hidden="true" href="#cb14-91" tabindex="-1"></a>      <span className="fu">annotate</span>(</span>
<span id="cb14-92"><a aria-hidden="true" href="#cb14-92" tabindex="-1"></a>        <span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb14-93"><a aria-hidden="true" href="#cb14-93" tabindex="-1"></a>        <span className="at">x =</span> year.vaccine.introduced, </span>
<span id="cb14-94"><a aria-hidden="true" href="#cb14-94" tabindex="-1"></a>        <span className="at">y =</span> data.summary.i<span className="sc">$</span>state <span className="sc">%&gt;%</span> <span className="fu">n_distinct</span>(), </span>
<span id="cb14-95"><a aria-hidden="true" href="#cb14-95" tabindex="-1"></a>        <span className="at">label =</span> <span className="st">"Vaccine introduced"</span>, </span>
<span id="cb14-96"><a aria-hidden="true" href="#cb14-96" tabindex="-1"></a>        <span className="at">vjust =</span> <span className="sc">-</span><span className="fl">1.2</span>, <span className="co"># shift the label upward</span></span>
<span id="cb14-97"><a aria-hidden="true" href="#cb14-97" tabindex="-1"></a>        <span className="at">hjust =</span> <span className="dv">0</span>, <span className="co"># left justify</span></span>
<span id="cb14-98"><a aria-hidden="true" href="#cb14-98" tabindex="-1"></a>        <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) </span>
<span id="cb14-99"><a aria-hidden="true" href="#cb14-99" tabindex="-1"></a>  &#125;</span>
<span id="cb14-100"><a aria-hidden="true" href="#cb14-100" tabindex="-1"></a>  </span>
<span id="cb14-101"><a aria-hidden="true" href="#cb14-101" tabindex="-1"></a>  <span className="fu">return</span>(myheatmap)</span>
<span id="cb14-102"><a aria-hidden="true" href="#cb14-102" tabindex="-1"></a>&#125;</span>
<span id="cb14-103"><a aria-hidden="true" href="#cb14-103" tabindex="-1"></a></span><br/>
<span id="cb14-104"><a aria-hidden="true" href="#cb14-104" tabindex="-1"></a><span className="co"># Create a heatmap plot for each disease:</span></span>
<span id="cb14-105"><a aria-hidden="true" href="#cb14-105" tabindex="-1"></a></span><br/>
<span id="cb14-106"><a aria-hidden="true" href="#cb14-106" tabindex="-1"></a><span className="co"># POLIO</span></span>
<span id="cb14-107"><a aria-hidden="true" href="#cb14-107" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"POLIO"</span>, </span>
<span id="cb14-108"><a aria-hidden="true" href="#cb14-108" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1954.5</span>)</span>
<span id="cb14-109"><a aria-hidden="true" href="#cb14-109" tabindex="-1"></a>p1</span>
<span id="cb14-110"><a aria-hidden="true" href="#cb14-110" tabindex="-1"></a></span><br/>
<span id="cb14-111"><a aria-hidden="true" href="#cb14-111" tabindex="-1"></a></span><br/>
<span id="cb14-112"><a aria-hidden="true" href="#cb14-112" tabindex="-1"></a><span className="co"># MUMPS</span></span>
<span id="cb14-113"><a aria-hidden="true" href="#cb14-113" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"MUMPS"</span>, </span>
<span id="cb14-114"><a aria-hidden="true" href="#cb14-114" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1967.5</span>)</span>
<span id="cb14-115"><a aria-hidden="true" href="#cb14-115" tabindex="-1"></a>p2</span>
<span id="cb14-116"><a aria-hidden="true" href="#cb14-116" tabindex="-1"></a></span><br/>
<span id="cb14-117"><a aria-hidden="true" href="#cb14-117" tabindex="-1"></a></span><br/>
<span id="cb14-118"><a aria-hidden="true" href="#cb14-118" tabindex="-1"></a><span className="co"># RUBELLA</span></span>
<span id="cb14-119"><a aria-hidden="true" href="#cb14-119" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"RUBELLA"</span>, </span>
<span id="cb14-120"><a aria-hidden="true" href="#cb14-120" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1969.5</span>)</span>
<span id="cb14-121"><a aria-hidden="true" href="#cb14-121" tabindex="-1"></a>p3</span>
<span id="cb14-122"><a aria-hidden="true" href="#cb14-122" tabindex="-1"></a></span><br/>
<span id="cb14-123"><a aria-hidden="true" href="#cb14-123" tabindex="-1"></a></span><br/>
<span id="cb14-124"><a aria-hidden="true" href="#cb14-124" tabindex="-1"></a><span className="co"># HEPATITIS A</span></span>
<span id="cb14-125"><a aria-hidden="true" href="#cb14-125" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"HEPATITIS A"</span>, </span>
<span id="cb14-126"><a aria-hidden="true" href="#cb14-126" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1995.5</span>)</span>
<span id="cb14-127"><a aria-hidden="true" href="#cb14-127" tabindex="-1"></a>p4</span>
<span id="cb14-128"><a aria-hidden="true" href="#cb14-128" tabindex="-1"></a></span><br/>
<span id="cb14-129"><a aria-hidden="true" href="#cb14-129" tabindex="-1"></a></span><br/>
<span id="cb14-130"><a aria-hidden="true" href="#cb14-130" tabindex="-1"></a><span className="co"># DIPHTHERIA</span></span>
<span id="cb14-131"><a aria-hidden="true" href="#cb14-131" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"DIPHTHERIA"</span>, </span>
<span id="cb14-132"><a aria-hidden="true" href="#cb14-132" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1926.5</span>) <span className="sc">+</span></span>
<span id="cb14-133"><a aria-hidden="true" href="#cb14-133" tabindex="-1"></a>  <span className="co"># add manual update</span></span>
<span id="cb14-134"><a aria-hidden="true" href="#cb14-134" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(</span>
<span id="cb14-135"><a aria-hidden="true" href="#cb14-135" tabindex="-1"></a>    <span className="at">colours =</span> myColors, <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">2000</span>, <span className="dv">500</span>))</span>
<span id="cb14-136"><a aria-hidden="true" href="#cb14-136" tabindex="-1"></a>p5</span>
<span id="cb14-137"><a aria-hidden="true" href="#cb14-137" tabindex="-1"></a></span><br/>
<span id="cb14-138"><a aria-hidden="true" href="#cb14-138" tabindex="-1"></a></span><br/>
<span id="cb14-139"><a aria-hidden="true" href="#cb14-139" tabindex="-1"></a><span className="co"># MEASLES</span></span>
<span id="cb14-140"><a aria-hidden="true" href="#cb14-140" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"MEASLES"</span>, </span>
<span id="cb14-141"><a aria-hidden="true" href="#cb14-141" tabindex="-1"></a>                <span className="at">year.vaccine.introduced =</span> <span className="fl">1963.5</span>) <span className="sc">+</span></span>
<span id="cb14-142"><a aria-hidden="true" href="#cb14-142" tabindex="-1"></a>  <span className="co"># update the color scale to accommodate higher case number</span></span>
<span id="cb14-143"><a aria-hidden="true" href="#cb14-143" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(</span>
<span id="cb14-144"><a aria-hidden="true" href="#cb14-144" tabindex="-1"></a>    <span className="at">colours =</span> myColors, <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">4000</span>, <span className="dv">500</span>) )</span>
<span id="cb14-145"><a aria-hidden="true" href="#cb14-145" tabindex="-1"></a>p6</span>
<span id="cb14-146"><a aria-hidden="true" href="#cb14-146" tabindex="-1"></a></span><br/>
<span id="cb14-147"><a aria-hidden="true" href="#cb14-147" tabindex="-1"></a></span><br/>
<span id="cb14-148"><a aria-hidden="true" href="#cb14-148" tabindex="-1"></a><span className="co"># SMALLPOX</span></span>
<span id="cb14-149"><a aria-hidden="true" href="#cb14-149" tabindex="-1"></a>p7 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"SMALLPOX"</span>) <span className="sc">+</span></span>
<span id="cb14-150"><a aria-hidden="true" href="#cb14-150" tabindex="-1"></a>  <span className="co"># add caption</span></span>
<span id="cb14-151"><a aria-hidden="true" href="#cb14-151" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">caption =</span> <span className="st">"The vaccine for smallpox was introduced in 1800s."</span>) <span className="sc">+</span></span>
<span id="cb14-152"><a aria-hidden="true" href="#cb14-152" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.caption =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> <span className="dv">0</span>))</span>
<span id="cb14-153"><a aria-hidden="true" href="#cb14-153" tabindex="-1"></a>p7</span>
<span id="cb14-154"><a aria-hidden="true" href="#cb14-154" tabindex="-1"></a></span><br/>
<span id="cb14-155"><a aria-hidden="true" href="#cb14-155" tabindex="-1"></a></span><br/>
<span id="cb14-156"><a aria-hidden="true" href="#cb14-156" tabindex="-1"></a><span className="co"># PERTUSSIS</span></span>
<span id="cb14-157"><a aria-hidden="true" href="#cb14-157" tabindex="-1"></a>p8 <span className="ot">&lt;-</span> <span className="fu">func.plot</span>(<span className="at">which.disease =</span> <span className="st">"PERTUSSIS"</span>) <span className="sc">+</span> </span>
<span id="cb14-158"><a aria-hidden="true" href="#cb14-158" tabindex="-1"></a>  <span className="co"># update the color scale </span></span>
<span id="cb14-159"><a aria-hidden="true" href="#cb14-159" tabindex="-1"></a>  <span className="fu">scale_fill_gradientn</span>(<span className="at">colours =</span> myColors, </span>
<span id="cb14-160"><a aria-hidden="true" href="#cb14-160" tabindex="-1"></a>                       <span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">800</span>, <span className="dv">100</span>)) <span className="sc">+</span></span>
<span id="cb14-161"><a aria-hidden="true" href="#cb14-161" tabindex="-1"></a>  </span>
<span id="cb14-162"><a aria-hidden="true" href="#cb14-162" tabindex="-1"></a>  <span className="co"># annotate the striking missing data across nearly 20 years:</span></span>
<span id="cb14-163"><a aria-hidden="true" href="#cb14-163" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"rect"</span>, <span className="at">xmin =</span> <span className="fl">1955.5</span>, <span className="at">xmax =</span> <span className="fl">1973.5</span>, </span>
<span id="cb14-164"><a aria-hidden="true" href="#cb14-164" tabindex="-1"></a>           <span className="at">ymin =</span> .<span className="dv">5</span>, <span className="at">ymax =</span> <span className="fl">51.5</span>,</span>
<span id="cb14-165"><a aria-hidden="true" href="#cb14-165" tabindex="-1"></a>           <span className="at">alpha =</span> <span className="dv">0</span>, <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">linewidth =</span> .<span className="dv">3</span>) <span className="sc">+</span></span>
<span id="cb14-166"><a aria-hidden="true" href="#cb14-166" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">1965</span>, <span className="at">y =</span> <span className="dv">30</span>, <span className="at">label =</span> <span className="st">"lost history"</span>)</span>
<span id="cb14-167"><a aria-hidden="true" href="#cb14-167" tabindex="-1"></a>p8</span>
<span id="cb14-168"><a aria-hidden="true" href="#cb14-168" tabindex="-1"></a></span><br/>
<span id="cb14-169"><a aria-hidden="true" href="#cb14-169" tabindex="-1"></a></span><br/>
<span id="cb14-170"><a aria-hidden="true" href="#cb14-170" tabindex="-1"></a></span><br/>
<span id="cb14-171"><a aria-hidden="true" href="#cb14-171" tabindex="-1"></a><span className="co"># combine all plots together</span></span>
<span id="cb14-172"><a aria-hidden="true" href="#cb14-172" tabindex="-1"></a><span className="fu">library</span>(patchwork)</span>
<span id="cb14-173"><a aria-hidden="true" href="#cb14-173" tabindex="-1"></a></span><br/>
<span id="cb14-174"><a aria-hidden="true" href="#cb14-174" tabindex="-1"></a>(p1 <span className="sc">|</span> p2 <span className="sc">|</span> p3 <span className="sc">|</span> p4) <span className="sc">/</span></span>
<span id="cb14-175"><a aria-hidden="true" href="#cb14-175" tabindex="-1"></a>  (p5 <span className="sc">|</span> p6 <span className="sc">|</span> p7 <span className="sc">|</span> p8 ) <span className="sc">+</span></span>
<span id="cb14-176"><a aria-hidden="true" href="#cb14-176" tabindex="-1"></a>  </span>
<span id="cb14-177"><a aria-hidden="true" href="#cb14-177" tabindex="-1"></a>  <span className="co"># and title to the combined plot</span></span>
<span id="cb14-178"><a aria-hidden="true" href="#cb14-178" tabindex="-1"></a>  <span className="fu">plot_annotation</span>(</span>
<span id="cb14-179"><a aria-hidden="true" href="#cb14-179" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Impact of Vaccination Against Infectious Diseases in US"</span>, </span>
<span id="cb14-180"><a aria-hidden="true" href="#cb14-180" tabindex="-1"></a>    <span className="at">theme =</span> <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">30</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>))) </span>
<span id="cb14-181"><a aria-hidden="true" href="#cb14-181" tabindex="-1"></a></span><br/>
<span id="cb14-182"><a aria-hidden="true" href="#cb14-182" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"heatmap_vaccine_all_8_diseases.png"</span>,</span>
<span id="cb14-183"><a aria-hidden="true" href="#cb14-183" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb14-184"><a aria-hidden="true" href="#cb14-184" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">20</span>, <span className="at">height =</span> <span className="dv">14</span>, <span className="at">dpi =</span> <span className="dv">300</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out the following <Link to="../ggplot2-heatmap-sunspot-activities"><strong>heatmap with synchornized lineplot</strong></Link> that visualize the sunspot activities in the past two hundred years.</p>
<p><Link to="../ggplot2-heatmap-sunspot-activities">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>And check out this <Link to="../ggplot2-heatmap-African-population"><strong>amazing heatmap visualizing the population distribution in Africa</strong></Link>. In particular, it leverages magical mathematical transformations in the color scale (with a simple line of code) to unveil the underlying highly skewed data pattern.</p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>The above heatmaps are all created using the generic function <code>geom_tile()</code> and <code>geom_raster()</code>. Alternatively, a heatmap can be created using <code>geom_bin2d()</code> in the form of a 2D histogram. Check out the following <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a map overlap</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}