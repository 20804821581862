import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrC(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Combine Multiple Vectors into a Single Vector</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_c()</code></strong> combines (concatenates) multiple character vectors into a single character vector. Elements from each vector in the correspondingly same positions are pieced together.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"waffle"</span>, <span className="st">"butter"</span>, <span className="st">"syrup"</span>)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>y <span className="ot">&lt;-</span> <span className="fu">c</span>(    <span className="st">"x3"</span>,     <span className="st">"x1"</span>,    <span className="st">"x2"</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>z <span className="ot">&lt;-</span> <span className="fu">c</span>(    <span className="st">"$2"</span>,     <span className="st">"$4"</span>,    <span className="st">"$8"</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">str_c</span>(x, y)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "wafflex3" "butterx1" "syrupx2" </code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_c</span>(x, y, z)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "wafflex3$2" "butterx1$4" "syrupx2$8" </code></pre>
</div>
</div>
<p>Use <code>sep</code> to specify a separator between each string piece.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_c</span>(x, y, z, <span className="at">sep =</span> <span className="st">", "</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "waffle, x3, $2" "butter, x1, $4" "syrup, x2, $8" </code></pre>
</div>
</div>
<p>Here the single length vector <code>in Walmart</code> is <em>recycled</em> three times to combine with elements in <code>x</code>, <code>y</code> and <code>z</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_c</span>(x, y, z, <span className="st">"in Walmart"</span>, <span className="at">sep =</span> <span className="st">", "</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "waffle, x3, $2, in Walmart" "butter, x1, $4, in Walmart" "syrup, x2, $8, in Walmart" </code></pre>
</div>
</div>
<p>The argument <code>collapse</code> defaults to <code>NULL</code>. If otherwise specified, the resulted vector will be flattened into a vector of length 1, as performed by <Link to="../15-str-flatten"><code>str_flatten()</code></Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_c</span>(x, y, z, <span className="at">sep =</span> <span className="st">", "</span> , <span className="at">collapse =</span> <span className="st">" - "</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "waffle, x3, $2 - butter, x1, $4 - syrup, x2, $8"</code></pre>
</div>
</div>
<h4 className="anchored">
🏆 <strong>One level up !</strong>
</h4>
<p><code>str_c()</code> is similar to the base R function <code>paste0()</code>, but differs in two ways. The first difference lies in the handling of missing value <code>NA</code>. In <code>paste0()</code>, <code>NA</code> combines as a normal string. In <code>str_c()</code>, <code>NA</code> does not combine with any string, and is returned as a missing value; this can cause loss of information during string combination.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># NA combines as a normal string</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">paste0</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="cn">NA</span>, <span className="st">"b"</span>), <span className="st">"c"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "ac"  "NAc" "bc" </code></pre>
</div>
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="co"># NA not combine with any string </span></span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a><span className="fu">str_c</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="cn">NA</span>, <span className="st">"b"</span>), <span className="st">"c"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "ac" NA   "bc"</code></pre>
</div>
</div>
<p>To avoid loss of information, consider use <Link to="../13-str-replace"><code>str_replace_NA()</code></Link> to turn <code>NA</code> into a <code>"NA"</code> character string, before string combination.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a><span className="fu">str_c</span>( <span className="fu">str_replace_na</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="cn">NA</span>, <span className="st">"b"</span>)), <span className="st">"c"</span> )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "ac"  "NAc" "bc" </code></pre>
</div>
</div>
<p>The second difference between <code>paste0()</code> and <code>str_c()</code> lies in the extent by which a shorter vector is recycled (repeated) to match the length of a longer vector during combination.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb17"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb17-1"><a aria-hidden="true" href="#cb17-1" tabindex="-1"></a><span className="co"># letters "a" and "b" recycles to match numbers 1 to 4</span></span>
<span id="cb17-2"><a aria-hidden="true" href="#cb17-2" tabindex="-1"></a><span className="fu">paste0</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>), <span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "a1" "b2" "a3" "b4"</code></pre>
</div>
</div>
<p>In the example of <code>str_c()</code> below, while “a” can be recycled to match numbers 1 to 4, attempt to combine with <code>c("a", "b")</code> will induce an error.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a><span className="fu">str_c</span>(<span className="st">"a"</span>, <span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "a1" "a2" "a3" "a4"</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb21"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb21-1"><a aria-hidden="true" href="#cb21-1" tabindex="-1"></a><span className="co"># not run; this line will cause an error</span></span>
<span id="cb21-2"><a aria-hidden="true" href="#cb21-2" tabindex="-1"></a><span className="fu">str_c</span>(<span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>), <span className="dv">1</span><span className="sc">:</span><span className="dv">4</span>)</span></code></pre></div>
</div>
</main>
</div>
</div>
)}