import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonLoopcontrolstatements(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Loop-Control-Statements">Loop Control Statements<a class="anchor-link" href="#Loop-Control-Statements">¶</a></h3><p>In the previous articles, we've explored both the <Link to='../python-for-loop'><code>for</code></Link> loop and the <Link to='../python-while-loop'><code>while</code></Link> loop, understanding how they iterate through code. In this article, we'll learn loop control statements which allow us to tailor the behavior of loops to suit our specific needs.</p>
<p>There are three main loop control statements in Python: <strong>break</strong>, <strong>continue</strong>, and <strong>pass</strong>.</p>
<p><strong>break</strong>: Terminates the loop prematurely.</p>
<p><strong>continue</strong>: Skips the rest of the current iteration and continues with the next one.</p>
<p><strong>pass</strong>: The pass statement is used as a placeholder in Python to avoid syntactical errors.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Using-break-to-Exit-a-Loop">Using <code>break</code> to Exit a Loop<a class="anchor-link" href="#Using-break-to-Exit-a-Loop">¶</a></h4><p>Use <code>break</code> when you want to exit a loop prematurely based on a certain condition.</p>
<p><strong>Example</strong></p>
<p>You are searching for a specific item in a list, and once you find it, you can exit the loop immediately.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a list</span></span>

<span><span class="n">numbers</span> <span class="o">=</span> <span class="p">[</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">,</span> <span class="mi">4</span><span class="p">,</span> <span class="mi">5</span><span class="p">,</span> <span class="mi">6</span><span class="p">]</span></span>


<br /><span><span class="c1"># For loop</span></span>

<span><span class="k">for</span> <span class="n">num</span> <span class="ow">in</span> <span class="n">numbers</span><span class="p">:</span></span>

<span>    <span class="c1"># conditional statements</span></span>

<span>    <span class="k">if</span> <span class="n">num</span> <span class="o">==</span> <span class="mi">4</span><span class="p">:</span></span>

<span>        <span class="c1"># If num equals 4, this "break" statement gets executed </span></span>

<span>        <span class="k">break</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="n">num</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>1
<br />2
<br />3
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>In this example, we used an <code>if</code> statement to search for the number 4 within the list. As the loop iterates through the numbers, the <code>break</code> statement is used to exit the loop immediately upon finding the number 4. Consequently, only the numbers 1, 2, and 3 are printed before the <code>break</code> statement exits the loop. Early exit from a loop is an optimization strategy that saves computational resources and reduces the loop's execution time.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Using-continue-to-Skip-an-Iteration">Using <code>continue</code> to Skip an Iteration<a class="anchor-link" href="#Using-continue-to-Skip-an-Iteration">¶</a></h4><p>Use <code>continue</code> when you want to skip the rest of the current iteration and move to the next iteration of the loop.</p>
<p><strong>Example</strong></p>
<p>You are processing a list of numbers, and you only want to print odd numbers and skip even numebrs.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a list</span></span>

<span><span class="n">numbers</span> <span class="o">=</span> <span class="p">[</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">,</span> <span class="mi">4</span><span class="p">,</span> <span class="mi">5</span><span class="p">,</span> <span class="mi">6</span><span class="p">]</span></span>


<br /><span><span class="c1"># For loop</span></span>

<span><span class="k">for</span> <span class="n">num</span> <span class="ow">in</span> <span class="n">numbers</span><span class="p">:</span></span>

<span>    <span class="c1"># conditional statements</span></span>

<span>    <span class="k">if</span> <span class="n">num</span> <span class="o">%</span> <span class="mi">2</span> <span class="o">==</span> <span class="mi">0</span><span class="p">:</span></span>

<span>        <span class="c1"># If num is even, the "continue" statement executes to skip the remaining code </span></span>

<span>        <span class="c1"># and proceed to the next iteration</span></span>

<span>        <span class="k">continue</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="n">num</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>1
<br />3
<br />5
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>In this example, we utilize a <code>for</code> loop to iterate through a list of numbers. We employ an <code>if</code> statement with the condition <code>num % 2 == 0</code> to identify even numbers. When <code>num</code> is even, the <code>continue</code> statement skips the remaining code, here it's the <code>print(num)</code>. It allows the loop to advance to the next iteration. Conversely, when <code>num</code> is odd and fails to meet the <code>if</code> condition, <code>print(num)</code> executes to print odd numbers.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Use-pass-as-a-Placeholder">Use <code>pass</code> as a Placeholder<a class="anchor-link" href="#Use-pass-as-a-Placeholder">¶</a></h4><p>Use <code>pass</code> as a placeholder if you need a loop structure with no immediate code inside, either for temporary holding or future implementation.</p>
<p>The <code>pass</code> statement isn't limited to loops; it's also applicable in <Link to='../function-definition-and-parameters'>functions</Link>, <Link to='../python-classes-and-objects'>classes</Link>, and other structures where a syntactical placeholder is required.</p>
<p><strong>Example</strong></p>
<p>Defining a loop structure intended for later development.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a list</span></span>

<span><span class="n">numbers</span> <span class="o">=</span> <span class="p">[</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">,</span> <span class="mi">4</span><span class="p">,</span> <span class="mi">5</span><span class="p">,</span> <span class="mi">6</span><span class="p">]</span></span>


<br /><span><span class="c1"># For loop</span></span>

<span><span class="k">for</span> <span class="n">item</span> <span class="ow">in</span> <span class="n">numbers</span><span class="p">:</span></span>

<span>    <span class="c1"># Placeholder for future code</span></span>

<span>    <span class="k">pass</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This code iterates through the <code>numbers</code> list, but it doesn't contain any meaningful code within the loop. To prevent Python from raising an error due to an empty loop body, the <code>pass</code> statement is used as a placeholder, allowing the loop to remain syntactically valid.</p>
</div>
</div>
</div>
</div>
</div>
)}