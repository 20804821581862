import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2MapFijiEarthquakes from '../graphics/map_fiji_earthquakes_theme_completed.png'; 
import imgGgplot2MapFijiEarthquakesWebp from '../graphics/map_fiji_earthquakes_theme_completed.webp'; 
export default function Ggplot2MapFijiEarthquakes(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Fiji Earthquakes with Map and Scatterplots in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><img className="cover-img" src={imgGgplot2MapFijiEarthquakes} /> In this work, we’ll create a map of the Oceania and Fiji islands region, and use scatterplot to illustrate the earthquake sites and relative activities in Fiji. <strong>Major techniques covered in this work include:</strong></p>
<ul>
<li><a href="#map">Draw a map.</a></li>
<li><a href="#annotation">Make text and arrow annotations.</a></li>
<li><a href="#scatterplot">Create a scatterplot on the map.</a></li>
</ul>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>w <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">head</span>(w, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 6
<br/>   long   lat group order region subregion
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;int&gt; &lt;chr&gt;  &lt;chr&gt;    
<br/>1 -69.9  12.5     1     1 Aruba  &lt;NA&gt;     
<br/>2 -69.9  12.4     1     2 Aruba  &lt;NA&gt;     
<br/>3 -69.9  12.4     1     3 Aruba  &lt;NA&gt;     
<br/>4 -70.0  12.5     1     4 Aruba  &lt;NA&gt;     </code></pre>
</div>
</div>
<p><span id="map">Create a world map, with zoom-in over the Australia - Fiji region.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> w <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(long, lat)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>               <span className="at">fill =</span> <span className="st">"yellow1"</span>, <span className="at">color =</span> <span className="st">"yellow4"</span>, <span className="at">alpha =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="fl">1.1</span>, </span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>              <span className="at">xlim =</span> <span className="fu">c</span>(<span className="dv">130</span>,<span className="dv">192</span>),</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>              <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">50</span>, <span className="dv">0</span>))</span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_fiji_earthquakes_base.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_fiji_earthquakes_base"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>In addition to <code>coord_fixed()</code>, we can use <code>coord_map()</code> to better preserve the shape of geographical features. This function though takes longer time to render the map.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># The package `mapproj` is required for `coord_map()`</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a><span className="co"># install.packages("mapproj")</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>p1 <span className="sc">+</span> <span className="fu">coord_map</span>( <span className="at">xlim =</span> <span className="fu">c</span>(<span className="dv">130</span>,<span className="dv">192</span>),</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>                <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">50</span>, <span className="dv">0</span>))</span></code></pre></div>
</div>
<p><span id="map">Highlight the Fiji islands, with text and arrow annotation.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="co"># redraw the Fiji islands in the map with blue highlight</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="at">data =</span> w <span className="sc">%&gt;%</span> <span className="fu">filter</span>(region <span className="sc">==</span> <span className="st">"Fiji"</span>), </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>    <span className="at">fill =</span> <span className="st">"blue"</span>) <span className="sc">+</span></span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  </span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="co"># add text annotation</span></span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">179</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">7</span>, <span className="at">label =</span> <span className="st">"Fiji"</span>,</span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">8</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"blue"</span>) <span className="sc">+</span></span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>  </span>
<span id="cb5-13"><a aria-hidden="true" href="#cb5-13" tabindex="-1"></a>  <span className="co"># add arrow</span></span>
<span id="cb5-14"><a aria-hidden="true" href="#cb5-14" tabindex="-1"></a>  <span className="fu">geom_segment</span>(</span>
<span id="cb5-15"><a aria-hidden="true" href="#cb5-15" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">179</span>, <span className="at">xend =</span> <span className="dv">179</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">11</span>, <span className="at">yend =</span> <span className="sc">-</span><span className="dv">15</span>),</span>
<span id="cb5-16"><a aria-hidden="true" href="#cb5-16" tabindex="-1"></a>    <span className="at">arrow =</span> <span className="fu">arrow</span>(<span className="at">type =</span> <span className="st">"closed"</span>, <span className="at">length =</span> <span className="fu">unit</span>(<span className="dv">6</span>, <span className="st">"pt"</span>) )) </span>
<span id="cb5-17"><a aria-hidden="true" href="#cb5-17" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_fiji_earthquakes_arrow_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_fiji_earthquakes_arrow_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="scatterplot">Add the earthquake data using the R built-in dataset <code>quakes</code>. Both datasets <code>w</code> and <code>quakes</code> have the <code>long</code> and <code>lat</code> variables, which are mapped to the axial aesthetics.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">data =</span> quakes, </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>             <span className="at">alpha =</span> .<span className="dv">05</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="at">color =</span> <span className="st">"tomato"</span>) </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_fiji_earthquakes_earthquake_scatterplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_fiji_earthquakes_earthquake_scatterplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="annotation">Add text annotation in Australia and New Zealand, and add plot title.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">138</span>, <span className="dv">180</span>), </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">25</span>, <span className="sc">-</span><span className="dv">45</span>), </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"Australia"</span>, <span className="st">"New</span><span className="sc">\n</span><span className="st">Zealand"</span>),</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">7</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>           <span className="at">color =</span> <span className="st">"yellow4"</span>, <span className="at">alpha =</span> .<span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>, <span className="co"># remove axis titles</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Earthquakes in Fiji since 1964"</span>) </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_fiji_earthquakes_country_annotation.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_fiji_earthquakes_country_annotation"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A brief touch on the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> element_text</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>        (<span className="at">hjust =</span> .<span className="dv">9</span>, <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">3</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">face =</span> <span className="st">"bold.italic"</span>)) </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_fiji_earthquakes_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/map_fiji_earthquakes_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>w <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>) </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a></span><br/>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a><span className="co"># Create a world map, with zoom-in over the Australia - Fiji region</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> w <span className="sc">%&gt;%</span> </span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(long, lat)) <span className="sc">+</span></span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>               <span className="at">fill =</span> <span className="st">"yellow1"</span>, <span className="at">color =</span> <span className="st">"yellow4"</span>, <span className="at">alpha =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="fl">1.1</span>, </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>              <span className="at">xlim =</span> <span className="fu">c</span>(<span className="dv">130</span>,<span className="dv">192</span>),</span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>              <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">50</span>, <span className="dv">0</span>))</span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>p1</span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a></span><br/>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a><span className="co"># Alternatively, we can use `coord_map` to determine the aspect ratio. </span></span>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a><span className="co"># This function though takes longer time to render the map. </span></span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a><span className="co"># The package `mapproj` is required for `coord_map()`</span></span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a><span className="co"># install.packages("mapproj")</span></span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a>p1 <span className="sc">+</span> <span className="fu">coord_map</span>( <span className="at">xlim =</span> <span className="fu">c</span>(<span className="dv">130</span>,<span className="dv">192</span>), <span className="at">ylim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">50</span>, <span className="dv">0</span>))</span>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a></span><br/>
<span id="cb9-21"><a aria-hidden="true" href="#cb9-21" tabindex="-1"></a></span><br/>
<span id="cb9-22"><a aria-hidden="true" href="#cb9-22" tabindex="-1"></a><span className="co"># Highlight the Fiji islands, with text and arrow annotation.</span></span>
<span id="cb9-23"><a aria-hidden="true" href="#cb9-23" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span></span>
<span id="cb9-24"><a aria-hidden="true" href="#cb9-24" tabindex="-1"></a>  <span className="co"># redraw the Fiji islands in the map with blue highlight</span></span>
<span id="cb9-25"><a aria-hidden="true" href="#cb9-25" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(</span>
<span id="cb9-26"><a aria-hidden="true" href="#cb9-26" tabindex="-1"></a>    <span className="at">data =</span> w <span className="sc">%&gt;%</span> <span className="fu">filter</span>(region <span className="sc">==</span> <span className="st">"Fiji"</span>), </span>
<span id="cb9-27"><a aria-hidden="true" href="#cb9-27" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb9-28"><a aria-hidden="true" href="#cb9-28" tabindex="-1"></a>    <span className="at">fill =</span> <span className="st">"blue"</span>) <span className="sc">+</span></span>
<span id="cb9-29"><a aria-hidden="true" href="#cb9-29" tabindex="-1"></a>  </span>
<span id="cb9-30"><a aria-hidden="true" href="#cb9-30" tabindex="-1"></a>  <span className="co"># add text annotation</span></span>
<span id="cb9-31"><a aria-hidden="true" href="#cb9-31" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb9-32"><a aria-hidden="true" href="#cb9-32" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>, <span className="at">x =</span> <span className="dv">179</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">7</span>, <span className="at">label =</span> <span className="st">"Fiji"</span>,</span>
<span id="cb9-33"><a aria-hidden="true" href="#cb9-33" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">8</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">color =</span> <span className="st">"blue"</span>) <span className="sc">+</span></span>
<span id="cb9-34"><a aria-hidden="true" href="#cb9-34" tabindex="-1"></a>  </span>
<span id="cb9-35"><a aria-hidden="true" href="#cb9-35" tabindex="-1"></a>  <span className="co"># add arrow</span></span>
<span id="cb9-36"><a aria-hidden="true" href="#cb9-36" tabindex="-1"></a>  <span className="fu">geom_segment</span>(</span>
<span id="cb9-37"><a aria-hidden="true" href="#cb9-37" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">x =</span> <span className="dv">179</span>, <span className="at">xend =</span> <span className="dv">179</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">11</span>, <span className="at">yend =</span> <span className="sc">-</span><span className="dv">15</span>),</span>
<span id="cb9-38"><a aria-hidden="true" href="#cb9-38" tabindex="-1"></a>    <span className="at">arrow =</span> <span className="fu">arrow</span>(<span className="at">type =</span> <span className="st">"closed"</span>, <span className="at">length =</span> <span className="fu">unit</span>(<span className="dv">6</span>, <span className="st">"pt"</span>) )) </span>
<span id="cb9-39"><a aria-hidden="true" href="#cb9-39" tabindex="-1"></a>p2</span>
<span id="cb9-40"><a aria-hidden="true" href="#cb9-40" tabindex="-1"></a></span><br/>
<span id="cb9-41"><a aria-hidden="true" href="#cb9-41" tabindex="-1"></a></span><br/>
<span id="cb9-42"><a aria-hidden="true" href="#cb9-42" tabindex="-1"></a><span className="co"># Add the earthquake data using the R built-in dataset `quakes`. </span></span>
<span id="cb9-43"><a aria-hidden="true" href="#cb9-43" tabindex="-1"></a><span className="co"># The `long` and `lat` variables in both datasets are used in the aesthetic mapping. </span></span>
<span id="cb9-44"><a aria-hidden="true" href="#cb9-44" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb9-45"><a aria-hidden="true" href="#cb9-45" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">data =</span> quakes, </span>
<span id="cb9-46"><a aria-hidden="true" href="#cb9-46" tabindex="-1"></a>             <span className="at">alpha =</span> .<span className="dv">05</span>, <span className="at">size =</span> <span className="dv">3</span>, <span className="at">color =</span> <span className="st">"tomato"</span>) </span>
<span id="cb9-47"><a aria-hidden="true" href="#cb9-47" tabindex="-1"></a>p3</span>
<span id="cb9-48"><a aria-hidden="true" href="#cb9-48" tabindex="-1"></a></span><br/>
<span id="cb9-49"><a aria-hidden="true" href="#cb9-49" tabindex="-1"></a></span><br/>
<span id="cb9-50"><a aria-hidden="true" href="#cb9-50" tabindex="-1"></a><span className="co"># Add text annotation to Australia and New Zealand, and add plot title. </span></span>
<span id="cb9-51"><a aria-hidden="true" href="#cb9-51" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb9-52"><a aria-hidden="true" href="#cb9-52" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb9-53"><a aria-hidden="true" href="#cb9-53" tabindex="-1"></a>           <span className="at">x =</span> <span className="fu">c</span>(<span className="dv">138</span>, <span className="dv">180</span>), </span>
<span id="cb9-54"><a aria-hidden="true" href="#cb9-54" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">25</span>, <span className="sc">-</span><span className="dv">45</span>), </span>
<span id="cb9-55"><a aria-hidden="true" href="#cb9-55" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"Australia"</span>, <span className="st">"New</span><span className="sc">\n</span><span className="st">Zealand"</span>),</span>
<span id="cb9-56"><a aria-hidden="true" href="#cb9-56" tabindex="-1"></a>           <span className="at">size =</span> <span className="dv">7</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb9-57"><a aria-hidden="true" href="#cb9-57" tabindex="-1"></a>           <span className="at">color =</span> <span className="st">"yellow4"</span>, <span className="at">alpha =</span> .<span className="dv">4</span>) <span className="sc">+</span></span>
<span id="cb9-58"><a aria-hidden="true" href="#cb9-58" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>, <span className="at">y =</span> <span className="cn">NULL</span>, <span className="co"># remove axis titles</span></span>
<span id="cb9-59"><a aria-hidden="true" href="#cb9-59" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Earthquakes in Fiji since 1964"</span>) </span>
<span id="cb9-60"><a aria-hidden="true" href="#cb9-60" tabindex="-1"></a>p4</span>
<span id="cb9-61"><a aria-hidden="true" href="#cb9-61" tabindex="-1"></a></span><br/>
<span id="cb9-62"><a aria-hidden="true" href="#cb9-62" tabindex="-1"></a></span><br/>
<span id="cb9-63"><a aria-hidden="true" href="#cb9-63" tabindex="-1"></a><span className="co"># A brief touch on the theme.</span></span>
<span id="cb9-64"><a aria-hidden="true" href="#cb9-64" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-65"><a aria-hidden="true" href="#cb9-65" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb9-66"><a aria-hidden="true" href="#cb9-66" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> element_text</span>
<span id="cb9-67"><a aria-hidden="true" href="#cb9-67" tabindex="-1"></a>        (<span className="at">hjust =</span> .<span className="dv">9</span>, <span className="at">vjust =</span> <span className="sc">-</span><span className="dv">3</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">face =</span> <span className="st">"bold.italic"</span>)) </span>
<span id="cb9-68"><a aria-hidden="true" href="#cb9-68" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>The above graphic combines a geographic map with a scatterplot to illustrate earthquake occurrences. The same technique can be readily extended to visualize the <Link to="../ggplot2-world-map-cities-populations"><strong>distribution of cities of different population sizes</strong></Link>.</p>
<p><Link to="../ggplot2-world-map-cities-populations">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to scatterplots, a map is often integrated with line plots to create powerful data visualizations. Check out the following article that visualizes <Link to="../ggplot2-map-airline"><strong>global flights and airports</strong></Link> as illustrated below. And also check how to <Link to="../ggplot2-map-airline-animation"><strong>turn static flight lines into animation</strong></Link>.</p>
<p><Link to="../ggplot2-map-airline">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out how to <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>integrate geographic map with 2D histogram</strong></Link> to visualize the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}