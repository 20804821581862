export function loadStylesheet(type, dataOrigin) {
        const content = `
        // pre > code.sourceCode { white-space: pre-wrap; position: relative; letter-spacing:0.1px;}
        pre > code.sourceCode {position: relative; letter-spacing:0.1px;}
        pre > code.sourceCode > span { display: block; line-height: 1.4;}
        pre > code.sourceCode > span:empty { height: 1.2em; }
        .sourceCode { overflow: auto; }
        code.sourceCode > span { color: inherit; text-decoration: inherit; }
        div.sourceCode { margin: 1em 0; }
        pre.sourceCode { margin: 0; padding:15px 15px;}
        @media screen {
        div.sourceCode { overflow: auto; }
        }
        @media print {
        pre > code.sourceCode { white-space: pre-wrap; }
        pre > code.sourceCode > span { text-indent: -5em; padding-left: 5em; }
        }
        pre.numberSource code
          { counter-reset: source-line 0; }
        pre.numberSource code > span
          { position: relative; left: -4em; counter-increment: source-line; }
        pre.numberSource code > span > a:first-child::before
          { content: counter(source-line);
            position: relative; left: -1em; text-align: right; vertical-align: baseline;
            border: none; display: inline-block;
            -webkit-touch-callout: none; -webkit-user-select: none;
            -khtml-user-select: none; -moz-user-select: none;
            -ms-user-select: none; user-select: none;
            padding: 0 4px; width: 4em;
          }
        pre.numberSource { margin-left: 3em;  padding-left: 4px; }
        div.sourceCode
          { color: #cccccc; background-color: rgb(0 0 0); }
        @media screen {
        pre > code.sourceCode > span > a:first-child::before { text-decoration: underline; }
        }
        code span.al { color: #ffcfaf; } /* Alert */
        code span.an { color: #7f9f7f; font-weight: bold; } /* Annotation */
        code span.at { } /* Attribute */
        code span.bn { color: #dca3a3; } /* BaseN */
        code span.bu { } /* BuiltIn */
        code span.cf { color: #be77ff; } /* ControlFlow */
        code span.ch { color: #dca3a3; } /* Char */
        code span.cn { color: #dca3a3; font-weight: bold; } /* Constant */
        code span.co { color: #89DDFF; font-style:italic;} /* Comment */
        code span.cv { color: #7f9f7f; font-weight: bold; } /* CommentVar */
        code span.do { color: #7f9f7f; } /* Documentation */
        code span.dt { color: #dfdfbf; } /* DataType */
        code span.dv { color: #dcdccc; } /* DecVal */
        code span.er { color: #c3bf9f; } /* Error */
        code span.ex { } /* Extension */
        code span.fl { color: #c0bed1; } /* Float */
        code span.fu { color: #C3E88D; } /* Function */
        code span.im { } /* Import */
        code span.in { color: #7f9f7f; font-weight: bold; } /* Information */
        code span.kw { color: #f0dfaf; } /* Keyword */
        code span.op { color: #f0efd0; } /* Operator */
        code span.ot { color: #efef8f; } /* Other */
        code span.pp { color: #ffcfaf; font-weight: bold; } /* Preprocessor */
        code span.sc { color: #89DDFF; } /* SpecialChar */
        code span.ss { color: #cc9393; } /* SpecialString */
        code span.st { color: #F78C6C; } /* String */
        code span.va { } /* Variable */
        code span.vs { color: #cc9393; } /* VerbatimString */
        code span.wa { color: #7f9f7f; font-weight: bold; } /* Warning */`

        const style = document.createElement('style');
        style.setAttribute('type', type);
        style.setAttribute('data-origin', dataOrigin);
        style.innerHTML = content
        
        // Append the style element to the <head> of the document
        document.head.appendChild(style);

}

export function changeStyle() {
    var sheets = document.styleSheets;
    for (var i = 0; i < sheets.length; i++) {
        if (sheets[i].ownerNode.dataset["origin"] !== "pandoc"){
            continue;
        } else {
            try {
                var rules = sheets[i].cssRules;
            } catch (e){
                continue;
            }
        }
        var j = 0;
        while (j < rules.length) {
            var rule = rules[j];
            // check if there is a div.sourceCode rule
            if (rule.type !== rule.STYLE_RULE || rule.selectorText !== "div.sourceCode") {
                j++;
                continue;
            } else {
                var style = rule.style.cssText;
                if (rule.style.color === '' && rule.style.backgroundColor === '') {
                    j++;
                    continue
                } else {
                    // replace div.sourceCode by a pre.sourceCode rule
                    sheets[i].deleteRule(j);
                    sheets[i].insertRule('pre.sourceCode{' + style + '}', j);
                }
            }
        }

    }
}

export function addCssClass() {
    var h_tags = document.querySelectorAll('h1, h2, h3:not(div.nav-title > *)');
    h_tags.forEach(heading=> {
        heading.classList.add('posts-heading')
    })

    var h_tags = document.querySelectorAll('h4');
    h_tags.forEach(heading => {
        heading.setAttribute('class', 'sub-title');
    });

    var strong_tags = document.querySelectorAll('strong');
    strong_tags.forEach(tag=> {
        tag.setAttribute('class', 'blue')
    })
}

