import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Summarize(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create a Summarizing Dataset</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>summarise()</code></strong> (or <code>summarize()</code>) creates a new dataset, which contains the summarizing statistics of selected columns. Without the presence of grouping variables, the output is a dataset of a single row.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>starwars2 <span className="ot">&lt;-</span> starwars[, <span className="dv">1</span><span className="sc">:</span><span className="dv">5</span>]</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># statistics calculated across all rows</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>starwars2 <span className="sc">%&gt;%</span> <span className="fu">summarise</span>(</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="co"># average height</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">height.mean =</span> <span className="fu">mean</span>(height, <span className="at">na.rm =</span> T),</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="co"># maximum mass         </span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="at">mass.max =</span> <span className="fu">max</span>(mass, <span className="at">na.rm =</span> T),</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="co"># unique number of skin colors</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="at">skin_color.n =</span> <span className="fu">n_distinct</span>(skin_color))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 1 × 3
<br/>    height.mean mass.max skin_color.n
<br/>          &lt;dbl&gt;    &lt;dbl&gt;        &lt;int&gt;
<br/>  1        175.     1358           31</code></pre>
</div>
</div>
<p>Often the <code>summarise()</code> is used together with grouping variables specified with <Link to="../6-grouped-dataset"><strong><code>group_by()</code></strong></Link> — see the next tutorial.</p>
</main>
</div>
</div>
)}