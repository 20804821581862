import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot22dHistogramHappy from '../graphics/happy_2D_histogram_completed.png'; 
import imgGgplot22dHistogramHappyWebp from '../graphics/happy_2D_histogram_completed.webp'; 
export default function Ggplot22dHistogramHappy(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create 2D Histogram in ggplot2 to Visualize Population Distribution in Happiness and Wealth in U.S.</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot22dHistogramHappyWebp} />
    <img className="cover-img" src={imgGgplot22dHistogramHappy} />
  </picture>

<p>In this article, we’ll visualize the population composition at different levels of happiness and financial status in the United States. <strong>Major techniques used in this article include:</strong></p>
<ul>
<li>Create a 2D histogram plot for <em>categorical</em> variables. (check <Link to="../ggplot2-2D-histogram-storm-activities-US">here</Link> for <em>continuous</em> variable)</li>
<li>Use <code>stat</code> and <code>after_stat</code> arguments for impromptu statistical computation and enhanced visualization flexibility and efficiency.</li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data">
<h3 className="anchored" data-anchor-id="packages-and-data">Packages and data</h3>
<p>Here we use the <code>happy</code> dataset from the <Link to="https://haleyjeppson.github.io/ggmosaic/"><code>ggmosaic</code></Link> package. To aid in visualization, here we remove the rows containing <code>NA</code> values in variables <code>happy</code> and <code>finrela</code> (financial status), and select data only of the year of 2018.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(stringr) <span className="co"># for string manipulation</span></span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># devtools::install_github("haleyjeppson/ggmosaic")</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">library</span>(ggmosaic)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span> <span className="fu">c</span>(<span className="fu">is.na</span>(happy) <span className="sc">|</span> <span className="fu">is.na</span>(finrela) ),</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>         year <span className="sc">==</span> <span className="dv">2018</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="fu">as_tibble</span>()</span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a></span><br/>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a><span className="fu">head</span>(h, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 12
<br/>   year   age degree finrela happy health marital sex   polviews partyid wtssall
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;fct&gt;  &lt;fct&gt;   &lt;fct&gt; &lt;fct&gt;  &lt;fct&gt;   &lt;fct&gt; &lt;fct&gt;    &lt;fct&gt;     &lt;dbl&gt;
<br/>1  2018    43 junio… below … pret… good   never … male  conserv… not st…     198
<br/>2  2018    74 high … below … very… excel… separa… fema… &lt;NA&gt;     ind,ne…      61
<br/>3  2018    42 bache… above … very… &lt;NA&gt;   married male  slghtly… ind,ne…      61
<br/># ℹ 1 more variable: nhappy &lt;dbl&gt;</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a 2D histogram using <code>geom_bin2d</code>. The <code>fill</code> aesthetic (not typed out here) of each rectangle defaults to be mapped to the headcount value of each combination level of <code>happy</code> and <code>finrela</code>. The <code>color</code> and <code>linewidth</code> specifies the outline of the rectangles.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> happy, <span className="at">y =</span> finrela)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_bin2d</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a></span><br/>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_bin2d.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/happy_2D_histogram_bin2d"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Label each rectangle with the associated proportion of the headcount relative to the total population. These proportion values are not directly available in the input dataset <code>h</code>. Instead, we’ll calculate these values on the fly using the <code>stat</code> and <code>after_stat</code> technique.</p>
<ul>
<li><p><strong><code>stat</code></strong>: statistical transformation. It specifies where the <code>x</code> and <code>y</code> aesthetics come from. In <code>geom_text</code>, the default is <code>stat = "identity"</code>, meaning that both <code>x</code> and <code>y</code> aesthetics are mapped with variables directly available in the input dataset. Alternatively, the <code>x</code> and <code>y</code> may be otherwise derived via statistical transformation performed impromptu by ggplot2. In this example, the <code>x</code> and <code>y</code> are based on bins, i.e., unique categorical values, as specified by <code>stat = "bin2d"</code>.</p></li>
<li><p><strong><code>after_stat</code></strong>: we use <code>count / sum(count)</code> to calculate the proportion, and pass the computed values to <code>after_stat</code> to be accessible to the <code>label</code> aesthetic mapping.</p></li>
</ul>
<p>More details of ggplot2 essentials can be found in this <Link to="/R/ebook/flash-to-r-ggplot2-mastery"><strong>highly recommended e-book</strong></Link> created by Harvard scientist.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span>   </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>    <span className="at">stat =</span> <span className="st">"bin2d"</span>, </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">after_stat</span>(count <span className="sc">/</span> <span className="fu">sum</span>(count) <span className="sc">*</span> <span className="dv">100</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>          <span className="fu">round</span>(<span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="fu">paste</span>(<span className="st">"%"</span>) ),</span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) </span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_text_bin2d.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/happy_2D_histogram_text_bin2d"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Wrap y-axis labels into two rows. Here we leverage the <code>str_wrap</code> function from the <code>stringr</code> package (popular for string manipulation).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">scale_y_discrete</span>(<span className="at">labels =</span> <span className="cf">function</span>(x) <span className="fu">str_wrap</span>(x, <span className="at">width =</span> <span className="dv">10</span>)) </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_wrapped_texts.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/happy_2D_histogram_wrapped_texts"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Update the color (fill) scale using the <Link to="../../R/visualization/16-ggplot2-color-viridis-palette">viridis</Link> color palettes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"F"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>) </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_color_scale_viridis.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/happy_2D_histogram_color_scale_viridis"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Load font from the <Link to="https://fonts.google.com/">Google Font Repository</Link>, and set “Amita” as the default font.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># Add Google font</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Amita"</span>, <span className="at">family =</span> <span className="st">"Amita"</span>)</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span></code></pre></div>
</div>
<p>Polish up the plot: adding axial and plot titles, and customize the theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="co"># add axial and plot title</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="at">x =</span> <span className="st">"Happiness"</span>, <span className="at">y =</span> <span className="st">"Financial status"</span>,</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Population Distribution in Happiness and Wealth"</span>, </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="st">"General social survery of Americans' happiness in 2018"</span>) <span className="sc">+</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  </span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>  <span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">13</span>, <span className="at">base_family =</span> <span className="st">"Amita"</span>) <span className="sc">+</span> <span className="co"># use Amita as the default font</span></span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>)),</span>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a></span><br/>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/happy_2D_histogram_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot to the folder “graphics” (which is in the same folder as the source code).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"Happiness and Wealth 2D Histogram.pdf"</span>,</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">6</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a><span className="fu">library</span>(stringr) <span className="co"># for string manipulation</span></span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a></span><br/>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a><span className="co"># devtools::install_github("haleyjeppson/ggmosaic")</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a><span className="fu">library</span>(ggmosaic)</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a></span><br/>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span> <span className="fu">c</span>(<span className="fu">is.na</span>(happy) <span className="sc">|</span> <span className="fu">is.na</span>(finrela) ),</span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>         year <span className="sc">==</span> <span className="dv">2018</span>)</span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a><span className="fu">head</span>(h, <span className="dv">3</span>)</span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a></span><br/>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a><span className="co"># Create a 2D histogram</span></span>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span></span>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(happy, finrela)) <span className="sc">+</span></span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>  <span className="fu">geom_bin2d</span>(<span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> <span className="dv">1</span>) </span>
<span id="cb10-17"><a aria-hidden="true" href="#cb10-17" tabindex="-1"></a></span><br/>
<span id="cb10-18"><a aria-hidden="true" href="#cb10-18" tabindex="-1"></a>p1</span>
<span id="cb10-19"><a aria-hidden="true" href="#cb10-19" tabindex="-1"></a></span><br/>
<span id="cb10-20"><a aria-hidden="true" href="#cb10-20" tabindex="-1"></a><span className="co"># Label each rectangle with the headcount proportion relative to the total population. </span></span>
<span id="cb10-21"><a aria-hidden="true" href="#cb10-21" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span>   </span>
<span id="cb10-22"><a aria-hidden="true" href="#cb10-22" tabindex="-1"></a>  <span className="fu">geom_text</span>(</span>
<span id="cb10-23"><a aria-hidden="true" href="#cb10-23" tabindex="-1"></a>    <span className="at">stat =</span> <span className="st">"bin2d"</span>, </span>
<span id="cb10-24"><a aria-hidden="true" href="#cb10-24" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">after_stat</span>(count <span className="sc">/</span> <span className="fu">sum</span>(count) <span className="sc">*</span> <span className="dv">100</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb10-25"><a aria-hidden="true" href="#cb10-25" tabindex="-1"></a>          <span className="fu">round</span>(<span className="dv">1</span>) <span className="sc">%&gt;%</span> <span className="fu">paste</span>(<span className="st">"%"</span>) ),</span>
<span id="cb10-26"><a aria-hidden="true" href="#cb10-26" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">4</span>) </span>
<span id="cb10-27"><a aria-hidden="true" href="#cb10-27" tabindex="-1"></a>p2</span>
<span id="cb10-28"><a aria-hidden="true" href="#cb10-28" tabindex="-1"></a></span><br/>
<span id="cb10-29"><a aria-hidden="true" href="#cb10-29" tabindex="-1"></a><span className="co"># Wrap y-axis labels into two rows. </span></span>
<span id="cb10-30"><a aria-hidden="true" href="#cb10-30" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb10-31"><a aria-hidden="true" href="#cb10-31" tabindex="-1"></a>  <span className="fu">scale_y_discrete</span>(<span className="at">labels =</span> <span className="cf">function</span>(x) <span className="fu">str_wrap</span>(x, <span className="at">width =</span> <span className="dv">10</span>)) </span>
<span id="cb10-32"><a aria-hidden="true" href="#cb10-32" tabindex="-1"></a>p3</span>
<span id="cb10-33"><a aria-hidden="true" href="#cb10-33" tabindex="-1"></a></span><br/>
<span id="cb10-34"><a aria-hidden="true" href="#cb10-34" tabindex="-1"></a><span className="co"># Update the filled color scale</span></span>
<span id="cb10-35"><a aria-hidden="true" href="#cb10-35" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span></span>
<span id="cb10-36"><a aria-hidden="true" href="#cb10-36" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"F"</span>, <span className="at">direction =</span> <span className="sc">-</span><span className="dv">1</span>) </span>
<span id="cb10-37"><a aria-hidden="true" href="#cb10-37" tabindex="-1"></a>p4</span>
<span id="cb10-38"><a aria-hidden="true" href="#cb10-38" tabindex="-1"></a></span><br/>
<span id="cb10-39"><a aria-hidden="true" href="#cb10-39" tabindex="-1"></a><span className="co"># Load font from Google Font Repository</span></span>
<span id="cb10-40"><a aria-hidden="true" href="#cb10-40" tabindex="-1"></a><span className="co"># Add Google font</span></span>
<span id="cb10-41"><a aria-hidden="true" href="#cb10-41" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb10-42"><a aria-hidden="true" href="#cb10-42" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Amita"</span>, <span className="at">family =</span> <span className="st">"Amita"</span>)</span>
<span id="cb10-43"><a aria-hidden="true" href="#cb10-43" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb10-44"><a aria-hidden="true" href="#cb10-44" tabindex="-1"></a></span><br/>
<span id="cb10-45"><a aria-hidden="true" href="#cb10-45" tabindex="-1"></a><span className="co"># Add axial and plot titles, and customize the theme. </span></span>
<span id="cb10-46"><a aria-hidden="true" href="#cb10-46" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb10-47"><a aria-hidden="true" href="#cb10-47" tabindex="-1"></a>  <span className="co"># add axial and plot title</span></span>
<span id="cb10-48"><a aria-hidden="true" href="#cb10-48" tabindex="-1"></a>  <span className="fu">labs</span>(</span>
<span id="cb10-49"><a aria-hidden="true" href="#cb10-49" tabindex="-1"></a>    <span className="at">x =</span> <span className="st">"Happiness"</span>, <span className="at">y =</span> <span className="st">"Financial status"</span>,</span>
<span id="cb10-50"><a aria-hidden="true" href="#cb10-50" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Population Distribution in Happiness and Wealth"</span>, </span>
<span id="cb10-51"><a aria-hidden="true" href="#cb10-51" tabindex="-1"></a>    <span className="at">subtitle =</span> <span className="st">"General social survery of Americans' happiness in 2018"</span>) <span className="sc">+</span></span>
<span id="cb10-52"><a aria-hidden="true" href="#cb10-52" tabindex="-1"></a>  </span>
<span id="cb10-53"><a aria-hidden="true" href="#cb10-53" tabindex="-1"></a>  <span className="co"># theme</span></span>
<span id="cb10-54"><a aria-hidden="true" href="#cb10-54" tabindex="-1"></a>  <span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">13</span>, <span className="at">base_family =</span> <span className="st">"Amita"</span>) <span className="sc">+</span> <span className="co"># use Amita as the default font</span></span>
<span id="cb10-55"><a aria-hidden="true" href="#cb10-55" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb10-56"><a aria-hidden="true" href="#cb10-56" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb10-57"><a aria-hidden="true" href="#cb10-57" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">color =</span> <span className="st">"snow4"</span>),</span>
<span id="cb10-58"><a aria-hidden="true" href="#cb10-58" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-59"><a aria-hidden="true" href="#cb10-59" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb10-60"><a aria-hidden="true" href="#cb10-60" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>)),</span>
<span id="cb10-61"><a aria-hidden="true" href="#cb10-61" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>) </span>
<span id="cb10-62"><a aria-hidden="true" href="#cb10-62" tabindex="-1"></a></span><br/>
<span id="cb10-63"><a aria-hidden="true" href="#cb10-63" tabindex="-1"></a>p5</span>
<span id="cb10-64"><a aria-hidden="true" href="#cb10-64" tabindex="-1"></a></span><br/>
<span id="cb10-65"><a aria-hidden="true" href="#cb10-65" tabindex="-1"></a><span className="co"># Save the plot to the folder "graphics" </span></span>
<span id="cb10-66"><a aria-hidden="true" href="#cb10-66" tabindex="-1"></a><span className="co"># folder "graphics" is in the same folder as the source code. </span></span>
<span id="cb10-67"><a aria-hidden="true" href="#cb10-67" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"Happiness and Wealth 2D Histogram.pdf"</span>,</span>
<span id="cb10-68"><a aria-hidden="true" href="#cb10-68" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb10-69"><a aria-hidden="true" href="#cb10-69" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">6</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p><code>geom_bin2d</code> was employed above to visualize counts for <em>categorical</em> variables. In addition, it is more frequently applied to visualize <em>continuous</em> variables – check this <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>awesome 2D histogram with a map overlay</strong></Link>, which visualizes the hurricane activities in North Atlantic Ocean.</p>
<p>
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</p>
<p><br/><br/></p>
<p>The above visualization on happiness and wealth employs colors and texts to depict the population distribution. As an alternative approach, we can leverage the <Link to="../ggplot2-mosaic-health-wealth"><strong>mosaic plot using rectangles to illustrate the people composition</strong></Link> in a visually more intuitive way.</p>
<p><Link to="../ggplot2-mosaic-health-wealth">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}