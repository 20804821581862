import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2WorldMapCitiesPopulations from '../graphics/world_map_city_populations_completed.png'; 
import imgGgplot2WorldMapCitiesPopulationsWebp from '../graphics/world_map_city_populations_completed.webp'; 
export default function Ggplot2WorldMapCitiesPopulations(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Big City Distributions with World Map and Scatterplot in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2WorldMapCitiesPopulationsWebp} />
    <img className="cover-img" src={imgGgplot2WorldMapCitiesPopulations} />
  </picture>

<p>In this article, we’ll create a world map and a scatterplot on top of it to visualize the distribution of cities with varied population sizes.</p>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>We’ll use the <code>map_data()</code> function from <code>ggplot2</code> to create a world map, and use the <code>world.cities</code> dataset from the <code>maps</code> package to create a scatterplot of cities.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(ggrepel)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(maps) <span className="co"># install.packages("maps")</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">13</span>)) <span className="co"># default theme</span></span></code></pre></div>
</div>
<p>Load the “Lobster” font from <Link to="https://fonts.google.com/">Google font repository</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Lobster"</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>)</span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span></code></pre></div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p>Create a world map.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>m <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>)</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">head</span>(m, <span className="dv">3</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>   long   lat group order region subregion
<br/>  &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;int&gt; &lt;chr&gt;  &lt;chr&gt;    
<br/>1 -69.9  12.5     1     1 Aruba  &lt;NA&gt;     
<br/>2 -69.9  12.4     1     2 Aruba  &lt;NA&gt;     
<br/>3 -69.9  12.4     1     3 Aruba  &lt;NA&gt;     </code></pre>
</div>
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> m <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">filter</span>(lat <span className="sc">&gt;</span> <span className="sc">-</span><span className="dv">55</span>) <span className="sc">%&gt;%</span> <span className="co"># remove Antarctic</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> long, <span className="at">y =</span> lat)) <span className="sc">+</span></span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">2</span>) <span className="sc">+</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="fl">1.3</span>, <span className="at">clip =</span> <span className="st">"off"</span>)</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_world_map.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/world_map_city_populations_world_map"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Use the <code>world.cities</code> dataset from the <code>maps</code> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">head</span>(world.cities, <span className="dv">3</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 6
<br/>  name               country.etc   pop   lat  long capital
<br/>  &lt;chr&gt;              &lt;chr&gt;       &lt;int&gt; &lt;dbl&gt; &lt;dbl&gt;   &lt;int&gt;
<br/>1 'Abasan al-Jadidah Palestine    5629  31.3  34.3       0
<br/>2 'Abasan al-Kabirah Palestine   18999  31.3  34.4       0
<br/>3 'Abdul Hakim       Pakistan    47788  30.6  72.1       0</code></pre>
</div>
</div>
<p>Here we’ll visualize cities and towns with a population size larger than 1000 people. As the population spans several orders of magnitude, we apply logarithmic transformation to the population before mapping it to <code>apha</code> and <code>color</code> aesthetics. (Also check the use of <Link to="../ggplot2-heatmap-African-population">pseudo-logarithmic transformation in color scale</Link> to visualize skewed data pattern.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">3</span>), </span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">alpha =</span> <span className="fu">log10</span>(pop), <span className="at">color =</span> <span className="fu">log10</span>(pop)),</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>    <span className="at">size =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">scale_color_distiller</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>) </span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_big_cities.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/world_map_city_populations_big_cities"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Highlight big cities with a population size over a million people as red points.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>  </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>    <span className="at">size =</span> .<span className="dv">2</span>, <span className="at">color =</span> <span className="st">"tomato3"</span>) </span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a></span><br/>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_bigger_cities.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/world_map_city_populations_bigger_cities"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Highlight mega cities with over 5 million population as enlarged dark red points.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span>  </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="co"># mega cities:  over 5 million</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">5</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">3</span>, <span className="at">shape =</span> <span className="dv">21</span>, <span className="at">fill =</span> <span className="st">"red4"</span>, <span className="at">color =</span> <span className="st">"white"</span>) <span className="sc">+</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="co"># add city names</span></span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>  <span className="fu">geom_text_repel</span>(</span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">5</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> name), </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>    <span className="at">max.overlaps =</span> <span className="cn">Inf</span>, <span className="at">box.padding =</span> <span className="fu">unit</span>(<span className="dv">0</span>, <span className="st">"pt"</span>), </span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">2</span>, <span className="at">color =</span> <span className="st">"cyan3"</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a>    <span className="at">min.segment.length =</span> <span className="dv">0</span>)</span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a></span><br/>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_biggest_cities.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/world_map_city_populations_biggest_cities"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Apply the blank canvas theme, and position the colorbar at the bottom left corner. Note that the <strong>themes specified in <em><code>guides()</code></em> takes precedence over that specified in <code>theme()</code></strong>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">37</span>, <span className="fl">0.02</span>),</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>        <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"floralwhite"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a>        <span className="co"># increase margin at top and bottom of the plot</span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">20</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)) <span className="sc">+</span> </span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a>  <span className="fu">guides</span>(</span>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>    <span className="co"># remove legend associated with the alpha aesthetic</span></span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a>    <span className="at">alpha =</span> <span className="cn">FALSE</span>, </span>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a>    <span className="co"># adjust the color bar</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>      <span className="at">direction =</span> <span className="st">"horizontal"</span>,</span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a>      <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">150</span>, <span className="st">"pt"</span>),</span>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">5</span>, <span className="st">"pt"</span>),</span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>      <span className="co"># with precedence over </span></span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a>      <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">vjust =</span> <span className="dv">1</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>)</span>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a>    )</span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a>  )</span>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/world_map_city_populations_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add legend of mega cities at the plot bottom right corner, and add plot title.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a>  <span className="co"># create a point</span></span>
<span id="cb12-3"><a aria-hidden="true" href="#cb12-3" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb12-4"><a aria-hidden="true" href="#cb12-4" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"point"</span>,  <span className="at">x =</span> <span className="dv">90</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, </span>
<span id="cb12-5"><a aria-hidden="true" href="#cb12-5" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"red3"</span>, <span className="at">size =</span> <span className="fl">3.5</span>) <span className="sc">+</span></span>
<span id="cb12-6"><a aria-hidden="true" href="#cb12-6" tabindex="-1"></a>  <span className="co"># add text label</span></span>
<span id="cb12-7"><a aria-hidden="true" href="#cb12-7" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb12-8"><a aria-hidden="true" href="#cb12-8" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>,  <span className="at">x =</span> <span className="dv">100</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, </span>
<span id="cb12-9"><a aria-hidden="true" href="#cb12-9" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"mega cities</span><span className="sc">\n</span><span className="st">pop. &gt; 5 million"</span>,</span>
<span id="cb12-10"><a aria-hidden="true" href="#cb12-10" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">size =</span> <span className="dv">4</span>, <span className="at">hjust =</span> <span className="dv">0</span>, </span>
<span id="cb12-11"><a aria-hidden="true" href="#cb12-11" tabindex="-1"></a>    <span className="at">family =</span> <span className="st">"Lobster"</span>) <span className="sc">+</span> <span className="co"># using the loaded Google font</span></span>
<span id="cb12-12"><a aria-hidden="true" href="#cb12-12" tabindex="-1"></a>  </span>
<span id="cb12-13"><a aria-hidden="true" href="#cb12-13" tabindex="-1"></a>  <span className="co"># add plot title</span></span>
<span id="cb12-14"><a aria-hidden="true" href="#cb12-14" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Populous Cities in the World"</span>) <span className="sc">+</span></span>
<span id="cb12-15"><a aria-hidden="true" href="#cb12-15" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>, <span className="at">face =</span> <span className="st">"italic"</span>)) </span>
<span id="cb12-16"><a aria-hidden="true" href="#cb12-16" tabindex="-1"></a></span><br/>
<span id="cb12-17"><a aria-hidden="true" href="#cb12-17" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/world_map_city_populations_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/world_map_city_populations_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Save the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="st">"World populous cities.pdf"</span>,</span>
<span id="cb13-2"><a aria-hidden="true" href="#cb13-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path to the "graphics" folder </span></span>
<span id="cb13-3"><a aria-hidden="true" href="#cb13-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">6</span>, <span className="at">height =</span> <span className="dv">4</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb14-3"><a aria-hidden="true" href="#cb14-3" tabindex="-1"></a><span className="fu">library</span>(ggrepel)</span>
<span id="cb14-4"><a aria-hidden="true" href="#cb14-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>())</span>
<span id="cb14-5"><a aria-hidden="true" href="#cb14-5" tabindex="-1"></a></span><br/>
<span id="cb14-6"><a aria-hidden="true" href="#cb14-6" tabindex="-1"></a>m <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>)</span>
<span id="cb14-7"><a aria-hidden="true" href="#cb14-7" tabindex="-1"></a><span className="fu">head</span>(m, <span className="dv">3</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb14-8"><a aria-hidden="true" href="#cb14-8" tabindex="-1"></a></span><br/>
<span id="cb14-9"><a aria-hidden="true" href="#cb14-9" tabindex="-1"></a><span className="co"># Load the "Lobster" font from Google font repository</span></span>
<span id="cb14-10"><a aria-hidden="true" href="#cb14-10" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb14-11"><a aria-hidden="true" href="#cb14-11" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Lobster"</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>)</span>
<span id="cb14-12"><a aria-hidden="true" href="#cb14-12" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb14-13"><a aria-hidden="true" href="#cb14-13" tabindex="-1"></a></span><br/>
<span id="cb14-14"><a aria-hidden="true" href="#cb14-14" tabindex="-1"></a></span><br/>
<span id="cb14-15"><a aria-hidden="true" href="#cb14-15" tabindex="-1"></a><span className="co"># Create a world map. </span></span>
<span id="cb14-16"><a aria-hidden="true" href="#cb14-16" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> m <span className="sc">%&gt;%</span> </span>
<span id="cb14-17"><a aria-hidden="true" href="#cb14-17" tabindex="-1"></a>  <span className="fu">filter</span>(lat <span className="sc">&gt;</span> <span className="sc">-</span><span className="dv">55</span>) <span className="sc">%&gt;%</span> <span className="co"># remove Antactic</span></span>
<span id="cb14-18"><a aria-hidden="true" href="#cb14-18" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> long, <span className="at">y =</span> lat)) <span className="sc">+</span></span>
<span id="cb14-19"><a aria-hidden="true" href="#cb14-19" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb14-20"><a aria-hidden="true" href="#cb14-20" tabindex="-1"></a>               <span className="at">color =</span> <span className="st">"white"</span>, <span className="at">linewidth =</span> .<span className="dv">2</span>) <span className="sc">+</span></span>
<span id="cb14-21"><a aria-hidden="true" href="#cb14-21" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="fl">1.5</span>, <span className="at">clip =</span> <span className="st">"off"</span>)</span>
<span id="cb14-22"><a aria-hidden="true" href="#cb14-22" tabindex="-1"></a></span><br/>
<span id="cb14-23"><a aria-hidden="true" href="#cb14-23" tabindex="-1"></a></span><br/>
<span id="cb14-24"><a aria-hidden="true" href="#cb14-24" tabindex="-1"></a><span className="co"># Using the `world.cities` dataset from the `maps` package. Here we visualize cities / towns with population larger than 1000. </span></span>
<span id="cb14-25"><a aria-hidden="true" href="#cb14-25" tabindex="-1"></a><span className="co">#install.packages("maps")</span></span>
<span id="cb14-26"><a aria-hidden="true" href="#cb14-26" tabindex="-1"></a></span><br/>
<span id="cb14-27"><a aria-hidden="true" href="#cb14-27" tabindex="-1"></a><span className="fu">library</span>(maps) </span>
<span id="cb14-28"><a aria-hidden="true" href="#cb14-28" tabindex="-1"></a><span className="fu">head</span>(world.cities, <span className="dv">3</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb14-29"><a aria-hidden="true" href="#cb14-29" tabindex="-1"></a></span><br/>
<span id="cb14-30"><a aria-hidden="true" href="#cb14-30" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb14-31"><a aria-hidden="true" href="#cb14-31" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb14-32"><a aria-hidden="true" href="#cb14-32" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">3</span>), </span>
<span id="cb14-33"><a aria-hidden="true" href="#cb14-33" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">alpha =</span> <span className="fu">log10</span>(pop), <span className="at">color =</span> <span className="fu">log10</span>(pop)),</span>
<span id="cb14-34"><a aria-hidden="true" href="#cb14-34" tabindex="-1"></a>    <span className="at">size =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb14-35"><a aria-hidden="true" href="#cb14-35" tabindex="-1"></a>  <span className="fu">scale_color_distiller</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>) </span>
<span id="cb14-36"><a aria-hidden="true" href="#cb14-36" tabindex="-1"></a>p2</span>
<span id="cb14-37"><a aria-hidden="true" href="#cb14-37" tabindex="-1"></a></span><br/>
<span id="cb14-38"><a aria-hidden="true" href="#cb14-38" tabindex="-1"></a></span><br/>
<span id="cb14-39"><a aria-hidden="true" href="#cb14-39" tabindex="-1"></a><span className="co"># Highlight big cities with over a million population as red points. </span></span>
<span id="cb14-40"><a aria-hidden="true" href="#cb14-40" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span>  </span>
<span id="cb14-41"><a aria-hidden="true" href="#cb14-41" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb14-42"><a aria-hidden="true" href="#cb14-42" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb14-43"><a aria-hidden="true" href="#cb14-43" tabindex="-1"></a>    <span className="at">size =</span> .<span className="dv">2</span>, <span className="at">color =</span> <span className="st">"tomato3"</span>) </span>
<span id="cb14-44"><a aria-hidden="true" href="#cb14-44" tabindex="-1"></a>p3</span>
<span id="cb14-45"><a aria-hidden="true" href="#cb14-45" tabindex="-1"></a></span><br/>
<span id="cb14-46"><a aria-hidden="true" href="#cb14-46" tabindex="-1"></a></span><br/>
<span id="cb14-47"><a aria-hidden="true" href="#cb14-47" tabindex="-1"></a><span className="co"># Highlight mega cities with over 5 million population as enlarged dark red points.</span></span>
<span id="cb14-48"><a aria-hidden="true" href="#cb14-48" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span>  </span>
<span id="cb14-49"><a aria-hidden="true" href="#cb14-49" tabindex="-1"></a>  <span className="co"># mega cities:  over 5 million</span></span>
<span id="cb14-50"><a aria-hidden="true" href="#cb14-50" tabindex="-1"></a>  <span className="fu">geom_point</span>(</span>
<span id="cb14-51"><a aria-hidden="true" href="#cb14-51" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">5</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb14-52"><a aria-hidden="true" href="#cb14-52" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">3</span>, <span className="at">shape =</span> <span className="dv">21</span>, <span className="at">fill =</span> <span className="st">"red4"</span>, <span className="at">color =</span> <span className="st">"white"</span>) <span className="sc">+</span></span>
<span id="cb14-53"><a aria-hidden="true" href="#cb14-53" tabindex="-1"></a>  <span className="co"># add city names</span></span>
<span id="cb14-54"><a aria-hidden="true" href="#cb14-54" tabindex="-1"></a>  <span className="fu">geom_text_repel</span>(</span>
<span id="cb14-55"><a aria-hidden="true" href="#cb14-55" tabindex="-1"></a>    <span className="at">data =</span> world.cities <span className="sc">%&gt;%</span> <span className="fu">filter</span>(pop <span className="sc">&gt;</span> <span className="dv">5</span><span className="sc">*</span><span className="dv">10</span><span className="sc">^</span><span className="dv">6</span>),</span>
<span id="cb14-56"><a aria-hidden="true" href="#cb14-56" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">label =</span> name), </span>
<span id="cb14-57"><a aria-hidden="true" href="#cb14-57" tabindex="-1"></a>    <span className="at">max.overlaps =</span> <span className="cn">Inf</span>, <span className="at">box.padding =</span> <span className="fu">unit</span>(<span className="dv">0</span>, <span className="st">"pt"</span>), </span>
<span id="cb14-58"><a aria-hidden="true" href="#cb14-58" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">2</span>, <span className="at">color =</span> <span className="st">"cyan3"</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb14-59"><a aria-hidden="true" href="#cb14-59" tabindex="-1"></a>    <span className="at">min.segment.length =</span> <span className="dv">0</span>)</span>
<span id="cb14-60"><a aria-hidden="true" href="#cb14-60" tabindex="-1"></a>p4</span>
<span id="cb14-61"><a aria-hidden="true" href="#cb14-61" tabindex="-1"></a></span><br/>
<span id="cb14-62"><a aria-hidden="true" href="#cb14-62" tabindex="-1"></a></span><br/>
<span id="cb14-63"><a aria-hidden="true" href="#cb14-63" tabindex="-1"></a><span className="co"># adjust theme and legend</span></span>
<span id="cb14-64"><a aria-hidden="true" href="#cb14-64" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb14-65"><a aria-hidden="true" href="#cb14-65" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb14-66"><a aria-hidden="true" href="#cb14-66" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">37</span>, <span className="fl">0.02</span>),</span>
<span id="cb14-67"><a aria-hidden="true" href="#cb14-67" tabindex="-1"></a>        <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"floralwhite"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb14-68"><a aria-hidden="true" href="#cb14-68" tabindex="-1"></a>        <span className="co"># increase margin at top and bottom of the plot</span></span>
<span id="cb14-69"><a aria-hidden="true" href="#cb14-69" tabindex="-1"></a>        <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>, <span className="at">b =</span> <span className="dv">20</span>, <span className="at">unit =</span> <span className="st">"pt"</span>)) <span className="sc">+</span> </span>
<span id="cb14-70"><a aria-hidden="true" href="#cb14-70" tabindex="-1"></a>  <span className="fu">guides</span>(</span>
<span id="cb14-71"><a aria-hidden="true" href="#cb14-71" tabindex="-1"></a>    <span className="co"># remove legend associated with the alpha aesthetic</span></span>
<span id="cb14-72"><a aria-hidden="true" href="#cb14-72" tabindex="-1"></a>    <span className="at">alpha =</span> <span className="cn">FALSE</span>, </span>
<span id="cb14-73"><a aria-hidden="true" href="#cb14-73" tabindex="-1"></a>    <span className="co"># adjust the color bar</span></span>
<span id="cb14-74"><a aria-hidden="true" href="#cb14-74" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb14-75"><a aria-hidden="true" href="#cb14-75" tabindex="-1"></a>      <span className="at">direction =</span> <span className="st">"horizontal"</span>,</span>
<span id="cb14-76"><a aria-hidden="true" href="#cb14-76" tabindex="-1"></a>      <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">150</span>, <span className="st">"pt"</span>),</span>
<span id="cb14-77"><a aria-hidden="true" href="#cb14-77" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">5</span>, <span className="st">"pt"</span>),</span>
<span id="cb14-78"><a aria-hidden="true" href="#cb14-78" tabindex="-1"></a>      <span className="co"># with precedence over </span></span>
<span id="cb14-79"><a aria-hidden="true" href="#cb14-79" tabindex="-1"></a>      <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">vjust =</span> <span className="dv">1</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>)</span>
<span id="cb14-80"><a aria-hidden="true" href="#cb14-80" tabindex="-1"></a>    )</span>
<span id="cb14-81"><a aria-hidden="true" href="#cb14-81" tabindex="-1"></a>  )</span>
<span id="cb14-82"><a aria-hidden="true" href="#cb14-82" tabindex="-1"></a>p5</span>
<span id="cb14-83"><a aria-hidden="true" href="#cb14-83" tabindex="-1"></a></span><br/>
<span id="cb14-84"><a aria-hidden="true" href="#cb14-84" tabindex="-1"></a></span><br/>
<span id="cb14-85"><a aria-hidden="true" href="#cb14-85" tabindex="-1"></a><span className="co"># Add legend of mega cities, and add plot title. </span></span>
<span id="cb14-86"><a aria-hidden="true" href="#cb14-86" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> </span>
<span id="cb14-87"><a aria-hidden="true" href="#cb14-87" tabindex="-1"></a>  <span className="co"># create a point</span></span>
<span id="cb14-88"><a aria-hidden="true" href="#cb14-88" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb14-89"><a aria-hidden="true" href="#cb14-89" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"point"</span>,  <span className="at">x =</span> <span className="dv">90</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, </span>
<span id="cb14-90"><a aria-hidden="true" href="#cb14-90" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"red3"</span>, <span className="at">size =</span> <span className="fl">3.5</span>) <span className="sc">+</span></span>
<span id="cb14-91"><a aria-hidden="true" href="#cb14-91" tabindex="-1"></a>  <span className="co"># add text label</span></span>
<span id="cb14-92"><a aria-hidden="true" href="#cb14-92" tabindex="-1"></a>  <span className="fu">annotate</span>(</span>
<span id="cb14-93"><a aria-hidden="true" href="#cb14-93" tabindex="-1"></a>    <span className="at">geom =</span> <span className="st">"text"</span>,  <span className="at">x =</span> <span className="dv">100</span>, <span className="at">y =</span> <span className="sc">-</span><span className="dv">60</span>, </span>
<span id="cb14-94"><a aria-hidden="true" href="#cb14-94" tabindex="-1"></a>    <span className="at">label =</span> <span className="st">"mega cities</span><span className="sc">\n</span><span className="st">pop. &gt; 5 million"</span>,</span>
<span id="cb14-95"><a aria-hidden="true" href="#cb14-95" tabindex="-1"></a>    <span className="at">color =</span> <span className="st">"black"</span>, <span className="at">size =</span> <span className="dv">4</span>, <span className="at">hjust =</span> <span className="dv">0</span>, </span>
<span id="cb14-96"><a aria-hidden="true" href="#cb14-96" tabindex="-1"></a>    <span className="at">family =</span> <span className="st">"Lobster"</span>) <span className="sc">+</span> <span className="co"># using the loaded Google font</span></span>
<span id="cb14-97"><a aria-hidden="true" href="#cb14-97" tabindex="-1"></a>  </span>
<span id="cb14-98"><a aria-hidden="true" href="#cb14-98" tabindex="-1"></a>  <span className="co"># add plot title</span></span>
<span id="cb14-99"><a aria-hidden="true" href="#cb14-99" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Populous Cities in the World"</span>) <span className="sc">+</span></span>
<span id="cb14-100"><a aria-hidden="true" href="#cb14-100" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">family =</span> <span className="st">"Lobster"</span>, <span className="at">face =</span> <span className="st">"italic"</span>)) </span>
<span id="cb14-101"><a aria-hidden="true" href="#cb14-101" tabindex="-1"></a></span><br/>
<span id="cb14-102"><a aria-hidden="true" href="#cb14-102" tabindex="-1"></a>p6</span></code></pre></div>
</div>
</div>
</div>
</div>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out the following article that visualizes the <Link to="../ggplot2-map-airline"><strong>global flights and airports</strong></Link> illustrated below. And also check how to modify the script to <Link to="../ggplot2-map-airline-animation"><strong>turn static flight lines into animation</strong></Link>.</p>
<p><Link to="../ggplot2-map-airline">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_airlines_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Check out the following awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}