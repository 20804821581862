import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Introduction(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Welcome to tidyr Tutorial!</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>Tidy data describes a standard way of storing data that is used wherever possible throughout the tidyverse. The tidy structure facilitates easier manipulation, visualization, and modeling, enabling efficient and reproducible data analysis pipelines.</p>
<p>A dataset is considered <em>tidy</em> if it meets the following three criteria:</p>
<ul>
<li>Each variable is a column; each column is a variable.</li>
<li>Each observation is a row; each row is an observation.</li>
<li>Each value is a cell; each cell is a single value.</li>
</ul>
<p>The goal of tidyr is to help you create tidy data frames. The easiest way to get tidyr is to install the whole tidyverse:</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"tidyverse"</span>)</span></code></pre></div>
</div>
<p>Alternatively, install just the tidyr package:</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">install.packages</span>(<span className="st">"tidyr"</span>)</span></code></pre></div>
</div>
<section className="level3" id="content-of-the-tidyr-tutorial">
<h3 className="anchored" data-anchor-id="content-of-the-tidyr-tutorial">Content of the tidyr tutorial</h3>
<p>This tutorial will systematically cover the following topics with detailed instructions and enriched examples:</p>
<p><strong>Pivot dataset into longer or wider format</strong></p>
<p><Link to="../pivot-longer-part1"><code>pivot_longer()</code></Link> reshapes dataset into a “longer” format. It is one of the most important tidyr functions, a silver bullet that turns messy data into tidy structure. Given the paramount importance and high versatility of this function, and the great complexity of dataset you can encounter in real-world analysis, we’ll use 4 short tutorials to explain this function from the basics to advanced techniques.</p>
<p><Link to="../pivot-wider-part1"><code>pivot_wider()</code></Link> reshapes the dataset into a “wider” format for summary presentation and analysis by many other tools. We’ll spend 3 tutorials to develop an in-depth discussion.</p>
<p><strong>Columns manipulation: </strong></p>
<ul>
<li><Link to="../unite-columns"><code>unite()</code></Link>: Unite multiple columns into one column<br/>
</li>
<li><Link to="../separate-columns"><code>separate()</code></Link>: Split a single column into multiple ones</li>
</ul>
<p><strong>Handle missing values <code>NA</code>: </strong></p>
<ul>
<li><Link to="../drop-rows-containing-missing-values"><code>drop_na()</code></Link>: Remove Rows Containing missing values</li>
<li><Link to="../fill-up-missing-values"><code>fill()</code></Link>: Fill up missing values with previous or following values<br/>
</li>
<li><Link to="../replace-NA-with-specified-values"><code>replace_na()</code></Link>: Replace missing values with specified values</li>
</ul>
<p><strong>Create all possible combinations of selected variables.</strong> We’ll spend three sections to discuss this topic:</p>
<ul>
<li><Link to="../expand-combinations-variables-part1"><code>expand()</code></Link>: Create all possible combinations between levels of the selected columns of an input dataset. We’ll also discuss <code>nesting()</code> used inside <code>expand()</code> to generate combinations already present in the dataset; and combine <code>expand()</code> with <code>*_join()</code> functions in the <strong>dplyr</strong> package to create useful applications.</li>
<li><Link to="../expand-combinations-variables-part3"><code>expand_grid()</code></Link>: Create combinations from vectors.</li>
</ul>
</section>
</main>
</div>
</div>
)}