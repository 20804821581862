import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonDatastructuressets(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Sets">Sets<a class="anchor-link" href="#Sets">¶</a></h3><p>In Python, <strong>sets</strong> are unordered collections of unique elements. They are useful for storing distinct values. Sets are created using curly braces <code>&#123;&#125;</code> or the <code>set()</code> constructor.</p>
<p><strong>Properties of Sets</strong></p>
<ul>
<li><strong>Uniqueness</strong>: Sets do not allow duplicate elements. Each element is unique within the set.</li>
<li><strong>Unordered</strong>: Sets are unordered, meaning the elements have no specific order. So you cannot access elements by index.</li>
<li><strong>Mutable</strong>: Sets can be modified after creation, such as adding, removing, or modifying items, without needing a new structure.</li>
<li>Sets can contain a mix of data types.</li>
</ul>
<p><strong>Example</strong></p>
<p>The example below is a set named <code>my_set</code>. It contains three distinct values.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">my_set</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">&#125;</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">my_set</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;1, 2, 'hello'&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Methods-of-Sets">Methods of Sets<a class="anchor-link" href="#Methods-of-Sets">¶</a></h4><p>Sets are mutable, meaning they allow adding and removing elements.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="add()-Function"><code>add()</code> Function<a class="anchor-link" href="#add()-Function">¶</a></h5><p>The <code>add()</code> function adds an element to the set if it is not already present as sets don't allow duplicate elements.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [30]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a set </span></span>

<span><span class="n">sample_set</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="s1">'hello'</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Add an integer to the set</span></span>

<span><span class="n">sample_set</span><span class="o">.</span><span class="n">add</span><span class="p">(</span><span class="mi">10</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_set</span><span class="p">)</span></span>


<br /><span><span class="c1"># Add a string to the set</span></span>

<span><span class="n">sample_set</span><span class="o">.</span><span class="n">add</span><span class="p">(</span><span class="s1">'world'</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_set</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;1, 2, 10, 'hello'&#125;
<br />&#123;1, 2, 'hello', 10, 'world'&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>It's important to note that the value "<code>10</code>" appearing at different positions in the <strong>set</strong> highlights the unordered characteristic of sets. <strong>Sets</strong> do not maintain a specific order for elements, so their positions may appear random.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="remove()-and-discard()-Functions"><code>remove()</code> and <code>discard()</code> Functions<a class="anchor-link" href="#remove()-and-discard()-Functions">¶</a></h5><p>Both <code>remove()</code> and <code>discard()</code> functions remove a specific element from the set. The difference is that if the elemnt is not found in the set, <code>remove()</code> raises a KeyError while <code>discard()</code> does not raise an error.</p>
<p><strong>Examples of using <code>remove()</code> and <code>discard()</code> functions to remove existing values from a set</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [35]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a set </span></span>

<span><span class="n">sample_set</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">10</span><span class="p">,</span> <span class="mi">20</span><span class="p">,</span> <span class="mi">30</span><span class="p">,</span> <span class="mi">40</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># use remove() to remove 20 from the set</span></span>

<span><span class="n">sample_set</span><span class="o">.</span><span class="n">remove</span><span class="p">(</span><span class="mi">20</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_set</span><span class="p">)</span></span>


<br /><span><span class="c1"># use discard() to remove 40 from the set</span></span>

<span><span class="n">sample_set</span><span class="o">.</span><span class="n">discard</span><span class="p">(</span><span class="mi">40</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_set</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;40, 10, 30&#125;
<br />&#123;10, 30&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Example of using <code>remove()</code> function to remove a non-existing value from a set</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [34]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a set </span></span>

<span><span class="n">sample_set</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">10</span><span class="p">,</span> <span class="mi">20</span><span class="p">,</span> <span class="mi">30</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># use remove() to remove an non-existing elemenet from the set raises KeyError</span></span>

<span><span class="n">sample_set</span><span class="o">.</span><span class="n">remove</span><span class="p">(</span><span class="mi">40</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_set</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="application/vnd.jupyter.stderr">
<pre className='demo-highlight python'><code className='sourceCode'>
<span><span class="ansi-red-fg">---------------------------------------------------------------------------</span></span>

<span><span class="ansi-red-fg">KeyError</span>                                  Traceback (most recent call last)
</span>
<span>Input <span class="ansi-green-fg">In [34]</span>, in <span class="ansi-cyan-fg">&lt;cell line: 5&gt;</span><span class="ansi-blue-fg">()</span></span>

<span><span class="ansi-green-intense-fg ansi-bold">      2</span> sample_set <span>=</span> &#123;<span>10</span>, <span>20</span>, <span>30</span>&#125;
</span>
<span><span class="ansi-green-intense-fg ansi-bold">      4</span> <span># use remove() to remove an unexisting elemenet from the set</span></span>

<span><span class="ansi-green-fg">----&gt; 5</span> <span class="ansi-yellow-bg">sample_set</span><span class="ansi-yellow-bg">.</span><span class="ansi-yellow-bg">remove</span><span class="ansi-yellow-bg">(</span><span class="ansi-yellow-bg">40</span><span class="ansi-yellow-bg">)</span></span>

<span><span class="ansi-green-intense-fg ansi-bold">      6</span> <span>print</span>(sample_set)
</span>

<br /><span><span class="ansi-red-fg">KeyError</span>: 4</span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Example of using <code>discard()</code> function to remove a non-existing value from a set</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [33]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a set </span></span>

<span><span class="n">sample_set</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">10</span><span class="p">,</span> <span class="mi">20</span><span class="p">,</span> <span class="mi">30</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># use discard() to remove an non-existing elemenet from the set. No error shows up</span></span>

<span><span class="n">sample_set</span><span class="o">.</span><span class="n">discard</span><span class="p">(</span><span class="mi">40</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_set</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;10, 20, 30&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="union()-Function"><code>union()</code> Function<a class="anchor-link" href="#union()-Function">¶</a></h5><p>The <code>union()</code> function returns a new set that is the union of two or more sets.</p>
<p><strong>Syntax</strong>: <code>set1.union(set2, set3, ...)</code></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [38]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create two sets</span></span>

<span><span class="n">set1</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">&#125;</span></span>

<span><span class="n">set2</span> <span class="o">=</span> <span class="p">&#123;</span><span class="mi">3</span><span class="p">,</span> <span class="mi">4</span><span class="p">,</span> <span class="mi">5</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Union set1 and set2</span></span>

<span><span class="n">union_set</span> <span class="o">=</span> <span class="n">set1</span><span class="o">.</span><span class="n">union</span><span class="p">(</span><span class="n">set2</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">union_set</span><span class="p">)</span></span>


<br /><span><span class="c1"># Create another set</span></span>

<span><span class="n">set3</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'hello'</span><span class="p">,</span> <span class="s1">'world!'</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Union set1, set2 and set3</span></span>

<span><span class="n">union_set2</span> <span class="o">=</span> <span class="n">set1</span><span class="o">.</span><span class="n">union</span><span class="p">(</span><span class="n">set2</span><span class="p">,</span> <span class="n">set3</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">union_set2</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;1, 2, 3, 4, 5&#125;
<br />&#123;1, 2, 3, 4, 5, 'world!', 'hello'&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>As sets don't allow duplicates, thus the <code>union_set</code> only contains unique values from <code>set1</code> and <code>set2</code>.</p>
</div>
</div>
</div>
</div>
</div>
)}