import React from 'react'; 
import { Link } from 'react-router-dom';
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonDatastructuresdictionaries(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Dictionaries">Dictionaries<a class="anchor-link" href="#Dictionaries">¶</a></h3><p>A Python dictionary is a built-in data structure that is used to store and manage collections of data in the form of key-value pairs, e.g. <code>&#123;key1: value1, key2: value2, key3: value3, ...&#125;</code>. Each key in a dictionary is associated with a corresponding value. They are created using curly braces <code>&#123;&#125;</code> or the <code>dict()</code> constructor.</p>
<p><strong>Properties of Dictionaries</strong></p>
<ul>
<li><strong>Unordered</strong>: Dictionaries are unordered collections of key-value pairs. The order of elements is not guaranteed in Python versions prior to Python 3.7, but from Python 3.7 onward, dictionaries maintain the insertion order of elements.</li>
<li><strong>Mutable</strong>: Dictionaries are mutable, which means you can add, modify, or remove key-value pairs after creating the dictionary.</li>
<li><strong>Keys are Unique</strong>: Keys in a dictionary are unique. Each key can only have one associated value. If you try to add a duplicate key, the new value will overwrite the existing one.</li>
<li><strong>Indexable</strong>: You can access the values in a dictionary by specifying the key associated with the target value. </li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Example</strong></p>
<p>The dictionary below contains three key-value pairs.</p>
<p>The keys are: name, age, city.</p>
<p>The values are: Alice, 30, New York.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">my_dict</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'name'</span><span class="p">:</span> <span class="s1">'Alice'</span><span class="p">,</span> <span class="s1">'age'</span><span class="p">:</span> <span class="mi">30</span><span class="p">,</span> <span class="s1">'city'</span><span class="p">:</span> <span class="s1">'New York'</span><span class="p">&#125;</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Methods-of-Dictionaries">Methods of Dictionaries<a class="anchor-link" href="#Methods-of-Dictionaries">¶</a></h4><h5 id="get()-Function"><code>get()</code> Function<a class="anchor-link" href="#get()-Function">¶</a></h5><p>The <code>get()</code> function returns the value associted with the specified key, or the default value if the key is not found.</p>
<p><strong>Syntax</strong>: <code>dictionary.get(key, default)</code></p>
<ul>
<li><strong>key</strong>: the specific key name associated with the target value.</li>
<li><strong>default</strong>: a self-defined value to return if the key is not found.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [16]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a dictionary</span></span>

<span><span class="n">sample_dict</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'name'</span><span class="p">:</span> <span class="s1">'John'</span><span class="p">,</span> <span class="s1">'age'</span><span class="p">:</span> <span class="mi">28</span><span class="p">,</span> <span class="s1">'hobbie'</span><span class="p">:</span> <span class="s1">'hiking'</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Get the value 'hiking', and set the default to 'Unknown'  </span></span>

<span><span class="n">hobbie</span> <span class="o">=</span> <span class="n">sample_dict</span><span class="o">.</span><span class="n">get</span><span class="p">(</span><span class="s1">'hobbie'</span><span class="p">,</span> <span class="s1">'Unknown'</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">hobbie</span><span class="p">)</span></span>


<br /><span><span class="c1"># Get the value 'John'</span></span>

<span><span class="n">name</span> <span class="o">=</span> <span class="n">sample_dict</span><span class="o">.</span><span class="n">get</span><span class="p">(</span><span class="s1">'name'</span><span class="p">,</span> <span class="s1">'Unknown'</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">name</span><span class="p">)</span></span>


<br /><span><span class="c1"># Using a non-exisiting key returns the default value </span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="o">.</span><span class="n">get</span><span class="p">(</span><span class="s1">'city'</span><span class="p">,</span> <span class="s1">'Unknown'</span><span class="p">))</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>hiking
<br />John
<br />Unknown
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Additionally, values can be accessed using <code>[]</code>. Unlike <code>get()</code> method,  which allows setting a default return value, using <code>[]</code> will raise a "KeyError" if the key doesn't exist in the dictionary.</p>
<p><strong>Example of using <code>[]</code> to access values</strong></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [20]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Get the value 'hiking' </span></span>

<span><span class="n">hobbie</span> <span class="o">=</span> <span class="n">sample_dict</span><span class="p">[</span><span class="s1">'hobbie'</span><span class="p">]</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">hobbie</span><span class="p">)</span></span>


<br /><span><span class="c1"># Get the value 'John'</span></span>

<span><span class="n">name</span> <span class="o">=</span> <span class="n">sample_dict</span><span class="p">[</span><span class="s1">'name'</span><span class="p">]</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">name</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>hiking
<br />John
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Using a non-existent key through <code>[]</code> will raise a KeyError.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [21]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Using a non-existing key raises KeyError</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="p">[</span><span class="s1">'city'</span><span class="p">])</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="application/vnd.jupyter.stderr">
<pre className='demo-highlight python'><code className='sourceCode'>
<span><span class="ansi-red-fg">---------------------------------------------------------------------------</span></span>

<span><span class="ansi-red-fg">KeyError</span>                                  Traceback (most recent call last)
</span>
<span>Input <span class="ansi-green-fg">In [21]</span>, in <span class="ansi-cyan-fg">&lt;cell line: 2&gt;</span><span class="ansi-blue-fg">()</span></span>

<span><span class="ansi-green-intense-fg ansi-bold">      1</span> <span># Using a non-existing key raises KeyError</span></span>

<span><span class="ansi-green-fg">----&gt; 2</span> <span>print</span>(<span class="ansi-yellow-bg">sample_dict</span><span class="ansi-yellow-bg">[</span><span class="ansi-yellow-bg">'</span><span class="ansi-yellow-bg">city</span><span class="ansi-yellow-bg">'</span><span class="ansi-yellow-bg">]</span>)
</span>

<br /><span><span class="ansi-red-fg">KeyError</span>: 'city</span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="keys()-Function"><code>keys()</code> Function<a class="anchor-link" href="#keys()-Function">¶</a></h5><p>The <code>keys()</code> function returns an object containing all keys in the dictionary.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [19]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a dictionary named "student"</span></span>

<span><span class="n">student</span> <span class="o">=</span> <span class="p">&#123;</span></span>

<span>    <span class="s2">"first_name"</span><span class="p">:</span> <span class="s2">"John"</span><span class="p">,</span></span>

<span>    <span class="s2">"last_name"</span><span class="p">:</span> <span class="s2">"Doe"</span><span class="p">,</span></span>

<span>    <span class="s2">"age"</span><span class="p">:</span> <span class="mi">20</span><span class="p">,</span></span>

<span>    <span class="s2">"grade"</span><span class="p">:</span> <span class="s2">"A"</span><span class="p">,</span></span>

<span>    <span class="s2">"courses"</span><span class="p">:</span> <span class="p">[</span><span class="s2">"Math"</span><span class="p">,</span> <span class="s2">"English"</span><span class="p">,</span> <span class="s2">"Science"</span><span class="p">]</span></span>

<span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Get all keys in the dictionary</span></span>

<span><span class="n">keys</span> <span class="o">=</span> <span class="n">student</span><span class="o">.</span><span class="n">keys</span><span class="p">()</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">keys</span><span class="p">)</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>dict_keys(['first_name', 'last_name', 'age', 'grade', 'courses'])
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="values()-Function"><code>values()</code> Function<a class="anchor-link" href="#values()-Function">¶</a></h5><p>The <code>values()</code> function returns an object containing all values in the dictionary.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [15]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Get all values in the dictionary</span></span>

<span><span class="n">values</span> <span class="o">=</span> <span class="n">student</span><span class="o">.</span><span class="n">values</span><span class="p">()</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">values</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>dict_values(['John', 'Doe', 20, 'A', ['Math', 'English', 'Science']])
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="items()-Function"><code>items()</code> Function<a class="anchor-link" href="#items()-Function">¶</a></h5><p>The <code>items()</code> function returns an object containing all key-value pairs (tuples) in the dictionary.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [18]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Get all key-value pairs in the dictionary</span></span>

<span><span class="n">items</span> <span class="o">=</span> <span class="n">student</span><span class="o">.</span><span class="n">items</span><span class="p">()</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">items</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>dict_items([('first_name', 'John'), ('last_name', 'Doe'), ('age', 20), ('grade', 'A'), ('courses', ['Math', 'English', 'Science'])])
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Each key-value pair is a <Link to='../data-structure-tuples'>tuple</Link>.</p>
<p>The objects returned by the methods <code>keys()</code>, <code>values()</code> and <code>items()</code> are all iterables.</p>
<p><strong>iterables:</strong> An iterable is any object that can return its elements one at a time, including <Link to='../data-structure-lists'>lists</Link>, <Link to='../data-structure-tuples'>tuple</Link>, <Link to='../data-types'>strings</Link>, <Link to='../data-structure-dictionaries'>dictionaries</Link>, and <Link to='../data-structure-sets'>sets</Link>.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="len()-Function"><code>len()</code> Function<a class="anchor-link" href="#len()-Function">¶</a></h5><p>The <code>len()</code> function returns the number of key-value pairs in the dictionary.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [21]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">count</span> <span class="o">=</span> <span class="nb">len</span><span class="p">(</span><span class="n">student</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">count</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>5
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="update()-Function"><code>update()</code> Function<a class="anchor-link" href="#update()-Function">¶</a></h5><p>The <code>update()</code> function updates the dictionary with key-value pairs from another dictionary.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a dictionary</span></span>

<span><span class="n">sample_dict</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'name'</span><span class="p">:</span> <span class="s1">'John'</span><span class="p">,</span> <span class="s1">'age'</span><span class="p">:</span> <span class="mi">30</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Update the dictionary by adding the city information</span></span>

<span><span class="n">sample_dict</span><span class="o">.</span><span class="n">update</span><span class="p">(&#123;</span><span class="s1">'city'</span><span class="p">:</span> <span class="s1">'New York'</span><span class="p">&#125;)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;'name': 'John', 'age': 30, 'city': 'New York'&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Update the dictionary by changing the age value</span></span>

<span><span class="n">sample_dict</span><span class="o">.</span><span class="n">update</span><span class="p">(&#123;</span><span class="s1">'age'</span><span class="p">:</span> <span class="s1">'31'</span><span class="p">&#125;)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;'name': 'John', 'age': '31', 'city': 'New York'&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5><code>pop()</code> Function</h5>
<p>The <code>pop()</code> function removes the specified key and its associated value from the dictionary and returns the value.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [13]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a dictionary</span></span>

<span><span class="n">sample_dict</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'brand'</span><span class="p">:</span> <span class="s1">'CocoCode Academy'</span><span class="p">,</span> <span class="s1">'type'</span><span class="p">:</span> <span class="s1">'learning website'</span><span class="p">&#125;</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="p">)</span></span>


<br /><span><span class="c1"># Remove the type from the dictionary</span></span>

<span><span class="n">sample_dict</span><span class="o">.</span><span class="n">pop</span><span class="p">(</span><span class="s1">'type'</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;'brand': 'CocoCode Academy', 'type': 'learning website'&#125;
<br />&#123;'brand': 'CocoCode Academy'&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="clear()-Function"><code>clear()</code> Function<a class="anchor-link" href="#clear()-Function">¶</a></h5><p>The <code>clear()</code> function removes all key-value pairs from the dictionary, making it empty.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [14]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a dictionary</span></span>

<span><span class="n">sample_dict</span> <span class="o">=</span> <span class="p">&#123;</span><span class="s1">'brand'</span><span class="p">:</span> <span class="s1">'CocoCode Academy'</span><span class="p">,</span> <span class="s1">'type'</span><span class="p">:</span> <span class="s1">'learning website'</span><span class="p">&#125;</span></span>


<br /><span><span class="c1"># Remove the type from the dictionary</span></span>

<span><span class="n">sample_dict</span><span class="o">.</span><span class="n">clear</span><span class="p">()</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">sample_dict</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>&#123;&#125;
</code></pre>
</div>
</div>
</div>
</div>
</div>
</div>
)}