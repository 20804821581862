import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonSlicingdataframe(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Select-a-Subset-of-Data">Select a Subset of Data<a class="anchor-link" href="#Select-a-Subset-of-Data">¶</a></h3><p>When working with data, you may not always need all columns or want to anallyze a specific portion of your dataset. In such situations, you can use the <code>loc[]</code> or <code>iloc[]</code> functions to extract the desired subset of data.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This tutorial uses classic Iris dataset, which can be downloaded here <a href="https://3codeacademy.s3.amazonaws.com/dataset/python/Iris.csv" id="downloadData">Iris dataset</a>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">import</span> <span class="nn">pandas</span> <span class="k">as</span> <span class="nn">pd</span></span>

<span><span class="n">df</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">read_csv</span><span class="p">(</span><span class="s1">'Iris.csv'</span><span class="p"></span>)</span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="1.-Select-a-Subset-of-Data-via-iloc%5B%5D">1. Select a Subset of Data via <code>iloc[]</code><a class="anchor-link" href="#1.-Select-a-Subset-of-Data-via-iloc%5B%5D">¶</a></h4><p>The <code>df.iloc[]</code> function is used for integer based indexing for selection by position, allowing access to a group of rows and columns by integer indices.</p>
<h5 id="Usage">Usage<a class="anchor-link" href="#Usage">¶</a></h5><p><code>.iloc[row_index, column_index]</code>takes two arguments:</p>
<ul>
<li><strong>row_index</strong>: This argument specifies the rows you want to select based on their integer positions. You can provide a single integer, a slice (e.g., 0:5), or a list of integers to select specific rows.</li>
<li><strong>column_index</strong>: This argument specifies the columns you want to select based on their integer positions. Similar to the rows, you can provide a single integer, a slice, or a list of integers to select specific columns.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Select-One-Column-by-Column-Index">Select One Column by Column Index<a class="anchor-link" href="#Select-One-Column-by-Column-Index">¶</a></h5><p>In this example, we've selected all rows using <code>:</code> and specifically chosen the first column with <code>[0]</code>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">iloc</span><span class="p">[:,</span> <span class="p">[</span><span class="mi">0</span><span class="p">]]</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
</tr>
<tr>
<th>4</th>
<td>5</td>
</tr>
<tr>
<th>...</th>
<td>...</td>
</tr>
<tr>
<th>145</th>
<td>146</td>
</tr>
<tr>
<th>146</th>
<td>147</td>
</tr>
<tr>
<th>147</th>
<td>148</td>
</tr>
<tr>
<th>148</th>
<td>149</td>
</tr>
<tr>
<th>149</th>
<td>150</td>
</tr>
</tbody>
</table>
<p>150 rows × 1 columns</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Select-First-3-Rows-and-One-column">Select First 3 Rows and One column<a class="anchor-link" href="#Select-First-3-Rows-and-One-column">¶</a></h5><p>In this example. we've selected the first 3 rows of data with <code>:3</code> and the second column with <code>[1]</code>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [10]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">iloc</span><span class="p">[:</span><span class="mi">3</span><span class="p">,</span> <span class="p">[</span><span class="mi">1</span><span class="p">]]</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>SepalLengthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>5.1</td>
</tr>
<tr>
<th>1</th>
<td>4.9</td>
</tr>
<tr>
<th>2</th>
<td>4.7</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="2.-Select-a-Subset-of-Data-via-loc%5B%5D">2. Select a Subset of Data via <code>loc[]</code><a class="anchor-link" href="#2.-Select-a-Subset-of-Data-via-loc%5B%5D">¶</a></h4><p>Different from <code>iloc[]</code> using positions, <code>loc[]</code> accesses specific rows and columns by labels or a filter rows based on a condition.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Select-one-column">Select one column<a class="anchor-link" href="#Select-one-column">¶</a></h5><p>In this example, we've selected the first 5 rows and the <code>SepalLengthCm</code> column.</p>
<p>It's essential to understand that in Python, data slicing with <code>[:]</code> is typically left-inclusive and right-exclusive. However, with <code>loc[:]</code>, <strong>it becomes inclusive on both sides</strong>. Therefore, to select the first 5 rows, we used <code>loc[:4]</code> or <code>iloc[:5]</code>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">loc</span><span class="p">[:</span><span class="mi">4</span><span class="p">,</span> <span class="p">[</span><span class="s1">'SepalLengthCm'</span><span class="p">]]</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>SepalLengthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>5.1</td>
</tr>
<tr>
<th>1</th>
<td>4.9</td>
</tr>
<tr>
<th>2</th>
<td>4.7</td>
</tr>
<tr>
<th>3</th>
<td>4.6</td>
</tr>
<tr>
<th>4</th>
<td>5.0</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Select-multiple-columns">Select multiple columns<a class="anchor-link" href="#Select-multiple-columns">¶</a></h5><p>Select first 10 rows and two columns out from the data using <code>loc[]</code></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [24]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">loc</span><span class="p">[:</span><span class="mi">9</span><span class="p">,</span> <span class="p">[</span><span class="s1">'SepalLengthCm'</span><span class="p">,</span> <span class="s1">'SepalWidthCm'</span><span class="p">]]</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>SepalLengthCm</th>
<th>SepalWidthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>5.1</td>
<td>3.5</td>
</tr>
<tr>
<th>1</th>
<td>4.9</td>
<td>3.0</td>
</tr>
<tr>
<th>2</th>
<td>4.7</td>
<td>3.2</td>
</tr>
<tr>
<th>3</th>
<td>4.6</td>
<td>3.1</td>
</tr>
<tr>
<th>4</th>
<td>5.0</td>
<td>3.6</td>
</tr>
<tr>
<th>5</th>
<td>5.4</td>
<td>3.9</td>
</tr>
<tr>
<th>6</th>
<td>4.6</td>
<td>3.4</td>
</tr>
<tr>
<th>7</th>
<td>5.0</td>
<td>3.4</td>
</tr>
<tr>
<th>8</th>
<td>4.4</td>
<td>2.9</td>
</tr>
<tr>
<th>9</th>
<td>4.9</td>
<td>3.1</td>
</tr>
</tbody>
</table>
<p>150 rows × 1 columns</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="3.-Filter-Data-Based-on-Conditions-with-loc">3. Filter Data Based on Conditions with <code>loc</code><a class="anchor-link" href="#3.-Filter-Data-Based-on-Conditions-with-loc">¶</a></h4><h5 id="Single-condition-Example">Single condition Example<a class="anchor-link" href="#Single-condition-Example">¶</a></h5><p>select records where its <code>SepalLengthCm</code> column values are larger than 7.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [18]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">loc</span><span class="p">[</span><span class="n">df</span><span class="p">[</span><span class="s1">'SepalLengthCm'</span><span class="p">]</span><span class="o">&gt;</span><span class="mi">7</span><span class="p">]</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
<th>SepalWidthCm</th>
<th>PetalLengthCm</th>
<th>PetalWidthCm</th>
<th>Species</th>
</tr>
</thead>
<tbody>
<tr>
<th>102</th>
<td>103</td>
<td>7.1</td>
<td>3.0</td>
<td>5.9</td>
<td>2.1</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>105</th>
<td>106</td>
<td>7.6</td>
<td>3.0</td>
<td>6.6</td>
<td>2.1</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>107</th>
<td>108</td>
<td>7.3</td>
<td>2.9</td>
<td>6.3</td>
<td>1.8</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>109</th>
<td>110</td>
<td>7.2</td>
<td>3.6</td>
<td>6.1</td>
<td>2.5</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>117</th>
<td>118</td>
<td>7.7</td>
<td>3.8</td>
<td>6.7</td>
<td>2.2</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>118</th>
<td>119</td>
<td>7.7</td>
<td>2.6</td>
<td>6.9</td>
<td>2.3</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>122</th>
<td>123</td>
<td>7.7</td>
<td>2.8</td>
<td>6.7</td>
<td>2.0</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>125</th>
<td>126</td>
<td>7.2</td>
<td>3.2</td>
<td>6.0</td>
<td>1.8</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>129</th>
<td>130</td>
<td>7.2</td>
<td>3.0</td>
<td>5.8</td>
<td>1.6</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>130</th>
<td>131</td>
<td>7.4</td>
<td>2.8</td>
<td>6.1</td>
<td>1.9</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>131</th>
<td>132</td>
<td>7.9</td>
<td>3.8</td>
<td>6.4</td>
<td>2.0</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>135</th>
<td>136</td>
<td>7.7</td>
<td>3.0</td>
<td>6.1</td>
<td>2.3</td>
<td>Iris-virginica</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Multiple-conditions-example">Multiple conditions example<a class="anchor-link" href="#Multiple-conditions-example">¶</a></h5><p>To select records where its <code>SepalLengthCm</code> are larger than 7 and <code>PetalWidthCm</code> are smaller than 2, we can use the <strong><code>&amp;</code></strong> operator for '<strong>and</strong>' to combine the two conditions. It's important to remember that when using <strong>and</strong> <code>&amp;</code> operator and <strong>or</strong> <code>|</code> opeartor to combine multiple conditions, we need to use parenthesis <code>()</code> to wrap each condition to ensure the filtering is working properly.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [23]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">loc</span><span class="p">[(</span><span class="n">df</span><span class="p">[</span><span class="s1">'SepalLengthCm'</span><span class="p">]</span><span class="o">&gt;</span><span class="mi">7</span><span class="p">)</span> <span class="o">&amp;</span> <span class="p">(</span><span class="n">df</span><span class="p">[</span><span class="s1">'PetalWidthCm'</span><span class="p">]</span><span class="o">&lt;</span><span class="mi">2</span><span class="p">)]</span></span> 

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
<th>SepalWidthCm</th>
<th>PetalLengthCm</th>
<th>PetalWidthCm</th>
<th>Species</th>
</tr>
</thead>
<tbody>
<tr>
<th>107</th>
<td>108</td>
<td>7.3</td>
<td>2.9</td>
<td>6.3</td>
<td>1.8</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>125</th>
<td>126</td>
<td>7.2</td>
<td>3.2</td>
<td>6.0</td>
<td>1.8</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>129</th>
<td>130</td>
<td>7.2</td>
<td>3.0</td>
<td>5.8</td>
<td>1.6</td>
<td>Iris-virginica</td>
</tr>
<tr>
<th>130</th>
<td>131</td>
<td>7.4</td>
<td>2.8</td>
<td>6.1</td>
<td>1.9</td>
<td>Iris-virginica</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Awesome! In this lesson, you've learned the skills to select specific data subsets using <code>iloc</code> and <code>loc</code>. In our next chapter, we'll delve into data sorting. Stay tuned!</p>
</div>
</div>
</div>
</div>
</div>
)}