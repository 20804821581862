import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ReorderMultipleLegends from '../tutorial/reorder_legends_base.png';

export default function Ggplot2ReorderMultipleLegends(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Reorder Multiple Legends in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains <strong>how to rearrange the order of multiple legends</strong>.</p>
<section className="level4" id="create-a-plot-with-many-legends">
<h4 className="anchored" data-anchor-id="create-a-plot-with-many-legends">Create a plot with many legends</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages </span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set default global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>() <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">legend.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">12</span>)))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>p <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Sepal.Length, <span className="at">y =</span> Sepal.Width, </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>             <span className="at">shape =</span> Species, <span className="at">color =</span> Petal.Length, </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>             <span className="at">size =</span> Petal.Width)) <span className="sc">+</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">2</span>, .<span className="dv">2</span>, <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">scale_color_distiller</span>(<span className="at">palette =</span> <span className="st">"Spectral"</span>)</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2ReorderMultipleLegends} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="reorder-three-legends-layout">
<h4 className="anchored" data-anchor-id="reorder-three-legends-layout">Reorder three legends layout</h4>
<p>Note that in the <code>guides()</code> function, the continuous color bar is called “colorbar”, and discrete legends as “legend”. This differentiation does <em>not</em> apply in the <code>theme()</code> function, where both colorbar and discrete legends are known as the “legend”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p <span className="sc">+</span></span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">shape =</span> <span className="fu">guide_legend</span>(<span className="at">order =</span> <span className="dv">1</span>),</span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>         <span className="at">color =</span> <span className="fu">guide_colorbar</span>(<span className="at">order =</span> <span className="dv">2</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/reorder_legends_rearranged.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}