import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function SetOperations(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Set Operations</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>Set operations</strong> in dplyr works on rows of a pair of <em>compatible</em> datasets. The datasets should have the same column names (possibly arranged in different orders), and are in compatible types.</p>
<p>For datasets A and B:</p>
<ul>
<li><strong><code>intersect(A, B)</code></strong> returns rows shared in common by both A and B.</li>
<li><strong><code>union(A, B)</code></strong> returns all rows in both A and B, <em>excluding</em> duplicated rows.</li>
<li><strong><code>union_all(A, B)</code></strong> returns all rows in both A and B, <em>including</em> duplicated rows.</li>
<li><strong><code>setdiff(A, B)</code></strong> returns all rows unique to A (not present in B).</li>
<li><strong><code>symdiff(A, B)</code></strong> computes the symmetric differences, i.e. all rows unique to A, and rows unique to B.</li>
<li><strong><code>setequal(A, B)</code></strong> returns a logic value TRUE if A and B contain the same rows (ignoring row order).</li>
</ul>
<p><code>intersect()</code>, <code>union()</code>, <code>setdiff()</code>, and <code>symdiff()</code> remove duplicated rows from the output dataset. In addition, the dplyr set operations can also work on a pair of <em>vectors</em> following the same rule, except that it works on elements of vectors, instead of rows of datasets.</p>
<p>Consider the following example. Note that in dataset A, the 1st and 3rd rows are duplicated.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>A <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">5</span>, <span className="dv">1</span>, <span className="dv">7</span>), <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">6</span>, <span className="dv">2</span>, <span className="dv">8</span>))</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>B <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="dv">10</span>, <span className="dv">7</span>, <span className="dv">1</span>),   <span className="at">y =</span> <span className="fu">c</span>(<span className="dv">20</span>, <span className="dv">8</span>, <span className="dv">2</span>))</span></code></pre></div>
</div>
<p>The set operations are illustrated as below:</p>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>A</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 4 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1     1     2
<br/>  2     5     6
<br/>  3     1     2
<br/>  4     7     8</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>B</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1    10    20
<br/>  2     7     8
<br/>  3     1     2</code></pre>
</div>
</div>
</div>
</div>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="co"># shared rows</span></span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a><span className="co"># NO duplicates</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a><span className="fu">intersect</span>(A, B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1     1     2
<br/>  2     7     8</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># all rows, </span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="co"># NO duplicates</span></span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">union</span>(A, B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 4 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1     1     2
<br/>  2     5     6
<br/>  3     7     8
<br/>  4    10    20</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="co"># all rows, </span></span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="co"># WITH duplicates</span></span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a><span className="fu">union_all</span>(A, B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 7 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1     1     2
<br/>  2     5     6
<br/>  3     1     2
<br/>  4     7     8
<br/>  5    10    20
<br/>  6     7     8
<br/>  7     1     2</code></pre>
</div>
</div>
</div>
</div>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="co"># unique to A</span></span>
<span id="cb12-2"><a aria-hidden="true" href="#cb12-2" tabindex="-1"></a><span className="fu">setdiff</span>(A, B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 1 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1     5     6</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="co"># unique to A and B</span></span>
<span id="cb14-2"><a aria-hidden="true" href="#cb14-2" tabindex="-1"></a><span className="fu">symdiff</span>(A, B)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>        x     y
<br/>    &lt;dbl&gt; &lt;dbl&gt;
<br/>  1     5     6
<br/>  2    10    20</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="co"># have same rows?</span></span>
<span id="cb16-2"><a aria-hidden="true" href="#cb16-2" tabindex="-1"></a><span className="fu">setequal</span>(A, A[<span className="dv">4</span><span className="sc">:</span><span className="dv">1</span>, ])</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1] TRUE</code></pre>
</div>
</div>
</div>
</div>
</main>
</div>
</div>
)}