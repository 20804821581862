import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrFlatten(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Combine Multiple Strings into a Single String</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_flatten()</code></strong> combines multiple string pieces into a single string. The output is a vector of length 1.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>fruit <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"mango"</span>, <span className="st">"peach"</span>, <span className="st">"lemon"</span>)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">str_flatten</span>(fruit)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mangopeachlemon"</code></pre>
</div>
</div>
<p>Use <code>collapse</code> to specify the separator between string pieces. It defaults to be an empty quote (no separator).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="co"># Separate pieces with comma and space.</span></span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a><span className="fu">str_flatten</span>(fruit, <span className="at">collapse =</span>  <span className="st">", "</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango, peach, lemon"</code></pre>
</div>
</div>
<p><code>last</code> specifies the separator to connect the last two string pieces.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_flatten</span>(fruit, <span className="at">collapse =</span>  <span className="st">", "</span>, <span className="at">last =</span> <span className="st">", and "</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango, peach, and lemon"</code></pre>
</div>
</div>
<p><strong><code>str_flatten_comma()</code></strong> automatically uses a comma as the separator; it has <code>collapse =  ", "</code> under the hood.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_flatten_comma</span>(fruit)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango, peach, lemon"</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_flatten_comma</span>(fruit, <span className="at">last =</span> <span className="st">", and "</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "mango, peach, and lemon"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}