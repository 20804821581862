import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot22dHistogramStormActivitiesUs from '../graphics/heatmap_storms_completed.png'; 
import imgGgplot22dHistogramStormActivitiesUsWebp from '../graphics/heatmap_storms_completed.webp'; 
export default function Ggplot22dHistogramStormActivitiesUs(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create 2D-Histogram and Geographic Map in ggplot2 to Visualize Atlantic Tropical Storm Activities</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot22dHistogramStormActivitiesUsWebp} />
    <img className="cover-img" src={imgGgplot22dHistogramStormActivitiesUs} />
  </picture>

<p>In this article, we’ll visualize the North Atlantic hurricane activities. We’ll create a 2D histogram to summarize the frequency of hurricanes at each degree of latitude and longitude, with a world map overlay to provide the geographical context.</p>
<p><strong>Major techniques covered in this article include:</strong></p>
<ul>
<li><a href="#geom_bin2d">Create a 2D histogram heatmap with <code>geom_bin2d()</code>.</a></li>
<li><a href="#worldmap">Create geographical maps with <code>map_data</code> and <code>geom_polygon()</code>.</a></li>
<li><a href="#theme_customization">Theme customization.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset <code>storms</code> used in this work is built in the <code>dplyr</code> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># set up the default global theme</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="fu">head</span>(storms, <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 13
<br/>  name   year month   day  hour   lat  long status       category  wind pressure
<br/>  &lt;chr&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;int&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;fct&gt;           &lt;dbl&gt; &lt;int&gt;    &lt;int&gt;
<br/>1 Amy    1975     6    27     0  27.5   -79 tropical de…       NA    25     1013
<br/>2 Amy    1975     6    27     6  28.5   -79 tropical de…       NA    25     1013
<br/>3 Amy    1975     6    27    12  29.5   -79 tropical de…       NA    25     1013
<br/># ℹ 2 more variables: tropicalstorm_force_diameter &lt;int&gt;,
<br/>#   hurricane_force_diameter &lt;int&gt;</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="geom_bin2d"><strong>Create a 2D histogram summarizing the relative frequency of hurricanes.</strong></span> Most hurricanes were measured every six hours during the lifetime of the storm, and involved location changes in their latitude (<code>lac</code>) and longitude (<code>long</code>). Here we use row counts to approximate the hurricane exposure in different geographical locations. <code>geom_bin2d</code> creates a 2D histogram, and counts the number of observations falling within each cell. Here <code>binwidth = 1</code> dictates each cell in the plot to be one unit in width and height, corresponding to a geographical resolution of 1° in latitude and longitude.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> storms <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(long, lat)) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="fu">geom_bin2d</span>(<span className="at">binwidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">n.breaks =</span> <span className="dv">6</span>) </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_2D_bin_plot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_storms_2D_bin_plot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="worldmap"><strong>Create a world map overlay on the 2D histogram.</strong></span> The map data <code>map_data</code> is built in ggplot2. It has columns of longitude (<code>long</code>) and latitude (<code>lat</code>) (same column names as in the <code>storm</code> dataset), and the grouping variable <code>group</code> to create maps with <code>geom_polygon</code>. The map is sketched out and the default fill is removed with <code>fill = NA</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>m <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a></span><br/>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">data =</span> m, <span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>               <span className="at">fill =</span> <span className="cn">NA</span>, <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) </span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_worldmap.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_storms_worldmap"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Zoom-in over the north Atlantic region</strong> with an aspect ratio of 1.5, and the desired range of longitude (<code>xlim</code>) and latitude (<code>ylim</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="fl">1.5</span>,  <span className="at">xlim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">125</span>, <span className="dv">5</span>), <span className="at">ylim =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">65</span>)) </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_north_Atlantic.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_storms_north_Atlantic"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="theme_customization"><strong>Apply a dark grey background</strong></span> to bring out the map outline and the storm heatmap, without complete merging with the low-activity storm data (black cells).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"grey25"</span>))</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_grey_background.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_storms_grey_background"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Revise axial labels, titles, and legends.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="co"># relabel the x-axis</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">120</span>, <span className="dv">20</span>, <span className="dv">40</span>)) <span className="sc">+</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>  <span className="co"># revise / add titles</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"longitude"</span>, <span className="at">y =</span> <span className="st">"Latitude"</span>, </span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Atlantic hurricanes 1975 - 2021"</span>,</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"data source: https://www.nhc.noaa.gov/data/#hurdat"</span>,</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>       <span className="at">fill =</span> <span className="st">"relative frequency of hurricanes"</span>, <span className="co"># change title of legend color bar</span></span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  ) <span className="sc">+</span> </span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="co"># customize the legend color bar</span></span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">250</span>, <span className="st">"pt"</span>),</span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>))) </span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_axis_title_legends.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_storms_axis_title_legends"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>A final touch-up. Here we use <code>geom_hline()</code> and <code>geom_vline()</code> to create subtle grid lines to aid in visualization. Grid lines added this way lie on top of the data (the map and storm heatmap). In comparison, grids generated by <code>theme(panel.grid = ...)</code> reside <em>below</em> the data and is invisible in this example.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">14</span>, <span className="at">face =</span> <span className="st">"italic"</span>),</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>  <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">color =</span> <span className="st">"snow4"</span>)) <span className="sc">+</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>  <span className="co"># lay grids on top of the data</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">90</span>, <span className="dv">90</span>, <span className="dv">10</span>), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">120</span>, <span className="dv">20</span>, <span className="dv">20</span>), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>) </span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>p6</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_storms_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a></span><br/>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a></span><br/>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> storms <span className="sc">%&gt;%</span> </span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(long, lat)) <span className="sc">+</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>  <span className="fu">geom_bin2d</span>(<span className="at">binwidth =</span> <span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"A"</span>, <span className="at">n.breaks =</span> <span className="dv">6</span>) </span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>p1</span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a></span><br/>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a><span className="co"># add world map</span></span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>m <span className="ot">&lt;-</span> <span className="fu">map_data</span>(<span className="st">"world"</span>) <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>()</span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a></span><br/>
<span id="cb9-16"><a aria-hidden="true" href="#cb9-16" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb9-17"><a aria-hidden="true" href="#cb9-17" tabindex="-1"></a>  <span className="fu">geom_polygon</span>(<span className="at">data =</span> m, <span className="fu">aes</span>(<span className="at">group =</span> group), </span>
<span id="cb9-18"><a aria-hidden="true" href="#cb9-18" tabindex="-1"></a>               <span className="at">fill =</span> <span className="cn">NA</span>, <span className="at">color =</span> <span className="st">"snow3"</span>, <span className="at">linewidth =</span> .<span className="dv">5</span>) </span>
<span id="cb9-19"><a aria-hidden="true" href="#cb9-19" tabindex="-1"></a>p2</span>
<span id="cb9-20"><a aria-hidden="true" href="#cb9-20" tabindex="-1"></a></span><br/>
<span id="cb9-21"><a aria-hidden="true" href="#cb9-21" tabindex="-1"></a><span className="co"># Zoom in to the north Atlantic region</span></span>
<span id="cb9-22"><a aria-hidden="true" href="#cb9-22" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb9-23"><a aria-hidden="true" href="#cb9-23" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="fl">1.5</span>,  <span className="at">xlim =</span> <span className="fu">c</span>(<span className="sc">-</span><span className="dv">125</span>, <span className="dv">5</span>), <span className="at">ylim =</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">65</span>)) </span>
<span id="cb9-24"><a aria-hidden="true" href="#cb9-24" tabindex="-1"></a>p3</span>
<span id="cb9-25"><a aria-hidden="true" href="#cb9-25" tabindex="-1"></a></span><br/>
<span id="cb9-26"><a aria-hidden="true" href="#cb9-26" tabindex="-1"></a></span><br/>
<span id="cb9-27"><a aria-hidden="true" href="#cb9-27" tabindex="-1"></a><span className="co"># Apply a dark background </span></span>
<span id="cb9-28"><a aria-hidden="true" href="#cb9-28" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb9-29"><a aria-hidden="true" href="#cb9-29" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"grey25"</span>))</span>
<span id="cb9-30"><a aria-hidden="true" href="#cb9-30" tabindex="-1"></a>p4</span>
<span id="cb9-31"><a aria-hidden="true" href="#cb9-31" tabindex="-1"></a></span><br/>
<span id="cb9-32"><a aria-hidden="true" href="#cb9-32" tabindex="-1"></a></span><br/>
<span id="cb9-33"><a aria-hidden="true" href="#cb9-33" tabindex="-1"></a><span className="co"># Revise axial labels, titles, and legends. </span></span>
<span id="cb9-34"><a aria-hidden="true" href="#cb9-34" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-35"><a aria-hidden="true" href="#cb9-35" tabindex="-1"></a>  <span className="co"># relabel the x-axis</span></span>
<span id="cb9-36"><a aria-hidden="true" href="#cb9-36" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">120</span>, <span className="dv">20</span>, <span className="dv">40</span>)) <span className="sc">+</span></span>
<span id="cb9-37"><a aria-hidden="true" href="#cb9-37" tabindex="-1"></a>  </span>
<span id="cb9-38"><a aria-hidden="true" href="#cb9-38" tabindex="-1"></a>  <span className="co"># revise / add titles</span></span>
<span id="cb9-39"><a aria-hidden="true" href="#cb9-39" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="st">"longitude"</span>, <span className="at">y =</span> <span className="st">"Latitude"</span>, </span>
<span id="cb9-40"><a aria-hidden="true" href="#cb9-40" tabindex="-1"></a>       <span className="at">title =</span> <span className="st">"Atlantic hurricanes 1975 - 2021"</span>,</span>
<span id="cb9-41"><a aria-hidden="true" href="#cb9-41" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"data source: https://www.nhc.noaa.gov/data/#hurdat"</span>,</span>
<span id="cb9-42"><a aria-hidden="true" href="#cb9-42" tabindex="-1"></a>       <span className="at">fill =</span> <span className="st">"relative frequency of hurricanes"</span>, <span className="co"># change title of legend color bar</span></span>
<span id="cb9-43"><a aria-hidden="true" href="#cb9-43" tabindex="-1"></a>  ) <span className="sc">+</span> </span>
<span id="cb9-44"><a aria-hidden="true" href="#cb9-44" tabindex="-1"></a>  <span className="co"># customize the legend color bar</span></span>
<span id="cb9-45"><a aria-hidden="true" href="#cb9-45" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">fill =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb9-46"><a aria-hidden="true" href="#cb9-46" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">250</span>, <span className="st">"pt"</span>),</span>
<span id="cb9-47"><a aria-hidden="true" href="#cb9-47" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>),</span>
<span id="cb9-48"><a aria-hidden="true" href="#cb9-48" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb9-49"><a aria-hidden="true" href="#cb9-49" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>))) </span>
<span id="cb9-50"><a aria-hidden="true" href="#cb9-50" tabindex="-1"></a>p5</span>
<span id="cb9-51"><a aria-hidden="true" href="#cb9-51" tabindex="-1"></a></span><br/>
<span id="cb9-52"><a aria-hidden="true" href="#cb9-52" tabindex="-1"></a></span><br/>
<span id="cb9-53"><a aria-hidden="true" href="#cb9-53" tabindex="-1"></a><span className="co"># A final touch-up. Grids added with  `geom_hline` and `geom_vline` reside on top of the data. In comparison, grids by `theme(panel.grid = ...)` lies below the data and is invisible in this example.</span></span>
<span id="cb9-54"><a aria-hidden="true" href="#cb9-54" tabindex="-1"></a></span><br/>
<span id="cb9-55"><a aria-hidden="true" href="#cb9-55" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb9-56"><a aria-hidden="true" href="#cb9-56" tabindex="-1"></a>  <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">14</span>, <span className="at">face =</span> <span className="st">"italic"</span>),</span>
<span id="cb9-57"><a aria-hidden="true" href="#cb9-57" tabindex="-1"></a>  <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">10</span>, <span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">color =</span> <span className="st">"snow4"</span>)) <span className="sc">+</span></span>
<span id="cb9-58"><a aria-hidden="true" href="#cb9-58" tabindex="-1"></a>  </span>
<span id="cb9-59"><a aria-hidden="true" href="#cb9-59" tabindex="-1"></a>  <span className="co"># lay grids on top of the data</span></span>
<span id="cb9-60"><a aria-hidden="true" href="#cb9-60" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">90</span>, <span className="dv">90</span>, <span className="dv">10</span>), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb9-61"><a aria-hidden="true" href="#cb9-61" tabindex="-1"></a>  <span className="fu">geom_vline</span>(<span className="at">xintercept =</span> <span className="fu">seq</span>(<span className="sc">-</span><span className="dv">120</span>, <span className="dv">20</span>, <span className="dv">20</span>), <span className="at">color =</span> <span className="st">"snow4"</span>, <span className="at">linewidth =</span> .<span className="dv">1</span>) </span>
<span id="cb9-62"><a aria-hidden="true" href="#cb9-62" tabindex="-1"></a>p6</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In the storm visualization above, <code>geom_bin2d()</code> is used to count observations on <em>continuous</em> scales (the latitude and longitude). Besides, it can be also applied for <em>discrete</em> scales, binned on each categorical level. Check out the following <Link to="../ggplot2-2D-histogram-happy"><strong>2D histogram on discrete scales</strong></Link> that shows the population proportion at different levels of happiness and wealth status in the United States in 2018.</p>
<p><Link to="../ggplot2-2D-histogram-happy">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/happy_2D_histogram_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>While a heatmap can be created in the form of a 2D histogram with <code>geom_bin2d()</code>, as illustrated above, it is often created using the generic function <code>geom_tile()</code> or <code>geom_raster()</code>. Check out the following heatmap created with <code>geom_tile()</code> that visualizes the <Link to="../ggplot2-heatmap-vaccine-for-polio"><strong>outbreaks of the polio disease before and after the vaccine introduction</strong></Link>.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-polio">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_polio_completed.png" />
  </picture>
</Link></p>
<p>And check this follow-up article where we’ll <Link to="../ggplot2-heatmap-vaccine-for-8-diseases"><strong>create a function to automate the heatmap visualization for 8 infectious diseases </strong></Link>before and after the vaccine invention.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}