import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2TitleLegendRemoval from '../tutorial/remove_legend_title_base.png';

export default function Ggplot2TitleLegendRemoval(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Remove Legend Titles in Two Distinct Ways in ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>This tutorial explains how to <strong>remove legend titles</strong> in two distinct aspects:</p>
<ul>
<li><a href="#remove_selected_legend_title">remove title of <em>selected</em> legend (of a specific aesthetic)</a></li>
<li><a href="#remove_all_legend_titles">remove title of <em>all</em> legends</a></li>
</ul>
<hr/>
<section className="level4" id="create-a-base-plot">
<h4 className="anchored" data-anchor-id="create-a-base-plot">Create a base plot</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># Packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a></span><br/>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>) <span className="sc">+</span> <span className="fu">theme</span>(</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="at">legend.title =</span> <span className="fu">element_text</span>(</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>    <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">color =</span> <span className="st">"turquoise3"</span>)))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>p <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> Sepal.Length, <span className="at">y =</span> Sepal.Width, </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>             <span className="at">color =</span> Petal.Length, <span className="at">shape =</span> Species)) <span className="sc">+</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">geom_point</span>(<span className="at">size =</span> <span className="dv">4</span>, <span className="at">alpha =</span> .<span className="dv">7</span>,</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>             <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">1</span>, .<span className="dv">1</span>, <span className="dv">123</span>)) <span className="sc">+</span></span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="fu">scale_color_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>)</span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a></span><br/>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>p</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="cover-img" src={imgGgplot2TitleLegendRemoval} /></p>
</figure>
</div>
</div>
</div>
</section>
<section className="level4" id="remove_selected_legend_title">
<h4 className="anchored" data-anchor-id="remove_selected_legend_title">Remove the title of <em>selected</em> legend</h4>
<p>Remove the title of the legend associated with the <code>shape</code> aesthetic. <code>labs()</code> is a quick solution to rename or remove legend titles (and axis titles as well).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">labs</span>(<span className="at">shape =</span> <span className="cn">NULL</span>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/remove_legend_title_labs.png"/></p>
</figure>
</div>
</div>
</div>
<p>Alternatively, use the <code>scale_*</code> function to rename or remove legend (and axis) titles.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_shape_discrete</span>(<span className="at">name =</span> <span className="cn">NULL</span>)</span></code></pre></div>
</div>
<p>Another option is to use the <code>guides()</code> function to rename or remove legend title. The following two codes are equivalent.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">scale_shape_discrete</span>(<span className="at">guide =</span> <span className="fu">guide_legend</span>(<span className="at">title =</span> <span className="cn">NULL</span>))</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">guides</span>(<span className="at">shape =</span> <span className="fu">guide_legend</span>(<span className="at">title =</span> <span className="cn">NULL</span>))</span></code></pre></div>
</div>
</section>
<section className="level4" id="remove_all_legend_titles">
<h4 className="anchored" data-anchor-id="remove_all_legend_titles">Remove the titles of <em>all</em> legends.</h4>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">legend.title =</span> <span className="fu">element_blank</span>())</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/remove_legend_title_remove_all_legend.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}