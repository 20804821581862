import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function CharacterClass(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Literal Characters and Character Class</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<section className="level3" id="literal-characters">
<h3 className="anchored" data-anchor-id="literal-characters">Literal Characters</h3>
<p>Literal characters are the simplest form of regular expression, and match themselves, e.g., “4B” matches exactly the pattern “4B” in a string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"melon - 4B"</span>, <span className="st">"Banana * B4"</span>, <span className="st">"Pineapple $ 3"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"4B"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ melon - &lt;4B&gt;
<br/>[2] │ Banana * B4
<br/>[3] │ Pineapple $ 3</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"4B"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]
<br/>[1,] "4B"
<br/>[2,] ""  
<br/>[3,] ""  </code></pre>
</div>
</div>
</div>
</section>
<section className="level3" id="character-classes">
<h3 className="anchored" data-anchor-id="character-classes">Character Classes</h3>
<p>Square brackets <code>[ ]</code> are used to define a character class. <em>Each individual</em> character within the brackets defines a pattern, e.g., <code>[4B]</code> matches any individual “4” and “B” in a string.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"[4B]"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ melon - &lt;4&gt;&lt;B&gt;
<br/>[2] │ &lt;B&gt;anana * &lt;B&gt;&lt;4&gt;
<br/>[3] │ Pineapple $ 3</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"[4B]"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1] [,2] [,3]
<br/>[1,] "4"  "B"  ""  
<br/>[2,] "B"  "B"  "4" 
<br/>[3,] ""   ""   ""  </code></pre>
</div>
</div>
</div>
<p>The pattern can be a range of characters, e.g., <code>[1-5]</code> matches any numerical characters ranging from 1 to 5 in a string.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"[1-5]"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ melon - &lt;4&gt;B
<br/>[2] │ Banana * B&lt;4&gt;
<br/>[3] │ Pineapple $ &lt;3&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"[1-5]"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]
<br/>[1,] "4" 
<br/>[2,] "4" 
<br/>[3,] "3" </code></pre>
</div>
</div>
</div>
<p><code>[A-C]</code> matches any one of the three letters “A”, “B”, or “C” in a string.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"[A-C]"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ melon - 4&lt;B&gt;
<br/>[2] │ &lt;B&gt;anana * &lt;B&gt;4
<br/>[3] │ Pineapple $ 3</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"[A-C]"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1] [,2]
<br/>[1,] "B"  ""  
<br/>[2,] "B"  "B" 
<br/>[3,] ""   ""  </code></pre>
</div>
</div>
</div>
<p>In like manner, <code>[a-z]</code> matches any lowercase letter, and <code>[a-zA-Z0-9]</code> matches any letter (in either lowercase or uppercase) and any numerical digit.</p>
<p>The character class can be written in shorthands wrapped in square brackets.</p>
<p><strong><code>[:digit:]</code></strong> matches any digit (0-9), equivalent to <code>[0-9]</code>.</p>
<p><strong><code>[:alpha:]</code></strong> matches any alphabetic character (uppercase or lowercase), equivalent to <code>[a-zA-Z]</code>.</p>
<p><strong><code>[:lower:]</code></strong> matches any lowercase alphabetic character, equivalent to <code>[a-z]</code>.</p>
<p><strong><code>[:upper:]</code></strong> matches any uppercase alphabetic character, equivalent to <code>[A-Z]</code>.</p>
<p><strong><code>[:alnum:]</code></strong> matches any alphanumeric character (letter or digit), equivalent to <code>[a-zA-Z0-9]</code>.</p>
<p><strong><code>[:space:]</code></strong> matches a single whitespace character.</p>
<p><strong><code>[:punct:]</code></strong> matches any punctuation character.</p>
<p><strong><code>[:print:]</code></strong> matches any printable character (including alphanumeric characters and punctuation).</p>
<p>In the following example, <code>[:upper:][:digit:]</code> matches any two-character string that comprises sequentially an uppercase letter and a digit number.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"E=mc^2...!"</span>, <span className="st">"P = M * A4"</span>)</span>
<span id="cb18-2"><a aria-hidden="true" href="#cb18-2" tabindex="-1"></a>b1 <span className="ot">&lt;-</span> <span className="st">"[:upper:][:digit:]"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb19"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb19-1"><a aria-hidden="true" href="#cb19-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, b1) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ E=mc^2...!
<br/>[2] │ P = M * &lt;A4&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb21"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb21-1"><a aria-hidden="true" href="#cb21-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(a, b1, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]
<br/>[1,] ""  
<br/>[2,] "A4"</code></pre>
</div>
</div>
</div>
<p>In contrast, if <code>[:upper:]</code> and <code>[:digit:]</code> is put inside the character class brackets, i.e., <code>[[:upper:][:digit:]]</code>, then it matches <em>any single</em> uppercase letter or digital number.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb23"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb23-1"><a aria-hidden="true" href="#cb23-1" tabindex="-1"></a>b2 <span className="ot">&lt;-</span> <span className="st">"[[:upper:][:digit:]]"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb24"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb24-1"><a aria-hidden="true" href="#cb24-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, b2) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;E&gt;=mc^&lt;2&gt;...!
<br/>[2] │ &lt;P&gt; = &lt;M&gt; * &lt;A&gt;&lt;4&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb26"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb26-1"><a aria-hidden="true" href="#cb26-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(a, b2, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1] [,2] [,3] [,4]
<br/>[1,] "E"  "2"  ""   ""  
<br/>[2,] "P"  "M"  "A"  "4" </code></pre>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}