import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonConcatenatedataframes(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Concatenate-DataFrames">Concatenate DataFrames<a class="anchor-link" href="#Concatenate-DataFrames">¶</a></h3><p>Concatenating DataFrames is an alternative method for merging DataFrames. Unlike joining, it doesn't require shared common columns between two DataFrames.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This tutorial uses classic Iris dataset, which can be downloaded here <a href="https://3codeacademy.s3.amazonaws.com/dataset/python/Iris.csv" id="downloadData">Iris dataset</a>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">import</span> <span class="nn">pandas</span> <span class="k">as</span> <span class="nn">pd</span></span>

<span><span class="n">df</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">read_csv</span><span class="p">(</span><span class="s1">'Iris.csv'</span><span class="p"></span>)</span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>We will split the Iris data into two DataFrames. The first DataFrame, named <strong>df1</strong> will consist of the first 75 rows of records, while the remaining data will be assigned to <strong>df2</strong>. In both DataFrames, we will retain only the first two columns.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df1</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">loc</span><span class="p">[:</span><span class="mi">74</span><span class="p">,</span> <span class="p">[</span><span class="s1">'Id'</span><span class="p">,</span> <span class="s1">'SepalLengthCm'</span><span class="p">]]</span><span class="o">.</span><span class="n">reset_index</span><span class="p">(</span><span class="n">drop</span><span class="o">=</span><span class="kc">True</span><span class="p">)</span></span>

<span><span class="n">df</span>1</span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>5.1</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>4.9</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>4.7</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>4.6</td>
</tr>
<tr>
<th>4</th>
<td>5</td>
<td>5.0</td>
</tr>
<tr>
<th>...</th>
<td>...</td>
<td>...</td>
</tr>
<tr>
<th>70</th>
<td>71</td>
<td>5.9</td>
</tr>
<tr>
<th>71</th>
<td>72</td>
<td>6.1</td>
</tr>
<tr>
<th>72</th>
<td>73</td>
<td>6.3</td>
</tr>
<tr>
<th>73</th>
<td>74</td>
<td>6.1</td>
</tr>
<tr>
<th>74</th>
<td>75</td>
<td>6.4</td>
</tr>
</tbody>
</table>
<p>75 rows × 2 columns</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>We apply the <code>reset_index(drop=True)</code> method to <code>df2</code> to remove its index inherited from <code>df</code>. This ensures that there is no interference with the performance of the <code>pd.concat()</code> function in our subsequent examples.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df2</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">loc</span><span class="p">[</span><span class="mi">75</span><span class="p">:,</span> <span class="p">[</span><span class="s1">'Id'</span><span class="p">,</span><span class="s1">'SepalLengthCm'</span><span class="p">]]</span><span class="o">.</span><span class="n">reset_index</span><span class="p">(</span><span class="n">drop</span><span class="o">=</span><span class="kc">True</span><span class="p">)</span></span>

<span><span class="n">df</span>2</span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>76</td>
<td>6.6</td>
</tr>
<tr>
<th>1</th>
<td>77</td>
<td>6.8</td>
</tr>
<tr>
<th>2</th>
<td>78</td>
<td>6.7</td>
</tr>
<tr>
<th>3</th>
<td>79</td>
<td>6.0</td>
</tr>
<tr>
<th>4</th>
<td>80</td>
<td>5.7</td>
</tr>
<tr>
<th>...</th>
<td>...</td>
<td>...</td>
</tr>
<tr>
<th>70</th>
<td>146</td>
<td>6.7</td>
</tr>
<tr>
<th>71</th>
<td>147</td>
<td>6.3</td>
</tr>
<tr>
<th>72</th>
<td>148</td>
<td>6.5</td>
</tr>
<tr>
<th>73</th>
<td>149</td>
<td>6.2</td>
</tr>
<tr>
<th>74</th>
<td>150</td>
<td>5.9</td>
</tr>
</tbody>
</table>
<p>75 rows × 2 columns</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="1.-Combine-the-Two-DataFrames-Vertically-(along-rows)">1. Combine the Two DataFrames Vertically (along rows)<a class="anchor-link" href="#1.-Combine-the-Two-DataFrames-Vertically-(along-rows)">¶</a></h4><p>To merge <strong>df1</strong> and <strong>df2</strong> vertically, use the <code>pd.concat()</code> function and set the parameter <code>axis=0</code>. This operation will result in a total of 150 rows of records after concatenation.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df_vertical</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">concat</span><span class="p">([</span><span class="n">df1</span><span class="p">,</span> <span class="n">df2</span><span class="p">],</span> <span class="n">axis</span><span class="o">=</span><span class="mi">0</span><span class="p">)</span></span>

<span><span class="n">df_vertica</span>l</span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>5.1</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>4.9</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>4.7</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>4.6</td>
</tr>
<tr>
<th>4</th>
<td>5</td>
<td>5.0</td>
</tr>
<tr>
<th>...</th>
<td>...</td>
<td>...</td>
</tr>
<tr>
<th>70</th>
<td>146</td>
<td>6.7</td>
</tr>
<tr>
<th>71</th>
<td>147</td>
<td>6.3</td>
</tr>
<tr>
<th>72</th>
<td>148</td>
<td>6.5</td>
</tr>
<tr>
<th>73</th>
<td>149</td>
<td>6.2</td>
</tr>
<tr>
<th>74</th>
<td>150</td>
<td>5.9</td>
</tr>
</tbody>
</table>
<p>150 rows × 2 columns</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="2.-Concatenate-the-Two-DataFrames-Horizontally-(along-columns)">2. Concatenate the Two DataFrames Horizontally (along columns)<a class="anchor-link" href="#2.-Concatenate-the-Two-DataFrames-Horizontally-(along-columns)">¶</a></h4><p>To combine <strong>df1</strong> and <strong>df2</strong> horizontally, use the <code>pd.concat()</code> function and set the parameter <code>axis=1</code>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [13]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df_horizontal</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">concat</span><span class="p">([</span><span class="n">df1</span><span class="p">,</span> <span class="n">df2</span><span class="p">],</span> <span class="n">axis</span><span class="o">=</span><span class="mi">1</span><span class="p">)</span></span>

<span><span class="n">df_horizonta</span>l</span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div></div><div class="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" class="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
<th>Id</th>
<th>SepalLengthCm</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>5.1</td>
<td>76</td>
<td>6.6</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>4.9</td>
<td>77</td>
<td>6.8</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>4.7</td>
<td>78</td>
<td>6.7</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>4.6</td>
<td>79</td>
<td>6.0</td>
</tr>
<tr>
<th>4</th>
<td>5</td>
<td>5.0</td>
<td>80</td>
<td>5.7</td>
</tr>
<tr>
<th>...</th>
<td>...</td>
<td>...</td>
<td>...</td>
<td>...</td>
</tr>
<tr>
<th>70</th>
<td>71</td>
<td>5.9</td>
<td>146</td>
<td>6.7</td>
</tr>
<tr>
<th>71</th>
<td>72</td>
<td>6.1</td>
<td>147</td>
<td>6.3</td>
</tr>
<tr>
<th>72</th>
<td>73</td>
<td>6.3</td>
<td>148</td>
<td>6.5</td>
</tr>
<tr>
<th>73</th>
<td>74</td>
<td>6.1</td>
<td>149</td>
<td>6.2</td>
</tr>
<tr>
<th>74</th>
<td>75</td>
<td>6.4</td>
<td>150</td>
<td>5.9</td>
</tr>
</tbody>
</table>
<p>75 rows × 4 columns</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>This operation results in a new DatFrame with 4 columns and 75 rows, as the <strong>df1</strong> and <strong>df2</strong> have been combined horizontally.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Excellent job! We have successfully covered the techniques for concatenating DataFrames, concluding our Data Wrangling series. Nevertheless, it's important to note that this is just a fraction of what Python offers in the realm of data analysis and data science. Please explore our other tutorials on this site for further insights. We believe that you have found value in your learning journey with us. Reward yourself with some snacks to acknowledge and celebrate your dedicated efforts! 🍦🍫🍦</p>
</div>
</div>
</div>
</div>
</div>
)}