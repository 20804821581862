import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarEmojiHappyMoney from '../graphics/Emoji_happiness_wealth_annotation_completed.png'; 
import imgGgplot2BarEmojiHappyMoneyWebp from '../graphics/Emoji_happiness_wealth_annotation_completed.webp'; 
export default function Ggplot2BarEmojiHappyMoney(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Draw Bars and Emoji Faces in ggplot2 to Visualize Population Distribution in Happiness &amp; Wealth in the U.S.</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarEmojiHappyMoneyWebp} />
    <img className="cover-img" src={imgGgplot2BarEmojiHappyMoney} />
  </picture>

<p>In an earlier work, we created <Link to="../ggplot2-Waffle-plot-Chernoff-faces-wealth">Emoji / Chernoff faces in a Waffle grid</Link> to visualize the <em>univariate</em> population distribution at different financial status. As one step further, in this article, we’ll use bars, lines, and Emoji faces to visualize the population distribution in levels of happiness at different financial status, and the association between happiness and wealth.</p>
<p><strong>Major techniques illustrated in this article include:</strong></p>
<ul>
<li><a href="#Google_Fonts">Use Google Fonts.</a></li>
<li><a href="#position">Synchronize graphics in a stacked position.</a></li>
<li><a href="#Emoji">Draw Emoji/Chernoff faces using the <code>ggChernoff</code> package.</a></li>
<li><a href="#margin">Customize plot margin (e.g., add elements beyond the plot boundary).</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset used in this work is available via the <Link to="https://haleyjeppson.github.io/ggmosaic/"><code>ggmosaic</code></Link> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># use the "happy" dataset from this package</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="co"># install.packages("ggmosaic")</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a><span className="co"># draw Emoji / Chernoff faces</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="co"># devtools::install_github('Selbosh/ggChernoff')</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a><span className="fu">library</span>(ggChernoff)</span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a></span><br/>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>())</span></code></pre></div>
</div>
<p>Calculate the number of people in each combined level of happiness (<code>happy</code>) and financial status (<code>finrela</code>).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="co"># use only year 2018</span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2018</span> ) <span className="sc">%&gt;%</span></span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="co"># remove rows with NA values in the "happy" or "finrela" columns</span></span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span> (<span className="fu">is.na</span>(happy) <span className="sc">|</span> <span className="fu">is.na</span>(finrela))) <span className="sc">%&gt;%</span> </span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a>  </span>
<span id="cb2-7"><a aria-hidden="true" href="#cb2-7" tabindex="-1"></a>  <span className="co"># summarize the number of people in each happiness-wealth condition</span></span>
<span id="cb2-8"><a aria-hidden="true" href="#cb2-8" tabindex="-1"></a>  <span className="fu">group_by</span>(finrela, happy) <span className="sc">%&gt;%</span> </span>
<span id="cb2-9"><a aria-hidden="true" href="#cb2-9" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">N =</span> <span className="fu">n</span>()) <span className="sc">%&gt;%</span> </span>
<span id="cb2-10"><a aria-hidden="true" href="#cb2-10" tabindex="-1"></a>  </span>
<span id="cb2-11"><a aria-hidden="true" href="#cb2-11" tabindex="-1"></a>  <span className="co"># turn 'happy' into a factor variable with specified level order</span></span>
<span id="cb2-12"><a aria-hidden="true" href="#cb2-12" tabindex="-1"></a>  <span className="co"># so as to visualize happy people at the top of the bar plot</span></span>
<span id="cb2-13"><a aria-hidden="true" href="#cb2-13" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">happy =</span> <span className="fu">factor</span>(</span>
<span id="cb2-14"><a aria-hidden="true" href="#cb2-14" tabindex="-1"></a>    happy,<span className="at">levels =</span> <span className="fu">c</span>(<span className="st">"very happy"</span>, <span className="st">"pretty happy"</span>, <span className="st">"not too happy"</span>)))</span>
<span id="cb2-15"><a aria-hidden="true" href="#cb2-15" tabindex="-1"></a></span><br/>
<span id="cb2-16"><a aria-hidden="true" href="#cb2-16" tabindex="-1"></a><span className="fu">head</span>(h, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/># Groups:   finrela [1]
<br/>  finrela           happy             N
<br/>  &lt;fct&gt;             &lt;fct&gt;         &lt;int&gt;
<br/>1 far below average not too happy    50
<br/>2 far below average pretty happy     64
<br/>3 far below average very happy       39</code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="Google_Fonts">Load fonts from <Link to="https://fonts.google.com/">Google font repository</Link>.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Gochi Hand"</span>, <span className="at">family =</span> <span className="st">"gochi"</span>)</span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Schoolbell"</span>, <span className="at">family =</span> <span className="st">"bell"</span>)</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span></code></pre></div>
</div>
<p>Create a bar plot showing the population proportion with different levels of happiness and wealth. Note that <code>position = "fill"</code> stacks the bars on top of each other (not to be confused with the <code>fill</code> aesthetic), and normalizes the y axis onto a scale of [0, 1].</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span>  </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> finrela, <span className="at">y =</span> N, <span className="at">fill =</span> happy)) <span className="sc">+</span> </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"fill"</span>, <span className="at">alpha =</span> .<span className="dv">4</span>, <span className="at">width =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"white"</span>)</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_barplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_happiness_wealth_barplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="position">Add lines to highlight the relationship between the level of happiness and wealth. Note that the lines also take the <code>fill</code> position to be aligned with the bars. <code>vjust</code> positions the lines in the center of the corresponding bars.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>), </span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">group =</span> happy, <span className="at">color =</span> happy), </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>            <span className="at">linewidth =</span> <span className="dv">1</span>)</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_stacked_lines.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_happiness_wealth_stacked_lines"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="Emoji">Draw <Link to="https://en.wikipedia.org/wiki/Chernoff_face">Emoji / Chernoff</Link> faces to denote the happiness level using the <Link to="https://github.com/Selbosh/ggChernoff"><code>ggChernoff</code></Link> package. <code>geom_chernoff</code> is used in a similar way as <code>geom_point</code>, but creates emotional faces instead of points. <em>Numerical</em> variables are mapped to aesthetics <code>smile</code>, <code>eye</code>, or <code>brow</code> to display the emotion, and larger values correspond to happier faces. Note that the <code>unclass</code> function is used to turn the factor variable <code>happy</code> into numerical (integer) values to be mapped to the Chernoff aesthetics. The faces also take a <code>fill</code> position to be aligned with the bars and lines.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">geom_chernoff</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="co"># turn the factor variable into integer </span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">smile =</span> <span className="dv">3</span> <span className="sc">-</span> <span className="fu">unclass</span>(happy),</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>        <span className="at">brow =</span>  <span className="dv">3</span> <span className="sc">-</span> <span className="fu">unclass</span>(happy)), </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>    <span className="at">show.legend =</span> F, <span className="co"># no show the associated legend</span></span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">10</span>) </span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_chernoff_faces.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_happiness_wealth_chernoff_faces"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Revise the axial labels and titles, plot title, and theme.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">labels =</span> <span className="fu">c</span>(<span className="st">"$"</span>, <span className="st">"$$"</span>, <span className="st">"$$$"</span>, <span className="st">"$$$$"</span>, <span className="st">"$$$$$"</span>),</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a>                   <span className="at">name =</span> <span className="st">"Financial condition"</span>) <span className="sc">+</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">1</span>, .<span className="dv">2</span>), </span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"Proportion"</span>, </span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>                     <span className="at">expand =</span> <span className="fu">expansion</span>(<span className="at">mult =</span> <span className="dv">0</span>)) <span className="sc">+</span> </span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"happiness possitively related with money"</span>) <span className="sc">+</span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>  </span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>    <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">18</span>, <span className="at">family =</span> <span className="st">"bell"</span>),</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    </span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>    <span className="co"># increase margin above x-axis title, and on the right side of y-axis title</span></span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a>    <span className="co"># use t-r-b-l for top, right, bottom, and left, respectively</span></span>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>)), </span>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>    </span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">family =</span> <span className="st">"gochi"</span>, <span className="at">size =</span> <span className="dv">18</span>,</span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>))) </span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_labels_titles_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_happiness_wealth_labels_titles_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="margin">Label the level of happiness at the right side of the plot. As shown in the final two lines, displaying graphical elements beyond the default plot boundary typically involves two steps: <strong>(1)</strong> turn clip “off”, and <strong>(2)</strong> increase the plot margin size. (The two happiest-wealthiest faces were slightly clipped at the x-axis, but are now completely displayed)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">5.6</span>, <span className="co"># treat x-axis as a numerical scale  </span></span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(.<span className="dv">8</span>, .<span className="dv">3</span>, .<span className="dv">03</span>),</span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"- Very</span><span className="sc">\n</span><span className="st">  happy!"</span>, <span className="st">"- Good"</span>, <span className="st">"- Unhappy"</span>),</span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>,  <span className="co"># justify to the left </span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>, <span className="at">family =</span> <span className="st">"bell"</span>,</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a>           <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"firebrick4"</span>, <span className="st">"green4"</span>, <span className="st">"steelblue4"</span>)) <span className="sc">+</span></span>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>  </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a>  <span className="co"># do NOT clip elements that overflow beyond the plot boundary</span></span>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span></span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  <span className="co"># make space for the text annotation on the right side of plot </span></span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">80</span>, <span className="at">t =</span> <span className="dv">10</span>))</span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a></span><br/>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/Emoji_happiness_wealth_annotation_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/Emoji_happiness_wealth_annotation_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a></span><br/>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a><span className="co"># use the "happy" dataset from this package</span></span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a><span className="co"># install.packages("ggmosaic")</span></span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a><span className="fu">library</span>(ggmosaic) </span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a></span><br/>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a><span className="co"># draw Emoji / Chernoff faces</span></span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a><span className="co"># devtools::install_github('Selbosh/ggChernoff')</span></span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a><span className="fu">library</span>(ggChernoff)</span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a></span><br/>
<span id="cb10-12"><a aria-hidden="true" href="#cb10-12" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_classic</span>())</span>
<span id="cb10-13"><a aria-hidden="true" href="#cb10-13" tabindex="-1"></a></span><br/>
<span id="cb10-14"><a aria-hidden="true" href="#cb10-14" tabindex="-1"></a></span><br/>
<span id="cb10-15"><a aria-hidden="true" href="#cb10-15" tabindex="-1"></a><span className="co"># Calculate the number of people in each combined level of happiness and financial status. </span></span>
<span id="cb10-16"><a aria-hidden="true" href="#cb10-16" tabindex="-1"></a>h <span className="ot">&lt;-</span> happy <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb10-17"><a aria-hidden="true" href="#cb10-17" tabindex="-1"></a>  <span className="co"># use only year 2018</span></span>
<span id="cb10-18"><a aria-hidden="true" href="#cb10-18" tabindex="-1"></a>  <span className="fu">filter</span>(year <span className="sc">==</span> <span className="dv">2018</span> ) <span className="sc">%&gt;%</span></span>
<span id="cb10-19"><a aria-hidden="true" href="#cb10-19" tabindex="-1"></a>  <span className="co"># remove rows with NA values in the "happy" or "finrela" columns</span></span>
<span id="cb10-20"><a aria-hidden="true" href="#cb10-20" tabindex="-1"></a>  <span className="fu">filter</span>(<span className="sc">!</span> (<span className="fu">is.na</span>(happy) <span className="sc">|</span> <span className="fu">is.na</span>(finrela))) <span className="sc">%&gt;%</span> </span>
<span id="cb10-21"><a aria-hidden="true" href="#cb10-21" tabindex="-1"></a>  </span>
<span id="cb10-22"><a aria-hidden="true" href="#cb10-22" tabindex="-1"></a>  <span className="co"># summarize the number of people in each happiness-wealth condition</span></span>
<span id="cb10-23"><a aria-hidden="true" href="#cb10-23" tabindex="-1"></a>  <span className="fu">group_by</span>(finrela, happy) <span className="sc">%&gt;%</span> </span>
<span id="cb10-24"><a aria-hidden="true" href="#cb10-24" tabindex="-1"></a>  <span className="fu">summarise</span>(<span className="at">N =</span> <span className="fu">n</span>()) <span className="sc">%&gt;%</span> </span>
<span id="cb10-25"><a aria-hidden="true" href="#cb10-25" tabindex="-1"></a>  </span>
<span id="cb10-26"><a aria-hidden="true" href="#cb10-26" tabindex="-1"></a>  <span className="co"># turn 'happy' into a factor variable with specified level order</span></span>
<span id="cb10-27"><a aria-hidden="true" href="#cb10-27" tabindex="-1"></a>  <span className="co"># so as to visualize happy people at the top of the bar plot</span></span>
<span id="cb10-28"><a aria-hidden="true" href="#cb10-28" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">happy =</span> <span className="fu">factor</span>(</span>
<span id="cb10-29"><a aria-hidden="true" href="#cb10-29" tabindex="-1"></a>    happy,<span className="at">levels =</span> <span className="fu">c</span>(<span className="st">"very happy"</span>, <span className="st">"pretty happy"</span>, <span className="st">"not too happy"</span>)))</span>
<span id="cb10-30"><a aria-hidden="true" href="#cb10-30" tabindex="-1"></a></span><br/>
<span id="cb10-31"><a aria-hidden="true" href="#cb10-31" tabindex="-1"></a><span className="fu">head</span>(h, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb10-32"><a aria-hidden="true" href="#cb10-32" tabindex="-1"></a></span><br/>
<span id="cb10-33"><a aria-hidden="true" href="#cb10-33" tabindex="-1"></a></span><br/>
<span id="cb10-34"><a aria-hidden="true" href="#cb10-34" tabindex="-1"></a><span className="co"># Load font from [Google font repository](https://fonts.google.com/). </span></span>
<span id="cb10-35"><a aria-hidden="true" href="#cb10-35" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb10-36"><a aria-hidden="true" href="#cb10-36" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Gochi Hand"</span>, <span className="at">family =</span> <span className="st">"gochi"</span>)</span>
<span id="cb10-37"><a aria-hidden="true" href="#cb10-37" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="at">name =</span> <span className="st">"Schoolbell"</span>, <span className="at">family =</span> <span className="st">"bell"</span>)</span>
<span id="cb10-38"><a aria-hidden="true" href="#cb10-38" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb10-39"><a aria-hidden="true" href="#cb10-39" tabindex="-1"></a></span><br/>
<span id="cb10-40"><a aria-hidden="true" href="#cb10-40" tabindex="-1"></a></span><br/>
<span id="cb10-41"><a aria-hidden="true" href="#cb10-41" tabindex="-1"></a><span className="co"># Create a bar plot showing happiness distribution at different financial condition</span></span>
<span id="cb10-42"><a aria-hidden="true" href="#cb10-42" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> h <span className="sc">%&gt;%</span>  </span>
<span id="cb10-43"><a aria-hidden="true" href="#cb10-43" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> finrela, <span className="at">y =</span> N, <span className="at">fill =</span> happy)) <span className="sc">+</span> </span>
<span id="cb10-44"><a aria-hidden="true" href="#cb10-44" tabindex="-1"></a>  <span className="fu">geom_col</span>(<span className="at">position =</span> <span className="st">"fill"</span>, <span className="at">alpha =</span> .<span className="dv">4</span>, <span className="at">width =</span> <span className="dv">1</span>, <span className="at">color =</span> <span className="st">"white"</span>)</span>
<span id="cb10-45"><a aria-hidden="true" href="#cb10-45" tabindex="-1"></a>p1</span>
<span id="cb10-46"><a aria-hidden="true" href="#cb10-46" tabindex="-1"></a></span><br/>
<span id="cb10-47"><a aria-hidden="true" href="#cb10-47" tabindex="-1"></a></span><br/>
<span id="cb10-48"><a aria-hidden="true" href="#cb10-48" tabindex="-1"></a><span className="co"># Add lines to highlight the relation between happiness and financial condition</span></span>
<span id="cb10-49"><a aria-hidden="true" href="#cb10-49" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> </span>
<span id="cb10-50"><a aria-hidden="true" href="#cb10-50" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>), </span>
<span id="cb10-51"><a aria-hidden="true" href="#cb10-51" tabindex="-1"></a>            <span className="fu">aes</span>(<span className="at">group =</span> happy, <span className="at">color =</span> happy), </span>
<span id="cb10-52"><a aria-hidden="true" href="#cb10-52" tabindex="-1"></a>            <span className="at">linewidth =</span> <span className="dv">1</span>)</span>
<span id="cb10-53"><a aria-hidden="true" href="#cb10-53" tabindex="-1"></a>p2</span>
<span id="cb10-54"><a aria-hidden="true" href="#cb10-54" tabindex="-1"></a></span><br/>
<span id="cb10-55"><a aria-hidden="true" href="#cb10-55" tabindex="-1"></a></span><br/>
<span id="cb10-56"><a aria-hidden="true" href="#cb10-56" tabindex="-1"></a><span className="co"># Draw Emoji / Chernoff faces. </span></span>
<span id="cb10-57"><a aria-hidden="true" href="#cb10-57" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> </span>
<span id="cb10-58"><a aria-hidden="true" href="#cb10-58" tabindex="-1"></a>  <span className="fu">geom_chernoff</span>(</span>
<span id="cb10-59"><a aria-hidden="true" href="#cb10-59" tabindex="-1"></a>    <span className="co"># turn the factor variable into integer </span></span>
<span id="cb10-60"><a aria-hidden="true" href="#cb10-60" tabindex="-1"></a>    <span className="fu">aes</span>(<span className="at">smile =</span> <span className="dv">3</span> <span className="sc">-</span> <span className="fu">unclass</span>(happy),</span>
<span id="cb10-61"><a aria-hidden="true" href="#cb10-61" tabindex="-1"></a>        <span className="at">brow =</span>  <span className="dv">3</span> <span className="sc">-</span> <span className="fu">unclass</span>(happy)), </span>
<span id="cb10-62"><a aria-hidden="true" href="#cb10-62" tabindex="-1"></a>    <span className="at">position =</span> <span className="fu">position_fill</span>(<span className="at">vjust =</span> .<span className="dv">5</span>),</span>
<span id="cb10-63"><a aria-hidden="true" href="#cb10-63" tabindex="-1"></a>    <span className="at">show.legend =</span> F, <span className="co"># no show the associated legend</span></span>
<span id="cb10-64"><a aria-hidden="true" href="#cb10-64" tabindex="-1"></a>    <span className="at">size =</span> <span className="dv">10</span>) </span>
<span id="cb10-65"><a aria-hidden="true" href="#cb10-65" tabindex="-1"></a>p3</span>
<span id="cb10-66"><a aria-hidden="true" href="#cb10-66" tabindex="-1"></a></span><br/>
<span id="cb10-67"><a aria-hidden="true" href="#cb10-67" tabindex="-1"></a></span><br/>
<span id="cb10-68"><a aria-hidden="true" href="#cb10-68" tabindex="-1"></a><span className="co">#Revise the axial labels and titles, plot title, and theme.</span></span>
<span id="cb10-69"><a aria-hidden="true" href="#cb10-69" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb10-70"><a aria-hidden="true" href="#cb10-70" tabindex="-1"></a>  <span className="fu">scale_x_discrete</span>(<span className="at">labels =</span> <span className="fu">c</span>(<span className="st">"$"</span>, <span className="st">"$$"</span>, <span className="st">"$$$"</span>, <span className="st">"$$$$"</span>, <span className="st">"$$$$$"</span>),</span>
<span id="cb10-71"><a aria-hidden="true" href="#cb10-71" tabindex="-1"></a>                   <span className="at">name =</span> <span className="st">"Financial condition"</span>) <span className="sc">+</span></span>
<span id="cb10-72"><a aria-hidden="true" href="#cb10-72" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">0</span>, <span className="dv">1</span>, .<span className="dv">2</span>), </span>
<span id="cb10-73"><a aria-hidden="true" href="#cb10-73" tabindex="-1"></a>                     <span className="at">name =</span> <span className="st">"Proportion"</span>, </span>
<span id="cb10-74"><a aria-hidden="true" href="#cb10-74" tabindex="-1"></a>                     <span className="at">expand =</span> <span className="fu">expansion</span>(<span className="at">mult =</span> <span className="dv">0</span>)) <span className="sc">+</span> </span>
<span id="cb10-75"><a aria-hidden="true" href="#cb10-75" tabindex="-1"></a>  <span className="fu">ggtitle</span>(<span className="st">"happiness possitively related with money"</span>) <span className="sc">+</span></span>
<span id="cb10-76"><a aria-hidden="true" href="#cb10-76" tabindex="-1"></a>  </span>
<span id="cb10-77"><a aria-hidden="true" href="#cb10-77" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb10-78"><a aria-hidden="true" href="#cb10-78" tabindex="-1"></a>    <span className="at">axis.line =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb10-79"><a aria-hidden="true" href="#cb10-79" tabindex="-1"></a>    <span className="at">legend.position =</span> <span className="st">"none"</span>,</span>
<span id="cb10-80"><a aria-hidden="true" href="#cb10-80" tabindex="-1"></a>    <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">size =</span> <span className="dv">18</span>, <span className="at">family =</span> <span className="st">"bell"</span>),</span>
<span id="cb10-81"><a aria-hidden="true" href="#cb10-81" tabindex="-1"></a>    </span>
<span id="cb10-82"><a aria-hidden="true" href="#cb10-82" tabindex="-1"></a>    <span className="co"># increase margin above x-axis title, and on the right side of y-axis title</span></span>
<span id="cb10-83"><a aria-hidden="true" href="#cb10-83" tabindex="-1"></a>    <span className="co"># use t-r-b-l for top, right, bottom, and left, respectively</span></span>
<span id="cb10-84"><a aria-hidden="true" href="#cb10-84" tabindex="-1"></a>    <span className="at">axis.title.x =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">10</span>)), </span>
<span id="cb10-85"><a aria-hidden="true" href="#cb10-85" tabindex="-1"></a>    <span className="at">axis.title.y =</span> <span className="fu">element_text</span>(<span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">10</span>)),</span>
<span id="cb10-86"><a aria-hidden="true" href="#cb10-86" tabindex="-1"></a>    </span>
<span id="cb10-87"><a aria-hidden="true" href="#cb10-87" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">family =</span> <span className="st">"gochi"</span>,</span>
<span id="cb10-88"><a aria-hidden="true" href="#cb10-88" tabindex="-1"></a>                              <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">10</span>, <span className="at">unit =</span> <span className="st">"pt"</span>))) </span>
<span id="cb10-89"><a aria-hidden="true" href="#cb10-89" tabindex="-1"></a>p4</span>
<span id="cb10-90"><a aria-hidden="true" href="#cb10-90" tabindex="-1"></a></span><br/>
<span id="cb10-91"><a aria-hidden="true" href="#cb10-91" tabindex="-1"></a></span><br/>
<span id="cb10-92"><a aria-hidden="true" href="#cb10-92" tabindex="-1"></a><span className="co"># Label the happiness level at the right edge of the plot.</span></span>
<span id="cb10-93"><a aria-hidden="true" href="#cb10-93" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb10-94"><a aria-hidden="true" href="#cb10-94" tabindex="-1"></a>  <span className="fu">annotate</span>(<span className="at">geom =</span> <span className="st">"text"</span>, </span>
<span id="cb10-95"><a aria-hidden="true" href="#cb10-95" tabindex="-1"></a>           <span className="at">x =</span> <span className="fl">5.6</span>, <span className="co"># treat x-axis as a numerical scale  </span></span>
<span id="cb10-96"><a aria-hidden="true" href="#cb10-96" tabindex="-1"></a>           <span className="at">y =</span> <span className="fu">c</span>(.<span className="dv">8</span>, .<span className="dv">3</span>, .<span className="dv">03</span>),</span>
<span id="cb10-97"><a aria-hidden="true" href="#cb10-97" tabindex="-1"></a>           <span className="at">label =</span> <span className="fu">c</span>(<span className="st">"- Very</span><span className="sc">\n</span><span className="st">  happy!"</span>, <span className="st">"- Good"</span>, <span className="st">"- Unhappy"</span>),</span>
<span id="cb10-98"><a aria-hidden="true" href="#cb10-98" tabindex="-1"></a>           <span className="at">hjust =</span> <span className="dv">0</span>,  <span className="co"># justify to the left </span></span>
<span id="cb10-99"><a aria-hidden="true" href="#cb10-99" tabindex="-1"></a>           <span className="at">fontface =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">5</span>, <span className="at">family =</span> <span className="st">"bell"</span>,</span>
<span id="cb10-100"><a aria-hidden="true" href="#cb10-100" tabindex="-1"></a>           <span className="at">color =</span> <span className="fu">c</span>(<span className="st">"firebrick4"</span>, <span className="st">"green4"</span>, <span className="st">"steelblue4"</span>)) <span className="sc">+</span></span>
<span id="cb10-101"><a aria-hidden="true" href="#cb10-101" tabindex="-1"></a>  </span>
<span id="cb10-102"><a aria-hidden="true" href="#cb10-102" tabindex="-1"></a>  <span className="co"># do NOT clip elements that overflow beyond the plot boundary</span></span>
<span id="cb10-103"><a aria-hidden="true" href="#cb10-103" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span></span>
<span id="cb10-104"><a aria-hidden="true" href="#cb10-104" tabindex="-1"></a>  <span className="co"># make space for the text annotation on the right side of plot </span></span>
<span id="cb10-105"><a aria-hidden="true" href="#cb10-105" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="at">r =</span> <span className="dv">80</span>, <span className="at">t =</span> <span className="dv">10</span>))</span>
<span id="cb10-106"><a aria-hidden="true" href="#cb10-106" tabindex="-1"></a></span><br/>
<span id="cb10-107"><a aria-hidden="true" href="#cb10-107" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>The bar plot above visualizes the <em>univariate</em> population distribution in happiness <em>conditioned</em> to each financial status, without showing the population distribution at different financial condition. To make the visualization more informative, we can use the <strong>mosaic plot</strong> to visualize the <strong>bivariate</strong> distribution, or the contingency table of two variables. Check out the following article below on how to <Link to="../ggplot2-mosaic-health-wealth"><strong>create a mosaic plot to visualize the population composition at varied levels of wealth and health status</strong></Link>.</p>
<p><Link to="../ggplot2-mosaic-health-wealth">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/mosaic_happy_theme_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In addition to the barplot and mosaic plot as illustrated above, pie charts and donut charts are often used to present proportional data. Check out the following <Link to="../ggplot2-donut-plot-admission"><strong>exploded donut charts in faceted layout</strong></Link> that visualizes the admission rates in UC Berkeley in 1973s.</p>
<p><Link to="../ggplot2-donut-plot-admission">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/donut_plot_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/donut_plot_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}