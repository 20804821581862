import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Map2 from '../RDataWrangling/purrr/contents/map2_output_react'
import PluckPart2 from '../RDataWrangling/purrr/contents/pluck-part2_output_react'
import Pmap from '../RDataWrangling/purrr/contents/pmap_output_react'
import PluckPart1 from '../RDataWrangling/purrr/contents/pluck-part1_output_react'
// import Pluck from '../RDataWrangling/purrr/contents/pluck_output_react'
import Map from '../RDataWrangling/purrr/contents/map_output_react'
import Introduction from '../RDataWrangling/purrr/contents/introduction_output_react'

const RpurrrData=[
{'component': <Introduction />, 'path': 'introduction', 'title': '0. introduction'},
{id:uuidv4(), path:'', title: 'Element-wise Operations'}, 
{'component': <Map />, 'path': 'map', 'title': '1. work on a single input'},
{'component': <Map2 />, 'path': 'map2', 'title': '2. paired two inputs'},
{'component': <Pmap />, 'path': 'pmap', 'title': '3. multiple inputs in parallel'},
// {'component': <Pluck />, 'path': 'pluck', 'title': 'pluck'},

{id:uuidv4(), path:'', title: 'Extract Elements from Nested Data Structure'}, 
{'component': <PluckPart1 />, 'path': 'pluck-part1', 'title': '4. basics of pluck'},
{'component': <PluckPart2 />, 'path': 'pluck-part2', 'title': '5. variants of pluck'},
]

export default RpurrrData