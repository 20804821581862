import React from 'react'; 
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkDayofweek(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="dayofweek"><code>dayofweek()</code><a class="anchor-link" href="#dayofweek">¶</a></h3><p>The <code>dayofweek()</code> function is used to extract the day of the week from a date or timestamp column. This function returns an integer value representing the day, where Sunday is 1, Monday is 2, and so forth, up to Saturday, which is 7.</p>
<h4 id="Other-similar-functions">Other similar functions<a class="anchor-link" href="#Other-similar-functions">¶</a></h4><ul>
<li><code>weekofyear()</code>: it returns an integer value representing the week of the year.</li>
<li><code>dayofyear()</code>: it returns an integer value representing the day of the year.</li>
<li><code>year()</code>: it returns an integer value representing the year</li>
<li><code>quarter()</code>: it returns an integer value representing the quarter of the year.</li>
<li><code>month()</code>: it returns an integer value representing the month of the year.</li>
<li><code>minute()</code>: it returns an integer value representing the minute of a datetime.</li>
<li><code>second()</code>: it returns an integer value representing the second of a datetime.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session-and-sample-DataFrame">Create Spark Session and sample DataFrame<a class="anchor-link" href="#Create-Spark-Session-and-sample-DataFrame">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>
<span><span class="kn">from</span> <span class="nn">pyspark.sql.functions</span> <span class="kn">import</span> <span class="n">dayofweek</span><span class="p">,</span> <span class="n">to_date</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"dayofweekExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>

<br /><span><span class="c1"># Sample DataFrame with Date Strings</span></span>
<span><span class="n">data</span> <span class="o">=</span> <span class="p">[(</span><span class="s2">"2023-07-01"</span><span class="p">,),</span> <span class="p">(</span><span class="s2">"2023-08-24"</span><span class="p">,),</span> <span class="p">(</span><span class="s2">"2023-10-11"</span><span class="p">,)]</span></span>
<span><span class="n">columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"Date"</span><span class="p">]</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="p">,</span> <span class="n">columns</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+----------+
<br />|      Date|
<br />+----------+
<br />|2023-07-01|
<br />|2023-08-24|
<br />|2023-10-11|
<br />+----------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Use-dayofweek-to-get-day-of-a-week">Example: Use <code>dayofweek</code> to get day of a week<a class="anchor-link" href="#Example:-Use-dayofweek-to-get-day-of-a-week">¶</a></h4><ul>
<li><code>to_date("Date")</code>: it converts the <strong>Date</strong> column from string type to date type.</li>
<li><code>dayofweek("Date")</code>: it returns the day of a week for the <strong>Date</strong> Column.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Convert String to Date Type</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">withColumn</span><span class="p">(</span><span class="s2">"Date"</span><span class="p">,</span> <span class="n">to_date</span><span class="p">(</span><span class="s2">"Date"</span><span class="p">))</span></span>

<br /><span><span class="c1"># Extracting Day of Week</span></span>
<span><span class="n">day_of_week_df</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">withColumn</span><span class="p">(</span><span class="s2">"Day of Week"</span><span class="p">,</span> <span class="n">dayofweek</span><span class="p">(</span><span class="s2">"Date"</span><span class="p">))</span></span>
<span><span class="n">day_of_week_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+----------+-----------+
<br />|      Date|Day of Week|
<br />+----------+-----------+
<br />|2023-07-01|          7|
<br />|2023-08-24|          5|
<br />|2023-10-11|          4|
<br />+----------+-----------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}