import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrTrunc(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Truncate a String to a Fixed Length</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_trunc()</code></strong> is similar to <code>str_pad()</code>, but truncates the strings to a fixed length specified by the <code>width</code> argument. By default, the extra length beyond the <code>width</code> value on the right side of the string will be truncated and replaced with ellipsis <code>...</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a></span><br/>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"art"</span>, <span className="st">"introduction of the modern Art"</span>)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="co"># reduce each string to a max of 10 characters, including ellipsis</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">str_trunc</span>(x, <span className="at">width =</span> <span className="dv">10</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "art"        "introdu..."</code></pre>
</div>
</div>
<p>You can truncate the extra length from the left side, and use a different set of characters in replace of the ellipsis.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_trunc</span>(x, <span className="at">width =</span> <span className="dv">10</span>, <span className="at">side =</span> <span className="st">"left"</span>, <span className="at">ellipsis =</span> <span className="st">"**"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "art"        "**dern Art"</code></pre>
</div>
</div>
<p>Not use ellipsis or special characters.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_trunc</span>(x, <span className="at">width =</span> <span className="dv">10</span>, <span className="at">side =</span> <span className="st">"right"</span>, <span className="at">ellipsis =</span> <span className="st">""</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "art"        "introducti"</code></pre>
</div>
</div>
<h4 className="anchored">
🏆 <strong>One level up !</strong>
</h4>
<p>If you want to ensure that all strings are the same length (often useful for print methods), consider combining <Link to="../7-str-pad"><code>str_pad()</code></Link> and <code>str_trunc()</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="co"># load the pipeline operator</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a><span className="fu">library</span>(dplyr) </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a></span><br/>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>cuisine <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"wine"</span>, <span className="st">"mozzarella"</span>, </span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>             <span className="st">"grilled beef"</span>, <span className="st">"baked potato and onion"</span>)</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a></span><br/>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>cuisine <span className="sc">%&gt;%</span> </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>  <span className="co"># truncate long strings to width 10</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="fu">str_trunc</span>(<span className="dv">10</span>, <span className="at">ellipsis =</span> <span className="st">""</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  <span className="co"># pad short strings to width 10</span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="fu">str_pad</span>(<span className="dv">10</span>, <span className="at">side =</span> <span className="st">"right"</span>, <span className="at">pad =</span> <span className="st">"-"</span>)  </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "wine------" "mozzarella" "grilled be" "baked pota"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}