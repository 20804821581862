import React from 'react'; 
import { Link } from 'react-router-dom';
import useCustomEffect from '../../useCustomEffect'; 
export default function SparkAgg(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="agg"><code>agg()</code><a class="anchor-link" href="#agg">¶</a></h3><p>In Apache Spark, the <code>agg()</code> function is used to perform complex aggregations on a DataFrame. It is often used after a <code>groupBy()</code> operation and can apply multiple aggregation functions at once.</p>
<h4 id="How-It-Works">How It Works<a class="anchor-link" href="#How-It-Works">¶</a></h4><ul>
<li><code>agg()</code> allows you to compute different types of aggregations (like <Link to='../sum'>sum</Link>, <Link to='../avg'>average</Link>, <Link to='../max'>maximum</Link>) on various DataFrame columns.</li>
<li>It can be used alongside <code>groupBy()</code> to perform these aggregations on groups of data.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Create-Spark-Session-and-sample-DataFrame">Create Spark Session and sample DataFrame<a class="anchor-link" href="#Create-Spark-Session-and-sample-DataFrame">¶</a></h4>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [9]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>
<span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">functions</span> <span class="k">as</span> <span class="n">F</span></span>

<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"aggExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>

<br /><span><span class="c1"># Sample DataFrame</span></span>
<span><span class="n">data</span> <span class="o">=</span> <span class="p">[(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"Sales"</span><span class="p">,</span> <span class="mi">3000</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Michael"</span><span class="p">,</span> <span class="s2">"Sales"</span><span class="p">,</span> <span class="mi">4600</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span> <span class="s2">"Sales"</span><span class="p">,</span> <span class="mi">4100</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Maria"</span><span class="p">,</span> <span class="s2">"Finance"</span><span class="p">,</span> <span class="mi">3000</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"James"</span><span class="p">,</span> <span class="s2">"Sales"</span><span class="p">,</span> <span class="mi">3000</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Scott"</span><span class="p">,</span> <span class="s2">"Finance"</span><span class="p">,</span> <span class="mi">3300</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Jen"</span><span class="p">,</span> <span class="s2">"Finance"</span><span class="p">,</span> <span class="mi">3900</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Jeff"</span><span class="p">,</span> <span class="s2">"Marketing"</span><span class="p">,</span> <span class="mi">3000</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Kumar"</span><span class="p">,</span> <span class="s2">"Marketing"</span><span class="p">,</span> <span class="mi">2000</span><span class="p">),</span></span>
<span>        <span class="p">(</span><span class="s2">"Saif"</span><span class="p">,</span> <span class="s2">"Sales"</span><span class="p">,</span> <span class="mi">4100</span><span class="p">)]</span></span>
<span><span class="n">columns</span> <span class="o">=</span> <span class="p">[</span><span class="s2">"employee_name"</span><span class="p">,</span> <span class="s2">"department"</span><span class="p">,</span> <span class="s2">"salary"</span><span class="p">]</span></span>

<br /><span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="p">,</span> <span class="n">schema</span><span class="o">=</span><span class="n">columns</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+-------------+----------+------+
<br />|employee_name|department|salary|
<br />+-------------+----------+------+
<br />|        James|     Sales|  3000|
<br />|      Michael|     Sales|  4600|
<br />|       Robert|     Sales|  4100|
<br />|        Maria|   Finance|  3000|
<br />|        James|     Sales|  3000|
<br />|        Scott|   Finance|  3300|
<br />|          Jen|   Finance|  3900|
<br />|         Jeff| Marketing|  3000|
<br />|        Kumar| Marketing|  2000|
<br />|         Saif|     Sales|  4100|
<br />+-------------+----------+------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Example:-Apply-multiple-aggregate-functions-on-grouped-data">Example: Apply multiple aggregate functions on grouped data<a class="anchor-link" href="#Example:-Apply-multiple-aggregate-functions-on-grouped-data">¶</a></h4><ul>
<li>We're going to group the DataFrame <strong>df</strong> by the <strong>department</strong> column.<br/></li>
<li>The <strong>agg</strong> function is used to calculate the <code>average</code>, <code>sum</code>, and <code>maximum</code> salary for each department. And we use the <strong>alias</strong> function to rename the output columns for better readability.</li>
</ul>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [11]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># GroupBy and Agg</span></span>
<span><span class="n">agg_df</span> <span class="o">=</span> <span class="n">df</span><span class="o">.</span><span class="n">groupBy</span><span class="p">(</span><span class="s2">"department"</span><span class="p">)</span></span>
<span>           <span class="o">.</span><span class="n">agg</span><span class="p">(</span></span>
<span>                <span class="n">F</span><span class="o">.</span><span class="n">avg</span><span class="p">(</span><span class="s2">"salary"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"averageSalary"</span><span class="p">),</span></span>
<span>                <span class="n">F</span><span class="o">.</span><span class="n">sum</span><span class="p">(</span><span class="s2">"salary"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"totalSalary"</span><span class="p">),</span></span>
<span>                <span class="n">F</span><span class="o">.</span><span class="n">max</span><span class="p">(</span><span class="s2">"salary"</span><span class="p">)</span><span class="o">.</span><span class="n">alias</span><span class="p">(</span><span class="s2">"maxSalary"</span><span class="p">)</span></span>
<span>            <span class="p">)</span></span>
<span><span class="n">agg_df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>+----------+-------------+-----------+---------+
<br />|department|averageSalary|totalSalary|maxSalary|
<br />+----------+-------------+-----------+---------+
<br />|     Sales|       3760.0|      18800|     4600|
<br />|   Finance|       3400.0|      10200|     3900|
<br />| Marketing|       2500.0|       5000|     3000|
<br />+----------+-------------+-----------+---------+
<br /></code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>
<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}