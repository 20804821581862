import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import KeepDistinctRows from '../RDataWrangling/dplyr/contents/8-keep-distinct-rows_output_react'
import GlimpseColumns from '../RDataWrangling/dplyr/contents/10-glimpse-columns_output_react'
import BindTwoDatasetsByColumnsRows from '../RDataWrangling/dplyr/contents/21-bind-two-datasets-by-columns-rows_output_react'
import NestJoinTwoDatasets from '../RDataWrangling/dplyr/contents/19-nest-join-two-datasets_output_react'
import CrossJoinTwoDatasets from '../RDataWrangling/dplyr/contents/20-cross-join-two-datasets_output_react'
import RepeatedOperationsOnMultipleColumn from '../RDataWrangling/dplyr/contents/14-repeated-operations-on-multiple-column_output_react'
import CountObservations from '../RDataWrangling/dplyr/contents/16-count-observations_output_react'
import Summarize from '../RDataWrangling/dplyr/contents/5-summarize_output_react'
import CountUniqueValues from '../RDataWrangling/dplyr/contents/24-count-unique-values_output_react'
import ReorderColumns from '../RDataWrangling/dplyr/contents/13-reorder-columns_output_react'
import MutatingJoinTwoDatasets from '../RDataWrangling/dplyr/contents/17-mutating-join-two-datasets_output_react'
import FilteringJoinTwoDatasets from '../RDataWrangling/dplyr/contents/18-filtering-join-two-datasets_output_react'
import GroupedDataset from '../RDataWrangling/dplyr/contents/6-grouped-dataset_output_react'
import Arrange from '../RDataWrangling/dplyr/contents/7-arrange_output_react'
import SelectColumns from '../RDataWrangling/dplyr/contents/2-select-columns_output_react'
import RenameColumns from '../RDataWrangling/dplyr/contents/12-rename-columns_output_react'
import FilterRows from '../RDataWrangling/dplyr/contents/3-filter-rows_output_react'
import Introduction from '../RDataWrangling/dplyr/contents/0-introduction_output_react'
import RowwiseOperations from '../RDataWrangling/dplyr/contents/15-rowwise-operations_output_react'
import MutateColumns from '../RDataWrangling/dplyr/contents/4-mutate-columns_output_react'
import DataMasking from '../RDataWrangling/dplyr/contents/25-data-masking_output_react'
import BetweenARange from '../RDataWrangling/dplyr/contents/23-between-a-range_output_react'
import ExtractASingleColumn from '../RDataWrangling/dplyr/contents/11-extract-a-single-column_output_react'
import SetOperations from '../RDataWrangling/dplyr/contents/22-set-operations_output_react'
import PipeOperator from '../RDataWrangling/dplyr/contents/1-pipe-operator_output_react'
import SelectRows from '../RDataWrangling/dplyr/contents/9-select-rows_output_react'

const RdplyrData=[
// {path:'0-introduction', title:'0 Introduction'},

 {'component': <Introduction />, 'path': '0-introduction', 'title': 'introduction'},

 {id:uuidv4(), path:'', title: 'TOP 7 Functions'}, 

{'component': <PipeOperator />, 'path': '1-pipe-operator', 'title': '1. pipe operator'},
{'component': <SelectColumns />, 'path': '2-select-columns', 'title': '2. select columns'},
{'component': <FilterRows />, 'path': '3-filter-rows', 'title': '3. filter rows'},
{'component': <MutateColumns />, 'path': '4-mutate-columns', 'title': '4. mutate columns'},
{'component': <Summarize />, 'path': '5-summarize', 'title': '5. summarize'},
{'component': <GroupedDataset />, 'path': '6-grouped-dataset', 'title': '6. grouped dataset'},
{'component': <Arrange />, 'path': '7-arrange', 'title': '7. arrange'},

{id:uuidv4(), path:'', title: 'Work on Row'}, 
{'component': <KeepDistinctRows />, 'path': '8-keep-distinct-rows', 'title': '8. keep distinct rows'},
{'component': <CountUniqueValues />, 'path': '24-count-unique-values', 'title': '9. count unique values'},
{'component': <SelectRows />, 'path': '9-select-rows', 'title': '10. select rows'},
{'component': <CountObservations />, 'path': '16-count-observations', 'title': '11. count observations'},
{'component': <BetweenARange />, 'path': '23-between-a-range', 'title': '12. between a range'},


{id:uuidv4(), path:'', title: 'Work on Columns'}, 
{'component': <GlimpseColumns />, 'path': '10-glimpse-columns', 'title': '13. glimpse columns'},
{'component': <ExtractASingleColumn />, 'path': '11-extract-a-single-column', 'title': '14. extract a single column'},
{'component': <RenameColumns />, 'path': '12-rename-columns', 'title': '15. rename columns'},
{'component': <ReorderColumns />, 'path': '13-reorder-columns', 'title': '16. reorder columns'},

{id:uuidv4(), path:'', title: 'Repeated Operations'}, 
{'component': <RepeatedOperationsOnMultipleColumn />, 'path': '14-repeated-operations-on-multiple-column', 'title': '17. repeated operations on multiple column'},
{'component': <RowwiseOperations />, 'path': '15-rowwise-operations', 'title': '18. rowwise operations'},

{id:uuidv4(), path:'', title: 'Paired Datasets'}, 
{'component': <MutatingJoinTwoDatasets />, 'path': '17-mutating-join-two-datasets', 'title': '19. mutating join two datasets'},
{'component': <FilteringJoinTwoDatasets />, 'path': '18-filtering-join-two-datasets', 'title': '20. filtering join two datasets'},
{'component': <NestJoinTwoDatasets />, 'path': '19-nest-join-two-datasets', 'title': '21. nest join two datasets'},
{'component': <CrossJoinTwoDatasets />, 'path': '20-cross-join-two-datasets', 'title': '22. cross join two datasets'},
{'component': <BindTwoDatasetsByColumnsRows />, 'path': '21-bind-two-datasets-by-columns-rows', 'title': '23. bind two datasets by columns rows'},
{'component': <SetOperations />, 'path': '22-set-operations', 'title': '24. set operations'},

{id:uuidv4(), path:'', title: 'Functional Programming'}, 
{'component': <DataMasking />, 'path': '25-data-masking', 'title': '25. data masking'},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
]

export default RdplyrData