import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PivotWiderPart3(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Spread Columns into Wider Dataset (3/3): <em>Generate new columns from multiple variables</em></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>In this section, we will discuss how to widen datasets by generating new columns from <em>multiple variables</em> in <code>pivot_wider()</code>. We’ll demonstrate the variable combinations in <code>names_from</code> and <code>values_from</code> respectively using the following two examples.</p>
<p><strong>e.g.1.</strong></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>production <span className="ot">&lt;-</span> <span className="fu">expand_grid</span>(</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="at">product =</span> <span className="fu">c</span>(<span className="st">"A"</span>, <span className="st">"B"</span>), </span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="at">country =</span> <span className="fu">c</span>(<span className="st">"UK"</span>, <span className="st">"USA"</span>), </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="at">year =</span> <span className="dv">2023</span><span className="sc">:</span><span className="dv">2025</span>) <span className="sc">%&gt;%</span></span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="fu">filter</span>((product <span className="sc">==</span> <span className="st">"A"</span> <span className="sc">&amp;</span> country <span className="sc">==</span> <span className="st">"UK"</span>) <span className="sc">|</span> product <span className="sc">==</span> <span className="st">"B"</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">production =</span> <span className="fu">rnorm</span>(<span className="fu">nrow</span>(.)))</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a></span><br/>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>production</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 9 × 4
<br/>  product country  year production
<br/>  &lt;chr&gt;   &lt;chr&gt;   &lt;int&gt;      &lt;dbl&gt;
<br/>1 A       UK       2023     -0.237
<br/>2 A       UK       2024     -0.971
<br/>3 A       UK       2025     -1.53 
<br/>4 B       UK       2023      0.153
<br/>5 B       UK       2024      0.599
<br/>6 B       UK       2025     -2.08 
<br/>7 B       USA      2023      1.14 
<br/>8 B       USA      2024     -2.01 
<br/>9 B       USA      2025     -0.913</code></pre>
</div>
</div>
<p>We want to widen the data so we have one column for each combination of <code>product</code> and <code>country</code>. The key is to specify multiple variables in <code>names_from</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>production <span className="sc">%&gt;%</span> <span className="fu">pivot_wider</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="co"># create new column names as combination of "product" and "country"</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="at">names_from =</span> <span className="fu">c</span>(product, country), </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="at">values_from =</span> production</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>   year   A_UK   B_UK  B_USA
<br/>  &lt;int&gt;  &lt;dbl&gt;  &lt;dbl&gt;  &lt;dbl&gt;
<br/>1  2023 -0.237  0.153  1.14 
<br/>2  2024 -0.971  0.599 -2.01 
<br/>3  2025 -1.53  -2.08  -0.913</code></pre>
</div>
</div>
<p>When either <code>names_from</code> or <code>values_from</code> select multiple variables, you can control how the column names in the output are constructed with arguments <code>names_sep</code> and <code>names_prefix</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>production <span className="sc">%&gt;%</span> <span className="fu">pivot_wider</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">names_from =</span> <span className="fu">c</span>(product, country), </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">values_from =</span> production,</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="at">names_sep =</span> <span className="st">"."</span>, <span className="co"># separate parts of the column name with a dot</span></span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>  <span className="at">names_prefix =</span> <span className="st">"prod."</span> <span className="co"># add prefix "prod."</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>   year prod.A.UK prod.B.UK prod.B.USA
<br/>  &lt;int&gt;     &lt;dbl&gt;     &lt;dbl&gt;      &lt;dbl&gt;
<br/>1  2023    -0.237     0.153      1.14 
<br/>2  2024    -0.971     0.599     -2.01 
<br/>3  2025    -1.53     -2.08      -0.913</code></pre>
</div>
</div>
<p>Alternatively, you can construct new column names using the argument <code>names_glue</code>. Here <code>prod_&#123;product&#125;_&#123;country&#125;</code> combines prefix “prod” with unique levels of the <code>product</code> and <code>country</code> variables, connected with underscores.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>production <span className="sc">%&gt;%</span> <span className="fu">pivot_wider</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="at">names_from =</span> <span className="fu">c</span>(product, country), </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="at">values_from =</span> production,</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>  <span className="at">names_glue =</span> <span className="st">"prod_&#123;product&#125;_&#123;country&#125;"</span> <span className="co"># specify new column name pattern</span></span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 4
<br/>   year prod_A_UK prod_B_UK prod_B_USA
<br/>  &lt;int&gt;     &lt;dbl&gt;     &lt;dbl&gt;      &lt;dbl&gt;
<br/>1  2023    -0.237     0.153      1.14 
<br/>2  2024    -0.971     0.599     -2.01 
<br/>3  2025    -1.53     -2.08      -0.913</code></pre>
</div>
</div>
<p><br/></p>
<p><strong>e.g.2.</strong> The <code>us_rent_income</code> dataset contains information about median income and rent for each state in the US for 2017.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>us_rent_income</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 104 × 5
<br/>   GEOID NAME       variable estimate   moe
<br/>   &lt;chr&gt; &lt;chr&gt;      &lt;chr&gt;       &lt;dbl&gt; &lt;dbl&gt;
<br/> 1 01    Alabama    income      24476   136
<br/> 2 01    Alabama    rent          747     3
<br/> 3 02    Alaska     income      32940   508
<br/> 4 02    Alaska     rent         1200    13
<br/> 5 04    Arizona    income      27517   148
<br/> 6 04    Arizona    rent          972     4
<br/> 7 05    Arkansas   income      23789   165
<br/> 8 05    Arkansas   rent          709     5
<br/> 9 06    California income      29454   109
<br/>10 06    California rent         1358     3
<br/># ℹ 94 more rows</code></pre>
</div>
</div>
<p>Here both <code>estimate</code> and <code>moe</code> (90% margin of error) are value columns, so we can supply them to <code>values_from</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>us_rent_income <span className="sc">%&gt;%</span> </span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>  <span className="fu">pivot_wider</span>(</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a>    <span className="at">names_from =</span> variable, </span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a>    <span className="at">values_from =</span> <span className="fu">c</span>(estimate, moe))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 52 × 6
<br/>   GEOID NAME                 estimate_income estimate_rent moe_income moe_rent
<br/>   &lt;chr&gt; &lt;chr&gt;                          &lt;dbl&gt;         &lt;dbl&gt;      &lt;dbl&gt;    &lt;dbl&gt;
<br/> 1 01    Alabama                        24476           747        136        3
<br/> 2 02    Alaska                         32940          1200        508       13
<br/> 3 04    Arizona                        27517           972        148        4
<br/> 4 05    Arkansas                       23789           709        165        5
<br/> 5 06    California                     29454          1358        109        3
<br/> 6 08    Colorado                       32401          1125        109        5
<br/> 7 09    Connecticut                    35326          1123        195        5
<br/> 8 10    Delaware                       31560          1076        247       10
<br/> 9 11    District of Columbia           43198          1424        681       17
<br/>10 12    Florida                        25952          1077         70        3
<br/># ℹ 42 more rows</code></pre>
</div>
</div>
</main>
</div>
</div>
)}