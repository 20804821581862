import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Pmap(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Apply a Function to Elements of Multiple Inputs in Parallel</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>TL;DR</strong> <code>pmap(.l, .f)</code> is very similar to <Link to="../map"><code>map(.x, .f)</code></Link> and <Link to="../map2"><code>map2(.x, .y, .f)</code></Link>, but but applies the operation <code>.f</code> in parallel to elements of <em>multiple inputs</em> defined by the argument <code>.l</code>. It returns a <em>list</em>. The <code>pmap_*()</code> functions, e.g., <code>pmap_lgl()</code>, <code>pmap_dbl()</code>, <code>pmap_int()</code> and <code>pmap_chr()</code>, works in a similar way as <code>pmap()</code> but returns a <em>vector</em> of the indicated type.</p>
<hr/>
<p>The <code>pmap()</code> function applies an operation to each element of multiple inputs in parallel. The basic argument structure follows <code>map(.l, .f)</code> (as in functions of <code>pmap_*()</code>):</p>
<ul>
<li><p><code>.l</code>: A list of inputs (vectors, lists, etc). Arguments will be supplied by position if unnamed, and by name if named. Vectors of length 1 will be recycled to any length; all other elements must be have the same length.</p></li>
<li><p><code>.f</code> defines the function to be applied to each paired elements in the input. It can be in the format of a named function (without quote), e.g. <code>sum</code>; or an anonymous function, e.g., <code>~ sum(.x, .y, .z)</code>, <code>~ .x + .y + .z</code>, <code>\(x, y, z) x + y + z</code>, and <code>function(x, y, z) x + y + z</code>.</p></li>
<li><p>The output is a list of the same length as the input.</p></li>
</ul>
<p>If <code>.f</code> returns a vector of length one in each iteration, you can return the result as a vector of the indicated type:</p>
<ul>
<li><code>pmap_dbl()</code> returns a vector of type of <em>double</em> (i.e., numeric).</li>
<li><code>pmap_lgl()</code> returns a vector of type of <em>logic</em>.</li>
<li><code>pmap_int()</code> returns a vector of type of <em>integer</em>.</li>
<li><code>pmap_chr()</code> returns a vector of type of <em>character</em>.</li>
</ul>
<p><strong>e.g.1.</strong> Calculate A + B + C + D, and return a list.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(purrr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>A <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">4</span>)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>B <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">0</span>, <span className="dv">1</span>, <span className="dv">2</span>)</span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>C <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">3</span>)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>D <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">3</span>)</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a><span className="fu">pmap</span>(<span className="at">.l =</span> <span className="fu">list</span>(A, B, C, D), <span className="at">.f =</span> sum)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [[1]]
<br/>  [1] 3
<span>  
</span><br/>  [[2]]
<br/>  [1] 7
<span>  
</span><br/>  [[3]]
<br/>  [1] 12</code></pre>
</div>
</div>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">pmap_dbl</span>(<span className="at">.l =</span> <span className="fu">list</span>(A, B, C, D), <span className="at">.f =</span> sum)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [1]  3  7 12</code></pre>
</div>
</div>
<p><strong>e.g.2.</strong> Calculate A<sup>B</sup> + C<sup>D</sup>: raise A to the power of B, and C to the power of D, and then sum up.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">pmap</span>(<span className="at">.l =</span> <span className="fu">list</span>(A, B, C, D), <span className="at">.f =</span> \(x, y, z, h) x <span className="sc">^</span> y <span className="sc">+</span> z <span className="sc">^</span> h )</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [[1]]
<br/>  [1] 2
<span>  
</span><br/>  [[2]]
<br/>  [1] 6
<span>  
</span><br/>  [[3]]
<br/>  [1] 43</code></pre>
</div>
</div>
<p><strong>e.g.3.</strong> The following code creates three vectors (of a list) of a normal distribution, with 3, 5, and 7 as elements number <code>n</code> per vector, with 1, 5, and 10 as the <code>mean</code>, and 0.1, 1 and 5 as the associated <code>sd</code>, respectively.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>N <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">3</span>, <span className="dv">5</span>, <span className="dv">7</span>)  <span className="co"># number of elements per vector</span></span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>M <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">5</span>, <span className="dv">10</span>) <span className="co"># mean of normal distribution</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>S <span className="ot">&lt;-</span> <span className="fu">c</span>(.<span className="dv">1</span>, <span className="dv">1</span>, <span className="dv">5</span>) <span className="co"># sd of the normal distribution</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a></span><br/>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a><span className="fu">pmap</span>(</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>  <span className="fu">list</span>(N, M, S),</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>  <span className="at">.f =</span> \(N, M, S) <span className="fu">rnorm</span>(<span className="at">n =</span> N, <span className="at">mean =</span> M, <span className="at">sd =</span> S) <span className="sc">%&gt;%</span> <span className="fu">round</span>(<span className="dv">2</span>)</span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  [[1]]
<br/>  [1] 0.85 1.00 1.11
<span>  
</span><br/>  [[2]]
<br/>  [1] 4.54 6.44 3.40 7.18 5.95
<span>  
</span><br/>  [[3]]
<br/>  [1]  3.58 17.87  8.48 13.65 -1.08  9.83 16.18</code></pre>
</div>
</div>
</main>
</div>
</div>
)}