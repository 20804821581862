import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function LookaroundAssertion(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Lookaround Assertions</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>Lookaround assertions</strong> include <em>positive</em> and <em>negative</em> lookarounds, and each include <em>lookahead</em> and <em>lookbehind</em>. In this tutorial, we’ll first focus on details of positive lookarounds, and then briefly touch upon negative lookarounds.</p>
<section className="level3" id="positive-lookaround-assertion">
<h3 className="anchored" data-anchor-id="positive-lookaround-assertion">Positive lookaround assertion</h3>
<p>A positive look around assertion indicates that in order for a particular pattern A to be a match, a pattern B must be coexisting, while pattern B itself is <em>not</em> part of the match. If the matched pattern A comes immediately <em>before</em> the pattern B, it constitutes a positive <strong>lookahead</strong>, noted as <code>A(?=B)</code>; if A comes immediately <em>after</em> B, it constitutes positive <strong>lookbehind</strong>, noted as <code>(?&lt;=B)A</code>.</p>
<p><strong><em>eg. 1. Positive lookahead.</em></strong> <code>\\w+(?=ing)</code> matches words that is followed with “ing”, but only considers the part before “ing” as a match. (Recall that <Link to="../5-escape-characters"><code>\w</code></Link> is equivalent to <Link to="../1-character-class">character class</Link> <code>[a-zA-Z0-9_]</code>; also note that a second backslash is needed to escape the first backslash).</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"I sing"</span>, <span className="st">"I am singing"</span>, <span className="st">"She is dancing"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"</span><span className="sc">\\</span><span className="st">w+(?=ing)"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ I &lt;s&gt;ing
<br/>[2] │ I am &lt;sing&gt;ing
<br/>[3] │ She is &lt;danc&gt;ing</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract</span>(x, <span className="st">"</span><span className="sc">\\</span><span className="st">w+(?=ing)"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "s"    "sing" "danc"</code></pre>
</div>
</div>
</div>
<p><strong><em>eg. 2. Positive lookahead.</em></strong> <code>.+(?=@harvard.edu$)</code> matches the username (anything before “@”) of any email address that ends with “<span className="citation" data-cites="harvard.edu">@harvard.edu</span>”. Note that the ending anchor <code>$</code> is <em>inside</em> the positive lookahead parentheses.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>emails <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"john.smith@harvard.edu"</span>,</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>            <span className="st">"mary_bosse@harvard.edu"</span>,</span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>            <span className="st">"Mr.Cool@tesla.com"</span>)</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>p <span className="ot">&lt;-</span> <span className="st">".+(?=@harvard.edu$)"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_view_all</span>(emails, p)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;john.smith&gt;@harvard.edu
<br/>[2] │ &lt;mary_bosse&gt;@harvard.edu
<br/>[3] │ Mr.Cool@tesla.com</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract</span>(emails, p)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "john.smith" "mary_bosse" NA          </code></pre>
</div>
</div>
</div>
<p><strong><em>eg. 3. Positive lookbehind.</em></strong> Using the same character vector <code>emails</code> as example, here <code>(?&lt;=@).*</code> matches the domain of an email address, i.e., strings of any length (noted by <code>.*</code>) that follows <code>@</code> (while the <code>@</code> sign itself is not part of the match).</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">str_view_all</span>(emails, <span className="st">"(?&lt;=@).*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ john.smith@&lt;harvard.edu&gt;
<br/>[2] │ mary_bosse@&lt;harvard.edu&gt;
<br/>[3] │ Mr.Cool@&lt;tesla.com&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb13"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb13-1"><a aria-hidden="true" href="#cb13-1" tabindex="-1"></a><span className="fu">str_extract</span>(emails, <span className="st">"(?&lt;=@).*"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "harvard.edu" "harvard.edu" "tesla.com"  </code></pre>
</div>
</div>
</div>
<p><strong><em>eg. 4. Positive lookbehind.</em></strong> <code>(?&lt;=\\$\\s&#123;0,1&#125;)\\d+</code> matches a monetary amount, without including the dollar sign and the white space (if any) in the matched pattern. Specifically, the literal dollar sign is noted as <code>\\$</code>, zero or one white space as <code>\\s&#123;0,1&#125;</code>, and one or more digits as <code>\\d+</code>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb15"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb15-1"><a aria-hidden="true" href="#cb15-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"2 books at $ 33"</span>, </span>
<span id="cb15-2"><a aria-hidden="true" href="#cb15-2" tabindex="-1"></a>       <span className="st">"8 pen and 10 pensils at $10"</span>)</span>
<span id="cb15-3"><a aria-hidden="true" href="#cb15-3" tabindex="-1"></a>money <span className="ot">&lt;-</span> <span className="st">"(?&lt;=</span><span className="sc">\\</span><span className="st">$</span><span className="sc">\\</span><span className="st">s&#123;0,1&#125;)</span><span className="sc">\\</span><span className="st">d+"</span></span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, money)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ 2 books at $ &lt;33&gt;
<br/>[2] │ 8 pen and 10 pensils at $&lt;10&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="fu">str_extract</span>(a, money)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "33" "10"</code></pre>
</div>
</div>
</div>
</section>
<section className="level3" id="negative-lookaround-assertion">
<h3 className="anchored" data-anchor-id="negative-lookaround-assertion">Negative lookaround assertion</h3>
<p>A <strong>negative lookaround assertion</strong> works very similarly to its positive counterpart, but in the other way around: in order for a particular pattern A to become a match, a particular pattern B must <em>not</em> be coexisting. The negative lookaround includes negative <strong>lookahead</strong> <code>A(?!B)</code>, with matched pattern A immediately ahead of the (undesired) pattern B; and <strong>lookbehind</strong> <code>(?&lt;!B)A</code>, with pattern A following the unwanted pattern B. We use the exclamation <code>!</code> the negate sign to create negative assertion, in place of the equation sign <code>=</code> as used in positive assertions.</p>
</section>
</main>
</div>
</div>
)}