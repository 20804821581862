import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2HeatmapSunspotActivities from '../graphics/heatmap_sunspot_completed.png'; 
import imgGgplot2HeatmapSunspotActivitiesWebp from '../graphics/heatmap_sunspot_completed.webp'; 
export default function Ggplot2HeatmapSunspotActivities(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Heatmap and Line Plot in ggplot2 to Visualize Sunspot Activities</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2HeatmapSunspotActivitiesWebp} />
    <img className="cover-img" src={imgGgplot2HeatmapSunspotActivities} />
  </picture>

<p>In this work, we’ll use ggplot2 to create a heatmap and a synchronized line plot to visualize the sunspot activities in the past ~270 years. <strong>Major techniques covered in this work include:</strong></p>
<ul>
<li><a href="#heatmap">Create a heatmap with <code>geom_raster()</code>.</a></li>
<li><a href="#lineplot">Create a line plot (by order of appearance in data) with <code>geom_path()</code>.</a></li>
<li><a href="#combine_plots">Combine plots using package <code>patchwork</code>.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<p>The dataset <code>sunspot.month</code> we use in this work is built in base R。</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(TSstudio)  <span className="co"># to work with time series type</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a><span className="fu">library</span>(patchwork) <span className="co"># combine plots together</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a></span><br/>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="co"># data cleanup</span></span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>sunspot.month.tidy <span className="ot">&lt;-</span> sunspot.month <span className="sc">%&gt;%</span></span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">ts_reshape</span>() <span className="sc">%&gt;%</span>  <span className="co"># convert the time series to "data.frame" format</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span>   <span className="co"># convert "data.frame" to "tibble" format</span></span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>  <span className="co"># restructure the dataset to a tidy format</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="sc">-</span>month, <span className="at">values_to =</span> <span className="st">"activity"</span>, <span className="at">names_to =</span> <span className="st">"year"</span>)</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a></span><br/>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a><span className="fu">head</span>(sunspot.month.tidy, <span className="dv">4</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 3
<br/>  month year  activity
<br/>  &lt;dbl&gt; &lt;chr&gt;    &lt;dbl&gt;
<br/>1     1 1749      58  
<br/>2     1 1750      73.3
<br/>3     1 1751      70  
<br/>4     1 1752      35  </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><strong>Set up the default theme.</strong> This ensures that both the heatmap and the line plot will be created in the same style.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">theme_set</span>(</span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">theme_light</span>(<span className="at">base_size =</span> <span className="dv">13</span>) <span className="sc">+</span></span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>    <span className="fu">theme</span>(<span className="at">panel.border =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>          <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>          <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>          <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>          <span className="at">legend.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>          <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>          <span className="at">axis.text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"lightyellow"</span>)))</span></code></pre></div>
</div>
<p><span id="heatmap"><strong>Create a heatmap</strong> showing the monthly sun spot activity from 1750 to 2000s.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p.heatmap <span className="ot">&lt;-</span> sunspot.month.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> month, <span className="at">y =</span> year, </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>             <span className="at">fill =</span> activity)) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_raster</span>() <span className="sc">+</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>  <span className="fu">scale_y_discrete</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1750</span>, <span className="dv">2010</span>, <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1</span>, <span className="dv">12</span>, <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>, <span className="at">begin =</span> .<span className="dv">1</span>) <span className="sc">+</span> </span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>)</span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a></span><br/>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a>p.heatmap</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_heatmap.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_sunspot_heatmap"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><br/></p>
<p><span id="lineplot"><strong>Create a line plot</strong> showing the monthly sun spot activity. Here We first reorder the rows in chronological order, and then use <code>geom_path()</code> to connect the dots of observations (rows) in order of their appearance in the dataset. In addition, mapping the <code>color</code> aesthetic to the <code>activity</code> variable renders a line with gradient color change.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p.line <span className="ot">&lt;-</span> sunspot.month.tidy <span className="sc">%&gt;%</span></span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="co"># define the order of observations to be connected into a line</span></span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="fu">arrange</span>(year, month) <span className="sc">%&gt;%</span> </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> activity, </span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>             <span className="at">color =</span> activity)) <span className="sc">+</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  </span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a>  <span className="co"># connect observations in order of appearance in data</span></span>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>  <span className="fu">geom_path</span>(<span className="fu">aes</span>(<span className="at">group =</span> <span className="dv">1</span>)) <span className="sc">+</span> </span>
<span id="cb5-9"><a aria-hidden="true" href="#cb5-9" tabindex="-1"></a>  </span>
<span id="cb5-10"><a aria-hidden="true" href="#cb5-10" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb5-11"><a aria-hidden="true" href="#cb5-11" tabindex="-1"></a>  <span className="fu">scale_color_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>, <span className="at">begin =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb5-12"><a aria-hidden="true" href="#cb5-12" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb5-13"><a aria-hidden="true" href="#cb5-13" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb5-14"><a aria-hidden="true" href="#cb5-14" tabindex="-1"></a>        <span className="at">axis.ticks.y =</span> <span className="fu">element_blank</span>()) <span className="sc">+</span></span>
<span id="cb5-15"><a aria-hidden="true" href="#cb5-15" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"activity"</span>) <span className="sc">+</span></span>
<span id="cb5-16"><a aria-hidden="true" href="#cb5-16" tabindex="-1"></a>  <span className="fu">guides</span>(</span>
<span id="cb5-17"><a aria-hidden="true" href="#cb5-17" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb5-18"><a aria-hidden="true" href="#cb5-18" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>), </span>
<span id="cb5-19"><a aria-hidden="true" href="#cb5-19" tabindex="-1"></a>      <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>), </span>
<span id="cb5-20"><a aria-hidden="true" href="#cb5-20" tabindex="-1"></a>      <span className="at">title =</span> <span className="cn">NULL</span>)) </span>
<span id="cb5-21"><a aria-hidden="true" href="#cb5-21" tabindex="-1"></a></span><br/>
<span id="cb5-22"><a aria-hidden="true" href="#cb5-22" tabindex="-1"></a>p.line</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_lineplot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_sunspot_lineplot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><br/></p>
<p><span id="combine_plots"><strong>Combine the heatmap and line plot together</strong> using the <Link to="https://patchwork.data-imaginist.com/"><code>patchwork</code></Link> package.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p.combined <span className="ot">&lt;-</span> (p.heatmap <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)) <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  p.line <span className="sc">+</span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="co"># relative width of the two plots</span></span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="fu">plot_layout</span>(<span className="at">widths =</span> <span className="fu">c</span>(<span className="dv">3</span>, <span className="dv">2</span>)) <span className="sc">+</span></span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  </span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="co"># add plot title</span></span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>  <span className="fu">plot_annotation</span>(</span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Sunspot Activities 1740 ~ 2013"</span>,</span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>    <span className="at">theme =</span> <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(</span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>      <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>))))</span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a></span><br/>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>p.combined </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_sunspot_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/heatmap_sunspot_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><br/></p>
<p>Save the plot.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"heatmap_sunspot_activities.pdf"</span>,</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">7</span>, <span className="at">height =</span> <span className="dv">7</span>)</span></code></pre></div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="do">### Packages and data cleanup</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a><span className="fu">library</span>(TSstudio) <span className="co"># to work with time series type</span></span>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a><span className="fu">library</span>(patchwork) <span className="co"># combine plots together</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a></span><br/>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a><span className="co"># data cleanup</span></span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>sunspot.month.tidy <span className="ot">&lt;-</span> sunspot.month <span className="sc">%&gt;%</span></span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="fu">ts_reshape</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>  <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>    <span className="sc">-</span>month, <span className="at">values_to =</span> <span className="st">"activity"</span>, <span className="at">names_to =</span> <span className="st">"year"</span>)</span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a></span><br/>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a><span className="fu">head</span>(sunspot.month.tidy, <span className="dv">30</span>)</span>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a></span><br/>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a></span><br/>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a><span className="co"># global theme</span></span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_light</span>(<span className="at">base_size =</span> <span className="dv">13</span>) <span className="sc">+</span></span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a>            <span className="fu">theme</span>(<span className="at">panel.border =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>                  <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a>                  <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a>                  <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>, <span className="at">color =</span> <span className="cn">NA</span>),</span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a>                  <span className="at">legend.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a>                  <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"lightyellow"</span>),</span>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a>                  <span className="at">axis.text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"lightyellow"</span>)))</span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a></span><br/>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a></span><br/>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a><span className="co"># Create a heatmap showing the monthly sun spot activity from 1750 to 2000s.  </span></span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a>p.heatmap <span className="ot">&lt;-</span> sunspot.month.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> month, <span className="at">y =</span> year, </span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a>             <span className="at">fill =</span> activity)) <span className="sc">+</span></span>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a>  <span className="fu">geom_raster</span>() <span className="sc">+</span></span>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a>  <span className="fu">scale_y_discrete</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1750</span>, <span className="dv">2010</span>, <span className="dv">50</span>)) <span className="sc">+</span></span>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a>  <span className="fu">scale_x_continuous</span>(<span className="at">breaks =</span> <span className="fu">seq</span>(<span className="dv">1</span>, <span className="dv">12</span>, <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb8-36"><a aria-hidden="true" href="#cb8-36" tabindex="-1"></a>  <span className="fu">scale_fill_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>, <span className="at">begin =</span> .<span className="dv">1</span>) <span className="sc">+</span> </span>
<span id="cb8-37"><a aria-hidden="true" href="#cb8-37" tabindex="-1"></a>  <span className="fu">coord_cartesian</span>(<span className="at">expand =</span> <span className="dv">0</span>)</span>
<span id="cb8-38"><a aria-hidden="true" href="#cb8-38" tabindex="-1"></a></span><br/>
<span id="cb8-39"><a aria-hidden="true" href="#cb8-39" tabindex="-1"></a>p.heatmap</span>
<span id="cb8-40"><a aria-hidden="true" href="#cb8-40" tabindex="-1"></a></span><br/>
<span id="cb8-41"><a aria-hidden="true" href="#cb8-41" tabindex="-1"></a></span><br/>
<span id="cb8-42"><a aria-hidden="true" href="#cb8-42" tabindex="-1"></a><span className="co"># Create a line plot.</span></span>
<span id="cb8-43"><a aria-hidden="true" href="#cb8-43" tabindex="-1"></a>p.line <span className="ot">&lt;-</span> sunspot.month.tidy <span className="sc">%&gt;%</span></span>
<span id="cb8-44"><a aria-hidden="true" href="#cb8-44" tabindex="-1"></a>  <span className="co"># define the order of observations to be connected into a line</span></span>
<span id="cb8-45"><a aria-hidden="true" href="#cb8-45" tabindex="-1"></a>  <span className="fu">arrange</span>(year, month) <span className="sc">%&gt;%</span> </span>
<span id="cb8-46"><a aria-hidden="true" href="#cb8-46" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> year, <span className="at">y =</span> activity, </span>
<span id="cb8-47"><a aria-hidden="true" href="#cb8-47" tabindex="-1"></a>             <span className="at">color =</span> activity)) <span className="sc">+</span></span>
<span id="cb8-48"><a aria-hidden="true" href="#cb8-48" tabindex="-1"></a>  </span>
<span id="cb8-49"><a aria-hidden="true" href="#cb8-49" tabindex="-1"></a>  <span className="co"># connect observations in order of appearance in data</span></span>
<span id="cb8-50"><a aria-hidden="true" href="#cb8-50" tabindex="-1"></a>  <span className="fu">geom_path</span>(<span className="fu">aes</span>(<span className="at">group =</span> <span className="dv">1</span>)) <span className="sc">+</span> </span>
<span id="cb8-51"><a aria-hidden="true" href="#cb8-51" tabindex="-1"></a>  </span>
<span id="cb8-52"><a aria-hidden="true" href="#cb8-52" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb8-53"><a aria-hidden="true" href="#cb8-53" tabindex="-1"></a>  <span className="fu">scale_color_viridis_c</span>(<span className="at">option =</span> <span className="st">"B"</span>, <span className="at">begin =</span> .<span className="dv">1</span>) <span className="sc">+</span></span>
<span id="cb8-54"><a aria-hidden="true" href="#cb8-54" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">axis.title.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-55"><a aria-hidden="true" href="#cb8-55" tabindex="-1"></a>        <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-56"><a aria-hidden="true" href="#cb8-56" tabindex="-1"></a>        <span className="at">axis.ticks.y =</span> <span className="fu">element_blank</span>()) <span className="sc">+</span></span>
<span id="cb8-57"><a aria-hidden="true" href="#cb8-57" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">y =</span> <span className="st">"activity"</span>) <span className="sc">+</span></span>
<span id="cb8-58"><a aria-hidden="true" href="#cb8-58" tabindex="-1"></a>  <span className="fu">guides</span>(</span>
<span id="cb8-59"><a aria-hidden="true" href="#cb8-59" tabindex="-1"></a>    <span className="at">color =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb8-60"><a aria-hidden="true" href="#cb8-60" tabindex="-1"></a>      <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>), </span>
<span id="cb8-61"><a aria-hidden="true" href="#cb8-61" tabindex="-1"></a>      <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">10</span>, <span className="st">"pt"</span>), </span>
<span id="cb8-62"><a aria-hidden="true" href="#cb8-62" tabindex="-1"></a>      <span className="at">title =</span> <span className="cn">NULL</span>)) </span>
<span id="cb8-63"><a aria-hidden="true" href="#cb8-63" tabindex="-1"></a></span><br/>
<span id="cb8-64"><a aria-hidden="true" href="#cb8-64" tabindex="-1"></a>p.line</span>
<span id="cb8-65"><a aria-hidden="true" href="#cb8-65" tabindex="-1"></a></span><br/>
<span id="cb8-66"><a aria-hidden="true" href="#cb8-66" tabindex="-1"></a><span className="co"># Combine the heatmap and line plot together.</span></span>
<span id="cb8-67"><a aria-hidden="true" href="#cb8-67" tabindex="-1"></a>(p.heatmap <span className="sc">+</span> <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="st">"none"</span>)) <span className="sc">+</span> </span>
<span id="cb8-68"><a aria-hidden="true" href="#cb8-68" tabindex="-1"></a>  p.line <span className="sc">+</span></span>
<span id="cb8-69"><a aria-hidden="true" href="#cb8-69" tabindex="-1"></a>  <span className="co"># relative width of the two plots</span></span>
<span id="cb8-70"><a aria-hidden="true" href="#cb8-70" tabindex="-1"></a>  <span className="fu">plot_layout</span>(<span className="at">widths =</span> <span className="fu">c</span>(<span className="dv">3</span>, <span className="dv">2</span>)) <span className="sc">+</span></span>
<span id="cb8-71"><a aria-hidden="true" href="#cb8-71" tabindex="-1"></a>  <span className="co"># add plot title of the combined graphic</span></span>
<span id="cb8-72"><a aria-hidden="true" href="#cb8-72" tabindex="-1"></a>  <span className="fu">plot_annotation</span>(</span>
<span id="cb8-73"><a aria-hidden="true" href="#cb8-73" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"Sunspot Activities 1740 ~ 2013"</span>,</span>
<span id="cb8-74"><a aria-hidden="true" href="#cb8-74" tabindex="-1"></a>    <span className="at">theme =</span> <span className="fu">theme</span>(<span className="at">plot.title =</span> <span className="fu">element_text</span>(</span>
<span id="cb8-75"><a aria-hidden="true" href="#cb8-75" tabindex="-1"></a>      <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>, <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">t =</span> <span className="dv">5</span>))))</span>
<span id="cb8-76"><a aria-hidden="true" href="#cb8-76" tabindex="-1"></a></span><br/>
<span id="cb8-77"><a aria-hidden="true" href="#cb8-77" tabindex="-1"></a></span><br/>
<span id="cb8-78"><a aria-hidden="true" href="#cb8-78" tabindex="-1"></a><span className="co"># Save the plot. </span></span>
<span id="cb8-79"><a aria-hidden="true" href="#cb8-79" tabindex="-1"></a><span className="fu">ggsave</span>(<span className="at">filename =</span> <span className="st">"heatmap_sunspot_activities.pdf"</span>,</span>
<span id="cb8-80"><a aria-hidden="true" href="#cb8-80" tabindex="-1"></a>       <span className="at">path =</span> <span className="st">"graphics"</span>, <span className="co"># a relative path</span></span>
<span id="cb8-81"><a aria-hidden="true" href="#cb8-81" tabindex="-1"></a>       <span className="at">width =</span> <span className="dv">7</span>, <span className="at">height =</span> <span className="dv">7</span>)</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Check out these beautiful <Link to="../ggplot2-heatmap-vaccine-for-8-diseases"><strong>heatmaps showing the disease incidences before and after the vaccine introduction</strong></Link> throughout the U.S. history.</p>
<p><Link to="../ggplot2-heatmap-vaccine-for-8-diseases">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_vaccine_function_completed_all_8_diseases.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>And check out this <Link to="../ggplot2-heatmap-African-population"><strong>amazing heatmap visualizing the population distribution in Africa</strong></Link>. In particular, it leverages magical mathematical transformations in the color scale (with a simple line of code) to unveil the underlying highly skewed data pattern.</p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>All above heatmaps are generated using the generic function <code>geom_tile()</code> or <code>geom_raster()</code>. Alternatively, <strong>a heatmap can be created in the form of a 2D histogram using <code>geom_bin2d()</code></strong> – check out the following awesome <Link to="../ggplot2-2D-histogram-storm-activities-US"><strong>2D histogram with a world map overlay</strong></Link> that visualizes the hurricane activities in North Atlantic Ocean.</p>
<p><Link to="../ggplot2-2D-histogram-storm-activities-US">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/heatmap_storms_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}