import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrPad(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Pad a String with White Space to a Fixed Length</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_pad()</code></strong> adds white space (or other characters) to a string so that it becomes a fixed length (or said width). By default, white space is added to the left side of the string. In the following example, 4 spaces are added before “a”, and 2 before “bee”, so that each padded string element is of length 5.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>hive <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"bee"</span>)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">str_pad</span>(hive, <span className="at">width =</span> <span className="dv">5</span>) <span className="co"># width of resulted string </span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "    a" "  bee"</code></pre>
</div>
</div>
<p>Here we pad with “-” at both sides of each string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a><span className="fu">str_pad</span>(hive, <span className="at">width =</span> <span className="dv">5</span>, </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>        <span className="at">side =</span> <span className="st">"both"</span>, <span className="at">pad =</span> <span className="st">"-"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "--a--" "-bee-"</code></pre>
</div>
</div>
<p>We can use vectorized arguments to pad string elements into different length and with different characters.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a><span className="fu">str_pad</span>(hive, <span className="at">width =</span> <span className="fu">c</span>(<span className="dv">4</span>, <span className="dv">6</span>), </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>        <span className="at">side =</span> <span className="st">"both"</span>, <span className="at">pad =</span> <span className="fu">c</span>(<span className="st">"-"</span>, <span className="st">"*"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "-a--"   "*bee**"</code></pre>
</div>
</div>
<p>If the original string element is longer than the specified <code>width</code> value, the original string will remain the <em>same</em>; it will <em>not</em> be truncated.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_pad</span>(<span className="fu">c</span>(hive, <span className="st">"beeees"</span>), <span className="at">width =</span> <span className="dv">5</span>, </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>        <span className="at">side =</span> <span className="st">"both"</span>, <span className="at">pad =</span> <span className="st">"-"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "--a--"  "-bee-"  "beeees"</code></pre>
</div>
</div>
</main>
</div>
</div>
)}