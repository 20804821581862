import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function FilteringJoinTwoDatasets(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Create Data Subset by Filtering Join</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>The filtering join filters rows in dataset A based on the presence or absence of matches in dataset B. The output is a subset of rows of A, with columns not modified.</p>
<ul>
<li><strong><code>semi_join()</code></strong> returns all rows of dataset A that <em>have</em> a match in B.</li>
<li><strong><code>anti_join()</code></strong> returns all rows of dataset A <em>without</em> a match in B.</li>
</ul>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>band_members</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>    name  band   
<br/>    &lt;chr&gt; &lt;chr&gt;  
<br/>  1 Mick  Stones 
<br/>  2 John  Beatles
<br/>  3 Paul  Beatles</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>band_instruments</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>    name  plays 
<br/>    &lt;chr&gt; &lt;chr&gt; 
<br/>  1 John  guitar
<br/>  2 Paul  bass  
<br/>  3 Keith guitar</code></pre>
</div>
</div>
</div>
</div>
<p>Create a subset of A (<code>band_members</code>), keeping only musicians in A that is also found in B (<code>band_instruments</code>) using the key column “name”.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>band_members <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">semi_join</span>(band_instruments, <span className="at">by =</span> <span className="st">"name"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 2 × 2
<br/>    name  band   
<br/>    &lt;chr&gt; &lt;chr&gt;  
<br/>  1 John  Beatles
<br/>  2 Paul  Beatles</code></pre>
</div>
</div>
<p>Create a subset of A containing musicians unique to A and <em>not</em> found in B.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>band_members <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">anti_join</span>(band_instruments, <span className="at">by =</span> <span className="st">"name"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 1 × 2
<br/>    name  band  
<br/>    &lt;chr&gt; &lt;chr&gt; 
<br/>  1 Mick  Stones</code></pre>
</div>
</div>
</main>
</div>
</div>
)}