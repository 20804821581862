import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2BarsReorderedCars from '../graphics/bars_reordered_normalized_completed.png'; 
import imgGgplot2BarsReorderedCarsWebp from '../graphics/bars_reordered_normalized_completed.webp'; 
export default function Ggplot2BarsReorderedCars(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Ordered Barplot with ggplot2 to Visualize Cars’ Engine Efficiency</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2BarsReorderedCarsWebp} />
    <img className="cover-img" src={imgGgplot2BarsReorderedCars} />
  </picture>

<p>In this visualization, we create a bar chart to compare cars’ mileage per gallon (mpg) using the R built-in dataset <code>mtcars</code>. Technically, we first normalize the mpg values into z-score, and then arrange the dataset based on the normalized result. You can find a <Link to="../../R/visualization/4-ggplot2-reorder-bars"><strong>complete guide</strong></Link> here how to reorder graphic elements in ggplot2.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>mtcars <span className="sc">%&gt;%</span> </span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>  <span className="co"># data wrangling</span></span>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">cars =</span> <span className="fu">rownames</span>(mtcars)) <span className="sc">%&gt;%</span> </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a>  <span className="fu">arrange</span>(mpg) <span className="sc">%&gt;%</span> </span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>  <span className="fu">mutate</span>(</span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>    <span className="at">mpg.z.score =</span> (mpg <span className="sc">-</span> <span className="fu">mean</span>(mpg)) <span className="sc">/</span> <span className="fu">sd</span>(mpg),</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>    <span className="at">cars =</span> <span className="fu">factor</span>(cars, <span className="at">levels =</span> cars), </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>    <span className="co"># put the new variable at the 2nd column</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>    <span className="at">.after =</span> <span className="dv">1</span>) <span className="sc">%&gt;%</span> </span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>  </span>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a>  <span className="co"># visualization</span></span>
<span id="cb1-15"><a aria-hidden="true" href="#cb1-15" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> cars, <span className="at">y =</span> mpg.z.score, <span className="at">fill =</span> <span className="fu">factor</span>(gear))) <span className="sc">+</span></span>
<span id="cb1-16"><a aria-hidden="true" href="#cb1-16" tabindex="-1"></a>  <span className="fu">geom_col</span>() <span className="sc">+</span></span>
<span id="cb1-17"><a aria-hidden="true" href="#cb1-17" tabindex="-1"></a>  <span className="fu">geom_hline</span>(<span className="at">yintercept =</span> <span className="dv">0</span>) <span className="sc">+</span></span>
<span id="cb1-18"><a aria-hidden="true" href="#cb1-18" tabindex="-1"></a>  <span className="fu">theme_minimal</span>() <span className="sc">+</span></span>
<span id="cb1-19"><a aria-hidden="true" href="#cb1-19" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">fill =</span> <span className="st">"Gear"</span>, </span>
<span id="cb1-20"><a aria-hidden="true" href="#cb1-20" tabindex="-1"></a>       <span className="at">y =</span> <span className="st">"miles per gallon (standardized)"</span>, </span>
<span id="cb1-21"><a aria-hidden="true" href="#cb1-21" tabindex="-1"></a>       <span className="at">x =</span> <span className="cn">NULL</span>) <span className="sc">+</span></span>
<span id="cb1-22"><a aria-hidden="true" href="#cb1-22" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">legend.position =</span> <span className="fu">c</span>(.<span className="dv">2</span>, .<span className="dv">8</span>),</span>
<span id="cb1-23"><a aria-hidden="true" href="#cb1-23" tabindex="-1"></a>        <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">angle =</span> <span className="dv">60</span>, <span className="at">hjust =</span> <span className="dv">1</span>))</span></code></pre></div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>In the charts above, the car labels are positioned far away from the bars. This can hurt the plot readability, and much white space is not effectively utilized. Instead, check this <Link to="../ggplot2-bar-sleep"><strong>improved visualization</strong></Link> to learn how to <strong>position the labels directly on the side of the bars</strong>.</p>
<p><Link to="../ggplot2-bar-sleep">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/bars_reordered_sleeptime_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Reordering of graphical elements is one of the most used and powerful techniques in data visualization, as illustrated in the above two examples. In the following <Link to="../ggplot2-area-plot-migration"><strong>stacked area / alluvial plot</strong></Link>, ribbons are stacked in well-designed order such that color gradients can be leveraged to distinguish different countries and continents.</p>
<p><Link to="../ggplot2-area-plot-migration">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/area_plot_migration_theme_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}