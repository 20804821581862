import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonFunctionslocalvariableandglobalvariable(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Global-Variable-and-Local-variable">Global Variable and Local variable<a class="anchor-link" href="#Global-Variable-and-Local-variable">¶</a></h3><p>In the <Link to="../variables">Variables</Link> article, we've explored the concept of variables. Now, let's delve deeper into the different types of variables. Broadly speaking, variables in Python can be categorized into two main types: <strong>global variables</strong> and <strong>local variables</strong>.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Local-Variable">Local Variable<a class="anchor-link" href="#Local-Variable">¶</a></h4><p>A local variable is a variable declared within a function or a block of code.</p>
<ul>
<li>Local variables are accessible only within that specific function or block. </li>
<li>Local variables have limited scope and are not visible or accessible outside of the function or block in which they are defined.</li>
</ul>
<h5 id="Example">Example<a class="anchor-link" href="#Example">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create a function </span></span>

<span><span class="k">def</span> <span class="nf">my_function</span><span class="p">():</span></span>

<span>    <span class="c1"># Initialize a variable x with value 10</span></span>

<span>    <span class="c1"># x is a local variable</span></span>

<span>    <span class="n">x</span> <span class="o">=</span> <span class="mi">10</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"Access x inside the function: "</span><span class="p">,</span> <span class="n">x</span><span class="p">)</span></span> 


<br /><span><span class="n">my_function</span><span class="p">()</span></span> 

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Access x inside the function:  10
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The variable x is defined within <code>my_function</code>, making it accessible inside the function for the print method.</p>
<p>However, tring to access the local variable x outside the function will result in an error:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># This will result in NameError: name 'x' is not defined</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s2">"Access x outside the function: "</span><span class="p">,</span> <span class="n">x</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="application/vnd.jupyter.stderr">
<pre className='demo-highlight python'><code className='sourceCode'>
<span><span class="ansi-red-fg">---------------------------------------------------------------------------</span></span>

<span><span class="ansi-red-fg">NameError</span>                                 Traceback (most recent call last)
</span>
<span>Input <span class="ansi-green-fg">In [4]</span>, in <span class="ansi-cyan-fg">&lt;cell line: 2&gt;</span><span class="ansi-blue-fg">()</span></span>

<span><span class="ansi-green-intense-fg ansi-bold">      1</span> <span># This will result in NameError: name 'x' is not defined</span></span>

<span><span class="ansi-green-fg">----&gt; 2</span> <span>print</span>(<span>"</span><span>Access x outside the function: </span><span>"</span>, <span class="ansi-yellow-bg">x</span>)
</span>

<br /><span><span class="ansi-red-fg">NameError</span>: name 'x' is not define</span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The NameError indicates that no variable named x is found in the current scope.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Global-Variable">Global Variable<a class="anchor-link" href="#Global-Variable">¶</a></h4><p>A global variable is a variable declared outside of any function or block of code.</p>
<ul>
<li>Global variables are accessible from anywhere within the program, including inside functions.</li>
</ul>
<h5 id="Example">Example<a class="anchor-link" href="#Example">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># global variable</span></span>

<span><span class="n">global_var</span> <span class="o">=</span> <span class="mi">20</span></span>  


<br /><span><span class="k">def</span> <span class="nf">my_function</span><span class="p">():</span></span>

<span>    <span class="c1"># Accessing global variable insdie the function</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"Access global_var inside a function: "</span><span class="p">,</span> <span class="n">global_var</span><span class="p">)</span></span>  


<br /><span><span class="n">my_function</span><span class="p">()</span></span>  


<br /><span><span class="nb">print</span><span class="p">(</span><span class="s2">"Access global_var outside a function: "</span><span class="p">,</span> <span class="n">global_var</span><span class="p">)</span></span> 

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Access global_var inside a function:  20
<br />Access global_var outside a function:  20
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>In this example, <code>global_var</code> is a global variable because it's defined outside of any function, making it accessible to the <code>print</code> method both inside and outside of <code>my_function</code>.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="One-variable-can-be-defined-both-inside-and-outside-of-a-function.">One variable can be defined both inside and outside of a function.<a class="anchor-link" href="#One-variable-can-be-defined-both-inside-and-outside-of-a-function.">¶</a></h5><p>When a variable is defined both inside and outside of a function, they operate as entirely separate entities, independent of each other, and do not impact one another's values or behavior.</p>
<p>In the example below, <code>global_var</code> is initially defined as a global variable and then redefined as a local variable within <code>my_function</code>. Printing <code>global_var</code> inside <code>my_function</code> yields a value of 30, whereas its value is 20 when printed outside the function.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [1]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">global_var</span> <span class="o">=</span> <span class="mi">20</span>  <span class="c1"># Define a global variable</span></span>


<br /><span><span class="k">def</span> <span class="nf">my_function</span><span class="p">():</span></span>

<span>    <span class="n">global_var</span> <span class="o">=</span> <span class="mi">30</span>  <span class="c1"># modifying the global variable inside the function</span></span>

<span>    <span class="c1"># Print the value of global_var inside the function</span></span>

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"Value of gloabl_var inside the function: "</span><span class="p">,</span> <span class="n">global_var</span><span class="p">)</span></span>


<br /><span><span class="n">my_function</span><span class="p">()</span></span> 


<br /><span><span class="nb">print</span><span class="p">(</span><span class="s2">"Value of gloabl_var outside the function: "</span><span class="p">,</span> <span class="n">global_var</span><span class="p">)</span></span>  

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Value of gloabl_var inside the function:  30
<br />Value of gloabl_var outside the function:  20
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>While syntactically correct, it's not recommended to use the same variable name in two different scopes. Best practice suggests creating unique variable names for clarity.</p>
<p>What if we want to modify the global variable inside a function?</p>
<p>In this case, we need to explicitly declare the variable using the <code>global</code> keyword.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Modifying-a-Global-Variable-Inside-a-Function">Modifying a Global Variable Inside a Function<a class="anchor-link" href="#Modifying-a-Global-Variable-Inside-a-Function">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [5]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># global variable</span></span>

<span><span class="n">global_var</span> <span class="o">=</span> <span class="mi">20</span></span>  


<br /><span><span class="k">def</span> <span class="nf">my_function</span><span class="p">():</span></span>

<span>    <span class="c1"># declaring the intention to use the global variable global_var</span></span>

<span>    <span class="k">global</span> <span class="n">global_var</span></span>

    
<br /><span>    <span class="c1"># modifying the global variable inside the function</span></span>

<span>    <span class="n">global_var</span> <span class="o">=</span> <span class="mi">30</span></span>  

<span>    <span class="nb">print</span><span class="p">(</span><span class="s2">"Value of gloabl_var inside the function: "</span><span class="p">,</span> <span class="n">global_var</span><span class="p">)</span></span>


<br /><span><span class="n">my_function</span><span class="p">()</span></span> 


<br /><span><span class="nb">print</span><span class="p">(</span><span class="s2">"Value of gloabl_var outside the function: "</span><span class="p">,</span> <span class="n">global_var</span><span class="p">)</span></span>  

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Value of gloabl_var inside the function:  30
<br />Value of gloabl_var outside the function:  30
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>By declaring <code>global global_var</code> inside the function, the <code>global_var</code> no longer acts as a new local variable but refers to the global variable instead.</p>
</div>
</div>
</div>
</div>
</div>
)}