import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function Anchors(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Specify the Start or End of Strings with Anchors</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong>Anchors</strong> define the position of a string where a match is supposed to be found: <code>^</code> denotes the start of the string, and <code>$</code> denotes the end of the string. Consider the following examples.</p>
<p><code>Hello$</code> matches “Hello” that resides at the very end of a string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"... Hello"</span>, <span className="st">"Hello world"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"Hello$"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ ... &lt;Hello&gt;
<br/>[2] │ Hello world</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"Hello$"</span>, <span className="at">simplify =</span> T) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]   
<br/>[1,] "Hello"
<br/>[2,] ""     </code></pre>
</div>
</div>
</div>
<p><code>^[aA]pple</code> matches “Apple” and “apple” that reside at the start of a string.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"apples are red"</span>, <span className="st">"Apples are juicy"</span>, <span className="st">"We love apples"</span>)</span></code></pre></div>
</div>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a><span className="fu">str_view_all</span>(a, <span className="st">"^[aA]pple"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;apple&gt;s are red
<br/>[2] │ &lt;Apple&gt;s are juicy
<br/>[3] │ We love apples</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(a, <span className="st">"^[aA]pple"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]   
<br/>[1,] "apple"
<br/>[2,] "Apple"
<br/>[3,] ""     </code></pre>
</div>
</div>
</div>
<hr/>
<p>It is important to note that when the caret <code>^</code> is used inside the square brackets <code>[]</code>, it has a different meaning: it <strong>negates</strong> the character class, and no longer functions as the starting anchor, e.g., <code>[^123]</code> matches any character <em>except</em> “1”, “2”, or “3”.</p>
<p>Compare the following two examples.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"12a 53b"</span>,  <span className="st">"b**89  *_..."</span>)</span></code></pre></div>
</div>
<p>Match consecutive digits 1 to 5 at the start of a string.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb12"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb12-1"><a aria-hidden="true" href="#cb12-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"^[1-5]+"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ &lt;12&gt;a 53b
<br/>[2] │ b**89  *_...</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb14"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb14-1"><a aria-hidden="true" href="#cb14-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"^[1-5]+"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]
<br/>[1,] "12"
<br/>[2,] ""  </code></pre>
</div>
</div>
</div>
<p>Match any consecutive characters excluding 1 to 5.</p>
<div id="flex">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb16"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb16-1"><a aria-hidden="true" href="#cb16-1" tabindex="-1"></a><span className="fu">str_view_all</span>(x, <span className="st">"[^1-5]+"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] │ 12&lt;a &gt;53&lt;b&gt;
<br/>[2] │ &lt;b**89  *_...&gt;</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb18"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb18-1"><a aria-hidden="true" href="#cb18-1" tabindex="-1"></a><span className="fu">str_extract_all</span>(x, <span className="st">"[^1-5]+"</span>, <span className="at">simplify =</span> T)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">     [,1]           [,2]
<br/>[1,] "a "           "b" 
<br/>[2,] "b**89  *_..." ""  </code></pre>
</div>
</div>
</div>
</main>
</div>
</div>
)}