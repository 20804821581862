import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useSparkCustomEffect} from '../../useCustomEffect'; 
export default function PythonOutput(){
useSparkCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h1 id="Create-a-PySpark-DataFrame-Using-createDataFrame()">Create a PySpark DataFrame Using <code>createDataFrame()</code><a class="anchor-link" href="#Create-a-PySpark-DataFrame-Using-createDataFrame()">¶</a></h1><p>The <code>createDataFrame()</code> method creates a PySpark DataFrame. If you're not familiar with PySpark DataFrame, check out this article: <Link to="../spark-dataframe">What is PySpark DataFrame</Link>.</p>
<p>This article covers the following topics:</p>
<ul>
<li><strong>Create a PySpark DataFrame from different data structures:</strong>
<ul>
<li><a href="#Create-a-PySpark-DataFrame-From-a-List-of-Lists">a list of lists</a></li>
<li><a href="#Create-a-PySpark-DataFrame-From-a-List-of-Tuples">a list of tuples</a></li>
<li><a href="#Create-a-PySpark-DataFrame-From-a-List-of-Dictionaries">a list of dictionaries</a></li>
<li><a href="#Create-a-PySpark-DataFrame-From-a-Pandas-DataFrame">a Pandas DataFrame</a></li>
</ul>
</li>
<li><strong><a href="#Define-Schema">Use the <code>schema</code> argument to define a DataFrame's schema</a></strong></li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Create-a-Spark-Session">Create a Spark Session<a class="anchor-link" href="#Create-a-Spark-Session">¶</a></h3><p>To start, initalize a SparkSession and assign it to a variable <code>spark</code>.</p>
<p><em>If you're not familiar with Spark session, click <Link to="../park-session">here</Link> to learn more.</em></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql</span> <span class="kn">import</span> <span class="n">SparkSession</span></span>


<br /><span><span class="c1"># Initialize Spark Session</span></span>
<span><span class="n">spark</span> <span class="o">=</span> <span class="n">SparkSession</span><span class="o">.</span><span class="n">builder</span><span class="o">.</span><span class="n">appName</span><span class="p">(</span><span class="s2">"createDataFrameExample"</span><span class="p">)</span><span class="o">.</span><span class="n">getOrCreate</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Create-a-PySpark-DataFrame-From-a-List-of-Lists">Create a PySpark DataFrame From a List of Lists<a class="anchor-link" href="#Create-a-PySpark-DataFrame-From-a-List-of-Lists">¶</a></h3>
<p><code>createDataFrame()</code> takes a list of lists as an input and turns it into a PySpark DataFrame.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [19]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'>
<span><span class="c1"># Sample data (a list of lists)</span></span>
<span><span class="n">data</span> <span class="o">=</span> <span class="p">[[</span><span class="s2">"Alice"</span><span class="p">,</span> <span class="mi">25</span><span class="p">],</span></span> 
<span>        <span class="p">[</span><span class="s2">"Bob"</span><span class="p">,</span> <span class="mi">30</span><span class="p">],</span></span> 
<span>        <span class="p">[</span><span class="s2">"Charlie"</span><span class="p">,</span> <span class="mi">22</span><span class="p">]]</span></span>

<br /><span><span class="c1"># Create a PySpark DataFrame</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>+-------+---+
<br />|     _1| _2|
<br />+-------+---+
<br />|  Alice| 25|
<br />|    Bob| 30|
<br />|Charlie| 22|
<br />+-------+---+
<br /></span></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The DataFrame created above has generic column names, <code>_1</code> and <code>_2</code> because we didn't specify custom names. We can use the <code>schema</code> argument to define specific column names.</p>
<h5 id="Define-Schema">Define Schema<a class="anchor-link" href="#Define-Schema">¶</a></h5><p>In the example below, the variable <code>schema</code> is a <code>StructType</code> object containing two <code>StructField</code> objects: "Name" (String) and "Age" (Integer).</p>
<p>To learn more about DataFrame schema and how to create one, check out this article: <Link to="../dataframe-schema">Create a PySpark DataFrame Schema</Link></p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [17]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">from</span> <span class="nn">pyspark.sql.types</span> <span class="kn">import</span> <span class="n">StructType</span><span class="p">,</span> <span class="n">StructField</span><span class="p">,</span> <span class="n">StringType</span><span class="p">,</span> <span class="n">IntegerType</span></span>


<br /><span><span class="c1"># Define a schema variable</span></span>
<span><span class="n">schema</span> <span class="o">=</span> <span class="n">StructType</span><span class="p">([</span></span>
<span>    <span class="n">StructField</span><span class="p">(</span><span class="s2">"Name"</span><span class="p">,</span> <span class="n">StringType</span><span class="p">(),</span> <span class="kc">True</span><span class="p">),</span></span>
<span>    <span class="n">StructField</span><span class="p">(</span><span class="s2">"Age"</span><span class="p">,</span> <span class="n">IntegerType</span><span class="p">(),</span> <span class="kc">True</span><span class="p">)</span></span>
<span><span class="p">])</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Pass the defined <code>schema</code> variable as an argument to the <code>schema</code> parameter:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [20]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Pass the schema variable as an argument to createDataFrame()</span></span>

<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="o">=</span><span class="n">data</span><span class="p">,</span> <span class="n">schema</span><span class="o">=</span><span class="n">schema</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>+-------+---+
<br />|   Name|Age|
<br />+-------+---+
<br />|  Alice| 25|
<br />|    Bob| 30|
<br />|Charlie| 22|
<br />+-------+---+
<br /></span></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Display the <code>df</code>'s schema:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [8]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">df</span><span class="o">.</span><span class="n">printSchema</span><span class="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>root
<br /> |-- Name: string (nullable = true)
<br /> |-- Age: integer (nullable = true)
<br /></span></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Create-a-PySpark-DataFrame-From-a-List-of-Tuples">Create a PySpark DataFrame From a List of Tuples<a class="anchor-link" href="#Create-a-PySpark-DataFrame-From-a-List-of-Tuples">¶</a></h3>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [21]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Sample data (list of tuples)</span></span>

<span><span class="n">data</span> <span class="o">=</span> <span class="p">[(</span><span class="s2">"James"</span><span class="p">,</span> <span class="mi">34</span><span class="p">),</span></span> 
<span>        <span class="p">(</span><span class="s2">"Anna"</span><span class="p">,</span> <span class="mi">28</span><span class="p">),</span></span> 
<span>        <span class="p">(</span><span class="s2">"Robert"</span><span class="p">,</span> <span class="mi">45</span><span class="p">)]</span></span>

<br /><span><span class="c1"># Define a schema variable</span></span>
<span><span class="n">schema</span> <span class="o">=</span> <span class="n">StructType</span><span class="p">([</span></span>
<span>    <span class="n">StructField</span><span class="p">(</span><span class="s2">"Name"</span><span class="p">,</span> <span class="n">StringType</span><span class="p">(),</span> <span class="kc">True</span><span class="p">),</span></span>
<span>    <span class="n">StructField</span><span class="p">(</span><span class="s2">"Age"</span><span class="p">,</span> <span class="n">IntegerType</span><span class="p">(),</span> <span class="kc">True</span><span class="p">)</span></span>
<span><span class="p">])</span></span>

<br /><span><span class="c1"># Pass the schema variable as an argument to the schema parameter</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="o">=</span><span class="n">data</span><span class="p">,</span> <span class="n">schema</span><span class="o">=</span><span class="n">schema</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>+------+---+
<br />|  Name|Age|
<br />+------+---+
<br />| James| 34|
<br />|  Anna| 28|
<br />|Robert| 45|
<br />+------+---+
<br /></span></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Create-a-PySpark-DataFrame-From-a-List-of-Dictionaries">Create a PySpark DataFrame From a List of Dictionaries<a class="anchor-link" href="#Create-a-PySpark-DataFrame-From-a-List-of-Dictionaries">¶</a></h3>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [15]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Sample data (list of tuples)</span></span>

<span><span class="n">data</span> <span class="o">=</span> <span class="p">[</span></span>
<span>    <span class="p">&#123;</span><span class="s2">"Name"</span><span class="p">:</span> <span class="s2">"James"</span><span class="p">,</span> <span class="s2">"Age"</span><span class="p">:</span> <span class="mi">34</span><span class="p">&#125;,</span></span>
<span>    <span class="p">&#123;</span><span class="s2">"Name"</span><span class="p">:</span> <span class="s2">"Anna"</span><span class="p">,</span> <span class="s2">"Age"</span><span class="p">:</span> <span class="mi">28</span><span class="p">&#125;,</span></span>
<span>    <span class="p">&#123;</span><span class="s2">"Name"</span><span class="p">:</span> <span class="s2">"Robert"</span><span class="p">,</span> <span class="s2">"Age"</span><span class="p">:</span> <span class="mi">45</span><span class="p">&#125;</span></span>
<span><span class="p">]</span></span>

<br /><span><span class="c1"># Define schema</span></span>
<span><span class="n">schema</span> <span class="o">=</span> <span class="n">StructType</span><span class="p">([</span></span>
<span>    <span class="n">StructField</span><span class="p">(</span><span class="s2">"Name"</span><span class="p">,</span> <span class="n">StringType</span><span class="p">(),</span> <span class="kc">True</span><span class="p">),</span></span>
<span>    <span class="n">StructField</span><span class="p">(</span><span class="s2">"Age"</span><span class="p">,</span> <span class="n">IntegerType</span><span class="p">(),</span> <span class="kc">True</span><span class="p">)</span></span>
<span><span class="p">])</span></span>

<br /><span><span class="c1"># Create a PySpark DataFrame</span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">data</span><span class="o">=</span><span class="n">data</span><span class="p">,</span> <span class="n">schema</span><span class="o">=</span><span class="n">schema</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>+------+---+
<br />|  Name|Age|
<br />+------+---+
<br />| James| 34|
<br />|  Anna| 28|
<br />|Robert| 45|
<br />+------+---+
<br /></span></code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Create-a-PySpark-DataFrame-From-a-Pandas-DataFrame">Create a PySpark DataFrame From a Pandas DataFrame<a class="anchor-link" href="#Create-a-PySpark-DataFrame-From-a-Pandas-DataFrame">¶</a></h3>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [24]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="kn">import</span> <span class="nn">pandas</span> <span class="k">as</span> <span class="nn">pd</span></span>


<br /><span><span class="c1"># Create a pandas DataFrame</span></span>
<span><span class="n">pandas_df</span> <span class="o">=</span> <span class="n">pd</span><span class="o">.</span><span class="n">DataFrame</span><span class="p">(&#123;</span></span>
<span>    <span class="s1">'Name'</span><span class="p">:</span> <span class="p">[</span><span class="s1">'James'</span><span class="p">,</span> <span class="s1">'Anna'</span><span class="p">,</span> <span class="s1">'Robert'</span><span class="p">],</span></span>
<span>    <span class="s1">'Age'</span><span class="p">:</span> <span class="p">[</span><span class="mi">34</span><span class="p">,</span> <span class="mi">28</span><span class="p">,</span> <span class="mi">45</span><span class="p">]</span></span>
<span><span class="p">&#125;)</span></span>

<br /><span><span class="c1"># Create a PySpark DataFrame from a Pandas DataFrame </span></span>
<span><span class="n">df</span> <span class="o">=</span> <span class="n">spark</span><span class="o">.</span><span class="n">createDataFrame</span><span class="p">(</span><span class="n">pandas_df</span><span class="p">)</span></span>
<span><span class="n">df</span><span class="o">.</span><span class="n">show</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'><span>+------+---+
<br />|  Name|Age|
<br />+------+---+
<br />| James| 34|
<br />|  Anna| 28|
<br />|Robert| 45|
<br />+------+---+
<br /></span></code></pre>
</div>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [25]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Stop the Spark Session</span></span>

<span><span class="n">spark</span><span class="o">.</span><span class="n">stop</span><span class="p">()</span></span>
</code></pre></div>
</div>
</div>
</div>
</div>
</div>
</div>
)}