
import './css/w3school.css';
import './css/pagetable.css';
import './css/App.css';
import './css/main.css';
import './css/codefolding-lua.css'
import './css/build.css'
import './css/klippy.min.css'
import './css/pygments.css';
import './css/homePage.css';
import './css/googlefonts.css';
import './css/blog.css'
import './css/landingPage.css';
import './css/salePage.css'


import {LoadJsScripts, LoadCssScripts} from "./js/loadScripts";
// import Post from './Post';

import React from 'react';
import {Route, Routes} from "react-router-dom";
import { useEffect } from 'react';

import Layout from "./Layout";
import IndexPage from './pages/HomeIndexPage';
// import LoginPage from './pages/LoginPage';
// import RegisterPage from './pages/RegisterPage';
import {UserContextProvider} from "./UserContext"
// import CreatePost from './pages/CreatePost';

import ScrollToTop from './ScrollToTop';
import GalleryIndexPage from './RgalleryPages/GalleryIndex';
// import FaqIndexPage from './faqPages/faqIndexPage';
import RVisualLandingPage from './RVisualization/RVisualLandingPage'
import BookPage from './BookPage';
import BookPageOld from './BookPage_Old'
import TutorialIndexLayout from './Python/basics/TutorialIndexPage';
import PythonPostsIndex from './Python/indexPage';
import VerticalPostsLayout from './VerticalPostsLayout'
// Python Components
import PythonPostsData from './data/PythonPostsData';
// import RPostsData from './data/RFAQPostsData';
import RGalleryData from './data/RGalleryData';
import RVisualizationData from './data/RVisualizationData';
import PythonTutorialData from './data/PythonTutorialData';
import PythonDataWranglingData from './data/PythonDataWranglingData';
import SparkTutorialData from './data/SparkTutorialData';


import PythonBasicsPage from './Python/basics/content/PythonPythonBasicsLandingPage_output_react'
import PythonOpeningstatement from './Python/data wrangling/content/0_OpeningStatement_output_react'
import SparkGettingStarted from './Databricks/contents/0-SparkGettingStarted'
import SparkTutorialIndexLayout from './Databricks/IndexPageSparkTutorial'
import DWTutorialIndexLayout from './Python/data wrangling/TutorialIndexPage'
import RVisualizationIndexLayout from './RVisualization/IndexPageRVisualization'
import RdplyrIndexLayout from './RDataWrangling/dplyr/IndexPageRdplyr';
import RdplyrData from './data/RdplyrData';
import RtidyrData from './data/RtidyrData';
import RtidyrIndexLayout from './RDataWrangling/tidyr/IndexPageRtidyr';
import TidyrIntro from './RDataWrangling/tidyr/contents/introduction_output_react'
import RpurrrIndexLayout from './RDataWrangling/purrr/IndexPageRpurrr';
import RpurrrData from './data/RpurrrData';
import Introduction from './RDataWrangling/dplyr/contents/0-introduction_output_react'
import StringrIntro from './RDataWrangling/stringr/contents/0-introduction_output_react'
import RegexIntro from './RDataWrangling/regular-expression/contents/0-introduction_output_react'
import RDataWranglingLandingPage from './RDataWrangling/RDataWranglingLandingPage'
import RstringrIndexLayout from './RDataWrangling/stringr/IndexPageRstringr'
import RstringrData from './data/RstringrData';
import RregularExpressionIndexLayout from './RDataWrangling/regular-expression/IndexPageRegularExpression';
import RregularExpressionData from './data/RregularExpressionData';
import RtibbleData from './data/RtibbleData.js';
import RtibbleIndexLayout from './RDataWrangling/tibble/IndexPageRtibble';
import TibbleIntro from './RDataWrangling/tibble/contents/introduction_output_react'

import BlogIndexPage from './blog/indexPage'
import BlogData from './data/BlogData';
import BlogLayOut from './blog/LayoutPage';
import RLandingPage from './pages/RLandingPage' 

import Privacy from './pages/PrivacyTerms'
import NotFoundPage from './pages/NotFoundPage';



function App() {
  useEffect(() => {
    const scriptUrls = [            
      // "https://databrewer.s3.amazonaws.com/js/pagedtable.js",
      // "https://s3.amazonaws.com/articles.rtistrystudio/js/clipboard.min.js",  
      "https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.11/clipboard.min.js",
      // "https://s3.amazonaws.com/articles.rtistrystudio/js/klippy.min.js",
      "https://databrewer.s3.amazonaws.com/js/klippy.min.js",
      // "https://s3.amazonaws.com/articles.rtistrystudio/js/bootstrap.min.js"
    ]     
  const cssUrls = [
    // "https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css"
    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css'
  ]

    LoadJsScripts(scriptUrls)
    LoadCssScripts(cssUrls)
    return () => {
      scriptUrls.forEach((scriptUrl) => {
          const script = document.querySelector(`script[src="${scriptUrl}"]`);
          if (script) {
              document.head.removeChild(script);
              console.log(`Script ${scriptUrl} has been removed`);
          }
          });
    }
  }, [])
  
  return (
    <UserContextProvider>
      <ScrollToTop />
        <Routes>   
          <Route path='/' element={<Layout />}>
            <Route index element={<IndexPage />} />
            {/* <Route path='/login' element={<LoginPage />} /> */}
            {/* <Route path='/register' element={<RegisterPage />} /> */}
            {/* <Route path='/create' element={<CreatePost />} /> */}
     
              {/* <Route index element={<GalleryIndexPage />} /> */}
              {/* <Route path='R-Gallery' element={<RIndexPageLayout><GalleryIndexPage /></RIndexPageLayout>} /> */}
            <Route path='R/gallery' element={<GalleryIndexPage />} />

            <Route path='R/gallery/*' element={<VerticalPostsLayout />}>            
              {RGalleryData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))} 
              <Route path='*' element={<NotFoundPage />} />    
            </Route> 

            {/* <Route path='R/visualization/' element={<VerticalPostsLayout />}> */}
            <Route path='R/visualization/' element={<RVisualizationIndexLayout />} >
              <Route index element={<RVisualLandingPage />} />
              {RVisualizationData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))} 
            </Route>

            <Route path='R/data-wrangling/' element={<RDataWranglingLandingPage />}> </Route>

            {/* dplyr */}
            <Route path='R/data-wrangling/dplyr/' element={<RdplyrIndexLayout />} >
              <Route index element={<Introduction />} />
            {RdplyrData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))} 
            </Route>

            {/*stringr  */}
            <Route path='R/data-wrangling/stringr/' element={<RstringrIndexLayout />} >
              <Route index element={<StringrIntro />} />
            {RstringrData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))} 
            </Route>

            <Route path='R/data-wrangling/regular-expression/' element={<RregularExpressionIndexLayout />} >
              <Route index element={<RegexIntro />} />
            {RregularExpressionData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))} 
            </Route>
            
            <Route path='R/data-wrangling/tidyr/' element={<RtidyrIndexLayout />}>
              <Route index element={<TidyrIntro />} />
              {
                RtidyrData.map((data, index) => (
                  <Route key={index} path={data.path} element={data.component} />
                ))
              }
            </Route>

            <Route path='R/data-wrangling/purrr/' element={<RpurrrIndexLayout />}>
              {/* <Route index element={<TidyrIntro />} /> */}
              {
                RpurrrData.map((data, index) => (
                  <Route key={index} path={data.path} element={data.component} />
                ))
              }
            </Route>

            <Route path='R/data-wrangling/tibble/' element={<RtibbleIndexLayout />}>
              <Route index element={<TibbleIntro />} />
              {
                RtibbleData.map((data, index) => (
                  <Route key={index} path={data.path} element={data.component} />
                ))
              }
            </Route>

            <Route path='R/eBook/flash-to-r-ggplot2-mastery' element={<BookPage />} />  
            <Route path='R/eBook/flash-to-r-ggplot2-mastery_old' element={<BookPageOld />} />      
            
            <Route path='python-tutorial/' element={<TutorialIndexLayout />}>
              <Route index path='getting-started' element={<PythonBasicsPage />} />
              {PythonTutorialData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))}        
            </Route>
            <Route path='python-data-wrangling/' element={<DWTutorialIndexLayout />}>
              <Route index element={<PythonOpeningstatement />} />
              {PythonDataWranglingData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))}  
            </Route>
            <Route path='python/' element={<VerticalPostsLayout />}>
              <Route index element={<PythonPostsIndex />} />
              {PythonPostsData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))}  
            </Route>  

            <Route path='pyspark-tutorial/' element={<SparkTutorialIndexLayout />}>
              <Route index path='getting-started' element={<SparkGettingStarted />} />
              {SparkTutorialData.map((data, index) => (
                <Route key={index} path={data.path} element={data.component} />
              ))}   
            </Route>
            <Route path='blog' element={<BlogIndexPage />} />
            <Route path='blog/*' element={<BlogLayOut />}>
              {BlogData.map((data, index) => (
                  <Route key={index} path={data.path} element={data.component} />
                ))}
            </Route>
            <Route path='R' element={<RLandingPage />} />


           

            <Route path='privacy' element={<Privacy />} /> 
            <Route path='*' element={<NotFoundPage />} />     
          </Route>
         
        </Routes>
 </UserContextProvider>

 
  );
}

export default App;
