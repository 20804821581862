import React from 'react';
import { Link } from 'react-router-dom';


const Card = ({path, img, description, color, title}) => {
    var colorClass = color
    if (color == 'pink') {
      colorClass = 'card-block-pink'
    } if (color == 'blue') {
      colorClass = 'card-block-blue'
    } if (color == 'yellow') {
      colorClass = 'card-block-yellow'
    }

    return (
        <div className="card-section flex-width-large-3">
          <Link className='a-black' to={path}>
            <div className="">
              <div className={`card-image ${colorClass} flexCenter card-logo-size`}>
                    <img src={img} className='img-size'></img>
              </div>  
              <div className="p-20 text-center">
                <p  className="fs-20">{description}</p>
              </div>          
              
            </div>                
          </Link>
      </div>  
    )
}


export default Card