import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import imgGgplot2ColorViridisPalette from '../tutorial/viridis_display.png'; 
import imgGgplot2ColorViridisPaletteWebp from '../tutorial/viridis_display.webp'; 
export default function Ggplot2ColorViridisPalette(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">A Complete Guide on the <em>Viridis</em> Color Palettes</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2ColorViridisPaletteWebp} />
    <img className="cover-img" src={imgGgplot2ColorViridisPalette} />
  </picture>

<p>In the former tutorial, we covered in detail the <Link to="../15-ggplot2-color-brewer-palette">brewer palettes</Link>. In this tutorial, we’ll discuss the pretty <strong>viridis color palettes</strong> (shown above), which shares a similar syntax with the brewer palettes.</p>
<ul>
<li><a href="#three_types_scale">Three types of scales: <code>scale_color_viridis_d</code>, <code>c</code>, and <code>b</code></a></li>
<li><a href="#extraction_colors">Extract color hex codes from the viridis palettes</a></li>
<li><a href="#display">Display colors from the viridis palettes</a></li>
</ul>
<hr/>
<section className="level3" id="three_types_scale">
<h3 className="anchored" data-anchor-id="three_types_scale"><code>scale_color_viridis_*()</code> with suffix <strong><code>d</code></strong>, <strong><code>c</code></strong>, or <strong><code>b</code></strong></h3>
<p>The basic formula of a viridis color scale is <code>scale_color_viridis_*()</code>, with <code>*</code> referring to three suffix options, including <code>d</code>, <code>c</code>, and <code>b</code>, depending on the type of the variable that is mapped to the <code>color</code> aesthetic. The palette is selected using argument e.g. <code>option = "C"</code> or <code>option = "plasma"</code> (see names and associated alphabets above).</p>
<ul>
<li>When a <strong>categorical</strong> variable is mapped to <code>color</code>, use <code>scale_color_viridis_d</code> to create <em>discrete</em> color legends.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages and global theme</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_bw</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>base <span className="ot">&lt;-</span> iris <span className="sc">%&gt;%</span> <span className="fu">ggplot</span>(<span className="fu">aes</span>(Sepal.Length, Sepal.Width)) </span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>base <span className="sc">+</span> </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="fu">aes</span>(<span className="at">color =</span> Species), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span> <span className="co"># Species a categorical variable</span></span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">scale_color_viridis_d</span>(<span className="at">option =</span> <span className="st">"D"</span>) <span className="co"># use suffix 'd' for discrete color scale</span></span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/viridis_discrete_scale.png"/></p>
</figure>
</div>
</div>
</div>
<p>When a <strong>numerical</strong> variable is mapped to <code>color</code>:</p>
<ul>
<li>use <code>scale_color_viridis_c</code> to generate <em>continuous</em> color bar, or</li>
<li>use <code>scale_color_viridis_b</code> to create <em>binned</em> (stepwise) color scale.</li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>base <span className="sc">+</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="co"># Petal.Length a numeric variable</span></span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="fu">aes</span>(<span className="at">color =</span> Petal.Length), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span> </span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a>  <span className="co"># use suffix 'c' for continuous color scale</span></span>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a>  <span className="fu">scale_color_viridis_c</span>(<span className="at">option =</span> <span className="st">"D"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/viridis_continuous_colorbar.png"/></p>
</figure>
</div>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>base <span className="sc">+</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">geom_jitter</span>(<span className="fu">aes</span>(<span className="at">color =</span> Petal.Length), <span className="at">size =</span> <span className="dv">3</span>) <span className="sc">+</span> </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>  <span className="co"># use suffix "b" for binned color legend</span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>  <span className="fu">scale_color_viridis_b</span>(<span className="at">option =</span> <span className="st">"D"</span>) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/viridis_binned_scale.png"/></p>
</figure>
</div>
</div>
</div>
<blockquote className="blockquote">
<p>In like manner, <code>scale_fill_viridis_*()</code> is used for the <code>fill</code> aesthetic, with the suffix options following the same rules above.</p>
</blockquote>
</section>
<section className="level3" id="extraction_colors">
<h3 className="anchored" data-anchor-id="extraction_colors">Extract colors from viridis palettes</h3>
<p>The above <code>scale_color_viridis_*()</code> functions are built-in ggplot2, and are directly available once the package ggplot2 is loaded. However, in order to extract color hex codes from the viridis palettes, you need to additionally load the <code>viridis</code> package.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="fu">library</span>(viridis)</span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a><span className="co"># extract 20 colors from palette option A</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>a <span className="ot">&lt;-</span> <span className="fu">viridis</span>(<span className="dv">20</span>, <span className="at">option =</span> <span className="st">"A"</span>)</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>a</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"> [1] "#000004FF" "#07071DFF" "#160F3BFF" "#29115AFF" "#400F73FF" "#56147DFF"
<br/> [7] "#6B1D81FF" "#802582FF" "#952C80FF" "#AB337CFF" "#C03A76FF" "#D6456CFF"
<br/>[13] "#E85362FF" "#F4685CFF" "#FA815FFF" "#FD9A6AFF" "#FEB37BFF" "#FECC8FFF"
<br/>[19] "#FDE4A6FF" "#FCFDBFFF"</code></pre>
</div>
</div>
<p>Unlike the <Link to="../15-ggplot2-color-brewer-palette">brewer palettes</Link> which are subjected to the maximum color number limits, the viridis palette has no such limit; it allows an unlimited number of transitional colors to be automatically interpolated and extracted.</p>
<p>Use the <code>scales</code> package to visualize the color hex codes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(scales)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a><span className="fu">show_col</span>(a, <span className="at">cex_label =</span> .<span className="dv">6</span>) <span className="co"># use 'cex_label' to set label size</span></span></code></pre></div>
</div>
<p><img className="img-fluid" src="tutorial/viridis_scales_show_col_cropped.png"/></p>
</section>
<section className="level3" id="display">
<h3 className="anchored" data-anchor-id="display">Display colors from the viridis palettes</h3>
<p>There is no simple function to display the color scales in an easy way. However, you can plot out the palettes using the following script. The script is contributed by <Link to="https://jmsallan.netlify.app/blog/the-viridis-palettes/">Prof. José María Sallan Leyes</Link> at Universitat Politècnica de Catalunya.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>viridis_names <span className="ot">&lt;-</span><span className="fu">c</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="st">"A (magma)"</span>, <span className="st">"B (inferno)"</span>, <span className="st">"C (plasma)"</span>, <span className="st">"D (viridis)"</span>, </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="st">"E (cividis)"</span>, <span className="st">"F (rocket)"</span>, <span className="st">"G (mako)"</span>, <span className="st">"H (turbo)"</span>)</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a></span><br/>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>n <span className="ot">&lt;-</span> <span className="dv">50</span> <span className="co"># control the smoothness of color transition</span></span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a><span className="fu">par</span>(<span className="at">mfrow=</span><span className="fu">c</span>(<span className="dv">4</span>,<span className="dv">2</span>), <span className="at">mar =</span> <span className="fu">rep</span>(<span className="dv">1</span>, <span className="dv">4</span>))</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a></span><br/>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>f <span className="ot">&lt;-</span> <span className="fu">sapply</span>(<span className="dv">1</span><span className="sc">:</span><span className="dv">8</span>, <span className="cf">function</span>(x) <span className="fu">image</span>(</span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>  <span className="fu">matrix</span>(<span className="dv">1</span><span className="sc">:</span>n, n, <span className="dv">1</span>), </span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>  <span className="at">col =</span> <span className="fu">viridis</span>(<span className="at">n =</span> n, <span className="at">option =</span> LETTERS[x]), </span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>  <span className="at">axes =</span><span className="cn">FALSE</span>, <span className="at">main =</span> viridis_names[x], <span className="at">cex.main =</span> <span className="fl">1.5</span>)</span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>)</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
<p><img className="img-fluid quarto-figure quarto-figure-center figure-img" src="tutorial/viridis_display.png"/></p>
</figure>
</div>
</div>
</div>
</section>
</main>
</div>
</div>
)}