import React from 'react'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonOperationsarithmetic(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Arithmetic-Operations">Arithmetic Operations<a class="anchor-link" href="#Arithmetic-Operations">¶</a></h3><p>Python's arithmetic operations on numeric values follow conventional mathematical calculations. Beyond numbers, programming also extends arithmetic operators to other types of values, such as strings and lists. Let's explore how they interact!</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Numbers">Numbers<a class="anchor-link" href="#Numbers">¶</a></h4><p>In python, numbers include both integers and floats. We'll first explore arithmetic operations with these data types.</p>
<h5 id="Arithmetic-operators">Arithmetic operators<a class="anchor-link" href="#Arithmetic-operators">¶</a></h5><ul>
    <a href='#Example-of-Summation-and-Deduction'><li><strong>summation</strong>: <code>+</code></li></a>
<a href='#Example-of-Summation-and-Deduction'><li><strong>deduction</strong>: <code>-</code></li></a>
<a href='#Example-of-Multiplication-and-Division'><li><strong>multiplication</strong>: <code>*</code></li></a>
<a href='#Example-of-Multiplication-and-Division'><li><strong>division</strong>: <code>/</code></li></a>
<a href='#Example-of-Modulo-and-Exponential'><li><strong>modulo</strong>: <code>%</code></li></a>
    <ul>
        <li>It is used to find the remainder of the division of the left operand by the right operand.</li>
        <li>e.g. <code>7%2 = 1</code></li>
    </ul>
<a href='#Example-of-Modulo-and-Exponential'><li><strong>exponential</strong>: <code>**</code></li></a>
    <ul>
    <li>e.g. <code>3**2 = 9</code></li>
    </ul>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example-of-Summation-and-Deduction">Example of Summation and Deduction<a class="anchor-link" href="#Example-of-Summation-and-Deduction">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables</span></span>

<span><span class="n">a</span> <span class="o">=</span> <span class="mi">10</span></span>

<span><span class="n">b</span> <span class="o">=</span> <span class="mf">2.5</span></span>


<br /><span><span class="c1"># summation</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">a</span> <span class="o">+</span> <span class="n">b</span><span class="p">)</span></span>


<br /><span><span class="c1"># deduction</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">a</span> <span class="o">-</span> <span class="n">b</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>12.5
<br />7.5
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example-of-Multiplication-and-Division">Example of Multiplication and Division<a class="anchor-link" href="#Example-of-Multiplication-and-Division">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables</span></span>

<span><span class="n">a</span> <span class="o">=</span> <span class="mi">20</span></span>

<span><span class="n">b</span> <span class="o">=</span> <span class="mf">2.5</span></span>


<br /><span><span class="c1"># multiplication</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">a</span><span class="o"> * </span><span class="n">b</span><span class="p">)</span></span>


<br /><span><span class="c1"># division</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">a</span><span class="o"> / </span><span class="n">b</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>50.0
<br />8.0
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Example-of-Modulo-and-Exponential">Example of Modulo and Exponential<a class="anchor-link" href="#Example-of-Modulo-and-Exponential">¶</a></h5>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create variables</span></span>

<span><span class="n">a</span> <span class="o">=</span> <span class="mi">7</span></span>

<span><span class="n">b</span> <span class="o">=</span> <span class="mi">2</span></span>


<br /><span><span class="c1"># Example for modulo </span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s2">"Example for modulo: 7%2"</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">a</span><span class="o"> % </span><span class="k">b</span>)
</span>

<br /><span><span class="c1"># Example for exponential</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="s2">"Example for exponetial: 3**2"</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">a</span><span class="o">**</span><span class="n">b</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">  
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Example for modulo: 7%2
<br />1
<br />Example for exponetial: 3**2
<br />49
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Strings">Strings<a class="anchor-link" href="#Strings">¶</a></h4><p>Arithmetic operators in Python can be used with various data types, not just numbers. Let's see how they function with strings.</p>
<h5 id="Operators-Used-in-Strings:">Operators Used in Strings:<a class="anchor-link" href="#Operators-Used-in-Strings:">¶</a></h5><ul>
<li>Concatenation using <code>+</code></li>
<li>Repetition using <code>*</code></li>
</ul>
<p>other arithmetic operators like <code>-, /, and %</code> are not applicable to strings in Python</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Concatenation-of-strings">Concatenation of strings<a class="anchor-link" href="#Concatenation-of-strings">¶</a></h5><p>String concatenation, crucial in data preprocessing and web development, effectively merges separate strings. This can be done using the <code>+</code> operator to combine two or more strings.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [13]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># creating string variables</span></span>

<span><span class="n">text1</span> <span class="o">=</span> <span class="s1">'Hello'</span></span>

<span><span class="n">text2</span> <span class="o">=</span> <span class="s2">"World"</span></span>


<br /><span><span class="c1"># Add strings together and print the result</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">text1</span> <span class="o">+</span> <span class="n">text2</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>HelloWorld
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The output string is a concatenation of <code>text1</code> and <code>text2</code>, yet they lack a space in between. Let's fix that by placing a space string <code>' '</code> in between.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [14]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="nb">print</span><span class="p">(</span><span class="n">text1</span> <span class="o">+</span> <span class="s1">' '</span> <span class="o">+</span> <span class="n">text2</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Hello World
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="String-repetition">String repetition<a class="anchor-link" href="#String-repetition">¶</a></h5><p>String repetition, achieved with the <code>*</code> operator, efficiently creates repetitive strings without manual typing.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [17]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">str1</span> <span class="o">=</span> <span class="s2">"Repeat me! "</span></span>

<span><span class="n">repeated_str</span> <span class="o">=</span> <span class="n">str1</span> <span class="o">*</span> <span class="mi">3</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">repeated_str</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Repeat me! Repeat me! Repeat me! 
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Note that a space is included at the end of <code>str1</code>, so the <code>repeated_str</code> has proper space in between.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Lists">Lists<a class="anchor-link" href="#Lists">¶</a></h4><p>In Python, arithmetic operators can be used on lists to perform various operations, such as concatenation, repetition, and element-wise addition or multiplication.</p>
<h5 id="Operators-Used-in-Lists:">Operators Used in Lists:<a class="anchor-link" href="#Operators-Used-in-Lists:">¶</a></h5><ul>
<li>Concatenation using <code>+</code></li>
<li>Repetition using <code>*</code></li>
</ul>
<p>other arithmetic operators like <code>-, /, and %</code> are not applicable to lists in Python</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Combination-of-lists">Combination of lists<a class="anchor-link" href="#Combination-of-lists">¶</a></h5><p>Lists combination effectively merges separate lists. This can be done using the <code>+</code> operator to combine two or more lists.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create lists</span></span>

<span><span class="n">list1</span> <span class="o">=</span> <span class="p">[</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">]</span></span>

<span><span class="n">list2</span> <span class="o">=</span> <span class="p">[</span><span class="mi">4</span><span class="p">,</span> <span class="mi">3</span><span class="p">,</span> <span class="mi">6</span><span class="p">]</span></span>


<br /><span><span class="c1"># Combine two lists</span></span>

<span><span class="n">result</span> <span class="o">=</span> <span class="n">list1</span> <span class="o">+</span> <span class="n">list2</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">result</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>[1, 2, 3, 4, 3, 6]
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="List-repetition">List repetition<a class="anchor-link" href="#List-repetition">¶</a></h5><p>You can repeat a list by using the * operator.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="n">list1</span> <span class="o">=</span> <span class="p">[</span><span class="mi">1</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="mi">3</span><span class="p">]</span></span>


<br /><span><span class="c1"># Repeat the list </span></span>

<span><span class="n">repeated_list</span> <span class="o">=</span> <span class="n">list1</span> <span class="o">*</span> <span class="mi">3</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">repeated_list</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>[1, 2, 3, 1, 2, 3, 1, 2, 3]
</code></pre>
</div>
</div>
</div>
</div>
</div>
</div>
)}