import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2LinePlotSocialMobility from '../graphics/lines_social_mobility_theme_completed.png'; 
import imgGgplot2LinePlotSocialMobilityWebp from '../graphics/lines_social_mobility_theme_completed.webp'; 
export default function Ggplot2LinePlotSocialMobility(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Visualize Social Mobility with Line plot Using ggplot2</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><span id="mobility"><img className="cover-img" src={imgGgplot2LinePlotSocialMobility} /></span></p>
<p>In this article, we’ll create a line plot to visualize the social mobility: how does a father’s occupation impact his son’s career path, surveyed in the 1970s in the United States.</p>
<p><strong>Major techniques covered in this tutorial include:</strong></p>
<ul>
<li><a href="#jittered_lines">Create lines in “jittered” position.</a></li>
<li><a href="#jittered_lines">Add secondary labels to categorical axis.</a></li>
<li><a href="#color_scale_skewed_data">Customize color scale for skewed dataset.</a></li>
<li><a href="#theme">Theme customization.</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="packages-and-data-cleanup">
<h3 className="anchored" data-anchor-id="packages-and-data-cleanup">Packages and data cleanup</h3>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(survival) <span className="co"># the dataset package</span></span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a>occupations <span className="ot">&lt;-</span> <span className="fu">levels</span>(logan<span className="sc">$</span>occupation)</span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a></span><br/>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a>logan.tidy <span className="ot">&lt;-</span>  logan <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">subject =</span> <span className="dv">1</span><span className="sc">:</span><span className="fu">nrow</span>(logan)) <span className="sc">%&gt;%</span> </span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="fu">c</span>(occupation, focc), </span>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"father.son"</span>, </span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"occupation"</span>)</span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a></span><br/>
<span id="cb1-14"><a aria-hidden="true" href="#cb1-14" tabindex="-1"></a><span className="fu">head</span>(logan.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 5
<br/>  education race      subject father.son occupation  
<br/>      &lt;int&gt; &lt;fct&gt;       &lt;int&gt; &lt;chr&gt;      &lt;fct&gt;       
<br/>1        14 non-black       1 occupation sales       
<br/>2        14 non-black       1 focc       professional
<br/>3        13 non-black       2 occupation craftsmen   </code></pre>
</div>
</div>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="jittered_lines"><strong>Create a “jittered” line plot.</strong> Each single line represents a father-son pair. A technical highlight in this visualization is the <strong>addition of a secondary axis to a <em>discrete (categorical)</em> scale</strong>, which is officially not supported in ggplot2. The secrete of achieving this goal is to first convert the categorical variable (i.e., <code>occupation</code>) into a numerical variable to enable the addition of the secondary axis (as we do at this step). We’ll then update the axis <code>labels</code> in a following step.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> logan.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> father.son, </span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>             <span className="co">#   as.numeric to enable adding secondary axis </span></span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>             <span className="at">y =</span> <span className="fu">as.numeric</span>(occupation), </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>             <span className="at">color =</span> education)) <span className="sc">+</span></span>
<span id="cb3-6"><a aria-hidden="true" href="#cb3-6" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> subject), <span className="at">alpha =</span> .<span className="dv">4</span>,</span>
<span id="cb3-7"><a aria-hidden="true" href="#cb3-7" tabindex="-1"></a>            </span>
<span id="cb3-8"><a aria-hidden="true" href="#cb3-8" tabindex="-1"></a>            <span className="co"># lines are "jittered" such that each occupation pair </span></span>
<span id="cb3-9"><a aria-hidden="true" href="#cb3-9" tabindex="-1"></a>            <span className="co"># between the father and the son can be individually displayed. </span></span>
<span id="cb3-10"><a aria-hidden="true" href="#cb3-10" tabindex="-1"></a>            <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">07</span>, .<span className="dv">07</span>, <span className="at">seed =</span> <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb3-11"><a aria-hidden="true" href="#cb3-11" tabindex="-1"></a>  </span>
<span id="cb3-12"><a aria-hidden="true" href="#cb3-12" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb3-13"><a aria-hidden="true" href="#cb3-13" tabindex="-1"></a></span><br/>
<span id="cb3-14"><a aria-hidden="true" href="#cb3-14" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_line_plot.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_social_mobility_line_plot"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Add secondary axis.</strong> The secondary axis transformation <code>sec_axis(trans = ~., ...)</code> at this step is possible only because the <code>occupation</code> variable had been turned into a numerical variable in the earlier step. Here we manually update the axis labels into the desired job names using the <code>labels</code> argument.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span>  </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="co"># fix labels of the original y axis</span></span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>    <span className="at">labels =</span> occupations, <span className="co"># update axis label</span></span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>    <span className="at">name =</span> <span className="st">"Father"</span>,</span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>    <span className="co"># add a secondary axis to display the son's job</span></span>
<span id="cb4-7"><a aria-hidden="true" href="#cb4-7" tabindex="-1"></a>    <span className="at">sec.axis =</span> <span className="fu">sec_axis</span>(<span className="at">trans =</span> <span className="sc">~</span>., </span>
<span id="cb4-8"><a aria-hidden="true" href="#cb4-8" tabindex="-1"></a>                        <span className="at">labels =</span> occupations, <span className="co"># update axis label</span></span>
<span id="cb4-9"><a aria-hidden="true" href="#cb4-9" tabindex="-1"></a>                        <span className="at">name =</span> <span className="st">"Son"</span>))</span>
<span id="cb4-10"><a aria-hidden="true" href="#cb4-10" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_secondary_axis.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_social_mobility_secondary_axis"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="color_scale_skewed_data"><strong>Customize the color scale.</strong> Observations with shorter education (0 ~ 10 years) are represented by green colors with slow color transition. As majority of the observations have longer education (&gt; 10 years), the color palettes are more dynamically applied to this data range, as adjusted by the <code>bias</code> argument. (This powerful technique is also employed to create these <Link to="../ggplot2-heatmap-vaccine-for-8-diseases"><strong>beautiful heatmaps</strong></Link>)</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"green4"</span>, <span className="st">"green1"</span>, <span className="st">"skyblue2"</span>, <span className="st">"blue1"</span>, <span className="st">"firebrick"</span>,</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>              <span className="st">"tomato"</span>, <span className="st">"orange"</span>, <span className="st">"gold"</span>, <span className="st">"yellow"</span>, <span className="st">"beige"</span>, <span className="st">"white"</span>)</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(myColors, <span className="at">bias =</span> .<span className="dv">4</span>)(<span className="dv">20</span>)</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a></span><br/>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>  <span className="fu">scale_color_gradientn</span>(<span className="at">colors =</span> myColors)</span>
<span id="cb5-7"><a aria-hidden="true" href="#cb5-7" tabindex="-1"></a></span><br/>
<span id="cb5-8"><a aria-hidden="true" href="#cb5-8" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_color_scale.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_social_mobility_color_scale"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>When customizing a color scale for skewed data distribution like this, another powerful approach is to use logarithmic or pseudo-logarithmic transformation, as demonstrated in this <a href="#African_population_heatmap"><strong>fabulous map of African population density</strong></a>.</p>
<p><br/></p>
<p><span id="theme"><strong>Adjust the legend, and add plot titles.</strong> It is important to note that <code>title.theme</code> in <code>guides()</code> <strong>takes precedence</strong> over <code>legend.title</code> in the generic <code>theme</code> syntax.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="co"># adjust legend color bar </span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">color =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">8</span>, <span className="st">"pt"</span>),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"total education years"</span>,</span>
<span id="cb6-8"><a aria-hidden="true" href="#cb6-8" tabindex="-1"></a>    </span>
<span id="cb6-9"><a aria-hidden="true" href="#cb6-9" tabindex="-1"></a>    <span className="co"># important: "title.theme" in "guides" takes precedence over</span></span>
<span id="cb6-10"><a aria-hidden="true" href="#cb6-10" tabindex="-1"></a>    <span className="co"># "legend.title" in the generic "theme" syntax</span></span>
<span id="cb6-11"><a aria-hidden="true" href="#cb6-11" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(</span>
<span id="cb6-12"><a aria-hidden="true" href="#cb6-12" tabindex="-1"></a>      <span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">color =</span> <span className="st">"snow3"</span>))) <span className="sc">+</span></span>
<span id="cb6-13"><a aria-hidden="true" href="#cb6-13" tabindex="-1"></a>  </span>
<span id="cb6-14"><a aria-hidden="true" href="#cb6-14" tabindex="-1"></a>  <span className="co"># remove x (left) - axis title</span></span>
<span id="cb6-15"><a aria-hidden="true" href="#cb6-15" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>)  <span className="sc">+</span></span>
<span id="cb6-16"><a aria-hidden="true" href="#cb6-16" tabindex="-1"></a>  <span className="co"># add plot titles</span></span>
<span id="cb6-17"><a aria-hidden="true" href="#cb6-17" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Social ladder &amp; mobility:"</span>,</span>
<span id="cb6-18"><a aria-hidden="true" href="#cb6-18" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"What does the son of a craftsman do?"</span>) </span>
<span id="cb6-19"><a aria-hidden="true" href="#cb6-19" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_legend.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_social_mobility_legend"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Update the theme.</strong> It is worth mentioning that in the <code>theme</code> syntax, the vertical axis is always considered as the y-axis, and the horizontal one as the x-axis, regardless of the presence of <code>coord_flip</code> or not.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="co"># background</span></span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>    </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="co"># legend</span></span>
<span id="cb7-9"><a aria-hidden="true" href="#cb7-9" tabindex="-1"></a>    <span className="at">legend.background =</span> <span className="fu">element_blank</span>(), <span className="co"># remove legend background</span></span>
<span id="cb7-10"><a aria-hidden="true" href="#cb7-10" tabindex="-1"></a>    <span className="at">legend.margin =</span> <span className="fu">margin</span>(<span className="at">l =</span> <span className="dv">20</span>), <span className="co"># increase legend left margin</span></span>
<span id="cb7-11"><a aria-hidden="true" href="#cb7-11" tabindex="-1"></a>    </span>
<span id="cb7-12"><a aria-hidden="true" href="#cb7-12" tabindex="-1"></a>    <span className="co"># texts</span></span>
<span id="cb7-13"><a aria-hidden="true" href="#cb7-13" tabindex="-1"></a>    <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"snow"</span>, <span className="at">size =</span> <span className="dv">14</span>),</span>
<span id="cb7-14"><a aria-hidden="true" href="#cb7-14" tabindex="-1"></a>    <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb7-15"><a aria-hidden="true" href="#cb7-15" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"snow"</span>),</span>
<span id="cb7-16"><a aria-hidden="true" href="#cb7-16" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb7-17"><a aria-hidden="true" href="#cb7-17" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>),</span>
<span id="cb7-18"><a aria-hidden="true" href="#cb7-18" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">size =</span> <span className="dv">12</span>),</span>
<span id="cb7-19"><a aria-hidden="true" href="#cb7-19" tabindex="-1"></a>    <span className="co"># increase margin around the plot</span></span>
<span id="cb7-20"><a aria-hidden="true" href="#cb7-20" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>)) </span>
<span id="cb7-21"><a aria-hidden="true" href="#cb7-21" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/lines_social_mobility_theme_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/lines_social_mobility_theme_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a><span className="fu">library</span>(survival)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a></span><br/>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>occupations <span className="ot">&lt;-</span> <span className="fu">levels</span>(logan<span className="sc">$</span>occupation)</span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a></span><br/>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>logan.tidy <span className="ot">&lt;-</span>  logan <span className="sc">%&gt;%</span> <span className="fu">as_tibble</span>() <span className="sc">%&gt;%</span> </span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">subject =</span> <span className="dv">1</span><span className="sc">:</span><span className="fu">nrow</span>(logan)) <span className="sc">%&gt;%</span> </span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(<span className="fu">c</span>(occupation, focc), </span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>               <span className="at">names_to =</span> <span className="st">"father.son"</span>, </span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>               <span className="at">values_to =</span> <span className="st">"occupation"</span>)</span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a></span><br/>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a><span className="fu">head</span>(logan.tidy, <span className="at">n =</span> <span className="dv">3</span>)</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a></span><br/>
<span id="cb8-16"><a aria-hidden="true" href="#cb8-16" tabindex="-1"></a></span><br/>
<span id="cb8-17"><a aria-hidden="true" href="#cb8-17" tabindex="-1"></a><span className="co"># Create a "jittered" line plot.</span></span>
<span id="cb8-18"><a aria-hidden="true" href="#cb8-18" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> logan.tidy <span className="sc">%&gt;%</span> </span>
<span id="cb8-19"><a aria-hidden="true" href="#cb8-19" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> father.son, </span>
<span id="cb8-20"><a aria-hidden="true" href="#cb8-20" tabindex="-1"></a>             <span className="co">#   as.numeric to enable adding secondary axis </span></span>
<span id="cb8-21"><a aria-hidden="true" href="#cb8-21" tabindex="-1"></a>             <span className="at">y =</span> <span className="fu">as.numeric</span>(occupation), </span>
<span id="cb8-22"><a aria-hidden="true" href="#cb8-22" tabindex="-1"></a>             <span className="at">color =</span> education)) <span className="sc">+</span></span>
<span id="cb8-23"><a aria-hidden="true" href="#cb8-23" tabindex="-1"></a>  <span className="fu">geom_line</span>(<span className="fu">aes</span>(<span className="at">group =</span> subject), <span className="at">alpha =</span> .<span className="dv">4</span>,</span>
<span id="cb8-24"><a aria-hidden="true" href="#cb8-24" tabindex="-1"></a>            </span>
<span id="cb8-25"><a aria-hidden="true" href="#cb8-25" tabindex="-1"></a>            <span className="co"># lines are "jittered" such that each occupation pair </span></span>
<span id="cb8-26"><a aria-hidden="true" href="#cb8-26" tabindex="-1"></a>            <span className="co"># between the father and the son can be individually displayed. </span></span>
<span id="cb8-27"><a aria-hidden="true" href="#cb8-27" tabindex="-1"></a>            <span className="at">position =</span> <span className="fu">position_jitter</span>(.<span className="dv">07</span>, .<span className="dv">07</span>, <span className="at">seed =</span> <span className="dv">1</span>)) <span className="sc">+</span></span>
<span id="cb8-28"><a aria-hidden="true" href="#cb8-28" tabindex="-1"></a>  </span>
<span id="cb8-29"><a aria-hidden="true" href="#cb8-29" tabindex="-1"></a>  <span className="fu">coord_flip</span>(<span className="at">expand =</span> <span className="dv">0</span>) </span>
<span id="cb8-30"><a aria-hidden="true" href="#cb8-30" tabindex="-1"></a></span><br/>
<span id="cb8-31"><a aria-hidden="true" href="#cb8-31" tabindex="-1"></a>p1</span>
<span id="cb8-32"><a aria-hidden="true" href="#cb8-32" tabindex="-1"></a></span><br/>
<span id="cb8-33"><a aria-hidden="true" href="#cb8-33" tabindex="-1"></a></span><br/>
<span id="cb8-34"><a aria-hidden="true" href="#cb8-34" tabindex="-1"></a><span className="co"># Add secondary axis.</span></span>
<span id="cb8-35"><a aria-hidden="true" href="#cb8-35" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span>  </span>
<span id="cb8-36"><a aria-hidden="true" href="#cb8-36" tabindex="-1"></a>  <span className="co"># fix labels of the original y axis</span></span>
<span id="cb8-37"><a aria-hidden="true" href="#cb8-37" tabindex="-1"></a>  <span className="fu">scale_y_continuous</span>(</span>
<span id="cb8-38"><a aria-hidden="true" href="#cb8-38" tabindex="-1"></a>    <span className="at">labels =</span> occupations, <span className="co"># update axis label</span></span>
<span id="cb8-39"><a aria-hidden="true" href="#cb8-39" tabindex="-1"></a>    <span className="at">name =</span> <span className="st">"Father"</span>,</span>
<span id="cb8-40"><a aria-hidden="true" href="#cb8-40" tabindex="-1"></a>    <span className="co"># add a secondary axis to display the son's job</span></span>
<span id="cb8-41"><a aria-hidden="true" href="#cb8-41" tabindex="-1"></a>    <span className="at">sec.axis =</span> <span className="fu">sec_axis</span>(<span className="at">trans =</span> <span className="sc">~</span>., </span>
<span id="cb8-42"><a aria-hidden="true" href="#cb8-42" tabindex="-1"></a>                        <span className="at">labels =</span> occupations, <span className="co"># update axis label</span></span>
<span id="cb8-43"><a aria-hidden="true" href="#cb8-43" tabindex="-1"></a>                        <span className="at">name =</span> <span className="st">"Son"</span>))</span>
<span id="cb8-44"><a aria-hidden="true" href="#cb8-44" tabindex="-1"></a>p2</span>
<span id="cb8-45"><a aria-hidden="true" href="#cb8-45" tabindex="-1"></a></span><br/>
<span id="cb8-46"><a aria-hidden="true" href="#cb8-46" tabindex="-1"></a></span><br/>
<span id="cb8-47"><a aria-hidden="true" href="#cb8-47" tabindex="-1"></a><span className="co"># Update the color scale.</span></span>
<span id="cb8-48"><a aria-hidden="true" href="#cb8-48" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"green4"</span>, <span className="st">"green1"</span>, <span className="st">"skyblue2"</span>, <span className="st">"blue1"</span>, <span className="st">"firebrick"</span>,</span>
<span id="cb8-49"><a aria-hidden="true" href="#cb8-49" tabindex="-1"></a>              <span className="st">"tomato"</span>, <span className="st">"orange"</span>, <span className="st">"gold"</span>, <span className="st">"yellow"</span>, <span className="st">"beige"</span>, <span className="st">"white"</span>)</span>
<span id="cb8-50"><a aria-hidden="true" href="#cb8-50" tabindex="-1"></a>myColors <span className="ot">&lt;-</span> <span className="fu">colorRampPalette</span>(myColors, <span className="at">bias =</span> .<span className="dv">4</span>)(<span className="dv">20</span>)</span>
<span id="cb8-51"><a aria-hidden="true" href="#cb8-51" tabindex="-1"></a></span><br/>
<span id="cb8-52"><a aria-hidden="true" href="#cb8-52" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span></span>
<span id="cb8-53"><a aria-hidden="true" href="#cb8-53" tabindex="-1"></a>  <span className="fu">scale_color_gradientn</span>(<span className="at">colors =</span> myColors)</span>
<span id="cb8-54"><a aria-hidden="true" href="#cb8-54" tabindex="-1"></a></span><br/>
<span id="cb8-55"><a aria-hidden="true" href="#cb8-55" tabindex="-1"></a>p3</span>
<span id="cb8-56"><a aria-hidden="true" href="#cb8-56" tabindex="-1"></a></span><br/>
<span id="cb8-57"><a aria-hidden="true" href="#cb8-57" tabindex="-1"></a></span><br/>
<span id="cb8-58"><a aria-hidden="true" href="#cb8-58" tabindex="-1"></a><span className="co"># Adjust the legend, and add plot titles.</span></span>
<span id="cb8-59"><a aria-hidden="true" href="#cb8-59" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> </span>
<span id="cb8-60"><a aria-hidden="true" href="#cb8-60" tabindex="-1"></a>  <span className="co"># adjust legend color bar </span></span>
<span id="cb8-61"><a aria-hidden="true" href="#cb8-61" tabindex="-1"></a>  <span className="fu">guides</span>(<span className="at">color =</span> <span className="fu">guide_colorbar</span>(</span>
<span id="cb8-62"><a aria-hidden="true" href="#cb8-62" tabindex="-1"></a>    <span className="at">barheight =</span> <span className="fu">unit</span>(<span className="dv">200</span>, <span className="st">"pt"</span>),</span>
<span id="cb8-63"><a aria-hidden="true" href="#cb8-63" tabindex="-1"></a>    <span className="at">barwidth =</span> <span className="fu">unit</span>(<span className="dv">8</span>, <span className="st">"pt"</span>),</span>
<span id="cb8-64"><a aria-hidden="true" href="#cb8-64" tabindex="-1"></a>    <span className="at">title.position =</span> <span className="st">"left"</span>,</span>
<span id="cb8-65"><a aria-hidden="true" href="#cb8-65" tabindex="-1"></a>    <span className="at">title =</span> <span className="st">"total education years"</span>,</span>
<span id="cb8-66"><a aria-hidden="true" href="#cb8-66" tabindex="-1"></a>    </span>
<span id="cb8-67"><a aria-hidden="true" href="#cb8-67" tabindex="-1"></a>    <span className="co"># important: "title.theme" in "guides" takes precedence over</span></span>
<span id="cb8-68"><a aria-hidden="true" href="#cb8-68" tabindex="-1"></a>    <span className="co"># "legend.title" in the generic "theme" syntax</span></span>
<span id="cb8-69"><a aria-hidden="true" href="#cb8-69" tabindex="-1"></a>    <span className="at">title.theme =</span> <span className="fu">element_text</span>(</span>
<span id="cb8-70"><a aria-hidden="true" href="#cb8-70" tabindex="-1"></a>      <span className="at">angle =</span> <span className="dv">90</span>, <span className="at">hjust =</span> .<span className="dv">5</span>, <span className="at">color =</span> <span className="st">"snow3"</span>))) <span className="sc">+</span></span>
<span id="cb8-71"><a aria-hidden="true" href="#cb8-71" tabindex="-1"></a>  </span>
<span id="cb8-72"><a aria-hidden="true" href="#cb8-72" tabindex="-1"></a>  <span className="co"># remove x (left) - axis title</span></span>
<span id="cb8-73"><a aria-hidden="true" href="#cb8-73" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">x =</span> <span className="cn">NULL</span>)  <span className="sc">+</span></span>
<span id="cb8-74"><a aria-hidden="true" href="#cb8-74" tabindex="-1"></a>  <span className="co"># add plot titles</span></span>
<span id="cb8-75"><a aria-hidden="true" href="#cb8-75" tabindex="-1"></a>  <span className="fu">labs</span>(<span className="at">title =</span> <span className="st">"Social ladder &amp; mobility:"</span>,</span>
<span id="cb8-76"><a aria-hidden="true" href="#cb8-76" tabindex="-1"></a>       <span className="at">subtitle =</span> <span className="st">"What does the son of a craftsman do?"</span>) </span>
<span id="cb8-77"><a aria-hidden="true" href="#cb8-77" tabindex="-1"></a>p4</span>
<span id="cb8-78"><a aria-hidden="true" href="#cb8-78" tabindex="-1"></a></span><br/>
<span id="cb8-79"><a aria-hidden="true" href="#cb8-79" tabindex="-1"></a></span><br/>
<span id="cb8-80"><a aria-hidden="true" href="#cb8-80" tabindex="-1"></a><span className="co"># Update the theme.</span></span>
<span id="cb8-81"><a aria-hidden="true" href="#cb8-81" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span> </span>
<span id="cb8-82"><a aria-hidden="true" href="#cb8-82" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb8-83"><a aria-hidden="true" href="#cb8-83" tabindex="-1"></a>    <span className="co"># background</span></span>
<span id="cb8-84"><a aria-hidden="true" href="#cb8-84" tabindex="-1"></a>    <span className="at">panel.grid =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-85"><a aria-hidden="true" href="#cb8-85" tabindex="-1"></a>    <span className="at">panel.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb8-86"><a aria-hidden="true" href="#cb8-86" tabindex="-1"></a>    <span className="at">plot.background =</span> <span className="fu">element_rect</span>(<span className="at">fill =</span> <span className="st">"black"</span>),</span>
<span id="cb8-87"><a aria-hidden="true" href="#cb8-87" tabindex="-1"></a>    </span>
<span id="cb8-88"><a aria-hidden="true" href="#cb8-88" tabindex="-1"></a>    <span className="co"># legend</span></span>
<span id="cb8-89"><a aria-hidden="true" href="#cb8-89" tabindex="-1"></a>    <span className="at">legend.background =</span> <span className="fu">element_blank</span>(), <span className="co"># remove legend background</span></span>
<span id="cb8-90"><a aria-hidden="true" href="#cb8-90" tabindex="-1"></a>    <span className="at">legend.margin =</span> <span className="fu">margin</span>(<span className="at">l =</span> <span className="dv">20</span>), <span className="co"># increase legend left margin</span></span>
<span id="cb8-91"><a aria-hidden="true" href="#cb8-91" tabindex="-1"></a>    </span>
<span id="cb8-92"><a aria-hidden="true" href="#cb8-92" tabindex="-1"></a>    <span className="co"># texts</span></span>
<span id="cb8-93"><a aria-hidden="true" href="#cb8-93" tabindex="-1"></a>    <span className="at">text =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"snow"</span>, <span className="at">size =</span> <span className="dv">14</span>),</span>
<span id="cb8-94"><a aria-hidden="true" href="#cb8-94" tabindex="-1"></a>    <span className="at">axis.text.y =</span> <span className="fu">element_blank</span>(),</span>
<span id="cb8-95"><a aria-hidden="true" href="#cb8-95" tabindex="-1"></a>    <span className="at">axis.text.x =</span> <span className="fu">element_text</span>(<span className="at">color =</span> <span className="st">"snow"</span>),</span>
<span id="cb8-96"><a aria-hidden="true" href="#cb8-96" tabindex="-1"></a>    <span className="at">axis.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>),</span>
<span id="cb8-97"><a aria-hidden="true" href="#cb8-97" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"bold"</span>, <span className="at">size =</span> <span className="dv">15</span>),</span>
<span id="cb8-98"><a aria-hidden="true" href="#cb8-98" tabindex="-1"></a>    <span className="at">plot.subtitle =</span> <span className="fu">element_text</span>(<span className="at">face =</span> <span className="st">"italic"</span>, <span className="at">size =</span> <span className="dv">12</span>),</span>
<span id="cb8-99"><a aria-hidden="true" href="#cb8-99" tabindex="-1"></a>    <span className="co"># increase margin around the plot</span></span>
<span id="cb8-100"><a aria-hidden="true" href="#cb8-100" tabindex="-1"></a>    <span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">20</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>)) </span>
<span id="cb8-101"><a aria-hidden="true" href="#cb8-101" tabindex="-1"></a>p5</span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p><span id="African_population_heatmap">In the visualization above, we used <code>colorRampPalette(..., bias = .4)</code> to cater the color scale to skewed data distribution. For <strong>highly skewed dataset</strong>, another powerful approach is to <strong>apply mathematical transformation in the color scale</strong>. Check this <strong><Link to="../ggplot2-heatmap-African-population">stunning African population heatmap with pseudo-logarithmic transform in color scale</Link></strong>, and you’ll be truly amazed how an appropriate color scale adjusted with a simple piece of code can make a tremendous difference.</span></p>
<p><Link to="../ggplot2-heatmap-African-population">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/map_African_population_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>In the above <a href="#mobility">social mobility visualization</a>, we have plotted out each individual observation (row), and each father-son pair is visualized as a single line. Often, it is desirable to plot not only individual observations, but also statistical summaries for a group, e.g., mean and variance. Check the following <Link to="../ggplot2-line-summary-statistics-milk"><strong>line plots with summary statistics</strong></Link>, and learn the <em>most efficient</em> way to combine individual data points and group summarizing statistics in a graph.</p>
<p><Link to="../ggplot2-line-summary-statistics-milk">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/milk_annotate_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/milk_annotate_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}