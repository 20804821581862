import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function NestJoinTwoDatasets(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Merge Two Datasets with Nest Join</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>A nest join leaves the left-side dataset A unchanged, and adds all columns of B as a single <em>list</em> column, where each element contains the rows of B that match the corresponding rows in A.</p>
<p>Consider the following example:</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a>A <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="dv">3</span>), <span className="at">y =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="st">"b"</span>, <span className="st">"c"</span>))</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>B <span className="ot">&lt;-</span> tibble<span className="sc">::</span><span className="fu">tibble</span>(<span className="at">x =</span> <span className="fu">c</span>(<span className="dv">2</span>, <span className="dv">3</span>, <span className="dv">3</span>), <span className="at">z =</span> <span className="fu">c</span>(<span className="st">"M"</span>, <span className="st">"P"</span>, <span className="st">"Q"</span>))</span></code></pre></div>
</div>
<div id="flex">
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>A</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>        x y    
<br/>    &lt;dbl&gt; &lt;chr&gt;
<br/>  1     1 a    
<br/>  2     2 b    
<br/>  3     3 c</code></pre>
</div>
</div>
</div>
<div>
<div className="cell">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>B</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 2
<br/>        x z    
<br/>    &lt;dbl&gt; &lt;chr&gt;
<br/>  1     2 M    
<br/>  2     3 P    
<br/>  3     3 Q</code></pre>
</div>
</div>
</div>
</div>
<p>In this example, dataset B is incorporated into dataset A as a single column “B”, and each element in column “B” is a list. The three rows in A respectively matches none, the 1st row, and the last two rows of dataset B. This is reflected in the tibble dimension [0 × 1], [1 × 1], and [2 × 1], respectively, in the column “B” in the output dataset.</p>
<div className="cell">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>A <span className="sc">%&gt;%</span> <span className="fu">nest_join</span>(B, <span className="at">by =</span> <span className="st">"x"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">  # A tibble: 3 × 3
<br/>        x y     B               
<br/>    &lt;dbl&gt; &lt;chr&gt; &lt;list&gt;          
<br/>  1     1 a     &lt;tibble [0 × 1]&gt;
<br/>  2     2 b     &lt;tibble [1 × 1]&gt;
<br/>  3     3 c     &lt;tibble [2 × 1]&gt;</code></pre>
</div>
</div>
</main>
</div>
</div>
)}