import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PivotLongerPart1(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Gather Columns into Longer and Narrower Dataset (1/4): <em>the Basics of</em> <code>pivot_longer()</code></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>pivot_longer()</code> (previously known as <Link to="https://tidyr.tidyverse.org/reference/gather.html"><code>gather()</code></Link>) is one of the most important functions in data cleanup. It converts a dataset into a <em>tidy</em> structure: each row is an observation, each column is a variable, and each cell contains a value entry. It is frequently needed to tidy wild-caught datasets which are often optimized for ease of data entry or visual comparison instead of ease of analysis.</p>
<p>Consider the following dataset <code>relig_income</code> that records the number of respondents of each income range in different religions.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>relig_income</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 18 × 11
<br/>   religion                `&lt;$10k` `$10-20k` `$20-30k` `$30-40k` `$40-50k` `$50-75k` `$75-100k` `$100-150k` `&gt;150k` `Don't know/refused`
<br/>   &lt;chr&gt;                     &lt;dbl&gt;     &lt;dbl&gt;     &lt;dbl&gt;     &lt;dbl&gt;     &lt;dbl&gt;     &lt;dbl&gt;      &lt;dbl&gt;       &lt;dbl&gt;   &lt;dbl&gt;                &lt;dbl&gt;
<br/> 1 Agnostic                     27        34        60        81        76       137        122         109      84                   96
<br/> 2 Atheist                      12        27        37        52        35        70         73          59      74                   76
<br/> 3 Buddhist                     27        21        30        34        33        58         62          39      53                   54
<br/> 4 Catholic                    418       617       732       670       638      1116        949         792     633                 1489
<br/> 5 Don’t know/refused           15        14        15        11        10        35         21          17      18                  116
<br/> 6 Evangelical Prot            575       869      1064       982       881      1486        949         723     414                 1529
<br/> 7 Hindu                         1         9         7         9        11        34         47          48      54                   37
<br/> 8 Historically Black Prot     228       244       236       238       197       223        131          81      78                  339
<br/> 9 Jehovah's Witness            20        27        24        24        21        30         15          11       6                   37
<br/>10 Jewish                       19        19        25        25        30        95         69          87     151                  162
<br/>11 Mainline Prot               289       495       619       655       651      1107        939         753     634                 1328
<br/>12 Mormon                       29        40        48        51        56       112         85          49      42                   69
<br/>13 Muslim                        6         7         9        10         9        23         16           8       6                   22
<br/>14 Orthodox                     13        17        23        32        32        47         38          42      46                   73
<br/>15 Other Christian               9         7        11        13        13        14         18          14      12                   18
<br/>16 Other Faiths                 20        33        40        46        49        63         46          40      41                   71
<br/>17 Other World Religions         5         2         3         4         2         7          3           4       4                    8
<br/>18 Unaffiliated                217       299       374       365       341       528        407         321     258                  597</code></pre>
</div>
</div>
<p>Instead of having the income ranges <code>&lt;$10k</code>, <code>$10-20k</code>, <code>$20-30k</code> … each being a separate column, we want in the tidied dataset a single column to record the income range, and another column to record the associated counts of respondents.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>relig_income <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>    <span className="at">cols =</span> <span className="sc">-</span>religion, </span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"income"</span>, </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"count"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 180 × 3
<br/>   religion income             count
<br/>   &lt;chr&gt;    &lt;chr&gt;              &lt;dbl&gt;
<br/> 1 Agnostic &lt;$10k                 27
<br/> 2 Agnostic $10-20k               34
<br/> 3 Agnostic $20-30k               60
<br/> 4 Agnostic $30-40k               81
<br/> 5 Agnostic $40-50k               76
<br/> 6 Agnostic $50-75k              137
<br/> 7 Agnostic $75-100k             122
<br/> 8 Agnostic $100-150k            109
<br/> 9 Agnostic &gt;150k                 84
<br/>10 Agnostic Don't know/refused    96
<br/># ℹ 170 more rows</code></pre>
</div>
</div>
<ul>
<li><p><code>data</code> is the first argument, taking the value of the dataset to be tidied up (e.g., <code>relig_income</code>). It can be conveniently passed into this function using the <Link to="/R/data-wrangling/dplyr/1-pipe-operator">pipe operator <code>%&gt;%</code></Link>.</p></li>
<li><p><code>cols</code> describes which columns need to be restructured. In this case, it’s every column except <code>religion</code>. Note that for the <code>cols</code> argument, column names are not quoted, a unique <Link to="/R/data-wrangling/dplyr/25-data-masking">data masking</Link> feature in <Link to="https://www.tidyverse.org/">tidyverse</Link> that allows one to easily select columns by calling column names directly.</p></li>
<li><p><code>names_to</code> gives the name of the new variable (e.g., <code>income</code>), whose cell values will come from names of columns specified by <code>cols</code>. That is, the names of the <code>cols</code>-specified columns will become values of the <code>income</code> variable of the returned dataset.</p></li>
<li><p><code>values_to</code> gives the name of the new variable (e.g., <code>count</code>), whose cell values will be created from the cells stored in columns specified by <code>cols</code>. That is, the cell values of the <code>cols</code>-selected columns will become values of the <code>count</code> variable of the returned dataset.</p></li>
</ul>
<p>Neither the <code>names_to</code> nor the <code>values_to</code> column exists in <code>relig_income</code>, so we provide them as strings surrounded by quotes.</p>
<p>In the output result, for each religion, the people count at each income range is displayed as a single row, and each column is a single variable.</p>
<p>Now you have acquainted yourself with the basic use of <code>pivot_longer()</code>. In the following three tutorials of <code>pivot_longer()</code>, you’ll get additional exercise over this important function, and learn more of its advanced features to efficiently pivot dataset with increasingly complex structure.</p>
</main>
</div>
</div>
)}