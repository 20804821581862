import React from 'react';
import StrReplace from '../RDataWrangling/stringr/contents/13-str-replace_output_react'
import StrDup from '../RDataWrangling/stringr/contents/3-str-dup_output_react'
import StrExtract from '../RDataWrangling/stringr/contents/12-str-extract_output_react'
import StrPad from '../RDataWrangling/stringr/contents/7-str-pad_output_react'
import StrLength from '../RDataWrangling/stringr/contents/1-str-length_output_react'
import StrCount from '../RDataWrangling/stringr/contents/2-str-count_output_react'
import StrSortStrOrder from '../RDataWrangling/stringr/contents/11-str-sort-str-order_output_react'
import StrFlatten from '../RDataWrangling/stringr/contents/15-str-flatten_output_react'
import StrWrap from '../RDataWrangling/stringr/contents/18-str-wrap_output_react'
import StrSub from '../RDataWrangling/stringr/contents/4-str-sub_output_react'
import StrTo from '../RDataWrangling/stringr/contents/10-str-to_output_react'
import Introduction from '../RDataWrangling/stringr/contents/0-introduction_output_react'
import StrTrunc from '../RDataWrangling/stringr/contents/8-str-trunc_output_react'
import StrSplit from '../RDataWrangling/stringr/contents/16-str-split_output_react'
import StrSubset from '../RDataWrangling/stringr/contents/6-str-subset_output_react'
import StrRemove from '../RDataWrangling/stringr/contents/14-str-remove_output_react'
import StrDetect from '../RDataWrangling/stringr/contents/5-str-detect_output_react'
import StrC from '../RDataWrangling/stringr/contents/17-str-c_output_react'
import StrTrim from '../RDataWrangling/stringr/contents/9-str-trim_output_react'
import StrMatch from '../RDataWrangling/stringr/contents/19-str-match_output_react'

const RstringrData=[
{'component': <Introduction />, 'path': '0-introduction', 'title': 'Introduction'},
{'component': <StrLength />, 'path': '1-str-length', 'title': '1. String length'},
{'component': <StrCount />, 'path': '2-str-count', 'title': '2. Count patterns'},
{'component': <StrDup />, 'path': '3-str-dup', 'title': '3. Duplicate a string'},
{'component': <StrSub />, 'path': '4-str-sub', 'title': '4. Select characters by positions'},
{'component': <StrDetect />, 'path': '5-str-detect', 'title': '5. Test presence of a pattern'},
{'component': <StrSubset />, 'path': '6-str-subset', 'title': '6. Select strings containing a pattern'},
{'component': <StrPad />, 'path': '7-str-pad', 'title': '7. Pad a string with white spaces'},
{'component': <StrTrunc />, 'path': '8-str-trunc', 'title': '8. Truncate a string'},
{'component': <StrTrim />, 'path': '9-str-trim', 'title': '9. Remove white spaces'},
{'component': <StrTo />, 'path': '10-str-to', 'title': '10. Convert to upper and lower cases'},
{'component': <StrSortStrOrder />, 'path': '11-str-sort-str-order', 'title': '11. Sort a string'},
{'component': <StrExtract />, 'path': '12-str-extract', 'title': '12. Extract pattern-matched strings'},
{'component': <StrReplace />, 'path': '13-str-replace', 'title': '13. Replace pattern-matched strings '},
{'component': <StrRemove />, 'path': '14-str-remove', 'title': '14. Remove pattern-matched strings'},
{'component': <StrFlatten />, 'path': '15-str-flatten', 'title': '15. Combine strings'},
{'component': <StrSplit />, 'path': '16-str-split', 'title': '16. Split strings'},
{'component': <StrC />, 'path': '17-str-c', 'title': '17. Combine character vectors'},
{'component': <StrWrap />, 'path': '18-str-wrap', 'title': '18. Wrap long texts'},
{'component': <StrMatch />, 'path': '19-str-match', 'title': '19 Extract capture groups'},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},
{'component': '', 'title': ''},





]

export default RstringrData