import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function PythonOopclassesandobjects(){
useCustomEffect()
return ( <div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Object-Oriented-Programming">Object Oriented Programming<a class="anchor-link" href="#Object-Oriented-Programming">¶</a></h3><p>Object-oriented programming (OOP) is a programming paradigm that revolves around the concept of "objects". Python, C++, Java and many other programming languages are all object-oriented programming languages.</p>
<p>Firstly, let's delve into the key components of OOP.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Class">Class<a class="anchor-link" href="#Class">¶</a></h4><p>A class is a blueprint that defines attributes (data) and methods (functions) common to all objects of that class.</p>
<p>For example, if you want to make a toy car, you'd start with a blueprint that tells you what the car should look like and what it should be able to do. In programming, a class is like that blueprint. It defines the properties (characteristics) and behaviors (actions) that objects made from that class will have.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Create-a-Class">Create a Class<a class="anchor-link" href="#Create-a-Class">¶</a></h5><p>To define a class, use the <code>class</code> keyword followed by the class name. The class name should start with a capital letter.</p>
<p><strong>Example</strong></p>
<p>Create a class named <code>Person</code>, with a property named <code>classification</code>:</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [7]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Define a class</span></span>

<span><span class="k">class</span> <span class="nc">Person</span><span class="p">:</span></span>

<span>    <span class="c1"># Define a class property named "classification"</span></span>

<span>    <span class="n">classification</span> <span class="o">=</span> <span class="s1">'mammals'</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>In this example, A class named <code>Person</code> is created. Inside the class, a <strong>class attribute<sup><a href="#annot1">1</a></sup></strong> named <code>classification</code> is defined and initialized with the value <code>mammals</code>. <strong>class attributes<sup><a href="#annot1">1</a></sup></strong> are attributes shared by all instances of the class.</p>
<p>Now we can use the <code>Person</code> class to initialize objects.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="Object">Object<a class="anchor-link" href="#Object">¶</a></h4><p>An objet is an instance or example created from a blueprint (class) with its own specifications.</p>
<p>For example, you use a blueprint (class) for car manufacturing to create a toy car (object). The finished toy car that you can hold in your hand is like an object. So, while the class describes what the car should be like, the object is the actual car you can play with.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Create-an-Object">Create an Object<a class="anchor-link" href="#Create-an-Object">¶</a></h5><p><strong>Example</strong></p>
<p>Create an object "p1" from the <code>Person</code> class. The "p1" object automatically inherits the class attribute <code>classification</code>.</p>
<p>This step is called instantiation.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [6]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create an instance of the Person class </span></span>

<span><span class="c1"># and assign it to the variable p1</span></span>

<span><span class="n">p1</span> <span class="o">=</span> <span class="n">Person</span><span class="p">()</span></span>

<span><span class="nb">print</span> <span class="p">(</span><span class="n">p1</span><span class="o">.</span><span class="n">classification</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>mammals
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>The object <code>p1</code> is an instance of the <code>Person</code> class. Consequently, <code>p1</code> possesses the class attributes defined within the <code>Person</code> class, such as the <code>classification</code> of being mammals.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="The-__init__()-Function">The <code>__init__()</code> Function<a class="anchor-link" href="#The-__init__()-Function">¶</a></h4><p>The examples above are the most basic representations of classes and objects, not typically practical for real-world applications.</p>
<p>For instance, in addition to the common classification of mammals inherited from the class <code>Person</code>, it would be advantageous for the <code>p1</code> object to possess distinctive attributes such as hobbies and age. To achieve that, the built-in <code>__init__()</code> function is used to initialize newly created instances with specific initial state and attributes.</p>
<p>The <code>__init__()</code> function is a constructor method, that is automatically called when a new instance of a class is created. Its primary purpose is to initialize attributes of the newly created object.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h5 id="Create-a-Class-with-__init__()-Function">Create a Class with <code>__init__()</code> Function<a class="anchor-link" href="#Create-a-Class-with-__init__()-Function">¶</a></h5><p>Add <code>__init__()</code> function to the Person class.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [12]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="k">class</span> <span class="nc">Person</span><span class="p">:</span></span>

<span>    <span class="n">classification</span> <span class="o">=</span> <span class="s1">'mammals'</span></span>

<span>    <span class="k">def</span> <span class="fm">__init__</span><span class="p">(</span><span class="bp">self</span><span class="p">,</span> <span class="n">name</span><span class="p">,</span> <span class="n">age</span><span class="p">,</span> <span class="n">hobby</span><span class="p">):</span></span>

<span>        <span class="bp">self</span><span class="o">.</span><span class="n">name</span> <span class="o">=</span> <span class="n">name</span></span>

<span>        <span class="bp">self</span><span class="o">.</span><span class="n">age</span> <span class="o">=</span> <span class="n">age</span></span>

<span>        <span class="bp">self</span><span class="o">.</span><span class="n">hobby</span> <span class="o">=</span> <span class="n">hobby</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Explanation</strong></p>
<ul>
<li><code>classification = 'mammals'</code>: <code>classification</code> is a class attribute that can be shared among all instances of the <code>Person</code> class. </li>
<li><code>def __init__()(self, name, age, hobby)</code>: This line defines the <code>__init__()</code> function. <ul>
<li><code>self</code>: The <code>self</code> parameter refers to instances or objects of the class. It's like a placeholer for new objects.</li>
<li>The parameters <code>name</code>, <code>age</code>, and <code>hobby</code> are function parameters used to receive data needed for creating new instances.</li>
</ul>
</li>
<li><code>self.name = name</code>: this assigns the argument <code>name</code> (the second name of the code) to the <code>name</code> (the first name of the code) attribute of objects.</li>
<li><code>self.age = age</code>: this assigns the argument <code>age</code> (the second age of the code) to the <code>age</code> (the first age of the code)  attribute of objects.</li>
<li><code>self.hobby = hobby</code>: this assigns the argument <code>hobby</code> (the second hobby of the code) to the <code>hobby</code> (the first hobby of the code) attribute of objects.</li>
</ul>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Create instances from the Person class</strong></p>
<p>Create two instances, <code>p1</code> and <code>p2</code>.</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [23]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create an instance "p1" of the Person class</span></span>

<span><span class="c1"># Pass values to name, age and hobby parameters</span></span>

<span><span class="n">p1</span> <span class="o">=</span> <span class="n">Person</span><span class="p">(</span><span class="n">name</span><span class="o">=</span><span class="s2">"Alice"</span><span class="p">,</span> <span class="n">age</span><span class="o">=</span><span class="mi">24</span><span class="p">,</span> <span class="n">hobby</span><span class="o">=</span><span class="s1">'hiking'</span><span class="p">)</span></span>


<br /><span><span class="nb">print</span><span class="p">(</span><span class="n">p1</span><span class="o">.</span><span class="n">name</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">p1</span><span class="o">.</span><span class="n">age</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">p1</span><span class="o">.</span><span class="n">hobby</span><span class="p">)</span></span>


<br /><span><span class="nb">print</span><span class="p">(</span><span class="n">p1</span><span class="o">.</span><span class="n">classification</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Alice
<br />24
<br />hiking
<br />mammals
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>When creating <code>p1</code>, the values for <code>name</code>, <code>age</code>, and <code>hobby</code> are provided as <Link to="../function-definition-and-parameters">keyword arguments</Link> to the <code>Person</code> class constructor. These arguments are then assigned to the corresponding attributes of the <code>p1</code> object using the lines within the class definition: <code>self.name = name</code>, <code>self.age = age</code>, <code>self.hobby = hobby</code>. Therefore, the <code>p1</code> object obtains attributes: name of "Alice", age of "24", hobby of "hiking".</p>
</div>
</div>
</div>
</div><div class="jp-Cell jp-CodeCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea">
<div class="jp-InputPrompt jp-InputArea-prompt">In [21]:</div>
<div class="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div class="CodeMirror cm-s-jupyter">
<div class="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span class="c1"># Create another instance "p2" of the Person class</span></span>

<span><span class="c1"># Pass different values to name, age and hobby parameters</span></span>

<span><span class="n">p2</span> <span class="o">=</span> <span class="n">Person</span><span class="p">(</span><span class="s2">"Jason"</span><span class="p">,</span> <span class="mi">28</span><span class="p">,</span> <span class="s1">'soccer'</span><span class="p">)</span></span>


<br /><span><span class="nb">print</span><span class="p">(</span><span class="n">p2</span><span class="o">.</span><span class="n">name</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">p2</span><span class="o">.</span><span class="n">age</span><span class="p">)</span></span>

<span><span class="nb">print</span><span class="p">(</span><span class="n">p2</span><span class="o">.</span><span class="n">hobby</span><span class="p">)</span></span>


<br /><span><span class="nb">print</span><span class="p">(</span><span class="n">p2</span><span class="o">.</span><span class="n">classification</span><span class="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div class="jp-Cell-outputWrapper">
<div class="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div class="jp-OutputArea jp-Cell-outputArea">
<div class="jp-OutputArea-child">
<div class="jp-OutputPrompt jp-OutputArea-prompt"></div>
<div class="jp-RenderedText jp-OutputArea-output" data-mime-type="text/plain">
<pre className='demo-highlight python'><code className='sourceCode'>Jason
<br />28
<br />soccer
<br />mammals
</code></pre>
</div>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Similarly, <code>p2</code> obtains its own attributes during instantiation: name of "Jason", age of "28", hobby of "soccer".</p>
<p>Both <code>p1</code> and <code>p2</code> share the same <code>classification</code> of mammals, since the <code>classification</code> attribute is a <strong>class attribute<sup><a href="#annot1">1</a></sup></strong> common to all new instances. Attributes defined in the <code>__init__()</code> function are <strong>instance attributes<sup><a href="#annot2">2</a></sup></strong> which can vary for different instances.</p>
</div>
</div>
</div>
</div>
<div class="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div class="jp-Cell-inputWrapper">
<div class="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div class="jp-InputArea jp-Cell-inputArea"><div class="jp-InputPrompt jp-InputArea-prompt">
</div><div class="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p><strong>Annotation</strong></p>
<p class="annotation" id="annot1"><strong>1. Class attributes</strong> are defined at the class level (outside of any method, typically near the top of the class definition).</p><p class="annotation" id="annot2"><strong>2. Instance attributes</strong> are defined in the <code>__init__()</code> method or other methods.</p>
</div>
</div>
</div>
</div>
</div>
)}