import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function ReplaceNaWithSpecifiedValues(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Replace Missing Value <code>NA</code> with Specified Values</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><code>replace_na()</code> replaces <code>NA</code> in a vector or columns of a data frame with specified values.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>df <span className="ot">&lt;-</span> <span className="fu">tibble</span>(<span className="at">A =</span> <span className="fu">c</span>(<span className="dv">1</span>, <span className="dv">2</span>, <span className="cn">NA</span>), <span className="at">B =</span> <span className="fu">c</span>(<span className="st">"a"</span>, <span className="cn">NA</span>, <span className="st">"b"</span>), <span className="at">C =</span> <span className="fu">c</span>(<span className="cn">NA</span>, <span className="cn">NA</span>, <span className="dv">10</span>))</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a>df</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>      A B         C
<br/>  &lt;dbl&gt; &lt;chr&gt; &lt;dbl&gt;
<br/>1     1 a        NA
<br/>2     2 &lt;NA&gt;     NA
<br/>3    NA b        10</code></pre>
</div>
</div>
<p>Replace <code>NA</code> with zero.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>df<span className="sc">$</span>A <span className="sc">%&gt;%</span> <span className="fu">replace_na</span>(<span className="at">replace =</span> <span className="dv">0</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] 1 2 0</code></pre>
</div>
</div>
<p>Replace <code>NA</code> with “unknown”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>df<span className="sc">$</span>B <span className="sc">%&gt;%</span> <span className="fu">replace_na</span>(<span className="at">replace =</span> <span className="st">"unknown"</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">[1] "a"       "unknown" "b"      </code></pre>
</div>
</div>
<p>Use <code>mutate()</code> to replace <code>NA</code> in columns <code>A</code> and <code>B</code> of the dataset.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>df <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(<span className="at">A =</span> <span className="fu">replace_na</span>(A, <span className="dv">0</span>),</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>              <span className="at">B =</span> <span className="fu">replace_na</span>(B, <span className="st">"unknown"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>      A B           C
<br/>  &lt;dbl&gt; &lt;chr&gt;   &lt;dbl&gt;
<br/>1     1 a          NA
<br/>2     2 unknown    NA
<br/>3     0 b          10</code></pre>
</div>
</div>
<p>You can use selection helper <Link to="/R/data-wrangling/dplyr/14-repeated-operations-on-multiple-column"><code>across()</code></Link> to apply the replacement to a range of columns, e.g., replacing <code>NA</code> with <code>0</code> in all numeric columns, and with <code>"unknown"</code> in all character columns.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>df <span className="sc">%&gt;%</span> <span className="fu">mutate</span>(</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">across</span>(is.numeric, <span className="sc">~</span><span className="fu">replace_na</span>(.x, <span className="dv">0</span>)),</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>  <span className="fu">across</span>(is.character, <span className="sc">~</span><span className="fu">replace_na</span>(.x, <span className="st">"unknown"</span>)))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>      A B           C
<br/>  &lt;dbl&gt; &lt;chr&gt;   &lt;dbl&gt;
<br/>1     1 a           0
<br/>2     2 unknown     0
<br/>3     0 b          10</code></pre>
</div>
</div>
<p>The dataset can be also a direct input to <code>replace_na()</code> as the first argument. In this case, the <code>replace</code> argument takes a <em>list</em> of values, with one value for each column that has <code>NA</code> values to be replaced.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="co"># Replace `NA` in column `A` with 0, and replace `NA` in column `B` with "unknown".</span></span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a>df <span className="sc">%&gt;%</span> <span className="fu">replace_na</span>(<span className="at">replace =</span> <span className="fu">list</span>(<span className="at">A =</span> <span className="dv">0</span>, <span className="at">B =</span> <span className="st">"unknown"</span>))</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 3 × 3
<br/>      A B           C
<br/>  &lt;dbl&gt; &lt;chr&gt;   &lt;dbl&gt;
<br/>1     1 a          NA
<br/>2     2 unknown    NA
<br/>3     0 b          10</code></pre>
</div>
</div>
</main>
</div>
</div>
)}