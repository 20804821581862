import React from 'react';


import Ggplot2HeatmapVaccineForPolio from '../RgalleryPages/contents/ggplot2-heatmap-vaccine-for-polio_output_react'
import Ggplot2ArrowsFacetWomenParliament from '../RgalleryPages/contents/ggplot2-arrows-facet-women-parliament_output_react'
import Ggplot2MapAirline from '../RgalleryPages/contents/ggplot2-map-airline_output_react'
import Ggplot2ArtsyRArtworks from '../RgalleryPages/contents/ggplot2-aRtsy-R-artworks_output_react'
import Ggplot2BarsReorderedCars from '../RgalleryPages/contents/ggplot2-bars-reordered-cars_output_react'
import Ggplot22dHistogramStormActivitiesUs from '../RgalleryPages/contents/ggplot2-2D-histogram-storm-activities-US_output_react'
import Ggplot2BarEmojiHappyMoney from '../RgalleryPages/contents/ggplot2-bar-Emoji-happy-money_output_react'
import Ggplot2WafflePlotWealthDistribution from '../RgalleryPages/contents/ggplot2-waffle-plot-wealth-distribution_output_react'
import Ggplot2LinePlotGapminder from '../RgalleryPages/contents/ggplot2-line-plot-gapminder_output_react'
import Ggplot2DonutGdp from '../RgalleryPages/contents/ggplot2-donut-GDP_output_react'
import Ggplot2LollipopDonutHorsepower from '../RgalleryPages/contents/ggplot2-lollipop-donut-horsepower_output_react'
import Ggplot2ScatterplotUrbanization from '../RgalleryPages/contents/ggplot2-scatterplot-urbanization_output_react'
import Ggplot2HeatmapVolcanoTopography from '../RgalleryPages/contents/ggplot2-heatmap-volcano-topography_output_react'
import Ggplot2MapFijiEarthquakes from '../RgalleryPages/contents/ggplot2-map-fiji-earthquakes_output_react'
import Ggplot2GeofacetUsStates from '../RgalleryPages/contents/ggplot2-geofacet-US-states_output_react'
import Ggplot2BarMortality from '../RgalleryPages/contents/ggplot2-bar-mortality_output_react'
import Ggplot2ScatterplotTechniques from '../RgalleryPages/contents/ggplot2-scatterplot-techniques_output_react'
import Ggplot2LinePlotSocialMobility from '../RgalleryPages/contents/ggplot2-line-plot-social-mobility_output_react'
import Ggplot2LineSummaryStatisticsMilk from '../RgalleryPages/contents/ggplot2-line-summary-statistics-milk_output_react'
import Ggplot2BarPolarCoordHorsepower from '../RgalleryPages/contents/ggplot2-bar-polar-coord-horsepower_output_react'
import Ggplot2BarCo2AirTravel from '../RgalleryPages/contents/ggplot2-bar-CO2-air-travel_output_react'
import Ggplot22dHistogramHappy from '../RgalleryPages/contents/ggplot2-2D-histogram-happy_output_react'
import Ggplot2PopulationPyramid from '../RgalleryPages/contents/ggplot2-population-pyramid_output_react'
import Ggplot2DrawWithMathFunctions from '../RgalleryPages/contents/ggplot2-draw-with-math-functions_output_react'
import Ggplot2RadarWine from '../RgalleryPages/contents/ggplot2-radar-wine_output_react'
import Ggplot2BarsMidwestCounty from '../RgalleryPages/contents/ggplot2-bars-midwest-county_output_react'
import Ggplot2LinePlotCigarettes from '../RgalleryPages/contents/ggplot2-line-plot-cigarettes_output_react'
import Ggplot2DumbellPlotVotesTrumpClinton from '../RgalleryPages/contents/ggplot2-dumbell-plot-votes-Trump-Clinton_output_react'
import Ggplot2LinePlotLifeExpectancy from '../RgalleryPages/contents/ggplot2-line-plot-life-expectancy_output_react'
import Ggplot2HeatmapSunspotActivities from '../RgalleryPages/contents/ggplot2-heatmap-sunspot-activities_output_react'
import Ggplot2ScatterplotDiamonds from '../RgalleryPages/contents/ggplot2-scatterplot-diamonds_output_react'
import Ggplot2HeatmapAfricanPopulation from '../RgalleryPages/contents/ggplot2-heatmap-African-population_output_react'
import Ggplot2WorldMapCitiesPopulations from '../RgalleryPages/contents/ggplot2-world-map-cities-populations_output_react'
import Ggplot2SegmentUsPresidentTerms from '../RgalleryPages/contents/ggplot2-segment-US-president-terms_output_react'
import Ggplot2StreamBlockbuster from '../RgalleryPages/contents/ggplot2-stream-blockbuster_output_react'
import Ggplot2AreaPlotMigration from '../RgalleryPages/contents/ggplot2-area-plot-migration_output_react'
import Ggplot2DonutPlotAdmission from '../RgalleryPages/contents/ggplot2-donut-plot-admission_output_react'
import Ggplot2DensityPlotGgridgeSunspot from '../RgalleryPages/contents/ggplot2-density-plot-ggridge-sunspot_output_react'
import Ggplot2MapAirlineAnimation from '../RgalleryPages/contents/ggplot2-map-airline-animation_output_react'
import Ggplot2HeatmapVaccineForDiseases from '../RgalleryPages/contents/ggplot2-heatmap-vaccine-for-8-diseases_output_react'
import Ggplot2BarSleep from '../RgalleryPages/contents/ggplot2-bar-sleep_output_react'
import Ggplot2PenguinScatterplotEllipseGgextra from '../RgalleryPages/contents/ggplot2-penguin-scatterplot-ellipse-ggExtra_output_react'
import Ggplot2WafflePlotChernoffFacesWealth from '../RgalleryPages/contents/ggplot2-Waffle-plot-Chernoff-faces-wealth_output_react'
import Ggplot2MosaicHealthWealth from '../RgalleryPages/contents/ggplot2-mosaic-health-wealth_output_react'
import Ggplot2PopulationPyramidAnimation from '../RgalleryPages/contents/ggplot2-population-pyramid-animation_output_react'
import Ggplot2LollipopPolarCoordHorsepower from '../RgalleryPages/contents/ggplot2-bar-polar-coord-horsepower_output_react'


import imgGgplot22dHistogramStormActivitiesUs from '../RgalleryPages/graphics/heatmap_storms_completed.png'
import imgGgplot2BarMortality from '../RgalleryPages/graphics/stacked_bar_mortality_theme_completed.png'
import imgGgplot2HeatmapVaccineForPolio from '../RgalleryPages/graphics/heatmap_vaccine_polio_completed.png'
import imgGgplot2MapAirline from '../RgalleryPages/graphics/map_airlines_completed.png'
import imgGgplot2BarsReorderedCars from '../RgalleryPages/graphics/bars_reordered_normalized_completed.png'
import imgGgplot2BarEmojiHappyMoney from '../RgalleryPages/graphics/Emoji_happiness_wealth_annotation_completed.png'
import imgGgplot2WafflePlotWealthDistribution from '../RgalleryPages/graphics/waffle_plot_wealth_completed.png'
import imgGgplot2DonutGdp from '../RgalleryPages/graphics/doughnut_plot_GDP_ggdraw_completed.png'
import imgGgplot2LollipopDonutHorsepower from '../RgalleryPages/graphics/horsepower_lollipop_theme_completed.png'
import imgGgplot2HeatmapVolcanoTopography from '../RgalleryPages/graphics/heatmap-volcanocompleted.png'
import imgGgplot2MapFijiEarthquakes from '../RgalleryPages/graphics/map_fiji_earthquakes_theme_completed.png'
import imgGgplot2GeofacetUsStates from '../RgalleryPages/graphics/geofacet_election_completed.png'
import imgGgplot2ScatterplotTechniques from '../RgalleryPages/graphics/penguins_theme_completed.png'
import imgGgplot2LinePlotSocialMobility from '../RgalleryPages/graphics/lines_social_mobility_theme_completed.png'
import imgGgplot2LineSummaryStatisticsMilk from '../RgalleryPages/graphics/milk_annotate_completed.png'
import imgGgplot2BarPolarCoordHorsepower from '../RgalleryPages/graphics/horsepower_theme_completed.png'
import imgGgplot2BarCo2AirTravel from '../RgalleryPages/graphics/bar_air_travel_completed.png'
import imgGgplot22dHistogramHappy from '../RgalleryPages/graphics/happy_2D_histogram_completed.png'
import imgGgplot2PopulationPyramid from '../RgalleryPages/graphics/population_pyramid_theme_completed.png'
import imgGgplot2DrawWithMathFunctions from '../RgalleryPages/graphics/artwork_overflow_completed.png'
import imgGgplot2BarsMidwestCounty from '../RgalleryPages/graphics/bars_counties_theme_completed.png'
import imgGgplot2LinePlotCigarettes from '../RgalleryPages/graphics/lineplot_smoking_theme_completed.png'
import imgGgplot2WorldMapCitiesPopulations from '../RgalleryPages/graphics/world_map_city_populations_completed.png'
import imgGgplot2LollipopPolarCoordHorsepower from '../RgalleryPages/graphics/horsepower_theme_completed.png'
import imgGgplot2SegmentUsPresidentTerms from '../RgalleryPages/graphics/president_terms_google_fonts_completed.png'
import imgGgplot2StreamBlockbuster from '../RgalleryPages/graphics/ggstream_blockbuster_theme_completed.png'
import imgGgplot2AreaPlotMigration from '../RgalleryPages/graphics/area_plot_migration_theme_completed.png'
import imgGgplot2DonutPlotAdmission from '../RgalleryPages/graphics/donut_plot_completed.png'
import imgGgplot2DensityPlotGgridgeSunspot from '../RgalleryPages/graphics/ggridge_sunspot_theme_completed.png'
import imgGgplot2HeatmapVaccineFor8Diseases from '../RgalleryPages/graphics/heatmap_vaccine_function_completed_all_8_diseases.png'
import imgGgplot2BarSleep from '../RgalleryPages/graphics/bars_reordered_sleeptime_completed.png'
import imgGgplot2WafflePlotChernoffFacesWealth from '../RgalleryPages/graphics/Emoji_waffle_happiness_completed.png'
import imgGgplot2PopulationPyramidAnimation from '../RgalleryPages/graphics/population_pyramid_animation_completed-1.gif'
import imgGgplot2HeatmapSunspotActivities from '../RgalleryPages/graphics/heatmap_sunspot_completed.png'
import imgGgplot2HeatmapAfricanPopulation from '../RgalleryPages/graphics/map_African_population_completed.png'
import imgGgplot2MosaicHealthWealth from '../RgalleryPages/graphics/mosaic_happy_theme_completed.png'; 
import imgGgplot2PenguinScatterplotEllipseGgextra from '../RgalleryPages/graphics/penguin_ggExtra_completed.png'; 
import imgGgplot2HeatmapVaccineForDiseases from '../RgalleryPages/graphics/heatmap_vaccine_function_completed_all_8_diseases.png'; 
import imgGgplot2MapAirlineAnimation from '../RgalleryPages/graphics/map_airlines_animation_gganimate_completed-1.gif'; 
import imgGgplot2ScatterplotDiamonds from '../RgalleryPages/graphics/diamonds_marginal_distribution_completed.png'; 
import imgGgplot2LinePlotLifeExpectancy from '../RgalleryPages/graphics/lines_longevity_theme_completed.png'; 
import imgGgplot2DumbellPlotVotesTrumpClinton from '../RgalleryPages/graphics/dumbbell_president_election_completed.png'; 
import imgGgplot2RadarWine from '../RgalleryPages/graphics/radar_plot_car_completed.png';
import imgGgplot2ScatterplotUrbanization from '../RgalleryPages/graphics/scatterplot_urbanization_ggrepel_completed.png'; 
import imgGgplot2LinePlotGapminder from '../RgalleryPages/graphics/lines_lifespan_theme_completed.png'; 
import imgGgplot2ArtsyRArtworks from '../RgalleryPages/graphics/aRtsy_completed.png';
import imgGgplot2ArrowsFacetWomenParliament from '../RgalleryPages/graphics/women_seats_parliament_completed.png'; 

import Ggplot2AreaEducation from '../RgalleryPages/contents/ggplot2-area-education_output_react'
import Ggplot2FishEncounters from '../RgalleryPages/contents/ggplot2-fish-encounters_output_react'
import Ggplot2MapMapproj from '../RgalleryPages/contents/ggplot2-map-mapproj_output_react'
import Ggplot2PhoneZoomIn from '../RgalleryPages/contents/ggplot2-phone-zoom-in_output_react'
import Ggplot2LineSlopeplot from '../RgalleryPages/contents/ggplot2-line-slopeplot_output_react'

import imgGgplot2AreaEducation from '../RgalleryPages/graphics/area_education_theme_completed.png'
import imgGgplot2FishEncounters from '../RgalleryPages/graphics/fish_encounter_theme_completed.png'
import imgGgplot2MapMapproj from '../RgalleryPages/graphics/mapproj_theme_completed.png'
import imgGgplot2PhoneZoomIn from '../RgalleryPages/graphics/telephone_theme_completed.png'
import imgGgplot2LineSlopeplot from '../RgalleryPages/graphics/slopePlot_completed.png'


import imgGgplot2HeatmapVaccineForPolioWebp from '../RgalleryPages/graphics/heatmap_vaccine_polio_completed.webp'
import imgGgplot2LineSlopeplotWebp from '../RgalleryPages/graphics/slopePlot_patchwork_completed.webp'
import imgGgplot2ArrowsFacetWomenParliamentWebp from '../RgalleryPages/graphics/women_seats_parliament_completed.webp'
import imgGgplot2MapAirlineWebp from '../RgalleryPages/graphics/map_airlines_completed.webp'
import imgGgplot2ArtsyRArtworksWebp from '../RgalleryPages/graphics/aRtsy_completed.webp'
import imgGgplot2BarsReorderedCarsWebp from '../RgalleryPages/graphics/bars_reordered_normalized_completed.webp'
import imgGgplot22dHistogramStormActivitiesUsWebp from '../RgalleryPages/graphics/heatmap_storms_completed.webp'
import imgGgplot2AreaEducationWebp from '../RgalleryPages/graphics/area_education_theme_completed.webp'
import imgGgplot2BarEmojiHappyMoneyWebp from '../RgalleryPages/graphics/Emoji_happiness_wealth_annotation_completed.webp'
import imgGgplot2WafflePlotWealthDistributionWebp from '../RgalleryPages/graphics/waffle_plot_wealth_completed.webp'
import imgGgplot2LinePlotGapminderWebp from '../RgalleryPages/graphics/lines_lifespan_theme_completed.webp'
import imgGgplot2DonutGdpWebp from '../RgalleryPages/graphics/doughnut_plot_GDP_ggdraw_completed.webp'
import imgGgplot2LollipopDonutHorsepowerWebp from '../RgalleryPages/graphics/horsepower_lollipop_theme_completed.webp'
import imgGgplot2ScatterplotUrbanizationWebp from '../RgalleryPages/graphics/scatterplot_urbanization_ggrepel_completed.webp'
import imgGgplot2PhoneZoomInWebp from '../RgalleryPages/graphics/telephone_theme_completed.webp'
import imgGgplot2HeatmapVolcanoTopographyWebp from '../RgalleryPages/graphics/heatmap-volcanocompleted.webp'
import imgGgplot2MapFijiEarthquakesWebp from '../RgalleryPages/graphics/map_fiji_earthquakes_theme_completed.webp'
import imgGgplot2GeofacetUsStatesWebp from '../RgalleryPages/graphics/geofacet_election_completed.webp'
import imgGgplot2BarMortalityWebp from '../RgalleryPages/graphics/stacked_bar_mortality_theme_completed.webp'
import imgGgplot2ScatterplotTechniquesWebp from '../RgalleryPages/graphics/penguins_theme_completed.webp'
import imgGgplot2LinePlotSocialMobilityWebp from '../RgalleryPages/graphics/lines_social_mobility_theme_completed.webp'
import imgGgplot2LineSummaryStatisticsMilkWebp from '../RgalleryPages/graphics/milk_annotate_completed.webp'
import imgGgplot2BarPolarCoordHorsepowerWebp from '../RgalleryPages/graphics/horsepower_theme_completed.webp'
import imgGgplot2BarCo2AirTravelWebp from '../RgalleryPages/graphics/bar_air_travel_completed.webp'
import imgGgplot22dHistogramHappyWebp from '../RgalleryPages/graphics/happy_2D_histogram_completed.webp'
import imgGgplot2PopulationPyramidWebp from '../RgalleryPages/graphics/population_pyramid_theme_completed.webp'
import imgGgplot2DrawWithMathFunctionsWebp from '../RgalleryPages/graphics/artwork_overflow_completed.webp'
import imgGgplot2MapMapprojWebp from '../RgalleryPages/graphics/mapproj_theme_completed.webp'
import imgGgplot2RadarWineWebp from '../RgalleryPages/graphics/radar_plot_car_completed.webp'
import imgGgplot2BarsMidwestCountyWebp from '../RgalleryPages/graphics/bars_counties_theme_completed.webp'
import imgGgplot2LinePlotCigarettesWebp from '../RgalleryPages/graphics/lineplot_smoking_theme_completed.webp'
import imgGgplot2DumbellPlotVotesTrumpClintonWebp from '../RgalleryPages/graphics/dumbbell_president_election_completed.webp'
import imgGgplot2LinePlotLifeExpectancyWebp from '../RgalleryPages/graphics/lines_longevity_theme_completed.webp'
import imgGgplot2HeatmapSunspotActivitiesWebp from '../RgalleryPages/graphics/heatmap_sunspot_completed.webp'
import imgGgplot2ScatterplotDiamondsWebp from '../RgalleryPages/graphics/diamonds_marginal_distribution_completed.webp'
import imgGgplot2HeatmapAfricanPopulationWebp from '../RgalleryPages/graphics/map_African_population_completed.webp'
import imgGgplot2WorldMapCitiesPopulationsWebp from '../RgalleryPages/graphics/world_map_city_populations_completed.webp'
import imgGgplot2SegmentUsPresidentTermsWebp from '../RgalleryPages/graphics/president_terms_google_fonts_completed.webp'
import imgGgplot2FishEncountersWebp from '../RgalleryPages/graphics/fish_encounter_theme_completed.webp'
import imgGgplot2StreamBlockbusterWebp from '../RgalleryPages/graphics/ggstream_blockbuster_theme_completed.webp'
import imgGgplot2AreaPlotMigrationWebp from '../RgalleryPages/graphics/area_plot_migration_theme_completed.webp'
import imgGgplot2DonutPlotAdmissionWebp from '../RgalleryPages/graphics/donut_plot_completed.webp'
import imgGgplot2DensityPlotGgridgeSunspotWebp from '../RgalleryPages/graphics/ggridge_sunspot_theme_completed.webp'
import imgGgplot2MapAirlineAnimationWebp from '../RgalleryPages/graphics/map_airlines_animation_gganimate_completed-1.webp'
import imgGgplot2HeatmapVaccineForDiseasesWebp from '../RgalleryPages/graphics/heatmap_vaccine_function_completed_all_8_diseases.webp'
import imgGgplot2BarSleepWebp from '../RgalleryPages/graphics/bars_reordered_sleeptime_completed.webp'
import imgGgplot2PenguinScatterplotEllipseGgextraWebp from '../RgalleryPages/graphics/penguin_ggExtra_completed.webp'
import imgGgplot2WafflePlotChernoffFacesWealthWebp from '../RgalleryPages/graphics/Emoji_waffle_happiness_completed.webp'
import imgGgplot2MosaicHealthWealthWebp from '../RgalleryPages/graphics/mosaic_happy_theme_completed.webp'
import imgGgplot2PopulationPyramidAnimationWebp from '../RgalleryPages/graphics/population_pyramid_animation_completed-1.webp'

const RGalleryData = [ 
    {'component': <Ggplot2PenguinScatterplotEllipseGgextra />, 'path': 'ggplot2-penguin-scatterplot-ellipse-ggExtra', 'title': 'ggplot2 penguin scatterplot ellipse ggExtra', cover: imgGgplot2PenguinScatterplotEllipseGgextra, 'cover_webp': imgGgplot2PenguinScatterplotEllipseGgextraWebp},
    {'component': <Ggplot2HeatmapVaccineForDiseases />, 'path': 'ggplot2-heatmap-vaccine-for-8-diseases', 'title': 'ggplot2 heatmap vaccine for 8 diseases', 'cover': imgGgplot2HeatmapVaccineForDiseases, 'cover_webp': imgGgplot2HeatmapVaccineForDiseasesWebp},
    {'component': <Ggplot2MapAirlineAnimation />, 'path': 'ggplot2-map-airline-animation', 'title': 'ggplot2 map airline animation', 'cover': imgGgplot2MapAirlineAnimation, 'cover_webp': imgGgplot2MapAirlineAnimationWebp},
    {'component': <Ggplot2ScatterplotDiamonds />, 'path': 'ggplot2-scatterplot-diamonds', 'title': 'ggplot2 scatterplot diamonds', 'cover': imgGgplot2ScatterplotDiamonds, 'cover_webp': imgGgplot2ScatterplotDiamondsWebp},
    {'component': <Ggplot2LinePlotLifeExpectancy />, 'path': 'ggplot2-line-plot-life-expectancy', 'title': 'ggplot2 line plot life expectancy', cover: imgGgplot2LinePlotLifeExpectancy, 'cover_webp': imgGgplot2LinePlotLifeExpectancyWebp},
    {'component': <Ggplot2DumbellPlotVotesTrumpClinton />, 'path': 'ggplot2-dumbell-plot-votes-Trump-Clinton', 'title': 'ggplot2 dumbell plot votes Trump Clinton', cover:imgGgplot2DumbellPlotVotesTrumpClinton, 'cover_webp': imgGgplot2DumbellPlotVotesTrumpClintonWebp},
    {'component': <Ggplot2RadarWine />, 'path': 'ggplot2-radar-wine', 'title': 'ggplot2 radar wine', cover: imgGgplot2RadarWine, 'cover_webp': imgGgplot2RadarWineWebp},
    {'component': <Ggplot2MosaicHealthWealth />, 'path': 'ggplot2-mosaic-health-wealth', 'title': 'ggplot2 mosaic health wealth', cover: imgGgplot2MosaicHealthWealth, 'cover_webp': imgGgplot2MosaicHealthWealthWebp},
    {'component': <Ggplot2ScatterplotUrbanization />, 'path': 'ggplot2-scatterplot-urbanization', 'title': 'ggplot2 scatterplot urbanization', cover: imgGgplot2ScatterplotUrbanization, 'cover_webp': imgGgplot2ScatterplotUrbanizationWebp},
    {'component': <Ggplot2LinePlotGapminder />, 'path': 'ggplot2-line-plot-gapminder', 'title': 'ggplot2 line plot gapminder', cover: imgGgplot2LinePlotGapminder, 'cover_webp': imgGgplot2LinePlotGapminderWebp},
    {'component': <Ggplot2ArrowsFacetWomenParliament />, 'path': 'ggplot2-arrows-facet-women-parliament', 'title': 'ggplot2 arrows facet women parliament', cover:imgGgplot2ArrowsFacetWomenParliament, 'cover_webp': imgGgplot2ArrowsFacetWomenParliamentWebp},
    {'component': <Ggplot2HeatmapSunspotActivities />, 'path': 'ggplot2-heatmap-sunspot-activities', 'title': 'ggplot2 heatmapt sunspot', cover:imgGgplot2HeatmapSunspotActivities, 'cover_webp': imgGgplot2HeatmapSunspotActivitiesWebp},
    {'component': <Ggplot2BarMortality />, 'path': 'ggpplot2-bar-mortality', 'title': 'ggpplot2 bar mortality', cover:imgGgplot2BarMortality, 'cover_webp': imgGgplot2BarMortalityWebp},
    {'component': <Ggplot2HeatmapAfricanPopulation />, 'path': 'ggplot2-heatmap-African-population', 'title': 'ggplot2 heatmap African population', cover: imgGgplot2HeatmapAfricanPopulation, 'cover_webp': imgGgplot2HeatmapAfricanPopulationWebp},
    {'component': <Ggplot2HeatmapVaccineForPolio />, 'path': 'ggplot2-heatmap-vaccine-for-polio', 'title': 'ggplot2 heatmap vaccine for polio', cover: imgGgplot2HeatmapVaccineForPolio, 'cover_webp': imgGgplot2HeatmapVaccineForPolioWebp},
    {'component': <Ggplot2WafflePlotChernoffFacesWealth />, 'path': 'ggplot2-Waffle-plot-Chernoff-faces-wealth', 'title': 'ggplot2 Waffle plot Chernoff faces wealth', cover: imgGgplot2WafflePlotChernoffFacesWealth, 'cover_webp': imgGgplot2WafflePlotChernoffFacesWealthWebp},
    {'component': <Ggplot2BarsReorderedCars />, 'path': 'ggplot2-bars-reordered-cars', 'title': 'ggplot2 bars reordered cars', cover:imgGgplot2BarsReorderedCars, 'cover_webp': imgGgplot2BarsReorderedCarsWebp},
  {'component': <Ggplot22dHistogramStormActivitiesUs />, 'path': 'ggplot2-2D-histogram-storm-activities-US', 'title': 'ggplot2 2D histogram storm activities US', cover: imgGgplot22dHistogramStormActivitiesUs, 'cover_webp': imgGgplot22dHistogramStormActivitiesUsWebp},
    {'component': <Ggplot2BarEmojiHappyMoney />, 'path': 'ggplot2-bar-Emoji-happy-money', 'title': 'ggplot2 bar Emoji happy money', cover:imgGgplot2BarEmojiHappyMoney,  'cover_webp': imgGgplot2BarEmojiHappyMoneyWebp},
    {'component': <Ggplot2WafflePlotWealthDistribution />, 'path': 'ggplot2-waffle-plot-wealth-distribution', 'title': 'ggplot2 waffle plot wealth distribution', cover: imgGgplot2WafflePlotWealthDistribution, 'cover_webp': imgGgplot2WafflePlotWealthDistributionWebp},
    {'component': <Ggplot2DonutGdp />, 'path': 'ggplot2-donut-GDP', 'title': 'ggplot2 donut GDP', cover: imgGgplot2DonutGdp, 'cover_webp': imgGgplot2DonutGdpWebp},
    {'component': <Ggplot2HeatmapVolcanoTopography />, 'path': 'ggplot2-heatmap-volcano-topography', 'title': 'ggplot2 heatmap volcano topography', cover: imgGgplot2HeatmapVolcanoTopography, 'cover_webp': imgGgplot2HeatmapVolcanoTopographyWebp},
    {'component': <Ggplot2MapFijiEarthquakes />, 'path': 'ggplot2-map-fiji-earthquakes', 'title': 'ggplot2 map fiji earthquakes', cover: imgGgplot2MapFijiEarthquakes, 'cover_webp': imgGgplot2MapFijiEarthquakesWebp},
    {'component': <Ggplot2GeofacetUsStates />, 'path': 'ggplot2-geofacet-US-states', 'title': 'ggplot2 geofacet US states', cover: imgGgplot2GeofacetUsStates, 'cover_webp': imgGgplot2GeofacetUsStatesWebp},
    {'component': <Ggplot2ScatterplotTechniques />, 'path': 'ggplot2-scatterplot-techniques', 'title': 'ggplot2 scatterplot techniques', cover: imgGgplot2ScatterplotTechniques, 'cover_webp': imgGgplot2ScatterplotTechniquesWebp},
    {'component': <Ggplot2BarCo2AirTravel />, 'path': 'ggplot2-bar-CO2-air-travel', 'title': 'ggplot2 bar CO2 air travel', cover: imgGgplot2BarCo2AirTravel, 'cover_webp': imgGgplot2BarCo2AirTravelWebp},
    {'component': <Ggplot2LinePlotSocialMobility />, 'path': 'ggplot2-line-plot-social-mobility', 'title': 'ggplot2 line plot social mobility', cover: imgGgplot2LinePlotSocialMobility, 'cover_webp': imgGgplot2LinePlotSocialMobilityWebp},
    {'component': <Ggplot22dHistogramHappy />, 'path': 'ggplot2-2D-histogram-happy', 'title': 'ggplot2 2D histogram happy', cover: imgGgplot22dHistogramHappy, 'cover_webp': imgGgplot22dHistogramHappyWebp},
    {'component': <Ggplot2PopulationPyramid />, 'path': 'ggplot2-population-pyramid', 'title': 'ggplot2 population pyramid', cover: imgGgplot2PopulationPyramid, 'cover_webp': imgGgplot2PopulationPyramidWebp},
    {'component': <Ggplot2BarsMidwestCounty />, 'path': 'ggplot2-bars-midwest-county', 'title': 'ggplot2 bars midwest county', cover: imgGgplot2BarsMidwestCounty, 'cover_webp': imgGgplot2BarsMidwestCountyWebp},
    {'component': <Ggplot2LinePlotCigarettes />, 'path': 'ggplot2-line-plot-cigarettes', 'title': 'ggplot2 line plot cigarettes', cover: imgGgplot2LinePlotCigarettes, 'cover_webp': imgGgplot2LinePlotCigarettesWebp},
    {'component': <Ggplot2WorldMapCitiesPopulations />, 'path': 'ggplot2-world-map-cities-populations', 'title': 'ggplot2 world map cities populations', cover: imgGgplot2WorldMapCitiesPopulations, 'cover_webp': imgGgplot2WorldMapCitiesPopulationsWebp},
    {'component': <Ggplot2DrawWithMathFunctions />, 'path': 'ggplot2-draw-with-math-functions', 'title': 'ggplot2 draw with math functions', cover: imgGgplot2DrawWithMathFunctions, 'cover_webp': imgGgplot2DrawWithMathFunctionsWebp},
    // {'component': <Ggplot2LollipopPolarCoordHorsepower />, 'path': 'ggplot2-lollipop-polar-coord-horsepower', 'title': 'ggplot2 lollipop polar coord horsepower', cover: imgGgplot2LollipopPolarCoordHorsepower},
    {'component': <Ggplot2MapAirline />, 'path': 'ggplot2-map-airline', 'title': 'ggplot2 map airline', cover: imgGgplot2MapAirline, 'cover_webp': imgGgplot2MapAirlineAnimationWebp},
    {'component': <Ggplot2AreaPlotMigration />, 'path': 'ggplot2-area-plot-migration', 'title': 'ggplot2 area plot migration', cover: imgGgplot2AreaPlotMigration, 'cover_webp': imgGgplot2AreaPlotMigrationWebp},
    {'component': <Ggplot2DonutPlotAdmission />, 'path': 'ggplot2-donut-plot-admission', 'title': 'ggplot2 donut plot admission', cover: imgGgplot2DonutPlotAdmission, 'cover_webp': imgGgplot2DonutPlotAdmissionWebp},
    {'component': <Ggplot2LineSummaryStatisticsMilk />, 'path': 'ggplot2-line-summary-statistics-milk', 'title': 'ggplot2 line summary statistics milk', cover: imgGgplot2LineSummaryStatisticsMilk, 'cover_webp': imgGgplot2LineSummaryStatisticsMilkWebp},
    {'component': <Ggplot2BarPolarCoordHorsepower />, 'path': 'ggplot2-bar-polar-coord-horsepower', 'title': 'ggplot2 bar polar coord horsepower', cover: imgGgplot2BarPolarCoordHorsepower, 'cover_webp': imgGgplot2BarPolarCoordHorsepowerWebp},
    {'component': <Ggplot2LollipopDonutHorsepower />, 'path': 'ggplot2-lollipop-donut-horsepower', 'title': 'ggplot2 lollipop donut horsepower', cover: imgGgplot2LollipopDonutHorsepower, 'cover_webp': imgGgplot2LollipopDonutHorsepowerWebp},
    // {'component': '<Ggplot2HeatmapVaccineFor8Diseases />', 'path': 'ggplot2-heatmap-vaccine-for-8-diseases', 'title': 'ggplot2 heatmap vaccine for 8 diseases', cover: imgGgplot2HeatmapVaccineFor8Diseases},
    {'component': <Ggplot2StreamBlockbuster />, 'path': 'ggplot2-stream-blockbuster', 'title': 'ggplot2 stream blockbuster', cover: imgGgplot2StreamBlockbuster, 'cover_webp': imgGgplot2StreamBlockbusterWebp},
    {'component': <Ggplot2PopulationPyramidAnimation />, 'path': 'ggplot2-population-pyramid-animation', 'title': 'ggplot2 population pyramid animation', cover: imgGgplot2PopulationPyramidAnimation, 'cover_webp': imgGgplot2PopulationPyramidAnimationWebp},
    {'component': <Ggplot2ArtsyRArtworks />, 'path': 'ggplot2-aRtsy-R-artworks', 'title': 'ggplot2 aRtsy R artworks', cover:'empty', cover: imgGgplot2ArtsyRArtworks, 'cover_webp': imgGgplot2ArtsyRArtworksWebp},
    {'component': <Ggplot2BarSleep />, 'path': 'ggplot2-bar-sleep', 'title': 'ggplot2 bar sleep', cover: imgGgplot2BarSleep, 'cover_webp': imgGgplot2BarSleepWebp},
    {'component': <Ggplot2SegmentUsPresidentTerms />, 'path': 'ggplot2-segment-US-president-terms', 'title': 'ggplot2 segment US president terms', cover: imgGgplot2SegmentUsPresidentTerms, 'cover_webp':imgGgplot2SegmentUsPresidentTermsWebp},
    {'component': <Ggplot2DensityPlotGgridgeSunspot />, 'path': 'ggplot2-density-plot-ggridge-sunspot', 'title': 'ggplot2 density plot ggridge sunspot', cover: imgGgplot2DensityPlotGgridgeSunspot, 'cover_webp':imgGgplot2DensityPlotGgridgeSunspotWebp},
    {'component': <Ggplot2AreaEducation />, 'path': 'ggplot2-area-education', 'title': 'ggplot2 area education', cover: imgGgplot2AreaEducation, 'cover_webp':imgGgplot2AreaEducationWebp},
    {'component': <Ggplot2FishEncounters />, 'path': 'ggplot2-fish-encounters', 'title': 'ggplot2 fish encounters', cover: imgGgplot2FishEncounters, 'cover_webp':imgGgplot2FishEncountersWebp},
    {'component': <Ggplot2MapMapproj />, 'path': 'ggplot2-map-mapproj', 'title': 'ggplot2 map mapproj', cover: imgGgplot2MapMapproj, 'cover_webp':imgGgplot2MapMapprojWebp},
    {'component': <Ggplot2PhoneZoomIn />, 'path': 'ggplot2-phone-zoom-in', 'title': 'ggplot2 phone zoom in', cover: imgGgplot2PhoneZoomIn, 'cover_webp':imgGgplot2PhoneZoomInWebp},
    {'component': <Ggplot2LineSlopeplot />, 'path': 'ggplot2-line-slopeplot', 'title': 'ggplot2 line slopeplot', 'cover': imgGgplot2LineSlopeplot, 'cover_webp': imgGgplot2LineSlopeplotWebp},
  ]

export default RGalleryData