import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function StrWrap(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Wrap Long Sentences</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p><strong><code>str_wrap()</code></strong> wraps long sentences into nicely formatted paragraphs.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(stringr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a>x <span className="ot">&lt;-</span> <span className="st">"William Shakespeare was a poet, actor and dramatist and is often seen as the best writer in the English language. He lived in the late 16th and early 17th century, but his plays are still widely performed today."</span></span></code></pre></div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a><span className="co"># print without wraping</span></span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a><span className="fu">cat</span>(x)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">William Shakespeare was a poet, actor and dramatist and is often seen as the best writer in the English language. He lived in the late 16th and early 17th century, but his plays are still widely performed today.</code></pre>
</div>
</div>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a><span className="co"># wrap texts, with not more than 60 characters in each line</span></span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a><span className="fu">cat</span>(<span className="fu">str_wrap</span>(x, <span className="at">width =</span> <span className="dv">60</span>)) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r">William Shakespeare was a poet, actor and dramatist and is
<br/>often seen as the best writer in the English language. He
<br/>lived in the late 16th and early 17th century, but his plays
<br/>are still widely performed today.</code></pre>
</div>
</div>
<p><code>str_wrap()</code> is a powerful tool to handle long texts in <Link to="../../../R/gallery">data visualization</Link>, as demonstrated in <Link to="../../../R/gallery/ggplot2-line-plot-cigarettes">this example</Link> to wrap extended plot titles.</p>
</main>
</div>
</div>
)}