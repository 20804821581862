import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../useCustomEffect'; 
import AddTabsetQuarto from '../../js/addCodeFoldingTabforQuarto'; 
import imgGgplot2SegmentUsPresidentTerms from '../graphics/president_terms_google_fonts_completed.png'; 
import imgGgplot2SegmentUsPresidentTermsWebp from '../graphics/president_terms_google_fonts_completed.webp'; 
export default function Ggplot2SegmentUsPresidentTerms(){
useRCustomEffect()
AddTabsetQuarto()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Use Lines Segment and Rectangle Annotations in ggplot2 to Visualize U.S. Presidential Terms</h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
  <picture>
    <source type="image/webp" srcset={imgGgplot2SegmentUsPresidentTermsWebp} />
    <img className="cover-img" src={imgGgplot2SegmentUsPresidentTerms} />
  </picture>

<p>In this article, we’ll visualize the continuity of US presidential terms from Dwight D. Eisenhower in 1953 to Donald Trump in 2021. <strong>Major techniques demonstrated in this article include:</strong></p>
<ul>
<li><a href="#reorder">Reorder graphical elements (e.g., the y-axis)</a></li>
<li><a href="#geom">Create geometrical elements: line segments, texts, and rectangles</a></li>
<li><a href="#ggtext">Create format-enriched texts with <code>ggtext</code> package</a></li>
<li><a href="#google_font_showtext">Enrich texts with fonts from Google Fonts Repository</a></li>
</ul>
<hr/>
<div className="tabset-margin-container"></div><div className="panel-tabset">
<ul className="nav nav-tabs" role="tablist"><li className="nav-item" role="presentation"><a aria-controls="tabset-1-1" aria-selected="true" className="nav-link active" data-bs-target="#tabset-1-1" data-bs-toggle="tab" href="" id="tabset-1-1-tab" role="tab">Stepwise instructions</a></li><li className="nav-item" role="presentation"><a aria-controls="tabset-1-2" aria-selected="false" className="nav-link" data-bs-target="#tabset-1-2" data-bs-toggle="tab" href="" id="tabset-1-2-tab" role="tab">Code only</a></li></ul>
<div className="tab-content">
<div aria-labelledby="tabset-1-1-tab" className="tab-pane active" id="tabset-1-1" role="tabpanel">
<section className="level3" id="data-cleanup">
<h3 className="anchored" data-anchor-id="data-cleanup">Data cleanup</h3>
<p>In this article, we’ll use the <code>presidential</code> dataset which is built in ggplot2. It shows the names of 12 U.S. presidents from Eisenhower to Trump, their start and end date of their terms, and their associated parties.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="co"># packages</span></span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-5"><a aria-hidden="true" href="#cb1-5" tabindex="-1"></a></span><br/>
<span id="cb1-6"><a aria-hidden="true" href="#cb1-6" tabindex="-1"></a><span className="co"># set global default theme</span></span>
<span id="cb1-7"><a aria-hidden="true" href="#cb1-7" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb1-8"><a aria-hidden="true" href="#cb1-8" tabindex="-1"></a></span><br/>
<span id="cb1-9"><a aria-hidden="true" href="#cb1-9" tabindex="-1"></a>presidential_2 <span className="ot">&lt;-</span> presidential</span>
<span id="cb1-10"><a aria-hidden="true" href="#cb1-10" tabindex="-1"></a></span><br/>
<span id="cb1-11"><a aria-hidden="true" href="#cb1-11" tabindex="-1"></a><span className="co"># Update the Bush name</span></span>
<span id="cb1-12"><a aria-hidden="true" href="#cb1-12" tabindex="-1"></a>presidential_2<span className="sc">$</span>name[<span className="dv">8</span>] <span className="ot">&lt;-</span> <span className="st">"H.W.Bush"</span></span>
<span id="cb1-13"><a aria-hidden="true" href="#cb1-13" tabindex="-1"></a>presidential_2<span className="sc">$</span>name[<span className="dv">10</span>] <span className="ot">&lt;-</span> <span className="st">"W.Bush"</span></span></code></pre></div>
</div>
<p><span id="reorder"><strong>Reorder the president names by the start date.</strong> It consists two critical steps:</span></p>
<ul>
<li><p>Arrange the rows by the start date. The arrangement proceeds in descending order (by <code>desc</code>), and early rows in the dataset will be plotted at the bottom of the plot.</p></li>
<li><p>Convert the <code>name</code> variable to a nominal factor (noted as <code>&lt;fct&gt;</code> in the printed output) to “memorize” the current order of president names.</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb2"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb2-1"><a aria-hidden="true" href="#cb2-1" tabindex="-1"></a>presidential_2.arranged <span className="ot">&lt;-</span> presidential_2 <span className="sc">%&gt;%</span> </span>
<span id="cb2-2"><a aria-hidden="true" href="#cb2-2" tabindex="-1"></a>  <span className="fu">arrange</span>(<span className="fu">desc</span>(start)) <span className="sc">%&gt;%</span> </span>
<span id="cb2-3"><a aria-hidden="true" href="#cb2-3" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">name =</span> <span className="fu">factor</span>(name, <span className="at">levels =</span> name))</span>
<span id="cb2-4"><a aria-hidden="true" href="#cb2-4" tabindex="-1"></a></span><br/>
<span id="cb2-5"><a aria-hidden="true" href="#cb2-5" tabindex="-1"></a><span className="co"># ready for visualization!</span></span>
<span id="cb2-6"><a aria-hidden="true" href="#cb2-6" tabindex="-1"></a><span className="fu">head</span>(presidential_2.arranged, <span className="at">n =</span> <span className="dv">4</span>) </span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 4 × 4
<br/>  name    start      end        party     
<br/>  &lt;fct&gt;   &lt;date&gt;     &lt;date&gt;     &lt;chr&gt;     
<br/>1 Trump   2017-01-20 2021-01-20 Republican
<br/>2 Obama   2009-01-20 2017-01-20 Democratic
<br/>3 W.Bush  2001-01-20 2009-01-20 Republican
<br/>4 Clinton 1993-01-20 2001-01-20 Democratic</code></pre>
</div>
</div>
<p>For the code above, you may also use <code>factor(name, levels = name, ordered = T)</code> to convert the <code>name</code> variable to an <em>ordered</em> factor (indicated as <code>&lt;ord&gt;</code>). In ggplot2 reordering, both a nominal <code>&lt;fct&gt;</code> and an ordered factor <code>&lt;ord&gt;</code> works the same. More details on ggplot2 graphic reordering from the basics to advanced skills can be found in this <Link to="../../R/visualization/4-ggplot2-reorder-bars">complete guide</Link>.</p>
</section>
<section className="level3" id="visualization">
<h3 className="anchored" data-anchor-id="visualization">Visualization</h3>
<p><span id="geom"><strong>Draw line segments to show the starting and ending year of the service terms of different Presidents.</strong> <code>geom_segment()</code> requires four aesthetics: <code>x</code>, <code>y</code> for the staring coordinate, and <code>xend</code> and <code>yend</code> for the ending coordinate of the line segment.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb4"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb4-1"><a aria-hidden="true" href="#cb4-1" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> presidential_2.arranged <span className="sc">%&gt;%</span> </span>
<span id="cb4-2"><a aria-hidden="true" href="#cb4-2" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> start, <span className="at">y =</span> name, <span className="at">label =</span> name, </span>
<span id="cb4-3"><a aria-hidden="true" href="#cb4-3" tabindex="-1"></a>             <span className="at">color =</span> party, <span className="at">fill =</span> party)) <span className="sc">+</span></span>
<span id="cb4-4"><a aria-hidden="true" href="#cb4-4" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="fu">aes</span>(<span className="at">xend =</span> end, <span className="at">yend =</span> name), </span>
<span id="cb4-5"><a aria-hidden="true" href="#cb4-5" tabindex="-1"></a>               <span className="at">linewidth =</span> <span className="dv">4</span>)</span>
<span id="cb4-6"><a aria-hidden="true" href="#cb4-6" tabindex="-1"></a>p1</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_line_segment.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_line_segment"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Add president names.</strong> By default, the texts are center justified relative to the <code>x</code> aesthetic (the starting year). Here instead, we use <code>hjust</code> to change the justification rule. The elements of the <code>hjust</code> vector is respectively applied to the president names in a <em>bottom up</em> order (from Trump to Eisenhower): the first value 0.3 shifts “Trump” to the left (so the text does not overflow beyond the plot boundary), and <code>rep(0, 11)</code> left-justifies the other 11 names to the left edge of the line segment.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="at">nudge_y =</span> .<span className="dv">35</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="fu">c</span>(.<span className="dv">3</span>, <span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">11</span>))) </span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>p2</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_texts_names.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_texts_names"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Add the years of serving terms.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb6"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb6-1"><a aria-hidden="true" href="#cb6-1" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb6-2"><a aria-hidden="true" href="#cb6-2" tabindex="-1"></a>  <span className="co"># use format %Y to extract the year component from a Date  </span></span>
<span id="cb6-3"><a aria-hidden="true" href="#cb6-3" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">paste</span>(<span className="fu">format</span>(start, <span className="st">"%Y"</span>), <span className="st">"~"</span>, <span className="fu">format</span>(end, <span className="st">"%Y"</span>))), </span>
<span id="cb6-4"><a aria-hidden="true" href="#cb6-4" tabindex="-1"></a>  <span className="at">nudge_y =</span> <span className="sc">-</span>.<span className="dv">35</span>, </span>
<span id="cb6-5"><a aria-hidden="true" href="#cb6-5" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="fu">c</span>(.<span className="dv">6</span>, <span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">11</span>)),</span>
<span id="cb6-6"><a aria-hidden="true" href="#cb6-6" tabindex="-1"></a>  <span className="at">size =</span> <span className="fl">3.5</span>) </span>
<span id="cb6-7"><a aria-hidden="true" href="#cb6-7" tabindex="-1"></a>p3</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_texts_terms.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_texts_terms"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><strong>Draw rectangles to highlight the serving terms and associated parties.</strong> <code>geom_rect()</code> requires four aesthetics to specify the coordinates of the four edges, respectively: <code>xmin</code>, <code>xmax</code>, <code>ymin</code>, and <code>ymax</code>. Use the infinite value <code>Inf</code> to draw rectangles extending across the entire axial range.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">geom_rect</span>(</span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">xmin =</span> start, <span className="at">xmax =</span> end, <span className="at">ymin =</span> <span className="sc">-</span><span className="cn">Inf</span>, <span className="at">ymax =</span> <span className="cn">Inf</span>), </span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>  <span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">linewidth =</span> .<span className="dv">2</span>, <span className="at">linetype =</span> <span className="st">"dotted"</span>) </span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>p4</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_rectangles.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_rectangles"/>
  </picture>
</figure>
</div>
</div>
</div>
<p>Adjust the color scale, plot aspect ratio, and themes.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb8"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb8-1"><a aria-hidden="true" href="#cb8-1" tabindex="-1"></a><span className="co"># color scale of line segments and texts annotations</span></span>
<span id="cb8-2"><a aria-hidden="true" href="#cb8-2" tabindex="-1"></a>myColors.light <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Democratic"</span> <span className="ot">=</span> <span className="st">"steelblue2"</span>, <span className="st">"Republican"</span> <span className="ot">=</span> <span className="st">"firebrick1"</span>)</span>
<span id="cb8-3"><a aria-hidden="true" href="#cb8-3" tabindex="-1"></a><span className="co"># color scale of the background</span></span>
<span id="cb8-4"><a aria-hidden="true" href="#cb8-4" tabindex="-1"></a>myColors.dark <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Democratic"</span> <span className="ot">=</span> <span className="st">"steelblue4"</span>, <span className="st">"Republican"</span> <span className="ot">=</span> <span className="st">"firebrick4"</span>)</span>
<span id="cb8-5"><a aria-hidden="true" href="#cb8-5" tabindex="-1"></a></span><br/>
<span id="cb8-6"><a aria-hidden="true" href="#cb8-6" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb8-7"><a aria-hidden="true" href="#cb8-7" tabindex="-1"></a>  <span className="co"># keep a long-narrow dimension</span></span>
<span id="cb8-8"><a aria-hidden="true" href="#cb8-8" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="dv">2000</span>, <span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span> </span>
<span id="cb8-9"><a aria-hidden="true" href="#cb8-9" tabindex="-1"></a>  <span className="co"># color / fill scale</span></span>
<span id="cb8-10"><a aria-hidden="true" href="#cb8-10" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> myColors.dark ) <span className="sc">+</span></span>
<span id="cb8-11"><a aria-hidden="true" href="#cb8-11" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> myColors.light) <span className="sc">+</span></span>
<span id="cb8-12"><a aria-hidden="true" href="#cb8-12" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb8-13"><a aria-hidden="true" href="#cb8-13" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">10</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>),</span>
<span id="cb8-14"><a aria-hidden="true" href="#cb8-14" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb8-15"><a aria-hidden="true" href="#cb8-15" tabindex="-1"></a>p5</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_scale_coord_theme.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_scale_coord_theme"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="ggtext"><strong>Add plot title with enriched colors using <Link to="https://github.com/wilkelab/ggtext"><code>ggtext</code></Link> package:</strong></span></p>
<ul>
<li><p><strong>Use HTML in the title string.</strong> The line breaks in the script (make by pressing the <em>Enter</em> or <em>return</em> key) makes the script easier to read, but will not be visible in the rendered graphic. If you want to make an indicated line break, use <code>&lt;br&gt;</code> anywhere desired. Alternatively, you can use automatic text wrapping (see the following bullet)</p></li>
<li><p><strong>Use <code>element_textbox_simple()</code> in <code>theme()</code></strong> in place of <code>element_text()</code> to render the HTML-masked title with automatic text wrapping. (Alternatively, use <code>element_markdown()</code> and HTML tag <code>&lt;br&gt;</code> to make indicated line breaks, as in <Link to="../ggplot2-scatterplot-techniques">this example</Link>.)</p></li>
</ul>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a></span><br/>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>plot_title <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>  <span className="st">"Presidential Chronicles: A Tale of </span></span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a><span className="st">  &lt;span style='color: steelblue3;'&gt;Democrats&lt;/span&gt; and </span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a><span className="st">  &lt;span style='color: red3;'&gt;Republicans&lt;/span&gt; from </span></span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a><span className="st">  Eisenhower to Trump – Unveiling the Power Shifts in the U.S."</span>)</span>
<span id="cb9-8"><a aria-hidden="true" href="#cb9-8" tabindex="-1"></a></span><br/>
<span id="cb9-9"><a aria-hidden="true" href="#cb9-9" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">ggtitle</span>(plot_title) </span>
<span id="cb9-10"><a aria-hidden="true" href="#cb9-10" tabindex="-1"></a></span><br/>
<span id="cb9-11"><a aria-hidden="true" href="#cb9-11" tabindex="-1"></a>p6 <span className="sc">+</span> </span>
<span id="cb9-12"><a aria-hidden="true" href="#cb9-12" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb9-13"><a aria-hidden="true" href="#cb9-13" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb9-14"><a aria-hidden="true" href="#cb9-14" tabindex="-1"></a>      <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">l =</span> <span className="dv">20</span>, <span className="at">r =</span> <span className="dv">20</span>, <span className="at">unit =</span> <span className="st">"pt"</span>),</span>
<span id="cb9-15"><a aria-hidden="true" href="#cb9-15" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">14</span>))</span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_ggtext.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_ggtext"/>
  </picture>
</figure>
</div>
</div>
</div>
<p><span id="google_font_showtext"><strong>Load <Link to="https://fonts.google.com/">Google font</Link> “Anton” to further enhance the title.</strong> Use <code>showtext</code> package to make it available to be used in ggplot2.</span></p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="st">"Anton"</span>, <span className="st">"Anton"</span>)</span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a></span><br/>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>p6 <span className="sc">+</span> </span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb10-8"><a aria-hidden="true" href="#cb10-8" tabindex="-1"></a>      <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">l =</span> <span className="dv">20</span>, <span className="at">r =</span> <span className="dv">20</span>, <span className="at">unit =</span> <span className="st">"pt"</span>),</span>
<span id="cb10-9"><a aria-hidden="true" href="#cb10-9" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">14</span>, </span>
<span id="cb10-10"><a aria-hidden="true" href="#cb10-10" tabindex="-1"></a>      <span className="co"># use Google font "Anton"</span></span>
<span id="cb10-11"><a aria-hidden="true" href="#cb10-11" tabindex="-1"></a>      <span className="at">family =</span> <span className="st">"Anton"</span>)) </span></code></pre></div>
<div className="cell-output-display">
<div className="quarto-figure quarto-figure-center">
<figure className="figure">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/president_terms_google_fonts_completed.webp" />
    <img className="img-fluid quarto-figure quarto-figure-center figure-img" src="graphics/president_terms_google_fonts_completed"/>
  </picture>
</figure>
</div>
</div>
</div>
</section>
</div>
<div aria-labelledby="tabset-1-2-tab" className="tab-pane" id="tabset-1-2" role="tabpanel">
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb11"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb11-1"><a aria-hidden="true" href="#cb11-1" tabindex="-1"></a><span className="fu">library</span>(ggplot2)</span>
<span id="cb11-2"><a aria-hidden="true" href="#cb11-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb11-3"><a aria-hidden="true" href="#cb11-3" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb11-4"><a aria-hidden="true" href="#cb11-4" tabindex="-1"></a></span><br/>
<span id="cb11-5"><a aria-hidden="true" href="#cb11-5" tabindex="-1"></a><span className="co"># set global default theme</span></span>
<span id="cb11-6"><a aria-hidden="true" href="#cb11-6" tabindex="-1"></a><span className="fu">theme_set</span>(<span className="fu">theme_minimal</span>(<span className="at">base_size =</span> <span className="dv">14</span>))</span>
<span id="cb11-7"><a aria-hidden="true" href="#cb11-7" tabindex="-1"></a></span><br/>
<span id="cb11-8"><a aria-hidden="true" href="#cb11-8" tabindex="-1"></a>presidential_2 <span className="ot">&lt;-</span> presidential</span>
<span id="cb11-9"><a aria-hidden="true" href="#cb11-9" tabindex="-1"></a></span><br/>
<span id="cb11-10"><a aria-hidden="true" href="#cb11-10" tabindex="-1"></a><span className="co"># Update the Bush name</span></span>
<span id="cb11-11"><a aria-hidden="true" href="#cb11-11" tabindex="-1"></a>presidential_2<span className="sc">$</span>name[<span className="dv">8</span>] <span className="ot">&lt;-</span> <span className="st">"H.W.Bush"</span></span>
<span id="cb11-12"><a aria-hidden="true" href="#cb11-12" tabindex="-1"></a>presidential_2<span className="sc">$</span>name[<span className="dv">10</span>] <span className="ot">&lt;-</span> <span className="st">"W.Bush"</span></span>
<span id="cb11-13"><a aria-hidden="true" href="#cb11-13" tabindex="-1"></a></span><br/>
<span id="cb11-14"><a aria-hidden="true" href="#cb11-14" tabindex="-1"></a><span className="co"># Reorder the president names by the start date</span></span>
<span id="cb11-15"><a aria-hidden="true" href="#cb11-15" tabindex="-1"></a>presidential_2.arranged <span className="ot">&lt;-</span> presidential_2 <span className="sc">%&gt;%</span> </span>
<span id="cb11-16"><a aria-hidden="true" href="#cb11-16" tabindex="-1"></a>  <span className="fu">arrange</span>(<span className="fu">desc</span>(start)) <span className="sc">%&gt;%</span> </span>
<span id="cb11-17"><a aria-hidden="true" href="#cb11-17" tabindex="-1"></a>  <span className="fu">mutate</span>(<span className="at">name =</span> <span className="fu">factor</span>(name, <span className="at">levels =</span> name))</span>
<span id="cb11-18"><a aria-hidden="true" href="#cb11-18" tabindex="-1"></a></span><br/>
<span id="cb11-19"><a aria-hidden="true" href="#cb11-19" tabindex="-1"></a><span className="co"># ready for visualization!</span></span>
<span id="cb11-20"><a aria-hidden="true" href="#cb11-20" tabindex="-1"></a><span className="fu">head</span>(presidential_2.arranged, <span className="at">n =</span> <span className="dv">4</span>) </span>
<span id="cb11-21"><a aria-hidden="true" href="#cb11-21" tabindex="-1"></a></span><br/>
<span id="cb11-22"><a aria-hidden="true" href="#cb11-22" tabindex="-1"></a></span><br/>
<span id="cb11-23"><a aria-hidden="true" href="#cb11-23" tabindex="-1"></a><span className="co"># Draw line segments showing the starting and ending year of the terms. </span></span>
<span id="cb11-24"><a aria-hidden="true" href="#cb11-24" tabindex="-1"></a>p1 <span className="ot">&lt;-</span> presidential_2.arranged <span className="sc">%&gt;%</span> </span>
<span id="cb11-25"><a aria-hidden="true" href="#cb11-25" tabindex="-1"></a>  <span className="fu">ggplot</span>(<span className="fu">aes</span>(<span className="at">x =</span> start, <span className="at">y =</span> name, <span className="at">label =</span> name, </span>
<span id="cb11-26"><a aria-hidden="true" href="#cb11-26" tabindex="-1"></a>             <span className="at">color =</span> party, <span className="at">fill =</span> party)) <span className="sc">+</span></span>
<span id="cb11-27"><a aria-hidden="true" href="#cb11-27" tabindex="-1"></a>  <span className="fu">geom_segment</span>(<span className="fu">aes</span>(<span className="at">xend =</span> end, <span className="at">yend =</span> name), </span>
<span id="cb11-28"><a aria-hidden="true" href="#cb11-28" tabindex="-1"></a>               <span className="at">linewidth =</span> <span className="dv">4</span>)</span>
<span id="cb11-29"><a aria-hidden="true" href="#cb11-29" tabindex="-1"></a>p1</span>
<span id="cb11-30"><a aria-hidden="true" href="#cb11-30" tabindex="-1"></a></span><br/>
<span id="cb11-31"><a aria-hidden="true" href="#cb11-31" tabindex="-1"></a></span><br/>
<span id="cb11-32"><a aria-hidden="true" href="#cb11-32" tabindex="-1"></a><span className="co"># Add president names</span></span>
<span id="cb11-33"><a aria-hidden="true" href="#cb11-33" tabindex="-1"></a>p2 <span className="ot">&lt;-</span> p1 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb11-34"><a aria-hidden="true" href="#cb11-34" tabindex="-1"></a>  <span className="at">nudge_y =</span> .<span className="dv">35</span>, <span className="at">fontface =</span> <span className="st">"bold"</span>, </span>
<span id="cb11-35"><a aria-hidden="true" href="#cb11-35" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="fu">c</span>(.<span className="dv">3</span>, <span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">11</span>))) </span>
<span id="cb11-36"><a aria-hidden="true" href="#cb11-36" tabindex="-1"></a>p2</span>
<span id="cb11-37"><a aria-hidden="true" href="#cb11-37" tabindex="-1"></a></span><br/>
<span id="cb11-38"><a aria-hidden="true" href="#cb11-38" tabindex="-1"></a></span><br/>
<span id="cb11-39"><a aria-hidden="true" href="#cb11-39" tabindex="-1"></a><span className="co"># Add years of terms.</span></span>
<span id="cb11-40"><a aria-hidden="true" href="#cb11-40" tabindex="-1"></a>p3 <span className="ot">&lt;-</span> p2 <span className="sc">+</span> <span className="fu">geom_text</span>(</span>
<span id="cb11-41"><a aria-hidden="true" href="#cb11-41" tabindex="-1"></a>  <span className="co"># use format %Y to extract the year component from a Date  </span></span>
<span id="cb11-42"><a aria-hidden="true" href="#cb11-42" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">label =</span> <span className="fu">paste</span>(<span className="fu">format</span>(start, <span className="st">"%Y"</span>), <span className="st">"~"</span>, <span className="fu">format</span>(end, <span className="st">"%Y"</span>))), </span>
<span id="cb11-43"><a aria-hidden="true" href="#cb11-43" tabindex="-1"></a>  <span className="at">nudge_y =</span> <span className="sc">-</span>.<span className="dv">35</span>, </span>
<span id="cb11-44"><a aria-hidden="true" href="#cb11-44" tabindex="-1"></a>  <span className="at">hjust =</span> <span className="fu">c</span>(.<span className="dv">6</span>, <span className="fu">rep</span>(<span className="dv">0</span>, <span className="dv">11</span>)),</span>
<span id="cb11-45"><a aria-hidden="true" href="#cb11-45" tabindex="-1"></a>  <span className="at">size =</span> <span className="fl">3.5</span>) </span>
<span id="cb11-46"><a aria-hidden="true" href="#cb11-46" tabindex="-1"></a>p3</span>
<span id="cb11-47"><a aria-hidden="true" href="#cb11-47" tabindex="-1"></a></span><br/>
<span id="cb11-48"><a aria-hidden="true" href="#cb11-48" tabindex="-1"></a></span><br/>
<span id="cb11-49"><a aria-hidden="true" href="#cb11-49" tabindex="-1"></a><span className="co"># Draw rectangles to highlight the serving terms and associated parties.</span></span>
<span id="cb11-50"><a aria-hidden="true" href="#cb11-50" tabindex="-1"></a>p4 <span className="ot">&lt;-</span> p3 <span className="sc">+</span> <span className="fu">geom_rect</span>(</span>
<span id="cb11-51"><a aria-hidden="true" href="#cb11-51" tabindex="-1"></a>  <span className="fu">aes</span>(<span className="at">xmin =</span> start, <span className="at">xmax =</span> end, <span className="at">ymin =</span> <span className="sc">-</span><span className="cn">Inf</span>, <span className="at">ymax =</span> <span className="cn">Inf</span>), </span>
<span id="cb11-52"><a aria-hidden="true" href="#cb11-52" tabindex="-1"></a>  <span className="at">alpha =</span> .<span className="dv">2</span>, <span className="at">linewidth =</span> .<span className="dv">2</span>, <span className="at">linetype =</span> <span className="st">"dotted"</span>) </span>
<span id="cb11-53"><a aria-hidden="true" href="#cb11-53" tabindex="-1"></a>p4</span>
<span id="cb11-54"><a aria-hidden="true" href="#cb11-54" tabindex="-1"></a></span><br/>
<span id="cb11-55"><a aria-hidden="true" href="#cb11-55" tabindex="-1"></a></span><br/>
<span id="cb11-56"><a aria-hidden="true" href="#cb11-56" tabindex="-1"></a><span className="co"># Adjust the color scale, aspect ratio, and themes. </span></span>
<span id="cb11-57"><a aria-hidden="true" href="#cb11-57" tabindex="-1"></a><span className="do">## color scale of line segments and texts annotations</span></span>
<span id="cb11-58"><a aria-hidden="true" href="#cb11-58" tabindex="-1"></a>myColors.light <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Democratic"</span> <span className="ot">=</span> <span className="st">"steelblue2"</span>, <span className="st">"Republican"</span> <span className="ot">=</span> <span className="st">"firebrick1"</span>)</span>
<span id="cb11-59"><a aria-hidden="true" href="#cb11-59" tabindex="-1"></a><span className="do">## color scale of the background</span></span>
<span id="cb11-60"><a aria-hidden="true" href="#cb11-60" tabindex="-1"></a>myColors.dark <span className="ot">&lt;-</span> <span className="fu">c</span>(<span className="st">"Democratic"</span> <span className="ot">=</span> <span className="st">"steelblue4"</span>, <span className="st">"Republican"</span> <span className="ot">=</span> <span className="st">"firebrick4"</span>)</span>
<span id="cb11-61"><a aria-hidden="true" href="#cb11-61" tabindex="-1"></a></span><br/>
<span id="cb11-62"><a aria-hidden="true" href="#cb11-62" tabindex="-1"></a>p5 <span className="ot">&lt;-</span> p4 <span className="sc">+</span></span>
<span id="cb11-63"><a aria-hidden="true" href="#cb11-63" tabindex="-1"></a>  <span className="co"># keep a long-narrow dimension</span></span>
<span id="cb11-64"><a aria-hidden="true" href="#cb11-64" tabindex="-1"></a>  <span className="fu">coord_fixed</span>(<span className="at">ratio =</span> <span className="dv">2000</span>, <span className="at">clip =</span> <span className="st">"off"</span>) <span className="sc">+</span> </span>
<span id="cb11-65"><a aria-hidden="true" href="#cb11-65" tabindex="-1"></a>  <span className="co"># color / fill scale</span></span>
<span id="cb11-66"><a aria-hidden="true" href="#cb11-66" tabindex="-1"></a>  <span className="fu">scale_color_manual</span>(<span className="at">values =</span> myColors.dark ) <span className="sc">+</span></span>
<span id="cb11-67"><a aria-hidden="true" href="#cb11-67" tabindex="-1"></a>  <span className="fu">scale_fill_manual</span>(<span className="at">values =</span> myColors.light) <span className="sc">+</span></span>
<span id="cb11-68"><a aria-hidden="true" href="#cb11-68" tabindex="-1"></a>  <span className="fu">theme_void</span>() <span className="sc">+</span></span>
<span id="cb11-69"><a aria-hidden="true" href="#cb11-69" tabindex="-1"></a>  <span className="fu">theme</span>(<span className="at">plot.margin =</span> <span className="fu">margin</span>(<span className="fu">rep</span>(<span className="dv">10</span>, <span className="dv">4</span>), <span className="at">unit =</span> <span className="st">"pt"</span>),</span>
<span id="cb11-70"><a aria-hidden="true" href="#cb11-70" tabindex="-1"></a>        <span className="at">legend.position =</span> <span className="st">"none"</span>)</span>
<span id="cb11-71"><a aria-hidden="true" href="#cb11-71" tabindex="-1"></a>p5</span>
<span id="cb11-72"><a aria-hidden="true" href="#cb11-72" tabindex="-1"></a></span><br/>
<span id="cb11-73"><a aria-hidden="true" href="#cb11-73" tabindex="-1"></a></span><br/>
<span id="cb11-74"><a aria-hidden="true" href="#cb11-74" tabindex="-1"></a><span className="co"># Add plot title with enriched colors</span></span>
<span id="cb11-75"><a aria-hidden="true" href="#cb11-75" tabindex="-1"></a><span className="fu">library</span>(ggtext)</span>
<span id="cb11-76"><a aria-hidden="true" href="#cb11-76" tabindex="-1"></a></span><br/>
<span id="cb11-77"><a aria-hidden="true" href="#cb11-77" tabindex="-1"></a>plot_title <span className="ot">&lt;-</span> <span className="fu">c</span>(</span>
<span id="cb11-78"><a aria-hidden="true" href="#cb11-78" tabindex="-1"></a>  <span className="st">"Presidential Chronicles: A Tale of </span></span>
<span id="cb11-79"><a aria-hidden="true" href="#cb11-79" tabindex="-1"></a><span className="st">  &lt;span style='color: steelblue3;'&gt;Democrats&lt;/span&gt; and </span></span>
<span id="cb11-80"><a aria-hidden="true" href="#cb11-80" tabindex="-1"></a><span className="st">  &lt;span style='color: red3;'&gt;Republicans&lt;/span&gt; from </span></span>
<span id="cb11-81"><a aria-hidden="true" href="#cb11-81" tabindex="-1"></a><span className="st">  Eisenhower to Trump – Unveiling the Power Shifts in the U.S."</span>)</span>
<span id="cb11-82"><a aria-hidden="true" href="#cb11-82" tabindex="-1"></a></span><br/>
<span id="cb11-83"><a aria-hidden="true" href="#cb11-83" tabindex="-1"></a>p6 <span className="ot">&lt;-</span> p5 <span className="sc">+</span> <span className="fu">ggtitle</span>(plot_title) </span>
<span id="cb11-84"><a aria-hidden="true" href="#cb11-84" tabindex="-1"></a></span><br/>
<span id="cb11-85"><a aria-hidden="true" href="#cb11-85" tabindex="-1"></a>p6 <span className="sc">+</span> </span>
<span id="cb11-86"><a aria-hidden="true" href="#cb11-86" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-87"><a aria-hidden="true" href="#cb11-87" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb11-88"><a aria-hidden="true" href="#cb11-88" tabindex="-1"></a>      <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">l =</span> <span className="dv">20</span>, <span className="at">r =</span> <span className="dv">20</span>, <span className="at">unit =</span> <span className="st">"pt"</span>),</span>
<span id="cb11-89"><a aria-hidden="true" href="#cb11-89" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">14</span>))</span>
<span id="cb11-90"><a aria-hidden="true" href="#cb11-90" tabindex="-1"></a></span><br/>
<span id="cb11-91"><a aria-hidden="true" href="#cb11-91" tabindex="-1"></a></span><br/>
<span id="cb11-92"><a aria-hidden="true" href="#cb11-92" tabindex="-1"></a><span className="co"># Load Google font "Anton" to further enhance the title.</span></span>
<span id="cb11-93"><a aria-hidden="true" href="#cb11-93" tabindex="-1"></a><span className="fu">library</span>(showtext)</span>
<span id="cb11-94"><a aria-hidden="true" href="#cb11-94" tabindex="-1"></a><span className="fu">showtext_auto</span>()</span>
<span id="cb11-95"><a aria-hidden="true" href="#cb11-95" tabindex="-1"></a><span className="fu">font_add_google</span>(<span className="st">"Anton"</span>, <span className="st">"Anton"</span>)</span>
<span id="cb11-96"><a aria-hidden="true" href="#cb11-96" tabindex="-1"></a></span><br/>
<span id="cb11-97"><a aria-hidden="true" href="#cb11-97" tabindex="-1"></a>p6 <span className="sc">+</span> </span>
<span id="cb11-98"><a aria-hidden="true" href="#cb11-98" tabindex="-1"></a>  <span className="fu">theme</span>(</span>
<span id="cb11-99"><a aria-hidden="true" href="#cb11-99" tabindex="-1"></a>    <span className="at">plot.title =</span> <span className="fu">element_textbox_simple</span>(</span>
<span id="cb11-100"><a aria-hidden="true" href="#cb11-100" tabindex="-1"></a>      <span className="at">margin =</span> <span className="fu">margin</span>(<span className="at">b =</span> <span className="dv">5</span>, <span className="at">l =</span> <span className="dv">20</span>, <span className="at">r =</span> <span className="dv">20</span>, <span className="at">unit =</span> <span className="st">"pt"</span>),</span>
<span id="cb11-101"><a aria-hidden="true" href="#cb11-101" tabindex="-1"></a>      <span className="at">size =</span> <span className="dv">14</span>, </span>
<span id="cb11-102"><a aria-hidden="true" href="#cb11-102" tabindex="-1"></a>      <span className="co"># use Google font "Anton"</span></span>
<span id="cb11-103"><a aria-hidden="true" href="#cb11-103" tabindex="-1"></a>      <span className="at">family =</span> <span className="st">"Anton"</span>)) </span></code></pre></div>
</div>
</div>
</div>
</div>
<p><br/></p>
<hr/>
<p><br/></p>
<h3 className="anchored">
<strong><em>Continue Exploring — 🚀 one level up!</em></strong>
</h3>
<p><br/></p>
<p>Line segments when used properly can become powerful elements to aid in visualization, as demonstrated above. In addition, line segments can be readily updated into arrows to display information about trend of changes. The following article leverages <Link to="../ggplot2-arrows-facet-women-parliament"><strong>annotated arrows in faceted panels</strong></Link> to depict the changes in the proportion of seats held by women in national parliaments from 2000 to 2020.</p>
<p><Link to="../ggplot2-arrows-facet-women-parliament">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/women_seats_parliament_completed.png" />
  </picture>
</Link></p>
<p><br/><br/></p>
<p>Line segments can be readily combined with points to create dumbbell plots, useful to highlight items with contrast. In the following article, <Link to="../ggplot2-dumbell-plot-votes-Trump-Clinton"><strong>annotated dumbbell plots in faceted panels</strong></Link> are employed to visualize the percentage of votes won by Donald Trump and Hillary Clinton from different population groups at the 2016 United States presidential election.</p>
<p><Link to="../ggplot2-dumbell-plot-votes-Trump-Clinton">
  <picture>
    <source type="image/webp" srcset="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_completed.webp" />
    <img className="img-fluid" src="https://s3.amazonaws.com/databrewer/media/graphics/dumbbell_president_election_completed.png" />
  </picture>
</Link></p>
</main>
</div>
</div>
)}