import React from 'react'; 
import {Link} from 'react-router-dom'; 
import {useRCustomEffect} from '../../../useCustomEffect'; 
export default function PivotLongerPart2(){
useRCustomEffect()
return ( <div>
<div className="page-columns page-rows-contents page-layout-article" id="quarto-content">
<main className="content" id="quarto-document-content">
<header className="quarto-title-block default" id="title-block-header">
<div className="quarto-title">
<h1 className="title">Gather Columns into Longer and Narrower Dataset (2/4): <em>Deal with Numeric data in Column Names</em></h1>
</div>
<div className="quarto-title-meta">
</div>
</header>
<p>The <code>billboard</code> dataset records the billboard rank of songs across all weeks in the year 2000. It has a similar structure as the <code>relig_income</code> dataset used in the <Link to="../pivot-longer-part1">last tutorial</Link>.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb1"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb1-1"><a aria-hidden="true" href="#cb1-1" tabindex="-1"></a><span className="fu">library</span>(tidyr)</span>
<span id="cb1-2"><a aria-hidden="true" href="#cb1-2" tabindex="-1"></a><span className="fu">library</span>(dplyr)</span>
<span id="cb1-3"><a aria-hidden="true" href="#cb1-3" tabindex="-1"></a></span><br/>
<span id="cb1-4"><a aria-hidden="true" href="#cb1-4" tabindex="-1"></a>billboard</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 317 × 79
<br/>   artist   track date.entered   wk1   wk2   wk3   wk4   wk5   wk6   wk7   wk8   wk9  wk10  wk11  wk12  wk13  wk14  wk15  wk16  wk17  wk18  wk19  wk20  wk21  wk22  wk23  wk24  wk25  wk26  wk27  wk28  wk29  wk30  wk31  wk32  wk33  wk34  wk35  wk36  wk37  wk38  wk39  wk40  wk41  wk42  wk43  wk44  wk45
<br/>   &lt;chr&gt;    &lt;chr&gt; &lt;date&gt;       &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt; &lt;dbl&gt;
<br/> 1 2 Pac    Baby… 2000-02-26      87    82    72    77    87    94    99    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 2 2Ge+her  The … 2000-09-02      91    87    92    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 3 3 Doors… Kryp… 2000-04-08      81    70    68    67    66    57    54    53    51    51    51    51    47    44    38    28    22    18    18    14    12     7     6     6     6     5     5     4     4     4     4     3     3     3     4     5     5     9     9    15    14    13    14    16    17
<br/> 4 3 Doors… Loser 2000-10-21      76    76    72    69    67    65    55    59    62    61    61    59    61    66    72    76    75    67    73    70    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 5 504 Boyz Wobb… 2000-04-15      57    34    25    17    17    31    36    49    53    57    64    70    75    76    78    85    92    96    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 6 98^0     Give… 2000-08-19      51    39    34    26    26    19     2     2     3     6     7    22    29    36    47    67    66    84    93    94    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 7 A*Teens  Danc… 2000-07-08      97    97    96    95   100    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 8 Aaliyah  I Do… 2000-01-29      84    62    51    41    38    35    35    38    38    36    37    37    38    49    61    63    62    67    83    86    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/> 9 Aaliyah  Try … 2000-03-18      59    53    38    28    21    18    16    14    12    10     9     8     6     1     2     2     2     2     3     4     5     5     6     9    13    14    16    23    22    33    36    43    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/>10 Adams, … Open… 2000-08-26      76    76    74    69    68    67    61    58    57    59    66    68    61    67    59    63    67    71    79    89    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA    NA
<br/># ℹ 307 more rows
<br/># ℹ 31 more variables: wk46 &lt;dbl&gt;, wk47 &lt;dbl&gt;, wk48 &lt;dbl&gt;, wk49 &lt;dbl&gt;, wk50 &lt;dbl&gt;, wk51 &lt;dbl&gt;, wk52 &lt;dbl&gt;, wk53 &lt;dbl&gt;, wk54 &lt;dbl&gt;, wk55 &lt;dbl&gt;, wk56 &lt;dbl&gt;, wk57 &lt;dbl&gt;, wk58 &lt;dbl&gt;, wk59 &lt;dbl&gt;, wk60 &lt;dbl&gt;, wk61 &lt;dbl&gt;, wk62 &lt;dbl&gt;, wk63 &lt;dbl&gt;, wk64 &lt;dbl&gt;, wk65 &lt;dbl&gt;, wk66 &lt;lgl&gt;, wk67 &lt;lgl&gt;, wk68 &lt;lgl&gt;,
<br/>#   wk69 &lt;lgl&gt;, wk70 &lt;lgl&gt;, wk71 &lt;lgl&gt;, wk72 &lt;lgl&gt;, wk73 &lt;lgl&gt;, wk74 &lt;lgl&gt;, wk75 &lt;lgl&gt;, wk76 &lt;lgl&gt;</code></pre>
</div>
</div>
<p>For all columns containing the prefix “wk”, we’ll restructure them into two columns: save the column names <code>wk1</code>, <code>wk2</code>, <code>wk3</code>…as values of the column <code>week</code>, and save all ranks across different weeks as values of the <code>rank</code> column. Here we use the selection helper <Link to="/R/data-wrangling/dplyr/2-select-columns#selection_helpers"><code>starts_with()</code></Link> to select columns with the prefix “wk”.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb3"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb3-1"><a aria-hidden="true" href="#cb3-1" tabindex="-1"></a>billboard <span className="sc">%&gt;%</span> </span>
<span id="cb3-2"><a aria-hidden="true" href="#cb3-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb3-3"><a aria-hidden="true" href="#cb3-3" tabindex="-1"></a>    <span className="at">cols =</span> <span className="fu">starts_with</span>(<span className="st">"wk"</span>),</span>
<span id="cb3-4"><a aria-hidden="true" href="#cb3-4" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"week"</span>, </span>
<span id="cb3-5"><a aria-hidden="true" href="#cb3-5" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"rank"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 24,092 × 5
<br/>   artist track                   date.entered week   rank
<br/>   &lt;chr&gt;  &lt;chr&gt;                   &lt;date&gt;       &lt;chr&gt; &lt;dbl&gt;
<br/> 1 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk1      87
<br/> 2 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk2      82
<br/> 3 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk3      72
<br/> 4 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk4      77
<br/> 5 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk5      87
<br/> 6 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk6      94
<br/> 7 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk7      99
<br/> 8 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk8      NA
<br/> 9 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk9      NA
<br/>10 2 Pac  Baby Don't Cry (Keep... 2000-02-26   wk10     NA
<br/># ℹ 24,082 more rows</code></pre>
</div>
</div>
<p>The useful data encoded in <code>billboard</code>’s column names <code>wk1</code>, <code>wk2</code>… is essentially the week number, while the constant prefix “wk” does not carry much useful information. As such, we can use argument <strong><code>names_prefix</code></strong> to remove the prefix “wk” from the <code>week</code> column in the pivoted dataset.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb5"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb5-1"><a aria-hidden="true" href="#cb5-1" tabindex="-1"></a>billboard <span className="sc">%&gt;%</span> </span>
<span id="cb5-2"><a aria-hidden="true" href="#cb5-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb5-3"><a aria-hidden="true" href="#cb5-3" tabindex="-1"></a>    <span className="at">cols =</span> <span className="fu">starts_with</span>(<span className="st">"wk"</span>),</span>
<span id="cb5-4"><a aria-hidden="true" href="#cb5-4" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"week"</span>,</span>
<span id="cb5-5"><a aria-hidden="true" href="#cb5-5" tabindex="-1"></a>    <span className="at">names_prefix =</span> <span className="st">"wk"</span>, <span className="co"># remove prefix "wk"</span></span>
<span id="cb5-6"><a aria-hidden="true" href="#cb5-6" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"rank"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 24,092 × 5
<br/>   artist track                   date.entered week   rank
<br/>   &lt;chr&gt;  &lt;chr&gt;                   &lt;date&gt;       &lt;chr&gt; &lt;dbl&gt;
<br/> 1 2 Pac  Baby Don't Cry (Keep... 2000-02-26   1        87
<br/> 2 2 Pac  Baby Don't Cry (Keep... 2000-02-26   2        82
<br/> 3 2 Pac  Baby Don't Cry (Keep... 2000-02-26   3        72
<br/> 4 2 Pac  Baby Don't Cry (Keep... 2000-02-26   4        77
<br/> 5 2 Pac  Baby Don't Cry (Keep... 2000-02-26   5        87
<br/> 6 2 Pac  Baby Don't Cry (Keep... 2000-02-26   6        94
<br/> 7 2 Pac  Baby Don't Cry (Keep... 2000-02-26   7        99
<br/> 8 2 Pac  Baby Don't Cry (Keep... 2000-02-26   8        NA
<br/> 9 2 Pac  Baby Don't Cry (Keep... 2000-02-26   9        NA
<br/>10 2 Pac  Baby Don't Cry (Keep... 2000-02-26   10       NA
<br/># ℹ 24,082 more rows</code></pre>
</div>
</div>
<p>On top of removing the prefix, we can use <strong><code>names_transform</code></strong> and a list of column name - function pair to change the type of the <code>week</code> column (or to perform other processing as demonstrated in later tutorials). The following script turns column <code>week</code> into an integer type.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb7"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb7-1"><a aria-hidden="true" href="#cb7-1" tabindex="-1"></a>billboard <span className="sc">%&gt;%</span> </span>
<span id="cb7-2"><a aria-hidden="true" href="#cb7-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb7-3"><a aria-hidden="true" href="#cb7-3" tabindex="-1"></a>    <span className="at">cols =</span> <span className="fu">starts_with</span>(<span className="st">"wk"</span>),</span>
<span id="cb7-4"><a aria-hidden="true" href="#cb7-4" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"week"</span>,</span>
<span id="cb7-5"><a aria-hidden="true" href="#cb7-5" tabindex="-1"></a>    <span className="at">names_prefix =</span> <span className="st">"wk"</span>, </span>
<span id="cb7-6"><a aria-hidden="true" href="#cb7-6" tabindex="-1"></a>    <span className="co"># convert 'week' to integer</span></span>
<span id="cb7-7"><a aria-hidden="true" href="#cb7-7" tabindex="-1"></a>    <span className="at">names_transform =</span> <span className="fu">list</span>(<span className="at">week =</span> as.integer), </span>
<span id="cb7-8"><a aria-hidden="true" href="#cb7-8" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"rank"</span>)</span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 24,092 × 5
<br/>   artist track                   date.entered  week  rank
<br/>   &lt;chr&gt;  &lt;chr&gt;                   &lt;date&gt;       &lt;int&gt; &lt;dbl&gt;
<br/> 1 2 Pac  Baby Don't Cry (Keep... 2000-02-26       1    87
<br/> 2 2 Pac  Baby Don't Cry (Keep... 2000-02-26       2    82
<br/> 3 2 Pac  Baby Don't Cry (Keep... 2000-02-26       3    72
<br/> 4 2 Pac  Baby Don't Cry (Keep... 2000-02-26       4    77
<br/> 5 2 Pac  Baby Don't Cry (Keep... 2000-02-26       5    87
<br/> 6 2 Pac  Baby Don't Cry (Keep... 2000-02-26       6    94
<br/> 7 2 Pac  Baby Don't Cry (Keep... 2000-02-26       7    99
<br/> 8 2 Pac  Baby Don't Cry (Keep... 2000-02-26       8    NA
<br/> 9 2 Pac  Baby Don't Cry (Keep... 2000-02-26       9    NA
<br/>10 2 Pac  Baby Don't Cry (Keep... 2000-02-26      10    NA
<br/># ℹ 24,082 more rows</code></pre>
</div>
</div>
<p>Alternatively, you can use a single argument with <code>readr::parse_number()</code> to automatically strip non-numeric components.</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb9"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb9-1"><a aria-hidden="true" href="#cb9-1" tabindex="-1"></a>billboard <span className="sc">%&gt;%</span> </span>
<span id="cb9-2"><a aria-hidden="true" href="#cb9-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb9-3"><a aria-hidden="true" href="#cb9-3" tabindex="-1"></a>    <span className="at">cols =</span> <span className="fu">starts_with</span>(<span className="st">"wk"</span>), </span>
<span id="cb9-4"><a aria-hidden="true" href="#cb9-4" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"week"</span>, </span>
<span id="cb9-5"><a aria-hidden="true" href="#cb9-5" tabindex="-1"></a>    <span className="co"># remove non-numeric components, and convert to 'numeric' type</span></span>
<span id="cb9-6"><a aria-hidden="true" href="#cb9-6" tabindex="-1"></a>    <span className="at">names_transform =</span> <span className="fu">list</span>(<span className="at">week =</span> readr<span className="sc">::</span>parse_number),</span>
<span id="cb9-7"><a aria-hidden="true" href="#cb9-7" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"rank"</span>)</span></code></pre></div>
</div>
<p>Use <strong><code>values_drop_na = TRUE</code></strong> to remove rows containing <code>NA</code> values in the <code>rank</code> column. (This effectively converts explicit missing values to implicit missing values, and should generally be used only when missing values in data were created by its structure.)</p>
<div className="cell" data-layout-align="center">
<div className="sourceCode cell-code" id="cb10"><pre className="sourceCode r code-with-copy"><code className="sourceCode r"><span id="cb10-1"><a aria-hidden="true" href="#cb10-1" tabindex="-1"></a>billboard <span className="sc">%&gt;%</span> </span>
<span id="cb10-2"><a aria-hidden="true" href="#cb10-2" tabindex="-1"></a>  <span className="fu">pivot_longer</span>(</span>
<span id="cb10-3"><a aria-hidden="true" href="#cb10-3" tabindex="-1"></a>    <span className="at">cols =</span> <span className="fu">starts_with</span>(<span className="st">"wk"</span>), </span>
<span id="cb10-4"><a aria-hidden="true" href="#cb10-4" tabindex="-1"></a>    <span className="at">names_to =</span> <span className="st">"week"</span>, </span>
<span id="cb10-5"><a aria-hidden="true" href="#cb10-5" tabindex="-1"></a>    <span className="at">names_transform =</span> <span className="fu">list</span>(<span className="at">week =</span> readr<span className="sc">::</span>parse_number),</span>
<span id="cb10-6"><a aria-hidden="true" href="#cb10-6" tabindex="-1"></a>    <span className="at">values_to =</span> <span className="st">"rank"</span>,</span>
<span id="cb10-7"><a aria-hidden="true" href="#cb10-7" tabindex="-1"></a>    <span className="at">values_drop_na =</span> T) <span className="co"># remove rows with NA values in 'rank'.</span></span></code></pre></div>
<div className="cell-output cell-output-stdout">
<pre className="demo-highlight sourceCode r rcss"><code className="sourceCode r"># A tibble: 5,307 × 5
<br/>   artist  track                   date.entered  week  rank
<br/>   &lt;chr&gt;   &lt;chr&gt;                   &lt;date&gt;       &lt;dbl&gt; &lt;dbl&gt;
<br/> 1 2 Pac   Baby Don't Cry (Keep... 2000-02-26       1    87
<br/> 2 2 Pac   Baby Don't Cry (Keep... 2000-02-26       2    82
<br/> 3 2 Pac   Baby Don't Cry (Keep... 2000-02-26       3    72
<br/> 4 2 Pac   Baby Don't Cry (Keep... 2000-02-26       4    77
<br/> 5 2 Pac   Baby Don't Cry (Keep... 2000-02-26       5    87
<br/> 6 2 Pac   Baby Don't Cry (Keep... 2000-02-26       6    94
<br/> 7 2 Pac   Baby Don't Cry (Keep... 2000-02-26       7    99
<br/> 8 2Ge+her The Hardest Part Of ... 2000-09-02       1    91
<br/> 9 2Ge+her The Hardest Part Of ... 2000-09-02       2    87
<br/>10 2Ge+her The Hardest Part Of ... 2000-09-02       3    92
<br/># ℹ 5,297 more rows</code></pre>
</div>
</div>
</main>
</div>
</div>
)}