import {useEffect} from 'react';
// import { useLocation} from 'react-router-dom'
import {loadStylesheet, changeStyle, addCssClass} from "./js/loadStyles"
import {ChangeImageUrlPython, ChangeImageUrlR, ChangeDatasetUrlR} from "./js/changeTagAttributes"
import CopyIcon from "./CopyIcon";

// for python jupyter notebook scripts
export default function useCustomEffect(){
        // for loading scripts
        
        useEffect(() => {
            const images = document.querySelectorAll('img');
            const originalImgSrcMap = new Map()
            images.forEach((image) => {
                const originalSrc = image.getAttribute('src');
                originalImgSrcMap.set(image, originalSrc);
                
                image.classList.add("python-img")
            })
            
            //remove <a class="anchor-link">
            const anchorLinks = document.querySelectorAll('a.anchor-link');
            anchorLinks.forEach((a) => {
                a.remove()
            })
            //remove <div class="jp-InputPrompt jp-InputArea-prompt">
            const jpInputs = document.querySelectorAll('div.jp-InputPrompt.jp-InputArea-prompt')
            jpInputs.forEach((input) => {input.remove()})
            
            // select all <pre> and add class
            const preList = document.querySelectorAll('div pre.demo-highlight')
            preList.forEach((pre) => {
                pre.classList.add("sourceCode", "r")
            })
            // add <p>Output:</p> to jupyter notebook
            const codeElements = document.querySelectorAll('div.jp-OutputArea-output pre.demo-highlight code.sourceCode')
            codeElements.forEach((codeElement) => {
                const pElement = document.createElement('p');
                pElement.setAttribute('class', 'output')
                pElement.textContent = 'Output:';
                codeElement.parentNode.insertBefore(pElement, codeElement)
            })
           
           
            // add <pre> to jupyter table output
            const tables = document.querySelectorAll('div.jp-RenderedHTMLCommon.jp-RenderedHTML div')          
            tables.forEach((table)=> {
                const preElement = document.createElement('pre')
                preElement.classList.add('table')
                preElement.classList.add('flexCenter')
                const nodesToSkip = [];
                while(table.firstChild) {
                    if (table.firstChild.tagName !== 'P'&& table.firstChild.nodeType === Node.ELEMENT_NODE) {
                        preElement.appendChild(table.firstChild)
                    } else {
                        
                        nodesToSkip.push(table.firstChild) 
                        table.removeChild(table.firstChild)
                    }                         
                }
                table.appendChild(preElement)
                nodesToSkip.forEach((node)=> {
                  
                    table.appendChild(node);
                })
               
            })
            // add Output to python returned dataframes
            const tableElements = document.querySelectorAll('div.jp-RenderedHTMLCommon.jp-RenderedHTML.jp-OutputArea-output.jp-OutputArea-executeResult > div > pre')
            // 
            // div.jp-RenderedHTMLCommon div pre.table
            tableElements.forEach((table) => {
                const pElement = document.createElement('p');
                pElement.setAttribute('class', 'output')
                pElement.classList.add('p-t-15')
                pElement.textContent = 'Output:';
                table.parentNode.insertBefore(pElement, table)
            })
            // Adjust for anchor link position
            const handleClick = (event) => {
                event.preventDefault();

                const targetId = event.currentTarget.getAttribute('href').substring(1); // Remove the '#' symbol
                console.log(targetId)
                const target = document.getElementById(targetId);
                
                const offsetTop = target.offsetTop; // Distance from the target element to the top of the document
                // console.log(offsetTop)
                const path = window.location.pathname;
                if (offsetTop) {
                    if (path.includes('R/gallery/')) {
                        const targetPosition = offsetTop + 35; // Adjust for R/gallery
                        console.log(targetPosition)
                        window.scrollTo({
                            top: targetPosition,
                            behavior: 'smooth'
                        });
                    } else {
                        const targetPosition = offsetTop -70; // Adjust for any additional offset
                        console.log(targetPosition)
                        window.scrollTo({
                            top: targetPosition,
                            behavior: 'smooth'
                        });
                    }   
                } else {
                    console.log(offsetTop)
                }         
            };    
            // Add event listeners to anchors
            const anchors = document.querySelectorAll('a[href^="#"]');
            anchors.forEach(anchor => {
                anchor.addEventListener('click', handleClick);
            });


            loadStylesheet('text/css', 'pandoc')
            changeStyle()
            addCssClass()
            // change title h3 tag to h1
            function changeTag() {
                // Select the existing h3 element
                var heading = document.querySelector('h3.posts-heading:not(div.nav-title > *');
                if (heading) {
                    var newHeading = document.createElement('h1');

                    // Copy inner content
                    newHeading.innerHTML = heading.innerHTML;

                    // Copy class attributes
                    newHeading.className = heading.className;

                    // Replace the old h3 with the new h1
                    heading.parentNode.replaceChild(newHeading, heading);
                }
            }
            // call the function to change title h3 tag to h1
            changeTag();
            
            // dynamically change web title based on page content
            const divElement = document.querySelector('.jp-RenderedHTMLCommon');
            // This finds the h3 tag from the Nav Bar
            const subject = document.querySelector('h3')
            if (divElement) {
            // Select the <h3> element inside the <div>
            const h1Element =  document.querySelector('h1.posts-heading');
            if (h1Element){
                    document.title = h1Element.textContent   
                }
            }  
            var description = divElement.querySelector('h1 + p')
            if (description) {
                // Check if meta tag already exists
                var existingMetaTag = document.querySelector('meta[name="description"]');
                
                if (existingMetaTag) {
                    // If it exists, update its content
                    existingMetaTag.setAttribute('content', description.textContent);
                } else {
                    // If it doesn't exist, create and append it
                    var metaTag = document.createElement('meta');
                    metaTag.setAttribute('name', 'description');
                    metaTag.setAttribute('content', description.textContent);
                    document.head.appendChild(metaTag);
                }
            } 

            // add https://s3.amazonaws.com prefix
            ChangeImageUrlPython() 

            // Clean up by removing the script elements when the component unmounts
            return () => {
                const styleTag = document.querySelector(`style[data-origin="pandoc"]`);
                if (styleTag) {
                    document.head.removeChild(styleTag); 
                }
    
                originalImgSrcMap.forEach((originalSrc, image) => {
                    image.setAttribute('src', originalSrc)
                })   
                const pElements = document.querySelectorAll('p.output')
                pElements.forEach((pElement) => {
                    if (pElement.textContent === 'Output:'){
                        pElement.remove();
                    }
                }); 
                // remove pre tags for tables  
                const tables = document.querySelectorAll('.jp-RenderedHTMLCommon div');
                tables.forEach((table) => {
                    const preElement = table.querySelector('pre');
                    if (preElement) {
                    while (preElement.firstChild) {
                        table.appendChild(preElement.firstChild);
                    }
                    table.removeChild(preElement);
                    }
                });  
                anchors.forEach(anchor => {
                    anchor.removeEventListener('click', handleClick);
                });
                
            };     
        }, []); 
        CopyIcon()
}

// for R markdown scripts
export function useRCustomEffect() {
    // for loading scripts
    useEffect(() => {
        // // exclude images under div.book-star
        const images = document.querySelectorAll(':not(.book-star) > img:not(#ggplot2-book)');
        const originalImgSrcMap = new Map()
        images.forEach((image) => {
            const originalSrc = image.getAttribute('src');
            originalImgSrcMap.set(image, originalSrc)
            // add a className to each image for styling purpose
            image.classList.add("post-img")
        })
        // for download dataset, this section must be put before ChangeDatasetUrlR() to get original href
        const downloadDatalinks = document.querySelectorAll('a.downloadData')
        const originalLinkMap = new Map();
        downloadDatalinks.forEach((link)=>{
            const originalLink = link.getAttribute('href')
            originalLinkMap.set(link, originalLink)
        })
        // add <p>Output:</p> to R markdown
        const codeElements = document.querySelectorAll('div.section > pre.sourceCode')
        codeElements.forEach((codeElement) => {
            const pElement = document.createElement('p');
            pElement.setAttribute('class', 'output')
            pElement.textContent = 'Output:';
            // codeElement.parentNode.insertBefore(pElement, codeElement)
            codeElement.prepend(pElement);
        })

        // add another class to R markdown <pre> containing Output 
        const preTags = document.querySelectorAll('div.section > pre.sourceCode')
        preTags.forEach((pre) => {
            pre.classList.add('rcss');
        })

        // add another class to R markdown <code> element under output <pre> tags
        const codeTags = document.querySelectorAll('div.section> pre.sourceCode > code.sourceCode')
        codeTags.forEach((code) => {
            code.classList.add('toggle')
        })

        // // 
        // const codeElements = document.querySelectorAll("#packages-and-data-cleanup > div > div.cell-output.cell-output-stdout > pre > code")
        //     codeElements.forEach((codeElement) => {
        //         const pElement = document.createElement('p');
        //         pElement.setAttribute('class', 'output')
        //         pElement.textContent = 'Output:';
        //         codeElement.parentNode.insertBefore(pElement, codeElement)
        //     })
        const codeEle = document.querySelectorAll("div.cell-output.cell-output-stdout > pre > code")
           if(codeEle) {
            codeEle.forEach((code) => {
                const pEle = document.createElement('p');
                pEle.setAttribute('class', 'output')
                pEle.textContent = 'Output:';
                code.parentNode.insertBefore(pEle, code)
            })
           }


        // Create a function to adjust for anchor link position
        const handleClick = (event) => {
            event.preventDefault();     
            const href = event.currentTarget.getAttribute('href')
            console.log(href)
            if (href) {
                const targetId = href.substring(1); // Remove the '#' symbol                     
                const target = document.getElementById(targetId);
                const offsetTop = target.offsetTop; // Distance from the target element to the top of the document
                // console.log(offsetTop)
                var targetPosition = offsetTop 
                const path = window.location.pathname;
                if (path.includes('R/gallery/')) {
                    var targetPosition = targetPosition + 35; // Adjust for R/gallery
                    // console.log(targetPosition)
 
                } else {
                    var targetPosition = targetPosition- 50; // Adjust for any additional offset
                    console.log(targetPosition)
                    // window.scrollTo({
                    //     top: targetPosition,
                    //     behavior: 'smooth'
                    // });
                };
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }  else {
                console.log(href)
            }   
        };    
        // Add event listeners to anchors
        const anchors = document.querySelectorAll('a[href^="#"]');
        anchors.forEach(anchor => {
            // console.log(anchor)
            anchor.addEventListener('click', handleClick);
        });



        loadStylesheet('text/css', 'pandoc')
        changeStyle()
        addCssClass()
        // add https://s3.amazonaws.com prefix
        ChangeImageUrlR()
        // add https://s3.amazonaws.com prefix to downloadable dataset
        ChangeDatasetUrlR()

        const divElement = document.querySelector('header > div.quarto-title');
        // This finds the h3 tag from the Nav Bar
        // const subject = document.querySelector('h3')
        if (divElement) {
        // Select the <h3> element inside the <div>
        const h1Element = divElement.querySelector('h1');
                if (h1Element){
                    document.title = h1Element.textContent   
                }
            }          
        var description = document.querySelector('header.quarto-title-block + p')
        if (description) {
            // Check if meta tag already exists
            var existingMetaTag = document.querySelector('meta[name="description"]');
            
            if (existingMetaTag) {
                // If it exists, update its content
                existingMetaTag.setAttribute('content', description.textContent);
            } else {
                // If it doesn't exist, create and append it
                var metaTag = document.createElement('meta');
                metaTag.setAttribute('name', 'description');
                metaTag.setAttribute('content', description.textContent);
                document.head.appendChild(metaTag);
            }
        } 

        // This must go after previous anchor link, otherwise it will disrupt the functionality of scrolling  
        // Function to scroll to the target element with the specified ID in URL with #
        const scrollToTarget = () => {
            const hash = window.location.hash;
            if (hash) {
            const target = document.querySelector(hash);
            if (target) {
                const headerOffset = 40; // Adjust this value according to your header height
                const targetPosition = target.getBoundingClientRect().top + window.scrollY;
                const adjustedPosition = targetPosition - headerOffset;
                window.scrollTo({
                    top: adjustedPosition,
                    behavior: 'smooth'
                });
                }
            }
        }; 
        // Scroll to the target element when the component mounts
        scrollToTarget(); 
        window.addEventListener('DOMContentLoaded', scrollToTarget)
        // Scroll to the target element whenever the hash changes (i.e., when navigating within the same page)
        window.addEventListener('hashchange', scrollToTarget)

        // Clean up by removing the script elements when the component unmounts
        return () => {
            const styleTag = document.querySelector(`style[data-origin="pandoc"]`);
            if (styleTag) {
                document.head.removeChild(styleTag);
            }
            // clear image link  
            originalImgSrcMap.forEach((originalSrc, image) => {
                image.setAttribute('src', originalSrc)
            })   
            // clear the download data link  
            originalLinkMap.forEach((originalLink, link) => {
                link.setAttribute('href', originalLink)
            })
            const pElements = document.querySelectorAll('p.output')
            pElements.forEach((pElement) => {
                if (pElement.textContent === 'Output:'){
                    pElement.remove();
                }
            })


            anchors.forEach(anchor => {
                anchor.removeEventListener('click', handleClick);
            });

            window.removeEventListener('hashchange', scrollToTarget);


        };     
    }, []); 
    // insert copy icon for code 
    CopyIcon()
}

// for 2+ versions of PySPark jupyter notebook scripts
export function useSparkCustomEffect(){
    // for loading scripts
    
    useEffect(() => {
        const images = document.querySelectorAll('img');
        const originalImgSrcMap = new Map()
        images.forEach((image) => {
            const originalSrc = image.getAttribute('src');
            originalImgSrcMap.set(image, originalSrc);
            
            image.classList.add("python-img")
        })
        
        //remove <a class="anchor-link">
        const anchorLinks = document.querySelectorAll('a.anchor-link');
        anchorLinks.forEach((a) => {
            a.remove()
        })
        //remove <div class="jp-InputPrompt jp-InputArea-prompt">
        const jpInputs = document.querySelectorAll('div.jp-InputPrompt.jp-InputArea-prompt')
        jpInputs.forEach((input) => {input.remove()})
        
        // select all <pre> and add class
        const preList = document.querySelectorAll('div pre.demo-highlight')
        preList.forEach((pre) => {
            pre.classList.add("sourceCode", "r")
        })
        // add <p>Output:</p> to jupyter notebook
        const codeElements = document.querySelectorAll('div.jp-OutputArea-output pre.demo-highlight code.sourceCode')
        codeElements.forEach((codeElement) => {
            const pElement = document.createElement('p');
            pElement.setAttribute('class', 'output')
            pElement.textContent = 'Output:';
            codeElement.parentNode.insertBefore(pElement, codeElement)
        })
       
       
        // add <pre> to jupyter table output
        const tables = document.querySelectorAll('div.jp-RenderedHTMLCommon.jp-RenderedHTML div')          
        tables.forEach((table)=> {
            const preElement = document.createElement('pre')
            preElement.classList.add('table')
            preElement.classList.add('flexCenter')
            const nodesToSkip = [];
            while(table.firstChild) {
                if (table.firstChild.tagName !== 'P'&& table.firstChild.nodeType === Node.ELEMENT_NODE) {
                    preElement.appendChild(table.firstChild)
                } else {
                    
                    nodesToSkip.push(table.firstChild) 
                    table.removeChild(table.firstChild)
                }                         
            }
            table.appendChild(preElement)
            nodesToSkip.forEach((node)=> {
              
                table.appendChild(node);
            })
           
        })
        // add Output to python returned dataframes
        const tableElements = document.querySelectorAll('div.jp-RenderedHTMLCommon.jp-RenderedHTML.jp-OutputArea-output.jp-OutputArea-executeResult > div > pre')
        // 
        // div.jp-RenderedHTMLCommon div pre.table
        tableElements.forEach((table) => {
            const pElement = document.createElement('p');
            pElement.setAttribute('class', 'output')
            pElement.classList.add('p-t-15')
            pElement.textContent = 'Output:';
            table.parentNode.insertBefore(pElement, table)
        })
        // Adjust for anchor link position
        const handleClick = (event) => {
            event.preventDefault();

            const targetId = event.currentTarget.getAttribute('href').substring(1); // Remove the '#' symbol
            console.log(targetId)
            const target = document.getElementById(targetId);
            
            const offsetTop = target.offsetTop; // Distance from the target element to the top of the document
            // console.log(offsetTop)
            const path = window.location.pathname;
            if (offsetTop) {
                if (path.includes('R/gallery/')) {
                    const targetPosition = offsetTop + 35; // Adjust for R/gallery
                    console.log(targetPosition)
                    window.scrollTo({
                        top: targetPosition,
                        behavior: 'smooth'
                    });
                } else {
                    const targetPosition = offsetTop -70; // Adjust for any additional offset
                    console.log(targetPosition)
                    window.scrollTo({
                        top: targetPosition,
                        behavior: 'smooth'
                    });
                }   
            } else {
                console.log(offsetTop)
            }         
        };    
        // Add event listeners to anchors
        const anchors = document.querySelectorAll('a[href^="#"]');
        anchors.forEach(anchor => {
            anchor.addEventListener('click', handleClick);
        });


        loadStylesheet('text/css', 'pandoc')
        changeStyle()
        addCssClass()
        
        // dynamically change web title based on page content
        const divElement = document.querySelector('.jp-RenderedHTMLCommon');
        // This finds the h3 tag from the Nav Bar
        const subject = document.querySelector('h3')
        if (divElement) {
        // Select the <h3> element inside the <div>
        const h1Element =  document.querySelector('h1.posts-heading');
        if (h1Element){
                document.title = h1Element.textContent   
            }
        }  
        var description = divElement.querySelector('h1 + p')
        if (description) {
            // Check if meta tag already exists
            var existingMetaTag = document.querySelector('meta[name="description"]');
            
            if (existingMetaTag) {
                // If it exists, update its content
                existingMetaTag.setAttribute('content', description.textContent);
            } else {
                // If it doesn't exist, create and append it
                var metaTag = document.createElement('meta');
                metaTag.setAttribute('name', 'description');
                metaTag.setAttribute('content', description.textContent);
                document.head.appendChild(metaTag);
            }
        } 

        // add https://s3.amazonaws.com prefix
        ChangeImageUrlPython() 

        // Clean up by removing the script elements when the component unmounts
        return () => {
            const styleTag = document.querySelector(`style[data-origin="pandoc"]`);
            if (styleTag) {
                document.head.removeChild(styleTag); 
            }

            originalImgSrcMap.forEach((originalSrc, image) => {
                image.setAttribute('src', originalSrc)
            })   
            const pElements = document.querySelectorAll('p.output')
            pElements.forEach((pElement) => {
                if (pElement.textContent === 'Output:'){
                    pElement.remove();
                }
            }); 
            // remove pre tags for tables  
            const tables = document.querySelectorAll('.jp-RenderedHTMLCommon div');
            tables.forEach((table) => {
                const preElement = table.querySelector('pre');
                if (preElement) {
                while (preElement.firstChild) {
                    table.appendChild(preElement.firstChild);
                }
                table.removeChild(preElement);
                }
            });  
            anchors.forEach(anchor => {
                anchor.removeEventListener('click', handleClick);
            });
            
        };     
    }, []); 
    CopyIcon()
}
