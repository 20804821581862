import React from 'react'; 
import {Link} from 'react-router-dom'; 
import useCustomEffect from '../../../useCustomEffect'; 
export default function Python1(){
useCustomEffect()
return ( <div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h3 id="Before-Start">Get Ready for the Data Wrangling Tutorial<a className="anchor-link" href="#Before-Start">¶</a></h3><p>To better prepare ourselves for following courses, we need to set up our environment and get dataset ready.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="1.-Download-sample-dataset">1. Download sample dataset<a className="anchor-link" href="#1.-Download-sample-dataset">¶</a></h4><p>We'll be using the classic machine learning dataset named <strong>Iris</strong> for exploring data wrangling in Python. Click <a href="https://3codeacademy.s3.amazonaws.com/dataset/python/Iris.csv" id="downloadData">here</a> to download the dataset.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="2.-Install-Python">2. Install Python<a className="anchor-link" href="#2.-Install-Python">¶</a></h4><p>To proceed with this tutorial, ensure that Python is installed in your environment. If you haven't already installed Python, you can follow this guide: <Link to="../../python-tutorial/install-python">How to Install Python</Link>.</p>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="3.-Install-pandas-library">3. Install pandas library<a className="anchor-link" href="#3.-Install-pandas-library">¶</a></h4><p>To use the <strong>pandas</strong> library, we'll need to install it within Python environment. Running the following code in either terminal or Jupyter Notebook:</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [ ]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">pip</span> <span className="n">install</span> <span className="n">pandas</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="4.-Import-pandas-library">4. Import pandas library<a className="anchor-link" href="#4.-Imoprt-pandas-library">¶</a></h4><p>In your IDE or Jupyter notebook, initiate a new Python script and input the following code to import the <strong>pandas</strong> package.</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [2]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="kn">import</span> <span className="nn">pandas</span> <span className="k">as</span> <span className="nn">pd</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="5.-Load-data-as-a-pandas-dataframe">5. Load data as a pandas dataframe<a className="anchor-link" href="#5.-Load-data-as-a-pandas-dataframe">¶</a></h4><p>To initiate your exploration of the <strong>Iris</strong> data, execute the following code snippet. Be sure to substitute <code>Iris.csv</code> with the accurate file path reflecting where you've saved the Iris dataset you downloaded:</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell jp-mod-noOutputs">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [3]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span> <span className="o">=</span> <span className="n">pd</span><span className="o">.</span><span className="n">read_csv</span><span className="p">(</span><span className="s1">'Iris.csv'</span><span className="p">)</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<h4 id="6.-Check-if-the-dataset-loaded-successfully">6. Check if the dataset loaded successfully<a className="anchor-link" href="#6.-Check-if-the-dataset-loaded-successfully">¶</a></h4><p>Once you've effectively imported the <strong>Iris</strong> data into a Pandas DataFrame, confirm this by invoking <code>df.head()</code>, returning the initial 5 rows of the dataset as illustrated below:</p>
</div>
</div>
</div>
</div><div className="jp-Cell jp-CodeCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea">
<div className="jp-InputPrompt jp-InputArea-prompt">In [4]:</div>
<div className="jp-CodeMirrorEditor jp-Editor jp-InputArea-editor" data-type="inline">
<div className="CodeMirror cm-s-jupyter">
<div className="highlight hl-ipython3"><pre className='demo-highlight python'><code className='sourceCode'><span><span className="n">df</span><span className="o">.</span><span className="n">head</span><span className="p">()</span></span>

</code></pre></div>
</div>
</div>
</div>
</div>
<div className="jp-Cell-outputWrapper">
<div className="jp-Collapser jp-OutputCollapser jp-Cell-outputCollapser">
</div>
<div className="jp-OutputArea jp-Cell-outputArea">
<div className="jp-OutputArea-child">
<div></div><div className="jp-RenderedHTMLCommon jp-RenderedHTML jp-OutputArea-output jp-OutputArea-executeResult" data-mime-type="text/html">
<div>

<br /><table border="1" className="dataframe">
<thead>
<tr>
<th></th>
<th>Id</th>
<th>SepalLengthCm</th>
<th>SepalWidthCm</th>
<th>PetalLengthCm</th>
<th>PetalWidthCm</th>
<th>Species</th>
</tr>
</thead>
<tbody>
<tr>
<th>0</th>
<td>1</td>
<td>5.1</td>
<td>3.5</td>
<td>1.4</td>
<td>0.2</td>
<td>Iris-setosa</td>
</tr>
<tr>
<th>1</th>
<td>2</td>
<td>4.9</td>
<td>3.0</td>
<td>1.4</td>
<td>0.2</td>
<td>Iris-setosa</td>
</tr>
<tr>
<th>2</th>
<td>3</td>
<td>4.7</td>
<td>3.2</td>
<td>1.3</td>
<td>0.2</td>
<td>Iris-setosa</td>
</tr>
<tr>
<th>3</th>
<td>4</td>
<td>4.6</td>
<td>3.1</td>
<td>1.5</td>
<td>0.2</td>
<td>Iris-setosa</td>
</tr>
<tr>
<th>4</th>
<td>5</td>
<td>5.0</td>
<td>3.6</td>
<td>1.4</td>
<td>0.2</td>
<td>Iris-setosa</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="jp-Cell jp-MarkdownCell jp-Notebook-cell">
<div className="jp-Cell-inputWrapper">
<div className="jp-Collapser jp-InputCollapser jp-Cell-inputCollapser">
</div>
<div className="jp-InputArea jp-Cell-inputArea"><div className="jp-InputPrompt jp-InputArea-prompt">
</div><div className="jp-RenderedHTMLCommon jp-RenderedMarkdown jp-MarkdownOutput" data-mime-type="text/markdown">
<p>Great job!🚀 You've successfully completed setting up the environment. Continue reading to dive deeper into exploration!</p>
</div>
</div>
</div>
</div>
</div>
)}